/**
 * Reducer
 * @param {*} state // state of the current reducer
 * @param {*} action // action dispatched {type:"",payload:{}}
 */
const initialState = {
	showSummaryInfo: true,
	applicationSummary: {},
	licenseSummary: {},
	vendorSummary: {},
	securitySummary: {},
};

export const SUMMARY_INFO = {
	SHOW_OR_HIDE: "SHOW_SUMMARY_INFO",
	SUMMARY_INFO_REQUESTED: "SUMMARY_INFO_REQUESTED",
	SET_SUMMARY_INFO: "SET_SUMMARY_INFO",
};

export function SummaryReducer(state = initialState, action) {
	switch (action.type) {
		case SUMMARY_INFO.SUMMARY_INFO_REQUESTED:
			var tempState = { ...state };
			tempState[action.payload.entity] = {
				...tempState[action.payload.entity],
				isLoadingData: true,
			};
			return tempState;

		case SUMMARY_INFO.SET_SUMMARY_INFO:
			var tempState = { ...state };
			tempState[action.payload.entity].data = { ...action.payload.data };
			return {
				...tempState,
				[action.payload.entity]: {
					...tempState[action.payload.entity],
					isLoadingData: false,
					error: action.payload.err ? action.payload.err : null,
				},
			};

		case SUMMARY_INFO.SHOW_OR_HIDE:
			var tempState = { ...state };

			return {
				...tempState,
				...action.payload,
			};
		default:
			return state;
	}
}
