import React, { useRef, useState } from "react";
import { Calendar } from "react-calendar";
import { useOutsideClickListener } from "utils/clickListenerHook";
import { dateResetTimeZone, UTCDateFormatter } from "utils/DateUtility";
import cal from "../DateRangePicker/calendarNew.svg";
import "components/Applications/Contracts/Contracts.css";
import moment from "moment";
import "./DatePicker.css";

export default function NewDatePicker({
	value = null,
	calendarContainerClassName = "default-date-range-picker-calendar-class",
	calendarClassName,
	minDate,
	maxDate,
	defaultCalendarView = "month",
	calendarView = "month",
	dateFormatter = (date, format) => moment(date).format(format),
	dateFormat = "DD MMM YYYY",
	style,
	onChange,
	onClickMonth = false,
	onClickYear = false,
	placeholder = "Date",
	height = "36px",
	width = "auto",
	disabled = false,
	showCalendarSVG = true,
	togglerClassName,
	showPlaceholderOnly = false,
}) {
	const ref = useRef();
	const [showCalendar, setShowCalendar] = useState(false);
	const [date, setDate] = useState(value ? new Date(value) : value);

	useOutsideClickListener(ref, () => {
		setShowCalendar(false);
	});

	const handleDateChange = (date) => {
		const newDate = dateResetTimeZone(date);
		setDate(newDate);
		onChange && onChange(newDate);
		setShowCalendar(false);
	};

	return (
		<div style={{ position: "relative" }}>
			<div
				key={`${value}`}
				className={`position-relative ${
					togglerClassName
						? togglerClassName
						: "d-flex flex-row border-1 border-radius-4 font-12 align-items-center pl-1 pr-2"
				} ${disabled ? "background-disabled" : "cursor-pointer"}`}
				style={{
					height: height,
					width: width,
					minWidth: "125px",
					...style,
				}}
				onClick={(e) => {
					!disabled && setShowCalendar(true);
				}}
			>
				{showCalendarSVG && (
					<img
						src={cal}
						height={24}
						width={24}
						className="date-range-picker-calendar-img mr-1"
					/>
				)}
				<div>
					{date && !showPlaceholderOnly
						? dateFormatter(date, dateFormat)
						: placeholder}
				</div>
			</div>
			{showCalendar && (
				<div
					ref={ref}
					className={`position-absolute z-index-20 ${calendarContainerClassName} new_date_picker_calendar_container_div`}
				>
					<Calendar
						value={date}
						onClickMonth={(date) =>
							onClickMonth && handleDateChange(date)
						}
						onClickYear={(date) =>
							onClickYear && handleDateChange(date)
						}
						onChange={(date) => {
							calendarView === "month" && handleDateChange(date);
						}}
						view={calendarView}
						maxDate={maxDate ? new Date(maxDate) : null}
						minDate={minDate ? new Date(minDate) : null}
						className={
							calendarClassName
								? `${calendarClassName} custom__calendar`
								: "custom__calendar"
						}
					/>
				</div>
			)}
		</div>
	);
}
