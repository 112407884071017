import _ from "underscore";
import { INVALID_DATA } from "./BulkEdit.constants";

export const validateDataObject = (data) => {
	if (_.isObject(data) && Object.keys(data)?.length > 0) return data;
	return INVALID_DATA;
};

const getExtraDataKeys = (menu, selectedOption) => {
	const obj = {};
	menu?.requiredDataKeys?.map((key) => {
		return (obj[key] = selectedOption?.[key]);
	});
	return obj;
};

export const getRefactoredData = (apiData) => {
	const { menu, selectedOption, selectedRows, unselectedRows, apiProps } =
		apiData;
	const rowsKey = menu?.selectedRowsKey || "ids";
	const patchKey = menu?.patchKey || "patchKey";
	const accessRowData = menu?.accessRowData;
	return {
		[rowsKey]: Object.keys(selectedRows) || [],
		[patchKey]: selectedOption?.[menu?.patchDataKey],
		rowData: accessRowData && selectedRows,
		unselectedRows,
		...getExtraDataKeys(menu, selectedOption),
		...apiProps,
	};
};

export const updateSubMenuWithCountOptions = ({
	option,
	selectedRows,
	noofRowsSelected,
}) => {
	const data = Object.values(selectedRows);

	const updatedSubMenu = option?.submenu?.reduce((obj, curr) => {
		if (!curr.showCount) return obj;
		let count = 0;
		for (const task of data) {
			if (task[curr.countVars.key] === curr.countVars.value) {
				count++;
			}
		}
		const show = !!count;
		if (!show) return [...obj];
		return [
			...obj,
			{ ...curr, count, totalCount: noofRowsSelected, show: !!count },
		];
	}, []);

	return { ...option, submenu: updatedSubMenu };
};
