import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
function getProgressStyle(value, canShowProgressBar = true) {
	const commonStyles = {
		trailColor: "transparent",
		textSize: canShowProgressBar ? "40px" : "52px",
		rotation: 0.5,
	};
	if (value >= 75) {
		return {
			...commonStyles,
			backgroundColor: "#E7F8E8",
			textColor: "#009307",
			pathColor: canShowProgressBar ? "#009307" : "transparent",
		};
	} else if (value >= 50) {
		return {
			...commonStyles,
			textColor: "#FFA217",
			backgroundColor: "#FFF6DC",
			pathColor: canShowProgressBar ? "#FFA217" : "transparent",
		};
	} else if (value >= 25) {
		return {
			...commonStyles,
			backgroundColor: "#EBEBEB",
			textColor: "#717171",
			pathColor: canShowProgressBar ? "#717171" : "transparent",
		};
	} else {
		return {
			...commonStyles,
			backgroundColor: "#FFE9E5",
			textColor: "#FE8955",
			pathColor: canShowProgressBar ? "#FE6955" : "transparent",
		};
	}
}

export function CustomCircularProgress({ data, component, ...props }) {
	let value = data?.[component?.valueKey] ?? 0;
	value = component?.showAbsoluteValue ? Math.floor(value) : value;
	let style = getProgressStyle(value, component.showProgressBar);
	return (
		<div
			style={{
				height: "24px",
				width: "24px",
				fontWeight: 900,
				...component.style,
			}}
		>
			<CircularProgressbar
				background
				text={Math.round(value) ?? 0}
				value={Math.round(value) ?? 0}
				maxValue={component?.maxValue || 100}
				styles={buildStyles(style)}
				strokeWidth={12}
			/>
		</div>
	);
}
function humanizeNumber(num) {
	function formatValue(value, suffix) {
		let baseValue = Math.floor(value);
		let decimalValue = value.toFixed(1);
		let formatted =
			value === baseValue ? baseValue.toString() : decimalValue;
		return formatted + suffix;
	}

	if (num < 1000) {
		return num.toString();
	} else if (num < 1000000) {
		return formatValue(num / 1000, "k");
	} else if (num < 1000000000) {
		return formatValue(num / 1000000, "M");
	} else {
		return formatValue(num / 1000000000, "B");
	}
}
