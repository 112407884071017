import moment from "moment";
import _, { isUndefined, isEmpty, isNull } from "underscore";
import {
	DUE_DATE,
	HASH_COMPLETED_DETAILS,
	HASH_ONGOING_DETAILS,
	HASH_UPCOMING_DETAILS,
	INVALID_DATA,
	MAX_NUMBER_OF_LEVELS,
	PENDING,
	START_DATE,
	TODAY,
	USER_ROLES,
	accessReviewUrls,
	actionTextMapper,
	actions,
	adminViewEntities,
	archiveCertGroups,
	certification_stage,
	certifications,
	colorMapForReviewDetails,
	configureReviewActions,
	empViewEntites,
	entityTypes,
	quickfiltersDataForReviewActionsForAdmin,
	quickfiltersDataForReviewActionsForEmp,
	reviewActionEditOptionsText,
	reviewActionsMapper,
	stackDataMapper,
	tasks,
} from "modules/AccessReview/constants/constants";
import { getSearchReqObj } from "common/infiniteTableUtil";
import clock from "assets/icons/clock.svg";
import greenCircle from "assets/icons/green-circle.svg";
import blueCircle from "assets/icons/blue-circle.svg";
import redClock from "assets/icons/red-clock.svg";
import { useSelector } from "react-redux";
import { getTooltipContent } from "../components/common/common";

export const IsCertOwner = (userId) => {
	const userInfo = useSelector((state) => state.userInfo);
	return userId === userInfo?.user_id;
};

export const checkIfAllFieldsFilled = (dataObj) => {
	const avoidVals = ["description"];
	const keys = Object.keys(dataObj).length;
	let vals = 0;
	if (keys) {
		vals = Object.keys(dataObj).filter((key) => {
			const val = dataObj[key];
			if (avoidVals.includes(key)) return false;
			if (_.isEmpty(val)) return true;
			return false;
		});
	}
	return vals.length !== 0 ? false : true;
};

export const checkIfAllFieldsFilledInObj = (dataObj) => {
	let actionObj = {};
	let stepsValidated = Object.keys(actions)?.map(() => false);
	Object.keys(actions).forEach((action, i) => {
		if (dataObj?.[action] && action === actions?.on_accept) {
			actionObj = dataObj?.[action];
			stepsValidated[i] =
				actionObj?.action === configureReviewActions.DO_NOTHING;
		}
		if (
			dataObj?.[action] &&
			(action === actions?.on_decline || action === actions?.on_modify)
		) {
			actionObj = dataObj?.[action];
			if (actionObj?.action !== configureReviewActions.SELECT_PLAYBOOK)
				return;

			stepsValidated[i] =
				actionObj?.workflow_template_id &&
				Object.keys(actionObj?.workflow_template_id)?.length > 0;
		}
	});

	let len = stepsValidated?.length - 1;
	while (len >= 0) {
		if (!stepsValidated[len]) return false;
		--len;
	}
	return true;
};

export const getFilterObj = (query) => {
	// did not add a filter for playbooks with variables here since this is Access Reviews, which will be making use of these playbooks
	const isPublishedObj = {
		field_values: [true],
		field_order: "contains",
		field_id: "is_published",
		filter_type: "boolean",
		field_name: "Published",
		negative: false,
		is_custom: false,
	};
	if (query && query.length > 0) {
		let filter_by = [getSearchReqObj(query, "name", "Workflow Name")];
		filter_by.push(isPublishedObj);
		return { filter_by: filter_by, sort_by: [], columns: [] };
	}
	return {
		filter_by: [{ ...isPublishedObj }],
		sort_by: [],
		columns: [],
	};
};

export const refactorRolesData = (roles) => {
	const keys = Object.keys(roles);
	let dataArr = [];
	keys &&
		keys.forEach((key) => {
			const obj = roles[key];
			const name = getRoleTypeString(key);
			dataArr.push({ ...obj, user_name: name, key: key });
		});
	return dataArr;
};

export const getRoleTypeString = (key) => {
	if (!key) return;
	const role = USER_ROLES?.[key];
	if (role) return role;
	const stringWithoutUnderscore = capitalizeFirstLetters(key, "_");
	return capitalizeFirstLetters(stringWithoutUnderscore);
};

export const capitalizeFirstLetters = (string, delimiter = " ") => {
	if (!string) return "";
	const words = string.split(delimiter);
	const res = words
		.map((word) => {
			return word[0].toUpperCase() + word.substring(1);
		})
		.join(" ");
	return res;
};

export const getMatchingColumnObjects = (columns, allColumnObjects) => {
	let res = [];
	if (allColumnObjects?.length === 0 || columns?.length === 0) return res;
	res = allColumnObjects
		?.map((columnObj) => {
			if (columns?.includes(columnObj?.group_name)) return columnObj;
		})
		.filter((obj) => obj);
	return res;
};

export const refactorUserData = (users) => {
	const newArr = users?.map((user) => {
		return { ...user, profile_img: user["user_profile"] };
	});
	return newArr;
};

export const refactorAppData = (apps, applications) => {
	const addedApplicationsIds = applications?.map((app) => app?.appId);
	let newArr = apps
		?.map((app) => {
			return {
				...app,
				app_type:
					app.app_sub_categories[0]?.sub_category_name ||
					app.app_short_description,
			};
		})
		.filter((app) => !addedApplicationsIds.includes(app?.app_id));
	return newArr;
};
export const getDaysDifferenceFromToday = (dateString) => {
	let today = moment.utc();
	let inputDate = moment.utc(dateString);
	today.set({ minutes: 59, hour: 23, seconds: 59 });
	return Math.round(inputDate.diff(today, "minutes") / (60 * 24));
};

export const isGraterThenToday = (date) => {
	let now = new Date();
	let startDate = new Date();
	startDate.setTime(Date.parse(date));

	return startDate.getTime() > now.getTime();
};

export const getOverDueMessage = (days, startDueDate) => {
	if (startDueDate === undefined) {
		if (days > 0) {
			return `pending in ${days} days`;
		} else {
			return `overdue:${Math.abs(days)} days`;
		}
	} else {
		if (days === 1) {
			return `Due in ${days} day`;
		} else if (days === 0) {
			return `Due today`;
		} else if (days === -1) {
			return `Overdue: ${Math.abs(days)} day`;
		} else if (days < -1) {
			return `Overdue: ${Math.abs(days)} days`;
		} else if (days > 1) {
			return `Due in ${days} days`;
		}
	}
};

export const formatDate = (date, format = "YYYY-MM-DD", nonUtc) => {
	if (nonUtc) return date && moment(date).format(format);
	return date && moment.utc(date).format(format);
};

export const getDifferenceInDates = (dateOne, dateTwo, format) => {
	if (dateOne && dateTwo) {
		const formattedDateOne = moment.utc(dateOne);
		const formattedDateTwo = moment.utc(dateTwo);
		return formattedDateOne.diff(formattedDateTwo, format);
	}
};

export const getRefactoredDataForBulkEdit = (data) => {
	const {
		comment = "",
		review_status,
		select_all = false,
		filter_by = [],
		rowData,
		userInfo,
		partiallyUnselected,
		unselectedRows,
		reviewer_id,
	} = data?.value;
	const unselectedRowIds = getUnselectedRowsForBulkEdit(unselectedRows);
	const requestBody = {
		...getDefaultBulkEditProps(
			select_all,
			partiallyUnselected,
			filter_by,
			unselectedRowIds,
			rowData
		),
		...getSelectOrUnselect(unselectedRowIds, select_all, userInfo, rowData),
		review_status: review_status,
		reviewer_id: reviewer_id,
		comment: comment,
	};

	return requestBody;
};

export const getDefaultBulkEditProps = (
	select_all,
	partiallyUnselected,
	filter_by,
	unselectedRowIds,
	rowData
) => {
	return {
		select_all: {
			enabled: select_all || partiallyUnselected || false,
			filter_by: filter_by,
			unselected_access_certification_user_id: unselectedRowIds,
		},
	};
};

export const getReviewersData = (levels) => {
	if (!levels) return [];
	const res = levels?.map((level, id) => {
		let levelData = {
			level: id + 1,
			primary_reviewer: {
				...getUserOrRoleData(
					level?.primary_reviewer?._id,
					level?.primary_reviewer?.key
				),
			},
			fallback_reviewer_user_id: level?.fallback_reviewer?._id,
		};
		delete levelData?.key;
		return levelData;
	});
	return res;
};

export const getUserOrRoleData = (userId, roleType) => {
	if (userId && userId.length > 0) {
		return { user_id: userId };
	} else {
		return { role: userId ? null : roleType };
	}
};

export const getIsValid = (data, id) => {
	return !(_.isEmpty(data) || !data?.reviewId || data?.reviewId !== id);
};

export const getPercentageOfRecordsCompleted = (reviewDetails) => {
	if (!reviewDetails) {
		return 0;
	}
	const { totalRecords, pendingRecords } = reviewDetails;

	if (!totalRecords || totalRecords === 0) {
		return 0;
	} else {
		return Math.floor(
			((totalRecords - pendingRecords) * 100) / totalRecords
		);
	}
};

export const getClass = (stage) => {
	if (isUndefined(stage)) return "";
	switch (stage) {
		case certification_stage.REVIEW:
			return "application__card__detailed__bar";
		case certification_stage.ACTIONS:
			return "application__card__detailed__bar__task__stage";
		case certification_stage.COMPLETED:
			return "application__card__detailed__completed__bar";
		default:
			return "";
	}
};

export const getSelectOrUnselect = (
	unselectedData,
	select_all,
	userInfo = {},
	rowData
) => {
	const selectedRowIds = getUserIdsForBulkEdit(
		select_all,
		rowData,
		userInfo,
		true
	);
	if (unselectedData?.length === 0) {
		return selectedRowIds;
	}
};

export const getUnselectedRowsForBulkEdit = (unselectedRows) => {
	return Object.values(unselectedRows || {})?.map(
		(obj) => obj?.access_certification_user_id
	);
};

const getUserIdsForBulkEdit = (
	select_all,
	rowData,
	userInfo,
	allRows = false
) => {
	if (select_all || !rowData) {
		return {};
	}
	if (allRows) {
		return {
			access_certification_user_id: Object.values(rowData)?.map(
				(obj) => obj?.access_certification_user_id
			),
		};
	}
	const res = {
		access_certification_user_id: [],
	};

	userInfo &&
		Object.values(rowData)?.forEach((obj) => {
			if (obj?.reviewer_id === userInfo?.user_id) {
				res.access_certification_user_id.push(
					obj?.access_certification_user_id
				);
			}
		});
	return res;
};

export const getQuickFiltersData = (isEmployee, dataObj) => {
	const quickFiltersCopy = isEmployee
		? quickfiltersDataForReviewActionsForEmp.slice()
		: quickfiltersDataForReviewActionsForAdmin.slice();
	const fieldIdsArr = quickFiltersCopy.map((filter) => filter?.field_id);

	fieldIdsArr?.forEach((fieldId) => {
		const index = quickFiltersCopy.findIndex(
			(obj) => obj?.field_id === fieldId
		);
		switch (fieldId) {
			case "review_status":
				break;
			case "reviewer_id":
				if (index > -1) {
					const reviewer_id_filterObj = quickFiltersCopy[index];
					reviewer_id_filterObj["field_values"] = [
						dataObj?.userInfo?.user_id,
					];
					reviewer_id_filterObj["dynamicFilterData"] = [
						{
							value: dataObj?.userInfo?.user_id,
							label: dataObj?.userInfo?.user_name,
							reviewer_id: dataObj?.userInfo?.user_id,
							reviewer_name: dataObj?.userInfo?.user_name,
							reviewer_profile_image: null,
							reviewer_email: dataObj?.userInfo?.alternate_emails,
						},
					];
					quickFiltersCopy[index] = reviewer_id_filterObj;
				}
				break;
			case "signoff_level":
				if (index > -1) {
					const reviewer_id_filterObj = quickFiltersCopy[index];
					reviewer_id_filterObj["field_values"] = [0];
					quickFiltersCopy[index] = reviewer_id_filterObj;
				}
				break;
			default:
				break;
		}
	});
	return quickFiltersCopy;
};

export const getArrayOfFilterObjs = (
	dataObj,
	keyName = "field_name",
	keyVal = "field_values"
) => {
	let res = [];
	if (!keyName || dataObj?.length === 0) return [];
	dataObj?.forEach((obj) => {
		res.push({ key: obj?.[keyName], val: obj?.[keyVal] });
	});
	return res;
};

export const getCertData = (stage, app) => {
	if (!stage) return;
	return stage === certification_stage.ACTIONS
		? app?.actionDetails
		: app?.reviewDetails;
};

export const getChartDataFromReviewDetails = (certStage, reviewDetails) => {
	switch (certStage) {
		case certification_stage.REVIEW:
			let chartData = [
				{
					value: reviewDetails?.approvedRecords || 0,
					color: colorMapForReviewDetails.approvedRecords,
				},
				{
					value: reviewDetails?.downgradedRecords || 0,
					color: colorMapForReviewDetails.downgradedRecords,
				},
				{
					value: reviewDetails?.declinedRecords || 0,
					color: colorMapForReviewDetails.declinedRecords,
				},
				{
					value:
						reviewDetails?.totalRecords &&
						reviewDetails?.totalRecords !== 0
							? reviewDetails.totalRecords -
								(reviewDetails.approvedRecords +
									reviewDetails.declinedRecords +
									reviewDetails.downgradedRecords)
							: 1,
					color: colorMapForReviewDetails.pendingRecords,
				},
			];
			return chartData;

		case certification_stage.ACTIONS:
			let charData = [
				{
					value: reviewDetails?.completedRecords || 0,
					color: colorMapForReviewDetails.approvedRecords,
				},
				{
					value: reviewDetails?.notRequiredRecords || 0,
					color: colorMapForReviewDetails.notRequiredRecords,
				},
				{
					value: reviewDetails?.pendingRecords || 0,
					color: colorMapForReviewDetails.pendingRecords,
				},
			];

			if (charData.filter((data) => data.value !== 0).length === 0) {
				charData.push({
					value: 1,
					color: colorMapForReviewDetails.pendingRecords,
				});
			}

			return charData;

		case certification_stage.COMPLETED:
			break;

		default:
			return [];
	}
};

export const enableConcludeBtn = (
	stageData,
	data,
	userInfo,
	concludeReviewJobStatus
) => {
	const stage = stageData?.stage;
	const isCertOwner = data?.reviewOwner?.user_id === userInfo?.user_id;
	switch (stage) {
		case certification_stage.REVIEW:
			if (!isCertOwner) return false;

			const conculdeReviewJobPending = ["pending"].includes(
				concludeReviewJobStatus
			);

			if (conculdeReviewJobPending) return false;

			const conculdeReviewJobNotPending = [
				"completed",
				"not_started",
				"failed",
			].includes(concludeReviewJobStatus);

			const allAppsReadyForRemediation =
				data?.applications?.filter((app) => app?.readyForRemediation)
					?.length === data?.applications?.length;

			return allAppsReadyForRemediation && conculdeReviewJobNotPending;

		case certification_stage.USER_CREATION_IN_PROGRESS:
			return false;
		case certification_stage.ACTIONS:
			return true;

		default:
			break;
	}
};

export const getReviewersObj = (primaryReviewer, fallbackReviewer) => {
	const primaryReviewerId = primaryReviewer?._id || primaryReviewer?.user_id;
	const isUser = primaryReviewerId && primaryReviewerId?.length > 0;
	return {
		review_user_type: isUser ? "user" : "role",
		review_user_value: isUser
			? primaryReviewerId
			: primaryReviewer?.key || primaryReviewer?.role_type,
		fallback_reviewer_id:
			fallbackReviewer?._id || fallbackReviewer?.user_id,
	};
};

export const getUnderscoreVal = (string, limiter) => {
	if (string && limiter) {
		return string.split(" ").join(limiter);
	}
};

export const getNewNoteMessage = (notes) => {
	return notes && notes.length > 0
		? `${notes.length} New notes added`
		: undefined;
};

export const getFilters = (isEdit, applicationReview) => {
	if (applicationReview?.filter_by || isEdit) {
		return applicationReview?.filter_by;
	}
	return [];
};

export const enablePointer = (stage, isEmployee, isUserCertOwner) => {
	const isUserCreationStageOrUpcoming =
		stage === certification_stage.USER_CREATION_IN_PROGRESS ||
		stage === certification_stage.UPCOMING;

	if (isEmployee) {
		switch (stage) {
			case certification_stage.REVIEW:
				return true;
			case certification_stage.ACTIONS:
			case certification_stage.COMPLETED:
				return isUserCertOwner;
			case certification_stage.UPCOMING:
			case certification_stage.USER_CREATION_IN_PROGRESS:
				return false;
			default:
				break;
		}
	} else {
		return !isUserCreationStageOrUpcoming;
	}
};

export const setDaysObjects = (sd, ed, td, firstWidth, secondWidth) => {
	const today = moment.utc(td);
	const start_date = moment.utc(sd);
	const end_date = moment.utc(ed);
	const daysData = getDateObjects(start_date, today, end_date);
	const reorderedDates = reorderDates(daysData, start_date, today, end_date);
	setUpConfigsForDates(reorderedDates, firstWidth, secondWidth);
	return reorderedDates;
};

const reorderDates = (daysData, start_date, today, end_date) => {
	let mapper = [daysData?.startDate, daysData?.dueDate];
	if (today < start_date) {
		mapper.unshift(daysData?.today);
	} else if (today >= start_date && today <= end_date) {
		mapper.splice(1, 0, daysData?.today);
	} else if (today > end_date) {
		mapper.push(daysData?.today);
	}
	return mapper;
};

const setUpConfigsForDates = (reorderedDates, firstWidth, secondWidth) => {
	const config = [
		{
			style: {
				width: "130%",
				border: "1px solid #ebebeb",
			},
			lineWidth: "115%",
		},
		{
			style: {
				width: "130%",
				border: "1px dashed #82A7ED",
			},
			lineWidth: "110%",
		},
	];
	let isDueDateMiddle = reorderedDates[1].text === DUE_DATE;
	let isStartDateMiddle = reorderedDates[1].text === START_DATE;

	styleUpdatesToDateObjs(
		reorderedDates,
		isDueDateMiddle,
		isStartDateMiddle,
		config
	);
	//Handle default props order
	reorderedDates[0] = {
		...reorderedDates[0],
		...config[0],
		width: firstWidth,
	};
	reorderedDates[1] = {
		...reorderedDates[1],
		...config[1],
		width: secondWidth,
	};

	reorderedDates[2] = {
		...reorderedDates[2],
		classNames: `d-flex justify-content-end w-20 ${
			isDueDateMiddle ? "mb-28px" : ""
		}`,
	};
};

const styleUpdatesToDateObjs = (
	reorderedDates,
	isDueDateMiddle,
	isStartDateMiddle,
	config
) => {
	if (isStartDateMiddle) {
		config[1] = {
			...config[1],
			style: {
				...config[1]?.style,
				border: "1px dashed #5FCF64",
			},
		};
	}
	if (isDueDateMiddle) {
		reorderedDates[1] = { ...reorderedDates[1], img: redClock };
		config[1] = {
			...config[1],
			style: {
				...config[1]?.style,
				border: "1px dashed #FE6955",
			},
		};
	}
};

const getDateObjects = (start_date, today, end_date) => {
	let daysObj = {
		startDate: {
			id: "startDate",
			text: START_DATE,
			date: start_date,
			img: greenCircle,
			classNames: "mb-28px d-flex justify-content-start",
			width: "",
		},

		today: {
			id: "today",
			text: TODAY,
			date: today,
			img: blueCircle,
			classNames: "review__today",
			width: "",
		},

		dueDate: {
			id: "dueDate",
			text: DUE_DATE,
			date: end_date,
			img: clock,
			classNames: "review__enddate",
			width: "",
		},
	};
	return daysObj;
};

export const getAdjustableWidth = (
	diffInStartAndDueDate,
	diffInStartAndToday,
	diffInEndAndToday
) => {
	const widths = [
		"w-20",
		"w-40",
		"w-60",
		"w-80",
		"w-100",
		"w-120",
		"w-140",
		"w-160",
	];

	let startWidthPercent =
		Math.abs(diffInStartAndToday / diffInStartAndDueDate) * 100;
	let endWidthPercent =
		Math.abs(diffInEndAndToday / diffInStartAndDueDate) * 100;

	let sw = Math.min(
		Math.ceil((startWidthPercent / 100) * widths.length),
		widths.length - 1
	);
	let ew = Math.min(
		Math.ceil((endWidthPercent / 100) * widths.length),
		widths.length - 1
	);

	return { startWidth: widths[sw], todayWidth: widths[ew] };
};

export const getStyle = (activeOption, selectedOption, index, el) => {
	let tempObj = {};
	if (index === activeOption || selectedOption?.label === el?.label) {
		tempObj = {
			...reviewActionsMapper[index].style,
			...reviewActionsMapper[index].hoverStyle,
		};
	} else {
		tempObj = {
			...reviewActionsMapper[index].style,
		};
	}
	return tempObj;
};

export const requestBodyForChangingReviewAction = ({
	clearData,
	selectedMenuOption,
	changeActionPopupData,
	editCommentPopupData,
	data,
	component,
}) => {
	const requestBody = {
		review_status: "",
		comment: "",
		postApiCleanUp: () => clearData && clearData(),
	};
	switch (selectedMenuOption) {
		case reviewActionEditOptionsText.CHANGE_ACTION:
			return {
				...requestBody,
				review_status: changeActionPopupData?.option,
				comment: changeActionPopupData?.comment ?? "",
			};
		case reviewActionEditOptionsText.ADD_COMMENT:
		case reviewActionEditOptionsText.EDIT_COMMENT:
			return {
				...requestBody,
				review_status: data[component?.valueKey],
				comment: editCommentPopupData?.comment ?? "",
			};
		default:
			return INVALID_DATA;
	}
};
export const checkIfReviewersDataExistsForAllApps = (apps) => {
	if (!apps) return false;
	const res = apps
		?.map((app) => {
			return checkIsReviewersDataFilled(app?.levels);
		})
		.filter((obj) => obj);
	return res?.length === apps?.length;
};

export const checkIsReviewersDataFilled = (levels) => {
	if (levels?.length === 0) return false;

	const res = levels
		?.map((level) => {
			return (
				!isEmpty(level?.primary_reviewer?.user_name) &&
				!isEmpty(level?.fallback_reviewer?.user_name)
			);
		})
		.filter((obj) => obj);

	return res?.length === levels?.length;
};

export const checkIfWholeReviewersDataIsEmpty = (levels) => {
	if (levels?.length === 0) return false;

	const res = levels
		?.map((level) => {
			return (
				isEmpty(level?.primary_reviewer?.user_name) &&
				isEmpty(level?.fallback_reviewer?.user_name)
			);
		})
		.filter((obj) => obj);

	return res?.length === levels?.length;
};

export const checkIfIncompleteDataExists = (levels) => {
	if (levels?.length === 0) return false;
	const res = levels?.map((level) => {
		return (
			(level?.primary_reviewer?.user_name &&
				isEmpty(level?.fallback_reviewer?.user_name)) ||
			(level?.fallback_reviewer?.user_name &&
				isEmpty(level?.primary_reviewer?.user_name))
		);
	});
	const filteredData = res?.filter((obj) => obj);
	return filteredData?.length >= 0;
};

export const getEntityStageText = (entityType, entityData) => {
	if (isEmpty(entityTypes?.[entityType]) || isEmpty(entityData))
		return INVALID_DATA;

	switch (entityType) {
		case entityTypes.APPLICATION:
			const levels = [
				"Zeroth",
				"First",
				"Second",
				"Third",
				"Fourth",
				"Fifth",
			];

			if (entityData?.ready_for_remediation) {
				return "Ready For Remediation";
			}

			if (
				isNaN(entityData?.current_review_level) ||
				entityData?.current_review_level > MAX_NUMBER_OF_LEVELS
			)
				return INVALID_DATA;

			return `${levels[entityData?.current_review_level]} Level Review`;

		default:
			break;
	}
};

export const getText = (stage) => {
	switch (stage) {
		case certification_stage.REVIEW:
			return "records completed";
		case certification_stage.ACTIONS:
		case certification_stage.COMPLETED:
			return "remediation actions pending";
		default:
			break;
	}
};

export const getNumber = (reviewDetails, stage) => {
	switch (stage) {
		case certification_stage.REVIEW:
			return `${
				(reviewDetails?.totalRecords || 0) -
				(reviewDetails?.pendingRecords || 0)
			} of ${reviewDetails?.totalRecords || 0}`;
		case certification_stage.ACTIONS:
		case certification_stage.COMPLETED:
			return `${reviewDetails?.pendingRecords || 0}`;

		default:
			break;
	}
};

export const getDataForStackedBar = (detailsData) => {
	let data = [];
	if (!detailsData) return data;

	Object.keys(stackDataMapper)?.forEach((key) => {
		const dataSet = stackDataMapper[key];
		if (detailsData?.[dataSet?.key]) {
			data.push({
				value: detailsData?.[dataSet?.key],
				color: colorMapForReviewDetails?.[dataSet?.key],
				tooltip: getTooltipContent(
					`${detailsData?.[dataSet?.key]} ${
						actionTextMapper?.[dataSet?.textKey]
					}`
				),
			});
		}
	});
	return data;
};

export const getSelectedAppFromReviewData = (data, appId) => {
	const app = data?.applications?.find((app) => appId === app?.app_id);
	return app;
};

export const apiMapper = (isEmployee, entity) => {
	if (!entity) return INVALID_DATA;
	if (isEmployee) return empViewEntites?.[entity];
	return adminViewEntities?.[entity];
};

export const disableSignOffBtn = (appData) => {
	if (
		appData?.signOffJobInProgress === PENDING ||
		appData?.readyForRemediation
	)
		return true;
	if (appData?.isSignOffFeasible) return false;
	return true;
};

export const isNullOrUndefined = (value) => isNull(value) || isUndefined(value);

export const getCertTypeBreadCrumbText = (hash, isEmployee) => {
	switch (hash) {
		case HASH_ONGOING_DETAILS:
			return {
				text: certifications.ONGOING_CERTIFICATION,
				route: accessReviewUrls(isEmployee).ONGOING_CERTIFICATION_PAGE,
			};

		case HASH_UPCOMING_DETAILS:
			return {
				text: certifications.UPCOMING_CERTIFICATION,
				route: accessReviewUrls(isEmployee).UPCOMING_CERTIFICATION_PAGE,
			};

		case HASH_COMPLETED_DETAILS:
			return {
				text: certifications.COMPLETED_CERTIFICATION,
				route: accessReviewUrls(isEmployee)
					.COMPLETED_CERTIFICATION_PAGE,
			};

		default:
			break;
	}
};

export const formatDateToLocalTimeZone = (
	timeStamp,
	format = "HH:mm:ss",
	showTz
) => {
	const dateAndTimeLocal = moment(timeStamp).tz(moment.tz.guess());
	const timeZoneString = dateAndTimeLocal.zoneAbbr();
	const formattedDate = dateAndTimeLocal.format(format);
	if (showTz) {
		return `${formattedDate} ${timeZoneString}`;
	}
	return formattedDate;
};

export const getCertGroup = (hash) => {
	if (["#ongoing", HASH_ONGOING_DETAILS].includes(hash))
		return archiveCertGroups.ongoing;
	else if (["#upcoming", HASH_UPCOMING_DETAILS].includes(hash))
		return archiveCertGroups.upcoming;
	else if (["#completed", HASH_COMPLETED_DETAILS].includes(hash))
		return archiveCertGroups.completed;
	else return archiveCertGroups.ongoing;
};

export const getRedirectUrlAfterArchive = (hash) => {
	switch (hash) {
		case HASH_ONGOING_DETAILS:
			return accessReviewUrls().ONGOING_CERTIFICATION_PAGE;
		case HASH_UPCOMING_DETAILS:
			return accessReviewUrls().UPCOMING_CERTIFICATION_PAGE;
		case HASH_COMPLETED_DETAILS:
			return accessReviewUrls().COMPLETED_CERTIFICATION_PAGE;
		default:
			return accessReviewUrls().LANDING_PAGE;
	}
};

export const getMetadataForSegmentAction = (data) => {
	return {
		user_app_role: data?.user_app_role,
		user_designation: data?.user_designation,
		user_email: data?.user_email,
		user_id: data?.user_id,
		user_name: data?.user_name,
		user_status: data?.user_status,
		review_status: data?.review_status,
		reviewer_id: data?.reviewer_id,
		reviewer_name: data?.reviewer_name,
		reviewer_email: data?.reviewer_email,
		signoff_level: data?.signoff_level,
	};
};

export const checkEmptyValuesForReviewers = (
	primary_reviewer,
	fallback_reviewer
) => {
	if (primary_reviewer && fallback_reviewer) {
		return Boolean(
			(primary_reviewer?.key || primary_reviewer?._id) &&
				fallback_reviewer?._id
		);
	}
	return false;
};

export const getDisplayColumnsData = (
	applications,
	applicationReview,
	fixedColumns,
	columns
) => {
	if (applicationReview?.columns?.length > 0) {
		return applicationReview?.columns;
	} else {
		if (applications?.length > 0) {
			return applications[0]?.columns;
		} else {
			return fixedColumns?.length > 0 ? fixedColumns : columns;
		}
	}
};

export const certStageWiseDataDispatcher = (
	cert_stage,
	conculdeReviewJobPending = false
) => {
	if (conculdeReviewJobPending)
		return tasks?.[certification_stage.CONCLUDE_REVIEW_JOB_IN_PROGRESS];

	switch (cert_stage) {
		case certification_stage.REVIEW:
			return tasks?.[certification_stage.REVIEW];

		case certification_stage.ACTIONS:
			return tasks?.[certification_stage.ACTIONS];

		case certification_stage.USER_CREATION_IN_PROGRESS:
			return tasks?.[certification_stage.USER_CREATION_IN_PROGRESS];

		default:
			return tasks?.[certification_stage.REVIEW];
	}
};
