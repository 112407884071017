import { Typography } from "@zluri/ui-components";
import React from "react";

export default function Label({component}) {
    return (
		<Typography
			variant="body_1_regular"
			color="secondary_grey_4"
			className="v2table__cell-empty-link"
		>
			{component.value}
		</Typography>
	);
}