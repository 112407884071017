import classNames from "classnames";
import React from "react";
import "./divider.css";

interface DividerProps extends React.HTMLProps<HTMLHRElement> {}
export default function Divider({ type, className }: DividerProps) {
	return (
		<hr
			className={classNames(
				`divider--variant-${type}`,
				className,
				"v2-divider"
			)}
		/>
	);
}
