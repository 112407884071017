import React, { useEffect, useState } from "react";
import { Button, FormCheck, Modal, Spinner } from "react-bootstrap";
import close from "../assets/close.svg";
import completeiconimg from "../components/Applications/Overview/completeicon.svg";
import { useDispatch } from "react-redux";
import { closeModal } from "reducers/modal.reducer";
import {
	BODY_VARIANT,
	Divider,
	GREY_VARIANT,
	Typography,
} from "@zluri/ui-components";
import "components/Applications/Contracts/Contracts.css";
import deleteWarning from "../assets/sharableViews/deleteWarning.svg";
import styled from "styled-components";
import deleteIcon from "../assets/sharableViews/deleteView.svg";
import moment from "moment";

const format = "D MMM, YYYY HH:mm z";
const timeZoneString = Intl.DateTimeFormat().resolvedOptions().timeZone;

const DeleteText = styled.span`
	color: var(--black, #222);
	font-size: 18px;
	font-weight: 600;
	margin-bottom: 4px;
`;

const StyledModal = styled(Modal)`
	& .modal-dialog {
		max-width: 602px !important;
	}
`;

const StyledButton = styled(Button)`
	background-color: #ff6767;
	border-color: #ff6767;
	width: 124px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const CancelText = styled.div`
	color: var(--700, #2266e2);
	font-size: 13px;
	font-weight: 500;
	cursor: pointer;
	margin-right: 20px;
`;

const OwnerWrapper = styled.div`
	border-radius: 4px;
	background: var(--Primary_Blue_Light-3, #e8f0fc);
	height: 20px;
	display: flex;
	align-items: center;
	padding: 2px 10px;
`;

export const CustomViewModal = (props) => {
	const { customViewSaved, modalProps } = props;
	const [isLoading, setIsLoading] = useState(false);
	const [deleteLoading, setDeleteLoading] = useState(false);
	const handleClose = props.handleClose || modalProps?.handleClose;
	const [customViewName, setcustomViewName] = useState(
		modalProps?.viewData?.name || ""
	);

	const customView = modalProps?.viewData?.is_custom;
	const [customViewData, setCustomViewData] = useState({});
	useEffect(() => {
		const is_global = !modalProps?.viewData?.user_id;
		setCustomViewData({
			...(modalProps?.viewData || {}),
			is_global,
			is_org_default: modalProps?.viewData?.is_org_default,
			is_default: modalProps?.viewData?.is_default,
		});
	}, [modalProps?.viewData]);
	const [customViewDescription, setcustomViewDescription] = useState(
		modalProps?.viewData?.description || ""
	);

	const dispatch = useDispatch();

	const disableSave = () => {
		if (!customViewName?.trim() || !customViewDescription?.trim()) {
			return true;
		}
		if (
			!customView &&
			modalProps?.isEdit &&
			!(!!customViewData.is_org_default || !!customViewData.is_default)
		) {
			return true;
		}
		return false;
	};

	const renderChekboxItems = () => {
		if (customView || !modalProps.isEdit) {
			return (
				<>
					<div className="d-flex flex-column mt-3 ml-2">
						<div className="d-flex">
							<FormCheck
								checked={customViewData?.is_default}
								type="checkbox"
								onClick={() =>
									setCustomViewData((d) => ({
										...d,
										is_default: !d?.is_default,
									}))
								}
							/>
							<div>
								<Typography
									color={GREY_VARIANT.SECONDARY_GREY_1}
									variant={BODY_VARIANT.BODY_1_REGULAR}
								>
									Set as default view for myself
								</Typography>
							</div>
						</div>
					</div>
					<Divider
						className="mt-3"
						backgroundColor="#DDDDDD"
						height={1}
						width="100%"
					/>
					<div className="d-flex mt-3 ml-2">
						<FormCheck
							type="checkbox"
							checked={!!customViewData?.is_global}
							onChange={() =>
								setCustomViewData((d) => ({
									...d,
									is_global: !d?.is_global,
									is_org_default: false,
								}))
							}
						/>
						<div className="custom_view_options-text">
							<Typography
								color={GREY_VARIANT.SECONDARY_GREY_1}
								variant={BODY_VARIANT.BODY_1_REGULAR}
							>
								Share View with Organization
							</Typography>
						</div>
					</div>
					<div className="d-flex mt-3 ml-2">
						<FormCheck
							type="checkbox"
							disabled={!customViewData?.is_global}
							onClick={() => {
								setCustomViewData((d) => ({
									...d,
									is_org_default: !d?.is_org_default,
									isOrgLevel: true,
								}));
							}}
							checked={customViewData?.is_org_default}
						/>
						<div>
							<Typography
								color={
									!customViewData?.is_global
										? GREY_VARIANT.SECONDARY_GREY_3
										: GREY_VARIANT.SECONDARY_GREY_1
								}
								variant={BODY_VARIANT.BODY_1_REGULAR}
							>
								Set as Default for Organization
							</Typography>
						</div>
					</div>
				</>
			);
		}
		return (
			<>
				<div className="d-flex flex-column mt-3 ml-2">
					<div className="d-flex">
						<FormCheck
							checked={customViewData?.is_default}
							type="checkbox"
							onClick={() =>
								setCustomViewData((d) => ({
									...d,
									is_default: !d?.is_default,
								}))
							}
						/>
						<div>
							<Typography
								color={GREY_VARIANT.SECONDARY_GREY_1}
								variant={BODY_VARIANT.BODY_1_REGULAR}
							>
								Set as default view for myself
							</Typography>
						</div>
					</div>
				</div>
				<div className="d-flex mt-3 ml-2">
					<FormCheck
						type="checkbox"
						disabled={!customViewData?.is_global}
						onClick={() => {
							setCustomViewData((d) => ({
								...d,
								is_org_default: !d?.is_org_default,
								isOrgLevel: true,
							}));
						}}
						checked={customViewData?.is_org_default}
					/>
					<div>
						<Typography
							color={
								!customViewData?.is_global
									? GREY_VARIANT.SECONDARY_GREY_3
									: GREY_VARIANT.SECONDARY_GREY_1
							}
							variant={BODY_VARIANT.BODY_1_REGULAR}
						>
							Set as Default for Organization
						</Typography>
					</div>
				</div>
			</>
		);
	};

	if (modalProps.modalType === "delete") {
		return (
			<StyledModal
				show={props.show}
				onHide={() => handleClose()}
				centered
				size="md"
			>
				<div
					className="d-flex flex-row align-items-center"
					style={{ marginBottom: "6px" }}
				>
					<div className="m-auto">
						<img src={deleteWarning} alt="warning" />
					</div>
					<img
						className="pr-4 cursor-pointer align-self-start py-4"
						alt="Close"
						onClick={() => handleClose()}
						src={close}
					/>
				</div>
				<Modal.Body
					className="bg-white rounded-top px-4"
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}
				>
					<DeleteText>
						{modalProps?.viewData?.user_id === null
							? "You are about to delete a view that is shared with all users"
							: "You are about to delete the view"}
					</DeleteText>
					<Typography
						variant="subheading_1_regular"
						color={GREY_VARIANT.SECONDARY_GREY_1}
					>
						This action can not be reverted. Are you sure you want
						to continue?
					</Typography>
				</Modal.Body>
				<hr style={{ marginTop: "24px", marginBottom: "24px" }} />
				<Modal.Footer style={{ paddingTop: "0px" }}>
					<CancelText onClick={() => handleClose()}>
						Cancel
					</CancelText>
					<StyledButton
						className="z__button"
						variant="danger"
						color="#ff6767"
						disabled={!customViewName || !customViewDescription}
						onClick={async (e) => {
							setDeleteLoading(true);
							await modalProps?.handleDelete(
								e,
								modalProps?.viewData
							);
							setDeleteLoading(false);
							dispatch(closeModal("customViewModal"));
						}}
					>
						{!deleteLoading && (
							<div className="d-flex">
								<img alt="delete icon" src={deleteIcon} />
								<span className="ml-2">Delete</span>
							</div>
						)}
						{deleteLoading && (
							<div className="d-inline-flex align-items-center mr-2 ml-2">
								{!customViewSaved && (
									<Spinner
										className="mr-2 ml-2 blue-spinner action-edit-spinner"
										animation="border"
									/>
								)}
								{customViewSaved && (
									<img
										style={{
											position: "relative",
											top: "2px",
										}}
										src={completeiconimg}
										width={14}
										alt="complete"
									/>
								)}
							</div>
						)}
					</StyledButton>
				</Modal.Footer>
			</StyledModal>
		);
	}

	return (
		<>
			<Modal
				show={props.show}
				onHide={() => handleClose()}
				centered
				size="md"
			>
				<div className="d-flex flex-column align-items-center w-100">
					<div
						className="d-flex flex-row align-items-center w-100"
						style={{ paddingTop: "24px", paddingBottom: "6px" }}
					>
						<div className="m-auto">
							<span className="contracts__heading d-flex flex-row">
								{modalProps?.isEdit
									? modalProps?.viewData?.name
									: "Create new view"}
							</span>
						</div>
						<img
							className="pr-4 cursor-pointer"
							alt="Close"
							onClick={() => handleClose()}
							src={close}
						/>
					</div>
					{customView ? (
						<OwnerWrapper>
							<Typography variant="button_extrasmall_regular">
								Created by
							</Typography>
							&nbsp;
							<Typography variant="subheading_4_bold">
								{modalProps?.viewData?.owner_id.name}
							</Typography>
							&nbsp;
							<Typography variant="button_extrasmall_regular">
								on
							</Typography>
							&nbsp;
							<Typography variant="subheading_4_bold">
								{moment(modalProps?.viewData?.created_at)
									.tz(timeZoneString)
									.format(format)}
							</Typography>
						</OwnerWrapper>
					) : null}
				</div>
				<Modal.Body className="bg-white rounded-top">
					<div className="p-3">
						<input
							className="p-2"
							name="Name"
							type="text"
							placeholder="Name"
							value={customViewName}
							onChange={(e) => setcustomViewName(e.target.value)}
							style={{ width: "100%" }}
							disabled={!customView && !!modalProps.isEdit}
						/>
						<input
							className="p-2 mt-2"
							name="Description"
							type="text"
							placeholder="Description"
							value={customViewDescription}
							onChange={(e) =>
								setcustomViewDescription(e.target.value)
							}
							style={{ width: "100%" }}
							disabled={!customView && !!modalProps.isEdit}
						/>
					</div>

					{modalProps?.viewOptions?.editViewOptions ? (
						<div className="mt-2 px-2">
							{React.createElement(
								modalProps?.viewOptions?.editViewOptions,
								{
									data: customViewData,
									onClick: (d) => {
										setCustomViewData((oldData) => {
											return {
												...oldData,
												...d,
											};
										});
									},
								}
							)}
							<Divider
								className="mt-3"
								backgroundColor="#DDDDDD"
								height={1}
								width="100%"
							/>
						</div>
					) : null}

					<div className="px-2">{renderChekboxItems()}</div>
				</Modal.Body>
				<Modal.Footer>
					<div className="d-flex justify-content-end w-100">
						<div className="d-flex">
							<Button
								disabled={disableSave()}
								onClick={async () => {
									setIsLoading(true);
									if (modalProps?.handleSubmit) {
										await modalProps?.handleSubmit(
											customViewName.trim(),
											customViewDescription.trim(),
											customViewData
										);
									}
									setIsLoading(false);
									dispatch(closeModal("customViewModal"));
								}}
							>
								{isLoading ? (
									<div className="d-inline-flex align-items-center mr-2 ml-2">
										{!customViewSaved && (
											<Spinner
												className="mr-2 ml-2 blue-spinner action-edit-spinner"
												animation="border"
											/>
										)}
										{customViewSaved && (
											<img
												style={{
													position: "relative",
													top: "2px",
												}}
												src={completeiconimg}
												width={14}
												alt="complete"
											/>
										)}
									</div>
								) : (
									"Save"
								)}
							</Button>
						</div>
					</div>
				</Modal.Footer>
				<hr />
			</Modal>
		</>
	);
};
