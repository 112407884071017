import React from "react";

import moment from "moment";

import iconUp from "assets/applications/Icon_Delta.svg";
import iconDown from "assets/applications/Icon_Delta_down.svg";
import iconNoChange from "assets/applications/Icon_Delta_NO.svg";
import learn_more from "assets/learn_more_base.svg";
import selectImpact from "assets/applications/tick.svg";
import csvIcon from "assets/applications/Icon_CSVFile.svg";
import manualIcon from "assets/applications/Icon_Manual.svg";

import { FULL_MONTH } from "utils/DateUtility";
import { kFormatter as currencyFormatter } from "constants/currency";
import {
	capitalizeFirstLetter,
	getCurrentFinancialYearBasedOnMonth,
	kFormatter,
} from "utils/common";
import { modalCard } from "../constants/ApplicationConstants";
import { getLocalTimezoneString } from "utils/DateUtility";
import loadable from "@loadable/component";
const HighchartsComponent = loadable.lib(
	() => import(/* webpackPrefetch: true */ "highcharts")
);

export const ACTUAL_SPENDS = {
	ACTUAL_SPEND: "ACTUAL_SPEND",
	ACTUAL_SPEND_COLUMN: "ACTUAL_SPEND_COLUMN",
	actual__spend: "actual__spend",
	actual__spend__column: "actual__spend__column",

	EST_COST: "EST_COST",
	EST_COST_COLUMN: "EST_COST_COLUMN",
	est__band: "est__band",
	est__cost__column: "est__cost__column",

	MISSING_TRANSACTION_COLUMN: "MISSING_TRANSACTION_COLUMN",
	missing__transaction__column: "missing__transaction__column",
	spends__graph__tooltip: "spends__graph__tooltip",
};

export const AMMORTISED_SPENDS = {
	AMMORTIZED_SPEND: "AMMORTIZED_SPEND",
	AMMORTIZED_SPEND_COLUMN: "AMMORTIZED_SPEND_COLUMN",
	ammortized_spend: "ammortized_spend",
	ammortized__spend__column: "ammortized__spend__column",

	EST_COST: "EST_COST",
	EST_COST_COLUMN: "EST_COST_COLUMN",
	est__band: "est__band",
	est__cost__column: "est__cost__column",

	TRANSACTION: "TRANSACTION",
	TRANSACTION_AVAILABLE: "TRANSACTION_AVAILABLE",
	TRANSACTION_MISSING: "TRANSACTION_MISSING",

	spends__graph__tooltip: "spends__graph__tooltip",
};

export const handleSummaryData = (amt) => {
	const ValueHandler = {
		POS: {
			amt: kFormatter(amt),
			comparedChange: kFormatter(amt),
			style: {
				color: "#5fcf64",
				fontWeight: 600,
				fontSize: "10px",
			},
			icon: iconUp,
			amtExtra: {
				amtSpend: "under",
				amtSymb: "+",
				amtBudget: "left",
			},
			amtStyle: {
				backgroundColor: "#E7F8E8",
				color: "#009307",
			},
		},
		NEG: {
			amt: kFormatter(-amt),
			comparedChange: kFormatter(-amt),
			style: {
				color: "#FE6955",
				fontWeight: 600,
				fontSize: "10px",
			},
			icon: iconDown,
			amtExtra: {
				amtSpend: "over",
				amtSymb: "-",
				amtBudget: "exceeded",
			},
			amtStyle: {
				color: "#FE6955",
				backgroundColor: "#FFE9E5",
			},
		},
		NEU: {
			amt: 0,
			comparedChange: "No Change",
			icon: iconNoChange,
			style: {
				color: "#a8a8a8",
				fontWeight: 400,
				fontSize: "10px",
			},
		},
	};
	switch (true) {
		case amt > 0:
			return ValueHandler.POS;
		case amt < 0:
			return ValueHandler.NEG;
		case amt === 0:
			return ValueHandler.NEU;
		default:
			break;
	}
};

export const handleComparedData = (comparedAmount, comparedSymbol) => {
	return (
		<>
			<img src={handleSummaryData(comparedAmount)?.icon} alt="" />
			<span>
				{comparedSymbol === "$" && comparedAmount !== 0 && "$"}{" "}
				{handleSummaryData(comparedAmount)?.comparedChange}
				{comparedSymbol === "%" && comparedAmount !== 0 && "%"}
			</span>
		</>
	);
};

export const handleFinancialYear = (year, month) => {
	if (!year || !month) return getCurrentFinancialYearBasedOnMonth();
	if (month >= 4)
		return `${year?.toString().substr(-2)}- ${(year + 1)
			?.toString()
			.substr(-2)}`;
	else
		return `${(year - 1)?.toString().substr(-2)}- ${year
			?.toString()
			.substr(-2)}`;
};

export const handleDropdownOption = (
	option,
	dropdownToggle,
	optionSelectedStyle,
	optionStyle
) => {
	return (
		<div
			className={`d-flex justify-content-between w-100 font-11  ${
				option?.field_name === dropdownToggle
					? optionSelectedStyle
					: optionStyle
			}`}
		>
			<span className="pl-1">{option?.field_name}</span>
			{option?.field_id === dropdownToggle ? (
				<img src={selectImpact} alt="" />
			) : (
				<span className="setting-dropdown-hover primary-color">+</span>
			)}
		</div>
	);
};

// export const fyMonths = (month) => {
export const handleComparingData = (
	CondtionParams,
	trueResultParams,
	falseResultParams
) => {
	return CondtionParams ? trueResultParams : falseResultParams || null;
};

export const handleSyncedData = (syncedData) => {
	const Pill = {
		sync: {
			pillValue: `Last Synced on ${moment(syncedData)
				.tz(getLocalTimezoneString())
				.format("DD MMM 'YY, HH:mm z")}`,
			pillStyle: "financial-green-pill",
		},
		disconnect: {
			pillValue: "Disconnected",
			pillStyle: "financial-red-pill",
		},
	};
	return syncedData ? Pill.sync : Pill.disconnect;
};

export const handleManualData = (
	conditionData,
	sourceData,
	currency,
	secondSourceData
) => {
	const MANUALSOURCES = {
		csv: {
			icon: csvIcon,
			iconStyle: "financial-green-pill",
			uploadDetails: `CSV uploaded by ${capitalizeFirstLetter(
				sourceData
			)} `,
			fileName: capitalizeFirstLetter(sourceData),
			lastTransaction: `Uploaded on ${moment(sourceData).format(
				"DD MMM 'YY, hh:mm A"
			)} `,
			totalTransaction: currencyFormatter(sourceData),
			numberOfTransaction: "No of Transaction",
		},
		manual: {
			icon: manualIcon,
			iconStyle: "purple-icon-bg",
			uploadDetails: "Manually added transaction",
			fileName: capitalizeFirstLetter(secondSourceData),
			lastTransaction: `Transaction added on ${moment(sourceData).format(
				"DD MMM 'YY, hh:mm A"
			)}`,
			totalTransaction: ` ${currencyFormatter(
				sourceData,
				currency,
				true,
				true
			)}`,
			numberOfTransaction: "Amount",
		},
	};
	return conditionData === modalCard.CSV
		? MANUALSOURCES.csv
		: MANUALSOURCES.manual;
};

const fyMonths = (month) => {
	const allMonths = FULL_MONTH;
	const xAxis = allMonths.slice(month - 1);
	const diff = allMonths?.length - xAxis?.length;
	const arr = allMonths.slice(0, diff);
	const values = xAxis.concat(arr);
	return values;
};

export const setAccruedSpendsGraph = (data, fy) => {
	const { current_fy_spend, previous_fy_spend } = data;
	const { start_month, start_year, end_year } = {
		start_month: fy?.start_month + 1,
		end_month: fy?.end_month + 1,
		start_year: fy?.start_year - 1,
		end_year: fy?.end_year,
	};
	const xAxis = fyMonths(start_month);

	const options = {
		chart: {
			type: "area",
			backgroundColor: "#f6f7fa",
			height: 300,
			spacingTop: 60,
			spacingBottom: 10,
		},
		plotOptions: {
			area: {
				states: {
					hover: {
						animation: {
							duration: 400,
						},
					},
				},
			},
			series: {
				stickyTracking: true, // to show the difference in tracking
				connectNulls: true,
				stckyTracking: true,
				marker: {
					enabled: false,
					states: {
						hover: {
							enabled: false,
						},
					},
				},
				states: {
					hover: {
						enabled: true,
						lineWidth: 0.7,
						animation: {
							duration: 400,
						},
					},
				},
			},
		},
		tooltip: {
			useHTML: true,
			className: "spends__graph__tooltip",
			borderColor: "none",
			borderWidth: 0 + "px",
			borderRadius: 8,
			backgroundColor: "black",
			padding: 6,
			outside: true,
			hideDelay: 0,
			formatter: function () {
				if (this.series.name === "PREVIOUS_YEAR") {
					return accruedTooltip(
						"Previous Year Spends",
						this.x,
						this.y
					);
				}
				if (this.series.name === "CURRENT_YEAR") {
					return accruedTooltip(
						`${
							this.point?.zone?.className ===
							"accrued__spends__area"
								? "Accrued Spends"
								: this.point?.zone?.className ===
									  "est__spends__area"
									? "Estimated Spends"
									: "Accrued Spends"
						}`,
						this.x,
						this.y
					);
				}
			},
		},
		title: {
			text: "",
		},
		credits: {
			enabled: false,
		},
		legend: {
			align: "right",
			symbolPadding: 0,
			symbolWidth: 0,
			symbolHeight: 0,
			symbolRadius: 0,
			squareSymbol: false,
			useHTML: true,
			labelFormatter: function () {
				const startEndFYIsSame = start_year + 1 === end_year;
				if (this?.name === "CURRENT_YEAR") {
					const labelTxt = `FY ${
						startEndFYIsSame
							? end_year?.toString()?.slice(2)
							: `${(start_year + 1)
									?.toString()
									.slice(2)}-${end_year
									?.toString()
									?.slice(2)}`
					}`;
					return legendSymbol("#FFA217", labelTxt);
				}
				if (this?.name === "PREVIOUS_YEAR") {
					const labelTxt = `FY ${
						startEndFYIsSame
							? start_year?.toString().slice(2)
							: `${start_year?.toString().slice(2)}-${(
									start_year + 1
								)
									?.toString()
									.slice(2)}`
					}`;
					return legendSymbol("#5ABAFF", labelTxt);
				}
			},
		},
		series: [
			{
				name: "CURRENT_YEAR",
				lineWidth: 0.6,
				data: current_fy_spend?.map((spend) =>
					Number(spend?.accrued_spend)
				),
				zoneAxis: "x",
				marker: { enabled: false },
				zones: [
					{
						value:
							(moment().year() >= fy?.start_year - 1 ||
								moment().year <= fy?.end_year) &&
							xAxis.indexOf(FULL_MONTH[moment().month()]),
						color: "#ffa217",
						fillColor: "#fff6dc",
						name: "ACCRUED_SPENDS_AREA",
						className: "accrued__spends__area",
					},
					{
						color: "#ffa217",
						fillColor: "transparent",
						dashStyle: "LongDash",
						name: "EST_SPENDS_AREA",
						className: "est__spends__area",
					},
				],
			},
			{
				lineWidth: 0.6,
				color: "#1176be",
				fillColor: "transparent",
				className: "prev__spends__area",
				data: previous_fy_spend?.map((spend) =>
					Number(spend?.accrued_spend)
				),
				zones: [
					{
						className: "prev__spends__area",
					},
				],
				name: "PREVIOUS_YEAR",
				marker: { enabled: false },
			},
		],
		xAxis: {
			className: "series__2",
			categories: previous_fy_spend?.map((fy) =>
				moment({ month: fy?.month - 1, year: fy?.year }).format("MMM")
			),
			labels: {
				useHTML: true,
				formatter: function () {
					return `<div class="font-8 bold-500 grey-1">${this.value}</div>`;
				},
				rotation: 0,
				autoRotation: 0,
				autoRotationLimit: 0,
				style: {
					whiteSpace: "nowrap",
				},
				overflow: "justify",
			},
			lineWidth: 1,
			left: 22,
			right: 10,
			lineColor: "#484848",
			tickWidth: 0,
			plotLines: [
				{
					color: "#2266e2", // Color value
					value:
						(moment().year() >= fy?.start_year - 1 ||
							moment().year <= fy?.end_year) &&
						xAxis.indexOf(FULL_MONTH[moment().month()]),
					width: 0.8, // Width of the line
					zIndex: 8,
					label: {
						useHTML: true,
						formatter: function () {
							return `
								<div class="d-flex flex-column align-items-center">
									<span class="primary-color bold-500 font-10" style="">Today</span>
									<span class="rounded-circle primary-color-bg" style="width:6.5px; height: 6.5px"></span>
								</div>
							`;
						},
						rotation: 360, // Content of the label.
						align: "right", // Positioning of the label. Default to center.
						x: 15,
						y: -6, // Amount of pixels the label will be repositioned according to the alignment.
					},
				},
			],
			plotBands: [
				{
					color: {
						pattern: {
							path: "M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11",
							// stroke: "grey",
							color: "#ebebeb",
							width: 10,
							height: 10,
						},
					},
					from:
						current_fy_spend?.length &&
						(moment().year() >= fy?.start_year - 1 ||
							moment().year <= fy?.end_year) &&
						xAxis.indexOf(FULL_MONTH[moment().month()]),
					to:
						current_fy_spend?.length >
						xAxis.indexOf(FULL_MONTH[moment().month()])
							? xAxis?.length
							: null,
					className: "est__band",
					label: {
						text: "Forecasted",
						align: "left",
						style: {
							color: "#717171",
							fontSize: "8px",
							fontWeight: "600",
						},
						x: +10, // Amount of pixels the label will be repositioned according to the alignment.
					},
				},
			],
		},
		yAxis: {
			title: {
				text: "",
			},
			tickAmount: 4,
			showEmpty: false,
			gridLineDashStyle: "longdash",
			labels: {
				formatter: function () {
					return data?.spends_budget !== this.value
						? spendsCurrencyFormat(this.value)
						: "";
				},
				align: "left",
				x: 0,
				y: -3,
			},
			plotLines: [
				{
					color: "#fe6955", // Color value
					useHTML: true,
					dashStyle: "ShortDash", // Style of the plot line. Default to solid
					value: data?.spends_budget && data.spends_budget / 12, // Value of where the line will appear
					width: 0.7, // Width of the line
					label: {
						formatter: function () {
							return (
								data?.spends_budget &&
								budget(data.spends_budget / 12)
							);
						},
						useHTML: true,
						style: {
							color: "#fe6955",
						},
						x: 0,
						y: -8,
						zIndex: 100,
					},
					zIndex: 100,
				},
			],
		},
		scrollbar: {
			enabled: true,
		},
		exporting: {
			enabled: false,
			button: {
				enabled: false,
			},
		},
	};
	return options;
};

export const setActualSpendsVsEstCostGraph = (
	data,
	fy,
	Highcharts,
	maxYAxisValue
) => {
	if (Highcharts)
		Highcharts.SVGRenderer.prototype.symbols["c-rect"] = function (
			x,
			y,
			w,
			h
		) {
			return [
				"M",
				x,
				Number(y + h / 2),
				"L",
				Number(x + w),
				Number(y + h / 2),
			];
		};
	const todayLineIndex = data?.spend_graphs_data?.findIndex(
		(val) =>
			val?.year_id === moment().year() &&
			val?.month_id === moment().month() + 1
	);
	const options = {
		chart: {
			type: "column",
			backgroundColor: "#f6f7fa",
			height: 300,
			spacingTop: 60,
			spacingBottom: 10,
			spacingRight: 30,
		},
		plotOptions: {
			connectNulls: true,
			scatter: {
				states: {
					hover: {
						enabled: false,
					},
				},
				marker: {
					states: {
						hover: {
							enabled: false,
						},
					},
				},
			},
		},
		tooltip: {
			useHTML: true,
			className: ACTUAL_SPENDS.spends__graph__tooltip,
			borderColor: "none",
			borderWidth: 0,
			borderRadius: 8,
			backgroundColor: "black",
			zIndex: 1000,
			padding: 6,
			outside: true,
			hideDelay: 0,
			formatter: function () {
				const {
					estimate_cost_per_month,
					actual_spend_per_month,
					date,
					transaction,
				} = this.point?.category;
				if (this.series?.name === ACTUAL_SPENDS.EST_COST) {
					return columnTooltip(
						estimate_cost_per_month,
						actual_spend_per_month,
						date
					);
				}
				if (
					this.point?.name ===
					ACTUAL_SPENDS.MISSING_TRANSACTION_COLUMN
				) {
					const { year_id, month_id } = transaction;
					return missingTransactionTooltip(month_id, year_id);
				}
				if (this.series?.name === ACTUAL_SPENDS.ACTUAL_SPEND) {
					return columnTooltip(
						estimate_cost_per_month,
						actual_spend_per_month,
						date
					);
				}
			},
		},
		title: {
			text: "",
		},
		credits: {
			enabled: false,
		},
		legend: {
			align: "right",
			symbolPadding: 0,
			symbolWidth: 0,
			symbolHeight: 0,
			symbolRadius: 0,
			squareSymbol: false,
			useHTML: true,
			labelFormatter: function () {
				if (this?.name === ACTUAL_SPENDS.ACTUAL_SPEND) {
					return legendSymbol("#2266e2", `Actual Spend`);
				}
				if (this?.name === ACTUAL_SPENDS.EST_COST) {
					return legendSymbol("#9E17FF", `Est. Cost`);
				}
			},
		},
		series: [
			{
				borderRadius: 3,
				name: ACTUAL_SPENDS.ACTUAL_SPEND,
				className: ACTUAL_SPENDS.actual__spend,
				data: data?.spend_graphs_data?.map((s) => {
					const {
						actual_spend_per_month,
						year_id,
						month_id,
						transaction,
					} = s;
					const isEstimatedColumn =
						moment({
							year: year_id,
							month: month_id === 12 ? 11 : month_id - 1,
						}) > moment();
					const isPreviousColumn =
						moment({
							year: year_id,
							month: month_id === 12 ? 11 : month_id,
						}) <= moment();
					return {
						y: Number(actual_spend_per_month),
						custom: s,
						colorIndex: -1,
						name: isEstimatedColumn
							? ACTUAL_SPENDS.EST_COST_COLUMN
							: ACTUAL_SPENDS.ACTUAL_SPEND_COLUMN,
						className: isEstimatedColumn
							? ACTUAL_SPENDS.est__cost__column
							: isPreviousColumn
								? ACTUAL_SPENDS.actual__spend__column
								: "",
						color:
							!isEstimatedColumn &&
							transaction &&
							!transaction?.transaction_amount
								? {
										pattern: {
											path: {
												d: "M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11",
											},
											color: "#ffffff",
											backgroundColor: "#DDDDDD",
											width: 10,
											height: 10,
										},
									}
								: isEstimatedColumn && {
										pattern: {
											path: {
												d: "M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11",
											},
											color: "#ffffff",
											backgroundColor: "#A0D8FF",
											width: 10,
											height: 10,
										},
										color: "orange",
									},
						states: {
							hover: {
								animation: {
									duration: 300,
								},
								color: {
									pattern: {
										path: {
											d: "M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11",
										},
										color: "#ffffff",
										backgroundColor:
											transaction &&
											!transaction?.transaction_amount &&
											!isEstimatedColumn
												? "#FE6955"
												: "#5ABAFF",
										width: 10,
										height: 10,
									},
								},
							},
						},
					};
				}),
				zIndex: 0,
				marker: { enabled: false },
			},
			{
				name: ACTUAL_SPENDS.EST_COST,
				stickyTracking: false,
				marker: {
					symbol: "c-rect",
					lineColor: "#9E17FF",
					lineWidth: 3,
					radius: 13,
				},
				type: "scatter",
				data: data?.spend_graphs_data?.map((s) => {
					const { estimate_cost_per_month } = s;
					return {
						y: Number(estimate_cost_per_month),
						name: ACTUAL_SPENDS.EST_COST,
					};
				}),
			},
		],
		xAxis: {
			className: "series__2",
			left: 38,
			maxPointWidth: 50,
			categories: data?.spend_graphs_data?.map((data) => {
				const {
					year_id,
					month_id,
					estimate_cost_per_month,
					actual_spend_per_month,
					transaction,
				} = data;
				return {
					date: moment({ year: year_id, month: month_id - 1 }),
					estimate_cost_per_month: estimate_cost_per_month,
					actual_spend_per_month: actual_spend_per_month,
					transaction: transaction,
				};
			}),
			labels: {
				useHTML: true,
				autoRotation: 0,
				autoRotationLimit: 0,
				formatter: function () {
					const date = moment(this?.value?.date).format("MMM ‘YY");
					return `<div class="font-8 bold-500 grey-1">${date}</div>`;
				},
				style: {
					whiteSpace: "nowrap",
				},
				padding: 0,
			},
			lineWidth: 1,
			lineColor: "#484848",
			tickWidth: 0,
			plotLines: [
				{
					color: "#2266e2", // Color value
					value: todayLineIndex,
					width: 0.8, // Width of the line
					zIndex: 8,
					label: {
						useHTML: true,
						formatter: function () {
							return todayLabel();
						},
						rotation: 360, // Content of the label.
						align: "right", // Positioning of the label. Default to center.
						x: 15,
						y: -6, // Amount of pixels the label will be repositioned according to the alignment.
					},
				},
			],
			plotBands: [
				{
					color: {
						pattern: {
							path: "M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11",
							color: "#ebebeb",
							width: 10,
							height: 10,
						},
					},
					from: todayLineIndex,
					to:
						todayLineIndex !== -1
							? data?.spend_graphs_data?.length
							: null,
					className: ACTUAL_SPENDS.est__band,
					label: {
						text: "Forecasted",
						align: "left",
						style: {
							color: "#717171",
							fontSize: "8px",
							fontWeight: "600",
						},
						x: +10, // Amount of pixels the label will be repositioned according to the alignment.
					},
				},
			],
		},
		yAxis: {
			title: {
				text: "",
			},
			max: maxYAxisValue,
			tickAmount: 4,
			showEmpty: false,
			labels: {
				formatter: function () {
					const value =
						data?.spends_budget !== this.value
							? spendsCurrencyFormat(this.value)
							: "";
					return `<div class="font-8 bold-500 grey-1">${value}</div>`;
				},
				align: "left",
				color: "#717171",
				fontSize: "8px",
				fontWeight: "600",
				x: 0,
				y: -3,
				zIndex: 5,
			},
			plotLines: [
				{
					color: "#fe6955", // Color value
					useHTML: true,
					dashStyle: "ShortDash", // Style of the plot line. Default to solid
					value: data?.spends_budget && data.spends_budget / 12, // Value of where the line will appear
					width: 0.7, // Width of the line
					label: {
						formatter: function () {
							return (
								data?.spends_budget &&
								budget(data.spends_budget / 12)
							);
						},
						useHTML: true,
						style: {
							color: "#fe6955",
						},
						x: 0,
						y: -8,
						zIndex: 100,
					},
					zIndex: 100,
				},
			],
			zIndex: 5,
		},
		scrollbar: {
			enabled: true,
		},
		exporting: {
			enabled: false,
			button: {
				enabled: false,
			},
		},
	};

	return options;
};

export const setAmmortizedSpendsVsEstCostGraph = (data, fy, Highcharts) => {
	if (Highcharts)
		Highcharts.SVGRenderer.prototype.symbols["c-rect"] = function (
			x,
			y,
			w,
			h
		) {
			return [
				"M",
				x,
				Number(y + h / 2),
				"L",
				Number(x + w),
				Number(y + h / 2),
			];
		};

	const todayLineIndex = data?.spend_graphs_data?.findIndex(
		(val) =>
			val?.year_id === moment().year() &&
			val?.month_id === moment().month() + 1
	);
	const options = {
		chart: {
			type: "column",
			backgroundColor: "#f6f7fa",
			height: 300,
			spacingTop: 60,
			spacingBottom: 10,
			zIndex: 1000,
		},
		plotOptions: {
			scatter: {
				states: {
					hover: {
						enabled: false,
					},
				},
				marker: {
					states: {
						hover: {
							enabled: false,
						},
					},
				},
			},
		},
		tooltip: {
			useHTML: true,
			className: AMMORTISED_SPENDS.spends__graph__tooltip,
			borderColor: "none",
			borderWidth: 0,
			borderRadius: 8,
			backgroundColor: "black",
			outside: true,
			zIndex: 100,
			padding: 6,
			hideDelay: 0,
			formatter: function () {
				const {
					estimate_cost_per_month,
					ammortized_spend_per_month,
					date,
					transaction,
				} = this.point?.category;
				if (this.series?.name === AMMORTISED_SPENDS.EST_COST) {
					return columnTooltip(
						estimate_cost_per_month,
						ammortized_spend_per_month,
						date,
						"Ammortized Spend"
					);
				}
				if (this.series?.name === AMMORTISED_SPENDS.TRANSACTION) {
					const { year_id, month_id, transaction_amount } =
						transaction;
					const date = moment({
						year: year_id,
						month: month_id - 1,
					}).format("MM/YYYY");
					return transactionTooltip(transaction_amount, date);
				}
				if (this.series?.name === AMMORTISED_SPENDS.AMMORTIZED_SPEND) {
					return columnTooltip(
						estimate_cost_per_month,
						ammortized_spend_per_month,
						date,
						"Ammortized Spend"
					);
				}
			},
		},
		title: {
			text: "",
		},
		credits: {
			enabled: false,
		},
		legend: {
			align: "right",
			symbolPadding: 0,
			symbolWidth: 0,
			symbolHeight: 0,
			symbolRadius: 0,
			squareSymbol: false,
			useHTML: true,
			labelFormatter: function () {
				if (this?.name === AMMORTISED_SPENDS.EST_COST) {
					return legendSymbol("#9E17FF", `Est. Cost`);
				}
				if (this?.name === AMMORTISED_SPENDS.AMMORTIZED_SPEND) {
					return legendSymbol("#2266e2", `Amortized Spend`);
				}
			},
		},
		series: [
			{
				borderRadius: 3,
				name: AMMORTISED_SPENDS.AMMORTIZED_SPEND,
				className: AMMORTISED_SPENDS.ammortized_spend,
				data: data?.spend_graphs_data?.map((s) => {
					const { year_id, month_id, actual_spend_per_month } = s;
					const isEstimatedColumn =
						moment({
							year: year_id,
							month: month_id === 12 ? 11 : month_id - 1,
						}) > moment();
					return {
						y: Number(actual_spend_per_month),
						custom: s,
						colorIndex: -1,
						name: isEstimatedColumn
							? AMMORTISED_SPENDS.EST_COST_COLUMN
							: AMMORTISED_SPENDS.AMMORTIZED_SPEND_COLUMN,
						className: isEstimatedColumn
							? AMMORTISED_SPENDS.est__cost__column
							: AMMORTISED_SPENDS.ammortized__spend__column,
						color: isEstimatedColumn && {
							pattern: {
								path: {
									d: "M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11",
								},
								color: "#ebebeb",
								backgroundColor: "#A0D8FF",
								width: 10,
								height: 10,
							},
							color: "orange",
						},
						states: {
							hover: {
								color: {
									pattern: {
										path: {
											d: "M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11",
										},
										color: "#ebebeb",
										backgroundColor: "#5ABAFF",
										width: 10,
										height: 10,
									},
								},
							},
						},
					};
				}),
				marker: { enabled: false },
			},
			{
				name: AMMORTISED_SPENDS.EST_COST,
				stickyTracking: false,
				marker: {
					symbol: "c-rect",
					lineColor: "#9E17FF",
					lineWidth: 3,
					radius: 13,
				},
				type: "scatter",
				data: data?.spend_graphs_data?.map((s) => {
					return {
						y: Number(s?.estimate_cost_per_month),
						name: AMMORTISED_SPENDS.EST_COST,
					};
				}),
			},
		],
		xAxis: {
			className: "series__2",
			left: 35,
			categories: data?.spend_graphs_data?.map((d) => {
				const {
					year_id,
					month_id,
					estimate_cost_per_month,
					actual_spend_per_month,
					transaction,
				} = d;
				return {
					date: moment({ year: year_id, month: month_id - 1 }),
					estimate_cost_per_month: estimate_cost_per_month,
					ammortized_spend_per_month: actual_spend_per_month,
					transaction: transaction,
				};
			}),
			labels: {
				formatter: function () {
					const date = moment(this.value.date).format("MMM ‘YY");
					return `<div class="font-8 bold-500 grey-1">${date}</div>`;
				},
				rotation: 0,
				autoRotation: 0,
				autoRotationLimit: 0,
				style: {
					whiteSpace: "nowrap",
				},
				overflow: "justify",
			},
			lineWidth: 1,
			lineColor: "#484848",
			tickWidth: 0,
			plotLines: [
				{
					color: "#2266e2", // Color value
					value: todayLineIndex,
					width: 0.8, // Width of the line
					zIndex: 8,
					label: {
						useHTML: true,
						formatter: function () {
							return todayLabel();
						},
						rotation: 360, // Content of the label.
						align: "right", // Positioning of the label. Default to center.
						x: 15,
						y: -6, // Amount of pixels the label will be repositioned according to the alignment.
					},
				},
			],
			plotBands: [
				{
					color: {
						pattern: {
							path: "M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11",
							color: "#ebebeb",
							width: 10,
							height: 10,
						},
					},
					from: todayLineIndex,
					to:
						todayLineIndex !== -1
							? data?.spend_graphs_data?.length
							: null,
					className: AMMORTISED_SPENDS.est__band,
					label: {
						text: "Forecasted",
						align: "left",
						style: {
							color: "#717171",
							fontSize: "8px",
							fontWeight: "600",
						},
						x: +10, // Amount of pixels the label will be repositioned according to the alignment.
					},
				},
			],
		},
		yAxis: {
			title: {
				text: "",
			},
			tickAmount: 4,
			showEmpty: false,
			gridLineDashStyle: "solid",
			labels: {
				formatter: function () {
					if (data?.spends_budget !== this.value)
						return spendsCurrencyFormat(this.value);
				},
				align: "left",
				x: 0,
				y: -3,
			},
			plotLines: [
				{
					color: "#fe6955", // Color value
					useHTML: true,
					dashStyle: "ShortDash", // Style of the plot line. Default to solid
					value: data?.spends_budget && data.spends_budget / 12, // Value of where the line will appear
					width: 0.7, // Width of the line
					label: {
						formatter: function () {
							return (
								data?.spends_budget &&
								budget(data.spends_budget / 12)
							);
						},
						useHTML: true,
						style: {
							color: "#fe6955",
						},
						x: 0,
						y: -8,
						zIndex: 100,
					},
					zIndex: 100,
				},
			],
			zIndex: 100,
		},
		exporting: {
			enabled: false,
			button: {
				enabled: false,
			},
		},
	};

	return options;
};

export const columnTooltip = (estimatedCost, actualSpend, date, label) => {
	const isGreaterThanCurrent = moment(date) > moment();
	const estimatedText = isGreaterThanCurrent
		? "Estimated Spend"
		: label || "Actual Spend";
	const dateText = isGreaterThanCurrent
		? "For " + moment(date).format("MMMM")
		: moment(date).format("MMM ‘YYYY");
	return `
	<div class='d-flex flex-column actual__spends__tooltip flex-1'>
		<div class='d-flex align-items-center justify-content-between mb-1'>
			<span class='font-8 bold-500 spends__tooltip__key'>${estimatedText}</span>
			<span class='font-8 bold-600 spends__tooltip__value ml-2'>${currencyFormatter(
				actualSpend,
				null
			)}</span>
		</div>
		<div class='d-flex align-items-center justify-content-between'>
			<span class='font-8 bold-500 spends__tooltip__key'>Estimated Cost</span>
			<span class='font-8 bold-600 spends__tooltip__value ml-2'>${currencyFormatter(
				estimatedCost,
				null
			)}</span>
		</div>
		<div class=" mt-1 font-8 d-flex w-100 spends__tooltip__date justify-content-center align-items-center bold-600 spends__tooltip__value">
			<span class="py-1">${dateText}</span>
		</div>
	</div>
`;
};

export const transactionTooltip = (transaction_amt, date) => {
	return `<div class="d-flex flex-column actual__spends__tooltip">
			<div class="d-flex align-items-center justify-content-between font-8 bold-500 spends__tooltip__key" >
				<div class=mr-2">Transaction on &nbsp</div>
				<div>${date}</div>
			</div>
			<div class="d-flex align-items-center justify-content-between font-8 bold-600 spends__tooltip__value mt-1">
				<span>${
					transaction_amt
						? currencyFormatter(transaction_amt, null)
						: "Missing Details"
				}</span>
				<img src=${learn_more} alt="" width="6" height="6" style='filter: invert(99%) sepia(7%) saturate(409%) hue-rotate(170deg) brightness(118%) contrast(100%);' />
			</div>
		</div>`;
};

export const accruedTooltip = (label, date, value) => {
	return `
		<div class='spends__tooltip__container d-flex w-100 flex-1 flex-column' style='width: '80px', height: '35px''>
		<div class="font-8 bold-500 w-100 flex-1 pb-1 spends__tooltip__label" style='color: "#dddddd !important"; border-bottom: "1px solid #ffffff"'>${label}</div>
		<div class='d-flex justify-content-between w-100 pt-1 flex-1 align-items-center' style='color: "#f6f7fa"'>
			<span class="font-8 bold-500 spends__tooltip__key">${date}</span>
			<span class="font-8 bold-600 spends__tooltip__value">${currencyFormatter(
				value,
				null
			)}</span>
		</div>
		</div>
	`;
};

export const budget = (budget, text) => {
	const value = currencyFormatter(budget, null) + "";
	return `<div style="background: #f6f7fa;" class="font-8 d-flex flex-column position-relative align-items-start">
				<div style="background: #f6f7fa;">${text || "Budget"}</div>
				<div style="background: #f6f7fa;">${value}</div>
			</div>`;
};

export const spendsCurrencyFormat = (
	value,
	gap,
	decimalsAfterDigit,
	commaSeparated,
	displayCurrency
) => {
	return currencyFormatter(
		Number(value),
		null,
		displayCurrency,
		decimalsAfterDigit,
		gap,
		commaSeparated
	);
};

const todayLabel = (text) => {
	return `
	<div class="d-flex flex-column align-items-center today__label">
		<span class="primary-color bold-500 font-10" style="">${text || "Today"}</span>
		<span class="rounded-circle primary-color-bg" style="width:6.5px; height: 6.5px"></span>
	</div>
`;
};

export const missingTransactionTooltip = (month_id, year_id) => {
	const transactionDate = moment({
		year: year_id,
		month: month_id === 12 ? 11 : month_id - 1,
	}).format("MM/YYYY");
	return `
	<div class='d-flex flex-column flex-1 actual__spends__tooltip' style='width: 90px;'>
		<div class="d-flex align-items-center justify-content-center font-8 bold-500 spends__tooltip__value pb-1" >${transactionDate}</div>
		<div class="d-flex align-items-center pt-1 justify-content-between font-8 bold-500 spends__tooltip__date">
			<div onClick="setSelectedModal('ADD_SINGLE_TRANSACTION')" class="flex-1 w-100">Add Transaction</div>
			<img src=${learn_more} alt="" width="6" height="6" style='filter: invert(99%) sepia(7%) saturate(409%) hue-rotate(170deg) brightness(118%) contrast(100%);' class="ml-1" />
		</div>
	</div>
`;
};

export const handleSummaryAmt = (amt, dollar, amtSymb) => {
	return (
		<>
			{dollar && <span className="font-14">{amtSymb && amtSymb} $</span>}
			{amt}
		</>
	);
};

export const handleExpireDate = (month, year) => {
	const currentYear = new Date().getFullYear();
	const currentMonth = new Date().getMonth();
	if (currentYear === year) {
		if (currentMonth < month) {
			return true;
		} else return false;
	} else if (currentYear < year) return true;
	else return false;
};
export const legendSymbol = (color, text, symbolClass, innerClass) => {
	return `
		<div class="d-flex align-items-center">
		<div class='legend__symbol rounded-circle d-flex align-items-center justify-content-center mr-2' + ' ' + ${symbolClass} style='border: ${
			color ? "1px solid " + color : "1px solid blue"
		}'>
		<div class='border-radius-8 legend__inner' + ${innerClass} style='background: ${
			color || "blue"
		}'></div>
		</div>
		<div class='font-10 bold-600 grey'>${text}</div>
		</div>
	`;
};

export const commonSegmentTrack = (message, paymentObj, orgId, orgName) => {
	window.analytics.track(message, {
		newPaymentObj: paymentObj,
		currentCategory: "Transactions",
		currentPageName: "Payment-Methods",
		orgId: orgId || "",
		orgName: orgName || "",
	});
};

export const commonSegmentTrack2 = (message, orgId, orgName) => {
	window.analytics.track(message, {
		currentCategory: "Transactions",
		currentPageName: "Payment-Methods",
		orgId: orgId || "",
		orgName: orgName || "",
	});
};

export const checkSomeValuesEmpty = (object) => {
	return Object.keys(object)
		?.map((key) => object[key]?.error)
		?.every((va) => va === false);
};

export const onValidate = (
	key,
	value,
	validation,
	setValidation,
	cardNumberLength = 4
) => {
	switch (key) {
		case "expiry_date":
			let date = value;
			if (!date)
				setValidation({
					...validation,
					expiry_date: { error: true, message: "Invalid date" },
				});
			else
				setValidation({
					...validation,
					expiry_date: { error: false },
				});
			return;
		case "logo_url":
			if (!value)
				setValidation({
					...validation,
					logo_url: {
						error: true,
						message: "Image is required.",
					},
				});
			else
				setValidation({
					...validation,
					logo_url: { error: false },
				});
			return;
		case "beneficiary_name":
			let beneficiary_name = value?.trimStart();
			if (!beneficiary_name || beneficiary_name?.length === 0)
				setValidation({
					...validation,
					beneficiary_name: {
						error: true,
						message: "Invalid date",
					},
				});
			else
				setValidation({
					...validation,
					beneficiary_name: { error: false },
				});
			return;
		case "name":
			let v = value?.trimStart();
			if (!v || v?.length === 0)
				setValidation({
					...validation,
					name: { error: true, message: "Name is required." },
				});
			else setValidation({ ...validation, name: { error: false } });
			return;
		case "number":
			let number = value?.trimStart();
			if (!number || number?.length < cardNumberLength)
				setValidation({
					...validation,
					number: {
						error: true,
						message: `Card number should be at least ${cardNumberLength} digits.`,
					},
				});
			else setValidation({ ...validation, number: { error: false } });
			return;
		case "type":
			let type = value?.trimStart();
			if (!type || type?.length === 0)
				setValidation({
					...validation,
					type: {
						error: true,
						message: "Card type is required.",
					},
				});
			else setValidation({ ...validation, type: { error: false } });
			return;

		case "currency":
			let currency = value?.trimStart();
			if (!currency || currency?.length === 0)
				setValidation({
					...validation,
					currency: {
						error: true,
						message: "Card type is required.",
					},
				});
			else
				setValidation({
					...validation,
					currency: { error: false },
				});
			return;
		default:
			return;
	}
};

export const handleSummaryLoader = (loaderAmount, loaderChange) => {
	return loaderAmount === 0 && loaderChange === 0 ? false : true;
};

export const getSpendsFinancialSourceDetail = (
	conditionData,
	sourceData,
	currency,
	secondSourceData
) => {
	const MANUALSOURCES = {
		csv: {
			icon: csvIcon,
			iconStyle: "financial-green-pill",
			uploadDetails: `CSV uploaded by ${capitalizeFirstLetter(
				sourceData
			)} `,
			fileName: capitalizeFirstLetter(sourceData),
			lastTransaction: `Uploaded on ${moment(sourceData).format(
				"DD MMM 'YY, hh:mm A"
			)} `,
			totalTransaction: sourceData,
			numberOfTransaction: "No of Transactions",
		},
		manual: {
			icon: manualIcon,
			iconStyle: "purple-icon-bg",
			uploadDetails: "Manually added transactions",
			fileName: capitalizeFirstLetter(secondSourceData),
			lastTransaction: `Transaction added on ${moment(sourceData).format(
				"DD MMM 'YY, hh:mm A"
			)}`,
			totalTransaction: ` ${currencyFormatter(
				secondSourceData,
				currency,
				true,
				null,
				true
			)}`,
			numberOfTransaction: "Amount",
		},
	};
	return conditionData === modalCard.CSV
		? MANUALSOURCES.csv
		: MANUALSOURCES.manual;
};

export const getSpendsFinancialSourceSyncDetail = (syncedData) => {
	const Pill = {
		sync: {
			pillValue: `Synced at ${moment(syncedData).format(
				"DD MMM 'YY, hh:mm A"
			)}`,
			pillStyle: "financial-green-pill",
		},
		disconnect: {
			pillValue: "Disconnected",
			pillStyle: "financial-red-pill",
		},
		connected: {
			pillValue: "Connected",
			pillStyle: "financial-green-pill",
		},
	};
	return syncedData === "disconnected"
		? Pill.disconnect
		: syncedData === "connected"
			? Pill.connected
			: syncedData
				? Pill.sync
				: null;
};

export const getSourceSyncText = (synced_at, status, toDays) => {
	let obj = {
		label: "",
		isConnected: false,
		className: "",
	};

	if (status === "not-connected") {
		obj = {
			label: "Disconnected",
			isConnected: false,
			className: "financial-red-pill",
		};
	}
	if (synced_at)
		obj = {
			...obj,
			label: toDays
				? moment(synced_at).fromNow()
				: `Synced at ${moment(synced_at).format(
						"DD MMM 'YY, hh:mm A"
					)}`,
			isConnected: true,
			className: "financial-green-pill",
		};
	return obj;
};

export const handleDownloadFileFromURL = (url, fileName) => {
	const link = document.createElement("a");
	link.href = url;
	link.setAttribute("download", fileName);
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};
export const APP_SPENDS_CHART = {
	ACCRUED_SPENDS: "ACCRUED_SPENDS",
	ACTUAL_SPENDS_VS_EST_COST: "ACTUAL_SPENDS_VS_EST_COST",
	AMMORTISED_SPENDS: "AMMORTISED_SPENDS",
};

function generateCSVdataForSpendsChart(graph, type) {
	let csvData;
	if (type === APP_SPENDS_CHART.ACCRUED_SPENDS) {
		csvData = graph?.series?.map((series) => {
			const year =
				series?.name === "CURRENT_YEAR"
					? moment().year()
					: moment().subtract(1, "year").year();

			const csvRow = {};
			csvRow["Year"] = year;
			graph?.xAxis?.categories?.forEach((month, index) => {
				if (month) {
					csvRow[month] = series?.data?.[index] ?? 0;
				}
			});
			return csvRow;
		});
	} else {
		const isAmmortisedSpends = APP_SPENDS_CHART.AMMORTISED_SPENDS;

		csvData = graph?.series?.map((series) => {
			const ammortisedSpendsTitle =
				series?.name === "AMMORTIZED_SPEND"
					? "Ammortized Spend"
					: "Estimated Cost";
			const actualSpendsTitle =
				series?.name === "ACTUAL_SPEND"
					? "Actual Spend"
					: "Estimated Cost";
			const title = isAmmortisedSpends
				? ammortisedSpendsTitle
				: actualSpendsTitle;

			const csvRow = {};
			csvRow["Series"] = title;
			graph?.xAxis?.categories?.forEach((category, index) => {
				const key = moment(category?.date).format("MMMM YYYY");
				console.log("format: ", key);
				if (category?.date) {
					csvRow[key] = series?.data?.[index]?.y ?? 0;
				}
			});
			return csvRow;
		});
	}

	const headers = Object?.keys(csvData?.[0]);

	const csv = [
		headers,
		...csvData?.map((obj) =>
			headers.map((header) => {
				const value = obj[header];
				return value;
			})
		),
	];
	return csv;
}

export function downloadSpendsCSV(data, fileName, type) {
	const csvData = generateCSVdataForSpendsChart(data, type);
	try {
		let csvContent = "data:text/csv;charset=utf-8,";
		csvData.forEach((row) => {
			const csvRow = row?.join(",");
			csvContent += csvRow + "\r\n";
		});

		const encodedUri = encodeURI(csvContent);
		const link = document.createElement("a");
		link.setAttribute("href", encodedUri);
		link.setAttribute("download", `${fileName}.csv`);
		document.body.appendChild(link);

		link.click();
		document.body.removeChild(link);
	} catch (error) {
		console.error("Error occurred during CSV download:", error);
	}
}
