import React from "react";
import Icon from "./Icon";
import { Tooltip as ZluriTooltip, Typography } from "@zluri/ui-components";
import { useSortableItemContext } from "./SortableItemContext";
import cn from "classnames";
interface IListItem {
	isItemChecked: boolean;
	details: string;
	groupName: string;
	title: string;
	disabled?: boolean;
}
const ListItem = ({
	isItemChecked,
	details,
	groupName,
	title,
	disabled = false,
}: IListItem) => (
	<>
		<div className="d-flex align-items-center  flex-1 gap-8">
			<div className="d-flex align-items-center gap-8">
				{disabled ? (
					<Icon name="rearrange" color="#ddd" />
				) : (
					<div style={{ cursor: "pointer" }}>
						<div className="dragging-handle-container">
							<DragHandle />
						</div>
						<div className="drag-icon">
							<Icon name="rearrange" />
						</div>
					</div>
				)}
				<div
					style={{ paddingTop: 2 }}
					className="d-flex align-items-center"
				>
					<input
						type="checkbox"
						checked={isItemChecked}
						style={{ width: 14, height: 14 }}
						disabled={disabled}
					/>
				</div>
			</div>
			<Text
				text={title}
				textVariant="body_2_regular"
				className="text-truncate-medium"
			/>
		</div>
		<div className="d-flex align-items-center w-30 justify-content-end">
			{groupName && (
				<Text
					text={groupName}
					textStyles={{
						background: "#E1E8F8",
						borderRadius: 4,
						width: "fit-content",
						padding: "2px 4px",
					}}
					textVariant={"body_3_medium"}
					textColor={"secondary_grey_1"}
					className="text-truncate-medium justify-content-end"
				/>
			)}
			{details && (
				<div>
					<ZluriTooltip
						className={"v2-table-cell"}
						placement={"top"}
						content={
							<div
								className="d-flex flex-column align-items-start"
								style={{
									maxWidth: 220,
									padding: "4px",
								}}
							>
								{groupName && (
									<Typography
										variant="body_2_medium"
										color="white"
										style={{
											background: "#484848",
											borderRadius: 8,
											width: "fit-content",
											padding: "2px 4px",
										}}
									>
										{groupName}
									</Typography>
								)}
								<Typography
									variant="body_1_bold"
									color="white"
									style={{ textAlign: "left" }}
								>
									{title}
								</Typography>
								<Typography
									variant="body_2_regular"
									color="white"
									style={{
										textAlign: "left",
									}}
								>
									{details}
								</Typography>
							</div>
						}
					>
						<Icon
							name="info"
							className="column-header-info__icon mx-2"
						/>
					</ZluriTooltip>
				</div>
			)}
		</div>
	</>
);

function DragHandle() {
	const { attributes, listeners, ref } = useSortableItemContext();

	return (
		<div
			{...attributes}
			{...listeners}
			ref={ref}
			className="drag-icon-hover"
		>
			<Icon name="rearrange-hover" />
		</div>
	);
}

interface ITextWithToolTip {
	text: string;
	textStyles?: React.CSSProperties;
	className: string;
	textColor?: string;
	textVariant: string; //(??)
}
const Text = ({
	text,
	textStyles = {},
	textVariant,
	textColor,
	className = "",
}: ITextWithToolTip) => {
	return (
		<div
			className={cn("d-flex align-items-center w-100", className)}
			style={{ maxWidth: "100%" }}
		>
			<div className="text-truncate-medium" style={{ maxWidth: "90%" }}>
				<Typography
					variant={textVariant}
					color={textColor}
					style={{
						maxWidth: "90%",
						whiteSpace: "nowrap",
						overflow: "hidden",
						textOverflow: "ellipsis",
						...textStyles,
					}}
				>
					{text}
				</Typography>
			</div>
		</div>
	);
};
export default ListItem;
