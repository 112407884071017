import React from "react";
import PropTypes from "prop-types";
const ArrowPolygon = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={14}
		height={11}
		fill="none"
		{...props}
	>
		<path
			fill="#F6F7FA"
			d="M6.134.5a1 1 0 0 1 1.732 0l5.196 9a1 1 0 0 1-.866 1.5H1.804a1 1 0 0 1-.866-1.5l5.196-9Z"
		/>
	</svg>
);
function AppSettings(props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="17"
			height="16"
			fill="none"
			viewBox="0 0 17 16"
			{...props}
		>
			<path
				stroke="#484848"
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M8.8 5c.721 0 1.306-.596 1.306-1.333 0-.736-.585-1.333-1.306-1.333-.721 0-1.306.597-1.306 1.333 0 .737.585 1.334 1.306 1.334zM10.105 3.667h4.26M3.262 3.667H7.49M6.187 9.334c.722 0 1.307-.597 1.307-1.333 0-.737-.585-1.334-1.307-1.334-.721 0-1.306.597-1.306 1.334 0 .736.585 1.333 1.306 1.333zM7.491 8h6.875M3.262 8h1.616M7.494 13.667c.721 0 1.306-.597 1.306-1.333S8.215 11 7.494 11c-.722 0-1.306.597-1.306 1.333s.584 1.333 1.306 1.333zM8.797 12.334h5.569M3.262 12.334h2.925"
			></path>
		</svg>
	);
}
const Plus = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={16}
		height={16}
		viewBox="0px 0px 16px 16px"
		fill="none"
		{...props}
	>
		<path
			fill="currentColor"
			d="M2 8a.75.75 0 0 0 .75.75h4.375a.125.125 0 0 1 .125.125v4.375a.75.75 0 1 0 1.5 0V8.875a.125.125 0 0 1 .125-.125h4.375a.75.75 0 1 0 0-1.5H8.875a.125.125 0 0 1-.125-.125V2.75a.75.75 0 0 0-1.5 0v4.375a.125.125 0 0 1-.125.125H2.75A.75.75 0 0 0 2 8Z"
		/>
	</svg>
);

const CSVFile = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={12}
		height={12}
		fill="none"
		{...props}
	>
		<g fill="#1176BE" clipPath="url(#a)">
			<path d="M10 9.5V5a.5.5 0 0 0-.5-.5h-9A.5.5 0 0 0 0 5v4.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5ZM3.25 8.438a.312.312 0 1 1 0 .625 1.812 1.812 0 1 1 0-3.625.312.312 0 1 1 0 .625 1.188 1.188 0 0 0 0 2.375Zm1.463-1.754.92.613a.964.964 0 0 1-.535 1.766H4.25a.312.312 0 1 1 0-.625h.85a.339.339 0 0 0 .188-.621l-.92-.613A.964.964 0 0 1 4.9 5.438h.85a.312.312 0 1 1 0 .625H4.9a.339.339 0 0 0-.188.621h.002Zm3.85-.184a4.09 4.09 0 0 1-.813 2.438.313.313 0 0 1-.5 0A4.089 4.089 0 0 1 6.437 6.5v-.75a.312.312 0 1 1 .625 0v.75c.002.513.117 1.02.337 1.482a.125.125 0 0 0 .2.007c.223-.465.338-.974.338-1.489v-.75a.312.312 0 1 1 .625 0l.002.75Z" />
			<path d="M11.854 2.854 9.146.147A.5.5 0 0 0 8.793 0H3a1 1 0 0 0-1 1v2.875A.125.125 0 0 0 2.125 4h.75A.125.125 0 0 0 3 3.876V1.25A.25.25 0 0 1 3.25 1h5.125a.125.125 0 0 1 .125.125V2.5a1 1 0 0 0 1 1h1.375a.125.125 0 0 1 .125.125v7.125a.25.25 0 0 1-.25.25H3.125A.125.125 0 0 1 3 10.875v-.25a.125.125 0 0 0-.125-.125h-.75a.125.125 0 0 0-.125.125V11a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V3.207a.5.5 0 0 0-.146-.353Z" />
		</g>
		<defs>
			<clipPath id="a">
				<path fill="#fff" d="M0 0h12v12H0z" />
			</clipPath>
		</defs>
	</svg>
);

function Close(props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			fill="none"
			viewBox="0 0 16 16"
			{...props}
		>
			<path
				fill="currentColor"
				d="M9.15 8.088a.125.125 0 010-.177l4.631-4.63a.75.75 0 00-1.062-1.06L8.09 6.848a.125.125 0 01-.178 0L3.281 2.22A.75.75 0 002.22 3.28l4.63 4.631a.125.125 0 010 .177L2.22 12.72a.75.75 0 101.06 1.06L7.912 9.15a.125.125 0 01.177 0l4.631 4.631a.75.75 0 001.061-1.06L9.15 8.088z"
			></path>
		</svg>
	);
}
const PDFFile = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={12}
		height={12}
		fill="none"
		{...props}
	>
		<g fill="#FE6955" clipPath="url(#a)">
			<path d="M4.226 6.161c-.053-.023-.163-.024-.163.082v1.989a.119.119 0 0 0 .163.105 1.188 1.188 0 0 0 0-2.176ZM2 6.061h-.312a.126.126 0 0 0-.125.127v.623a.126.126 0 0 0 .125.126H2a.437.437 0 1 0 0-.875Z" />
			<path d="M9 9.5V5a.5.5 0 0 0-.5-.5h-8A.5.5 0 0 0 0 5v4.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5ZM2 7.561h-.312a.126.126 0 0 0-.125.127V8.75a.312.312 0 1 1-.625 0v-3a.312.312 0 0 1 .312-.313H2a1.062 1.062 0 0 1 0 2.125Zm1.75 1.5a.312.312 0 0 1-.313-.312v-3a.312.312 0 0 1 .313-.313 1.813 1.813 0 0 1 0 3.626Zm4-3h-1a.187.187 0 0 0-.188.188v.562a.126.126 0 0 0 .127.125h.561a.312.312 0 1 1 0 .625h-.561a.126.126 0 0 0-.127.127V8.75a.312.312 0 1 1-.625 0v-2.5a.813.813 0 0 1 .813-.814h1a.312.312 0 1 1 0 .625Z" />
			<path d="M11.854 2.853 9.146.146A.5.5 0 0 0 8.793 0H3a1 1 0 0 0-1 1v2.875A.125.125 0 0 0 2.125 4h.75A.125.125 0 0 0 3 3.874V1.25A.25.25 0 0 1 3.25 1h5.125a.125.125 0 0 1 .125.125V2.5a1 1 0 0 0 1 1h1.375a.125.125 0 0 1 .125.125v7.125a.25.25 0 0 1-.25.25H3.125A.125.125 0 0 1 3 10.875v-.25a.124.124 0 0 0-.124-.125h-.751a.125.125 0 0 0-.125.125V11a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V3.206a.5.5 0 0 0-.146-.353Z" />
		</g>
		<defs>
			<clipPath id="a">
				<path fill="#fff" d="M0 0h12v12H0z" />
			</clipPath>
		</defs>
	</svg>
);

function HelpCenter(props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			fill="none"
			viewBox="0 0 16 16"
			{...props}
		>
			<g
				stroke="currentColor"
				strokeLinecap="round"
				strokeLinejoin="round"
				clipPath="url(#clip0_2501_302854)"
			>
				<path d="M6 6a2 2 0 112.667 1.886A1 1 0 008 8.83V9"></path>
				<path d="M14.5.5h-13a1 1 0 00-1 1v13a1 1 0 001 1h13a1 1 0 001-1v-13a1 1 0 00-1-1z"></path>
				<path
					fill="currentColor"
					d="M7.861 11.042a.25.25 0 11.278.417.25.25 0 01-.278-.417z"
				></path>
			</g>
			<defs>
				<clipPath id="clip0_2501_302854">
					<path fill="currentColor" d="M0 0H16V16H0z"></path>
				</clipPath>
			</defs>
		</svg>
	);
}
function Delete(props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			fill="none"
			viewBox="0 0 16 16"
			{...props}
		>
			<g clipPath="url(#clip0_1046_40809)">
				<path
					fill="currentColor"
					d="M12.993 5h-10a.333.333 0 00-.333.333v9.334A1.333 1.333 0 003.993 16h8a1.333 1.333 0 001.334-1.333V5.333A.333.333 0 0012.993 5zm-6.166 8.667a.5.5 0 11-1 0v-6a.5.5 0 011 0v6zm3.333 0a.5.5 0 11-1 0v-6a.5.5 0 011 0v6zm4.5-11h-3.167a.167.167 0 01-.166-.167v-.833A1.667 1.667 0 009.66 0H6.327A1.667 1.667 0 004.66 1.667V2.5a.167.167 0 01-.167.167H1.327a.667.667 0 100 1.333H14.66a.667.667 0 000-1.333zM5.993 2.5v-.833a.333.333 0 01.334-.334H9.66a.333.333 0 01.333.334V2.5a.167.167 0 01-.166.167H6.16a.167.167 0 01-.167-.167z"
				></path>
			</g>
			<defs>
				<clipPath id="clip0_1046_40809">
					<path fill="#fff" d="M0 0H16V16H0z"></path>
				</clipPath>
			</defs>
		</svg>
	);
}
function DataIcon(props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="10"
			height="10"
			fill="none"
			viewBox="0 0 10 10"
			{...props}
		>
			<g fill="currentColor" clipPath="url(#clip0_4442_323021)">
				<path d="M4.583 8.238v.828a.104.104 0 01-.104.104H2.318a.412.412 0 100 .824h5.355a.412.412 0 100-.824H5.512a.104.104 0 01-.104-.104V8.24a.406.406 0 00-.4-.408H5a.41.41 0 00-.417.407zm4.12 1.345a.412.412 0 00.413.411h.412a.412.412 0 100-.823h-.412a.412.412 0 00-.412.412zm-8.239.411h.412a.412.412 0 100-.824H.464a.412.412 0 100 .824zm8.548-5.133a.104.104 0 00-.17-.08c-.799.648-2.301.99-3.744.99-1.442 0-2.945-.342-3.743-.99a.104.104 0 00-.17.08v.91c0 .911 1.753 1.649 3.913 1.649 2.161 0 3.914-.738 3.914-1.648v-.91z"></path>
				<path d="M5.099 3.712c-1.443 0-2.946-.342-3.744-.991a.104.104 0 00-.17.08v.911c0 .91 1.752 1.648 3.913 1.648 2.161 0 3.914-.737 3.914-1.648v-.91a.104.104 0 00-.17-.08c-.799.648-2.301.99-3.743.99z"></path>
				<path d="M5.101 3.3c2.162 0 3.914-.737 3.914-1.647S7.263.005 5.1.005c-2.16 0-3.913.738-3.913 1.648 0 .91 1.752 1.648 3.913 1.648z"></path>
			</g>
			<defs>
				<clipPath id="clip0_4442_323021">
					<path fill="#fff" d="M0 0H10V10H0z"></path>
				</clipPath>
			</defs>
		</svg>
	);
}

function Download(props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			fill="currentColor"
			className="bi bi-download"
			viewBox="0 0 16 16"
			strokeWidth={8}
			{...props}
		>
			<path d="M.5 9.9a.5.5 0 01.5.5v2.5a1 1 0 001 1h12a1 1 0 001-1v-2.5a.5.5 0 011 0v2.5a2 2 0 01-2 2H2a2 2 0 01-2-2v-2.5a.5.5 0 01.5-.5z"></path>
			<path d="M7.646 11.854a.5.5 0 00.708 0l3-3a.5.5 0 00-.708-.708L8.5 10.293V1.5a.5.5 0 00-1 0v8.793L5.354 8.146a.5.5 0 10-.708.708l3 3z"></path>
		</svg>
	);
}

function InfoIcon(props) {
	return (
		<svg
			width="4"
			height="10"
			viewBox="0 0 4 10"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M3.375 1.7625C3.375 1.96028 3.31635 2.15362 3.20647 2.31807C3.09659 2.48252 2.94041 2.61069 2.75768 2.68638C2.57496 2.76206 2.37389 2.78187 2.17991 2.74328C1.98593 2.7047 1.80775 2.60946 1.66789 2.4696C1.52804 2.32975 1.4328 2.15157 1.39421 1.95759C1.35563 1.76361 1.37543 1.56254 1.45112 1.37981C1.52681 1.19709 1.65498 1.04091 1.81943 0.931027C1.98388 0.821146 2.17722 0.762497 2.375 0.762497C2.64022 0.762497 2.89457 0.867854 3.08211 1.05539C3.26964 1.24293 3.375 1.49728 3.375 1.7625ZM1.875 8.4875V5.5025C1.875 5.43619 1.84866 5.3726 1.80178 5.32572C1.75489 5.27884 1.6913 5.2525 1.625 5.2525H1.375C1.17609 5.2525 0.985322 5.17348 0.844669 5.03283C0.704017 4.89217 0.625 4.70141 0.625 4.5025C0.625 4.30359 0.704017 4.11282 0.844669 3.97217C0.985322 3.83152 1.17609 3.7525 1.375 3.7525H2.62C2.81891 3.7525 3.00968 3.83152 3.15033 3.97217C3.29098 4.11282 3.37 4.30359 3.37 4.5025V8.4875C3.37 8.68641 3.29098 8.87717 3.15033 9.01783C3.00968 9.15848 2.81891 9.2375 2.62 9.2375C2.42109 9.2375 2.23032 9.15848 2.08967 9.01783C1.94902 8.87717 1.87 8.68641 1.87 8.4875H1.875Z"
				fill="#717171"
			/>
		</svg>
	);
}
function MoreVert(props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			fill="none"
			viewBox="0 0 16 16"
			{...props}
		>
			<path
				fill="#484848"
				d="M7.5 4.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM7.5 9.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM7.5 14.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"
			></path>
		</svg>
	);
}
function LongArrowUp(props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			fill="none"
			viewBox="0 0 16 16"
			{...props}
		>
			<path
				fill="currentColor"
				d="M10.531 4.916L8.326 2.791a.486.486 0 00-.653 0L5.468 4.916a.43.43 0 00-.1.485c.035.08.095.15.17.2a.474.474 0 00.257.074h1.397c.03 0 .06.012.082.033a.11.11 0 01.034.078v6.88c0 .177.073.347.202.472.13.125.306.195.49.195s.36-.07.49-.195a.655.655 0 00.202-.472v-6.88a.107.107 0 01.034-.079.115.115 0 01.082-.032h1.397c.091 0 .18-.026.257-.075a.448.448 0 00.17-.2.43.43 0 00-.1-.484z"
			></path>
		</svg>
	);
}

function LongArrowDown(props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			fill="none"
			viewBox="0 0 16 16"
			{...props}
		>
			<path
				fill="currentColor"
				d="M5.468 11.084l2.206 2.125a.486.486 0 00.653 0l2.205-2.125a.43.43 0 00.1-.484.448.448 0 00-.17-.2.474.474 0 00-.257-.075H8.808a.117.117 0 01-.082-.033.109.109 0 01-.034-.078v-6.88a.655.655 0 00-.202-.472.706.706 0 00-.49-.195c-.184 0-.36.07-.49.195a.655.655 0 00-.202.472v6.88a.107.107 0 01-.034.079.115.115 0 01-.082.032H5.795a.474.474 0 00-.257.075.448.448 0 00-.17.2.43.43 0 00.1.484z"
			></path>
		</svg>
	);
}
function ButtonUp() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="17"
			fill="none"
			viewBox="0 0 16 17"
		>
			<g fill="#717171" clipPath="url(#clip0_5787_12875)">
				<path d="M1.527 7.773h.933c.084 0 .164-.03.225-.086l4.643-4.222a.996.996 0 011.345 0l4.643 4.222a.333.333 0 00.224.086h.934a.658.658 0 00.614-.417.65.65 0 00-.17-.743L8.457.738a.658.658 0 00-.452-.181.677.677 0 00-.454.176L1.088 6.61a.647.647 0 00-.18.733.663.663 0 00.62.431z"></path>
				<path d="M8.456 5.071a.658.658 0 00-.452-.181.677.677 0 00-.454.177l-6.462 5.875a.647.647 0 00-.18.733.663.663 0 00.62.432h.932c.084 0 .164-.03.225-.086l4.643-4.222a.996.996 0 011.345 0l4.643 4.222a.333.333 0 00.224.086h.934a.657.657 0 00.614-.417.65.65 0 00-.17-.743L8.457 5.071z"></path>
				<path d="M8.45 9.397a.667.667 0 00-.897 0L1.082 15.28a.666.666 0 00.451 1.16h12.939a.667.667 0 00.449-1.16L8.45 9.398z"></path>
			</g>
			<defs>
				<clipPath id="clip0_5787_12875">
					<path
						fill="#fff"
						d="M0 0H16V16H0z"
						transform="translate(0 .5)"
					></path>
				</clipPath>
			</defs>
		</svg>
	);
}

function UploadIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			fill="none"
			viewBox="0 0 20 20"
		>
			<g fill="#484848" clipPath="url(#clip0_15032_87947)">
				<path d="M5.417 9.167a5.417 5.417 0 105.416 5.416 5.423 5.423 0 00-5.416-5.416zm2.041 5.18a.417.417 0 01-.375.236h-.73a.104.104 0 00-.103.101v2.4a.833.833 0 11-1.667 0v-2.396a.106.106 0 00-.103-.105h-.73a.416.416 0 01-.326-.676l1.667-2.084a.431.431 0 01.651 0l1.667 2.084a.417.417 0 01.05.44zm3.531-8.489A1.244 1.244 0 1011 3.371a1.244 1.244 0 00-.01 2.487z"></path>
				<path d="M19.512 2.5L17.5.487A1.667 1.667 0 0016.322 0H6.667A1.667 1.667 0 005 1.667v6.062a.207.207 0 00.202.208c.305.006.865.029 1.237.069a.204.204 0 00.228-.207V2.083a.417.417 0 01.416-.416h9.066c.11 0 .216.043.294.121l1.769 1.769a.416.416 0 01.121.294V15a.417.417 0 01-.416.417h-5.715a.203.203 0 00-.201.175 6.61 6.61 0 01-.303 1.215.204.204 0 00.19.276h6.445A1.667 1.667 0 0020 15.415V3.678c0-.442-.175-.865-.488-1.178z"></path>
				<path d="M17.064 10.75a.155.155 0 00-.005-.157L14.362 6.28a.31.31 0 00-.527 0l-1.509 2.417a.156.156 0 01-.264 0l-.536-.864a.313.313 0 00-.528 0L9.96 9.494a.204.204 0 00.035.258c.322.303.614.637.87.998a.2.2 0 00.166.084h5.9a.155.155 0 00.133-.084z"></path>
			</g>
			<defs>
				<clipPath id="clip0_15032_87947">
					<path fill="#fff" d="M0 0H20V20H0z"></path>
				</clipPath>
			</defs>
		</svg>
	);
}

function TopDown() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="17"
			fill="none"
			viewBox="0 0 16 17"
		>
			<g fill="#717171" clipPath="url(#clip0_5787_12889)">
				<path d="M14.473 9.223h-.934a.333.333 0 00-.224.087l-4.643 4.222a.996.996 0 01-1.345 0L2.683 9.31a.333.333 0 00-.224-.087h-.933a.657.657 0 00-.614.418.65.65 0 00.17.742l6.461 5.876a.66.66 0 00.453.181.68.68 0 00.454-.176l6.462-5.876a.648.648 0 00.18-.733.663.663 0 00-.62-.432z"></path>
				<path d="M7.543 11.926a.66.66 0 00.453.18c.168 0 .33-.063.454-.175l6.462-5.876a.648.648 0 00.18-.734.662.662 0 00-.62-.432h-.932a.333.333 0 00-.225.087L8.672 9.198a.996.996 0 01-1.345 0L2.683 4.977a.333.333 0 00-.224-.087h-.933a.657.657 0 00-.614.417.65.65 0 00.17.743l6.461 5.876z"></path>
				<path d="M7.55 7.6a.667.667 0 00.897 0l6.471-5.883a.667.667 0 00-.448-1.16H1.528a.667.667 0 00-.449 1.16L7.55 7.599z"></path>
			</g>
			<defs>
				<clipPath id="clip0_5787_12889">
					<path
						fill="#fff"
						d="M0 0H16V16H0z"
						transform="translate(0 .5)"
					></path>
				</clipPath>
			</defs>
		</svg>
	);
}
function SearchIcon(props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={24}
			height={24}
			fill="none"
			{...props}
		>
			<path
				fill="#5287E8"
				d="m23.423 20.58-5.063-5.125a9.992 9.992 0 1 0-2.814 2.844l5.03 5.093a2.029 2.029 0 0 0 2.83.016 2 2 0 0 0 .017-2.828ZM9.997 1.73a8.252 8.252 0 1 1-8.252 8.252A8.262 8.262 0 0 1 9.997 1.73Z"
			/>
			<path
				fill="#5287E8"
				d="M9.997 16.733a6.752 6.752 0 1 0 0-13.503 6.752 6.752 0 0 0 0 13.503Z"
			/>
		</svg>
	);
}
function AlertIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			fill="none"
			viewBox="0 0 16 16"
		>
			<path
				fill="#FE6955"
				d="M7.988 0a8.137 8.137 0 00-5.674 2.435A7.866 7.866 0 000 8.138 7.852 7.852 0 007.866 16h.142A8.074 8.074 0 0016 7.86 7.846 7.846 0 007.988 0zm-.989 11.028a.984.984 0 01.966-1.02h.018a1.018 1.018 0 01.953 1.363.984.984 0 01-.904.637h-.018A1.019 1.019 0 017 11.028zm.333-2.695v-4a.667.667 0 011.334 0v4a.667.667 0 01-1.334 0z"
			></path>
		</svg>
	);
}

function DangerIcon(props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="12"
			height="12"
			fill="none"
			viewBox="0 0 12 12"
			{...props}
		>
			<path
				fill="currentColor"
				d="M11.5 5.293L6.707.5a1 1 0 00-1.414 0L.5 5.293a1 1 0 000 1.414L5.293 11.5a1 1 0 001.414 0L11.5 6.707a1 1 0 000-1.414zM5.534 3.208a.5.5 0 011 0v3a.5.5 0 01-1 0v-3zm.525 5.755h-.014a.765.765 0 01-.714-1.022.737.737 0 01.677-.478h.014a.763.763 0 01.715 1.023.737.737 0 01-.678.477z"
			></path>
		</svg>
	);
}

function WarningIcon(props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			fill="none"
			viewBox="0 0 16 16"
			{...props}
		>
			<path
				fill="currentColor"
				d="M7.989 0a8.137 8.137 0 00-5.674 2.435A7.867 7.867 0 000 8.138 7.853 7.853 0 007.867 16h.142A8.074 8.074 0 0016 7.86 7.844 7.844 0 007.99 0zM7 11.028a.984.984 0 01.966-1.02h.018a1.018 1.018 0 011.015.98.983.983 0 01-.966 1.02h-.018A1.02 1.02 0 017 11.028zm.333-2.695v-4a.667.667 0 111.334 0v4a.667.667 0 01-1.334 0z"
			></path>
		</svg>
	);
}
function CircleTick() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			fill="none"
			viewBox="0 0 16 16"
		>
			<path
				fill="#2266E2"
				d="M8 0a8 8 0 108 8 8.01 8.01 0 00-8-8zm4.618 5.467l-4.563 6.192a.674.674 0 01-.954.126L3.843 9.179a.667.667 0 11.833-1.041l2.717 2.174 4.152-5.634a.665.665 0 011.129.031.669.669 0 01-.056.758z"
			></path>
		</svg>
	);
}
function PencilIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			fill="none"
			viewBox="0 0 16 16"
		>
			<g clipPath="url(#clip0_335_1046)">
				<path
					fill="#484848"
					d="M10.791 2.34a.343.343 0 00-.471 0L2.71 9.95a.333.333 0 000 .47l2.854 2.854a.333.333 0 00.47 0l7.61-7.607a.333.333 0 000-.472L10.79 2.341zM2 11.14a.333.333 0 00-.552.133L.02 15.553a.334.334 0 00.422.422l4.28-1.426a.333.333 0 00.133-.552L2 11.14zM15.312.678a2.409 2.409 0 00-3.325 0l-.475.475a.333.333 0 000 .472l2.852 2.853a.333.333 0 00.472 0L15.312 4a2.354 2.354 0 000-3.322z"
				></path>
			</g>
			<defs>
				<clipPath id="clip0_335_1046">
					<path fill="#fff" d="M0 0H16V16H0z"></path>
				</clipPath>
			</defs>
		</svg>
	);
}
function RecentIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			fill="none"
			viewBox="0 0 16 16"
		>
			<path
				stroke="#484848"
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M5.528 7.472l2.19 2.157 5.844-5.796"
			></path>
			<path
				stroke="#484848"
				strokeLinecap="round"
				strokeWidth="0.945"
				d="M10.553 3.671a5.37 5.37 0 00-2.736-.744c-2.971 0-5.38 2.399-5.38 5.357 0 2.959 2.409 5.358 5.38 5.358 2.97 0 5.378-2.399 5.378-5.358 0-.497-.068-.98-.196-1.437"
			></path>
			<path
				stroke="#484848"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="0.85"
				d="M9.27 4.377l1.698-.328-.329-1.69"
			></path>
		</svg>
	);
}

function LowRiskLevel() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			fill="none"
			viewBox="0 0 16 16"
		>
			<path
				fill="#5FCF64"
				d="M8 8.955a1.331 1.331 0 01-.944-.39L2.251 3.76A.89.89 0 013.51 2.5L7.91 6.903a.126.126 0 00.178 0L12.491 2.5A.89.89 0 0113.75 3.76L8.945 8.565a1.331 1.331 0 01-.945.39z"
			></path>
			<path
				fill="#5FCF64"
				d="M8 13.235a1.332 1.332 0 01-.944-.39L2.25 8.04A.89.89 0 013.51 6.78l4.4 4.402a.127.127 0 00.138.027.127.127 0 00.041-.027L12.49 6.78a.89.89 0 011.26 1.259l-4.806 4.805a1.33 1.33 0 01-.944.39z"
			></path>
		</svg>
	);
}
function MediumriskLevel() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			fill="none"
			viewBox="0 0 16 16"
		>
			<path
				fill="#FFA217"
				d="M8 6.53a1.331 1.331 0 01.944.39l4.805 4.805a.89.89 0 01-1.259 1.26l-4.4-4.402a.126.126 0 00-.18 0l-4.4 4.401a.89.89 0 01-1.26-1.259L7.057 6.92A1.331 1.331 0 018 6.53z"
			></path>
			<path
				fill="#FFA217"
				d="M8 2.25a1.331 1.331 0 01.944.39l4.805 4.805a.89.89 0 01-1.259 1.26l-4.4-4.402a.126.126 0 00-.18 0l-4.4 4.401a.89.89 0 01-1.26-1.259L7.057 2.64A1.331 1.331 0 018 2.25z"
			></path>
		</svg>
	);
}

function HighRiskLevel() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			fill="none"
			viewBox="0 0 16 16"
		>
			<path
				fill="#FE6955"
				d="M8 9a1.331 1.331 0 01.944.39l4.805 4.805a.89.89 0 01-1.259 1.26l-4.4-4.402a.124.124 0 00-.09-.037.125.125 0 00-.09.037l-4.4 4.401a.89.89 0 01-1.26-1.259L7.057 9.39A1.331 1.331 0 018 9z"
			></path>
			<path
				fill="#FE6955"
				d="M8 4.5a1.331 1.331 0 01.944.39l4.805 4.805a.89.89 0 01-1.259 1.26l-4.4-4.402a.126.126 0 00-.18 0l-4.4 4.401a.89.89 0 01-1.26-1.259L7.057 4.89A1.331 1.331 0 018 4.5z"
			></path>
			<path
				fill="#FE6955"
				d="M8 .167a1.331 1.331 0 01.944.39l4.805 4.805a.89.89 0 01-1.259 1.26L8.09 2.22a.126.126 0 00-.18 0l-4.4 4.401a.89.89 0 01-1.26-1.259L7.057.557A1.331 1.331 0 018 .167z"
			></path>
		</svg>
	);
}
function UnAvailableRiskLevel() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			fill="none"
			viewBox="0 0 16 16"
		>
			<g fill="#A8A8A8" clipPath="url(#clip0_5180_54060)">
				<path d="M8 0a8 8 0 108 8 8.009 8.009 0 00-8-8zm0 14.667A6.666 6.666 0 1114.667 8 6.674 6.674 0 018 14.667z"></path>
				<path d="M12 6.5H4a.833.833 0 00-.833.833v1.334c0 .46.373.833.833.833h8c.46 0 .833-.373.833-.833V7.333A.833.833 0 0012 6.5z"></path>
			</g>
			<defs>
				<clipPath id="clip0_5180_54060">
					<path fill="#fff" d="M0 0H16V16H0z"></path>
				</clipPath>
			</defs>
		</svg>
	);
}
function Task(props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			fill="none"
			viewBox="0 0 16 16"
			{...props}
		>
			<g fill="currentcolor" clipPath="url(#clip0_6_32)">
				<path d="M13.667 1.333h-3.334a.16.16 0 01-.138-.08 2.552 2.552 0 00-4.392 0 .16.16 0 01-.136.08H2.333a1 1 0 00-1 1V15a1 1 0 001 1h11.334a1 1 0 001-1V2.333a1 1 0 00-1-1zm-1 9.507c0 .398-.142.782-.4 1.084l-1.28 1.493A1.667 1.667 0 019.721 14H3.667a.333.333 0 01-.334-.333v-10a.333.333 0 01.334-.333h8.666a.333.333 0 01.334.334v7.173z"></path>
				<path d="M9.833 5.775l-2.59 3.453a.166.166 0 01-.251.018l-1.21-1.21A.834.834 0 104.6 9.214l1.44 1.441c.292.29.686.454 1.097.454.037 0 .073 0 .11-.004a1.549 1.549 0 001.133-.618l2.785-3.712a.835.835 0 00-.46-1.307.834.834 0 00-.874.307h.002z"></path>
			</g>
			<defs>
				<clipPath id="clip0_6_32">
					<path fill="currentColor" d="M0 0H16V16H0z"></path>
				</clipPath>
			</defs>
		</svg>
	);
}
function Application(props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			fill="none"
			viewBox="0 0 16 16"
			{...props}
		>
			<g fill="currentColor" clipPath="url(#clip0_5_8)">
				<path d="M12.333 2.834a2 2 0 00-2-2H2a2 2 0 00-2 2v6.333a2 2 0 002 2h8.333a2 2 0 002-2V2.834zm-5.897-.667a.644.644 0 011.128 0c.064.1.1.215.103.334a.648.648 0 01-.103.333.644.644 0 01-1.128 0 .648.648 0 01-.103-.333.646.646 0 01.103-.334zm-2.333 0a.644.644 0 011.128 0c.064.1.1.215.102.334a.648.648 0 01-.102.333.644.644 0 01-1.128 0A.648.648 0 014 2.501a.646.646 0 01.103-.334zm-2.375.061a.667.667 0 01.605-.394.655.655 0 01.564.333c.064.1.1.215.103.334a.648.648 0 01-.103.333.644.644 0 01-1.128 0 .648.648 0 01-.102-.333c0-.095.021-.188.061-.273zM11 9.168a.666.666 0 01-.667.666H2a.667.667 0 01-.667-.667V4.333a.167.167 0 01.167-.166h9.333a.167.167 0 01.167.166v4.834z"></path>
				<path d="M16 6.834a2 2 0 00-2-2h-.333a.333.333 0 00-.334.333v2.667a.333.333 0 00.334.333h.833a.167.167 0 01.167.167v4.833a.667.667 0 01-.667.667H5.667A.666.666 0 015 13.167v-.333a.667.667 0 10-1.333 0v.333a2 2 0 002 2H14a2 2 0 002-2V6.834z"></path>
			</g>
			<defs>
				<clipPath id="clip0_5_8">
					<path fill="currentColor" d="M0 0H16V16H0z"></path>
				</clipPath>
			</defs>
		</svg>
	);
}
function Support(props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			fill="none"
			viewBox="0 0 16 16"
			{...props}
		>
			<g clipPath="url(#clip0_2479_186564)">
				<path
					stroke="#484848"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M2.5 6a2 2 0 00-2 2v2a2 2 0 002 2H3a.5.5 0 00.5-.5v-5A.5.5 0 003 6h-.5zm0 0a5.5 5.5 0 1111 0m0 6H13a.5.5 0 01-.5-.5v-5A.5.5 0 0113 6h.5m0 6a2 2 0 002-2V8a2 2 0 00-2-2m0 6v.5a2 2 0 01-2 2H10m0 0a1 1 0 00-1-1H8a1 1 0 000 2h1a1 1 0 001-1z"
				></path>
			</g>
			<defs>
				<clipPath id="clip0_2479_186564">
					<path fill="#fff" d="M0 0H16V16H0z"></path>
				</clipPath>
			</defs>
		</svg>
	);
}
function Feedback() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			fill="none"
			viewBox="0 0 16 16"
		>
			<g clipPath="url(#clip0_2479_186574)">
				<path
					fill="#484848"
					fillRule="evenodd"
					d="M1.146 1.146A.5.5 0 011.5 1h13a.5.5 0 01.5.5v4a.5.5 0 001 0v-4A1.5 1.5 0 0014.5 0h-13A1.5 1.5 0 000 1.5v10A1.5 1.5 0 001.5 13H2v2.5a.5.5 0 00.8.4l2.98-2.233a.5.5 0 00-.6-.8L3 14.5v-2a.5.5 0 00-.5-.5h-1a.5.5 0 01-.5-.5v-10a.5.5 0 01.146-.354zm12.294 7.12a.91.91 0 011.287 0l.006.006a.91.91 0 010 1.286l-4.98 4.981-1.616.324.324-1.617 4.98-4.98zM14.084 7a1.91 1.91 0 00-1.35.56l-5.088 5.087a.5.5 0 00-.136.255l-.5 2.5a.5.5 0 00.588.588l2.5-.5a.5.5 0 00.256-.136l5.086-5.09a1.91 1.91 0 000-2.699l-.006-.006a1.91 1.91 0 00-1.35-.56zM4 3a.5.5 0 000 1h8a.5.5 0 000-1H4zm-.5 3.5A.5.5 0 014 6h6a.5.5 0 010 1H4a.5.5 0 01-.5-.5zM4 9a.5.5 0 000 1h4a.5.5 0 000-1H4z"
					clipRule="evenodd"
				></path>
			</g>
			<defs>
				<clipPath id="clip0_2479_186574">
					<path fill="#fff" d="M0 0H16V16H0z"></path>
				</clipPath>
			</defs>
		</svg>
	);
}

function Help(props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			fill="none"
			viewBox="0 0 16 16"
			{...props}
		>
			<g clipPath="url(#clip0_2479_9059)">
				<path
					fill="#fff"
					d="M8 0a8 8 0 108 8 8.009 8.009 0 00-8-8zm0 12.667a1 1 0 110-2 1 1 0 010 2zm1.067-4.054a.667.667 0 00-.4.612.667.667 0 01-1.334 0 2 2 0 011.2-1.834A1.333 1.333 0 106.667 6.17a.667.667 0 01-1.334 0 2.667 2.667 0 113.734 2.444z"
				></path>
			</g>
			<defs>
				<clipPath id="clip0_2479_9059">
					<path fill="#fff" d="M0 0H16V16H0z"></path>
				</clipPath>
			</defs>
		</svg>
	);
}
function License(props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			fill="none"
			viewBox="0 0 16 16"
			{...props}
		>
			<g fill="currentColor" clipPath="url(#clip0_7042_482191)">
				<path d="M15.665 6a2 2 0 00-2-2h-.173a.166.166 0 01-.166-.154A2 2 0 0011.332 2h-.173a.166.166 0 01-.166-.154A2 2 0 008.999 0H2.332a2 2 0 00-2 2v8a2 2 0 002 2h.173a.166.166 0 01.166.154A2 2 0 004.665 14h.174a.167.167 0 01.166.154A2 2 0 006.999 16h6.666a2 2 0 002-2V6zM2.332 10.667A.667.667 0 011.665 10V2a.667.667 0 01.667-.667h6.667a.667.667 0 01.628.445A.167.167 0 019.47 2H4.665a2 2 0 00-2 2v6.5a.166.166 0 01-.166.167h-.167zm2.333 2A.667.667 0 014 12V4a.667.667 0 01.666-.667h6.667a.667.667 0 01.629.445.167.167 0 01-.158.222H7a2 2 0 00-2 2v6.5a.166.166 0 01-.167.167h-.167zM14.332 14a.667.667 0 01-.667.667H7A.667.667 0 016.332 14V6a.667.667 0 01.667-.667h6.666a.667.667 0 01.667.667v8z"></path>
				<path d="M10.146 6.441a.501.501 0 00-.704.079l-.866 1.084a.165.165 0 01-.248.014l-.14-.14a.5.5 0 10-.707.708l.667.666a.506.506 0 00.744-.04l1.333-1.667a.5.5 0 00-.08-.704zm2.687 1.225H11.5a.5.5 0 000 1h1.333a.5.5 0 000-1zm-2.687 2.442a.501.501 0 00-.704.078l-.866 1.085a.165.165 0 01-.248.014l-.14-.14a.5.5 0 10-.707.707l.667.667a.506.506 0 00.744-.04l1.333-1.667a.5.5 0 00-.08-.704zm3.187 1.724a.5.5 0 00-.5-.5H11.5a.5.5 0 100 1h1.333a.5.5 0 00.5-.5z"></path>
			</g>
			<defs>
				<clipPath id="clip0_7042_482191">
					<path fill="#fff" d="M0 0H16V16H0z"></path>
				</clipPath>
			</defs>
		</svg>
	);
}
function Bell(props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="21"
			height="20"
			fill="none"
			viewBox="0 0 21 20"
			{...props}
		>
			<g clipPath="url(#clip0_1075_48937)">
				<path
					fill="currentColor"
					d="M17.833 14.583a1.25 1.25 0 01-1.25-1.25V9.282a6.63 6.63 0 00-5.416-6.721V.833a.833.833 0 10-1.667 0v1.728a6.63 6.63 0 00-5.417 6.72v4.052a1.25 1.25 0 01-1.25 1.25.833.833 0 100 1.667h15a.833.833 0 000-1.667zM12.197 17.5H8.47a.209.209 0 00-.207.185 2.083 2.083 0 104.14 0 .206.206 0 00-.206-.185z"
				></path>
			</g>
			<defs>
				<clipPath id="clip0_1075_48937">
					<path
						fill="currentColor"
						d="M0 0H20V20H0z"
						transform="translate(.333)"
					></path>
				</clipPath>
			</defs>
		</svg>
	);
}

function Tick(props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="12"
			height="12"
			fill="none"
			viewBox="0 0 12 12"
			{...props}
		>
			<path
				fill="currentColor"
				d="M11.573 2.7l-1.396-1.4a.25.25 0 00-.354 0L3.927 7.2a.25.25 0 01-.354 0L2.177 5.8a.25.25 0 00-.354 0L.427 7.2a.25.25 0 000 .354L3.573 10.7a.25.25 0 00.354 0l7.646-7.65a.25.25 0 000-.35z"
			></path>
		</svg>
	);
}

function CaretDown(props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="6"
			height="6"
			fill="none"
			viewBox="0 0 6 6"
			{...props}
		>
			<g clipPath="url(#clip0_9594_24927)">
				<path
					fill="currentColor"
					d="M2.763 4.824a.575.575 0 00.67-.145l2.452-2.787a.462.462 0 00-.375-.773l-5.017.015a.462.462 0 00-.378.758L2.568 4.68c.054.061.12.11.195.144z"
				></path>
			</g>
			<defs>
				<clipPath id="clip0_9594_24927">
					<path fill="#fff" d="M0 0H6V6H0z"></path>
				</clipPath>
			</defs>
		</svg>
	);
}

function BulkUpdate() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			fill="none"
			viewBox="0 0 16 16"
		>
			<g fill="#484848" clipPath="url(#clip0_1956_583026)">
				<path d="M7.999 5.665h4.666a.667.667 0 000-1.333H8a.667.667 0 000 1.333z"></path>
				<path d="M16 2.943A1.331 1.331 0 0015.61 2L14 .39c-.25-.25-.59-.39-.943-.39H5.333A1.333 1.333 0 004 1.333v4.85a.165.165 0 00.161.167c.244.004.692.023.99.055a.164.164 0 00.182-.165V1.667a.333.333 0 01.334-.334h7.252c.089 0 .173.035.236.098l1.414 1.414a.333.333 0 01.098.236V12a.333.333 0 01-.334.333H9.761a.164.164 0 00-.16.14c-.05.331-.132.657-.243.972a.163.163 0 00.152.221h5.157A1.334 1.334 0 0016 12.333v-9.39z"></path>
				<path d="M11.667 7.999A.667.667 0 0011 7.332H8.333a.637.637 0 00-.452.192.115.115 0 00.004.17c.302.27.573.573.807.903a.16.16 0 00.133.066H11A.667.667 0 0011.667 8zm-7.334-.667a4.333 4.333 0 100 8.667 4.333 4.333 0 000-8.667zm1.594 3.792a.333.333 0 01-.26.541h-.5a.166.166 0 00-.167.167v1.833a.667.667 0 01-1.333 0v-1.833a.166.166 0 00-.167-.167H3a.334.334 0 01-.26-.541l1.333-1.667a.345.345 0 01.521 0l1.333 1.667z"></path>
			</g>
			<defs>
				<clipPath id="clip0_1956_583026">
					<path fill="#fff" d="M0 0H16V16H0z"></path>
				</clipPath>
			</defs>
		</svg>
	);
}

function Database(props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			fill="none"
			viewBox="0 0 16 16"
			{...props}
		>
			<g clipPath="url(#clip0_11477_61746)">
				<path
					fill="currentColor"
					d="M3.765 14.604a.652.652 0 000 1.303h8.467a.652.652 0 000-1.303H8.818a.167.167 0 01-.167-.167v-1.792a.165.165 0 01.158-.166c3.062-.17 6.044-1.297 6.044-3.25V3.365C14.853 1.24 11.32.093 8 .093c-3.321 0-6.85 1.145-6.858 3.266v5.873c0 1.955 2.986 3.081 6.05 3.25a.167.167 0 01.158.167v1.792a.166.166 0 01-.167.166l-3.418-.003zM2.474 5.358c1.7.9 3.604 1.342 5.526 1.28 1.92.062 3.824-.38 5.522-1.28v.94c0 .79-2.151 1.939-5.523 1.939-3.371 0-5.525-1.149-5.525-1.94v-.939zM8 1.426c3.37 0 5.52 1.147 5.523 1.938v.005c-.007.792-2.156 1.934-5.522 1.934S2.475 4.157 2.475 3.365c0-.79 2.151-1.939 5.525-1.939zm-5.523 7.8V8.29C4.175 9.19 6.079 9.631 8 9.57c1.92.061 3.825-.38 5.523-1.28v.939c0 .791-2.152 1.94-5.523 1.94-3.371 0-5.526-1.149-5.526-1.94l.003-.003zm11.39 6.03a.651.651 0 00.651.651h.651a.652.652 0 000-1.303h-.651a.65.65 0 00-.651.651zm-13.685 0a.651.651 0 00.651.651h.652a.652.652 0 000-1.303H.833a.65.65 0 00-.651.651z"
				></path>
			</g>
			<defs>
				<clipPath id="clip0_11477_61746">
					<path fill="#fff" d="M0 0H16V16H0z"></path>
				</clipPath>
			</defs>
		</svg>
	);
}
function MinusIcon(props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			fill="none"
			viewBox="0 0 16 16"
			{...props}
		>
			<rect
				width="10"
				height="1.333"
				x="3"
				y="7.333"
				fill="currentColor"
				rx="0.667"
			></rect>
		</svg>
	);
}
function LearnMore(props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="10"
			height="10"
			fill="#A8A8A8"
			viewBox="0 0 10 10"
			{...props}
		>
			<path
				fill="currentColor"
				d="M10 7.96a.79.79 0 01-1.579 0V3.022a.131.131 0 00-.225-.093l-6.849 6.84A.79.79 0 01.232 8.652l6.857-6.849a.131.131 0 00-.093-.224H2.04A.79.79 0 112.04 0h6.644A1.316 1.316 0 0110 1.317V7.96z"
			></path>
		</svg>
	);
}
function Exclamation(props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			fill="none"
			viewBox="0 0 16 16"
			{...props}
		>
			<g clipPath="url(#clip0_16039_101198)">
				<path
					fill="currentColor"
					d="M7.989 0a8.137 8.137 0 00-5.674 2.435A7.867 7.867 0 000 8.138 7.852 7.852 0 007.867 16h.142A8.074 8.074 0 0016 7.86 7.844 7.844 0 007.989 0zM7 11.028a.984.984 0 01.966-1.02h.018a1.018 1.018 0 011.015.98.983.983 0 01-.966 1.02h-.018A1.019 1.019 0 017 11.028zm.333-2.695v-4a.667.667 0 111.334 0v4a.667.667 0 01-1.334 0z"
				></path>
			</g>
			<defs>
				<clipPath id="clip0_16039_101198">
					<path fill="#fff" d="M0 0H16V16H0z"></path>
				</clipPath>
			</defs>
		</svg>
	);
}
const iconDatabase = {
	"arrow-polygon": ArrowPolygon,
	plus: Plus,
	"csv-file": CSVFile,
	"pdf-file": PDFFile,
	delete: Delete,
	download: Download,
	"more-vert": MoreVert,
	"bottom-up": ButtonUp,
	"top-down": TopDown,
	"app-settings": AppSettings,
	warning: WarningIcon,
	"circle-tick": CircleTick,
	alert: AlertIcon,
	danger: DangerIcon,
	pencil: PencilIcon,
	recent: RecentIcon,
	upload: UploadIcon,
	data: DataIcon,
	"low-risk-level": LowRiskLevel,
	"high-risk-level": HighRiskLevel,
	"medium-risk-level": MediumriskLevel,
	"unavailable-risk-level": UnAvailableRiskLevel,
	close: Close,
	task: Task,
	application: Application,
	"help-center": HelpCenter,
	support: Support,
	feedback: Feedback,
	help: Help,
	license: License,
	bell: Bell,
	tick: Tick,
	search: SearchIcon,
	"caret-down": CaretDown,
	"bulk-update": BulkUpdate,
	database: Database,
	info: InfoIcon,
	"long-arrow-up": LongArrowUp,
	"long-arrow-down": LongArrowDown,
	minus: MinusIcon,
	"learn-more": LearnMore,
	exclamation: Exclamation,
};

const Icon = ({ name, ...otherProps }) => {
	const Component = iconDatabase[name];
	return <Component name={name} {...otherProps} />;
};
Icon.propTypes = {
	name: PropTypes.oneOf(Object.keys(iconDatabase)).isRequired,
};
Icon.DisplayName = "Svg Icon";
export default Icon;
