import React, { createContext, useContext, useState, useCallback } from "react";

import PropTypes from "prop-types";
import {
	getValueFromLocalStorage,
	setValueToLocalStorage,
} from "utils/localStorage";

const PlatformContext = createContext({
	platform: "",
	style: {},
	onPlatformChange: () => {},
});

export const PLATFORM_TYPE = {
	SAAS: "saas",
	IGA: "iga",
};
const themeStyle = {
	[PLATFORM_TYPE.SAAS]: {
		"--zluri-primary-bg-color": "#001c50",
		"--zluri-primary-bg-gradient-color":
			"linear-gradient(109.04deg, #001E54 -0.86%, #001131 100%)",
		"--zluri-secondary-bg-color": "#2266e2",
		"--zluri-secondary-light-bg-color": "#5287e8",
		"--zluri-secondary-extra-light-bg-color": "#82a7ed",
		"--zluri-primary-text-color": "#ffffff",
	},
	[PLATFORM_TYPE.IGA]: {
		"--zluri-primary-bg-color": "#390011",
		"--zluri-primary-bg-gradient-color":
			"linear-gradient(109.04deg, #400013 -0.86%, #26000B 100%)",
		"--zluri-secondary-bg-color": "#801736",
		"--zluri-secondary-light-bg-color": "#a73658",
		"--zluri-secondary-extra-light-bg-color": "#c43e66",
		"--zluri-primary-text-color": "#ffffff",
	},
};

export function PlatformProvider({ children }) {
	const [platform, setPlatform] = useState(
		getValueFromLocalStorage("z_platform") ?? PLATFORM_TYPE.SAAS
	);

	const style = themeStyle[platform];

	const onPlatformChange = useCallback((platform) => {
		setPlatform(platform);
		setValueToLocalStorage("z_platform", platform);
	}, []);

	return (
		<PlatformContext.Provider value={{ platform, style, onPlatformChange }}>
			{children}
		</PlatformContext.Provider>
	);
}
PlatformProvider.propTypes = {
	children: PropTypes.element,
};

export function usePlatform() {
	const platform = useContext(PlatformContext);

	if (!platform) throw new Error("Invalid Context Call!");

	return platform;
}
