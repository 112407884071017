import React from "react";

import PropTypes from "prop-types";

import MultilevelFilter from "../MultiLevelFilter/MultilevelFilter";

import { useGroupedSources, useSourcesApiByModule } from "./hooks";
import { getSourceDefaultValues } from "./utils";
import { isEmpty } from "underscore";

const MultiLevelSource = ({ filter, intID, value, onChange }) => {
	const { sources, error, loading } = useSourcesApiByModule({
		type: filter?.module_key,
		appId: intID,
		apiValues: value,
	});

	const { options } = useGroupedSources({ sources });

	const handleCheck = (api_values) => {
		onChange(api_values);
	};

	return (
		<MultilevelFilter
			name={filter?.field_name}
			subName={"Source Type"}
			categorizedOptions={options}
			onCheck={handleCheck}
			selectedKeys={isEmpty(value) ? {} : getSourceDefaultValues(value)}
			loading={loading}
			error={error}
		/>
	);
};

export default MultiLevelSource;

MultiLevelSource.propTypes = {
	filter: PropTypes.object,
	intID: PropTypes.number,
	value: PropTypes.array,
	onChange: PropTypes.func,
};
