// import { compose } from "redux";
import { createStore } from "redux-dynamic-modules-core";
import { getThunkExtension } from "redux-dynamic-modules-thunk";
import { getSagaExtension } from "redux-dynamic-modules-saga";
import { routerMiddleware } from "connected-react-router/immutable";
import history from "./history";
import { dynamicModules } from "../reducers";
import { loadState, updateLocalStorage } from "./localStorage";
import { connectRouter } from "connected-react-router";
import { userInfoReducer } from "reducers/userinfo.reducer";
import {
	costCentersApi,
	vendorsApi,
	applicationsListApi,
	searchApplicationsListApi,
	addExistingApplicationsApi,
	settingsFieldApi,
	searchUsersListApi,
	appOverviewSummaryMetricsApi,
} from "./store.rtk";
import { searchOwnersListApi } from "./store.rtk";
import { searchCategoriesListApi } from "./store.rtk";
import { addApplicationsApi } from "./store.rtk";
import {
	paymentMethodApi,
	chargebackSettingsApi,
	departmentApi,
	spendsSettingsApi,
} from "rtk/rtk";

// const traceEnabledEnvironment = ["localhost"];
// const trace = !!traceEnabledEnvironment.includes(process.env.REACT_APP_ENV);
// const componseEnhance =
// 	window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__?.({ trace }) || compose;
// const persistedState = loadState();

const routerModule = {
	id: "router",
	reducerMap: {
		router: connectRouter(history),
	},
	middlewares: [routerMiddleware(history)],
};

const rtkModule = {
	id: "rtk",
	reducerMap: {
		[vendorsApi.reducerPath]: vendorsApi.reducer,
		[applicationsListApi.reducerPath]: applicationsListApi.reducer,
		[searchOwnersListApi.reducerPath]: searchOwnersListApi.reducer,
		[searchCategoriesListApi.reducerPath]: searchCategoriesListApi.reducer,
		[addApplicationsApi.reducerPath]: addApplicationsApi.reducer,
		[settingsFieldApi.reducerPath]: settingsFieldApi.reducer,
		[searchUsersListApi.reducerPath]: searchUsersListApi.reducer,
		[addExistingApplicationsApi.reducerPath]:
			addExistingApplicationsApi.reducer,
		[searchApplicationsListApi.reducerPath]:
			searchApplicationsListApi.reducer,
		[paymentMethodApi.reducerPath]: paymentMethodApi.reducer,
		[applicationsListApi.reducerPath]: applicationsListApi.reducer,
		[chargebackSettingsApi.reducerPath]: chargebackSettingsApi.reducer,
		[departmentApi.reducerPath]: departmentApi.reducer,
		[spendsSettingsApi.reducerPath]: spendsSettingsApi.reducer,
		[appOverviewSummaryMetricsApi.reducerPath]:
			appOverviewSummaryMetricsApi.reducer,

	},
	middlewares: [
		vendorsApi.middleware,
		costCentersApi.middleware,
		applicationsListApi.middleware,
		searchApplicationsListApi.middleware,
		searchOwnersListApi.middleware,
		searchCategoriesListApi.middleware,
		addApplicationsApi.middleware,
		addExistingApplicationsApi.middleware,
		settingsFieldApi.middleware,
		searchUsersListApi.middleware,
		paymentMethodApi.middleware,
		applicationsListApi.middleware,
		chargebackSettingsApi.middleware,
		departmentApi.middleware,
		spendsSettingsApi.middleware,
		appOverviewSummaryMetricsApi.middleware,
	],
};

const userInfoModule = {
	id: "userInfo",
	reducerMap: { userInfo: userInfoReducer },
};

export const store = createStore(
	{
		initialState: loadState(),
		extensions: [getThunkExtension(), getSagaExtension()],
		// advancedCombineReducers: createRootReducer(history),
	},
	routerModule,
	userInfoModule,
	rtkModule
);
store.addModules(dynamicModules);

store.subscribe(updateLocalStorage);
