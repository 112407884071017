import GetImageOrNameBadge from "common/GetImageOrNameBadge";
import React from "react";
import { getNested } from "utils/getNested";

export default function ImageNameBadgeRenderer({ component, data }) {
	const valuefromComponent = component.valueKey;
	const url = Array.isArray(valuefromComponent)
		? valuefromComponent.reduce((_, x) => _?.[x], data)
		: getNested(data, valuefromComponent);
	const namefromComponent = component.nameKey;
	const name = Array.isArray(namefromComponent)
		? namefromComponent.reduce((_, x) => _?.[x], data)
		: getNested(data, namefromComponent);
	return url || name ? (
		<div style={component.style}>
			<GetImageOrNameBadge
				width={20}
				height={20}
				url={url}
				name={name}
				borderRadius={component?.badgeVariant === "round" ? "50%" : 0}
			/>
		</div>
	) : null;
}
