import React from "react";

import moment from "moment";
import {
	$createParagraphNode,
	$createTextNode,
	$getRoot,
	createEditor,
} from "lexical";
import { filter, sortBy, where } from "underscore";

import {
	Button,
	IconList,
	SecondaryButtonVariant,
	theme,
} from "@zluri/ui-components";
import Links from "../components/OverviewV2/Header/Links";

import uparrow_black from "assets/icons/Icon_Arrow_Up.svg";
import add_contract from "assets/icons/Icon_Contracts.svg";
import add_subscription from "assets/icons/Icon_Add_Subscriptions.svg";
import add_users from "assets/icons/Icon_Add_Users.svg";
import add_pertpetual from "assets/icons/Icon_Add_Perpetual.svg";
import archive_icon from "assets/icons/Icon_Archive.svg";
import add_transaction from "assets/icons/Icon_Add_Transaction.svg";
import mergeApplicationIcon from "assets/icons/Icon_Fit.svg";
import mapApplicationIcon from "assets/icons/Icon_Add_Application.svg";
import link from "assets/icons/clip.svg";
import notes_primary from "assets/notes_primary.svg";
import list_primary from "assets/list_primary.svg";
import user_valid_badge from "assets/user_valid_badge.svg";
import spend_money from "assets/spend_money.svg";
import note_list from "assets/note_list.svg";
import primarySourceSettings from "assets/icons/v2Icons/Icon_Primary_Sources.svg";

import {
	setSelectedModal,
	setSelectedTab,
} from "../redux/Applications.actions";
import {
	OVERVIEW_MODAL,
	SPENDS_MODAL,
} from "../constants/ApplicationConstants";
import { internationalFormatNumberFormatter } from "constants/currency";
import { spendsCurrencyFormat } from "./Spends";
import { OVERVIEW_CHECKLIST_STATE } from "../constants/OverviewV2.constants";
import { withHttp } from "utils/common";
import { TriggerIssue } from "utils/sentry";
import { mapToApp, mergeApps } from "services/api/applications";
import replaceZluriWithPartnerName from "utils/replaceZluriFromResponse";
import ErrorWrapper from "UIComponents/Rbac/ErrorWrapper";
import { openModal } from "reducers/modal.reducer";

const getOverviewActions = (
	app,
	archived,
	updateAppArchiveStatus,
	sourceSettings
) => {
	return [
		[
			{
				text: "Add Contract",
				icon: add_contract,
				action: () => window.open("/contract/new"),
			},
			{
				text: "Add Subscription",
				icon: add_subscription,
				action: () => window.open("/subscription/new"),
			},
			{
				text: "Add Perpetual",
				icon: add_pertpetual,
				action: () => window.open("/perpetual/new"),
			},
			{
				text: "Add Users",
				icon: add_users,
				action: () => window.open(`/applications/${app?.app_id}#users`),
			},
			{
				text: "Add Transactions",
				icon: add_transaction,
				dispatch: setSelectedTab(OVERVIEW_MODAL.ADD_SINGLE_TRANSACTION),
			},
		],
		[
			{
				text: "Map to a different app",
				icon: mapApplicationIcon,
				dispatch: setSelectedTab(OVERVIEW_MODAL.MAP_APPLICATION),
			},
			{
				text: "Merge with another app",
				icon: mergeApplicationIcon,
				dispatch: setSelectedTab(OVERVIEW_MODAL.MERGE_APPLICATION),
			},
		],

		[
			{
				text: archived ? "UnArchive" : "Archive",
				icon: archive_icon,
				subContent: {
					title: `Are you sure you want to ${
						archived ? "un archive" : "archive"
					} ${app?.app_name} ?`,
					onApply: updateAppArchiveStatus,
					applyText: (
						<div className="d-flex align-items-center gap-4">
							<span>{archived ? "UnArchive" : "Archive"}</span>
						</div>
					),
				},
			},
			{
				text: "Primary source settings",
				icon: primarySourceSettings,
				dispatch: openModal("applicationSourceSettings", {
					sources: app?.app_source_array,
					settings: sourceSettings,
					appId: app?.app_id,
				}),
			},
		],
	];
};

const generateSourcesList = (sources) => {
	if (!sources?.length) return [{ src: "", name: "M" }];
	return sources?.map((source) => {
		return {
			src: source?.logo,
			name: source?.keyword,
		};
	});
};

const overviewHeaderTabs = (data, appLoaded, clickListeners, appId) => {
	const { notes, links, sources, checklist } = data;
	const options = [
		{
			data: true,
			editable: false,
			onClick: clickListeners?.sources,
			className: "z_overview_header-sources",
			prefix: {
				style: { width: "fit-content" },
				content: (
					<IconList
						list={generateSourcesList(sources?.data?.all)}
						maxCount={3}
						gap={4}
						withTooltip={false}
					/>
				),
			},
			title: "Sources",
			loading: sources?.loading,
		},
		{
			className: "z_overview_header-notes",
			editable: false,
			data: true,
			onClick: clickListeners?.notes,
			prefix: {
				content: <img src={notes_primary} alt="" />,
			},
			title: (
				<HeaderTitle
					count={notes?.count}
					text={notes?.count ? "Notes" : "Add Note"}
					countClassName="primary_tag blue_tint_bg black"
				/>
			),
			activeBorderColor: "#82A7ED",
			loading: notes?.loading,
		},
		{
			className: "z_overview_header-links mr-auto",
			editable: false,
			data: true,
			title: (
				<HeaderTitle
					count={links?.data?.length}
					text={links?.data?.[0]?.link || "Add Link"}
					countClassName="primary_tag blue_tint_bg black"
				/>
			),
			prefix: {
				content: <img src={link} alt="" width={12} height={12} />,
			},
			popover: {
				content: (handleClose) => (
					<Links
						appId={appId}
						onClose={handleClose}
						links={links?.data}
						loading={links?.loading}
						isAdding={links?.isAdding}
					/>
				),
				placement: "bottomLeft",
				className: "z_overview_header-links-popover",
			},
			loading: !appLoaded,
		},
		{
			className: "z_overview_header-checklist",
			data,
			prefix: {
				content: (
					<img src={list_primary} width={16} height={16} alt="" />
				),
			},
			isCheckList: true,
			editable: false,
			onClick: clickListeners?.checkList,
			activeBorderColor: "#82A7ED",
			title: (
				<HeaderTitle
					count={checklist?.pending_review}
					text="Checklist"
					countClassName="error_tag"
				/>
			),
			loading: checklist?.loading,
		},
	];
	return options;
};

const overviewMetricMap = (data, appId) => {
	const { users, spends, licenses, contracts } = data;
	const OVERVIEW_METRIC = [
		{
			data: users?.primary_active_users_count,
			className: "spends_summary_card z_overview_metric-card",
			title: "Active Users Primary Sources",
			showRedirect: true,
			redirectUrl: `/applications/${appId}#users`,
			tagRenderProps: {
				title: users?.primary_active_users_count,
				icon: user_valid_badge,
				bgColor: "#E1E8F8",
				color: "#2266e2",
			},
			description: `${
				internationalFormatNumberFormatter({
					number: users?.active_users_count,
				}) || "-"
			} Active Users from all sources`,
			tooltipData: {
				content: replaceZluriWithPartnerName(
					"Number of users Zluri is detecting"
				),
				placement: "top",
			},
			noDataRendererProps: {
				title: "Application has no users",

				onActionClick: () =>
					window.open(`/applications/${appId}#users`),
				buttonText: "Add Users",
			},
			compareData: {
				amount: users?.user_count_diff,
				text: "compared to previous month",
			},
		},
		{
			data: spends?.actual_spend,
			className: "spends_summary_card z_overview_metric-card",
			title: "Spend in Current FY",
			showRedirect: true,
			redirectUrl: `/v2/applications/${appId}#spends`,
			tagRenderProps: {
				title: spendsCurrencyFormat(
					spends?.actual_spend || 0,
					true,
					null,
					true
				),
				icon: spend_money,
				bgColor: "#F0DCFF",
				color: "#9E17FF",
			},
			description: `Annualised costs: ${
				spendsCurrencyFormat(
					spends?.annualized_spend,
					true,
					null,
					true
				) || "-"
			}`,
			tooltipData: {
				content:
					"Spends and Costs of the application for the current FY",
				placement: "top",
			},
			noDataRendererProps: {
				title: "Application has no spend",
				onActionClick: () => (window.location.hash = "#spends"),
				dispatch: setSelectedModal(SPENDS_MODAL.ADD_SINGLE_TRANSACTION),
				buttonText: "Add Spends",
			},
			compareData: {
				amount: spends?.actual_spend_diff,
				symbol: "$",
				text: "compared to previous FY",
			},
		},
		{
			data: licenses?.assigned,
			className: "spends_summary_card z_overview_metric-card",
			title: "Assigned Licenses",
			showRedirect: true,
			redirectUrl: `/applications/${appId}#licenses`,
			tagRenderProps: {
				title: licenses?.assigned
					? internationalFormatNumberFormatter({
							number: licenses?.assigned,
						})
					: 0,
				icon: note_list,
				bgColor: "#E7F8E8",
				color: "#009307",
			},
			description: `${
				licenses?.optimizable_licenses_count
					? internationalFormatNumberFormatter({
							number: licenses?.optimizable_licenses_count,
						})
					: 0
			} optimizable licenses`,
			tooltipData: {
				content:
					"Application licences available and assigned to the users",
				placement: "top",
			},
			noDataRendererProps: {
				title: "Application has no licences",
				onActionClick: () => window.open("/contract/new"),
				buttonText: "Add Licences",
			},
			compareData: {
				amount: licenses?.licenses_count_diff,
				text: "compared to previous FY",
			},
		},
		{
			data: contracts?.upcoming_due_date,
			className: "spends_summary_card z_overview_metric-card",
			title: "Upcoming Contract End Date",
			showRedirect: false,
			tagRenderProps: {
				title: (
					<span className="pl-2">
						{moment(contracts?.upcoming_due_date).format(
							"Do MMMM, 'YY"
						)}
					</span>
				),
				bgColor: "#E6F5FF",
				color: "#1176BE",
			},
			description: `
			${
				contracts?.affected_licenses
					? internationalFormatNumberFormatter(
							contracts?.affected_licenses
						)
					: "0"
			} Licences will be affected`,
			tooltipData: {
				content:
					"End date of the contract and the licences belonging to the contract",
				placement: "top",
			},
			noDataRendererProps: {
				title: "Application has no active contract",
				onActionClick: () => window.open("/contract/new"),
				buttonText: "Add Contract",
			},
			compareData: {
				text: contracts?.name,
			},
		},
	];
	return OVERVIEW_METRIC;
};

const handleSort = (sortKey, list, apiValues, sort) => {
	if (list?.length <= 1) return list;
	const sorted_list = sortBy(list, sortKey);
	switch (sort) {
		case "ascending":
			return sorted_list;
		case "descending":
			return sorted_list?.reverse();
		default:
			return apiValues;
	}
};

const handleSearch = (searchKey1, searchKey2, list, apiValues, query) => {
	return filter(list, (item) =>
		(item?.[searchKey1] || item?.[searchKey2])
			?.toLocaleLowerCase()
			?.includes(query?.toLocaleLowerCase())
	);
};

const HeaderTitle = ({ text, count, countClassName }) => {
	return (
		<span className="d-flex gap-8 align-items-center">
			<span className="text-truncate-medium">{text}</span>
			{count > 0 && <span className={`${countClassName} `}>{count}</span>}
		</span>
	);
};

const getSourcesByType = (sources) => {
	const sourceWiseData = sources?.source_wise;
	return {
		all: sourceWiseData,
		integrations: sourceWiseData?.filter((source) => {
			if (source?.tag === "Others" || source?.keyword === "manual") {
				return source;
			}
		}),
		agent: where(sourceWiseData, { tag: "Agent" }),
		sso: where(sourceWiseData, { tag: "SSO" }),
	};
};

const getCheckListCount = (list) => {
	const state = {
		not_completed_count: 0,
		error_count: 0,
		has_error: false,
	};
	list?.map((item) => {
		const local_status_key = OVERVIEW_CHECKLIST_STATE[item?.status]?.key;
		if (
			local_status_key ===
				OVERVIEW_CHECKLIST_STATE["NOT COMPLETED"].key &&
			!item?.isSkipped
		)
			state.not_completed_count++;
		if (
			local_status_key === OVERVIEW_CHECKLIST_STATE.ERROR.key &&
			!item?.isSkipped
		)
			state.error_count++;
	});
	return { ...state, has_error: state?.error_count > 0 };
};

const handleArrowExpandIcon = ({ isActive }) => (
	<img
		width={16}
		height={16}
		src={uparrow_black}
		style={{
			transform: `rotate(${!isActive ? 180 : 0}deg)`,
			transition: "all 0.1s ease-in-out",
		}}
		alt=""
	/>
);

const handleSourceExpandIcon = ({ isActive, isConnected, hasMetaData }) => {
	if (!isConnected) return null;
	return (
		<Button
			className={`${!hasMetaData && "o-7 z_overview_sources_btn_disable"}`}
			disabled={!hasMetaData}
			variant={SecondaryButtonVariant.secondary_default}
			theme={theme}
		>
			{!isActive ? "Show All Types" : "Hide Types"}
		</Button>
	);
};

const handleLinkClick = (url) => {
	if (url) window.open(`${withHttp(url)}`, "_blank");
};

const handleMapApplication = ({
	appId,
	selectedApplication,
	onSuccess,
	onError,
	onFail,
	onComplete,
}) => {
	if (appId && selectedApplication?.app_id) {
		mapToApp(appId, selectedApplication.app_id)
			.then((res) => {
				if (res.error) {
					throw Error(res.error);
				}

				if (res.status === "fail") {
					onFail && onFail();
					return;
				}

				onSuccess && onSuccess();
			})
			.catch((err) => {
				TriggerIssue("Error while mapping app to another app:", err);
				onError && onError();
			})
			.finally(() => {
				onComplete && onComplete();
			});
	}
};

const handleMergeApplication = ({
	appId,
	selectedApplication,
	onSuccess,
	onError,
	onComplete,
}) => {
	if (selectedApplication?.app_id) {
		mergeApps(appId, selectedApplication.app_id)
			.then((res) => {
				if (res.error) {
					throw Error(res.error);
				}

				onSuccess && onSuccess();
			})
			.catch((err) => {
				TriggerIssue("Error while merging app to another app:", err);
				onError && onError();
			})
			.finally(() => {
				onComplete && onComplete();
			});
	}
};

async function plainTextToLexicalState(text) {
	const lexicalEditor = createEditor();

	return new Promise((resolve) => {
		const removeUpdateListener = lexicalEditor.registerUpdateListener(
			({ editorState }) => {
				resolve(JSON.stringify(editorState));
				removeUpdateListener();
			}
		);

		lexicalEditor.update(() => {
			const paragraph = $createParagraphNode();
			const textNode = $createTextNode(text);
			paragraph.append(textNode);
			$getRoot().clear().append(paragraph);
		});
	});
}

function generateCSVdataForOverviewCharts(graph) {
	const csvData = graph?.chartData?.xAxis?.categories?.map(
		(departmentName) => {
			const departmentData = graph?.data?.find(
				(dept) => dept?.dept_name === departmentName
			)?.graph_data;

			const csvRow = {};
			csvRow["Department"] = departmentName;
			graph?.legends?.forEach((license) => {
				if (license?.name) {
					csvRow[license.name] = departmentData[license.name] ?? 0;
				}
			});
			return csvRow;
		}
	);

	const headers = Object?.keys(csvData?.[0]);

	const csv = [
		headers,
		...csvData?.map((obj) =>
			headers.map((header) => {
				const value = obj[header];
				return value;
			})
		),
	];
	return csv;
}

function downloadCSV(data, fileName) {
	const csvData = generateCSVdataForOverviewCharts(data);
	try {
		let csvContent = "data:text/csv;charset=utf-8,";
		csvData.forEach((row) => {
			const csvRow = row?.join(",");
			csvContent += csvRow + "\r\n";
		});

		const encodedUri = encodeURI(csvContent);
		const link = document.createElement("a");
		link.setAttribute("href", encodedUri);
		link.setAttribute("download", `${fileName}.csv`);
		document.body.appendChild(link);

		link.click();
		document.body.removeChild(link);
	} catch (error) {
		console.error("Error occurred during CSV download:", error);
	}
}

export {
	getOverviewActions,
	overviewHeaderTabs,
	generateSourcesList,
	overviewMetricMap,
	handleSort,
	handleSearch,
	getSourcesByType,
	getCheckListCount,
	handleArrowExpandIcon,
	handleSourceExpandIcon,
	handleLinkClick,
	handleMapApplication,
	handleMergeApplication,
	plainTextToLexicalState,
	downloadCSV,
};
