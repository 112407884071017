import React, { useState } from "react";

import {
	Button,
	Dropdown,
	Typography,
	theme,
	ConfirmPopup,
	GREY_VARIANT,
} from "@zluri/ui-components";
import { unmapDesktopAgents, removeDesktopAgents } from "services/api/agents";

import arrowDownIcon from "assets/icons/arrow-down-12.svg";
import confirmDeleteIcon from "assets/icons/confirm-delete.svg";
import userCrossicon from "assets/icons/user-cross.svg";
import { useDispatch } from "react-redux";
import { AgentsEntity } from "modules/Agents/constants/AgentConstants";

export default function AddDesktopDropdownComponent({ data, component }) {
	let items = [];

	const dispatch = useDispatch();
	const [isUnmap, setIsUnmap] = useState(false);
	const [isRemove, setIsRemove] = useState(false);

	const handleClick = (type) => {
		if (type === "cancel") {
			setIsRemove(false);
			setIsUnmap(false);
		} else if (type === "remove") {
			const reqBody = {
				removeAgentRequests: [
					{
						id: data?._id,
					},
				],
			};
			removeAgent(reqBody);
			setIsRemove(false);
		} else if (type === "unmap") {
			const reqBody = {
				unMapUsers: [
					{
						id: data?._id,
					},
				],
			};
			unMapAgent(reqBody);
			setIsUnmap(false);
		}
	};

	const unMapAgent = (reqBody) => {
		unmapDesktopAgents(reqBody).then((data) => {
			dispatch({
				type: "GET_TABLE_DATA",
				payload: {
					entity: AgentsEntity,
					shouldRefresh: true,
				},
			});
		});
	};

	const removeAgent = (reqBody) => {
		removeDesktopAgents(reqBody).then((data) => {
			dispatch({
				type: "GET_TABLE_DATA",
				payload: {
					entity: AgentsEntity,
					shouldRefresh: true,
				},
			});
		});
	};

	const handleDropdDownClick = (key, btn_name) => {
		btn_name === "unmap" ? setIsUnmap(true) : setIsRemove(true);
	};

	component?.buttons.map((button) => {
		let variant = button.variant;
		if (button?.key === "unmap") {
			variant = data?.user_name ? variant : "alerttag_small_semibold";
		}
		items.push({
			key: data._id,
			btn_name: button?.key,
			user_name: data?.user_name,
			user_email: data?.user_email,
			label: (
				<Typography
					variant={variant}
					color={
						!data?.user_name && button?.key === "unmap"
							? GREY_VARIANT.SECONDARY_GREY_4
							: GREY_VARIANT.SECONDARY_GREY_1
					}
				>
					{button.name}
				</Typography>
			),
			disabled:
				!data?.user_name && button?.key === "unmap" ? true : false,
		});
	});

	const ButtonActions = {
		unmap: {
			text: "Unmap",
			onClick: (e) => handleClick("unmap"),
			variant: "primary-default",
		},
		remove: {
			text: "Remove",
			onClick: (e) => handleClick("remove"),
			variant: "primary-danger",
		},
		cancel: {
			text: "Cancel",
			onClick: (e) => handleClick("cancel"),
			variant: "primary-alternative",
		},
	};
	const ButtonGroup = ({ actions }) => {
		const cancelButtonActions = ButtonActions.cancel;
		return (
			<>
				<Button theme={theme} {...cancelButtonActions}>
					{cancelButtonActions.text}
				</Button>
				<Button theme={theme} {...actions}>
					{actions.text}
				</Button>
			</>
		);
	};

	return (
		<div>
			<Dropdown
				menu={{
					items,
					onClick: (e) => {
						const key = e?.key;
						const btn_name = e?.item?.props?.btn_name;
						handleDropdDownClick(key, btn_name);
					},
				}}
			>
				<Button
					variant="secondary-default"
					iconPosition="end"
					endIcon={arrowDownIcon}
					theme={theme}
				>
					Other Actions
				</Button>
			</Dropdown>
			<ConfirmPopup
				showConfirmPopup={isRemove}
				headerText="Confirm to Remove Agent from Organization"
				logo={confirmDeleteIcon}
				subHeaderText="This action is irreversible. All information attributed to this agent will be deleted. Consider un-mapping the user instead."
				element={<ButtonGroup actions={ButtonActions.remove} />}
			/>
			<ConfirmPopup
				showConfirmPopup={isUnmap}
				headerText="Confirm to Unmap User from Agent"
				logo={userCrossicon}
				subHeaderText={`This action will unmap ${data?.user_name} ${data?.user_email} from this agent.`}
				element={<ButtonGroup actions={ButtonActions.unmap} />}
			/>
		</div>
	);
}
