import React from "react";
import loadable from "@loadable/component";
import { LoaderPage } from "common/Loader/LoaderPage";

export default function customLoadable(
	importCallback,
	loader = <LoaderPage />
) {
	return loadable(importCallback, {
		fallback: loader,
	});
}
