import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import trimText from "read-more-react/dist/utils/trimText";
import OverlayTooltip from "UIComponents/OverlayToolTip";
import Filter from "./Filter";
import List from "../List/List";
import filterIcon from "assets/Icon_AddFilter.svg";
import arrow_down from "assets/arrow-down.svg";
import CellClickComponent from "./ClickComponent";
import ListItem from "../List/ListItem";
import { Overlay, Tooltip } from "react-bootstrap";
import { useOutsideClickListener } from "utils/clickListenerHook";
import SingleSelectFilter from "./SingleSelectFilter";
import StringFilter from "./StringFilter";
import _ from "underscore";
import Chips from "components/Users/Applications/Modals/FiltersRenderer/Chip";
import Divider from "../Divider/Divider";
import MultiSelectFilter from "./MultiSelectFilter";
import icon_database from "assets/Icon_Database.svg";
import icon_puzzle from "assets/Icon_Puzzle.svg";
import icon_usertick from "assets/Icon_UserTick.svg";
import icon_date from "assets/Icon_Date.svg";
import icon_document from "assets/Icon_Document.svg";
import icon_money from "assets/Icon_Money.svg";
import icon_checkbadge from "assets/Icon_CheckBadge.svg";
import icon_alertuser from "assets/Icon_Alert_User.svg";
import icon_chair from "assets/Icon_Chair.svg";
import icon_alert from "assets/Icon_Alert.svg";
import icon_tag from "assets/Icon_Tag.svg";
import Icon_Add from "assets/Icon_add.svg";
import icon_number_id from "assets/icons/icon_numberid.svg";
import icon_user_email from "assets/icons/user-email.svg";
import icon_agent_type from "assets/icons/agent-type.svg";
import icon_app_type_device from "assets/icons/icon_App_Type_Device.svg";
import icon_type_device from "assets/icons/icon_Type_Device.svg";
import { Dropdown } from "react-bootstrap";
import NumberRangeFilter from "./NumberRangeFilter";
import DateRangeFilter from "./DateRangeFIlter";
import { OverlayTrigger, Popover } from "react-bootstrap/esm";
import FilterListItem from "./FilterListItem";
import DropdownButton from "v2UIComponents/DropDownButton/DropDownButton";
import DropDownButtonTab from "v2UIComponents/DropDownButtonTab/DropDownButtonTab";
import {
	Button,
	IconButton,
	Typography,
	theme as V2_DEFAULT_THEME,
} from "@zluri/ui-components";
import DynamicSearchSelect from "./DynamicFilterRenderer";

const filterIconMap = {
	icon_database,
	icon_puzzle,
	icon_usertick,
	icon_date,
	icon_document,
	icon_money,
	icon_checkbadge,
	icon_alertuser,
	icon_chair,
	icon_alert,
	icon_tag,
	icon_number_id,
	icon_user_email,
	icon_agent_type,
	icon_app_type_device,
	icon_type_device,
};
const filterUIMap = {
	string: {
		component: StringFilter,
	},
	range: {
		component: NumberRangeFilter,
	},
	date_range: {
		component: DateRangeFilter,
	},
	single_select: {
		component: SingleSelectFilter,
	},
	multi_select: {
		component: MultiSelectFilter,
	},
	dynamic_filter: {
		component: DynamicSearchSelect,
	},
};
export default function FilterList({
	entity,
	toggleAppliedFilters,
	subEntityData,
	isOnlyEdit = false,
	onSave,
	apiProps,
	intID,
}) {
	const dispatch = useDispatch();
	const filters = useSelector(
		(state) => state.v2TableProperties?.[entity]?.properties
	);
	const dataEntity = subEntityData?.entity || entity;
	const appliedFilters = useSelector(
		(state) => state.v2Table[dataEntity]?.filter_by
	);
	const appliedFiltersCount = appliedFilters?.length;
	const [visibleFilters, setVisibleFilters] = useState(
		appliedFilters || filters
	);

	const [filterValues, setFilterValues] = useState({});

	const [filterString, setFilterString] = useState("");
	const [activeIndex, setActiveIndex] = useState(-1);
	const listContainerRef = useRef(null);
	const filterIconRef = useRef(null);
	const [show, setShow] = useState(false);
	const [resetDone, setResetDone] = useState(false);

	useEffect(() => {
		setShow(false);
		setActiveIndex(-1);
	}, [dataEntity]);

	useEffect(() => {
		!show && setActiveIndex(-1);
	}, [show]);

	useEffect(() => {
		if (Array.isArray(appliedFilters)) {
			let initialFilterValues = {};
			appliedFilters.forEach((f) => {
				if (f.filter_category !== "quick_filter")
					initialFilterValues[f.field_id] = f;
			});
			setFilterValues(initialFilterValues);
		}
	}, [appliedFilters]);

	useOutsideClickListener(
		[listContainerRef],
		() => {
			setShow(false);
		},
		[],
		["filter_item", "sc-list-container", "ant-picker-dropdown"]
	);

	const handleUpdate = (filter, value, apiValues) => {
		setFilterValues((v) => ({
			...v,
			[filter.field_id]: { ...filter, field_values: value, ...apiValues },
		}));
	};

	const handleRemove = (id) => {
		setFilterValues((v) => _.omit(v, id));
		setResetDone(true);
	};

	useEffect(() => {
		if (filterString) {
			const updatedFilters = filters.filter((f) =>
				f.field_name.toLowerCase().includes(filterString.toLowerCase())
			);
			setVisibleFilters(updatedFilters);
		} else {
			setVisibleFilters(filters);
		}
	}, [filterString, filters]);

	const popup = (
		<List
			onSearch={(value) => setFilterString(value)}
			onApply={() => {
				let updatedFilters = Object.values(filterValues);
				const quickFilters = appliedFilters?.filter(
					(f) => f.filter_category === "quick_filter"
				);
				if (quickFilters?.length > 0) {
					updatedFilters = [...updatedFilters, ...quickFilters];
				}

				isOnlyEdit
					? onSave(updatedFilters)
					: dispatch({
							type: "GET_TABLE_DATA",
							payload: {
								shouldRefresh: true,
								filterBy: updatedFilters,
								entity,
								subEntityData,
								apiProps,
								intID,
							},
						});
				setShow(false);
			}}
			onClear={() => {
				dispatch({
					type: "GET_TABLE_DATA",
					payload: {
						shouldRefresh: true,
						filterBy: [],
						entity,
						subEntityData,
						apiProps,
						intID,
					},
				});
				setShow(false);
			}}
			applyDisabled={_.isEmpty(filterValues)}
		>
			{Array.isArray(visibleFilters) &&
				visibleFilters
					.filter(
						(filter) =>
							!!filter.field_type &&
							filterUIMap[filter.field_type]
					)
					.map((filter) => {
						return {
							...filter,
							...filterUIMap[filter.field_type],
						};
					})
					.map((filter, index) => (
						<OverlayTooltip
							key={index}
							isStickyTooltip
							// show
							alwaysShow={activeIndex === index}
							onHide={() => {
								if (activeIndex === index) setActiveIndex(-1);
								setShow(false);
							}}
							className="filter_item v2-filter-popover"
							placement="auto-start"
							trigger="click"
							isCustomTooltip
							popperConfig={{
								modifiers: [
									{
										name: "arrow",
										options: {
											padding: ({
												popper,
												reference,
												placement,
											}) => {
												if (
													reference.y -
														listContainerRef.current
															.offsetTop <
														60 &&
													reference.y -
														listContainerRef.current
															.offsetTop >
														240
												) {
													setActiveIndex(-1);
												}
												return 0;
											},
										},
									},
								],
							}}
							overlay={
								<div className="d-flex flex-column cursor-pointer mx-2 filter_item">
									<div className="mt-2">
										<Typography
											variant="subheading_3_bold"
											color="secondary_grey_2"
										>
											{filter.field_name}
										</Typography>
									</div>
									<div className=" mt-3">
										{React.createElement(
											filterUIMap[filter.field_type]
												.component,
											{
												value: filterValues[
													filter.field_id
												]?.field_values,
												filterData:
													filterValues[
														filter.field_id
													],
												onChange: handleUpdate.bind(
													null,
													filter
												),
												onRemove: handleRemove.bind(
													null,
													filter.field_id
												),
												filter,
												intID,
												resetDone,
												setResetDone,
												apiProps,
											}
										)}
									</div>
									<Divider type="new" />
									<div
										className="d-flex cursor-pointer my-2"
										onClick={handleRemove.bind(
											null,
											filter.field_id
										)}
									>
										<DropDownButtonTab>
											<DropdownButton>
												<Typography
													variant="button_extrasmall_medium"
													color="button_extrasmall_medium"
												>
													Reset Filter
												</Typography>
											</DropdownButton>
										</DropDownButtonTab>
									</div>
								</div>
							}
						>
							<DropDownButtonTab>
								<DropdownButton
									onClick={() => {
										setActiveIndex(index);
									}}
								>
									<img
										className="mr-1"
										src={
											filterIconMap?.[filter?.icon] ||
											icon_database
										}
									/>

									<Typography
										variant="button_extrasmall_medium"
										color="secondary_grey_2"
									>
										{filter.field_name}
									</Typography>
								</DropdownButton>
							</DropDownButtonTab>
						</OverlayTooltip>
					))}
		</List>
	);

	return (
		<div
			className="d-flex align-items-center"
			ref={listContainerRef}
			style={{ gap: 1 }}
		>
			{/* <Button
				style={{
					fontSize: "10px",
					color: "#717171",
					height: "32px",
					width: isOnlyEdit ? "76px" : "auto",
					...(isOnlyEdit && { borderRadius: 8 }),
				}}
				variant="grey"
				shape={isOnlyEdit ? "default" : "left"}
				onClick={() => setShow((s) => !s)}
			>
				{!isOnlyEdit && (
					<img
						className="mr-1"
						ref={filterIconRef}
						src={filterIcon}
					/>
				)}
				{isOnlyEdit && "+ Add Filter"}
			</Button> */}
			{isOnlyEdit ? (
				<div ref={filterIconRef}>
					<Button
						variant="primary-alternative"
						iconPosition="start"
						startIcon={Icon_Add}
						onClick={() => setShow((s) => !s)}
						ref={filterIconRef}
					>
						Add Filter
					</Button>
				</div>
			) : (
				<div ref={filterIconRef}>
					<IconButton
						theme={V2_DEFAULT_THEME}
						icon={filterIcon}
						onClick={() => setShow((s) => !s)}
						position="left"
						shape="square"
						variant="grey"
					/>
				</div>
			)}
			{appliedFiltersCount !== undefined && !isOnlyEdit && (
				<IconButton
					theme={V2_DEFAULT_THEME}
					icon={arrow_down}
					onClick={toggleAppliedFilters}
					position="right"
					shape="square"
					variant="grey"
					iconText={appliedFiltersCount}
					style={{ height: 32, width: 32, gap: 4 }}
				/>
			)}
			<Overlay
				target={filterIconRef.current}
				show={show}
				placement={"bottom"}
			>
				<Tooltip className="v2-tooltip" show>
					{popup}
				</Tooltip>
			</Overlay>
		</div>
	);
}
