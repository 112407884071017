import React from "react";

import { Link } from "react-router-dom";
import { Typography } from "@zluri/ui-components";

import OptimizeNowPlaybookCell from "../OptimizationV2/OptimizeNowPlaybookCell";
import LongTextTooltip from "modules/shared/components/LongTextTooltip/LongTextTooltip";
import OptimizationSummaryAppCell from "../OptimizationSummary/OptimizationSummaryAppCell";
import OptimizationSummaryToggleLicenseDisplay from "../OptimizationSummary/OptimizationSummaryToggleLicenseDisplay";

import getstartedstep1 from "assets/optimization/getstartedstep1.svg";
import getstartedstep2 from "assets/optimization/getstartedstep2.svg";
import refresh from "assets/icons/refresh.svg";
import upload from "assets/upload.svg";

import { HeaderFormatter } from "modules/licenses/utils/LicensesUtils";
import { kFormatter } from "constants/currency";
import { UTCDateFormatter, getDateDiff } from "utils/DateUtility";
import { capitalizeFirstLetter } from "utils/common";
import { getValueFromLocalStorage } from "utils/localStorage";
import { getHyperLinkMetaData } from "modules/Optimization/utils/OptimizationUtils";
import "modules/Optimization/styles/ApplicationOptimization.css";

const partner = getValueFromLocalStorage("partner");

export const optimizationEntityType = {
	application: "application",
	contract: "contract",
};

export const generateOptimizationStatuses = {
	NOT_GENERATED: "not-generated",
	PENDING: "pending",
	COMPLETED: "completed",
	ERROR: "error",
};

export const optimizationLicenseClassifications = {
	UNASSIGNED: "unassigned",
	LEFT_ORG: "left_org",
	UNUSED: "unused",
	UNDER_USED: "under_used",
	ACTIVELY_USED: "actively_used",
};

export const optimizationFilterTypes = {
	MONTH: "month",
	USAGE: "usage",
};

export const filterStringArrIndex = {
	usage: 1,
	month: 3,
};

export const optimizationFunnelLevels = [
	{
		keyField: optimizationLicenseClassifications.UNASSIGNED,
		title: "Unassigned",
		barColor: "#FFB169",
		description: "The number of licenses not assigned to any user",
	},
	{
		keyField: optimizationLicenseClassifications.LEFT_ORG,
		title: "Undeprovisioned",
		barColor: "#B5D1FD",
		description:
			"Licenses assigned to users who are deprovisioned in the SSO",
	},
	{
		keyField: optimizationLicenseClassifications.UNUSED,
		title: "Unused",
		barColor: "#478CFA",
		filterType: optimizationFilterTypes.MONTH,
		filterIndex: filterStringArrIndex.month,
		description: `Licenses assigned to users for which ${partner?.name} has found no activity the given period`,
	},
	{
		keyField: optimizationLicenseClassifications.UNDER_USED,
		title: "Under Used",
		barColor: "#2266E2",
		filterType: optimizationFilterTypes.USAGE,
		filterIndex: filterStringArrIndex.usage,
		description:
			"Licenses assigned to users with usage percentage is less then the given threshold",
	},
	{
		keyField: optimizationLicenseClassifications.ACTIVELY_USED,
		title: "Actively Used",
		barColor: "#6967E0",
		description: "The number of licenses utilized effectively by the users",
	},
];

export const optimizationForecastBarGraphHeight = "173px";

export const optimizationAmountType = {
	SAVINGS: "savings",
	WASTAGE: "wastage",
};

export const optimizationUsageFilters = [
	{
		label: "< 30% usage",
		value: 30,
	},
	{
		label: "< 40% usage",
		value: 40,
	},
	{
		label: "< 50% usage",
		value: 50,
	},
];

export const optimizationDaysFilters = [
	{
		label: "> 30 days",
		value: 30,
	},
	{
		label: "> 60 days",
		value: 60,
	},
	{
		label: "> 90 days",
		value: 90,
	},
];

export const optimizationFilters = {
	usage: [
		{
			tableLabel: "Usage < 30%",
			label: "< 30% usage",
			configurationLabel: "30 percent",
			configurationValue: 30,
			value: 30,
		},
		{
			tableLabel: "Usage < 40%",
			label: "< 40% usage",
			configurationLabel: "40 percent",
			configurationValue: 40,
			value: 40,
		},
		{
			tableLabel: "Usage < 50%",
			label: "< 50% usage",
			configurationLabel: "50 percent",
			configurationValue: 50,
			value: 50,
		},
	],
	month: [
		{
			tableLabel: "Unused since 30 days",
			label: "> 1 month",
			configurationLabel: "30 days",
			configurationValue: 30,
			value: 1,
		},
		{
			tableLabel: "Unused since 60 days",
			label: "> 2 months",
			configurationLabel: "60 days",
			configurationValue: 60,
			value: 2,
		},
		{
			tableLabel: "Unused since 90 days",
			label: "> 3 months",
			configurationLabel: "90 days",
			configurationValue: 90,
			value: 3,
		},
	],
	discovery_date_exclusion_days_options: [
		{
			configurationLabel: "0 days",
			configurationValue: 0,
		},
		{
			configurationLabel: "30 days",
			configurationValue: 30,
		},
		{
			configurationLabel: "60 days",
			configurationValue: 60,
		},
		{
			configurationLabel: "90 days",
			configurationValue: 90,
		},
	],
};

export const licenseUsersTableMetaData = {
	columns: [
		{ group_name: "user" },
		{ group_name: "usage" },
		{ group_name: "role" },
		{ group_name: "activity" },
		{ group_name: "source" },
	],
};

export const licenseUsersStatuses = [
	"left_org",
	"unused",
	"under_used",
	"actively_used",
];

export const licenseListTooltipKeyHeaderCSS = [
	{
		key: "license_name",
		header: "License",
		style: {
			width: "120px",
			maxWidth: "120px",
			marginRight: "5px",
			whiteSpace: "nowrap",
			overflow: "hidden",
			textOverflow: "ellipsis",
		},
	},
	{
		key: "quantity",
		header: "Qty",
		style: {
			width: "50px",
			marginRight: "5px",
			marginLeft: "5px",
		},
	},
	{
		key: "cost",
		header: "Cost",
		CSS: {
			width: "80px",
			marginLeft: "5px",
		},
	},
];

export const optimizationReduxConstants = {
	LICENSE_USER_PROPERTIES_REQUESTED: "LICENSE_USER_PROPERTIES_REQUESTED",
	LICENSE_USER_PROPERTIES_FETCHED: "LICENSE_USER_PROPERTIES_FETCHED",
	SET_OPTIMIZATION_FILTER: "SET_OPTIMIZATION_FILTER",
	PDF_GENERATION_START: "PDF_GENERATION_START",
	PDF_GENERATION_END: "PDF_GENERATION_END",
	OPTIMIZATION_SUMMARY_REQUESTED: "OPTIMIZATION_SUMMARY_REQUESTED",
	OPTIMIZATION_SUMMARY_FETCHED: "OPTIMIZATION_SUMMARY_FETCHED",
	SET_OPTIMIZATION_SUMMARY_FILTER: "SET_OPTIMIZATION_SUMMARY_FILTER",
	TOGGLE_LICENSE_ROW_DISPLAY: "TOGGLE_LICENSE_ROW_DISPLAY",
	REQUEST_LICENSE_SUMMARY: "REQUEST_LICENSE_SUMMARY",
	FETCH_LICENSE_SUMMARY: "FETCH_LICENSE_SUMMARY",
	REFRESH_LICENSE_SUMMARY: "REFRESH_LICENSE_SUMMARY",
};

export const optimizationDefaultFilter = "usage_30_month_1";

export const reportRegenerateTypes = {
	REFRESH: "REFRESH",
	MODIFY: "MODIFY",
};

export const OptimizationGetStartedSteps = [
	{
		image: getstartedstep1,
		title: `Add licenses and contracts to ${partner?.name}`,
		description: `${partner?.name} needs cost of licenses to calculate potential savings in optimization report. Please add a contract/subscription with the licenses and their costs.`,
		buttonText: (type) =>
			type === optimizationEntityType.contract
				? "Edit Contract"
				: "Add Contract",
		buttonClick: (history, id, type) =>
			history.push(
				type === optimizationEntityType.contract
					? `/contract/edit/${id}`
					: "/contract/new"
			),
	},
	{
		image: getstartedstep2,
		title: "Map licenses to users",
		description:
			"We have found no licenses assigned to any user for the application. Please assign licenses to get accurate potential savings in the report.",
		buttonText: () => "Map Licenses",
		buttonClick: (history, id, type) =>
			history.push(
				type === optimizationEntityType.contract
					? `/licenses/mapping/${id}`
					: "#users"
			),
	},
	{
		title: "Optimize Licenses",
	},
];

export const textForPotentialSavings = "Savings under review";

export const textForRealizedSavings = "Estimated realized savings";

export const optimizationTableColumnTypes = {
	string: { type: "string", formatter: (v) => v || "-" },
	count: { type: "count", formatter: (v) => v || 0 },
	cost: { type: "cost", formatter: (v) => kFormatter(v) },
	boolean: { type: "boolean", formatter: (v) => (!!v ? "Yes" : "No") },
	date: { type: "date", formatter: (v) => (v ? UTCDateFormatter(v) : "-") },
	percentage: {
		type: "percentage",
		formatter: (v) => `${v ? Number(v?.toFixed(2)) : 0}%`,
	},
	application: {
		type: "application",
		formatter: (v, w) => (
			<OptimizationSummaryAppCell
				app_name={w?.app_name}
				app_logo={w?.app_logo}
				app_auth_status={w?.app_state}
				app_id={w?.app_id}
				logo_height="auto"
				logo_width={20}
			/>
		),
	},
	license: {
		type: "license",
		formatter: (v) => <LongTextTooltip maxWidth={160} text={v} />,
	},
	toggle_license_display: {
		type: "toggle_license_display",
		formatter: (v, w) => (
			<OptimizationSummaryToggleLicenseDisplay row={w} />
		),
	},
};

export const optimizationAppsTableColumns = [
	{
		group_name: "app_estimated_wastage",
		group_type: "cost",
		header: "Estimated Wastage",
	},
	{
		group_name: "app_realised_savings",
		group_type: "cost",
		header: "Realised Savings",
	},
	{
		group_name: "app_monthly_potential_savings",
		group_type: "cost",
		header: `${textForPotentialSavings} (monthly)`,
	},
	{
		group_name: "app_annual_potential_savings",
		group_type: "cost",
		header: `${textForPotentialSavings} (annual)`,
	},
	{
		group_name: "app_optimisable_licenses_count",
		group_type: "count",
		header: "Optimizable Licenses",
	},
	{
		group_name: "app_optimised_licenses_count",
		group_type: "count",
		header: "Optimized Licenses",
	},
];

export const optimizationAppUsersTableColumns = [
	{
		group_name: "is_optimisable",
		group_type: "boolean",
		header: "Is User Optimizable?",
	},
	{
		group_name: "date_since_optimisable",
		group_type: "date",
		header: "Date Since Optimizable",
	},
	{
		group_name: "reason",
		group_type: "string",
		header: "Optimizability Reason",
	},
	{
		group_name: "estimated_wastage",
		group_type: "cost",
		header: "Estimated Wastage",
	},
	{
		group_name: "realised_savings",
		group_type: "cost",
		header: "Realised Savings",
	},
	{
		group_name: "monthly_potential_savings",
		group_type: "cost",
		header: `${textForPotentialSavings} (monthly)`,
	},
	{
		group_name: "annual_potential_savings",
		group_type: "cost",
		header: `${textForPotentialSavings} (annual)`,
	},
];

export const optimizationSummaryTableFilterKeys = [
	"usage_30_month_1",
	"usage_30_month_2",
	"usage_30_month_3",
	"usage_40_month_1",
	"usage_40_month_2",
	"usage_40_month_3",
	"usage_50_month_1",
	"usage_50_month_2",
	"usage_50_month_3",
];

export const optimizationSummaryColumns = [
	{
		group_name: "arrow",
		dataField: null,
		group_type: "toggle_license_display",
		headerContainerClassName:
			"optimization_summary_toggle_license_display_header",
		cellContainerClassName:
			"optimization_summary_toggle_license_display_cell",
		notExportable: true,
	},
	{
		group_name: "application",
		dataField: "app_name",
		group_type: "application",
		header: "Application",
		headerClassName: "d-flex",
		cellClassName: "d-flex",
	},
	{
		group_name: "potential_savings",
		dataField: "potential_savings",
		group_type: "cost",
		header: (
			<HeaderFormatter
				text={textForPotentialSavings}
				onClick={() => window.open(optimizationSummaryBlogLink)}
				isSupportUrl={true}
			/>
		),
		exportHeader: textForPotentialSavings,
	},
	{
		group_name: "optimizable_licenses",
		dataField: "optimizable_licenses",
		group_type: "count",
		header: "Optimizable Licenses",
		navigateTo: ({
			history,
			app_id,
			selected_filter = "usage_30_month_1",
			license_id,
			settings,
		}) =>
			history.push(
				`/applications/${app_id}?metaData=${getHyperLinkMetaData(
					optimizationLicenseClassifications.LEFT_ORG,
					selected_filter,
					license_id,
					settings
				)}#users`
			),
	},
	{
		group_name: "undeprovisioned licenses",
		dataField: "left_organisation_licenses",
		group_type: "count",
		header: "Undeprovisioned Licenses",
		headerContainerClassName:
			"optimization_summary_realised_saving_display_cell optimization_summary_table_head_row_cell",
		cellContainerClassName:
			"optimization_summary_realised_saving_display_cell optimization_summary_table_body_row_cell",
		navigateTo: ({
			history,
			app_id,
			selected_filter = "usage_30_month_1",
			license_id,
			settings,
			optimizationReason = "left org",
		}) => {
			history.push(
				`/applications/${app_id}?metaData=${getHyperLinkMetaData(
					optimizationLicenseClassifications.LEFT_ORG,
					selected_filter,
					license_id,
					settings,
					optimizationReason
				)}#users`
			);
		},
	},
	{
		group_name: "unused licenses",
		dataField: "unused_licenses",
		group_type: "count",
		header: "Unused Licenses",
		navigateTo: ({
			history,
			app_id,
			selected_filter = "usage_30_month_1",
			license_id,
			settings,
			optimizationReason = "unused",
		}) => {
			history.push(
				`/applications/${app_id}?metaData=${getHyperLinkMetaData(
					optimizationLicenseClassifications.LEFT_ORG,
					selected_filter,
					license_id,
					settings,
					optimizationReason
				)}#users`
			);
		},
	},

	{
		group_name: "app_cost_per_license",
		dataField: "",
		group_type: "string",
		header: "Cost/License (per month)",
	},
	{
		group_name: "realised_savings",
		dataField: "realised_savings",
		group_type: "cost",
		header: (
			<HeaderFormatter
				text={textForRealizedSavings + " (monthly)"}
				onClick={() => window.open(optimizationSummaryBlogLink)}
				isSupportUrl={true}
			/>
		),
		headerContainerClassName:
			"optimization_summary_realised_saving_display_cell optimization_summary_table_head_row_cell",
		cellContainerClassName:
			"optimization_summary_realised_saving_display_cell optimization_summary_table_body_row_cell",
		exportHeader: `${textForRealizedSavings} (monthly)`,
	},
	{
		group_name: "optimized_licenses",
		dataField: "optimized_licenses",
		group_type: "count",
		header: "Optimized Licenses",
	},
];

export const optimizationSummaryBlogLink =
	"https://support.zluri.com/support/solutions/articles/73000606994";

export const optimizationSummaryMetaCardTypes = [
	// {
	// 	amountKey: "prev_month_estimated_wastage",
	// 	monthType: "past",
	// 	title: "Estimated Wastage",
	// 	pillBackground: "#FFE9E5",
	// 	numberColor: "#BB2915",
	// 	desc: "in the past month",
	// },
	{
		amountKey: "next_month_potential_savings",
		monthType: "upcoming",
		title: textForPotentialSavings,
		pillBackground: "#E8F0FC",
		numberColor: "#2266E2",
		desc: "Monthly cost of licenses that can be reviewed & revoked",
	},
	{
		amountKey: "next_month_realised_savings",
		monthType: "upcoming",
		title: textForRealizedSavings,
		pillBackground: "#E8F0FC",
		numberColor: "#2266E2",
		desc: "Monthly cost of optimizable licenses those have been removed",
	},
];

export const optimizationSummaryLicenseColumn = {
	group_name: "license",
	dataField: "license_name",
	group_type: "license",
	cellClassName: "d-flex",
};

export const optimizationV2TopRowCards = [
	{
		title: "Optimizable Licenses",
		valueKey: "optimizable_license_count",
		valueType: "number",
		percentChangeKey: "optimizable_license_count_change",
		tooltip: "Sum of un-deprovisioned, unused, underused licenses.",
		desc: "Count of unused & un-deprovisioned licenses based on the current configuration",
	},
	// {
	// 	title: "Wastage",
	// 	valueKey: "wastage",
	// 	valueType: "cost",
	// 	percentChangeKey: "wastage_change",
	// 	blog: optimizationSummaryBlogLink,
	// },
	{
		title: textForPotentialSavings,
		valueKey: "potential_savings",
		valueType: "cost",
		percentChangeKey: "potential_savings_change",
		blog: optimizationSummaryBlogLink,
		desc: "Monthly cost of licenses that can be reviewed & revoked",
	},
	{
		title: textForRealizedSavings,
		valueKey: "realised_savings",
		valueType: "cost",
		percentChangeKey: "realised_savings_change",
		blog: optimizationSummaryBlogLink,
		desc: "Monthly cost of optimizable licenses those have been removed",
	},
	{
		title: "Configuration",
	},
];

export const optimizationConfigurationSettingCards = [
	{
		title: "Unused Licenses",
		settingsKey: "unused_licenses_settings",
		thresholdOptions: [
			optimizationFilters.month,
			optimizationFilters.discovery_date_exclusion_days_options,
		],
		thresholdText: [
			"Include users who have not used the application in last",
			"Exclude users who have been discovered within last",
		],
		descriptiveText: [
			"Select the threshold to identify unused licenses",
			"Select the threshold to exclude users from being identified as unused",
		],
		thresholdDropdownMenuClassName:
			"unused_license_threshold_dropdown_menu",
	},
	// {
	// 	title: "Underused Licenses",
	// 	settingsKey: "under_used_licenses_settings",
	// 	thresholdOptions: optimizationFilters.usage,
	// 	thresholdText: "Under used licenses threshold",
	// 	thresholdDropdownMenuClassName:
	// 		"under_used_license_threshold_dropdown_menu",
	// },
	{
		title: "Undeprovisioned Licenses",
		settingsKey: "left_org_licenses_settings",
	},
];

export const optimizationActionOptions = [
	{
		value: "remove_license",
		label: "Remove License",
	},
	{
		value: "downgrade_license",
		label: "Downgrade License",
	},
];

export const optimizationAreaChartDropdownAllOption = {
	license_id: "all",
	license_name: "All",
};

export const optimizationLicenseBreakdownBarCharts = {
	unused: {
		key: "unused",
		titleText: "Unused",
		barColor: "#5ABAFF",
		barChartKey: "unused_licenses_breakup",
		licenseListKey: "unused_wastage_breakdown",
		wastageKey: "unused_total_wastage",
		optimizableLicenseCountKey: "unused_optimizable_licenses_count",
		licenseListCountKey: "unused_licenses_count",
	},
	// under_used: {
	// 	key: "under_used",
	// 	titleText: "Underused",
	// 	barColor: "#1176BE",
	// 	barChartKey: "under_used_licenses_breakup",
	// 	licenseListKey: "under_used_wastage_breakdown",
	// 	wastageKey: "under_used_total_wastage",
	// 	optimizableLicenseCountKey: "under_used_optimizable_licenses_count",
	// 	licenseListCountKey: "under_used_licenses_count",
	// },
	left_org: {
		key: "left_org",
		titleText: "Undeprovisioned",
		barColor: "#82A7ED",
		barChartKey: "left_org_licenses_breakup",
		licenseListKey: "left_org_wastage_breakdown",
		wastageKey: "left_org_total_wastage",
		optimizableLicenseCountKey: "left_org_optimizable_licenses_count",
		licenseListCountKey: "left_organisation_count",
	},
};

export const optimizationLicenseCountAndWastageByUsageStyles = {
	licenseName: {
		fontWeight: 400,
		fontSize: "11px",
		lineHeight: "125%",
		letterSpacing: "0.0025em",
		color: "#222222",
	},
	contractName: {
		fontWeight: 500,
		fontSize: "8px",
		lineHeight: "100%",
		color: "#717171",
	},
	quantity: {
		fontWeight: 700,
		fontSize: "11px",
		lineHeight: "125%",
		letterSpacing: "0.0025em",
		color: "#222222",
	},
	wastage: {
		fontWeight: 700,
		fontSize: "11px",
		lineHeight: "125%",
		letterSpacing: "0.0025em",
		color: "#FFA217",
	},
};

export const optimizationRepeatOptions = {
	never: { label: "Never", value: "never" },
	days: { label: "Daily", value: "days" },
	weeks: { label: "Weekly", value: "weeks" },
	months: { label: "Monthly", value: "months" },
	years: { label: "Yearly", value: "years" },
};

export const optimizationWeekDays = {
	Mon: 1,
	Tue: 2,
	Wed: 3,
	Thu: 4,
	Fri: 5,
	Sat: 6,
	Sun: 7,
};

export const accountTypesSettingsOptions = [
	{ label: "Group", value: "group" },
	{ label: "Service", value: "service" },
	{ label: "External", value: "external" },
];

export const pastOptimizationStyles = {
	run_on: {
		fontWeight: 400,
		fontSize: "11px",
		lineHeight: "125%",
		letterSpacing: "0.0025em",
		color: "#222222",
	},
	type: {
		fontWeight: 400,
		fontSize: "11px",
		lineHeight: "125%",
		letterSpacing: "0.0025em",
		color: "#222222",
	},
	licenses_optimized: {
		fontWeight: 700,
		fontSize: "11px",
		lineHeight: "125%",
		letterSpacing: "0.0025em",
		color: "#222222",
	},
	users_optimized: {
		fontWeight: 700,
		fontSize: "11px",
		lineHeight: "125%",
		letterSpacing: "0.0025em",
		color: "#222222",
	},
	realised_savings: {
		fontWeight: 700,
		fontSize: "11px",
		lineHeight: "125%",
		letterSpacing: "0.0025em",
		color: "#5FCF64",
	},
	licenses_run_on: {
		fontWeight: 700,
		fontSize: "11px",
		lineHeight: "125%",
		letterSpacing: "0.0025em",
		color: "#222222",
	},
	users_run_on: {
		fontWeight: 700,
		fontSize: "11px",
		lineHeight: "125%",
		letterSpacing: "0.0025em",
		color: "#222222",
	},
	completed: {
		fontWeight: 700,
		fontSize: "11px",
		lineHeight: "125%",
		letterSpacing: "0.0025em",
		color: "#222222",
	},
	pending: {
		fontWeight: 700,
		fontSize: "11px",
		lineHeight: "125%",
		letterSpacing: "0.0025em",
		color: "#222222",
	},
	error: {
		fontWeight: 700,
		fontSize: "11px",
		lineHeight: "125%",
		letterSpacing: "0.0025em",
		color: "#222222",
	},
	did_not_run: {
		fontWeight: 700,
		fontSize: "11px",
		lineHeight: "125%",
		letterSpacing: "0.0025em",
		color: "#222222",
	},
};

export const optimizeNowUsersListColumns = [
	{
		group_name: "user",
		formatter: (user) => (
			<Link
				to={`/users/${encodeURI(user.user_id)}#overview`}
				className="text-decoration-none"
			>
				<LongTextTooltip text={user.name || "-"} maxWidth="170px" />
			</Link>
		),
		header: "User",
	},
	{
		group_name: "license",
		formatter: (user) => (
			<LongTextTooltip text={user.license_name || "-"} maxWidth="170px" />
		),
		header: "License",
	},
	{
		group_name: "user_account_type",
		formatter: (user) =>
			capitalizeFirstLetter(user.user_account_type) || "-",
		header: "Account Type",
	},
	{
		group_name: "user_app_last_used",
		formatter: (user) => (
			<div className="d-flex flex-row align-items-center">
				{user?.user_app_last_used
					? `${getDateDiff(
							new Date(),
							new Date(user?.user_app_last_used)
						)} ago`
					: "-"}
			</div>
		),
		header: "Last Used (All Sources)",
	},
	{
		group_name: "user_status",
		formatter: (user) => capitalizeFirstLetter(user?.user_status) || "-",
		header: "User Employment Status",
	},
	{
		group_name: "optimization_type",
		formatter: (user) =>
			capitalizeFirstLetter(user.optimization_type) || "-",
		header: "Optimization Type",
	},
	// {
	// 	group_name: "wastage",
	// 	formatter: (user) => kFormatter(user.wastage),
	// 	header: "Wastage here",
	// },
	// {
	// 	group_name: "action",
	// 	formatter: (user) =>
	// 		user.action
	// 			? capitalizeFirstLetter(user.action).replaceAll("_", " ")
	// 			: "-",
	// 	header: "Action",
	// },
	{
		group_name: "playbook",
		formatter: (user, users, setUsers, checked, setChecked) => (
			<OptimizeNowPlaybookCell
				user={user}
				users={users}
				setUsers={setUsers}
				checked={checked}
				setChecked={setChecked}
			/>
		),
		header: "Playbook",
	},
];

export const pastOptimizationUsersListColumns = [
	{
		group_name: "user",
		formatter: (user, appId) => {
			const meta = {
				columns: [],
				filter_by: [
					{
						field_id: "user_id",
						field_name: "User Id",
						field_values: [user.user_id],
						filter_type: "objectId",
						negative: false,
						is_custom: false,
					},
					{
						field_values: user.user_archive,
						field_id: "user_archive",
						filter_type: "boolean",
						field_name: "User Archive",
						negative: false,
						is_custom: false,
					},
				],
				sort_by: [],
			};
			return user.name === "Deleted User" ? (
				<LongTextTooltip text={user.name || "-"} maxWidth="170px" />
			) : (
				<Link
					to={`/applications/${encodeURI(
						appId
					)}?metaData=${JSON.stringify(meta)}#users`}
					className="text-decoration-none"
				>
					<LongTextTooltip text={user.name || "-"} maxWidth="170px" />
				</Link>
			);
		},
		header: "User",
	},
	{
		group_name: "email",
		formatter: (user) => (
			<LongTextTooltip text={user.email || "-"} maxWidth="170px" />
		),
		header: "Email",
	},
	{
		group_name: "license",
		formatter: (user) => (
			<LongTextTooltip text={user.license_name || "-"} maxWidth="170px" />
		),
		header: "License",
	},
	{
		group_name: "account_type",
		formatter: (user) => capitalizeFirstLetter(user.account_type) || "-",
		header: "Account Type",
	},
	{
		group_name: "optimization_type",
		formatter: (user) =>
			capitalizeFirstLetter(user.optimization_type) || "-",
		header: "Optimization Reason",
	},
	{
		group_name: "playbook_name",
		formatter: (user) => (
			<LongTextTooltip
				text={user.playbook_name || "-"}
				maxWidth="170px"
			/>
		),
		header: "Playbook",
	},
	{
		group_name: "playbook_status",
		formatter: (user) => user.playbook_status || "-",
		header: "Playbook Status",
	},
	{
		group_name: "action",
		formatter: (user) => user.action || "-",
		header: "User Action",
	},
];

export const optimizationV2ReduxConstants = {
	TOP_ROW_REQUESTED: "OPTIMIZATION_TOP_ROW_REQUESTED",
	TOP_ROW_FETCHED: "OPTIMIZATION_TOP_ROW_FETCHED",
	CONFIGURATION_REQUESTED: "CONFIGURATION_REQUESTED",
	CONFIGURATION_FETCHED: "CONFIGURATION_FETCHED",
	CONFIGURATION_UPDATED: "CONFIGURATION_UPDATED",
	CONFIGURATION_SAVED: "CONFIGURATION_SAVED",
	LICENSE_TREND_REQUESTED: "LICENSE_TREND_REQUESTED",
	LICENSE_TREND_FETCHED: "LICENSE_TREND_FETCHED",
	BAR_CHART_SECTION_REQUESTED: "BAR_CHART_SECTION_REQUESTED",
	BAR_CHART_SECTION_FETCHED: "BAR_CHART_SECTION_FETCHED",
	REPORT_REQUESTED: "REPORT_REQUESTED",
	REPORT_FETCHED: "REPORT_FETCHED",
	REPORT_REFRESHED: "REPORT_REFRESHED",
	OPTIMIZE_NOW_USERS_LIST_REQUESTED: "OPTIMIZE_NOW_USERS_LIST_REQUESTED",
	OPTIMIZE_NOW_USERS_LIST_FETCHED: "OPTIMIZE_NOW_USERS_LIST_FETCHED",
	OPTIMIZE_NOW_USERS_UPDATED: "OPTIMIZE_NOW_USERS_UPDATED",
	OPTIMIZE_NOW_META_REQUESTED: "OPTIMIZE_NOW_META_REQUESTED",
	OPTIMIZE_NOW_META_FETCHED: "OPTIMIZE_NOW_META_FETCHED",
	PAST_OPTIMIZATION_USERS_REQUESTED: "PAST_OPTIMIZATION_USERS_REQUESTED",
	PAST_OPTIMIZATION_USERS_FETCHED: "PAST_OPTIMIZATION_USERS_FETCHED",
	PAST_OPTIMIZATION_META_REQUESTED: "PAST_OPTIMIZATION_META_REQUESTED",
	PAST_OPTIMIZATION_META_FETCHED: "PAST_OPTIMIZATION_META_FETCHED",
	PAST_OPTIMIZATION_LIST_REQUESTED: "PAST_OPTIMIZATION_LIST_REQUESTED",
	PAST_OPTIMIZATION_LIST_FETCHED: "PAST_OPTIMIZATION_LIST_FETCHED",
	REALIZED_SAVINGS_LIST_REQUESTED: "REALIZED_SAVINGS_LIST_REQUESTED",
	REALIZED_SAVINGS_LIST_FETCHED: "REALIZED_SAVINGS_LIST_FETCHED",
	REALIZED_SAVINGS_USERS_LIST_REQUESTED:
		"REALIZED_SAVINGS_USERS_LIST_REQUESTED",
	REALIZED_SAVINGS_USERS_LIST_FETCHED: "REALIZED_SAVINGS_USERS_LIST_FETCHED",
	REALIZED_SAVINGS_TOTAL_USERS_REQUESTED:
		"REALIZED_SAVINGS_TOTAL_USERS_REQUESTED",
	REALIZED_SAVINGS_TOTAL_USERS_FETCHED:
		"REALIZED_SAVINGS_TOTAL_USERS_FETCHED",
};

export const pastOptimizationActions = {
	foregoLicense: "Forego License",
	keepLicense: "Keep License",
};

// optmization v3 constats

export const legendSymbols = [
	{
		text: "Unused",
		color: "#9EC1FA",
	},
	{
		text: "Left Org",
		color: "#BBAFED",
	},
];

export const optimizationV3ReduxConstants = {
	OPTIMIZATION_LICENSE_TREND_REQUESTED:
		"OPTIMIZATION_LICENSE_TREND_REQUESTED",
	OPTIMIZATION_LICENSE_TREND_FETCHED: "OPTIMIZATION_LICENSE_TREND_FETCHED",
};

export const previousOptimizationV3QuickFiltersMockData = [
	{
		field_id: "workflow_status",
		field_name: "Completed",
		field_values: ["completed"],
		field_order: "contains",
		filter_type: "search_in_string",
		negative: false,
	},
	{
		field_id: "workflow_status",
		field_name: "In Progress",
		field_values: ["pending"],
		field_order: "contains",
		filter_type: "search_in_string",
		negative: false,
	},
	{
		field_id: "workflow_status",
		field_name: "Failed",
		field_values: ["failed"],
		field_order: "contains",
		filter_type: "search_in_string",
		negative: false,
	},
];

export const pastOptimizationV3LogsQuickFiltersMockData = [
	{
		field_id: "type",
		field_name: "Continuous",
		field_values: ["Continuous"],
		field_order: "contains",
		filter_type: "search_in_string",
		negative: false,
	},
	{
		field_id: "type",
		field_name: "One-Time",
		field_values: ["One-Time"],
		field_order: "contains",
		filter_type: "search_in_string",
		negative: false,
	},
];

export const SHOW_MORE_OPTIONS = [
	{
		id: "REFRESH_CHART",
		icon: (
			<img
				className="z_optimization_refresh_chart"
				src={refresh}
				alt=""
			/>
		),
		label: (
			<Typography variant="button_extrasmall_medium">Refresh</Typography>
		),
		filter: "",
		text: "Refresh",
	},
	{
		id: "DOWNLOAD_AS",
		icon: <img src={upload} alt="" />,
		label: (
			<Typography variant="button_extrasmall_medium">
				Download As
			</Typography>
		),
		filter: "",
		text: "Download As",
		children: [
			{
				id: "DOWNLOAD_AS_PNG",
				key: 1 - 1,
				label: (
					<Typography variant="button_extrasmall_medium">
						Download As PNG
					</Typography>
				),
			},
			{
				id: "DOWNLOAD_AS_PDF",
				key: 1 - 2,
				label: (
					<Typography variant="button_extrasmall_medium">
						Download As PDF
					</Typography>
				),
			},
			{
				id: "DOWNLOAD_AS_CSV",
				key: 1 - 3,
				label: (
					<Typography variant="button_extrasmall_medium">
						Download As CSV
					</Typography>
				),
			},
		],
	},
];

export const playbookOptimizationV3StatusOptions = {
	completed: {
		pillColor: "#009307",
		pillBackGround: "#E7F8E8",
	},
	failed: {
		pillColor: "#BB2915",
		pillBackGround: "#FFE9E5",
	},
	pending: {
		pillColor: "#C97B07",
		pillBackGround: "#FFF6DC",
	},
	didnotrun: {
		pillColor: "#222222",
		pillBackGround: "#ebebeb",
	},
};

export const OptimizationTableColumnGroups = {
	APPLICATIONS: "applications",
	APPLICATION_USERS: "application_users",
	OPTIMIZATION_SUMMARY: "optimization_summary",
	OPTIMIZE_NOW_USERS_LIST: "optimize_now_users_list",
	PAST_OPTIMIZATION_USERS_LIST: "past_optimization_users_list",
	OPTIMIZATION_SUMMARY_LICENSE_BREAKDOWN:
		"optimization_summary_license_breakdown",
	REALIZED_SAVING_USERS_LIST: "realized_saving_users_list",
};

export const realizedSavingUsersListColumns = [
	{
		group_name: "end_date",
		formatter: (user) => UTCDateFormatter(user.end_date) || "-",
		header: "License revocation date",
	},
	{
		group_name: "user_name",
		formatter: (user) => {
			const meta = {
				columns: [],
				filter_by: [
					{
						field_id: "user_id",
						field_name: "User Id",
						field_values: [user.user_id],
						filter_type: "objectId",
						negative: false,
						is_custom: false,
					},
					{
						field_values: user.user_archive,
						field_id: "user_archive",
						filter_type: "boolean",
						field_name: "User Archive",
						negative: false,
						is_custom: false,
					},
				],
				sort_by: [],
			};
			return (
				<Link
					to={`/applications/${encodeURI(
						user.org_app_id
					)}?metaData=${JSON.stringify(meta)}#users`}
					className="text-decoration-none"
				>
					<LongTextTooltip
						text={user.user_name || "-"}
						maxWidth="130px"
					/>
				</Link>
			);
		},
		header: "User",
	},
	{
		group_name: "user_email",
		formatter: (user) => (
			<LongTextTooltip text={user.user_email || "-"} maxWidth="130px" />
		),
		header: "Email",
	},
	{
		group_name: "license_name",
		formatter: (user) => (
			<LongTextTooltip text={user.license_name || "-"} maxWidth="130px" />
		),
		header: "License",
	},
	{
		group_name: "user_account_type",
		formatter: (user) =>
			capitalizeFirstLetter(user.user_account_type) || "-",
		header: "Account Type",
	},
	{
		group_name: "optimization_reason",
		formatter: (user) =>
			capitalizeFirstLetter(user.optimization_reason) || "-",
		header: "Optimization Reason",
	},
	{
		group_name: "date_since_optimizable",
		formatter: (user) =>
			UTCDateFormatter(user.date_since_optimizable) || "-",
		header: "Date since optimizable",
	},
	{
		group_name: "realised_savings",
		formatter: (user) => kFormatter(user.realised_savings),
		header: textForRealizedSavings,
	},
];
