import React from "react";
import Papa from "papaparse";
import { Spinner } from "react-bootstrap";
import { desanitizeCSVCell } from "utils/common";
import { uploadFile } from "services/upload/upload";
import { processBulkUpload } from "modules/BulkUpload/service/BulkUpload.api";
import { validateBulkUploadCSV } from "modules/BulkUpload/service/BulkUpload.api";
import DocumentUpload from "modules/shared/components/DocumentUpload/DocumentUpload";
import { bulkContractUploadCSVHeaders } from "modules/licenses/constants/LicenseConstants";
import { ApiResponseNotification } from "modules/shared/components/ApiResponseNotification/ApiResponseNotification";
import { apiResponseTypes } from "modules/shared/components/ApiResponseNotification/ApiResponseNotificationConstants";

export default function ContractBulkUploadCSVImport({
	setInvalidRows,
	setUploading,
	setUploaded,
	handleClose,
	setFile,
}) {
	const callProcessBulkUpload = (file) => {
		uploadFile(file)
			.then((res) => {
				processBulkUpload({
					type: "contract",
					files: [
						{
							file_type: "csv",
							file_name: file.name,
							source_url: res.resourceUrl,
						},
					],
				})
					.then((res) => {
						ApiResponseNotification({
							responseType: apiResponseTypes.SUCCESS,
							title: "Contract Bulk Upload has been initiated!",
						});
						setFile();
						setUploading(false);
						setUploaded(true);
						handleClose();
					})
					.catch((error) => {
						ApiResponseNotification({
							responseType: apiResponseTypes.ERROR,
							title: "Server Error! The file could not be uploaded. Try Again!",
							errorObj: error,
						});
						setUploading(false);
						setUploaded(false);
					});
			})
			.catch((error) => {
				ApiResponseNotification({
					responseType: apiResponseTypes.ERROR,
					title: "Server Error! The file could not be uploaded. Try Again!",
					errorObj: error,
				});
				setUploading(false);
				setUploaded(false);
			});
	};

	const readUploadedFile = (file) => {
		setUploading(true);
		setFile(file);
		Papa.parse(file, {
			header: true,
			skipEmptyLines: true,
			comments: "#",
			complete: (results) => {
				const missingHeaders = [];
				const wrongHeaders = [];

				const csvHeaders = [...results.meta.fields];

				for (const header of csvHeaders) {
					if (!bulkContractUploadCSVHeaders.includes(header)) {
						wrongHeaders.push(header);
					}
				}

				for (const header of bulkContractUploadCSVHeaders) {
					if (!csvHeaders.includes(header)) {
						missingHeaders.push(header);
					}
				}

				if (missingHeaders.length > 0 || wrongHeaders.length > 0) {
					ApiResponseNotification({
						responseType: apiResponseTypes.ERROR,
						title: "Invalid/Missing Headers",
						description: `The uploaded CSV has invalid/missing headers!`,
					});
					setUploading(false);
					setUploaded(false);
					return;
				}

				const csvData = [...results?.data];
				for (const row of csvData) {
					for (const key in row) {
						row[key] = desanitizeCSVCell(row[key]);
					}
				}

				validateBulkUploadCSV({
					data: csvData,
					type: "contract",
				})
					.then((res) => {
						if (!res.success) {
							setInvalidRows([...res.data]);
							setUploading(false);
							setUploaded(true);
						} else {
							callProcessBulkUpload(file);
						}
					})
					.catch((err) => {
						ApiResponseNotification({
							responseType: apiResponseTypes.ERROR,
							title: `Server Error! The CSV could not be validated. Please try again.`,
							errorObj: err,
						});
						setUploading(false);
						setUploaded(false);
					});
			},
		});
	};

	return (
		<DocumentUpload
			acceptedFileArray={[".csv"]}
			loader={
				<div className="primary_bg_2 border-radius-8 padding_4 font-10 bold-500 font-white">
					<Spinner
						animation="border"
						role="status"
						variant="dark"
						size="sm"
						style={{ borderWidth: 2 }}
					/>
				</div>
			}
			getSourceUrl={false}
			readUploadedFile={readUploadedFile}
		>
			<div className="primary_bg_2 border-radius-8 padding_4 font-10 bold-500 font-white">
				Upload CSV
			</div>
		</DocumentUpload>
	);
}
