import React from "react";
import {
	BODY_VARIANT,
	Tooltip as ToolTip,
	Typography,
} from "@zluri/ui-components";

export const HeaderFormatterOverLineToolTip = ({
	main_text,
	descriptive_text,
}) => {
	return main_text ? (
		<>
			{main_text}
			{descriptive_text ? (
				<div
					className="info-icon-overview-v2"
					style={{
						margin: "0px 13px",
						height: "18px",
						width: "2px",
						paddingBottom: "4px",
						borderRadius: "50%",
						display: "inline-block",
					}}
				>
					<ToolTip
						content={
							<Typography
								variant={BODY_VARIANT.BODY_2_REGULAR}
								color="white"
							>
								{descriptive_text}
							</Typography>
						}
						placement="top"
						className="border-radius-8"
					>
						<p style={{ marginTop: "-7.5px", marginLeft: "-8px" }}>
							<span
								style={{
									color: "#717171",
									fontSize: "12px",
									fontWeight: "bold",
								}}
							>
								i
							</span>
						</p>
					</ToolTip>
				</div>
			) : null}
		</>
	) : null;
};
