export const RESTRICTION_CONSTANTS = {
	SHOW_UPGRADE_MODAL: "SHOW_UPGRADE_MODAL",
	HIDE_UPGRADE_MODAL: "HIDE_UPGRADE_MODAL",
};

export const ENTITIES = {
	OVERVIEW: "overview",
	CONTRACTS: "contracts",
	APPLICATIONS: "apps",
	TRANSACTIONS: "transactions",
	COST_CENTERS: "cost centers",
	PAYMENT: "payment",
	USERS: "users",
	DEPARTMENT: "department",
	RECOMANDATIONS: "recomandations",
	INTEGRATIONS: "integrations",
	RENEWALS: "renewals",
	CUSTOM_FIELDS: "custom_fields",
	ADMINISTRATION: "administration",
	USER_APPS: "user#applications",
	APPLICATION_USERS: "application#users",
	APPLICATION_LICENSES: "application#licenses",
	APPLICATION_MAP_LICENSES: "application#map_licenses",
	APPLICATION_CONTRACTS: "application#contracts",
	APPLICATION_TRANSACTIONS: "application#spends",
	APPLICATION_OPTIMIZATION: "application#optimization",
	APPLICATION_RECOMMANDATIONS: "application#recommandations",
	DEPARTMENT_APPLICATIONS: "department#application",
	DEPARTMENT_USERS: "department#users",
	EXPORT_CSV: "export_csv",
	ASSIGN_PAYMENT: "assign_payment",
	ASSIGN_APPLICATION: "assign_applications",
	UPLOADS: "uploads",
	SETTINGS: "settings",
	SUBSCRIPTION: "subscription",
	PERPETUAL: "perpetual",
	LICENSES: "licenses",
	WORKFLOWS: "workflows",
};
