import React from "react";
import PropTypes from "prop-types";
import {
	BLUE_VARIANT,
	BODY_VARIANT,
	GREY_VARIANT,
	Typography,
} from "@zluri/ui-components";

import { ComplianceList } from "../../SecurityCompliance/ComplianceList/ComplianceList";
import { Button } from "UIComponents/Button/Button";

import "./styles.css";

const AppComplianceToolTipContent = ({ header, body, button }) => (
	<div>
		<div className="white-bg position-relative border-radius-8 app-about-compliance-popover-card">
			<div className="white-blue-bg overflow-hidden w-100 d-flex justify-content-between align-items-center p-2 app-about-compliance-popover-header">
				{header}
			</div>
			<div className="font-9 px-2 d-flex flex-column">{body}</div>
			<div className="w-100 p-2">{button}</div>
		</div>
		<div className="position-relative w-100 h-100 top-0 left-0 bg-primary z-100" />
	</div>
);

AppComplianceToolTipContent.propTypes = {
	header: PropTypes.element,
	body: PropTypes.element,
	button: PropTypes.element,
};

export function AppComplianceClickPopover({ data: { complianceData } }) {
	const handleViewAllCompliancesClick = () => {
		const securityComplianceHash = "#security_compliance";
		const currentHash = window.location.hash;

		if (currentHash !== securityComplianceHash) {
			window.location.hash = securityComplianceHash;
		}
	};

	return (
		<AppComplianceToolTipContent
			header={
				<Typography
					color={GREY_VARIANT.SECONDARY_GREY_2}
					variant={BODY_VARIANT.BODY_2_BOLD}
				>
					Compliances
				</Typography>
			}
			body={<ComplianceList complianceData={complianceData} />}
			button={
				<Button
					onClick={handleViewAllCompliancesClick}
					className="w-100 p-2 blue_tint_bg border-radius-8 header__overlay_btn"
				>
					<Typography
						color={BLUE_VARIANT.PRIMARY_BLUE_DARK_1}
						variant={BODY_VARIANT.BODY_3_REGULAR}
					>
						View all Compliances
					</Typography>
				</Button>
			}
		/>
	);
}

AppComplianceClickPopover.propTypes = {
	data: PropTypes.shape({
		complianceData: PropTypes.array,
	}),
};
