import { Typography, Tooltip } from "@zluri/ui-components";
import React from "react";
import cn from "classnames";
import Icon from "components/Icon";
import "./license.scss";

function LicenseNameCell({ component, data, ...props }) {
	const value = data?.[component?.valueKey];

	return (
		<div className="flex license_name_cell_container">
			<Tooltip
				placement="top"
				content={
					<div className="d-flex align-items-center px-1 py-1">
						<Typography
							variant="alerttag_small_semibold"
							color="white"
							className="ml-1"
						>
							{value}
						</Typography>
					</div>
				}
			>
				<Typography
					variant={"body_1_regular"}
					className="ellipsize-typography flex-1"
					color="secondary_grey_1"
				>
					{value}
				</Typography>
			</Tooltip>

			{data?.integration_id && (
				<Tooltip
					placement="top"
					content={
						<div className="d-flex align-items-center px-1 py-1">
							<Typography
								variant="alerttag_small_semibold"
								color="white"
								className="ml-1"
							>
								Detected from integration
							</Typography>
						</div>
					}
				>
					<div className="license_name_cell_icon">
						<Icon name="data" color="#1176BE" />
					</div>
				</Tooltip>
			)}
		</div>
	);
}

const benchmarkIconMapper = {
	"below benchmark": "low-risk-level",
	"above benchmark": "high-risk-level",
	"fair price": "medium-risk-level",
	unavailable: "unavailable-risk-level",
};

export function CostBenchmarkCell({ component, data, ...props }) {
	const value = data?.[component?.valueKey] ?? "unavailable";

	const icon = benchmarkIconMapper[value];
	return (
		<div className="flex gap-2 align-items-center">
			<Icon name={icon} />
			<Typography
				variant={"body_1_regular"}
				color="secondary_grey_1 text-capitalize"
				className="ellipsize-typography"
			>
				{value}
			</Typography>
		</div>
	);
}

export default LicenseNameCell;
