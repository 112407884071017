import GetImageOrNameBadge from "common/GetImageOrNameBadge";
import React from "react";
import { capitalizeFirstLetter, kFormatter } from "utils/common";
import { getChangeStatusIcon } from "./ChangeNumber";
import { TagsRenderer } from "./tags";
import { AppCategoryDescription, PrimaryTextRenderer } from "./text";
import decrement from "assets/v2tables/decrement.svg";
import IncrementIcon from "assets/v2tables/Icon_Arrow_Up.svg";
import DecrementIcon from "assets/v2tables/Icon_Arrow_Down.svg";
import { Link } from "react-router-dom";
import credit_card from "assets/v2tables/credit_card.svg";
import { getImageForPaymentMethodDropdownNew } from "components/Transactions/Recognised/Dropdown";
import {
	Currency,
	CurrencySymbol,
	Divider,
	NameBadge,
	Tooltip,
	Typography,
} from "@zluri/ui-components";
import { getShortHandFromNow } from "utils/DateUtility";

export function ImageCellRenderer({ component, data }) {
	return (
		<div style={component.style}>
			<img height={24} width="auto" src={data[component.valueKey]} />
		</div>
	);
}

//TODO extract to separate file
export function ViewMoreRenderer({ component, data }) {
	if (data[component.valueKey]?.length > (component.count || 3)) {
		return (
			<div className="view_more_button">
				<Typography variant="body_1_medium" color="primary_blue_dark_1">
					+ {data[component.valueKey].length - (component.count || 3)}
				</Typography>
			</div>
		);
	}
	return null;
}

//v1Url is for the links that needs to route without `/v2` prefix,
function LinkOrDiv({ to, as, v1Url, ...otherProps }) {
	if (as === "div") return <div {...otherProps} />;

	if (v1Url) {
		// Since the basename for router history is changed to v2 whenever the url changes to '/v2' prefixed routes, it becomes impossible to route to v1 urls with Link (react-router-dom v5)
		// So for those v1 routes using a tag
		return (
			<a
				href={v1Url}
				{...otherProps}
				aria-label={otherProps?.["aria-label"]}
				style={{ textDecoration: "none", ...(otherProps?.style ?? {}) }}
			/>
		);
	}
	if (!to) throw new Error("Link element should have `to` prop");
	return (
		<Link
			href={to}
			{...otherProps}
			style={{ textDecoration: "none", ...(otherProps?.style ?? {}) }}
		/>
	);
}

//TODO extract to separate file
export function ImageListCellRenderer({ component, data, clickComponent }) {
	return (
		<>
			{(!data[component.valueKey] || !data[component.valueKey]?.length) &&
				"-"}
			<div
				className="cursor-pointer"
				style={{ display: "flex", alignItems: "center", gap: "4px" }}
				// onClick={() => component.isClickable && handleClick()}
			>
				{data[component.valueKey]?.slice(0, 3).map((obj, index) =>
					obj ? (
						<div
							key={index}
							className={`source-icon-list ${
								component?.imageClassName || ""
							}`}
						>
							<Tooltip
								placement="bottom"
								content={
									<div className="px-1">
										<Typography
											variant="body_2_regular"
											color="white"
										>
											{capitalizeFirstLetter(
												obj[component.nameKey] ||
													obj.name ||
													obj.keyword
											)}
										</Typography>
									</div>
								}
							>
								{obj.logo && obj.keyword !== "manual" ? (
									<div>
										<img
											className="img-circle"
											key={index}
											width={20}
											alt={obj.logo}
											src={
												obj[component.imageKey] ||
												obj.logo
											}
										></img>
									</div>
								) : obj.keyword ? (
									<div
										style={{
											background: "#E1E8F8",
											borderRadius: "50%",
											width: "20px",
											textAlign: "center",
											height: "20px",
											fontSize: "11px",
											color: "#2266E2",
											justifyContent: "center",
										}}
										className="d-flex align-items-center"
									>
										M
									</div>
								) : (
									<div>
										<GetImageOrNameBadge
											width={20}
											height={20}
											name={
												obj[component.nameKey] ||
												obj.name
											}
										/>
									</div>
								)}
							</Tooltip>
						</div>
					) : (
						<div key={index}>"-"</div>
					)
				)}
			</div>
		</>
	);
}
export function PrimarySourceCellRenderer({ component, data }) {
	let primarySource =
		data?.[component.valueKey] && Array.isArray(data?.[component.valueKey])
			? data?.[component.valueKey].filter((item) => item.is_primary)
			: null;

	primarySource = primarySource ? primarySource?.[0] : null; //Only first one if many

	return (
		<>
			{!primarySource && "-"}
			<div
				className="cursor-pointer"
				style={{ display: "flex", alignItems: "center", gap: "4px" }}
			>
				<div
					className={`source-icon-list ${
						component?.imageClassName || ""
					}`}
				>
					<Tooltip
						placement="bottom"
						content={
							<div className="px-1">
								<Typography
									variant="body_2_regular"
									color="white"
								>
									{capitalizeFirstLetter(
										primarySource.name ||
											primarySource.keyword
									)}
								</Typography>
							</div>
						}
					>
						{primarySource.logo &&
						primarySource.keyword !== "manual" ? (
							<div>
								<img
									className="img-circle"
									width={20}
									alt={primarySource.logo}
									src={
										primarySource[component.imageKey] ||
										primarySource.logo
									}
								></img>
							</div>
						) : primarySource.keyword ? (
							<div
								style={{
									background: "#E1E8F8",
									borderRadius: "50%",
									width: "20px",
									textAlign: "center",
									height: "20px",
									fontSize: "11px",
									color: "#2266E2",
									justifyContent: "center",
								}}
								className="d-flex align-items-center"
							>
								M
							</div>
						) : (
							<div>
								<GetImageOrNameBadge
									width={20}
									height={20}
									name={
										primarySource[component.nameKey] ||
										primarySource.name
									}
								/>
							</div>
						)}
					</Tooltip>
				</div>
			</div>
		</>
	);
}

//TODO extract to separate file
export function ActiveUserPopOverRenderer({ component, data, ...props }) {
	let licenseData = [
		{
			name: "Unused Licenses",
			value: "100",
		},
		{
			name: "Underused Licenses",
			value: "50",
		},
		{
			name: "Underprovisioned Licenses",
			value: "100",
		},
	];
	return (
		<div>
			<div className="v2table__sources-header">
				<div className="v2table__sources-header-change_text">
					<img width={4} height={8} src={decrement} />
					<div>{`51 (12%)`}</div>
					<div className="v2table__graytext">{`in last 4w`}</div>
				</div>
			</div>
			{data.source_array?.map((item, index) => (
				<ActionUserOption item={item} index={index} data={data} />
			))}
		</div>
	);
}

export function ActiveUserIntegrationPopOverRenderer({ component, data }) {
	const userChange = data[component.user_change_key];
	const userChangePercentage = data[component.user_change_percentage_key];
	const userSources = data[component.user_sources_key] ?? [];
	return (
		<div>
			<div className="v2table__popover-header">
				<div className="v2table__sources-header-change_text px-2">
					<img
						src={userChange > 0 ? IncrementIcon : DecrementIcon}
						alt={
							userChange > 0 ? "Increment Icon" : "Decrement Icon"
						}
					/>
					<Typography
						variant="alerttag_regular_semibold"
						color={
							userChange > 0 ? "accent_green_1" : "accent_red_1"
						}
					>
						{userChange} ({userChangePercentage}%)
					</Typography>
					<Typography
						variant="alerttag_regular_medium"
						color="secondary_grey_3"
					>
						this month
					</Typography>
				</div>
			</div>
			{/*had to do this because backend was sometimes sending object for this weirdly*/}
			{Array.isArray(userSources) &&
				userSources?.map((item, index) => (
					<ActionUserOptionIntegration
						item={item}
						index={index}
						key={item._id}
						sourcesLength={userSources.length}
					/>
				))}
		</div>
	);
}

function ActionUserOptionIntegration({ item, index, sourcesLength }) {
	return (
		<>
			{item && (item.name || item.keyword === "manual") && (
				<>
					<div className="" key={index}>
						<div className="d-flex flex-column w-100">
							<div
								className="d-flex px-2 justify-content-between align-items-center"
								style={{ margin: "9px 0px" }}
							>
								<div className="d-flex align-items-center">
									{item.logo && item.keyword !== "manual" ? (
										<img
											alt=""
											width={16}
											height={16}
											src={item.logo}
											className="rounded-circle"
										/>
									) : (
										<NameBadge
											name="M"
											height="16px"
											width="16px"
											fontSize={10}
											fontColor="#2266E2"
										/>
									)}
									<div className="d-flex flex-column ml-2">
										<Typography
											variant="body_2_bold"
											color="secondary_grey_2"
										>
											{item.name ? item.name : "Manual"}
										</Typography>
									</div>
								</div>
								<div>
									<Typography
										variant="body_2_bold"
										color="secondary_grey_2"
									>
										{item.user_count}
									</Typography>
								</div>
							</div>
							{item.sources?.length > 0 && (
								<>
									<hr style={{ margin: "0 6px" }} />
									<div>
										{item.sources?.map((source, idx) => (
											<div key={source._id}>
												<div
													className="d-flex justify-content-between"
													style={{
														padding: "9px 8px",
													}}
												>
													<div className="d-flex flex-column">
														<div
															className="d-flex align-items-center"
															style={{
																marginLeft: 24,
															}}
														>
															<Typography
																variant="body_2_regular"
																color="secondary_grey_2"
															>
																{source.name
																	? source.name
																	: "Manual"}
															</Typography>
														</div>
													</div>
													<Typography
														variant="body_2_bold"
														color="secondary_grey_2"
													>
														{item.users || 100}
													</Typography>
												</div>
												{idx !==
													item.sources?.length -
														1 && (
													<Divider
														backgroundColor="#EBEBEB"
														borderRadius={8}
														height={1}
														width="100%"
														className="mx-1"
													/>
												)}
											</div>
										))}
									</div>
								</>
							)}
						</div>
					</div>
					{index !== sourcesLength - 1 && (
						<Divider
							backgroundColor="#EBEBEB"
							borderRadius={8}
							height={1}
							width="100%"
						/>
					)}
				</>
			)}
		</>
	);
}

function ActionUserOption({ item, index, data }) {
	return (
		<>
			{item && (item.name || item.keyword === "manual") && (
				<>
					<div className="v2table__sources__source_item" key={index}>
						<div
							className="d-flex align-items-center"
							style={{ gap: "8px" }}
						>
							{item.logo && item.keyword !== "manual" ? (
								<img
									height={20}
									width={20}
									src={item.logo}
									alt={item.name}
								/>
							) : (
								<div
									style={{
										width: "20px",
										textAlign: "center",
										height: "20px",
										fontSize: "11px",
										color: "#2266E2",
									}}
								>
									M
								</div>
							)}
							<div className="d-flex flex-column">
								<div className="font-10 d-flex align-items-center">
									{item.name ? item.name : "Manual"}
								</div>
								<PrimaryTextRenderer />
							</div>
						</div>
						<div className="v2table__graytext">
							{item.users || 100}
						</div>
					</div>
					{index !== data.source_array?.length - 1 && (
						<hr style={{ margin: "0 4px" }} />
					)}
				</>
			)}
		</>
	);
}

export function SourcePopOverRenderer({ component, data, ...props }) {
	const value = data[component.valueKey];

	if (!value || value.length === 0) return null;
	return value?.map((item, index) => {
		if (!item) return "-";

		const isLastElement = index === data[component.valueKey]?.length - 1;
		return (
			(item.name ||
				item.keyword === "manual" ||
				item.integration_name) && (
				<>
					<div
						style={{
							backgroundColor: item.is_error ? "#F6F7FA" : "none",
						}}
						className="flex px-2 py-2 z-v2_table_cell_popover__content align-items-center justify-content-between"
					>
						<div
							style={{
								gap: 8,
								filter: item.is_error
									? "grayscale(100%)"
									: "none",
							}}
							className="flex align-items-center"
						>
							{item.logo && item.keyword !== "manual" ? (
								<img
									height={16}
									width={16}
									src={item.logo}
									alt={item.name}
								/>
							) : (
								<div
									style={{
										background: "#E1E8F8",
										borderRadius: "50%",
										width: "16px",
										textAlign: "center",
										height: "16px",
										fontSize: "10px",
										color: "#2266E2",
									}}
								>
									M
								</div>
							)}
							<LinkOrDiv
								as={item.keyword === "manual" ? "div" : "link"}
								v1Url={`/integrations/${item.integration_id}/instance#instances`}
								aria-label={
									item.name || item.integration_name
										? item.name || item.integration_name
										: "Manual"
								}
								className="flex flex-column align-items-start cursor-pointer"
							>
								{item.tag && (
									<Typography
										variant="body_3_regular"
										color="secondary_grey_4"
										style={{ marginBottom: 2 }}
									>
										{capitalizeFirstLetter(item.tag)}
									</Typography>
								)}
								<Typography
									variant="body_2_bold"
									color="secondary_grey_2"
								>
									{item.name || item.integration_name
										? item.name || item.integration_name
										: "Manual"}
								</Typography>
								<Typography
									variant="body_3_medium"
									color="secondary_grey_3"
								>
									{item.org_integration_name}
								</Typography>
							</LinkOrDiv>
						</div>

						{item.org_integration_status === "error" ||
						item.org_integration_status === "disconnected" ? (
							<LinkOrDiv
								v1Url={`/integrations/${item.integration_id}/instance?instanceId=${item.org_integration_id}#instances`}
								style={{
									backgroundColor: "#FFE9E5",
									color: "#FE6955",
									fontSize: "10px",
									height: "25px",
									textAlign: "center",
									padding: "5px",
									borderRadius: "5px",
								}}
								className="cursor-pointer mt-2"
							>
								Resolve
							</LinkOrDiv>
						) : (
							<div className="d-flex flex-column align-items-end">
								{item.is_primary && (
									<Typography
										variant="body_3_bold"
										color="#5ABAFF"
										style={{ marginBottom: 2 }}
									>
										Primary
									</Typography>
								)}
								<Typography
									variant="body_3_regular"
									color="secondary_grey_4"
								>
									Synced
								</Typography>
								{item?.updatedAt && (
									<Typography
										variant="body_3_bold"
										color="secondary_grey_3"
									>
										{getShortHandFromNow(item?.updatedAt)}
									</Typography>
								)}
							</div>
						)}
					</div>
					{!isLastElement && <hr style={{ margin: "0 4px" }} />}
				</>
			)
		);
	});
}

export function PaymentPopoverRenderer({ component, data, ...props }) {
	const dataValue = data[component.valueKey];
	if (!dataValue || dataValue.length === 0) return null;

	return dataValue?.map((item, index) => {
		if (!item) return "-";
		const isLastElement = index === dataValue?.length - 1;
		if (item.name || item.keyword === "manual")
			return (
				<>
					<div
						style={{
							justifyContent: "space-between",
							backgroundColor: item.is_error ? "#F6F7FA" : "none",
						}}
						className="flex px-2 py-1 z-v2_table_cell_popover__content"
					>
						<div
							style={{
								alignItems: "center",
								filter: item.is_error
									? "grayscale(100%)"
									: "none",
							}}
							className="flex "
						>
							<div>
								{
									<img
										src={
											getImageForPaymentMethodDropdownNew(
												item
											) || credit_card
										}
										alt={"payment method"}
									/>
								}
							</div>
							<div
								style={{
									flexDirection: "column",
									alignItems: "flex-start",
								}}
								className="ml-2 flex"
							>
								<LinkOrDiv
									as={item.integration_id ? "link" : "div"}
									to={`/integrations/catalog/detail/${item.integration_id}/#overview`}
									aria-label={item.name}
									style={{
										fontSize: "11px",
										fontWeight: "600",
										color: item.is_error
											? "#A8A8A8"
											: "#484848",
									}}
									className="cursor-pointer"
								>
									{item.name || "-"}
								</LinkOrDiv>
								<div className="grey-1 font-9">
									{item.type === "bank"
										? "A/C. No."
										: "Card No"}{" "}
									....{item.details?.number}
								</div>
							</div>
						</div>
						<div
							style={{
								flexDirection: "column",
								textAlign: "end",
							}}
							className="ml-2 mt-2 flex"
						>
							<div
								style={{
									color: "#A8A8A8",
									fontSize: "10px",
								}}
							>
								{/*This was removed because it was not in scope as mentioned by Mith*/}
							</div>
						</div>
					</div>
					{isLastElement && <hr style={{ margin: "0 4px" }} />}
				</>
			);
		return "-";
	});
}

//TODO extract to separate file
export function UserPopOverRenderer({ component, data, ...props }) {
	return (
		<>
			<div
				style={{ backgroundColor: "#f6f7fa", fontSize: "9px" }}
				className="flex pl-2 py-1"
			>
				<div style={{ color: "#5FCF64" }}>
					<img src={getChangeStatusIcon(10)} /> 51(12%)
				</div>
				<div className="ml-1"> in last 4w</div>
			</div>
			{data.source_array.map(
				(item, index) =>
					item &&
					(item.name || item.keyword === "manual") && (
						<>
							<div
								style={{
									justifyContent: "space-between",
									alignItems: "center",
								}}
								className="flex px-2 py-1"
							>
								<div
									style={{ alignItems: "center" }}
									className="flex "
								>
									{item.logo && item.keyword !== "manual" ? (
										<img
											style={{ borderRadius: "50%" }}
											height={20}
											width={20}
											src={item.logo}
										/>
									) : (
										<div
											style={{
												width: "20px",
												textAlign: "center",
												height: "20px",
												fontSize: "11px",
												color: "#2266E2",
											}}
										>
											M
										</div>
									)}
									<div
										style={{ flexDirection: "column" }}
										className="ml-2 flex"
									>
										<div style={{ fontSize: "13px" }}>
											{item.name ? item.name : "Manual"}
										</div>
									</div>
								</div>

								<div
									style={{
										color: "#717171",
										fontSize: "10px",
									}}
								>
									100
								</div>
							</div>
							<hr style={{ marginTop: "5px" }} />
						</>
					)
			)}
		</>
	);
}

//TODO extract to separate file
export function LicensePopOver({ component, data, ...props }) {
	let optimizedData = data[component.valueKey];
	return (
		<>
			{optimizedData.map(
				(item, index) =>
					item &&
					item.license_name && (
						<>
							<div
								style={{
									justifyContent: "space-between",
									alignItems: "center",
								}}
								className="flex px-2 py-1 m-1"
							>
								<div
									style={{
										fontSize: "11px",
										fontWeight: "400",
									}}
								>
									{item.license_name
										? item.license_name
										: "License"}
								</div>
								<div
									style={{
										color: "#717171",
										fontSize: "10px",
										fontWeight: "600",
									}}
								>
									{item.license_realized_savings}
								</div>
							</div>

							{index !== optimizedData?.length - 1 && (
								<hr style={{ margin: "3px 3px" }} />
							)}
						</>
					)
			)}
		</>
	);
}

export function OptimizedPopover({ component, data, ...props }) {
	let optimizedData = data[component.valueKey];
	let count = data[component.primaryKey];
	let countChange = data[component.secondaryKey];
	let licenseData = [
		"Unused Licenses",
		"Underused Licenses",
		"Un-deprovisioned Licenses",
	];
	let nameMap = {
		"Unused Licenses": "app_unused_license_count",
		"Underused Licenses": "app_underused_license_count",
		"Un-deprovisioned Licenses": "app_left_org_license_count",
	};
	return (
		<div>
			<div className="v2table__popover-header">
				<div className="v2table__sources-header-change_text px-2">
					<img
						src={count > 0 ? IncrementIcon : DecrementIcon}
						alt={count > 0 ? "increasing icon" : "decreasing icon"}
					/>
					<Typography
						variant="alerttag_regular_semibold"
						color={count > 0 ? "accent_green_1" : "accent_red_1"}
					>
						{count} ({countChange}%)
					</Typography>
					<Typography
						variant="alerttag_regular_medium"
						color="secondary_grey_3"
					>
						In last 4 weeks
					</Typography>
				</div>
			</div>
			{licenseData?.map(
				(item, index) =>
					item && (
						<div key={index}>
							<div className="v2table__sources__source_item z-v2_table_cell_popover__content">
								<Typography
									variant="body_2_regular"
									color="secondary_grey_2"
								>
									{item}
								</Typography>
								<Typography
									variant="body_2_bold"
									color="secondary_grey_3"
								>
									{optimizedData?.[0]?.[nameMap[item]] || 100}
								</Typography>
							</div>
							{index !== licenseData?.length - 1 && (
								<Divider
									backgroundColor="#EBEBEB"
									borderRadius={8}
									height={1}
									width="100%"
									className="mx-1"
								/>
							)}
						</div>
					)
			)}
		</div>
	);
}

export function OptimisationPopOver({ component, data, ...props }) {
	const optimizedData = data[component.valueKey];
	return (
		<>
			{optimizedData?.map(
				(item, index) =>
					item && (
						<div key={item.license_id}>
							<div className="v2table__sources__source_item">
								<Typography
									variant="body_2_regular"
									color="secondary_grey_2"
								>
									{item.license_name
										? item.license_name
										: "License"}
								</Typography>
								<Typography
									variant="body_2_bold"
									color="secondary_grey_3"
								>
									{item.optimized_license_count}
								</Typography>
							</div>

							{index !== optimizedData?.length - 1 && (
								<Divider
									backgroundColor="#EBEBEB"
									borderRadius={8}
									height={1}
									width="100%"
									className="mx-1"
								/>
							)}
						</div>
					)
			)}
		</>
	);
}

export function LicenseTypeNamePopOver({ component, data, ...props }) {
	return (
		<div style={{ maxHeight: 250, overflowY: "auto", overflowX: "hidden" }}>
			{data[component.valueKey]?.map(
				(item, index) =>
					item && (
						<div key={item._id}>
							<div className="flex px-2 py-2 justify-content-between align-items-center">
								<div className="flex align-items-center">
									<div className="d-flex flex-column align-items-start">
										<Typography
											variant="body_2_bold"
											color="secondary_grey_2"
											style={{
												width: 150,
												textAlign: "left",
											}}
										>
											{item.license_name
												? item.license_name
												: "License"}
										</Typography>
									</div>
								</div>
							</div>

							{index !== data[component.valueKey]?.length - 1 && (
								<hr style={{ margin: "0px 4px" }} />
							)}
						</div>
					)
			)}
		</div>
	);
}
//TODO extract to separate file
export function LicenseTypePopOver({ component, data, ...props }) {
	const secondaryTextMapper = (license) => {
		return {
			count: {
				primary: `${license?.assigned_count || 0} Assigned`,
				divider: true,
				secondary: `${
					license?.quantity - license?.assigned_count || 0
				} Unmapped`,
			},
			mappedBy: {
				primary: `Mapped by ${license?.updated_by_user_name || "User"}`,
			},
		};
	};
	const secondaryTextKey = component?.secondaryTextType || "count";
	return (
		<div style={{ maxHeight: 250, overflowY: "auto", overflowX: "hidden" }}>
			{data[component.valueKey]?.map(
				(license, index) =>
					license && (
						<div key={license?._id}>
							<div className="flex px-2 z-v2_table_cell_popover__content py-2 justify-content-between align-items-center">
								<div className="flex align-items-center overflow-hidden">
									<div className="d-flex flex-column align-items-start overflow-hidden">
										<Typography
											variant="body_2_bold"
											color="secondary_grey_2"
											style={{
												textWrap: "nowrap",
												textOverflow: "ellipsis",
												width: "100%",
												overflow: "hidden",
											}}
										>
											{license?.license_name ||
											license?.name
												? license?.license_name ||
													license?.name
												: "N/A"}
										</Typography>

										{license.quantity ? (
											<div>
												<Typography
													variant="body_3_regular"
													color="secondary_grey_3"
												>
													Quantity |{" "}
													{license?.quantity}
												</Typography>
											</div>
										) : (
											<div className="d-flex align-items-center">
												{secondaryTextMapper(license)?.[
													secondaryTextKey
												]?.primary && (
													<Typography
														variant="body_3_regular"
														color="secondary_grey_3"
													>
														{
															secondaryTextMapper(
																license
															)?.[
																secondaryTextKey
															]?.primary
														}
													</Typography>
												)}
												{secondaryTextMapper(license)?.[
													secondaryTextKey
												]?.divider && (
													<div
														className="mx-1"
														style={{
															width: "1px",
															height: "11px",
															background:
																"#717171",
															borderRadius:
																"100px",
														}}
													/>
												)}
												{secondaryTextMapper(license)?.[
													secondaryTextKey
												]?.secondary && (
													<Typography
														variant="body_3_regular"
														color="secondary_grey_3"
													>
														{
															secondaryTextMapper(
																license
															)?.[
																secondaryTextKey
															]?.secondary
														}
													</Typography>
												)}
											</div>
										)}
									</div>
								</div>

								<div
									className="d-flex flex-column justify-content-center align-items-end"
									style={{ minWidth: 50 }}
								>
									{license?.cost_per_license ? (
										<Typography
											variant="body_2_bold"
											color="secondary_grey_3"
										>
											${" "}
											{kFormatter(
												license?.cost_per_license
													?.amount_org_currency ||
													license?.cost_per_item
														?.amount_org_currency ||
													0
											)}
										</Typography>
									) : null}
									{license?.cost_per_license ? (
										<Typography
											variant="body_3_regular"
											color="secondary_grey_3"
											style={{ marginLeft: 2 }}
										>
											/
											{license?.cost_per_license
												?.period ||
												license?.cost_per_item
													?.period ||
												"term"}
										</Typography>
									) : null}
								</div>
							</div>

							{index !==
								data[component?.valueKey]?.length - 1 && (
								<hr style={{ margin: "0px 4px" }} />
							)}
						</div>
					)
			)}
		</div>
	);
}
export function OptimizationTypePopOver({ component, data, ...props }) {
	let optimizedData = data[component.valueKey];
	let isWastage = component.primaryKey === "wastage"; // license_past_month_wastage
	let text = isWastage ? "Licenses Wasted" : undefined;
	const realizedSavings =
		component.valueKey === "app_realized_savings_breakup";
	const potentialSavings =
		component.valueKey === "app_monthly_potential_savings_breakup";
	return (
		<>
			{optimizedData?.map(
				(item, index) =>
					item && (
						<>
							<div className="v2table__sources__source_item">
								<div
									style={{
										gap: 2,
									}}
									className="d-flex flex-column align-items-start"
								>
									<Typography
										variant={
											realizedSavings
												? "body_2_regular"
												: "body_2_bold"
										}
										color="secondary_grey_2"
									>
										{item.license_name
											? item.license_name
											: "License"}
									</Typography>
									{potentialSavings && (
										<Typography
											variant="body_3_regular"
											color="secondary_grey_3"
										>
											{item.optimizable_license_count}{" "}
											Optimizable licenses
										</Typography>
									)}
									{isWastage && (
										<Typography
											variant="body_3_regular"
											color="accent_red_1"
										>
											{item.opimizable_license_count}{" "}
											{text}
										</Typography>
									)}
								</div>

								<div
									className="d-flex align-items-center"
									style={{ gap: 2 }}
								>
									<CurrencySymbol
										color="secondary_grey_3"
										currencyCode="USD"
										fontVariant="body_2_bold"
										locale="en-US"
									/>
									<Currency
										color="secondary_grey_3"
										fontVariant="body_2_bold"
										locale="en-US"
										value={
											realizedSavings
												? item.license_realized_savings
												: potentialSavings
													? item.license_monthly_potential_savings
													: item.license_past_month_wastage ||
														0
										}
									/>
								</div>
							</div>
							{index !== optimizedData?.length - 1 && (
								<hr style={{ margin: "3px 3px" }} />
							)}
						</>
					)
			)}
		</>
	);
}

const formatNumber = (n) => {
	if (isNaN(+n) || !n) return "-";
	return n % 1 !== 0 ? n?.toFixed(2) : n;
};

export function ChargebackPopOverRenderer({ component, data, ...props }) {
	const value = data[component.valueKey];
	if (!value || value?.length === 0) return null;

	return value?.map(
		(item, index) =>
			item && (
				<>
					<div
						style={{
							justifyContent: "space-between",
							alignItems: "center",
						}}
						className="flex px-2 py-1 m-1 z-v2_table_cell_popover__content"
					>
						<div
							style={{
								fontSize: "12px",
								color: "484848",
								fontWeight: "600",
							}}
						>
							{item.department_name ?? "License"}
						</div>
						<div
							style={{
								color: "#717171",
								fontSize: "10px",
								flexDirection: "column",
								alignItems: "flex-end",
							}}
							className="flex"
						>
							<div
								style={{ fontWeight: "600", color: "#717171" }}
								className=""
							>{`$${formatNumber(item.department_spend)}`}</div>
							<div
								style={{ fontWeight: "500", color: "#717171" }}
							>{`${item.percentage ?? 0}% of Total Cost`}</div>
						</div>
					</div>

					{index !== value?.length - 1 && (
						<hr style={{ margin: "3px 3px" }} />
					)}
				</>
			)
	);
}

export function ListRenderer({ component, data }) {
	const labelKey = component?.labelKey;
	const valueKey = component?.valueKey;
	return (
		<div>
			{data?.[valueKey]?.map((item, index) => (
				<div
					key={item?.[labelKey]}
					className={
						"d-flex p-2 flex-column align-items-start z-v2_table_cell_popover__content"
					}
				>
					<Typography
						variant="body_3_regular"
						color="secondary_grey_1"
					>
						{item?.[labelKey]}
					</Typography>
				</div>
			))}
		</div>
	);
}

export function CategoryPopOverRenderer({ component, data }) {
	return (
		<div className="">
			{data.app_sub_categories.map((item, index) => (
				<React.Fragment key={item?.category_id}>
					<AppCategoryDescription
						obj={item}
						className="z-v2_table_cell_popover__content"
					/>
					{index !== data.app_sub_categories.length - 1 && (
						<div
							style={{
								margin: "4px 2px",
								height: 1,
								background: "#EBEBEB",
								borderRadius: 8,
							}}
						/>
					)}
				</React.Fragment>
			))}
		</div>
	);
}

export function TagPopOverRenderer({ component, data }) {
	return data.app_tags.map(
		(item, index) =>
			item &&
			index > 0 && (
				<div className="d-flex flex-wrap">
					<TagsRenderer
						data={{ app_tags: [item] }}
						component={component}
					></TagsRenderer>
				</div>
			)
	);
}

export function SimpleViewMoreTextPopOver({ component, data }) {
	return (
		<>
			{data[component.valueKey]?.map(
				(row, index) =>
					row &&
					index > 0 && (
						<>
							<div
								className="font-12"
								style={{
									padding: "8px",
									wordBreak: "break-word",
								}}
							>{`${row}`}</div>
							{index < data[component.valueKey].length - 1 && (
								<hr style={{ margin: "3px 3px" }} />
							)}
						</>
					)
			)}
		</>
	);
}
