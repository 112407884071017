import React, { useContext } from "react";
import empty from "../../../assets/applications/contractsempty.svg";
import "../../../common/empty.css";
import add from "../../../assets/addwhite.svg";
import RoleContext from "../../../services/roleContext/roleContext";
import { useHistory } from "react-router-dom";
import Dropdown from "UIComponents/CustomDropdown/Dropdown";
import downarrow from "assets/sidebar/downarrow.svg";

export function EmptyContracts({ isVendorContract = false }) {
	const { isViewer } = useContext(RoleContext);
	const history = useHistory();
	return (
		<div
			className="d-flex flex-column justify-content-center align-items-center"
			style={{ margin: "auto" }}
		>
			<img src={empty} width={200} />
			<div className="empty-header">No contracts added</div>
			{!isViewer && (
				<>
					<div className="empty-lower">
						Add contracts to receive renewal reminders and
						recommendations
					</div>
					{isVendorContract ? (
						<>
							<Dropdown
								className="d-flex align-items-center"
								toggler={
									<div className="contract_uploads_add_button">
										<div className="contract_uploads_add_button_text">
											+ Add
										</div>
										<img
											src={downarrow}
											width={8}
											height={4}
											alt=""
										/>
									</div>
								}
								top={35}
								options={[
									{
										label: "Contract",
										onClick: () =>
											history.push("/contract/new"),
									},
									{
										label: "Subscription",
										onClick: () =>
											history.push("/subscription/new"),
									},
									{
										label: "Perpetual",
										onClick: () =>
											history.push("/perpetual/new"),
									},
								]}
								optionFormatter={(option) => option.label}
								onOptionSelect={(option) => option.onClick()}
							/>
						</>
					) : (
						<button
							color="primary"
							className="ov__button2 empty-page-button"
							onClick={() => history.push(`/contract/new`)}
							style={{ width: "max-content" }}
						>
							<img style={{ paddingRight: "5px" }} src={add} />
							Add Contract
						</button>
					)}
				</>
			)}
		</div>
	);
}
