import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import iconWarning from "modules/transactionMapping/assets/icon-warning.svg";
import styled from "styled-components";
import { GREY_VARIANT, Typography, Checkbox } from "@zluri/ui-components";

const HeaderWrapper = styled.div`
	display: flex;
	align-items: center;
`;

const ConditionsChecked = styled.div`
	margin-left: auto;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
`;

const CheckListWrapper = styled.div`
	margin-top: 10px;
	display: flex;
	flex-direction: column;
	gap: 15px;
`;

const CheckListItemWrapper = styled.div`
	display: flex;
	gap: 10px;
	align-items: center;
	padding: 5px 10px 5px 1px;
`;

const ChecklistOuterWrapper = styled.div`
	padding: 10px;
`;

const SideCurtainChecklist = ({
	cheklistHeader,
	checkListItems,
	onChecklistChange,
}) => {
	const [checkList, setCheckList] = useState(checkListItems);

	useEffect(() => {
		if (!(checkList && checkList.length > 0)) {
			setCheckList(checkListItems);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [checkListItems]);

	const getCheckedCount = (checkList) =>
		checkList.reduce((acc, curr) => {
			if (curr.checked) {
				return acc + 1;
			}
			return acc;
		}, 0);

	const checkboxClickeHandler = (checkedIndex) => {
		const newList = checkList.map((item, index) =>
			index === checkedIndex
				? { ...item, checked: !item.checked }
				: { ...item }
		);
		const checkedCount = getCheckedCount(newList);
		setCheckList(newList);
		onChecklistChange(newList, checkedCount);
	};

	return (
		<ChecklistOuterWrapper>
			<HeaderWrapper>
				<img src={iconWarning} alt="warning" />
				<Typography
					variant="body_2_bold"
					style={{ marginLeft: "4px" }}
					color={GREY_VARIANT.SECONDARY_GREY_2}
				>
					{cheklistHeader}
				</Typography>
				<ConditionsChecked>
					<Typography
						variant="body_2_bold"
						color={
							getCheckedCount(checkList) === checkList.length
								? "#5287E8"
								: "#FFA217"
						}
					>
						{getCheckedCount(checkList)}/{checkList.length}
					</Typography>
					<Typography
						variant="body_3_regular"
						color={GREY_VARIANT.SECONDARY_GREY_3}
					>
						Conditions Marked
					</Typography>
				</ConditionsChecked>
			</HeaderWrapper>
			<CheckListWrapper>
				{checkList.map(({ text, checked }, index) => (
					<CheckListItemWrapper key={text}>
						<Checkbox
							checked={checked}
							onChange={() => checkboxClickeHandler(index)}
						/>
						<Typography
							variant="body_2_regular"
							color={GREY_VARIANT.SECONDARY_GREY_2}
						>
							{text}
						</Typography>
					</CheckListItemWrapper>
				))}
			</CheckListWrapper>
		</ChecklistOuterWrapper>
	);
};

export default SideCurtainChecklist;

SideCurtainChecklist.propTypes = {
	cheklistHeader: PropTypes.string,
	checkListItems: PropTypes.array,
	onChecklistChange: PropTypes.func,
};
