/* eslint-disable react/prop-types */
import CurtainTable from "modules/transactionMapping/components/SideCurtain/CurtainTable";
import SideCurtainChecklist from "modules/transactionMapping/components/SideCurtain/SideCurtainChecklist";
import ModalDropdown from "modules/transactionMapping/components/Modal/ModalDropdown";
import React from "react";
import StatsBlock from "modules/transactionMapping/components/SideCurtain/StatsBlock";

export const curtainMapping = {
	V2_TABLE_BLOCK: ({
		entity,
		intID,
		paginationEntity,
		exportEntity,
		apiProps,
	}) => (
		<CurtainTable
			entity={entity}
			intID={intID}
			paginationEntity={paginationEntity}
			exportEntity={exportEntity}
			apiProps={apiProps}
			disableUrlSync
		/>
	),
	CHECKLIST_BLOCK: ({
		cheklistHeader,
		checkListItems,
		onChecklistChange,
	}) => (
		<SideCurtainChecklist
			cheklistHeader={cheklistHeader}
			checkListItems={checkListItems}
			onChecklistChange={onChecklistChange}
		/>
	),
	DROPDOWN_BLOCK: ({
		title,
		text,
		dropdownLabel,
		dropdownOptions,
		fetchOptionsHandler,
		optionRender,
		onValueSelect,
		value,
	}) => (
		<ModalDropdown
			title={title}
			text={text}
			dropdownLabel={dropdownLabel}
			dropdownOptions={dropdownOptions}
			fetchOptionsHandler={fetchOptionsHandler}
			optionRender={optionRender}
			onValueSelect={onValueSelect}
			value={value}
			width="266px"
		/>
	),
	STATS_BLOCK: ({ stats, heading }) => (
		<StatsBlock stats={stats} heading={heading} />
	),
	CUSTOM_BLOCK: ({ customBlock }) => customBlock,
};
