import React from "react";
import styled from "styled-components";
import { operatorIconMapping } from "modules/transactionMapping/constants/operatorIconMapping";
import PropTypes from "prop-types";
import {
	Typography,
	BLUE_VARIANT,
	Popover,
	GREY_VARIANT,
} from "@zluri/ui-components";
import { Divider } from "antd";
import { getConditionsCount } from "modules/transactionMapping/constants/ruleMapping";
import { useHistory } from "react-router-dom";

const ConditionWrapper = styled.div`
	max-width: ${({ $maxWidth }) => ($maxWidth ? $maxWidth : "180px")};
	padding: 4px 6px;
	height: 24px;
	display: flex;
	align-items: center;
	flex: 1 0 0;
	border-radius: 4px;
	background: var(--primary-blue-light-3, #e8f0fc);
	margin-left: ${({ $haveLefttMargin }) =>
		$haveLefttMargin ? "10px" : "0px"};
`;

const NumberWrapper = styled.div`
	padding: 4px 6px;
	height: 24px;
	display: flex;
	align-items: center;
	margin-left: 8px;
`;

const RulesPreviewWrapper = styled.div`
	display: flex;
	overflow: hidden;
	height: 24px;
`;

const PopoverWrapper = styled.div`
	padding: 8px;
	display: flex;
	flex-direction: column;
	gap: 4px;
	width: 220px;
`;

const StyledDivider = styled(Divider)`
	&.ant-divider-inner-text {
		padding-inline: 10px;
	}
`;

const SingleCondition = ({
	selectedAttribute,
	index,
	maxWidth,
	operator,
	value,
	hasMargin = true,
}) => {
	return (
		<ConditionWrapper
			$haveLefttMargin={hasMargin && index > 0}
			$maxWidth={maxWidth}
		>
			<Typography
				variant="button_extrasmall_medium"
				style={{
					textOverflow: "ellipsis",
					overflow: "hidden",
					whiteSpace: "nowrap",
					marginRight: "4px",
				}}
				color={GREY_VARIANT.SECONDARY_GREY_2}
			>
				{selectedAttribute.label}
			</Typography>
			<img
				alt={operator}
				src={
					operatorIconMapping[`${selectedAttribute.type}-${operator}`]
				}
				style={{ marginRight: "4px" }}
			/>
			<Typography
				variant="button_extrasmall_medium"
				style={{
					textOverflow: "ellipsis",
					overflow: "hidden",
					whiteSpace: "nowrap",
					marginRight: "4px",
				}}
				color={GREY_VARIANT.SECONDARY_GREY_2}
			>
				{value}
			</Typography>
		</ConditionWrapper>
	);
};

const OrWrapper = styled.div`
	border-radius: 8px;
	background: var(--Accent-Amber-3, #fff6dc);
	display: flex;
	height: 16px;
	padding: 2px 8px;
	justify-content: center;
	align-items: center;
	margin-bottom: 8px;
	margin-top: 8px;
`;

const RulesPreview = ({
	conditions,
	groupAfter = 1,
	maxWidth,
	allConditions,
	redirectUrl = null,
}) => {
	const shortenedCoditions = conditions.slice(0, groupAfter);
	const conditionsCount = getConditionsCount(allConditions);
	const countToDisplay = conditionsCount - 1;
	const { push } = useHistory();

	const valueToDisplay = (value) => {
		if (Array.isArray(value)) {
			return value
				.map((item) => (item?.vendor_name ? item?.vendor_name : item))
				.join(", ");
		}
		if (value?.vendor_name) {
			return value?.vendor_name;
		}
		return value;
	};

	const getAdditionalNumber = () => {
		return (
			<NumberWrapper>
				<Typography
					variant="body_1_medium"
					color={BLUE_VARIANT.PRIMARY_BLUE_DARK_1}
					style={{
						cursor: "pointer",
					}}
				>
					+{countToDisplay}
				</Typography>
			</NumberWrapper>
		);
	};

	return (
		<RulesPreviewWrapper>
			{shortenedCoditions.map(
				({ selectedAttribute, operator, selectedValue }, index) => {
					return (
						<SingleCondition
							key={selectedAttribute.value + index}
							selectedAttribute={selectedAttribute}
							index={index}
							maxWidth={maxWidth}
							operator={operator}
							value={valueToDisplay(selectedValue)}
						/>
					);
				}
			)}
			{countToDisplay > 0 ? (
				redirectUrl ? (
					<div onClick={() => push(redirectUrl)}>
						{getAdditionalNumber()}
					</div>
				) : (
					<Popover
						trigger={["click"]}
						title={null}
						content={
							<PopoverWrapper>
								{allConditions["any"].map(({ all }, index) => (
									<>
										{all.map(
											(
												{
													selectedAttribute,
													operator,
													selectedValue,
												},
												index
											) => (
												<SingleCondition
													key={
														selectedAttribute.value +
														index
													}
													selectedAttribute={
														selectedAttribute
													}
													index={index}
													maxWidth="220px"
													operator={operator}
													value={valueToDisplay(
														selectedValue
													)}
													hasMargin={false}
												/>
											)
										)}
										{index <
										allConditions["any"].length - 1 ? (
											<StyledDivider
												style={{
													margin: "0px",
												}}
											>
												<OrWrapper>
													<Typography
														color="#C97B07"
														variant="button_extrasmall_regular"
													>
														OR
													</Typography>
												</OrWrapper>
											</StyledDivider>
										) : null}
									</>
								))}
							</PopoverWrapper>
						}
					>
						{getAdditionalNumber()}
					</Popover>
				)
			) : null}
		</RulesPreviewWrapper>
	);
};

export default RulesPreview;

RulesPreview.propTypes = {
	conditions: PropTypes.array,
	groupAfter: PropTypes.number,
	maxWidth: PropTypes.string,
	allConditions: PropTypes.object,
	redirectUrl: PropTypes.string,
};

SingleCondition.propTypes = {
	selectedAttribute: PropTypes.object,
	index: PropTypes.number,
	maxWidth: PropTypes.number,
	operator: PropTypes.string,
	value: PropTypes.string,
	hasMargin: PropTypes.bool,
};
