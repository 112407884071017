import React, { useState } from "react";
import TransactionMappingGeneralModal from "modules/transactionMapping/components/Modal/TransactionMappingGeneralModal";
import { deleteRule } from "modules/transactionMapping/services/transactionMapping.api";
import PropTypes from "prop-types";
import { transactionActions } from "modules/transactionMapping/constants/actions";
import { deactivateDeleteInfo } from "modules/transactionMapping/utils/getModalInfoBanner";

const getModalItems = ({
	action,
	setMapTransactionOption,
	mapTransactionOption,
}) => {
	if (action === transactionActions.TRANSACTION_MAPPING) {
		return [
			{
				type: "DUAL_OPTION_SELECTION_BLOCK",
				title: "Do you want to unmap transactions that are previously mapped?",
				text: "This rule considers the both recognized and unrecognized transactions.",
				onSelectHandler: (e) => setMapTransactionOption(e),
				value: mapTransactionOption,
				options: [
					{
						label: "Yes, Unmap transactions",
						value: 1,
					},
					{
						label: "Do not Unmap transactions",
						value: 2,
					},
				],
			},
			...deactivateDeleteInfo({ action, mapTransactionOption }),
			{
				type: "INFO_BANNER_BLOCK",
				text: "Upon deletion, the updated by and action type info for the impacted transactions will be removed.",
			},
		];
	}

	if (action === transactionActions.TRANSACTION_ARCHIVING) {
		return [
			{
				type: "DUAL_OPTION_SELECTION_BLOCK",
				title: "Do you want to unarchive transactions that are previously mapped?",
				text: "This rule consider archived transactions.",
				onSelectHandler: (e) => setMapTransactionOption(e),
				value: mapTransactionOption,
				options: [
					{
						label: "Yes, Unarchive transactions",
						value: 1,
					},
					{
						label: "Do not Unarchive transactions",
						value: 2,
					},
				],
			},
			...deactivateDeleteInfo({ action, mapTransactionOption }),
			{
				type: "INFO_BANNER_BLOCK",
				text: "Upon deletion, the updated by and action type info for the impacted transactions will be removed.",
			},
		];
	}
	return [
		{
			type: "DUAL_OPTION_SELECTION_BLOCK",
			title: "Should the unmapped transactions run through the rules? ",
			text: "This rule considers unrecognised transactions.",
			onSelectHandler: (e) => setMapTransactionOption(e),
			value: mapTransactionOption,
			options: [
				{
					label: "Yes",
					value: 1,
				},
				{
					label: "No",
					value: 2,
				},
			],
		},
		...deactivateDeleteInfo({ action, mapTransactionOption }),
		{
			type: "INFO_BANNER_BLOCK",
			text: "Upon deletion, the updated by and action type info for the impacted transactions will be removed.",
		},
	];
};

const DeleteRuleModal = ({ closeHandler, rule: data }) => {
	const action = data.actions[0]["v"].action_type;
	const [mapTransactionOption, setMapTransactionOption] = useState(null);
	const [deleteLoading, setDeleteLoading] = useState(false);

	return (
		<TransactionMappingGeneralModal
			heading="Are you sure you want to delete?"
			modalItems={getModalItems({
				action,
				setMapTransactionOption,
				mapTransactionOption,
			})}
			footerCtas={[
				{
					label: "close",
					variant: "primary-alternative",
					onClick: closeHandler,
				},
				{
					label: "Delete rule",
					variant: "secondary-danger",
					disabled: !mapTransactionOption || deleteLoading,
					loading: deleteLoading,
					onClick: () => {
						setDeleteLoading(true);
						deleteRule({
							ruleId: data._id,
							tag: "transaction",
							revert_rule_action:
								((action ===
									transactionActions.TRANSACTION_MAPPING ||
									action ===
										transactionActions.TRANSACTION_ARCHIVING) &&
									mapTransactionOption === 1) ||
								(action ===
									transactionActions.TRANSACTION_UNMAPPING &&
									mapTransactionOption === 1),
						}).then(() => {
							setDeleteLoading(false);
							window.open(
								`${
									window.origin
								}/spends/automation?showToast=${JSON.stringify({
									message: `${data.name} is deleted successfully`,
								})}`,
								"_self"
							);
							closeHandler();
						});
					},
				},
			]}
			onCloseHandler={() => closeHandler()}
		/>
	);
};

export default DeleteRuleModal;

DeleteRuleModal.propTypes = {
	closeHandler: PropTypes.func,
	rule: PropTypes.object,
};
