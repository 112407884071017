import React, { useEffect, useState } from "react";
import {
	Actions,
	BUTTONS,
	COMPLETE_SETUP,
	DATE_FORMAT,
	STATUS,
	ViewType,
	ViewsInCreateReviewPage,
	accessReviewUrls,
	completeReviewTypes,
	toastProps,
	popupEntities,
} from "modules/AccessReview/constants/constants";
import { RenderReviewType } from "../SelectApplications/SelectApplications";
import { DatePicker } from "@zluri/ui-components";
import { useDispatch, useSelector } from "react-redux";
import {
	changeView,
	updateReviewSetupDetails,
	makeActiveStep,
	updateProgressbar,
	clearAllReviewDetails,
} from "modules/AccessReview/redux/AccessReview.actions";
import { useHistory } from "react-router-dom";
import { createCertification } from "modules/AccessReview/service/AccessReview.service";
import { TriggerIssue } from "utils/sentry";
import _ from "underscore";
import ControlFlowButtonGroup from "../ControlFlowButtonGroup/ControlFlowButtonGroup";
import moment from "moment";
import { toast } from "react-toastify";
import { ShowToast } from "containers/v2table/BulkEdit/ShowToast";
import "modules/AccessReview/components/ReviewDetailsForm/ReviewDetailsForm.css";
import "modules/AccessReview/components/CompleteReviewSetup/CompleteReviewSetup.css";
import { AccessReviewCreateReviewRequestBody } from "modules/AccessReview/model/AccessReview.model";
import { Popup } from "../common/common";
import ToastNotificaion from "UIComponents/Rbac/ToastNotification";
import { RBAC_ERROR, RBAC_ERROR_STATUS_CODES } from "constants/rbac";

export default function CompleteReviewSetup() {
	const dispatch = useDispatch();
	const history = useHistory();
	const [isNextEnabled, setIsNextEnabled] = useState(false);
	const [showCancelPopup, setShowCancelPopup] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const { completeSetupObj, applications, reviewDetailsForm, initialLevels } =
		useSelector((state) => state.accessReview);

	useEffect(() => {
		const isValidated = Object.values(completeSetupObj).some((val) => {
			return val === null || val === "";
		});

		dispatch(
			updateProgressbar(
				2,
				!isValidated ? STATUS.Completed : STATUS.InProgress,
				ViewType.ViewsInCreateReviewPage
			)
		);

		dispatch(makeActiveStep(2));

		if (isValidated) setIsNextEnabled(false);
		else setIsNextEnabled(true);
	}, [completeSetupObj, dispatch]);

	const handleCreateReview = () => {
		setIsSubmitting(true);

		let data = {
			applications: applications,
			reviewers_configuration: initialLevels,
			...completeSetupObj,
			...reviewDetailsForm,
		};
		const body = new AccessReviewCreateReviewRequestBody(data);
		createCertification(body)
			.then((res) => {
				if (res) {
					setIsSubmitting(false);
					history.push(accessReviewUrls().LANDING_PAGE);
					if (res?.data?.creating_users_asynchronously)
						toast(<ShowToast type={toastProps.success} />);
					dispatch(clearAllReviewDetails());
				}
			})
			.catch((err) => {
				if (
					err?.response?.data?.type === RBAC_ERROR &&
					RBAC_ERROR_STATUS_CODES.includes(err?.response?.status)
				) {
					toast(<ToastNotificaion />);
				} else {
					TriggerIssue("Error while creating certification", err);
					toast(<ShowToast type={toastProps.failure} />);
				}
				setIsSubmitting(false);
			});
	};

	const handleBackClick = () => {
		dispatch(
			changeView(
				ViewsInCreateReviewPage.States.CompleteReviewSetup,
				Actions.PREV,
				ViewType.ViewsInCreateReviewPage
			)
		);
	};

	return (
		<div className="d-flex flex-column">
			<Popup
				show={showCancelPopup}
				setShow={setShowCancelPopup}
				entity={popupEntities.cancelPopup}
			/>
			<div className="review-details-form">
				<div className="font-12 bold-600 font-family-sora">
					{COMPLETE_SETUP}
				</div>
				<div className="select-review-type">
					<RenderReviewType
						reviewTypes={completeReviewTypes}
						activeReviewType={completeSetupObj?.review_setup_action}
						objKey="review_setup_action"
						headerStyles="font-14 bold-500"
						descriptionStyles="font-11 bold-400"
					/>
				</div>
			</div>
			<div className="start-end-date-section">
				<div className="d-flex justify-content-between w-100 gap-10">
					<DatePicker
						minDate={moment().format("YYYY-MM-DD")}
						maxDate={completeSetupObj?.end_date}
						value={completeSetupObj?.start_date || ""}
						onChange={(date) => {
							dispatch(
								updateReviewSetupDetails({
									...completeSetupObj,
									start_date: date,
								})
							);
						}}
						className="w-100"
						format={DATE_FORMAT}
						placeholder="Review Start Date"
					/>

					<DatePicker
						minDate={completeSetupObj?.start_date}
						maxDate={completeSetupObj?.remediation_end_date}
						value={completeSetupObj?.end_date || ""}
						onChange={(date) => {
							dispatch(
								updateReviewSetupDetails({
									...completeSetupObj,
									end_date: date,
								})
							);
						}}
						disabled={_.isEmpty(completeSetupObj?.start_date)}
						className="w-100"
						format={DATE_FORMAT}
						placeholder="Review End Date"
					/>
					<DatePicker
						minDate={completeSetupObj?.end_date}
						value={completeSetupObj?.remediation_end_date || ""}
						onChange={(date) => {
							dispatch(
								updateReviewSetupDetails({
									...completeSetupObj,
									remediation_end_date: date,
								})
							);
						}}
						disabled={
							_.isEmpty(completeSetupObj?.end_date) ||
							_.isEmpty(completeSetupObj?.start_date)
						}
						className="w-100"
						format={DATE_FORMAT}
						placeholder="Remediation End Date"
					/>
				</div>
				{/* <div className="d-flex justify-content-end align-items-center mt-3">
					<ToggleSwitch
						height={18}
						width={32}
						checked={reviewDetails?.review_extension_enabled}
						onChange={(v) => {
							setReviewDetails({
								...reviewDetails,
								review_extension_enabled: v,
							});
						}}
						checkedIcon={false}
						uncheckedIcon={false}
						onColor={"#2266E2"}
						offColor={"#EBEBEB"}
					/>
					<span className="font-11 family-sora bold-400 ml-1">
						Allow review extension till all reviews are completed
					</span>
				</div> */}
			</div>
			<div
				className="review-form-btn-container"
				style={{ borderRadius: "0px 0px 8px 8px" }}
			>
				<ControlFlowButtonGroup
					onBackClick={handleBackClick}
					onCancelClick={() => setShowCancelPopup(true)}
					onActionClick={handleCreateReview}
					actionBtnLoading={isSubmitting}
					actionBtnDisabled={!isNextEnabled}
					actionBtnClasses="create__cert__btn"
					actionBtnText={BUTTONS.CREATE_CERTIFICATION}
				/>
			</div>
		</div>
	);
}
