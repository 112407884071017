import {
	getAppPropertiesListv3,
	getAppTabCount,
	getAppChargeBackPropertiesListv3,
	getApplicationChargebackSubDepartments,
	getApplicationChargebacks,
} from "services/api/applications";
import {
	getAllApplications,
	getNeedsReviewApplications,
	getRestrictedApplications,
	getUnmanagedApplications,
	patchApplication,
} from "services/api/applicationsv2";
import {
	getAllCertificationAppUsers,
	getAllCertificationAppUsersPropertiesList,
	getAllCertificationAppUsersEmpView,
	getAllCertificationAppUsersPropertiesListEmpView,
	getCertificationReviewersData,
	getCertificationReviewersDataPropertiesList,
	getAccessCertificateApplicationFilters,
	getAccessCertificateApplicationFiltersForEmployee,
} from "services/api/certifications";
import {
	getAllRedundantOptimizationData,
	getRedundantOptimizationPropertiesListv3,
} from "services/api/optimization";
import {
	getAllTasks,
	getTasksPropertiesListv3,
	getTasksTabCount,
} from "services/api/tasksV2";
import { getViewsv2 } from "services/api/views";
import {
	getAllDesktopAgentsTable,
	getDesktopAgentsTable,
} from "services/api/agents";
import {
	getALLSpendsTransactionTable,
	getAppContractsV2,
	getAppContractsV2Properties,
	getAppCriticalUsersList,
	getAppCriticalUsersProperties,
	getAppLicensesV2,
	getAppLicensesV2Properties,
	getAppMapLicensesV2,
	getAppMapLicensesV2Properties,
	getAppUserPropertiesList,
	getApplicationUsers,
	getSpendsTransactionTable,
} from "modules/applications/service/Applications.api";
import {
	getAllApplicationUsers,
	getAppUsersPropertiesList,
	patchUserReviewStatus,
	patchUserReviewStatusEmpView,
} from "modules/AccessReview/service/AccessReview.service";
import {
	getSpendsRules,
	getSpendsRulesPropertiesList,
	getPreviewMatchingTransactions,
	getPreviewMatchingTransactionsPropertiesList,
	getImpactedTransactions,
} from "services/api/spendsRules";
import {
	getAppOptimizationPastLogsPropertiesList,
	getAppOptimizationReviewUsersPropertiesList,
	getOptimizationReviewUsers,
	getPastOptimizationLogs,
	getPreviousOptimizationRuns,
	getPreviousOptimizationRunsPropertiesList,
	getAppRealizedSavingsListV3,
	getAppRealizedSavingsPropertiesV3,
	getAppRealizedSavingsUsersBreakdownPropertiesV3,
	getAppRealizedSavingsUsersBreakdownListV3,
} from "modules/Optimization/service/OptimizationV2.api";

export const TableEntityEnum = {
	APP_CRITICAL_USERS: "app_critical_users",
	APP_LICENSES: "app_licenses",
	APP_MAP_LICENSES: "app_map_licenses",
	APP_OPTIMIZATION_PREVIOUS_OPTIMZATION_RUNS:
		"app_optimization_previous_optimization_runs",
	APP_PREVIOUS_OPTIMIZATION_LOGS: "app_previous_optimization_logs",
	APPLICATION_CHARGEBACKS: "Application Chargebacks", //(???)
	APP_OPTIMIZATION_REVIEW_USERS: "app_optimization_review_users",
	APP_REALIZED_SAVINGS: "app_realized_savings",
	APP_SPENDS_TRANSACTION: "app_spends_transaction",
	APPLICATION_USERS: "application_users",
	DESKTOP_AGENTS: "desktop_agents",

	PREVIOUS_MATCHING_TRANSACTIONS: "preview_matching_transactions",
	SPENDS_RULE: "spends_rules",
	REDUNDANT_OPTIMIZATION: "redundant_optimization",

	IMPACTED_TRANSACTIONS: "impacted_transactions",

	// Access reviews
	APP_OVERVIEW_USERS: "app_review_users",
	APP_OVERVIEW_USERS_EMP_VIEW: "app_users_emp_view",
	REVIEWER_TABLE: "access_cert_reviewers",
	SELECT_USERS: "select_users",
	SELECT_COLUMNS: "select_columns",
	APP_REALIZED_SAVINGS_USERS_BREAKDOWN:
		"app_realized_savings_users_breakdown",

	// for Application
	APPLICATION: "application",
	ALL_APPLICATION: "allApplications",
	MANAGED: "managed",
	NEEDS_REVIEW: "needsReview", //(??)
	RESTRICTED: "restricted",
	UNMANAGED: "unmanaged",

	// for tasks
	TASKS: "tasks",
	PENDING: "pending",
	COMPLETED: "completed",
	CANCELLED: "cancelled",

	VIEWS: "views", // (??)
	APP_CONTRACTS: "app_contracts",
} as const;

export type TableEntityType =
	(typeof TableEntityEnum)[keyof typeof TableEntityEnum];
export const API_TYPES = {
	LIST: "listApi",
	FILTER: "filterApi",
	PATCH: "patchApi",
	TABCOUNT: "tabCount",
	NODE_LIST: "nodeListApi", // (??)
} as const;

type ApiType = (typeof API_TYPES)[keyof typeof API_TYPES];

const entityApiMap: Record<TableEntityType, Partial<Record<ApiType, any>>> = {
	select_users: {
		listApi: getAllApplicationUsers,
		filterApi: getAppUsersPropertiesList,
	},
	select_columns: {
		listApi: getAllApplicationUsers,
		filterApi: getAppUsersPropertiesList,
	},
	application_users: {
		listApi: getApplicationUsers,
		filterApi: getAppUserPropertiesList,
	},
	app_review_users: {
		listApi: getAllCertificationAppUsers,
		filterApi: getAccessCertificateApplicationFilters,
		patchApi: patchUserReviewStatus,
	},
	app_critical_users: {
		listApi: getAppCriticalUsersList,
		filterApi: getAppCriticalUsersProperties,
	},
	app_users_emp_view: {
		listApi: getAllCertificationAppUsersEmpView,
		filterApi: getAccessCertificateApplicationFiltersForEmployee,
		patchApi: patchUserReviewStatusEmpView,
	},
	app_spends_transaction: {
		listApi: getALLSpendsTransactionTable,
		filterApi: getSpendsTransactionTable,
	},
	application: {
		listApi: getAllApplications,
		filterApi: getAppPropertiesListv3,
		patchApi: patchApplication,
		tabCount: getAppTabCount,
	},
	allApplications: { listApi: getAllApplications },
	managed: { listApi: getAllApplications },
	unmanaged: { listApi: getUnmanagedApplications },
	restricted: { listApi: getRestrictedApplications },
	needsReview: { listApi: getNeedsReviewApplications },
	redundant_optimization: {
		listApi: getAllRedundantOptimizationData,
		filterApi: getRedundantOptimizationPropertiesListv3,
	},
	tasks: {
		listApi: getAllTasks,
		filterApi: getTasksPropertiesListv3,
		tabCount: getTasksTabCount,
	},
	pending: {
		listApi: getAllTasks,
		filterApi: getTasksPropertiesListv3,
		tabCount: getTasksTabCount,
	},
	completed: {
		listApi: getAllTasks,
		filterApi: getTasksPropertiesListv3,
		tabCount: getTasksTabCount,
	},
	cancelled: {
		listApi: getAllTasks,
		filterApi: getTasksPropertiesListv3,
		tabCount: getTasksTabCount,
	},
	desktop_agents: {
		listApi: getAllDesktopAgentsTable,
		filterApi: getDesktopAgentsTable,
	},
	views: { listApi: getViewsv2 },
	"Application Chargebacks": {
		listApi: getApplicationChargebacks,
		filterApi: getAppChargeBackPropertiesListv3,
		nodeListApi: getApplicationChargebackSubDepartments,
	},
	spends_rules: {
		listApi: getSpendsRules,
		filterApi: getSpendsRulesPropertiesList,
	},
	app_previous_optimization_logs: {
		listApi: getPastOptimizationLogs,
		filterApi: getAppOptimizationPastLogsPropertiesList,
	},
	app_optimization_review_users: {
		listApi: getOptimizationReviewUsers,
		filterApi: getAppOptimizationReviewUsersPropertiesList,
	},
	app_optimization_previous_optimization_runs: {
		listApi: getPreviousOptimizationRuns,
		filterApi: getPreviousOptimizationRunsPropertiesList,
	},
	app_licenses: {
		listApi: getAppLicensesV2,
		filterApi: getAppLicensesV2Properties,
	},
	app_contracts: {
		listApi: getAppContractsV2,
		filterApi: getAppContractsV2Properties,
	},
	app_map_licenses: {
		listApi: getAppMapLicensesV2,
		filterApi: getAppMapLicensesV2Properties,
	},
	access_cert_reviewers: {
		listApi: getCertificationReviewersData,
		filterApi: getCertificationReviewersDataPropertiesList,
	},
	preview_matching_transactions: {
		listApi: getPreviewMatchingTransactions,
		filterApi: getPreviewMatchingTransactionsPropertiesList,
	},
	impacted_transactions: {
		listApi: getImpactedTransactions,
		filterApi: getPreviewMatchingTransactionsPropertiesList,
	},
	app_realized_savings: {
		filterApi: getAppRealizedSavingsPropertiesV3,
		listApi: getAppRealizedSavingsListV3,
	},
	app_realized_savings_users_breakdown: {
		filterApi: getAppRealizedSavingsUsersBreakdownPropertiesV3,
		listApi: getAppRealizedSavingsUsersBreakdownListV3,
	},
};

export function getEntityApi(entity: TableEntityType, apiType: ApiType) {
	return entityApiMap[entity][apiType];
}

export const csvFileFormat = {
	GENERAL: "general",
	EXCEL: "excel",
};
