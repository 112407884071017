import React, { useMemo } from "react";

import PropTypes from "prop-types";
import { isEmpty } from "underscore";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import { NoDataCard } from "@zluri/ui-components";
import SomethingWentWrong from "../../Spends/SomethingWentWrong/SomethingWentWrong";
import ContentLoader from "react-content-loader";
import Footer from "./Footer";

import emptyEvents from "assets/applications/emptyEvents.svg";
import ErrorWrapper from "UIComponents/Rbac/ErrorWrapper";

const HighChartsContainer = ({
	loading,
	loaded,
	error,
	refreshCallBack,
	hasData,
	chartOptions,
	splitBy,
	legends,
}) => {
	const options = useMemo(() => chartOptions, [chartOptions]);

	return (
		<>
			{loading && !loaded && (
				<div
					className="h-100 position-absolute w-100 d-flex flex-1 z_highcharts_container-loader"
					style={{ left: 0, top: 0, zIndex: 1000 }}
				>
					<ContentLoader
						width="100%"
						height={245}
						backgroundColor="#fafafa"
						foregroundColor="#ffffff"
						opacity={0.8}
					>
						<rect width="100%" height="100%" rx="2" />
					</ContentLoader>
				</div>
			)}
			{!isEmpty(error) ? (
				<ErrorWrapper
					error={error}
					size="medium"
					className="z_highcharts_container-error_card"
					refreshCall={refreshCallBack && refreshCallBack}
				/>
			) : !hasData && !loading ? (
				<NoDataCard
					className="z_highcharts_container-nodata_card"
					iconContainerStyle={{
						width: "64px",
						height: "64px",
					}}
					title="No Data Available"
					icon={emptyEvents}
				/>
			) : (
				<>
					<HighchartsReact
						highcharts={Highcharts}
						options={options}
						constructorType="chart"
					/>
				</>
			)}
			{!error && !loading && hasData && (
				<span
					className="z_highcharts_container-footer"
					style={{ position: "absolute", right: "0", bottom: "0" }}
				>
					<Footer legends={legends} split_by={splitBy} />
				</span>
			)}
		</>
	);
};

export default React.memo(HighChartsContainer);

HighChartsContainer.propTypes = {
	loading: PropTypes.bool,
	loaded: PropTypes.bool,
	error: PropTypes.object,
	refreshCallBack: PropTypes.func,
	hasData: PropTypes.bool,
	chartOptions: PropTypes.object,
	splitBy: PropTypes.array,
	legends: PropTypes.array,
};
