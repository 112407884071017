import queryString from "query-string";

interface IParseTemplateString<T> {
	template: T;
	data: Record<string, any>;
}
export function parseTemplateString<T>({
	template,
	data,
}: IParseTemplateString<T>): T {
	try {
		const stringified = JSON.stringify(template);

		const parsedString = stringified
			.replaceAll("((", "{{")
			.replaceAll("))", "}}")
			.replace(/{{\s*([^}]+)\s*}}/g, (match, key) => {
				// Trim the key to remove any extra spaces
				key = key.trim();

				// Return the corresponding value from the data object
				return data[key] !== undefined ? data[key] : match;
			});
		return JSON.parse(parsedString);
	} catch (e) {
		console.error(e);
		return template;
	}
}

interface IGetRedirectionUrl {
	redirectUrl: string;
	hash: string;
	queryParams: Record<string, any>;
}
export function getRedirectionUrl({
	redirectUrl,
	hash,
	queryParams,
}: IGetRedirectionUrl): string {
	let query = "";
	if (queryParams) {
		const keysArray = Object.keys(queryParams);

		const parsedQueryParams = keysArray.reduce((obj, key) => {
			const _stringified = JSON.stringify(queryParams?.[key]);

			return { ...obj, [key]: _stringified };
		}, {});

		query = queryString.stringify(parsedQueryParams, {
			skipEmptyString: true,
			skipNull: true,
		});
	}

	return `${redirectUrl}${query ? "?" + query : ""}${hash ? "#" + hash : ""}`;
}
