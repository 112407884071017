import { folder_type } from "modules/applications/components/automation/appPlaybooks/appPlaybooks-constants";
import { filterPropertiesHelper } from "modules/v2InfiniteTable/utils/v2infiniteTableUtil";
import { MONTH } from "utils/DateUtility";
import { client, clientV2 } from "../../../../../utils/client";
import { getSegmentFromURL } from "@zluri/ui-components";

export async function getAllCompletedMiniplaybooks(
	reqBody,
	page,
	row,
	cancelTokenSource
) {
	let options = {
		params: {
			page,
			row,
			type: "onboarding",
		},
	};
	if (cancelTokenSource) {
		options.cancelToken = cancelTokenSource.token;
	}
	let currentappId = getSegmentFromURL(2);
	const response = await clientV2.post(
		`mini-playbooks/applications/${currentappId}/runs?row=${row}&page=${page}`,
		reqBody
	);
	return response?.data;
}

export async function getAllAppPlaybooksV2(
	type,
	pageNo,
	row,
	reqBody,
	currentappId
) {
	const response = await clientV2.post(
		`mini-playbooks/applications/${currentappId}/templates?page=${pageNo}&row=${row}&folder_type=${type}`,
		reqBody
	);
	return response?.data;
}

export async function getAllAppRulesV2(
	type,
	pageNo,
	row,
	reqBody,
	currentappId
) {
	const response = await clientV2.post(
		`mini-playbooks/${currentappId}/rules?page=${pageNo}&row=${row}&folder_type=${type}`,
		reqBody
	);
	return response?.data;
}

export async function addApplicationtoNodes(workflowId, appId, folderType) {
	const response = await client.put(
		`workflows/${workflowId}/nodes/add/application?module=template&type=license_optimization`,

		{
			app_id: appId,
			isAppAdded: true,
		}
	);
	return response.data;
}

export async function saveWorkFlowTemplate(workflowId, body) {
	const url = `mini-playbooks/${workflowId}/save/template?module=template`;
	const response = await client.post(url, body);
	return response.data;
}
export async function createTemplate(folderType) {
	let currentappId = getSegmentFromURL(2);
	const response = await client.post(
		`mini-playbooks/applications/${currentappId}/create/template`,
		{
			workflow: {
				folder_type: folderType,
				name: `${
					folder_type[folderType]
				} Playbook on ${new Date().getDate()} ${
					MONTH[new Date().getMonth()]
				}`,
			},
		}
	);
	return response?.data;
}

export async function getAppRunsFilters() {
	const response = await clientV2.get("mini-playbooks/runs/filters");
	let newResponseData = filterPropertiesHelper(response.data);
	response.data = newResponseData;
	return response;
}

export async function getAppRulesFilters() {
	const response = await clientV2.get("rules/filters");
	let newResponseData = filterPropertiesHelper(response.data);
	response.data = newResponseData;
	return response;
}

export async function getAppPlaybooks({
	name,
	row,
	page,
	template,
	searchOnlyPublishedAndNoVariables,
}) {
	let currentappId = getSegmentFromURL(2);
	const body = {
		filter_by: [],
		sort_by: [{ name: -1 }],
		columns: [],
	};
	if (name) {
		body.filter_by.push({
			field_values: [name],
			field_id: "name",
			filter_type: "search_in_string",
			field_order: ["contains"],
			negative: false,
		});
	}
	if (searchOnlyPublishedAndNoVariables) {
		body.filter_by.push(
			{
				field_values: [true],
				field_order: "contains",
				field_id: "is_published",
				filter_type: "boolean",
				field_name: "Published",
				negative: false,
				is_custom: false,
			},
			{
				field_values: false,
				field_id: "using_variables",
				filter_type: "boolean",
				field_name: "Variables",
				negative: false,
				is_custom: false,
			}
		);
	}
	const response = await clientV2.post(
		`mini-playbooks/applications/${currentappId}/templates?page=${page}&row=${row}${
			template ? `&folder_type=${template}` : ""
		}`,
		body
	);
	return response?.data;
}

export async function searchUserswithIds(userIds, workflow_template_id) {
	const body = {
		resource: "orguser",
		operation: "read",
		body: {
			identifier: {
				_id: {
					$in: userIds,
				},
			},
			projection: ["_id", "name", "email"],
			workflow_template_id: workflow_template_id,
		},
	};
	const response = await client.post("workflows/zluri-action", body);
	return response?.data;
}

export async function updateRule(body) {
	const ruleId = body?._id;
	const url = `mini-playbooks/rules/${ruleId}`;
	const response = await client.put(url, body);
	return response.data;
}

export async function createRule(folderType, entity = "application") {
	let currentappId = getSegmentFromURL(2);
	const folder_map = {
		provision: "Provision",
		deprovision: "Deprovision",
		license_management: "License Management",
	};
	const response = await client.post(
		`mini-playbooks/${currentappId}/rules?folder_type=${folderType}&entity=${entity}`,
		{
			name: `Automation rule for ${
				folder_map[folderType]
			} on ${new Date().getDate()} ${MONTH[new Date().getMonth()]}`,
			description: `Created ${entity} rule`,
		}
	);
	return response?.data;
}

export async function deleteAppRule(ruleId) {
	const url = `mini-playbooks/rules/${ruleId}`;
	const response = await client.delete(url);
	return response.data;
}

export async function getMostUsedAppPlaybookService(type, pageNo, row = 3) {
	const response = await client.get(
		`mini-playbooks/templates/most-used?page=${pageNo}&row=${row}&folder_type=${type}`
	);
	return response?.data;
}

export async function updateOrderOfRule({ id, type, currentOrder, newOrder }) {
	const response = await client.put(
		`mini-playbooks/rules/${id}/order?folder_type=${type}&current_order=${currentOrder}&new_order=${newOrder}`
	);
	return response.data;
}

export async function getAppRules({
	appId,
	pageNo,
	row,
	reqBody,
	tag,
	options = {},
}) {
	const response = await clientV2.post(
		`mini-playbooks/${appId}/rules?page=${pageNo}&row=${row}&folder_type=${tag}&entity=application&tag=${tag}`,
		reqBody
	);

	return response?.data;
}
