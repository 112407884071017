import React, { useCallback, useEffect, useRef, useState } from "react";
import "./SearchSelect.css";

import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { isEmpty } from "underscore";
import moment from "moment";
import debounce from "lodash.debounce";

import {
	BODY_VARIANT,
	Button,
	Divider,
	GREY_VARIANT,
	Loader,
	SearchBox,
	TOAST_COLOR,
	Typography,
	theme,
	toast,
	useGetSegmentFromURL,
} from "@zluri/ui-components";

import tick from "assets/tick-mark.svg";
import applicationIcon from "assets/common/Icon_Application_Outline.svg";
import plusIcon from "assets/icons/v2Icons/Icon_Plus.svg";

import { handleEllipseText } from "modules/applications/constants/AppLicensesConstants";
import { getUserAppRolesForFilter } from "services/api/applicationsv2";
import { TOAST_MESSAGE } from "modules/shared/constants/toast.constants";
import { TriggerIssue } from "utils/sentry";
import {
	addNewRole,
	searchUserAppRoles,
	unAssignRole,
	updateRole,
} from "services/api/roleApi";
import { client } from "utils/client";

export function UserAppRolePopover(props) {
	const {
		data,
		component: {
			valueKey,
			hideConfigure = false,
			ignoreFirstValue = false,
		},
		rowIndex,
		columnIndex,
		entity,
		hidePopup,
	} = props;
	let userId = data?._id;

	const [show, setShow] = useState(false);
	const [hideConfigurePopover, setHideConfigurePopover] =
		useState(hideConfigure);
	const dispatch = useDispatch();
	const appId = useGetSegmentFromURL(2);

	const roles = data?.[valueKey]?.slice(ignoreFirstValue ? 1 : 0);

	const addOrRemoveRole = async (newRoleValue, isAddRole, isEditing) => {
		const successToastMessage = isAddRole
			? TOAST_MESSAGE[
					isEditing ? "ROLE_UPDATE_SUCCESS" : "ROLE_ADD_SUCCESS"
				]
			: "Removed Role Successfully";
		const errorToastMessage = isAddRole
			? TOAST_MESSAGE[isEditing ? "ROLE_UPDATE_ERROR" : "ROLE_ADD_ERROR"]
			: "Removed Role Successfully";
		dispatch({
			type: "UPDATE_RECORD",
			payload: {
				rowIndex: rowIndex,
				columnIndex: columnIndex,
				skipApi: true,
			},
		});
		try {
			isAddRole
				? await updateRole(appId, [userId], newRoleValue?.[0])
				: await unAssignRole(appId, [userId]);
			toast(successToastMessage, {
				indicatorColor: TOAST_COLOR.SUCCESS,
			});
			dispatch({
				type: "RECORD_UPDATED",
				payload: {
					entity: entity,
					rowIndex: rowIndex,
					columnIndex: columnIndex,
					index: rowIndex,
					value: {
						user_app_role_array: isAddRole
							? newRoleValue.map((role) => {
									return role;
								})
							: null,
					},
				},
			});
		} catch (error) {
			dispatch({
				type: "RECORD_UPDATE_FAILED",
				payload: {
					entity: entity,
					rowIndex: rowIndex,
					columnIndex: columnIndex,
					errorMessage: "Error!",
				},
			});
			toast(errorToastMessage, {
				indicatorColor: TOAST_COLOR.ERROR,
			});
			TriggerIssue(errorToastMessage, error);
		} finally {
			hidePopup && hidePopup();
		}
	};

	return show || hideConfigurePopover ? (
		<RolesSearchSelect
			{...props}
			selectedNames={roles}
			onSuccess={() => setHideConfigurePopover(false)}
			userId={data?._id}
			addOrRemoveRole={addOrRemoveRole}
		/>
	) : (
		<div className="w-100">
			<div
				className="d-flex gap-4 w-100 align-items-center"
				style={{ padding: "5px", flexDirection: "column" }}
			>
				<div
					className="cell-header__menu-option cursor-pointer z-option-menu__option "
					onClick={() => {
						setShow(true);
						setHideConfigurePopover(true);
					}}
				>
					<Typography variant="button_extrasmall_medium">
						Change role
					</Typography>
				</div>
				<div
					className="cell-header__menu-option  cursor-pointer z-option-menu__option "
					onClick={() => {
						addOrRemoveRole();
						setShow(false);
					}}
				>
					<Typography variant="button_extrasmall_medium">
						Remove role
					</Typography>
				</div>
			</div>
		</div>
	);
}

UserAppRolePopover.propTypes = {
	data: PropTypes.object,
	component: PropTypes.shape({
		valueKey: PropTypes.string,
		hideConfigure: PropTypes.bool,
		ignoreFirstValue: PropTypes.bool,
	}),
};

const FETCH_STATUS = {
	IDLE: "IDLE",
	PENDING: "PENDING",
	SUCCESS: "SUCCESS",
	ERROR: "ERROR",
};

export default function RolesSearchSelect({
	selectedNames = [],
	hidePopup,
	addOrRemoveRole,
}) {
	const [roleStatus, setRoleStatus] = useState({
		status: FETCH_STATUS.IDLE,
	});
	const [searchData, setSearchData] = useState("");
	const [selectedOptions, setSelectedOptions] = useState([]);

	const appId = useGetSegmentFromURL(2);
	const cancelTokenRef = useRef(null);

	const fetchRoles = async () => {
		try {
			setRoleStatus({ status: FETCH_STATUS.PENDING });
			const roles = await getUserAppRolesForFilter({ appId });
			setRoleStatus({ status: FETCH_STATUS.SUCCESS, data: roles });
			setSelectedOptions(
				roles?.filter((role) => isSelected(selectedNames, role))
			);
		} catch (err) {
			setRoleStatus({
				status: FETCH_STATUS.ERROR,
				message: err?.message,
			});
		}
	};
	const searchRoles = useCallback(async (searchQuery) => {
		setRoleStatus({ status: FETCH_STATUS.PENDING });
		if (searchQuery === "") {
			return fetchRoles();
		}

		if (cancelTokenRef.current)
			cancelTokenRef.current.cancel(
				"Operation cancelled in favor of a new request"
			);
		cancelTokenRef.current = client.CancelToken.source();
		try {
			const roles = await searchUserAppRoles(
				appId,
				searchQuery,
				cancelTokenRef.current
			);
			const rolesValues = roles.map((role) => role.role_value);
			setRoleStatus({ status: FETCH_STATUS.SUCCESS, data: rolesValues });
			setSelectedOptions(
				rolesValues?.filter((role) => isSelected(selectedNames, role))
			);
		} catch (err) {
			if (cancelTokenRef.current === client.CancelToken.source()) {
				setRoleStatus({
					status: FETCH_STATUS.ERROR,
					message: err?.message,
				});
			}
		}
	}, []);

	function handleChange(e) {
		const query = e.target.value;
		setSearchData(query);
		debouncedSearch(query);
	}

	const debouncedSearch = useCallback(
		debounce((query) => searchRoles(query), 300),
		[searchRoles]
	);

	useEffect(() => {
		return () => {
			if (cancelTokenRef.current) {
				cancelTokenRef.current.cancel();
			}
		};
	}, []);

	useEffect(() => {
		roleStatus.status === FETCH_STATUS.IDLE && fetchRoles();
	}, [roleStatus, appId, selectedNames]);

	const isSelected = (selectedOptions, selectedRole) => {
		return selectedOptions.some(
			(role) => role.toLowerCase() === selectedRole.toLowerCase()
		);
	};

	const handleOptionToggle = (selectedRole) => {
		setSelectedOptions([selectedRole]);
		// TODO: use the below code once we use multiple roles.
		// if (isSelected(selectedOptions, selectedRole)) {
		// 	setSelectedOptions(
		// 		selectedOptions.filter((role) => role !== selectedRole)
		// 	);
		// } else {
		// 	setSelectedOptions([...selectedOptions, selectedRole]);
		// }
	};

	const isEditing = selectedNames?.length;

	const handleSave = async (selectedRoles) => {
		addOrRemoveRole(selectedRoles, true, isEditing);
	};

	const handleAddRole = async () => {
		let newRole = searchData;
		if (!newRole) return;
		try {
			setRoleStatus({ status: FETCH_STATUS.PENDING });
			await addNewRole(appId, newRole);
			toast("Role Created Successfully", {
				indicatorColor: TOAST_COLOR.SUCCESS,
			});
			fetchRoles();
		} catch (error) {
			const message = "Error creating role";
			toast(message, {
				indicatorColor: TOAST_COLOR.ERROR,
			});
		} finally {
			setSearchData("");
		}
	};

	return (
		<div className="p-1 w-100">
			<SearchBox
				isCollapsible={false}
				onChangeText={(e) => {
					handleChange(e);
				}}
				value={searchData}
			/>
			<div style={{ marginBlock: "2px" }}>
				<Divider
					height="1px"
					backgroundColor="#E1E8F8"
					borderRadius="8px"
				/>
			</div>
			<div
				className="overflow-auto d-flex flex-column gap-2"
				style={{ maxHeight: "120px" }}
			>
				{roleStatus.status === FETCH_STATUS.PENDING && (
					<div
						className="d-flex justify-content-center align-items-center"
						style={{ height: "100px" }}
					>
						<Loader />
					</div>
				)}
				{Array.isArray(roleStatus?.data) &&
					!roleStatus?.data.length &&
					searchData && (
						<div
							onClick={() => handleAddRole()}
							className="flex align-items-center gap-1 p-2 cursor-pointer"
						>
							<img width={"12px"} src={plusIcon} />
							<div className="pl-1 font-10">
								Create "{searchData || "new"}" Role
							</div>
						</div>
					)}
				{roleStatus.status === FETCH_STATUS.SUCCESS &&
					roleStatus?.data?.map((role, idx) => (
						<div
							key={idx}
							className={`d-flex justify-content-between align-items-center w-100 gap-2 border-radius-8 cursor-pointer select_options ${
								isSelected(selectedOptions, role) &&
								"search_selected"
							}`}
							style={{
								padding: "6px",
								height: "30px",
							}}
							onClick={() => handleOptionToggle(role)}
						>
							<Typography
								variant={BODY_VARIANT.BODY_2_BOLD}
								color={GREY_VARIANT.SECONDARY_GREY_2}
							>
								{handleEllipseText(role, role, "100px")}
							</Typography>
							<div className="w-10">
								{isSelected(selectedOptions, role) && (
									<img src={tick} alt="tick" />
								)}
							</div>
						</div>
					))}
			</div>
			<FooterAction
				selectedRoles={selectedOptions}
				handleSave={handleSave}
				hidePopup={hidePopup}
			/>
		</div>
	);
}
RolesSearchSelect.propTypes = {
	selectedNames: PropTypes.array,
	rowIndex: PropTypes.number,
	columnIndex: PropTypes.number,
	entity: PropTypes.string,
	hidePopup: PropTypes.func,
	userId: PropTypes.string,
};

const FooterAction = ({ selectedRoles, handleSave, hidePopup }) => {
	return (
		<div className="z-v2-filter__footer w-100">
			<Button
				theme={theme}
				variant="primary-alternative"
				style={{ minWidth: 0, width: "100%" }}
				onClick={() => hidePopup && hidePopup()}
			>
				Cancel
			</Button>
			<Button
				theme={theme}
				variant="primary-default"
				className="w-100"
				style={{ minWidth: 0, width: "100%" }}
				onClick={() => handleSave(selectedRoles)}
				disabled={isEmpty(selectedRoles)}
			>
				Save
			</Button>
		</div>
	);
};
FooterAction.propTypes = {
	selectedRoles: PropTypes.array,
	handleSave: PropTypes.func,
	hidePopup: PropTypes.func,
};
