import React from "react";
import PropTypes from "prop-types";

import SelectEntity from "containers/v2table/cellRenderer/SelectEntity";
import { ownerKeys } from "modules/applications/constants/AppAbout.constants";
import { AppUserOption } from "./AppUserOption";

export function AppOwnerEditPopover({ field_id, data }) {
	const { id, name, image, email, type } = ownerKeys?.[field_id];

	const component = {
		entity: "user",
		updateKeys: {
			id,
			name,
			image,
			email,
			type,
		},
		customApi: "ownerTask",
	};

	const optionsFormatter = (selected, handleSelect) => {
		const onSelect = () => handleSelect(selected);
		return (
			<AppUserOption
				id={selected?.user_id}
				name={selected?.user_name}
				imageSrc={selected?.profile_img}
				selectedId={data?.[ownerKeys[field_id]?.id]}
				handleSelect={onSelect}
			/>
		);
	};

	return (
		<SelectEntity
			component={component}
			appId={data["app_id"]}
			data={data}
			hideScrollAndDivider
			hideEmail
			optionsMaxHeight={170}
			optionsFormatter={optionsFormatter}
			containerStyle={{ padding: 2 }}
		/>
	);
}

AppOwnerEditPopover.propTypes = {
	field_id: PropTypes.string,
	data: PropTypes.shape({
		app_id: PropTypes.string,
	}),
};
