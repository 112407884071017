import React from "react";
import { getValueFromLocalStorage } from "utils/localStorage";
import { userRoles } from "constants/userRole";
const RoleContext = React.createContext({
	userRole: getValueFromLocalStorage("userInfo")?.user_role || "",
	isViewer: getValueFromLocalStorage("userInfo")?.isViewer || "",
	partner: getValueFromLocalStorage("partner") || {},
	hiddenModules:
		getValueFromLocalStorage("userInfo")?.org_plan?.hide_modules || [],
	enableBetaFeatures:
		getValueFromLocalStorage("userInfo")?.org_beta_features || [],
	isItViewer:
		getValueFromLocalStorage("userInfo")?.user_role === userRoles.IT_VIEWER
			? true
			: false,
});

export const RoleProvider = RoleContext.Provider;
export const RoleConsumer = RoleContext.Consumer;

export default RoleContext;
