import React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import { client } from "utils/client";
import { debounce, unescape } from "utils/common";
import { ImageOrNameBadge, SearchBox, Typography } from "@zluri/ui-components";
import {
	checkSpecialCharacters,
	searchAllApps,
	searchUsers,
} from "services/api/search";
import tickIcon from "assets/icons/selected_view.svg";
import PropTypes from "prop-types";

import "components/Applications/Overview/Overview.css";
import { Loader } from "@zluri/ui-components";
import { TriggerIssue } from "utils/sentry";

import { isEmpty } from "underscore";

function getApiProps() {
	return {
		orgusers: {
			searchAPI: (query = "", cancelToken) => {
				return searchUsers(query, cancelToken);
			},
			placeholder: "Search Users",
			id_key: "user_id",
			name_key: "user_name",
			image_key: "profile_img",
			email_key: "user_email",
			triggerMessage: "Error while Fetching Users",
			getApi: searchUsers,
			dataAccessor: "results", // TODO: prj find a better way. what happens if data is nested?
		},
		orgapplications: {
			searchAPI: (query = "", cancelToken) => {
				return searchAllApps(query, cancelToken, true);
			},
			placeholder: "Search Applications",
			id_key: "app_id",
			name_key: "app_name",
			image_key: "app_logo",
			triggerMessage: "Error while Fetching Applications",
			getApi: searchAllApps,
			dataAccessor: "results", // TODO: prj find a better way. what happens if data is nested?
		},
	};
}

//TODO: This is just a copy from dynamif_filters for now
// TODO: prj -> This needs to be edited
export default function EntitySelector(props) {
	const { onSelect, reference } = props;

	const searchEntity = reference;

	const apiAndOtherInfo = getApiProps();
	const [loadingDefaultOptions, setLoadingDefaultOptions] = useState(false);
	const [defaultOptions, setDefaultOptions] = useState();
	const [options, setOptions] = useState([]);
	const [loading, setloading] = useState(false);
	const [searchText, setSearchText] = useState("");
	const cancelToken = useRef();
	const initialOptions = useRef([]); // for storing initial default options since it can be modified later on
	const hadSelected = useRef([]); // for storing selected values even after unselecting

	const imageKey = apiAndOtherInfo[searchEntity]?.image_key;
	const nameKey = apiAndOtherInfo[searchEntity]?.name_key;
	const idKey = apiAndOtherInfo[searchEntity]?.id_key;
	const dataAccessor = apiAndOtherInfo[searchEntity]?.dataAccessor;

	useEffect(() => {
		if (!defaultOptions) {
			setLoadingDefaultOptions(true);
			apiAndOtherInfo[searchEntity]
				.getApi("")
				.then((res) => {
					if (
						Array.isArray(res) &&
						res.length > 0 &&
						typeof res[0] === "string"
					) {
						let temp = res.map((el) => {
							return {
								value: el,
								_id: el,
							};
						});
						initialOptions.current = removeDuplicateId(
							[...hadSelected.current, ...temp],
							idKey
						);
						setDefaultOptions(initialOptions.current);
					} else if (res && dataAccessor) {
						initialOptions.current = removeDuplicateId(
							[...hadSelected.current, ...res?.[dataAccessor]],
							idKey
						);
						setDefaultOptions(initialOptions.current);
					} else {
						initialOptions.current = removeDuplicateId(
							[...hadSelected.current, ...res],
							idKey
						);
						setDefaultOptions(initialOptions.current);
					}
				})
				.catch((err) => {
					TriggerIssue(
						`${apiAndOtherInfo[searchEntity].triggerMessage}`
					);
				})
				.finally(() => setLoadingDefaultOptions(false));
		}
	}, []);

	const handleEdit = (text) => {
		if (cancelToken.current) cancelToken.current.cancel();
		if (text.length === 0) {
			setOptions([...(defaultOptions ?? [])]);
			setloading(false);
		}
		if (text.length > 1) {
			if (checkSpecialCharacters(text, true)) {
				setOptions([]);
				setloading(false);
			} else {
				setloading(true);
				cancelToken.current = client.CancelToken.source();
				generateSuggestions(text, cancelToken.current);
			}
		}
	};

	const generateSuggestions = useCallback(
		debounce((query, cancelToken) => {
			apiAndOtherInfo[searchEntity]
				.searchAPI(query, cancelToken)
				.then((res) => {
					if (dataAccessor) {
						setOptions(res?.[dataAccessor]);
					} else {
						setOptions(res);
					}
				})
				.catch((err) => {
					TriggerIssue(
						"There was an error while generating suggestions",
						err
					);
				})
				.finally(() => {
					setloading(false);
					setLoadingDefaultOptions(false);
				});
		}, 200),
		[]
	);

	const suggestionOptions = isEmpty(searchText) ? defaultOptions : options;

	return (
		<div>
			<div
				className="p-1"
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
				}}
			>
				<div className="dynamic_filter_container">
					<div />
					<SearchBox
						value={searchText}
						onChangeText={(e) => {
							setSearchText(e.target.value);
							handleEdit(e.target.value);
						}}
						onClear={() => {
							handleEdit("");
							setSearchText("");
						}}
						isCollapsible={false}
					/>
				</div>
				{loadingDefaultOptions || loading ? (
					<>
						<div
							style={{
								margin: "4px ",
								backgroundColor: "#F6F7FA",
							}}
						>
							<Loader color="blue" width={32} height={32} />
						</div>
					</>
				) : (Array.isArray(suggestionOptions) &&
						suggestionOptions.length > 0) ||
				  (Array.isArray(options) && options.length > 0) ? (
					<div
						style={{ height: "128px", overflowY: "auto" }}
						className="w-100"
					>
						{suggestionOptions.map((option) => {
							const isSelected = false;
							return (
								<>
									<div
										role="listitem"
										className="d-flex justify-content-between z-v2_table_cell_popover__content"
										style={{
											padding: "5px",
										}}
										onClick={() => {
											onSelect({
												value: option[idKey],
												label: option[nameKey],
												image: option[imageKey],
											});
										}}
										key={option?.[idKey]}
									>
										<div className="d-flex align-items-center ml-2">
											<ImageOrNameBadge
												height="16px"
												width="16px"
												url={unescape(option[imageKey])}
												name={option[nameKey]}
												fontSize="9px"
												className="mr-2"
												nameBadgeClasses="mr-2"
											/>
											<div
												className={
													"truncate_10vw flex flex-column"
												}
											>
												<Typography
													variant={
														"button_extrasmall_medium"
													}
													color="secondary_grey_2"
													className="text-capitalize"
												>
													{option[nameKey]}
												</Typography>
												<Typography
													variant={"body_3_regular"}
													color="secondary_grey_3"
													className="text-capitalize"
												>
													{option?.user_email}
												</Typography>
											</div>
										</div>
										{isSelected && (
											<img
												src={tickIcon}
												alt="tick icon"
											/>
										)}
									</div>
								</>
							);
						})}
					</div>
				) : (
					<div
						className="d-flex align-items-center justify-content-center"
						style={{
							margin: "4px ",
							backgroundColor: "#F6F7FA",
							height: "32px",
						}}
					>
						<Typography
							variant="button_extrasmall_regular"
							color="secondary-grey-1"
						>
							No matching items found.
						</Typography>
					</div>
				)}
			</div>
		</div>
	);
}

// Used for removing duplicated ids, when selecting default options to show
function removeDuplicateId(array, idKey) {
	return array.reduce((acc, curr) => {
		const existingItem = acc.find(
			(item) => item?.[idKey] === curr?.[idKey]
		);
		if (!existingItem) acc.push(curr);
		return acc;
	}, []);
}

EntitySelector.propTypes = {
	onSelect: PropTypes.func,
	reference: PropTypes.oneOf("orgusers", "orgapplications"),
};
