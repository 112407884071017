import React from "react";
import exclamation from "assets/exclamation.svg";
import { CustomCellComponent } from "./componentFactory";
import "./style.css";
import classNames from "classnames";
import { Typography } from "@zluri/ui-components";

export default function ViewCell({ component, loading, ...props }) {
	return (
		<div
			className={classNames(
				"v2tables__cell-view__cell",
				"w-100",
				{ "v2tables__cell-edit-overlay": props.isEditable },

				{
					cell__error:
						(component?.errorKey &&
							!!props?.data?.[component.errorKey]) ||
						loading?.[props.rowIndex]?.[props.columnIndex]?.error,
				}
			)}
		>
			<div
				style={{
					borderTopColor: component?.errorColor
						? "#FFA217"
						: "#FE6955",
				}}
				className="cell__error__collor"
			></div>
			<div className={classNames("cell__update__failed pr-1")}>
				<img
					className="mx-1"
					height={8}
					src={exclamation}
					style={{ color: "white" }}
					alt=""
				/>
				<Typography
					className="cell__error-text"
					variant="body_3_regular"
					color="white"
				>
					{loading?.[props.rowIndex]?.[props.columnIndex]
						?.errorMessage
						? loading?.[props.rowIndex]?.[props.columnIndex]
								?.errorMessage
						: "Update Failed"}
				</Typography>
			</div>
			{/*<CustomColumn component={component} isRoot {...props} />*/}
			<CustomCellComponent
				{...props}
				isRoot
				component={component}
				type={component.type}
			/>
		</div>
	);
}
