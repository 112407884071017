import React, { useRef } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useEffect } from "react";
import { dateResetTimeZone } from "utils/DateUtility";
import loadable from "@loadable/component";
const MomentTimezone = loadable.lib(() => import("moment-timezone"));

export default function CreatedAt(props) {
	const momentRef = useRef();
	const {
		text,
		user_name,
		source,
		rule_name,
		showTime = false,
		showAmPm = false,
		showName = true,
		showUTC = false,
		showAbbr = false,
		showScheduledTime = false,
		scheduledData,
	} = props;

	const localTimeZone = momentRef?.current?.default?.tz?.guess();
	const abbr = momentRef?.current?.default.tz(localTimeZone).zoneAbbr();

	return (
		<>
			<MomentTimezone ref={momentRef} />
			<div className="flex flex-row align-items-center created-at-component justify-content-start">
				<div className="d-flex flex-column created-at-container">
					<div className="truncate_20vw created-at-date">
						{showUTC ? (
							<MomentTimezone>
								{({ default: moment }) => (
									<>
										{moment(text).format("DD MMM 'YY") ===
										"Invalid date"
											? "N/A"
											: showTime
											  ? showAmPm
													? moment
															.utc(text)
															.format(
																"DD MMM 'YY, hh:mm A"
															)
													: moment(text)
															.utc(text)
															.format(
																"DD MMM 'YY, HH:mm"
															)
											  : moment(text)
														.utc(text)
														.format(
															"DD MMM 'YY"
														)}{" "}
										{showAbbr && abbr
											? `${"( " + abbr + " )"}`
											: ""}
									</>
								)}
							</MomentTimezone>
						) : showScheduledTime && scheduledData ? (
							<MomentTimezone>
								{({ default: moment }) => (
									<>
										{scheduledData?.label_date
											? moment(
													scheduledData?.label_date
											  ).format("DD MMM 'YY") ===
											  "Invalid date"
												? "N/A"
												: moment
														.utc(
															scheduledData?.label_date
														)
														.format("DD MMM 'YY, ")
											: "N/A"}
										{scheduledData?.time}{" "}
										{showAbbr &&
										scheduledData &&
										scheduledData?.timezone
											? `${
													"( " +
													scheduledData?.timezone
														?.abbr +
													" )"
											  }`
											: ""}
									</>
								)}
							</MomentTimezone>
						) : (
							<MomentTimezone>
								{({ default: moment }) => (
									<>
										{text
											? moment(text).format(
													"DD MMM 'YY"
											  ) === "Invalid date"
												? "N/A"
												: showTime
												  ? showAmPm
														? moment(text).format(
																"DD MMM 'YY, hh:mm A"
														  )
														: moment(text).format(
																"DD MMM 'YY, HH:mm"
														  )
												  : moment(text).format(
															"DD MMM 'YY"
												    )
											: "N/A"}{" "}
										{showAbbr && abbr
											? `${"( " + abbr + " )"}`
											: ""}
									</>
								)}
							</MomentTimezone>
						)}
					</div>

					{showName &&
						(source && rule_name ? (
							<OverlayTrigger
								placement="top"
								overlay={<Tooltip>{rule_name}</Tooltip>}
							>
								<div className="truncate_10vw created-at-user">
									triggered by {rule_name || ""}
								</div>
							</OverlayTrigger>
						) : (
							<OverlayTrigger
								placement="top"
								overlay={<Tooltip>{user_name}</Tooltip>}
							>
								<div className="truncate_10vw created-at-user">
									by {user_name || "N/A"}
								</div>
							</OverlayTrigger>
						))}
				</div>
			</div>
		</>
	);
}
