import {
	BADGE_VARIANTS,
	BODY_VARIANT,
	Button,
	GREY_VARIANT,
	ImageOrNameBadge,
	Tooltip,
	Typography,
	theme,
} from "@zluri/ui-components";
import { activityStatus, modalCard } from "./SourcePopOverConstant.js";
import { getSpendsTransactionTableSource } from "modules/applications/service/Applications.api";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { TriggerIssue } from "utils/sentry";
import csvIcon from "assets/applications/Icon_CSVFile.svg";
import manualIcon from "assets/applications/Icon_Manual.svg";
import { capitalizeFirstLetter, debounce } from "utils/common";
import moment from "moment-timezone";
import { handleSyncedData } from "modules/applications/utils/Spends";
import { Skeleton } from "antd";
import { getLocalTimezoneString } from "utils/DateUtility";
import DownloadInSamePage from "modules/shared/components/DownloadInSamePage/DownloadInSamePage";
import { Spinner } from "react-bootstrap";
import { getDesktopAgentsSource } from "services/api/agents";

export default function sourcePopOver(props) {
	const { data, component } = props;
	const { id: appId } = useParams();
	const [sourceData, setSourceData] = useState();

	useEffect(() => {
		generateSuggestions();
	}, [data, appId]);

	const source_of_entity = {
		app_spends_transaction: {
			sourceAPI: getSpendsTransactionTableSource,
			reqBody: [appId, data[component?.valueKey]],
		},
		desktop_agents: {
			sourceAPI: getDesktopAgentsSource,
			reqBody: [data[component?.valueKey]],
		},
	};

	const generateSuggestions = useCallback(
		debounce(() => {
			source_of_entity[props.entity]
				.sourceAPI(...(source_of_entity[props.entity]?.reqBody || ""))
				.then((res) => {
					setSourceData(res);
				})
				.catch((err) => {
					TriggerIssue("Source Popover Not Found", err);
				});
		}, 200),
		[]
	);

	const handleStatus = (status) => {
		const statusCheck =
			status === activityStatus.CONNECTED
				? "activeStatus"
				: "inActiveStatusV2";
		return (
			<div className="d-flex align-items-center" style={{ gap: "2.5px" }}>
				<div className={`${statusCheck} d-flex`}></div>
				<div>{capitalizeFirstLetter(status)}</div>
			</div>
		);
	};

	const sourceDataType = (type, source_data) => {
		const SOURCETYPE = {
			manually: {
				icon: manualIcon,
				sourceName: "Manually added by",
				sourceNameStyle: {
					variant: BODY_VARIANT.BODY_3_MEDIUM,
					color: GREY_VARIANT.SECONDARY_GREY_3,
				},
				typeName: capitalizeFirstLetter(source_data),
				typeNameStyle: {
					variant: BODY_VARIANT.BODY_2_BOLD,
					color: GREY_VARIANT.SECONDARY_GREY_2,
				},
				typeStatus: source_data
					? moment(source_data)
							.tz(getLocalTimezoneString())
							.format("DD MMM 'YY")
					: null,
				typeTime: source_data
					? moment(source_data)
							.tz(getLocalTimezoneString())
							.format("HH:mm z")
					: null,
			},
			csv: {
				icon: csvIcon,
				sourceName: capitalizeFirstLetter(source_data),
				sourceNameStyle: {
					variant: BODY_VARIANT.BODY_2_BOLD,
					color: GREY_VARIANT.SECONDARY_GREY_2,
				},
				typeName: `by ${capitalizeFirstLetter(source_data)}`,
				typeNameStyle: {
					variant: BODY_VARIANT.BODY_3_MEDIUM,
					color: GREY_VARIANT.SECONDARY_GREY_3,
				},
				typeStatus: source_data
					? moment(source_data)
							.tz(getLocalTimezoneString())
							.format("DD MMM 'YY")
					: null,
				typeTime: source_data
					? moment(source_data)
							.tz(getLocalTimezoneString())
							.format("HH:mm z")
					: null,
				typeFooter: {
					url: source_data,
					data: "Download File",
				},
			},
			integration: {
				icon: source_data,
				sourceName: capitalizeFirstLetter(source_data),
				sourceNameStyle: {
					variant: BODY_VARIANT.BODY_2_BOLD,
					color: GREY_VARIANT.SECONDARY_GREY_2,
				},
				typeNameStyle: {
					variant: BODY_VARIANT.BODY_3_REGULAR,
					color: GREY_VARIANT.SECONDARY_GREY_3,
				},
				typeName: capitalizeFirstLetter(source_data),
				typeStatus: handleStatus(source_data),
				typeFooter: {
					data: source_data
						? handleSyncedData(source_data)?.pillValue
						: "No data available",
				},
			},
		};

		switch (type) {
			case modalCard.CSV:
				return SOURCETYPE.csv;
			case modalCard.MANNUAL:
				return SOURCETYPE.manually;
			case modalCard.INTEGRATION:
				return SOURCETYPE.integration;
			default:
				break;
		}
	};

	const handleInstanceType = () => {
		switch (true) {
			case sourceData?.source_type === modalCard.MANNUAL:
				return sourceData?.source_name;
			case sourceData?.source_type === modalCard.INTEGRATION:
				return sourceData?.global_integration_name;
			default:
				return sourceData?.transaction_description;
		}
	};

	return (
		<div
			className="d-flex flex-column justify-content-center align-content-center white_bg border-radius-8 p-2"
			style={{ ...component?.style }}
		>
			{!sourceData ? (
				<div className="d-flex flex-1 flex-row border-radius-8">
					<Skeleton
						active
						paragraph={{
							rows: 2,
							style: {
								marginBottom: "0px",
							},
						}}
					/>
				</div>
			) : !sourceData?.source_type ? (
				<Typography
					variant={BODY_VARIANT.BODY_2_BOLD}
					color={GREY_VARIANT.SECONDARY_GREY_2}
				>
					No data available
				</Typography>
			) : (
				<>
					<div className="d-flex justify-content-between align-items-center w-100">
						<div className="d-flex flex-row align-items-center">
							<div className="mr-2">
								<ImageOrNameBadge
									url={
										sourceDataType(
											sourceData?.source_type,
											sourceData?.source_img
										)?.icon
									}
									name={
										sourceDataType(
											sourceData?.source_type,
											sourceData?.source_name
										)?.sourceName
									}
									className="z-transaction-source_icon"
									width={20}
									height={18}
									variant={BADGE_VARIANTS.ROUND}
								/>
							</div>
							<div
								className="d-flex flex-column "
								style={{ maxWidth: "100%" }}
							>
								<Tooltip
									className="font-10"
									content={
										<>
											{
												sourceDataType(
													sourceData?.source_type,
													sourceData?.source_name
												)?.sourceName
											}
										</>
									}
								>
									<Typography
										variant={
											sourceDataType(
												sourceData?.source_type
											)?.sourceNameStyle?.variant
										}
										style={{ maxWidth: "100px" }}
										className="text-truncate-small"
										color={
											sourceDataType(
												sourceData?.source_type
											)?.sourceNameStyle?.color
										}
									>
										{
											sourceDataType(
												sourceData?.source_type,
												sourceData?.source_name
											)?.sourceName
										}
									</Typography>
								</Tooltip>

								<Tooltip
									className="font-10 text-truncate-small"
									content={
										<>
											{
												sourceDataType(
													sourceData?.source_type,
													handleInstanceType()
												)?.typeName
											}
										</>
									}
								>
									<Typography
										variant={
											sourceDataType(
												sourceData?.source_type
											)?.typeNameStyle?.variant
										}
										color={
											sourceDataType(
												sourceData?.source_type
											)?.typeNameStyle?.color
										}
										style={{ marginTop: "2px" }}
									>
										{
											sourceDataType(
												sourceData?.source_type,
												handleInstanceType()
											)?.typeName
										}
									</Typography>
								</Tooltip>
							</div>
						</div>

						<div className="d-flex flex-row align-items-center">
							<div
								className="d-flex flex-column align-items-end"
								style={{ maxWidth: "100%" }}
							>
								<Typography
									variant={BODY_VARIANT.BODY_3_REGULAR}
									color={GREY_VARIANT.SECONDARY_GREY_3}
								>
									{
										sourceDataType(
											sourceData?.source_type,
											sourceData?.org_integration_status ||
												sourceData?.upload_date
										)?.typeStatus
									}
								</Typography>
								<Typography
									variant={BODY_VARIANT.BODY_3_REGULAR}
									color={GREY_VARIANT.SECONDARY_GREY_3}
								>
									{
										sourceDataType(
											sourceData?.source_type,
											sourceData?.org_integration_status ||
												sourceData?.upload_date
										)?.typeTime
									}
								</Typography>
							</div>
						</div>
					</div>
					{sourceData?.source_type !== modalCard.MANNUAL && (
						<>
							<hr className="outer-border mt-2 m-0 "></hr>
							<div className="w-100">
								{sourceData?.source_type === modalCard.CSV ? (
									<Button
										disabled={!sourceData?.source_url}
										theme={theme}
										icon={null}
										variant="secondary-default"
										className="w-100 mt-1"
									>
										<DownloadInSamePage
											loader={
												<Spinner
													animation="border"
													role="status"
													color="#e8f0fc"
													size="sm"
													className="ml-2"
													style={{ borderWidth: 2 }}
												></Spinner>
											}
											s3LinkFromProps={
												sourceDataType(
													sourceData?.source_type,
													sourceData?.source_url
												)?.typeFooter?.url
											}
											doc={{
												type: "text/csv",
												name:
													sourceData?.source_name ??
													"transaction_source",
											}}
											downloadTransactionCSV
										>
											{
												sourceDataType(
													sourceData?.source_type,
													sourceData?.last_sync_date
												)?.typeFooter?.data
											}
										</DownloadInSamePage>
									</Button>
								) : (
									<Typography
										variant={BODY_VARIANT.BODY_3_MEDIUM}
										color={GREY_VARIANT.SECONDARY_GREY_2}
									>
										{
											sourceDataType(
												sourceData?.source_type,
												sourceData?.last_sync_date
											)?.typeFooter?.data
										}
									</Typography>
								)}
							</div>
						</>
					)}
				</>
			)}
		</div>
	);
}
