import GroupCellRenderer from "./group";
import ImageNameBadgeRenderer from "./ImageNameBadge";
import Label from "./label";
import Link from "./link";
import {
	ActiveUserPopOverRenderer,
	ChargebackPopOverRenderer,
	ImageCellRenderer,
	ImageListCellRenderer,
	LicensePopOver,
	LicenseTypePopOver,
	SourcePopOverRenderer,
	UserPopOverRenderer,
	ViewMoreRenderer,
	CategoryPopOverRenderer,
	ActiveUserIntegrationPopOverRenderer,
	OptimisationPopOver,
	OptimizedPopover,
	OptimizationTypePopOver,
	PaymentPopoverRenderer,
	SimpleViewMoreTextPopOver,
	LicenseTypeNamePopOver,
	ListRenderer,
	PrimarySourceCellRenderer,
} from "./image";
import LogoCellRenderer, { LogoArrayCellRenderer } from "./logo";
import SelectEntity, { LocalEntitySelector } from "./SelectEntity";
import {
	AppLicenseMappingDescription,
	CategoryDescRenderer,
	IntegrationConnectText,
	licenseTypeDesc,
	OwnerDetailsRenderer,
	SpendsBudgetDetailsRenderer,
	PrimaryTextRenderer,
	StatusAndTextRenderer,
	Text,
	TextCurrency,
	TextMap,
	TitleDescriptionArrRenderer,
	TitleDescriptionRenderer,
	UsageTextRenderer,
	firstElement,
	IntegrationSourceText,
	NewTitleDescriptionRenderer,
	CustomReviewerEdit,
	CustomReviewerNameComponent,
	SubCategoryRenderer,
	BooleanTextRenderer,
	FirstTextRenderer,
} from "./text";
import ChangeNumber, { ChangeSpend } from "./ChangeNumber";
import EditOnHover from "./EditOnHover";
import React, { useState } from "react";
import LicenseForm from "./LicenseForm";
import {
	ActionTag,
	agentTextRenderer,
	AppUserActionsPopover,
	AppRealizedSavingsAction,
	AutomatedActionStatus,
	CustomAppQuality,
	CustomAppSecurityCompliancePopup,
	CustomCertificationAction,
	CustomRisk,
	CustomRiskSetupPopup,
	CustomSecurityThreatPopup,
	CustomSecurityThreats,
	CustomTagsPopover,
	CustomTagsRenderer,
	CustomThreat,
	MarkAsComplete,
} from "./tags";
import withToolTip from "./withToolTip";
import AddWithEditRenderer from "./AddWithEdit";
import { Score, UsageBenchmark } from "./score";
import { DateTime } from "./dateTime";
import { UsageQuality } from "./usageQuality";
import { Progress } from "./progress";
import { Cost } from "./cost";
import { EmptyDirectIntegration } from "./EmptyDirectIntegration";
import { CustomSpendForecastPopup } from "./popovers/Spends";
import { Usage } from "./usage";
import { Contracts, UpcomingContracts } from "./contracts";
import FilterList from "./Filterlist";
import { AppStatePopover } from "./popovers/AppState";
import Filter from "./Filter";
import BooleanFilter from "./SingleSelectFilter";
import StaticSelect from "./StaticSelect";
import SpendsBudgetPopOver, {
	SpendsLastDatePopover,
} from "./popovers/SpendsBudgetPopOver";
import {
	ContractName,
	ContractsPopover,
	EmptyContactConnection,
} from "./popovers/ContractCustom";
import { CustomCircularProgress } from "./mini-charts/CustomCircularProgress";
import { CustomMeterChart } from "./mini-charts/CustomMeterChart";
import { UsageBenchmarkPopover, UsagePopover } from "./popovers/UsagePopovers";
import {
	AppStatus,
	AppStatusPopover,
	PlaybookStatus,
} from "./popovers/AppStatus";
import { ClusterApplications } from "./Cluster-application-list";
import TaskType from "./taskType";
import TaskActions from "./taskActions";
import {
	CustomNotificationText,
	CustomSwitch,
	CustomNotificationPopOver,
} from "./CustomSwitch";
import { Popover, getSegmentFromURL } from "@zluri/ui-components";
import CustomRenderingData from "./popovers/CustomRenderingData";
import HoverVendor from "./HoverVendor";
import SelectPaymentMethod from "./popovers/SelectPaymentMethod";
import { EmailPopover } from "./popovers/EmailPopover";
import ConditionsRenderer from "modules/transactionMapping/components/V2TableComponents/ConditionsRenderer";
import RuleActionRenderer from "modules/transactionMapping/components/V2TableComponents/RuleActionRenderer";
import StatusRenderer from "modules/transactionMapping/components/V2TableComponents/StatusRenderer";
import PriorityOrderRenderer from "modules/transactionMapping/components/V2TableComponents/PriorityOrderRenderer";
import OptionsRenderer from "modules/transactionMapping/components/V2TableComponents/OptionsRenderer";
import CustomTagsV2 from "./popovers/CustomTagsV2";
import LicensesPopoverV2 from "./popovers/LicensesPopoverV2";
import SearchSelect from "./popovers/SearchSelect/SearchSelect";
import { ActionButtonRenderer } from "./button";
import SourcePopOver from "./popovers/sourcePopOver";
import AddDesktopDropdownComponent from "modules/Agents/components/AddDropdownComponent/AddDropdownComponent";
import MultiLevelSource from "../SourceTypeFilter/SourceTypeFilter";
import TransactionMappedRenderer from "modules/transactionMapping/components/V2TableComponents/TransactionMappedRenderer";
import ArchivedStatus from "./ArchivedStatus";
import { InstanceListPopover } from "./popovers/InstanceList";
import { Path } from "./path";
import { CostCentrePopover } from "./popovers/CostCentre";
import { UserAppRolePopover } from "./popovers/SearchSelect/UserAppRole";
import CustomFields from "./CustomFields";
import LicenseNameCell, { CostBenchmarkCell } from "./license";
import { getRedirectionUrl, parseTemplateString } from "../functions";
import { IComponent } from "../types";
import LinkOrDiv from "components/LinkOrDiv";
import { invoke } from "../utils";

type CellRendererEnum = string;

type CellRendererType = Record<
	CellRendererEnum,
	{
		renderer: (props: any) => React.ReactNode;
		defaultStyles?: React.CSSProperties;
	}
>;
const CELL_RENDERER_TYPE_MAP: CellRendererType = {
	group: { renderer: GroupCellRenderer },
	text: {
		renderer: Text,
		defaultStyles: {
			overflow: "hidden",
			textOverflow: "ellipsis",
			width: "100%",
		},
	},
	text_map: {
		renderer: TextMap,
		defaultStyles: {
			overflow: "hidden",
			textOverflow: "ellipsis",
			width: "100%",
		},
	},
	text_currency: {
		renderer: TextCurrency,
		defaultStyles: {
			overflow: "hidden",
			textOverflow: "ellipsis",
			width: "100%",
		},
	},
	number: {
		renderer: Text,
		defaultStyles: {
			overflow: "hidden",
			textOverflow: "ellipsis",
			width: "30%",
		},
	},
	cost: {
		renderer: Cost,
		defaultStyles: {
			overflow: "hidden",
			textOverflow: "ellipsis",
			width: "100%",
		},
	},
	link: {
		renderer: Link,
		defaultStyles: {
			overflow: "hidden",
			textOverflow: "ellipsis",
			width: "100%",
		},
	},
	image: { renderer: ImageCellRenderer },
	logo: { renderer: LogoCellRenderer },
	logo_arr: { renderer: LogoArrayCellRenderer },
	label: { renderer: Label },
	selectEntity: { renderer: SelectEntity },
	static_select: { renderer: StaticSelect },
	license_form: { renderer: LicenseForm },
	imageOrNameBadge: { renderer: ImageNameBadgeRenderer },
	image_list: { renderer: ImageListCellRenderer },
	primary_source: { renderer: PrimarySourceCellRenderer },
	view_more: { renderer: ViewMoreRenderer },
	source_popover: { renderer: SourcePopOverRenderer },
	activeuser_popover: { renderer: ActiveUserPopOverRenderer },
	activeuserintegration_popover: {
		renderer: ActiveUserIntegrationPopOverRenderer,
	},
	user_popover: { renderer: UserPopOverRenderer },
	chargeback_popover: { renderer: ChargebackPopOverRenderer },
	license_popover: { renderer: LicensePopOver },
	optimisation_popover: { renderer: OptimisationPopOver },
	optimized_popover: { renderer: OptimizedPopover },
	license_type_popover: {
		renderer: LicenseTypePopOver,
	},
	license_name_cell: {
		renderer: LicenseNameCell,
		defaultStyles: {
			overflow: "hidden",
			width: "100%",
		},
	},
	license_name_popover: {
		renderer: LicenseTypeNamePopOver,
	},
	optimization_type_popover: { renderer: OptimizationTypePopOver },
	title_desc: { renderer: TitleDescriptionRenderer },
	title_desc_arr: { renderer: TitleDescriptionArrRenderer },
	title_payment: { renderer: NewTitleDescriptionRenderer },
	payment_popover: { renderer: PaymentPopoverRenderer },
	item_status_text: { renderer: StatusAndTextRenderer },
	change_number: {
		renderer: ChangeNumber,
		defaultStyles: {
			overflow: "hidden",
			width: "100%",
		},
	},
	change_spend: {
		renderer: ChangeSpend,
		defaultStyles: {
			overflow: "hidden",
			width: "100%",
		},
	},
	list: { renderer: ListRenderer },
	category_custom: { renderer: CategoryDescRenderer },
	sub_category_custom: { renderer: SubCategoryRenderer },
	category_popover: { renderer: CategoryPopOverRenderer },
	tags: { renderer: CustomTagsRenderer },
	tags_popover: { renderer: CustomTagsPopover },
	license_custom: { renderer: AddWithEditRenderer },
	edit_cell: { renderer: EditOnHover },
	integration_text: {
		renderer: IntegrationConnectText,
		defaultStyles: {
			overflow: "hidden",
			textOverflow: "ellipsis",
			width: "100%",
		},
	},
	integration_source_text: {
		renderer: IntegrationSourceText,
		defaultStyles: {
			overflow: "hidden",
			textOverflow: "ellipsis",
			width: "100%",
		},
	},
	primary_text: { renderer: PrimaryTextRenderer },
	owner_details: {
		renderer: OwnerDetailsRenderer,
	},
	spend_budget_details: {
		renderer: SpendsBudgetDetailsRenderer,
	},
	spend_budget_popover: {
		renderer: SpendsBudgetPopOver,
	},
	score: {
		renderer: Score,
	},
	date: {
		renderer: DateTime,
		defaultStyles: {
			overflow: "hidden",
			textOverflow: "ellipsis",
			width: "100%",
		},
	},
	usageQuality: {
		renderer: UsageQuality,
	},
	progress: {
		renderer: Progress,
	},
	//**Do not use this: This will be deprecated soon**/
	filter_list: { renderer: FilterList },
	empty_direct_integration: { renderer: EmptyDirectIntegration },
	custom_fields: {
		renderer: CustomFields,
		defaultStyles: {
			overflow: "hidden",
			textOverflow: "ellipsis",
			width: "100%",
		},
	},
	custom_threat: {
		renderer: CustomThreat,
	},
	custom_risk: {
		renderer: CustomRisk,
	},
	custom_security_threats: {
		renderer: CustomSecurityThreats,
	},
	custom_risk_setup_popup: {
		renderer: CustomRiskSetupPopup,
	},
	custom_security_threat_popup: {
		renderer: CustomSecurityThreatPopup,
	},
	custom_app_security_compliance_popup: {
		renderer: CustomAppSecurityCompliancePopup,
	},
	custom_spend_forecast_popup: {
		renderer: CustomSpendForecastPopup,
	},
	usage: { renderer: Usage },
	usageBenchmark: { renderer: UsageBenchmark },
	contracts: { renderer: Contracts },
	upcomingContracts: { renderer: UpcomingContracts },
	actions: { renderer: ActionTag },
	app_users_actions: { renderer: AppUserActionsPopover },
	app_realized_savings_actions: { renderer: AppRealizedSavingsAction },
	app_state_popover: { renderer: AppStatePopover },
	license_type_name: { renderer: licenseTypeDesc },
	app_license_mapping_description: { renderer: AppLicenseMappingDescription },
	filter: { renderer: Filter },
	boolean_filter: { renderer: BooleanFilter },
	contract_name: { renderer: ContractName },
	contract_popover: { renderer: ContractsPopover },
	empty_contract: { renderer: EmptyContactConnection },
	custom_app_quality: { renderer: CustomAppQuality },
	custom_circular_progress: { renderer: CustomCircularProgress },
	custom_meter_chart: { renderer: CustomMeterChart },
	usage_popover: { renderer: UsagePopover },
	usage_benchmark_popover: { renderer: UsageBenchmarkPopover },
	app_status: { renderer: AppStatus },
	app_status_popover: {
		renderer: AppStatusPopover,
		defaultStyles: {
			flex: 1,
		},
	},
	usage_text: { renderer: UsageTextRenderer },
	firstEl: { renderer: firstElement },
	textViewMorePopover: { renderer: SimpleViewMoreTextPopOver },
	agentTextFormatter: { renderer: agentTextRenderer },
	custom_certification_action: {
		renderer: CustomCertificationAction,
		defaultStyles: { width: "100%" },
	},
	spends_popover: { renderer: SpendsLastDatePopover },
	clusterApplication: { renderer: ClusterApplications },
	task_type: { renderer: TaskType },
	task_actions: { renderer: TaskActions },
	mark_as_complete: { renderer: MarkAsComplete },
	automated_action_status: { renderer: AutomatedActionStatus },
	custom_switch: {
		renderer: CustomSwitch,
		defaultStyles: { display: "flex", alignItems: "center" },
	},
	custom_notifications_text: {
		renderer: CustomNotificationText,
		defaultStyles: { display: "flex", alignItems: "center" },
	},
	custom_notification_popover: { renderer: CustomNotificationPopOver },
	desktop_action: { renderer: AddDesktopDropdownComponent },
	custom_rendering_data: { renderer: CustomRenderingData },
	sources_popover_details: { renderer: SourcePopOver },
	transaction_payment_method: { renderer: SelectPaymentMethod },
	transaction_vender_tooltip: { renderer: HoverVendor },
	custom_reviewer_edit: { renderer: CustomReviewerEdit },
	source_type: {
		renderer: MultiLevelSource,
	},
	custom_reviewer_name_component: {
		renderer: CustomReviewerNameComponent,
		defaultStyles: {
			overflow: "hidden",
			textOverflow: "ellipsis",
			width: "100%",
		},
	},
	optimization_playbook_status: { renderer: PlaybookStatus },
	email_list_popover: { renderer: EmailPopover },
	conditions: {
		renderer: ConditionsRenderer,
	},
	rules_actions: {
		renderer: RuleActionRenderer,
	},
	status: {
		renderer: StatusRenderer,
	},
	priority_order: {
		renderer: PriorityOrderRenderer,
	},
	rules_options: {
		renderer: OptionsRenderer,
	},
	tags_v2: { renderer: CustomTagsV2 },
	licenses_popover_v2: { renderer: LicensesPopoverV2 },
	search_select: { renderer: SearchSelect },
	action_button: {
		renderer: ActionButtonRenderer,
	},
	transaction_mapped: {
		renderer: TransactionMappedRenderer,
	},
	archived_status: { renderer: ArchivedStatus },
	boolean_text: {
		renderer: BooleanTextRenderer,
	},
	first_text: {
		renderer: FirstTextRenderer,
	},
	instance_list_popover: {
		renderer: InstanceListPopover,
	},
	path: {
		renderer: Path,
	},
	cost_centre_popover: {
		renderer: CostCentrePopover,
	},
	cost_benchmark: {
		renderer: CostBenchmarkCell,
	},
	user_app_role_popover: {
		renderer: UserAppRolePopover,
	},
	local_entity_selector: {
		renderer: LocalEntitySelector,
	},
};

const getCellRenderer = (type: CellRendererEnum) => {
	return (
		CELL_RENDERER_TYPE_MAP?.[type]?.renderer ||
		CELL_RENDERER_TYPE_MAP.text?.renderer
	);
};

const getCellStyle = (type: CellRendererEnum) => {
	return CELL_RENDERER_TYPE_MAP[type]?.defaultStyles || {};
};

const getClickComponent = (component: IComponent, props) => {
	if (component?.clickComponent?.triggerComponent) {
		return props[component?.clickComponent?.triggerComponent];
	} else return component?.clickComponent;
};

interface ICustomCellComponent {
	type: CellRendererEnum;
	isRoot?: boolean;
	component: IComponent;
	showClickComponent?: () => void;
	hasHover?: boolean;
	hasClick?: boolean;
	data: any[];
	placement: string;
	handleUpdate: (id: string, data: any, value: any) => void;
}
const CustomCellComponent = ({
	type,
	isRoot,
	component,
	...props
}: ICustomCellComponent) => {
	const [openPopover, setOpenPopover] = useState(false);

	const clickComponent = getClickComponent(component, props);

	const handleUpdate = (id: string, data: any, value: any) => {
		setOpenPopover(false);
		props?.handleUpdate(id, data, value);
	};

	const showClickComponent = isRoot
		? () => setOpenPopover(true)
		: props?.showClickComponent;

	const mainCell = withToolTip(
		React.createElement(getCellRenderer(type) as any, {
			component,
			showClickComponent,
			...props,
			hasHover: props?.hasHover || !!component?.hoverComponent,
			hasClick: props?.hasClick || !!clickComponent,
			hidePopup: () => setOpenPopover(false),
		}),
		{ ...component, hidePopup: () => setOpenPopover(false), ...props }
	);

	const style = {
		flexShrink: 0,
		cursor: "pointer",
		width: isRoot ? "100%" : undefined,
		color: "#222",
		...getCellStyle(type),
	};

	const url = invoke(() => {
		if (clickComponent?.redirectUrl) {
			const applicationId = getSegmentFromURL(2);
			const parsed = parseTemplateString({
				template: {
					redirectUrl: clickComponent.redirectUrl,
					hash: clickComponent.hash,
					queryParams: clickComponent.queryParams,
				},
				data: { ...props?.data, app_id: applicationId },
			});
			const uri = getRedirectionUrl({
				redirectUrl: parsed.redirectUrl,
				hash: parsed.hash,
				queryParams: parsed.queryParams,
			});
			return uri;
		}
	});

	const popoverContent = React.createElement(
		getCellRenderer(clickComponent?.type) as any,
		{
			component: clickComponent,
			...props,
			handleUpdate,
			hidePopup: () => setOpenPopover(false),
		}
	);

	return (
		<LinkOrDiv
			to={url}
			target={clickComponent?.openInNewTab ? "_blank" : ""}
			style={{ ...style }}
			className={
				component.className ? component.className + "-wrapper" : ""
			}
		>
			{popoverContent &&
			clickComponent &&
			!clickComponent?.redirectUrl ? (
				<Popover
					placement={props.placement || "bottomRight"}
					trigger="click"
					className={`v2-click-component ${
						clickComponent?.className ?? ""
					}`}
					show={openPopover}
					onToggle={setOpenPopover}
					content={popoverContent}
				>
					<div
						onClick={(e) => {
							e.stopPropagation();
							setOpenPopover(true);
						}}
					>
						{mainCell}
					</div>
				</Popover>
			) : (
				mainCell
			)}
		</LinkOrDiv>
	);
};

export {
	CELL_RENDERER_TYPE_MAP,
	getCellRenderer,
	getCellStyle,
	getClickComponent,
	CustomCellComponent,
};
