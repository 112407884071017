import React from "react";
import cn from "classnames";
import "./index.scss";
import Icon from "components/Icon/index.jsx";

interface IFilterButton {
	size: "large" | "small";
	isActive?: boolean;
	text: string;
	onRemove?: () => void;
	onClick?: () => void;
}

function FilterPillButton(props: IFilterButton) {
	const { text, isActive, size = "large", onRemove, onClick } = props;

	return (
		<button
			className={cn(
				"z__filter_pill_button",
				size === "large"
					? "z__filter_pill_button_large"
					: "z__filter_pill_button_small",
				isActive && "z__filter_pill_active"
			)}
			onClick={onClick}
		>
			{isActive && (
				<div className="z__filter_pill_remove" onClick={onRemove}>
					<Icon
						name="close"
						color="white"
						style={{ width: 6, height: 6 }}
					/>
				</div>
			)}
			<p>{text}</p>
		</button>
	);
}

export default FilterPillButton;
