import React from "react";
import { Button, theme } from "@zluri/ui-components";
import { useDispatch, useSelector } from "react-redux";
import { getReviewersObj } from "modules/AccessReview/util/util";
import { ColumnReorder } from "containers/v2table/ColumnReorder/ColumnReorder";
import "modules/AccessReview/components/ModifyColumnsExtended/ModifyColumnsExtended.css";
import { fixedColumnsGroupNames } from "modules/AccessReview/constants/constants";

export function ModifyColumnsExtended({
	entity,
	displayColumns,
	setDisplayColumns,
	entireColumnList,
	setEntireColumnList,
	fixedColumns,
	fixedColumnsWithProps,
}) {
	const dispatch = useDispatch();
	const tableData = useSelector((state) => state.v2Table?.[entity]);
	const { applicationReview } = useSelector((state) => state.accessReview);
	const primaryReviewer = applicationReview?.levels[0]?.primary_reviewer;
	const fallbackReviewer = applicationReview?.levels[0]?.fallback_reviewer;
	const apiProps = {
		...getReviewersObj(primaryReviewer, fallbackReviewer),
		appId: applicationReview?.appId,
	};

	const handleSubmit = () => {
		dispatch({
			type: "GET_TABLE_DATA",
			payload: {
				entity: entity,
				cols: displayColumns,
				shouldRefresh: true,
				filterBy: tableData?.filter_by || [],
				apiProps: apiProps,
			},
		});
	};

	const handleReset = () => {
		dispatch({
			type: "GET_TABLE_DATA",
			payload: {
				entity: entity,
				cols: fixedColumnsGroupNames,
				shouldRefresh: true,
				filterBy: tableData?.filter_by || [],
				apiProps: apiProps,
			},
		});
		setDisplayColumns(fixedColumnsWithProps);
	};

	return (
		<>
			<ColumnReorder
				columns={entireColumnList}
				selectedColumns={displayColumns}
				setSelectedColumns={(cols) => {
					setDisplayColumns(cols);
				}}
				setRearrangedColumns={(cols) => setEntireColumnList(cols)}
				fixedColumns={fixedColumns}
			/>
			<div className="d-flex w-100 justify-content-center">
				<div
					className="d-flex justify-content-between gap-10"
					style={{ paddingBottom: "10px" }}
				>
					<Button
						className="modify__columns__extended__buttons"
						theme={theme}
						variant="primary-default"
						onClick={() => {
							handleSubmit();
						}}
					>
						Update
					</Button>
					<Button
						className="modify__columns__extended__buttons"
						theme={theme}
						variant="primary-alternative"
						onClick={() => {
							handleReset();
						}}
					>
						Reset
					</Button>
				</div>
			</div>
		</>
	);
}
