import React from "react";
import warningExcliamation from "../../assets/warningExclaimation.svg";
import "./FallbackError.css";
import { Typography } from "@zluri/ui-components";
import refresh from "assets/icons/refresh.svg";
const FallbackError = ({ showIcon = true, ...props }) => {
	return (
		<div
			className="fallback__error-wrapper"
			style={{
				...props.style,
				backgroundColor: props.backgroundColor,
				position: "relative",
			}}
		>
			{props.title && (
				<h6
					style={{
						position: "absolute",
						left: "10px",
						top: "10px",
						fontSize:
							props.size === "widget" || props.size === "small"
								? "10px"
								: props.size === "medium"
									? "13px"
									: "15px",
						color: "#717171",
					}}
				>
					{props.title.toUpperCase()}
				</h6>
			)}
			{showIcon && <img src={warningExcliamation} />}
			<h4>{props.errorMessage || "Something went wrong !"}</h4>
			{props.refreshCall && (
				<span
					className="d-flex align-items-center"
					onClick={props.refreshCall && props.refreshCall}
				>
					{props.icon || (
						<img
							src={props.icon || refresh}
							width={12}
							height={12}
							alt=""
						/>
					)}
					<Typography
						variant="body_1_regular"
						color="secondary_grey_2"
						className="ml-1"
					>
						{props.iconText || "Refresh"}
					</Typography>
				</span>
			)}
		</div>
	);
};
export default FallbackError;
