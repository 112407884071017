import {
	Typography,
	ImageOrNameBadge,
	DatePicker,
	Popover,
} from "@zluri/ui-components";
import React, { useState } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { format } from "date-fns";
import { noop } from "underscore";

import { getNested } from "utils/getNested";
import pencil from "assets/icons/edit-pen.svg";

import CustomFieldTextEditPopover from "./CustomFieldTextEditPopover";
import EntitySelector from "./EntitySelector";
import CustomFieldSelectEditPopover from "./CustomFieldSelectEditPopover";
import { FieldTypePropTypes, ReferencePropTypes } from "./const";
import EmptyCustomField from "./EmptyCustomField";

import "./index.scss";

// This is not so good I know.. But I don't know ..
function CustomField({ data, component, handleUpdate }) {
	const fieldName = component?.fieldName;
	const fieldType = component?.fieldType;

	const isEditable = component?.isEditable;

	const value = getNested(
		data,
		`custom_fields.${fieldName}.${fieldType === "reference" ? "custom_field_name" : "field_value"}`
	);
	const [openPopup, setOpenPopup] = useState(false);

	const updateField = ({ data: updateData, isEdit }) => {
		const patchObj = {
			patches: [
				{
					custom_field_id: component?.fieldId,
					op: isEdit ? "replace" : "add",
					field: "custom_fields",
					value:
						fieldType === "reference"
							? updateData.value
							: updateData,
				},
			],
		};

		handleUpdate(data._id, patchObj, {
			custom_fields: {
				...(data.custom_fields ?? {}),
				[component?.fieldName]: {
					...(fieldType === "reference"
						? {
								custom_field_name: updateData.label,
								field_value: updateData.value,
								custom_field_image: updateData.image,
							}
						: { field_value: updateData }),
				},
			},
		});
	};

	if (!value) {
		if (!component.isEditable) {
			return "-";
		}
		return (
			<EmptyCustomField
				type={fieldType}
				reference={component.fieldReference}
				options={component.fieldOptions}
				onSubmit={(data) => {
					updateField({ data, isEdit: false });
				}}
			/>
		);
	}

	if (fieldType === "reference") {
		const image = getNested(
			data,
			`custom_fields.${fieldName}.custom_field_image`
		);
		return (
			<div>
				<Popover
					show={openPopup}
					onToggle={isEditable ? setOpenPopup : noop}
					trigger="click"
					content={
						<EntitySelector
							onSelect={(data) => {
								updateField({ data, isEdit: true });
								setOpenPopup(false);
							}}
							reference={component.fieldReference}
						/>
					}
				>
					<div className="z__custom_field_wrapper">
						<div className="flex overflow-hidden">
							<ImageOrNameBadge
								height={16}
								url={image}
								name={value}
								variant="round"
								width={16}
								className="mr-2"
								nameClassName="mr-2"
							/>

							<div className="d-flex ellipsize-typography flex-column align-items-start ml-2">
								<Typography
									color="secondary_grey_2"
									variant="body_2_bold"
									className="v2table__cell-text"
								>
									{value}
								</Typography>
							</div>
						</div>
						{isEditable && (
							<div className="z__custom_field_edit_icon-wrapper">
								<img
									src={pencil}
									alt="pencil icon"
									className="z__custom_field_edit_icon"
								/>
							</div>
						)}
					</div>
				</Popover>
			</div>
		);
	}
	if (fieldType === "date") {
		if (!isEditable) {
			return (
				<Typography
					variant="body_1_regular"
					color="secondary_grey_1"
					className={cn("ellipsize-typography")}
				>
					{format(new Date(value ?? ""), "d MMM, yyyy")}
				</Typography>
			);
		}
		return (
			<div className="text-right justify-content-end">
				<DatePicker
					className="z__custom_field_date_picker"
					calendarView="date"
					cellRenderer={null}
					customToggler={() => (
						<Typography
							variant="body_1_regular"
							color="secondary_grey_1"
							className={cn("ellipsize-typography")}
						>
							{format(new Date(value ?? ""), "d MMM, yyyy")}
						</Typography>
					)}
					customWeekNames={{
						lang: "en",
						weekNames: ["S", "M", "T", "W", "T", "F", "S"],
					}}
					format="DD/MM/YYYY"
					onChange={(date) => {
						updateField({
							data: date?.toISOString(),
							isEdit: true,
						});
					}}
					value={value}
					placement="topLeft"
					setDisabledDate={null}
					style={{ width: "75%" }}
					showFooter={false}
				/>
			</div>
		);
	}

	if (fieldType === "text")
		return (
			<div>
				<Popover
					show={isEditable ? openPopup : false}
					onToggle={setOpenPopup}
					trigger="click"
					content={
						<CustomFieldTextEditPopover
							defaultValue={value}
							onClose={() => setOpenPopup(false)}
							onSubmit={(data) => {
								updateField({ data, isEdit: true });
								setOpenPopup(false);
							}}
						/>
					}
				>
					<div className="z__custom_field_wrapper">
						<Typography
							variant={"body_1_regular"}
							color="secondary_grey_1"
							className={cn("text-capitalize")}
						>
							{value}
						</Typography>
						{isEditable && (
							<div className="z__custom_field_edit_icon-wrapper">
								<div className="z__custom_field_edit_icon">
									<img src={pencil} alt="pencil icon" />
								</div>
							</div>
						)}
					</div>
				</Popover>
			</div>
		);

	if (fieldType === "select" || fieldType === "bool")
		return (
			<div>
				<Popover
					show={openPopup}
					onToggle={isEditable ? setOpenPopup : noop}
					trigger="click"
					content={
						<CustomFieldSelectEditPopover
							defaultValue={value}
							options={component?.fieldOptions}
							onClose={() => setOpenPopup(false)}
							onSubmit={(data) => {
								updateField({ data, isEdit: true });
								setOpenPopup(false);
							}}
						/>
					}
				>
					<div className="z__custom_field_wrapper">
						<Typography
							variant={"body_1_regular"}
							color="secondary_grey_1"
							className={cn("text-capitalize")}
						>
							{value}
						</Typography>
						<div className="z__custom_field_edit_icon-wrapper">
							{isEditable && (
								<div className="z__custom_field_edit_icon">
									<img src={pencil} alt="pencil icon" />
								</div>
							)}
						</div>
					</div>
				</Popover>
			</div>
		);

	return (
		<div className="z__custom_field_wrapper">
			<Typography
				variant={"body_1_regular"}
				color="secondary_grey_1"
				className={cn("text-capitalize")}
			>
				{value}
			</Typography>
			<div className="z__custom_field_edit_icon-wrapper">
				<div className="z__custom_field_edit_icon">
					<img src={pencil} alt="pencil icon" width={16} />
				</div>
			</div>
		</div>
	);
}

CustomField.propTypes = {
	data: PropTypes.object,
	component: {
		fieldType: FieldTypePropTypes,
		fieldReference: ReferencePropTypes,
	},
	handleUpdate: PropTypes.func,
};

export default CustomField;
