import React, { useState } from "react";

import PropTypes from "prop-types";

import { Collapsible, NoDataCard } from "@zluri/ui-components";
import ConnectIntegrationBanner from "./ConnectIntegrationBanner";

import emptyEvents from "assets/applications/emptyEvents.svg";

import { useSourcesList } from "modules/applications/containers/OverviewV2/customHooks";
import { handleSourceExpandIcon } from "modules/applications/utils/OverviewV2.utils";

const SourceTabList = ({ sources, name, connectIntegration }) => {
	const [active, setActive] = useState("");
	const { tabs } = useSourcesList(sources);

	if (!sources?.length)
		return (
			<NoDataCard
				iconContainerStyle={{
					width: "32px",
					height: "32px",
				}}
				icon={emptyEvents}
				title={`No sources found ${name ? "for " + name : ""}`}
			/>
		);
	return (
		<div className="d-flex flex-column gap-10">
			{!connectIntegration && <ConnectIntegrationBanner />}
			<Collapsible
				collapsible="icon"
				expandIconPosition="end"
				accordion
				activeKey={active}
				onChange={(key) => setActive(key?.[0] ?? "")}
				items={tabs}
				bordered={false}
				rootClassName="z_source_tab"
				destroyInactivePanel
				expandIcon={handleSourceExpandIcon}
			/>
		</div>
	);
};

export default SourceTabList;

SourceTabList.propTypes = {
	sources: PropTypes.object,
	name: PropTypes.string,
	connectIntegration: PropTypes.bool,
};
