import React from "react";

import "./ConfirmPopup.css";

import cross from "assets/reports/cross.svg";

import { Modal } from "react-bootstrap";
import {
	BODY_VARIANT,
	GREY_VARIANT,
	SUB_HEADING_VARIANT,
	Typography,
} from "@zluri/ui-components";
import ControlFlowButtonGroup from "../ControlFlowButtonGroup/ControlFlowButtonGroup";

export default function ConfirmPopup({
	show = false,
	setShow,
	size = "sm",
	btnProps,
	subHeaderText = "Once you sign-off, your reviews will be saved and you’ll no longer be able to take review actions.",
	headerText = "Confirm Sign-Off?",
	headerLogo,
	bodyRenderer
}) {
	return (
		<Modal
			size={size}
			centered
			show={show}
			contentClassName="confirm_popup"
			dialogClassName="d-flex justify-content-center"
		>
			<div className="confirmation_popup_container">
				<div className="confirmation_popup_header">
					<div className="d-flex gap-8 align-items-center">
						{headerLogo && (
							<img
								src={headerLogo}
								alt=""
								height={16}
								width={16}
							/>
						)}
						<Typography
							variant={SUB_HEADING_VARIANT.SUBHEADING_1_BOLD}
							color={GREY_VARIANT.SECONDARY_GREY_1}
						>
							{headerText}
						</Typography>
					</div>
					<img
						src={cross}
						alt=""
						className="cursor-pointer padding_8"
						onClick={() => setShow(false)}
					/>
				</div>
				{
				bodyRenderer ? (
					bodyRenderer
				) : (
					<div className="confirmation_popup_text">
					<Typography
						variant={BODY_VARIANT.BODY_2_REGULAR}
						color={GREY_VARIANT.SECONDARY_GREY_1}
					>
						{subHeaderText}
					</Typography>
					</div>
				)}
				<div className="confirmation_popup_footer">
					<ControlFlowButtonGroup {...btnProps} />
				</div>
			</div>
		</Modal>
	);
}
