import React, { useEffect, useState } from "react";

import "./Popovers.css";

import pencil from "assets/icons/edit-pen.svg";
import blueTick from "assets/icons/blue-tick.svg";
import IconArrowRight from "assets/common/icon_arrow_right.svg";
import warningSvg from "modules/applications/components/chargebacks/assets/Icon_Alert.svg";

import {
	BODY_VARIANT,
	BUTTON_VARIANT,
	Field,
	GREY_VARIANT,
	ImageOrNameBadge,
	Popover,
	Typography,
} from "@zluri/ui-components";
import {
	APPROVED,
	DECLINED,
	INVALID_DATA,
	MODIFIED,
	StylesForReviewActionPopover,
	reviewActionEditOptionsText,
	reviewActionOptions,
	reviewActions,
} from "modules/AccessReview/constants/constants";
import ControlFlowButtonGroup from "../ControlFlowButtonGroup/ControlFlowButtonGroup";
import { requestBodyForChangingReviewAction } from "modules/AccessReview/util/util";
import { trackActionSegment } from "modules/shared/utils/segment";

export default function EditReviewActionPopover({
	data,
	component,
	hover,
	setHover,
	handleReviewAction,
	shouldEdit,
}) {
	const [isPopoverOpen, setIsPopoverOpen] = useState(false);
	const [actionBtnLoading, setActionBtnLoading] = useState(false);
	const [selectedMenuOption, setSelectedMenuOption] = useState();
	const defaultStateForEditCommentPopup = {
		comment: data?.review_comment || "",
	};
	const defaultStateForChangeActionPopup = {
		option: "",
		comment: "",
	};
	const [editCommentPopupData, setEditCommentPopupData] = useState(
		defaultStateForEditCommentPopup
	);
	const [changeActionPopupData, setChangeActionPopupData] = useState(
		defaultStateForChangeActionPopup
	);

	const showEditAction = isPopoverOpen ? true : shouldEdit && hover;

	useEffect(() => {
		setEditCommentPopupData({
			...editCommentPopupData,
			comment: data?.review_comment ?? "",
		});
	}, [data?.review_comment]);

	const updateReviewAction = () => {
		setActionBtnLoading(true);
		const reqBody = {
			clearData: clearData,
			selectedMenuOption: selectedMenuOption,
			changeActionPopupData: changeActionPopupData,
			editCommentPopupData: editCommentPopupData,
			data: data,
			component: component,
		};
		const sanitizedReqBody = requestBodyForChangingReviewAction(reqBody);
		if (sanitizedReqBody === INVALID_DATA) return;
		handleReviewAction(sanitizedReqBody);
	};

	const clearData = () => {
		setActionBtnLoading(false);
		setIsPopoverOpen(false);
		setSelectedMenuOption("");
		setHover(false);
		setChangeActionPopupData(defaultStateForChangeActionPopup);
		setEditCommentPopupData(defaultStateForEditCommentPopup);
	};

	return (
		<Popover
			placement="bottomRight"
			trigger="click"
			show={isPopoverOpen}
			onToggle={(val) => {
				setIsPopoverOpen(val);
				!val && clearData();
			}}
			className="review_action_edit_menu"
			content={
				<div className="d-flex flex-column gap-1 padding_2">
					{reviewActionOptions?.map((option) => (
						<MainPopoverContent
							setEditCommentPopupData={setEditCommentPopupData}
							editCommentPopupData={editCommentPopupData}
							changeActionPopupData={changeActionPopupData}
							setChangeActionPopupData={setChangeActionPopupData}
							clearData={clearData}
							updateFunc={updateReviewAction}
							actionBtnLoading={actionBtnLoading}
							data={data}
							component={component}
							option={option}
							key={option?.text}
							selectedMenuOption={selectedMenuOption}
							setSelectedMenuOption={setSelectedMenuOption}
						/>
					))}
				</div>
			}
		>
			{showEditAction && (
				<div
					className="review_action_edit_btn"
					style={
						isPopoverOpen
							? StylesForReviewActionPopover.hover
							: StylesForReviewActionPopover.noHover
					}
				>
					<img src={pencil} alt="" height={12} width={12} />
				</div>
			)}
		</Popover>
	);
}

const MainPopoverContent = (props) => {
	const {
		setEditCommentPopupData,
		editCommentPopupData,
		clearData,
		updateFunc,
		actionBtnLoading,
		data,
		option,
		selectedMenuOption,
		setSelectedMenuOption,
	} = props;

	const isSelected = selectedMenuOption === option?.text;

	const MenuText =
		option?.text === reviewActionEditOptionsText?.ADD_COMMENT &&
		data?.review_comment
			? reviewActionEditOptionsText?.EDIT_COMMENT
			: option?.text;

	const invokePopOverContent = () => {
		switch (selectedMenuOption) {
			case reviewActionEditOptionsText.CHANGE_ACTION:
				return <ChangeActionPopoverContent {...props} />;
			case reviewActionEditOptionsText.ADD_COMMENT:
			case reviewActionEditOptionsText.EDIT_COMMENT:
				return (
					<EditActionPopoverContent
						placeholder="Start typing your comment here..."
						handlers={{
							onChange: (e) =>
								setEditCommentPopupData({
									...editCommentPopupData,
									comment: e.target?.value,
								}),
						}}
						showCommentMandatoryText={false}
						commentText={editCommentPopupData?.comment}
						onActionClick={updateFunc}
						onCancelClick={clearData}
						actionBtnLoading={actionBtnLoading}
						actionBtnDisabled={!editCommentPopupData?.comment}
						actionBtnText="Save"
					/>
				);
			default:
				break;
		}
	};

	return (
		<Popover
			content={invokePopOverContent()}
			placement="rightTop"
			key={option?.text}
		>
			<div
				className="tag_review_action"
				onClick={() => {
					if (!isSelected) {
						trackActionSegment(
							`Review users table - edit review actions, ${MenuText}`
						);
					}

					setSelectedMenuOption(option?.text);
				}}
				style={
					isSelected
						? {
								borderRadius: "6px",
								border: "1px solid #b1c8f3",
								background: "#e8f0fc",
						  }
						: {}
				}
			>
				<Typography
					variant={BUTTON_VARIANT.BUTTON_EXTRA_SMALL_MEDIUM}
					className="tag_review_action_option"
				>
					{MenuText}
				</Typography>
				<img src={IconArrowRight} alt="" />
			</div>
		</Popover>
	);
};

const ChangeToActionPill = ({
	setChangeActionPopupData,
	changeActionPopupData,
	actionData,
	isSelected,
}) => {
	return (
		<div
			className="change_to_action_pill"
			onClick={() => {
				setChangeActionPopupData({
					...changeActionPopupData,
					option: actionData?.type,
				});
			}}
		>
			{isSelected ? (
				<img src={blueTick} alt="" width={12} height={12} />
			) : (
				<span className="checkbox-round" />
			)}
			<div className="d-flex align-items-center gap-4">
				<Typography
					variant={BODY_VARIANT.BODY_2_REGULAR}
					color={
						isSelected
							? GREY_VARIANT.SECONDARY_GREY_1
							: GREY_VARIANT.SECONDARY_GREY_3
					}
					className="change_to_action_text"
				>
					{actionData?.label}
				</Typography>

				<img src={actionData?.img} height={12} width={12} alt="" />
			</div>
		</div>
	);
};

const ChangeToActionPills = ({
	changeActionTo,
	changeActionPopupData,
	setChangeActionPopupData,
}) => {
	return (
		<div className="d-flex w-100 align-items-center gap-8">
			{changeActionTo?.map((action) => {
				const actionData = reviewActions[action];
				const isSelected =
					changeActionPopupData?.option === actionData?.type;
				return (
					<ChangeToActionPill
						actionData={actionData}
						changeActionPopupData={changeActionPopupData}
						isSelected={isSelected}
						setChangeActionPopupData={setChangeActionPopupData}
						key={action?.label}
					/>
				);
			})}
		</div>
	);
};

const ChangeActionPopoverContent = ({
	changeActionPopupData,
	setChangeActionPopupData,
	clearData,
	updateFunc,
	actionBtnLoading,
	data,
	component,
}) => {
	const actions = [APPROVED, MODIFIED, DECLINED];
	const valueKey = data[component?.valueKey];
	const changeActionTo = actions.filter((action) => action !== valueKey);
	const disableActionBtn = !(
		changeActionPopupData?.option && changeActionPopupData?.comment
	);

	const getCurrentActionPill = () => {
		const actionObj = reviewActions?.[valueKey];
		if (!actionObj) return;
		return (
			<div style={actionObj?.changeActionStyles}>
				<img src={actionObj?.img} alt="" />
				{actionObj?.label}
			</div>
		);
	};

	return (
		<div className="review_action_popup_container">
			<div className="w-100">
				<Typography
					variant={BUTTON_VARIANT.BUTTON_EXTRA_SMALL_MEDIUM}
					color={GREY_VARIANT.SECONDARY_GREY_2}
					className="d-flex align-items-center gap-2"
				>
					<span>Change</span>
					{getCurrentActionPill()}
					<span>action to:</span>
				</Typography>
			</div>
			<ChangeToActionPills
				changeActionPopupData={changeActionPopupData}
				changeActionTo={changeActionTo}
				setChangeActionPopupData={setChangeActionPopupData}
			/>
			<Field
				className="first_action_container_comment_field"
				fieldType="textarea"
				placeholder="Comment is mandatory when changing action."
				handlers={{
					onChange: (e) =>
						setChangeActionPopupData({
							...changeActionPopupData,
							comment: e.target?.value,
						}),
				}}
				value={changeActionPopupData?.comment}
				max={512}
				showCount={true}
				hideLabel={true}
			/>
			<ControlFlowButtonGroup
				onCancelClick={() => clearData()}
				onActionClick={() => updateFunc()}
				actionBtnLoading={actionBtnLoading}
				actionBtnDisabled={disableActionBtn}
				actionBtnText="Save"
				isActionBtnBlock={true}
				isCancelBtnBlock={true}
				style={{ width: "48.5%" }}
				actionBtnClasses="ht-33"
			/>
		</div>
	);
};

export const EditActionPopoverContent = ({
	commentText,
	actionBtnLoading,
	placeholder,
	showCommentMandatoryTextElement = (
		<div className="d-flex gap-8 padding_8 light-yellow-bg border-radius-8 align-items-center">
			<img src={warningSvg} alt="" />
			<Typography
				variant={BODY_VARIANT.BODY_2_MEDIUM}
				color={GREY_VARIANT.SECONDARY_GREY_2}
			>
				Adding comment is mandatory.
			</Typography>
		</div>
	),
	showCommentMandatoryText = true,
	onCancelClick,
	onActionClick,
	actionBtnDisabled,
	actionBtnVariant,
	actionBtnText,
	maxCharLimit = 512,
	showCount = true,
	hideLabel = true,
	handlers,
}) => {
	return (
		<div className="first_action_container">
			<Field
				className="first_action_container_comment_field"
				fieldType="textarea"
				placeholder={placeholder}
				handlers={handlers}
				value={commentText}
				max={maxCharLimit}
				showCount={showCount}
				hideLabel={hideLabel}
			/>
			{showCommentMandatoryText && showCommentMandatoryTextElement}
			<ControlFlowButtonGroup
				onCancelClick={onCancelClick}
				onActionClick={onActionClick}
				actionBtnLoading={actionBtnLoading}
				actionBtnDisabled={actionBtnDisabled}
				actionBtnText={actionBtnText}
				actionBtnVariant={actionBtnVariant}
				actionBtnClasses="ht-33"
			/>
		</div>
	);
};

export const CommentPopoverContent = ({
	badgeName,
	badgeImg,
	userName,
	userEmail,
	actionText = "Action taken on",
	date,
	content,
}) => {
	return (
		<div className="review_action_comment">
			<div className="review_action_comment_header">
				<div className="d-flex align-items-center gap-6">
					<ImageOrNameBadge
						name={badgeName}
						url={badgeImg}
						height={16}
						width={16}
					/>
					<div className="d-flex flex-column comment_popover_text">
						<Typography
							variant={BUTTON_VARIANT.BUTTON_EXTRA_SMALL_MEDIUM}
							color={GREY_VARIANT.SECONDARY_GREY_2}
						>
							{userName}
						</Typography>
						<Typography
							variant={BODY_VARIANT.BODY_3_REGULAR}
							color={GREY_VARIANT.SECONDARY_GREY_3}
						>
							{userEmail}
						</Typography>
					</div>
				</div>
				<div className="d-flex flex-column align-items-end gap-2">
					<Typography
						variant={BODY_VARIANT.BODY_2_MEDIUM}
						color={GREY_VARIANT.SECONDARY_GREY_3}
					>
						{actionText}
					</Typography>
					<Typography
						variant={BODY_VARIANT.BODY_2_MEDIUM}
						color={GREY_VARIANT.SECONDARY_GREY_3}
					>
						{date}
					</Typography>
				</div>
			</div>
			<div className="review_action_comment_section">
				<Typography
					variant={BODY_VARIANT.BODY_2_REGULAR}
					color={GREY_VARIANT.SECONDARY_GREY_3}
					className="text-wrap text-break"
				>
					{content}
				</Typography>
			</div>
		</div>
	);
};

export const NotAReviewerPopup = () => {
	return (
		<Popover
			placement="bottomLeft"
			trigger="hover"
			content={
				<div className="pending_action_popover_container">
					<img src={warningSvg} alt="" />
					<Typography
						variant={BODY_VARIANT.BODY_2_MEDIUM}
						color={GREY_VARIANT.SECONDARY_GREY_2}
					>
						You’re not the reviewer for this user, So you cannot
						take review action.
					</Typography>
				</div>
			}
		>
			Pending
		</Popover>
	);
};
