import React, { useCallback, useMemo, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import CellRenderer from "./cellRenderer";
import "./styles.css";
import { getEntityApi } from "containers/V2TableConstants";
import { isUndefined } from "underscore";
import { useScreenTag } from "containers/v2table/AppliedFilters/Views/hooks";
import qs from "query-string";
export default function useTable({
	entity,
	subEntityData,
	intID,
	forceInfinite,
	apiProps,
	enableViews,
	disableUrlSync,
	enableCacheRestore,
	entityId,
}) {
	const dispatch = useDispatch();
	const dataEntity = subEntityData?.entity || entity;
	const tableData = useSelector((state) => state.v2Table[dataEntity]);
	const views = useSelector((state) => state.v2Views[dataEntity]);
	const apiPropsRef = useRef(apiProps);

	const tableProperties = useSelector(
		(state) => state.v2TableProperties[dataEntity]
	);
	const tableMeta = tableProperties?.table_meta;

	const isLeafNode = (row) => {
		return (
			row[tableMeta?.["leafRowConditionKey"]] ===
			tableMeta?.["leafRowConditionValue"]
		);
	};

	const isCheckBoxDisabled = (rowIndex) => {
		if (dataSource?.length && rowIndex) {
			if (tableMeta?.["checkBoxEnableKey"])
				return (
					dataSource[rowIndex]?.[tableMeta?.["checkBoxEnableKey"]] !==
					tableMeta?.["checkBoxEnableValue"]
				);
			else {
				return false;
			}
		}
		return false;
	};

	//showViews represents if we have the screenTag for the given routes
	const { screenTag, showViews, appId } = useScreenTag(dataEntity);

	const loadNodeOnce = async ({ node, nodeProps }) => {
		const nodeListApi = getEntityApi(entity, "nodeListApi");
		return await nodeListApi(
			{
				columns: [],
				filter_by: [],
				sort_by: [],
			},
			0,
			20,
			null,
			undefined,
			node[tableMeta?.entity_key] || intID,
			node[tableMeta?.parent_key]
		).then((data) => data.data);
	};

	// Once forceInfinite props is removed. this logic needs to be changed.
	const dataSource = useMemo(() => {
		const page = tableData?.page;
		const allData = tableData?.data;
		if ((tableData?.isInfinite || forceInfinite) && allData) {
			const _data = [];
			for (let i = 0; i <= (page ?? 0); i++) {
				_data.push(...(allData?.[i] ?? []));
			}
			return _data;
		}
		if (!tableData?.isInfinite && !forceInfinite) {
			return allData?.[page] ?? [];
		}
		return allData?.[page] ?? [];
	}, [
		tableData?.isInfinite,
		tableData?.page,
		forceInfinite,
		tableData?.data,
	]);

	const { obj: propertiesColumnsObj, arr: propertiesColumns } =
		useMemo(() => {
			if ((tableProperties?.columns?.length ?? 0) < 1)
				return { obj: {}, arr: [] };
			return tableProperties?.columns.reduce(
				(acc, curr) => {
					if (!curr?.ui?.header) return acc;
					const _temp = { ...curr, ...curr.ui };
					return {
						obj: { ...acc.obj, [_temp.group_name]: _temp },
						arr: [...acc.arr, _temp],
					};
				},
				{ obj: {}, arr: [] }
			);
		}, [tableProperties?.columns]);

	const { obj: filterPropertiesObj, arr: filterProperties } = useMemo(() => {
		if ((tableProperties?.properties?.length ?? 0) < 1)
			return { obj: {}, arr: [] };
		return tableProperties?.properties.reduce(
			(acc, curr) => {
				if (!curr) return acc;

				const key = curr.field_name
					.replace(/\s+/g, " ")
					.trim()
					?.toLowerCase();
				return {
					obj: { ...acc.obj, [key]: curr },
					arr: [...acc.arr, curr],
				};
			},
			{ obj: {}, arr: [] }
		);
	}, [tableProperties?.properties]);

	const sortInfo = useMemo(() => {
		if (tableData?.sortBy && tableData?.sortBy?.length > 0) {
			return tableData.sortBy.map((sortObj) => {
				return {
					name: Object.keys(sortObj)[0],
					dir: Object.values(sortObj)[0],
				};
			});
		}
	}, [tableData?.sortBy]);

	const loadMoreData = () => {
		return dispatch({
			type: "GET_NEXT_PAGE",
			payload: {
				entity,
				subEntityData,
				apiProps,
				isInfinite: forceInfinite || tableData?.isInfinite,
				intID,
				quick_filter: tableData?.quick_filter,
			},
		});
	};
	const loadPrevData = () => {
		dispatch({
			type: "GET_PREV_PAGE",
			payload: {
				entity,
				subEntityData,
				isInfinite: forceInfinite || tableData?.isInfinite,
				apiProps,
				intID,
				quick_filter: tableData?.quick_filter,
			},
		});
	};

	const onSort = useCallback(
		(key, direction) => {
			if (key) {
				dispatch({
					type: "GET_TABLE_DATA",
					payload: {
						shouldRefresh: true,
						sortBy: [{ [key]: direction }],
						entity,
						subEntityData,
						apiProps,
					},
				});
			} else {
				console.log("key is not available for the header ");
			}
		},
		[apiProps, dispatch, entity, subEntityData]
	);

	const updateViews = useCallback(
		({ type, data }) => {
			// For now only refetch is allowed.
			const applicableTypes = ["refetch"];

			if (!applicableTypes.includes(type)) {
				throw new Error(`Wrong views action type sent`);
			}

			dispatch({
				type: "V2/UPDATE_VIEWS",
				payload: {
					entity,
					subEntityData,
					screen_tag: screenTag,
					type,
					appId,
				},
			});
		},
		[entity, subEntityData, screenTag, dispatch, appId]
	);
	const columns = useMemo(() => {
		if (tableData && tableProperties) {
			const tableColumns = tableData?.columns;
			const finalColumns = [];
			for (let i = 0; i < tableColumns?.length; i++) {
				const _data = tableColumns[i];

				const col = propertiesColumnsObj[_data.group_name];
				if (col) {
					const finalCol = {
						...col,
						...col.ui,
						sortable: col.is_sortable,
						isFilterable: !!col?.enabled_filters?.length ?? false,
						fieldIds: col?.field_ids ?? _data.field_ids,
						// Once all the backend has been updated to use the `sort_key` keyname, `sortKey` should be removed entirely
						// using ui for sortKey should be deprecated in future
						sortKey:
							col?.sort_key ?? col?.sortKey ?? col.ui?.sortKey,

						// enabledFilters is used for column level filters.
						// only field_name is unique amongst other keys, so a list of field_name is used here to filter the filters in column level from main filters.
						enabledFilters: col?.enabled_filters,
						exportKey: col?.export_key,
					};
					delete finalCol.ui;
					finalColumns.push(finalCol);
				}
			}
			return finalColumns
				.filter((column) => column?.header)
				.map((headerItem, index) => {
					const {
						name,
						header,
						group_name,
						component,
						columnDescription,
						sortKey,
						sortable,
						fieldIds,
						isFilterable,
						enabledFilters,
						defaultLocked,
						exportKey,
						...rest
					} = headerItem;
					const column = {
						id: group_name + " " + header, // Do note this id is useful for treeColumn (expandable rows)
						group_name: name,
						col_group_name: group_name,
						name: header,
						header,
						columnDescription,
						defaultWidth: component?.defaultWidth || 130,
						hideable: false,
						resizable: true,
						rowDetailsWidth: "max-viewport-width",
						maxWidth: component?.maxWidth,
						minWidth: component?.minWidth,
						sortKey,
						exportKey,
						sortInfo,
						headerItem,
						sortable,
						isFilterable,
						enabledFilters,
						fieldIds,
						defaultLocked,
					};
					if (component) {
						const render = (props) => (
							<CellRenderer
								key={index}
								component={component}
								tableEntity={
									subEntityData?.parentEntity ||
									"applications" ||
									entity
								}
								entity={subEntityData?.entity || entity}
								parentEntity={entity}
								{...props}
								{...rest}
							/>
						);
						return { ...column, render };
					} else return column;
				});
		}
	}, [
		tableData,
		tableProperties,
		propertiesColumnsObj,
		sortInfo,
		subEntityData?.parentEntity,
		subEntityData?.entity,
		entity,
	]);

	useEffect(() => {
		if (entity) {
			const params = getTableRelatedParams(dataEntity);
			dispatch({
				type: "GET_TABLE_DATA",
				payload: {
					entity,
					entityId,
					subEntityData,
					page: params?.page || 0,
					isInfinite: params?.isInfinite || forceInfinite,
					isViewRequired: enableViews && showViews,
					cols: params?.columns ? params?.columns : undefined,
					searchQuery: params?.searchQuery ? params?.searchQuery : "",
					filterBy: params?.filterBy ? params?.filterBy : undefined,
					sortBy: params?.sortBy,
					recordsPerPage:
						params?.recordsPerPage || (forceInfinite ? 50 : 20),
					intID,
					disableUrlSync,
					shouldRefresh: true,
					isFirstLoad: true,
					appId,
					screen_tag: screenTag,
					apiProps: apiPropsRef.current,
					viewId: params?.viewId,
					enableCacheRestore,
				},
			});
		}
	}, [
		entity,
		subEntityData?.entity,
		screenTag,
		dispatch,
		enableViews,
		disableUrlSync,
		intID,
		dataEntity,
		forceInfinite,
		showViews,
		appId,
		enableCacheRestore,
		entityId,
	]);

	const isInSearchState = Array.isArray(tableData?.searchQuery)
		? tableData?.searchQuery?.length > 0
		: !!tableData?.searchQuery;
	return {
		columns: columns || [],
		data: tableData?.data,
		views: views?.data,
		updateViews: updateViews,
		isViewsEnabled: showViews && enableViews && views?.data?.length > 0,
		tableData,
		isInSearchState,
		tableProperties,
		dataSource,
		count: tableData?.count,
		searchQuery: tableData?.searchQuery,
		limit: 20,
		isInfinite: !isUndefined(tableData?.isInfinite)
			? tableData?.isInfinite
			: false,

		sortInfo,
		loadMoreData,
		loading: tableData?.loading,
		isInfiniteLoading: tableData?.isInfiniteLoading,
		page: tableData?.page,
		recordsPerPage: forceInfinite ? 50 : tableData?.recordsPerPage || 20,
		loadPrevData,
		filterBy: tableData?.filter_by || [],
		other_tabs_count: tableData?.other_tabs_count,
		propertiesColumns: propertiesColumns,
		tableMeta,
		isLeafNode,
		isCheckBoxDisabled,
		loadNodeOnce,
		quick_filter: tableData?.quick_filter,
		filterPropertiesObj,
		filterProperties,
		onSort,
		error: tableData?.error || views?.error || tableProperties?.error,
		viewId: tableData?.viewId,
	};
}

function getTableRelatedParams(dataEntity) {
	const tableParams = qs.parse(window.location.search);
	const stringifiedParams = tableParams?.[dataEntity];
	try {
		if (typeof stringifiedParams === "string") {
			const parsedParams = JSON.parse(stringifiedParams);
			return parsedParams;
		}
		return {};
	} catch {
		return {};
	}
}
