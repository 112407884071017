import { toast, TOAST_COLOR } from "@zluri/ui-components";

import { TriggerIssue } from "utils/sentry";
import { TOAST_MESSAGE } from "../constants/toast.constants";

/**
 * Get filename from s3Link
 * @param {string} link s3Link
 * @returns {string} filename for that link including extension
 */
function getFileNameFromS3Link(link) {
	const filePath = link.split("?")[0];
	const fullFileName = filePath.split("/").pop();
	return fullFileName;
}

/**
 * Download file from s3Link using AJAX request
 * @param {string} s3Link s3Link
 * @returns {Promise<void>}
 */
export async function downloadFileAJAX(s3Link) {
	try {
		const response = await fetch(s3Link);
		if (!response.ok) {
			throw new Error("Network Response Error!");
		}

		const fileName = getFileNameFromS3Link(s3Link);

		const blob = await response.blob();

		toast(`Successfully downloaded ${fileName}`, {
			indicatorColor: TOAST_COLOR.SUCCESS,
		});

		const url = window.URL.createObjectURL(blob);
		const a = document.createElement("a");
		a.href = url;
		a.download = fileName;
		document.body.appendChild(a);
		a.click();
		a.remove();
		window.URL.revokeObjectURL(url);
	} catch (error) {
		TriggerIssue(TOAST_MESSAGE.FILE_DOWNLOAD_ERROR, error);
		toast(TOAST_MESSAGE.FILE_DOWNLOAD_ERROR, {
			indicatorColor: TOAST_COLOR.ERROR,
			action: {
				color: TOAST_COLOR.OTHER,
				text: "Retry",
				onClick: ({ closeToast }) => {
					closeToast();
					downloadFileAJAX(s3Link);
				},
			},
		});
	}
}
