import React from "react";
import _ from "underscore";
import lowRisk from "assets/low_risk.svg";
import highRisk from "assets/high_risk.svg";
import mediumRisk from "assets/medium_risk.svg";
import available from "assets/available.svg";
import unavailable from "assets/unavailable.svg";
import non_compliant from "assets/non_compliant.svg";
import validated from "assets/validated.svg";
import needsreview from "assets/applications/needsreview.svg";
import error_red_round from "assets/error_red_round.svg";
import check from "assets/check-green-bordered.svg";
import {
	API_COMPLIANCE_STATUS,
	GRADE_COLORS,
	ICON_LABEL,
	LOCAL_COMPLIANCE_STATUS,
} from "../constants/securityComplianceConstants";
import moment from "moment";
import IconLabel from "../components/SecurityCompliance/IconLabel/IconLabel";
import {
	ALERT_TAG_VARIANT,
	BODY_VARIANT,
	GREY_VARIANT,
	Tooltip,
	Typography,
} from "@zluri/ui-components";
import ContentLoader from "react-content-loader";
import { Divider } from "antd";

export const dropDownOptions = {
	LOW_RISK: {
		title: "Low Risk",
		icon: lowRisk,
		scoreRange: [1, 2],
		apiValue: 1,
		background: "#40E3951A",
	},
	MEDIUM_RISK: {
		title: "Medium Risk",
		icon: mediumRisk,
		scoreRange: [3],
		apiValue: 3,
		background: "#FFC1171A",
	},
	HIGH_RISK: {
		title: "High Risk",
		icon: highRisk,
		scoreRange: [4, 5],
		apiValue: 5,
		background: "#FF67671A",
	},
};

export const getBackgroundColorClassByValue = (val) => {
	const matchedLetter = val
		.toString()
		.toUpperCase()
		.match(/[a-z0-9+-]/gi);
	const grade = matchedLetter?.[0] || "?";

	if (_.contains(["A", "-"], grade)) {
		return GRADE_COLORS.GREEN_ACCENT;
	}
	if (_.contains(["A", "I", "M", "1", "X"], grade)) {
		return GRADE_COLORS.GREEN;
	}
	if (_.contains(["?"], grade)) {
		return GRADE_COLORS.VIOLET;
	}
	if (_.contains(["D"], grade)) {
		return GRADE_COLORS.PURPLE;
	}
	if (_.contains(["B"], grade)) {
		return GRADE_COLORS.GREYISH_YELLOW;
	}
	if (_.contains(["C"], grade)) {
		return GRADE_COLORS.LIGHT_BROWN;
	}
	if (_.contains(["E"], grade)) {
		return GRADE_COLORS.DARK_BROWN;
	}
	if (_.contains(["O"], grade)) {
		return GRADE_COLORS.GREY;
	}
	if (_.contains(["F", "0"], grade)) {
		return GRADE_COLORS.RED;
	}
};

export const getRiskByValue = (dropDownOptions, value) => {
	if ([1, 2].includes(value)) {
		return dropDownOptions.LOW_RISK;
	} else if ([3].includes(value)) {
		return dropDownOptions.MEDIUM_RISK;
	} else if ([4, 5].includes(value)) {
		return dropDownOptions.HIGH_RISK;
	}
};
export const getRiskColorByTitle = (title) => {
	if (title === "High Risk") {
		return "#FE6955";
	} else if (title === "Medium Risk") {
		return "#FFA217";
	} else if (title === "Low Risk") {
		return "#5FCF64";
	}
};

export const getThreatIconByRating = (rate) => {
	if (rate <= 2) {
		return { icon: lowRisk, title: "Low" };
	} else if (rate === 3) {
		return { icon: mediumRisk, title: "Medium" };
	} else if (rate >= 4) {
		return { icon: highRisk, title: "High" };
	}
};

export const getStatusIcon = (status) => {
	switch (status) {
		case "approved":
			return {
				icon: validated,
				bg: "#E7F8E8",
				color: "#5FCF64",
				text: LOCAL_COMPLIANCE_STATUS.VALIDATED,
				apiValue: API_COMPLIANCE_STATUS.APPROVED,
			};
		case "rejected":
			return {
				icon: non_compliant,
				bg: "#FFE9E5",
				color: "#FE6955",
				text: LOCAL_COMPLIANCE_STATUS.NON_COMPLIANT,
				apiValue: API_COMPLIANCE_STATUS.REJECTED,
			};
		case "no information":
			return {
				icon: unavailable,
				bg: "#DDDDDD",
				color: "#484848",
				text: LOCAL_COMPLIANCE_STATUS.UNAVAILABLE,
				apiValue: API_COMPLIANCE_STATUS.NO_INFORMATION,
			};
		case "available":
			return {
				icon: available,
				bg: "#E6F5FF",
				color: "#5ABAFF",
				text: LOCAL_COMPLIANCE_STATUS.AVAILABLE,
				apiValue: API_COMPLIANCE_STATUS.AVAILABLE,
			};
		case "review now":
			return {
				icon: needsreview,
				bg: "#DDDDDD",
				color: "",
				text: LOCAL_COMPLIANCE_STATUS.REVIEW_NOW,
				apiValue: API_COMPLIANCE_STATUS.REVIEW_NOW,
			};
		default:
			return null;
	}
};

export const getDateRangeString = (start_date, end_date) => {
	return `${moment(start_date)?.format("DD MMM, ‘YY")} - ${moment(
		end_date
	)?.format("DD MMM, ‘YY")}`;
};

export const getTimeFormat = (date, format) => {
	switch (format) {
		case "hh:mm A":
			return moment(date).format(format);
		default:
			return "";
	}
};

export const getDateFormat = (date, format) => {
	return moment(date).format(format);
};

export const EmptyField = ({ value, className }) => {
	return (
		<div className={`font-11 bold-400 grey-1 ${className}`}>
			{value || "-"}
		</div>
	);
};

export const test_score_columns = [
	{
		dataField: "test",
		text: "Test",
		formatter: (dataField, row) => (
			<>
				{dataField ? (
					<div className="d-flex flex-row">
						<Typography
							variant={BODY_VARIANT.BODY_2_MEDIUM}
							color={GREY_VARIANT.SECONDARY_GREY_2}
							className="text-nowrap"
						>
							{dataField}
						</Typography>
					</div>
				) : (
					<EmptyField />
				)}
			</>
		),
	},
	{
		dataField: "score",
		text: "Score",
		formatter: (dataField, row) => (
			<Typography
				variant={BODY_VARIANT.BODY_2_MEDIUM}
				color={GREY_VARIANT.SECONDARY_GREY_2}
				className={`d-flex align-items-center text-nowrap test-score`}
			>
				{dataField ? (
					<>
						<img
							className="mr-1"
							src={!row?.pass ? error_red_round : check}
							width={16}
							height={16}
							alt=""
						/>
						<span>{dataField}</span>
					</>
				) : (
					<EmptyField />
				)}
			</Typography>
		),
	},
	{
		dataField: "reason",
		text: "Reason",
		formatter: (dataField) => (
			<>
				{dataField ? (
					<Typography
						variant={BODY_VARIANT.BODY_2_REGULAR}
						color={GREY_VARIANT.SECONDARY_GREY_3}
					>
						{dataField}
					</Typography>
				) : (
					<EmptyField />
				)}
			</>
		),
	},
];

export const raw_server_columns = [
	{
		dataField: "header",
		text: "Header",
		formatter: (dataField) => (
			<>
				{dataField ? (
					<Typography
						variant={BODY_VARIANT.BODY_2_MEDIUM}
						color={GREY_VARIANT.SECONDARY_GREY_2}
						className="text-nowrap"
					>
						{dataField}
					</Typography>
				) : (
					<EmptyField />
				)}
			</>
		),
	},
	{
		dataField: "value",
		text: "Value",
		formatter: (dataField) => (
			<>
				{dataField ? (
					<Typography
						variant={BODY_VARIANT.BODY_2_REGULAR}
						color={GREY_VARIANT.SECONDARY_GREY_3}
						style={{ overflowWrap: "anywhere" }}
					>
						{dataField}
					</Typography>
				) : (
					<EmptyField />
				)}
			</>
		),
	},
];

export const columnValueDefault = (value, className) => {
	return (
		<>
			{value ? (
				<Typography
					variant={BODY_VARIANT.BODY_2_REGULAR}
					color={GREY_VARIANT.SECONDARY_GREY_3}
					className={` ${className}`}
				>
					{value}
				</Typography>
			) : (
				<EmptyField />
			)}
		</>
	);
};

export const tls_cipher_suites_columns = [
	{
		dataField: "cipher_suite",
		text: "Cipher suites",
		formatter: (dataField) =>
			columnValueDefault(dataField, "bold-500 grey cipher_table_text"),
	},
	{
		dataField: "code",
		text: "Code",
		formatter: (dataField) => columnValueDefault(dataField),
	},
	{
		dataField: "key_size",
		text: "Key Size",
		formatter: (dataField) => columnValueDefault(dataField),
	},
	{
		dataField: "aead",
		text: "Verdict 1",
		formatter: (dataField) => (
			<>
				{_.isBoolean(dataField) ? (
					<IconLabel iconLabel={ICON_LABEL[dataField]} />
				) : (
					<EmptyField />
				)}
			</>
		),
	},
	{
		dataField: "pfs",
		text: "Verdict 2",
		formatter: (dataField) => (
			<>
				{_.isBoolean(dataField) ? (
					<IconLabel iconLabel={ICON_LABEL[dataField]} />
				) : (
					<EmptyField />
				)}
			</>
		),
	},
	{
		dataField: "protocols",
		text: "Protocols",
		formatter: (dataField) =>
			columnValueDefault(dataField, "cipher__suit__protocol"),
	},
];

export const immirhil_cipher_suites_columns = [
	{
		dataField: "cipher_suite",
		text: "Cipher suites",
		formatter: (dataField) => (
			<Typography
				variant={BODY_VARIANT.BODY_2_MEDIUM}
				color={GREY_VARIANT.SECONDARY_GREY_2}
				style={{ overflow: "hidden", width: "50px" }}
			>
				{dataField ? (
					<Tooltip
						content={
							<Typography
								color="white"
								variant={
									ALERT_TAG_VARIANT.ALERT_TAG_EXTRA_SMALL_MEDIUM
								}
							>
								{dataField}
							</Typography>
						}
					>
						<div
							className="text-truncate mw-90"
							style={{ maxWidth: "90%" }}
						>
							{dataField}
						</div>
					</Tooltip>
				) : (
					"-"
				)}
			</Typography>
		),
	},
	{
		dataField: "key_exchange_type",
		text: "Key Type",
		formatter: (dataField) => columnValueDefault(dataField),
	},
	{
		dataField: "key_exchange_key_size",
		text: "Key Size",
		formatter: (dataField) => columnValueDefault(dataField),
	},
	{
		dataField: "authentication_type",
		text: "Authentication Type",
		formatter: (dataField) => columnValueDefault(dataField),
	},
	{
		dataField: "authentication_key_size",
		text: "Authentication Size",
		formatter: (dataField) => columnValueDefault(dataField),
	},
	{
		dataField: "encryption_type",
		text: "Encryption Type",
		formatter: (dataField) => columnValueDefault(dataField),
	},
	{
		dataField: "encryption_key_size",
		text: "Encryption Size",
		formatter: (dataField) => columnValueDefault(dataField),
	},
	{
		dataField: "encryption_block_size",
		text: "Encryption Block Size",
		formatter: (dataField) => columnValueDefault(dataField),
	},
	{
		dataField: "encryption_mode",
		text: "Encryption Mode",
		formatter: (dataField) => columnValueDefault(dataField),
	},
	{
		dataField: "mac_type",
		text: "Mac Type",
		formatter: (dataField) => columnValueDefault(dataField),
	},
	{
		dataField: "mac_size",
		text: "Mac Size",
		formatter: (dataField) => columnValueDefault(dataField),
	},
	{
		dataField: "pfs",
		text: "pfs",
		formatter: (dataField) => columnValueDefault(dataField),
	},
];

export const certification_columns = [
	{
		dataField: "subject",
		text: "Subject",
		formatter: (dataField) =>
			columnValueDefault(dataField, "grey bold-500"),
	},
	{
		dataField: "common_names",
		text: "Common Names",
		formatter: (dataField) => (
			<>
				{dataField ? (
					<Typography
						variant={BODY_VARIANT.BODY_2_REGULAR}
						color={GREY_VARIANT.SECONDARY_GREY_3}
						className="d-flex flex-column"
					>
						{dataField &&
							Array.isArray(dataField) &&
							dataField.map((name) => (
								<div key={name}>{name}</div>
							))}
					</Typography>
				) : (
					<EmptyField />
				)}
			</>
		),
	},
	{
		dataField: "alternative_names",
		text: "Alternate Names",
		formatter: (dataField) => (
			<>
				{dataField ? (
					<Typography
						variant={BODY_VARIANT.BODY_2_REGULAR}
						color={GREY_VARIANT.SECONDARY_GREY_3}
						className="d-flex flex-column"
					>
						{dataField &&
							Array.isArray(dataField) &&
							dataField?.map((name) => (
								<div key={name}>{name}</div>
							))}
					</Typography>
				) : (
					<EmptyField />
				)}
			</>
		),
	},
	{
		dataField: "serial_no",
		text: "Serial No",
		formatter: (dataField) => columnValueDefault(dataField),
	},
	{
		dataField: "valid_from",
		text: "Valid From",
		formatter: (dataField) => columnValueDefault(dataField),
	},
];

export const protocol_columns = [
	{
		dataField: "protocol_type",
		text: "Protocol - Cipher Suits",
		formatter: (dataField) =>
			columnValueDefault(dataField, "bold-500 grey"),
	},
	{
		dataField: "protocol_exists",
		text: "Code",
		formatter: (dataField) => (
			<>
				{_.isBoolean(dataField) ? (
					<IconLabel iconLabel={ICON_LABEL[dataField]} />
				) : (
					<EmptyField />
				)}
			</>
		),
	},
];

export const getHTTpObservatorySummaryDetails = (summaryDetails) => {
	return (
		<>
			<div className="d-flex flex-row scan_summary">
				<Typography
					className="summary__property"
					variant={BODY_VARIANT.BODY_2_MEDIUM}
					color={GREY_VARIANT.SECONDARY_GREY_2}
				>
					Scan ID
				</Typography>
				<Typography
					variant={BODY_VARIANT.BODY_2_REGULAR}
					color={GREY_VARIANT.SECONDARY_GREY_3}
					className={`summary__value ${
						!summaryDetails?.scan_id && "o-6"
					}`}
				>
					{summaryDetails?.scan_id || "-"}
				</Typography>
			</div>
			<div className="d-flex flex-row scan_summary">
				<Typography
					className="summary__property"
					variant={BODY_VARIANT.BODY_2_MEDIUM}
					color={GREY_VARIANT.SECONDARY_GREY_2}
				>
					Duration
				</Typography>
				<Typography
					variant={BODY_VARIANT.BODY_2_REGULAR}
					color={GREY_VARIANT.SECONDARY_GREY_3}
					className={`summary__value ${
						!summaryDetails?.duration && "o-6"
					}`}
				>
					{!_.isEmpty(summaryDetails?.duration) ? (
						<>{summaryDetails?.duration}</>
					) : (
						"-"
					)}
				</Typography>
			</div>
			<div className="d-flex flex-row scan_summary">
				<Typography
					className="summary__property"
					variant={BODY_VARIANT.BODY_2_MEDIUM}
					color={GREY_VARIANT.SECONDARY_GREY_2}
				>
					Tests Passed
				</Typography>
				<Typography
					variant={BODY_VARIANT.BODY_2_REGULAR}
					color={GREY_VARIANT.SECONDARY_GREY_3}
					className={`${
						!(
							summaryDetails?.tests_passed ||
							summaryDetails?.test_total
						) && "o-6"
					}`}
				>
					{!_.isEmpty(
						summaryDetails?.tests_passed ||
							summaryDetails?.test_total
					) ? (
						<>
							{`${summaryDetails?.tests_passed} of   ${summaryDetails?.test_total}`}
						</>
					) : (
						"-"
					)}
				</Typography>
			</div>
			<div className="d-flex flex-row scan_summary">
				<Typography
					className="summary__property"
					variant={BODY_VARIANT.BODY_2_MEDIUM}
					color={GREY_VARIANT.SECONDARY_GREY_2}
				>
					Score
				</Typography>
				<Typography
					variant={BODY_VARIANT.BODY_2_REGULAR}
					color={GREY_VARIANT.SECONDARY_GREY_3}
					className={`${
						!(
							summaryDetails?.score || summaryDetails?.score_total
						) && "o-6"
					}`}
				>
					{!_.isEmpty(
						summaryDetails?.score || summaryDetails?.score_total
					) ? (
						<>
							{`${summaryDetails?.score} of   ${summaryDetails?.score_total}`}
						</>
					) : (
						"-"
					)}
				</Typography>
			</div>
		</>
	);
};

export const getTlsObservatorySummaryDetails = (summaryDetails) => {
	return (
		<>
			<div className="d-flex flex-row scan_summary">
				<Typography
					className="summary__property"
					variant={BODY_VARIANT.BODY_2_MEDIUM}
					color={GREY_VARIANT.SECONDARY_GREY_2}
				>
					Scan ID
				</Typography>
				<Typography
					variant={BODY_VARIANT.BODY_2_REGULAR}
					color={GREY_VARIANT.SECONDARY_GREY_3}
					className={`${!summaryDetails?.scan_id && "o-5"}`}
				>
					{summaryDetails?.scan_id || "-"}
				</Typography>
			</div>
			<div className="d-flex flex-row scan_summary">
				<Typography
					className="summary__property"
					variant={BODY_VARIANT.BODY_2_MEDIUM}
					color={GREY_VARIANT.SECONDARY_GREY_2}
				>
					End Time
				</Typography>
				<Typography
					variant={BODY_VARIANT.BODY_2_REGULAR}
					color={GREY_VARIANT.SECONDARY_GREY_3}
					className={`${!summaryDetails?.end_time && "o-5"}`}
				>
					{summaryDetails?.end_time || "Data Unavailable"}
				</Typography>
			</div>
			<div className="d-flex flex-row scan_summary">
				<Typography
					className="summary__property"
					variant={BODY_VARIANT.BODY_2_MEDIUM}
					color={GREY_VARIANT.SECONDARY_GREY_2}
				>
					Compatibility Level
				</Typography>
				<Typography
					variant={BODY_VARIANT.BODY_2_REGULAR}
					color={GREY_VARIANT.SECONDARY_GREY_3}
					className={`  ${
						!summaryDetails?.compatibility_level && "o-5"
					}`}
				>
					{summaryDetails?.compatibility_level
						?.toLocaleLowerCase()
						?.includes("non-compliant")
						? "Not Compliant"
						: summaryDetails?.compatibility_level || "-"}
				</Typography>
			</div>
		</>
	);
};

export const renderSummaryRow = (label, value) => {
	return (
		<div className="d-flex flex-row scan_summary">
			<Typography
				variant={BODY_VARIANT.BODY_2_MEDIUM}
				color={GREY_VARIANT.SECONDARY_GREY_2}
				className="summary__property"
			>
				{label}
			</Typography>
			<Typography
				variant={BODY_VARIANT.BODY_2_REGULAR}
				color={GREY_VARIANT.SECONDARY_GREY_3}
				className={`${!value && "o-6"}`}
			>
				{value && Array.isArray(value)
					? value.map((v, index) => (
							<span key={index}>
								{index > 0 && ", "}
								{v}
							</span>
						))
					: value || "-"}
			</Typography>
		</div>
	);
};

export const getImmirhilSummaryDetails = (summaryDetails) => {
	return (
		<>
			{renderSummaryRow(
				"Host",
				_.first(summaryDetails?.host?.split(" "))
			)}
			{renderSummaryRow(
				"IP address",
				_.first(summaryDetails?.ip_address?.split(" "))
			)}
			{renderSummaryRow(
				"Score",
				`${
					_.isEmpty(
						summaryDetails?.overall_score ||
							summaryDetails?.overcall_score_outof
					)
						? ""
						: `${_.first(
								summaryDetails?.overall_score?.split(" ")
							)}/${_.first(
								summaryDetails?.overcall_score_outof?.split(" ")
							)}`
				}`
			)}
			{renderSummaryRow(
				"Protocol score",
				`${
					_.isEmpty(summaryDetails?.protocol_score_100)
						? ""
						: `${_.first(
								summaryDetails?.protocol_score_100?.split(" ")
							)}/100`
				}`
			)}
			{renderSummaryRow(
				"Key exchange score",
				`${
					_.isEmpty(summaryDetails?.key_exchange_score_100)
						? ""
						: `${_.first(
								summaryDetails?.key_exchange_score_100?.split(
									" "
								)
							)}/100`
				}`
			)}
			{renderSummaryRow(
				"Cipher Score",
				`${
					_.isEmpty(summaryDetails?.cipher_score_100)
						? ""
						: `${_.first(
								summaryDetails?.cipher_score_100.split(" ")
							)}/100`
				}`
			)}
			{renderSummaryRow(
				"TLS protocol",
				_.first(summaryDetails?.tls_protocol?.split(" "))
			)}
			{renderSummaryRow("Keys", [...new Set(summaryDetails?.keys)])}
		</>
	);
};

export const IMPACTSTATUS = {
	LOW: {
		text: "Low Impact",
		color: "#009307",
		backgroundColor: "#E7F8E8",
		border: "1px solid #009307",
	},

	MEDIUM: {
		text: "Medium Impact",
		color: "#C97B07",
		backgroundColor: "#FFF6DC",
		border: "1px solid #C97B07",
	},
	HIGH: {
		text: "High Impact",
		color: "#FF6767",
		backgroundColor: "#FFE9E5",
		border: "1px solid #FF6767",
	},
	VERYHIGH: {
		text: "Very High Impact",
		color: "#BB2915",
		backgroundColor: "#FFE9E5",
		border: "1px solid #BB2915",
	},
};

export const getImpactStatus = (impact) => {
	switch (impact) {
		case 5:
			return IMPACTSTATUS.VERYHIGH;
		case 4:
			return IMPACTSTATUS.HIGH;
		case 3:
			return IMPACTSTATUS.MEDIUM;
		case 2:
			return IMPACTSTATUS.LOW;
		case 1:
			return IMPACTSTATUS.LOW;
		default:
			return {
				text: "unavailable",
				color: "#717171",
			};
	}
};

export const SecurityHeaderLoader = (
	<span className="d-flex align-items-center gap-10">
		<ContentLoader
			height="22px"
			width="80px"
			backgroundColor="#EBEBEB"
			foregroundColor="#f3f3f3"
		>
			<rect width="100%" height="100%" rx="8" />
		</ContentLoader>
		<ContentLoader
			height="22px"
			width="80px"
			backgroundColor="#EBEBEB"
			foregroundColor="#f3f3f3"
		>
			<rect width="100%" height="100%" rx="8" />
		</ContentLoader>
	</span>
);

export const EventCardLoader = (
	<div
		className="d-flex align-items-center gap-8 flex-1 border-radius-8 padding_10"
		style={{ background: "#f9f9f9" }}
	>
		<span className="d-flex flex-column gap-4 flex-1">
			<ContentLoader
				height="16px"
				width="60%"
				backgroundColor="#EBEBEB"
				foregroundColor="#f3f3f3"
			>
				<rect width="100%" height="100%" rx="8" />
			</ContentLoader>
			<ContentLoader
				height="40px"
				width="90%"
				backgroundColor="#EBEBEB"
				foregroundColor="#f3f3f3"
			>
				<rect width="100%" height="100%" rx="8" />
			</ContentLoader>
		</span>
		<span className="d-flex flex-column gap-4 ml-auto">
			<ContentLoader
				height="10px"
				width="80px"
				backgroundColor="#EBEBEB"
				foregroundColor="#f3f3f3"
				className="ml-auto"
			>
				<rect width="100%" height="100%" rx="4" />
			</ContentLoader>
			<ContentLoader
				height="32px"
				width="100px"
				backgroundColor="#EBEBEB"
				foregroundColor="#f3f3f3"
			>
				<rect width="100%" height="100%" rx="6" />
			</ContentLoader>
		</span>
	</div>
);

export const EventDivider = ({ text, style }) => {
	return (
		<Divider orientation="center" style={{ margin: "10px 0px", ...style }}>
			<Typography
				variant={BODY_VARIANT.BODY_2_REGULAR}
				color={GREY_VARIANT.SECONDARY_GREY_3}
			>
				{text}
			</Typography>
		</Divider>
	);
};
