import React from "react";

import icon_greater_than from "assets/Icon_Numeric_Greaterthan.svg";
import less_than from "assets/icons/less-than.svg";
import equal_to from "assets/icons/equal-to.svg";
import range from "assets/icons/range.svg";
import CondtionalSelect from "./ConditionalSelect";
import moment from "moment";

const filterListProps = [
	{
		label: "Date is",
		inputType: "date",
		icon: equal_to,
		api_values: {
			field_order: ["eq"],
			negative: false,
		},
	},
	{
		label: "Date is before",
		inputType: "date",
		icon: less_than,
		api_values: {
			field_order: ["lt"],
			negative: false,
		},
	},
	{
		label: "Date is after",
		inputType: "date",
		rangeType: "date",
		icon: icon_greater_than,
		api_values: {
			field_order: ["gt"],
			negative: false,
		},
	},
	{
		label: "Relative Date Range",
		inputType: "range",
		rangeType: "date",
		icon: range,
		api_values: {
			field_order: ["gte", "lte"],
			negative: false,
		},
	},
];
export default function DateRangeFilter({
	onChange,
	value,
	filter,
	filterData,
}) {
	const handleChange = (val, id) => {
		console.log({ val });
		if (filterListProps[id].inputType === "range") {
			onChange(val, filterListProps[id].api_values);
		} else onChange([val], filterListProps[id].api_values);
	};
	const selectedIndex = filterListProps.findIndex(
		(item) =>
			item.api_values.field_order?.toString() ===
			filterData?.field_order?.toString()
	);

	return (
		<CondtionalSelect
			onChange={handleChange}
			text={
				filterListProps[selectedIndex]?.inputType === "range"
					? value
					: moment(value?.[0]).utc(true)
			}
			selectedIndex={selectedIndex}
			// filter
			options={filterListProps}
		/>
	);
}
