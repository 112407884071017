import {
	client,
	clientEmployee,
	clientEmployeeV2,
	clientV2,
} from "utils/client";
import {
	getRefactoredDataForBulkEdit,
	getUnselectedRowsForBulkEdit,
	getSelectOrUnselect,
	getDefaultBulkEditProps,
} from "../util/util";
import { filterPropertiesHelper } from "modules/v2InfiniteTable/utils/v2infiniteTableUtil";
import { getSegmentFromURL } from "@zluri/ui-components";

import { isObject } from "underscore";

export async function fetchReviews(type = "ongoing", page = 0, row = 10) {
	const response = await client.get(
		`access-certifications?q=${type}&page=${page}&row=${row}`
	);

	return response.data || response || [];
}

export async function fetchReviewData(reviewId) {
	let response;
	response = await client.get(`access-certification/${reviewId}`);
	return response.data || response || {};
}

export async function fetchReviewDataForClone(reviewId) {
	let response;
	response = await client.get(`access-certification/${reviewId}/copy`);
	return response.data || response || {};
}

export async function createCertification(data) {
	let response;
	try {
		response = await clientV2.post("access-certifications/", { ...data });
	} catch (error) {
		throw error;
	}
	return response || [];
}

export async function updateCertStage(certId, body) {
	if (!certId || !body) return;
	const response = await clientV2.patch(
		`access-certification/${certId}/stage`,
		{ stage: body }
	);
	return response?.data;
}

export async function getReportUrl(reviewId) {
	let response;
	try {
		response = await clientV2.get(
			`access-certification/${reviewId}/completion-report`
		);
	} catch (error) {
		return {
			error: error,
		};
	}
	return response || [];
}

//Bulk Edit Review API
export async function bulkEditUserReviewStatus(data) {
	const { reviewId, appId } = data?.value;
	const requestBody = getRefactoredDataForBulkEdit(data);
	const response = await clientV2.patch(
		`access-certification/${reviewId}/application/${appId}/users/status/review/edit`,
		requestBody
	);

	return response?.data;
}

//Bulk Edit Review API EmpView
export async function bulkEditUserReviewStatusEmpView(data) {
	const { reviewId, appId } = data?.value;
	const requestBody = getRefactoredDataForBulkEdit(data);
	const response = await clientEmployeeV2.patch(
		`access-certification/${reviewId}/application/${appId}/users/status/review/edit`,
		requestBody
	);

	return response?.data;
}

//Bulk Delegate Reviewer API
export async function bulkEditAccessRowReviewer(data) {
	const { reviewId, appId } = data?.value;
	const requestBody = getRefactoredDataForBulkEdit(data);
	const response = await clientV2.patch(
		`access-certification/${reviewId}/application/${appId}/users/reviewer/edit`,
		requestBody
	);

	return response?.data;
}

// Bulk Delegate Reviewer API EmpView
export async function bulkEditAccessRowReviewerEmpView(data) {
	const { reviewId, appId } = data?.value;
	const requestBody = getRefactoredDataForBulkEdit(data);
	const response = await clientEmployeeV2.patch(
		`access-certification/${reviewId}/application/${appId}/users/reviewer/bulk-delegate`,
		requestBody
	);

	return response?.data;
}

//Bulk Edit Review - Users Preview count API
export async function bulkEditUsersPreviewCounts(data) {
	const {
		reviewId,
		appId,
		select_all,
		rowData,
		filter_by,
		unSelectedRows,
		partiallyUnselected,
	} = data;
	const unselectedRowIds = getUnselectedRowsForBulkEdit(unSelectedRows);
	const requestBody = {
		...getDefaultBulkEditProps(
			select_all,
			partiallyUnselected,
			filter_by,
			unselectedRowIds,
			rowData
		),
		...getSelectOrUnselect(unselectedRowIds, select_all, {}, rowData),
	};

	const response = await clientV2.post(
		`access-certification/${reviewId}/application/${appId}/users/status/review/preview`,
		requestBody
	);
	return response?.data;
}

//Bulk Edit Review EmpView - Users Preview count API
export async function bulkEditUsersPreviewCountsEmpView(data) {
	const {
		reviewId,
		appId,
		select_all,
		rowData,
		filter_by,
		unSelectedRows,
		partiallyUnselected,
	} = data;
	const unselectedRowIds = getUnselectedRowsForBulkEdit(unSelectedRows);
	const requestBody = {
		...getDefaultBulkEditProps(
			select_all,
			partiallyUnselected,
			filter_by,
			unselectedRowIds,
			rowData
		),
		...getSelectOrUnselect(unselectedRowIds, select_all, {}, rowData),
	};

	const response = await clientEmployeeV2.post(
		`access-certification/${reviewId}/application/${appId}/users/status/review/preview`,
		requestBody
	);
	return response?.data;
}

//Bulk Delegate Reviewer - Users Preview count API
export async function bulkEditReviewersPreviewCounts(data) {
	const {
		reviewId,
		appId,
		select_all,
		rowData,
		filter_by,
		unSelectedRows,
		partiallyUnselected,
		reviewer_id,
	} = data;
	const unselectedRowIds = getUnselectedRowsForBulkEdit(unSelectedRows);

	const requestBody = {
		reviewer_id: reviewer_id,
		...getDefaultBulkEditProps(
			select_all,
			partiallyUnselected,
			filter_by,
			unselectedRowIds,
			rowData
		),
		...getSelectOrUnselect(unselectedRowIds, select_all, {}, rowData),
	};

	const response = await clientV2.post(
		`access-certification/${reviewId}/application/${appId}/users/reviewer/edit/preview`,
		requestBody
	);
	return response?.data;
}

//Bulk Delegate Reviewer EmpView - Users Preview count API
export async function bulkEditReviewersPreviewCountsEmpView(data) {
	const {
		reviewId,
		appId,
		select_all,
		rowData,
		filter_by,
		unSelectedRows,
		partiallyUnselected,
		reviewer_id,
	} = data;
	const unselectedRowIds = getUnselectedRowsForBulkEdit(unSelectedRows);

	const requestBody = {
		reviewer_id: reviewer_id,
		...getDefaultBulkEditProps(
			select_all,
			partiallyUnselected,
			filter_by,
			unselectedRowIds,
			rowData
		),
		...getSelectOrUnselect(unselectedRowIds, select_all, {}, rowData),
	};

	const response = await clientEmployeeV2.post(
		`access-certification/${reviewId}/application/${appId}/users/reviewer/bulk-delegate/preview`,
		requestBody
	);
	return response?.data;
}

export async function getInsightsData(reviewId, appId) {
	const requestBody = {};
	const response = await clientV2.get(
		`access-certification/${reviewId}/application/${appId}/insights`,
		requestBody
	);
	return response;
}
export async function editAccessCertificationReviewer(
	reviewId,
	appId,
	reqBody
) {
	const response = await clientV2.patch(
		`access-certification/${reviewId}/application/${appId}/users/reviewer/edit`,
		reqBody
	);

	return response?.data;
}

export async function editAccessCertificationReviewerEmpView(
	reviewId,
	appId,
	reqBody
) {
	const response = await clientEmployeeV2.patch(
		`access-certification/${reviewId}/application/${appId}/users/reviewer/bulk-delegate`,
		reqBody
	);

	return response?.data;
}

export async function patchUserReviewStatus(certId, appId, patchObj) {
	const response = await clientV2.patch(
		`access-certification/${certId}/application/${appId}/users/status/review/edit`,
		patchObj
	);

	return response?.data;
}

export async function patchUserReviewStatusEmpView(certId, appId, patchObj) {
	const response = await clientEmployeeV2.patch(
		`access-certification/${certId}/application/${appId}/users/status/review/edit`,
		patchObj
	);
	return response.data;
}

export async function getReviewersForTable(apiProps) {
	const isEmployee = getSegmentFromURL(1) === "user";
	let varClient = isEmployee ? clientEmployee : client;
	const response = await varClient.get(
		`access-certification/${apiProps.reviewId}/application/${apiProps.appId}/reviewers/search?query=`
	);
	return response.data?.reviewers;
}

export async function getActionFilterForReviewerTable(apiProps, filter_by) {
	const response = await client.post(
		`access-certification/${apiProps.reviewId}/application/${apiProps.appId}/same-user-reviewer
	`,
		{ filter_by: filter_by }
	);

	return response.data;
}

export async function getActionFilterForReviewerTableEmpView(
	apiProps,
	filter_by
) {
	const response = await clientEmployee.post(
		`access-certification/${apiProps.reviewId}/application/${apiProps.appId}/same-user-reviewer
	`,
		{ filter_by: filter_by }
	);

	return response.data;
}

export async function sendManualReminder(reviewId) {
	const response = await clientV2.get(
		`access-certification/${reviewId}/manual-reminder`
	);

	return response.data;
}
export async function fetchReviewsEmpView(
	type = "ongoing",
	page = 0,
	row = 10
) {
	const response = await clientEmployee.get(
		`access-certifications?q=${type}&page=${page}&row=${row}`
	);

	return response.data || response || [];
}

export async function fetchReviewDataEmpView(reviewId) {
	const response = await clientEmployee.get(
		`access-certification/${reviewId}`
	);

	return response.data || response || {};
}

export async function bulkSendRemindersToReviewers(certId, reqObj) {
	const response = await client.post(
		`access-certification/${certId}/reviewers/reminder`,
		reqObj
	);
	return response.data || response;
}

export async function searchReviewers(
	query,
	cancelTokenSource = null,
	apiProps
) {
	let options = {};
	if (cancelTokenSource) {
		options = {
			cancelToken: cancelTokenSource.token,
		};
	}
	const isEmployee = getSegmentFromURL(1) === "user";
	let varClient = isEmployee ? clientEmployee : client;
	const response = await varClient.get(
		`access-certification/${apiProps.reviewId}/application/${apiProps.appId}/reviewers/search?query=${query}`,
		options
	);
	return response.data.reviewers;
}

export async function isSignOffFeasible(certId, appId) {
	const response = await client.get(
		`access-certification/${certId}/application/${appId}/signoff/check`
	);

	return response.data;
}

export async function performSignOff(certId, appId) {
	const response = await client.post(
		`access-certification/${certId}/application/${appId}/signoff`
	);

	return response.data;
}

export async function signOffJobStatus(certId, appId, reqBody) {
	const response = await client.post(
		`access-certification/${certId}/application/${appId}/signoff/status`,
		reqBody
	);

	return response.data;
}

export async function isSignOffFeasibleEmpView(certId, appId) {
	const response = await clientEmployee.get(
		`access-certification/${certId}/application/${appId}/signoff/check`
	);

	return response.data;
}

export async function performSignOffEmpView(certId, appId) {
	const response = await clientEmployee.post(
		`access-certification/${certId}/application/${appId}/signoff`
	);

	return response.data;
}

export async function signOffJobStatusEmpView(certId, appId, reqBody) {
	const response = await clientEmployee.post(
		`access-certification/${certId}/application/${appId}/signoff/status`,
		reqBody
	);

	return response.data;
}

export async function getAllApplicationUsers(
	reqObj,
	page,
	row,
	cancelTokenSource = null,
	searchReqParams,
	intId,
	apiProps
) {
	let options = {};
	let { review_user_type, review_user_value, fallback_reviewer_id, appId } =
		apiProps || {};
	let getReviewerColumn = `review_user_type=${review_user_type}&review_user_value=${review_user_value}&fallback_reviewer_id=${fallback_reviewer_id}`;
	let url = `access-certification/application/${appId}/users?page=${page}&row=${row}&${getReviewerColumn}`;
	if (cancelTokenSource) {
		options = {
			cancelToken: cancelTokenSource.token,
		};
	}

	if (isObject(searchReqParams) && Object.keys(searchReqParams).length > 0) {
		url = `access-certification/application/${appId}/users?is_search=${true}&search_query=${encodeURIComponent(
			searchReqParams?.search_query
		)}&page=${page}&row=${row}`;
	}

	const body = {
		...reqObj,
		columns: reqObj?.columns
			?.map((col) => col.group_name)
			.filter((col) => col),
	};

	const response = await client.post(
		url,
		body?.columns?.length === 0 ? reqObj : body,
		options
	);
	let newResponseData = filterPropertiesHelper(response.data);
	response.data = newResponseData;

	return response.data;
}

export async function getAppUsersPropertiesList(apiProps) {
	const response = await client.get("access-certification/filters");
	let newResponseData = filterPropertiesHelper(response.data);
	response.data = newResponseData;
	return response.data;
}

export async function getAppUsersSourcesForAccessCert(
	accessCertificateId,
	appId
) {
	const response = await client.get(
		`access-certification/${accessCertificateId}/application/${appId}/sources`
	);
	return response.data;
}

export async function getAppUsersSourcesForAccessCertEmpView(certId, appId) {
	const response = await clientEmployee.get(
		`access-certification/${certId}/application/${appId}/sources`
	);
	return response.data;
}

export async function getStatusForConcludeReviewJob({ certId }) {
	const response = await client.get(
		`access-certification/${certId}/conclude-review/status`
	);
	return response.data;
}
