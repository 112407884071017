import React from "react";

import icon_string_contains from "assets/Icon_String_Contains.svg";
import icon_string_not_contains from "assets/Icon_String_DoesnotContain.svg";

import CondtionalSelect from "./ConditionalSelect";

const filterListProps = [
	{
		label: "Contains",
		icon: icon_string_contains,
		api_values: {
			field_order: "contains",
			negative: false,
		},
	},
	{
		label: "Does not contain",
		icon: icon_string_not_contains,
		api_values: {
			field_order: "n_contains",
			negative: true,
		},
	},
];
export default function StringFilter({
	onChange,
	value,
	filterData,
	className,
}) {
	const handleChange = (val, id) => {
		onChange([val], filterListProps[id].api_values);
	};
	return (
		<CondtionalSelect
			onChange={handleChange}
			text={value?.[0]}
			selectedIndex={filterListProps.findIndex(
				(item) =>
					item.api_values.field_order === filterData?.field_order
			)}
			options={filterListProps}
			filterData={filterData}
			className={className}
		/>
	);
}
