import React, { useState } from "react";
import menu from "assets/Icon_Vertical_Menu.svg";
import ruler from "assets/Icon_RowRuler.svg";
import rightarrow from "assets/Icon_Arrow_Right.svg";
import blacktick from "assets/black_tick.svg";
import PropTypes from "prop-types";

import Download from "assets/v2tables/download-icon.svg";
import CustomDownload from "assets/v2tables/custom-download.svg";
import {
	IconButton,
	Popover,
	Tooltip,
	Typography,
	theme as V2_DEFAULT_THEME,
} from "@zluri/ui-components";
import Divider from "../Divider/Divider";
import { DensityEnum, useTableContext } from "../TableContext/context";
import { csvFileFormat } from "../../V2TableConstants";
import Icon from "components/Icon";

export default function OptionsMenu({
	hideExportAsCSV,
	hideExportCSVForExcel,
	onExportClick,
	hideCustomExport = false,
	loading,
}) {
	const [show, setShow] = useState(false);
	const { checkboxState, openBulkUpdateSidesheet, enableBulkUpdate } =
		useTableContext();

	const popup = (
		<div className="menu-options-container">
			<div className="d-flex w-100 justify-content-between">
				<Popover
					placement="leftTop"
					className="z-option-menu__popover"
					trigger={["hover"]}
					style={{ width: "140px" }}
					content={<DensityPopover onClick={() => setShow(false)} />}
				>
					<div className="d-flex justify-content-between cursor-pointer cell-header__menu-option align-items-center z-option-menu__option">
						<div>
							<img
								src={ruler}
								alt=""
								style={{ marginRight: "6px" }}
							/>
							<Typography variant="button_extrasmall_medium">
								View Density
							</Typography>
						</div>

						<div className="ml-4">
							<img src={rightarrow} alt="right-arrow" />
						</div>
					</div>
				</Popover>
			</div>
			<Divider type="type1" />
			{!hideExportAsCSV && (
				<div className="d-flex w-100 justify-content-between">
					<Popover
						placement="leftTop"
						className="z-option-menu__popover"
						trigger={["hover"]}
						style={{ width: "140px" }}
						content={
							<ViewExportPopover
								onClick={(fileFormat) => {
									onExportClick(fileFormat);
									setShow(false);
								}}
								hideExportCSVForExcel={hideExportCSVForExcel}
							/>
						}
					>
						<div className="d-flex justify-content-between cell-header__menu-option align-items-center cursor-pointer z-option-menu__option">
							<div>
								<img
									src={Download}
									alt="download icon"
									style={{
										marginRight: 6,
										height: 16,
										width: 16,
									}}
								/>
								<Typography variant="button_extrasmall_medium">
									Export View
								</Typography>
							</div>
							<div className="ml-4">
								<img src={rightarrow} alt="right-arrow" />
							</div>
						</div>
					</Popover>
				</div>
			)}

			{!hideCustomExport && (
				<div
					className="d-flex cell-header__menu-option align-items-center cursor-pointer z-option-menu__option gap-6"
					onClick={() => {
						onExportClick({
							isCustom: true,
							selectedRows: Object.keys(checkboxState.selected),
						});
						setShow(false);
					}}
				>
					<img
						src={CustomDownload}
						alt="download icon"
						style={{ height: 16, width: 16 }}
					/>
					<Typography variant="button_extrasmall_medium">
						Custom Export
					</Typography>
				</div>
			)}
			{enableBulkUpdate && (
				<div
					className="d-flex cell-header__menu-option align-items-center cursor-pointer z-option-menu__option gap-6"
					onClick={() => {
						openBulkUpdateSidesheet();
						setShow(false);
					}}
				>
					<Icon name="bulk-update" />
					<Typography variant="button_extrasmall_medium">
						Bulk Update
					</Typography>
				</div>
			)}
		</div>
	);

	return (
		<Popover
			className="z-option-menu__popover"
			content={popup}
			placement={"bottomRight"}
			show={show}
			onToggle={setShow}
		>
			<div>
				<Tooltip
					content={
						<Typography variant="body_2_regular" color="white">
							More Actions
						</Typography>
					}
				>
					<IconButton
						disabled={loading}
						theme={V2_DEFAULT_THEME}
						icon={menu}
						onClick={() => setShow((s) => !s)}
						position="default"
						shape="square"
						variant={show ? "light-blue" : "white"}
						style={{ margin: "0px 2px" }}
					/>
				</Tooltip>
			</div>
		</Popover>
	);
}
OptionsMenu.propTypes = {
	hideExportAsCSV: PropTypes.bool,
	onExportClick: PropTypes.func,
	hideCustomExport: PropTypes.bool,
	hideExportCSVForExcel: PropTypes.bool,
	loading: PropTypes.bool,
};

function ViewExportPopover({ onClick, hideExportCSVForExcel }) {
	return (
		<div className="d-flex flex-column cursor-pointer ">
			<div
				className="d-flex cell-header__menu-option align-items-center cursor-pointer z-option-menu__option"
				onClick={() => {
					onClick({ fileFormat: csvFileFormat.GENERAL });
				}}
			>
				<Typography variant="button_extrasmall_medium">
					Export View as CSV
				</Typography>
			</div>

			{!hideExportCSVForExcel && (
				<div
					className="d-flex cell-header__menu-option align-items-center cursor-pointer z-option-menu__option"
					onClick={() => {
						onClick({ fileFormat: csvFileFormat.EXCEL });
					}}
				>
					<Typography variant="button_extrasmall_medium">
						Export CSV for Excel
					</Typography>
				</div>
			)}
		</div>
	);
}

ViewExportPopover.propTypes = {
	onClick: PropTypes.func,
	hideExportCSVForExcel: PropTypes.bool,
};
function DensityPopover({ onClick }) {
	const { density, updateTableDensity } = useTableContext();

	const updateViewDensity = (density) => {
		updateTableDensity(density);
		onClick();
	};
	return (
		<div className="d-flex flex-column cursor-pointer justify-content-between">
			<div
				className="d-flex  cursor-pointer justify-content-between cell-header__menu-option align-items-center z-option-menu__option"
				onClick={() => {
					updateViewDensity(DensityEnum.COMPACT);
				}}
			>
				<Typography variant="button_extrasmall_medium">
					Compact
				</Typography>
				{density === DensityEnum.COMPACT && (
					<img src={blacktick} alt="tick mark" />
				)}
			</div>
			<div
				className="d-flex cursor-pointer justify-content-between cell-header__menu-option align-items-center z-option-menu__option"
				onClick={() => {
					updateViewDensity(DensityEnum.COMFORTABLE);
				}}
			>
				<Typography variant="button_extrasmall_medium">
					Comfortable
				</Typography>
				{density === DensityEnum.COMFORTABLE && (
					<img src={blacktick} alt="tick mark" />
				)}
			</div>
		</div>
	);
}

DensityPopover.propTypes = {
	onClick: PropTypes.func,
};
