import React from "react";

import classNames from "classnames";
import PropTypes, {
	bool,
	string,
	node,
	array,
	shape,
	arrayOf,
} from "prop-types";
import { map } from "underscore";

import { BUTTON_VARIANT, GREY_VARIANT, Typography } from "@zluri/ui-components";

import arrow from "assets/icons/rightArrowFilled.svg";

export const CategoryOption = ({ isSelected, onClick, title, count }) => {
	return (
		<Typography
			className={classNames(
				`padding_8 d-flex align-items-center gap-4 z_multilevel_filter-category`,
				{ "z_multilevel_filter-category-selected": isSelected }
			)}
			variant={BUTTON_VARIANT.BUTTON_EXTRA_SMALL_MEDIUM}
			color={GREY_VARIANT.SECONDARY_GREY_2}
			onClick={onClick}
		>
			<span className="mr-auto">{title}</span>
			{count > 0 && (
				<span className="z_multilevel_filter-categories_category-count secondary_tag blue_tint_bg black">
					{count}
				</span>
			)}
			<span className="d-flex align-items-center justify-content-center">
				<img src={arrow} alt="" />
			</span>
		</Typography>
	);
};

const Options = ({ options, count, onClick, isSelected }) => {
	return (
		<>
			{map(options, ({ key, title, options }) => (
				<React.Fragment key={key}>
					<CategoryOption
						onClick={() => onClick(key, options)}
						isSelected={isSelected(key)}
						count={count(key, options)}
						title={title}
					/>
				</React.Fragment>
			))}
		</>
	);
};

export const CategoryOptions = React.memo(Options);

Options.propTypes = {
	options: shape({
		value: string,
		title: node,
		children: arrayOf(
			shape({
				value: string,
				title: node,
				disabled: bool,
				children: array,
			})
		),
		disabled: bool,
	}),
	count: PropTypes.func,
	onClick: PropTypes.func,
	isSelected: PropTypes.func,
};

CategoryOption.propTypes = {
	isSelected: PropTypes.bool,
	onClick: PropTypes.func,
	title: PropTypes.string,
	count: PropTypes.number,
};
