import GetImageOrNameBadge from 'common/GetImageOrNameBadge';
import {Typography} from '@zluri/ui-components';
import React from 'react';

export function ClusterApplications({ component, data, ...props }) {
    const applications = data[component.valueKey];
    
	return (
		<div className="d-flex align-items-center">
			{applications.slice(0, 4)?.map((app, index) => (
				<>
					<div key={app._id} className="d-flex align-items-center">
						<GetImageOrNameBadge
							width={16}
							height={16}
							url={app.url}
							name={app.name}
						/>{" "}
						<Typography variant="body_1_regular" className="pl-1">
							{app.name}
						</Typography>
					</div>
					{index !== 3 && <div className="px-2">+</div>}
				</>
			))}
		</div>
	);
}
