import { filterPropertiesHelper } from "modules/v2InfiniteTable/utils/v2infiniteTableUtil";
import { client, clientV3, clientEmployeeV2 } from "../../utils/client";

function getClient() {
	return window.location.href.includes("user") ? clientEmployeeV2 : clientV3;
}
export async function getAllTasks(
	reqObj,
	page,
	row,
	cancelTokenSource = null,
	searchReqParams,
) {
	let options = {};
	let url = "tasks?page=" + page + "&row=" + row;
	if (cancelTokenSource) {
		options = {
			cancelToken: cancelTokenSource.token,
		};
	}

	if (searchReqParams?.length > 0) {
		url = `tasks?is_search=${true}&search_query=${encodeURIComponent(
			searchReqParams,
		)}&page=${page}&row=${row}`;
	}

	let response = await getClient().post(url, reqObj, options);

	return response.data;
}

export async function getTasksPropertiesListv3() {
	const response = await getClient().get(`tasks/filters`);
	let newResponseData = filterPropertiesHelper(response.data);
	response.data = newResponseData;
	return newResponseData;
}
export async function getTasksTabCount() {
	const response = await getClient().get("tasks/tab_count");
	return response.data;
}
