import React, { useRef, useState } from "react";

export default function LicenseForm(props) {
	const [editText, setEditText] = useState("");
	const [cost, setCost] = useState("");
	const [quantity, setQuantity] = useState("");
	const cancelToken = useRef();
	const editFieldRef = useRef();

	return (
		<div
			ref={editFieldRef}
			style={{
				width: "160px",
				height: "26px",
				borderRadius: "6px",
				border: "1px solid #2266E2",
			}}
			className="d-flex align-items-center"
		>
			<input
				type="text"
				className="border-0"
				style={{ flexGrow: 1, backgroundColor: "inherit" }}
				placeholder={`License Name`}
				value={editText}
				onChange={(e) => setEditText(e.target.value)}
			/>
			<div className="flex">
				<input
					type="text"
					className="border-0"
					style={{ flexGrow: 1, backgroundColor: "inherit" }}
					placeholder={`Cost`}
					value={editText}
					onChange={(e) => setCost(e.target.value)}
				/>
				<input
					type="text"
					className="border-0"
					style={{ flexGrow: 1, backgroundColor: "inherit" }}
					placeholder={`Quantity`}
					value={editText}
					onChange={(e) => setQuantity(e.target.value)}
				/>
			</div>
		</div>
	);
}
