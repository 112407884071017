import { singleUserReduxConstants } from "../constants/Users.constants";
import {
	UserMetricsModel,
	UserModel,
	UserOnboardOffboardDetailsModel,
} from "../model/Users.model";

const initialState = {};

export function SingleUserReducer(state = initialState, action) {
	var tempState = { ...state };
	var userId = action?.payload?.userId;
	if (!tempState[userId]) {
		tempState[userId] = {};
	}

	switch (action.type) {
		case singleUserReduxConstants.USER_BASIC_DETAILS_REQUESTED:
			tempState[userId].basicDetails = { loaded: false };
			return tempState;

		case singleUserReduxConstants.USER_BASIC_DETAILS_FETCHED:
			tempState[userId].basicDetails = {
				loaded: true,
				basicDetails: new UserModel(action.payload.basicDetails),
				error: action.payload.error,
			};
			return tempState;

		case singleUserReduxConstants.USER_ACTION_HISTORY_REQUESTED:
			tempState[userId].actionHistory = { loaded: false };
			return tempState;
		case singleUserReduxConstants.USER_ACTION_HISTORY_FETCHED:
			tempState[userId].actionHistory = {
				loaded: true,
				actionHistory: action.payload.actionHistory,
				error: action.payload.error,
			};
			return tempState;

		case singleUserReduxConstants.USER_ASSOCIATIONS_REQUESTED:
			tempState[userId].userAssociations = { loaded: false };
			return tempState;
		case singleUserReduxConstants.USER_ASSOCIATIONS_FETCHED:
			tempState[userId].userAssociations = {
				loaded: true,
				userAssociations: action.payload.userAssociations,
				error: action.payload.error,
			};
			return tempState;
		case singleUserReduxConstants.USER_SPEND_DATA_REQUESTED:
			tempState[userId].userSpendsData = { loaded: false };
			return tempState;
		case singleUserReduxConstants.USER_SPEND_DATA_FETCHED:
			tempState[userId].userSpendsData = {
				loaded: true,
				userSpendsData: action.payload.userSpendsData,
			};
			return tempState;

		case singleUserReduxConstants.UPDATE_USER_BASIC_DETAILS:
			tempState[userId].basicDetails = {
				loaded: true,
				basicDetails: {
					...tempState[userId].basicDetails?.basicDetails,
					...action.payload.updateDetails,
				},
			};
			return tempState;
		case singleUserReduxConstants.USER_OVERVIEW_METRICS_REQUESTED:
			tempState[userId].userMetrics = { loaded: false };
			return tempState;
		case singleUserReduxConstants.USER_OVERVIEW_METRICS_FETCHED:
			tempState[userId].userMetrics = {
				loaded: true,
				userMetrics: new UserMetricsModel(action.payload.userMetrics),
				error: action.payload.error,
			};
			return tempState;

		case singleUserReduxConstants.USER_ONBOARD_OFFBOARD_DETAILS_REQUESTED:
			tempState[userId].userOnboardOffboardDetails = { loaded: false };
			return tempState;
		case singleUserReduxConstants.USER_ONBOARD_OFFBOARD_DETAILS_FETCHED:
			const res = action.payload.userOnboardOffboardDetails;
			tempState[userId].userOnboardOffboardDetails = {
				loaded: true,
				userOnboardOffboardDetails: res
					? new UserOnboardOffboardDetailsModel(res)
					: null,
				error: action.payload.error,
			};
			return tempState;

		case singleUserReduxConstants.UPDATE_USER_CUSTOM_FIELD_DATA:
			tempState[userId].basicDetails = {
				loaded: true,
				basicDetails: {
					...tempState[userId].basicDetails.basicDetails,
					user_custom_fields: action.payload.user_custom_fields,
				},
			};
			return tempState;

		default:
			return state;
	}
}
