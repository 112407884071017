import { transactionActions } from "modules/transactionMapping/constants/actions";

export const deactivateDeleteInfo = ({ action, mapTransactionOption }) => {
	if (action === transactionActions.TRANSACTION_UNMAPPING) {
		if (mapTransactionOption === 1) {
			return [
				{
					type: "INFO_BANNER_BLOCK",
					text: "All Transaction unmapped using this rule previously will go through rules again.",
				},
			];
		}
		if (mapTransactionOption === 2) {
			return [
				{
					type: "INFO_BANNER_BLOCK",
					text: "All transactions unmapped will remain unaffected.",
				},
			];
		}
	}
	if (action === transactionActions.TRANSACTION_MAPPING) {
		if (mapTransactionOption === 1) {
			return [
				{
					type: "INFO_BANNER_BLOCK",
					text: "All transactions mapped using this rule previously will be unmapped.",
				},
			];
		}
		if (mapTransactionOption === 2) {
			return [
				{
					type: "INFO_BANNER_BLOCK",
					text: "All transactions mapped will remain unaffected.",
				},
			];
		}
	}
	if (action === transactionActions.TRANSACTION_ARCHIVING) {
		if (mapTransactionOption === 1) {
			return [
				{
					type: "INFO_BANNER_BLOCK",
					text: "All transactions archived using this rule previously will be unarchived.",
				},
			];
		}
		if (mapTransactionOption === 2) {
			return [
				{
					type: "INFO_BANNER_BLOCK",
					text: "All transactions archived will remain unaffected.",
				},
			];
		}
	}
	return [];
};

export const activateInfo = ({
	action,
	mapTransactionOption,
	extraMapTransactionOption,
	newRule,
}) => {
	if (action === transactionActions.TRANSACTION_UNMAPPING) {
		if (newRule) {
			if (mapTransactionOption === 1) {
				return [
					{
						type: "INFO_BANNER_BLOCK",
						text: "This rule will affect the transactions that are identified in the future.",
					},
				];
			}
			if (mapTransactionOption === 2) {
				return [
					{
						type: "INFO_BANNER_BLOCK",
						text: "This rule will affect the existing and future transactions.",
					},
				];
			}
		}
		if (mapTransactionOption === 2 && extraMapTransactionOption === 1) {
			return [
				{
					type: "INFO_BANNER_BLOCK",
					text: "All the transactions affected in the past will be run through the rules again and the modified rule will affect the Future & Existing transactions.",
				},
			];
		}
		if (mapTransactionOption === 1 && extraMapTransactionOption === 1) {
			return [
				{
					type: "INFO_BANNER_BLOCK",
					text: "All the transactions affected in the past will be run through the rules again and the modified rule will affect the Future transactions.",
				},
			];
		}
		if (mapTransactionOption === 2 && extraMapTransactionOption === 2) {
			return [
				{
					type: "INFO_BANNER_BLOCK",
					text: "All the transactions affected in the past will remain unaffected and the modified rule will affect the Future & Existing transactions.",
				},
			];
		}
		if (mapTransactionOption === 1 && extraMapTransactionOption === 2) {
			return [
				{
					type: "INFO_BANNER_BLOCK",
					text: "All the transactions affected in the past will remain unaffected and the modified rule will affect the Future transactions.",
				},
			];
		}
	}
	if (action === transactionActions.TRANSACTION_ARCHIVING) {
		if (newRule) {
			if (mapTransactionOption === 1) {
				return [
					{
						type: "INFO_BANNER_BLOCK",
						text: "Transactions that are matching conditions will be archived.",
					},
				];
			}
			if (mapTransactionOption === 2) {
				return [
					{
						type: "INFO_BANNER_BLOCK",
						text: "This rule will affect the existing and future transactions.",
					},
				];
			}
		}
		if (mapTransactionOption === 2 && extraMapTransactionOption === 1) {
			return [
				{
					type: "INFO_BANNER_BLOCK",
					text: "All the transactions affected in the past will be unarchived and the modified rule will affect the Future & Existing transactions.",
				},
			];
		}
		if (mapTransactionOption === 1 && extraMapTransactionOption === 1) {
			return [
				{
					type: "INFO_BANNER_BLOCK",
					text: "All the transactions affected in the past will be unarchived and the modified rule will affect the Future transactions.",
				},
			];
		}
		if (mapTransactionOption === 2 && extraMapTransactionOption === 2) {
			return [
				{
					type: "INFO_BANNER_BLOCK",
					text: "All the transactions affected in the past will remain unaffected and the modified rule will affect the Future & Existing transactions.",
				},
			];
		}
		if (mapTransactionOption === 1 && extraMapTransactionOption === 2) {
			return [
				{
					type: "INFO_BANNER_BLOCK",
					text: "All the transactions affected in the past will remain unaffected and the modified rule will affect the Future transactions.",
				},
			];
		}
	}
	if (action === transactionActions.TRANSACTION_MAPPING) {
		if (newRule) {
			if (mapTransactionOption === 1) {
				return [
					{
						type: "INFO_BANNER_BLOCK",
						text: "This rule will affect the transactions that are identified in the future.",
					},
				];
			}
			if (mapTransactionOption === 2) {
				return [
					{
						type: "INFO_BANNER_BLOCK",
						text: "This rule will affect the existing and future transactions.",
					},
				];
			}
		} else {
			if (extraMapTransactionOption === 1 && mapTransactionOption === 1) {
				return [
					{
						type: "INFO_BANNER_BLOCK",
						text: "All transactions mapped using this rule previously will be unmapped and future transactions will be mapped using this rule.",
					},
				];
			}
			if (extraMapTransactionOption === 1 && mapTransactionOption === 2) {
				return [
					{
						type: "INFO_BANNER_BLOCK",
						text: "All transactions mapped using this rule previously will be unmapped and this rule will affect the existing & future transactions.",
					},
				];
			}
			if (extraMapTransactionOption === 2 && mapTransactionOption === 1) {
				return [
					{
						type: "INFO_BANNER_BLOCK",
						text: "All transactions mapped will remain unaffected and future transactions will be mapped using this rule.",
					},
				];
			}
			if (extraMapTransactionOption === 2 && mapTransactionOption === 2) {
				return [
					{
						type: "INFO_BANNER_BLOCK",
						text: "All transactions mapped will remain unaffected and this rule will affect the existing & future transactions.",
					},
				];
			}
		}
	}
	return [];
};
