export const TOAST_MESSAGE = {
	CATEGORY_UPDATE_SUCESS: "Category successfully updated.",
	CATEGORY_UPDATE_ERROR: "Error updating Category!",

	OWNER_UPDATE_SUCCESS: "Owner successfully updated.",
	OWNER_UPDATE_ERROR: "Error updating Owner!",

	IT_OWNER_UPDATE_SUCCESS: "IT owner successfully updated.",
	IT_OWNER_UPDATE_ERROR: "Error updating IT owner!",

	FINANCE_OWNER_UPDATE_SUCCESS: "Finance owner successfully updated.",
	FINANCE_OWNER_UPDATE_ERROR: "Error updating finance owner!",

	RISK_LEVEL_UPDATE_SUCCESS: "Risk level successfully updated.",
	RISK_LEVEL_UPDATE_ERROR: "Error updating risk level!",

	APP_STATUS_UPDATE_SUCCESS: "App status successfully updated.",
	APP_STATUS_UPDATE_ERROR: "Error updating app status!",

	APP_TYPE_UPDATE_SUCCESS: "App type successfully updated.",
	APP_TYPE_UPDATE_ERROR: "Error updating app type!",

	APP_TAGS_UPDATE_SUCCESS: "App tags successfully updated.",
	APP_TAGS_UPDATE_ERROR: "Error updating app tags!",

	OPTIMIZATION_PLAYBOOK_NOT_SELECTED_ERROR:
		"Optimization for selected users cannot be started: Some users do not have any playbook selected!",

	OPTIMIZATION_START_SUCCESS:
		"The playbook for the selected users have been triggered: The status of the runs will be available in the past optimization section in upto 6 hours.",
	OPTIMIZATION_START_ERROR:
		"Error while starting optimization workflows for selected users. Please try again!",

	OPTIMIZATION_PLAYBOOK_NOT_PUBLISHED_ERROR:
		"One or more of the selected playbooks is not published!",

	DEFAULT_PAYMENT_MARK_SUCCESS: "Default payment marked successfully.",
	DEFAULT_PAYMENT_MARK_ERROR: "Error marking default payment!",

	PAYMENT_METHOD_ADD_SUCCESS: "Payment method successfully added.",
	PAYMENT_METHOD_ADD_ERROR: "Error adding payment method!",

	SPENDS_SETTINGS_UPDATE_SUCCESS: "Spends settings successfully updated.",
	SPENDS_SETTINGS_UPDATE_ERROR: "Error updating spends settings!",

	FILE_DOWNLOAD_ERROR: "Error in downloading the file!",

	COMPLIANCE_ADD_SUCCESS: "Compliance successfully added.",
	COMPLIANCE_ADD_ERROR: "Error in adding compliance!",

	COMPLIANCE_UPDATE_SUCCESS: "Compliance successfully updated.",
	COMPLIANCE_UPDATE_ERROR: "Error in updating compliance!",

	COMPLIANCE_ALREADY_ADDED_ERROR:
		"This compliance is already added to the application!",

	COMPLIANCE_STATUS_UPDATE_SUCCESS: "Compliance status successfully updated.",
	COMPLIANCE_STATUS_UPDATE_ERROR: "Error updating compliance status!",

	COMPLIANCE_EFFECTIVE_DATE_UPDATE_SUCCESS:
		"Effective date successfully updated.",
	COMPLIANCE_EFFECTIVE_DATE_UPDATE_ERROR: "Error updating effective date!",

	COMPLIANCE_EXPIRY_DATE_UPDATE_SUCCESS: "Expiry date successfully updated.",
	COMPLIANCE_EXPIRY_DATE_UPDATE_ERROR: "Error updating expiry date!",

	COMPLIANCE_DOCUMENT_REMOVE_SUCCESS:
		"The document has been successfully deleted.",
	COMPLIANCE_DOCUMENT_REMOVE_ERROR:
		"Error in deleting the document of the compliance!",

	MAP_APPLICATION_SUCCESS: "Successfully mapped the application.",
	MAP_APPLICATION_ERROR: "Error mapping the application!",

	MERGE_APPLICATION_SUCCESS: "Successfully merged the application.",
	MERGE_APPLICATION_ERROR: "Error merging the application!",

	ADD_SINGLE_TRANSACTION_SUCCESS: "Successfully added the transaction.",
	ADD_SINGLE_TRANSACTION_ERROR: "Error adding the transaction!",

	ADD_APPLICATION_NOTES_SUCCESS: "Successfully added the application note.",
	ADD_APPLICATION_NOTES_ERROR: "Error adding the application note!",

	DELETE_APPLICATION_NOTES_SUCCESS:
		"Successfully deleted the application note.",
	DELETE_APPLICATION_NOTES_ERROR: "Error deleting the application note!",

	EDIT_APPLICATION_NOTES_SUCCESS: "Successfully edited the application note.",
	EDIT_APPLICATION_NOTES_ERROR: "Error editing the application note!",

	ADD_APPLICATION_LINK_SUCCESS: "Successfully added the application link.",
	ADD_APPLICATION_LINK_ERROR: "Error adding the application link!",

	EDIT_APPLICATION_LINK_SUCCESS: "Successfully updated the application link.",
	EDIT_APPLICATION_LINK_ERROR: "Error updating the application link!",

	DELETE_APPLICATION_LINK_SUCCESS:
		"Successfully deleted the application link.",
	DELETE_APPLICATION_LINK_ERROR: "Error deleting the application link!",

	ARCHIVE_APPLICATION_SUCCESS: "Successfully archived the application.",
	ARCHIVE_APPLICATION_ERROR: "Error archiving the application!",

	UNARCHIVE_APPLICATION_SUCCESS: "Successfully unarchived the application.",
	UNARCHIVE_APPLICATION_ERROR: "Error unarchiving the application!",

	SKIP_CHECKLIST_SUCCESS: "Successfully skipped the application checklist.",
	SKIP_CHECKLIST_ERROR: "Error skipping the application checklist!",

	UNSKIP_CHECKLIST_SUCCESS:
		"Successfully unskipped the application checklist.",
	UNSKIP_CHECKLIST_ERROR: "Error unskipping the application checklist!",

	EXPORT_CSV_SUCCESS: "Your export is in queue.",
	EXPORT_CSV_ERROR: "Some error occured when exporting!",

	APPLICATION_AUTH_STATUS_UPDATE_SUCCESS:
		"Successfully updated application authorization status.",
	APPLICATION_AUTH_STATUS_UPDATE_ERROR:
		"Error while updating application authorization status!",

	LICENSE_UPDATE_SUCCESS: "Successfully updated license.",
	LICENSE_UPDATE_ERROR: "Error updating license!",

	LICENSE_ADD_SUCCESS: "Successfully added license.",
	LICENSE_ADD_ERROR: "Error adding license!",

	ROLE_UPDATE_SUCCESS: "Successfully updated role.",
	ROLE_UPDATE_ERROR: "Error updating role!",

	ROLE_ADD_SUCCESS: "Successfully added role.",
	ROLE_ADD_ERROR: "Error adding role!",
};
