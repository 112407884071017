import React from "react";
import { RBAC_ERROR, RBAC_ERROR_STATUS_CODES } from "../../constants/rbac";
import RbacErrorComponent from "./RbacError";
import FallbackError from "./FallbackError";

const ErrorWrapper = ({ error, ...rest }) => {
	if (
		error?.response?.data?.type === RBAC_ERROR &&
		RBAC_ERROR_STATUS_CODES.includes(error?.response?.status)
	) {
		console.error(error);
		return (
			<RbacErrorComponent
				size={rest?.size}
				backgroundColor={rest.backgroundColor}
				style={rest.style}
				showIcon={rest.showIcon}
			/>
		);
	}
	return rest.fallback ? rest.fallback : <FallbackError {...rest} />;
};

export default ErrorWrapper;
