import React from "react";
import PropTypes from "prop-types";
import { Tooltip, Typography } from "@zluri/ui-components";

import SelectEntity from "containers/v2table/cellRenderer/SelectEntity";

import iconTick from "assets/check-filled.svg";
import subcategoryarrow from "assets/applications/subcategoryarrow.svg";

import "./styles.css";

const AppCategoryDescription = ({
	category,
	handleSelect,
	selectedCategories,
}) => {
	const categoryIsSelected = selectedCategories.includes(
		category?.sub_category_id
	);

	return (
		<div
			className={`my-1 d-flex align-items-center justify-content-between gap-8 p-1 border-radius-8 cursor-pointer pr-2 app-about-category-options-container ${
				categoryIsSelected && "light-blue-bg"
			}`}
			onClick={() => handleSelect(category)}
		>
			<div className="d-flex flex-column align-items-start">
				<Tooltip
					placement="top"
					content={
						category?.category_name && (
							<Typography
								variant="body_2_regular"
								color="white"
								className="px-1"
							>
								{category.category_name}
							</Typography>
						)
					}
				>
					<Typography
						variant="body_3_regular"
						color="secondary_grey_1"
						className="truncate_10vw light-blue-bg app-about-category-popover-name"
					>
						{category?.category_name ?? "-"}
					</Typography>
				</Tooltip>

				<div className="d-flex">
					<img
						src={subcategoryarrow}
						alt="arrow"
						className="mx-1 position-relative"
						style={{ top: -2 }}
					/>
					<Tooltip
						placement="top"
						content={
							category?.sub_category_name && (
								<Typography
									variant="body_2_regular"
									color="white"
									className="px-1"
								>
									{category.sub_category_name}
								</Typography>
							)
						}
					>
						<Typography
							variant="body_2_regular"
							color="secondary_grey_1"
							className="truncate_10vw"
						>
							{category?.sub_category_name ?? "-"}
						</Typography>
					</Tooltip>
				</div>
			</div>
			{categoryIsSelected && (
				<img
					src={iconTick}
					alt="tick"
					width={14}
					height={12}
					className="cursor-pointer"
				/>
			)}
		</div>
	);
};

AppCategoryDescription.propTypes = {
	category: PropTypes.shape({
		sub_category_id: PropTypes.string,
		category_name: PropTypes.string,
		sub_category_name: PropTypes.string,
	}),
	handleSelect: PropTypes.func,
	selectedCategories: PropTypes.array,
};

export function AppCategoryEditPopover({ data }) {
	const selectedCategories =
		data?.app_sub_categories?.map((subCat) => subCat?.sub_category_id) ??
		[];

	return (
		<SelectEntity
			component={{
				entity: "categories",
				customApi: "subCategoryTask",
			}}
			optionsFormatter={(selected, handleSelect) => (
				<AppCategoryDescription
					category={{
						...selected,
						sub_category_name: selected?.name,
					}}
					selectedCategories={selectedCategories}
					handleSelect={handleSelect}
				/>
			)}
			appId={data["app_id"]}
			data={data}
			hideScrollAndDivider
			hideEmail
			optionsMaxHeight={170}
		/>
	);
}

AppCategoryEditPopover.propTypes = {
	data: PropTypes.shape({
		app_sub_categories: PropTypes.array,
		app_id: PropTypes.string,
	}),
};
