import React, { useEffect, useRef, useState } from "react";

import PropTypes from "prop-types";

import {
	IconButton,
	Popover,
	theme,
	useOutsideClickListener,
} from "@zluri/ui-components";
import { AppActionItems } from "./AppActionItems";

import DotsImage from "assets/three-dots-black.svg";
import { useDispatch } from "react-redux";
import { setAppArchive } from "modules/applications/redux/Applications.actions";

const AppActions = ({ app, appId }) => {
	const ref = useRef();
	const dispatch = useDispatch();

	const [show, setShow] = useState();

	useOutsideClickListener(
		[ref],
		() => setShow(false),
		[],
		["z_overview_header-actions_popover", "z_overview_header-subcontent"]
	);

	useEffect(() => {
		if (app?.app_id) dispatch(setAppArchive(app?.app_archive));
	}, [app]);

	return (
		<Popover
			ref={ref}
			className="z_overview_header-actions_popover"
			content={
				<AppActionItems
					show={show}
					setShow={setShow}
					app={app}
					appId={appId}
				/>
			}
			trigger={["click"]}
			placement="bottomRight"
			show={show}
		>
			<IconButton
				onClick={() => setShow(true)}
				className="z_overview_header-actions"
				icon={DotsImage}
				theme={theme}
				variant="grey"
			/>
		</Popover>
	);
};

export default AppActions;

AppActions.propTypes = {
	app: PropTypes.object,
	appId: PropTypes.string,
};
