import { useEffect, useMemo, useState } from "react";

import { isFunction } from "underscore";

import {
	SOURCE_API_MAPPER,
	SOURCE_FILTER_GROUP,
	getGroupedSourcesOptions,
} from "./utils";

const useGroupedSources = ({ sources }) => {
	const options = useMemo(() => {
		if (sources?.length) {
			return getGroupedSourcesOptions(sources, SOURCE_FILTER_GROUP);
		}
	}, [sources]);
	return { options };
};

const useSourcesApiByModule = ({ type = SOURCE_API_MAPPER.user, appId }) => {
	const [data, setData] = useState({
		sources: [],
		error: null,
		loading: false,
	});

	const apiConfig = SOURCE_API_MAPPER[type];

	async function getSources() {
		const response = await apiConfig?.api({ appId });
		setData({
			...data,
			sources:
				apiConfig?.formatter && isFunction(apiConfig?.formatter)
					? apiConfig?.formatter(response)
					: response,
			loading: false,
			error: null,
		});
	}

	useEffect(() => {
		try {
			setData({ ...data, loading: true });
			if (apiConfig?.api && isFunction(apiConfig?.api)) {
				getSources();
			}
		} catch (e) {
			setData({ ...data, loading: false, error: e });
		}
	}, []);

	return data;
};

export { useGroupedSources, useSourcesApiByModule };
