import React from "react";

import PropTypes from "prop-types";

import { Divider } from "antd";
import {
	BUTTON_VARIANT,
	GREY_VARIANT,
	Loader,
	NoDataCard,
	Typography,
} from "@zluri/ui-components";

import unmap from "assets/icons/Icon_Unmap.svg";

import { useOverviewActionGenerator } from "modules/applications/containers/OverviewV2/customHooks";
import { HeaderTabWithPopover } from "./Header";

const AppActionItems = ({ app, appId, setShow }) => {
	const { actions } = useOverviewActionGenerator(setShow, appId, app);

	return (
		<span className="d-flex flex-column align-items-start cursor-pointer padding_2 z_overview_header-action-list">
			{actions?.map((child, idx) => (
				<>
					{child?.map(({ icon, text, onClick, subContent }, idx) => (
						<HeaderTabWithPopover
							key={idx}
							Component={
								<ActionItem
									onClick={onClick}
									icon={icon}
									text={text}
									key={idx}
								/>
							}
							popoverProps={{
								content: <SubActionItem {...subContent} />,
								placement: "left",
								className: "z_overview_header-subcontent",
							}}
							hasPopover={subContent}
						/>
					))}
					<Divider
						style={{ background: "#E1E8F8", margin: "0" }}
						key={idx}
					/>
				</>
			))}
		</span>
	);
};

const ActionItem = ({ onClick, icon, text }) => {
	return (
		<span
			onClick={onClick}
			className="d-flex align-items-center gap-6 padding_6 z_overview_header-action_option flex-1 w-100"
		>
			<img src={icon} alt="" width={16} height={16} />
			<Typography
				variant={BUTTON_VARIANT.BUTTON_EXTRA_SMALL_MEDIUM}
				color={GREY_VARIANT.SECONDARY_GREY_2}
			>
				{text}
			</Typography>
		</span>
	);
};

const SubActionItem = ({ onCancel, title, applyText, onApply, loading }) => {
	return (
		<NoDataCard
			className=""
			title={title}
			icon={unmap}
			iconStyle={{
				width: "16px",
				height: "16px",
			}}
			iconContainerStyle={{
				width: "fit-content",
				height: "fit-content",
				background: "#FFE9E5",
			}}
			actions={[
				{
					text: "Cancel",
					onClick: onCancel,
					variant: "primary-alternative",
					disabled: loading,
				},
				{
					text: loading ? <Loader color="white" /> : applyText,
					onClick: onApply,
					variant: "primary-danger",
					disabled: loading,
				},
			]}
		/>
	);
};

ActionItem.propTypes = {
	onClick: PropTypes.func,
	icon: PropTypes.string,
	text: PropTypes.node,
};

AppActionItems.propTypes = {
	app: PropTypes.object,
	appId: PropTypes.string,
	setShow: PropTypes.func,
};

SubActionItem.propTypes = {
	onCancel: PropTypes.func,
	title: PropTypes.node,
	applyText: PropTypes.node,
	onApply: PropTypes.func,
	loading: PropTypes.bool,
};

export { AppActionItems, SubActionItem, ActionItem };
