import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	checkAndFetchAllV2Data,
	checkAndFetchV2SourceListForFilter,
	clearAllV2DataCache,
	fetchAllV2Data,
	searchAllV2Data,
	v2updateScrollPosition,
} from "./redux/v2infinite-action";
import {
	addArchiveFilter,
	defaultReqBody,
	getSearchReqObj,
} from "./utils/v2infiniteTableUtil";
import { debounce } from "../../utils/common";
import { InfiniteTableFilter } from "./InfiniteTableFilter";
import { EmptySearch } from "../../common/EmptySearch";
import { ErrorComponent } from "../../common/ErrorComponnet";
import { Empty } from "../security/components/Empty/Empty";
import InfiniteTable from "./infiniteTable";
import { SCREEN_TAG_MAP } from "../../constants/views";
import { clearAllV2DataCache as clearPaginatedTableData } from "modules/v2PaginatedTable/redux/v2paginated-action";
import { filterTableColumns } from "modules/shared/utils/shouldRenderFeature";
import { createSelector } from "@reduxjs/toolkit";
import ErrorWrapper from "UIComponents/Rbac/ErrorWrapper";

export default function InfiniteTableContainer({
	columnsMapper,
	screenTagKey,
	screenType,
	v2TableEntity,
	v2SearchFieldId,
	v2SearchFieldName,
	getAPI,
	searchAPI,
	propertyListAPI,
	keyField,
	chipText,
	hasBulkEdit = false,
	bulkEditComponents,
	onAddClick,
	fullRowArray,
	fullRowMapper,
	exportComponent,
	archiveFilterId,
	sourceListAPI,
	bulkUpdateViaCSVComponent,
	rowFormatRequired,
	rowFormatClassName,
	user_search,
	pageLayoutPresent,
	active_view,
	set_all_present,
	forceShowBulkEditComponents,
	hasExpandedRow,
	loadexpandedRowComponent,
	app_search,
	singleKeywordSearch = false,
	hideColumnsButton,
	tableFilterLeftSideComps,
	handleRowClick,
	resetFilter = false,
	excludeFilters = [],
	callLoadTableHelpers,
	searchPlaceholder,
	viewOptions,
	customFieldEntity,
	hideFilterIcons = false,
	tableInfoBanner,
	addClickComponent,
}) {
	const cancelToken = useRef();
	const [checked, setChecked] = useState([]);
	const v2Data = (state) => state.v2Data;
	const [reqBody, setReqBody] = useState({ ...defaultReqBody });
	const [v2Entity, setV2Entity] = useState(v2TableEntity);
	const v2EntitySelector = createSelector(
		[v2Data],
		(a) => a[v2Entity]?.[`${JSON.stringify(reqBody)}`] || {}
	);
	const v2SourceListSelector = createSelector(
		[v2Data],
		(a) => a[v2Entity]?.source_list || {}
	);
	const {
		data,
		metaData,
		hasMoreData,
		isLoadingData,
		pageNo,
		error: dataError,
		scrollTop,
	} = useSelector(v2EntitySelector);

	const v2FilterEntitySelector = createSelector(
		[v2Data],
		(a) => a[v2Entity]?.property_file || {}
	);
	const viewsDataSelector = createSelector(
		[(state) => state.viewsnew],
		(a) => a?.[SCREEN_TAG_MAP[screenTagKey]] || {}
	);

	const {
		source_list_for_filter_loaded,
		source_list_for_filter,
		error: source_list_error,
	} = useSelector(v2SourceListSelector);

	const dispatch = useDispatch();
	const query = useSelector((state) => state.router.location.query);
	const [searchQuery, setSearchQuery] = useState(
		query?.searchQuery
			? decodeURIComponent(query && query?.searchQuery)
			: undefined
	);
	const router = useSelector((state) => state.router);
	let { hash } = router.location;
	hash = hash.split("#");
	hash = hash[hash.length - 1];
	const [showErrorModal, setShowErrorModal] = useState(false);
	const [checkAll, setCheckAll] = useState(false);
	const [checkAllExceptionData, setCheckAllExceptionData] = useState([]);
	const [selectedData, setSelectedData] = useState([]);
	const [searchFilter, setSearchFilter] = useState([]);
	const { error: filterError } = useSelector(v2FilterEntitySelector);
	const { error: viewsError } = useSelector(viewsDataSelector);
	const viewsData = useSelector(viewsDataSelector);

	const tableError = filterError || viewsError || dataError;
	const filtersError = filterError || viewsError;

	useEffect(() => {
		if (!source_list_for_filter_loaded && sourceListAPI) {
			dispatch(
				checkAndFetchV2SourceListForFilter(v2Entity, sourceListAPI)
			);
		}
	}, []);
	useEffect(() => {
		if (query?.selectedTab === "runs") handleRefresh();
		if (callLoadTableHelpers && reqBody && reqBody.filter_by) {
			callLoadTableHelpers(reqBody);
		}
	}, [query]);

	useEffect(() => {
		if (v2TableEntity) {
			setV2Entity(v2TableEntity);
		}
	}, [v2TableEntity]);

	useEffect(() => {
		if (searchQuery && query?.metaData && query.searchQuery) {
			loadData();
		}
		if (callLoadTableHelpers && reqBody && reqBody.filter_by) {
			callLoadTableHelpers(reqBody);
		}
	}, [pageNo]);

	useEffect(() => {
		if (!query?.searchQuery) {
			loadData();
		} else if (query?.searchQuery) {
			loadSearch();
		}
	}, [query]);

	useEffect(() => {
		if (searchQuery === "") {
			loadData();
		} else if (
			searchQuery?.length > 1 ||
			(searchQuery && singleKeywordSearch)
		) {
			loadSearch();
			let searchFilterObj = getSearchReqObj(
				searchQuery,
				v2SearchFieldId,
				v2SearchFieldName
			);
			setSearchFilter(searchFilterObj);
		}
	}, [searchQuery]);

	const loadSearch = () => {
		let urlMetaData;
		let urlReqBody = reqBody;
		let newReqObj = { ...reqBody };
		if (query.metaData) {
			urlMetaData = JSON.parse(JSON.stringify(query));
			urlReqBody = JSON.parse(decodeURIComponent(urlMetaData.metaData));
			newReqObj.sort_by = urlReqBody.sort_by || [];
			newReqObj.filter_by = urlReqBody.filter_by || [];
			newReqObj.group_filter_by = urlReqBody.group_filter_by || [];
			newReqObj.columns = urlReqBody.columns || [];
			if (urlReqBody.reset_filter) {
				newReqObj.reset_filter = urlReqBody.reset_filter;
			} else {
				delete newReqObj.reset_filter;
			}
			if (
				archiveFilterId &&
				urlReqBody?.filter_by?.length &&
				!searchQuery
			) {
				newReqObj.filter_by = addArchiveFilter(
					urlReqBody,
					newReqObj,
					archiveFilterId
				);
			}
		}
		if (screenTagKey) {
			newReqObj.screen_tag = SCREEN_TAG_MAP[screenTagKey];
		}
		if (screenType) {
			newReqObj.type = screenType;
		}
		if (searchQuery && searchQuery.length > 0) {
			delete newReqObj.reset_filter;
			const searchObj = getSearchReqObj(
				searchQuery,
				v2SearchFieldId,
				v2SearchFieldName
			);
			const searchByEmailObj = getSearchReqObj(
				searchQuery,
				screenType === "onboarding" || screenType === "offboarding"
					? "onboardingoffboardingusers.email"
					: "user_email",
				"User Email"
			);
			newReqObj.filter_by = user_search
				? [
						searchObj,
						{
							field_id: "user_account_type",
							field_name: "User Account Type",
							field_values: stateFilterMap[hash],
							filter_type: "string",
							field_order: "contains",
							negative: false,
							is_custom: false,
						},
					]
				: app_search
					? [
							searchObj,
							{
								field_id: "app_state",
								field_name: "Authorisation Status",
								field_values: stateFilterMap[hash],
								filter_type: "string",
								field_order: "contains",
								negative: false,
								is_custom: false,
							},
						]
					: [searchObj];
		} else {
			newReqObj.filter_by = [];
			delete newReqObj.user_search;
		}
		let searchReqParams = {
			is_search: true,
			search_query: searchQuery,
		};
		setReqBody({ ...newReqObj });

		dispatch(
			checkAndFetchAllV2Data(
				newReqObj,
				0,
				30,
				v2Entity,
				getAPI,
				searchReqParams
			)
		);
	};

	const loadData = (isRefresh) => {
		if (
			query.searchQuery &&
			searchQuery?.length > 1 &&
			!isRefresh &&
			tableError
		)
			return;
		let urlMetaData;
		let urlReqBody = reqBody;
		let newReqObj = { ...reqBody };
		if (query.metaData) {
			urlMetaData = JSON.parse(JSON.stringify(query));
			urlReqBody = JSON.parse(decodeURIComponent(urlMetaData.metaData));
			newReqObj.sort_by = urlReqBody.sort_by || [];
			newReqObj.filter_by = urlReqBody.filter_by || [];
			newReqObj.group_filter_by = urlReqBody.group_filter_by || [];
			newReqObj.columns = urlReqBody.columns || [];
			if (urlReqBody.reset_filter) {
				newReqObj.reset_filter = urlReqBody.reset_filter;
			} else {
				delete newReqObj.reset_filter;
			}
			if (
				archiveFilterId &&
				newReqObj?.filter_by?.length &&
				!searchQuery
			) {
				newReqObj.filter_by = addArchiveFilter(
					newReqObj,
					newReqObj,
					archiveFilterId
				);
			}
		}
		if (screenTagKey) {
			newReqObj.screen_tag = SCREEN_TAG_MAP[screenTagKey];
		}
		if (screenType) {
			newReqObj.type = screenType;
		}
		if (resetFilter && !query.metaData) {
			newReqObj.filter_by = [];
		}
		setReqBody({ ...newReqObj });
		dispatch(checkAndFetchAllV2Data(newReqObj, 0, 30, v2Entity, getAPI));
	};

	function handleLoadMore() {
		if (hasMoreData && !tableError) {
			dispatch(fetchAllV2Data(reqBody, pageNo + 1, 30, v2Entity, getAPI));
		}
	}

	async function handleRefresh() {
		const clearData = await dispatch(clearAllV2DataCache(v2Entity));
		const clearPaginatedData = dispatch(clearPaginatedTableData(v2Entity));
		if (searchQuery?.length > 1) {
			loadSearch();
		} else {
			loadData();
		}
		setShowErrorModal(false);
		if (callLoadTableHelpers && reqBody && reqBody.filter_by) {
			callLoadTableHelpers(reqBody);
		}
	}

	function updateScrollPosition(scrollTop) {
		dispatch(v2updateScrollPosition(v2Entity, reqBody, scrollTop));
	}

	console.log("source_list_error", source_list_error);

	return (
		<>
			<InfiniteTableFilter
				error={filtersError}
				checked={checked}
				setChecked={setChecked}
				searchQuery={searchQuery}
				setSearchQuery={debounce(setSearchQuery, 700)}
				metaData={metaData}
				columnsMapper={filterTableColumns(
					columnsMapper(handleRefresh, metaData, setChecked)
				)}
				usedColumns={metaData?.columns}
				handleRefresh={handleRefresh}
				isLoadingData={isLoadingData}
				reqBody={reqBody}
				setReqBody={setReqBody}
				v2Entity={v2Entity}
				getAPI={getAPI}
				propertyListAPI={propertyListAPI}
				chipText={chipText}
				hasBulkEdit={hasBulkEdit}
				bulkEditComponents={bulkEditComponents}
				onAddClick={onAddClick}
				exportComponent={exportComponent}
				screenTagKey={screenTagKey}
				sourceList={source_list_for_filter}
				sourceListError={source_list_error}
				bulkUpdateViaCSVComponent={bulkUpdateViaCSVComponent}
				pageLayoutPresent={pageLayoutPresent}
				checkAll={checkAll}
				setCheckAll={setCheckAll}
				setCheckAllExceptionData={setCheckAllExceptionData}
				active_view={active_view}
				checkAllExceptionData={checkAllExceptionData}
				selectedData={selectedData}
				setSelectedData={setSelectedData}
				forceShowBulkEditComponents={forceShowBulkEditComponents}
				hideColumnsButton={hideColumnsButton}
				tableFilterLeftSideComps={tableFilterLeftSideComps}
				excludeFilters={excludeFilters}
				viewOptions={viewOptions}
				searchPlaceholder={searchPlaceholder}
				hideFilterIcons={hideFilterIcons}
				tableInfoBanner={tableInfoBanner}
				addClickComponent={addClickComponent}
			/>
			<InfiniteTable
				error={tableError}
				checked={checked}
				setChecked={setChecked}
				data={data}
				metaData={metaData}
				handleLoadMore={handleLoadMore}
				columnsMapper={filterTableColumns(
					columnsMapper(handleRefresh, metaData, setChecked)
				)}
				keyField={keyField}
				hasExpandedRow={hasExpandedRow}
				v2TableEntity={v2TableEntity}
				customFieldEntity={customFieldEntity}
				emptyState={
					searchQuery && !tableError ? (
						<EmptySearch
							searchQuery={searchQuery}
							metaData={metaData}
							searchFilter={searchFilter}
							app_search={app_search}
							user_search={user_search}
							onReset={() => {
								setSearchQuery("");
								setReqBody({ ...defaultReqBody });
							}}
						/>
					) : tableError ? (
						<ErrorWrapper
							error={tableError}
							fallback={
								<ErrorComponent
									onReset={() => {
										setSearchQuery();
										setReqBody({ ...defaultReqBody });
										dispatch(
											fetchAllV2Data(
												reqBody,
												0,
												30,
												v2Entity,
												getAPI
											)
										);
									}}
								/>
							}
						/>
					) : (
						<Empty />
					)
				}
				searchQuery={searchQuery}
				onSort={() => searchQuery && setSearchQuery("")}
				isLoadingData={isLoadingData}
				hasMoreData={hasMoreData}
				allowFewSpecialCharacters={true}
				disableCheckbox={!hasBulkEdit}
				key={JSON.stringify({ ...reqBody })}
				fullRowArray={fullRowArray}
				fullRowMapper={fullRowMapper}
				rowFormatRequired={rowFormatRequired}
				rowFormatClassName={rowFormatClassName}
				checkAll={checkAll}
				setCheckAll={setCheckAll}
				checkAllExceptionData={checkAllExceptionData}
				setCheckAllExceptionData={setCheckAllExceptionData}
				chipText={chipText}
				set_all_present={set_all_present}
				updateScrollPosition={updateScrollPosition}
				scrollTop={scrollTop}
				selectedData={selectedData}
				setSelectedData={setSelectedData}
				loadexpandedRowComponent={loadexpandedRowComponent}
				handleRefresh={handleRefresh}
				handleRowClick={handleRowClick}
			/>
		</>
	);
}

export const stateFilterMap = {
	managed: ["centrally managed", "team managed", "individually managed"],
	unmanaged: ["unmanaged"],
	needs_review: ["needs review"],
	restricted: ["restricted"],
	group: ["group"],
	employees: ["employee"],
	service: ["service"],
	marked_for_onboarding: ["onboarding"],
	marked_for_offboarding: ["offboarding"],
	external: ["external"],
	all_apps: [
		"centrally managed",
		"team managed",
		"individually managed",
		"unmanaged",
		"needs review",
		"restricted",
	],
};
