import React, { useState } from "react";
import PropTypes from "prop-types";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import subcategoryarrow from "assets/applications/subcategoryarrow.svg";
import arrowInt from "assets/v2tables/arrow-grey.svg";
import { getCellRenderer } from "containers/v2table/cellRenderer/componentFactory";
import { Link } from "react-router-dom";
import pencil from "assets/icon_edit_large.svg";
import Icon_Edit_Pencil_Disabled from "../../../assets/icons/Icon_Edit_Pencil_Disabled.svg";

import { capitalizeFirstLetter } from "utils/common";
import Divider from "../Divider/Divider";
import CurrencySymbol from "common/CurrencyCommons/currency-symbol";
import { getOrgCurrency } from "constants/currency";
import chargebackLicense from "assets/v2tables/license-chargeback.svg";
import chargebackManual from "assets/v2tables/chargeback-manual.svg";
import chargebackTotal from "assets/v2tables/chargeback-total.svg";
import chargebackUsers from "assets/v2tables/chargeback-users.svg";
import classNames from "classnames";
import ManualLicense from "assets/v2tables/icon-manual-licensemapping.svg";
import IntegrationLicense from "assets/v2tables/license-mapping-integration.svg";
import AutoManualLicense from "assets/v2tables/license-mapping-auto.svg";
import moment from "moment";
import {
	BODY_VARIANT,
	Currency,
	GREY_VARIANT,
	ImageOrNameBadge,
	Typography,
	CurrencySymbol as ZluriCurrencySymbol,
	theme,
	Tooltip as ZTooltip,
	Button,
} from "@zluri/ui-components";
import LongTextTooltip from "modules/shared/components/LongTextTooltip/LongTextTooltip";
import { useSelector } from "react-redux";
import {
	HASH_ONGOING_DETAILS,
	PENDING,
	TOOLTIP_DELEGATE_REVEIWER_DISABLED_SIGNEDOFF,
	TOOLTIP_DELEGATE_REVIEWER_DISABLED_IF_NOT_OWNER,
	certification_stage,
} from "modules/AccessReview/constants/constants";
import { IsCertOwner } from "modules/AccessReview/util/util";

import { getNested } from "utils/getNested";
import cn from "classnames";
import { SpendsBudgetFields } from "containers/v2table/cellRenderer/popovers/SpendsBudgetPopOver";
import { isUndefined } from "underscore";
import { getTooltipContent } from "modules/AccessReview/components/common/common";
import { getSegmentFromURL } from "@zluri/ui-components";

export function Text({ data, component, hasHover, hasClick }) {
	const valuefromComponent = component?.valueKey;

	const value = Array.isArray(valuefromComponent)
		? valuefromComponent?.reduce((_, x) => _?.[x], data)
		: getNested(data, valuefromComponent);
	const typeOfValue = component?.valueType ?? typeof value;

	const TypeMapper = {
		number: (
			<span className="d-flex align-items-center justify-content-end">
				{isUndefined(value) ? (
					<Typography
						color="secondary_grey_1"
						variant="body_1_regular"
					>
						-
					</Typography>
				) : (
					<>
						{/*TODO -> Don't understand why currency is used for all types of text*/}
						<Currency
							color="secondary_grey_1"
							fontVariant="body_1_regular"
							locale="en-US"
							value={value ?? 0}
							showDecimals={!!component?.showDecimals}
							className={classNames(
								{ cell__dashed__underline: hasHover },
								{ cell__click__hover: hasClick }
							)}
						/>
						{component?.showPercentage && (
							<Typography
								variant="body_1_regular"
								color="secondary_grey_1"
								style={{ marginLeft: 2 }}
								className={classNames(
									{ cell__dashed__underline: hasHover },
									{ cell__click__hover: hasClick }
								)}
							>
								%
							</Typography>
						)}
					</>
				)}
			</span>
		),
		string: (
			<Typography
				variant={component?.fontVariant || "body_1_regular"}
				color="secondary_grey_1"
				className={classNames(
					{ cell__dashed__underline: hasHover },
					{ cell__click__hover: hasClick }
				)}
			>
				{/* This needs to be fixed later */}
				{value === "macos" ? "MacOS" : capitalizeFirstLetter(value)}
				{component?.showPercentage && " %"}
			</Typography>
		),
		email: (
			<Typography
				variant={component?.fontVariant || "body_1_regular"}
				color="secondary_grey_1"
				className={classNames(
					{ cell__dashed__underline: hasHover },
					{ cell__click__hover: hasClick }
				)}
			>
				{value}
			</Typography>
		),
		boolean: (
			<Typography
				variant={"body_1_regular"}
				color="secondary_grey_1"
				className="text-capitalize"
			>
				{String(value)}
			</Typography>
		),
	};

	return (
		<span
			style={{
				borderBottom:
					component?.hoverComponent && "1px dashed #000!important",
				padding: component?.hoverComponent && "4px 4px",
				...component.style,
			}}
			className="v2table__cell-text cursor-pointer w-100"
		>
			{TypeMapper[typeOfValue] ? (
				TypeMapper[typeOfValue]
			) : value ? (
				<Typography
					variant={component.fontVariant || "body_1_regular"}
					color="secondary_grey_1"
					className={classNames(
						{ cell__dashed__underline: hasHover },
						{ cell__click__hover: hasClick }
					)}
				>
					{value}
					{component.showPercentage && " %"}
				</Typography>
			) : (
				<div className="w-100 d-flex justify-content-center align-items-center">
					<span>-</span>
				</div>
			)}
		</span>
	);
}
export function IntegrationSourceText({ data, component }) {
	let value = data?.[component.valueKey]?.[0];
	if (component.isPrimary) {
		value =
			data?.[component.valueKey] &&
			Array.isArray(data?.[component.valueKey])
				? data?.[component.valueKey].filter((item) => item.is_primary)
				: null;

		value = value ? value?.[0] : null;
	}
	return (
		<span
			style={{
				borderBottom:
					component?.hoverComponent && "1px dashed #000!important",
				padding: component?.hoverComponent && "4px 4px",
				...component.style,
			}}
			className="v2table__cell-text cursor-pointer w-100"
		>
			<Typography
				variant={component.fontVariant || "body_1_regular"}
				color="secondary_grey_1"
			>
				{capitalizeFirstLetter(value.keyword)}
			</Typography>
		</span>
	);
}
export function TextMap({ data, component }) {
	const options = {
		license: "License Based",
		active_users: "Active Users",
		active_users_last_30_days: "Active Users in the Month",
		default: "Active Users",
		manual: "Manual",
	};

	const icons = {
		license: chargebackLicense,
		active_users_last_30_days: chargebackUsers,
		default: chargebackLicense,
		active_users: chargebackTotal,
		manual: chargebackManual,
	};
	return (
		<span
			style={{
				borderBottom:
					component?.hoverComponent && "1px dashed #000!important",
				padding: component?.hoverComponent && "4px 4px",
				...component.style,
			}}
			className="v2table__cell-text cursor-pointer w-100 truncate"
		>
			{icons[data[component.valueKey]] && (
				<img
					alt={options[data[component.valueKey]]}
					src={icons[data[component.valueKey]]}
					style={{ paddingRight: 8 }}
				/>
			)}
			<Typography
				variant={component.fontVariant || "body_1_regular"}
				color="secondary_grey_1"
				style={{ textOverflow: "ellipsis", overflow: "hidden" }}
			>
				{options[data[component.valueKey]] || "-"}
			</Typography>
		</span>
	);
}

//$finding001
export function TextCurrency({ data, component }) {
	const orgCurrency = getOrgCurrency();
	return (
		<span
			style={{
				borderBottom:
					component?.hoverComponent && "1px dashed #000!important",
				padding: component?.hoverComponent && "4px 5px",
				...component.style,
			}}
			className="v2table__cell-text cursor-pointer w-100"
		>
			<ZluriCurrencySymbol
				color="secondary_grey_1"
				currencyCode={data[component?.current_valueKey] || orgCurrency}
				fontVariant="body_1_regular"
				locale="en-US"
			/>
		</span>
	);
}

export function IntegrationConnectText({ data, component }) {
	const value = data[component.valueKey];

	if (!value)
		return (
			<Typography variant="body_1_regular" color="secondary_grey_1">
				-
			</Typography>
		);

	if (value === "connect now") {
		return (
			<Link
				to={`integrations/catalog/detail/${data._id}#overview`}
				className="d-flex"
				style={{ textDecoration: "none" }}
			>
				<Typography
					variant="body_1_regular"
					color="secondary_grey_4"
					className="v2table__cell-empty-link"
				>
					Connect Now
				</Typography>
				<img
					className="ml-1 v2table__cell-empty-link-icon"
					src={arrowInt}
					alt="arrow icon"
				/>
			</Link>
		);
	}
	return (
		<Typography variant="body_1_regular" color="secondary_grey_1">
			{capitalizeFirstLetter(data[component.valueKey])}
		</Typography>
	);
}

export function TitleDescriptionRenderer({ data, component }) {
	return (
		<div
			style={{ flexDirection: "column" }}
			className="flex py-1 cursor-pointer"
		>
			<div
				style={{
					borderBottom:
						component?.hoverComponent && "1px dashed #000",
				}}
				className="v2table__cell-text"
			>
				{capitalizeFirstLetter(data[component.valueKeyTitle])}
			</div>
			<div
				style={{
					color: "#717171",
					fontSize: "9px",
					borderBottom:
						component?.hoverComponent && "1px dashed #000",
				}}
				className="v2table__cell-text"
			>
				{component.descriptionComponent
					? React.createElement(
							getCellRenderer(component.descriptionComponent),
							{
								component,
								data,
							}
						)
					: data[component.valueKeyDescription]}
			</div>
		</div>
	);
}

export function TitleDescriptionArrRenderer({ data, component }) {
	let array = data[component.value];
	let title = component.valueKeyTitle;
	let description = component.valueKeyDescription;
	return (
		<div
			style={{ flexDirection: "column" }}
			className="flex py-1 cursor-pointer"
		>
			<Typography
				variant="body_1_regular"
				color="secondary_grey_1"
				className="v2table__cell-text"
			>
				{capitalizeFirstLetter(array?.[0]?.[title])}
			</Typography>

			<Typography variant="body_3_regular" color="secondary_grey_3">
				{component.descriptionComponent
					? React.createElement(
							getCellRenderer(component.descriptionComponent),
							{
								component,
								data,
							}
						)
					: array?.[0]?.type === "bank"
						? "A/C. No."
						: "Card No"}{" "}
				{" ••••" + capitalizeFirstLetter(array?.[0]?.details?.number)}
			</Typography>
		</div>
	);
}
export function StatusAndTextRenderer({ data, component }) {
	return (
		<span
			style={{
				borderBottom:
					component?.hoverComponent && "1px dashed #000!important",
			}}
			className="v2table__cell-text"
		>
			{data[component.valueKey]}
		</span>
	);
}
// remove from here
export function licenseTypeDesc({ data, component }) {
	const license = (license_name) => {
		return (
			<Typography
				variant="body_1_regular"
				color="secondary_grey_1"
				style={{
					width: "auto",
					overflow: "hidden",
					textOverflow: "ellipsis",
				}}
			>
				{license_name}
			</Typography>
		);
	};
	return (
		<>
			{data[component.valueKey]?.slice(0, 1).map((obj, index) => (
				<div
					className="d-flex flex-column"
					key={obj._id}
					style={component?.style}
				>
					{component?.showTooltip ? (
						<ZTooltip
							className="v2-table-cell"
							placement="bottom"
							content={
								<Typography
									variant="body_2_regular"
									color="white"
								>
									{obj?.license_name || obj?.name}
								</Typography>
							}
						>
							{license(obj?.license_name || obj?.name)}
						</ZTooltip>
					) : (
						license(obj?.license_name || obj?.name)
					)}

					{(obj?.license_quantity > 0 || obj?.quantity > 0) &&
						component?.showCount && (
							<div className="d-flex align-items-center">
								<Typography
									variant="body_2_bold"
									color="secondary_grey_3"
									className="mr-1"
								>
									{obj.license_quantity || obj?.quantity}
								</Typography>
								<Typography
									variant="body_2_regular"
									color="secondary_grey_3"
								>
									{obj.license_quantity === 1 ||
									obj?.quantity === 1
										? "License"
										: "Licenses"}
								</Typography>
							</div>
						)}
				</div>
			))}
		</>
	);
}

export function firstElement({ data, component }) {
	return (
		<>
			{data[component.valueKey]?.slice(0, 1).map((row, index) => (
				<OverlayTrigger
					placement="top"
					delay={{ show: 250, hide: 400 }}
					overlay={(toolTipProps) => (
						<Tooltip className="black-tooltip" {...toolTipProps}>
							<div className="px-1">
								<Typography
									variant="body_2_regular"
									color="white"
								>
									{row}
								</Typography>
							</div>
						</Tooltip>
					)}
				>
					<div className="font-14 ellipsize-typography black">{`${row}`}</div>
				</OverlayTrigger>
			))}
		</>
	);
}

export function AppLicenseMappingDescription({ data, component }) {
	const licenseMappingIcons = {
		manual: ManualLicense,
		api_integration: IntegrationLicense,
		agenda: IntegrationLicense,
		integration: IntegrationLicense,
		auto: AutoManualLicense,
	};
	// return (
	// 	<div className="font-8 grey-1">{`last ${
	// 		data?.app_license_mapping === "manual" ? "edited" : "synced"
	// 	} at ${data?.app_license_mapping_last_edited}`}</div>
	// );
	const value = data[component.valueKey];
	const icon = licenseMappingIcons[value?.toLowerCase()];
	return (
		<div className="d-flex align-items-center">
			<div className="mr-2">
				<img src={icon} alt="" style={{ width: 16, height: 16 }} />
			</div>
			<div className="d-flex flex-column">
				<Typography variant="body_1_regular" color="secondary_grey_1">
					{capitalizeFirstLetter(value)}
				</Typography>
				<div className="d-flex">
					<Typography
						variant="body_2_regular"
						color="secondary_grey_3"
						className="mr-1"
					>
						{data?.app_license_mapping === "manual"
							? "Edited"
							: "Synced"}{" "}
						on
					</Typography>
					<Typography variant="body_2_bold" color="secondary_grey_3">
						{moment(data?.app_license_mapping_last_edited).format(
							"DD MMM, YY"
						)}
					</Typography>
				</div>
			</div>
		</div>
	);
}

export const AppCategoryDescription = ({ obj, className = "" }) => {
	return (
		<div
			className={cn(
				"d-flex p-2 flex-column align-items-start ",
				className
			)}
		>
			<ZTooltip
				placement="top"
				content={
					<Typography
						variant="body_2_regular"
						color="white"
						className="px-1"
					>
						{obj.category_name || ""}
					</Typography>
				}
			>
				<Typography
					variant="body_3_regular"
					color="secondary_grey_1"
					className="truncate_10vw"
					style={{
						padding: "2px 4px",
						background: "#E8F0FC",
						borderRadius: 4,
						width: "fit-content",
						marginBottom: 2,
					}}
				>
					{obj.category_name}
				</Typography>
			</ZTooltip>

			<div
				className="d-flex"
				style={{ width: "100%", overflow: "hidden" }}
			>
				<img
					src={subcategoryarrow}
					alt=""
					style={{
						top: -2,
						position: "relative",
						margin: "0px 4px",
					}}
				/>
				<ZTooltip
					placement="top"
					content={
						<Typography
							variant="body_2_regular"
							color="white"
							className="px-1"
						>
							{obj.sub_category_name || ""}
						</Typography>
					}
				>
					<Typography
						variant="body_2_regular"
						color="secondary_grey_1"
						className="truncate"
					>
						{obj.sub_category_name || ""}
					</Typography>
				</ZTooltip>
			</div>
		</div>
	);
};

export function CategoryDescRenderer({ data, component }) {
	return (
		<div>
			{data[component.valueKey]?.slice(0, 1).map((obj, index) => (
				<AppCategoryDescription obj={obj} key={index} />
			))}
		</div>
	);
}
export function SubCategoryRenderer({ data, component }) {
	return (
		<div
			style={{
				padding: "2px 4px",
				background: "#E8F0FC",
				borderRadius: 4,
				overflow: "hidden",
				textOverflow: "ellipsis",
			}}
		>
			<Typography variant="body_2_regular" color="secondary_grey_1">
				{data[component.valueKey]?.[0].sub_category_name}
			</Typography>
		</div>
	);
}

export function PrimaryTextRenderer({ style }) {
	return (
		<Typography variant="alerttag_small_medium" color="#5ABAFF">
			Primary
		</Typography>
	);
}

// This is introduced just to ensure that if  other modules aren't using keys prop then it defaults back to original keys
const __default__owner_keys = {
	image: "app_owner_profile",
	email: "app_owner_email",
	designation: "app_owner_designation",
};
export function OwnerDetailsRenderer({ data, component, ...props }) {
	const value = data?.[component?.valueKey];
	const keys = component?.keys ?? __default__owner_keys;
	if (!value) return null;
	return (
		<div className="v2table__owner-details">
			<div className="d-flex align-items-center">
				<ImageOrNameBadge
					height={16}
					name={value}
					url={data?.[keys?.image]}
					variant="round"
					width={16}
					className="mr-2"
					nameClassName="mr-2"
				/>

				<div className="d-flex flex-column align-items-start ml-2">
					<Typography
						color="secondary_grey_2"
						variant="body_2_bold"
						className="v2table__cell-text"
						style={{
							maxWidth: 150,
						}}
					>
						{value}
					</Typography>
					<Typography
						style={{
							maxWidth: 150,
						}}
						className="v2table__cell-text"
						color="secondary_grey_3"
						variant="body_3_regular"
					>
						{data?.[keys?.email]}
					</Typography>
				</div>
				{/*<div>*/}
				{/*	<img*/}
				{/*		style={{*/}
				{/*			position: "absolute",*/}
				{/*			top: 13,*/}
				{/*			right: 13,*/}
				{/*		}}*/}
				{/*		width={14}*/}
				{/*		src={pencil}*/}
				{/*		onClick={(e) => {*/}
				{/*			e.stopPropagation();*/}
				{/*			e.preventDefault();*/}
				{/*			props.setEditState();*/}
				{/*		}}*/}
				{/*	/>*/}
				{/*</div>*/}
			</div>
			<Divider className="my-1" type="type1" />
			<div className="d-flex justify-content-between align-items-center mt-2">
				<Typography
					variant="body_3_medium"
					color="secondary_grey_2"
					className="ml-4"
				>
					{data?.[keys?.designation]}
				</Typography>

				<div className="d-flex align-items-center">
					<div className="activeStatus mr-1" />
					<Typography
						variant="body_3_medium"
						color="secondary_grey_2"
					>
						Active
					</Typography>
				</div>
			</div>
		</div>
	);
}
export function SpendsBudgetDetailsRenderer({ data, component, ...props }) {
	const org_currency = getOrgCurrency();
	const max_app_budget = data[component.valueKey];
	const spent = data["app_spend"];
	const hasSpendsExceeded =
		max_app_budget === 0 ? false : spent >= max_app_budget;

	const spentPercentage = (spent / max_app_budget) * 100;

	const [isEdit, setIsEdit] = useState(false);
	const [showPopup, setShowPopup] = useState(false);
	return (
		<div
			className="d-flex flex-column w-100"
			style={{ padding: 8, gap: 8 }}
		>
			<div className="d-flex flex-row justify-content-between">
				<Typography variant={"body_2_bold"}>Budget</Typography>
				<Typography variant="body_2_regular">
					<div className="d-flex">
						<CurrencySymbol currencyCode={org_currency} />
						<Typography
							color={
								hasSpendsExceeded
									? "accent_red_1"
									: "secondary_grey_3"
							}
							variant={
								hasSpendsExceeded
									? "body_2_bold"
									: "body_2_regular"
							}
						>
							{spent}
						</Typography>
						&nbsp; of &nbsp;
						<CurrencySymbol currencyCode={org_currency} />{" "}
						{max_app_budget} used
					</div>
				</Typography>
			</div>
			<div className="d-flex align-items-center py-1">
				<div className="pr-2 w-100">
					<div role="progressbar" className="z-progress-bar">
						<div
							style={{
								width: `${spentPercentage}%`,
								backgroundColor: hasSpendsExceeded
									? "#2266E2"
									: "#FE6955",
							}}
							className="z-progress-value"
						/>
					</div>
				</div>
			</div>
			<hr style={{ margin: 0 }} />

			{isEdit ? (
				<SpendsBudgetFields
					defaultValue={data[component.valueKey]}
					onCancel={() => props.hidePopup()}
					handleUpdate={props.handleUpdate}
					appId={data.app_id}
				/>
			) : (
				<Button
					icon={null}
					theme={theme}
					variant="secondary-default"
					onClick={() => setIsEdit(true)}
				>
					Edit Budget
				</Button>
			)}
		</div>
	);
}

export function UsageTextRenderer({ data, component }) {
	return (
		<Typography
			variant="body_1_regular"
			color="secondary_grey_1"
			style={component.style}
		>
			{data[component.valueKey] === "active" ? "In use" : "Not in use"}
		</Typography>
	);
}

export function NewTitleDescriptionRenderer({ data, component }) {
	let title = data[component?.valueKeyTitle];
	let description = data[component?.valueKeyDescription];
	let type = data[component?.valueKeyType];
	const typeCheck = type !== "other" || type !== null;
	return (
		<div
			style={{ flexDirection: "column" }}
			className="flex py-1 cursor-pointer"
		>
			<Typography
				variant={BODY_VARIANT.BODY_1_REGULAR}
				color={GREY_VARIANT.SECONDARY_GREY_2}
				className="v2table__cell-text"
			>
				<LongTextTooltip
					text={capitalizeFirstLetter(title)}
					maxWidth="150px"
					tooltipClassName="sidebar-tooltip"
				/>
			</Typography>

			<Typography variant="body_3_regular" color="secondary_grey_3">
				{typeCheck
					? component.descriptionComponent
						? React.createElement(
								getCellRenderer(component.descriptionComponent),
								{
									component,
									data,
								}
							)
						: type === "bank"
							? "A/C. No."
							: "Card No"
					: null}{" "}
				{typeCheck && " ••••" + capitalizeFirstLetter(description)}
			</Typography>
		</div>
	);
}

export function CustomReviewerEdit({ data, component }) {
	const userInfo = useSelector((state) => state.userInfo);

	const reduxData = useSelector((state) => state.accessReview);
	const selectedApp = reduxData?.selectedApp;
	const reviewData = reduxData?.review?.data;
	const signOffJobNotPending = selectedApp?.signOffJobInProgress !== PENDING;
	const isNotReadyForRemediation = !selectedApp?.readyForRemediation;

	const isEmployee = getSegmentFromURL(1) === "user";
	const isCertOwner = IsCertOwner(reviewData?.reviewOwner?.user_id);
	const isOngDetailsScreen = window.location.hash === HASH_ONGOING_DETAILS;
	const isReviewStage =
		reviewData?.certification_stage === certification_stage.REVIEW;
	const isReviewerOrOwner =
		data?.reviewer_id === userInfo?.user_id ||
		userInfo?.user_id === data?.owner_id;
	const isNotSignedOff =
		data?.signoff_level < selectedApp?.currentReviewLevel;

	const isEnabledForAdmin = isOngDetailsScreen && isReviewStage;

	const canAdminDelegate =
		isReviewerOrOwner &&
		isEnabledForAdmin &&
		signOffJobNotPending &&
		isNotReadyForRemediation &&
		isNotSignedOff;

	const cannotDelegateReviewer =
		isEnabledForAdmin && (!isNotSignedOff || !isReviewerOrOwner);

	const isEnabledForEmp = isCertOwner && isOngDetailsScreen && isReviewStage;

	let tooltipTextForDelegateReviewerDisabled;
	if (!isNotSignedOff) {
		tooltipTextForDelegateReviewerDisabled =
			TOOLTIP_DELEGATE_REVEIWER_DISABLED_SIGNEDOFF;
	} else if (!isReviewerOrOwner) {
		tooltipTextForDelegateReviewerDisabled =
			TOOLTIP_DELEGATE_REVIEWER_DISABLED_IF_NOT_OWNER;
	}

	const DelegateReviewerComp = (
		<img className="edit__button__reviewer" src={pencil} alt="pencil" />
	);
	const DelegateReviewerDisabled = (
		<ZTooltip
			placement="bottom"
			content={getTooltipContent(tooltipTextForDelegateReviewerDisabled)}
		>
			<img src={Icon_Edit_Pencil_Disabled} alt="pencil" />
		</ZTooltip>
	);

	return canAdminDelegate
		? DelegateReviewerComp
		: cannotDelegateReviewer
			? DelegateReviewerDisabled
			: null;
}

export function CustomReviewerNameComponent({ data, component }) {
	return (
		<>
			<ZTooltip
				placement="bottom"
				className="custom_reviewer_name_tooltip"
				content={
					<div
						className="text_align_centre"
						style={{
							whiteSpace: "nowrap",
						}}
					>
						<Typography variant="body_2_regular" color="white">
							{data?.reviewer_id === data?.user_id
								? "Users and Reviewers can’t be the same, update Reviewer to proceed"
								: data?.reviewer_name}
						</Typography>
					</div>
				}
			>
				<div
					className="v2table__cell-text cursor-pointer w-100"
					style={{ marginLeft: "2px" }}
				>
					<Typography
						variant={"body_1_regular"}
						color="secondary_grey_1"
					>
						{data?.reviewer_name}
					</Typography>
				</div>
			</ZTooltip>
		</>
	);
}

/* Being used in
   Application Users -> Instances
 */
export function FirstTextRenderer({ data, component }) {
	const { valueKey, textKey, index = 0, style = {} } = component;

	const array = data?.[valueKey];
	const value = array?.at(index);
	const text = typeof value === "string" ? value : value?.[textKey];

	return (
		<Typography
			variant={BODY_VARIANT.BODY_1_REGULAR}
			color="#222222"
			className="v2table__cell-text"
			style={style}
		>
			{text}
		</Typography>
	);
}
FirstTextRenderer.propTypes = {
	data: PropTypes.object,
	component: PropTypes.shape({
		valueKey: PropTypes.string,
		textKey: PropTypes.string,
		index: PropTypes.number,
		style: PropTypes.object,
	}),
};

export function BooleanTextRenderer({ data, component }) {
	const {
		valueKey,
		trueString = "Yes",
		falseString = "No",
		style = {},
	} = component;

	const value = data?.[valueKey];
	const isTrue = Boolean(value);

	return (
		<Typography
			variant={BODY_VARIANT.BODY_1_REGULAR}
			color={GREY_VARIANT.SECONDARY_GREY_1}
			style={style}
		>
			{isTrue ? trueString : falseString}
		</Typography>
	);
}
BooleanTextRenderer.propTypes = {
	data: PropTypes.object,
	component: PropTypes.shape({
		valueKey: PropTypes.string,
		trueString: PropTypes.string,
		falseString: PropTypes.string,
		style: PropTypes.object,
	}),
};
