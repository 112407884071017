import React from "react";
import { Modal } from "react-bootstrap";
import styled from "styled-components";
import close from "assets/v2tables/editEntity/close.svg";
import PropTypes from "prop-types";
import { modalMapping } from "../../constants/modalMapping";
import {
	Button,
	theme,
	Typography,
	Tooltip,
	BODY_VARIANT,
} from "@zluri/ui-components";
import { Loader } from "common/Loader/Loader";

const StyledModal = styled(Modal)`
	.modal-content {
		border-radius: 8px !important;
		overflow: hidden;
		width: ${({ $width }) => $width}!important;
	}
`;

const ModalWrapper = styled.div`
	height: 100%;

	display: flex;
	flex-direction: column;
`;

const LoaderWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const ModalHeader = styled.div`
	display: flex;
	height: 48px;
	padding: 10px;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	background: var(--secondary-grey-7, #f6f7fa);
`;

const ModalItemsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	padding: 10px;
`;

const StyledCloseWrapper = styled.div`
	cursor: pointer;
	width: 32px;
	height: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 8px;
	&:hover {
		border: 1px solid var(--Secondary_Grey-3, #717171);
	}
`;

const ModalFooter = styled.div`
	display: flex;
	padding: 10px;
	align-items: center;
	margin-top: auto;
	justify-content: ${({ spaceBetween }) =>
		spaceBetween ? "space-between" : "flex-end"};
`;

const CtaWrapper = styled.div`
	margin-left: ${({ $hasLeftMargin }) => ($hasLeftMargin ? "10px" : "0px")};
`;

const GeneralModal = ({
	heading,
	modalItems,
	footerCtas,
	onCloseHandler,
	spaceBetween,
	loading,
	width = "408px",
	height = "294px",
}) => {
	return (
		<StyledModal show centered $width={width}>
			<ModalWrapper $height={height}>
				<ModalHeader>
					<Typography variant="body_1_bold">{heading}</Typography>
					<Tooltip
						content={
							<Typography
								variant={BODY_VARIANT.BODY_2_REGULAR}
								color="white"
							>
								close
							</Typography>
						}
						placement="bottom"
						className="border-radius-8"
					>
						<StyledCloseWrapper>
							<img
								width={16}
								height={16}
								alt="Close"
								src={close}
								onClick={onCloseHandler}
							/>
						</StyledCloseWrapper>
					</Tooltip>
				</ModalHeader>
				{loading ? (
					<LoaderWrapper>
						<Loader height={150} width={150} />
					</LoaderWrapper>
				) : (
					<ModalItemsWrapper>
						{modalItems?.map(({ type, ...rest }, index) => (
							<>{modalMapping[type]({ ...rest })}</>
						))}
					</ModalItemsWrapper>
				)}
				<ModalFooter spaceBetween={spaceBetween}>
					{footerCtas?.map(({ label, ...rest }, index) => (
						<CtaWrapper
							key={label + index}
							$hasLeftMargin={index > 0}
						>
							<Button {...rest} theme={theme}>
								{label}
							</Button>
						</CtaWrapper>
					))}
				</ModalFooter>
			</ModalWrapper>
		</StyledModal>
	);
};

export default GeneralModal;

GeneralModal.propTypes = {
	heading: PropTypes.string,
	modalItems: PropTypes.array,
	footerCtas: PropTypes.array,
	onCloseHandler: PropTypes.func,
	spaceBetween: PropTypes.bool,
	loading: PropTypes.bool,
	width: PropTypes.string,
	height: PropTypes.string,
};
