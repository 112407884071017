import React, { useRef, useState } from "react";
import { Overlay } from "react-bootstrap";
import edit from "components/Users/Overview/edit.svg";

import "containers/v2table/cellRenderer/emptycell.css";
import cn from "classnames";

import { getCellRenderer } from "containers/v2table/cellRenderer/componentFactory";
import {
	AppTagsPopoverRenderer,
	AppTagsViewRenderer,
} from "./popovers/AppTags";
import {
	LicenseCustomPopoverRenderer,
	LicenseCustomViewRenderer,
} from "./popovers/LicenseCustom";
import OverlayTooltip from "UIComponents/OverlayToolTip";

export const ADD_EDIT_POPOVER_MAP = {
	tags: AppTagsPopoverRenderer,
	license_custom: LicenseCustomPopoverRenderer,
};

export const ADD_EDIT_VIEW_COMP_MAP = {
	tags: AppTagsViewRenderer,
	license_custom: LicenseCustomViewRenderer,
};

export default function AddWithEditRenderer({
	data,
	handleUpdate,
	component,
	...props
}) {
	const target = useRef();

	const [showPopover, setShowPopover] = useState(false);

	return (
		<>
			<div
				className="d-flex align-items-center"
				ref={target}
				style={{ minWidth: "120px", maxWidth: "200px" }}
			>
				<OverlayTooltip
					className="test-tooltip"
					isStickyTooltip
					wrapperStyles={{ width: "100%" }}
					overlay={
						<div
							style={{
								background: "#FFFFFF",
								borderRadius: "8px",
							}}
						>
							{React.createElement(
								ADD_EDIT_POPOVER_MAP[component.type],
								{
									...props,
									setShowPopover: setShowPopover,
									handleUpdate,
									data,
								}
							)}
						</div>
					}
					placement={"top"}
					hide={!component.isHoverInViewComponent}
				>
					<div className="cursor-pointer">
						{data[component.valueKey] ? (
							React.createElement(
								ADD_EDIT_VIEW_COMP_MAP[component.type],
								{
									...props,
									setShowPopover: setShowPopover,
									handleUpdate,
									data,
								}
							)
						) : (
							<></>
						)}
					</div>
				</OverlayTooltip>
				{!data[component.valueKey] ? (
					<div
						className="d-flex align-items-center"
						onClick={() => {
							setShowPopover(true);
						}}
					>
						{component.emptyComponent
							? React.createElement(
									getCellRenderer(
										component?.emptyComponent?.type
									),
									{
										component: component.emptyComponent,
									}
								)
							: "-"}
					</div>
				) : component.editButtonPresent ? (
					<>
						<div className="overview__middle__topconttext2 d-flex flex-column align-items-start">
							<div
								className="font-12 primary-color cursor-pointer"
								onClick={() => {
									setShowPopover(!showPopover);
								}}
							>
								<img src={edit} alt="edit-icon" />
							</div>
						</div>
					</>
				) : (
					<></>
				)}
				<Overlay
					rootCloseEvent={"mousedown"}
					target={target.current}
					show={showPopover}
					placement="bottom"
					rootClose
					onHide={() => setShowPopover(false)}
				>
					{({
						placement,
						arrowProps,
						show: _show,
						popper,
						...overlayProps
					}) => (
						<div
							{...overlayProps}
							style={{
								background: "#FFFFFF",
								boxShadow: "-2px 4px 12px rgba(0, 0, 0, 0.12)",
								borderRadius: "8px",

								...overlayProps.style,
							}}
						>
							{React.createElement(
								ADD_EDIT_POPOVER_MAP[component.type],
								{
									...props,
									setShowPopover: setShowPopover,
									handleUpdate,
									data,
								}
							)}
						</div>
					)}
				</Overlay>
			</div>
		</>
	);
}
