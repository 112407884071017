import React from "react";
import { Drawer, Button, theme } from "@zluri/ui-components";
import PropTypes from "prop-types";
import { curtainMapping } from "modules/transactionMapping/constants/curtainMapping";
import styled from "styled-components";
import ErrorWrapper from "UIComponents/Rbac/ErrorWrapper";

const FooterWrapper = styled.div`
	display: flex;
	gap: 10px;
`;

const HeaderWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`;

const CurtainListWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
	height: 100%;
`;

const CurtainItemWrapper = styled.div`
	border-radius: 8px;
	background: var(--Brand-White, #fff);
	height: ${({ $height }) => $height};
`;

const TransactionMappingGeneralSideCurtain = ({
	open,
	handleClose,
	header,
	footerCtas = [],
	curtainItems = [],
	width = "1034px",
	error = null,
}) => {
	return (
		<>
			<Drawer
				width={width}
				open={open}
				onClose={() => {
					handleClose();
				}}
				customFooter={
					footerCtas ? (
						<FooterWrapper>
							{footerCtas.map(({ label, ...rest }, index) => (
								<div key={label + index}>
									<Button {...rest} theme={theme}>
										{label}
									</Button>
								</div>
							))}
						</FooterWrapper>
					) : null
				}
				header={<HeaderWrapper>{header}</HeaderWrapper>}
			>
				{!error ? (
					<CurtainListWrapper>
						{curtainItems.map(({ type, ...rest }, index) => (
							<CurtainItemWrapper
								key={type + index}
								$height={
									type === "V2_TABLE_BLOCK" ? "100%" : null
								}
							>
								{curtainMapping[type]({ ...rest })}
							</CurtainItemWrapper>
						))}
					</CurtainListWrapper>
				) : (
					<ErrorWrapper error={error} />
				)}
			</Drawer>
		</>
	);
};

export default TransactionMappingGeneralSideCurtain;

TransactionMappingGeneralSideCurtain.propTypes = {
	open: PropTypes.bool,
	handleClose: PropTypes.func,
	header: PropTypes.node,
	footerCtas: PropTypes.array,
	curtainItems: PropTypes.array,
	width: PropTypes.string,
	error: PropTypes.object,
};
