const initialState = {
	data: [],
	columns: [],
	filters: [],
};
export default function v2TablePropertiesReducer(state = initialState, action) {
	switch (action.type) {
		case "V2/PROPERTIES_FETCHED": {
			const { entity, data, error } = action.payload;

			return {
				...state,
				[entity]: { ...data, error },
			};
		}
		case "V2/CLEAR_PROPERTIES": {
			const { entity, data } = action.payload;

			return {
				...state,
				[entity]: null,
			};
		}
		case "UPDATE_COLUMN_ORDER": {
			const { entity, updatedColumns } = action.payload;
			return {
				...state,
				[entity]: {
					...state[entity],
					columns: updatedColumns,
				},
			};
		}
		default:
			return state;
	}
}
