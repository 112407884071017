import {
	BODY_VARIANT,
	GREY_VARIANT,
	SUB_HEADING_VARIANT,
	Typography,
} from "@zluri/ui-components";
import React from "react";

export const ShowToast = ({ type }) => {
	const {
		header = "Action Successful!",
		subheader = "Changes will be reflected shortly",
		icon = "",
	} = type;
	return (
		<div className="d-flex align-items-center gap-20">
			{icon && <img src={icon} alt="" />}
			<div className="d-flex flex-column">
				{header && (
					<span>
						<Typography
							variant={BODY_VARIANT.BODY_1_REGULAR}
							color={GREY_VARIANT?.SECONDARY_GREY_5}
						>
							{header}
						</Typography>
					</span>
				)}
				{subheader && (
					<span>
						<Typography
							variant={SUB_HEADING_VARIANT.SUBHEADING_2_SEMIBOLD}
							color={GREY_VARIANT.SECONDARY_GREY_3}
						>
							{subheader}
						</Typography>
					</span>
				)}
			</div>
		</div>
	);
};
