import React, { useEffect, useState } from "react";
import {
	Actions,
	BUTTONS,
	SELECT_APPLICATIONS,
	STATUS,
	ViewType,
	reviewTypes,
	ViewsInCreateReviewPage,
	SET_UP_CERTIFICATION,
	selectApplicationHeaders,
	popupEntities,
	reviewersPopoverType,
} from "modules/AccessReview/constants/constants";
import { useDispatch, useSelector } from "react-redux";

import ellipse from "assets/icons/ellipse.svg";
import blueTick from "assets/icons/blue-tick.svg";
import comingSoon from "assets/icons/coming-soon.svg";
import editPen from "assets/icons/edit-pen.svg";
import deleteSvg from "assets/delete_filled.svg";

import "./SelectApplications.css";
import {
	changeView,
	deleteApplicationInReview,
	editApplicationInReview,
	isEditingApp,
	makeActiveStep,
	toggleAddApplicationModal,
	updateProgressbar,
} from "modules/AccessReview/redux/AccessReview.actions";
import AddApplicationModal from "../AddApplicationModal/AddApplicationModal";
import { Tooltip, Button, ImageOrNameBadge, theme } from "@zluri/ui-components";
import _ from "underscore";
import { AppAuthStatusIconAndTooltip } from "common/AppAuthStatus";
import ControlFlowButtonGroup from "../ControlFlowButtonGroup/ControlFlowButtonGroup";
import { EntityCard } from "../EntityCard/EntityCard";
import "modules/AccessReview/components/ReviewDetailsForm/ReviewDetailsForm.css";
import "modules/AccessReview/components/SelectV2/SelectV2.css";
import "modules/AccessReview/components/SelectApplications/SelectApplications.css";
import ReviewersDataAtLevels from "../ReviewersDataAtLevels/ReviewersDataAtLevels";
import { checkIfReviewersDataExistsForAllApps } from "modules/AccessReview/util/util";
import { Popup } from "../common/common";

export default function SelectApplications() {
	const dispatch = useDispatch();
	const [isNextEnabled, setIsNextEnabled] = useState(false);
	const [showCancelPopup, setShowCancelPopup] = useState(false);
	const [deleteAppPopup, setDeleteAppPopup] = useState(false);
	const { showAddApplicationModal, applications, reviewDetailsForm } =
		useSelector((state) => state.accessReview);
	const [reviewDetailsFormObj, setReviewDetailsFormObj] =
		useState(reviewDetailsForm);
	const handleChange = (value, name) => {
		const reviewDetails = { ...reviewDetailsFormObj, [name]: value };
		setReviewDetailsFormObj(reviewDetails);
	};

	useEffect(() => {
		if (!showAddApplicationModal) {
			dispatch(isEditingApp(false));
		}
	}, [showAddApplicationModal, dispatch]);

	useEffect(() => {
		const isValidated =
			!_.isEmpty(applications) &&
			checkIfReviewersDataExistsForAllApps(applications);
		dispatch(
			updateProgressbar(
				1,
				isValidated ? STATUS.Completed : STATUS.InProgress,
				ViewType.ViewsInCreateReviewPage
			)
		);
		dispatch(makeActiveStep(1));

		if (isValidated) {
			setIsNextEnabled(true);
		} else setIsNextEnabled(false);
	}, [applications, dispatch]);

	const handleNextClick = () => {
		dispatch(
			changeView(
				ViewsInCreateReviewPage.States.SelectApplications,
				Actions.NEXT,
				ViewType.ViewsInCreateReviewPage
			)
		);
	};

	const handleBackClick = () => {
		dispatch(
			changeView(
				ViewsInCreateReviewPage.States.SelectApplications,
				Actions.PREV,
				ViewType.ViewsInCreateReviewPage
			)
		);
	};

	return (
		<div className="d-flex flex-column">
			<Popup
				show={showCancelPopup}
				setShow={setShowCancelPopup}
				entity={popupEntities.cancelPopup}
			/>

			{/* hide the entity selection component from cert creation flow. Will need it in the future for enabling groups. So, commenting it */}
			{/* <div className="review-details-form">
				<div className="font-12 bold-600">{SET_UP_CERTIFICATION}</div>
				<div className="select-review-type d-flex">
					<EntityCard
						reviewTypes={reviewTypes}
						handleClick={handleChange}
						activeReviewType={reviewDetailsFormObj.review_type}
						objKey="review_type"
						headerStyles="font-14 bold-500"
						descriptionStyles="font-11 bold-400"
					/>
				</div>
			</div> */}

			<div className="select-application-container">
				<div className="font-12 bold-600">{SELECT_APPLICATIONS}</div>

				{applications?.length !== 0 && (
					<RenderApplications
						applications={applications}
						setDeleteAppPopup={setDeleteAppPopup}
						deleteAppPopup={deleteAppPopup}
					/>
				)}

				<Button
					theme={theme}
					variant="primary-default"
					onClick={() => {
						dispatch(toggleAddApplicationModal(true));
					}}
					className="w-100"
				>
					{BUTTONS.PLUS_ADD_APPLICATION}
				</Button>
			</div>
			<div className="review-form-btn-container">
				<ControlFlowButtonGroup
					onBackClick={handleBackClick}
					onCancelClick={() => setShowCancelPopup(true)}
					onActionClick={handleNextClick}
					actionBtnDisabled={!isNextEnabled}
				/>
			</div>
			{showAddApplicationModal && <AddApplicationModal />}
		</div>
	);
}

const RenderApplications = ({
	applications,
	deleteAppPopup,
	setDeleteAppPopup,
}) => {
	const dispatch = useDispatch();
	const handleEditApp = (appId) => {
		dispatch(isEditingApp(true));
		dispatch(editApplicationInReview(appId));
		dispatch(toggleAddApplicationModal(true));
	};

	const [app, setApp] = useState({});

	const handleDeleteApp = (app) => {
		setDeleteAppPopup(true);
		app && setApp(app);
	};
	return (
		<div className="mt-2 w-100">
			<Popup
				show={deleteAppPopup}
				setShow={setDeleteAppPopup}
				handleAction={() => {
					setDeleteAppPopup(false);
					dispatch(deleteApplicationInReview(app?.appId));
				}}
				dynamicRemainingProps={{
					subHeaderText: `${
						app?.application?.app_name || "This app"
					} and all its configurations will be deleted from this certification.`,
				}}
				entity={popupEntities.deleteApp}
			/>

			<table className="w-100">
				<thead>
					<tr
						className="d-flex bold-400 font-9 grey-1"
						style={{ padding: "0px 20px 0px 20px" }}
					>
						{selectApplicationHeaders?.map((header, index) => {
							return (
								<th
									className="flex-1 d-flex justify-content-start"
									key={header.name + index}
								>
									{header.name}
								</th>
							);
						})}
						<th style={{ marginRight: "10px" }}>Actions</th>
					</tr>
				</thead>
				<tbody
					className="mt-2 overflow-auto hide-scrollbar"
					style={{ maxHeight: "40vh" }}
				>
					{applications?.map((app) => {
						return (
							<tr
								key={app?.id}
								className="d-flex flex-row select-app-card mt-2 align-items-center justify-content-center"
								style={{ padding: "0px 20px" }}
							>
								<td className="d-flex flex-1">
									<div className="d-flex align-items-center justify-content-start">
										<Tooltip
											placement="top"
											content={app.application?.app_name}
										>
											<div
												className="text-truncate"
												style={{
													maxWidth: "200px",
													width: "fit-content",
												}}
											>
												<ImageOrNameBadge
													url={
														app.application
															?.app_logo
													}
													name={
														app.application
															?.app_name
													}
													height={15}
													width={15}
													className="border-radius-4"
												/>
												<span className="bold-600 font-12 black-1 pl-2">
													{app.application?.app_name}
												</span>
											</div>
										</Tooltip>

										<AppAuthStatusIconAndTooltip
											style={{ paddingLeft: "4px" }}
											authStatus={
												app.application?.app_state
											}
											tooltipClasses={"tooltip-black"}
										/>
									</div>
								</td>

								<td className="flex-1 d-flex justify-content-start">
									<span className="bold-400 font-12">
										{app?.selected_user_count
											? `${app?.selected_user_count} Users`
											: "-"}
									</span>
								</td>

								<ReviewersDataAtLevels
									levels={app?.levels}
									popoverType={reviewersPopoverType.DEFAULT}
								/>

								<td
									className="d-flex justify-content-end"
									style={{ gap: "10px" }}
								>
									<div
										onClick={() =>
											handleEditApp(app?.appId)
										}
										className="action__btn d-flex justify-content-center align-items-center"
									>
										<img
											src={editPen}
											alt=""
											style={{
												color: "#717171",
												minWidth: "12px",
												minHeight: "12px",
											}}
										/>
									</div>
									<div
										onClick={() => handleDeleteApp(app)}
										className="action__btn d-flex justify-content-center align-items-center"
									>
										<img
											src={deleteSvg}
											alt=""
											className="justify-content-end btn__dimensions"
											style={{
												minWidth: "12px",
												minHeight: "12px",
												filter: "invert(46%) sepia(10%) saturate(8%) hue-rotate(326deg) brightness(92%) contrast(87%)",
											}}
										/>
									</div>
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		</div>
	);
};

export const RenderReviewType = ({
	reviewTypes,
	handleClick,
	activeReviewType,
	objKey,
	headerStyles,
	descriptionStyles,
}) => {
	return reviewTypes.map((type) => {
		const isActive = activeReviewType === type?.name;
		const reviewTypeCardClass = isActive ? "review-type-card-border" : "";
		const reviewTypeDisabledClass = !type?.isActive ? "o-5" : "";
		return (
			<div
				key={type?.name}
				className={`review-type-card font-11 pd-10 ${reviewTypeCardClass} ${reviewTypeDisabledClass}`}
				onClick={() => {
					if (!type?.isActive) return;
					handleClick && handleClick(type?.name, objKey);
				}}
			>
				<div className="d-flex">
					<img src={isActive ? blueTick : ellipse} alt="" />
					<div className="ml-2 mr-2">
						<div className="pb-1">
							<span className={headerStyles}>{type?.name}</span>
							<span>
								{!type?.isActive && (
									<img
										className="ml-2"
										src={comingSoon}
										alt=""
									/>
								)}
							</span>
						</div>
						<div className={descriptionStyles}>{type?.info}</div>
					</div>
				</div>
			</div>
		);
	});
};
