/**
 *
 * Product Gating
 *
 * App.js - List of routes, each route have shouldRender key with feature name
 * Sidebar - List of menu, each menu have shouldRender key with feature name
 * Module - List of tabs, with each hash have shouldRender key with feature name
 * here -  in Config we have configuration for each module
 * application sidebar.js - secondary sidebar
 *
 * shouldRender function checks if the feature name is present in org plan enale key.
 *
 */

import { ENTITIES } from "../../../constants";

export const FEATURE_NAME = {
	AUDIT_ACCESS_REVIEW: "AUDIT_ACCESS_REVIEW",
	SAAS_FINANCIAL_INTEGRATIONS: "SAAS_FINANCIAL_INTEGRATIONS",
	SAAS_BROWSER_AGENTS: "SAAS_BROWSER_AGENTS",
	SAAS_DESKTOP_AGENTS: "SAAS_DESKTOP_AGENTS",
	DM_USER_GROUPS: "DM_USER_GROUPS",
	DM_LOCATIONS: "DM_LOCATIONS",
	DM_BUSINESS_UNITS: "DM_BUSINESS_UNITS",
	DM_COST_CENTER: "DM_COST_CENTER",
	APP_OVERVIEW_CUSTOMIZABLE_WIDGET: "APP_OVERVIEW_CUSTOMIZABLE_WIDGET",
	APP_SPENDS_BUDGET: "APP_SPENDS_BUDGET",
	APP_SECURITY_COMPLIANCE: "APP_SECURITY_COMPLIANCE",
	APP_CHARGEBACK: "APP_CHARGEBACK",
	APP_ABOUT: "APP_ABOUT",
	APP_USER: "APP_USER",
	APP_OPTIMIZATION: "APP_OPTIMIZATION",
	APP_OPTIMIZATION_REVIEW_USERS: "APP_OPTIMIZATION_REVIEW_USERS",
	APP_OPTIMIZATION_PREVIOUS_OPTIMIZATION:
		"APP_OPTIMIZATION_PREVIOUS_OPTIMIZATION",
	APP_AUTOMATION: "APP_AUTOMATION",
	APP_INSTANCE: "APP_INSTANCE",
	SM_TRANSACTION: "SM_TRANSACTION",
	SM_SPENDS_OVERVIEW: "SM_SPENDS_OVERVIEW",
	SM_CHARGEBACK: "SM_CHARGEBACK",
	SM_BUDGETING: "SM_BUDGETING",
	SM_SPEND_AUTOMATIONS: "SM_SPEND_AUTOMATIONS",
	LM_CONTRACTS: "LM_CONTRACTS",
	LM_LICENSE: "LM_LICENSE",
	LM_VENDOR_MANAGEMENT: "LM_VENDOR_MANAGEMENT",
	LM_COSTS: "LM_COSTS",
	LM_RENEWALS: "LM_RENEWALS",
	LM_LICENSE_AUTOMATIONS: "LM_LICENSE_AUTOMATIONS",
	SO_OPTIMISATIONS_SUMMARY: "SO_OPTIMISATIONS_SUMMARY",
	SO_USAGE_BASED_OPTIMISATION: "SO_USAGE_BASED_OPTIMISATION",
	SO_REDUNDANT_OPTIMISATION: "SO_REDUNDANT_OPTIMISATION",
	SO_APP_INSIGHTS_SPENDS_AND_BUDGET: "SO_APP_INSIGHTS_SPENDS_AND_BUDGET",
	SO_APP_INSIGHTS_APP_LICENSES: "SO_APP_INSIGHTS_APP_LICENSES",
	SO_SAAS_OPT_AUTOMATIONS: "SO_SAAS_OPT_AUTOMATIONS",
	SO_APP_INSIGHTS_APP_SECURITY_AND_COMPLIANCE:
		"SO_APP_INSIGHTS_APP_SECURITY_AND_COMPLIANCE",
	REPORTS: "REPORTS",
	REPORTS_SPEND: "REPORTS_SPEND",
	REPORTS_COST: "REPORTS_COST",
	REPORTS_LICENSE: "REPORTS_LICENSE",
	REPORTS_WORKFLOW: "REPORTS_WORKFLOW",
	REPORTS_OPTIMISATION: "REPORTS_OPTIMISATION",
	REPORTS_CUSTOM: "REPORTS_CUSTOM",
	SECURITY_RISK_THREAT_TRACKING_MANUAL:
		"SECURITY_RISK_THREAT_TRACKING_MANUAL",
	SECURITY_RISK_THREAT_AUTOMATED_DETECTION:
		"SECURITY_RISK_THREAT_AUTOMATED_DETECTION",
	SECURITY_COMPLIANCE: "SECURITY_COMPLIANCE",
	SECURITY_AUDIT_LOGS: "SECURITY_AUDIT_LOGS",
	WORKFLOWS_USER_ONBOARDING: "WORKFLOWS_USER_ONBOARDING",
	WORKFLOWS_USER_MIDLIFE_CYCLE: "WORKFLOWS_USER_MIDLIFE_CYCLE",
	WORKFLOWS_USER_OFFBOARDING: "WORKFLOWS_USER_OFFBOARDING",
	WORKFLOWS_APP_PROVISIONING: "WORKFLOWS_APP_PROVISIONING",
	WORKFLOWS_APP_DEPROVISIONING: "WORKFLOWS_APP_DEPROVISIONING",
	WORKFLOWS_REQUEST_TO_FOREGO_LICENSE: "WORKFLOWS_REQUEST_TO_FOREGO_LICENSE",
	ZERO_TOUCH_AUTOMATIONS_ONBOARDING: "ZERO_TOUCH_AUTOMATIONS_ONBOARDING",
	ZERO_TOUCH_AUTOMATIONS_MIDLIFE_CYCLE:
		"ZERO_TOUCH_AUTOMATIONS_MIDLIFE_CYCLE",
	ZERO_TOUCH_AUTOMATIONS_OFFBOARDING: "ZERO_TOUCH_AUTOMATIONS_OFFBOARDING",
	ZERO_TOUCH_AUTOMATIONS_APPLICATION: "ZERO_TOUCH_AUTOMATIONS_APPLICATION",
	APP_STORE_CATALOG: "APP_STORE_CATALOG",
	APP_STORE_REQUISITION_MANAGEMENT: "APP_STORE_REQUISITION_MANAGEMENT",
	APP_STORE_REQUEST_AUTOMATIONS: "APP_STORE_REQUEST_AUTOMATIONS",
	TASK_MANAGEMENT_TASKS_DASHBOARD: "TASK_MANAGEMENT_TASKS_DASHBOARD",
	TASK_MANAGEMENT_INTEGRATION_WITH_TOOLS:
		"TASK_MANAGEMENT_INTEGRATION_WITH_TOOLS",
	APP_MAP_LICENSES: "APP_MAP_LICENSES",
	TRANSACTION_RULE_ENGINE: "transactions_rule_engine",
};

/** Some modules get tabs from backend so best thing would be to have a seperate congif property.
 * For modules which have static tabs, we can maintain featureName in both module level and here.
 */
export const CONFIG = {
	[ENTITIES.APPLICATIONS]: [
		{
			hash: "#spends",
			featureName: FEATURE_NAME.APP_SPENDS_BUDGET,
		},
		{
			hash: "#optimization",
			featureName: FEATURE_NAME.APP_OPTIMIZATION,
		},
		{
			hash: "#optimization_v2",
			featureName: FEATURE_NAME.APP_OPTIMIZATION,
		},
		{
			hash: "#instances",
			isBeta: true,
			featureName: FEATURE_NAME.APP_INSTANCE,
		},
		{
			hash: "#automation",
			isBeta: false,
			featureName: FEATURE_NAME.APP_AUTOMATION,
		},
		{
			hash: "#security",
			featureName: FEATURE_NAME.APP_SECURITY_COMPLIANCE,
		},
		{
			hash: "#spends",
			featureName: FEATURE_NAME.APP_SPENDS_BUDGET,
		},
		{
			hash: "#about",
			featureName: FEATURE_NAME.APP_ABOUT,
		},
		{
			hash: "#licenses",
			featureName: FEATURE_NAME.LM_LICENSE,
		},
		{
			hash: "#map_licenses",
			featureName: FEATURE_NAME.APP_MAP_LICENSES,
		},
		{
			hash: "#chargebacks",
			isBeta: true,
			featureName: FEATURE_NAME.APP_CHARGEBACK,
		},
	],
	[ENTITIES.USERS]: [
		{
			hash: "#group",
			featureName: FEATURE_NAME.DM_USER_GROUPS,
		},
	],
	[ENTITIES.LICENSES]: [
		{
			hash: "#allContracts",
			featureName: FEATURE_NAME.LM_CONTRACTS,
		},
		{
			hash: "#allLicenses",
			featureName: FEATURE_NAME.LM_LICENSE,
		},
	],
	[ENTITIES.WORKFLOWS]: [
		{
			hash: "#rules",
			featureName: FEATURE_NAME.ZERO_TOUCH_AUTOMATIONS_ONBOARDING,
		},
		{
			hash: "#rules",
			featureName: FEATURE_NAME.ZERO_TOUCH_AUTOMATIONS_OFFBOARDING,
		},
	],
	[ENTITIES.INTEGRATIONS]: [],
};
