export const applicationConstants = {
	ALL_APPLICATIONS_REQUESTED: "ALL_APPLICATIONS_REQUESTED",
	ALL_APPLICATIONS_FETCHED: "ALL_APPLICATIONS_FETCHED",
	ALL_CONTRACTS_REQUESTED: "ALL_CONTRACTS_REQUESTED",
	ALL_CONTRACTS_FETCHED: "ALL_CONTRACTS_FETCHED",
	APP_ACTIONHISTORY_REQUESTED: "APP_ACTIONHISTORY_REQUESTED",
	APP_ACTIONHISTORY_FETCHED: "APP_ACTIONHISTORY_FETCHED",
	RENEWALS_REQUESTED: "RENEWALS_REQUESTED",
	RENEWALS_FETCHED: "RENEWALS_FETCHED",
	UPCOMING_RENEWALS_REQUESTED: "UPCOMING_RENEWALS_REQUESTED",
	UPCOMING_RENEWALS_FETCHED: "UPCOMING_RENEWALS_FETCHED",
	CONTRACT_INFO_REQUESTED: "CONTRACT_INFO_REQUESTED",
	CONTRACT_INFO_FETCHED: "CONTRACT_INFO_FETCHED",
	APPLICATION_INFO_REQUESTED: "APPLICATION_INFO_REQUESTED",
	APPLICATION_INFO_FETCHED: "APPLICATION_INFO_FETCHED",
	SINGLE_APPLICATION_CONTRACT_INFO_REQUESTED:
		"SINGLE_APPLICATION_CONTRACT_INFO_REQUESTED",
	SINGLE_APPLICATION_CONTRACT_INFO_FETCHED:
		"SINGLE_APPLICATION_CONTRACT_INFO_FETCHED",
	SINGLE_APPLICATION_USERS_INFO_REQUESTED:
		"SINGLE_APPLICATION_USERS_INFO_REQUESTED",
	SINGLE_APPLICATION_USERS_INFO_FETCHED:
		"SINGLE_APPLICATION_USERS_INFO_FETCHED",
	SINGLE_APPLICATION_TRANSACTION_INFO_REQUESTED:
		"SINGLE_APPLICATION_TRANSACTION_INFO_REQUESTED",
	SINGLE_APPLICATION_TRANSACTION_INFO_FETCHED:
		"SINGLE_APPLICATION_TRANSACTION_INFO_FETCHED",
	TOGGLE_ADD_USER: "TOGGLE_ADD_USER",
	TOGGLE_ADD_CONTRACT: "TOGGLE_ADD_CONTRACT",
	TOGGLE_ADD_TRANSACTION: "TOGGLE_ADD_TRANSACTION",
	SPENDGRAPH_REQUESTED: "SPENDGRAPH_REQUESTED",
	SPENDGRAPH_FETCHED: "SPENDGRAPH_FETCHED",
	USAGEGRAPH_REQUESTED: "USAGEGRAPH_REQUESTED",
	USAGEGRAPH_FETCHED: "USAGEGRAPH_FETCHED",
	SPENDGRAPH_ALREADY_PRESENT: "SPENDGRAPH_ALREADY_PRESENT",
	USAGEGRAPH_ALREADY_PRESENT: "USAGEGRAPH_ALREADY_PRESENT",
	DELETE_APPLICATIONS_CACHE: "DELETE_APPLICATIONS_CACHE",
	DELETE_SINGLE_APPLICATION_TRANSACTION_CACHE:
		"DELETE_SINGLE_APPLICATION_TRANSACTION_CACHE",
	DELETE_SINGLE_APPLICATION_USERS_CACHE:
		"DELETE_SINGLE_APPLICATION_USERS_CACHE",
	DELETE_SINGLE_APPLICATION_CONTRACT_CACHE:
		"DELETE_SINGLE_APPLICATION_CONTRACT_CACHE",
	DELETE_ALL_CONTRACTS_CACHE: "DELETE_ALL_CONTRACTS_CACHE",
	DELETE_ALL_SINGLE_APPLICATION_TRANS_CACHE:
		"DELETE_ALL_SINGLE_APPLICATION_TRANS_CACHE",
	SEARCH_SINGLE_APPLICATION_TRANSACTIONS_REQUESTED:
		"SEARCH_SINGLE_APPLICATION_TRANSACTIONS_REQUESTED",
	SEARCH_SINGLE_APPLICATION_TRANSACTIONS_FETCHED:
		"SEARCH_SINGLE_APPLICATION_TRANSACTIONS_FETCHED",
	TOGGLE_ADD_USER: "TOGGLE_ADD_USER",
	TOGGLE_ADD_CONTRACT: "TOGGLE_ADD_CONTRACT",
	TOGGLE_ADD_TRANSACTION: "TOGGLE_ADD_TRANSACTION",
	ALL_VENDORS_REQUESTED: "ALL_VENDORS_REQUESTED",
	ALL_VENDORS_FETCHED: "ALL_VENDORS_FETCHED",
	SINGLE_VENDOR_CONTRACTS_REQUESTED: "SINGLE_VENDOR_CONTRACTS_REQUESTED",
	SINGLE_VENDOR_CONTRACTS_FETCHED: "SINGLE_VENDOR_CONTRACTS_FETCHED",
	DELETE_SINGLE_VENDOR_CONTRACTS_CACHE:
		"DELETE_SINGLE_VENDOR_CONTRACTS_CACHE",
	FETCH_APPLICATION_EVENTS_REQUESTED: "FETCH_APPLICATION_EVENTS_REQUESTED",
	FETCHED_APPLICATION_EVENTS: "FETCHED_APPLICATION_EVENTS",
	FETCH_APPLICATION_DATASHARED_INFO_REQUESTED:
		"FETCH_APPLICATION_DATASHARED_INFO_REQUESTED",
	FETCHED_APPLICATION_DATASHARED_INFO: "FETCHED_APPLICATION_DATASHARED_INFO",
	FETCH_APPLICATION_SECURITY_PROBES_REQUESTED:
		"FETCH_APPLICATION_SECURITY_PROBES_REQUESTED",
	FETCHED_APPLICATION_SECURITY_PROBES: "FETCHED_APPLICATION_SECURITY_PROBES",
	FETCH_APPLICATION_COMPLIANCE_REQUESTED:
		"FETCH_APPLICATION_COMPLIANCE_REQUESTED",
	FETCHED_APPLICATION_COMPLIANCE: "FETCHED_APPLICATION_COMPLIANCE",
	VENDOR_DETAILS_REQUESTED: "VENDOR_DETAILS_REQUESTED",
	VENDOR_DETAILS_FETCHED: "VENDOR_DETAILS_FETCHED",
};

export const APPLICATION_TYPE = {
	APPLICATION: "application",
	BROWSER: "browser",
	WEBSITE: "website",
	DEVICE: "device",
	OTHERS: "other",
};

export const APPLICATION_AUTH_STATUS = {
	AUTHORISED: "authorized",
	RESTRICTED: "restricted",
	NEEDSREVIEW: "needs review",
};

export const APPLICATION_RECOMMENDATION_HEADER_TEXT = {
	no_activity_in_30_days: "Inactive Users",
	low_usage: "Low Usage",
	deprovisioned: "Deprovisioned",
};

export const APPLICATION_TABS = {
	managed: "Managed",
	unmanaged: "Unmanaged",
	restricted: "Restricted",
	needs_review: "Needs Review",
	all_apps: "All Apps",
};
