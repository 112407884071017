import React from "react";
import PropTypes from "prop-types";
import {
	ALERT_TAG_VARIANT,
	BLUE_VARIANT,
	BODY_VARIANT,
	GREY_VARIANT,
	ImageOrNameBadge,
	Tooltip,
	Typography,
} from "@zluri/ui-components";

import { isNullOrUndefined } from "modules/applications/utils/appAbout.utils";

import "./styles.css";

export function AppAboutText({
	text,
	imageSrc,
	imageStyle,
	showDefaultAvatar,
	isLink,
}) {
	const handleClick = () => {
		if (isLink) {
			window.open(text);
		}
	};

	if (isNullOrUndefined(text))
		return (
			<Typography
				color={GREY_VARIANT.SECONDARY_GREY_3}
				variant={BODY_VARIANT.BODY_2_REGULAR}
			>
				-
			</Typography>
		);

	return (
		<div className="d-flex gap-4 align-items-center app-about-text-component-txt-container">
			<Tooltip
				content={
					<Typography
						color="white"
						variant={ALERT_TAG_VARIANT.ALERT_TAG_SMALL_MEDIUM}
					>
						{text}
					</Typography>
				}
				placement="bottom"
			>
				<span onClick={handleClick} className="d-flex">
					<Typography
						color={
							isLink
								? BLUE_VARIANT.PRIMARY_BLUE_DARK_1
								: GREY_VARIANT.SECONDARY_GREY_1
						}
						variant={BODY_VARIANT.BODY_2_REGULAR}
						className={`text-truncate-small ${
							isLink && "cursor-pointer"
						}`}
						style={{
							maxWidth: "240px",
						}}
					>
						{text}
					</Typography>
				</span>
			</Tooltip>

			{(!isNullOrUndefined(imageSrc) || showDefaultAvatar) && (
				<div style={{ transform: "translateY(-1px)" }}>
					<ImageOrNameBadge
						height={12}
						width={12}
						name={text}
						url={imageSrc}
						variant="round"
						nameBadgeClasses="app-about-text-name-badge"
						className="object-contain"
						{...imageStyle}
					/>
				</div>
			)}
		</div>
	);
}

AppAboutText.propTypes = {
	text: PropTypes.string,
	imageSrc: PropTypes.string,
	imageStyle: PropTypes.object,
	showDefaultAvatar: PropTypes.bool,
	isLink: PropTypes.bool,
};
