import React from "react";

import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { BUTTON_VARIANT, GREY_VARIANT, Typography } from "@zluri/ui-components";
import { downloadCSV } from "./OverviewV2.utils";

const prepareChartData = (graphData, seriesColor) => {
	const chartColor = seriesColor || chartColors;
	if (graphData?.length) {
		const xAxisData = graphData?.map((data) => data?.dept_name);
		const licenseName = graphData
			?.flatMap((item) => Object.keys(item?.graph_data))
			?.filter(
				(value, index, license) => license?.indexOf(value) === index
			);
		const seriesData = licenseName?.map((license, index) => {
			return {
				name: license,
				data: graphData?.map(function (item) {
					return {
						name: license,
						...item,
						y: item?.graph_data[license] || 0,
						color: chartColor.colors[
							index % chartColor.colors.length
						],
					};
				}),
			};
		});
		return {
			xAxisData,
			seriesData,
		};
	}
	return {};
};

const prepareLegendColor = (graphData, colors) => {
	return graphData?.seriesData?.map((data, index) => {
		return {
			name: data.name,
			color: (colors || chartColors).colors[
				index % chartColors.colors.length
			],
		};
	});
};

const toolTipForLables = (value) => {
	return `
	<div class="x_axis_labels_stack_bar">${`${String(value)?.slice(
		0,
		3
	)}...`}<span class="x_axis_lable_tooltip_for_stack_bar">${value}</span><div>
	`;
};

const downloadPNG = (elementId, fileName) => {
	const domElememnt = document.getElementById(elementId);
	return html2canvas(domElememnt).then((canvas) => {
		const imgData = canvas.toDataURL("image/png");
		const link = document.createElement("a");
		link.href = imgData;
		link.download = `${fileName}.png`;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	});
};

const downloadPDF = (
	elementId,
	fileName,
	canvasConfiguarions = defaultChartConfig
) => {
	const {
		orientation,
		units,
		format,
		xDistance = 10,
		yDistance = 50,
		xWidth = 400,
		yWidth = 180,
	} = canvasConfiguarions;
	const domElememnt = document.getElementById(elementId);
	return html2canvas(domElememnt).then((canvas) => {
		const imgData = canvas.toDataURL("image/png");
		const pdf = new jsPDF(orientation, units, format);
		pdf.addImage(imgData, "JPEG", xDistance, yDistance, xWidth, yWidth);
		pdf.save(`${fileName}.pdf`);
	});
};

const handleShowMoreOption = (
	id,
	element,
	fileName,
	dispatchFunc,
	canvasConfiguarions,
	graphData
) => {
	switch (id) {
		case "REFRESH_CHART":
			return dispatchFunc;
		case "DOWNLOAD_AS_PNG":
			return downloadPNG(element, fileName);
		case "DOWNLOAD_AS_PDF":
			return downloadPDF(
				element,
				fileName,
				canvasConfiguarions ?? defaultChartConfig
			);
		case "DOWNLOAD_AS_CSV":
			return downloadCSV(graphData, fileName);
		default:
			return null;
	}
};

const defaultChartConfig = {
	orientation: "l",
	units: "mm",
	format: "a3",
	xDistance: 10,
	yDistance: 50,
	xWidth: 400,
	yWidth: 180,
};

const user_active_colors = {
	colors: ["#FFA99D", "#82A7ED"],
};

const optimizable_colors = {
	colors: ["#FFE9AA", "#B1C8F3"],
};

const chartColors = {
	colors: ["#A0D8FF", "#82A7ED", "#BBAFED", "#9EC1FA", "#FFCA99", "#DDDDDD"],
};

const licensesByDepartmentChartConfig = (graphData, props) => {
	return {
		chart: {
			type: "column",
		},

		title: {
			text: "",
		},
		exporting: {
			enabled: false, // Disable exporting options
		},
		navigation: {
			buttonOptions: {
				enabled: false, // Disable the print button
			},
		},
		credits: {
			enabled: false, // Disable the credits link
		},
		contextMenu: {
			enabled: false, // Disable the context menu
		},
		legend: {
			enabled: false,
		},
		xAxis: {
			align: "start",
			lineColor: "#717171",
			categories: graphData?.xAxisData,
			labels: {
				useHTML: true,
				zIndex: 0,
				rotation: 0,
				style: {
					zIndex: 1,
				},
				formatter: function () {
					return toolTipForLables(this.value);
				},
			},
		},

		yAxis: {
			allowDecimals: false,
			min: 0,
			tickAmount: 4,
			title: {
				text: "",
			},
		},

		tooltip: {
			className: "z_overview_graph_tooltip",
			shared: true,
			useHTML: true,
			outside: true,
			shadow: false,
			padding: 0,
			borderRadius: 8,
			background: "white",
			hideDelay: 0,
			formatter: function () {
				const { points } = this;
				return stackBarTooltip(points, this?.x, this?.total);
			},
		},

		plotOptions: {
			column: {
				stacking: "normal",
				// pointWidth: 40,
			},
			series: {
				stickyTracking: false,
				cursor: "pointer",
			},
		},

		series: graphData?.seriesData,
		...props,
	};
};

const generateViewByOptions = (views) => {
	return views?.map((view, idx) => ({
		key: idx,
		label: (
			<div className="d-flex align-items-center gap-6">
				<Typography
					variant={BUTTON_VARIANT.BUTTON_EXTRA_SMALL_MEDIUM}
					color={GREY_VARIANT.SECONDARY_GREY_2}
				>
					{view?.text}
				</Typography>
			</div>
		),
		data: { ...view },
	}));
};

const stackBarTooltip = (points, name, value) => {
	const keyMap = {
		optimizable: "Optimizable Users",
		non_optimizable: "Other Users",
		active: "Active",
	};
	let element = "";
	points?.forEach((p) => {
		element += `<div class="d-flex align-items-center gap-4 z_stack_bar_tooltip-list_item"><span class="z_legend-diamond" style="background: ${p?.color}" ></span><span class="z_stack_bar_tooltip-list_item-title">${keyMap[p?.key] ?? p?.key}</span><span class="ml-auto z_stack_bar_tooltip-list_item-value">${p?.y}</span></div><div class="z_stack_bar_tooltip-list_item-break"></div>`;
	});

	return `
	<div class="d-flex flex-column">
	<span class="z_stack_bar_tooltip-header d-flex align-items-center flex-1">
	<span class="z_stack_bar_tooltip-title">${name}</span>
	<span class="z_stack_bar_tooltip-value ml-auto">${value}</span>
	</span>
	<div class="z_stack_bar_tooltip-list_item-break"></div>
	<span class="z_stack_bar_tooltip-list z_scrollbar-secondary d-flex flex-column" >
	${element}
	</span>
	</div>`;
};

export {
	prepareChartData,
	prepareLegendColor,
	toolTipForLables,
	downloadPNG,
	downloadPDF,
	handleShowMoreOption,
	defaultChartConfig,
	chartColors,
	optimizable_colors,
	user_active_colors,
	licensesByDepartmentChartConfig,
	generateViewByOptions,
	stackBarTooltip,
};
