import React, { useCallback, useEffect, useRef, useState } from "react";
import Chip from "./Chip";
import { ACTION_TYPE } from "./redux";
import { Form } from "react-bootstrap";
import {
	debounce,
	recursive_decode_request_data,
} from "../../../../../utils/common";
import {
	searchUsers,
	searchAllApps,
	searchAppTags,
	searchVendors,
	searchAppCategories,
	checkSpecialCharacters,
	searchAllDepartmentsV2,
	searchEntityCustomValues,
	searchAppCategoriesInFilters,
	searchLicensesV2,
	searchContractsV2,
	searchSubscriptionV2,
	searchPerpetualV2,
	searchApplicationLicensesV2,
} from "../../../../../services/api/search";
import { client } from "../../../../../utils/client";
import { useDispatch, useSelector } from "react-redux";
import { TriggerIssue } from "../../../../../utils/sentry";
import { getSearchReqObj } from "common/infiniteTableUtil";
import { SuggestionMenu } from "../../../../../common/SuggestionMenu/SuggestionMenu";
import { getGroupsList } from "modules/Groups/service/Groups.api";
import ErrorWrapper from "UIComponents/Rbac/ErrorWrapper";

const multiOptions = [
	{
		label: "Is Any of",
		search_in_string: {
			field_order: "contains",
			negative: false,
		},
	},
	{
		label: "Is Not of",
		search_in_string: { field_order: "contains", negative: true },
	},
];

const fieldMap = {
	app_name: {
		api: searchAllApps,
		displayKey: "app_name",
		apiKey: "app_id",
		label: "Application",
		imageKey: "app_logo",
		allowFewSpecialCharacters: true,
	},
	kbkey: {
		api: searchAllApps,
		displayKey: "app_name",
		apiKey: "app_id",
		label: "Application",
		imageKey: "app_logo",
		allowFewSpecialCharacters: true,
	},
	owner_name: {
		api: searchUsers,
		displayKey: "user_name",
		apiKey: "user_id",
		label: "Owner",
		imageKey: "profile_img",
		allowFewSpecialCharacters: true,
	},
	"owner.name": {
		api: searchUsers,
		displayKey: "user_name",
		apiKey: "user_id",
		label: "Owner",
		imageKey: "profile_img",
		allowFewSpecialCharacters: true,
	},
	"financial_owner.name": {
		api: searchUsers,
		displayKey: "user_name",
		apiKey: "user_id",
		label: "Owner",
		imageKey: "profile_img",
		allowFewSpecialCharacters: true,
	},
	"technical_owner.name": {
		api: searchUsers,
		displayKey: "user_name",
		apiKey: "user_id",
		label: "Owner",
		imageKey: "profile_img",
		allowFewSpecialCharacters: true,
	},
	contract_owner_name: {
		api: searchUsers,
		displayKey: "user_name",
		apiKey: "user_id",
		label: "Owner",
		imageKey: "profile_img",
		allowFewSpecialCharacters: true,
	},
	contract_financial_owner_name: {
		api: searchUsers,
		displayKey: "user_name",
		apiKey: "user_id",
		label: "Owner",
		imageKey: "profile_img",
		allowFewSpecialCharacters: true,
	},
	contract_it_owner_name: {
		api: searchUsers,
		displayKey: "user_name",
		apiKey: "user_id",
		label: "Owner",
		imageKey: "profile_img",
		allowFewSpecialCharacters: true,
	},
	contract_negotiation_owner_name: {
		api: searchUsers,
		displayKey: "user_name",
		apiKey: "user_id",
		label: "Owner",
		imageKey: "profile_img",
		allowFewSpecialCharacters: true,
	},
	app_owner_name: {
		api: searchUsers,
		displayKey: "user_name",
		apiKey: "user_id",
		label: "Owner",
		imageKey: "profile_img",
		allowFewSpecialCharacters: true,
	},
	app_financial_owner_name: {
		api: searchUsers,
		displayKey: "user_name",
		apiKey: "user_id",
		label: "Owner",
		imageKey: "profile_img",
		allowFewSpecialCharacters: true,
	},
	app_technical_owner_name: {
		api: searchUsers,
		displayKey: "user_name",
		apiKey: "user_id",
		label: "Owner",
		imageKey: "profile_img",
		allowFewSpecialCharacters: true,
	},
	user_name: {
		api: searchUsers,
		displayKey: "user_name",
		apiKey: "user_id",
		label: "User",
		imageKey: "profile_img",
		allowFewSpecialCharacters: true,
	},
	created_by_user_name: {
		api: searchUsers,
		displayKey: "user_name",
		apiKey: "user_id",
		label: "Created by User",
		imageKey: "profile_img",
		allowFewSpecialCharacters: true,
	},
	published_by_user_name: {
		api: searchUsers,
		displayKey: "user_name",
		apiKey: "user_id",
		label: "Published by User",
		imageKey: "profile_img",
		allowFewSpecialCharacters: true,
	},
	vendor_name: {
		api: searchVendors,
		displayKey: "vendor_name",
		apiKey: "vendor_id",
		label: "Vendor",
		imageKey: "vendor_logo",
		allowFewSpecialCharacters: false,
	},
	dept_head_name: {
		api: searchUsers,
		displayKey: "user_name",
		apiKey: "user_id",
		label: "Owner",
		imageKey: "profile_img",
		allowFewSpecialCharacters: true,
	},
	dept_head_id: {
		api: searchUsers,
		displayKey: "user_name",
		apiKey: "user_id",
		label: "Owner",
		imageKey: "profile_img",
		allowFewSpecialCharacters: true,
	},
	custom_fields: {
		api: searchEntityCustomValues,
		displayKey: "custom_field_name",
		selectedId: "custom_field_id",
		apiKey: "field_id",
		label: "entity",
		imageKey: "custom_field_image",
		allowFewSpecialCharacters: true,
	},
	app_tags: {
		api: searchAppTags,
		displayKey: "app_tag",
		label: "Tag",
		allowFewSpecialCharacters: true,
	},
	"app_sub_categories.category_name": {
		api: searchAppCategoriesInFilters,
		displayKey: "category",
		label: "Category",
		allowFewSpecialCharacters: true,
	},
	"app_sub_categories.sub_category_name": {
		api: searchAppCategories,
		displayKey: "name",
		label: "Sub-Category",
		allowFewSpecialCharacters: true,
	},
	financial_owner_name: {
		api: searchUsers,
		displayKey: "user_name",
		apiKey: "user_id",
		label: "Financial Owner",
		imageKey: "profile_img",
		allowFewSpecialCharacters: true,
	},
	negotiation_owner_name: {
		api: searchUsers,
		displayKey: "user_name",
		apiKey: "user_id",
		label: "Negotiation Owner",
		imageKey: "profile_img",
		allowFewSpecialCharacters: true,
	},
	it_owner_name: {
		api: searchUsers,
		displayKey: "user_name",
		apiKey: "user_id",
		label: "IT Owner",
		imageKey: "profile_img",
		allowFewSpecialCharacters: true,
	},
	user_owner_name: {
		api: searchUsers,
		displayKey: "user_name",
		apiKey: "user_id",
		label: "Owner",
		imageKey: "profile_img",
		allowFewSpecialCharacters: true,
	},
	user_reporting_manager_name: {
		api: searchUsers,
		displayKey: "user_name",
		apiKey: "user_id",
		label: "Reporting Manager",
		imageKey: "profile_img",
		allowFewSpecialCharacters: true,
	},
	user_department_name: {
		api: (query, cancelToken) =>
			searchAllDepartmentsV2(
				{
					columns: [],
					sort_by: [],
					filter_by: [
						getSearchReqObj(query, "dept_name", "Department Name"),
					],
				},
				cancelToken
			),
		displayKey: "dept_name",
		apiKey: "dept_id",
		label: "Department",
		allowFewSpecialCharacters: true,
	},
	user_email: {
		api: searchUsers,
		textKey: "user_name",
		displayKey: "user_email",
		apiKey: "user_id",
		label: "User Email",
		imageKey: "profile_img",
		allowFewSpecialCharacters: true,
	},
	user_groups: {
		api: (query, cancelToken) =>
			getGroupsList(
				{
					columns: [],
					sort_by: [],
					filter_by: [
						getSearchReqObj(query, "group_name", "Group Name"),
					],
				},
				0,
				30,
				cancelToken
			),
		displayKey: "group_name",
		apiKey: "group_id",
		label: "Group ID",
		allowFewSpecialCharacters: true,
	},
	"onboardingoffboardingusers.name": {
		api: searchUsers,
		displayKey: "user_name",
		apiKey: "user_id",
		label: "User",
		imageKey: "profile_img",
		allowFewSpecialCharacters: true,
	},
	"onboardingoffboardingusers._id": {
		api: searchUsers,
		displayKey: "user_name",
		apiKey: "user_id",
		label: "User ID",
		imageKey: "profile_img",
		allowFewSpecialCharacters: true,
	},
	"onboardingoffboardingusers.email": {
		api: searchUsers,
		displayKey: "user_email",
		apiKey: "user_id",
		label: "User Email",
		imageKey: "profile_img",
		allowFewSpecialCharacters: true,
	},
	app_technical_owner_email: {
		api: searchUsers,
		textKey: "user_name",
		displayKey: "user_email",
		apiKey: "user_id",
		label: "User Email",
		imageKey: "profile_img",
		allowFewSpecialCharacters: true,
	},
	app_financial_owner_email: {
		api: searchUsers,
		textKey: "user_name",
		displayKey: "user_email",
		apiKey: "user_id",
		label: "User Email",
		imageKey: "profile_img",
		allowFewSpecialCharacters: true,
	},
	app_owner_email: {
		api: searchUsers,
		textKey: "user_name",
		displayKey: "user_email",
		apiKey: "user_id",
		label: "User Email",
		imageKey: "profile_img",
		allowFewSpecialCharacters: true,
	},
	dept_name: {
		api: (query, cancelToken) =>
			searchAllDepartmentsV2(
				{
					columns: [],
					sort_by: [],
					filter_by: [
						getSearchReqObj(query, "dept_name", "Department Name"),
					],
				},
				cancelToken
			),
		displayKey: "dept_name",
		apiKey: "dept_id",
		label: "Department",
		allowFewSpecialCharacters: true,
	},
	license_name: {
		api: (query, cancelToken) =>
			searchLicensesV2(
				{
					columns: [],
					sort_by: [],
					filter_by: [
						getSearchReqObj(query, "license_name", "License Name"),
					],
				},
				cancelToken
			),
		displayKey: "license_name",
		apiKey: "license_id",
		label: "License",
		imageKey: "app_logo",
		allowFewSpecialCharacters: true,
	},
	contract_name: {
		api: (query, cancelToken) => {
			const api =
				window.location.hash === "#allContracts"
					? searchContractsV2
					: window.location.hash === "#allSubscriptions"
					? searchSubscriptionV2
					: searchPerpetualV2;
			return api(
				{
					columns: [],
					sort_by: [],
					filter_by: [
						getSearchReqObj(
							query,
							"contract_name",
							window.location.hash === "#allContracts"
								? "Contract Name"
								: window.location.hash === "#allContracts"
								? "Subscription Name"
								: "Perpetual Name"
						),
					],
				},
				cancelToken
			);
		},
		displayKey: "contract_name",
		apiKey: "contract_id",
		getLabel: () => window.location.hash.slice(4),
		imageKey: "app_logo",
		allowFewSpecialCharacters: true,
	},
	"licenses.license_name": {
		api: (query, cancelToken) =>
			searchApplicationLicensesV2(
				{
					columns: [],
					sort_by: [],
					filter_by: [
						getSearchReqObj(query, "license_name", "License Name"),
					],
				},
				cancelToken
			),
		displayKey: "license_name",
		apiKey: "license_id",
		label: "License",
		imageKey: "app_logo",
		allowFewSpecialCharacters: true,
	},
	"app_department_array.dept_name": {
		api: (query, cancelToken) =>
			searchAllDepartmentsV2(
				{
					columns: [],
					sort_by: [],
					filter_by: [
						getSearchReqObj(query, "dept_name", "Department Name"),
					],
				},
				cancelToken
			),
		displayKey: "dept_name",
		apiKey: "dept_id",
		label: "Department",
		allowFewSpecialCharacters: true,
	},
};

export default function Autosuggestions(props) {
	console.log("AUTOsUGGSTIONS");
	const cancelToken = useRef();
	const [userSuggestions, setUserSuggestions] = useState([]);
	const [metadata, setMetadata] = useState({});
	const [userSuggestionsLoading, setUserSuggestionsLoading] = useState(true);

	const [fieldId, setFieldId] = useState("");
	const [error, setError] = useState(null);

	useEffect(() => {
		if (typeof props.field_id === "string") setFieldId(props.field_id);
		else if (
			props.filter_type === "objectId" &&
			Array.isArray(props.field_id) &&
			props.field_id.length > 0
		) {
			setFieldId("kbkey");
		}
	}, [props.field_id]);

	const values = useSelector((state) => state.filters[fieldId]);
	const [value, setValue] = useState([]);
	const router = useSelector((state) => state.router);
	const [activeOption, setActiveOption] = useState(
		fieldId === "app_tags" ? (props.isNotOf ? 1 : 0) : 0
	);
	const type = props.filter_type.toLocaleLowerCase();

	const dispatch = useDispatch();

	const [showUserSuggestions, setShowUserSuggestions] = useState(false);
	const { pathname } = router.location;
	const path = pathname.split("/");
	let pathName = path[1];

	useEffect(() => {
		if (fieldId) {
			setMetadata(
				fieldMap[props.searchable ? props.original_field_id : fieldId]
			);
		}
	}, [fieldId]);
	const generateUserSuggestions = useCallback(
		debounce((query, reqCancelToken) => {
			if (query && query.length >= 1) {
				if (
					checkSpecialCharacters(
						query,
						fieldMap[
							props.searchable ? props.original_field_id : fieldId
						]?.allowFewSpecialCharacters
					)
				) {
					setUserSuggestions([]);
					setUserSuggestionsLoading(false);
				} else if (metadata.api) {
					metadata
						.api(query, reqCancelToken, true, fieldId, pathName)
						.then((res) => {
							if (
								res?.results ||
								res?.data ||
								res?.[0]?.custom_fields
							) {
								setUserSuggestions(
									res.results ||
										res.data ||
										res[0].custom_fields
								);
							} else if (res.error) {
								setUserSuggestions([]);
							}
							setUserSuggestionsLoading(false);
						})
						.catch((err) => {
							if (!client.isCancel(err)) {
								if (
									metadata?.getLabel
										? metadata?.getLabel()
										: metadata?.label
								) {
									TriggerIssue(
										`Error while searching ${
											metadata?.getLabel
												? metadata?.getLabel()
												: metadata?.label
										}`,
										err
									);
								} else {
									TriggerIssue(`Error while searching`, err);
								}
								setUserSuggestionsLoading(false);
								setUserSuggestions([]);
								setError(err);
							}
						});
				}
			}
		}, 300)
	);

	const handleClose = (index) => {
		const previousValue = values?.field_values || [];
		const previousCustomFieldValues = values?.custom_field_values || [];
		const updatedValues = (
			previousCustomFieldValues.length
				? previousCustomFieldValues
				: previousValue
		).filter((_item, idx) => {
			return idx !== index;
		});
		if (updatedValues.length === 0) {
			dispatch({
				type: ACTION_TYPE.RESET_FILTER,
				payload: {
					key: fieldId,
					value: { ...props, field_values: updatedValues },
				},
			});
		} else {
			dispatch({
				type: ACTION_TYPE.UPDATE_FILTER,
				payload: {
					key: fieldId,
					value: {
						...props,
						field_values: updatedValues,
						field_order: "contains",
					},
				},
			});
		}
	};

	const handleActiveOptionChange = (index) => {
		setActiveOption(index);
		setValue("");
		dispatch({
			type: ACTION_TYPE.UPDATE_FILTER,
			payload: {
				key: fieldId,
				value: {
					...props,
					field_order: "contains",
					negative:
						fieldId === "app_tags"
							? multiOptions[activeOption][type].negative
							: false,
					field_values: [],
					custom_field_values: [],
				},
			},
		});
	};

	const handleAppOwnerSelect = (respObj) => {
		const { displayKey, apiKey, selectedId } = metadata;
		const name = respObj[displayKey];
		const value = respObj[displayKey];
		const idValue = respObj[apiKey];
		const customFieldId = respObj[selectedId];
		if (values?.field_values?.indexOf(respObj[displayKey]) > -1) {
			setShowUserSuggestions(false);
			setValue("");
			return;
		}
		const previousValue = values?.field_values || [];
		const updatedSelectedValues = [
			...previousValue,
			encodeURIComponent(value),
		];
		const updatedSelectedIdValues = [
			...previousValue,
			encodeURIComponent(idValue),
		];

		const previousNameValue = values?.field_name_values || [];
		const updatedSelectedNameValue = [
			...previousNameValue,
			encodeURI(name),
		];

		const previousCustomFieldValues = values?.custom_field_values || [];
		const customUpdatedSelectedValues = [
			...previousCustomFieldValues,
			encodeURIComponent(
				respObj.org_custom_field_type === "reference"
					? customFieldId
					: value
			),
		];

		setValue("");
		dispatch({
			type: ACTION_TYPE.UPDATE_FILTER,
			payload: {
				key: fieldId,
				value: {
					...props,
					field_order: "contains",
					negative:
						fieldId === "app_tags"
							? multiOptions[activeOption][type].negative
							: false,
					field_values:
						props.filter_type === "objectId"
							? updatedSelectedIdValues
							: updatedSelectedValues,
					field_name_values: updatedSelectedNameValue,
					custom_field_values: customUpdatedSelectedValues,
				},
			},
		});
		setShowUserSuggestions(false);
	};

	const handleAppOwnerChange = (query) => {
		query = query?.trimStart();
		setValue(query);

		if (cancelToken.current)
			cancelToken.current.cancel(
				"Operation cancelled in favor of a new request"
			);

		if (query.length == 0) {
			setShowUserSuggestions(false);
			setUserSuggestionsLoading(false);
			return;
		}

		if (checkSpecialCharacters(query)) {
			setUserSuggestions([]);
			setShowUserSuggestions(true);
			setUserSuggestionsLoading(false);
			return;
		}

		setUserSuggestionsLoading(true);
		setShowUserSuggestions(true);
		cancelToken.current = client.CancelToken.source();
		generateUserSuggestions(query, cancelToken.current);
	};

	const AutoSuggestContainer = ({ multiOption, option, index = 0 }) => {
		let labelName = option?.label
			? option.label
			: props.filter_type === "boolean"
			? "Is of"
			: "Is Any of";
		return (
			<div className="autosuggest__container">
				<Form.Check
					onChange={() =>
						multiOption && handleActiveOptionChange(index)
					}
					className="my-1"
					id={`suggestionBox${props?.field_name}`}
					checked={activeOption === index}
					type="radio"
					label={labelName}
				/>
				{activeOption === index && (
					<div
						style={{ marginTop: "10px" }}
						className="chip__container mb-2"
					>
						<div>
							<Chip
								inputArea={
									<div className="chip__input__wrapper">
										<Form.Control
											type="textarea"
											className="chip__input"
											placeholder={`Add ${
												metadata?.getLabel
													? metadata?.getLabel()
													: metadata?.label
											}`}
											value={value}
											// isInvalid={isInvalid("app_owner_id")}
											onChange={(e) =>
												handleAppOwnerChange(
													e.target.value,
													option
												)
											}
											autoFocus={true}
										/>
									</div>
								}
								onClose={handleClose}
								values={
									props.filter_type === "objectId"
										? recursive_decode_request_data(
												values?.field_name_values
										  )
										: recursive_decode_request_data(
												values?.field_values
										  )
								}
								showResetAll={false}
							/>
						</div>
					</div>
				)}
				<Form.Control.Feedback type="invalid">
					{`Please select a valid ${
						metadata?.getLabel
							? metadata?.getLabel()
							: metadata?.label
					}.`}
				</Form.Control.Feedback>
				{activeOption === index && (
					<div className="position-relative w-100">
						<SuggestionMenu
							className="position-static"
							show={showUserSuggestions}
							loading={userSuggestionsLoading}
							options={userSuggestions}
							onSelect={handleAppOwnerSelect}
							dataKeys={{
								image: metadata?.imageKey || "profile_img",
								text: metadata?.textKey || metadata?.displayKey,
								email: "user_email",
							}}
						/>
					</div>
				)}
			</div>
		);
	};

	if (error) {
		return <ErrorWrapper showIcon={false} error={error} />;
	}
	return fieldId === "app_tags" ? (
		<>
			{multiOptions.map((option, index) => (
				<AutoSuggestContainer
					multiOption={true}
					option={option}
					index={index}
					key={`suggestionBox${props?.field_name}${index}`}
				/>
			))}
		</>
	) : (
		<AutoSuggestContainer />
	);
}
