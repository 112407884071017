import React from "react";
import axios from "axios";
import { setRedirectURL } from "modules/shared/utils/setRedirectURL";
import { saveAPIResponseToStore } from "../reducers/api.reducer";
import { store } from "../utils/store";
import { toast } from "react-toastify";
import {
	recursive_decode_request_data,
	recursive_unescape_response_data,
} from "./common";
import {
	getValueFromSessionStorage,
	setValueToLocalStorage,
} from "./localStorage";
import { ErrorResponseFromAPI, TriggerIssue } from "./sentry";
import { clearStorage, getValueFromLocalStorage } from "./localStorage";
import { PARTNER } from "../modules/shared/constants/app.constants";
import { RBAC_ERROR, RBAC_ERROR_STATUS_CODES } from "constants/rbac";
import ToastNotificaion from "UIComponents/Rbac/ToastNotification";

const backendUrl =
	getValueFromLocalStorage("backendUrl") || process.env.REACT_APP_API_URL;
const workflowUrl = process.env.REACT_APP_WORKFLOW_API_URL;

const VULNERABLE_URL = [
	"/auth/signup",
	"/auth/authorize",
	"/auth/authorize2",
	"organizations",
	"overview",
	"onboarding",
	"validate-user-email",
	"validate-org-slug",
];

const authLessEndpoints = ["validate-user-email", "validate-org-slug"];

const FORCE_VULNERABLE_URL = [
	"manual-task-list",
	"mark-action-as-completed",
	"sign-declaration",
];

// DEFAULTS FOR all the instance of axios
axios.defaults.baseURL = backendUrl;
axios.defaults.headers.common["strict-transport-security"] =
	"max-age=63072000; includeSubDomains; preload";
axios.defaults.headers.common["Content-Security-Policy"] =
	"upgrade-insecure-requests";
axios.defaults.headers.common["X-Frame-Options"] = "SAMEORIGIN";
axios.defaults.headers.common["X-Content-Type-Options"] = "nosniff";
axios.defaults.headers.common["Referrer-Policy"] = "same-origin";
axios.defaults.headers.common["X-XSS-Protection"] = "1; mode=block";
axios.defaults.headers.common["server"] = "cloudfront";
axios.defaults.headers.common["Permissions-Policy"] =
	"geolocation=(self), midi=(self), push=(self), sync-xhr=(self), microphone=(self), camera=(self), magnetometer=(self), gyroscope=(self), speaker=(self), vibrate=(self), fullscreen=(self), payment=(self)";

function isVulnerableURL(request) {
	if (request) {
		const idx = FORCE_VULNERABLE_URL.findIndex((el) =>
			request.url.includes(el)
		);
		if (idx > -1) return true;
		const index = VULNERABLE_URL.findIndex(
			(el) =>
				request.url.includes(el) &&
				!request.url.includes("workflows") &&
				!request.url.includes("optimization") &&
				!request.url.includes("onboarding-offboarding") &&
				!request.url.includes("rules") &&
				!request.url.includes("mini-playbooks") &&
				!request.url.includes("integrations") &&
				!request.url.includes("health-insights") &&
				!request.url.includes("onboarding-users-via-csv") &&
				!request.url.includes("get-app-overview")
		);
		return index > -1 ? true : false;
	}
	return true;
}

function decodeForURLs(request) {
	return ["signed-url"].some((url) => request.url.includes(url));
}

// Axios instances
const client = axios.create();
const clientV2 = axios.create();
const AppclientV2 = axios.create();
const clientV3 = axios.create();
const clientEmployee = axios.create();
const clientEmployeeV2 = axios.create();
const workflowClient = axios.create();

const CLIENT_OBJ = [
	{
		client: client,
		version: null,
	},
	{
		client: clientV2,
		version: "v2",
	},
	{
		client: clientV3,
		version: "v3",
	},
	{
		client: clientEmployee,
		version: "employee",
	},
	{
		client: clientEmployeeV2,
		version: "employee/v2",
	},
	{
		client: workflowClient,
		version: "",
		name: "workflow",
	},
];

const ignoreUnescapeDataUrl = [
	"workflows/manual-action/templates",
	"/nodes/application/",
	"/workflows/",
	"/dynamic-input",
]; // add more url's to ignore unescape

CLIENT_OBJ.forEach((item) => {
	item.client.interceptors.response.use(
		function (response) {
			if (response?.data?.error) {
				ErrorResponseFromAPI(response);
			}
			const ignoreUnescapeUrl = ignoreUnescapeDataUrl.some((v) =>
				response.config.url.includes(v)
			);
			if (!response?.data?.inputFields && !ignoreUnescapeUrl) {
				response.data = recursive_unescape_response_data(
					response?.data
				);
			}
			return response;
		},
		function (error) {
			if (
				error.response?.data.type === RBAC_ERROR &&
				RBAC_ERROR_STATUS_CODES.includes(error.response.status) &&
				error.response?.data.operation === "mutation"
			) {
				toast(<ToastNotificaion />, {
					hideProgressBar: true,
				});
			}
			ErrorResponseFromAPI(error?.response);
			if (
				error &&
				error.response &&
				(error.response.status === 401 ||
					(error.response.status === 400 &&
						error.response.data.userInfo === null)) &&
				!error.config?.url.includes("notifications")
			) {
				// update logic to handle notification call, on tenant swith it is called too early and it causes to logout.
				setRedirectURL(
					window.location.href.replace(window.location.origin, "")
				);
				clearStorage();
				window.location.replace("/logout");
			}
			if (
				error &&
				error.response?.status === 403 &&
				getValueFromSessionStorage("support")
			) {
				toast.error("You’re not authorized to perform this action");
			}
			return Promise.reject(error);
		}
	);

	item.client.interceptors.request.use(async (request) => {
		if (authLessEndpoints.indexOf(request.url) > -1) {
			return request;
		}

		try {
			if (item.name === "workflow") {
				request.baseURL = workflowUrl;
			} else {
				request.baseURL = backendUrl;
			}
			let params = getAuth0Params();
			const token = await window.getAccessTokenSilently(params);
			setValueToLocalStorage("token", token);
			request.headers.common["Authorization"] = `Bearer ${token}`;
			const orgId = getValueFromLocalStorage("userInfo")
				? getValueFromLocalStorage("userInfo").org_id
				: undefined;
			if (
				(item.version === "v2" || decodeForURLs(request)) &&
				request.data
			) {
				request.data = recursive_decode_request_data(request.data);
			}
			if (
				!isVulnerableURL(request) ||
				item.version === "employee" ||
				item.version === "employee/v2"
			) {
				request.url = `${
					item.version ? item.version : ""
				}/organization/${orgId}/${request.url}`;
			}
			return request;
		} catch (err) {
			// TriggerIssue(
			// 	"Auth0 refresh grant failed, redirecting user to logout screen - client.js",
			// 	err
			// );
			console.error(
				"Auth0 refresh grant failed, redirecting user to logout screen - client.js",
				err
			);
			setRedirectURL(
				window.location.href.replace(window.location.origin, "")
			);
			window.location.replace("/logout");
		}
	});
});

client.CancelToken = axios.CancelToken;
client.isCancel = axios.isCancel;

const cacheClient = axios.create();

cacheClient.interceptors.request.use(async (request) => {
	try {
		let params = getAuth0Params();
		const token = await window.getAccessTokenSilently(params);
		setValueToLocalStorage("token", token);
		request.headers.common["Authorization"] = `Bearer ${token}`;
		const orgId = getValueFromLocalStorage("userInfo")
			? getValueFromLocalStorage("userInfo").org_id
			: undefined;
		if (!isVulnerableURL(request)) {
			request.url = `/organization/${orgId}/${request.url}`;
		}
		let isCached = store.getState().api.getCalls[request.url];
		if (isCached) {
			request.headers.cached = true;
			request.data = isCached;
			return Promise.reject(request);
		}
		return request;
	} catch (err) {
		TriggerIssue(
			"Auth0 refresh grant failed, redirecting user to logout screen - client.js",
			err
		);
		setRedirectURL(
			window.location.href.replace(window.location.origin, "")
		);
		window.location.replace("/logout");
	}
});

cacheClient.interceptors.response.use(
	(response) => {
		if (response.config.method) {
			if (response.config.url) {
				store.dispatch(
					saveAPIResponseToStore(response.config.url, response.data)
				);
			}
		}
		return response;
	},
	(error) => {
		if (error?.headers?.cached === true) {
			return Promise.resolve(error);
		}
		return Promise.reject(error);
	}
);
export const axiosBaseQuery =
	({ baseUrl } = { baseUrl: "" }) =>
	async ({ url, method, data, params, headers }) => {
		try {
			const result = await client({
				url: baseUrl + url,
				method,
				data,
				params,
				headers,
			});
			return { data: result.data };
		} catch (axiosError) {
			const err = axiosError;
			return {
				error: {
					status: err.response?.status,
					data: err.response?.data || err.message,
				},
			};
		}
	};
export const axiosBaseQueryV2 =
	({ baseUrl } = { baseUrl: "" }) =>
	async ({ url, method, data, params, headers }) => {
		try {
			const result = await clientV2({
				url: baseUrl + url,
				method,
				data,
				params,
				headers,
			});
			return { data: result.data };
		} catch (axiosError) {
			const err = axiosError;
			return {
				error: {
					status: err.response?.status,
					data: err.response?.data || err.message,
				},
			};
		}
	};
export {
	client,
	clientV2,
	clientV3,
	cacheClient,
	clientEmployee,
	clientEmployeeV2,
	AppclientV2,
	workflowClient,
};

function getAuth0Params() {
	const tenantId = getValueFromSessionStorage("tenantId");
	const orgSlug = getValueFromLocalStorage("orgSlug");
	if (tenantId) {
		return {
			authorizationParams: {
				orgId: tenantId,
			},
		};
	}
	if (orgSlug) {
		return {
			authorizationParams: {
				orgSlug,
			},
		};
	}
}
