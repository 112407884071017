import moment from "moment";
import { FULL_MONTH } from "utils/DateUtility";
import "../components/AppLicenses/AppLicensesChart/AppLicensesChart.css";
import { appLicensesCurrencyFormat } from "../utils/AppLicenses.utils";
import { AMBER_VARIANT, BLUE_VARIANT, RED_VARIANT } from "@zluri/ui-components";
import "../components/AppLicenses/AppLicensesTypeContainer/AppLicenses.css";
import React from "react";
import { HandleToolTip } from "../components/chargebacks/utils/chargeback";
import { searchAllDepartments } from "services/api/search";
import { format, isFuture } from "date-fns";
const todayLineIndex = (chartData) => {
	return (
		chartData &&
		(chartData?.findIndex(
			(val) =>
				val ===
				`${FULL_MONTH[moment().month() - 1].slice(0, 3)}'${
					moment().year() % 100
				}`
		) ?? 0) + 1
	);
};

const generateTooltipItemMarkuprow = ({
	headerText,
	name,
	value,
	indicatorColor,
	includeDivider,
}) => {
	return `
				<div
					class="d-flex justify-content-between align-items-center"
					style="margin-left: 2px; margin-right: 4px;"
				>
					<div
					  style="
						font-family: Sora;
						font-size: 11px;
						font-style: normal;
						font-weight: ${headerText ? "700" : "500"};
						line-height: 125%;
						letter-spacing: 0.028px;
						color: ${headerText ? "#484848" : "#717171"};
						display: flex;
						justify-content: space-between;
						align-items: center;
						gap: 6px;
						text-transform: capitalize;
						text-overflow: ellipsis;
					  "
					>
					 ${
							headerText
								? name
								: `<span
						style="
						  display: inline-block;
						  background-color: ${indicatorColor ?? "#2266E2"};
									opacity: ${headerText ? 1 : 0.7};
						  height: 8px;
						  width: 8px;
						  transform: rotate(45deg);
						"
					  ></span
					  >
						<div class="app_license_tooltip_text">
							${name}
						</div>
					  `
						}
					</div>
					<div
							style="
								font-family: Sora;
						font-size: ${headerText ? "11px" : "9px"};
						font-style: normal;
						font-weight: ${headerText ? "700" : "400"};
						line-height: 125%;
						letter-spacing: 0.028px;
						color: #717171;
							"
					>${value}</div>
				</div>

				${
					includeDivider
						? '<div style="min-height: 1px; background-color: #ebebeb; margin-right:2px;"></div>'
						: ""
				}
				`;
};

const generateTooltipItemMarkup = ({
	headerText,
	name,
	value,
	indicatorColor,
	includeDivider,
}) => {
	return `
	<div
    class="d-flex justify-content-between align-items-center"
    style="gap: 40px"
  >
    <div
      style="
        font-family: Sora;
        font-size: 11px;
        font-style: normal;
        font-weight: ${headerText ? "700" : "500"};
        line-height: 125%;
        letter-spacing: 0.028px;
        color: ${headerText ? "#484848" : "#717171"};
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 6px;
      "
    >
      <span
        style="
          display: inline-block;
          background-color: ${indicatorColor ?? "#2266E2"};
          height: 7px;
          width: 7px;
          transform: rotate(45deg);
        "
      ></span
      >${name}
    </div>
    <div
			style="
				font-family: Sora;
        font-size: ${headerText ? "11px" : "9px"};
        font-style: normal;
        font-weight: ${headerText ? "700" : "400"};
        line-height: 125%;
        letter-spacing: 0.028px;
        color: #717171;
			"
		>${value}</div>
  </div>
	${
		includeDivider
			? '<div style="height: 1px; background-color: #ebebeb"></div>'
			: ""
	}
	`;
};

const LegendSymbolColorMap = {
	totalLicenses: { label: "Total Licenses", color: "#2266E2" },
	assigned: { label: "Assigned", color: "#9EC1FA	" },
	overused: { label: "Overused", color: "#BBAFED" },
	cost: { label: "Costs", color: "#FFA217" },
};

export const appLicensesChartConfig = (graphData, Offset) => {
	const xAxisLength = graphData?.xAxisData?.length;

	const todayIndex = todayLineIndex(graphData.xAxisData);

	const todayLine =
		todayIndex > 0
			? {
					color: "#2266e2",
					value: todayIndex,
					width: 0.8,
					zIndex: 10,
					label: {
						useHTML: true,
						formatter: function () {
							return `
					<div class="d-flex flex-column align-items-center" style="margin-top:10px">
						<span class="primary-color bold-500 font-10" style="">Today</span>
						<span class="rounded-circle primary-color-bg" style="width:6.5px; height: 6.5px"></span>
					</div>
				`;
						},
						rotation: 360,
						x: -15,
						y: -12,
					},
				}
			: {};
	return {
		chart: {
			type: "area",
			height: 200,
			plotBackgroundColor: "#E6F5FF",
			marginBottom: 4,
			borderRadius: 10,
			style: {
				zIndex: 2,
			},
		},
		title: {
			text: "",
		},
		contextMenu: {
			enabled: false, // Disable the context menu
		},
		exporting: {
			enabled: false, // Disable exporting options
		},
		navigation: {
			buttonOptions: {
				enabled: false, // Disable the print button
			},
		},
		legend: {
			enabled: false,
		},
		xAxis: {
			categories: graphData?.xAxisData,
			opposite: true,
			gridLineWidth: "1px",
			lineWidth: 0,
			tickWidth: 0,
			max: xAxisLength > 3 && xAxisLength < 11 ? xAxisLength : 11,
			labels: {
				stpe: 2,
				style: {
					fontSize: 11,
				},
			},
			plotLines: [todayLine],
			plotBands: [
				{
					color: {
						pattern: {
							path: "M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11",
							color: "#ebebeb",
							width: 10,
							height: 10,
							zIndex: 100,
						},
					},
					from: FULL_MONTH[moment().month() - 1].slice(0, 3),
					to: graphData[graphData?.xAxisData?.length - 1],
				},
			],
			crosshair: {
				width: 1,
				color: "#FE6955",
				dashStyle: "dash",
			},
		},
		yAxis: {
			min: 0,
			tickAmount: 3,
			title: {
				text: "Licenses",
				style: {
					fontSize: 11,
					fontWeight: 600,
					color: "#000000",
				},
			},
			labels: {
				style: {
					fontSize: 11,
				},
				align: "right",
				x: -1,
			},
			gridLineDashStyle: "longdash",
			gridLineColor: "#A0D8FF",
			offset: Offset,
		},
		credits: {
			enabled: false,
		},
		tooltip: {
			formatter: function () {
				const pointItemsMarkup = this.points
					.map((point, idx) =>
						generateTooltipItemMarkup({
							name: point.series.name,
							value: point.y,
							indicatorColor: point.color,
							includeDivider: idx < this.points.length - 1,
							headerText: idx === 0,
						})
					)
					.join("");

				return `
					<div class="d-flex flex-column" style="gap: 5px">
						${pointItemsMarkup}
					</div>
				`;
			},
			positioner: function (labelWidth, _, point) {
				let tooltipX, tooltipY;

				if (point.plotX + labelWidth < this.chart.plotWidth - 50) {
					tooltipX = point.plotX + 90;
				} else {
					tooltipX = point.plotX - 110;
				}

				tooltipY = 80;

				return {
					x: tooltipX,
					y: tooltipY,
				};
			},
			borderRadius: 8,
			shadow: false,
			shared: true,
			useHTML: true,
		},
		plotOptions: {
			area: {
				marker: {
					enabled: false,
					symbol: "diamond",
					states: {
						hover: {
							enabled: true,
						},
					},
				},
			},
		},
		series: [
			{
				name: LegendSymbolColorMap.totalLicenses.label,
				fillColor: LegendSymbolColorMap.totalLicenses.color,
				color: LegendSymbolColorMap.totalLicenses.color,
				lineColor: "#ffffff",
				data: graphData?.quantitySeries?.map((point) => point.active),
			},
			{
				name: LegendSymbolColorMap.assigned.label,
				fillColor: LegendSymbolColorMap.assigned.color,
				color: LegendSymbolColorMap.assigned.color,
				lineColor: "#ffffff",
				data: graphData?.quantitySeries?.map((point) => point.assigned),
			},
			{
				name: LegendSymbolColorMap.overused.label,
				fillColor: LegendSymbolColorMap.overused.color,
				color: LegendSymbolColorMap.overused.color,
				lineColor: "#ffffff",
				data: graphData?.quantitySeries?.map((point) => point.overused),
			},
		],
	};
};
export const costIncurredChartConfig = (graphData, Offset) => {
	const xAxisLength = graphData?.xAxisData?.length;
	const todayIndex = todayLineIndex(graphData.xAxisData);

	const firstDataInQuantitySeries = graphData?.quantitySeries?.[0];

	const firstDataDate = new Date(
		firstDataInQuantitySeries?.year_id,
		firstDataInQuantitySeries?.month_id - 1,
		firstDataInQuantitySeries?.day
	);
	const isFirstDataFromFuture = isFuture(firstDataDate);
	const todayLine =
		todayIndex > 0
			? {
					color: "#2266e2",
					value: todayIndex,
					width: 0.8,
					zIndex: 10,
				}
			: {};
	const futureBand =
		todayIndex > 0 || isFirstDataFromFuture
			? {
					color: {
						pattern: {
							path: "M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11",
							color: "#ebebeb",
							width: 10,
							height: 10,
							zIndex: 100,
						},
					},
					from: todayIndex,
					to: graphData && graphData?.xAxisData?.length,
				}
			: {};
	return {
		chart: {
			type: "line",
			height: 200,
			plotBackgroundColor: "#F6F7FA",
			marginBottom: 20,
			borderRadius: 10,
		},
		title: {
			text: "",
		},
		contextMenu: {
			enabled: false, // Disable the context menu
		},
		exporting: {
			enabled: false, // Disable exporting options
		},
		navigation: {
			buttonOptions: {
				enabled: false, // Disable the print button
			},
		},
		legend: {
			enabled: false,
			align: "right",
		},
		xAxis: {
			categories: graphData?.xAxisData,
			gridLineWidth: "1px",
			lineWidth: 0,
			tickWidth: 0,
			max: xAxisLength > 3 && xAxisLength < 11 ? xAxisLength : 11,
			allowDecimals: false,
			labels: {
				enabled: false,
				stpe: 2,
			},
			plotLines: [todayLine],
			plotBands: [futureBand],
			crosshair: {
				width: 1,
				color: "#FFA217",
				dashStyle: "dash",
			},
		},
		yAxis: {
			min: 0,
			tickAmount: 3,
			title: {
				text: "Costs",
				style: {
					fontSize: 11,
					fontWeight: 600,
					color: "#000000",
				},
			},
			labels: {
				style: {
					fontSize: 11,
				},
				align: "right",
				x: -1,
			},
			gridLineDashStyle: "longdash",
			gridLineColor: "#FFA99D",
			offset: Offset,
		},
		credits: {
			enabled: false,
		},
		tooltip: {
			formatter: function () {
				return generateTooltipItemMarkup({
					name: this.point.series.name,
					value: appLicensesCurrencyFormat(
						this.point.y,
						true,
						null,
						true
					),
					indicatorColor: this.point.color,
				});
			},
			positioner: function (labelWidth, _, point) {
				let tooltipX, tooltipY;

				if (point.plotX + labelWidth < this.chart.plotWidth - 50) {
					tooltipX = point.plotX + 100;
				} else {
					tooltipX = point.plotX - 75;
				}

				tooltipY = point.plotY - 5;

				return {
					x: tooltipX,
					y: tooltipY,
				};
			},
			shadow: false,
			borderRadius: 8,
			shared: true,
			useHTML: true,
		},
		plotOptions: {
			line: {
				marker: {
					enabled: true,
					symbol: "circle",
					states: {
						hover: {
							enabled: true,
						},
					},
					fillColor: "#FFA217",
				},
			},
		},
		series: [
			{
				name: "Cost",
				fillColor: "#FFDC7A",
				lineColor: "#FFA217",
				data: graphData?.costSeries?.map((point) => point.cost),
				color: "#FFA217",
			},
			{
				name: "chart",
				fillColor: "#FFDC7A",
				lineColor: "#FFA217",
				data: graphData?.costSeries?.map((point) => point.cost),
			},
			{
				name: "chart-1",
				fillColor: "#FFDC7A",
				lineColor: "#FFA217",
				data: graphData?.costSeries?.map((point) => point.cost),
			},
		],
	};
};

export const legendSymbols = [
	{
		text: LegendSymbolColorMap.totalLicenses.label,
		color: LegendSymbolColorMap.totalLicenses.color,
	},
	{
		text: LegendSymbolColorMap.assigned.label,
		color: LegendSymbolColorMap.assigned.color,
	},
	{
		text: LegendSymbolColorMap.overused.label,
		color: LegendSymbolColorMap.overused.color,
	},
	{
		text: LegendSymbolColorMap.cost.label,
		color: LegendSymbolColorMap.cost.color,
	},
];

export const getLicensesChartData = (graphData, fy) => {
	const xdata = getXAxisData(fy);
	const qunatity_data = graphData?.license_quantity?.graph_data || [];
	const cost_data = graphData?.license_cost?.graph_data || [];
	let currentDate = new Date(fy?.start_year, fy?.start_month);
	let endDate = new Date(fy?.end_year, fy?.end_month);
	const monthDiff = Math.abs(
		(endDate.getFullYear() - currentDate.getFullYear()) * 12 +
			endDate.getMonth() -
			currentDate.getMonth()
	);

	const cost = cost_data.map((data) => {
		return {
			day: data?.day || 0,
			month_id: data.month || 0,
			year_id: data.year || 0,
			cost: data.cost,
		};
	});

	const quantity = qunatity_data.map((data) => {
		return {
			day: data?.day || 0,
			month_id: data.month || 0,
			year_id: data.year || 0,
			assigned: data.mapped_quantity || 0,
			unassigned:
				(data.active_quantity || 0) - (data.mapped_quantity || 0),
			overused: data.overused_quantity || 0,
			active: data.active_quantity || 0,
		};
	});
	return {
		xAxisData: xdata,
		quantitySeries:
			monthDiff <= 3
				? quantity
				: quantity.filter((data) => data.day === 1),
		costSeries:
			monthDiff <= 3 ? cost : cost.filter((data) => data.day === 1),
	};
};
const getXAxisData = (fy) => {
	let startMonth = fy?.start_month + 1;
	let startYear = fy?.start_year;
	let endMonth = fy?.end_month + 1;
	let endYear = fy?.end_year;
	const xdata = [];

	let currentDate = new Date(startYear, startMonth - 1);
	let endDate = new Date(endYear, endMonth - 1);
	const monthDiff = Math.abs(
		(endDate.getFullYear() - currentDate.getFullYear()) * 12 +
			endDate.getMonth() -
			currentDate.getMonth()
	);

	if (monthDiff <= 3) {
		while (currentDate <= endDate) {
			const formattedDate = format(currentDate, "dd MMM''yy");

			xdata.push(formattedDate);
			currentDate.setDate(currentDate.getDate() + 1);
		}
	} else {
		while (
			currentDate.getFullYear() < endYear ||
			(currentDate.getFullYear() === endYear &&
				currentDate.getMonth() <= endMonth - 1)
		) {
			const options = { month: "short", year: "2-digit" };
			const formattedDate = new Intl.DateTimeFormat("en-US", options)
				.format(currentDate)
				.replace(" ", "'");
			xdata.push(formattedDate);
			currentDate.setMonth(currentDate.getMonth() + 1);
		}
	}
	return xdata;
};

export const percentageCalculator = (value, totalValue) =>
	(value / totalValue) * 100;

export const barColor = (value) => {
	return {
		red: {
			color: "#FFA99D",
			value,
			typoColor: RED_VARIANT.ACCENT_RED_1,
		},
		yellow: {
			color: "#FFDC7A",
			value,
			typoColor: AMBER_VARIANT.ACCENT_AMBER_4,
		},
		green: {
			color: "#A3E4A6",
			value,
			typoColor: "",
		},
		blue: {
			color: "#2266E2",
			value,
			typoColor: BLUE_VARIANT.PRIMARY_BLUE_DARK_1,
		},
	};
};

export const dynamicBarColor = (value, totalValue) => {
	const percentageCalculated = percentageCalculator(value, totalValue);

	switch (true) {
		case percentageCalculated > 50:
			return barColor(value).green;
		case percentageCalculated < 50 && percentageCalculated > 20:
			return barColor(value).yellow;
		case percentageCalculated < 20:
			return barColor(value).red;
		default:
			return barColor(value).red;
	}
};

export const overMappedData = (number, total_number) => {
	let new_total_number, new_number;

	if (number > total_number) {
		new_total_number = total_number + (number - total_number);
		new_number = number - (number - total_number);
		return { new_number, new_total_number };
	} else {
		return { new_number: number, new_total_number: total_number };
	}
};

export const mappedLicenseColor = (value, totalValue) => {
	switch (true) {
		case value > totalValue:
			return barColor(value).yellow;
		case value < totalValue && value >= 20:
			return barColor(value).blue;
		case value < 20:
			return barColor(value).red;
		default:
			break;
	}
};

export const licensesByDepartmentChartConfig = (graphData, activeTab) => {
	return {
		chart: {
			type: "column",
			height: 315,
			spacingBottom: 20,
			backgroundColor: "#F6F7FA",
		},

		title: {
			text: "",
		},
		exporting: {
			enabled: false, // Disable exporting options
		},
		navigation: {
			buttonOptions: {
				enabled: false, // Disable the print button
			},
		},
		credits: {
			enabled: false, // Disable the credits link
		},
		contextMenu: {
			enabled: false, // Disable the context menu
		},
		legend: {
			enabled: false,
		},

		xAxis: {
			categories: graphData?.xAxisData,
			labels: {
				useHTML: true,
				rotation: 0,
				style: {
					width: "60px",
				},
				formatter: function () {
					return toolTipForLables(this.value);
				},
				align: "center",
			},
		},

		yAxis: {
			allowDecimals: false,
			min: 0,
			tickAmount: 5,
			title: {
				text: "",
			},
		},
		tooltip: {
			backgroundColor: "transparent",
			formatter: function () {
				const topPoints = this.points;

				const topPointItemsMarkup = topPoints?.map((point, idx) => {
					return generateTooltipItemMarkuprow({
						name: point.series.name,
						value:
							activeTab === "costs"
								? appLicensesCurrencyFormat(
										point.y,
										true,
										null,
										true
									)
								: point.y,
						indicatorColor: point.color,
						includeDivider: idx !== topPoints.length - 1,
					});
				});
				const final = [...topPointItemsMarkup].join("");

				return `
				<div class="app_licenses_tooltip_container">
				    <div class="d-flex flex-column app_licenses_tooltip_scrollbar " style="gap: 8px;
					max-height: 185px; min-width: 176px;
					overflow-y: auto;">
				        ${final}
				    </div>
				</div>
				`;
			},
			shared: true,
			useHTML: true,
			borderRadius: 8,
			shadow: false,
		},

		plotOptions: {
			column: {
				stacking: "normal",
				pointWidth: 40,
			},
		},

		series: graphData?.seriesData,
	};
};

export const prepareLegendColor = (graphData) => {
	return graphData?.seriesData?.map((data, index) => {
		return {
			name: data.name,
			color: chartColors.colors[index % chartColors.colors.length],
		};
	});
};

export const chartColors = {
	colors: ["#A0D8FF", "#82A7ED", "#BBAFED", "#9EC1FA", "#FFCA99", "#DDDDDD"],
};

export const tabConfig = (chartLicenseAndCostData) => {
	return [
		{
			eventKey: "no_of_licenses",
			title: "No. of Licenses",
			chartData: chartLicenseAndCostData,
		},
		{
			eventKey: "costs",
			title: "Cost",
			chartData: chartLicenseAndCostData,
		},
	];
};

const toolTipForLables = (value) => {
	return `
	<div class="x_axis_labels_stack_bar">
		<div style=
		"
		max-width: 60px;
		text-transform: capitalize;
		width: fit-content;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		" >
		${`${value}...`}
		</div>
	<span class="x_axis_lable_tooltip_for_stack_bar">${value}</span>
	<div>
	`;
};

export const handleEllipseText = (
	tooltipData,
	tooltipName,
	maxWidth = "120px"
) => {
	return (
		<HandleToolTip
			tooltipData={tooltipData}
			tooltipName={tooltipName}
			maxWidth={maxWidth}
		/>
	);
};

export const handleExceedColorForLicenses = (
	value,
	total_value,
	firstResult,
	secondResult
) => {
	return value > total_value ? firstResult : secondResult;
};

export const licenseQuickFilterObj = [
	{
		btnTitle: "Paid Licenses",
		type: "paid",
		classNames: "font-19 bold-400",
		tooltipContent: "",
		styles: { width: "fit-content", fontWeight: "400" },
	},
	{
		btnTitle: "All Licenses",
		type: "all",
		classNames: "font-19 bold-400",
		tooltipContent: "",
		styles: { width: "fit-content", fontWeight: "400" },
	},
];

export const licensesDropdownSubMenuObj = {
	department: {
		searchApi: searchAllDepartments,
		clear_filter: {
			text: "Reset Filter",
			classNames: "clear_filter",
			textStyles: {
				fontSize: "10px",
			},
		},
		title: {
			text: "Department",
			classNames: "dept_title",
		},
		dataAccessor: "results",
		idKey: "department_id",
	},
};

export const licenseHeaderFilter = [
	{
		filterText: "By Department",
		type: "department",
		key: "by_department",
	},
];
