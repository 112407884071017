export const getAutomationsPlaybookIdFilterMetaData = (playbookId) => {
	const meta = {
		columns: [],
		filter_by: [],
		sort_by: [],
	};
	meta.filter_by = [
		{
			field_id: "workflow_template_id",
			field_name: "Playbook Id",
			field_values: [playbookId],
			filter_type: "objectId",
			is_custom: false,
			negative: false,
		},
	];
	return decodeURIComponent(JSON.stringify(meta));
};

export const getRulesFilterDatawithPlaybookId = (playbookId) => {
	const meta = {
		columns: [],
		filter_by: [],
		sort_by: [],
	};
	if (playbookId) {
		meta.filter_by = [
			{
				field_name: "Status",
				field_type: "select",
				field_values: ["active"],
				field_id: "status",
				filter_type: "string",
				negative: false,
				is_field_static: true,
				editable: false,
				draggable: false,
				is_sortable: true,
				sort_default_value: 1,
				is_filterable: true,
			},
			{
				field_name: "Playbooks",
				field_type: ["autocomplete", "select"],
				parent_field_id: "events",
				field_values: [[playbookId], ["run_workflow"]],
				field_id: ["workflowTemplateId", "type"],
				filter_type: "elem_match",
				negative: false,
				is_field_static: false,
				editable: false,
				draggable: false,
				is_sortable: true,
				sort_default_value: 1,
				is_filterable: true,
			},
		];
	}
	return meta;
};

export const getAutomationsRuleIdFilterMetaData = (ruleId) => {
	const meta = {
		columns: [],
		filter_by: [],
		group_filter_by: {
			entity_group: [],
			entity_type: " ",
		},
		sort_by: [],
	};

	meta.filter_by = [
		{
			field_id: "rule_id",
			field_name: "Rule Id",
			field_values: [ruleId],
			filter_type: "objectId",
			is_custom: false,
			negative: false,
		},
	];

	return JSON.stringify(meta);
};
