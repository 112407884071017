import "./styles.css";
import Stepper from ".";
import Step from "./Step";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setInititalStepperState, updateStep } from "./redux";

export default function ReduxStepper({
	steps = [],
	reduxStepperStepClass,
	reduxStepperContainerClass = "plan__stepper",
}) {
	const activeStep = useSelector(
		(state) => state.stepper.stepConfig.activeStep
	);
	const screen = useSelector((state) => state.stepper.screen);

	useEffect(() => {
		return function cleanup() {
			if (screen === "OVERVIEW" || screen === "STEPPER")
				dispatch(setInititalStepperState());
		};
	}, []);

	const dispatch = useDispatch();

	return (
		<div className={reduxStepperContainerClass}>
			<Stepper activeStep={activeStep}>
				{Array.isArray(steps) &&
					steps.map((step, index) => (
						<Step
							key={index}
							title={step.title}
							index={index}
							activeStep={activeStep}
							reduxStepperStepClass={reduxStepperStepClass}
						>
							{React.cloneElement(step.component, {
								updateStep: () =>
									dispatch(updateStep(index + 2)),
								index,
							})}
						</Step>
					))}
			</Stepper>
		</div>
	);
}
