import React from "react";
import PropTypes from "prop-types";
import {
	Tooltip,
	Typography,
	ALERT_TAG_VARIANT,
	BODY_VARIANT,
	GREY_VARIANT,
} from "@zluri/ui-components";

export function LongTextTooltip({
	text,
	maxWidth,
	style,
	variant,
	color,
	className,
	tooltipClassname,
	placement = "bottom",
	showTooltip,
}) {
	return (
		<Tooltip
			content={
				text &&
				showTooltip && (
					<Typography
						color="white"
						variant={ALERT_TAG_VARIANT.ALERT_TAG_SMALL_MEDIUM}
					>
						{text}
					</Typography>
				)
			}
			placement={placement}
			className={tooltipClassname}
		>
			<Typography
				variant={variant ?? BODY_VARIANT.BODY_2_REGULAR}
				color={color ?? GREY_VARIANT.SECONDARY_GREY_1}
				className={className}
				style={{
					maxWidth: maxWidth,
					width: "fit-content",
					whiteSpace: "nowrap",
					overflow: "hidden",
					textOverflow: "ellipsis",
					...style,
				}}
			>
				{text}
			</Typography>
		</Tooltip>
	);
}

LongTextTooltip.propTypes = {
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	style: PropTypes.object,
	variant: PropTypes.string,
	color: PropTypes.string,
	className: PropTypes.string,
	showTooltip: PropTypes.bool,
	tooltipClassname: PropTypes.string,
	placement: PropTypes.string,
};
