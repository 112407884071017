import React, { useEffect, useState, useRef } from "react";
import leftarrow from "assets/Icon_Left_Chevron.svg";
import rightarrow from "assets/Icon_Right_Chevron.svg";
import pagination from "assets/pagination.svg";
import scroll_icon from "assets/scroll_icon.svg";
import tick from "assets/pagination_tick.svg";

import "./index.css";

import { useDispatch } from "react-redux";
import { Typography, theme, IconButton, Popover } from "@zluri/ui-components";
import Divider from "containers/v2table/Divider/Divider";
import DropDownButtonTab from "v2UIComponents/DropDownButtonTab/DropDownButtonTab";
import DropdownButton from "v2UIComponents/DropDownButton/DropDownButton";
import PropTypes from "prop-types";
import { useTableContext } from "containers/v2table/TableContext/context";
import VerticalDivider from "containers/v2table/VerticalDivider";
import TextTooltip from "../TextTooltip";

const PAGINATION_TYPES = {
	INFINITE: "infinite",
	PAGINATION: "pagination",
};

const paginationTypeOptions = [
	{
		label: "Infinite Scroll",
		icon: scroll_icon,
		value: PAGINATION_TYPES.INFINITE,
	},
	{
		label: "Pagination",
		icon: pagination,
		value: PAGINATION_TYPES.PAGINATION,
	},
];

function useCheckSiblingElement(ref) {
	const [state, setState] = useState({ hasNext: false, hasPrevious: false });
	useEffect(() => {
		if (ref.current) {
			const element = ref.current;
			setState({
				hasNext: element.nextSibling,
				hasPrevious: element.previousSibling,
			});
		}
	}, [ref]);

	return state;
}

interface IPaginationControl {
	loadMoreData: () => void;
	loadPrevData: () => void;
	page: number;
	recordsPerPage: number;
	loading: boolean;
}
export default function PaginationControl({
	loadMoreData,
	loadPrevData,
	page,
	recordsPerPage,
	loading,
}: IPaginationControl) {
	const [paginationType, setPaginationType] = useState("");
	const paginationRef = useRef(null);

	const { hasNext, hasPrevious } = useCheckSiblingElement(paginationRef);
	const {
		dataEntity,
		gridRef,
		apiProps,
		totalDataCount: total,
		isInfinite,
		paginationText,
		resetCheckboxState,
	} = useTableContext();
	const rowCount = recordsPerPage;
	const totalPages = Math.floor(total / +rowCount);

	const [showPopup, setShowPopup] = useState(false);
	const dispatch = useDispatch();

	useEffect(() => {
		if (isInfinite) setPaginationType(PAGINATION_TYPES.INFINITE);
		else setPaginationType(PAGINATION_TYPES.PAGINATION);
	}, [isInfinite]);

	const getCurrentCount = () => {
		if (!total) return `0-0`;

		const start = page * rowCount + 1;
		const end = (page + 1) * rowCount;
		return `${start}-${end > total ? total : end}`;
	};

	const handleLeftClick = async () => {
		loadPrevData();
		resetCheckboxState();
	};
	const handleRightClick = async () => {
		loadMoreData();
		resetCheckboxState();
	};

	const updateRecordPerPage = (recordsPerPage) => {
		dispatch({
			type: "UPDATE_RECORDS_PER_PAGE",
			payload: {
				entity: dataEntity,
				recordsPerPage,
				isInfinite: false,
				shouldRefresh: true,
				apiProps: apiProps,
			},
		});
		setShowPopup(false);
	};

	return (
		<div
			className="d-flex align-items-center position-relative gap-8"
			ref={paginationRef}
		>
			{hasPrevious && <VerticalDivider />}
			<Popover
				trigger={["click"]}
				show={showPopup}
				onToggle={setShowPopup}
				content={
					<div className="d-flex flex-column pagination_options">
						{paginationTypeOptions.map((option) => {
							return (
								<DropDownButtonTab
									key={option.label}
									onClick={() => {
										gridRef?.current?.setScrollLeft(0);
										dispatch({
											type: "UPDATE_PAGINATION_TYPE",
											payload: {
												entity: dataEntity,
												isInfinite:
													option.value ===
													PAGINATION_TYPES.INFINITE,
											},
										});
										resetCheckboxState();
										setShowPopup(false);
									}}
								>
									<DropdownButton className="d-flex align-items-center gap-6">
										<img
											src={option.icon}
											alt="scroll icon"
										/>
										<Typography
											style={{
												width: 86,
											}}
											className="d-flex text-start flex-1"
											variant="button_extrasmall_medium"
										>
											{option.label}
										</Typography>
										{paginationType === option.value && (
											<img src={tick} alt="tick icon" />
										)}
									</DropdownButton>
								</DropDownButtonTab>
							);
						})}

						{paginationType === PAGINATION_TYPES.PAGINATION && (
							<>
								<Divider type="new" />
								{[10, 20, 30].map((number) => {
									const isSelected = rowCount === number;
									return (
										<PaginationRecordsPerPageSelector
											key={number}
											onClick={() =>
												updateRecordPerPage(number)
											}
											count={number}
											isSelected={isSelected}
										/>
									);
								})}
							</>
						)}
					</div>
				}
			>
				<div>
					<TextTooltip title="Pagination Configuration">
						<button className="v2_pagination_controller">
							<Typography
								variant="button_extrasmall_regular"
								color="secondary_grey_2"
								className="text-nowrap"
								style={{ lineHeight: "100%" }}
							>
								{`${
									paginationType === PAGINATION_TYPES.INFINITE
										? "Showing "
										: `${getCurrentCount()} of`
								} ${total || 0} `}
								<span className="text-capitalize">
									{paginationText}
								</span>
							</Typography>
						</button>
					</TextTooltip>
				</div>
			</Popover>

			<div className="flex align-items-center gap-8">
				{paginationType === PAGINATION_TYPES.PAGINATION && (
					<>
						{page !== 0 && (
							<TextTooltip title="Previous Page">
								<IconButton
									theme={theme}
									aria-label="Go to Previous page"
									hidden={page === 0}
									onClick={() => {
										handleLeftClick();
									}}
									variant="white"
									shape="square"
									position="default"
									icon={leftarrow}
									disabled={loading}
								/>
							</TextTooltip>
						)}
						{page < totalPages && (
							<TextTooltip title="Next Page">
								<IconButton
									theme={theme}
									aria-label="Go to Previous page"
									icon={rightarrow}
									hidden={
										totalPages === 0 || page === totalPages
									}
									onClick={() => {
										handleRightClick();
									}}
									variant="white"
									shape="square"
									position="default"
									disabled={loading}
								/>
							</TextTooltip>
						)}
					</>
				)}
			</div>
			{hasNext && <VerticalDivider />}
		</div>
	);
}

interface IPaginationRecordsPerPageSelector {
	onClick: () => void;
	isSelected?: boolean;
	count: number;
}
function PaginationRecordsPerPageSelector({
	onClick,
	isSelected = false,
	count,
}: IPaginationRecordsPerPageSelector) {
	return (
		<DropDownButtonTab onClick={onClick}>
			<DropdownButton>
				<div className="d-flex justify-content-between w-100">
					<Typography variant="button_extrasmall_medium">
						{count} per page
					</Typography>
					{isSelected && <img src={tick} alt="tick icon" />}
				</div>
			</DropdownButton>
		</DropDownButtonTab>
	);
}

PaginationRecordsPerPageSelector.propTypes = {
	onClick: PropTypes.func.isRequired,
	isSelected: PropTypes.bool,
	count: PropTypes.number.isRequired,
};
