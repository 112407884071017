import React, { useState } from "react";
import arrowDownIcon from "assets/icons/arrow-down-12.svg";
import { Button, theme, getSegmentFromURL } from "@zluri/ui-components";
import rightarrow from "assets/Icon_Arrow_Right.svg";
import "UIComponents/CustomDropdown/Dropdown.css";
import BulkRunAPlaybook from "modules/workflow/components/BulkRunAPlaybook/BulkRunAPlaybook";
import { useTableContext } from "containers/v2table/TableContext/context";

export const RunPlaybookV2 = ({ loading = false }) => {
	const [openViewPopover, setopenViewPopover] = useState(false);
	const appId = getSegmentFromURL(2);
	const { checkboxState, isAllDataSelected } = useTableContext();
	const selectedRows = checkboxState?.selected;

	const showBulkEdit =
		isAllDataSelected || Object.keys(selectedRows).length > 0;

	if (!showBulkEdit) return null;

	return (
		<BulkRunAPlaybook
			userIds={Object.keys(selectedRows)}
			toggler={
				<Button
					theme={theme}
					variant="secondary-default"
					endIcon={arrowDownIcon}
					iconPosition="end"
					disabled={loading}
					onClick={() => setopenViewPopover(!openViewPopover)}
					dropdownWidth="auto"
				>
					Run a Playbook
				</Button>
			}
			appIdforMiniPlaybook={appId}
			menuOptionClass="cell-header__menu-option-medium d-flex align-items-center"
			menuStyleV2={{
				right: "-295px",
				width: "280px",
				top: "0px",
				borderRadius: "8px",
			}}
			isV2={true}
			rightarrowIcon={rightarrow}
			inputClassName="dropdown_search_bar_container dropdown_search_bar_container_v2"
		/>
	);
};
