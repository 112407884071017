import React, { useMemo, useState } from "react";
import {
	DndContext,
	MouseSensor,
	TouchSensor,
	useSensor,
	useSensors,
	UniqueIdentifier,
	closestCenter,
} from "@dnd-kit/core";
import {
	SortableContext,
	verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import cn from "classnames";
import ListItem from "./ListItem";
import SortableOverlay from "./SortableOverlay";
// import {  restrictToFirstScrollableAncestor,} from '@dnd-kit/modifiers'

interface IColumns {
	group_name: string;
	name: string;
	header: string;
	columnDescription: string;
}
interface ISortableList<T extends IColumns> {
	items: T[];
	onSortEnd: (props: { oldIndex: number; newIndex: number }) => void;
	children: React.ReactNode;
	checkedMap: Map<UniqueIdentifier, T>;
}
function SortableList<T extends IColumns>({
	items,
	onSortEnd,
	children,
	checkedMap,
}: ISortableList<T>) {
	const getIndex = (id) => {
		return items.findIndex((item) => item.group_name === id);
	};

	const sensors = useSensors(
		useSensor(MouseSensor, {
			activationConstraint: {
				distance: 10,
			},
		}),
		useSensor(TouchSensor, {
			activationConstraint: {
				delay: 250,
				tolerance: 5,
			},
		})
	);

	const [activeId, setActiveId] = useState<null | UniqueIdentifier>(null);

	const activeItem = useMemo(
		() => items.find((item) => item.group_name === activeId),
		[activeId, items]
	);

	const isActiveItemChecked = activeId ? checkedMap.has(activeId) : false;

	return (
		<DndContext
			sensors={sensors}
			onDragStart={({ active }) => {
				if (active) {
					setActiveId(active.id);
				}
			}}
			// modifiers={[  restrictToFirstScrollableAncestor,]}
			collisionDetection={closestCenter}
			onDragEnd={({ active, over }) => {
				if (over && active.id !== over.id) {
					onSortEnd({
						oldIndex: getIndex(active.id),
						newIndex: getIndex(over.id),
					});
				}
				setActiveId(null);
			}}
			onDragCancel={() => setActiveId(null)}
		>
			<SortableContext
				items={items as any}
				strategy={verticalListSortingStrategy}
			>
				{children}
			</SortableContext>
			<SortableOverlay>
				{activeItem ? (
					<li
						className={cn(
							"flex align-items-center column-item justify-content-between",
							isActiveItemChecked && "column-item-checked"
						)}
					>
						<ListItem
							groupName={activeItem?.name}
							title={activeItem?.header}
							isItemChecked={isActiveItemChecked}
							details={activeItem.columnDescription}
						/>
					</li>
				) : null}
			</SortableOverlay>
		</DndContext>
	);
}

export default SortableList;
