export const operators = {
	select: {
		EQUALS: "equal",
		NOT_EQUALS: "notEqual",
		IS_IN: "in",
		IS_NOT_IN: "notIn",
	},
	entity: {
		EQUALS: "equal",
		NOT_EQUALS: "notEqual",
		IS_IN: "isIn",
		IS_NOT_IN: "isNotIn",
		EQUAL_TO_PRIMARY_SOURCE: "equalPrimarySource",
		NOT_EQUAL_TO_PRIMARY_SOURCE: "notEqualPrimarySource",
		IS_IN_PRIMARY_SOURCE: "inPrimarySource",
		IS_NOT_IN_PRIMARY_SOURCE: "notEqualPrimarySource",
	},
	numeric: {
		EQUALS: "equal",
		NOT_EQUALS: "notEqual",
		IS_LESS_THAN: "lessThan",
		IS_GREATER_THAN: "greaterThan",
	},
	inputbox: {
		EQUALS: "equal",
		NOT_EQUALS: "notEqual",
		TEXT_CONTAINS: "textContains",
		TEXT_DOES_NOT_CONTAIN: "textDoesNotContain",
	},
	date: {
		IS_BEFORE: "lessThan",
		IS_AFTER: "greaterThan",
	},
	string: {
		EQUALS: "equal",
		NOT_EQUALS: "notEqual",
		TEXT_CONTAINS: "textContains",
		TEXT_DOES_NOT_CONTAIN: "textDoesNotContain",
		TEXT_STARTS_WITH: "textStartWith",
		TEXT_DOES_NOT_START_WITH: "textDoesNotStartWith",
		TEXT_ENDS_WITH: "textEndWith",
		TEXT_DOES_NOT_END_WITH: "textDoesNotEndWith",
		IS_EMPTY: "isEmpty",
		IS_NOT_EMPTY: "isNotEmpty",
	},
	objectid: {
		EQUALS: "equal",
		NOT_EQUALS: "notEqual",
		IS_IN: "in",
		IS_NOT_IN: "notIn",
		EQUAL_TO_PRIMARY_SOURCE: "equalPrimarySource",
		NOT_EQUAL_TO_PRIMARY_SOURCE: "notEqualPrimarySource",
		IS_IN_PRIMARY_SOURCE: "inPrimarySource",
		IS_NOT_IN_PRIMARY_SOURCE: "notEqualPrimarySource",
	},
};

export const valueType = {
	STRING: "string",
	SELECT: "select",
	NUMERIC: "numeric",
	INPUTBOX: "inputbox",
	DATE: "date",
	ENTITY: "entity",
	OBJECTID: "objectid",
};

export const singleValueOperators = new Set(["equal", "notEqual"]);

export const booleanOperators = new Set(["isEmpty", "isNotEmpty"]);
