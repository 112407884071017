import { getSourcesByType } from "../utils/OverviewV2.utils";
import { ApplicationConstants } from "./Applications.constant";

const initialState = {
	spends: {
		selectedTab: "",
		overview: {},
		summary: {},
		[ApplicationConstants.ACCRUED_SPENDS]: {},
		[[ApplicationConstants.ACTUAL_SPENDS_VS_EST_COST]]: {},
		[ApplicationConstants.AMMORTIZED_SPENDS_VS_EST_COST]: {},
		financial: {},
		paymentMethod: {},
		setting: {},
		transactionTableSources: {},
		transactionTableVendor: {},
	},
	appAbout: {
		appAboutSimilarApplication: {},
		alternateApplications: {},
		alternateAppFeatures: {},
	},
	overview: {
		archived: null,
		sources: {
			data: {
				integrations: [],
				agents: [],
				sso: [],
				all: [],
			},
			count: 0,
		},
		notes: {
			notes: [],
			count: 0,
		},
		links: {
			data: [],
			count: 0,
		},
		checklist: {
			pending_review: 0,
			loading: true,
			loaded: false,
			data: {},
		},
		metrics: {
			data: {
				users: {
					primary_active_users_count: 0,
					active_users_count: 0,
				},
				spends: {
					actual_spend: 0,
					annualized_spend: 0,
				},
				licenses: {
					assigned: 0,
					optimizable: 0,
				},
				contracts: {
					upcoming_due_date: "",
					affected_licenses: 0,
					name: "",
				},
			},
		},
		user_app_license: {
			data: {},
			loaded: false,
			loading: true,
		},
		user_app_optimization: {
			data: {},
			loaded: false,
			loading: true,
		},
		user_app_status: {
			data: {},
			loaded: false,
			loading: true,
		},
	},
};

export function ApplicationsReducer(state = initialState, action) {
	var newState = { ...state };
	switch (action.type) {
		case ApplicationConstants.SET_SELECTED_TAB:
			newState.spends = {
				...newState.spends,
				selectedTab: action?.payload || "",
			};
			return newState;
		case ApplicationConstants.SET_DEFAULT_PAYMENT_METHOD:
			newState.spends.defaultPayment = action?.payload || "";
			return newState;
		case ApplicationConstants.FETCH_APPLICATION_SPENDS_OVERVIEW_DETAILS_REQUESTED:
			newState.spends.overviewDetails = {
				loading: true,
				loaded: false,
			};
			return newState;
		case ApplicationConstants.FETCHED_APPLICATION_SPENDS_OVERVIEW_DETAILS:
			newState.spends.overviewDetails = {
				loading: false,
				loaded: true,
				data: action.payload?.data,
				error: action?.payload?.error,
			};
			return newState;
		case ApplicationConstants.FETCH_APPLICATION_SPENDS_SUMMARY_REQUESTED:
			newState.spends.summary = {
				loading: true,
				loaded: false,
				data: {},
			};
			return newState;
		case ApplicationConstants.FETCHED_APPLICATION_SPENDS_SUMMARY:
			newState.spends.summary = {
				loading: false,
				loaded: true,
				data: action.payload.data || {},
				error: action.payload.error,
			};
			return newState;
		case ApplicationConstants.FETCH_APPLICATION_SPENDS_FINANCIAL_REQUESTED:
			newState.spends.financial = {
				loading: true,
				loaded: false,
				data: {},
				error: action?.payload?.error,
			};
			return newState;
		case ApplicationConstants.FETCHED_APPLICATION_SPENDS_FINANCIAL:
			newState.spends.financial = {
				loading: false,
				loaded: true,
				data: action.payload.data || {},
				error: action.payload.error,
			};
			return newState;
		case ApplicationConstants.FETCH_APPLICATION_SPENDS_PAYMENT_METHOD_REQUESTED:
			newState.spends.paymentMethod = {
				loading: true,
				loaded: false,
				data: {},
			};
			return newState;
		case ApplicationConstants.FETCHED_APPLICATION_SPENDS_PAYMENT_METHOD:
			newState.spends.paymentMethod = {
				loading: false,
				loaded: true,
				data: action.payload.data || {},
				error: action.payload?.error,
			};
			return newState;
		case ApplicationConstants.FETCH_APPLICATION_SPENDS_SETTING_REQUESTED:
			newState.spends.setting = {
				loading: true,
				loaded: false,
				data: action.payload.data || {},
				error: action.payload.error,
			};
			return newState;
		case ApplicationConstants.FETCHED_APPLICATION_SPENDS_SETTING:
			newState.spends.setting = {
				loading: false,
				loaded: true,
				data: action.payload.data || {},
				error: action.payload.error,
			};
			return newState;
		case ApplicationConstants.FETCH_ACCRUED_SPENDS_GRAPH_REQUESTED:
			newState.spends[ApplicationConstants.ACCRUED_SPENDS] = {
				loading: true,
				loaded: false,
				data: {},
			};
			return newState;
		case ApplicationConstants.FETCHED_ACCRUED_SPENDS_GRAPH:
			newState.spends[ApplicationConstants.ACCRUED_SPENDS] = {
				loading: false,
				loaded: true,
				data: action.payload.data || {},
				error: action.payload.error,
			};
			return newState;
		case ApplicationConstants.FETCH_ACTUAL_SPENDS_VS_COST_GRAPH_REQUESTED:
			newState.spends[ApplicationConstants.ACTUAL_SPENDS_VS_EST_COST] = {
				loading: true,
				loaded: false,
				data: {},
			};
			return newState;
		case ApplicationConstants.FETCHED_ACTUAL_SPENDS_VS_COST_GRAPH:
			newState.spends[ApplicationConstants.ACTUAL_SPENDS_VS_EST_COST] = {
				loading: false,
				loaded: true,
				data: action.payload.data || {},
				error: action.payload.error,
			};
			return newState;
		case ApplicationConstants.FETCH_AMMORTIZED_SPENDS_VS_COST_GRAPH_REQUESTED:
			newState.spends[
				ApplicationConstants.AMMORTIZED_SPENDS_VS_EST_COST
			] = {
				loading: true,
				loaded: false,
				data: {},
			};
			return newState;
		case ApplicationConstants.FETCHED_AMMORTIZED_SPENDS_VS_COST_GRAPH:
			newState.spends[
				ApplicationConstants.AMMORTIZED_SPENDS_VS_EST_COST
			] = {
				loading: false,
				loaded: true,
				data: action.payload.data || {},
				error: action.payload.error,
			};
			return newState;
		case ApplicationConstants.FETCH_SPENDS_TRANSACTION_VENDOR_REQUESTED:
			newState.spends.transactionTableVendor = {
				loading: true,
				loaded: false,
				data: {},
			};
			return newState;
		case ApplicationConstants.FETCHED_SPENDS_TRANSACTION_VENDOR:
			newState.spends.transactionTableVendor = {
				loading: false,
				loaded: true,
				data: action.payload.data || {},
				error: action.payload.error,
			};
			return newState;
		case ApplicationConstants.UPDATE_SPENDS_CHART_BUDGET:
			const actual_spend = ApplicationConstants.ACTUAL_SPENDS_VS_EST_COST;
			const accrued_spend = ApplicationConstants.ACCRUED_SPENDS;
			const ammortized_spend =
				ApplicationConstants.AMMORTIZED_SPENDS_VS_EST_COST;

			newState.spends = {
				...newState.spends,
				[accrued_spend]: {
					...newState.spends[accrued_spend],
					data: {
						...newState.spends[accrued_spend]?.data,
						...action?.payload,
					},
				},
				[actual_spend]: {
					...newState.spends[actual_spend],
					data: {
						...newState.spends[actual_spend]?.data,
						...action?.payload,
					},
				},
				[ammortized_spend]: {
					...newState.spends[ammortized_spend],
					data: {
						...newState.spends[ammortized_spend]?.data,
						...action?.payload,
					},
				},
			};
			return newState;

		case ApplicationConstants.FETCH_APP_ABOUT_SIMILAR_APPS_REQUESTED:
			newState.appAbout = {
				...newState.appAbout,
				appAboutSimilarApplication: {
					loading: true,
					loaded: false,
					data: {},
				},
			};
			return newState;
		case ApplicationConstants.FETCHED_APP_ABOUT_SIMILAR_APPS:
			newState.appAbout = {
				...newState?.appAbout,
				similarApplications: {
					loading: false,
					loaded: true,
					data: action.payload.data,
					error: action.payload.error,
				},
			};
			return newState;
		case ApplicationConstants.FETCH_ALTERNATE_APPS_REQUESTED:
			newState.appAbout = {
				...newState?.appAbout,
				alternateApplications: {
					loading: true,
					loaded: false,
					count: 0,
					data: {},
				},
			};
			return newState;
		case ApplicationConstants.FETCHED_ALTERNATE_APPS:
			newState.appAbout = {
				...state.appAbout,
				alternateApplications: {
					loaded: true,
					loading: false,
					error: action.payload.error,
					count: action.payload.count,
					alternate_apps: action?.payload?.alternate_apps,
				},
			};
			return newState;
		case ApplicationConstants.FETCH_ALTERNATE_APP_FEATURES_REQUESTED:
			newState.appAbout = {
				...newState?.appAbout,
				alternateAppFeatures: {
					...newState?.appAbout?.alternateAppFeatures,
					loading: true,
					count: 0,
				},
			};
			return newState;
		case ApplicationConstants.FETCHED_ALTERNATE_APP_FEATURES:
			newState.appAbout = {
				...newState?.appAbout,
				alternateAppFeatures: {
					...newState?.appAbout?.alternateAppFeatures,
					loading: false,
					loaded: true,
					count: action?.payload?.count,
					alternateApps: action?.payload?.features,
				},
			};
			return newState;

		case ApplicationConstants.FETCH_APP_OVERVIEW_SOURCES_REQUESTED:
			newState.overview = {
				...newState.overview,
				sources: {
					...newState.overview.sources,
					loaded: false,
					loading: true,
				},
			};
			return newState;
		case ApplicationConstants.FETCHED_APP_OVERVIEW_SOURCES:
			const source_data = getSourcesByType(action?.payload?.data) || [];
			const metaData = action?.payload?.data?.total || {};
			newState.overview = {
				...newState.overview,
				sources: {
					...newState.overview.sources,
					metaData,
					data: {
						...source_data,
					},
					loaded: true,
					loading: false,
					error: action.payload?.error,
				},
			};
			return newState;
		case ApplicationConstants.FETCH_APP_OVERVIEW_METRIC_REQUESTED:
			newState.overview = {
				...newState?.overview,
				metrics: {
					...newState?.overview.metrics,
					loading: true,
					loaded: false,
					error: {},
				},
			};
			return newState;
		case ApplicationConstants.FETCHED_APP_OVERVIEW_METRIC:
			newState.overview = {
				...newState.overview,
				metrics: {
					loaded: true,
					loading: false,
					data: { ...action.payload?.data },
					error: action.payload?.error,
				},
			};
			return newState;
		case ApplicationConstants.FETCH_APP_OVERVIEW_NOTES_REQUESTED:
			newState.overview = {
				...newState.overview,
				notes: {
					...newState.overview.notes,
					loading: true,
					error: {},
					data: [],
				},
			};
			return newState;
		case ApplicationConstants.FETCHED_APP_OVERVIEW_NOTES:
			newState.overview = {
				...newState.overview,
				notes: {
					loading: false,
					loaded: true,
					error: action?.payload?.error,
					data: action.payload?.data,
					count: action.payload?.data?.length || 0,
				},
			};
			return newState;
		case ApplicationConstants.UPDATE_NOTE_LIST:
			newState.overview = {
				...newState.overview,
				notes: {
					...newState.overview.notes,
					data: action.payload,
				},
			};
			return newState;
		case ApplicationConstants.ADD_OVERVIEW_NOTE:
			newState.overview = {
				...newState.overview,
				notes: {
					...newState.overview.notes,
					data: [...newState.overview.notes.data, action.payload],
					count: newState.overview.notes.count + 1,
				},
			};
			return newState;
		case ApplicationConstants.DELETE_OVERVIEW_NOTE:
			newState.overview = {
				...newState.overview,
				notes: {
					...newState.overview.notes,
					data: newState.overview.notes.data.filter(
						(note) => note?._id !== action.payload
					),
					count: newState.overview.notes.count - 1,
				},
			};
			return newState;
		case ApplicationConstants.UPDATE_OVERVIEW_NOTE: {
			newState.overview = {
				...newState.overview,
				notes: {
					...newState.overview.notes,
					data: newState.overview.notes.data.map((note) =>
						note?._id === action.payload?._id
							? action.payload
							: note
					),
				},
			};
			return newState;
		}

		case ApplicationConstants.FETCH_APP_OVERVIEW_LINKS_REQUESTED:
			newState.overview = {
				...newState.overview,
				links: {
					...newState.overview.links,
					loading: true,
				},
			};
			return newState;
		case ApplicationConstants.FETCHED_APP_OVERVIEW_LINKS:
			const links_data =
				action?.payload?.data?.map((d, idx) => ({
					link: d,
					id: idx,
				})) || [];
			newState.overview = {
				...newState.overview,
				links: {
					...newState.overview.links,
					data: [...links_data],
					count: links_data?.length || 0,
					loading: false,
				},
			};
			return newState;

		case ApplicationConstants.ADD_APP_OVERVIEW_LINK_REQUESTED:
			newState.overview = {
				...newState.overview,
				links: {
					...newState.overview.links,
					isAdding: true,
					loading: true,
				},
			};
			return newState;
		case ApplicationConstants.ADDED_APP_OVERVIEW_LINK:
			const added_links = [
				...newState.overview.links?.data,
				action?.payload,
			];
			newState.overview = {
				...newState.overview,
				links: {
					...newState.overview.links,
					data: added_links,
					loading: false,
				},
				count: added_links?.length || 0,
			};
			return newState;
		case ApplicationConstants.UPDATE_APP_OVERVIEW_LINK_REQUESTED:
			newState.overview = {
				...newState.overview,
				links: {
					...newState.overview.links,
					data: [
						...newState.overview.links.data?.map((link) => {
							if (link?.id === action?.payload?.id)
								return {
									...link,
									loading: true,
								};
							return link;
						}),
					],
					loaded: false,
					error: action?.payload?.error,
				},
			};
			return newState;
		case ApplicationConstants.UPDATED_APP_OVERVIEW_LINK:
			const links = newState.overview.links.data.map((li) => {
				if (li?.id === action.payload?.id) {
					return {
						...li,
						loading: false,
						link: action.payload?.link,
					};
				}
				return li;
			});
			newState.overview = {
				...newState.overview,
				links: {
					...newState.overview.links,
					data: [...links],
					loaded: true,
					error: action?.payload?.error,
				},
			};
			return newState;
		case ApplicationConstants.DELETE_APP_OVERVIEW_LINK_REQUESTED:
			newState.overview = {
				...newState.overview,
				links: {
					...newState.overview.links,
					data: [
						...newState.overview.links.data?.map((link) => {
							if (link?.id === action?.payload?.id)
								return { ...link, loading: true };
							return link;
						}),
					],
					error: action?.payload?.error,
				},
			};
			return newState;
		case ApplicationConstants.DELETED_APP_OVERVIEW_LINK:
			const updated_links = newState.overview.links.data.filter(
				(li) => li?.id !== action.payload?.id
			);
			newState.overview = {
				...newState.overview,
				links: {
					...newState.overview.links,
					data: [...updated_links],
					count: updated_links?.length || 0,
					loading: false,
					loaded: true,
					error: action?.payload?.error,
				},
			};
			return newState;
		case ApplicationConstants.SET_APP_ARCHIVE:
			newState.overview = {
				...newState.overview,
				archived: action?.payload,
			};
			return newState;
		case ApplicationConstants.FETCH_APP_OVERVIEW_CHECKLIST_REQUESTED:
			newState.overview = {
				...newState.overview,
				checklist: {
					...newState.overview.checklist,
					loading: true,
					loaded: false,
					error: {},
				},
			};
			return newState;
		case ApplicationConstants.FETCHED_APP_OVERVIEW_CHECKLIST:
			let pending_review = 0;
			delete action?.payload?.data?.["primary_source_settings"];
			const data = Object.assign({}, action?.payload?.data || {});
			Object.keys(data)?.map((key) => {
				data[key]?.map((d) => {
					if (
						["NOT COMPLETED", "ERROR"].includes(d?.status) &&
						!d?.isSkipped
					)
						pending_review++;
					return d;
				});
			});

			newState.overview = {
				...newState.overview,
				checklist: {
					...newState.overview.checklist,
					loading: false,
					loaded: true,
					data: action?.payload?.data,
					error: action?.payload.error,
					pending_review,
				},
			};
			return newState;

		case ApplicationConstants.SKIP_OVERVIEW_CHECKLIST_ITEM_REQUESTED:
			const loading_checklist = newState.overview.checklist?.data[
				action?.payload?.parent_id
			]?.map((check) => {
				if (check?._id === action?.payload?.item_id)
					return {
						...check,
						loading: true,
					};
				return check;
			});
			newState.overview = {
				...newState.overview,
				checklist: {
					...newState.overview.checklist,
					data: {
						...newState.overview.checklist?.data,
						[action?.payload?.parent_id]: [...loading_checklist],
					},
				},
			};

			return newState;
		case ApplicationConstants.SKIPPED_OVERVIEW_CHECKLIST_ITEM:
			const updatedChecklist = newState.overview.checklist?.data[
				action?.payload?.parent_id
			]?.map((check) => {
				if (check?._id === action?.payload?.item_id)
					return {
						...check,
						loading: false,
						isSkipped: action?.payload?.error
							? check?.isSkipped
							: action?.payload?.unSkip
								? false
								: true,
					};
				return check;
			});
			newState.overview = {
				...newState.overview,
				checklist: {
					...newState.overview.checklist,
					data: {
						...newState.overview.checklist?.data,
						[action?.payload?.parent_id]: [...updatedChecklist],
					},
					pending_review: action?.payload?.error
						? newState.overview.checklist?.pending_review
						: newState.overview.checklist?.pending_review > 0 &&
							--newState.overview.checklist.pending_review,
				},
			};
			return newState;

		case ApplicationConstants.FETCH_APP_USER_LICENSES_REQUESTED:
			newState.overview = {
				...newState.overview,
				user_app_license: {
					...newState.overview.user_app_license,
					loading: true,
					loaded: false,
					error: null,
				},
			};
			return newState;
		case ApplicationConstants.FETCHED_APP_USER_LICENSES:
			newState.overview = {
				...newState.overview,
				user_app_license: {
					...newState.overview.user_app_license,
					loading: false,
					loaded: true,
					data: action?.payload?.data,
					error: action?.payload?.error,
				},
			};
			return newState;

		case ApplicationConstants.FETCH_APP_USER_OPTIMIZATION_REQUESTED:
			newState.overview = {
				...newState.overview,
				user_app_optimization: {
					...newState.overview.user_app_optimization,
					loading: true,
					loaded: false,
					error: null,
				},
			};
			return newState;
		case ApplicationConstants.FETCHED_APP_USER_OPTIMIZATION:
			newState.overview = {
				...newState.overview,
				user_app_optimization: {
					...newState.overview.user_app_optimization,
					loading: false,
					loaded: true,
					data: action?.payload?.data,
					error: action?.payload?.error,
				},
			};
			return newState;

		case ApplicationConstants.FETCH_APP_USER_STATUS_REQUESTED:
			newState.overview = {
				...newState.overview,
				user_app_status: {
					...newState.overview.user_app_status,
					loading: true,
					loaded: false,
					error: null,
				},
			};
			return newState;
		case ApplicationConstants.FETCHED_APP_USER_STATUS:
			newState.overview = {
				...newState.overview,
				user_app_status: {
					...newState.overview.user_app_status,
					loading: false,
					loaded: true,
					data: action?.payload?.data,
					error: action?.payload?.error,
				},
			};
			return newState;

		default:
			return state;
	}
}
