import { Typography } from "@zluri/ui-components";
import React, { useId } from "react";

export default function MultiSelectFilter({ onChange, value, filter }) {
	const id = useId();
	return (
		<div className="d-flex flex-column">
			{filter?.field_labels?.map((label, idx) => {
				const isChecked =
					value?.includes(filter.field_values[idx]) ?? false;
				return (
					<div
						className="d-flex align-items-center mb-3 gap-2"
						key={label}
					>
						<input
							className="cursor-pointer"
							style={{ height: 14 }}
							type="checkbox"
							name={label}
							id={id + label}
							checked={isChecked}
							value={isChecked}
							onClick={(e) => {
								let updatedValue = [];
								if (e.target.checked) {
									updatedValue = Array.isArray(value)
										? [...value, filter.field_values[idx]]
										: [filter.field_values[idx]];
								} else {
									updatedValue = Array.isArray(value)
										? value.filter(
												(i) =>
													filter.field_values[idx] !==
													i
											)
										: filter.field_values.filter(
												(i) =>
													filter.field_values[idx] !==
													i
											);
								}
								onChange(updatedValue);
							}}
						/>
						<label
							style={{
								fontFamily: "Sora",
								fontStyle: "normal",
								fontWeight: 600,
								fontSize: 10,
								lineHeight: "150%",
								marginBottom: 0,
								marginLeft: 9,
								textAlign: "center",
								color: "#484848",
							}}
							htmlFor={id + label}
						>
							<Typography
								variant="button_extrasmall_semibold"
								color="secondary_grey_2"
							>
								{label}
							</Typography>
						</label>
					</div>
				);
			})}
		</div>
	);
}
