import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { Typography, Tooltip, BODY_VARIANT } from "@zluri/ui-components";
import "./AppliedFilters.css";
import Filter from "containers/v2table/FilterList/Filter";
import { formatDate } from "modules/AccessReview/util/util";
import FilterPillButton from "../FilterPillButton";
import { useFilterState } from "../FilterList/hooks";
import PropTypes from "prop-types";
import { useTableContext } from "../TableContext/context";

export default function AppliedFilters({
	entity,
	appliedFilters,
	subEntityData,
	apiProps,
	intID,
}) {
	const { isAnyDataSelected, enableBulkEdit } = useTableContext();
	const dispatch = useDispatch();
	const handleRemove = useCallback(
		(filter) => {
			//if filter isn't available then remove all filter
			const updatedFilters = filter
				? appliedFilters.filter(
						(appliedFilter) =>
							appliedFilter.field_name !== filter.field_name
					)
				: [];
			dispatch({
				type: "GET_TABLE_DATA",
				payload: {
					shouldRefresh: true,
					filterBy: updatedFilters,
					entity,
					subEntityData,
					apiProps,
					intID,
				},
			});
		},
		[appliedFilters, dispatch, entity, subEntityData, apiProps, intID]
	);
	if (isAnyDataSelected && enableBulkEdit) return null;
	return (
		<div className="applied__filters__row">
			<div className="d-flex flex-1 gap-8 align-items-center">
				{appliedFilters
					?.filter((row) => !row.hideFilterOnAppliedFiltersSection)
					?.map((filter, idx) => {
						const displayText = generateFilterInfoText(filter);
						return (
							<AppliedFilterPill
								key={idx + filter.field_name}
								fieldName={filter.field_name}
								displayText={displayText}
							/>
						);
					})}
				<div
					style={{
						width: 1,
						height: 16,
						backgroundColor: "#DDDDDD",
					}}
				/>
				<Filter>
					<button className="z_filter_pill_action">
						<Typography
							variant="button_extrasmall_regular"
							color={"secondary_grey_3"}
							className="text-nowrap "
							style={{ lineHeight: "100%" }}
						>
							+ Add filter
						</Typography>
					</button>
				</Filter>
				<div style={{ flex: 1 }} />
				<button
					className=" z_filter_pill_action"
					onClick={() => handleRemove()}
				>
					<Typography
						variant="button_extrasmall_regular"
						color={"secondary_grey_3"}
						className="text-nowrap"
					>
						Clear Filter
					</Typography>
				</button>
			</div>
		</div>
	);
}
AppliedFilters.propTypes = {
	entity: PropTypes.string,
	appliedFilters: PropTypes.array,
	subEntityData: PropTypes.object,
	apiProps: PropTypes.any,
	intID: PropTypes.number,
};
function AppliedFilterPill({ displayText, fieldName }) {
	const { onClearAllFilter } = useFilterState({
		enabledFilters: [fieldName],
	});
	return (
		<Filter enabledFilters={[fieldName]}>
			<div>
				<Tooltip
					content={
						!!displayText ? (
							<Typography
								variant={BODY_VARIANT.BODY_2_REGULAR}
								color="white"
								className="text-nowrap"
							>
								{displayText}
							</Typography>
						) : null
					}
					placement="bottom"
					className="border-radius-8 z__applied_filter-tooltip"
				>
					<div>
						<FilterPillButton
							size="small"
							text={fieldName}
							isActive
							onRemove={(e) => {
								e.stopPropagation();
								onClearAllFilter();
							}}
						/>
					</div>
				</Tooltip>
			</div>
		</Filter>
	);
}

AppliedFilterPill.propTypes = {
	displayText: PropTypes.string,
	fieldName: PropTypes.string.isRequired,
};

// There are six types of field_type as of now => string| range| single_select | multi_select | dynamic_select -> this determines the type of filter UI rendered
function generateFilterInfoText(filter) {
	if (!filter) return "";

	const _mapper = {
		contains: "contains",
		n_contains: "doesn't contain",
		gt: ">",
		lt: "<",
		eq: "=",
		lte: "≤",
		gte: "≥",
	};

	const name = filter.field_name;

	//  Why two different things filterType vs fieldType.. I don't know
	const filterType = filter.filter_type;
	const fieldType = filter.field_type;

	const fieldOrder = Array.isArray(filter?.field_order)
		? filter?.field_order?.[0]
		: filter?.field_order;
	const value = filter?.field_values?.[0];

	//TODO: fieldType "select"=> should be removed | currently this is coming from views
	if (fieldType === "single_select" || fieldType === "select") {
		if (filterType === "string" || filterType === "search_in_string") {
			return `"${name}" ${_mapper[fieldOrder] ?? "="} "${value}"`;
		}
		if (filterType === "boolean") {
			return `"${name}" = "${filter?.field_values}"`;
		}
	}

	if (fieldType === "string") {
		if (filterType === "string" || filterType === "search_in_string") {
			return `"${name}" ${_mapper[fieldOrder] ?? "="} "${value}"`;
		}
	}

	// ≠
	if (fieldType === "range") {
		if (filterType === "range") {
			// if this is range then for now field_order contains ["gte","lte"]
			const isRange = filter?.field_order?.length === 2;
			if (isRange)
				return ` "${filter?.field_values?.[0]}" ≤ "${name}" ≤  "${filter?.field_values?.[1]}"`;

			return `"${name}"  ${_mapper[fieldOrder]} "${value}"`;
		}
	}

	if (fieldType === "date_range") {
		// if this is range then for now field_order contains ["gte","lte"]
		const isRange = filter?.field_order?.length === 2;
		const date1 = formatDate(filter?.field_values?.[0]);
		const date2 = formatDate(filter?.field_values?.[1]);
		if (isRange) return `"${date1}" ≤ "${name}" ≤ "${date2}"`;
		return `"${name}"  ${_mapper[fieldOrder]} "${formatDate(value)}"`;
	}

	if (fieldType === "multi_select") {
		const totalData = filter?.field_values?.length ?? 0;
		if (filterType === "string") {
			return `"${name}" is "${filter?.field_values?.[0]}" ${
				totalData > 1 ? `+ ${totalData - 1}` : ""
			}`;
		}
	}
	if (fieldType === "dynamic_filter") {
		const totalData = filter?.dynamicFilterData?.length ?? 0;
		return `"${name}" is "${filter?.dynamicFilterData?.[0]?.label}" ${
			totalData > 1 ? `+ ${totalData - 1}` : ""
		}`;
	}
	if (fieldType === "multi_source") {
		const totalData = filter?.field_values?.length ?? 0;
		return `"${name}" is "${filter?.field_values?.[0]?.renderValue?.[0]}" ${
			totalData > 1 ? `+ ${totalData - 1}` : ""
		}`;
	}
	if (filterType === "string") {
		const totalData = filter?.field_values?.length ?? 0;
		if (filterType === "string") {
			return `"${name}" ${_mapper[fieldOrder] ?? "is"} "${value}" ${
				totalData > 1 ? `+ ${totalData - 1}` : ""
			}`;
		}
	}
}
