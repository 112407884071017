import { Typography } from "@zluri/ui-components";
import React from "react";
import refresh from "assets/icons/refresh.svg";
import upload from "assets/upload.svg";
import "../components/AppLicenses/AppLicensesChart/AppLicensesChart.css";
import { kFormatter } from "constants/currency";
import { chartColors } from "../constants/AppLicensesConstants";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { TriggerIssue } from "utils/sentry";

export const SHOW_MORE_OPTIONS = [
	{
		id: "REFRESH_CHART",
		icon: <img src={refresh} alt="" />,
		label: (
			<Typography variant="button_extrasmall_medium">Refresh</Typography>
		),
		filter: "",
		text: "Refresh",
	},
	{
		id: "DOWNLOAD_AS",
		icon: <img src={upload} alt="" />,
		label: (
			<Typography variant="button_extrasmall_medium">
				Download As
			</Typography>
		),
		filter: "",
		text: "Download As",
		children: [
			{
				id: "DOWNLOAD_AS_PNG",
				key: 1 - 1,
				label: (
					<Typography variant="button_extrasmall_medium">
						Download As PNG
					</Typography>
				),
			},
			{
				id: "DOWNLOAD_AS_PDF",
				key: 1 - 2,
				label: (
					<Typography variant="button_extrasmall_medium">
						Download As PDF
					</Typography>
				),
			},
			{
				id: "DOWNLOAD_AS_CSV",
				key: 1 - 3,
				label: (
					<Typography variant="button_extrasmall_medium">
						Download As CSV
					</Typography>
				),
			},
		],
	},
];

export const appLicensesCurrencyFormat = (
	value,
	gap,
	decimalsAfterDigit,
	commaSeparated
) => {
	return kFormatter(
		Number(value),
		null,
		true,
		decimalsAfterDigit,
		gap,
		commaSeparated
	);
};

export const prepareChartData = (graphData) => {
	const xAxisData = graphData?.map((data) => data?.dept_name);
	const licenseName = graphData
		?.flatMap((item) => Object.keys(item?.graph_data))
		?.filter((value, index, license) => license?.indexOf(value) === index);
	const seriesData = licenseName?.map((license, index) => {
		return {
			name: license,
			data: graphData?.map(function (item) {
				return {
					name: license,
					y: item?.graph_data[license] || 0,
					color: chartColors.colors[
						index % chartColors.colors.length
					],
				};
			}),
		};
	});
	return {
		xAxisData,
		seriesData,
	};
};

export const handleShowMoreOption = (
	id,
	element,
	fileName,
	canvasConfiguarions,
	dispatchFunc,
	csvDataFunc
) => {
	switch (id) {
		case "REFRESH_CHART":
			return dispatchFunc;
		case "DOWNLOAD_AS_PNG":
			return downloadPNG(element, fileName);
		case "DOWNLOAD_AS_PDF":
			return downloadPDF(element, fileName, canvasConfiguarions);
		case "DOWNLOAD_AS_CSV":
			return downloadCSV(csvDataFunc, fileName);
		default:
			return null;
	}
};

export const downloadCSV = (preparedDataForCsv, fileName) => {
	const csvData = preparedDataForCsv;

	const headers = Object?.keys(csvData[0]);

	const csv = [
		headers,
		...csvData?.map((obj) =>
			headers.map((header) => {
				const value = obj[header];
				return value;
			})
		),
	];
	try {
		let csvContent = "data:text/csv;charset=utf-8,";
		csv.forEach((row) => {
			const csvRow = row?.join(",");
			csvContent += csvRow + "\r\n";
		});

		const encodedUri = encodeURI(csvContent);
		const link = document.createElement("a");
		link.setAttribute("href", encodedUri);
		link.setAttribute(
			"download",
			fileName ? `${fileName}.csv` : "App Licenses.csv"
		);
		document.body.appendChild(link);

		link.click();
		document.body.removeChild(link);
	} catch (error) {
		TriggerIssue("Error occurred during CSV download", error);
	}
};

export const downloadPNG = async (elementId, fileName) => {
	const domElememnt = document.getElementById(elementId);
	return html2canvas(domElememnt)
		.then((canvas) => {
			const imgData = canvas.toDataURL("image/png");
			const link = document.createElement("a");
			link.href = imgData;
			link.download = `${fileName}.png`;
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		})
		.catch((error) => TriggerIssue("Download License chart Failed", error));
};

export const downloadPDF = async (
	elementId,
	fileName,
	canvasConfiguarions = {
		orientation: "l",
		units: "mm",
		format: "a3",
		xDistance: 0,
		yDistance: 0,
		xWidth: 0,
		yWidth: 0,
	}
) => {
	const {
		orientation,
		units,
		format,
		xDistance = 10,
		yDistance = 50,
		xWidth = 400,
		yWidth = 180,
	} = canvasConfiguarions;
	const domElememnt = document.getElementById(elementId);
	return html2canvas(domElememnt)
		.then((canvas) => {
			const imgData = canvas.toDataURL("image/png");
			const pdf = new jsPDF(orientation, units, format);
			pdf.addImage(imgData, "JPEG", xDistance, yDistance, xWidth, yWidth);
			pdf.save(`${fileName}.pdf`);
		})
		.catch((error) =>
			TriggerIssue("Download License chart PDF Failed", error)
		);
};

export const getYAxisOffset = (optimizableYMax, costIncurredYMax) => {
	const licensesChart = optimizableYMax ?? 0;
	const licensesChartLength = licensesChart.toString().length;
	const costChartYMax = costIncurredYMax ?? 0;
	const costChartYMaxLength = costChartYMax.toString().length;

	const difference = licensesChartLength - costChartYMaxLength;
	const offset = {
		licensesChart: 0,
		costChart: 0,
	};
	if (difference < 0) {
		offset.licensesChart = Math.abs(difference * 4);
	} else if (difference > 0) {
		offset.costChart = Math.abs(difference * 4);
	}

	return offset;
};
