import React, { useId, useState } from "react";

import PropTypes from "prop-types";
import { isArray, isEmpty } from "underscore";

import { Typography } from "@zluri/ui-components";

import icon_greater_than from "assets/Icon_Numeric_Greaterthan.svg";
import less_than from "assets/icons/less-than.svg";
import equal_to from "assets/icons/equal-to.svg";
import range from "assets/icons/range.svg";

const filterListProps = [
	{
		label: "Greater than",
		inputType: "number",
		icon: icon_greater_than,
		api_values: {
			field_order: ["gt"],
			negative: false,
		},
	},
	{
		label: "Lesser than",
		inputType: "number",
		icon: less_than,
		api_values: {
			field_order: ["lt"],
			negative: false,
		},
	},
	{
		label: "Equal to",
		inputType: "number",
		icon: equal_to,
		api_values: {
			field_order: ["eq"],
			negative: false,
		},
	},
	{
		label: "Range",
		inputType: "range",
		icon: range,
		api_values: {
			field_order: ["gte", "lte"],
			negative: false,
		},
	},
];
// field_order helps identify whether the selected value is greaterThan,lessThan,equalTo or a range
export default function NumberRangeFilter({
	onChange,
	value,
	filterData,
	className,
}) {
	const handleChange = (val, id) => {
		if (filterListProps[id].inputType === "range") {
			const range_values = isArray(val)
				? val?.map((_v) => (isEmpty(_v) ? _v : Number(_v)))
				: [];
			onChange(range_values, filterListProps[id].api_values);
		} else
			onChange(
				[isEmpty(val) ? val : Number(val)],
				filterListProps[id].api_values
			);
	};

	return (
		<NumberCondtionalSelect
			className={className}
			onChange={handleChange}
			defaultValue={
				filterData?.field_type === "range" ? value : value?.[0]
			}
			filterData={filterData}
		/>
	);
}
NumberRangeFilter.propTypes = {
	onChange: PropTypes.func,
	value: PropTypes.string,
	filterData: PropTypes.shape({
		field_type: PropTypes.string,
	}),
	className: PropTypes.string,
};

export function NumberCondtionalSelect({
	onChange,
	defaultValue = "",
	filterData,
	className,
}) {
	const [selected, setSelected] = useState(() =>
		filterListProps.findIndex(
			(item) =>
				item.api_values.field_order?.toString() ===
				filterData?.field_order?.toString()
		)
	);
	const [singleValue, setSingleValue] = useState(defaultValue);
	const [rangeValue1, setRangeValue1] = useState(defaultValue?.[0]);
	const [rangeValue2, setRangeValue2] = useState(defaultValue?.[1]);

	const id = useId();
	return (
		<div
			className={`d-flex flex-column ${className} px-2 z-conditional-select`}
		>
			{filterListProps.map((filter, idx) => {
				const isSelected = idx === selected;

				return (
					<div key={filter.label} className="d-flex flex-column">
						<div className="d-flex text-left align-items-center py-2 gap-8">
							<input
								className="cursor-pointer v2-text"
								style={{ height: 14 }}
								type="radio"
								name={filter?.label}
								id={filter?.label + id}
								label={filter?.label}
								checked={isSelected}
								onClick={() => {
									setSelected(idx);
								}}
							/>
							<label
								style={{
									fontFamily: "Sora",
									fontStyle: "normal",
									fontWeight: 600,
									fontSize: 10,
									lineHeight: "150%",
									marginBottom: 0,
									textAlign: "center",
									color: "#484848",
								}}
								htmlFor={filter?.label + id}
							>
								<>
									<img
										src={filter?.icon}
										width={16}
										className="mr-1"
										alt=""
									/>

									<Typography
										variant={"button_extrasmall_semibold"}
										color="secondary_grey_2"
									>
										{filter?.label}
									</Typography>
								</>
							</label>
						</div>
						{isSelected &&
							(filter.inputType !== "range" ? (
								<div className="">
									<input
										className="grey-1 px-2 py-3 range__filter-input border-radius-8"
										style={{
											border: "1px solid #DDDDDD",
											borderRadius: "8px !important",
										}}
										type="number"
										placeholder={
											filter?.inputType ?? "Text"
										}
										value={
											filterData?.field_order?.toString() ===
											filter?.api_values?.field_order?.toString()
												? singleValue
												: ""
										}
										onChange={(e) => {
											setSingleValue(e.target.value);
											onChange(e.target.value, idx);
										}}
									/>
								</div>
							) : (
								<div className="d-flex align-items-center">
									<input
										className="grey-1 px-2 py-3 mr-1 range__filter-input border-radius-8 "
										style={{
											border: "1px solid #DDDDDD",
										}}
										type="number"
										placeholder={
											filter?.inputType ?? "Number"
										}
										value={rangeValue1}
										onChange={(e) => {
											setRangeValue1(e.target.value);
											onChange(
												[e.target.value, rangeValue2],
												idx
											);
										}}
									/>
									-
									<input
										className="grey-1 px-2 py-3 w-100 flex-1 ml-1 range__filter-input border-radius-8 "
										style={{
											border: "1px solid #DDDDDD",
											borderRadius: "8px !important",
										}}
										type={"number"}
										placeholder={
											filter?.inputType ?? "Number"
										}
										value={rangeValue2}
										onChange={(e) => {
											setRangeValue2(e.target.value);
											onChange(
												[rangeValue1, e.target.value],
												idx
											);
										}}
									/>
								</div>
							))}
					</div>
				);
			})}
		</div>
	);
}
NumberCondtionalSelect.propTypes = {
	onChange: PropTypes.func,
	defaultValue: PropTypes.string,
	filterData: PropTypes.shape({
		field_order: PropTypes.string,
	}),
	className: PropTypes.string,
};
