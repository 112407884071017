import { DraggableAttributes } from "@dnd-kit/core";
import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import React, { createContext, useContext } from "react";
interface IContext {
	listeners?: SyntheticListenerMap;
	attributes: DraggableAttributes;
	ref: (element: HTMLElement | null) => void;
}
const SortableItemContext = createContext<Partial<IContext>>({
	attributes: undefined,
	listeners: undefined,
	ref() {},
});

export function useSortableItemContext() {
	const context = useContext(SortableItemContext);

	if (!context)
		throw new Error(
			"useSortableContext should be used inside SortableItemContextWrapper"
		);

	return {
		listeners: context.listeners ?? {},
		attributes: context.attributes ?? {},
		ref: context.ref,
	};
}

function SortableItemContextWrapper({
	context,
	children,
}: {
	context: IContext;
	children: React.ReactNode;
}) {
	return (
		<SortableItemContext.Provider value={context}>
			{children}
		</SortableItemContext.Provider>
	);
}

export default SortableItemContextWrapper;
