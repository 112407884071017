import React, { useEffect, useState } from "react";
import "./NoteCard.css";

import PropTypes from "prop-types";
import moment from "moment";

import { Divider, Popover } from "@zluri/ui-components";
import { NoteColor } from "./NoteColor";
import {
	NoteReminder,
	ReminderRemoveConfirmation,
	ReminderUpdate,
} from "./Reminder";

import colorPickerIcon from "assets/Icon_ColoPicker.svg";

import { NOTE_COLORS } from "modules/applications/constants/OverviewV2.constants";

export const NoteFooter = ({
	noteColor,
	setNoteColor,
	reminderDateTime,
	setReminderDateTime,
	onAddEditReminder,
	onDeleteReminder,
	hideReminder,
	updatingReminder,
	removingReminder,
	tooManyRequests,
	setTooManyRequests,
}) => {
	const [showReminderPopover, setShowReminderPopover] = useState(false);
	const [showConfirmRemove, setShowConfirmRemove] = useState(false);
	const [date, setDate] = useState();
	const [time, setTime] = useState();
	const [invalidDateTime, setInvalidDateTime] = useState(false);

	const resetInitialDateTime = () => {
		if (reminderDateTime) {
			const reminderDate = moment(reminderDateTime);
			setDate(reminderDate);
			setTime({ selectedValue: reminderDate.format("hh:mm A") });
		}
	};

	useEffect(() => resetInitialDateTime(), [reminderDateTime]);

	const handleSaveClick = () => {
		const now = moment();
		const reminderDate = moment(reminderDateTime);
		const combinedDateTime = date
			.set({
				hour: parseInt(time?.hh || reminderDate.format("HH")),
				minute: parseInt(time?.mm || reminderDate.format("mm")),
			})
			.format("YYYY-MM-DD HH:mm:ss");

		const timestamp = moment(combinedDateTime);
		if (timestamp.isAfter(now)) {
			onAddEditReminder(timestamp, {
				onSuccess: () => {
					setReminderDateTime(
						moment(combinedDateTime).format("D MMM YY, HH:mm")
					);
					setTooManyRequests(false);
					setInvalidDateTime(false);
					setShowReminderPopover(false);
				},
			});
		} else {
			setShowReminderPopover(false);
			setInvalidDateTime(true);
			resetInitialDateTime();
		}
	};

	const handleResetDateTime = () => {
		setShowReminderPopover(false);
		resetInitialDateTime();
	};

	const handleOnSuccess = () => {
		setReminderDateTime(null);
		setDate(null);
		setTime(null);
		setShowReminderPopover(false);
		setShowConfirmRemove(false);
		setTooManyRequests(false);
		setInvalidDateTime(false);
	};

	const handleDeleteClick = () => {
		if (showConfirmRemove)
			onDeleteReminder({
				onSuccess: handleOnSuccess,
			});
		else setShowConfirmRemove(true);
	};

	const handleNoteColorChange = (color) => {
		setNoteColor(color);
	};

	const handleDateChange = (date) => {
		setDate(date);
		setTooManyRequests(false);
		setInvalidDateTime(false);
	};

	const handleTimeChange = (time) => {
		setTime(time);
		setTooManyRequests(false);
		setInvalidDateTime(false);
	};

	return (
		<div className="ml-1 d-flex align-items-center gap-6">
			<Popover
				placement="top"
				content={
					<div
						className="d-flex align-items-center justify-content-between"
						style={{
							padding: "8px 10px",
						}}
					>
						{NOTE_COLORS.map((color) => (
							<NoteColor
								key={color.HEX}
								color={color.HEX}
								hoverColor={color.HOVER_HEX}
								selectedColor={noteColor}
								onClick={handleNoteColorChange}
							/>
						))}
					</div>
				}
			>
				<img
					className="cursor-pointer"
					src={colorPickerIcon}
					height="14px"
					width="14px"
					alt="color picker"
					style={{ transform: "translateY(1px)" }}
				/>
			</Popover>
			{!hideReminder && (
				<>
					<Divider
						width="1px"
						height="12px"
						backgroundColor="#DDD"
						borderRadius={4}
					/>
					<Popover
						show={showReminderPopover}
						placement="top"
						content={
							showConfirmRemove ? (
								<ReminderRemoveConfirmation
									removingReminder={removingReminder}
									onRemove={handleDeleteClick}
									onCancel={() => setShowConfirmRemove(false)}
								/>
							) : (
								<ReminderUpdate
									date={date}
									onDateChange={handleDateChange}
									time={time?.selectedValue}
									onTimeChange={handleTimeChange}
									updatingReminder={updatingReminder}
									reminderDateTime={reminderDateTime}
									onDelete={handleDeleteClick}
									onCancel={handleResetDateTime}
									onSave={handleSaveClick}
								/>
							)
						}
					>
						<NoteReminder
							invalidDateTime={invalidDateTime}
							tooManyRequests={tooManyRequests}
							showReminderPopover={showReminderPopover}
							reminderDateTime={reminderDateTime}
							onReminderClick={() => setShowReminderPopover(true)}
						/>
					</Popover>
				</>
			)}
		</div>
	);
};

NoteFooter.propTypes = {
	noteColor: PropTypes.string,
	setNoteColor: PropTypes.func,
	reminderDateTime: PropTypes.string,
	setReminderDateTime: PropTypes.func,
	onAddEditReminder: PropTypes.func,
	onDeleteReminder: PropTypes.func,
	hideReminder: PropTypes.bool,
	updatingReminder: PropTypes.bool,
	removingReminder: PropTypes.bool,
	tooManyRequests: PropTypes.bool,
	setTooManyRequests: PropTypes.func,
};
