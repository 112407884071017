import React from "react";
import PropTypes from "prop-types";
import {
	BUTTON_VARIANT,
	Button,
	Dropdown,
	GREY_VARIANT,
	Typography,
	theme,
} from "@zluri/ui-components";
import Ellipse from "modules/applications/components/chargebacks/assets/Ellipse.png";

export function LegendDropdown({ legends = [] }) {
	return (
		<Dropdown
			arrow
			autoAdjustOverflow
			menu={{
				items:
					!Array.isArray(legends) || !legends?.length
						? ["No Data"]
						: [
								{
									key: 0,
									label: (
										<div>
											<ul className="chargeback-legend">
												{legends?.map(
													(legend, index) => (
														<li
															key={index}
															className="chargeback-legend-list"
														>
															<div className="chargeback-headtitle">
																<div
																	className="chargeback-legend-ul-li-sub chargeback-blue-legend-li"
																	style={{
																		border: `1px solid ${legend.color}`,
																	}}
																>
																	<div
																		className="chargeback-legend-radio"
																		style={{
																			backgroundColor:
																				legend.color,
																		}}
																	></div>
																</div>
																<Typography
																	variant={
																		BUTTON_VARIANT.BUTTON_EXTRA_SMALL_MEDIUM
																	}
																	color={
																		GREY_VARIANT.SECONDARY_GREY_2
																	}
																>
																	{
																		legend.name
																	}
																</Typography>
															</div>
														</li>
													)
												)}
											</ul>
										</div>
									),
								},
							],
				onClick: function noRefCheck() {},
				selectable: true,
			}}
			placement="topRight"
			trigger={["click"]}
			overlayClassName="chargeback-legend-overlay"
		>
			<Button
				className="border-0"
				variant="secondary-default"
				theme={theme}
				startIcon={Ellipse}
			>
				Legend
			</Button>
		</Dropdown>
	);
}
LegendDropdown.propTypes = {
	legends: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string.isRequired,
			color: PropTypes.string.isRequired,
		})
	).isRequired,
};
