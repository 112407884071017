import { EntityCustomFieldData } from "modules/custom-fields/model/model";

export class SingleApplicationBasicDetails {
	constructor(obj) {
		// Basic Details
		this.app_id = obj.app_id || obj._id || null;
		this.app_name = obj.app_name || null;
		this.app_logo = obj.app_logo || null;
		this.app_auth_status =
			(obj.app_state ?? obj.app_auth_status) || "needs review";
		this.app_status = obj.app_status || "inactive";
		this.app_short_description =
			(obj.app_small_description ?? obj.app_short_description) || null;
		this.app_description = obj.app_description || null;
		this.app_is_custom = obj.app_is_custom || null;
		this.app_link = obj.app_link || null;
		this.app_links = obj.app_links || null;
		this.app_archive = obj.app_archive || false;
		this.app_integration_id = obj.app_integration_id || null;
		// Owner Fields
		this.app_owner_id = obj.app_owner_id || null;
		this.app_owner_name = obj.app_owner_name || null;
		this.app_owner_email = obj.app_owner_email || null;
		this.app_owner_profile = obj.app_owner_profile || null;
		this.app_owner_account_type = obj.app_owner_account_type || null;
		// IT Owner Fields
		this.app_technical_owner_id = obj.app_technical_owner_id || null;
		this.app_technical_owner_name = obj.app_technical_owner_name || null;
		this.app_technical_owner_email = obj.app_technical_owner_email || null;
		this.app_technical_owner_profile =
			obj.app_technical_owner_profile || null;
		this.app_technical_owner_account_type =
			obj.app_technical_owner_account_type || null;
		// Finance Owner Fields
		this.app_financial_owner_id = obj.app_financial_owner_id || null;
		this.app_financial_owner_name = obj.app_financial_owner_name || null;
		this.app_financial_owner_email = obj.app_financial_owner_email || null;
		this.app_financial_owner_profile =
			obj.app_financial_owner_profile || null;
		this.app_financial_owner_account_type =
			obj.app_financial_owner_account_type || null;
		this.app_financial_owner_status =
			obj.app_financial_owner_status || null;
		// Left Pane Top Fields Below Owners
		this.app_categories = Array.isArray(obj.app_categories)
			? obj.app_categories
			: [];
		this.app_sub_categories = Array.isArray(obj.app_sub_categories)
			? obj.app_sub_categories
			: [];
		this.app_active_contracts_count = isNaN(obj.app_active_contracts_count)
			? 0
			: obj.app_active_contracts_count;
		this.app_contracts_count = isNaN(obj.app_contracts_count)
			? 0
			: obj.app_contracts_count;
		this.app_type = obj.app_type || "application";
		this.app_budget = obj.app_budget;
		this.app_usage = isNaN(obj.app_usage) ? 0 : obj.app_usage;
		// Right Pane Top Card Fields
		this.app_active_users_via_direct_integration = isNaN(
			obj.app_active_users_via_direct_integration
		)
			? 0
			: obj.app_active_users_via_direct_integration;
		this.active_app_user_count_via_all_sources = isNaN(
			obj.active_app_user_count ??
				obj.active_app_user_count_via_all_sources
		)
			? 0
			: obj.active_app_user_count ??
				obj.active_app_user_count_via_all_sources;
		this.app_assigned_license_count = isNaN(obj.app_assigned_license_count)
			? 0
			: obj.app_assigned_license_count;
		this.app_active_contracts = Array.isArray(obj.app_active_contracts)
			? obj.app_active_contracts
			: [];
		this.app_spend = obj.app_spend || 0;
		this.app_spend_prev_fy = obj.app_spend_prev_fy || 0;
		this.app_cost = obj.app_cost || 0;
		this.app_annualized_cost = isNaN(
			obj.app_cost_change ?? obj.app_annualized_cost
		)
			? 0
			: (obj.app_cost_change ?? obj.app_annualized_cost) * 12;
		// Custom Field Data
		this.custom_fields = Array.isArray(obj.custom_fields)
			? obj.custom_fields
					.filter((cf) => cf.field_id)
					.map((cf) => new EntityCustomFieldData(cf))
			: [];
		// App Source Array
		this.app_source_array = Array.isArray(
			obj.source_array ?? obj.app_source_array
		)
			? obj.source_array ?? obj.app_source_array
			: [];
		// App Tags
		this.app_tags = Array.isArray(obj.app_tags) ? obj.app_tags : [];
		// App Split Departments Details
		this.app_spend_split = obj.app_spend_split || "default";
		this.app_monthly_spend = obj.app_monthly_spend || 0;
		this.app_previous_year_dept_spend =
			obj.app_previous_year_dept_spend || 0;
		// Optimization Metrics
		this.app_active_contracts_count = Array.isArray(
			obj.app_active_contracts ?? obj.app_active_contracts_count
		)
			? obj.app_active_contracts.length ?? obj.app_active_contracts_count
			: 0;
		this.app_user_with_license_count = obj.app_user_with_license_count || 0;
		this.app_active_licenses_count = obj.app_active_licenses_count || 0;
		this.source_direct_integration = obj?.source_direct_integration;
		this.license_utilization = obj?.license_utilization || {};
	}
}
