import { combineReducers } from "redux";
import { transactionReducer } from "./transaction.reducer";
import { departmentReducer } from "./department.reducer";
import { uiReducer } from "./ui.reducer";
import {
	applicationActionHistoryReducer,
	applicationReducer,
} from "./application.reducer";
import { settingsReducer } from "./settings.reducer";
import { usersReducer } from "./users.reducer";
import {
	userReducer,
	userActionHistoryReducer,
	userApplicationsActionHistoryReducer,
} from "../components/Users/redux";
import { userDetailsReducer } from "./userDetails.reducer";
import { searchReducer } from "./search.reducer";
import { userInfoReducer } from "./userinfo.reducer";
import { overviewReducer } from "./overview.reducer";
import { restrictionsReducer } from "./restrictions.reducer";
import { customFieldsReducer } from "../modules/custom-fields/redux/custom-field";
import { integrationsReducer } from "../modules/integrations/redux/integrations";
import { noteReminderReducers } from "../modules/notes/redux/reminder";
import {
	gettingStartedReducer,
	toggleGettingStartedModalReducer,
} from "../components/GettingStarted/reducer";
import { notificationReducer } from "../common/Notification/notification-reducer";
import { connectRouter } from "connected-react-router";
import { filtersReducer } from "../components/Users/Applications/Modals/FiltersRenderer/redux";
import { groupsFilterReducer } from "components/Users/Applications/Modals/FiltersRenderer/groupsReducer";
import { viewsReducer } from "./views.reducer";
import { apiReducer } from "./api.reducer";
import { stepperReducer } from "../common/Stepper/redux";
import { modalReducer } from "./modal.reducer";
import { viewsnewReducer } from "modules/views/redux/viewsnew.reducer";
import {
	appCardsReducer,
	globalCardsReducer,
} from "modules/applications/components/HealthMetrics/redux";
import { featureFlagsReducer } from "./featureFlags.reducer";
import { v2InfiniteReducer } from "modules/v2InfiniteTable/redux/v2infinite.reducer";
import { v2PaginatedReducer } from "modules/v2PaginatedTable/redux/v2paginated.reducer";
import { employeeReducer } from "./employee.reducer";
import {
	SpendVSCostGraphReducer,
	SpendVSCostReducer,
} from "modules/spendvscost/redux/spendvscost_reducer";
import { appReducer } from "modules/shared/redux/app.redux";
import { LicenseMapperReducer } from "modules/licenses/components/LicenseMapper/LicenseMapper.reducer";
import { TaskManagementReducer } from "modules/TaskManagement/redux/TaskManagement.reducer";
import { accessReviewReducer } from "modules/AccessReview/redux/AccessReview.reducers";
import v2TableReducer from "containers/v2table/reducer";
import v2TablePropertiesReducer from "containers/v2table/properties_reducer";
import v2ViewsReducer from "containers/v2table/AppliedFilters/Views/views_reducer";
import { SummaryReducer } from "modules/OrgSummary/redux/summary.reducer";
import { ApplicationsReducer } from "modules/applications/redux/Applications.reducer";
import { SingleUserReducer } from "modules/User/redux/Users.reducer";
import CostCentersReducer from "modules/CostCenter/redux/costCenters.reducer";
import { SingleApplicationReducer } from "modules/Application/redux/Application.reducer";
import rootSaga from "utils/saga";
import { ChargebackDepartmentReducer } from "modules/applications/components/chargebacks/redux/department/department.reducer";
import { OptimizationReducer } from "modules/Optimization/redux/Optimization.reducer";
import { workflowsReducer } from "modules/workflow/redux/workflow";
import { AppLicensesReducer } from "modules/applications/redux/AppLicenses/AppLicenses.reducer";
import { headerReducer } from "./header.reducer";

export const dynamicModules = [
	{ id: "transactions", reducerMap: { transactions: transactionReducer } },
	{ id: "departments", reducerMap: { departments: departmentReducer } },
	{ id: "applications", reducerMap: { applications: applicationReducer } },
	{ id: "settings", reducerMap: { settings: settingsReducer } },
	{ id: "ui", reducerMap: { ui: uiReducer } },
	{ id: "user", reducerMap: { user: userReducer } },
	{
		id: "userActionHistory",
		reducerMap: { userActionHistory: userActionHistoryReducer },
	},
	{
		id: "userApplicationsActionHistory",
		reducerMap: {
			userApplicationsActionHistory: userApplicationsActionHistoryReducer,
		},
	},
	{
		id: "applicationActionHistory",
		reducerMap: {
			applicationActionHistory: applicationActionHistoryReducer,
		},
	},
	{ id: "users", reducerMap: { users: usersReducer } },
	{ id: "search", reducerMap: { search: searchReducer } },
	{ id: "overview", reducerMap: { overview: overviewReducer } },
	// { id: "renewal", reducerMap: { renewal: renewalReducers } },
	{ id: "customFields", reducerMap: { customFields: customFieldsReducer } },
	{ id: "integrations", reducerMap: { integrations: integrationsReducer } },
	{ id: "note", reducerMap: { note: noteReminderReducers } },
	{ id: "userDetails", reducerMap: { userDetails: userDetailsReducer } },
	{ id: "restrictions", reducerMap: { restrictions: restrictionsReducer } },
	{
		id: "gettingStartedStatuses",
		reducerMap: { gettingStartedStatuses: gettingStartedReducer },
	},
	{ id: "notifications", reducerMap: { notifications: notificationReducer } },
	{
		id: "showGettingStartedModal",
		reducerMap: {
			showGettingStartedModal: toggleGettingStartedModalReducer,
		},
	},
	{ id: "filters", reducerMap: { filters: filtersReducer } },
	{
		id: "groups_filters",
		reducerMap: { groups_filters: groupsFilterReducer },
	},
	{ id: "views", reducerMap: { views: viewsReducer } },
	{ id: "api", reducerMap: { api: apiReducer } },
	{ id: "stepper", reducerMap: { stepper: stepperReducer } },
	{ id: "modal", reducerMap: { modal: modalReducer } },
	{ id: "v2Data", reducerMap: { v2Data: v2InfiniteReducer } },
	{
		id: "v2PaginatedData",
		reducerMap: { v2PaginatedData: v2PaginatedReducer },
	},
	{ id: "viewsnew", reducerMap: { viewsnew: viewsnewReducer } },
	{
		id: "globalHealthCards",
		reducerMap: { globalHealthCards: globalCardsReducer },
	},
	{ id: "appHealthCards", reducerMap: { appHealthCards: appCardsReducer } },
	{ id: "featureFlags", reducerMap: { featureFlags: featureFlagsReducer } },
	{ id: "employee", reducerMap: { employee: employeeReducer } },
	{ id: "spendvscost", reducerMap: { spendvscost: SpendVSCostReducer } },
	{
		id: "spendvscostgraph",
		reducerMap: { spendvscostgraph: SpendVSCostGraphReducer },
	},
	{ id: "app", reducerMap: { app: appReducer } },
	{
		id: "licenseMapper",
		reducerMap: { licenseMapper: LicenseMapperReducer },
	},
	{ id: "optimization", reducerMap: { optimization: OptimizationReducer } },
	{ id: "tasks", reducerMap: { tasks: TaskManagementReducer } },
	{ id: "accessReview", reducerMap: { accessReview: accessReviewReducer } },

	{
		id: "v2Table",
		reducerMap: { v2Table: v2TableReducer },
		sagas: [rootSaga],
	},
	{
		id: "v2TableProperties",
		reducerMap: { v2TableProperties: v2TablePropertiesReducer },
	},
	{ id: "v2Views", reducerMap: { v2Views: v2ViewsReducer } },
	{ id: "orgSummary", reducerMap: { orgSummary: SummaryReducer } },
	{
		id: "applications_v2",
		reducerMap: { applications_v2: ApplicationsReducer },
	},
	{ id: "costCenters", reducerMap: { costCenters: CostCentersReducer } },
	{
		id: "singleApplication",
		reducerMap: { singleApplication: SingleApplicationReducer },
	},
	{ id: "singleUser", reducerMap: { singleUser: SingleUserReducer } },
	{
		id: "ChargebackDepartmentReducer",
		reducerMap: {
			ChargebackDepartmentReducer: ChargebackDepartmentReducer,
		},
	},
	{
		id: "workflows",
		reducerMap: { workflows: workflowsReducer },
	},
	{
		id: "AppLicensesReducer",
		reducerMap: {
			AppLicensesReducer: AppLicensesReducer,
		},
	},
	{
		id: "headerDetails",
		reducerMap: {
			headerDetails: headerReducer,
		},
	},
];
