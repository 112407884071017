import { client } from "utils/client";

export async function generateExportReportCSV(
	exportRequestObj,
	export_entity,
	parent_entity_id
) {
	if (!exportRequestObj.file_format) {
		exportRequestObj.file_format = "general";
	}
	const response = await client.post(`export/${export_entity}`, {
		...exportRequestObj,
		export_entity,
		parent_entity_id,
	});
	return response.data;
}
