import React, { useEffect, useState, useRef } from "react";

import arrowDownIcon from "assets/icons/arrow-down-12.svg";
import "./views.css";
import { useDispatch } from "react-redux";

import ViewSelector from "containers/v2table/AppliedFilters/Views/ViewSelector";
import PropTypes from "prop-types";
import cn from "classnames";
import {
	Button,
	Popover,
	Typography,
	theme,
	Tooltip,
	Divider,
} from "@zluri/ui-components";
import {
	useScreenTag,
	useViewsData,
} from "containers/v2table/AppliedFilters/Views/hooks";
import { useOutsideClickListener } from "utils/clickListenerHook";

import Icon from "containers/v2table/AppliedFilters/Views/Icons";
import SaveAsNewViewForm from "./SaveAsNewViewForm";
import { useTableContext } from "containers/v2table/TableContext/context";
import VerticalDivider from "containers/v2table/VerticalDivider";
import SearchBar from "containers/v2table/Searchbar";
import {
	getValueFromLocalStorage,
	setValueToLocalStorage,
} from "utils/localStorage";
// TODO: This should have been a component
// sorry
const ViewsV2 = ({
	entity,
	subEntityData,
	intID,
	views,
	updateViews,
	viewId,
	loading,
}) => {
	const dispatch = useDispatch();

	const refetchViews = () => updateViews({ type: "refetch" });
	const dataEntity = subEntityData?.entity || entity;

	const { appId } = useScreenTag(dataEntity);

	const [openViewPopover, setOpenViewPopover] = useState(false);
	const { displayData, onFilter, defaultView } = useViewsData({
		data: views,
		openViewPopover,
	});

	const [activeView, setActiveView] = useState(null);

	const { isAnyDataSelected, enableBulkEdit } = useTableContext();
	useEffect(() => {
		if (viewId) {
			const _view = views.find((v) => v._id === viewId);
			setActiveView(_view);
		} else {
			setActiveView((a) => {
				if (!loading && !a) {
					return defaultView;
				}
				return a;
			});
		}
	}, [viewId, views, defaultView, loading]);

	const onSelect = (selectedViews) => {
		setActiveView(selectedViews);
		setOpenViewPopover(false);

		const viewFromLocalStorage = getValueFromLocalStorage("v2TableViews");
		const selectedViewMap = {
			...viewFromLocalStorage,
			[dataEntity]: selectedViews?._id,
		};
		setValueToLocalStorage("v2TableViews", JSON.stringify(selectedViewMap));

		dispatch({
			type: "GET_TABLE_DATA",
			payload: {
				entity,
				subEntityData,
				cols: selectedViews.v2_columns,
				shouldRefresh: true,
				is_viewed_req: true,
				filterBy: selectedViews.filters,
				apiProps: { appId: intID },
				sortBy: selectedViews.sort,
				viewId: selectedViews._id,
			},
		});
	};

	const ref = useRef();
	const [openSaveAsNewViewPopup, setOpenSaveAsNewView] = useState(false);
	const [openSAveAsNewViewFromFooter, setOpenSaveAsNewViewFromFooter] =
		useState(false);

	useOutsideClickListener(
		[ref],
		() => {
			setOpenViewPopover(false);
		},
		[],
		[
			"z__view-selector-container",
			"z-popover",
			"z__view-selector-popup",
			"z__view_delete-popup",
		]
	);
	const isEmpty =
		displayData.custom?.length === 0 && displayData?.standard?.length === 0;
	if ((isAnyDataSelected && enableBulkEdit) || !activeView) return null;

	return (
		<div className="flex align-items-center gap-10">
			<button className="z__view-selector-trigger-button">
				<Popover
					placement="bottomLeft"
					trigger={["click"]}
					show={openViewPopover}
					content={
						<div className="z__view-selector-container">
							<header className="">
								<Typography
									variant="body_1_bold"
									color="secondary_grey_2"
								>
									Table Views
								</Typography>
								<div style={{ width: "100%" }}>
									<SearchBar
										placeholder="Search Views"
										onChange={onFilter}
										onClear={onFilter}
										className="bg-white"
										autoFocus
									/>
								</div>
							</header>
							<Divider
								height="1px"
								style={{ color: "#E6F5FF" }}
							/>
							{isEmpty ? (
								<EmptyViews />
							) : (
								<ViewSelector
									customViews={displayData.custom}
									standardViews={displayData.standard}
									activeView={activeView?._id}
									onSelect={onSelect}
									appId={appId}
									refetch={refetchViews}
									defaultViewId={defaultView?._id}
								/>
							)}
							<Divider
								height={1}
								color="#e1e8f8"
								className="my-1"
							/>
							<div
								className="w-100"
								style={{ padding: "0px 4px 4px 4px" }}
							>
								<Popover
									placement="right"
									trigger={["click"]}
									show={openSAveAsNewViewFromFooter}
									onToggle={setOpenSaveAsNewViewFromFooter}
									header={
										<Typography
											variant="body_1_bold"
											color="secondary_grey_2"
											style={{ flex: 1 }}
										>
											Save as New View
										</Typography>
									}
									content={
										<div className="z__view-save-as-new-form">
											<SaveAsNewViewForm
												onCancel={() =>
													setOpenSaveAsNewViewFromFooter(
														false
													)
												}
												onSave={onSelect}
												refetch={refetchViews}
											/>
										</div>
									}
								>
									<Button
										theme={theme}
										variant="secondary-default"
										className="w-100"
										disabled={viewId}
									>
										Save as new View
									</Button>
								</Popover>
							</div>
						</div>
					}
				>
					<div ref={ref} onClick={() => setOpenViewPopover(true)}>
						<Tooltip
							content={
								<Typography
									variant="body_2_regular"
									color="white"
								>
									Change View
								</Typography>
							}
						>
							<div
								className={cn(
									"z__view-selector-trigger-selector",
									viewId &&
										"z__view-trigger-selector-rounded",
									openViewPopover &&
										"z__view-selector-trigger-selector-active"
								)}
							>
								<Icon
									name={activeView?.name}
									className="z__view-selector-selected-icon"
								/>

								<Typography
									variant="button_extrasmall_semibold"
									color="primary_blue_dark_2"
									className="z__view-selector-title flex-1 ellipsize-typography"
								>
									{activeView?.name ?? ""}
								</Typography>
								<img
									style={{ marginLeft: "4px" }}
									src={arrowDownIcon}
									alt="Icon"
								/>
							</div>
						</Tooltip>
					</div>
				</Popover>

				{!viewId && activeView && (
					<Popover
						placement="bottomLeft"
						trigger={["click"]}
						show={openSaveAsNewViewPopup}
						onToggle={setOpenSaveAsNewView}
						header={
							<Typography
								variant="body_1_bold"
								color="secondary_grey_2"
								style={{ flex: 1 }}
							>
								Save as New View
							</Typography>
						}
						content={
							<div className="z__view-save-as-new-form">
								<SaveAsNewViewForm
									onCancel={() => setOpenSaveAsNewView(false)}
									refetch={refetchViews}
									onSave={onSelect}
								/>
							</div>
						}
					>
						<div>
							<Tooltip
								content={
									<Typography
										variant="body_2_regular"
										color="white"
									>
										Save as New View
									</Typography>
								}
							>
								<div
									className={cn(
										"z__view-selector-trigger-creator",
										openSaveAsNewViewPopup &&
											"z__view-selector-trigger-creator-active"
									)}
								>
									<Icon
										name="create-new-view"
										className="z__view-selector-create-new-icon"
									/>
								</div>
							</Tooltip>
						</div>
					</Popover>
				)}
			</button>
			<VerticalDivider />
		</div>
	);
};
ViewsV2.propTypes = {
	entity: PropTypes.string,
	subEntityData: PropTypes.object,
	intID: PropTypes.string,
	views: PropTypes.arrayOf(PropTypes.object),
	updateViews: PropTypes.func,
	viewId: PropTypes.string,
	loading: PropTypes.bool,
};
function EmptyViews() {
	return (
		<div
			style={{
				height: 240,
				flex: 1,
				overflowY: "scroll",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				flexDirection: "column",
				maxWidth: 240,
				margin: "0px auto",
				textAlign: "center",
			}}
			className="hide-scrollbar"
		>
			<div
				style={{
					backgroundColor: "#E1E8F8",
					borderRadius: "100%",
					height: 50,
					width: 50,
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					gap: 8,
				}}
			>
				<Icon name="search" style={{ color: "#5287E8" }} />
			</div>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center",
					gap: 4,
				}}
			>
				<Typography variant="body_2_bold" color="secondary_grey_1">
					No results
				</Typography>{" "}
				<Typography variant="body_2_regular" color="secondary_grey_1">
					We couldn't find any view matching your search!
				</Typography>
			</div>
		</div>
	);
}
export default ViewsV2;
