import React from "react";
import classNames from "classnames";
import "./DropDownButton.css";

interface Prop extends React.HTMLProps<HTMLDivElement> {}
export default function DropdownButton({ children, className, ...rest }: Prop) {
	return (
		<div
			className={classNames(
				"dropdown__button__wrapper",
				"cursor-pointer",
				className
			)}
			{...rest}
		>
			{children}
		</div>
	);
}
