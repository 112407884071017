import React from "react";

import moment from "moment";

import {
	BODY_VARIANT,
	GREY_VARIANT,
	NoDataCard,
	SUB_HEADING_VARIANT,
	Typography,
} from "@zluri/ui-components";

import NoDataIcon from "assets/applications/Icon_No_Summary.svg";
import CardSVG from "assets/credit_card.svg";
import VisaSVG from "assets/visa_card.svg";
import MasterCardSVG from "assets/master_card.svg";
import OtherPaymentSVG from "assets/other_payment.svg";
import BankSVG from "assets/payments_bank.svg";
import AmericanExpressSVG from "assets/american_express.svg";

import { MONTH } from "utils/DateUtility";
import { toast } from "react-toastify";
import { getDropDownOptions } from "../constants/ApplicationConstants";

export const sortApplicationsFunction = (data) => {
	let tempArray = data.results;
	let arrayWithIntegrationId = tempArray.filter(
		(el) => el.app_integration_id !== null
	);
	let arrayWithoutIntegrationId = tempArray.filter(
		(el) => el.app_integration_id === null
	);
	let newArray = arrayWithIntegrationId.concat(arrayWithoutIntegrationId);
	return newArray;
};

export const updateTriggerTitle = (title, application) => {
	if (title) {
		title = title.replace(
			/<b>@application_name@<\/b>/g,
			application?.app_name ? application.app_name : ""
		);
		title = title.replace(
			/<b>@org_application_name@<\/b>/g,
			application?.app_name ? application.app_name : ""
		);
	}
	return title;
};

export const getThreatType = (value, typeChecker) => {
	if ([1, 2].includes(value)) {
		return getDropDownOptions({ typeChecker }).LOW_RISK;
	} else if ([3].includes(value)) {
		return getDropDownOptions({ typeChecker }).MEDIUM_RISK;
	} else if ([4, 5].includes(value)) {
		return getDropDownOptions({ typeChecker }).HIGH_RISK;
	}
};

export const handleCheckedFilter = (
	appliedFilter,
	setAppliedFilter,
	filter,
	value,
	e
) => {
	const tempAppliedFilters = [...appliedFilter];
	const filterIndex = tempAppliedFilters.findIndex(
		(appliedFilter) => appliedFilter.field_id === filter.field_id
	);
	if (filter?.field_type === "radio") {
		tempAppliedFilters[filterIndex].selectedValue = {
			filter: value,
			value: e?.target?.value,
		};
		setAppliedFilter([...tempAppliedFilters]);
		return;
	}
	if (filterIndex > -1 && !filter?.field_type) {
		const fieldValueIndex = tempAppliedFilters[
			filterIndex
		].selectedValues.findIndex((field_value) => field_value === value);
		if (fieldValueIndex > -1) {
			tempAppliedFilters[filterIndex].selectedValues?.splice(
				fieldValueIndex,
				1
			);
		} else {
			tempAppliedFilters[filterIndex].selectedValues.push(value);
		}
	} else {
		const tempFilter = { ...filter, selectedValues: [value] };
		tempAppliedFilters.push(tempFilter);
	}
	setAppliedFilter([...tempAppliedFilters]);
};

export const selectFieldValueIsChecked = (appliedFilter, filter, value) => {
	const tempAppliedFilters = appliedFilter ? [...appliedFilter] : [];
	const filterIndex = tempAppliedFilters.findIndex(
		(appliedFilter) => appliedFilter?.field_id === filter?.field_id
	);
	if (filter?.field_type) {
		if (value === filter?.selectedValue?.filter) {
			return true;
		}
	}
	if (filterIndex > -1 && !filter?.field_type) {
		return tempAppliedFilters[filterIndex]?.selectedValues?.includes(value);
	}
};

export const tableAscendingSorting = (header, a, b) => {
	if (header.filterKey === "user_name")
		return a?.user_name.localeCompare(b?.user_name);
	else if (header.filterKey === "user_app_status")
		return a?.user_app_status.localeCompare(b?.user_app_status);
	else if (header.filterKey === "risk_level")
		return a.scope?.risk - b.scope.risk;
};
export const tableDescendingSorting = (header, a, b) => {
	if (header.filterKey === "user_name")
		return b?.user_name.localeCompare(a?.user_name);
	else if (header.filterKey === "user_app_status")
		return b?.user_app_status.localeCompare(a?.user_app_status);
	else if (header.filterKey === "risk_level")
		return b.scope?.risk - a.scope.risk;
};
export const testValidUrl = (str) => {
	const regex =
		/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm;
	return regex.test(str.toLowerCase());
};

export const getImageByCardTypeV2 = (paymentMethod) => {
	var image;
	var type = paymentMethod?.card_type;
	image =
		type === "credit_card"
			? CardSVG
			: type === "visa"
				? VisaSVG
				: type === "mastercard"
					? MasterCardSVG
					: type === "bank"
						? BankSVG
						: type === "american_express"
							? AmericanExpressSVG
							: type === "other" &&
								  !paymentMethod?.payment_method_logo_url
								? paymentMethod?.payment_method_logo_url
								: OtherPaymentSVG;
	return image;
};
// return the data of the FY based on the FY month
export const financialyearData = (
	fy_month = 4,
	fy_year = [2022, 2023],
	data
) => {
	return data?.filter((d) => {
		if (fy_year[0] === d?.year_id) {
			if (fy_month <= d?.month_id) {
				if (d?.month_id) {
					d.month_id = MONTH[d?.month_id - 1];
				}
				return d;
			}
		}
		if (fy_year[1] === d?.year_id) {
			if (fy_month > d?.month_id) {
				if (d?.month_id) {
					d.month_id = MONTH[d?.month_id - 1];
				}
				return d;
			}
		}
	});
};

export const legendSymbol = (color, text, symbolClass, innerClass) => {
	return `
		<div class="d-flex align-items-center">
		<div class='legend__symbol rounded-circle d-flex align-items-center justify-content-center mr-2' + ' ' + ${symbolClass} style='border: ${
			color ? "1px solid " + color : "1px solid blue"
		}'>
		<div class='border-radius-8 legend__inner' + ${innerClass} style='background: ${
			color || "blue"
		}'></div>
		</div>
		<div class='font-10 bold-600 grey'>${text}</div>
		</div>
	`;
};
export const tooltip = (label, dateText, valueText) => {
	return `
		<div class='spends__tooltip__container d-flex w-100 flex-1 flex-column' style='width: '80px', height: '35px''>
		<div class="font-8 bold-500 w-100 flex-1 pb-1 spends__tooltip__label" style='color: "#dddddd !important"; border-bottom: "1px solid #ffffff"'>${label}</div>
		<div class='d-flex justify-content-between w-100 pt-1 flex-1 align-items-center' style='color: "#f6f7fa"'>
			<span class="font-8 bold-500 spends__tooltip__key">${dateText}</span>
			<span class="font-8 bold-600 spends__tooltip__value">${valueText}</span>
		</div>
		</div>
	`;
};

export const getDateFormat = (date, format) => {
	return moment(date).format(format);
};

export const getFYInfo = (month, date) => {
	let start_month = month || moment(date).month();
	let start_year =
		start_month > 1 ? moment(date).year() - 1 : moment(date).year();
	let end_month =
		start_month + 11 > 12 ? start_month + 11 - 12 : start_month + 11;
	let end_year = start_month === 1 ? start_year : start_year + 1;
	let fy = moment({ month: start_month, year: start_year });
	return { start_month, end_month, start_year, end_year, fy };
};

export const toastSuccess = (content = "Successful!", props) => {
	toast.success(content, {
		position: "top-right",
		autoClose: 5000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: "light",
		style: {
			borderRadius: "8px",
			fontSize: "10px",
			fontWeight: 600,
			color: "#484848",
		},
		...props,
	});
};

export const toastError = (content = "Successful!", props) => {
	toast.error(content, {
		position: "top-right",
		autoClose: 5000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: "light",
		style: {
			borderRadius: "8px",
			fontSize: "10px",
			fontWeight: 600,
			color: "#484848",
		},
		...props,
	});
};

export const SummaryMetricNoDataCard = ({
	title,
	buttonText,
	onActionClick,
	description = "No data available",
}) => {
	return (
		<NoDataCard
			actions={[
				{
					onClick: onActionClick,
					variant: "secondary-default",
					text: buttonText,
					className: "w-100",
				},
			]}
			className="summary_nodata_card"
			icon={NoDataIcon}
			iconStyle={{
				height: "20px",
				width: "20px",
			}}
			iconContainerStyle={{
				background: "none",
				width: "20px",
				height: "20px",
				padding: "0px",
			}}
			label={
				<Typography
					style={{ marginTop: "2px" }}
					variant={BODY_VARIANT.BODY_2_REGULAR}
					color={GREY_VARIANT.SECONDARY_GREY_2}
				>
					{description}
				</Typography>
			}
			title={
				<Typography
					variant={SUB_HEADING_VARIANT.SUBHEADING_2_BOLD}
					color={GREY_VARIANT.SECONDARY_GREY_1}
				>
					{title}
				</Typography>
			}
		/>
	);
};
