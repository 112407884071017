export const DepartmentConstants = {

    FETCH_CHARGEBACK_DEPARTMENT_SUMMARY_REQUESTED:
        "FETCH_CHARGEBACK_DEPARTMENT_SUMMARY_REQUESTED",

    FETCH_CHARGEBACK_DEPARTMENT_SUMMARY: "FETCH_CHARGEBACK_DEPARTMENT_SUMMARY",

    FETCH_CHARGEBACK_DEPARTMENT_ACTUAL_SPEND_REQUESTED:
        "FETCH_CHARGEBACK_DEPARTMENT_ACTUAL_SPEND_REQUESTED",

    FETCH_CHARGEBACK_DEPARTMENT_ACTUAL_SPEND:
        "FETCH_CHARGEBACK_DEPARTMENT_ACTUAL_SPEND",

    FETCH_CHARGEBACK_SUB_DEPARTMENT_ACTUAL_SPEND_REQUESTED:
        "FETCH_CHARGEBACK_SUB_DEPARTMENT_ACTUAL_SPEND_REQUESTED",

    FETCH_CHARGEBACK_SUB_DEPARTMENT_ACTUAL_SPEND:
        "FETCH_CHARGEBACK_SUB_DEPARTMENT_ACTUAL_SPEND",

    FETCH_CHARGEBACK_APPLICATION_BUDGET_REQUESTED:
        "FETCH_CHARGEBACK_APPLICATION_BUDGET_REQUESTED",
    FETCH_CHARGEBACK_APPLICATION_BUDGET: "FETCH_CHARGEBACK_APPLICATION_BUDGET",

    FETCH_CHARGEBACK_DEPARTMENT_SPEND_REQUESTED:
        "FETCH_CHARGEBACK_DEPARTMENT_SPEND_REQUESTED",

    FETCH_CHARGEBACK_DEPARTMENT_SPEND: "FETCH_CHARGEBACK_SUB_DEPARTMENT_SPEND",

    FETCH_CHARGEBACK_SUB_DEPARTMENT_SPEND_REQUESTED:
    "FETCH_CHARGEBACK_DEPARTMENT_SPEND_REQUESTED",

FETCH_CHARGEBACK_SUB_DEPARTMENT_SPEND: "FETCH_CHARGEBACK_SUB_DEPARTMENT_SPEND"

}