import * as React from "react";
export const DefaultIcon = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={16}
		height={16}
		fill="none"
		{...props}
	>
		<g fill="currentColor" clipPath="url(#a)">
			<path d="M4.667 6.5H3.333A.333.333 0 0 0 3 6.835v1.333c0 .184.15.334.333.334h1.334C4.85 8.5 5 8.35 5 8.167V6.834a.333.333 0 0 0-.333-.333ZM4.667 9.834H3.333a.333.333 0 0 0-.333.333v1.334c0 .184.15.333.333.333h1.334c.184 0 .333-.15.333-.333v-1.334a.333.333 0 0 0-.333-.333ZM8.667 6.5H7.333A.333.333 0 0 0 7 6.835v1.333c0 .184.15.334.333.334h1.334C8.85 8.5 9 8.35 9 8.167V6.834a.333.333 0 0 0-.333-.333ZM8.667 9.834H7.333a.333.333 0 0 0-.333.333v1.334c0 .184.15.333.333.333h1.334c.184 0 .333-.15.333-.333v-1.334a.333.333 0 0 0-.333-.333ZM12.667 6.5h-1.334a.333.333 0 0 0-.333.334v1.333c0 .184.15.334.333.334h1.334c.184 0 .333-.15.333-.334V6.834a.333.333 0 0 0-.333-.333ZM12.667 9.834h-1.334a.333.333 0 0 0-.333.333v1.334c0 .184.15.333.333.333h1.334c.184 0 .333-.15.333-.333v-1.334a.333.333 0 0 0-.333-.333Z" />
			<path d="M16 3.167a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v9.667a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3.167Zm-9.564-.666a.644.644 0 0 1 1.128 0c.064.1.1.215.103.333a.648.648 0 0 1-.103.333.644.644 0 0 1-1.128 0 .648.648 0 0 1-.103-.333.646.646 0 0 1 .103-.333Zm-2.333 0a.644.644 0 0 1 1.128 0c.064.1.1.215.102.333a.648.648 0 0 1-.102.333.644.644 0 0 1-1.128 0A.648.648 0 0 1 4 2.834a.646.646 0 0 1 .103-.333Zm-2.375.06a.667.667 0 0 1 .605-.394.655.655 0 0 1 .564.334c.064.1.1.215.103.333a.648.648 0 0 1-.103.333.644.644 0 0 1-1.128 0 .648.648 0 0 1-.102-.333c0-.094.021-.187.061-.273Zm12.939 10.273a.667.667 0 0 1-.667.667H2a.666.666 0 0 1-.667-.667V4.667A.167.167 0 0 1 1.5 4.5h13a.167.167 0 0 1 .167.167v8.167Z" />
		</g>
		<defs>
			<clipPath id="a">
				<path fill="#fff" d="M0 0h16v16H0z" />
			</clipPath>
		</defs>
	</svg>
);

export const RenewalViewIcon = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={16}
		height={16}
		fill="none"
		{...props}
	>
		<g fill="currentColor" clipPath="url(#a)">
			<path d="M7.917 7.583a2.153 2.153 0 0 1 1.908-2h.758a.166.166 0 0 0 .167-.166 5.34 5.34 0 1 0-2.925 4.755.167.167 0 0 0 .092-.149v-2.44Zm-3.584.334a.5.5 0 0 1 0-1H5.69a.395.395 0 0 0 .146-.761l-1.375-.55a1.39 1.39 0 0 1 .24-2.654.166.166 0 0 0 .133-.163v-.206a.5.5 0 1 1 1 0v.167A.167.167 0 0 0 6 2.917h.333a.5.5 0 0 1 0 1H4.978a.395.395 0 0 0-.147.76l1.376.55a1.39 1.39 0 0 1-.24 2.654.166.166 0 0 0-.134.164v.205a.5.5 0 0 1-1 0v-.167a.167.167 0 0 0-.166-.166h-.334Z" />
			<path d="M15.008 6.583H9.825c-.44 0-.908.651-.908 1v7.334c0 .349.466 1 .908 1h5.183c.441 0 .909-.651.909-1V7.583c0-.349-.468-1-.909-1Zm-.925 7.674a.5.5 0 1 1 0-1.001.5.5 0 0 1 0 1Zm-3.833-.5a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm0-1.674a.5.5 0 1 1 1.001 0 .5.5 0 0 1-1 0Zm1.667 1.674a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm0-1.674a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm2.166.5a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1Zm.167-2.333h-3.667c-.178 0-.356-.146-.356-.46V8.25c0-.315.18-.445.358-.445h3.666c.18 0 .358.134.358.445v1.555c-.002.315-.18.445-.359.445Z" />
		</g>
		<defs>
			<clipPath id="a">
				<path fill="#fff" d="M0 0h16v16H0z" />
			</clipPath>
		</defs>
	</svg>
);

export const SecurityViewIcon = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={16}
		height={16}
		fill="none"
		{...props}
	>
		<g clipPath="url(#a)">
			<path
				fill="currentColor"
				d="M13.612 0H2.388a1 1 0 0 0-.994 1v4.175a12.17 12.17 0 0 0 6.45 10.786.33.33 0 0 0 .313 0 12.174 12.174 0 0 0 6.45-10.786V1a1 1 0 0 0-.995-1Zm-1.678 5.297L7.2 9.763a.667.667 0 0 1-.933-.016L4.685 8.15a.667.667 0 1 1 .947-.938l1.121 1.133 4.264-4.018a.667.667 0 1 1 .917.97Z"
			/>
		</g>
		<defs>
			<clipPath id="a">
				<path fill="#fff" d="M0 0h16v16H0z" />
			</clipPath>
		</defs>
	</svg>
);

export const SpendViewIcon = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={16}
		height={16}
		fill="none"
		{...props}
	>
		<g fill="currentColor" clipPath="url(#a)">
			<path d="m5.105 1.254.943 3.582a.667.667 0 1 0 1.289-.339L6.56 1.543a.167.167 0 0 1 .161-.21h3.972a.667.667 0 0 0 0-1.333H6.08a.983.983 0 0 0-.775.379 1.059 1.059 0 0 0-.216.796c.004.026.01.053.016.079Z" />
			<path d="M14.578 2.374A.984.984 0 0 0 13.805 2H8.86a.99.99 0 0 0-.757.355 1.04 1.04 0 0 0-.22.856l1 4.274a.667.667 0 1 0 1.297-.304l-.855-3.643a.167.167 0 0 1 .163-.205h3.746a.167.167 0 0 1 .164.19l-.533 3.716a.667.667 0 0 0 1.32.189l.61-4.26a1.044 1.044 0 0 0-.216-.794Z" />
			<path d="M13.753 11.013a.667.667 0 0 1 .041-.667l.206-.31a.667.667 0 0 0 0-.74l-.37-.555a.167.167 0 0 0-.14-.074H8.333a.167.167 0 0 1-.164-.138L7.747 6.17a.166.166 0 0 0-.256-.11l-.546.364c-.24.16-.443.37-.594.616l-2.2 3.57c-.155.253-.39.446-.666.55l-2.134.8a.166.166 0 0 0-.108.155l-.05 3.717a.166.166 0 0 0 .167.169h4.52a.167.167 0 0 0 .063-.012l1.28-.512c.235-.095.487-.143.742-.144h4.879a.165.165 0 0 0 .117-.048l.66-.66a.667.667 0 0 0 .162-.682l-.116-.348a.667.667 0 0 1 .036-.509l.394-.788a.667.667 0 0 0 0-.596l-.344-.69ZM11.413 6.333a.833.833 0 1 0 0-1.666.833.833 0 0 0 0 1.666Z" />
		</g>
		<defs>
			<clipPath id="a">
				<path fill="#fff" d="M0 0h16v16H0z" />
			</clipPath>
		</defs>
	</svg>
);

export const CreateNewViewIcon = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="16"
		height="16"
		fill="none"
		viewBox="0 0 16 16"
	>
		<g clipPath="url(#clip0_2565_111252)">
			<path
				stroke="#5287E8"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.091"
				d="M12.5 6V1.3a.8.8 0 00-.8-.8H3.963A1.6 1.6 0 002.83.969L.97 2.832A1.6 1.6 0 00.5 3.962V11.7a.8.8 0 00.8.8h1.6M4.5.5v3.2a.8.8 0 00.8.8h4a.8.8 0 00.8-.8V.5M8 6.9H3.7a.8.8 0 00-.8.8v4.8m0 0H6m-1.5-4h2.41M4.5 10.1h1.318"
			></path>
			<path
				fill="#5287E8"
				d="M11.667 7.332a4.333 4.333 0 100 8.667 4.333 4.333 0 000-8.667zm1.667 4.833h-1a.167.167 0 00-.167.167v1a.5.5 0 11-1 0v-1a.166.166 0 00-.167-.167h-1a.5.5 0 110-1h1a.166.166 0 00.167-.166v-1a.5.5 0 011 0v1a.167.167 0 00.167.166h1a.5.5 0 110 1z"
			></path>
		</g>
		<defs>
			<clipPath id="clip0_2565_111252">
				<path fill="#fff" d="M0 0H16V16H0z"></path>
			</clipPath>
		</defs>
	</svg>
);

export const CustomViewIcon = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={16}
		height={16}
		fill="none"
		{...props}
	>
		<g clipPath="url(#a)">
			<path
				fill="#484848"
				d="M14.667 0H1.333A1.333 1.333 0 0 0 0 1.333v13.334A1.333 1.333 0 0 0 1.333 16h13.334A1.334 1.334 0 0 0 16 14.667V1.333A1.334 1.334 0 0 0 14.667 0ZM10 1.667v12.666a.333.333 0 0 1-.333.334H6.333A.333.333 0 0 1 6 14.333V1.667a.333.333 0 0 1 .333-.334h3.334a.333.333 0 0 1 .333.334Zm-8.333-.334h2.666a.333.333 0 0 1 .334.334v12.666a.333.333 0 0 1-.334.334H1.667a.333.333 0 0 1-.334-.334V1.667a.333.333 0 0 1 .334-.334Zm12.666 13.334h-2.666a.333.333 0 0 1-.334-.334V1.667a.333.333 0 0 1 .334-.334h2.666a.333.333 0 0 1 .334.334v12.666a.333.333 0 0 1-.334.334Z"
			/>
		</g>
		<defs>
			<clipPath id="a">
				<path fill="#fff" d="M0 0h16v16H0z" />
			</clipPath>
		</defs>
	</svg>
);
export const UsageViewIcon = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={16}
		height={16}
		fill="none"
		{...props}
	>
		<g fill="currentColor" clipPath="url(#a)">
			<path d="M12.333 2.834a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v6.333a2 2 0 0 0 2 2h8.333a2 2 0 0 0 2-2V2.834Zm-5.897-.667a.644.644 0 0 1 1.128 0c.064.1.1.215.103.334a.648.648 0 0 1-.103.333.644.644 0 0 1-1.128 0 .648.648 0 0 1-.103-.333.646.646 0 0 1 .103-.334Zm-2.333 0a.644.644 0 0 1 1.128 0c.064.1.1.215.102.334a.648.648 0 0 1-.102.333.644.644 0 0 1-1.128 0A.648.648 0 0 1 4 2.501a.646.646 0 0 1 .103-.334Zm-2.375.061a.667.667 0 0 1 .605-.394.655.655 0 0 1 .564.333c.064.1.1.215.103.334a.648.648 0 0 1-.103.333.644.644 0 0 1-1.128 0 .648.648 0 0 1-.102-.333c0-.095.021-.188.061-.273ZM11 9.168a.666.666 0 0 1-.667.666H2a.667.667 0 0 1-.667-.667V4.333a.167.167 0 0 1 .167-.166h9.333a.167.167 0 0 1 .167.166v4.834Z" />
			<path d="M16 6.834a2 2 0 0 0-2-2h-.333a.333.333 0 0 0-.334.333v2.667a.333.333 0 0 0 .334.333h.833a.166.166 0 0 1 .167.167v4.833a.667.667 0 0 1-.667.667H5.667A.667.667 0 0 1 5 13.167v-.333a.667.667 0 1 0-1.333 0v.333a2 2 0 0 0 2 2H14a2 2 0 0 0 2-2V6.834Z" />
		</g>
		<defs>
			<clipPath id="a">
				<path fill="#fff" d="M0 0h16v16H0z" />
			</clipPath>
		</defs>
	</svg>
);

const SearchIcon = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={24}
		height={24}
		fill="none"
		{...props}
	>
		<path
			fill="#5287E8"
			d="m23.423 20.58-5.063-5.125a9.992 9.992 0 1 0-2.814 2.844l5.03 5.093a2.029 2.029 0 0 0 2.83.016 2 2 0 0 0 .017-2.828ZM9.997 1.73a8.252 8.252 0 1 1-8.252 8.252A8.262 8.262 0 0 1 9.997 1.73Z"
		/>
		<path
			fill="#5287E8"
			d="M9.997 16.733a6.752 6.752 0 1 0 0-13.503 6.752 6.752 0 0 0 0 13.503Z"
		/>
	</svg>
);

const SetAsDefaultIcon = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={17}
		height={16}
		fill="none"
		{...props}
	>
		<g fill="#484848" clipPath="url(#a)">
			<path d="M1.31 3.667h4a.333.333 0 0 0 .333-.334v-.666A.667.667 0 0 0 4.977 2H3.81a.333.333 0 0 1-.333-.333v-1a.667.667 0 0 0-1.334 0v1A.333.333 0 0 1 1.81 2h-.167a.667.667 0 0 0-.666.667v.666a.333.333 0 0 0 .333.334Zm10.667 1.666h3.333A.333.333 0 0 0 15.643 5v-.333A.666.666 0 0 0 14.977 4H12.31a.666.666 0 0 0-.667.667V5a.333.333 0 0 0 .334.333Zm-6.334-.666H.977a.667.667 0 0 0-.667.666v10A.667.667 0 0 0 .977 16h1a.333.333 0 0 0 .333-.333v-1.334a.667.667 0 0 1 .667-.666h.666a.667.667 0 0 1 .667.666v1.334a.333.333 0 0 0 .333.333h1a.666.666 0 0 0 .667-.667v-10a.667.667 0 0 0-.667-.666Zm-.833 7.166h-3a.5.5 0 1 1 0-1h3a.5.5 0 0 1 0 1Zm0-2.333h-3a.5.5 0 1 1 0-1h3a.5.5 0 0 1 0 1Zm0-2.333h-3a.5.5 0 1 1 0-1h3a.5.5 0 0 1 0 1Zm10.833-.834h-4a.667.667 0 0 0-.666.667v8.333a.667.667 0 0 0 .666.667h.667a.334.334 0 0 0 .333-.333v-1.334a.667.667 0 0 1 .667-.666h.667a.667.667 0 0 1 .666.666v1.334a.333.333 0 0 0 .334.333h.666a.666.666 0 0 0 .667-.667V7a.667.667 0 0 0-.667-.667Zm-.833 5.5h-2.333a.5.5 0 1 1 0-1h2.333a.5.5 0 0 1 0 1Zm0-2.666h-2.333a.5.5 0 1 1 0-1h2.333a.5.5 0 0 1 0 1ZM9.643 9.333h-2a.333.333 0 0 0-.333.334v6c0 .184.15.333.333.333h2c.184 0 .334-.15.334-.333v-6a.333.333 0 0 0-.334-.334Z" />
		</g>
		<defs>
			<clipPath id="a">
				<path fill="#fff" d="M.31 0h16v16h-16z" />
			</clipPath>
		</defs>
	</svg>
);

const XIcon = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={17}
		height={16}
		fill="none"
		{...props}
	>
		<path
			fill="#A8A8A8"
			d="M9.65 8.088a.125.125 0 0 1 0-.177l4.631-4.63a.75.75 0 0 0-1.062-1.06L8.59 6.848a.125.125 0 0 1-.178 0L3.781 2.22A.75.75 0 1 0 2.72 3.28l4.63 4.631a.125.125 0 0 1 0 .177L2.72 12.72a.75.75 0 0 0 1.06 1.06L8.412 9.15a.125.125 0 0 1 .177 0l4.631 4.631a.75.75 0 1 0 1.061-1.06L9.65 8.088Z"
		/>
	</svg>
);
function Cog(props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			fill="none"
			viewBox="0 0 16 16"
			{...props}
		>
			<g clipPath="url(#clip0_2558_64809)">
				<path
					fill="#484848"
					d="M15 6.333h-1.241a5.972 5.972 0 00-.509-1.226l.878-.878a1 1 0 000-1.414l-.943-.942a1 1 0 00-1.414 0l-.879.878a5.94 5.94 0 00-1.225-.509V1a1 1 0 00-1-1H7.333a1 1 0 00-1 1v1.242a5.98 5.98 0 00-1.226.509l-.878-.878a1 1 0 00-1.414 0l-.944.942a1 1 0 000 1.415l.879.878a5.92 5.92 0 00-.509 1.225H1a1 1 0 00-1 1v1.334a1 1 0 001 1h1.241c.123.426.294.837.509 1.226l-.88.879a1 1 0 000 1.414l.943.943a1.03 1.03 0 001.415 0l.879-.878c.388.214.8.385 1.226.509V15a1 1 0 001 1h1.334a1 1 0 001-1v-1.24a5.972 5.972 0 001.226-.509l.878.878a1 1 0 001.414 0l.943-.942a1 1 0 000-1.414l-.878-.878c.215-.389.386-.8.509-1.227H15a1 1 0 001-1.001V7.333a1 1 0 00-1-1zm-7 5a3.334 3.334 0 110-6.667 3.334 3.334 0 010 6.667z"
				></path>
			</g>
			<defs>
				<clipPath id="clip0_2558_64809">
					<path fill="#fff" d="M0 0H16V16H0z"></path>
				</clipPath>
			</defs>
		</svg>
	);
}

function Delete(props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			fill="none"
			viewBox="0 0 16 16"
			{...props}
		>
			<g clipPath="url(#clip0_1046_40809)">
				<path
					fill="currentColor"
					d="M12.993 5h-10a.333.333 0 00-.333.333v9.334A1.333 1.333 0 003.993 16h8a1.333 1.333 0 001.334-1.333V5.333A.333.333 0 0012.993 5zm-6.166 8.667a.5.5 0 11-1 0v-6a.5.5 0 011 0v6zm3.333 0a.5.5 0 11-1 0v-6a.5.5 0 011 0v6zm4.5-11h-3.167a.167.167 0 01-.166-.167v-.833A1.667 1.667 0 009.66 0H6.327A1.667 1.667 0 004.66 1.667V2.5a.167.167 0 01-.167.167H1.327a.667.667 0 100 1.333H14.66a.667.667 0 000-1.333zM5.993 2.5v-.833a.333.333 0 01.334-.334H9.66a.333.333 0 01.333.334V2.5a.167.167 0 01-.166.167H6.16a.167.167 0 01-.167-.167z"
				></path>
			</g>
			<defs>
				<clipPath id="clip0_1046_40809">
					<path fill="#fff" d="M0 0H16V16H0z"></path>
				</clipPath>
			</defs>
		</svg>
	);
}
function Unlink() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			fill="none"
			viewBox="0 0 16 16"
		>
			<rect width="16" height="16" fill="#FE6955" rx="8"></rect>
			<g fill="#fff" clipPath="url(#clip0_2558_101037)">
				<path d="M8.135 8.969a.333.333 0 00-.18.435.333.333 0 01-.073.364l-.943.943a.667.667 0 01-.942 0l-.708-.707a.666.666 0 010-.943l.944-.943a.338.338 0 01.364-.072.333.333 0 00.254-.617 1.011 1.011 0 00-1.09.218l-.943.942a1.335 1.335 0 000 1.886l.707.707a1.334 1.334 0 001.886 0l.943-.943a1.003 1.003 0 00.217-1.09.333.333 0 00-.436-.18z"></path>
				<path d="M6.586 9.413a.337.337 0 00.471 0L9.65 6.821a.333.333 0 00-.471-.472L6.586 8.942a.333.333 0 000 .471z"></path>
				<path d="M11.182 5.524l-.707-.707a1.335 1.335 0 00-1.886 0l-.943.943a1.002 1.002 0 00-.217 1.09.333.333 0 10.617-.255.333.333 0 01.072-.362l.943-.943a.667.667 0 01.943 0l.707.707a.667.667 0 010 .943l-.944.941a.338.338 0 01-.364.073.333.333 0 10-.255.616 1.009 1.009 0 001.09-.217l.943-.943a1.336 1.336 0 00.001-1.886z"></path>
			</g>
			<defs>
				<clipPath id="clip0_2558_101037">
					<path
						fill="#fff"
						d="M0 0H8V8H0z"
						transform="translate(4 4)"
					></path>
				</clipPath>
			</defs>
		</svg>
	);
}
function Exclamation(props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			fill="none"
			viewBox="0 0 16 16"
			{...props}
		>
			<g clipPath="url(#clip0_16039_101198)">
				<path
					fill="currentColor"
					d="M7.989 0a8.137 8.137 0 00-5.674 2.435A7.867 7.867 0 000 8.138 7.852 7.852 0 007.867 16h.142A8.074 8.074 0 0016 7.86 7.844 7.844 0 007.989 0zM7 11.028a.984.984 0 01.966-1.02h.018a1.018 1.018 0 011.015.98.983.983 0 01-.966 1.02h-.018A1.019 1.019 0 017 11.028zm.333-2.695v-4a.667.667 0 111.334 0v4a.667.667 0 01-1.334 0z"
				></path>
			</g>
			<defs>
				<clipPath id="clip0_16039_101198">
					<path fill="#fff" d="M0 0H16V16H0z"></path>
				</clipPath>
			</defs>
		</svg>
	);
}
const svgIconsMap = {
	"Main View": DefaultIcon,
	"Usage View": UsageViewIcon,
	"Renewal View": RenewalViewIcon,
	"Security View": SecurityViewIcon,
	"Spend View": SpendViewIcon,
	"create-new-view": CreateNewViewIcon,
	search: SearchIcon,
	"custom-view": CustomViewIcon,
	close: XIcon,
	"set-as-default": SetAsDefaultIcon,
	cog: Cog,
	delete: Delete,
	unlink: Unlink,
	exclamation: Exclamation,
};

const Icon = ({ name, ...otherProps }) => {
	const Component = svgIconsMap[name] ?? DefaultIcon;
	return <Component {...otherProps} />;
};

export default Icon;
