const RECOGNISED_TABLE_ROW = 30;
const UNRECOGNISED_TABLE_ROW = 30;
const SINGLE_APP_CONTRACTS = 30;
const ARCHIVED_TABLE_ROW = 30;
const UPLOADS_TABLE_ROW = 30;
const USERS_TABLE_ROW = 30;
const ALL_APPLICATIONS_ROW = 30;
const ALL_DEPARTMENTS_ROW = 30;
const USERMAPPING_ROW = 30;
const MINIMUM_ROW = 30;
export const defaults = {
	RECOGNISED_TABLE_ROW,
	UNRECOGNISED_TABLE_ROW,
	SINGLE_APP_CONTRACTS,
	ARCHIVED_TABLE_ROW,
	UPLOADS_TABLE_ROW,
	USERS_TABLE_ROW,
	ALL_APPLICATIONS_ROW,
	ALL_DEPARTMENTS_ROW,
	USERMAPPING_ROW,
	MINIMUM_ROW,
};
