import React, { useState } from "react";
import {
	BODY_VARIANT,
	Button,
	Loader,
	Field,
	TextPill,
	Typography,
	theme,
} from "@zluri/ui-components";
import cn from "classnames";
import tagIcon from "assets/Icon_Tag.svg";
import closeIcon from "assets/Icon_Close.svg";
import { getSegmentFromURL } from "@zluri/ui-components";

export function AppAboutTagsClickPopover({
	data,
	handleEdit: handleUpdate,
	component,
	...props
}) {
	const dataValue = data["app_tags"];

	const [tags, setTags] = useState(dataValue);

	const [inputValue, setInputValue] = useState("");
	const handleSubmit = () => {
		const patchObj = {
			patches: [
				{
					op: "replace",
					field: "tags",
					value: tags,
				},
			],
		};
		let updatedData = { ...data };
		updatedData.app_tags = tags;

		handleUpdate(updatedData, "app_tags", patchObj, true);
	};

	return (
		<div>
			<div
				style={{
					height: 37,
					display: "flex",
					flexDirection: "column",
					background: "#F6F7FA",
					padding: 8,
				}}
			>
				<Typography variant="body_1_bold">Tags</Typography>
			</div>
			<div style={{ padding: 8 }}>
				<div className="flex gap-4 flex-wrap">
					{tags?.map((value, index) => {
						return (
							<button
								className={cn("z__tags_v2-pill")}
								key={value + index}
								onClick={() =>
									setTags((_t) => {
										return _t.filter((d) => d !== value);
									})
								}
							>
								<Typography
									variant="button_extrasmall_regular"
									color="#5287E8"
								>
									{value}
								</Typography>
								<img src={closeIcon} alt="close icon" />
							</button>
						);
					})}
				</div>
			</div>
			{tags.length > 0 && (
				<div
					style={{ paddingRight: 8, paddingLeft: 8, marginBottom: 8 }}
				>
					<div className="separator" />
				</div>
			)}
			<div
				style={{ padding: 8, paddingTop: 0 }}
				className="flex flex-column gap-4"
			>
				<Field
					handlers={{
						onChange: (e) => {
							setInputValue(e.target.value);
						},
						onKeyDown: (e) => {
							if (e.key === "Enter" && inputValue) {
								setTags((_t) => [..._t, inputValue]);
								setInputValue("");
							}
						},
					}}
					prefixIcon={tagIcon}
					type="text"
					fieldType="input"
					value={inputValue}
					placeholder="Add Tags"
					className="font-11 bold-400 grey-1"
				/>
				<div className="flex gap-1">
					<div style={{ width: 8, height: 8 }}>
						<InfoIcon />
					</div>
					<Typography
						variant="alerttag_extrasmall_medium"
						color="#717171"
					>
						Press ENTER after typing to add a tag.
					</Typography>
				</div>
			</div>
			<div
				style={{ padding: "6px 8px 8px 8px" }}
				className="flex gap-2 justify-content-center"
			>
				<Button
					theme={theme}
					variant="primary-default"
					style={{ minWidth: 98 }}
					disabled={!!inputValue}
					onClick={handleSubmit}
				>
					Save
				</Button>
			</div>
		</div>
	);
}

function InfoIcon() {
	return (
		<div
			style={{
				height: "100%",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			}}
		>
			<svg
				width="4"
				height="10"
				viewBox="0 0 4 10"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M3.375 1.7625C3.375 1.96028 3.31635 2.15362 3.20647 2.31807C3.09659 2.48252 2.94041 2.61069 2.75768 2.68638C2.57496 2.76206 2.37389 2.78187 2.17991 2.74328C1.98593 2.7047 1.80775 2.60946 1.66789 2.4696C1.52804 2.32975 1.4328 2.15157 1.39421 1.95759C1.35563 1.76361 1.37543 1.56254 1.45112 1.37981C1.52681 1.19709 1.65498 1.04091 1.81943 0.931027C1.98388 0.821146 2.17722 0.762497 2.375 0.762497C2.64022 0.762497 2.89457 0.867854 3.08211 1.05539C3.26964 1.24293 3.375 1.49728 3.375 1.7625ZM1.875 8.4875V5.5025C1.875 5.43619 1.84866 5.3726 1.80178 5.32572C1.75489 5.27884 1.6913 5.2525 1.625 5.2525H1.375C1.17609 5.2525 0.985322 5.17348 0.844669 5.03283C0.704017 4.89217 0.625 4.70141 0.625 4.5025C0.625 4.30359 0.704017 4.11282 0.844669 3.97217C0.985322 3.83152 1.17609 3.7525 1.375 3.7525H2.62C2.81891 3.7525 3.00968 3.83152 3.15033 3.97217C3.29098 4.11282 3.37 4.30359 3.37 4.5025V8.4875C3.37 8.68641 3.29098 8.87717 3.15033 9.01783C3.00968 9.15848 2.81891 9.2375 2.62 9.2375C2.42109 9.2375 2.23032 9.15848 2.08967 9.01783C1.94902 8.87717 1.87 8.68641 1.87 8.4875H1.875Z"
					fill="#717171"
				/>
			</svg>
		</div>
	);
}
