import React from "react";
import MenuIcon from "assets/v2tables/vertical-menu-icon.svg";
import AddFilter from "assets/v2tables/add-filter.svg";
import SortAscending from "assets/v2tables/sort-ascending.svg";
import SortDescending from "assets/v2tables/sort-descending.svg";
import TickMark from "assets/tick-mark.svg";
import rightarrow from "assets/Icon_Arrow_Right.svg";

import { Popover, Typography } from "@zluri/ui-components";
import PropTypes from "prop-types";
import Filter from "containers/v2table/FilterList/Filter";
import { useTableContext } from "containers/v2table/TableContext/context";

const ColumnDirections = {
	ASC: 1,
	DSC: -1,
};

export default function TableHeaderOptionsMenu({
	columnSortState,
	isFilterable,
	isSortable,
	sortKey,
	enabledFilters,
	onSort,
}) {
	const { enableColumnLevelFilter, disableSort } = useTableContext();

	const isFilterEnabled = enableColumnLevelFilter && isFilterable;
	const isSortEnabled = !disableSort && isSortable;

	if (!(isSortEnabled || isFilterEnabled)) return null;

	const popup = (
		<div className="cell-header-options-menu">
			{isFilterEnabled && (
				<Filter
					popoverPlacement="rightTop"
					enabledFilters={enabledFilters}
				>
					<div className="d-flex align-items-center cursor-pointer justify-content-between cell-header__menu-option">
						<div className="d-flex align-items-center">
							<img
								src={AddFilter}
								alt="add filter"
								className="mr-1"
							/>
							<Typography variant="button_extrasmall_medium">
								Filter Column
							</Typography>
						</div>
						<img src={rightarrow} alt="" />
					</div>
				</Filter>
			)}
			{isSortEnabled && (
				<>
					<div>
						<SortIndicator
							onClick={() => {
								onSort(sortKey, ColumnDirections.ASC);
							}}
							direction="ASC"
							isSelected={columnSortState === 1}
						/>
					</div>
					<div>
						<SortIndicator
							onClick={() => {
								onSort(sortKey, ColumnDirections.DSC);
							}}
							direction="DESC"
							isSelected={columnSortState === -1}
						/>
					</div>
				</>
			)}
		</div>
	);

	return (
		<div className="cursor-pointer cell-header-addcol">
			<Popover
				placement="bottom"
				delay={{ show: 250, hide: 400 }}
				content={popup}
				className="z-v2-column-filter-popover"
				trigger={["click", "focus"]}
			>
				<img src={MenuIcon} alt="menu" />
			</Popover>
		</div>
	);
}

TableHeaderOptionsMenu.propTypes = {
	sortKey: PropTypes.string,
	setIsHovering: PropTypes.func, // TODO: Why??
	columnSortState: PropTypes.number,
	enabledFilters: PropTypes.arrayOf(PropTypes.string),
	onSort: PropTypes.func,
	isFilterable: PropTypes.bool,
	isSortable: PropTypes.bool,
};
function SortIndicator({ isSelected, direction, onClick }) {
	return (
		<button
			onClick={onClick}
			className="d-flex cursor-pointer align-items-center justify-content-between cell-header__menu-option"
		>
			<div className="d-flex align-items-center">
				<img
					src={direction === "DESC" ? SortDescending : SortAscending}
					alt={
						direction === "DESC"
							? "Descending Icon"
							: "Ascending Icon"
					}
					className="mr-1"
				/>
				<Typography variant="button_extrasmall_medium">
					{direction === "DESC"
						? "Sort Descending"
						: "Sort Ascending"}
				</Typography>
			</div>
			{isSelected && <img src={TickMark} alt="tick mark" />}
		</button>
	);
}

SortIndicator.propTypes = {
	isSelected: PropTypes.bool,
	direction: PropTypes.oneOf(["DESC", "ASC"]),
	onClick: PropTypes.func,
};
