import React from "react";
import PropTypes from "prop-types";
import { isEmpty } from "underscore";

import { AppCategoryDescription } from "containers/v2table/cellRenderer/text";
import { isNullOrUndefined } from "modules/applications/utils/appAbout.utils";
import { Divider } from "@zluri/ui-components";

export function AppAboutCategoriesClickPopover({ data }) {
	if (
		isNullOrUndefined(data?.app_sub_categories) ||
		isEmpty(data?.app_sub_categories)
	)
		return;

	const excludeIndexUpto = 1;
	const appSubCategories = data.app_sub_categories.slice(excludeIndexUpto);

	return (
		<div className="p-2">
			{appSubCategories.map((item, index) => (
				<React.Fragment key={item?.category_id}>
					<AppCategoryDescription obj={item} />
					{index !== appSubCategories.length - 1 && (
						<Divider
							backgroundColor="#EBEBEB"
							borderRadius={8}
							height={1}
							className="mx-1 my-2"
						/>
					)}
				</React.Fragment>
			))}
		</div>
	);
}

AppAboutCategoriesClickPopover.propTypes = {
	data: PropTypes.shape({
		app_sub_categories: PropTypes.array,
	}),
};
