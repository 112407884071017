import _ from "underscore";
import { toast } from "react-toastify";
import { Loader } from "common/Loader/Loader";
import { getSignedS3URL } from "./Download.api";
import { SUPPORTED_FILE_FORMATS } from "constants/upload";
import React, { useEffect, useRef, useState } from "react";
import { ApiResponseNotification } from "../ApiResponseNotification/ApiResponseNotification";
import { apiResponseTypes } from "../ApiResponseNotification/ApiResponseNotificationConstants";
import axios from "axios";
import { TriggerIssue } from "utils/sentry";
import { TOAST_COLOR, isV2URL, toast as v2Toast } from "@zluri/ui-components";
import { TOAST_MESSAGE } from "modules/shared/constants/toast.constants";
import { downloadFileAJAX } from "modules/shared/utils/download-csv";
import ToastNotificaion from "UIComponents/Rbac/ToastNotification";
import { RBAC_ERROR, RBAC_ERROR_STATUS_CODES } from "constants/rbac";

export default function DownloadInSamePage({
	loader = <Loader width={24} height={24} />,
	api,
	children,
	tokenLink,
	s3LinkFromProps,
	doc,
	downloadTransactionCSV,
	employeeURLReqd = false,
	useV2Toast,
}) {
	const hiddenAnchor = useRef();
	const hiddenDownloadButton = useRef();
	const [s3Link, setS3Link] = useState();
	const [loading, setLoading] = useState(false);
	const imgExtensions = ["png", "jpg", "jpeg"];
	const isV2 = isV2URL();

	const onError = (err) => {
		const error = err;
		const rbacError =
			(error?.response?.data?.type === RBAC_ERROR &&
				RBAC_ERROR_STATUS_CODES.includes(error?.response?.status)) ||
			false;
		if (!rbacError) {
			if (useV2Toast || isV2) {
				TriggerIssue(TOAST_MESSAGE.FILE_DOWNLOAD_ERROR, err);
				v2Toast(TOAST_MESSAGE.FILE_DOWNLOAD_ERROR, {
					indicatorColor: TOAST_COLOR.ERROR,
					action: {
						color: TOAST_COLOR.OTHER,
						text: "Retry",
						onClick: ({ closeToast }) => {
							closeToast();
							getS3Link();
						},
					},
				});
			} else {
				ApiResponseNotification({
					errorObj: err,
					title: TOAST_MESSAGE.FILE_DOWNLOAD_ERROR,
					responseType: apiResponseTypes.ERROR,
					retry: getS3Link,
				});
			}
		} else {
			toast(<ToastNotificaion />);
		}
	};

	const getS3Link = () => {
		isV2 && toast.update(tokenLink, { autoClose: false });
		setLoading(true);
		(api
			? api(tokenLink.split("=")[1])
			: getSignedS3URL({ file_url: s3LinkFromProps, employeeURLReqd })
		)
			.then((res) => {
				if (res.link) {
					setS3Link(res.link);
				} else if (res.signed_url) {
					setS3Link(res.signed_url);
				} else {
					setLoading(false);
				}
			})
			.catch((err) => {
				setLoading(false);
				onError(err);
			});
	};

	useEffect(() => {
		const handleLinkChange = async () => {
			if (s3Link) {
				if (s3LinkFromPropsIsImage() || s3LinkFromPropsIsPdf()) {
					window.open(s3Link);
				} else {
					if (downloadTransactionCSV) {
						downloadImage(s3Link);
					} else {
						if (isV2) {
							await downloadFileAJAX(s3Link);
							toast.dismiss(tokenLink);
						} else hiddenAnchor.current.click();
					}
				}
				setLoading(false);
				setS3Link();
			}
		};

		handleLinkChange();
	}, [s3Link]);

	function s3LinkFromPropsIsImage() {
		const extension = SUPPORTED_FILE_FORMATS.includes(
			"." + _.last(doc?.type?.split("/"))
		)
			? _.last(doc?.type?.split("/"))
			: _.last(doc?.name?.split("."));
		return imgExtensions.includes(extension);
	}

	function s3LinkFromPropsIsPdf() {
		const extension = SUPPORTED_FILE_FORMATS.includes(
			"." + _.last(doc?.type?.split("/"))
		)
			? _.last(doc?.type?.split("/"))
			: _.last(doc?.name?.split("."));
		return extension === "pdf";
	}

	// useEffect(() => {
	// 	if (s3LinkFromPropsIsImage()) {
	// 		const btn = document.getElementById("hiddenDownloadButton");
	// 		btn.addEventListener("click", (event) => {
	// 			event.preventDefault();
	// 			downloadImage(s3LinkFromProps);
	// 		});
	// 	}
	// }, []);

	function downloadImage(url) {
		axios
			.get(url, {
				responseType: "blob",
			})
			.then((response) => {
				const blob = new Blob([response.data], {
					type: "text/csv",
				});
				let a = document.createElement("a");
				a.download = doc?.name || url.replace(/^.*[\\\/]/, "");
				a.href = window.URL.createObjectURL(blob);
				document.body.appendChild(a);
				a.click();
				a.remove();
			})
			.catch((err) => {
				onError(err);
			});
	}

	return (
		<>
			<a href={s3Link} hidden={true} download ref={hiddenAnchor} />
			<button
				hidden={true}
				id="hiddenDownloadButton"
				ref={hiddenDownloadButton}
			/>
			<div
				className="cursor-pointer"
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
					getS3Link();
				}}
			>
				{loading ? loader : children}
			</div>
		</>
	);
}
