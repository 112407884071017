export const EntityConfiguration = {
	application: {},
};

export const entityNameMapForQuickFilters = {
	app_review_users: "Review Users",
	app_users_emp_view: "Review Users",
};

export const ExportEntityEnum = Object.freeze({
	APPLICATIONS: "applications",
	APPLICATION_MONTH_COST: "application_month_cost",
	APPLICATION_MONTH_SPEND: "application_month_spend",
	INACTIVE_APPOWNER_USERS: "inactive_appowner_users",
	APPLICATIONS_TRANSACTIONS: "applications_transactions",
	REDUNDANT_APPLICATIONS: "redundant_applications",
	SECURITY_COMPLIANCE: "security_compliance",
	DEPARTMENTS: "departments",
	DEPARTMENTS_APPLICATIONS: "departments_applications",
	DEPARTMENTS_USERS: "departments_users",
	DEPARTMENT_BUDGET: "department_budget",
	DEPARTMENTS_COST: "departments_cost",
	DEPARTMENTS_SPEND: "departments_spend",
	CONTRACTS: "contracts",
	CONTRACTS_RENEWALS: "contracts_renewals",
	LICENSES: "licenses",
	LICENSE_CONTRACTS: "license_contracts",
	LICENSE_PERPETUALS: "license_perpetuals",
	LICENSE_OPTIMIZATION: "license_optimization",
	LICENSE_SUBSCRIPTIONS: "license_subscriptions",
	OPTIMIZATION_SUMMARY: "optimization_summary",
	RECOGNIZED_TRANSACTIONS: "recognized_transactions",
	UNRECOGNIZED_TRANSACTIONS: "unrecognized_transactions",
	ARCHIVED_TRANSACTIONS: "archived_transactions",
	PAYMENTMETHOD_COST: "paymentmethod_cost",
	USERS: "users",
	USERS_ACTIVITY: "users_activity",
	APPLICATIONS_USERS: "applications_users",
	USERS_APPLICATIONS: "users_applications",
	USERS_COST: "users_cost",
	CRITICAL_USERS: "critical_users",
	DESKTOP_AGENTS: "desktop_agents",
	COMPLIANCE: "compliance",
	COST_CENTER: "cost_center",
	APPLICATION_SUITE: "application_suite",
	VENDORS: "vendors",
	AGENTS_USERS: "agents_users",
	APPLICATION_CHARGEBACK: "application_chargeback",
	APPLICATION_SPEND: "application_spend",
	APPLICATION_USERS: "application_users",
	APPLICATION_LICENSE: "application_license",
	APPLICATION_MAP_LICENSE: "application_map_license",
	REVIEWERS: "reviewers",
	COMPLETED_CAMPAIGNS_DATA: "completed_campaigns_data",
});

export type ExportEntityType =
	(typeof ExportEntityEnum)[keyof typeof ExportEntityEnum];
