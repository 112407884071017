import React from "react";
import PropTypes from "prop-types";
import V2Table from "containers/V2Table";

const CurtainTable = ({
	entity,
	intID,
	paginationEntity,
	exportEntity,
	apiProps,
}) => {
	return (
		<V2Table
			entity={entity}
			intID={intID}
			paginationEntity={paginationEntity}
			hideCustomExport
			exportEntity={exportEntity}
			hideExportAsCSV
			apiProps={apiProps}
			showCheckboxColumn={false}
		/>
	);
};

export default CurtainTable;

CurtainTable.propTypes = {
	entity: PropTypes.string,
	intID: PropTypes.string,
	paginationEntity: PropTypes.string,
	exportEntity: PropTypes.string,
	apiProps: PropTypes.object,
};
