import React, { useEffect, useRef, useState } from "react";
import "./styles.css";

import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { debounce } from "underscore";
import {
	Button,
	theme,
	Popover,
	Typography,
	Divider,
} from "@zluri/ui-components";

import {
	FILTER_ICON_MAP,
	FILTER_TYPE_RENDERER,
	FilterComponent,
	useFilterOptions,
	useFilterState,
} from "containers/v2table/FilterList/hooks";
import cn from "classnames";
import { useTableContext } from "containers/v2table/TableContext/context";
import Icon from "components/Icon";
import SearchBar from "../Searchbar";
import { isEmpty } from "underscore";

const Filter = ({ children, enabledFilters, popoverPlacement }) => {
	const { dataEntity } = useTableContext();

	const [open, setOpen] = useState(false);

	const {
		filterState,
		hasFilterChanged,
		onRemoveFilterByName,
		onFilterUpdate,
		onApplyFilter,
		onClearAllFilter,
		onResetFilter,
	} = useFilterState({ enabledFilters, open });

	//default applied filters for the table coming from the api response
	const appliedFilters = useSelector(
		(state) => state.v2Table[dataEntity]?.filter_by
	);

	const { options: filterOptions } = useFilterOptions({
		enabledFilters,
	});

	if (!filterOptions?.length) return children;
	if (filterOptions.length === 1) {
		const data = filterOptions?.[0];
		return (
			<Popover
				className="z-v2-filter__popover"
				show={open}
				onToggle={setOpen}
				content={
					<FilterComponent
						filterData={filterState?.[data.field_name]}
						onChange={onFilterUpdate.bind(null, data)}
						onRemove={() => {
							onClearAllFilter();
							setOpen(false);
						}}
						filter={data}
					/>
				}
				footer={
					<div className="z-v2-filter__footer">
						<Button
							theme={theme}
							variant="secondary-default"
							style={{ width: "100%" }}
							disabled={!hasFilterChanged}
							onClick={() => {
								onApplyFilter();
								setOpen(false);
							}}
						>
							Save Filter
						</Button>
					</div>
				}
				trigger={["click"]}
				placement={popoverPlacement ?? "bottom"}
			>
				{children}
			</Popover>
		);
	}

	const filtersCount = Object.values(filterState)?.filter(
		(f) => f.state === "REMOVED" || f.state === "UPDATED"
	);

	return (
		<Popover
			className="z-v2-filter__popover"
			show={open}
			onToggle={setOpen}
			content={
				<FilterMenu
					applicableFilters={filterOptions}
					handleRemove={onRemoveFilterByName}
					handleUpdate={onFilterUpdate}
					filterState={filterState}
				/>
			}
			footer={
				<FilterFooter
					onReset={() => {
						onResetFilter();
						setOpen(false);
					}}
					onApply={() => {
						onApplyFilter();
						setOpen(false);
					}}
					// filtersCount={}
					disableApply={!filtersCount.length}
					disableClear={!appliedFilters?.length}
				/>
			}
			trigger={["click"]}
			placement={popoverPlacement ?? "bottom"}
		>
			{children}
		</Popover>
	);
};

export const FilterFooter = ({
	onReset,
	onApply,
	disableClear,
	disableApply,
}) => {
	return (
		<div className="z-v2-filter__footer">
			<Button
				theme={theme}
				variant="primary-alternative"
				onClick={onReset}
				disabled={disableClear}
				className="z-v2-filter__footer-reset-button"
			>
				Reset Defaults
			</Button>
			<Button
				theme={theme}
				variant="primary-default"
				onClick={onApply}
				disabled={disableApply}
			>
				Apply
			</Button>
		</div>
	);
};
FilterFooter.propTypes = {
	onReset: PropTypes.func,
	onApply: PropTypes.func,
	disableClear: PropTypes.bool,
	disableApply: PropTypes.bool,
};
export default Filter;

export function FilterMenu({
	applicableFilters,
	handleUpdate,
	filterState,
	handleRemove,
}) {
	const [displayedFilters, setDisplayedFilters] = useState(applicableFilters);
	const debouncedText = debounce((e) => {
		const _text = e.target.value.toUpperCase();
		setDisplayedFilters(
			applicableFilters.filter((a) =>
				a.field_name?.toUpperCase().includes(_text)
			)
		);
	}, 300);

	const containerRef = useRef(null);

	if (applicableFilters?.length === 1) {
		const data = applicableFilters?.[0];
		return (
			<FilterComponent
				filterData={filterState?.[data.field_name]}
				onChange={handleUpdate.bind(null, data)}
				onRemove={handleRemove.bind(null, data.field_name)}
				filter={data}
			/>
		);
	}

	return (
		<div className="z-v2-filter-menu-container">
			{applicableFilters?.length > 6 && (
				<>
					<header>
						<SearchBar
							onChange={debouncedText}
							onClear={() => {
								setDisplayedFilters(applicableFilters);
							}}
							placeholder="Search Table Filters..."
							autoFocus
						/>
					</header>
					<Divider height={1} color="#e1e8f8" />
				</>
			)}

			<div
				className="z-v2-filter-menu d-flex flex-column gap-2"
				style={{ height: applicableFilters?.length > 6 ? 240 : "auto" }}
				ref={containerRef}
			>
				{(displayedFilters?.length ?? 0) === 0 && <EmptyState />}
				{displayedFilters?.map((a) => {
					if (!a.field_name) return null;
					const id = a.field_name;

					const state = filterState[id]?.state;
					const isApplied = filterState[id]?.isApplied;
					const isUpdated =
						(state === "UPDATED") | (state === "REMOVED");

					if (!FILTER_TYPE_RENDERER[a.field_type]?.renderer)
						return null;
					return (
						<FilterMenuPopover
							handleRemove={handleRemove}
							handleUpdate={handleUpdate}
							data={a}
							filterState={filterState}
							key={id}
							containerRef={containerRef}
							isSelected={isApplied}
						>
							<div className="d-flex justify-content-between align-items-center gap-6 flex-grow-1">
								<img
									src={
										FILTER_ICON_MAP?.[a?.icon] ||
										FILTER_ICON_MAP.icon_database
									}
									alt={id}
								/>
								<Typography
									variant="button_extrasmall_medium"
									className="flex-1"
								>
									{a.field_name}
								</Typography>
								<div className="z-v2-filter__state-icon-container">
									{!!(isApplied || isUpdated) && (
										<div
											className={cn(
												"z-v2-filter__state-icon",
												isApplied &&
													"z-v2-filter__state-icon-active",
												isUpdated &&
													"z-v2-filter__state-icon-updated",
												isApplied &&
													isUpdated &&
													"z-v2-filter__state-icon-active-updated"
											)}
										>
											<Icon name="tick" />
										</div>
									)}
								</div>
							</div>
						</FilterMenuPopover>
					);
				})}
			</div>
		</div>
	);
}
FilterMenu.propTypes = {
	filterState: PropTypes.arrayOf(PropTypes.object), // TODO: create shape
	handleRemove: PropTypes.func,
	handleUpdate: PropTypes.func,
	applicableFilters: PropTypes.arrayOf(PropTypes.object), // TODO: create shape
};

function EmptyState() {
	return (
		<div
			style={{
				height: 240,
				flex: 1,
				overflowY: "scroll",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				flexDirection: "column",
				maxWidth: 240,
				margin: "0px auto",
				textAlign: "center",
			}}
			className="hide-scrollbar"
		>
			<div
				style={{
					backgroundColor: "#E1E8F8",
					borderRadius: "100%",
					height: 50,
					width: 50,
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					gap: 8,
				}}
			>
				<Icon name="search" style={{ color: "#5287E8" }} />
			</div>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center",
					gap: 4,
				}}
			>
				<Typography variant="body_2_bold" color="secondary_grey_1">
					No results
				</Typography>{" "}
				<Typography variant="body_2_regular" color="secondary_grey_1">
					We couldn't find any filters matching your search!
				</Typography>
			</div>
		</div>
	);
}
const FilterMenuPopover = React.memo(
	({
		handleRemove,
		handleUpdate,
		data,
		filterState,
		children,
		containerRef,
		isSelected,
	}) => {
		const { field_name } = data;
		const id = field_name;
		const [show, setShow] = useState(false);

		useEffect(() => {
			const element = containerRef?.current;
			if (element) {
				const handleScroll = () => {
					setShow(false);
				};

				element.addEventListener("scroll", handleScroll);

				return () =>
					element.removeEventListener("scroll", handleScroll);
			}
		}, [containerRef]);

		const footer = (
			<div className="z-v2-filter__footer">
				<Button
					theme={theme}
					variant="secondary-default"
					style={{ width: "100%" }}
					onClick={() => {
						setShow(false);
					}}
					disabled={isEmpty(filterState?.[id]?.field_values)}
				>
					Save Filter
				</Button>
			</div>
		);

		return (
			<Popover
				className="z-v2-filter-option__popover z-v2-column-filter-popover"
				trigger={["click"]}
				show={show}
				onToggle={setShow}
				placement="right"
				footer={footer}
				content={
					<FilterComponent
						filterData={filterState?.[id]}
						onChange={(a, b) => {
							// TODO: find a,b (??)
							handleUpdate(data, a, b);
						}}
						filter={data}
						onRemove={() => handleRemove(id)}
					/>
				}
			>
				<div
					className={cn(
						"z-v2-column-filter-option",
						(isSelected || show) &&
							"z-v2-column-filter-option-selected"
					)}
				>
					{children}
				</div>
			</Popover>
		);
	}
);
FilterMenuPopover.propTypes = {
	isSelected: PropTypes.bool,
	handleRemove: PropTypes.func,
	handleUpdate: PropTypes.func,
	data: PropTypes.shape({
		field_name: PropTypes.string,
	}),
	children: PropTypes.elementType.isRequired,
	containerRef: PropTypes.oneOfType([
		// Either a function
		PropTypes.func,
		// Or the instance of a DOM native element (see the note about SSR)
		PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
	]),
	filterState: PropTypes.object, // TODO: create shape
};
FilterMenuPopover.displayName = "FilterMenuPopover";

Filter.propTypes = {
	// This is `field_ids` available for each column in property_file or `field_id` in each filter data
	enabledFilters: PropTypes.arrayOf(PropTypes.string),
	children: PropTypes.oneOfType([
		PropTypes.element,
		PropTypes.arrayOf(PropTypes.element),
	]).isRequired,
	popoverPlacement: PropTypes.string, // TODO: use enum
};
