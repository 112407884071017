import React from "react";
import { Progress } from "./progress";
import { Score, UsageBenchmark } from "./score";

import { UsageQuality } from "./usageQuality";

export function Usage({ data, component }) {
	return (
		<div style={{ width: "220px" }}>
			<div className=" font-11 black-1 p-2">
				<div>
					<div className="d-flex mb-2 align-items-center">
						<Progress data={data} component={component} />
						<div className="bold-600">
							<Score
								data={data}
								component={component}
								style={{ fontSize: "12px", marginLeft: "8px" }}
							/>{" "}
							Usage{" "}
						</div>
					</div>

					<div>
						<UsageBenchmark data={data} />
					</div>
				</div>
			</div>
			<div
				className="p-2 usage-usage-qyuality"
				style={{ background: "#F6F7FA" }}
			>
				<p className="grey-1 font-8 m-0 bold-500 mb-1">
					Usage data for{" "}
					<span className="text-capitalize">{data.app_name}</span> is{" "}
					{
						<UsageQuality
							data={data}
							component={component}
							style={{ fontSize: "8px!important" }}
						/>
					}
				</p>
				<p className="bold-500 font-8 glow_blue mb-0">
					Learn more about Data Quality
				</p>
			</div>
		</div>
	);
}
