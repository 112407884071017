import React from "react";

import moment from "moment";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { isNull, isUndefined } from "underscore";
import { BODY_VARIANT, GREY_VARIANT } from "@zluri/ui-components";

import { SummaryCardPillWithImage } from "modules/Optimization/OptimizationV3/SummaryCardPillWithImage";

import LicensesIcon from "modules/applications/components/chargebacks/assets/Icon_Licenses (1).svg";
import PotentialSavingsIcon from "modules/Optimization/assets/Icon_PotentialSavings.svg";
import SavingIcon from "modules/Optimization/assets/Icon_Saving.svg";

import {
	optimizationAmountType,
	optimizationDefaultFilter,
	optimizationSummaryColumns,
	optimizationTableColumnTypes,
	optimizationAppsTableColumns,
	optimizationSummaryLicenseColumn,
	optimizationAppUsersTableColumns,
	optimizationLicenseClassifications,
	optimizationAreaChartDropdownAllOption,
	optimizeNowUsersListColumns,
	pastOptimizationUsersListColumns,
	realizedSavingUsersListColumns,
	OptimizationTableColumnGroups,
} from "../constants/OptimizationConstants";
import { handleSummaryAmt } from "modules/applications/utils/Spends";
import {
	internationalFormatNumberFormatter,
	kFormatter,
} from "constants/currency";
import { HandleToolTip } from "modules/applications/components/chargebacks/utils/chargeback";
import { dateResetTimeZone, MONTH } from "utils/DateUtility";
import { getTotalLicenseRowData } from "modules/licenses/utils/LicensesUtils";
import { FULL_MONTH } from "utils/DateUtility";
import { sanitizeCSVCell, sanitizeCSVCellForExcel } from "utils/common";
import Icon from "components/Icon";

export const generateCSVData = (data, keyField, forExcel = false) => {
	function csvFormatter(action) {
		return generateLicenseCSVRow(
			action.monthly_data,
			keyField,
			action.license_name,
			action.contract_name
		);
	}

	let csvData = Array.isArray(data?.map(csvFormatter))
		? data?.map(csvFormatter)
		: [];

	if (forExcel) {
		for (const row of csvData) {
			for (const key in row) {
				row[key] = sanitizeCSVCellForExcel(row[key]);
			}
		}
	} else {
		for (const row of csvData) {
			for (const key in row) {
				row[key] = sanitizeCSVCell(row[key]);
			}
		}
	}

	return csvData;
};

const generateLicenseCSVRow = (data, keyField, license_name, contract_name) => {
	const cost_key = `${keyField}_cost`;

	const license_row = {
		"License Name": license_name,
		"Contract Name": contract_name,
	};

	data?.map((cell) => {
		license_row[`${MONTH[cell.month_id - 1]} ${cell.year_id} Quantity`] =
			cell[keyField] || "0";
		if (keyField !== "actively_used") {
			license_row[
				`${MONTH[cell.month_id - 1]} ${
					cell.year_id
				} Savings(+)/Wastage(-)`
			] = `${
				cell.license_savings_type === optimizationAmountType.WASTAGE
					? "-"
					: ""
			}${cell[cost_key] || "0"}`;
		}
	});

	return license_row;
};

export const getLicenseListFromOptimizationData = (data) => {
	return data?.table_data?.map((td) => {
		return {
			license_id: td.license_id,
			license_name: td.license_name,
			contract_id: td.contract_id,
			contract_name: td.contract_name,
		};
	});
};

export const getLicenseListForOverviewTableTooltip = (
	data,
	optimizationFunnel,
	date
) => {
	const month = date.getMonth() + 1;
	const year = date.getFullYear();

	const optimizationFunnelData =
		data[
			optimizationFunnel === "total"
				? optimizationLicenseClassifications.ACTIVELY_USED
				: optimizationFunnel
		];

	const licenseList =
		Array.isArray(optimizationFunnelData?.table_data) &&
		optimizationFunnelData?.table_data?.map((license) => {
			const licenseObj = {};
			licenseObj.license_name = license.license_name;
			const licenseDetailsForDate = license.monthly_data.find(
				(monthlyData) =>
					monthlyData.year_id === year &&
					monthlyData.month_id === month
			);
			licenseObj.quantity =
				licenseDetailsForDate[
					optimizationFunnel === "total"
						? "total_license"
						: optimizationFunnel
				];
			licenseObj.cost =
				licenseDetailsForDate[`${optimizationFunnel}_cost`];
			return licenseObj;
		});

	return licenseList || [];
};

export const showAppOptimizationGetStarted = (app) => {
	if (
		app?.app_active_contracts_count > 0 &&
		app?.app_user_with_license_count > 0
	) {
		return false;
	}
	return true;
};

export const showContractOptimizationGetStarted = (contract) => {
	if (getTotalLicenseRowData(contract).in_use > 0) {
		return false;
	}
	return true;
};

export const getOptimizationTableColumnGroups = (v2Entity) => {
	let optimizationColumns = [];

	switch (v2Entity) {
		case OptimizationTableColumnGroups.APPLICATIONS:
			optimizationColumns = optimizationAppsTableColumns;
			break;
		case OptimizationTableColumnGroups.APPLICATION_USERS:
			optimizationColumns = optimizationAppUsersTableColumns;
			break;
		case OptimizationTableColumnGroups.OPTIMIZATION_SUMMARY:
			optimizationColumns = optimizationSummaryColumns;
			break;
		case OptimizationTableColumnGroups.OPTIMIZE_NOW_USERS_LIST:
			optimizationColumns = optimizeNowUsersListColumns;
			break;
		case OptimizationTableColumnGroups.PAST_OPTIMIZATION_USERS_LIST:
			optimizationColumns = pastOptimizationUsersListColumns;
			break;
		case OptimizationTableColumnGroups.OPTIMIZATION_SUMMARY_LICENSE_BREAKDOWN:
			optimizationColumns =
				getOptimizationSummaryLicenseBreakdownColumns();
			break;
		case OptimizationTableColumnGroups.REALIZED_SAVING_USERS_LIST:
			optimizationColumns = realizedSavingUsersListColumns;
			break;
		default:
			optimizationColumns = [];
	}
	const optimizationColumnGroups = {};

	for (const column of optimizationColumns) {
		optimizationColumnGroups[column.group_name] = {
			dataField: column.group_name,
			text: column.header,
			sortKey: column.group_name,
			formatter:
				column.formatter ||
				optimizationTableColumnTypes[column.group_type].formatter,
		};
	}

	return optimizationColumnGroups;
};

export const getHyperLinkMetaData = (
	optimizationType,
	selectedFilter = optimizationDefaultFilter,
	licenseId = null,
	settings,
	optimizationReason = null
) => {
	const usageForFilter = Number(selectedFilter.split("_")[1]);
	const monthForFilter = Number(selectedFilter.split("_")[3]);

	const columns = [
		{
			group_name: "user",
			field_ids: [
				"user_id",
				"user_name",
				"user_profile",
				"user_app_id",
				"app_status",
				"user_status",
				"user_account_type",
				"user_app_status",
				"user_app_discovered",
				"user_app_archive",
				"user_archive",
				"user_alternate_emails",
			],
			is_sortable: true,
		},
		{
			group_name: "user_status",
			field_ids: ["user_status"],
			is_sortable: true,
		},
		{
			group_name: "user_email",
			field_ids: ["user_email"],
			is_sortable: true,
		},
		{
			group_name: "license_details",
			field_ids: ["contracts", "licenses"],
			is_sortable: true,
		},
		{
			group_name: "reason",
			field_ids: ["reason"],
			is_sortable: true,
		},
		{
			group_name: "monthly_potential_savings",
			field_ids: ["monthly_potential_savings"],
			is_sortable: true,
		},
		{
			group_name: "app_source",
			field_ids: ["source_array"],
			is_sortable: false,
		},
		{
			group_name: "user_app_last_used",
			field_ids: ["user_app_last_used", "user_app_last_sync"],
			is_sortable: true,
		},
		{
			group_name: "ellipsis",
			field_ids: ["user_app_id"],
			is_sortable: false,
		},
	];

	const meta = {
		columns: columns,
		filter_by: [
			{
				field_id: "license_mapped",
				field_name: "License Mapped",
				field_values: true,
				filter_type: "boolean",
				negative: false,
				is_custom: false,
			},
			{
				field_values: false,
				field_id: "user_archive",
				filter_type: "boolean",
				field_name: "User Archive",
				negative: false,
				is_custom: false,
			},
			{
				field_id: "user_app_archive",
				field_name: "User Application Archive",
				field_values: false,
				filter_type: "boolean",
				negative: false,
				is_custom: false,
			},
			{
				field_id: "licenses.is_optimisable",
				field_name: "Is License Optimizable?",
				field_values: true,
				filter_type: "boolean",
				negative: false,
				is_custom: false,
			},
		],
		sort_by: [],
	};

	if (licenseId) {
		meta.filter_by.push({
			field_id: "licenses.license_id",
			field_name: "License Id",
			field_values: [licenseId],
			filter_type: "objectId",
			negative: false,
			is_custom: false,
		});
	}

	if (optimizationReason) {
		meta.filter_by.push({
			field_id: "reason",
			field_name: "Optimizability Reason",
			field_values: [optimizationReason],
			filter_type: "search_in_string",
			field_order: "contains",
			negative: false,
			is_custom: false,
		});
	}

	switch (optimizationType) {
		case optimizationLicenseClassifications.ACTIVELY_USED:
			meta.filter_by.push({
				field_id: "user_status",
				field_name: "User Status",
				field_values: ["active", "suspended"],
				filter_type: "string",
				field_order: "contains",
				negative: false,
				is_custom: false,
			});
			meta.filter_by.push({
				field_id: "user_app_last_used",
				field_name: "Last Used",
				field_values: [
					`${dateResetTimeZone(
						new Date(
							new Date().setDate(
								new Date().getDate() - monthForFilter * 30
							)
						)
					)}`,
				],
				filter_type: "date_range",
				field_order: ["gt"],
				negative: false,
				is_custom: false,
				timestamp_type: true,
			});
			meta.filter_by.push({
				field_id: "user_app_current_month_usage",
				field_name: "Usage (Current month)",
				field_values: [usageForFilter],
				filter_type: "range",
				field_order: ["lt"],
				negative: false,
				is_custom: false,
			});
			break;
		default:
			break;
	}

	return JSON.stringify(meta);
};

export const getOptimizationSummaryLicenseBreakdownColumns = () => {
	const columns = [...optimizationSummaryColumns].map((col) =>
		col.group_name === "app_cost_per_license"
			? {
					group_name: "app_cost_per_license",
					dataField: "app_cost_per_license",
					group_type: "cost",
					header: "Cost/License (per month)",
				}
			: col
	);
	columns.splice(0, 2);
	columns.unshift(optimizationSummaryLicenseColumn);
	return columns;
};

export const getContinuousOptimizationBannerTextsAndColor = (settings) => {
	const subtextArray = [];
	if (
		// settings?.left_org_licenses_settings?.optimization_action &&
		settings?.left_org_licenses_settings?.repeat_interval &&
		settings?.left_org_licenses_settings?.repeat_interval !== "never"
		// settings?.left_org_licenses_settings?.playbook?._id
	) {
		subtextArray.push("undeprovisioned");
	}
	if (
		// settings?.unused_licenses_settings?.optimization_action &&
		settings?.unused_licenses_settings?.repeat_interval &&
		settings?.unused_licenses_settings?.repeat_interval !== "never"
		// settings?.unused_licenses_settings?.playbook?._id
	) {
		subtextArray.push("unused");
	}
	// if (
	// 	// settings?.under_used_licenses_settings?.optimization_action &&
	// 	settings?.under_used_licenses_settings?.repeat_interval &&
	// 	settings?.under_used_licenses_settings?.repeat_interval !== "never"
	// 	// settings?.under_used_licenses_settings?.playbook?._id
	// ) {
	// 	subtextArray.push("underused");
	// }

	return {
		enabledValueText:
			subtextArray.length === 2
				? "Enabled"
				: subtextArray.length === 0
					? "Disabled"
					: `Partially Enabled`,
		enabledValueTextColor:
			subtextArray.length === 2
				? "#5fcf64"
				: subtextArray.length === 0
					? "#ff6767"
					: "#FFA217",

		enabledValueSubText:
			subtextArray.length === 2
				? "Enabled for undeprovisioned and unused"
				: subtextArray.length === 0
					? "Disabled for undeprovisioned and unused"
					: `Enabled for ${subtextArray.join(" and ")}`,
	};
};

export const getLicenseListForAreaChartDropdown = (data) => {
	const optimizationFunnelData =
		data?.[optimizationLicenseClassifications.ACTIVELY_USED];

	const licenseList = Array.isArray(optimizationFunnelData?.table_data)
		? optimizationFunnelData?.table_data?.map((license) => {
				const licenseObj = {};
				licenseObj.license_id = license.license_id;
				licenseObj.license_name = license.license_name;
				return licenseObj;
			})
		: [];

	return [...licenseList, optimizationAreaChartDropdownAllOption];
};

export const getOptimizationAreaChartData = (data) => {
	const licenses = getLicenseListForAreaChartDropdown(data);

	const area_graph_data = {};

	for (const license of licenses) {
		const graph_data = [];
		if (
			license.license_id ===
			optimizationAreaChartDropdownAllOption.license_id
		) {
			const unassigned_graph_data = data?.unassigned?.graph_data;
			const left_org_graph_data = data?.left_org?.graph_data;
			const unused_graph_data = data?.unused?.graph_data;
			const under_used_graph_data = data?.under_used?.graph_data;
			const actively_used_graph_data = data?.actively_used?.graph_data;

			const graph_data_length = actively_used_graph_data?.length || 0;

			for (let i = 0; i < graph_data_length; i++) {
				const graph_data_for_month_year = {};
				graph_data_for_month_year.month_id =
					unassigned_graph_data[i]?.month_id ||
					unassigned_graph_data[i]?.month ||
					0;
				graph_data_for_month_year.year_id =
					unassigned_graph_data[i]?.year_id ||
					unassigned_graph_data[i]?.year ||
					0;

				graph_data_for_month_year.unassigned =
					unassigned_graph_data[i]?.unassigned || 0;
				graph_data_for_month_year.left_org =
					left_org_graph_data[i]?.left_org || 0;
				graph_data_for_month_year.unused =
					unused_graph_data[i]?.unused || 0;
				graph_data_for_month_year.under_used =
					under_used_graph_data[i]?.under_used || 0;
				graph_data_for_month_year.actively_used =
					actively_used_graph_data[i]?.actively_used || 0;
				graph_data_for_month_year.total =
					actively_used_graph_data[i]?.total_license ||
					actively_used_graph_data[i]?.total ||
					0;
				graph_data.push(graph_data_for_month_year);
			}

			area_graph_data[optimizationAreaChartDropdownAllOption.license_id] =
				graph_data;
		} else {
			const unassigned_graph_data =
				data.unassigned.table_data.find(
					(license_data) =>
						license_data.license_id === license.license_id
				)?.monthly_data || [];
			const left_org_graph_data =
				data.left_org.table_data.find(
					(license_data) =>
						license_data.license_id === license.license_id
				)?.monthly_data || [];
			const unused_graph_data =
				data.unused.table_data.find(
					(license_data) =>
						license_data.license_id === license.license_id
				)?.monthly_data || [];
			const under_used_graph_data =
				data.under_used.table_data.find(
					(license_data) =>
						license_data.license_id === license.license_id
				)?.monthly_data || [];
			const actively_used_graph_data =
				data.actively_used.table_data.find(
					(license_data) =>
						license_data.license_id === license.license_id
				)?.monthly_data || [];

			const graph_data_length = actively_used_graph_data.length;

			for (let i = 0; i < graph_data_length; i++) {
				const graph_data_for_month_year = {};
				graph_data_for_month_year.month_id =
					unassigned_graph_data[i]?.month_id ||
					unassigned_graph_data[i]?.month ||
					0;
				graph_data_for_month_year.year_id =
					unassigned_graph_data[i]?.year_id ||
					unassigned_graph_data[i]?.year ||
					0;

				graph_data_for_month_year.unassigned =
					unassigned_graph_data[i]?.unassigned || 0;
				graph_data_for_month_year.left_org =
					left_org_graph_data[i]?.left_org || 0;
				graph_data_for_month_year.unused =
					unused_graph_data[i]?.unused || 0;
				graph_data_for_month_year.under_used =
					under_used_graph_data[i]?.under_used || 0;
				graph_data_for_month_year.actively_used =
					actively_used_graph_data[i]?.actively_used || 0;
				graph_data_for_month_year.total =
					actively_used_graph_data[i]?.total_license ||
					actively_used_graph_data[i]?.total ||
					0;
				graph_data.push(graph_data_for_month_year);
			}

			area_graph_data[license.license_id] = graph_data;
		}
	}
	return area_graph_data;
};

export const optimizeNowSingleUserCheck = (checked, setChecked, id) => {
	const _checked = [...checked];
	const index = _checked.findIndex((_id) => _id === id);
	if (index > -1) {
		_checked.splice(index, 1);
	} else {
		_checked.push(id);
	}
	setChecked([..._checked]);
};

export const optimizeNowPageIsChecked = (users, checked) => {
	let flag = "CHECKED";
	for (const user of users) {
		if (!checked.includes(user._id)) {
			flag = "UNCHECKED";
		}
	}
	if (
		flag !== "CHECKED" &&
		users.some((user) => checked.includes(user._id))
	) {
		flag = "PARTIALLY CHECKED";
	}
	return flag;
};

export const optimizeNowTablePageCheck = (users, checked, setChecked) => {
	const _checked = [...checked];
	switch (optimizeNowPageIsChecked(users, checked)) {
		case "UNCHECKED":
			for (const user of users) {
				_checked.push(user._id);
			}
			setChecked([..._checked]);
			return;
		case "CHECKED":
		case "PARTIALLY CHECKED":
		default:
			for (const user of users) {
				const index = _checked.findIndex((id) => id === user._id);
				if (index > -1) {
					_checked.splice(index, 1);
				}
			}
			setChecked([..._checked]);
			return;
	}
};

//Optmization chart utils

const todayLineIndex = (chartData) => {
	return (
		chartData &&
		chartData?.findIndex(
			(val) =>
				val ===
				`${FULL_MONTH[moment().month()].slice(0, 3)}'${
					moment().year() % 100
				}`
		)
	);
};

const generateTooltipItemMarkup = ({
	headerText,
	name,
	value,
	indicatorColor,
	includeDivider,
}) => {
	return `
	<div
    class="d-flex justify-content-between align-items-center"
    style="gap: 40px"
  >
    <div
      style="
        font-family: Sora;
        font-size: 11px;
        font-style: normal;
        font-weight: ${headerText ? "700" : "500"};
        line-height: 125%;
        letter-spacing: 0.028px;
        color: ${headerText ? "#484848" : "#717171"};
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 6px;
      "
    >
      <span
        style="
          display: inline-block;
          background-color: ${indicatorColor ?? "#2266E2"};
          height: 7px;
          width: 7px;
          transform: rotate(45deg);
        "
      ></span
      >${name}
    </div>
    <div
			style="
				font-family: Sora;
        font-size: ${headerText ? "11px" : "9px"};
        font-style: normal;
        font-weight: ${headerText ? "700" : "400"};
        line-height: 125%;
        letter-spacing: 0.028px;
        color: #717171;
			"
		>${value}</div>
  </div>
	${
		includeDivider
			? '<div style="height: 1px; background-color: #ebebeb"></div>'
			: ""
	}
	`;
};

export const optimizableV3ChartConfig = (graphData, yOffset) => {
	return {
		chart: {
			type: "area",
			height: 200,
			plotBackgroundColor: "#E6F5FF",
			marginBottom: 4,
			borderRadius: 10,
		},
		title: {
			text: "",
		},
		contextMenu: {
			enabled: false, // Disable the context menu
		},
		exporting: {
			enabled: false, // Disable exporting options
		},
		navigation: {
			buttonOptions: {
				enabled: false, // Disable the print button
			},
		},
		legend: {
			enabled: false,
		},
		xAxis: {
			categories: graphData?.xData,
			opposite: true,
			gridLineWidth: "1px",
			lineWidth: 0,
			tickWidth: 0,
			max:
				graphData?.xData?.length >= 11
					? 11
					: graphData?.xData?.length - 1,
			labels: {
				stpe: 2,
				style: {
					fontSize: 11,
				},
			},
			plotLines: [
				{
					color: "#2266e2",
					value: todayLineIndex(graphData.xData),
					width: 0.8,
					zIndex: 10,
					label: {
						useHTML: true,
						formatter: function () {
							return `
								<div class="d-flex flex-column align-items-center" style="margin-top:10px">
									<span class="primary-color bold-500 font-10" style="">Today</span>
									<span class="rounded-circle primary-color-bg" style="width:6.5px; height: 6.5px"></span>
								</div>
							`;
						},
						rotation: 360,
						x: -15,
						y: -12,
					},
				},
			],
			plotBands: [
				{
					color: {
						pattern: {
							path: "M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11",
							color: "#ebebeb",
							width: 10,
							height: 10,
							zIndex: 100,
						},
					},
					from: todayLineIndex(graphData.xData),
					to:
						todayLineIndex(graphData.xData) !== -1
							? graphData.xData && graphData.xData?.length - 1
							: null,
				},
			],
			crosshair: {
				width: 1,
				color: "#FE6955",
				dashStyle: "dash",
			},
		},
		yAxis: {
			min: 0,
			tickAmount: 3,
			title: {
				text: "Optimizable",
				style: {
					fontSize: 11,
					fontWeight: 600,
					color: "#000000",
				},
			},
			labels: {
				style: {
					fontSize: 11,
				},
				align: "right",
			},
			gridLineDashStyle: "longdash",
			gridLineColor: "#A0D8FF",
			offset: yOffset,
		},
		credits: {
			enabled: false,
		},
		tooltip: {
			formatter: function () {
				const total = this.points.reduce((acc, cur) => acc + cur.y, 0);

				const pointItemsMarkup = this.points
					.map((point, idx) =>
						generateTooltipItemMarkup({
							name: point.series.name,
							value: point.y,
							indicatorColor: point.color,
							includeDivider: idx < this.points.length - 1,
						})
					)
					.join("");

				return `
					<div class="d-flex flex-column" style="gap: 5px">
						${generateTooltipItemMarkup({
							headerText: true,
							includeDivider: true,
							name: "Optimizable",
							value: total,
						})}
						${pointItemsMarkup}
					</div>
				`;
			},
			positioner: function (labelWidth, _, point) {
				let tooltipX, tooltipY;

				if (point.plotX + labelWidth < this.chart.plotWidth - 50) {
					tooltipX = point.plotX + 80;
				} else {
					tooltipX = point.plotX - 90;
				}

				tooltipY = 80;

				return {
					x: tooltipX,
					y: tooltipY,
				};
			},
			borderRadius: 8,
			shadow: false,
			shared: true,
			useHTML: true,
		},
		plotOptions: {
			area: {
				stacking: "normal",
				marker: {
					enabled: false,
					symbol: "diamond",
					states: {
						hover: {
							enabled: true,
						},
					},
				},
			},
		},
		series: [
			{
				name: "Unused",
				fillColor: "#9EC1FA",
				lineColor: "#ffffff",
				color: "#82A7ED",
				data: graphData?.ySeries?.map((point) => point?.unused ?? 0),
			},
			{
				name: "Left org",
				fillColor: "#BBAFED",
				lineColor: "#ffffff",
				color: "#C87AFF",
				data: graphData?.ySeries?.map((point) => point?.left_org ?? 0),
			},
		],
	};
};
export const costIncurredChartConfig = (graphData, yOffset) => {
	return {
		chart: {
			type: "area",
			height: 200,
			plotBackgroundColor: "#FFF6DC",
			marginBottom: 20,
			borderRadius: 10,
		},
		title: {
			text: "",
		},
		contextMenu: {
			enabled: false, // Disable the context menu
		},
		exporting: {
			enabled: false, // Disable exporting options
		},
		navigation: {
			buttonOptions: {
				enabled: false, // Disable the print button
			},
		},
		legend: {
			enabled: false,
			align: "right",
		},
		xAxis: {
			categories: graphData?.xData,
			gridLineWidth: "1px",
			lineWidth: 0,
			tickWidth: 0,
			max:
				graphData?.xData?.length >= 11
					? 11
					: graphData?.xData?.length - 1,
			allowDecimals: false,
			labels: {
				enabled: false,
				stpe: 2,
			},
			plotLines: [
				{
					color: "#2266e2",
					value: todayLineIndex(graphData.xData),
					width: 0.8,
					zIndex: 10,
				},
			],
			plotBands: [
				{
					color: {
						pattern: {
							path: "M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11",
							color: "#ebebeb",
							width: 10,
							height: 10,
							zIndex: 100,
						},
					},
					from: todayLineIndex(graphData.xData),
					to:
						todayLineIndex(graphData.xData) !== -1
							? graphData.xData && graphData.xData?.length - 1
							: null,
				},
			],
			crosshair: {
				width: 1,
				color: "#FE6955",
				dashStyle: "dash",
			},
		},
		yAxis: {
			min: 0,
			tickAmount: 3,
			title: {
				text: "Cost Incurred",
				style: {
					fontSize: 11,
					fontWeight: 600,
					color: "#000000",
				},
			},
			labels: {
				formatter: function () {
					return kFormatter(this.value);
				},
				style: {
					fontSize: 11,
				},
				align: "right",
			},
			gridLineDashStyle: "longdash",
			gridLineColor: "#FFA99D",
			offset: yOffset,
		},
		credits: {
			enabled: false,
		},
		tooltip: {
			formatter: function () {
				return generateTooltipItemMarkup({
					name: this.point.series.name,
					value: optimizationV3CurrencyFormat(
						this.point.y,
						true,
						null,
						true
					),
					indicatorColor: this.point.color,
				});
			},
			positioner: function (labelWidth, _, point) {
				let tooltipX, tooltipY;

				if (point.plotX + labelWidth < this.chart.plotWidth - 50) {
					tooltipX = point.plotX + 85;
				} else {
					tooltipX = point.plotX - 130;
				}

				tooltipY = point.plotY - 5;

				return {
					x: tooltipX,
					y: tooltipY,
				};
			},
			shadow: false,
			borderRadius: 8,
			shared: true,
			useHTML: true,
		},
		plotOptions: {
			area: {
				marker: {
					enabled: false,
					symbol: "diamond",
					states: {
						hover: {
							enabled: true,
						},
					},
				},
			},
		},
		series: [
			{
				name: "Realised Savings",
				fillColor: "#FFA99D",
				lineColor: "#FE6955",
				color: "#FFA99D",
				data: graphData.ySeries?.map(
					(point) => point?.realised_savings ?? 0
				),
			},
			{
				name: "chart",
				fillColor: "#FFA99D",
				lineColor: "#FE6955",
				color: "#FFA99D",
				data: graphData?.ySeries?.map(
					(point) => point?.realised_savings ?? 0
				),
			},
		],
	};
};

const generateCSVdataForOptmizationV3Charts = (chartData) => {
	const csvData = chartData?.ySeries?.map((data) => {
		const csvRow = {};
		csvRow["Month"] = FULL_MONTH[data.month_id - 1];
		csvRow["Year"] = data.year_id;
		csvRow["Optimizable Licenses"] = data.unused + data.left_org;
		csvRow["Cost Incurred"] = data.realised_savings;
		return csvRow;
	});

	const headers = Object?.keys(csvData[0]);

	const csv = [
		headers,
		...csvData?.map((obj) =>
			headers.map((header) => {
				const value = obj[header];
				return value;
			})
		),
	];
	return csv;
};

export const downloadCSV = (data, fileName) => {
	const csvData = generateCSVdataForOptmizationV3Charts(data);
	try {
		let csvContent = "data:text/csv;charset=utf-8,";
		csvData.forEach((row) => {
			const csvRow = row?.join(",");
			csvContent += csvRow + "\r\n";
		});

		const encodedUri = encodeURI(csvContent);
		const link = document.createElement("a");
		link.setAttribute("href", encodedUri);
		link.setAttribute(
			"download",
			fileName ? `${fileName}.csv` : "Optmizable Licenses.csv"
		);
		document.body.appendChild(link);

		link.click();
		document.body.removeChild(link);
	} catch (error) {
		console.error("Error occurred during CSV download:", error);
	}
};

export const downloadPNG = (element) => {
	return html2canvas(element).then((canvas) => {
		const imgData = canvas.toDataURL("image/png");
		const link = document.createElement("a");
		link.href = imgData;
		link.download = "Optimizable Licenses.png";
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	});
};

export const downloadPDF = (element) => {
	return html2canvas(element).then((canvas) => {
		const imgData = canvas.toDataURL("image/png");
		const pdf = new jsPDF("l", "mm", "a3");
		pdf.addImage(imgData, "JPEG", 10, 50, 400, 180);
		pdf.save("Optimizable Licenses.pdf");
	});
};

// optmization v3 utils start

export const getOptimizationV3ChartData = (data, fy) => {
	const xAxis = getXAxisData(fy);
	const left_org_graph_data = data?.left_org?.graph_data;
	const unused_graph_data = data?.unused?.graph_data;
	const actively_used_graph_data = data?.actively_used?.graph_data;
	const underused_graph_data = data?.under_used?.graph_data;

	const graph_data_length = actively_used_graph_data?.length || 0;
	const graph_data = [];
	const map = new Map();

	for (let i = 0; i < graph_data_length; i++) {
		const graph_data_for_month_year = {};
		const realisedSavings = Math.floor(
			left_org_graph_data[i]?.left_org_cost +
				unused_graph_data[i]?.unused_cost +
				underused_graph_data[i]?.under_used_cost
		);
		graph_data_for_month_year.month_id =
			actively_used_graph_data[i]?.month_id ||
			actively_used_graph_data[i]?.month ||
			0;
		graph_data_for_month_year.year_id =
			actively_used_graph_data[i]?.year_id ||
			actively_used_graph_data[i]?.year ||
			0;
		graph_data_for_month_year.left_org =
			left_org_graph_data[i]?.left_org || 0;
		graph_data_for_month_year.unused = unused_graph_data[i]?.unused || 0;
		graph_data_for_month_year.realised_savings = realisedSavings || 0;
		graph_data.push(graph_data_for_month_year);
		const monthId =
			actively_used_graph_data[i]?.month_id ||
			actively_used_graph_data[i]?.month ||
			0;
		const yearId =
			actively_used_graph_data[i]?.year_id ||
			actively_used_graph_data[i]?.year ||
			0;
		const id = `${yearId}-${monthId}`;
		map.set(id, graph_data_for_month_year);
	}

	const ySeries = xAxis.map((x) => map.get(x.id));
	const xData = xAxis.map((x) => x.formattedDate);

	return { ySeries, xData };
};

export const optimizationV3CurrencyFormat = (
	value,
	gap,
	decimalsAfterDigit,
	commaSeparated
) => {
	return kFormatter(
		Number(value),
		null,
		true,
		decimalsAfterDigit,
		gap,
		commaSeparated
	);
};

export const handleOptimizationV3Summary = (optimizationSummary) => {
	if (!optimizationSummary) {
		return null;
	}
	const optimizableLicenseCount =
		optimizationSummary?.optimizable_license_count;
	// const estimatedWastage = optimizationSummary?.wastage;
	const potentialSavings = optimizationSummary?.potential_savings;
	const realisedSavings = optimizationSummary?.realised_savings;
	const optimizeLicenses = optimizationSummary?.optimized_licenses_count;

	return [
		{
			isUndefined: isUndefined(optimizableLicenseCount),
			title: "Optimizable Licenses",
			tagRender: (
				<HandleToolTip
					tooltipData={SummaryCardPillWithImage({
						value: handleSummaryAmt(
							internationalFormatNumberFormatter({
								number: optimizableLicenseCount,
							})
						),
						renderImage: (
							<img
								src={LicensesIcon}
								alt="Licenses"
								className="white"
								width={16}
								style={{
									filter: "invert(66%) sepia(48%) saturate(957%) hue-rotate(346deg) brightness(101%) contrast(101%)",
								}}
							/>
						),
						style: {
							backgroundColor: "#FFF6DC",
							color: "#FFA217",
						},
					})}
					tooltipName={optimizableLicenseCount}
					textLength={optimizableLicenseCount?.toString()?.length}
				/>
			),
			tooltipContent:
				"Number of underutilized licenses based on the configured rules",
		},

		{
			isUndefined: isUndefined(potentialSavings),
			title: "Savings Under Review",
			tagRender: (
				<HandleToolTip
					tooltipData={SummaryCardPillWithImage({
						value: optimizationV3CurrencyFormat(
							potentialSavings,
							true,
							null,
							true
						),
						renderImage: (
							<img
								src={PotentialSavingsIcon}
								alt="Potential Savings"
								className="white"
								width={16}
							/>
						),
						style: {
							backgroundColor: "#E7F8E8",
							color: "#009307",
						},
					})}
					tooltipName={potentialSavings}
					textLength={potentialSavings?.length}
				/>
			),
			tooltipContent:
				"Amount that can be saved next month if action is taken now",
		},
		{
			isUndefined: isUndefined(optimizeLicenses),
			title: "Optimized Licenses",
			tagRender: (
				<HandleToolTip
					tooltipData={SummaryCardPillWithImage({
						value: optimizationV3CurrencyFormat(
							optimizeLicenses,
							true,
							null,
							true
						),
						renderImage: <Icon name="license" color="#FE6955" />,
						style: {
							backgroundColor: "#FFE9E5",
							color: "#FE6955",
						},
					})}
					tooltipName={optimizeLicenses}
					textLength={optimizeLicenses?.length}
				/>
			),
			tooltipContent: "Total Optimized Licenses",
		},
		{
			isUndefined: isUndefined(realisedSavings),
			title: "Realised Savings",
			tagRender: (
				<HandleToolTip
					tooltipData={SummaryCardPillWithImage({
						value: optimizationV3CurrencyFormat(
							optimizeLicenses,
							true,
							null,
							true
						),
						renderImage: (
							<img
								src={SavingIcon}
								alt="Saving"
								className="white"
								width={16}
							/>
						),
						style: {
							backgroundColor: "#E6F5FF",
							color: "#1176BE",
						},
					})}
					tooltipName={realisedSavings}
					textLength={realisedSavings?.length}
				/>
			),
			tooltipContent:
				"Annual savings that has been realized on by revoking optimizable licenses",
		},
	];
};

export const handlePreviousOptimizationV3Summary = (optimizationSummary) => {
	const { total_realised_savings, users_affected, types_of_licenses } =
		optimizationSummary ?? {};

	const optimizedLicensesCount =
		!isNull(optimizationSummary) &&
		!isUndefined(optimizationSummary) &&
		typeof meta === "object"
			? Object.values(optimizationSummary)
					?.filter(
						(data) =>
							!isNull(data?.license_count) &&
							!isUndefined(data?.license_count)
					)
					.reduce((acc, curDt) => {
						return acc + curDt?.license_count;
					}, 0)
			: 0;
	const realisedSavingsAmt = total_realised_savings ?? 0;
	const usersAffectedCount = users_affected ?? 0;
	const typesOfLicensesCount = types_of_licenses ?? 0;

	return [
		{
			title: "Licenses Optimized",
			value: internationalFormatNumberFormatter({
				number: optimizedLicensesCount,
			}),
		},
		{
			title: "Realised Savings",
			value: optimizationV3CurrencyFormat(
				realisedSavingsAmt,
				true,
				null,
				true
			),
		},
		{
			title: "Users Affected",
			value: internationalFormatNumberFormatter({
				number: usersAffectedCount,
			}),
		},
		{
			title: "Types of Licenses",
			value: internationalFormatNumberFormatter({
				number: typesOfLicensesCount,
			}),
		},
	];
};

export const handleOptimizationV3Rules = (optimizationRules) => {
	const labels = {
		Undeprovisioned: "Left Org Licenses",
		Underused: "Underused Licenses",
		Unused: "Unused Licenses",
	};

	if (isNull(optimizationRules) || isUndefined(optimizationRules)) return [];

	const formattedRules = Object.values(optimizationRules)
		.filter((rule) => Object.keys(labels).includes(rule?.label))
		.map((rule) => ({
			title: labels[rule?.label],
			numberOfLicenses: rule?.license_count ?? 0,
		}));

	return formattedRules;
};

export const handleOptimizationV3ReviewUsersSummary = (optimizationData) => {
	const potentialSavingsAmt = kFormatter(
		optimizationData?.total_potential_savings ?? 0,
		0
	);
	const optimizableLicenseCount =
		!isNull(optimizationData) &&
		!isUndefined(optimizationData) &&
		typeof optimizationData === "object"
			? internationalFormatNumberFormatter({
					number: Object.values(optimizationData)
						?.filter(
							(data) =>
								!isNull(data?.license_count) &&
								!isUndefined(data?.license_count)
						)
						.reduce((acc, curDt) => {
							return acc + curDt?.license_count;
						}, 0),
				})
			: 0;
	const usersAffectedCount = optimizationData?.users_affected ?? 0;
	const typesOfLicensesCount = optimizationData?.types_of_licenses ?? 0;

	return {
		potentialSavingsAmt,
		optimizableLicenseCount,
		usersAffectedCount,
		typesOfLicensesCount,
	};
};

export const getOptimizeV3UsersBody = (selectedRows, playbookIds) => {
	const convertSelectedRowsToArray = Object.values(selectedRows);
	const arr = [];
	for (const object of convertSelectedRowsToArray) {
		arr.push({
			user_id: object?.user_id,
			license_id: object?.license_id,
			playbook_id: playbookIds?.[object._id]?.playbook?._id,
			user_app_license_id: object?.user_app_license_id,
			optimisation_reason: encodeURIComponent(object?.reason),
			date_since_optimisable: object?.date_since_optimisable,
			is_optimisable: object?.is_optimisable,
		});
	}
	return arr;
};

export const handleReviewUserSummary = ({
	potentialSavingsAmt,
	usersAffectedCount,
	typesOfLicensesCount,
}) => {
	return [
		{
			label: "Potential Saving",
			value: potentialSavingsAmt,
			color: "#FFA217",
			variant: BODY_VARIANT.BODY_2_BOLD,
		},
		{
			label: "Users Affected",
			value: usersAffectedCount,
			color: GREY_VARIANT.SECONDARY_GREY_2,
			variant: BODY_VARIANT.BODY_3_BOLD,
		},
		{
			label: "Types of Licenses",
			value: typesOfLicensesCount,
			color: GREY_VARIANT.SECONDARY_GREY_2,
			variant: BODY_VARIANT.BODY_3_BOLD,
		},
	];
};

export const getYAxisOffset = (optimizableYMax, costIncurredYMax) => {
	const optimizableChartYMax = optimizableYMax ?? 0;
	const optimizableChartYMaxLength = optimizableChartYMax.toString().length;
	const costIncurredChartYMax = costIncurredYMax ?? 0;
	const costIncurredChartYMaxLength =
		costIncurredChartYMax.toString().length + 2;

	const difference = optimizableChartYMaxLength - costIncurredChartYMaxLength;
	const offset = {
		optimizableChart: 0,
		costChart: 0,
	};
	if (difference < 0) {
		offset.optimizableChart = Math.abs(difference * 4);
	} else if (difference > 0) {
		offset.costChart = Math.abs(difference * 4);
	}

	return offset;
};

const getXAxisData = (fy) => {
	let startMonth = fy?.start_month + 1;
	let startYear = fy?.start_year;
	let endMonth = fy?.end_month + 1;
	let endYear = fy?.end_year;
	const xdata = [];

	let currentDate = new Date(startYear, startMonth - 1);

	while (
		currentDate.getFullYear() < endYear ||
		(currentDate.getFullYear() === endYear &&
			currentDate.getMonth() <= endMonth - 1)
	) {
		const formattedDate = `${FULL_MONTH[currentDate.getMonth()].slice(
			0,
			3
		)}'${currentDate.getFullYear().toString().slice(2)}`;
		xdata.push({
			id: `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}`,
			formattedDate,
		});
		currentDate.setMonth(currentDate.getMonth() + 1);
	}

	return xdata;
};

// optmization v3 utils end

export const getOptimizationInclusionText = (
	exclude_from_unused_optimization,
	exclude_from_undeprovisioned_optimization
) => {
	if (
		exclude_from_unused_optimization &&
		exclude_from_undeprovisioned_optimization
	) {
		return "Excluded for all Optimization";
	} else if (
		exclude_from_unused_optimization &&
		!exclude_from_undeprovisioned_optimization
	) {
		return "Excluded for Unused";
	} else if (
		!exclude_from_unused_optimization &&
		exclude_from_undeprovisioned_optimization
	) {
		return "Excluded for Undeprovisioned";
	} else {
		return "Included for Optimization";
	}
};
