import { BUTTON_VARIANT, TagName } from "@zluri/ui-components";
import { HandleToolTip } from "modules/applications/components/chargebacks/utils/chargeback";
import React from "react";

export default function CustomTagsV2({
	data,
	component,
	showCount = 4,
	...props
}) {
	const tags = data[component?.valueKey];
	const tagStyle = ["#5287E8", "#9E17FF", "#5FCF64", "#FFA217"];

	const getRandomColor = (tagStyle) => {
		const randomColor =
			tagStyle[Math.floor(Math.random() * tagStyle.length)];
		return randomColor;
	};

	const randomStyle = getRandomColor(tagStyle);

	const adjustTagsWidth = (tags) => {
		const tagLength = tags?.length;
		switch (tagLength) {
			case 1:
				return "200px";
			case 2:
				return "150px";
			case 3:
				return "100px";
			default:
				return "60px";
		}
	};

	return (
		<div className="d-flex gap-8 w-100">
			{tags?.slice(0, showCount)?.map((tagsData, index) => (
				<div key={index} className="d-flex w-100">
					<HandleToolTip
						tooltipData={
							<div
								className="border-radius-8 p-1"
								style={{
									color: randomStyle,
									border: `1px solid ${randomStyle}`,
								}}
							>
								<div
									className="tooltip_for_tag"
									style={{ maxWidth: adjustTagsWidth(tags) }}
								>
									{tagsData?.license_name}
								</div>
							</div>
						}
						tooltipName={tagsData?.license_name}
					/>
				</div>
			))}
		</div>
	);
}
