import React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import { client } from "utils/client";
import { debounce, unescape } from "utils/common";
import {
	Checkbox,
	ImageOrNameBadge,
	SearchBox,
	Typography,
} from "@zluri/ui-components";
import {
	checkSpecialCharacters,
	searchAllApps,
	searchAllDepartments,
	searchAppCategories,
	searchAppCategoriesInFilters,
	searchUsers,
} from "services/api/search";

import "components/Applications/Overview/Overview.css";
import "./select_entity.css";
import { Loader } from "@zluri/ui-components";
import { TriggerIssue } from "utils/sentry";
import {
	getUserAppInstancesForFilter,
	getUserAppRolesForFilter,
} from "../../../services/api/applicationsv2";
import {
	getReviewersForTable,
	searchReviewers,
} from "../../../modules/AccessReview/service/AccessReview.service";
import { isEmpty } from "underscore";
import ErrorWrapper from "UIComponents/Rbac/ErrorWrapper";

function getApiProps(apiProps) {
	return {
		// TODO: this should have been reviewers
		user: {
			searchAPI: (query = "", cancelToken) => {
				return searchReviewers(query, cancelToken, apiProps);
			},
			placeholder: "Search Users",
			id_key: "reviewer_id",
			name_key: "reviewer_name",
			local_search: false,
			triggerMessage: "Error while Fetching Users",
			getApi: getReviewersForTable,
		},
		app_users: {
			searchAPI: (query = "", cancelToken) => {
				return searchUsers(query, cancelToken, apiProps);
			},
			placeholder: "Search Users",
			id_key: "user_id",
			name_key: "user_name",
			image_key: "profile_img",
			email_key: "user_email",
			local_search: false,
			triggerMessage: "Error while Fetching Users",
			getApi: searchUsers,
			dataAccessor: "results", // TODO: prj find a better way. what happens if data is nested?
		},
		applications: {
			searchAPI: (query = "", cancelToken) => {
				return searchAllApps(query, cancelToken, true);
			},
			placeholder: "Search Applications",
			id_key: "app_id",
			name_key: "app_name",
			image_key: "app_logo",
			triggerMessage: "Error while Fetching Applications",
			getApi: searchAllApps,
			dataAccessor: "results",
		},
		department: {
			searchAPI: (query = "", cancelToken) => {
				return searchAllDepartments(query, cancelToken, apiProps);
			},
			placeholder: "Search Departments",
			id_key: "department_id",
			name_key: "department_name",
			local_search: false,
			triggerMessage: "Error while fetching department",
			getApi: searchAllDepartments,
			dataAccessor: "results",
		},
		roles: {
			getApi: getUserAppRolesForFilter,
			placeholder: "Search Roles",
			id_key: "value",
			name_key: "value",
			local_search: true,
			triggerMessage: "Error while Fetching Roles",
		},
		app_instance: {
			getApi: getUserAppInstancesForFilter,
			placeholder: "Search Instances",
			id_key: "value",
			name_key: "value",
			local_search: true,
			triggerMessage: "Error while Fetching Roles",
		},
		desktop_agent_name: {
			searchAPI: (query = "", cancelToken) =>
				searchUsers(query, cancelToken, apiProps),
			placeholder: "Search Users",
			id_key: "user_id",
			name_key: "user_name",
			image_key: "profile_img",
			email_key: "user_email",
			local_search: false,
			triggerMessage: "Error while Fetching Users",
			getApi: searchUsers,
			dataAccessor: "results",
		},
		desktop_agent_email: {
			searchAPI: (query = "", cancelToken) =>
				searchUsers(query, cancelToken, apiProps),
			placeholder: "Search Users",
			id_key: "user_id",
			name_key: "user_email",
			local_search: false,
			triggerMessage: "Error while Fetching Users",
			getApi: searchUsers,
			dataAccessor: "results",
		},
		application_category: {
			searchAPI: (query = "", cancelToken) =>
				searchAppCategoriesInFilters(query, cancelToken),
			placeholder: "Search Category",
			id_key: "category",
			name_key: "category",
			local_search: false,
			triggerMessage: "Error while Fetching Category",
			getApi: searchAppCategoriesInFilters,
			dataAccessor: "results",
		},
		application_sub_category: {
			searchAPI: (query = "", cancelToken) =>
				searchAppCategories(query, cancelToken),
			placeholder: "Search Sub Category",
			id_key: "_id",
			name_key: "name",
			local_search: false,
			triggerMessage: "Error while Fetching Search Category",
			getApi: searchAppCategories,
			dataAccessor: "results",
		},
	};
}

//TODO: prj need updates for owner dynamic filter in app main table
// need to update the code
// !!! DO NOT USE THIS IN V2TABLE CELL.. THIS IS CONFIGURED TO BE USED ONLY IN V2_FILTER
// TODO: should probably remove displayableLabelForValues in next release

/**
 * @param  {string} props.component.entity - Deprecated: props.component.entity is depreciated, instead use entity. This component was part of createV2Component before, now its seperated out to be used only at filter component.
 * @returns ReactNode
 */
export default function DynamicSearchSelect(props) {
	const { value, filter, filterData, apiProps = {}, onChange } = props;

	// Adding fallback for supporting older implementation
	const searchEntity = filter?.entity ?? filter?.ui?.component?.entity;

	const apiAndOtherInfo = getApiProps(apiProps);
	const [loadingDefaultOptions, setLoadingDefaultOptions] = useState(false);
	const [defaultOptions, setDefaultOptions] = useState();
	const [options, setOptions] = useState([]);
	const [loading, setloading] = useState(false);
	const [selection, setSelection] = useState([]);
	const [searchText, setSearchText] = useState("");
	const [searchError, setSearchError] = useState(null);
	const cancelToken = useRef();
	const initialOptions = useRef([]); // for storing initial default options since it can be modified later on
	const hadSelected = useRef([]); // for storing selected values even after unselecting

	const imageKey = apiAndOtherInfo[searchEntity]?.image_key;
	const nameKey = apiAndOtherInfo[searchEntity]?.name_key;
	const idKey = apiAndOtherInfo[searchEntity]?.id_key;
	const dataAccessor = apiAndOtherInfo[searchEntity]?.dataAccessor;

	useEffect(() => {
		if (!defaultOptions) {
			setLoadingDefaultOptions(true);
			apiAndOtherInfo[searchEntity]
				.getApi(apiProps)
				.then((res) => {
					if (
						Array.isArray(res) &&
						res.length > 0 &&
						typeof res[0] === "string"
					) {
						let temp = res.map((el) => {
							return {
								value: el,
								_id: el,
							};
						});
						initialOptions.current = removeDuplicateId(
							[...hadSelected.current, ...temp],
							idKey
						);
						setDefaultOptions(initialOptions.current);
					} else if (res && dataAccessor) {
						initialOptions.current = removeDuplicateId(
							[...hadSelected.current, ...res?.[dataAccessor]],
							idKey
						);
						setDefaultOptions(initialOptions.current);
					} else {
						initialOptions.current = removeDuplicateId(
							[...hadSelected.current, ...res],
							idKey
						);
						setDefaultOptions(initialOptions.current);
					}
				})
				.catch((searchError) => {
					if (!client.isCancel(searchError)) {
						setSearchError(searchError);
						TriggerIssue(
							`${apiAndOtherInfo[searchEntity].triggerMessage}`
						);
					}
				})
				.finally(() => setLoadingDefaultOptions(false));
		}
	}, []);

	const handleEdit = (text) => {
		if (cancelToken.current) cancelToken.current.cancel();
		if (text.length === 0) {
			setOptions([...(defaultOptions ?? [])]);
			setloading(false);
		}
		if (text.length > 1) {
			if (checkSpecialCharacters(text, true)) {
				setOptions([]);
				setloading(false);
			} else {
				if (apiAndOtherInfo[searchEntity].local_search) {
					generateLocalResults(text);
				} else {
					setloading(true);
					cancelToken.current = client.CancelToken.source();
					generateSuggestions(text, cancelToken.current);
				}
			}
		}
	};
	const generateLocalResults = (searchTerm) => {
		const filteredOptions =
			defaultOptions?.filter((row) =>
				row[apiAndOtherInfo[searchEntity].name_key]
					?.toString()
					.toLowerCase()
					?.includes(searchTerm?.toLowerCase())
			) ?? [];
		setOptions([...filteredOptions]);
	};
	const generateSuggestions = useCallback(
		debounce((query, cancelToken) => {
			apiAndOtherInfo[searchEntity]
				.searchAPI(query, cancelToken)
				.then((res) => {
					if (dataAccessor) {
						setOptions(res?.[dataAccessor]);
					} else {
						setOptions(res);
					}
				})
				.catch((searchError) => {
					if (!client.isCancel(searchError)) {
						setSearchError(searchError);
						TriggerIssue(
							"There was an error while generating suggestions",
							searchError
						);
					}
				})
				.finally(() => {
					setloading(false);
					setLoadingDefaultOptions(false);
				});
		}, 200),
		[]
	);
	const handleSelect = (selectedValue) => {
		let index = selection.findIndex(
			(row) => row?.[idKey] === selectedValue?.[idKey]
		);
		let tempSelection;
		if (index > -1) {
			tempSelection = selection.filter(
				(row) => row?.[idKey] !== selectedValue?.[idKey]
			);
		} else {
			tempSelection = [...selection, selectedValue];
			hadSelected.current = [...hadSelected.current, selectedValue];
			setDefaultOptions(
				removeDuplicateId(
					[
						...hadSelected.current,
						...selection,
						...initialOptions.current,
					],
					idKey
				)
			);
		}
		setSelection(tempSelection);

		onChange &&
			onChange(
				tempSelection.map((s) => ({
					value: s?.[idKey],
					label: s?.[nameKey],
					...s,
				}))
			);
	};

	useEffect(() => {
		if (Array.isArray(value) && value.length > 0) {
			let temp = [];
			for (let i = 0; i < value.length; i++) {
				let obj = {};
				obj[idKey] = value[i];
				obj[nameKey] = filterData?.dynamicFilterData?.[i]?.[nameKey];
				obj[imageKey] = filterData?.dynamicFilterData?.[i]?.[imageKey];
				temp.push(obj);
			}
			setSelection([...temp]);
			if (!hadSelected.current?.length) hadSelected.current = [...temp];
			setDefaultOptions(
				removeDuplicateId(
					[...hadSelected.current, ...initialOptions.current],
					idKey
				)
			);
		}
		if (!value) setSelection([]);
	}, [value]);

	const suggestionOptions = isEmpty(searchText) ? defaultOptions : options;

	return searchError ? (
		<ErrorWrapper size="small" error={searchError} />
	) : (
		<>
			<div
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
				}}
			>
				<div className="dynamic_filter_container">
					<div />
					<SearchBox
						value={searchText}
						onChangeText={(e) => {
							setSearchText(e.target.value);
							handleEdit(e.target.value);
						}}
						onClear={() => {
							handleEdit("");
							setSearchText("");
						}}
						isCollapsible={false}
					/>
				</div>
				{loadingDefaultOptions || loading ? (
					<>
						<div
							style={{
								margin: "4px ",
								backgroundColor: "#F6F7FA",
							}}
						>
							<Loader color="blue" width={32} height={32} />
						</div>
					</>
				) : (Array.isArray(suggestionOptions) &&
						suggestionOptions.length > 0) ||
				  (Array.isArray(options) && options.length > 0) ? (
					<div
						style={{ height: "128px", overflowY: "auto" }}
						className="w-100"
					>
						{suggestionOptions.map((option) => {
							const isSelected = selection.some(
								(i) => i?.[idKey] === option?.[idKey]
							);
							return (
								<>
									<div
										role="listitem"
										className="d-flex"
										style={{
											padding: "5px",
										}}
										key={option?.[idKey]}
									>
										<Checkbox
											key={selection}
											checked={isSelected}
											onChange={() =>
												handleSelect(option)
											}
										/>
										<div className="d-flex align-items-center ml-2">
											<ImageOrNameBadge
												height="16px"
												width="16px"
												url={unescape(option[imageKey])}
												name={option[nameKey]}
												fontSize="9px"
												className="mr-2"
												nameBadgeClasses="mr-2"
											/>
											<div className={"truncate_10vw"}>
												<Typography
													variant={
														isSelected
															? "button_extrasmall_semibold"
															: "button_extrasmall_regular"
													}
													color="secondary_grey_2"
													className="text-capitalize"
												>
													{option[nameKey]}
												</Typography>
											</div>
										</div>
									</div>
								</>
							);
						})}
					</div>
				) : (
					<div
						className="d-flex align-items-center justify-content-center"
						style={{
							margin: "4px ",
							backgroundColor: "#F6F7FA",
							height: "32px",
						}}
					>
						<Typography
							variant="button_extrasmall_regular"
							color="secondary-grey-1"
						>
							No matching items found.
						</Typography>
					</div>
				)}
			</div>
		</>
	);
}

// Used for removing duplicated ids, when selecting default options to show
function removeDuplicateId(array, idKey) {
	return array.reduce((acc, curr) => {
		const existingItem = acc.find(
			(item) => item?.[idKey] === curr?.[idKey]
		);
		if (!existingItem) acc.push(curr);
		return acc;
	}, []);
}
