// STANDARD NEEDED TO BE SET FOR THIS
// DO NOT USE THIS YET!!
import { axiosBaseQuery, axiosBaseQueryV2 } from "../utils/client";
import { createApi } from "@reduxjs/toolkit/query/react";

export const paymentMethodApi = createApi({
	reducerPath: "paymentMethodApi",
	baseQuery: axiosBaseQuery(),
	endpoints: (builder) => ({
		getPaymentMethods: builder.query({
			query: () => ({ url: "payment_methods" }),
		}),
	}),
});

export const { useGetPaymentMethodsQuery } = paymentMethodApi;

export const chargebackSettingsApi = createApi({
	reducerPath: "chargebackSettingsApi",
	baseQuery: axiosBaseQueryV2(),
	endpoints: (builder) => ({
		getChargebackSettingsBottomUp: builder.query({
			query: (queryParams) => {
				const appId = queryParams.appId;
				const url = `applications/${appId}/chargeback/settings/bottom-up`;
				return { url };
			},
		}),
		getChargebackSettingsTopDown: builder.query({
			query: (queryParams) => {
				const appId = queryParams.appId;
				const url = `applications/${appId}/chargeback/settings/top-down`;
				return { url };
			},
		}),
		addSubDepartmentWiseSplit: builder.mutation({
			query: ({ appId, data }) => {
				const url = `applications/${appId}/department-split`;
				return {
					url,
					method: "PUT",
					data,
				};
			},
		}),
		getDepartmentSplitData: builder.query({
			query: (queryParams) => {
				const appId = queryParams.appId;
				const url = `applications/${appId}/department-split`;
				return { url };
			},
		}),
	}),
});
export const {
	useGetChargebackSettingsBottomUpQuery,
	useGetChargebackSettingsTopDownQuery,
	useAddSubDepartmentWiseSplitMutation,
	useGetDepartmentSplitDataQuery,
} = chargebackSettingsApi;

export const spendsSettingsApi = createApi({
	reducerPath: "spendsSettingsApi",
	baseQuery: axiosBaseQueryV2(),
	endpoints: (builder) => ({
		getSpendsSettings: builder.query({
			query: (queryParams) => {
				const appId = queryParams.appId;
				const url = `applications/${appId}/spends-settings`;
				return { url };
			},
		}),
	}),
});

export const { useGetSpendsSettingsQuery } = spendsSettingsApi;

export const departmentApi = createApi({
	reducerPath: "departmentApi",
	baseQuery: axiosBaseQuery(),
	endpoints: (builder) => ({
		getDepartmentAndSubDepartmentList: builder.query({
			query: (queryParams) => {
				const appId = queryParams.appId;
				const searchQuery = queryParams.searchQuery ?? "";
				const url = `applications/${appId}/departments/search?include_sub_departments=true&search_query=${searchQuery}`;

				return {
					url,
				};
			},
		}),
	}),
});

export const { useGetDepartmentAndSubDepartmentListQuery } = departmentApi;
