import React, { useState } from "react";
import TransactionMappingGeneralModal from "modules/transactionMapping/components/Modal/TransactionMappingGeneralModal";
import { Typography, theme } from "@zluri/ui-components";
import PropTypes from "prop-types";
import {
	editRule,
	createRule,
} from "modules/transactionMapping/services/transactionMapping.api";
import { rulesStatus } from "modules/transactionMapping/constants/ruleMapping";
import { transactionActions } from "modules/transactionMapping/constants/actions";
import {
	deactivateDeleteInfo,
	activateInfo,
} from "modules/transactionMapping/utils/getModalInfoBanner";

const ActivateDeactivateModal = ({
	openTransactionModalType,
	setOpenTransactionModalType,
	rowData,
	createRulePage,
	setEditRuleLoading,
	onSaveCallback = () => {},
	isDirty,
	currentStatus,
}) => {
	const [mapTransactionOption, setMapTransactionOption] = useState(null);
	const [extraMapTransactionOption, setExtraMapTransactionOption] =
		useState(null);
	const [editLoading, setEditLoading] = useState(false);

	const action = rowData.actions[0]["v"].action_type;

	const getModalOptionItems = () => {
		if (openTransactionModalType === rulesStatus.INACTIVE) {
			if (action === transactionActions.TRANSACTION_UNMAPPING) {
				return [
					{
						type: "DUAL_OPTION_SELECTION_BLOCK",
						title: "Should the unmapped transactions run through the rules? ",
						text: "This rule considers unrecognised transactions.",
						onSelectHandler: (e) => setMapTransactionOption(e),
						value: mapTransactionOption,
						options: [
							{
								label: "Yes",
								value: 1,
							},
							{
								label: "No",
								value: 2,
							},
						],
					},
					...deactivateDeleteInfo({
						action,
						mapTransactionOption,
					}),
				];
			}
			if (action === transactionActions.TRANSACTION_MAPPING) {
				return [
					{
						type: "DUAL_OPTION_SELECTION_BLOCK",
						title: "Do you want to unmap transactions that are previously mapped?",
						text: "This rule considers the recognized transactions.",
						onSelectHandler: (e) => setMapTransactionOption(e),
						value: mapTransactionOption,
						options: [
							{
								label: "Yes, Unmap transactions",
								value: 1,
							},
							{
								label: "Do not Unmap transactions",
								value: 2,
							},
						],
					},
					...deactivateDeleteInfo({
						action,
						mapTransactionOption,
					}),
				];
			}
			return [
				{
					type: "DUAL_OPTION_SELECTION_BLOCK",
					title: "Do you want to unarchive transactions that are previously archived?",
					text: "This rule considers the both recognized and unrecognized transactions.",
					onSelectHandler: (e) => setMapTransactionOption(e),
					value: mapTransactionOption,
					options: [
						{
							label: "Yes, Unarchive transactions",
							value: 1,
						},
						{
							label: "Do not Unarchive transactions",
							value: 2,
						},
					],
				},
				...deactivateDeleteInfo({
					action,
					mapTransactionOption,
				}),
			];
		} else {
			if (action === transactionActions.TRANSACTION_MAPPING) {
				return [
					...(rowData._id &&
					isDirty &&
					currentStatus === rulesStatus.ACTIVE
						? [
								{
									type: "DUAL_OPTION_SELECTION_BLOCK",
									title: "Do you want to unmap transactions that are previously mapped?",
									text: "This rule considers the both recognized and unrecognized transactions.",
									onSelectHandler: (e) =>
										setExtraMapTransactionOption(e),
									value: extraMapTransactionOption,
									options: [
										{
											label: "Yes, Unmap transactions",
											value: 1,
										},
										{
											label: "Do not Unmap transactions",
											value: 2,
										},
									],
								},
						  ]
						: []),
					{
						type: "DUAL_OPTION_SELECTION_BLOCK",
						title: "Select which transactions you want to map.",
						text: "This rule considers the unrecognized transactions.",
						onSelectHandler: (e) => setMapTransactionOption(e),
						value: mapTransactionOption,
						options: [
							{
								label: "Future Transactions Only",
								value: 1,
							},
							{
								label: "Future & Existing Transactions",
								value: 2,
							},
						],
					},
					...activateInfo({
						action,
						extraMapTransactionOption,
						mapTransactionOption,
						newRule: !rowData._id,
					}),
				];
			}
			if (action === transactionActions.TRANSACTION_UNMAPPING) {
				return [
					{
						type: "DUAL_OPTION_SELECTION_BLOCK",
						title: "Select which transactions you want to Unmap.",
						text: "This rule considers recognised transactions.",
						onSelectHandler: (e) => setMapTransactionOption(e),
						value: mapTransactionOption,
						options: [
							{
								label: "Future Transactions Only",
								value: 1,
							},
							{
								label: "Future & Existing Transactions",
								value: 2,
							},
						],
					},
					...(rowData._id &&
					isDirty &&
					currentStatus === rulesStatus.ACTIVE
						? [
								{
									type: "DUAL_OPTION_SELECTION_BLOCK",
									title: "Do you want the unmapped transaction to be run through the rules?",
									onSelectHandler: (e) =>
										setExtraMapTransactionOption(e),
									value: extraMapTransactionOption,
									options: [
										{
											label: "Yes",
											value: 1,
										},
										{
											label: "No",
											value: 2,
										},
									],
								},
						  ]
						: []),
					...activateInfo({
						action,
						mapTransactionOption,
						extraMapTransactionOption,
						newRule: !rowData._id,
					}),
				];
			}
			return [
				{
					type: "DUAL_OPTION_SELECTION_BLOCK",
					title: "Select which transactions you want to Archive.",
					text: "This rule considers the both unrecognized transactions and recognized transactions.",
					onSelectHandler: (e) => setMapTransactionOption(e),
					value: mapTransactionOption,
					options: [
						{
							label: "Future Transactions Only",
							value: 1,
						},
						{
							label: "Future & Existing Transactions",
							value: 2,
						},
					],
				},
				...(rowData._id &&
				isDirty &&
				currentStatus === rulesStatus.ACTIVE
					? [
							{
								type: "DUAL_OPTION_SELECTION_BLOCK",
								title: "Do you want to unarchive transactions that are previously archived?",
								onSelectHandler: (e) =>
									setExtraMapTransactionOption(e),
								value: extraMapTransactionOption,
								options: [
									{
										label: "Yes, unarchive transactions",
										value: 1,
									},
									{
										label: "Do not unarchive transactions",
										value: 2,
									},
								],
							},
					  ]
					: []),
				...activateInfo({
					action,
					mapTransactionOption,
					extraMapTransactionOption,
					newRule: !rowData._id,
				}),
			];
		}
	};

	const optionSelectedHandler = () => {
		const isDecativateRule =
			openTransactionModalType === rulesStatus.INACTIVE;
		const isActivateRule = openTransactionModalType === rulesStatus.ACTIVE;
		const isUnmappingAction =
			action === transactionActions.TRANSACTION_UNMAPPING;
		const isFirstOptionSelected = mapTransactionOption === 1;
		const isSecondOptionSelected = mapTransactionOption === 2;
		setEditLoading(true);
		if (!createRulePage) {
			editRule({
				body: {
					...rowData,
					status: openTransactionModalType,
				},
				modify_existing_transactions:
					isActivateRule && isSecondOptionSelected,
				revert_rule_action:
					(isDecativateRule && isFirstOptionSelected) ||
					(isActivateRule && extraMapTransactionOption === 1) ||
					(isDecativateRule &&
						isUnmappingAction &&
						isFirstOptionSelected),
				ruleId: rowData._id,
			})
				.then(() => {
					onSaveCallback({
						name: rowData.name,
						status: openTransactionModalType,
					});
					setEditLoading(false);
					setOpenTransactionModalType(null);
				})
				.catch(() => {
					setOpenTransactionModalType(null);
				});
		} else {
			const body = {
				...rowData,
				tag: "transaction",
				status: openTransactionModalType,
			};
			setEditRuleLoading(true);
			if (rowData._id) {
				editRule({
					body,
					modify_existing_transactions:
						isActivateRule && isSecondOptionSelected,
					revert_rule_action:
						(isDecativateRule && isFirstOptionSelected) ||
						(isActivateRule && extraMapTransactionOption === 1) ||
						(isDecativateRule &&
							isUnmappingAction &&
							isFirstOptionSelected),
					ruleId: rowData._id,
				}).then(() => {
					setEditRuleLoading(false);
					setOpenTransactionModalType(null);
					setEditLoading(false);
					onSaveCallback({
						name: rowData.name,
						status: openTransactionModalType,
					});
				});
			} else {
				createRule({
					body,
					modify_existing_transactions: mapTransactionOption === 2,
				}).then(() => {
					setEditRuleLoading(false);
					setOpenTransactionModalType(null);
					setEditLoading(false);
					onSaveCallback({
						name: rowData.name,
						status: openTransactionModalType,
					});
				});
			}
		}
	};

	return (
		<TransactionMappingGeneralModal
			heading={
				<Typography variant="subheading_1_bold">
					{openTransactionModalType === rulesStatus.INACTIVE
						? "Are you sure you want to Deactivate the rule?"
						: "Do you want to activate the rule?"}
				</Typography>
			}
			modalItems={getModalOptionItems()}
			onCloseHandler={() => setOpenTransactionModalType(null)}
			footerCtas={[
				{
					label: "close",
					variant: "primary-alternative",
					theme: theme,
					onClick: () => setOpenTransactionModalType(null),
				},
				{
					label:
						openTransactionModalType === rulesStatus.INACTIVE
							? "Deactivate"
							: "Save",
					variant:
						openTransactionModalType === rulesStatus.INACTIVE
							? "secondary-danger"
							: "primary-default",
					theme: theme,
					onClick: () => {
						optionSelectedHandler();
					},
					loading: editLoading,
					// need to update this
					disabled: !mapTransactionOption || editLoading,
				},
			]}
		/>
	);
};

export default ActivateDeactivateModal;

ActivateDeactivateModal.propTypes = {
	openTransactionModalType: PropTypes.string,
	setOpenTransactionModalType: PropTypes.func,
	rowData: PropTypes.object,
	createRulePage: PropTypes.bool,
	setEditRuleLoading: PropTypes.func,
	onSaveCallback: PropTypes.func,
	isDirty: PropTypes.bool,
	currentStatus: PropTypes.bool,
};
