import React from "react";
import { capitalizeFirstLetter } from "utils/common";
import { GridRefType } from "./types";
import { useTableContext } from "containers/v2table/TableContext/context";
import { Typography } from "@zluri/ui-components";
interface ISelectionInfoBanner {
	count: number;
	selectAllText?: string;
	gridRef: GridRefType;
}
function SelectionInfoBanner({
	count,
	selectAllText,
	gridRef,
}: ISelectionInfoBanner) {
	const {
		isBulkSelected,
		isAllDataSelected,
		dataEntity,
		checkboxState,
		onAllDataSelectionChange,
		resetCheckboxState,
	} = useTableContext();

	const formattedParentEntity = capitalizeFirstLetter(
		selectAllText || dataEntity
	);

	const handleSelectAllRowClick = () => {
		if (gridRef?.current) gridRef.current?.selectAll();
		onAllDataSelectionChange(true);
	};

	const numberOfSelectedRows = Object.keys(
		checkboxState.selected ?? {}
	)?.length;

	const numberOfUnSelectedRows =
		Object.keys(checkboxState.unselected)?.length ?? 0;

	if (!isBulkSelected) return null;
	return (
		<div
			className={`select-all__info-banner ${
				isBulkSelected ? "fadeIn" : "fadeOut"
			}`}
		>
			<Typography
				variant="alerttag_small_medium"
				color="secondary_grey_1"
				className="px-2"
			>
				{isAllDataSelected
					? `All ${
							count - numberOfUnSelectedRows
						} of ${count} ${formattedParentEntity} are selected.`
					: `${numberOfSelectedRows} of ${count} ${formattedParentEntity} selected on this page`}
			</Typography>
			<button
				className={`banner-action-button`}
				onClick={() => {
					gridRef?.current?.deselectAll();
					if (isAllDataSelected) {
						resetCheckboxState();
						return;
					}
					handleSelectAllRowClick();
				}}
			>
				<Typography
					variant="alerttag_small_medium"
					color="primary_blue_dark_1"
					className="px-2 select-all__info-banner-link"
				>
					{isAllDataSelected
						? `Clear Selection`
						: `Select all ${count} ${formattedParentEntity}`}
				</Typography>
			</button>
		</div>
	);
}
export default SelectionInfoBanner;
