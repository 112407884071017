import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
	Accordion,
	Card,
	OverlayTrigger,
	Tooltip,
	useAccordionToggle,
} from "react-bootstrap";
import quest from "assets/icons/question-circle.svg";
import deleteIcon from "assets/deleteIcon.svg";
import green_tick from "assets/green_tick.svg";
import write_icon from "assets/write.svg";
import downArrowIcon from "assets/arrowdropdown.svg";
import collapsedICon from "assets/collapsed_icon_log.svg";

const WebhookEvents = ({
	showSelectedCard,
	data,
	isWebhookEnabled,
	setIsWebhookEnabled,
	disabled,
}) => {
	const renderSelectedWebhookCardDesc = (event, idx) => (
		<>
			<div className="d-flex mt-2">
				<span
					style={{ color: "#484848" }}
					className="d-flex flex-1 font-10 bold-500 align-items-center"
				>
					<img
						style={{ width: "18px", height: "18px" }}
						className="mr-2"
						alt=""
						src={green_tick}
					/>
					{event?.name}
				</span>
				<img alt="" src={write_icon} />
			</div>
		</>
	);

	const renderSelectedWebhookCard = () =>
		isWebhookEnabled && (
			<Card className="p-3 my-3">
				<div className="d-flex">
					<span className="d-flex flex-1 font-12 bold-600 grey-1">
						Scopes for Webhook Events
					</span>
					<img
						onClick={() => setIsWebhookEnabled(!isWebhookEnabled)}
						className="pointer"
						alt=""
						src={deleteIcon}
					/>
				</div>
				{data?.webhook_events &&
					data?.webhook_events?.map((event, idx) =>
						renderSelectedWebhookCardDesc(event, idx)
					)}
			</Card>
		);

	const renderToolTip = (event) => (
		<OverlayTrigger
			placement="top"
			delay={{ show: 250, hide: 400 }}
			overlay={(props) => (
				<Tooltip {...props} id="button-tooltip-workflow">
					<p
						style={{ maxWidth: "200px" }}
						className="m-0 font-12 grey-1"
					>
						{" "}
						{event?.description ||
							"Webhooks can be used as trigger for creating workflows & automations"}
					</p>
				</Tooltip>
			)}
		>
			<img alt="" src={quest} width={12} className="ml-2" />
		</OverlayTrigger>
	);

	const renderWebhookEventDesc = (event, idx) => (
		<>
			<div style={{ color: "#484848" }} className="d-flex flex-column">
				<span className="d-flex flex-1 font-10 bold-500">
					{event?.name}
				</span>
				<span className="d-flex flex-1 font-8 bold-400">
					{event?.description}
				</span>
			</div>
			{data?.webhook_events?.length - idx !== 1 ? (
				<hr className="my-2" />
			) : null}
		</>
	);

	const renderWebhookEventCard = () => (
		<Accordion key={1}>
			<Card>
				<Card.Header
					style={{
						backgroundColor: "transparent",
						border: "none",
					}}
				>
					<div className="scope-card d-flex flex-row">
						<div className="d-flex align-items-center flex-1">
							<span
								style={{ color: "#484848" }}
								className="d-flex flex-1 font-12 bold-500"
							>
								<OverlayTrigger
									placement="top"
									delay={{ show: 250, hide: 400 }}
									overlay={(props) => (
										<Tooltip
											{...props}
											id="button-tooltip-workflow"
											hidden={!disabled}
										>
											<p
												style={{ maxWidth: "200px" }}
												className="m-0 font-12 grey-1"
											>
												{
													"Inorder to enable the use of webhook for this integration, you have to reconnect the integration"
												}
											</p>
										</Tooltip>
									)}
								>
									<input
										disabled={disabled}
										className="mr-2"
										type="checkbox"
										name="webhook"
										checked={isWebhookEnabled}
										onChange={(e) => {
											setIsWebhookEnabled(
												e.target.checked
											);
										}}
									/>
								</OverlayTrigger>
								{"Enable Webhook for this Integration"}
								{renderToolTip()}
							</span>
						</div>
						<ContextAwareToggle
							eventKey={1}
							isWebhookEnabled={isWebhookEnabled}
						/>
					</div>
				</Card.Header>
				<Accordion.Collapse eventKey={1}>
					<Card.Body className="px-3 pt-1">
						{data?.webhook_events &&
							data?.webhook_events?.map((event, idx) =>
								renderWebhookEventDesc(event, idx)
							)}
					</Card.Body>
				</Accordion.Collapse>
			</Card>
		</Accordion>
	);

	return (
		<div className="container ml-1 mt-3 other-scopes">
			{showSelectedCard && renderSelectedWebhookCard()}
			{renderWebhookEventCard()}
		</div>
	);
};

export default WebhookEvents;

WebhookEvents.propTypes = {
	showSelectedCard: PropTypes.bool,
	data: PropTypes.object,
	isWebhookEnabled: PropTypes.bool,
	setIsWebhookEnabled: PropTypes.func,
	disabled: PropTypes.bool,
};

WebhookEvents.defaultProps = {
	showSelectedCard: false,
	data: {},
	isWebhookEnabled: false,
	setIsWebhookEnabled: () => {},
	disabled: false,
};

function ContextAwareToggle({
	children,
	eventKey,
	callback,
	isWebhookEnabled,
}) {
	const [toggleState, setToggleState] = useState(false);

	const decoratedOnClick = useAccordionToggle(eventKey, () => {
		setToggleState(!toggleState);
		callback && callback(eventKey);
	});

	useEffect(() => {
		if (isWebhookEnabled && !toggleState) {
			decoratedOnClick();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isWebhookEnabled]);

	return (
		<span className="pl-1">
			<img
				alt=""
				onClick={decoratedOnClick}
				src={toggleState ? collapsedICon : downArrowIcon}
				width="10px"
				className="ml-2"
			/>
		</span>
	);
}
