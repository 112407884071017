import { TriggerIssue } from "utils/sentry";
import {
	OptimizationV2BarChartSection,
	OptimizationV2LicenseList,
	OptimizationV2Settings,
	OptimizationV2TopRow,
	OptimizeNowMeta,
	OptimizeNowUsersList,
	PastOptimization,
	PastOptimizationMeta,
	PastOptimizationUsersList,
	RealizedSavings,
	RealizedSavingUsersListModel,
} from "../models/Optimization.model";
import {
	deleteOptimizationV2Report,
	getOptimizableV2Licenses,
	getOptimizationV2BarCharts,
	getOptimizationV2Configuration,
	getOptimizationV2LicenseTrend,
	getOptimizationV2Report,
	getOptimizationV2TopRow,
	getOptimizeNowMeta,
	getOptimizeNowUsersList,
	getPastOptimizationList,
	getPastOptimizationMeta,
	getPastOptimizationUsersList,
	getRealizedSavingUsersList,
	getRealizedSavingsList,
} from "../service/OptimizationV2.api";
import {
	generateOptimizationStatuses,
	optimizationV3ReduxConstants,
	optimizationV2ReduxConstants,
} from "../constants/OptimizationConstants";
import axios from "axios";

export function checkAndFetchOptimizationV2TopRow(appId) {
	return async function (dispatch, getState) {
		if (getState().optimizationV2?.[appId]?.topRow?.loaded) {
			return;
		} else {
			dispatch(fetchOptimizationV2TopRow(appId));
		}
	};
}

export function fetchOptimizationV2TopRow(appId) {
	return async function (dispatch) {
		try {
			dispatch({
				type: optimizationV2ReduxConstants.TOP_ROW_REQUESTED,
				payload: { appId },
			});
			const response = await getOptimizationV2TopRow(appId);
			dispatch({
				type: optimizationV2ReduxConstants.TOP_ROW_FETCHED,
				payload: { appId, topRow: new OptimizationV2TopRow(response) },
			});
		} catch (error) {
			dispatch({
				type: optimizationV2ReduxConstants.TOP_ROW_FETCHED,
				payload: {
					appId,
					error: error,
					topRow: new OptimizationV2TopRow({}),
				},
			});
			TriggerIssue(`Error while fetching optimization top row`, error);
		}
	};
}

export function checkAndFetchOptimizationV2Configuration(appId) {
	return async function (dispatch, getState) {
		if (getState().optimizationV2?.[appId]?.config?.loaded) {
			return;
		} else {
			dispatch(fetchOptimizationV2Configuration(appId));
		}
	};
}

export function fetchOptimizationV2Configuration(appId) {
	return async function (dispatch) {
		try {
			dispatch({
				type: optimizationV2ReduxConstants.CONFIGURATION_REQUESTED,
				payload: { appId },
			});
			const response = await getOptimizationV2Configuration(appId);
			dispatch({
				type: optimizationV2ReduxConstants.CONFIGURATION_FETCHED,
				payload: {
					appId,
					config: new OptimizationV2Settings(
						response?.app_optimization_settings || {}
					),
					savedConfig: new OptimizationV2Settings(
						response?.app_optimization_settings || {}
					),
					licenses:
						new OptimizationV2LicenseList({
							licenses:
								response?.license_optimization_settings || [],
						})?.licenses || [],
				},
			});
		} catch (error) {
			dispatch({
				type: optimizationV2ReduxConstants.CONFIGURATION_FETCHED,
				payload: {
					appId,
					error: error,
					config: new OptimizationV2Settings({}),
				},
			});
			TriggerIssue(
				`Error while fetching optimization configuration settings`,
				error
			);
		}
	};
}

export function updateOptimizationV2Configuration(appId, settings) {
	return async function (dispatch) {
		try {
			dispatch({
				type: optimizationV2ReduxConstants.CONFIGURATION_UPDATED,
				payload: { appId, settings },
			});
		} catch (error) {
			TriggerIssue(
				`Error while updating optimization configuration settings`,
				error
			);
		}
	};
}

export function saveOptimizationV2Configuration(appId, settings) {
	return async function (dispatch) {
		try {
			dispatch({
				type: optimizationV2ReduxConstants.CONFIGURATION_SAVED,
				payload: { appId, settings },
			});
		} catch (error) {
			TriggerIssue(
				`Error while saving optimization configuration settings`,
				error
			);
		}
	};
}

export function checkAndFetchOptimizationV2LicenseTrend(appId) {
	return async function (dispatch, getState) {
		if (getState().optimizationV2?.[appId]?.trend?.loaded) {
			return;
		} else {
			dispatch(fetchOptimizationV2LicenseTrend(appId));
		}
	};
}

export function fetchOptimizationV2LicenseTrend(appId) {
	return async function (dispatch) {
		try {
			dispatch({
				type: optimizationV2ReduxConstants.LICENSE_TREND_REQUESTED,
				payload: { appId },
			});
			let response = await getOptimizationV2LicenseTrend(appId);

			if (response.data_s3_url) {
				await axios
					.get(response.data_s3_url, {
						responseType: "json",
					})
					.then(async (res) => {
						response.data = res.data;
					});
			}

			dispatch({
				type: optimizationV2ReduxConstants.LICENSE_TREND_FETCHED,
				payload: {
					appId,
					trend: response,
				},
			});
		} catch (error) {
			console.log("fetchOptimizationV2LicenseTrend", error);
			dispatch({
				type: optimizationV2ReduxConstants.LICENSE_TREND_FETCHED,
				payload: {
					appId,
					error: error,
					trend: {},
				},
			});
			TriggerIssue(
				`Error while fetching optimization license trend`,
				error
			);
		}
	};
}

export function checkAndFetchOptimizationV2BarCharts(appId) {
	return async function (dispatch, getState) {
		if (getState().optimizationV2?.[appId]?.charts?.loaded) {
			return;
		} else {
			dispatch(fetchOptimizationV2BarCharts(appId));
		}
	};
}

export function fetchOptimizationV2BarCharts(appId) {
	return async function (dispatch) {
		try {
			dispatch({
				type: optimizationV2ReduxConstants.BAR_CHART_SECTION_REQUESTED,
				payload: { appId },
			});
			const response = await getOptimizationV2BarCharts(appId);

			let data = {};

			if (response.data_s3_url) {
				await axios
					.get(response.data_s3_url, {
						responseType: "json",
					})
					.then(async (res) => {
						data = res.data;
					});
			}

			dispatch({
				type: optimizationV2ReduxConstants.BAR_CHART_SECTION_FETCHED,
				payload: {
					appId,
					charts: new OptimizationV2BarChartSection(data),
				},
			});
		} catch (error) {
			dispatch({
				type: optimizationV2ReduxConstants.BAR_CHART_SECTION_FETCHED,
				payload: {
					appId,
					error: error,
					charts: new OptimizationV2BarChartSection({}),
				},
			});
			TriggerIssue(`Error while fetching optimization bar charts`, error);
		}
	};
}

export function checkAndFetchOptimizationV2PastList(appId) {
	return async function (dispatch, getState) {
		if (getState().optimizationV2?.[appId]?.pastList?.loaded) {
			return;
		} else {
			dispatch(fetchOptimizationV2PastList(appId));
		}
	};
}

export function fetchOptimizationV2PastList(appId) {
	return async function (dispatch) {
		try {
			dispatch({
				type: optimizationV2ReduxConstants.PAST_OPTIMIZATION_LIST_REQUESTED,
				payload: { appId },
			});
			const response = await getPastOptimizationList(appId);
			dispatch({
				type: optimizationV2ReduxConstants.PAST_OPTIMIZATION_LIST_FETCHED,
				payload: {
					appId,
					past: new PastOptimization(response),
				},
			});
		} catch (error) {
			dispatch({
				type: optimizationV2ReduxConstants.PAST_OPTIMIZATION_LIST_FETCHED,
				payload: {
					appId,
					error: error,
					past: new PastOptimization({}),
				},
			});
			TriggerIssue(`Error while fetching optimization list`, error);
		}
	};
}

export function checkAndFetchOptimizationV2Report({
	appId,
	firstLoad,
	startMonth,
	startYear,
	endMonth,
	endYear,
}) {
	return async function (dispatch, getState) {
		if (getState().optimizationV2?.[appId]?.report?.loaded) {
			return;
		} else {
			dispatch(
				fetchOptimizationV2Report({
					appId,
					firstLoad,
					startMonth,
					startYear,
					endMonth,
					endYear,
				})
			);
		}
	};
}

export function fetchOptimizationV2Report({
	appId,
	firstLoad,
	startMonth,
	startYear,
	endMonth,
	endYear,
	status,
}) {
	return async function (dispatch) {
		try {
			dispatch({
				type: optimizationV2ReduxConstants.REPORT_REQUESTED,
				payload: { appId, firstLoad, status },
			});
			let response = await getOptimizationV2Report({
				appId,
				firstLoad,
				startMonth,
				startYear,
				endMonth,
				endYear,
			});

			if (
				response.status === generateOptimizationStatuses.COMPLETED &&
				response.data_s3_url
			) {
				await axios
					.get(response.data_s3_url, {
						responseType: "json",
					})
					.then(async (res) => {
						response.data = res.data;
					});
			}

			dispatch({
				type: optimizationV2ReduxConstants.REPORT_FETCHED,
				payload: {
					appId,
					report: response.data,
					status: response.status,
				},
			});
		} catch (error) {
			dispatch({
				type: optimizationV2ReduxConstants.REPORT_FETCHED,
				payload: {
					appId,
					error: error,
					report: {},
					status: generateOptimizationStatuses.ERROR,
				},
			});
			TriggerIssue(`Error while fetching optimization report`, error);
		}
	};
}

export function refreshOptimizationV2Report({
	appId,
	firstLoad,
	startMonth,
	startYear,
	endMonth,
	endYear,
}) {
	return async function (dispatch) {
		try {
			dispatch({
				type: optimizationV2ReduxConstants.REPORT_REFRESHED,
				payload: { appId },
			});
			const response = await deleteOptimizationV2Report(appId);
			if (response.status === "success") {
				dispatch(
					fetchOptimizationV2Report({
						appId,
						firstLoad,
						startMonth,
						startYear,
						endMonth,
						endYear,
					})
				);
			}
		} catch (error) {
			TriggerIssue(`Error while refreshing optimization report`, error);
			dispatch(
				fetchOptimizationV2Report({
					appId,
					firstLoad: true,
				})
			);
		}
	};
}

export function modifyOptimizationV2Report(appId) {
	return async function (dispatch) {
		try {
			dispatch({
				type: optimizationV2ReduxConstants.REPORT_REFRESHED,
				payload: { appId },
			});
			await deleteOptimizationV2Report(appId);
			dispatch({
				type: optimizationV2ReduxConstants.REPORT_FETCHED,
				payload: {
					appId,
					report: {},
					status: generateOptimizationStatuses.NOT_GENERATED,
				},
			});
		} catch (error) {
			TriggerIssue(`Error while deleting optimization report`, error);
			dispatch(
				fetchOptimizationV2Report({
					appId,
					firstLoad: true,
				})
			);
		}
	};
}

export function fetchAllOptimizeNowUsers(appId) {
	return async function (dispatch) {
		try {
			let page = 0;
			let loading = true;
			dispatch({
				type: optimizationV2ReduxConstants.OPTIMIZE_NOW_USERS_LIST_REQUESTED,
				payload: { appId },
			});
			while (loading) {
				const response = await getOptimizeNowUsersList(
					appId,
					page,
					1000
				);
				dispatch({
					type: optimizationV2ReduxConstants.OPTIMIZE_NOW_USERS_LIST_FETCHED,
					payload: {
						appId,
						more: response?.userLicenseDetails?.length === 1000,
						users: new OptimizeNowUsersList({
							users: response.userLicenseDetails,
						}),
					},
				});
				if (response?.userLicenseDetails?.length !== 1000) {
					loading = false;
				}
				page++;
			}
		} catch (err) {
			TriggerIssue(`Error while fetching optimize now users`, err);
			dispatch({
				type: optimizationV2ReduxConstants.OPTIMIZE_NOW_USERS_LIST_FETCHED,
				payload: {
					appId,
					more: false,
					users: new OptimizeNowUsersList({}),
					error: err,
				},
			});
		}
	};
}

export function updateOptimizeNowUsers(appId, users) {
	return async function (dispatch) {
		try {
			dispatch({
				type: optimizationV2ReduxConstants.OPTIMIZE_NOW_USERS_UPDATED,
				payload: { appId, users },
			});
		} catch (error) {
			dispatch({
				type: optimizationV2ReduxConstants.OPTIMIZE_NOW_USERS_UPDATED,
				payload: {
					appId,
					error: error,
					users: [],
				},
			});
			TriggerIssue(`Error while updating optimize now users`, error);
		}
	};
}

export function checkAndFetchOptimizeNowMeta(appId) {
	return async function (dispatch, getState) {
		if (getState().optimizationV2?.[appId]?.optimize?.loaded) {
			return;
		} else {
			dispatch(fetchOptimizeNowMeta(appId));
		}
	};
}

export function fetchOptimizeNowMeta(appId) {
	return async function (dispatch) {
		try {
			dispatch({
				type: optimizationV2ReduxConstants.OPTIMIZE_NOW_META_REQUESTED,
				payload: { appId },
			});
			const response = await getOptimizeNowMeta(appId);
			dispatch({
				type: optimizationV2ReduxConstants.OPTIMIZE_NOW_META_FETCHED,
				payload: { appId, meta: new OptimizeNowMeta(response) },
			});
		} catch (error) {
			dispatch({
				type: optimizationV2ReduxConstants.OPTIMIZE_NOW_META_FETCHED,
				payload: {
					appId,
					error: error,
					meta: new OptimizeNowMeta({}),
				},
			});
			TriggerIssue(`Error while fetching optimize now meta`, error);
		}
	};
}

export function fetchAllPastOptimizationUsers(appId, logId) {
	return async function (dispatch) {
		try {
			let page = 0;
			let loading = true;
			dispatch({
				type: optimizationV2ReduxConstants.PAST_OPTIMIZATION_META_REQUESTED,
				payload: { appId, logId },
			});
			dispatch({
				type: optimizationV2ReduxConstants.PAST_OPTIMIZATION_META_REQUESTED,
				payload: { appId, logId },
			});
			while (loading) {
				const response = await getPastOptimizationUsersList(
					appId,
					logId,
					page,
					1000
				);
				dispatch({
					type: optimizationV2ReduxConstants.PAST_OPTIMIZATION_USERS_FETCHED,
					payload: {
						appId,
						logId,
						more: response?.userLicenseDetails?.length === 1000,
						users: new PastOptimizationUsersList({
							users: response.userLicenseDetails,
						}),
					},
				});
				if (page === 0) {
					dispatch({
						type: optimizationV2ReduxConstants.PAST_OPTIMIZATION_META_FETCHED,
						payload: {
							appId,
							logId,
							meta: new PastOptimizationMeta(response.meta),
						},
					});
				}
				if (response?.userLicenseDetails?.length !== 1000) {
					loading = false;
				}
				page++;
			}
		} catch (err) {
			TriggerIssue(
				`Error while fetching past optimization users and meta`,
				err
			);
			dispatch({
				type: optimizationV2ReduxConstants.PAST_OPTIMIZATION_USERS_FETCHED,
				payload: {
					appId,
					logId,
					more: false,
					users: new PastOptimizationUsersList({}),
				},
			});
			dispatch({
				type: optimizationV2ReduxConstants.PAST_OPTIMIZATION_META_FETCHED,
				payload: {
					appId,
					logId,
					error: err,
					meta: new PastOptimizationMeta({}),
				},
			});
		}
	};
}

export function checkAndFetchPastOptimizationMeta(appId, logId) {
	return async function (dispatch, getState) {
		if (getState().optimizationV2?.[appId]?.past?.[logId]?.loaded) {
			return;
		} else {
			dispatch(fetchPastOptimizationMeta(appId, logId));
		}
	};
}

export function fetchPastOptimizationMeta(appId, logId) {
	return async function (dispatch) {
		try {
			dispatch({
				type: optimizationV2ReduxConstants.PAST_OPTIMIZATION_META_REQUESTED,
				payload: { appId, logId },
			});
			const response = await getPastOptimizationMeta(appId, logId);
			dispatch({
				type: optimizationV2ReduxConstants.PAST_OPTIMIZATION_META_FETCHED,
				payload: {
					appId,
					logId,
					meta: new PastOptimizationMeta(response),
				},
			});
		} catch (error) {
			dispatch({
				type: optimizationV2ReduxConstants.PAST_OPTIMIZATION_META_FETCHED,
				payload: {
					appId,
					logId,
					error: error,
					meta: new PastOptimizationMeta({}),
				},
			});
			TriggerIssue(`Error while fetching past optimization meta`, error);
		}
	};
}

//optimization v3

export function fetchOptimizableV3Licenses(
	appId,
	startYear,
	startMonth,
	endYear,
	endMonth
) {
	return async function (dispatch) {
		try {
			dispatch({
				type: optimizationV3ReduxConstants.OPTIMIZATION_LICENSE_TREND_REQUESTED,
				payload: { appId },
			});
			const response = await getOptimizableV2Licenses(
				appId,
				startYear,
				startMonth,
				endYear,
				endMonth
			);
			dispatch({
				type: optimizationV3ReduxConstants.OPTIMIZATION_LICENSE_TREND_FETCHED,
				payload: {
					appId,
					licenses: response,
				},
			});
		} catch (error) {
			dispatch({
				type: optimizationV3ReduxConstants.OPTIMIZATION_LICENSE_TREND_FETCHED,
				payload: {
					appId,
					error: error,
					licenses: {},
				},
			});
			TriggerIssue(
				`Error while fetching optimization license trend`,
				error
			);
		}
	};
}

export function checkAndFetchRealizedSavingsList(appId) {
	return async function (dispatch, getState) {
		if (getState().optimizationV2?.[appId]?.realizedSavings?.loaded) {
			return;
		} else {
			dispatch(fetchRealizedSavingsList(appId));
		}
	};
}

export function fetchRealizedSavingsList(appId) {
	return async function (dispatch) {
		try {
			dispatch({
				type: optimizationV2ReduxConstants.REALIZED_SAVINGS_LIST_REQUESTED,
				payload: { appId },
			});
			const response = await getRealizedSavingsList(appId);
			dispatch({
				type: optimizationV2ReduxConstants.REALIZED_SAVINGS_LIST_FETCHED,
				payload: {
					appId,
					data: new RealizedSavings(response),
				},
			});
		} catch (error) {
			dispatch({
				type: optimizationV2ReduxConstants.REALIZED_SAVINGS_LIST_FETCHED,
				payload: {
					appId,
					error: error,
					data: new RealizedSavings({}),
				},
			});
			TriggerIssue(
				`Error while fetching realized savings breakdown list`,
				error
			);
		}
	};
}

export function fetchRealizedSavingsUsers({
	appId,
	date,
	report_type = "partial",
}) {
	return async function (dispatch) {
		try {
			dispatch({
				type: optimizationV2ReduxConstants.REALIZED_SAVINGS_USERS_LIST_REQUESTED,
				payload: { appId, date },
			});
			const start_period = new Date(date);
			const end_period = new Date(
				new Date(start_period).setUTCHours(23, 59, 59, 999)
			);
			const response = await getRealizedSavingUsersList({
				appId,
				start_period: new Date(date),
				end_period,
				report_type,
			});
			dispatch({
				type: optimizationV2ReduxConstants.REALIZED_SAVINGS_USERS_LIST_FETCHED,
				payload: {
					appId,
					date,
					users: new RealizedSavingUsersListModel({
						users: response,
					}),
				},
			});
		} catch (err) {
			TriggerIssue(
				`Error while fetching realized saving users for date ${date}`,
				err
			);
			dispatch({
				type: optimizationV2ReduxConstants.REALIZED_SAVINGS_USERS_LIST_FETCHED,
				payload: {
					appId,
					date,
					more: false,
					users: new RealizedSavingUsersListModel({}),
					error: err,
				},
			});
		}
	};
}

export function checkAndFetchRealizedSavingsListUsersCSV({
	appId,
	startDate = null,
	endDate = null,
	reportType = "full",
}) {
	return async function (dispatch, getState) {
		if (
			getState().optimizationV2?.[appId]?.realizedSavingsListUsersCSV
				?.loaded
		) {
			return;
		} else {
			dispatch(
				fetchRealizedSavingsListUsersCSV({
					appId: appId,
					startDate: startDate,
					endDate: endDate,
					reportType: reportType,
				})
			);
		}
	};
}

export function fetchRealizedSavingsListUsersCSV({
	appId,
	startDate = null,
	endDate = null,
	reportType = "full",
}) {
	return async function (dispatch) {
		try {
			dispatch({
				type: optimizationV2ReduxConstants.REALIZED_SAVINGS_TOTAL_USERS_REQUESTED,
				payload: { appId },
			});
			const response = await getRealizedSavingUsersList({
				appId: appId,
				start_period: startDate,
				end_period: endDate,
				report_type: reportType,
			});
			dispatch({
				type: optimizationV2ReduxConstants.REALIZED_SAVINGS_TOTAL_USERS_FETCHED,
				payload: {
					appId,
					data: new RealizedSavingUsersListModel({
						users: response,
					}),
				},
			});
		} catch (error) {
			TriggerIssue(
				`Error while fetching all realized saving users`,
				error
			);
			dispatch({
				type: optimizationV2ReduxConstants.REALIZED_SAVINGS_TOTAL_USERS_FETCHED,
				payload: {
					appId,
					more: false,
					data: new RealizedSavingUsersListModel({}),
				},
			});
		}
	};
}
