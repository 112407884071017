import React from "react";
import styled from "styled-components";
import RulesPreview from "../Rules/RulesPreview";
import PropTypes from "prop-types";
import { Typography, GREY_VARIANT } from "@zluri/ui-components";

const ConditionsContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: 10px;
	border-radius: 8px;
	background: var(--secondary-grey-7, #f6f7fa);
	width: 100%;
`;

const ConditionTitle = styled.h2`
	font-size: 12px;
	font-style: normal;
	font-weight: 600;
	line-height: 120%;
	margin: 0px;
	margin-bottom: 8px;
`;

const ModalConditionsPreview = ({ text, title, conditions }) => {
	return (
		<>
			<Typography
				color={GREY_VARIANT.SECONDARY_GREY_3}
				variant="body_2_regular"
				style={{ marginBottom: "10px" }}
			>
				{text}
			</Typography>
			<ConditionsContainer>
				<ConditionTitle>{title}</ConditionTitle>
				<RulesPreview conditions={conditions} groupAfter={1} />
			</ConditionsContainer>
		</>
	);
};

export default ModalConditionsPreview;

ModalConditionsPreview.propTypes = {
	text: PropTypes.string,
	title: PropTypes.string,
	conditions: PropTypes.array,
};
