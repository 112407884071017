import { clientV3 } from "../../utils/client";
import { filterPropertiesHelper } from "modules/v2InfiniteTable/utils/v2infiniteTableUtil";
import _ from "underscore";

export async function getSpendsRules(
	reqObj,
	page,
	row,
	cancelTokenSource = null,
	searchReqParams,
	intID,
	apiProps = {}
) {
	const { tag = "transaction" } = apiProps;
	let options = {};
	let url = `rules?page=${page}&row=${row}&tag=${tag}`;
	if (cancelTokenSource) {
		options = {
			cancelToken: cancelTokenSource.token,
		};
	}

	if (
		_.isObject(searchReqParams) &&
		Object.keys(searchReqParams).length > 0
	) {
		url = `rules?search_query=${encodeURIComponent(
			searchReqParams
		)}&page=${page}&row=${row}&tag=${tag}`;
	}

	const response = await clientV3.post(url, reqObj, options);
	let newResponseData = filterPropertiesHelper(response.data);

	response.data = newResponseData;

	return response.data;
}

export async function getSpendsRulesPropertiesList() {
	const response = await clientV3.get("rules/filters?tag=transaction");
	return response.data;
}

export async function getPreviewMatchingTransactions(
	reqObj,
	page,
	row,
	cancelTokenSource = null,
	searchReqParams,
	intID,
	apiProps = {}
) {
	const { rules_conditions, tag = "transaction" } = apiProps;
	let options = {};
	let url = `rules/preview-matching-entities?page=${page}&row=${row}&tag=${tag}`;
	if (cancelTokenSource) {
		options = {
			cancelToken: cancelTokenSource.token,
		};
	}

	if (
		_.isObject(searchReqParams) &&
		Object.keys(searchReqParams).length > 0
	) {
		url = `rules/preview-matching-entities?search_query=${encodeURIComponent(
			searchReqParams
		)}&page=${page}&row=${row}&tag=${tag}`;
	}

	const response = await clientV3.post(
		url,
		{ ...reqObj, rules_conditions },
		options
	);
	let newResponseData = filterPropertiesHelper(response.data);

	response.data = newResponseData;

	return response.data;
}

export async function getPreviewMatchingTransactionsPropertiesList() {
	const response = await clientV3.get(
		"rules/preview-matching-entities/filters?tag=transaction"
	);
	return response.data;
}

export async function getImpactedTransactions(
	reqObj,
	page,
	row,
	cancelTokenSource = null,
	searchReqParams,
	intID,
	apiProps = {}
) {
	const { ruleId, tag = "transaction" } = apiProps;
	let options = {};
	let url = `rules/${ruleId}/preview-impacted-entities?page=${page}&row=${row}&tag=${tag}`;
	if (cancelTokenSource) {
		options = {
			cancelToken: cancelTokenSource.token,
		};
	}

	if (
		_.isObject(searchReqParams) &&
		Object.keys(searchReqParams).length > 0
	) {
		url = `rules/${ruleId}/preview-impacted-entities?search_query=${encodeURIComponent(
			searchReqParams
		)}&page=${page}&row=${row}&tag=${tag}`;
	}

	const response = await clientV3.post(url, reqObj, options);
	let newResponseData = filterPropertiesHelper(response.data);

	response.data = newResponseData;

	return response.data;
}
