import React from "react";
import { Typography } from "@zluri/ui-components";
import PropTypes from "prop-types";
import tickIcon from "assets/icons/selected_view.svg";
import cn from "classnames";

function CustomFieldSelectEditPopover({ defaultValue, options, onSubmit }) {
	return (
		<div>
			{options?.map((opt) => {
				const isSelected = defaultValue === opt;
				return (
					<div
						key={opt}
						className={cn(
							"d-flex p-2 justify-content-between align-items-start z-v2_table_cell_popover__content",
							isSelected &&
								"z-v2_table_cell_popover__content-active"
						)}
						onClick={() => {
							onSubmit(opt);
						}}
					>
						<Typography
							variant="button_extrasmall_medium"
							color="secondary_grey_1"
						>
							{opt}
						</Typography>
						{isSelected && <img src={tickIcon} alt="tick icon" />}
					</div>
				);
			})}
		</div>
	);
}
CustomFieldSelectEditPopover.propTypes = {
	defaultValue: PropTypes.string,
	options: PropTypes.arrayOf(PropTypes.string),
	onSubmit: PropTypes.func,
};

export default CustomFieldSelectEditPopover;
