import { BODY_VARIANT, GREY_VARIANT, Typography } from "@zluri/ui-components";
import React, { useEffect, useState } from "react";

import editIcon from "assets/edit-icon.svg";
import LongTextTooltip from "modules/shared/components/LongTextTooltip/LongTextTooltip";
import { fetchTransactionTableVendor } from "modules/applications/service/Applications.api";
import { TriggerIssue } from "utils/sentry";
import { Skeleton } from "antd";

export default function HoverVendor(props) {
	const { data, component } = props;
	const [vendorData, setVendorData] = useState();

	useEffect(() => {
		fetchVendorData();
	}, [data]);

	const fetchVendorData = () => {
		if (data[component?.valueKey]) {
			fetchTransactionTableVendor(data[component?.valueKey])
				.then((res) => setVendorData(res))
				.catch((err) => TriggerIssue("Vendor Data Not Found", err));
		}
	};

	return (
		<div>
			{!vendorData ? (
				<div className="d-flex flex-1 flex-row border-radius-8 p-2">
					<Skeleton
						active
						paragraph={{
							rows: 2,
							style: {
								marginBottom: "0px",
							},
						}}
					/>
				</div>
			) : (
				<>
					<div
						className="d-flex justify-content-between p-2 "
						style={{ backgroundColor: "#F6F7FA" }}
					>
						<div className="d-flex flex-column">
							<Typography
								variant={BODY_VARIANT.BODY_2_BOLD}
								color={GREY_VARIANT.SECONDARY_GREY_2}
							>
								<LongTextTooltip
									text={vendorData?.name}
									tooltipName="sidebar-tooltip"
									maxWidth="120px"
								/>
							</Typography>
							<Typography
								variant={BODY_VARIANT.BODY_3_REGULAR}
								color={GREY_VARIANT.SECONDARY_GREY_3}
							>
								{vendorData?.status}
							</Typography>
						</div>
						<div
							onClick={(e) => {
								e.stopPropagation();
								e.preventDefault();
								props.setEditState(true);
							}}
						>
							<img
								src={editIcon}
								alt=""
								style={{ width: "12px", height: "12px" }}
							/>
						</div>
					</div>
					<hr className="outer-border-thin m-0 p-0" />
					<div className="">
						<div className="d-flex justify-content-between p-2">
							<Typography
								variant={BODY_VARIANT.BODY_2_REGULAR}
								color={GREY_VARIANT.SECONDARY_GREY_2}
							>
								Total Contracts
							</Typography>
							<Typography
								variant={BODY_VARIANT.BODY_2_BOLD}
								color={GREY_VARIANT.SECONDARY_GREY_3}
							>
								{vendorData?.active_contracts || 0}
							</Typography>
						</div>
						<hr className="outer-border-thin m-0 p-0" />
						<div className="d-flex justify-content-between p-2">
							<Typography
								variant={BODY_VARIANT.BODY_2_REGULAR}
								color={GREY_VARIANT.SECONDARY_GREY_2}
							>
								Total Applications
							</Typography>
							<Typography
								variant={BODY_VARIANT.BODY_2_BOLD}
								color={GREY_VARIANT.SECONDARY_GREY_3}
							>
								{vendorData?.total_apps || 0}
							</Typography>
						</div>
					</div>
				</>
			)}
		</div>
	);
}
