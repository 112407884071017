import React, { useState } from "react";
import PropTypes from "prop-types";
import { Typography } from "@zluri/ui-components";
import TransactionMappingSideCurtain from "modules/transactionMapping/components/SideCurtain/TransactionMappingGeneralSideCurtain";
import { getValueFromLocalStorage } from "utils/localStorage";
import SideArrow from "modules/applications/components/chargebacks/assets/sideArrow.svg";
const PriorityOrderRenderer = ({ data }) => {
	const { accumulated_data, name, _id } = data;
	const orgId = getValueFromLocalStorage("userInfo")?.org_id;
	const [openMatchingTransactionCurtain, setOpenMatchingTransactionCurtain] =
		useState(false);
	const docsModified = +accumulated_data?.transactions?.docs_modified;
	return (
		<>
			<Typography
				variant="body_1_regular"
				color="secondary_grey_1"
				onClick={() => {
					if (docsModified) {
						setOpenMatchingTransactionCurtain(true);
					}
				}}
			>
				{docsModified ? docsModified : docsModified === 0 ? "0" : "-"}
			</Typography>
			<TransactionMappingSideCurtain
				key={openMatchingTransactionCurtain}
				open={openMatchingTransactionCurtain}
				handleClose={() => {
					setOpenMatchingTransactionCurtain(false);
				}}
				width="1034px"
				header={
					<>
						<Typography variant="subheading_1_regular">
							{name}
						</Typography>
						<img src={SideArrow} alt="sideArrow" />
						<Typography variant="subheading_1_bold">
							Impacted Transactions
						</Typography>
					</>
				}
				curtainItems={[
					{
						type: "V2_TABLE_BLOCK",
						entity: "impacted_transactions",
						intID: orgId,
						paginationEntity: "Transactions",
						apiProps: { ruleId: _id },
					},
				]}
			/>
		</>
	);
};

export default PriorityOrderRenderer;

PriorityOrderRenderer.propTypes = {
	data: PropTypes.object,
};
