import React, { useEffect, useState } from "react";
import "../selectColumn/selectColumn.css";
import bsearch from "assets/icons/blue-search.svg";
import close from "assets/close.svg";
import check from "assets/icons/check-blue.svg";
import { Button } from "UIComponents/Button/Button";
import Divider from "../Divider/Divider";

export default function List({
	options,
	selected = [],
	nameKey = "name",
	onApply,
	children,
	className,
	maxHeight,
	applyDisabled = true,
	onSearch,
	onClear
}) {
	const [columns, setColumns] = useState([...selected]);
	const [searchValue, setSearchValue] = useState("");



	return (
		<div className="sc-list-container ">
			<div className="p-1">			{/* search */}
				<div className="d-flex align-items-center searchbar__wrapper">
					<img src={bsearch} width="12px" className="mr-1" />
					<input
						className="searchbar__input"
						type="text"
						placeholder="Search"
						value={searchValue}
						onChange={(e) => {
							setSearchValue(e.target.value);
							onSearch(e.target.value);
						}}
					/>
					<div
						className="pointer d-flex"
						onClick={() => {
							setSearchValue("");
						}}
					>
						<img src={close} width="9px" />
					</div>
				</div>
			</div>
			<div style={{
				maxHeight: maxHeight || 200,
				overflow: "auto",
				paddingBottom: 110,
			}}>
				{children}
			</div>
			{/* list */}
			{/* <div className="sc-list-container-list">{colList}</div> */}

			{/* footer */}
			<div className="flex justify-content-between">
				<Divider type="new" />
				<Button
					className="m-1 border-radius-8 w-100 font-10"
					onClick={() => {
						setColumns([]);
						onClear && onClear();
					}}
					style={{ background: "#ebebeb", color: "#717171" }}
				>
					Clear All
				</Button>
				<Button
					className="border-radius-8 w-100 font-10 m-1"
					style={{ background: "#5287E8" }}
					onClick={() => {
						onApply();
					}}
					disabled={applyDisabled}
				>
					Apply
				</Button>
			</div>
		</div>
	);
}
