import { client, clientEmployee } from "utils/client";

export async function getAllCertificationAppUsers(
	reqObj,
	page,
	row,
	cancelTokenSource = null,
	searchReqParams,
	intID,
	apiProps
) {
	const { appId, reviewId } = apiProps;
	if (appId && reviewId) {
		const response = await client.post(
			`access-certification/${reviewId}/application/${appId}/users?page=${page}&row=${row}`,
			reqObj
		);
		return response.data;
	}
	return [];
}

export async function getAllCertificationAppUsersPropertiesList({
	appId,
	reviewId,
}) {
	const response = await client.get(
		`access-certification/${reviewId}/application/${appId}/all-filters`
	);
	return response.data || response || {};
}

export async function getAllCertificationAppUsersPropertiesListForCloning({
	appId,
	reviewId,
}) {
	const response = await client.get(`access-certification/all-filters`);
	return response.data || response || {};
}

export async function getAllCertificationAppUsersPropertiesListEmpView({
	appId,
	reviewId,
}) {
	const response = await clientEmployee.get(
		`access-certification/${reviewId}/application/${appId}/all-filters`
	);
	return response.data || response || {};
}

export async function getAccessCertificateApplicationFilters({
	appId,
	reviewId,
}) {
	const response = await client.get(
		`access-certification/${reviewId}/application/${appId}/filters`
	);
	return response.data || response || {};
}

export async function getAccessCertificateApplicationFiltersForEmployee({
	appId,
	reviewId,
}) {
	const response = await clientEmployee.get(
		`access-certification/${reviewId}/application/${appId}/filters`
	);
	return response.data || response || {};
}

export async function getAllCertificationAppUsersEmpView(
	reqObj,
	page,
	row,
	cancelTokenSource = null,
	searchReqParams,
	intID,
	apiProps
) {
	const { appId, reviewId } = apiProps;
	if (appId && reviewId) {
		const response = await clientEmployee.post(
			`access-certification/${reviewId}/application/${appId}/users?page=${page}&row=${row}`,
			reqObj
		);
		return response.data;
	}
	return [];
}

export async function getCertificationReviewersData(
	reqObj,
	page,
	row,
	cancelTokenSource = null,
	searchReqParams,
	intID,
	apiProps
) {
	const { reviewId } = apiProps;
	let options = {};
	let url =
		`access-certification/${reviewId}/reviewers?page=` +
		page +
		"&row=" +
		row;
	if (cancelTokenSource) {
		options = {
			cancelToken: cancelTokenSource.token,
		};
	}

	if (searchReqParams?.length > 0) {
		url = `access-certification/${reviewId}/reviewers?is_search=${true}&search_query=${encodeURIComponent(
			searchReqParams
		)}&page=${page}&row=${row}`;
	}
	let response = await client.post(url, reqObj, options);
	return response.data;
}

export async function getCertificationReviewersDataPropertiesList() {
	const response = await client.get(
		"access-certification/properties/reviewer-filters"
	);
	return response.data;
}
