import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Typography, BLUE_VARIANT, GREY_VARIANT } from "@zluri/ui-components";
import { rulesStatus } from "modules/transactionMapping/constants/ruleMapping";

const PriorityWrapper = styled.div`
	border-radius: 8px;
	background: ${({ $background }) => $background};
	display: flex;
	height: 24px;
	min-width: 24px;
	justify-content: center;
	align-items: center;
`;

const PriorityOrderRenderer = ({ data }) => {
	const { priority_order, status } = data;

	return (
		<PriorityWrapper
			$background={status === rulesStatus.INACTIVE ? "#DDD" : "#e8f0fc"}
		>
			<Typography
				variant="heading_1_semibold"
				color={
					status === rulesStatus.INACTIVE
						? GREY_VARIANT.SECONDARY_GREY_3
						: BLUE_VARIANT.PRIMARY_BLUE_DARK_1
				}
			>
				{priority_order + 1}
			</Typography>
		</PriorityWrapper>
	);
};

export default PriorityOrderRenderer;

PriorityOrderRenderer.propTypes = {
	data: PropTypes.object,
};
