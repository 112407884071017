export const DATA_FRESHNESS_CONSTANTS = {
	USER_APPLICATION: "user_application",
	APPLICATION_LICENSE: "application_license",
	APPLICATION_TRANSACTION: "application_transaction",
	USER_APPLICATION_LAST_ACTIVITY: "user_application_last_activity",
	TRANSACTION: "transaction",
	APPLICATION: "application",
	USER: "user",
	CHARGEBACK: "chargeback",
};
