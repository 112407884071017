import appStatusIcon from "assets/applications/Icon-app-status.svg";
import appTypeIcon from "assets/applications/about/Icon_App_Type.svg";
import appCategoryIcon from "assets/applications/about/Icon_Category.svg";
import appTagIcon from "assets/applications/about/Icon_Tag.svg";
import appLinkIcon from "assets/icon_link.svg";
import ownerIcon from "assets/applications/about/Icon_Owner.svg";
import itOwnerIcon from "assets/applications/about/Icon_IT_Owner.svg";
import financeOwnerIcon from "assets/applications/about/Icon_Finance_Owner.svg";
import contractIcon from "assets/applications/about/Icon_Contracts.svg";
import riskIcon from "assets/applications/about/Icon_Risk.svg";
import complianceCertificateIcon from "assets/applications/about/Icon_Compliance_Certificate.svg";
import threatIcon from "assets/applications/about/Icon_Threat.svg";
import activeStatus from "containers/v2table/BulkEdit/assets/Icon_Active.svg";
import inactiveStatus from "assets/applications/inactivecheck.svg";
import EnterpriseContract from "assets/v2tables/enterprise-contract.svg";
import SubscriptionContract from "assets/v2tables/subscription-contract.svg";
import PerpetualContract from "assets/v2tables/perpetual-contract.svg";
import lowRisk from "assets/low_risk.svg";
import mediumRisk from "assets/medium_risk.svg";
import highRisk from "assets/high_risk.svg";
import contractOwner from "assets/icons/v2Icons/Contract_Owner.svg";
import contractNegotiationOwner from "assets/icons/v2Icons/Icon_Contract_Negotiation_owner.svg";
import contractTechnicalOwner from "assets/icons/v2Icons/Icon_Contract_Technical_owner.svg";
import contractFinanceOwner from "assets/icons/v2Icons/Icon_FinanceOwner.svg";

import { TOAST_MESSAGE } from "modules/shared/constants/toast.constants";

export const manualRiskLevelOptions = [
	{
		label: "Low",
		icon: lowRisk,
		scoreRange: [1, 2],
		value: 1,
	},
	{
		label: "Medium",
		icon: mediumRisk,
		scoreRange: [3],
		value: 3,
	},
	{
		label: "High",
		icon: highRisk,
		scoreRange: [4, 5],
		value: 5,
	},
];

export const appStatusOptions = [
	{
		icon: activeStatus,
		label: "Active",
		value: "active",
	},
	{
		label: "Inactive",
		icon: inactiveStatus,
		value: "inactive",
	},
];

export const boolCustomFieldOptions = [
	{
		label: "Yes",
		value: "Yes",
	},
	{
		label: "No",
		value: "No",
	},
];

export const statusTypes = {
	ACTIVE: "active",
	INACTIVE: "inactive",
};

export const ContractIcons = {
	contract: EnterpriseContract,
	subscription: SubscriptionContract,
	perpetual: PerpetualContract,
};

export const ContractTypes = {
	contract: "contract",
	subscription: "subscription",
	perpetual: "perpetual",
};

export const statusList = {
	ACTIVE: {
		statusIcon: activeStatus,
		statusLabel: "Active",
		statusStyle: {
			backgroundColor: "#e7f8e8",
			color: "#009307",
			padding: "2px 8px",
		},
		statusClassName: "active-status",
	},
	INACTIVE: {
		statusIcon: inactiveStatus,
		statusLabel: "Inactive",
		statusStyle: {
			backgroundColor: "#ffe9e5",
			color: "#bb2915",
			padding: "2px 8px",
		},
		statusClassName: "inactive-status",
	},
};

export const dataComponent = {
	TEXT: "TEXT",
	MULTITEXT: "MULTITEXT",
	CATEGORY: "CATEGORY",
	TAG: "TAG",
};

export const editType = {
	POPOVER: "POPOVER",
	INPUT: "INPUT",
	CALENDAR: "CALENDAR",
};

export const fetchState = {
	INITIAL: "INITIAL",
	LOADING: "LOADING",
	ERROR: "ERROR",
	FETCHED: "FETCHED",
};

export const appPropertyHeaderKeys = [
	"app_detail",
	"app_roles",
	"app_contracts",
	"custom_fields",
];

export const securityPropertyHeaderKeys = ["app_compliance"];

export const ownerKeys = {
	app_owner_name: {
		id: "app_owner_id",
		name: "app_owner_name",
		image: "app_owner_profile",
		email: "app_owner_email",
		type: "app_owner_account_type",
	},
	app_technical_owner_name: {
		id: "app_technical_owner_id",
		name: "app_technical_owner_name",
		image: "app_technical_owner_profile",
		email: "app_technical_owner_email",
		type: "app_technical_owner_account_type",
	},
	app_financial_owner_name: {
		id: "app_financial_owner_id",
		name: "app_financial_owner_name",
		image: "app_financial_owner_profile",
		email: "app_financial_owner_email",
		type: "app_financial_owner_account_type",
	},
};

export const appOverviewProperty = [
	{
		header_id: "app_detail",
		header_text: "Application Details",
		sub_detail: [
			{
				field_id: "app_status",
				field_icon: appStatusIcon,
				field_sub_detail_text: "Application Status",
				field_empty_text: "Add Application Status",
				component_type: dataComponent.TEXT,
				is_editable: true,
				edit_type: editType.POPOVER,
			},
			{
				field_id: "app_type",
				field_icon: appTypeIcon,
				field_sub_detail_text: "Type",
				field_empty_text: "Add Type",
				component_type: dataComponent.TEXT,
				is_editable: true,
				edit_type: editType.POPOVER,
			},
			{
				field_id: "app_sub_categories",
				field_icon: appCategoryIcon,
				field_sub_detail_text: "Category",
				field_empty_text: "Add Category",
				component_type: dataComponent.CATEGORY,
				edit_type: editType.POPOVER,
			},
			{
				field_id: "app_link",
				field_icon: appLinkIcon,
				field_sub_detail_text: "App Link",
				component_type: dataComponent.TEXT,
				edit_type: editType.INPUT,
				hide_edit_popover: true,
			},
			{
				field_id: "app_tags",
				field_icon: appTagIcon,
				field_sub_detail_text: "App Tags",
				component_type: dataComponent.TAG,
				is_editable: true,
				edit_type: editType.POPOVER,
			},
		],
	},
	{
		header_id: "app_roles",
		header_text: "Roles & Responsibilities",
		sub_detail: [
			{
				field_id: "app_owner_name",
				field_icon: ownerIcon,
				field_sub_detail_text: "Owner",
				field_empty_text: "Add Owner",
				component_type: dataComponent.TEXT,
				is_editable: true,
				edit_type: editType.POPOVER,
			},
			{
				field_id: "app_technical_owner_name",
				field_icon: itOwnerIcon,
				field_sub_detail_text: "IT Owner",
				field_empty_text: "Add IT Owner",
				component_type: dataComponent.TEXT,
				is_editable: true,
				edit_type: editType.POPOVER,
			},
			{
				field_id: "app_financial_owner_name",
				field_icon: financeOwnerIcon,
				field_sub_detail_text: "Finance Owner",
				field_empty_text: "Add Finance Owner",
				component_type: dataComponent.TEXT,
				is_editable: true,
				edit_type: editType.POPOVER,
			},
		],
	},
	{
		header_id: "app_contracts",
		header_text: "Contracts & Licenses",
		sub_detail: [
			{
				field_id: "app_active_contracts",
				field_icon: contractIcon,
				field_sub_detail_text: "Contracts",
				component_type: dataComponent.MULTITEXT,
			},
			{
				field_id: "contract_owners",
				field_icon: contractOwner,
				field_sub_detail_text: "Contract Owners",
				component_type: dataComponent.MULTITEXT,
			},
			{
				field_id: "contract_IT_owners",
				field_icon: contractTechnicalOwner,
				field_sub_detail_text: "Contract Technical Owner",
				component_type: dataComponent.MULTITEXT,
			},
			{
				field_id: "contract_financial_owners",
				field_icon: contractFinanceOwner,
				field_sub_detail_text: "Contract Financial Owner",
				component_type: dataComponent.MULTITEXT,
			},
			{
				field_id: "contract_negotiation_owners",
				field_icon: contractNegotiationOwner,
				field_sub_detail_text: "Contract Negotiation Owner",
				component_type: dataComponent.MULTITEXT,
			},
		],
	},
	{
		header_id: "app_compliance",
		header_text: "Security & Compliance",
		sub_detail: [
			{
				field_id: "manual_risk_level",
				field_icon: riskIcon,
				field_sub_detail_text: "Risk Level",
				field_empty_text: "Add Risk Level",
				component_type: dataComponent.TEXT,
				is_editable: true,
				edit_type: editType.POPOVER,
				is_security_overview: true,
			},
			{
				field_id: "compliance",
				field_icon: complianceCertificateIcon,
				field_sub_detail_text: "Compliance Certificates",
				component_type: dataComponent.TAG,
				is_security_overview: true,
			},
			{
				field_id: "threat",
				field_icon: threatIcon,
				field_sub_detail_text: "Threat Level",
				component_type: dataComponent.TEXT,
				is_security_overview: true,
			},
		],
	},
];

export const OWNER_TOAST_CONTENT = {
	app_owner_id: {
		success: TOAST_MESSAGE.OWNER_UPDATE_SUCCESS,
		error: TOAST_MESSAGE.OWNER_UPDATE_ERROR,
	},
	app_technical_owner_id: {
		success: TOAST_MESSAGE.IT_OWNER_UPDATE_SUCCESS,
		error: TOAST_MESSAGE.IT_OWNER_UPDATE_ERROR,
	},
	app_financial_owner_id: {
		success: TOAST_MESSAGE.FINANCE_OWNER_UPDATE_SUCCESS,
		error: TOAST_MESSAGE.FINANCE_OWNER_UPDATE_ERROR,
	},
};

export const APP_TOAST_CONTENT = {
	app_status: {
		success: TOAST_MESSAGE.APP_STATUS_UPDATE_SUCCESS,
		error: TOAST_MESSAGE.APP_STATUS_UPDATE_ERROR,
	},
	app_type: {
		success: TOAST_MESSAGE.APP_TYPE_UPDATE_SUCCESS,
		error: TOAST_MESSAGE.APP_TYPE_UPDATE_ERROR,
	},
	app_tags: {
		success: TOAST_MESSAGE.APP_TAGS_UPDATE_SUCCESS,
		error: TOAST_MESSAGE.APP_TAGS_UPDATE_ERROR,
	},
};
