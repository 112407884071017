import draft from "../assets/icon-draft.svg";
import inactive from "../assets/icon-inactive.svg";
import active from "../assets/mapping-successful.svg";
import moment from "moment";
import ruleBased from "modules/transactionMapping/assets/transactionTable/rule-based.svg";
import manual from "modules/transactionMapping/assets/transactionTable/manual.svg";
import zluriAi from "modules/transactionMapping/assets/transactionTable/zluri-ai.svg";

export const rulesStatus = {
	ACTIVE: "active",
	INACTIVE: "inactive",
	DRAFT: "draft",
};

export const ruleMappingStatesDropdown = [
	{
		icon: active,
		label: "Active",
		value: rulesStatus.ACTIVE,
		key: rulesStatus.ACTIVE,
		textColor: "var(--accent-green-4, #009307)",
		backgroundColor: "var(--accent-green-3, #E7F8E8)",
	},
	{
		icon: inactive,
		label: "Inactive",
		value: rulesStatus.INACTIVE,
		key: rulesStatus.INACTIVE,
		textColor: "var(--accent-red-4, #BB2915)",
		backgroundColor: " var(--accent-red-3, #FFE9E5)",
	},
	{
		icon: draft,
		label: "Draft",
		value: rulesStatus.DRAFT,
		key: rulesStatus.DRAFT,
		textColor: "var(--secondary-grey-3, #717171)",
		backgroundColor: "var(--secondary-grey-7, #F6F7FA)",
	},
];

export const ruleStatesObject = {
	active: {
		icon: active,
		label: "Active",
		textColor: "var(--accent-green-4, #009307)",
		backgroundColor: "var(--accent-green-3, #E7F8E8)",
		value: "active",
	},
	inactive: {
		icon: inactive,
		label: "Inactive",
		textColor: "var(--secondary-grey-3, #717171)",
		backgroundColor: "var(--secondary-grey-7, #F6F7FA)",
		value: "inactive",
	},
	draft: {
		icon: draft,
		label: "Draft",
		textColor: "var(--secondary-grey-3, #717171)",
		backgroundColor: "var(--secondary-grey-7, #F6F7FA)",
		value: "draft",
	},
};

export const rulesCondition = [
	{
		label: "AND Condition",
		value: "AND",
		textColor: "var(--Accent-Blue-4, #1176BE)",
		backgroundColor: "var(--Accent-Blue-3, #E6F5FF)",
	},
	{
		label: "OR Condition",
		value: "OR",
		textColor: "var(--Accent-Amber-4, #C97B07)",
		backgroundColor: "var(--Accent-Amber-3, #FFF6DC)",
	},
];

export const emptyRule = {
	name: `Rule created on ${moment(new Date()).format("DD MMM YYYY hh:mm A")}`,
	conditions: {},
	tag: "transaction",
	actions: [],
};

export const transactionTableColumns = {
	RULE: {
		label: "Rule based",
		icon: ruleBased,
	},
	MANUAL: {
		label: "Manual",
		icon: manual,
	},
	ZLURI_AI: {
		label: "Zluri AI",
		icon: zluriAi,
	},
};

export const previewMappingTransactionPathMapping = {
	"$.description": "$.transaction_description",
};

export const executionType = {
	EXECUTE_ALL: "execute_all",
	FAILED: "failed",
};

export const executeAllRulesAcknowledgementsMapping = {
	"execute-all": ({ totalTransactionCount }) => [
		`I understand that a total of ${totalTransactionCount} transactions may be impacted by the rules.`,

		"I am aware that this process is irreversible and cannot be stopped once initiated.",

		"I acknowledge that, due to the significant number of transactions, the process may take a few hours to be completed.",

		"I understand that rule creation and editing options will be temporarily disabled until the process is successfully completed.",

		"I am aware that the transaction that does match any rules will remain unchanged.",
	],
	"execute-all-unrecognized": ({ unrecognizedTransactionCount }) => [
		`Total of ${unrecognizedTransactionCount} unrecognized  transactions will be impacted by the rules`,

		"I am aware that this process is irreversible and cannot be stopped once initiated.",

		"I acknowledge that, due to the significant number of transactions, the process may take a few hours to be completed.",

		"I understand that rule creation and editing options will be temporarily disabled until the process is successfully completed.",

		"I am aware that the transaction that does match any rules will remain unchanged.",
	],
};

export const getConditionsCount = (condition_obj) => {
	let total_count = 0;
	if (condition_obj && condition_obj?.any && condition_obj?.any.length > 0) {
		condition_obj.any.forEach((any_obj) => {
			total_count += any_obj.all?.length;
		});
	}
	return total_count;
};

export const executeAllStatuses = {
	FAILED: "failed",
	RUNNING: "running",
	SUCCESS: "success",
};
