function convertCustomFieldFilterPayload(appliedFilter) {
	return appliedFilter?.map((f) => {
		if (f.is_custom) {
			const appliedValues = f.field_values;
			return {
				...f,
				field_values: {
					custom_field_id: f.custom_field_id,
					custom_field_values: appliedValues,
				},
			};
		}
		return { ...f };
	});
}

function convertCustomFieldFilterResponse(filterResponse) {
	return filterResponse?.map((f) => {
		if (f.is_custom && f.field_values?.custom_field_id) {
			const formatted = {
				...f,
				field_values: f.field_values?.custom_field_values,
			};
			return formatted;
		}
		return { ...f };
	});
}

export { convertCustomFieldFilterPayload, convertCustomFieldFilterResponse };
