import React, { useState } from "react";
import cn from "classnames";
import { Typography, Field, Button, theme } from "@zluri/ui-components";
import closeIcon from "assets/Icon_Close.svg";
import tagIcon from "assets/Icon_Tag.svg";

export default function TagsBulkEdit({ option, entity, onOptionClick }) {
	const [tags, setTags] = useState([]);

	const [inputValue, setInputValue] = useState("");
	const handleSubmit = () => {
		onOptionClick(option, { value: tags });
	};

	return (
		<div style={{ maxWidth: 220 }}>
			<div
				style={{
					height: 37,
					display: "flex",
					flexDirection: "column",
					background: "#F6F7FA",
					padding: 8,
				}}
			>
				<Typography variant="body_1_bold">Tags</Typography>
			</div>
			<div style={{ padding: 8 }}>
				<div className="flex gap-4 flex-wrap">
					{tags?.map((value, index) => {
						return (
							<button
								className={cn("z__tags_v2-pill")}
								key={value + index}
								onClick={() =>
									setTags((_t) => {
										return _t.filter((d) => d !== value);
									})
								}
							>
								<Typography
									variant="button_extrasmall_regular"
									color="#5287E8"
								>
									{value}
								</Typography>
								<img src={closeIcon} alt="close icon" />
							</button>
						);
					})}
				</div>
			</div>
			{tags.length > 0 && (
				<div
					style={{ paddingRight: 8, paddingLeft: 8, marginBottom: 8 }}
				>
					<div className="separator" />
				</div>
			)}
			<div
				style={{ padding: 8, paddingTop: 0 }}
				className="flex flex-column gap-4"
			>
				<Field
					handlers={{
						onChange: (e) => {
							setInputValue(e.target.value);
						},
						onKeyDown: (e) => {
							if (e.key === "Enter" && inputValue) {
								setTags((_t) => [..._t, inputValue]);
								setInputValue("");
							}
						},
					}}
					prefixIcon={tagIcon}
					type="text"
					fieldType="input"
					value={inputValue}
					placeholder="Add Tags"
					className="font-11 bold-400 grey-1"
				/>
				<div className="flex gap-1">
					<Typography
						variant="alerttag_extrasmall_medium"
						color="#717171"
					>
						Press ENTER after typing to add a tag.
					</Typography>
				</div>
			</div>
			<div
				style={{ padding: "6px 8px 8px 8px" }}
				className="flex gap-2 justify-content-between"
			>
				<Button
					variant="primary-alternative"
					onClick={() => {}}
					style={{ minWidth: 98 }}
				>
					Cancel
				</Button>
				<Button
					theme={theme}
					variant="primary-default"
					style={{ minWidth: 98 }}
					disabled={!!inputValue}
					onClick={handleSubmit}
				>
					Save
				</Button>
			</div>
		</div>
	);
}
