import React from "react";
import ReviewDetailsForm from "../components/ReviewDetailsForm/ReviewDetailsForm";
import SelectApplications from "../components/SelectApplications/SelectApplications";
import SelectApplicationInModal from "../components/SelectApplicationInModal/SelectApplicationInModal";
import SelectUsers from "../components/SelectUsers/SelectUsers";
import CompleteReviewSetup from "../components/CompleteReviewSetup/CompleteReviewSetup";
import ConfigureReviewActions from "../components/ConfigureReviewActions/ConfigureReviewActions";
import ongoingReviewsIcon from "assets/icons/Icon_OngoingReview.svg";
import completedReviewsIcon from "assets/icons/Icon_CompletedReviews.svg";
import upcoming_certification_icon from "assets/icons/Icon_UpcomingReview.svg";
import SelectColumns from "../components/SelectColumns/SelectColumns";
import reviewStageSvg from "assets/icons/review-stage.svg";
import taskStageSvg from "assets/icons/task-stage.svg";
import successSvg from "containers/v2table/BulkEdit/assets/greenCheckCircle.svg";
import failureSvg from "containers/v2table/BulkEdit/assets/unAuthorizedRed.svg";
import squaregreen from "assets/v2tables/square_green.svg";
import squareamber from "assets/v2tables/square_amber.svg";
import squarered from "assets/v2tables/square_red.svg";
import warningYellow from "assets/icons/Icon_Help_Yellow.svg";
import warningRed from "components/Onboarding/warning.svg";

import {
	NumberPill,
	PrimaryButtonVariant,
	SecondaryButtonVariant,
	BODY_VARIANT,
	GREY_VARIANT,
	Typography,
} from "@zluri/ui-components";
import {
	bulkEditReviewersPreviewCounts,
	bulkEditReviewersPreviewCountsEmpView,
	bulkEditUsersPreviewCounts,
	bulkEditUsersPreviewCountsEmpView,
	isSignOffFeasible,
	isSignOffFeasibleEmpView,
	performSignOff,
	performSignOffEmpView,
	signOffJobStatus,
	signOffJobStatusEmpView,
} from "../service/AccessReview.service";

export const SIDEBAR = "AccessReviewCompSidebar";
export const CREATE_NEW_CERTIFICATION = "Create New Certification";
export const CERTIFICATION_DETAILS = "Certification Details";
export const SELECT_APPLICATIONS = "Select Applications";
export const SELECT_APPLICATION = "Select Application";
export const APPLICATION = "Application *";
export const SELECT_APPLICATION_TEXT_LABEL =
	"Start typing an application you’d like to review";
export const PRIMARY_REVIEWER = "primary_reviewer";
export const FALLBACK_REVIEWER = "fallback_reviewer";
export const ADD_APPLICATION = "Add Application";
export const SET_UP_CERTIFICATION = "Set Up Certification";
export const SELECT_REVIEWER = "Select Reviewer";
export const SELECT_REVIEWERS = "Select Reviewers";
export const COMPLETE_SETUP = "Complete Setup";
export const CONFIGURE_ACTIONS = "Configure Actions";
export const ACCESS_REVIEWS = "Access Reviews";
export const SEND_REMINDER = "Send Reminder";
export const CONCLUDE_REVIEW = "Conclude Review";
export const COMPLETE_CERTIFICATION = "Complete Certification";
export const ADD_A_NOTE = "Add a note";
export const TODAY = "Today";
export const DUE_DATE = "Due Date";
export const START_DATE = "Start Date";
export const ACCESS_CERTIFICATIONS = "Access Certifications";
export const SEARCH_FOR_APPLICATION = "Search for Application";
export const reviewCount = 400;
export const DELEGATE_REVIEW = "Delegate Review";
export const REVIEW_ACTION = "Review Action";
export const APPROVED = "approved";
export const MODIFIED = "modified";
export const PENDING = "pending";
export const DECLINED = "declined";
export const INVALID_DATA = "INVALID_DATA";
export const MAX_NUMBER_OF_LEVELS = 5;
export const HASH_ONGOING_DETAILS = "#ongoingdetails";
export const HASH_UPCOMING_DETAILS = "#upcomingdetails";
export const HASH_COMPLETED_DETAILS = "#completeddetails";
export const COMPLETED = "completed";
export const NOT_STARTED = "not_started";
export const TOOLTIP_SIGNOFF_CHECKMARK =
	"This record has been reviewed and signed off";
export const TOOLTIP_DELEGATE_REVEIWER_DISABLED_SIGNEDOFF =
	"Reviewer can't be edited since the record is reviewed and signed off";
export const TOOLTIP_DELEGATE_REVIEWER_DISABLED_IF_NOT_OWNER =
	"Reviewer can't be edited since you're not the reviewer or certification owner";
export const NO_PENDING_REVIEWS_HEADER =
	"You have no pending reviews in this certification";
export const NO_PENDING_REVIEWS_SUBHEADER =
	"Click on the button below to go back to the home page";

export const V2_TABLE_ENTITIES = {
	APP_OVERVIEW_USERS: "app_review_users",
	APP_OVERVIEW_USERS_EMP_VIEW: "app_users_emp_view",
	REVIEWER_TABLE: "access_cert_reviewers",
	SELECT_USERS: "select_users",
	SELECT_COLUMNS: "select_columns",
};

export const BUTTONS = {
	PLUS_ADD_APPLICATION: "+ Add Application",
	NEXT: "Next",
	CANCEL: "Cancel",
	ADD_APPLICATION: "Add Application",
	CREATE_CERTIFICATION: "Create Certification",
	SAVE_APPLICATION: "Save Application",
};

export const STATUS = {
	Initial: "Initial",
	InProgress: "In progress",
	Completed: "Completed",
};

export const reviewTypes = [
	{
		name: "Applications",
		info: "Select applications and users belonging to each application",
		isActive: true,
	},
	{
		name: "Groups",
		info: "Select groups and users belonging to each group",
		isActive: false,
	},
	{
		name: "Users",
		info: "Select individual users or users belonging to specific directory entities (department, cost center, etc.)",
		isActive: false,
	},
	{
		name: "Resources",
		info: "Select resources and users with access to each resource",
		isActive: false,
	},
	{
		name: "Entitlements",
		info: "Select specific entitlements and users with these entitlements",
		isActive: false,
	},
];

export const completeReviewTypes = [
	{
		name: "Create Certification",
		info: "Select when your certification should start & end.",
		isActive: true,
	},
	{
		name: "Save as Template",
		info: "Save your certification as a template that can be reused later.",
		isActive: false,
	},
];

export const DATE_FORMAT = "DD MMM YYYY";
export const Actions = {
	NEXT: "NEXT",
	PREV: "PREV",
};

export const StatesForCreateReviewPage = {
	ReviewDetailsForm: {
		name: "ReviewDetailsForm",
		Comp: ReviewDetailsForm,
		NEXT: "SelectApplications",
	},
	SelectApplications: {
		name: "SelectApplications",
		Comp: SelectApplications,
		PREV: "ReviewDetailsForm",
		NEXT: "CompleteReviewSetup",
	},
	CompleteReviewSetup: {
		name: "CompleteReviewSetup",
		Comp: CompleteReviewSetup,
		PREV: "SelectApplications",
	},
};

export const completeSetupObj = {
	certification_extension_enabled: false,
	review_setup_action: "Create Certification",
	end_date: "",
	start_date: "",
	remediation_end_date: "",
};

export const ViewsInCreateReviewPage = {
	CurrentView: StatesForCreateReviewPage.ReviewDetailsForm,
	States: StatesForCreateReviewPage,
};

export const StatesForAddApplicationModal = {
	SelectApplicationInModal: {
		name: "SelectApplicationInModal",
		Comp: SelectApplicationInModal,
		NEXT: "SelectUsers",
	},
	SelectUsers: {
		name: "SelectUsers",
		Comp: SelectUsers,
		PREV: "SelectApplicationInModal",
		NEXT: "SelectColumns",
	},
	SelectColumns: {
		name: "SelectColumns",
		Comp: SelectColumns,
		PREV: "SelectUsers",
		NEXT: "ConfigureReviewActions",
	},
	ConfigureReviewActions: {
		name: "ConfigureReviewActions",
		Comp: ConfigureReviewActions,
		PREV: "SelectColumns",
	},
};

export const ViewType = {
	ViewsInAddApplicationModal: "ViewsInAddApplicationModal",
	ViewsInCreateReviewPage: "ViewsInCreateReviewPage",
};

export const ViewsInAddApplicationModal = {
	CurrentView: StatesForAddApplicationModal.SelectApplicationInModal,
	States: StatesForAddApplicationModal,
};

export const progressBar = JSON.stringify([
	{
		STEP_NUM: "1",
		STEP: "Certification Details",
		STEP_INFO: "Lorem ipsum dolor sit amet",
		STATUS: STATUS.InProgress,
		ACTIVE: true,
	},
	{
		STEP_NUM: "2",
		STEP: "Set Up Certification",
		STEP_INFO: "Lorem ipsum dolor sit amet",
		STATUS: STATUS.Initial,
		ACTIVE: false,
	},
	{
		STEP_NUM: "3",
		STEP: "Complete Setup",
		STEP_INFO: "Lorem ipsum dolor sit amet",
		STATUS: STATUS.Initial,
		ACTIVE: false,
	},
]);

export const progressBarInModal = JSON.stringify([
	{
		STEP_NUM: "1",
		STEP: "Select App",
		STEP_INFO: "Lorem ipsum dolor sit amet",
		STATUS: STATUS.InProgress,
	},
	{
		STEP_NUM: "2",
		STEP: "Select Users",
		STEP_INFO: "Lorem ipsum dolor sit amet",
		STATUS: STATUS.Initial,
	},
	{
		STEP_NUM: "3",
		STEP: "Select Columns",
		STEP_INFO: "Lorem ipsum dolor sit amet",
		STATUS: STATUS.Initial,
	},
	{
		STEP_NUM: "4",
		STEP: "Setup Actions",
		STEP_INFO: "Lorem ipsum dolor sit amet",
		STATUS: STATUS.Initial,
	},
]);

export const reviewDetailsFormStrings = {
	type: "type",
	name: "name",
	owner: "owner",
	description: "description",
};

export const reviewDetailsForm = {
	review_type: "Applications",
	name: "",
	owner: "",
	description: "",
};

export const configureReviewActions = {
	DO_NOTHING: "Do nothing",
	SELECT_PLAYBOOK: "Select Playbook",
	SETUP_MANUAL_TASK: "Manual Task",
};

export const defaultApproveAction = [
	{
		action: configureReviewActions.DO_NOTHING,
	},
];

export const defaultActions = [
	{
		action: configureReviewActions.SETUP_MANUAL_TASK,
		// disabled: true,
	},
	{
		action: configureReviewActions.SELECT_PLAYBOOK,
		disabled: true,
	},
];

export const selectApplicationHeaders = [
	{ name: "Application" },
	{ name: "Users" },
	{ name: "Levels" },
];

export const defaultReviewActions = {
	action: configureReviewActions.SELECT_PLAYBOOK,
	assignee: {},
	description: "",
	due_in_days: "",
	assignee_id: "",
	workflow_template_id: {},
};

export const defaultAppState = {
	application: {},
	reviewers: {},
	review_actions: {
		on_accept: {
			...defaultReviewActions,
			action: configureReviewActions.DO_NOTHING,
		},
		on_decline: defaultReviewActions,
		on_modify: defaultReviewActions,
	},
	levels: [],
};

export const actions = {
	on_accept: "on_accept",
	on_decline: "on_decline",
	on_modify: "on_modify",
};

export const certification_stage = {
	REVIEW: "review",
	ACTIONS: "actions",
	COMPLETED: "complete",
	UPCOMING: "upcoming",
	USER_CREATION_IN_PROGRESS: "user_creation_in_progress",
	NON_ONG_REVIEW: "non_ong_review",
	ARCHIVED: "archived",
	CONCLUDE_REVIEW_JOB_IN_PROGRESS: "conclude_review_job_in_progress",
};

export const createReviewActions = {
	UPDATE_REVIEW_DETAILS_FORM: "UPDATE_REVIEW_DETAILS_FORM",
	UPDATE_PROGRESS_BAR: "UPDATE_PROGRESS_BAR",
	CHANGE_VIEW: "CHANGE_VIEW",
	UPDATE_SELECTED_APPLICATION: "UPDATE_SELECTED_APPLICATION",
	TOGGLE_LOADER: "TOGGLE_LOADER",
	UPDATE_REVIEWERS_INFO: "UPDATE_REVIEWERS_INFO",
	TOGGLE_ADD_APPLICATION_MODAL: "TOGGLE_ADD_APPLICATION_MODAL",
	FETCH_DEFAULT_USERS_AND_ROLES: "FETCH_DEFAULT_USERS_AND_ROLES",
	UPDATE_COMPLETE_REVIEW_SETUP: "UPDATE_COMPLETE_REVIEW_SETUP",
	MAKE_CURRENT_STEP_ACTIVE: "MAKE_CURRENT_STEP_ACTIVE",
	EDIT_APPLICATION: "EDIT_APPLICATION",
	DELETE_APPLICATION: "DELETE_APPLICATION",
	ADD_APPLICATION: "ADD_APPLICATION",
	UPDATE_ACTIONS_DATA: "UPDATE_ACTIONS_DATA",
	IS_EDITING_APP: "IS_EDITING_APP",
	CLEAR_MODAL_DATA: "CLEAR_MODAL_DATA",
	CLEAR_ALL_REVIEW_DETAILS: "CLEAR_ALL_REVIEW_DETAILS",
	EMPTY_APPLICATIONS_ARRAY: "EMPTY_APPLICATIONS_ARRAY",
	SET_REVIEW_DATA: "SET_REVIEW_DATA",
	UPDATING_COLUMNS: "UPDATING_COLUMNS",
	UPDATING_FILTER_BY: "UPDATING_FILTER_BY",
	ADD_LEVEL: "ADD_LEVEL",
	REMOVE_LEVEL: "REMOVE_LEVEL",
	SET_LEVEL_DATA: "SET_LEVEL_DATA",
	ADD_INITIAL_LEVEL: "ADD_INITIAL_LEVEL",
	REMOVE_INITIAL_LEVEL: "REMOVE_INITIAL_LEVEL",
	SET_INITIAL_LEVEL_DATA: "SET_INITIAL_LEVEL_DATA",
};

export const GetAccessReviewSidebarData = (
	ongoingReviewsCount,
	upcomingReviewsCount,
	completedReviewsCount
) => {
	return [
		{
			tab: [
				{
					hash: "#ongoing",
					text: "Ongoing",
					tabLogo: ongoingReviewsIcon,
					element: NumberPill({
						number: ongoingReviewsCount || 0,
						style: sidebarTabStyle,
					}),
				},
				{
					hash: "#upcoming",
					text: "Upcoming",
					tabLogo: upcoming_certification_icon,
					element: NumberPill({
						number: upcomingReviewsCount || 0,
						style: sidebarTabStyle,
					}),
				},
				{
					hash: "#completed",
					text: "Completed",
					tabLogo: completedReviewsIcon,
					element: NumberPill({
						number: completedReviewsCount || 0,
						style: sidebarTabStyle,
					}),
				},
			],
		},
	];
};

const sidebarTabStyle = {
	marginLeft: "auto",
	padding: "8px",
	width: "fit-content",
	height: "fit-content",
	lineHeight: "8px",
	fontSize: "8px",
	fontWeight: "600",
	color: "white",
	background: "#2266E2",
	borderRadius: "4px",
};

export const ongoingReviewsSelectButtonsData = [
	{
		selector: certification_stage.REVIEW,
		data: {
			name: "Review Stage",
		},
	},
	{
		selector: certification_stage.ACTIONS,
		data: {
			name: "Actions Stage",
		},
	},
];

export const ACTION_TYPE = {
	GET_REVIEWS: "GET_REVIEWS",
	GET_ACTION_FILTERS: "GET_ACTION_FILTERS",
	FETCHED_ACTION_FILTERS: "FETCHED_ACTION_FILTERS",
	CLEAR_ACTION_FILTERS: "CLEAR_ACTION_FILTERS",
	CLONE_AND_EDIT_CERT_DATA: "CLONE_AND_EDIT_CERT_DATA",
	FETCHING_CERT_DATA: "FETCHING_CERT_DATA",
	UPDATE_SIGN_OFF_JOB_STATUS: "UPDATE_SIGN_OFF_JOB_STATUS",
	UPDATE_SELECTED_APP_INFO: "UPDATE_SELECTED_APP_INFO",
	UPDATE_SIGN_OFF_FEASIBLE_STATUS: "UPDATE_SIGN_OFF_FEASIBLE_STATUS",
	REMOVE_CERT_DATA: "REMOVE_CERT_DATA",
	UPDATE_SETUP_REVIEWERS_TOGGLE: "UPDATE_SETUP_REVIEWERS_TOGGLE",
};

export const updateTypes = {
	NOTE: "note",
	REMINDER: "reminder",
};

export const colorMapForReviewDetails = {
	approvedRecords: "#5FCF64",
	completedRecords: "#5FCF64",
	declinedRecords: "#FE6955",
	failedRecords: "#FE6955",
	downgradedRecords: "#FFDC7A",
	notRequiredRecords: "#FFDC7A",
	pendingRecords: "#DDDDDD",
	noData: "#DDDDDD",
};

export const accessReviewUrls = (isEmployee = false) => {
	const emp = isEmployee ? "/user" : "";
	return {
		LANDING_PAGE: `${emp}/accessreview#ongoing`,
		ONGOING_CERTIFICATION_PAGE: `${emp}/accessreview#ongoing`,
		UPCOMING_CERTIFICATION_PAGE: `${emp}/accessreview#upcoming`,
		COMPLETED_CERTIFICATION_PAGE: `${emp}/accessreview#completed`,
		individualOngoingReviewPage: (id) =>
			`${emp}/accessreview/${encodeURI(id)}#ongoingdetails`,
		individualUpcomingReviewPage: (id) =>
			`${emp}/accessreview/${encodeURI(id)}#upcomingdetails`,
		individualCompletedReviewPage: (id) =>
			`${emp}/accessreview/${encodeURI(id)}#completeddetails`,
		CREATE_REVIEW_PAGE: "/accessreview#create_certification",
		ONGOING_CERT_APPLICATION_PAGE: (reviewId, appId) =>
			`${emp}/accessreview/${encodeURI(reviewId)}/application/${encodeURI(
				appId
			)}`,
		CERT_APPLICATION_PAGE: (reviewId, appId) =>
			`${emp}/accessreview/${encodeURI(reviewId)}/application/${encodeURI(
				appId
			)}/overview`,
		CLONE_CERT_LOADER: "/accessreview/campaign/clone/create",
	};
};
export const tasks = {
	[certification_stage.REVIEW]: {
		stage: certification_stage.REVIEW,
		stage_img: reviewStageSvg,
		stage_text: "Review Stage",
		is_validating: true,
		show_timeline: true,
		small_text: "Are you sure you want to conclude the review stage?",
		confirmation_btn_text: CONCLUDE_REVIEW,
		show_sendreminder: true,
		enable_sendreminder: true,
		tooltip_text:
			'This option will be enabled for the certification owner once all applications are “Ready For Remediation”',
	},
	[certification_stage.ACTIONS]: {
		stage: certification_stage.ACTIONS,
		stage_img: taskStageSvg,
		stage_text: "Actions Stage",
		is_validating: true,
		show_timeline: false,
		small_text: "Are you sure you want to complete the certification?",
		tooltip_text: "This option will be enabled for the certification owner",
		confirmation_btn_text: COMPLETE_CERTIFICATION,
		show_sendreminder: false,
		enable_sendreminder: true,
	},
	[certification_stage.USER_CREATION_IN_PROGRESS]: {
		stage: certification_stage.USER_CREATION_IN_PROGRESS,
		stage_img: reviewStageSvg,
		stage_text: "Review Stage",
		is_validating: true,
		show_timeline: true,
		small_text: "Are you sure you want to conclude the review stage?",
		confirmation_btn_text: CONCLUDE_REVIEW,
		show_sendreminder: true,
		enable_sendreminder: false,
	},
	[certification_stage.CONCLUDE_REVIEW_JOB_IN_PROGRESS]: {
		stage: certification_stage.CONCLUDE_REVIEW_JOB_IN_PROGRESS,
		stage_img: taskStageSvg,
		stage_text: "Actions Stage",
		is_validating: false,
		show_timeline: false,
		small_text: "Are you sure you want to conclude the review stage?",
		confirmation_btn_text: COMPLETE_CERTIFICATION,
		show_sendreminder: true,
		enable_sendreminder: false,
		tooltip_text:
			"Remediation actions are being initiated. This action will be enabled once they’re initiated",
	},
};

export const actionsData = {
	// manual_tasks: [
	// 	{
	// 		type: "Reject",
	// 		dataPoint: "on_decline",
	// 		placeHolder: "Select action when Rejected*",
	// 	},
	// 	{
	// 		type: "Modify",
	// 		dataPoint: "on_modify",
	// 		placeHolder: "Select action when Modify*",
	// 	},
	// ],
	select_playbook: [
		{
			type: "Reject",
			placeHolder: "Choose Playbook when Revoked*",
			dataPoint: "on_decline",
		},
		{
			type: "Modify",
			placeHolder: "Choose Playbook when Modified*",
			dataPoint: "on_modify",
		},
	],
};

export const certifications = {
	ONGOING_CERTIFICATION: "Ongoing Certifications",
	UPCOMING_CERTIFICATION: "Upcoming Certifications",
	COMPLETED_CERTIFICATION: "Completed Certifications",
};

export const getOverViewCards = (
	ongoingReviewCount,
	upcomingReviewCount,
	completedReviewCount,
	isEmployee = false
) => {
	return {
		ongoingReview: {
			count: ongoingReviewCount || 0,
			message: certifications.ONGOING_CERTIFICATION,
			view: true,
			linkAddress: accessReviewUrls(isEmployee).LANDING_PAGE,
		},
		upcomingReview: {
			count: upcomingReviewCount || 0,
			message: certifications.UPCOMING_CERTIFICATION,
			view: true,
			linkAddress:
				accessReviewUrls(isEmployee).UPCOMING_CERTIFICATION_PAGE,
		},
		completedReview: {
			count: completedReviewCount || 0,
			message: certifications.COMPLETED_CERTIFICATION,
			view: true,
			linkAddress:
				accessReviewUrls(isEmployee).COMPLETED_CERTIFICATION_PAGE,
		},
	};
};

export const reviewStringMapper = {
	ongoing: "ongoingReviews",
	upcoming: "upcomingReviews",
	completed: "completedReviews",
};

export const actionTextMapper = {
	pending: "Pending",
	approved: "Approved",
	completed: "Completed",
	declined: "Revoke",
	failed: "Failed",
	modified: "Modify",
	not_required: "not_required",
	notRequired: "Not Required",
};

export const stageTextPill = {
	[certification_stage.REVIEW]: "Review Stage",
	[certification_stage.ACTIONS]: "Action Stage",
	[certification_stage.USER_CREATION_IN_PROGRESS]: "Review Stage",
};

export const quickfiltersDataForReviewActionsForEmp = [
	{
		field_id: "review_status",
		quick_filter_label: "Review Pending",
		field_name: "Review Status",
		field_values: ["pending"],
		filter_type: "string",
		field_order: "contains",
		negative: false,
		is_custom: false,
	},
	{
		field_id: "signoff_level",
		quick_filter_label: "Sign Off Pending",
		field_name: "Sign Off Complete",
		field_values: [],
		filter_type: "exact_match",
		field_order: "eq",
		negative: false,
		is_custom: false,
	},
];

export const quickfiltersDataForReviewActionsForAdmin = [
	{
		field_id: "review_status",
		quick_filter_label: "Review Pending",
		field_name: "Review Status",
		field_values: ["pending"],
		filter_type: "string",
		field_order: "contains",
		negative: false,
		is_custom: false,
	},
	{
		field_id: "reviewer_id",
		quick_filter_label: "Assigned to me",
		field_name: "Reviewer",
		field_type: "dynamic_filter",
		ui: {
			component: {
				type: "dynamicFilterRenderer",
				entity: "user",
				width: "219px",
				hideOverflow: true,
			},
		},
		field_values: [],
		filter_type: "objectId",
		field_order: "contains",
		negative: false,
		is_custom: false,
	},
	{
		field_id: "signoff_level",
		quick_filter_label: "Sign Off Pending",
		field_name: "Sign Off Complete",
		field_values: [],
		filter_type: "exact_match",
		field_order: "eq",
		negative: false,
		is_custom: false,
	},
];

export const fixedColumns = [
	"User",
	"Email ID",
	"Current Reviewer",
	"User Status",
	"User Application Status",
	"Licenses",
	"Role",
	"Job Title",
	"User Account Type",
	"Department",
];

export const fixedColumnsGroupNames = [
	"user",
	"user_email",
	"reviewer",
	"user_status",
	"user_app_status",
	"license",
	"user_app_role",
	"user_designation",
	"user_account_type",
	"dept",
];

export const toastProps = {
	success: {
		icon: successSvg,
		header: "Certification creation in progress",
		subheader: "We’ll notify you once this is completed.",
	},
	failure: {
		icon: failureSvg,
		header: "Certification creation failed",
		subheader: "Please try again after some time or contact Zluri support.",
	},
	reminderSuccess: {
		icon: successSvg,
		header: "Reminder sent",
		subheader:
			"Reminder has been sent to all reviewers who have pending reviews to complete.",
	},
	reminderFailure: {
		icon: failureSvg,
		header: "Unable to send reminder",
		subheader: "Please try again in sometime or contact Zluri support.",
	},
	reminderCoolOffPeriod: {
		icon: failureSvg,
		header: "Manual reminder sent in the last hour",
		subheader:
			"A manual reminder was sent to all reviewers in the last hour. Please try again in some time.",
	},
};

export const previewApiMapper = {
	[V2_TABLE_ENTITIES.APP_OVERVIEW_USERS]: {
		[REVIEW_ACTION]: bulkEditUsersPreviewCounts,
		[DELEGATE_REVIEW]: bulkEditReviewersPreviewCounts,
	},
	[V2_TABLE_ENTITIES.APP_OVERVIEW_USERS_EMP_VIEW]: {
		[REVIEW_ACTION]: bulkEditUsersPreviewCountsEmpView,
		[DELEGATE_REVIEW]: bulkEditReviewersPreviewCountsEmpView,
	},
};

export const toastPropsForSingleReviewer = {
	reminderSuccess: {
		icon: successSvg,
		header: "Reminder sent",
		subheader: "",
	},

	reminderCoolOffPeriod: {
		icon: failureSvg,
		header: "Manual reminder sent in the last hour",
		subheader:
			"A manual reminder was sent to the reviewer in the last hour. Please try again in some time.",
	},
};
export const defaultPropsForSelectReviewer = {
	defaultClassNames: true,
	isNameBadge: true,
	optionsContainerClassName: "dropdown_height",
	inputStyle: {
		fontSize: "11px",
		fontWeight: "400",
	},
	style: {
		padding: "1px",
		height: "28px",
	},
	search: true,
	imageOrNameBadgeImgClassName: "selected-option-img mr-1",
	imageOrNameBadgeNameClassName: "search-name-options mr-1",
	fieldNames: {
		label: "user_name",
		description: "user_email",
		logo: "user_profile",
	},
	className: "w-50",
	customRenderer: true,
};

const defaultChangeActionStyles = {
	display: "flex",
	alignItems: "center",
	padding: "4px",
	gap: "2px",
	borderRadius: "4px",
};

export const reviewActions = {
	[APPROVED]: {
		label: "Approve",
		type: "approved",
		style: {
			height: "32px",
			width: "32px",
			borderRadius: "8px 0px 0px 8px",
		},
		hoverStyle: {
			background: "#E7F8E8",
			border: "1px solid #A3E4A6",
		},
		bgColor: "#E7F8E8",
		img: squaregreen,
		changeActionStyles: {
			...defaultChangeActionStyles,
			background: "#E7F8E8",
		},
	},
	[MODIFIED]: {
		label: "Modify",
		type: "modified",
		style: {
			height: "32px",
			width: "32px",
			borderRadius: "0px",
		},
		hoverStyle: {
			background: "#FFF6DC",
			border: "1px solid #FFDC7A",
		},
		bgColor: "#FFF6DC",
		img: squareamber,
		changeActionStyles: {
			...defaultChangeActionStyles,
			background: "#FFF6DC",
		},
	},
	[DECLINED]: {
		label: "Revoke",
		type: "declined",
		style: {
			height: "32px",
			width: "32px",
			borderRadius: "0px 8px 8px 0px",
		},
		hoverStyle: {
			background: "#FFE9E5",
			border: "1px solid #FFA99D",
		},
		bgColor: "#FFE9E5",
		img: squarered,
		changeActionStyles: {
			...defaultChangeActionStyles,
			background: "#FFE9E5",
		},
	},
	[PENDING]: {
		type: "pending",
		bgColor: "#EBEBEB",
	},
};

export const reviewActionsMapper = [
	reviewActions?.approved,
	reviewActions?.modified,
	reviewActions?.declined,
];

const reviewActionsDefaultStyleForEdit = {
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	background: "1px transparent none",
	padding: "10px",
	borderRadius: "4px",
	height: "16px",
	width: "16px",
};
export const StylesForReviewActionPopover = {
	hover: {
		...reviewActionsDefaultStyleForEdit,
		border: "1px solid #717171",
		cursor: "pointer",
	},
	noHover: {
		...reviewActionsDefaultStyleForEdit,
	},
};

export const reviewActionEditOptionsText = {
	CHANGE_ACTION: "Change Action",
	ADD_COMMENT: "Add Comment",
	EDIT_COMMENT: "Edit Comment",
};

export const reviewActionOptions = [
	{ text: reviewActionEditOptionsText.CHANGE_ACTION },
	{ text: reviewActionEditOptionsText.ADD_COMMENT },
];

export const USER_ROLES = {
	owner: "Owner",
	finance_admin: "Finance Admin",
	procurement_admin: "Procurement Admin",
	integration_admin: "Integration Admin",
	reporting_manager: "Reporting Manager",
	it_admin: "IT Admin",
	app_it_owner: "App IT Owner",
	app_finance_owner: "App Finance Owner",
	dept_head: "Department Head",
	app_owner: "App Owner",
};
export const defaultLevelState = {
	primary_reviewer: {},
	fallback_reviewer: {},
};

export const entityTypes = {
	APPLICATION: "APPLICATION",
};

export const stackDataMapper = {
	approvedRecords: {
		key: "approvedRecords",
		textKey: "approved",
	},
	completedRecords: {
		key: "completedRecords",
		textKey: "completed",
	},
	declinedRecords: {
		key: "declinedRecords",
		textKey: "declined",
	},
	failedRecords: {
		key: "failedRecords",
		textKey: "failed",
	},
	downgradedRecords: {
		key: "downgradedRecords",
		textKey: "modified",
	},
	notRequiredRecords: {
		key: "notRequiredRecords",
		textKey: "notRequired",
	},
	pendingRecords: {
		key: "pendingRecords",
		textKey: "pending",
	},
};

export const entities = {
	checkIfSignOffFeasible: "checkIfSignOffFeasible",
	performSignOff: "performSignOff",
	signOffJobStatus: "signOffJobStatus",
};

export const adminViewEntities = {
	[entities?.checkIfSignOffFeasible]: isSignOffFeasible,
	[entities?.performSignOff]: performSignOff,
	[entities?.signOffJobStatus]: signOffJobStatus,
};
export const empViewEntites = {
	[entities?.checkIfSignOffFeasible]: isSignOffFeasibleEmpView,
	[entities?.performSignOff]: performSignOffEmpView,
	[entities?.signOffJobStatus]: signOffJobStatusEmpView,
};

export const signOffBtnTooltipText = (appData, isEmployee) => {
	const status = appData?.signOffJobInProgress;
	switch (status) {
		case PENDING:
			return "Your previous actions are being saved. Please check again in some time";
		case COMPLETED:
			return "All reviews are completed and signed off";
		case NOT_STARTED:
			break;
		default:
			break;
	}
	if (appData?.isSignOffFeasible) return;
	if (appData?.readyForRemediation)
		return "All reviews are completed and signed off";
	if (appData?.reviewDetails?.pendingRecords !== 0 && isEmployee)
		return "Sign-off will be enabled once all your reviews are completed";
	return "Sign-off option will be enabled only for reviewers if they have at least one review action that is yet to be signed off";
};

export const popupEntities = {
	cancelPopup: "cancelPopup",
	cancelPopupInAddAppModal: "cancelPopupInAddAppModal",
	signOffPopup: "signOffPopup",
	sendReminder: "sendReminder",
	transitionStage: "transitionStage",
	deleteApp: "deleteApp",
	archiveCertification: "archiveCertification",
	defaultReviewers: "defaultReviewers",
};

export const defaultPropsForPopups = {
	[popupEntities.cancelPopup]: {
		btnGroupProps: {
			actionBtnText: "Yes",
			cancelBtnText: "No",
			actionBtnVariant: PrimaryButtonVariant.primary_danger,
		},
		rest: {
			headerText: "Heads up!",
			subHeaderText:
				"Are you sure you want to cancel? All changes made will be lost.",
		},
	},
	[popupEntities.cancelPopupInAddAppModal]: {
		btnGroupProps: {
			actionBtnText: "Yes",
			cancelBtnText: "No",
			actionBtnVariant: PrimaryButtonVariant.primary_danger,
		},
		rest: {
			headerText: "Heads up!",
			subHeaderText:
				"Are you sure you want to cancel? All changes made will be lost.",
		},
	},
	[popupEntities.deleteApp]: {
		btnGroupProps: {
			actionBtnText: "Yes",
			cancelBtnText: "No",
			actionBtnVariant: PrimaryButtonVariant.primary_danger,
		},
		rest: {
			headerText: "Heads up!",
		},
	},
	[popupEntities.sendReminder]: {
		btnGroupProps: {
			actionBtnText: SEND_REMINDER,
			cancelBtnText: BUTTONS.CANCEL,
			actionBtnVariant: PrimaryButtonVariant.primary_default,
			cancelBtnVariant: SecondaryButtonVariant.secondary_default,
			actionBtnTooltipText:
				"Reviewers with pending reviews will not be notified if a reminder was sent in the last hour",
		},
		rest: {
			headerText: "Do you want to continue?",
			headerLogo: warningYellow,
			bodyRenderer: (
				<div className="confirmation_popup_text">
					<Typography
						variant={BODY_VARIANT.BODY_2_REGULAR}
						color={GREY_VARIANT.SECONDARY_GREY_1}
					>
						<div className="d-flex gap-12 flex-column">
							<div>
								Are you sure you want to send a reminder to all
								reviewers with pending reviews and sign offs?
							</div>
							<div>
								Note: Reviewers who received a reminder in the
								last one hour will not be sent another reminder.
							</div>
						</div>
					</Typography>
				</div>
			),
		},
	},
	[popupEntities.transitionStage]: {
		btnGroupProps: {
			actionBtnVariant: PrimaryButtonVariant.primary_default,
			cancelBtnText: BUTTONS.CANCEL,
			cancelBtnVariant: SecondaryButtonVariant.secondary_default,
		},
		rest: {
			headerLogo: warningRed,
		},
	},
	[popupEntities.signOffPopup]: {
		btnGroupProps: {
			actionBtnText: "Confirm",
		},
		rest: {
			headerLogo: warningYellow,
		},
	},
	[popupEntities.archiveCertification]: {
		btnGroupProps: {
			actionBtnText: "Archive",
			cancelBtnText: "Cancel",
			actionBtnVariant: PrimaryButtonVariant.primary_danger,
		},
		rest: {
			headerText: "Heads up!",
			subHeaderText:
				"Are you sure you want to archive this certification? You’ll no longer be able to access this on Zluri.",
		},
	},
	[popupEntities.defaultReviewers]: {
		btnGroupProps: {
			actionBtnText: "Proceed",
			cancelBtnText: "Cancel",
			actionBtnVariant: PrimaryButtonVariant.primary_danger,
		},
		rest: {
			headerText: "Heads up!",
			subHeaderText:
				"This action will remove any default reviewers setup for this certification. Are you sure you want to proceed?",
		},
	},
};

export const menuOptions = {
	CSV_EXPORT: "csv_report",
	CSV_AS_EXCEL: "excel_report",
	PDF_REPORT: "pdf_report",
	CLONE_CAMPAIGN: "clone_campaign",
	ARCHIVE_CAMPAIGN: "archive_campaign",
};

export const archiveCertGroups = {
	ongoing: reviewStringMapper.ongoing,
	upcoming: reviewStringMapper.upcoming,
	completed: reviewStringMapper.completed,
};

export const certOverviewScreens = [
	HASH_COMPLETED_DETAILS,
	HASH_ONGOING_DETAILS,
	HASH_UPCOMING_DETAILS,
];

export const menuOptionsOn = {
	ongoingList: [
		menuOptions.CSV_EXPORT,
		menuOptions.CSV_AS_EXCEL,
		menuOptions.CLONE_CAMPAIGN,
		menuOptions.ARCHIVE_CAMPAIGN,
	],
	ongoingDetails: [
		menuOptions.CSV_EXPORT,
		menuOptions.CSV_AS_EXCEL,
		menuOptions.CLONE_CAMPAIGN,
		menuOptions.ARCHIVE_CAMPAIGN,
	],
	upcomingList: [menuOptions.CLONE_CAMPAIGN, menuOptions.ARCHIVE_CAMPAIGN],
	upcomingDetails: [menuOptions.CLONE_CAMPAIGN, menuOptions.ARCHIVE_CAMPAIGN],
	completedList: [
		menuOptions.CSV_EXPORT,
		menuOptions.CSV_AS_EXCEL,
		menuOptions.PDF_REPORT,
		menuOptions.CLONE_CAMPAIGN,
		menuOptions.ARCHIVE_CAMPAIGN,
	],
	completedDetails: [
		menuOptions.CSV_EXPORT,
		menuOptions.CSV_AS_EXCEL,
		menuOptions.PDF_REPORT,
		menuOptions.CLONE_CAMPAIGN,
		menuOptions.ARCHIVE_CAMPAIGN,
	],
};

export const csvFileFormat = {
	GENERAL: "general",
	EXCEL: "excel",
};

export const reviewersPopoverType = {
	DEFAULT: "DEFAULT",
	HIGHLIGHTED_CURRENT_LEVEL: "HIGHLIGHTED_CURRENT_LEVEL",
};

export const csvExportJobName = {
	ONGOING_CERT_EXPORT: "ongoing_campaigns_data",
	COMPLETED_CERT_EXPORT: "completed_campaigns_data",
};
