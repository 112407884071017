import React from "react";
import PropTypes from "prop-types";
import {
	ALERT_TAG_VARIANT,
	BLUE_VARIANT,
	BODY_VARIANT,
	GREY_VARIANT,
	Popover,
	Tooltip,
	Typography,
} from "@zluri/ui-components";

import { isNullOrUndefined } from "modules/applications/utils/appAbout.utils";

import arrowRightIcon from "assets/applications/about/Icon_Arrow_Right.svg";

export function AppAboutCategories({ subCategories, ...rest }) {
	if (isNullOrUndefined(subCategories)) return null;

	const {
		category_name: firstCategoryName,
		sub_category_name: firstSubCategoryName,
	} = subCategories[0];

	return (
		<div className="d-flex gap-4 align-items-center">
			<div className="d-flex gap-4 align-items-center">
				<Tooltip
					content={
						!isNullOrUndefined(firstCategoryName) && (
							<Typography
								color="white"
								variant={
									ALERT_TAG_VARIANT?.ALERT_TAG_SMALL_MEDIUM
								}
							>
								{firstCategoryName}
							</Typography>
						)
					}
					placement="bottom"
				>
					<Typography
						color={GREY_VARIANT.SECONDARY_GREY_1}
						variant={BODY_VARIANT.BODY_2_REGULAR}
						className="text-truncate-small light-blue-bg app-about-category-tag"
						style={{
							maxWidth: "120px",
						}}
					>
						{!isNullOrUndefined(firstCategoryName)
							? firstCategoryName
							: "-"}
					</Typography>
				</Tooltip>
				<img src={arrowRightIcon} alt="Arrow Right" />
				<Tooltip
					content={
						!isNullOrUndefined(firstSubCategoryName) && (
							<Typography
								color="white"
								variant={
									ALERT_TAG_VARIANT.ALERT_TAG_SMALL_MEDIUM
								}
							>
								{firstSubCategoryName}
							</Typography>
						)
					}
					placement="bottom"
				>
					<Typography
						color={GREY_VARIANT.SECONDARY_GREY_1}
						variant={BODY_VARIANT.BODY_2_REGULAR}
						className="text-truncate-small"
						style={{
							maxWidth: "120px",
						}}
					>
						{!isNullOrUndefined(firstSubCategoryName)
							? firstSubCategoryName
							: "-"}
					</Typography>
				</Tooltip>
			</div>
			{subCategories?.length > 1 && (
				<Popover
					arrow
					content={rest?.clickPopoverContent}
					placement="bottomRight"
					trigger={["click"]}
				>
					<Typography
						color={BLUE_VARIANT.PRIMARY_BLUE_DARK_1}
						variant={BODY_VARIANT.BODY_2_MEDIUM}
						className="cursor-pointer px-1"
						style={{ lineHeight: 1 }}
					>
						+{subCategories.length - 1}
					</Typography>
				</Popover>
			)}
		</div>
	);
}

AppAboutCategories.propTypes = {
	categories: PropTypes.array,
	subCategories: PropTypes.array,
};
