import React, { useEffect, useState } from "react";
import {
	Actions,
	BUTTONS,
	STATUS,
	StatesForAddApplicationModal,
	V2_TABLE_ENTITIES,
	ViewType,
	popupEntities,
} from "modules/AccessReview/constants/constants";
import whiteTick from "assets/icons/white-tick.svg";
import { useDispatch, useSelector } from "react-redux";
import {
	addApplicationToReview,
	changeView,
	clearModalData,
	isEditingApp,
	toggleAddApplicationModal,
} from "modules/AccessReview/redux/AccessReview.actions";
import { Drawer, Typography, HEADING_VARIANT } from "@zluri/ui-components";
import ControlFlowButtonGroup from "../ControlFlowButtonGroup/ControlFlowButtonGroup";
import "modules/AccessReview/components/ReviewDetailsForm/ReviewDetailsForm.css";
import "modules/AccessReview/components/AddApplicationModal/AddApplicationModal.css";
import { Popup } from "../common/common";

export default function AddApplicationModal() {
	const dispatch = useDispatch();
	const {
		progressBarInModal,
		ViewsInAddApplicationModal,
		applicationReview,
		isEdit,
	} = useSelector((state) => state.accessReview);
	const selectUsersEntity = V2_TABLE_ENTITIES.SELECT_USERS;
	const selectColumnsEntity = V2_TABLE_ENTITIES.SELECT_COLUMNS;

	const userData = useSelector((state) => state.v2Table?.[selectUsersEntity]);
	const [nextBtndisabled, setNextBtnDisabled] = useState(true);
	const [showCancelPopup, setShowCancelPopup] = useState(false);

	const handleNextClick = () => {
		if (
			ViewsInAddApplicationModal.name ===
			StatesForAddApplicationModal.ConfigureReviewActions.name
		) {
			dispatch(isEditingApp(false));
			dispatch(
				addApplicationToReview(
					{
						...applicationReview,
						filter_by: userData?.filter_by,
					},
					isEdit
				)
			);
		}
		dispatch(
			changeView(
				ViewsInAddApplicationModal,
				Actions.NEXT,
				ViewType.ViewsInAddApplicationModal
			)
		);
	};

	const handlePrevClick = () => {
		dispatch(
			changeView(
				ViewsInAddApplicationModal,
				Actions.PREV,
				ViewType.ViewsInAddApplicationModal
			)
		);
	};

	useEffect(() => {
		dispatch({
			type: "CLEAR_INTERNAL_DATA",
			payload: { entity: selectUsersEntity },
		});
		dispatch({
			type: "CLEAR_INTERNAL_DATA",
			payload: { entity: selectColumnsEntity },
		});
	}, []);

	return (
		<Drawer
			rootClassName="add__application__modal "
			className="add-application-modal-container"
			placement="right"
			onClose={() => {
				dispatch(toggleAddApplicationModal(false));
				dispatch(clearModalData());
			}}
			width="80%"
			open={true}
			header={
				<AddApplicationModalHeader
					progressBarInModal={progressBarInModal}
				/>
			}
			customFooter={
				<div className="white-bg d-flex justify-content-start">
					<div
						className="review-form-btn-container"
						style={{ padding: "0px", margin: "0px" }}
					>
						<ControlFlowButtonGroup
							iconBtnDisabled={
								ViewsInAddApplicationModal?.name ===
								StatesForAddApplicationModal
									?.SelectApplicationInModal?.name
							}
							onBackClick={handlePrevClick}
							onCancelClick={() => setShowCancelPopup(true)}
							actionBtnDisabled={nextBtndisabled}
							onActionClick={() => handleNextClick()}
							actionBtnText={
								ViewsInAddApplicationModal?.name ===
								StatesForAddApplicationModal
									?.ConfigureReviewActions?.name
									? isEdit
										? BUTTONS.SAVE_APPLICATION
										: BUTTONS.ADD_APPLICATION
									: BUTTONS.NEXT
							}
						/>
					</div>
				</div>
			}
		>
			<ViewsInAddApplicationModal.Comp
				setNextBtnDisabled={setNextBtnDisabled}
			/>
			<Popup
				setShow={setShowCancelPopup}
				show={showCancelPopup}
				entity={popupEntities.cancelPopupInAddAppModal}
			/>
		</Drawer>
	);
}

const AddApplicationModalHeader = ({ progressBarInModal }) => {
	return (
		<div className="white-bg d-flex justify-content-between">
			<div className="w-20 text-center d-flex justify-content-start">
				<Typography
					style={{ fontWeight: 700 }}
					variant={HEADING_VARIANT.HEADING_2_MEDIUM}
				>
					Add Application
				</Typography>
			</div>
			<div className="w-80 text-center d-flex justify-content-end">
				{renderSteps(progressBarInModal)}
			</div>
		</div>
	);
};

const renderSteps = (progressBarInModal) => {
	return (
		<div className="d-flex steps-header-container d-flex justify-content-end">
			{progressBarInModal.map((step, index) => {
				const inProgressOrCompleted =
					step.STATUS === STATUS.InProgress ||
					step.STATUS === STATUS.Completed;
				const stepTextClass = inProgressOrCompleted
					? "primary-color"
					: "grey";
				const stepNumClass = inProgressOrCompleted
					? "step-num-active"
					: "step-num-initial";
				return (
					<div
						key={step.STEP}
						className="d-flex justify-content-center align-items-center family-sora position-relative step-container"
					>
						<div
							className={`bold-600 font-10 ${stepNumClass} step-num-base`}
						>
							{step.STATUS === STATUS.Completed ? (
								<img src={whiteTick} alt="" />
							) : (
								step.STEP_NUM
							)}
						</div>
						<div
							className={`font-12 bold-500 ${stepTextClass} ml-2`}
						>
							{step.STEP}
						</div>
						{progressBarInModal.length - 1 !== index && (
							<div className="line-seperator position-absolute"></div>
						)}
					</div>
				);
			})}
		</div>
	);
};
