import React from "react";
import "./emptycell.css";
import { CustomCellComponent } from "./componentFactory";

export default function EmptyCellRenderer({
	component,
	handleOnClick,
	...props
}) {
	return (
		<div>
			<div
				style={component.onHoverTransition ? {} : {}}
				className={`${
					component.onHoverTransition ? "empty_cell_container" : " "
				} testing-class`}
			>
				<CustomCellComponent
					{...props}
					component={component}
					type={component.type}
				/>
			</div>
		</div>
	);
}
