import { AppLicensesConstants } from "./AppLicenses.constant";

const initialState = {
	appLicenses: {
		appLicensesSummaryMetrics: {},
		appLicensesSummary: {},
		licensesMappedStatus: {},
		licensesMappedUserStatus: {},
		licensesTypeSummary: [],
		licensesSummaryGraph: [],
		licensesCostGraph: {},
		licensesSyncGraph: {},
		bulkEditLicense: [],
	},
};

export function AppLicensesReducer(state = initialState, action) {
	var newState = { ...state };
	switch (action.type) {
		case AppLicensesConstants.SET_BULK_EDIT_LICENSE:
			newState.appLicenses.bulkEditLicense = action?.payload || [];
			return newState;
		case AppLicensesConstants.FETCH_LICENSES_SUMMARY_METRICS:
			newState.appLicenses.appLicensesSummaryMetrics = {
				loading: true,
				loaded: false,
				data: [],
			};
			return newState;
		case AppLicensesConstants.FETCHED_LICENSES_SUMMARY_METRICS:
			newState.appLicenses.appLicensesSummaryMetrics = {
				loading: false,
				loaded: true,
				data: action?.payload?.data,
				error: action?.payload?.error,
			};
			return newState;
		case AppLicensesConstants.FETCH_MAP_LICENSES_SUMMARY:
			newState.appLicenses.appLicensesSummary = {
				loading: true,
				loaded: false,
				data: [],
			};
			return newState;
		case AppLicensesConstants.FETCHED_MAP_LICENSES_SUMMARY:
			newState.appLicenses.appLicensesSummary = {
				loading: false,
				loaded: true,
				data: action?.payload?.data,
				error: action?.payload?.error,
			};
			return newState;
		case AppLicensesConstants.FETCH_LICENSES_MAPPING_STATUS:
			newState.appLicenses.licensesMappedStatus = {
				loading: true,
				loaded: false,
				data: {},
			};
			return newState;
		case AppLicensesConstants.FETCHED_LICENSES_MAPPING_STATUS:
			newState.appLicenses.licensesMappedStatus = {
				loading: false,
				loaded: true,
				data: action?.payload?.data,
				error: action?.payload?.error,
			};
			return newState;
		case AppLicensesConstants.FETCH_LICENSES_TYPE_SUMMARY:
			newState.appLicenses.licensesTypeSummary = {
				loading: true,
				loaded: false,
				data: [],
			};
			return newState;
		case AppLicensesConstants.FETCHED_LICENSES_TYPE_SUMMARY:
			newState.appLicenses.licensesTypeSummary = {
				loading: false,
				loaded: true,
				data: action?.payload?.data || [],
				error: action?.payload?.error,
			};
			return newState;
		case AppLicensesConstants.FETCH_LICENSES_MAPPING_USER_STATUS:
			newState.appLicenses.licensesMappedUserStatus = {
				loading: true,
				loaded: false,
				data: {},
			};
			return newState;
		case AppLicensesConstants.FETCHED_LICENSES_MAPPING_USER_STATUS:
			newState.appLicenses.licensesMappedUserStatus = {
				loading: false,
				loaded: true,
				data: action?.payload?.data,
				error: action?.payload?.error,
			};
			return newState;
		case AppLicensesConstants.FETCH_LICENSES_BY_DEPARTMENT:
			newState.appLicenses.licensesSummaryGraph = {
				loading: true,
				loaded: false,
				data: [],
			};
			return newState;
		case AppLicensesConstants.FETCHED_LICENSES_BY_DEPARTMENT:
			newState.appLicenses.licensesSummaryGraph = {
				loading: false,
				loaded: true,
				data: action?.payload?.data || [],
				error: action?.payload?.error,
			};
			return newState;
		case AppLicensesConstants.FETCH_LICENSES_AND_CONTRACTS_GRAPH:
			newState.appLicenses.licensesCostGraph = {
				loading: true,
				loaded: false,
				data: {},
			};
			return newState;
		case AppLicensesConstants.FETCHED_LICENSES_AND_CONTRACTS_GRAPH:
			newState.appLicenses.licensesCostGraph = {
				loading: false,
				loaded: true,
				data: action?.payload?.data,
				error: action?.payload?.error,
			};
			return newState;

		default:
			return state;
	}
}
