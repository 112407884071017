import { Typography } from "@zluri/ui-components";
import styled from "styled-components";
import React from "react";
import MapIcon from "modules/transactionMapping/assets/actions/map-to-application.svg";
import UnrecognizedIcon from "modules/transactionMapping/assets/actions/mark-as-unrecognized.svg";
import ArchiveIcon from "modules/transactionMapping/assets/actions/mark-as-archive.svg";

const OptionWrapper = styled.div`
	display: flex;
	padding: 10px;
	gap: 10px;
	align-items: center;
`;

export const transactionActions = {
	TRANSACTION_MAPPING: "transaction_mapping",
	TRANSACTION_UNMAPPING: "transaction_unmapping",
	TRANSACTION_ARCHIVING: "transaction_archiving",
};

export const actionsOptions = [
	{
		value: transactionActions.TRANSACTION_MAPPING,
		label: (
			<OptionWrapper>
				<img src={MapIcon} alt="map" />
				<Typography variant="alerttag_regular_medium">
					Map to application
				</Typography>
			</OptionWrapper>
		),
	},
	{
		value: transactionActions.TRANSACTION_UNMAPPING,
		label: (
			<OptionWrapper>
				<img src={UnrecognizedIcon} alt="unrecgnized" />
				<Typography variant="alerttag_regular_medium">
					Mark as Unrecognised
				</Typography>
			</OptionWrapper>
		),
	},
	{
		value: transactionActions.TRANSACTION_ARCHIVING,
		label: (
			<OptionWrapper>
				<img src={ArchiveIcon} alt="archive" />
				<Typography variant="alerttag_regular_medium">
					Mark as Archived
				</Typography>
			</OptionWrapper>
		),
	},
];
