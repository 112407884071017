import { getSegmentFromURL } from "@zluri/ui-components";
import { client, clientV3 } from "utils/client";

export async function getMapLicensesSummary(appId) {
	const response = await clientV3.get(
		`applications/${appId}/licenses/licenses-summary`
	);
	return response;
}
export async function getLicensesMappedStatus(appId) {
	const response = await clientV3.get(
		`applications/${appId}/licenses/mapping-stats`
	);
	return response;
}
export async function getLicensesMappedUserStatus(appId) {
	const response = await clientV3.get(
		`applications/${appId}/licenses/user-stats`
	);
	return response;
}

export async function getLicensesTypeSummary(appId, isPaid) {
	const response = await clientV3.get(
		`applications/${appId}/licenses/license-types-summary`,
		{
			params: {
				...(isPaid !== undefined && { is_paid: isPaid }),
			},
		}
	);
	return response;
}

export async function getLicensesByDepartment(appId, license_name) {
	const response = await clientV3.get(
		`applications/${appId}/licenses/graphs/licenses-by-departments?license_name=${license_name}`
	);
	return response;
}

export async function postLicensesByDepartment(appId, reqBody) {
	const response = await clientV3.post(
		`applications/${appId}/licenses/graphs/licenses-by-departments`,
		reqBody
	);
	return response;
}

export async function getLicensesGraph(appId) {
	const response = await clientV3.get(
		`applications/${appId}/licenses/graphs`
	);
	return response;
}
export async function getLicensesSummaryMetrics(appId) {
	const response = await clientV3.get(
		`applications/${appId}/licenses/summary-metrics`
	);
	return response;
}
export async function getLicensesAndContractsGraph(
	appId,
	start_month,
	start_year,
	end_month,
	end_year
) {
	const response = await clientV3.get(
		`applications/${appId}/licenses/graphs?startMonth=${start_month}&startYear=${start_year}&endMonth=${end_month}&endYear=${end_year}`
	);

	return response;
}

export const getBulkLicenses = async (appId, reqBody) => {
	const response = await clientV3.post(
		`applications/${appId}/licenses/bulk-map-licenses`,
		reqBody
	);
	return response?.data;
};

export const getBulkMapLicenses = async (reqBody) => {
	const applicationId = getSegmentFromURL(2);
	const response = await clientV3.post(
		`applications/${applicationId}/licenses/bulk-map-licenses`,
		reqBody
	);
	return response?.data;
};
