import React from "react";
import PropTypes from "prop-types";
import "./AppBasedUsage.css";
import { APP_BASED_USAGE_OPTIONS } from "modules/workflow/constants/constant";

const AppBasedUsage = ({ appBasedUsageFlag, handleAppBasedUsageChange }) => {
	const optionRenderer = APP_BASED_USAGE_OPTIONS.map((option, index) => (
		<React.Fragment key={index}>
			<label className="bold-400 mb-1 font-11 flex-fill d-flex align-items-center pt-2">
				<input
					className="p-2 mr-2"
					checked={
						Boolean(appBasedUsageFlag) === option.value
							? true
							: false
					}
					value={option.value}
					type="radio"
					name="run_offboarding_workflow_for_all_apps"
					id={option.label}
					onClick={() => handleAppBasedUsageChange(option.value)}
				/>
				{option.label}
			</label>
		</React.Fragment>
	));

	return (
		<div className="d-flex flex-1 app_based_usage_wf_playbook p-2">
			<span className="bold-500 font-12">
				Run actions of selected playbook on :
			</span>
			<span className="d-flex">{optionRenderer}</span>
		</div>
	);
};

export default AppBasedUsage;

AppBasedUsage.propTypes = {
	appBasedUsageFlag: PropTypes.bool,
	handleAppBasedUsageChange: PropTypes.func,
};

AppBasedUsage.defaultProps = {
	appBasedUsageFlag: false,
	handleAppBasedUsageChange: () => {},
};
