import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { BUTTON_VARIANT, GREY_VARIANT, Typography } from "@zluri/ui-components";
import { isEmpty } from "underscore";

import closeIcon from "assets/v2tables/pageHeader/close.svg";
import searchIcon from "assets/icons/blue-search.svg";
import checkBlueIcon from "assets/check-filled.svg";

export function AppAboutSelectSearchDropdown({
	initialOptions,
	handleEdit,
	checkOptionIsSelected,
}) {
	const [options, setOptions] = useState(initialOptions);
	const [inputText, setInputText] = useState("");

	const inputRef = useRef();
	useEffect(() => {
		inputRef.current?.focus();
	}, []);

	const handleInputChange = (e) => {
		const searchText = e.target.value;
		setInputText(searchText);

		if (isEmpty(searchText)) setOptions(initialOptions);
		else
			setOptions(
				initialOptions.filter((option) =>
					option.value
						.toLowerCase()
						.includes(searchText.trim().toLowerCase())
				)
			);
	};

	const handleClearClick = () => {
		setInputText("");
		setOptions(initialOptions);
	};

	return (
		<div className="d-flex gap-2 app-about-popover-select-container padding_2">
			<div
				className="app-about-text-edit-container"
				style={{ height: 28 }}
			>
				<img src={searchIcon} alt="close" height={12} width={12} />
				<input
					ref={inputRef}
					type="text"
					value={inputText}
					onChange={handleInputChange}
					placeholder="Search"
					className="app-about-text-search"
				/>
				<img
					onClick={handleClearClick}
					src={closeIcon}
					alt="close"
					className="cursor-pointer"
					height={12}
					width={12}
				/>
			</div>
			{Array.isArray(options) &&
				options?.map((option) => {
					return (
						<div
							key={option?.label}
							className={`d-flex align-center justify-between app-about-select-container ${
								checkOptionIsSelected(option) && "blue_tint_bg"
							}`}
							onClick={() => handleEdit(option?.value)}
						>
							<div className="d-flex align-center gap-4 app-about-select-container-left">
								<Typography
									color={GREY_VARIANT.SECONDARY_GREY_2}
									variant={
										BUTTON_VARIANT.BUTTON_EXTRA_SMALL_MEDIUM
									}
								>
									{option?.label}
								</Typography>
							</div>
							<div className="app-about-select-container-right">
								{checkOptionIsSelected(option) && (
									<img
										src={checkBlueIcon}
										alt="check"
										width={14}
										height={12}
									/>
								)}
							</div>
						</div>
					);
				})}
			{options?.length < 1 && (
				<div
					className="d-flex justify-content-center align-items-center"
					style={{ height: 30 }}
				>
					<Typography
						color={GREY_VARIANT.SECONDARY_GREY_4}
						variant={BUTTON_VARIANT.BUTTON_EXTRA_SMALL_MEDIUM}
						className="font-italic"
						style={{ fontSize: 12 }}
					>
						No Results Found!
					</Typography>
				</div>
			)}
		</div>
	);
}

AppAboutSelectSearchDropdown.propTypes = {
	initialOptions: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
		})
	),
	handleEdit: PropTypes.func,
	checkOptionIsSelected: PropTypes.func,
};
