import React, { useState, useId, useEffect } from "react";
import {
	Button,
	Typography,
	theme,
	Field,
	Divider,
	// TOAST_COLOR,
	toast,
} from "@zluri/ui-components";
import { TriggerIssue } from "utils/sentry";

import viewTitleIcon from "assets/icons/Icon_Columns.svg";
import viewDescriptionIcon from "assets/icons/Icon_Description.svg";
import { useTableContext } from "containers/v2table/TableContext/context";
import {
	saveCustomViewService,
	updateCustomViewService,
	saveAsDefaultView,
} from "services/api/viewsv2";
import { useScreenTag } from "./hooks";

// type VisibilityType = "myself" | "organization";
// interface ICustomViewData {
// 	name: string;
// 	description: string;
// 	viewVisibility: VisibilityType;
// }

interface ISaveAsNewViewForm {
	onCancel: () => void;
	refetch: () => void;
	defaultValue?: {
		viewId: string;
		appId: string;
		title: string;
		description: string;
		isStandardView: boolean;
		isOrganizationDefault: boolean;
		isGlobalView: boolean;
		isMyDefault: boolean;
	};
	onSave: (view: any) => void;
}
function SaveAsNewViewForm({
	onCancel,
	refetch,
	defaultValue,
	onSave,
}: ISaveAsNewViewForm) {
	const { filterBy, columns, sortBy, dataEntity, isInfinite } =
		useTableContext();

	const [isGlobalView, setIsGlobalView] = useState(
		(defaultValue?.isOrganizationDefault ||
			defaultValue?.isGlobalView ||
			defaultValue?.isStandardView) ??
			false
	);
	const [isOrganizationDefault, setIsOrganizationDefault] = useState(
		defaultValue?.isOrganizationDefault ?? false
	);
	const [isMyDefault, setIsMyDefault] = useState(
		defaultValue?.isMyDefault ?? false
	);

	const hasDefaultValue = !!defaultValue;
	const isDefaultValueFromStandardView = defaultValue?.isStandardView;

	const [name, setName] = useState(defaultValue?.title ?? "");
	const [description, setDescription] = useState(
		defaultValue?.description ?? ""
	);

	useEffect(() => {
		if (defaultValue) {
			setName(defaultValue.title);
			setDescription(defaultValue.description);
			setIsGlobalView(
				(defaultValue.isOrganizationDefault ||
					defaultValue.isGlobalView) ??
					false
			);
			setIsOrganizationDefault(defaultValue.isOrganizationDefault);
			setIsMyDefault(defaultValue.isMyDefault);
		}
	}, [defaultValue]);

	const id = useId();

	const { screenTag, appId } = useScreenTag(dataEntity);
	const [isSavingCustomView, setIsSavingCustomView] = useState(false);

	async function onSaveCustomView({
		name,
		description,
		isGlobalView,
		isOrganizationDefault,
		isMyDefault,
	}) {
		setIsSavingCustomView(true);
		try {
			const _columns = columns?.map((c) => c.col_group_name);
			const filtersObj = {
				...(filterBy && { filter_by: filterBy }),
				...(sortBy && {
					sort_by: sortBy?.map((s) => ({ [s.name]: s.dir })),
				}),
				...(columns && { v2_columns: _columns }),
			};

			const reqBody = {
				name: name,
				description: description,
				screen_tag: screenTag,
				...filtersObj,
				is_org_default: isOrganizationDefault,
				is_global: isGlobalView,
				is_default: isMyDefault,
				layout_option: isInfinite ? "infinite_scroll" : "paginated",
			};

			if (defaultValue?.isStandardView) {
				await saveAsDefaultView(defaultValue?.viewId, screenTag, appId);
				toast(`"${name}" changes saved.`);
			} else if (!!defaultValue) {
				await updateCustomViewService(defaultValue?.viewId, reqBody);
				toast(`"${name}" changes saved.`);
			} else {
				const res = await saveCustomViewService(reqBody);
				onSave(res.data);
				toast(`"${name}" view is saved.`);
			}
			await refetch();
			onCancel();
		} catch {
			TriggerIssue("Error in saving custom view");
		}
		setIsSavingCustomView(false);
	}

	const showOrganizationVisibilitySelector = !(
		defaultValue?.isStandardView ?? false
	);

	// if it is already saved as visibile to organization or is already saved as default to organization
	// this checkbox should be disabled.
	const disableOrganizationVisibilitySelector =
		defaultValue?.isOrganizationDefault || defaultValue?.isGlobalView;
	const disableOrganizationDefaultSelector =
		defaultValue?.isOrganizationDefault;
	return (
		<div>
			<div style={{ padding: "6px 6px 4px 6px" }}>
				<div className="flex flex-column gap-6">
					<div>
						<Field
							handlers={{
								onChange: (e) => {
									setName(e.target.value);
								},
							}}
							value={name}
							prefixIcon={viewTitleIcon}
							type="text"
							fieldType="input"
							placeholder="Custom View Name *"
							className="font-11 bold-400 grey-1"
							disabled={
								isDefaultValueFromStandardView &&
								hasDefaultValue
							}
						/>
					</div>
					<div style={{ position: "relative" }}>
						{/*There seems to be some issue with prefix icon in field component for text area so doing this quick fix for now*/}
						<img
							src={viewDescriptionIcon}
							className="mr-1 mt-2 header-search-icon-hover"
							style={{ position: "absolute", left: 8 }}
							alt="description icon"
						/>
						<Field
							handlers={{
								onChange: (e) => {
									setDescription(e.target.value);
								},
							}}
							value={description}
							prefixIcon={viewDescriptionIcon}
							type="text"
							fieldType="textarea"
							placeholder="View Description *"
							style={{ paddingLeft: 8 }}
							className="font-11 bold-400 grey-1 z__view-selector-text-area"
							disabled={
								isDefaultValueFromStandardView &&
								hasDefaultValue
							}
						/>
					</div>
				</div>

				<Checkbox
					label="Set as default for myself"
					isChecked={isMyDefault}
					name="isMyDefault"
					id={`${id}-isMyDefault`}
					onChange={() => setIsMyDefault((_b) => !_b)}
				/>
				{showOrganizationVisibilitySelector && (
					<Divider
						height={1}
						style={{ backgroundColor: "#EBEBEB" }}
					/>
				)}

				<div className={"flex  flex-column"}>
					{showOrganizationVisibilitySelector && (
						<Checkbox
							label="Share View with Organization"
							isChecked={isGlobalView}
							name="isGlobalView"
							id={`${id}-isGlobalView`}
							disabled={disableOrganizationVisibilitySelector}
							onChange={() => {
								const _isGlobal = isGlobalView;
								if (_isGlobal) {
									setIsGlobalView(false);
									setIsOrganizationDefault(false);
								} else {
									setIsGlobalView(true);
								}
							}}
						/>
					)}
					<Checkbox
						label="Set as default for Organization"
						isChecked={isOrganizationDefault}
						name="isOrganiztionDefault"
						id={`${id}-isOrganizationDefault`}
						disabled={
							!isGlobalView || disableOrganizationDefaultSelector
						}
						onChange={() => setIsOrganizationDefault((_b) => !_b)}
					/>
				</div>
			</div>
			<Divider height={1} style={{ backgroundColor: "#EBEBEB" }} />
			<div
				className="flex"
				style={{ padding: "6px 8px 8px 8px", gap: 8 }}
			>
				<Button
					theme={theme}
					variant="primary-alternative"
					type="reset"
					onClick={() => {
						onCancel();
					}}
				>
					Cancel
				</Button>
				<Button
					theme={theme}
					variant="primary-default"
					loading={isSavingCustomView}
					disabled={!description || !name}
					onClick={() => {
						onSaveCustomView({
							name,
							description,
							isGlobalView,
							isOrganizationDefault,
							isMyDefault,
						});
					}}
				>
					Save
				</Button>
			</div>
		</div>
	);
}
export default SaveAsNewViewForm;

interface ICheckbox {
	isChecked: boolean;
	id: string;
	disabled?: boolean;
	label: string;
	onChange: () => void;
	name: string;
}
function Checkbox({
	isChecked,
	id,
	disabled = false,
	label,
	onChange,
}: ICheckbox) {
	return (
		<div
			className="d-flex gap-8 align-items-center"
			style={{ padding: 10, height: 32 }}
		>
			<div className="flex align-items-center ">
				<input
					type="checkbox"
					id={`${id}-organization`}
					name="visibility"
					checked={isChecked}
					onChange={onChange}
					disabled={disabled}
				/>
			</div>
			<label htmlFor={`${id}-organization`} className="m-0 flex-1">
				<Typography
					variant={
						isChecked
							? "button_extrasmall_semibold"
							: "button_extrasmall_regular"
					}
					color={disabled ? "secondary_grey_3" : "secondary_grey_1"}
				>
					{label}
				</Typography>
			</label>
		</div>
	);
}
