import React, { useCallback, useEffect, useState } from "react";
import { TriggerIssue } from "utils/sentry";
import { debounce } from "underscore";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Icon from "containers/v2table/AppliedFilters/Views/Icons";
import { Typography, useGetSegmentFromURL } from "@zluri/ui-components";
import { getViewsv2 } from "services/api/viewsv2";

export const useViewsAPI = (screenTag) => {
	// This state will contain data from API
	// This state will contain data for rendering, could be filtered, could be sorted whatever
	const [data, setData] = useState(null);
	const [status, setStatus] = useState("INITIAL");

	const fetchData = useCallback(async () => {
		if (!screenTag) return;
		setStatus("LOADING");

		try {
			const viewsList = await getViewsv2(screenTag);
			setStatus("SUCCESS");
			setData(viewsList);
			return new Promise((resolve) => resolve(viewsList));
		} catch (err) {
			setStatus("ERROR");
			TriggerIssue("Error in fetching views", err);
		}
	}, [screenTag]);

	const isLoading = status === "LOADING";
	return {
		status,
		fetchData,
		data,
		isLoading,
	};
};

export const useViewsData = ({ data, openViewPopover }) => {
	const [originalData, setOriginalData] = useState({
		custom: [],
		standard: [],
	});

	// This state will contain data for rendering, could be filtered, could be sorted whatever
	const [displayData, setDisplayData] = useState({
		custom: [],
		standard: [],
	});

	useEffect(() => {
		if (!data) return;
		const { custom, standard } = data?.reduce(
			(obj, curr) => {
				if (curr.is_custom) {
					return { ...obj, custom: [...obj.custom, curr] };
				}

				return { ...obj, standard: [...obj.standard, curr] };
			},
			{ custom: [], standard: [] }
		);

		setDisplayData({ custom, standard });
		setOriginalData({ custom, standard });
	}, [data, openViewPopover]);

	const defaultView =
		data?.find((v) => v.is_default) ??
		data?.find((v) => v.is_system_default);

	const onFilter = debounce((e) => {
		const _text = e?.target?.value;
		if (!_text) {
			setDisplayData(originalData);
			return;
		}
		const filterData = (data) =>
			data.filter(
				(item) =>
					item.name.toLowerCase().includes(_text.toLowerCase()) ||
					item.description.toLowerCase().includes(_text.toLowerCase())
			);
		const filteredCustom = filterData(originalData?.custom);
		const filteredStandard = filterData(originalData?.standard);

		setDisplayData({
			custom: filteredCustom,
			standard: filteredStandard,
		});
	}, 500);

	return {
		data,
		displayData,
		onFilter,
		defaultView,
	};
};

export const screenTagMapV2 = {
	// these are for application main table
	allApplications: 1,
	managed: 5,
	unmanaged: 6,
	restricted: 7,
	needsReview: 8,
	// this is for app_users table
	application_users: 4,
	//for users
	user_application_v2: 3,
};

export const useScreenTag = (dataEntity) => {
	const { hash } = useLocation();

	const itemId = useGetSegmentFromURL(2);

	const _hash = hash?.slice(1);
	const appId = _hash === "users" ? itemId : undefined;

	const _screenTag = screenTagMapV2[dataEntity];

	return {
		screenTag: _screenTag,
		showViews: !!_screenTag,
		appId,
	};
	// throw new Error("Shouldn't reach here for now");
	// const _screenTag = screenTagConditionsMap["app_user"]
	// 	? "app_user"
	// 	: screenTagConditionsMap["user_app"]
	// 	  ? "user_app"
	// 	  : hash;
	//
	// const screenTag = screenTagMapV2[_screenTag];
	// const appId = screenTagConditionsMap["app_user"] ? itemId : undefined;
	//
	// return { screenTag, appId, showViews: !!_screenTag };
};

// TODO: need to look for text overflow cases
export function useV2Toast() {
	const _toast = (props) => {
		if (typeof props !== "string")
			throw new Error("For now useV2Toast will support string only");
		const text = props;
		toast(
			<Typography variant="body_1_regular" color="brand_white">
				{text}
			</Typography>,
			{
				position: "bottom-center",
				hideProgressBar: true,
				autoClose: 5000,
				theme: "dark",
				style: {
					padding: "8px 6px 8px 16px",
					minHeight: 0,
					borderRadius: 8,
					backgroundColor: "#222",
				},
				bodyStyle: { margin: 0, padding: 0 },
				closeButton: (
					<div
						style={{
							marginLeft: 20,
							padding: 10,
							height: 28,
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<Icon name="close" style={{ color: "#A8A8A8" }} />
					</div>
				),
			}
		);
	};
	return _toast;
}
