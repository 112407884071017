import styled from "styled-components";
import React from "react";
import iconHelp from "../../assets/icon-help.svg";
import PropTypes from "prop-types";
import { Typography, GREY_VARIANT } from "@zluri/ui-components";

const SuccessBanner = styled.div`
	padding: 8px;
	border-radius: 8px;
	background: var(--accent-amber-3, #fff6dc);
	display: flex;
	align-items: center;
	margin-top: ${({ $moveToBottom }) => ($moveToBottom ? "auto" : null)};
`;

const ModalInfoBanner = ({ text, moveToBottom }) => {
	return (
		<SuccessBanner $moveToBottom={moveToBottom}>
			<img alt="Mapping successful" src={iconHelp} />
			<Typography
				variant="body_2_regular"
				style={{ marginLeft: "10px" }}
				color={GREY_VARIANT.SECONDARY_GREY_2}
			>
				{text}
			</Typography>
		</SuccessBanner>
	);
};

export default ModalInfoBanner;

ModalInfoBanner.propTypes = {
	text: PropTypes.string,
	moveToBottom: PropTypes.bool,
};
