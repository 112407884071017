import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Dropdown from "UIComponents/CustomDropdown/Dropdown";
import { searchAppPlaybooks } from "../service/OptimizationV2.api";
import { updateOptimizeNowUsers } from "../redux/OptimizationV2-action";
import { OptimizationV2SettingsPlaybook } from "../models/Optimization.model";
import arrowdropdown from "components/Transactions/Unrecognised/arrowdropdown.svg";
import LongTextTooltip from "modules/shared/components/LongTextTooltip/LongTextTooltip";
import "modules/Optimization/styles/OptimizationV2.css";
import { getSegmentFromURL } from "@zluri/ui-components";

export default function OptimizeNowPlaybookCell({
	user,
	users,
	setUsers,
	checked,
	setChecked,
}) {
	const dispatch = useDispatch();
	const appId = getSegmentFromURL(2);
	const [localStateUser, setLocalStateUser] = useState({ ...user });
	const oldKey = `${user.user_id}_${user.license_id}_${user.playbook_id}`;
	const handlePlaybookSelect = (playbook) => {
		const userobject = {
			...localStateUser,
			playbook: playbook,
			playbook_id: playbook._id,
		};
		const updatedUserArray = [userobject];
		dispatch(updateOptimizeNowUsers(appId, updatedUserArray));
		setLocalStateUser(userobject);

		let _users = [...users];

		for (const updatedUser of updatedUserArray) {
			const index = _users.findIndex(
				(user) => user._id === updatedUser._id
			);
			if (index > -1) {
				const newKey = `${updatedUser.user_id || null}_${
					updatedUser.license_id || null
				}_${updatedUser.playbook._id || null}`;
				_users[index] = {
					...updatedUser,
					_id: newKey,
				};
				if (checked?.some((a) => a === oldKey)) {
					const filteredChecked = [...checked]
						.filter((a) => a !== oldKey)
						.concat(newKey);
					setChecked(filteredChecked);
				}
			}
		}

		setUsers([..._users]);
	};

	return (
		<>
			<Dropdown
				toggler={
					<>
						<div className="optimize_now_playbook_cell_select_toggler">
							{localStateUser.playbook &&
							localStateUser.playbook?.name ? (
								<LongTextTooltip
									text={localStateUser.playbook?.name}
									placement={"left"}
									maxWidth="180px"
								/>
							) : (
								"Select playbook"
							)}
							<img
								src={arrowdropdown}
								height={10}
								width={7}
								alt=""
							/>
						</div>
					</>
				}
				options={[]}
				apiSearch={true}
				apiSearchCall={(query, cancelToken) =>
					searchAppPlaybooks(appId, query, cancelToken)
				}
				apiSearchDataKey="data"
				optionFormatter={(option) => (
					<>
						<LongTextTooltip
							text={option.name}
							placement={"left"}
							maxWidth="217px"
						/>
					</>
				)}
				onOptionSelect={(option) =>
					handlePlaybookSelect(
						new OptimizationV2SettingsPlaybook(option)
					)
				}
				menuClassName="optimization_now_playbook_cell_dropdown_menu"
				searchBoxStyle={{ width: "217px" }}
			/>
		</>
	);
}
