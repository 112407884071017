import React, { useState } from "react";
import account from "../../../assets/icons/account.svg";
import currency from "../../../assets/icons/cryto-currency.svg";
import notification from "../../../assets/icons/notification.svg";
import notificationDone from "../../../assets/icons/notification-done.svg";
import { DateTime } from "./dateTime";
import { mapValueToKeyState } from "utils/mapValueToKeyState";
import { getNthDayBeforeDate } from "utils/DateUtility";
import { addReminder } from "../../../modules/renewals/redux/renewal";
import { useDispatch } from "react-redux";

export function Contracts({ data, component }) {
	const [editContract, setEditContract] = useState(null);
	const [reminderBefore, setRemindBefore] = useState(null);
	const dispatch = useDispatch();
	const [date, setDate] = useState();

	const onSave = (e) => {
		dispatch(
			addReminder(
				{
					id: editContract._id,
					renewalID: editContract.renewal_id,
				},
				{ date: date }
			)
		);
	};

	const contracts = data[component.valueKey]
		.filter((n) => n)
		.map((contract, index) => {
			return (
				<div key={index} style={{ borderBottom: "1px solid #F6F7FA" }}>
					<div className="flex justify-content-between align-items-center p-2">
						<div>
							<div className="flex mb-1">
								<img
									src={
										contract.type === "contract"
											? account
											: currency
									}
									className="mr-1"
								/>
								<h3 className="bold-700 font-10 grey m-0">
									{contract.name}
								</h3>
							</div>
							<div>
								<p className="font-8 grey-1 mb-0 text-left">
									Upcoming Renewal{" "}
									<DateTime
										style={{
											fontSize: "8px",
											color: "#717171!important",
										}}
										data={contract}
										component={{
											valueKey: "start_date",
											format: "DD/MM/YY",
										}}
									/>{" "}
								</p>
							</div>
						</div>
						{!contract.reminder_date && (
							<img
								className="pointer"
								src={notification}
								onClick={() => {
									setEditContract(contract);
								}}
							/>
						)}
					</div>
					{/* footer */}
					{contract.reminder_date && (
						<div style={{ background: "#F6F7FA" }} className="p-2">
							<p className="bold-500 font-8 mb-0 grey-1">
								<img src={notificationDone} /> Reminder set for{" "}
								<DateTime
									style={{
										fontSize: "8px",
										color: "#717171!important",
									}}
									data={contract}
									component={{
										valueKey: "reminder_date",
										format: "DD/MM/YY",
									}}
								/>
							</p>
						</div>
					)}
					{editContract?._id === contract._id && (
						<div style={{ background: "#F6F7FA" }} className="p-2">
							<div className="flex align-items-center  mb-2">
								<input
									placeholder="Days"
									className="border-radius-8 grey-1 font-9 mr-1 p-1"
									style={{
										border: "1px solid #717171",
										background: "#fff",
										maxWidth: "100px",
										height: "24px",
									}}
									value={reminderBefore}
									onChange={(e) => {
										setRemindBefore(e.target.value);
										mapValueToKeyState(
											setDate,
											getNthDayBeforeDate(
												e.target.value,
												new Date(contract.renewal_date)
											)?.toISOString()
										);
									}}
								/>
								<p className="mb-0 font-9 grey">
									days before renewal
								</p>
							</div>
							<button
								className="border-radius-8 p-2 primary-color font-10 w-100"
								disabled={!reminderBefore}
								style={{
									background: "#E8F0FC",
									borderRadius: "8px",
									border: "none",
								}}
								onClick={() => {
									onSave();
								}}
							>
								Set Reminder
							</button>
						</div>
					)}
				</div>
			);
		});
	return (
		<div
			className="hide-scrollbar"
			style={{
				maxHeight: "200px",
				overflow: "scroll",
			}}
		>
			<div> {contracts}</div>
		</div>
	);
}

export function UpcomingContracts({ data, component }) {
	const [reminderBefore, setRemindBefore] = useState(null);
	const dispatch = useDispatch();
	const [date, setDate] = useState();

	const onSave = (e) => {
		dispatch(
			addReminder(
				{
					id: data.app_next_renewal_contract_id,
					renewalID: data.app_next_renewal_date_id,
				},
				{ date: date }
			)
		);
	};

	return (
		<div
			className="hide-scrollbar"
			style={{
				maxHeight: "200px",
				overflow: "scroll",
			}}
		>
			<h3 className="bold-700 font-10 grey text-left">Set Reminder</h3>

			<div className="flex mb-1 p-2">
				<img
					src={true === "contract" ? account : currency}
					className="mr-1"
				/>
				<div>
					<h3 className="bold-400 font-10 grey m-0">
						{" "}
						{data["app_next_renewal_contract_name"]}{" "}
					</h3>
					<p className="font-8 grey-1 mb-0 text-left">
						Upcoming Payment $
						{data["app_next_renewal_contract_payment"]}
					</p>
				</div>
			</div>

			<div className="flex align-items-center  mb-2">
				<input
					placeholder="Days"
					className="border-radius-8 grey-1 font-9 mr-1 p-1"
					style={{
						border: "1px solid #717171",
						background: "#fff",
						maxWidth: "100px",
						height: "24px",
					}}
					value={reminderBefore}
					onChange={(e) => {
						setRemindBefore(e.target.value);
						mapValueToKeyState(
							setDate,
							getNthDayBeforeDate(
								e.target.value,
								new Date(data.app_next_renewal_date_id)
							)?.toISOString()
						);
					}}
				/>
				<p className="mb-0 font-9 grey">days before renewal</p>
			</div>
			{data.app_next_renewal_date_reminder && (
				<div style={{ background: "#F6F7FA" }} className="p-2 mb-1">
					<p className="bold-500 font-8 mb-0 grey-1">
						<img src={notificationDone} /> Reminder set for{" "}
						<DateTime
							style={{
								fontSize: "8px",
								color: "#717171!important",
							}}
							data={data}
							component={{
								valueKey: "app_next_renewal_date_reminder",
								format: "DD/MM/YY",
							}}
						/>
					</p>
				</div>
			)}

			<div className="flex">
				<button
					className="border-radius-8 p-2 primary-color font-10 w-100 mr-2"
					style={{
						background: "#EBEBEB",
						borderRadius: "8px",
						border: "none",
					}}
				>
					Cancel
				</button>
				<button
					className="border-radius-8 p-2 primary-color font-10 w-100"
					style={{
						background: "#E8F0FC",
						borderRadius: "8px",
						border: "none",
					}}
					onClick={() => {
						onSave();
					}}
				>
					Set Reminder
				</button>
			</div>
		</div>
	);
}
