import { DotLottiePlayer } from "@dotlottie/react-player";
import React from "react";
import introLottieFile from "./introLoading.json";

export const RenderFirstLoad = () => {
	return (
		<div
			style={{
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
				// background: "rgba(121, 134, 203, 0.25)",
				// color: "#ef9a9a",
				opacity: 0.6,
				display: "flex",
				position: "absolute",
				alignItems: "center",
				justifyContent: "center",
			}}
		>
			<div style={{ width: "100px" }}>
				<DotLottiePlayer
					src={introLottieFile}
					autoplay
					loop
				></DotLottiePlayer>
			</div>
		</div>
	);
};
