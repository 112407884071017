import React from "react";
import { CustomCircularProgress } from "../mini-charts/CustomCircularProgress";
import { CustomMeterChart } from "../mini-charts/CustomMeterChart";
import arrowConnect from "assets/v2tables/arrow-int.svg";
import { Typography } from "@zluri/ui-components";

const getColorClasBaseOnUsageGrade = (usage_grade) => {
	if (!usage_grade) return "";
	const usageGradeInUpperCase = usage_grade.toUpperCase();

	if (usageGradeInUpperCase === "EXCELLENT") {
		return "accent_green_4";
	}
	if (usageGradeInUpperCase === "AVERAGE") {
		return "secondary_grey_3";
	}
	if (usageGradeInUpperCase === "GOOD") {
		return "#FFA217";
	}
	if (usageGradeInUpperCase === "POOR") {
		return "accent_red_3";
	}
};
export function UsagePopover({ data, component, ...props }) {
	const actualValue = data?.[component?.actualValueKey] ?? 0;
	const grade = data?.[component?.gradeKey];
	const benchMarkValue = data?.[component?.benchMarkValueKey] ?? 0;
	const difference = (actualValue ?? 0) - (benchMarkValue ?? 0);

	const appUsageGrade = grade ?? data?.["app_usage_grade"];
	return (
		<>
			<div className="d-flex align-items-center mt-2 mb-1">
				<div className="px-2 py-1">
					<CustomCircularProgress
						data={data}
						component={component}
						{...props}
					/>
				</div>
				<div className="d-flex flex-column">
					<div style={{ color: "#FFA217", textAlign: "left" }}>
						<Typography
							variant="body_1_bold"
							className="text-transform-capitalize"
							color={getColorClasBaseOnUsageGrade(appUsageGrade)}
						>
							{appUsageGrade ?? "N/A"} Usage
						</Typography>
					</div>
					<Typography variant="body_3_bold">
						<span
							className={`${
								difference > 0 ? "green" : "unauthorized_red"
							}`}
						>
							{Math.round(Math.abs(difference))}%
						</span>{" "}
						{/* {difference > 0 ? "better" : "worse"} usage than
						benchmark */}
					</Typography>
				</div>
			</div>
			<CustomMeterChart data={data} component={component} {...props} />
			{/* {!!data[component?.benchMarkValueKey] && (
				<div className="py-1 px-2 white-blue-bg">
					<Typography variant="alerttag_small_medium">
						{`Data quality is `}
						<span className="green text-transform-capitalize">
							{data["app_usage_quality"] ?? "N/A"}
						</span>{" "}
						<img src={arrowConnect} alt="link" />
					</Typography>
				</div>
			)} */}
		</>
	);
}

export function UsageBenchmarkPopover({ data, component, ...props }) {
	const actualValue = data[component.actualValueKey];
	const benchMarkValue = data[component.benchMarkValueKey];

	const difference = (actualValue ?? 0) - (benchMarkValue ?? 0);
	return (
		<>
			<div className="d-flex px-2 mt-2 mb-1">
				<Typography variant="body_3_bold">
					<span
						className={`${
							difference > 0 ? "green" : "unauthorized_red"
						}`}
					>
						{Math.abs(difference)}%
					</span>{" "}
					{difference > 0 ? "better" : "worse"} usage than benchmark
				</Typography>
			</div>
			<CustomMeterChart data={data} component={component} {...props} />
		</>
	);
}
