import React, { useState } from "react";
import { NameBadge } from "./NameBadge";

export default function GetImageOrNameBadge({
	url,
	name,
	height,
	width,
	fontSize,
	borderRadius,
	imageClassName,
	nameClassName,
	style = {},
}) {
	const [imgError, setImgError] = useState(false);
	return (
		<>
			{url && !imgError ? (
				<img
					onError={(e) => {
						setImgError(true);
					}}
					src={url}
					height={height}
					width={width}
					style={{
						borderRadius: borderRadius,
						objectFit: "contain",
						...style,
					}}
					alt={name}
					className={imageClassName}
				/>
			) : (
				<NameBadge
					name={name}
					height={height || width}
					width={width || height}
					fontSize={fontSize}
					borderRadius={borderRadius}
					className={nameClassName}
				/>
			)}
		</>
	);
}
