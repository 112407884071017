export const costCenterTable = {
	costCenters: "cost_centers",
	costCenterApplications: "cost_center_applications",
	costCenterUsers: "cost_center_users",
};

export const costCenterReduxConstants = {
	COST_CENTER_INFO_REQUESTED: "COST_CENTER_INFO_REQUESTED",
	COST_CENTER_INFO_FETCHED: "COST_CENTER_INFO_FETCHED",
	DELETE_COST_CENTERS_CACHE: "DELETE_COST_CENTERS_CACHE",
};
