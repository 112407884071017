import React, { useEffect, useMemo, useState } from "react";
import "./ColumnReorder.css";
import { arrayMoveImmutable } from "array-move";
import EmptySearch from "assets/v2tables/no-results.svg";
import NumberPill from "UIComponents/NumberPill/NumberPill";
import { Typography } from "@zluri/ui-components";
import cn from "classnames";
import PropTypes from "prop-types";
import ListItem from "./ListItem";
import SortableItem from "./SortableItem";
import SortableList from "./SortableList";
import SearchBar from "../Searchbar";

export const DEFAULT_FIXED_COLUMNS = [
	"application",
	"user",
	"department",
	"contract",
	"vendor",
	"transaction_description",
	"license",
	"whats_next",
	"notifications",
	"task",
	"actions",
	"source of mapping",
	"rule name",
	"priority",
];

const FixedItem = ({
	name,
	header,
	columnDescription,
	unselectedItems = [],
}) => {
	const isItemChecked =
		unselectedItems?.length === 0
			? true
			: !unselectedItems?.includes(header?.toLowerCase());

	return (
		<div
			className="flex align-items-center column-item justify-content-between gap-4 column-item-disabled"
			style={{ padding: 8 }}
		>
			<ListItem
				groupName={name}
				title={header}
				isItemChecked={isItemChecked}
				details={columnDescription}
				disabled
			/>
		</div>
	);
};
FixedItem.propTypes = {
	name: PropTypes.string,
	header: PropTypes.string,
	columnDescription: PropTypes.string,
	unselectedItems: PropTypes.array,
};

export const ColumnReorder = ({
	columns,
	selectedColumns,
	setSelectedColumns,
	setRearrangedColumns,
	fixedColumns = DEFAULT_FIXED_COLUMNS,
	disabledUnselectedItems = [],
}) => {
	const [checkedMap, setCheckedMap] = useState(() => new Map());

	const [searchValue, setSearchValue] = useState("");

	const onSortEnd = ({ oldIndex, newIndex }) => {
		const newList = arrayMoveImmutable(columns, oldIndex, newIndex);
		setRearrangedColumns(newList);
	};

	function handleChecked(element) {
		const _checkedMap = new Map(checkedMap);
		const uniqueId = element.group_name;
		if (_checkedMap.has(uniqueId)) {
			_checkedMap.delete(uniqueId);
		} else {
			_checkedMap.set(uniqueId, element);
		}
		setCheckedMap(_checkedMap);

		setSelectedColumns([..._checkedMap.values()]);
	}

	useEffect(() => {
		if (selectedColumns && columns) {
			const _checkedMap = new Map();

			for (let index = 0; index < columns.length; index++) {
				const col = columns[index];
				const selectedColumn = selectedColumns?.find(
					(sel) =>
						(sel.name === col.name && sel.header === col.header) ||
						// TODO: prj this is to support access reviews Select all columns Reset button fixes
						// There is inconsistency in teh selectedColumns data in here
						(sel.ui?.name === col.name &&
							sel.ui?.header === col.header)
				);
				if (selectedColumn) {
					const uniqueId = selectedColumn.group_name;
					_checkedMap.set(uniqueId, selectedColumn);
				}
			}
			setCheckedMap(_checkedMap);
		}
	}, [columns, selectedColumns]);

	const searchedItems = useMemo(() => {
		if (searchValue.length === 0) return columns;
		return columns?.filter(
			(a) =>
				a.name?.toLowerCase().includes(searchValue.toLowerCase()) ||
				a.header?.toLowerCase().includes(searchValue.toLowerCase()) ||
				a.group_name?.toLowerCase().includes(searchValue.toLowerCase())
		);
	}, [columns, searchValue]);

	const isAllSelected = !searchedItems?.some(
		(s) => !checkedMap.has(s.group_name)
	);

	const fixedColumnsMap = fixedColumns?.reduce((acc, curr) => {
		return acc.set(curr.toLowerCase(), true);
	}, new Map());
	const handleAllChecked = () => {
		const isInSearch = !!searchValue;

		if (isAllSelected && !isInSearch) {
			const _selected = columns?.filter((a) =>
				fixedColumnsMap.has(a?.header?.toLowerCase())
			);
			const _map = _selected.reduce((acc, curr) => {
				acc.set(curr.group_name, curr);
				return acc;
			}, new Map());
			setCheckedMap(_map);
			setSelectedColumns([..._map.values()]);

			return;
		}

		const _map = searchedItems.reduce((acc, curr) => {
			if (isAllSelected) {
				acc.delete(curr.group_name);
			} else {
				acc.set(curr.group_name, curr);
			}
			return acc;
		}, new Map(checkedMap));
		setCheckedMap(_map);

		setSelectedColumns([..._map.values()]);
	};

	const emptyResult = (
		<div className="empty_search_results">
			<NumberPill
				number={<img src={EmptySearch} width={24} height={24} alt="" />}
				pillHeight={50}
				borderRadius={"50%"}
				style={{ width: "50px", marginBottom: "14px" }}
			/>
			<Typography variant="body_2_bold">
				No records found for &quot;{searchValue}&quot;
			</Typography>
			<Typography variant="body_2_regular">
				We couldn’t find any view matching your search!
			</Typography>
		</div>
	);
	return (
		<div className={cn("column-reorder-container flex-1")}>
			<div className="flex flex-column gap-10">
				<SearchBar
					onChange={(e) => {
						setSearchValue(e.target.value);
					}}
					onClear={() => {
						setSearchValue("");
					}}
					placeholder="Search Columns"
				/>

				{!(searchValue.length > 0) && (
					<li
						className={`flex align-items-center column-item justify-content-between ${
							false ? "column-item-checked" : ""
						}`}
						onClick={() => {
							handleAllChecked();
						}}
					>
						<div
							className="d-flex align-items-center w-70"
							style={{ padding: "6px 8px" }}
						>
							<div
								className={cn(
									"column-item-checkbox align-items-center d-flex p-2"
								)}
							>
								<input
									type="checkbox"
									checked={isAllSelected}
									style={{ width: 14, height: 14 }}
								/>
							</div>
							<div
								className={`text-truncate-medium d-flex align-items-center w-100`}
								style={{ maxWidth: "100%" }}
							>
								<div
									className="text-truncate-medium"
									style={{ maxWidth: "90%" }}
								>
									<Typography
										variant={"body_2_regular"}
										style={{
											maxWidth: "90%",
											whiteSpace: "nowrap",
											overflow: "hidden",
											textOverflow: "ellipsis",
										}}
									>
										Select All columns
									</Typography>
								</div>
							</div>
						</div>

						<div
							className="d-flex align-items-center  justify-content-end"
							style={{ padding: 6 }}
						>
							<div className="flex flex-column align-items-end justify-content-end">
								<Typography
									variant="body_2_bold"
									color="#2266E2"
								>
									{checkedMap.size ?? 0} of {columns?.length}
								</Typography>
								<Typography
									variant="body_3_regular"
									color="#717171"
								>
									column selected
								</Typography>
							</div>
						</div>
					</li>
				)}
			</div>
			<div
				className="flex flex-column gap-4 overflow-scroll hide-scrollbar"
				style={{
					marginTop: (searchValue?.length ?? 0) > 0 ? 10 : 4,
				}}
			>
				{searchedItems.length === 0 ? (
					emptyResult
				) : (
					<SortableList
						items={searchedItems}
						onSortEnd={onSortEnd}
						checkedMap={checkedMap}
					>
						<ul className="flex flex-column p-0 m-0 gap-4">
							{searchedItems?.length > 0 &&
								searchedItems.map((value, index) => {
									const isFixed = fixedColumnsMap.has(
										value?.header?.toLowerCase()
									);
									if (isFixed) {
										return (
											<FixedItem
												name={value.name}
												key={`item-${value?.header}-${index}`}
												header={value.header}
												columnDescription={
													value.columnDescription
												}
												unselectedItems={
													disabledUnselectedItems
												}
											/>
										);
									}

									return (
										<SortableItem
											key={`item-${value?.header}-${index}`}
											id={value.group_name}
											header={value.header}
											name={value.name}
											columnDescription={
												value.columnDescription
											}
											checkedMap={checkedMap}
											onClick={() => handleChecked(value)}
										/>
									);
								})}
						</ul>
					</SortableList>
				)}
			</div>
		</div>
	);
};

const ColumnsPropType = PropTypes.arrayOf(PropTypes.object);

ColumnReorder.propTypes = {
	columns: ColumnsPropType,
	selectedColumns: ColumnsPropType,
	setSelectedColumns: PropTypes.func,
	setRearrangedColumns: PropTypes.func,
	style: PropTypes.object,
	fixedColumns: PropTypes.array,
	disabledUnselectedItems: PropTypes.array,
};
