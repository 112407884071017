import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ModifyColumnsExtended } from "../ModifyColumnsExtended/ModifyColumnsExtended";
import {
	updateColumns,
	updateProgressbar,
} from "modules/AccessReview/redux/AccessReview.actions";
import {
	STATUS,
	V2_TABLE_ENTITIES,
	ViewType,
	fixedColumns,
	fixedColumnsGroupNames,
} from "modules/AccessReview/constants/constants";
import V2Table from "containers/V2Table";
import {
	getDisplayColumnsData,
	getReviewersObj,
} from "modules/AccessReview/util/util";
import HeaderContentForTable from "../HeaderContentForTable/HeaderContentForTable";
import "modules/AccessReview/components/SelectColumns/SelectColumns.css";
import "modules/AccessReview/components/SelectUsers/SelectUsers.css";

export default function SelectColumns(props) {
	const dispatch = useDispatch();
	const { applications, applicationReview } = useSelector(
		(state) => state.accessReview
	);
	const tableProperties = useSelector(
		(state) => state.v2TableProperties?.[V2_TABLE_ENTITIES.SELECT_COLUMNS]
	);
	const primaryReviewer = applicationReview?.levels[0]?.primary_reviewer;
	const fallbackReviewer = applicationReview?.levels[0]?.fallback_reviewer;
	const apiProps = {
		...getReviewersObj(primaryReviewer, fallbackReviewer),
		appId: applicationReview?.appId,
	};
	const [displayColumns, setDisplayColumns] = useState([]);
	const [entireColumnList, setEntireColumnList] = useState([]); // As we are fixing only headers so specified header value rather than group value
	const [fixedColumnsWithProps, setFixedColumnsWithProps] = useState([]);

	useEffect(() => {
		dispatch(updateColumns(displayColumns));
		const isValidated = displayColumns?.length > 0;
		dispatch(
			updateProgressbar(
				2,
				isValidated ? STATUS.Completed : STATUS.InProgress,
				ViewType.ViewsInAddApplicationModal
			)
		);
		isValidated
			? props.setNextBtnDisabled(false)
			: props.setNextBtnDisabled(true);
	}, [displayColumns]);

	useEffect(() => {
		if (tableProperties?.columns) {
			const fixedColumns = tableProperties?.columns?.filter((column) =>
				fixedColumnsGroupNames.includes(column?.group_name)
			);
			setFixedColumnsWithProps(fixedColumns);
			const displayColsTemp = getDisplayColumnsData(
				applications,
				applicationReview,
				fixedColumns,
				tableProperties?.columns
			);
			const finalDisplayColsData = [];
			for (let i = 0; i < displayColsTemp.length; i++) {
				const temp = {
					...displayColsTemp[i],
					...displayColsTemp[i].ui,
					group_name: displayColsTemp[i].group_name,
				};
				delete temp.ui;
				if (temp.header) finalDisplayColsData.push(temp);
			}
			setDisplayColumns(finalDisplayColsData);
			const entireColsList = [];
			const tableCols = tableProperties?.columns;
			for (let i = 0; i < tableCols.length; i++) {
				const temp = {
					...tableCols[i],
					...tableCols[i].ui,
					group_name: tableCols[i].group_name,
				};
				delete temp.ui;
				if (temp.header) entireColsList.push(temp);
			}
			setEntireColumnList(entireColsList);
		}
	}, [tableProperties?.columns]);

	useEffect(() => {
		dispatch({
			type: "GET_TABLE_DATA",
			payload: {
				entity: V2_TABLE_ENTITIES.SELECT_COLUMNS,
				filterBy: [],
				cols: applicationReview?.columns
					? applicationReview?.columns
					: applications?.length > 0
						? applications[0]?.columns
						: fixedColumnsGroupNames,
				shouldRefresh: true,
				apiProps: apiProps,
			},
		});
	}, []);

	return (
		<div className="d-flex h-100 justify-content-center flex-1 align-items-center select__columns__container">
			<div className="w-65 users__table__container">
				<V2Table
					entity={V2_TABLE_ENTITIES.SELECT_COLUMNS}
					apiProps={apiProps}
					hideFilters={true}
					intID={applicationReview?.appId}
					hideOptionsMenu={true}
					hidePagination={true}
					forceInfinite={true}
					hideColumnModifier={true}
					disableSort
					hideSearch
					headerContent={
						<HeaderContentForTable
							wrapperClasses="w-100"
							headerText="Modify Columns"
							subHeaderText="Select what application user data will be visible to reviewers."
						/>
					}
					showCheckboxColumn={false}
					emptyMessage="No results found"
					paginationEntity="app users"
				></V2Table>
			</div>
			<div className="w-35 h-100 d-flex flex-column white-bg border-radius-8">
				<ModifyColumnsExtended
					entity={V2_TABLE_ENTITIES.SELECT_COLUMNS}
					displayColumns={displayColumns}
					setDisplayColumns={setDisplayColumns}
					entireColumnList={entireColumnList}
					setEntireColumnList={setEntireColumnList}
					fixedColumns={fixedColumns}
					fixedColumnsWithProps={fixedColumnsWithProps}
				/>
			</div>
		</div>
	);
}
