import { getChargebackPieSeries } from "modules/applications/components/chargebacks/utils/chargeback";
import { DepartmentConstants } from "./department.constant";

const initialState = {
	summary: { loaded: false, data: {} },
	chargebackActualSpend: { loaded: false, data: [] },
	appBudget: { loaded: false, data: {} },
	chargebackDepartmentSpend: { loaded: false, data: [] },
};

export function ChargebackDepartmentReducer(state = initialState, action) {
	var newState = { ...state };
	switch (action.type) {
		case DepartmentConstants.FETCH_CHARGEBACK_DEPARTMENT_SUMMARY_REQUESTED:
			newState.summary = {
				loaded: false,
				loading: true,
				data: {},
			};
			return newState;
		case DepartmentConstants.FETCH_CHARGEBACK_DEPARTMENT_SUMMARY:
			newState.summary = {
				loaded: true,
				loading: false,
				data: action.payload.data,
				error: action.payload.error,
			};
			return newState;

		case DepartmentConstants.FETCH_CHARGEBACK_DEPARTMENT_ACTUAL_SPEND_REQUESTED:
			newState.chargebackActualSpend = {
				loaded: false,
				data: [],
			};
			return newState;

		case DepartmentConstants.FETCH_CHARGEBACK_DEPARTMENT_ACTUAL_SPEND:
			newState.chargebackActualSpend = {
				loaded: true,
				data: action.payload.data?.actual_dept_spend,
				error: action.payload.error,
			};
			return newState;

		case DepartmentConstants.FETCH_CHARGEBACK_APPLICATION_BUDGET_REQUESTED:
			newState.appBudget = {
				loaded: false,
				appBudget: {},
			};
			return newState;
		case DepartmentConstants.FETCH_CHARGEBACK_APPLICATION_BUDGET:
			newState.appBudget = {
				loaded: false,
				data: action.payload.data,
				error: action.payload.error,
			};
			return newState;
		case DepartmentConstants.FETCH_CHARGEBACK_DEPARTMENT_SPEND_REQUESTED:
			newState.chargebackDepartmentSpend = {
				loaded: false,
				loading: true,
				data: [],
			};
			return newState;
		case DepartmentConstants.FETCH_CHARGEBACK_DEPARTMENT_SPEND:
			newState.chargebackDepartmentSpend = {
				loaded: true,
				data: Array.isArray(action.payload.data?.departments)
					? getChargebackPieSeries(action.payload.data?.departments)
					: [],
				error: action.payload.error,
			};
			return newState;
		default:
			return state;
	}
}
