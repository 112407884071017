import React from "react";
import { Typography } from "@zluri/ui-components";
import FilterPillButton from "../FilterPillButton";
import { useTableContext } from "../TableContext/context";

interface IAppliedSearchRow {
	handleSearch: (prop: {
		keywords?: string | string[];
		clearSearch?: boolean;
	}) => void;
	searchQuery: string | string[];
	totalResult: number;
}
export default function AppliedeSearchRow({
	handleSearch,
	searchQuery,
	totalResult,
}: IAppliedSearchRow) {
	const { appliedSearchLabel } = useTableContext();
	const searchTags = Array.isArray(searchQuery)
		? searchQuery
		: typeof searchQuery === "string"
			? (searchQuery?.split(",") ?? [])
			: [];

	return (
		<div className="d-flex flex-1 gap-8 align-items-center">
			<div className="d-flex align-items-center gap-4">
				<Typography
					variant="button_small_regular"
					color="secondary_grey_3"
					style={{ fontSize: 12 }}
				>
					{totalResult} Results found for
				</Typography>
				<div className="flex align-items-center gap-4">
					{searchTags?.map((tag, idx) => {
						const isFirstElement = idx === 0;
						const isLastElement = idx === searchTags.length - 1;
						return (
							<React.Fragment key={tag + idx}>
								{!isFirstElement && (
									<Typography
										variant="button_small_regular"
										color="secondary_grey_3"
										style={{ fontSize: 12 }}
									>
										{isLastElement ? "or" : ","}
									</Typography>
								)}
								<FilterPillButton
									size="small"
									text={tag}
									isActive
									onRemove={() => {
										const filteredTags = searchTags?.filter(
											(f) => f !== tag
										);
										handleSearch({
											keywords: filteredTags,
										});
									}}
								/>
							</React.Fragment>
						);
					})}
				</div>
				<Typography
					variant="button_small_regular"
					color="secondary_grey_3"
					style={{ fontSize: 12 }}
				>
					in {appliedSearchLabel}
				</Typography>
			</div>
			<div style={{ flex: 1 }} />
			<button
				className=" z_filter_pill_action"
				onClick={() => handleSearch({ clearSearch: true })}
				// onClick={() => handleRemove()}
			>
				<Typography
					variant="button_extrasmall_regular"
					color={"secondary_grey_3"}
					className="text-nowrap"
				>
					Clear Search
				</Typography>
			</button>
		</div>
	);
}
// function AppliedFilterPill({ displayText, fieldName }) {
// 	return (
// 		<Filter enabledFilters={[fieldName]}>
// 			<div>
// 				<div></div>
// 			</div>
// 		</Filter>
// 	);
// }
