import React from "react";
import PropTypes from "prop-types";
import refresh from "assets/icons/refresh.svg";
import { Typography } from "@zluri/ui-components";
import ErrorWrapper from "UIComponents/Rbac/ErrorWrapper";

export const ErrorContent = ({
	refreshCall,
	errorMessage,
	errorClassName,
	icon,
	iconText,
	bgColor = "transparent",
	className = "position-absolute",
}) => {
	return (
		<div
			className={`d-flex cursor-pointer flex-column align-items-center justify-content-center w-100 h-100  ${className}`}
			onClick={refreshCall && refreshCall}
			style={{ top: 0, left: 0, backgroundColor: bgColor }}
		>
			<Typography
				variant="body_1_medium"
				color="secondary_grey_2"
				className={`${errorClassName}`}
			>
				{errorMessage || "Something Went Wrong"}
			</Typography>

			{refreshCall && (
				<span className="d-flex align-items-center">
					{icon || (
						<img
							src={icon || refresh}
							width={12}
							height={12}
							alt=""
						/>
					)}
					<Typography
						variant="body_1_regular"
						color="secondary_grey_2"
						className="ml-1"
					>
						{iconText || "Refresh"}
					</Typography>
				</span>
			)}
		</div>
	);
};

const SomethingWentWrong = ({
	rbacErrorSize,
	rbacErrorStyle,
	errorObject,
	refreshCall,
	errorMessage,
	errorClassName,
	icon,
	iconText,
	bgColor = "transparent",
	className = "position-absolute",
}) => {
	return (
		<ErrorWrapper
			style={rbacErrorStyle}
			size={rbacErrorSize}
			error={errorObject ? errorObject : null}
			fallback={
				<ErrorContent
					refreshCall={refreshCall}
					errorMessage={errorMessage}
					errorClassName={errorClassName}
					icon={icon}
					iconText={iconText}
					bgColor="transparent"
					className="position-absolute"
				/>
			}
		/>
	);
};

export default SomethingWentWrong;

SomethingWentWrong.propTypes = {
	refreshCall: PropTypes.func,
	errorMessage: PropTypes.string,
	errorClassName: PropTypes.string,
	icon: PropTypes.element,
	iconText: PropTypes.string,
	className: PropTypes.string,
	bgColor: PropTypes.string,
};
