import React from "react";
import styled from "styled-components";
import { Divider } from "antd";
import linkArrow from "assets/icons/link-arrow.svg";
import PropTypes from "prop-types";
import { timeSince } from "utils/DateUtility";
import warning from "assets/icons/delete-warning.svg";
import { Typography, GREY_VARIANT, BLUE_VARIANT } from "@zluri/ui-components";

const DataFreshnessModalComponentWrapper = styled.div`
	width: 261px;
	height: 291px;
	background: #ffffff;
	border: 1px solid #ebebeb;
	box-sizing: border-box;
	box-shadow:
		0px 5px 30px rgba(0, 0, 0, 0.05),
		0px 8px 20px rgba(0, 0, 0, 0.1);
	border-radius: 4px;
	z-index: 10000;
	position: absolute;
	right: ${({ alignRight }) => (alignRight ? "-240px" : "4px")};
	display: flex;
	flex-direction: column;
`;
const InnerWrapper = styled.div`
	width: 100%;
	height: 100%;
	position: relative;
`;

const HeaderWrapper = styled.div`
	padding: 10px 4px 0px;
`;

const StyledDivider = styled(Divider)`
	margin-top: 10px;
	margin-bottom: 0px;
`;

const LinkWrapper = styled.div`
	display: flex;
	align-items: center;
	margin-top: 8px;
	height: 18px;
	margin-left: 21px;
	margin-top: 8px;
	margin-bottom: 8px;
`;

const FooterWrapper = styled.div`
	position: absolute;
	bottom: 0px;
	width: 261px;
	height: 44px;
	padding: 0px 4px;
	background: #ffffff;
`;

const DataFreshnessList = styled.div`
	width: 100%;
	height: 222px;
	overflow-y: auto;
	padding-bottom: 12px;
`;

const DataFreshnessItem = styled.div`
	height: 45px;
	width: 230px;
	display: flex;
	margin-top: 12px;
`;

const LogoWrapper = styled.div`
	width: 16px;
	height: 16px;
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: center;
	margin-right: 18px;
	margin-left: 15px;
`;

const ContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

const PrimarySourceWrapper = styled.div`
	background: var(--glow, #5abaff);
	padding: 1px 4px;
	width: 79px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const DisclaimerWrapper = styled.div`
	display: flex;
	margin-left: 21px;
	margin-bottom: 10px;
`;

const ImageWrapper = styled.div`
	margin-right: 8px;
	display: flex;
	align-items: center;
`;

const DataFreshnessModalComponent = ({
	alignRight,
	sourceList,
	showDisclaimer,
}) => {
	const learnMoreClickedHandler = () => {
		window.open(
			"https://support.zluri.com/support/solutions/articles/73000622458-data-freshness-indicator",
			"_blank"
		);
	};

	return (
		<DataFreshnessModalComponentWrapper alignRight={alignRight}>
			<InnerWrapper>
				<HeaderWrapper>
					{showDisclaimer ? (
						<DisclaimerWrapper>
							<ImageWrapper>
								<img
									src={warning}
									alt="warning"
									width={16}
									height={16}
								/>
							</ImageWrapper>

							<Typography
								variant="button_extrasmall_regular"
								color={GREY_VARIANT.SECONDARY_GREY_1}
							>
								Activity data takes longer to process
								<br />
								compared to user and license data.
							</Typography>
						</DisclaimerWrapper>
					) : (
						<Typography
							color={GREY_VARIANT.SECONDARY_GREY_3}
							style={{
								opacity: "0.6",
								marginLeft: "18px",
							}}
							variant="button_extrasmall_regular"
						>
							Based on source data of
						</Typography>
					)}
					<StyledDivider />
				</HeaderWrapper>
				<DataFreshnessList>
					{sourceList.map(
						({ name, logo, sync_ran_at, is_primary }) => (
							<DataFreshnessItem key={name}>
								<LogoWrapper>
									<img src={logo} alt={name} />
								</LogoWrapper>
								<ContentWrapper>
									{is_primary ? (
										<PrimarySourceWrapper>
											<Typography
												variant="body_3_medium"
												color="#ffffff"
											>
												Primary Source
											</Typography>
										</PrimarySourceWrapper>
									) : null}
									<Typography
										color={GREY_VARIANT.SECONDARY_GREY_1}
										variant="subheading_2_regular"
									>
										{name}
									</Typography>
									<Typography
										variant="subheading_2_regular"
										color={GREY_VARIANT.SECONDARY_GREY_3}
									>
										{timeSince(
											new Date(sync_ran_at),
											new Date()
										)}{" "}
										ago
									</Typography>
								</ContentWrapper>
							</DataFreshnessItem>
						)
					)}
				</DataFreshnessList>
				<FooterWrapper>
					<StyledDivider />
					<LinkWrapper onClick={learnMoreClickedHandler}>
						<Typography
							variant="button_extrasmall_medium"
							color={BLUE_VARIANT.PRIMARY_BLUE_DARK_1}
							style={{
								marginRight: "4px",
								cursor: "pointer",
							}}
						>
							LEARN MORE
						</Typography>
						<img src={linkArrow} alt="link arrow" />
					</LinkWrapper>
				</FooterWrapper>
			</InnerWrapper>
		</DataFreshnessModalComponentWrapper>
	);
};

export default DataFreshnessModalComponent;

DataFreshnessModalComponent.propTypes = {
	sourceList: PropTypes.array,
	alignRight: PropTypes.bool,
	showDisclaimer: PropTypes.bool,
};
