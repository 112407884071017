import {
	Button,
	Typography,
	theme as V2_DEFAULT_THEME,
} from "@zluri/ui-components";
import React from "react";
import ToggleSwitch from "react-switch";
import IconAlert from "assets/applications/Icon_SpendAlert.svg";

export const CustomSwitch = ({ data, component }) => {
	const valueKey = data[component.valueKey];
	return (
		<div className="d-flex align-items-center">
			<ToggleSwitch
				height={16}
				width={28}
				handleDiameter={12}
				checked={valueKey?.restricted}
				onChange={(v) => {}}
				checkedIcon={false}
				uncheckedIcon={false}
				onColor="#5287E8"
				offColor="#DDDDDD"
			/>
		</div>
	);
};

export const CustomNotificationText = ({ data, component }) => {
	const valueKey = data[component.valueKey];
	return (
		<>
			<Typography
				variant="subheading_2_regular"
				color="secondary_grey_1"
				className="ml-2"
			>
				{valueKey?.restricted ? "On" : "Off"}
			</Typography>
		</>
	);
};

export const CustomNotificationPopOver = ({ data, component, ...props }) => {
	const valueKey = data[component.valueKey];
	const handleClick = (updatedValue) => {
		if (!!valueKey?.restricted === updatedValue) return;
		try {
			const patchObj = {
				patches: [
					{
						op: "replace",
						field: "notifications.restricted",
						value: updatedValue,
					},
				],
			};
			props.handleUpdate(data.app_id, patchObj, {
				app_notification: { restricted: updatedValue },
			});
		} catch (err) {
			console.error("Error updating application owner:", err);
		}
	};

	return (
		<div style={{ padding: 8 }}>
			<div className="d-flex flex-column justify-content-center align-items-center">
				<img src={IconAlert} alt="" style={{ width: 20, height: 20 }} />
				<Typography
					variant="body_2_regular"
					color="secondary_grey_1"
					className="mt-1"
				>
					Are you sure you want to turn{" "}
					{!valueKey?.restricted ? "on" : "off"} notifications for{" "}
					{data.app_name}?
				</Typography>
				<div
					className="d-flex w-100 justify-content-evenly mt-2 mx-2"
					style={{ gap: 8 }}
				>
					<Button
						theme={V2_DEFAULT_THEME}
						variant="primary-alternative"
						onClick={() => props.hidePopup()}
						style={{ minWidth: 98 }}
					>
						Cancel
					</Button>
					<Button
						theme={V2_DEFAULT_THEME}
						variant="primary-default"
						onClick={() => handleClick(!valueKey?.restricted)}
						style={{ minWidth: 98 }}
					>
						Turn {!valueKey?.restricted ? "On" : "Off"}
					</Button>
				</div>
			</div>
		</div>
	);
};
