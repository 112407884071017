const ACTION_TYPE = {}
const initialState = {
	SHOW_HEALTH_POINTS: false
}

export function featureFlagsReducer(state = initialState, action) {
	switch (action.type) {
		default:
			return state;
		}
}
