/* eslint-disable react/prop-types */
import React from "react";
import plusIcon from "../assets/icons/plus.svg";
import { CUSTOM_VIEW_NAME } from "../constants/views";
import { useDispatch } from "react-redux";
import { openModal } from "reducers/modal.reducer";
import { GREY_VARIANT, Typography } from "@zluri/ui-components";
import { getValueFromLocalStorage } from "utils/localStorage";

const SaveCustomView = ({
	group,
	groupByOptions,
	setCustomViewSaved,
	viewOptions,
	handleSubmit,
}) => {
	const dispatch = useDispatch();
	const userInfo = getValueFromLocalStorage("userInfo");
	return (
		(group === CUSTOM_VIEW_NAME ||
			!groupByOptions.hasOwnProperty(CUSTOM_VIEW_NAME)) && (
			<>
				<hr
					style={{
						marginTop: "0px",
						marginBottom: "0px",
					}}
				/>
				<div
					onClick={() => {
						dispatch(
							openModal("customViewModal", {
								handleSubmit: handleSubmit,
								isEdit: false,
								viewOptions,
								viewData: {
									is_default: true,
									user_id: userInfo.user_id,
								},
							})
						);
						setCustomViewSaved(false);
					}}
					className="px-2 py-3 font-12 cursor-pointer"
					style={{
						display: "flex",
						alignItems: "center",
						gap: "4px",
					}}
				>
					<span>
						<img
							width="18px"
							height="18px"
							src={plusIcon}
							alt="add"
						/>
					</span>
					<Typography
						variant="subheading_2_regular"
						color={GREY_VARIANT.SECONDARY_GREY_2}
					>
						Save Custom View
					</Typography>
				</div>
				<hr className="m-0" />
			</>
		)
	);
};

export default SaveCustomView;
