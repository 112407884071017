import { Typography, Tooltip } from "@zluri/ui-components";
import React from "react";
import NumberPill from "UIComponents/NumberPill/NumberPill";
import "./MeterChart.css";

const statusTexts = ["Poor", "Mediocre", "Good", "Excellent"];

export function CustomMeterChart({ data, component }) {
	const actualValue = Math.round(data?.[component?.actualValueKey] ?? 0);
	const benchMarkValue = Math.round(
		data?.[component?.benchMarkValueKey] ?? 0
	);

	return (
		<div className="px-2 py-1">
			<div className="meter">
				<div className="meter-chart-progress-bar w-100"></div>
				<Tooltip
					placement="top"
					content={
						<div className="d-flex align-items-center px-1 py-1">
							<NumberPill
								number
								pillBackGround={"#A0D8FF"}
								pillSize={8}
							/>
							<Typography
								variant="alerttag_small_semibold"
								color="white"
								className="ml-1"
							>
								{actualValue}
							</Typography>
						</div>
					}
				>
					<div
						className="status-mark usage-score-line"
						style={{ left: `${actualValue}%` }}
					></div>
				</Tooltip>

				<Tooltip
					placement="top"
					content={
						<div className=" d-flex align-items-center px-1 py-1">
							<NumberPill
								number
								pillBackGround={"#1176BE"}
								pillSize={8}
							/>
							<Typography
								variant="alerttag_small_semibold"
								color="white"
								className="ml-1"
							>
								{benchMarkValue}
							</Typography>
						</div>
					}
				>
					<div
						className="benchmark-score-line status-mark"
						style={{ left: `${benchMarkValue}%` }}
					></div>
				</Tooltip>
			</div>
			<div className="text-alignment">
				{statusTexts.map((text) => (
					<Typography
						variant="alerttag_extrasmall_semibold"
						color="secondary_grey_4"
						key={text}
					>
						{text}
					</Typography>
				))}
			</div>

			{/* This will be required later, this is descoped for now. */}
			{/* <div className="mt-2">
				<div
					className={`d-flex mr-3 align-items-center justify-content-between`}
					style={{
						height: "20px",
						padding: "4px",
						borderRadius: "8px",
					}}
				>
					<div className="d-flex align-items-center">
						<NumberPill
							number
							pillBackGround={"#A0D8FF"}
							pillSize={12}
						/>
						<Typography
							variant="alerttag_extrasmall_medium"
							color="secondary_grey_3"
							className="ml-1"
						>
							Usage Score - {actualValue}
						</Typography>
					</div>
					<div className="d-flex align-items-center">
						<NumberPill
							number
							pillBackGround={"#1176BE"}
							pillSize={12}
						/>
						<Typography
							variant="alerttag_extrasmall_medium"
							color="secondary_grey_3"
							className="ml-1"
						>
							Benchmark - {benchMarkValue}
						</Typography>
					</div>
				</div>
			</div> */}
		</div>
	);
}
