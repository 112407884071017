import { isFunction } from "underscore";

export const SORT_OPTIONS = {
	DEFAULT: "default",
	COLUMNS_A_Z: "columns_a_z",
	CATEGORIES_A_Z: "categories_a_z",
};

export const sortColumns = (sortDirection, items, stickyHeader = "") => {
	if (sortDirection === SORT_OPTIONS.DEFAULT) return items;
	if (sortDirection === SORT_OPTIONS.COLUMNS_A_Z) {
		const updatedColumns = [...items].sort((a, b) => {
			if (a?.header?.toLowerCase() === stickyHeader) {
				// If only a is in the firstItems array, give it higher priority
				return -1;
			} else if (b?.header?.toLowerCase() === stickyHeader) {
				// If only b is in the firstItems array, give it higher priority
				return 1;
			} else {
				// Sort alphabetically
				return a.header.localeCompare(b.header);
			}
		});
		return updatedColumns;
	}
	if (sortDirection === SORT_OPTIONS.CATEGORIES_A_Z) {
		const updatedColumns = [...items].sort((a, b) => {
			if (a?.header?.toLowerCase() === stickyHeader) {
				return -1;
			} else if (b?.header?.toLowerCase() === stickyHeader) {
				return 1;
			} else {
				return a.name?.localeCompare(b.name);
			}
		});
		return updatedColumns;
	}
};
export function invoke(cb) {
	if (isFunction) return cb();
}
