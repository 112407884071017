import { client } from "utils/client";

export async function getBulkUploadPropertiesFile({ type }) {
	const response = await client.post(`bulk-upload/properties`, {
		type,
	});
	return response.data;
}

export async function getBulkUploadList({ type, page, row, query }) {
	const response = await client.post(`bulk-upload/list`, {
		type,
		query,
		page,
		row,
	});
	return response.data;
}

export async function validateBulkUploadCSV({ data, type }) {
	const response = await client.post(`bulk-upload/validate`, {
		data,
		type,
	});
	return response.data;
}

export async function processBulkUpload({ type, files, meta = {} }) {
	const response = await client.post(`bulk-upload/process`, {
		type,
		files,
		meta,
	});
	return response.data;
}

export async function retryProcessBulkUpload({ type, bulk_upload_id }) {
	const response = await client.put(`bulk-upload/retry`, {
		type,
		bulk_upload_id,
	});
	return response.data;
}

export async function getBulkUploadProcessedRecords({ bulk_upload_id }) {
	const response = await client.get(`bulk-upload/${bulk_upload_id}/draft`);
	return response.data;
}

export async function updateBulkUploadDraft({
	draft,
	bulk_upload_id,
	process_bulk_upload_id,
}) {
	const response = await client.put(
		`bulk-upload/${bulk_upload_id}/draft/${process_bulk_upload_id}`,
		{ draft }
	);
	return response.data;
}

export async function getBulkUploadRecord({ bulk_upload_id }) {
	const response = await client.get(`bulk-upload/${bulk_upload_id}`);
	return response.data;
}
