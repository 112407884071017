import React from "react";

import "./AddLevelsOfReviews.css";

import {
	GREY_VARIANT,
	SUB_HEADING_VARIANT,
	Typography,
} from "@zluri/ui-components";
import Levels from "./Levels";
import ToggleSwitch from "react-switch";
import { isBoolean } from "underscore";

export default function AddLevelsOfReviews({
	levels,
	header,
	handleChange,
	handleAddLevel,
	handleRemoveLevel,
	hideDescription = false,
	initialLevelsExist,
	handleSetupReviewersToggle,
	shouldRenderLevels,
	isDefaultReviewersComp = false,
}) {
	const showLevels = isBoolean(shouldRenderLevels)
		? shouldRenderLevels
		: initialLevelsExist;
	return (
		<div className="d-flex flex-column gap-10">
			{header && (
				<Typography variant={SUB_HEADING_VARIANT.SUBHEADING_2_BOLD}>
					{header}
				</Typography>
			)}
			{isDefaultReviewersComp && (
				<div className="d-flex align-items-center gap-10">
					<ToggleSwitch
						height={18}
						width={32}
						checked={initialLevelsExist}
						onChange={(val) =>
							handleSetupReviewersToggle &&
							handleSetupReviewersToggle(val)
						}
						checkedIcon={false}
						uncheckedIcon={false}
						onColor="#2266E2"
						offColor="#EBEBEB"
					/>
					<div className="d-flex flex-column gap-2">
						<Typography
							variant={SUB_HEADING_VARIANT.SUBHEADING_2_BOLD}
							color={GREY_VARIANT.SECONDARY_GREY_2}
						>
							Set up default reviewers
						</Typography>
						{!hideDescription && (
							<Typography
								variant={
									SUB_HEADING_VARIANT.SUBHEADING_2_REGULAR
								}
								color={GREY_VARIANT.SECONDARY_GREY_2}
							>
								Reviewers set up here will be automatically
								assigned to the entities included in the review.
								You will have the option to edit them for
								individual entities if required.
							</Typography>
						)}
					</div>
				</div>
			)}
			{showLevels && (
				<Levels
					levels={levels}
					handleChange={handleChange}
					handleAddLevel={handleAddLevel}
					handleRemoveLevel={handleRemoveLevel}
				/>
			)}
		</div>
	);
}
