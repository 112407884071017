import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@zluri/ui-components";
import RuleActionMapped from "modules/transactionMapping/assets/rule-action-mapped.svg";
import RuleActionUnmapped from "modules/transactionMapping/assets/rule-action-unmapped.svg";
import RuleActionArchived from "modules/transactionMapping/assets/rule-action-archived.svg";
import styled from "styled-components";
import { transactionActions } from "modules/transactionMapping/constants/actions";

const RuleActionWrapper = styled.div`
	display: flex;
	gap: 8px;
	overflow: hidden;
`;

const iconMapping = {
	[transactionActions.TRANSACTION_MAPPING]: RuleActionMapped,
	[transactionActions.TRANSACTION_UNMAPPING]: RuleActionUnmapped,
	[transactionActions.TRANSACTION_ARCHIVING]: RuleActionArchived,
};

const RuleActionRenderer = ({ data }) => {
	const { actions } = data;

	const { action_type, action_params } = actions[0]["v"];
	const { mapped_application } = action_params || {};
	const textToRender = () => {
		if (mapped_application) {
			return mapped_application.app_name;
		}
		if (action_type === transactionActions.TRANSACTION_UNMAPPING) {
			return "Mark as Unrecognized";
		}
		return "Mark as Archived";
	};

	return (
		<RuleActionWrapper>
			<img src={iconMapping[action_type]} alt="rule action" />
			<Typography variant="body_1_regular">{textToRender()}</Typography>
		</RuleActionWrapper>
	);
};

export default RuleActionRenderer;

RuleActionRenderer.propTypes = {
	data: PropTypes.object,
};
