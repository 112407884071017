import React from "react";
import "./NotesOverview.css";

import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import {
	Button,
	SecondaryButtonVariant,
	TertiaryButtonVariant,
	theme,
} from "@zluri/ui-components";
import { SingleNote } from "../NoteCard/SingleNote";

import plusIcon from "assets/icons/plus.svg";

import { OVERVIEW_MODAL } from "modules/applications/constants/ApplicationConstants";
import { setSelectedTab } from "modules/applications/redux/Applications.actions";
import { NOTES_DRAWER_TYPE } from "modules/applications/constants/OverviewV2.constants";
import ErrorWrapper from "UIComponents/Rbac/ErrorWrapper";

const NotesList = ({ notes, appId, setNoteDrawerState }) => {
	return (
		<>
			{notes?.map((note) => (
				<div
					key={note?._id}
					className="z_overview_note flex-1 border-radius-8 overflow-hidden"
				>
					<SingleNote
						appId={appId}
						initialNote={note}
						contentEditableClassName="z_overview_note_content_editable"
						setNoteDrawerState={setNoteDrawerState}
						noteVariant="sm"
					/>
				</div>
			))}
		</>
	);
};

const NotesActionButtons = ({ count = 0, setNoteDrawerState }) => {
	const dispatch = useDispatch();

	const handleDrawerState = (type) => {
		setNoteDrawerState({
			type: type,
		});
		dispatch(setSelectedTab(OVERVIEW_MODAL.NOTES));
	};

	return (
		<>
			<Button
				theme={theme}
				variant={TertiaryButtonVariant.tertiary_default}
				className="z_overview_notes_btn w-100"
				onClick={() =>
					handleDrawerState(NOTES_DRAWER_TYPE.LISTING_NOTE)
				}
			>
				View All {count} Notes
			</Button>
			<Button
				theme={theme}
				variant={SecondaryButtonVariant.secondary_default}
				className="z_overview_notes_btn w-100"
				onClick={() => handleDrawerState(NOTES_DRAWER_TYPE.ADDING_NOTE)}
			>
				<img src={plusIcon} className="mr-1" alt="add note" />
				Add New Note
			</Button>
		</>
	);
};

export function NotesOverview({
	appId,
	notes,
	showCheckList,
	setNoteDrawerState,
}) {
	const notesData = notes?.data?.slice(0, showCheckList ? 2 : 3);

	return (
		<div className="d-flex align-items-end grey-7-bg p-2 h-auto gap-8 border-radius-8">
			{notes?.error ? (
				<ErrorWrapper error={notes?.error} size="medium" />
			) : (
				<>
					<div
						className={`z_overview_notes_container ${
							showCheckList && "z_overview_notes_container-sm"
						} gap-8 flex-1`}
					>
						<NotesList
							appId={appId}
							notes={notesData}
							setNoteDrawerState={setNoteDrawerState}
						/>
					</div>
					{notes?.count && (
						<div className="z_overview_notes_btnz_overview_notes_btn_container d-flex flex-column gap-8">
							<NotesActionButtons
								count={notes?.count}
								setNoteDrawerState={setNoteDrawerState}
							/>
						</div>
					)}
				</>
			)}
		</div>
	);
}

NotesList.propTypes = {
	appId: PropTypes.string,
	notes: PropTypes.array,
	setNoteDrawerState: PropTypes.func,
};
NotesActionButtons.propTypes = {
	count: PropTypes.number,
	setNoteDrawerState: PropTypes.func,
};
NotesOverview.propTypes = {
	appId: PropTypes.string,
	notes: PropTypes.shape({ count: PropTypes.number, data: PropTypes.array }),
	showCheckList: PropTypes.bool,
	setNoteDrawerState: PropTypes.func,
};
