import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { checkSpecialCharacters } from "services/api/search";
import cross from "assets/v2tables/tags-cross.svg";
import completed from "assets/icons/completeicon.svg";
import acceptbutton from "assets/icons/acceptbutton.svg";
import cancel from "assets/icons/cancel.svg";
import { Button } from "UIComponents/Button/Button";
import { Spinner } from "react-bootstrap";
import { Form, Overlay } from "react-bootstrap";
import Chips from "components/Users/Applications/Modals/FiltersRenderer/Chip";
import NumberPill from "UIComponents/NumberPill/NumberPill";
const STATUS = {
	DEFAULT: "DEFAULT",
	LOADING: "LOADING",
	UPDATED: "UPDATED",
};
export function AppTagsViewRenderer({ data }) {
	return (
		<>
			<div className="d-flex align-items-center">
				{data?.app_tags &&
					Array.isArray(data.app_tags) &&
					data.app_tags.slice(0, 1).map((el, id) => (
						<OverlayTrigger
							placement="top"
							overlay={<Tooltip>{el}</Tooltip>}
						>
							<NumberPill
								number={el}
								fontColor={"#484848"}
								fontSize="11px"
								fontWeight="400"
								pillBackGround={"#E1E8F8"}
								pillHeight="22px"
								borderRadius={99}
								style={{ padding: "8px" }}
							/>
						</OverlayTrigger>
					))}
				{Array.isArray(data.app_tags) && data.app_tags.length > 1 && (
					<>
						<OverlayTrigger
							placement="top"
							overlay={
								<Tooltip>
									{data.app_tags.slice(1).join(",")}
								</Tooltip>
							}
						>
							<div
								className={`d-flex flex-center cursor-pointer `}
								style={{
									width: "25px",

									borderRadius: " 4px",
									height: "100%",
								}}
							>
								<span
									style={{
										fontSize: 12,
										color: "#5287E8",
									}}
								>
									+ {data.app_tags.length - 1}
								</span>
							</div>
						</OverlayTrigger>
					</>
				)}
			</div>
		</>
	);
}
export function AppTagsPopoverRenderer({
	data,
	component,
	handleUpdate,
	setShowPopover,
	...props
}) {
	const [values, setValues] = useState([]);
	const [inputVal, setInputVal] = useState("");
	const [status, setStatus] = useState(STATUS.DEFAULT);

	const handleSubmit = () => {
		if (
			(Array.isArray(values) && values.length > 0) ||
			inputVal.length > 0
		) {
			handleUpdate(
				data._id,
				{},
				{
					app_tags: values.length > 0 ? values : inputVal,
				}
			);
			// setShowPopover(false);
			// setInputVal("");
			// setValues([]);
		}
	};

	useEffect(() => {
		setValues(data?.[component?.valueKey] || []);
	}, [data]);
	const handleChipInputChange = (e, onBlur) => {
		const val = e.target.value;
		if (
			val?.charAt(val.length - 1) !== "," &&
			checkSpecialCharacters(val, false)
		) {
			return;
		}
		setInputVal(val);
		if (val?.charAt(val.length - 1) === "," || onBlur) {
			if (!val) {
				return;
			}
			const updatedValues = val.split(",").filter((i) => !!i);
			if (values?.indexOf(updatedValues[0]) > -1) return;
			const previousValues = values || [];
			setValues([...previousValues, ...updatedValues]);
			setInputVal("");
		}
	};

	const renderTagsWithRemoveButton = (el, index, className) => {
		return (
			<OverlayTrigger placement="top" overlay={<Tooltip>{el}</Tooltip>}>
				<div
					className={`mr-1 d-flex align-items-center justify-content-center`}
					style={{
						marginBottom: "10px",
						fontColor: "#484848",
						fontSize: "11px",
						backgroundColor: "#E1E8F8",
						height: "22px",
						borderRadius: "99px",
						padding: "8px",
					}}
				>
					<span
						className="text-truncate mr-1"
						style={{
							maxWidth: "60px",
							width: "fit-content",
						}}
					>
						{el}
					</span>
					<img
						src={cross}
						className="ml-auto cursor-pointer"
						onClick={() => {
							let tempArray = [...values];
							tempArray.splice(index, 1);
							setValues(tempArray);
						}}
					></img>
				</div>
			</OverlayTrigger>
		);
	};

	return (
		<div
			className="w-100 d-flex flex-column border-radius-4"
			style={{
				background: "#FFFFFF",
				padding: "8px",
				maxWidth: "160px",
			}}

			// This is a popover
		>
			<div className="font-11 mb-1" style={{ fontWeight: "700" }}>
				Tags
			</div>
			<div className="d-flex align-items-center flex-wrap">
				{Array.isArray(values) &&
					values.map((el, index) =>
						renderTagsWithRemoveButton(el, index)
					)}
			</div>
			<div
				className="d-flex align-items-center"
				style={{ position: "relative" }}
			>
				<Chips
					inputArea={
						<OverlayTrigger
							placement="bottom"
							// show
							// rootClose={false}
							overlay={
								<Tooltip>
									<span className="text-capitalize">
										Press comma(,) to add tag
									</span>
								</Tooltip>
							}
						>
							<Form.Control
								autoFocus={true}
								type="text"
								className="chip__input custom_bg_tags"
								placeholder={`Add Tags`}
								value={inputVal}
								onKeyPress={(e) => {
									if (e.key === "Enter") {
										handleSubmit();
									}
								}}
								onChange={handleChipInputChange}
							/>
						</OverlayTrigger>
					}
					onClose={() => {
						setInputVal("");
					}}
					showResetAll={false}
				/>
				<div
					className={`z__if__edit_actions  d-flex ml-2`}
					style={{
						top: status === STATUS.LOADING ? "-1px" : "0",
					}}
				>
					{status === STATUS.LOADING && (
						<Spinner
							animation="border"
							variant="light"
							bsPrefix="my-custom-spinner"
							className="my-custom-spinner mr-2"
						/>
					)}
					{status === STATUS.UPDATED && (
						<img src={completed} className="mt-1 mr-2" width="16" />
					)}
				</div>
			</div>
		</div>
	);
}
