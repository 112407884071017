import { ProgressBarLoader } from "common/Loader/ProgressBarLoader";
import React from "react";

const LOADER = {
	DATA_PROCESSING: "data-processing",
};

function getLoaderTemplate(type) {
	switch (type) {
		case type === LOADER.DATA_PROCESSING:
		default:
			return (
				<div className="d-flex ">
					<ProgressBarLoader
						style={{ marginTop: "-2px" }}
						height={20}
						width={20}
					/>{" "}
					<span className="ml-2"> Data Processing</span>
				</div>
			);
	}
}

export function Loader(props) {
	return (
		<span style={{ opacity: 0.6 }} className="v2table__cell-loader">
			{getLoaderTemplate(props.type)}
		</span>
	);
}
