export const transactionConstants = {
	RECOGNISED_TRANSACTIONS_REQUESTED: "RECOGNISED_TRANSACTIONS_REQUESTED",
	RECOGNISED_TRANSACTIONS_FETCHED: "RECOGNISED_TRANSACTIONS_FETCHED",
	UNRECOGNISED_TRANSACTIONS_REQUESTED: "UNRECOGNISED_TRANSACTIONS_REQUESTED",
	UNRECOGNISED_TRANSACTIONS_FETCHED: "UNRECOGNISED_TRANSACTIONS_FETCHED",
	ARCHIVED_REQUESTED: "ARCHIVED_REQUESTED",
	ARCHIVED_FETCHED: "ARCHIVED_FETCHED",
	PAYMENT_METHODS_FETCHED: "PAYMENT_METHODS_FETCHED",
	PAYMENT_METHODS_REQUESTED: "PAYMENT_METHOD_REQUESTED",
	UPLOADS_FETCHED: "UPLOADS_FETCHED",
	UPLOADS_REQUESTED: "UPLOADS_REQUESTED",
	UPLOADS_RECOG_FETCHED: "UPLOADS_RECOG_FETCHED",
	UPLOADS_RECOG_REQUESTED: "UPLOADS_RECOG_REQUESTED",
	UPLOADS_UNRECOG_FETCHED: "UPLOADS_UNRECOG_FETCHED",
	UPLOADS_UNRECOG_REQUESTED: "UPLOADS_UNRECOG_REQUESTED",
	UPLOADS_ARC_FETCHED: "UPLOADS_ARC_FETCHED",
	UPLOADS_ARC_REQUESTED: "UPLOADS_ARC_REQUESTED",
	DELETE_RECOGNISED_TRANSACTIONS_CACHE:
		"DELETE_RECOGNISED_TRANSACTIONS_CACHE",
	DELETE_ALL_RECOGNISED_TRANSACTIONS_CACHE:
		"DELETE_ALL_RECOGNISED_TRANSACTIONS_CACHE",
	DELETE_UNRECOGNISED_TRANSACTIONS_CACHE:
		"DELETE_UNRECOGNISED_TRANSACTIONS_CACHE",
	DELETE_ALL_UNRECOGNISED_TRANSACTIONS_CACHE:
		"DELETE_ALL_UNRECOGNISED_TRANSACTIONS_CACHE",
	DELETE_ARCHIVED_CACHE: "DELETE_ARCHIVED_CACHE",
	DELETE_ALL_ARCHIVED_TRANSACTIONS_CACHE:
		"DELETE_ALL_ARCHIVED_TRANSACTIONS_CACHE",
	DELETE_UPLOADS_RECOG_CACHE: "DELETE_UPLOADS_RECOG_CACHE",
	DELETE_ALL_UPLOADS_RECOG_CACHE: "DELETE_ALL_UPLOADS_RECOG_CACHE",
	DELETE_UPLOADS_UNRECOG_CACHE: "DELETE_UPLOADS_UNRECOG_CACHE",
	DELETE_ALL_UPLOADS_UNRECOG_CACHE: "DELETE_ALL_UPLOADS_UNRECOG_CACHE",
	DELETE_UPLOADS_CACHE: "DELETE_UPLOADS_CACHE",
	DELETE_ALL_UPLOADS_ARCHIVED_CACHE: "DELETE_ALL_UPLOADS_ARCHIVED_CACHE",
	DELETE_UPLOADS_ARC_CACHE: "DELETE_UPLOADS_ARC_CACHE",
	SEARCH_RECOGNISED_TRANSACTIONS_FETCHED:
		"SEARCH_RECOGNISED_TRANSACTIONS_FETCHED",
	SEARCH_RECOGNISED_TRANSACTIONS_REQUESTED:
		"SEARCH_RECOGNISED_TRANSACTIONS_REQUESTED",
	SEARCH_ARCHIVED_TRANSACTIONS_FETCHED:
		"SEARCH_ARCHIVED_TRANSACTIONS_FETCHED",
	SEARCH_ARCHIVED_TRANSACTIONS_REQUESTED:
		"SEARCH_ARCHIVED_TRANSACTIONS_REQUESTED",
	SEARCH_UPLOADS_RECOG_FETCHED: "SEARCH_UPLOADS_RECOG_FETCHED",
	SEARCH_UPLOADS_RECOG_REQUESTED: "SEARCH_UPLOADS_RECOG_REQUESTED",
	SEARCH_UPLOADS_UNRECOG_REQUESTED: "SEARCH_UPLOADS_UNRECOG_REQUESTED",
	SEARCH_UPLOADS_UNRECOG_FETCHED: "SEARCH_UPLOADS_UNRECOG_FETCHED",
	SEARCH_UPLOADS_ARCH_REQUESTED: "SEARCH_UPLOADS_ARCH_REQUESTED",
	SEARCH_UPLOADS_ARCH_FETCHED: "SEARCH_UPLOADS_ARCH_FETCHED",
	RESET_TRANSACTIONS: "RESET_TRANSACTIONS",
	SEARCH_UPLOADS: "SEARCH_UPLOADS",
	SEARCH_UNRECOGNISED_TRANSACTIONS_FETCHED:
		"SEARCH_UNRECOGNISED_TRANSACTIONS_FETCHED",
	SEARCH_UNRECOGNISED_TRANSACTIONS_REQUESTED:
		"SEARCH_UNRECOGNISED_TRANSACTIONS_REQUESTED",
};

export const departmentConstants = {
	DEPARTMENTS_REQUESTED: "DEPARTMENTS_REQUESTED",
	DEPARTMENTS_FETCHED: "DEPARTMENTS_FETCHED",
	DEPARTMENT_INFO_REQUESTED: "DEPARTMENT_INFO_REQUESTED",
	DEPARTMENT_INFO_FETCHED: "DEPARTMENT_INFO_FETCHED",
	SINGLE_DEPARTMENT_APPS_REQUESTED: "SINGLE_DEPARTMENT_APPS_REQUESTED",
	SINGLE_DEPARTMENT_APPS_FETCHED: "SINGLE_DEPARTMENT_APPS_FETCHED",
	DELETE_DEPARTMENTS_CACHE: "DELETE_DEPARTMENTS_CACHE",
	SINGLE_DEPARTMENT_USERS_REQUESTED: "SINGLE_DEPARTMENT_USERS_REQUESTED",
	SINGLE_DEPARTMENT_USERS_FETCHED: "SINGLE_DEPARTMENT_USERS_FETCHED",
	DELETE_SINGLE_DEPARTMENT_USERS: "DELETE_SINGLE_DEPARTMENT_USERS",
	DELETE_DEPARTMENTS_APPS_CACHE: "DELETE_DEPARTMENTS_APPS_CACHE",
};

export const v2EntitiesTransactions = {
	recognized: "recognized",
	unrecognized: "unrecognized",
};

export const MAX_TRANSACTION_UPLOAD_LIMIT = 150000;
