import React from "react";

import PropTypes from "prop-types";

import { BUTTON_VARIANT, GREY_VARIANT, Typography } from "@zluri/ui-components";

const CheckListHeader = ({
	headerText,
	setExpanded,
	expanded,
	id,
	isExpanded,
	count,
}) => {
	return (
		<span
			className="d-flex align-items-center justify-content-between flex-1"
			style={{ padding: "0px 6px" }}
		>
			<Typography
				variant={BUTTON_VARIANT.BUTTON_EXTRA_SMALL_SEMIBOLD}
				color={GREY_VARIANT.SECONDARY_GREY_1}
				className="d-flex align-items-center gap-8"
			>
				<span>{headerText}</span>
				{(count?.error_count || count?.not_completed_count) > 0 && (
					<span
						className={
							count?.has_error ? "error_tag" : "primary_tag"
						}
					>
						{count?.has_error
							? count?.error_count
							: count?.not_completed_count}
					</span>
				)}
			</Typography>
			<Typography
				variant={BUTTON_VARIANT.BUTTON_EXTRA_SMALL_MEDIUM}
				color="#2266e2"
				onClick={() =>
					setExpanded(handleCheckListToggle(expanded, isExpanded, id))
				}
			>
				{isExpanded ? "Hide" : "Show"}
			</Typography>
		</span>
	);
};

const handleCheckListToggle = (expanded, isExpanded, id) => {
	if (isExpanded) return [...expanded?.filter((ex) => ex !== id)];
	else return [...expanded, id];
};

export default CheckListHeader;

CheckListHeader.propTypes = {
	headerText: PropTypes.string,
	setExpanded: PropTypes.func,
	expanded: PropTypes.array,
	id: PropTypes.string,
	isExpanded: PropTypes.bool,
	count: PropTypes.number,
};
