import sassPlatformIcon from "assets/Icon_SaasPlatform.svg";
import igaPlatformIcon from "assets/Icon_IGA.svg";

import { headerConstants } from "constants/header";
import { PLATFORM_TYPE } from "modules/shared/utils/platform.hook";

const initialState = {
	platforms: [
		{
			id: "0",
			icon: sassPlatformIcon,
			title: "SaaS Management",
			description: "Apps, Users, Licenses,  and Optimization",
			lastViewed: "2024-03-19T12:00:00",
			platform: PLATFORM_TYPE.SAAS,
			active: true,
		},
		{
			id: "1",
			icon: igaPlatformIcon,
			title: "Identity Governance & Administration",
			description: "Manage and Review Access",
			lastViewed: "2024-03-19T12:00:00",
			platform: PLATFORM_TYPE.IGA,
		},
	],
};

// TODO(nav): This reducer is not used anywhere in app. Keeping it for future change, if not required remove this.
export function headerReducer(state = initialState, action) {
	const { type, payload } = action;
	switch (type) {
		case headerConstants.UPDATE_ACTIVE_PLATFORM: {
			const updatedPlatforms = state.platforms.map((platform) => ({
				...platform,
				active: payload?.id === platform.id ? true : false,
			}));

			return {
				...state,
				platforms: updatedPlatforms,
			};
		}
		default:
			return state;
	}
}
