import MultiselectIs from "modules/transactionMapping/assets/operators/multiselect-is.svg";
import MultiselectIsNot from "modules/transactionMapping/assets/operators/multiselect-is-not.svg";
import MultiselectIsIn from "modules/transactionMapping/assets/operators/multiselect-is-any-of.svg";
import MultiselectIsNotIn from "modules/transactionMapping/assets/operators/multiselect-is-not-of.svg";
import NumericEquals from "modules/transactionMapping/assets/operators/number-is-equal-to.svg";
import NumericGreaterThan from "modules/transactionMapping/assets/operators/number-is-greater-than.svg";
import NumericLesserThan from "modules/transactionMapping/assets/operators/number-is-lesser-than.svg";
import NumericIsNotEqualTo from "modules/transactionMapping/assets/operators/number-is-not-equal-to.svg";
import StringEquals from "modules/transactionMapping/assets/operators/string-is-equal-to.svg";
import StringNotEquals from "modules/transactionMapping/assets/operators/string-is-not-equal-to.svg";
import StringTextContains from "modules/transactionMapping/assets/operators/string-contains.svg";
import StringTextDoesNotContains from "modules/transactionMapping/assets/operators/string-does-not-contain.svg";
import SringEndsWith from "modules/transactionMapping/assets/operators/string-ends-with.svg";
import SringDoesNotEndWith from "modules/transactionMapping/assets/operators/string-does-not-end-with.svg";
import StringStartsWith from "modules/transactionMapping/assets/operators/string-starts-with.svg";
import StringDoesNotStartWith from "modules/transactionMapping/assets/operators/string-does-not-start-with.svg";
import DateIsBefore from "modules/transactionMapping/assets/operators/date-is-earlier-than.svg";
import DateIsAfter from "modules/transactionMapping/assets/operators/date-is-later-than.svg";
import IsEmpty from "modules/transactionMapping/assets/operators/is-empty.svg";
import IsNotEmpty from "modules/transactionMapping/assets/operators/is-not-empty.svg";
import { operators } from "./operators";

export const operatorIconMapping = {
	[`select-${operators.select.EQUALS}`]: MultiselectIs,
	[`select-${operators.select.NOT_EQUALS}`]: MultiselectIsNot,
	[`select-${operators.select.IS_IN}`]: MultiselectIsIn,
	[`select-${operators.select.IS_NOT_IN}`]: MultiselectIsNotIn,
	[`entity-${operators.entity.EQUALS}`]: MultiselectIs,
	[`entity-${operators.entity.EQUAL_TO_PRIMARY_SOURCE}`]: MultiselectIs,
	[`entity-${operators.entity.IS_IN}`]: MultiselectIsIn,
	[`entity-${operators.entity.IS_IN_PRIMARY_SOURCE}`]: MultiselectIsIn,
	[`entity-${operators.entity.IS_NOT_IN}`]: MultiselectIsNotIn,
	[`entity-${operators.entity.IS_NOT_IN_PRIMARY_SOURCE}`]: MultiselectIsNotIn,
	[`entity-${operators.entity.NOT_EQUALS}`]: MultiselectIsNot,
	[`entity-${operators.entity.NOT_EQUAL_TO_PRIMARY_SOURCE}`]:
		MultiselectIsNot,
	[`numeric-${operators.numeric.EQUALS}`]: NumericEquals,
	[`numeric-${operators.numeric.IS_GREATER_THAN}`]: NumericGreaterThan,
	[`numeric-${operators.numeric.IS_LESS_THAN}`]: NumericLesserThan,
	[`numeric-${operators.numeric.NOT_EQUALS}`]: NumericIsNotEqualTo,
	[`inputbox-${operators.inputbox.EQUALS}`]: StringEquals,
	[`inputbox-${operators.inputbox.NOT_EQUALS}`]: StringNotEquals,
	[`inputbox-${operators.inputbox.TEXT_CONTAINS}`]: StringTextContains,
	[`inputbox-${operators.inputbox.TEXT_DOES_NOT_CONTAIN}`]:
		StringTextDoesNotContains,
	[`string-${operators.string.EQUALS}`]: StringEquals,
	[`string-${operators.string.NOT_EQUALS}`]: StringNotEquals,
	[`string-${operators.string.TEXT_CONTAINS}`]: StringTextContains,
	[`string-${operators.string.TEXT_DOES_NOT_CONTAIN}`]:
		StringTextDoesNotContains,
	[`string-${operators.string.TEXT_STARTS_WITH}`]: StringStartsWith,
	[`string-${operators.string.TEXT_DOES_NOT_START_WITH}`]:
		StringDoesNotStartWith,
	[`string-${operators.string.TEXT_ENDS_WITH}`]: SringEndsWith,
	[`string-${operators.string.TEXT_DOES_NOT_END_WITH}`]: SringDoesNotEndWith,
	[`string-${operators.string.IS_EMPTY}`]: IsEmpty,
	[`string-${operators.string.IS_NOT_EMPTY}`]: IsNotEmpty,
	[`date-${operators.date.IS_AFTER}`]: DateIsAfter,
	[`date-${operators.date.IS_BEFORE}`]: DateIsBefore,
	[`objectid-${operators.entity.EQUALS}`]: MultiselectIs,
	[`objectid-${operators.entity.EQUAL_TO_PRIMARY_SOURCE}`]: MultiselectIs,
	[`objectid-${operators.entity.IS_IN}`]: MultiselectIsIn,
	[`objectid-${operators.entity.IS_IN_PRIMARY_SOURCE}`]: MultiselectIsIn,
	[`objectid-${operators.entity.IS_NOT_IN}`]: MultiselectIsNotIn,
	[`objectid-${operators.entity.IS_NOT_IN_PRIMARY_SOURCE}`]:
		MultiselectIsNotIn,
	[`objectid-${operators.entity.NOT_EQUALS}`]: MultiselectIsNot,
	[`objectid-${operators.entity.NOT_EQUAL_TO_PRIMARY_SOURCE}`]:
		MultiselectIsNot,
};

export const operatorOptions = Object.keys(operatorIconMapping).map((key) => ({
	value: key,
	label: key,
	icon: operatorIconMapping[key],
}));
