export const v2InfiniteConstants = {
	ALL_DATA_REQUESTED: "ALL_DATA_REQUESTED",
	ALL_DATA_FETCHED: "ALL_DATA_FETCHED",
	CLEAR_ALL_DATA_CACHE: "CLEAR_ALL_DATA_CACHE",
	NEXT_PAGE: "NEXT_PAGE",
	SEARCH_DATA_REQUESTED: "SEARCH_DATA_REQUESTED",
	SEARCH_DATA_FETCHED: "SEARCH_DATA_FETCHED",
	PROPERTY_FILE_REQUESTED: "PROPERTY_FILE_REQUESTED",
	PROPERTY_FILE_FETCHED: "PROPERTY_FILE_FETCHED",
	SOURCE_LIST_FOR_FILTER_REQUESTED: "SOURCE_LIST_FOR_FILTER_REQUESTED",
	SOURCE_LIST_FOR_FILTER_FETCHED: "SOURCE_LIST_FOR_FILTER_FETCHED",
	CLEAR_PROPERTY_FILE: "CLEAR_PROPERTY_FILE",
	UPDATE_SCROLL_POSTION: "UPDATE_SCROLL_POSTION",
	UPDATE_V2_DATA: "UPDATE_V2_DATA",
	CURRENT_DATA_KEY: "CURRENT_DATA_KEY",
	REPLACE_V2_DATA: "REPLACE_V2_DATA",
};

export const ARCHIVE_FIELD_ID = {
	APPLICATION: "app_archive",
	USER: "user_archive",
	DEPARTMENT: "dept_archive",
};
export const excludeFilterMap = {
	users: new Set(["user_account_type"]),
};
