export const AppLicensesConstants = {
	FETCH_LICENSES_SUMMARY_METRICS: "FETCH_LICENSES_SUMMARY_METRICS",
	FETCHED_LICENSES_SUMMARY_METRICS: "FETCHED_LICENSES_SUMMARY_METRICS",
	FETCH_MAP_LICENSES_SUMMARY: "FETCH_MAP_LICENSES_SUMMARY",
	FETCHED_MAP_LICENSES_SUMMARY: "FETCHED_MAP_LICENSES_SUMMARY",
	FETCH_LICENSES_MAPPING_STATUS: "FETCH_LICENSES_MAPPING_STATUS",
	FETCHED_LICENSES_MAPPING_STATUS: "FETCHED_LICENSES_MAPPING_STATUS",
	FETCH_LICENSES_MAPPING_USER_STATUS: "FETCH_LICENSES_MAPPING_USER_STATUS",
	FETCHED_LICENSES_MAPPING_USER_STATUS:
		"FETCHED_LICENSES_MAPPING_USER_STATUS",
	FETCH_LICENSES_TYPE_SUMMARY: "FETCH_LICENSES_TYPE_SUMMARY",
	FETCHED_LICENSES_TYPE_SUMMARY: "FETCHED_LICENSES_TYPE_SUMMARY",
	FETCH_LICENSES_BY_DEPARTMENT: "FETCH_LICENSES_BY_DEPARTMENT",
	FETCHED_LICENSES_BY_DEPARTMENT: "FETCHED_LICENSES_BY_DEPARTMENT",
	FETCH_LICENSES_AND_CONTRACTS_GRAPH: "FEFETCH_LICENSES_AND_CONTRACTS_GRAPH",
	FETCHED_LICENSES_AND_CONTRACTS_GRAPH:
		"FETCHED_LICENSES_AND_CONTRACTS_GRAPH",
	FETCH_LICENSES_SYNC_GRAPH: "FETCH_LICENSES_SYNC_GRAPH",
	FETCHED_LICENSES_SYNC_GRAPH: "FETCHED_LICENSES_SYNC_GRAPH",
	SET_BULK_EDIT_LICENSE: "SET_BULK_EDIT_LICENSE",
};
