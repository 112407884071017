import { filterPropertiesHelper } from "modules/v2InfiniteTable/utils/v2infiniteTableUtil";
import { clientV2, clientV3 } from "utils/client";
import { getSegmentFromURL } from "@zluri/ui-components";

export async function searchAppPlaybooks(appId, query, cancelTokenSource) {
	let options = {};
	if (cancelTokenSource) {
		options.cancelToken = cancelTokenSource.token;
	}
	let reqBody = {
		filter_by: [
			{
				field_values: [query],
				field_order: "contains",
				field_id: "name",
				filter_type: "search_in_string",
				field_name: "Workflow Name",
				negative: false,
				is_custom: false,
			},
		],
		sort_by: [],
		columns: [],
	};
	const response = await clientV2.post(
		`mini-playbooks/applications/${appId}/templates?page=${0}&row=${30}`,
		reqBody,
		options
	);
	return response?.data;
}

export async function getOptimizationV2TopRow(appId) {
	const response = await clientV2.get(
		`applications/${appId}/optimization/overview`
	);
	return response.data;
}

export async function getOptimizationV2Configuration(appId) {
	const response = await clientV2.get(
		`applications/${appId}/optimization/configuration`
	);
	return response.data;
}

export async function postOptimizationV2Configuration(appId, config) {
	const response = await clientV2.post(
		`applications/${appId}/optimization/configuration`,
		config
	);
	return response.data;
}

export async function getOptimizationV2LicenseTrend(appId) {
	const response = await clientV2.get(
		`applications/${appId}/optimization/license-trend`
	);
	return response.data;
}

export async function getOptimizationV2BarCharts(appId) {
	const response = await clientV2.get(
		`application/${appId}/optimization/licenses-summary/license-breakdown`
	);
	return response.data;
}

export async function getOptimizationV2Report({
	appId,
	firstLoad = false,
	startMonth,
	startYear,
	endMonth,
	endYear,
}) {
	let response;
	if (firstLoad) {
		response = await clientV2.get(
			`applications/${appId}/optimization/generate-report?first_load=${firstLoad}`
		);
	} else {
		response = await clientV2.get(
			`applications/${appId}/optimization/generate-report?first_load=${firstLoad}&start_month=${startMonth}&end_month=${endMonth}&start_year=${startYear}&end_year=${endYear}`
		);
	}
	return response.data;
}

export async function getOptimizeNowUsersList(appId, page, row) {
	const response = await clientV2.get(
		`applications/${appId}/optimization/user-license-details?page=${page}&row=${row}`
	);
	return response.data;
}

export async function getOptimizeNowMeta(appId) {
	const response = await clientV2.get(
		`applications/${appId}/optimization/license-details`
	);
	return response.data;
}

export async function optimizeUsers(appId, user_playbook_data) {
	const response = await clientV2.post(
		`applications/${appId}/optimization/bulk-create-run`,
		{ user_playbook_data }
	);
	return response.data;
}

export async function getPastOptimizationUsersList(appId, logId, page, row) {
	const response = await clientV2.get(
		`applications/${appId}/optimization/past-optimization-user-license-details/log/${logId}?page=${page}&row=${row}`
	);
	return response.data;
}

export async function getPastOptimizationMeta(appId, logId) {
	const response = await clientV2.get(
		`applications/${appId}/optimization/past-optimization-license-details/log/${logId}`
	);
	return response.data;
}

export async function deleteOptimizationV2Report(appId) {
	const response = await clientV2.post(
		`applications/${appId}/optimization/delete-report`
	);
	return response.data;
}
//optimization v2.1
export async function getAppOptimizationPastLogsPropertiesList() {
	const response = await clientV3.get(
		`application/optimization/past-optimization-logs/filter`
	);
	return response?.data;
}
export async function getAppOptimizationReviewUsersPropertiesList() {
	const response = await clientV3.get(
		`applications/optimization/user-license-details/filter `
	);
	let newResponseData = filterPropertiesHelper(response.data);
	return newResponseData;
}

export async function getPreviousOptimizationRunsPropertiesList() {
	const response = await clientV3.get(
		`applications/optimization/past-optimization-user-license-details/filter`
	);
	let newResponseData = filterPropertiesHelper(response.data);
	return newResponseData;
}

export async function getPastOptimizationLogs(
	reqObj,
	page,
	row,
	cancelTokenSource = null,
	searchReqParams,
	applicationId
) {
	let options = {};
	let url = `application/${applicationId}/optimization/licenses-summary/past-optimization-logs?page=${page}&row=${row}`;
	if (cancelTokenSource) {
		options = {
			cancelToken: cancelTokenSource.token,
		};
	}

	if (Object.keys(searchReqParams).length > 0) {
		url = `application/${applicationId}/optimization/licenses-summary/past-optimization-logs?is_search=${true}&search_query=${encodeURIComponent(
			searchReqParams
		)}&page=${page}&row=${row}`;
	}

	const response = await clientV3.post(url, reqObj, options);

	return response.data;
}

export async function getOptimizationReviewUsers(
	reqObj,
	page,
	row,
	cancelTokenSource = null,
	searchReqParams,
	applicationId
) {
	let options = {};
	let url = `applications/${applicationId}/optimization/user-license-details?page=${page}&row=${row}`;
	if (cancelTokenSource) {
		options = {
			cancelToken: cancelTokenSource.token,
		};
	}

	if (Object.keys(searchReqParams).length > 0) {
		url = `applications/${applicationId}/optimization/user-license-details?is_search=${true}&search_query=${encodeURIComponent(
			searchReqParams
		)}&page=${page}&row=${row}`;
	}

	const response = await clientV3.post(url, reqObj, options);

	let newResponseData = filterPropertiesHelper(response.data);
	response.data = newResponseData;
	return response.data;
}

export async function getPreviousOptimizationRuns(
	reqObj,
	page,
	row,
	cancelTokenSource = null,
	searchReqParams,
	applicationId,
	apiProps
) {
	let options = {};
	const { logId } = apiProps;
	let url = `applications/${applicationId}/optimization/past-optimization-user-license-details/log/${logId}?page=${page}&row=${row}`;
	if (cancelTokenSource) {
		options = {
			cancelToken: cancelTokenSource.token,
		};
	}

	if (Object.keys(searchReqParams).length > 0) {
		url = `applications/${applicationId}/optimization/past-optimization-user-license-details/log/${logId}?is_search=${true}&search_query=${encodeURIComponent(
			searchReqParams
		)}&page=${page}&row=${row}`;
	}

	const response = await clientV3.post(url, reqObj, options);

	let newResponseData = filterPropertiesHelper(response.data);
	response.data = newResponseData;
	return response.data;
}

export async function getOptimizableV2Licenses(
	appId,
	startYear,
	startMonth,
	endYear,
	endMonth
) {
	const response = await clientV2.get(
		`applications/${appId}/optimization/graph?startYear=${startYear}&startMonth=${startMonth}&endYear=${endYear}&endMonth=${endMonth}`
	);
	return response.data;
}

export async function getPastOptimizationList(appId) {
	const response = await clientV2.get(
		`application/${appId}/optimization/past-optimization`
	);
	return response.data;
}

export async function getRealizedSavingsList(appId) {
	const response = await clientV2.get(
		`applications/${appId}/realized-savings`
	);
	return response.data;
}

export async function getRealizedSavingUsersList({
	appId,
	start_period,
	end_period,
	report_type,
}) {
	const response = await clientV2.post(
		`applications/${appId}/realized-savings/users`,
		{
			...(start_period && { start_period }),
			...(end_period && { end_period }),
			report_type,
		}
	);
	return response.data;
}

export async function excludeFromOptimization({
	entity,
	setAll = false,
	filterBy = [],
	userSearch = false,
	exclude_ids,
	exclude_from_optimization_types,
}) {
	const response = await clientV2.put(`optimization/exclude`, {
		entity,
		set_all: setAll,
		filter_by: filterBy,
		user_search: userSearch,
		exclude_ids,
		exclude_from_optimization_types,
	});
	return response.data;
}

export async function getAppRealizedSavingsPropertiesV3() {
	const appId = getSegmentFromURL(2);
	const response = await clientV3.get(
		`applications/${appId}/realized-savings/filters`
	);
	return response.data;
}
export async function getAppRealizedSavingsListV3(
	reqObj,
	page,
	row,
	cancelTokenSource = null,
	searchReqParams
) {
	let options = {};
	const appId = getSegmentFromURL(2);
	let url =
		`applications/${appId}/realized-savings?page=` + page + "&row=" + row;
	if (cancelTokenSource) {
		options = {
			cancelToken: cancelTokenSource.token,
		};
	}

	if (searchReqParams?.length > 0) {
		url = `applications?is_search=${true}&search_query=${encodeURIComponent(
			searchReqParams
		)}&page=${page}&row=${row}`;
	}

	let response = await clientV3.post(url, reqObj, options);

	return response.data;
}

export async function getAppRealizedSavingsUsersBreakdownPropertiesV3() {
	const appId = getSegmentFromURL(2);
	const response = await clientV3.get(
		`applications/${appId}/realized-savings/users/filters`
	);
	return response.data;
}
export async function getAppRealizedSavingsUsersBreakdownListV3(
	reqObj = {},
	page,
	row,
	cancelTokenSource = null,
	searchReqParams,
	intID,
	apiProps
) {
	let options = {};
	const appId = getSegmentFromURL(2);
	let url =
		`applications/${appId}/realized-savings/users?page=` +
		page +
		"&row=" +
		row;
	if (cancelTokenSource) {
		options = {
			cancelToken: cancelTokenSource.token,
		};
	}

	if (searchReqParams?.length > 0) {
		url = `applications?is_search=${true}&search_query=${encodeURIComponent(
			searchReqParams
		)}&page=${page}&row=${row}`;
	}

	const body = {
		...reqObj,
		report_type: apiProps.report_type,
		start_period: apiProps.start_period,
		end_period: apiProps.end_period,
	};
	let response = await clientV3.post(url, body, options);

	return response.data;
}
