import {
	BODY_VARIANT,
	Button,
	GREY_VARIANT,
	SearchBox,
	Typography,
	theme,
} from "@zluri/ui-components";
import { handleEllipseText } from "modules/applications/constants/AppLicensesConstants";
import { spendsCurrencyFormat } from "modules/applications/utils/Spends";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./SearchSelect.css";
import tick from "assets/tick-mark.svg";
import { isEmpty } from "underscore";
import { apiKeyAndOtherData, customApi } from "./searchSelectApiHelper";
import { setLicenseData } from "modules/applications/redux/AppLicenses/AppLicenses.action";
import { getSegmentFromURL } from "@zluri/ui-components";

export default function SearchSelect(props) {
	const {
		appLicenses: { appLicensesSummary },
	} = useSelector((state) => state.AppLicensesReducer);
	const activeLicenses = appLicensesSummary?.data?.filter(
		(license) => license?.contract_status === "active"
	);

	const [searchData, setSearchData] = useState("");
	const [selectedOptions, setSelectedOptions] = useState(
		activeLicenses?.filter((option) =>
			props?.selectedIds?.includes(option?.license_id)
		) ?? []
	);
	const appId = getSegmentFromURL(2);
	const dispatch = useDispatch();

	const tableRenderKey =
		apiKeyAndOtherData[props?.component?.headKey ?? "licenses"];
	const bulkEditRenderKey = apiKeyAndOtherData[props?.option?.headKey];
	const bulkEditApiKey = props?.option?.bulkEditKey;

	const isSelected = (selectedOptions, option) => {
		return selectedOptions.some(
			(selectedOption) => selectedOption.license_id === option.license_id
		);
	};

	const handleOptionToggle = (option) => {
		if (isSelected(selectedOptions, option)) {
			setSelectedOptions(
				selectedOptions.filter(
					(selectedOption) =>
						selectedOption?.license_id !== option?.license_id
				)
			);
		} else {
			setSelectedOptions([...selectedOptions, option]);
		}
	};

	const filteredOptions = activeLicenses?.filter((option) =>
		option?.license_name?.toLowerCase().includes(searchData?.toLowerCase())
	);

	const handleSave = async (selectedValue) => {
		await customApi[props?.component?.pathApiKey]({
			selectedValue,
			props,
			dispatch,
			appId,
			bulkEditApiKey,
			isEditing: props?.selectedIds?.length,
		});
	};

	const upadtingEditLicense = (license) => {
		props?.setCloseDropdown(true);
		props?.bulkEditProps?.optionClicked();
		dispatch(setLicenseData(license));
	};

	return (
		<div className="p-1 w-100">
			<SearchBox
				isCollapsible={false}
				onChangeText={(e) => setSearchData(e.target.value)}
			/>
			<div
				className="overflow-auto d-flex flex-column gap-2"
				style={{
					height: activeLicenses?.length < 3 ? "100%" : "20vh",
				}}
			>
				{filteredOptions?.map((lic, idx) => (
					<div
						key={idx}
						className={`d-flex justify-content-between align-items-center w-100 gap-2 border-radius-8 cursor-pointer select_options ${
							isSelected(selectedOptions, lic) &&
							"search_selected"
						}`}
						style={{
							padding: "6px",
						}}
						onClick={() => handleOptionToggle(lic)}
					>
						<div className="d-flex flex-column gap-2">
							<Typography
								variant={BODY_VARIANT.BODY_2_BOLD}
								color={GREY_VARIANT.SECONDARY_GREY_2}
							>
								{handleEllipseText(
									lic[
										(tableRenderKey ?? bulkEditRenderKey)
											.license_name
									] ?? "-",
									lic[
										(tableRenderKey ?? bulkEditRenderKey)
											.license_name
									],
									"100px"
								)}
							</Typography>
							<Typography
								variant={BODY_VARIANT.BODY_3_MEDIUM}
								color={GREY_VARIANT.SECONDARY_GREY_2}
							>
								{handleEllipseText(
									lic[
										(tableRenderKey ?? bulkEditRenderKey)
											.contract_name
									],
									lic[
										(tableRenderKey ?? bulkEditRenderKey)
											.contract_name
									],
									"100px"
								)}
							</Typography>
						</div>
						<div className="d-flex gap-4 w-100 justify-content-end">
							<div className="w-80 d-flex justify-content-end align-items-center">
								<Typography
									variant={BODY_VARIANT.BODY_2_MEDIUM}
									color={GREY_VARIANT.SECONDARY_GREY_3}
								>
									{spendsCurrencyFormat(
										lic[
											(
												tableRenderKey ??
												bulkEditRenderKey
											).rightHeadData
										] ?? 0
									)}
								</Typography>
								<Typography
									variant={BODY_VARIANT.BODY_2_REGULAR}
									color={GREY_VARIANT.SECONDARY_GREY_3}
								>
									/month
								</Typography>
							</div>
							<div className="w-10">
								{isSelected(selectedOptions, lic) && (
									<img src={tick} alt="" />
								)}
							</div>
						</div>
					</div>
				))}
			</div>
			<FooterAction
				selectedOptions={selectedOptions}
				setSelectedOptions={setSelectedOptions}
				handleSave={handleSave}
				upadtingEditLicense={upadtingEditLicense}
				bulkEditProps={props?.bulkEditProps}
				hidePopup={props?.hidePopup}
			/>
		</div>
	);
}

const FooterAction = ({
	selectedOptions,
	setSelectedOptions,
	handleSave,
	upadtingEditLicense,
	bulkEditProps,
	hidePopup,
}) => {
	return (
		<div className="z-v2-filter__footer w-100">
			<Button
				theme={theme}
				variant="primary-alternative"
				style={{ minWidth: 0, width: "100%" }}
				onClick={() => {
					hidePopup && hidePopup();
					setSelectedOptions([]);
				}}
			>
				Cancel
			</Button>
			<Button
				theme={theme}
				variant="primary-default"
				className="w-100"
				style={{ minWidth: 0, width: "100%" }}
				onClick={() => {
					bulkEditProps?.optionClicked
						? upadtingEditLicense(selectedOptions)
						: handleSave(selectedOptions);
				}}
				disabled={isEmpty(selectedOptions)}
			>
				Save
			</Button>
		</div>
	);
};
