import React, { useCallback, useContext, useState } from "react";
import { Typography, BODY_VARIANT, Tooltip, toast } from "@zluri/ui-components";
import {
	Button,
	ConfirmPopup,
	GREY_VARIANT,
	SUB_HEADING_VARIANT,
	theme,
	Popover,
} from "@zluri/ui-components";
import downArrowIcon from "assets/icons/Icon_Down_Filter.svg";
import { deleteCustomView } from "services/api/viewsv2";
import { TriggerIssue } from "utils/sentry";
import Icon from "containers/v2table/AppliedFilters/Views/Icons";
import cn from "classnames";
import "./views.css";
import SaveAsNewViewForm from "./SaveAsNewViewForm";
import PropTypes from "prop-types";

function ViewSelector({
	customViews = [],
	standardViews = [],
	activeView,
	onSelect,
	appId,
	refetch,
	defaultViewId,
}) {
	return (
		<div className="hide-scrollbar flex-1" style={{ overflow: "scroll" }}>
			{(standardViews?.length ?? 0) > 0 && (
				<Accordion>
					<Summary title="Standard Views" />
					<Content>
						<div
							className="flex flex-column gap-2"
							style={{ padding: 2 }}
						>
							{standardViews?.map((d) => {
								return (
									<Cell
										key={d._id}
										title={d.name}
										isActive={activeView === d._id}
										isDefaultView={defaultViewId === d._id}
										isGlobalView={d.is_global || !d.user_id}
										isOrganizationDefault={d.is_org_default}
										description={d.description}
										handleViewSelect={() => onSelect(d)}
										viewId={d._id}
										appId={appId}
										refetch={refetch}
										isStandardView
									/>
								);
							})}
						</div>
					</Content>
				</Accordion>
			)}
			{(customViews.length ?? 0) > 0 && (
				<Accordion>
					<Summary title="Custom Views" />
					<Content>
						<div
							className="flex flex-column gap-2"
							style={{ padding: 2 }}
						>
							{customViews?.map((d) => {
								return (
									<Cell
										key={d._id}
										title={d.name}
										isActive={activeView === d._id}
										isDefaultView={defaultViewId === d._id}
										isGlobalView={d.is_global || !d.user_id}
										isOrganizationDefault={d.is_org_default}
										description={d.description}
										handleViewSelect={() => onSelect(d)}
										appId={appId}
										refetch={refetch}
										viewId={d._id}
									/>
								);
							})}
						</div>
					</Content>
				</Accordion>
			)}
		</div>
	);
}

const AccordionContext = React.createContext({ open: false });
function Accordion({ children }) {
	const [open, setOpen] = useState(true);
	const onToggle = useCallback((bool) => {
		setOpen((_o) => bool ?? !_o);
	}, []);
	return (
		<AccordionContext.Provider value={{ open, onToggle }}>
			{children}
		</AccordionContext.Provider>
	);
}
Accordion.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.element,
		PropTypes.arrayOf(PropTypes.element),
	]).isRequired,
};
const useAccordionContext = () => {
	const context = useContext(AccordionContext);

	if (!context) {
		throw new Error(
			"You need to use useAccordionContext hook inside Accordion Provider"
		);
	}
	return context;
};
function Summary({ title }) {
	const { onToggle, open } = useAccordionContext();
	return (
		<div onClick={() => onToggle()}>
			<div
				style={{
					backgroundColor: "#F6F7FA",
					padding: "4px 10px",
					gap: "4px",
					justifyContent: "space-between",
					display: "flex",
					cursor: "pointer",
				}}
			>
				<Typography variant="body_2_medium" color="secondary_grey_1">
					{title}
				</Typography>
				<img
					style={{
						transform: open ? "rotate(180deg)" : "rotate(0deg)",
						transition: "transform 0.3s ease-out",
					}}
					height={16}
					width={16}
					src={downArrowIcon}
					alt="accordion arrow"
				/>
			</div>
		</div>
	);
}

Summary.propTypes = {
	title: PropTypes.string.isRequired,
};
function Content({ children }) {
	const { open } = useAccordionContext();

	return (
		<div
			style={{
				overflow: "hidden",
				maxHeight: open ? "1200px" : "0px",
				transition: "max-height 0.3s ease-out",
			}}
		>
			{children}
		</div>
	);
}
Content.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.element,
		PropTypes.arrayOf(PropTypes.element),
	]).isRequired,
};
function Cell({
	isActive = false,
	title = "",
	description = "",
	handleViewSelect,
	isDefaultView = false,
	appId,
	refetch,
	viewId,
	isStandardView,
	isGlobalView,
	isOrganizationDefault,
}) {
	const [openSaveAsNewViewPopup, setOpenSaveAsNewViewPopup] = useState(false);
	const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);

	const isSelected = openSaveAsNewViewPopup || openDeleteConfirm;
	return (
		<>
			<div onClick={handleViewSelect}>
				<div
					className={cn(
						`z__view-selector-cell`,
						isActive && "z__view-selector-cell-active",
						isSelected && "z__view-selector-cell-selected"
					)}
				>
					<div className="d-flex gap-8 align-items-center flex-1 overflow-hidden">
						<div className="d-flex align-items-start">
							<Icon
								name={isStandardView ? title : "custom-view"}
							/>
						</div>
						<div className="flex-1 d-flex flex-column overflow-hidden flex-1">
							<div className="d-flex gap-4">
								<Typography
									variant={
										isActive
											? BODY_VARIANT.BODY_2_BOLD
											: BODY_VARIANT.BODY_2_MEDIUM
									}
									color="#222"
									style={{
										textOverflow: "ellipsis",
										overflow: "hidden",
										textWrap: "nowrap",
									}}
								>
									{title}
								</Typography>
								{isDefaultView && (
									<div className="z__view-selector-defaultview-indicator">
										<Typography
											variant={BODY_VARIANT.BODY_3_MEDIUM}
											color="#1176BE"
											className="text-nowrap"
										>
											Default
										</Typography>
									</div>
								)}
							</div>

							<p className="z__view-selector-description">
								{description}
							</p>
						</div>
						<div className="d-flex flex-column align-items-end">
							<div
								className="z__view-selector-cell-action"
								onClick={(e) => e.stopPropagation()}
							>
								<Popover
									placement="right"
									trigger={["click"]}
									show={openSaveAsNewViewPopup}
									onToggle={setOpenSaveAsNewViewPopup}
									header={
										<Typography
											variant="body_1_bold"
											color="secondary_grey_2"
											style={{ flex: 1 }}
										>
											Configure View
										</Typography>
									}
									content={
										<div
											className="z__view-save-as-new-form"
											onClick={(e) => e.stopPropagation()}
										>
											<SaveAsNewViewForm
												refetch={refetch}
												defaultValue={{
													viewId,
													appId,
													title,
													description,
													isStandardView,
													isOrganizationDefault,
													isGlobalView,
													isMyDefault: isDefaultView,
												}}
												onSave={handleViewSelect}
												onCancel={() =>
													setOpenSaveAsNewViewPopup(
														false
													)
												}
											/>
										</div>
									}
								>
									<TextTooltip title="Configure View">
										<button
											className={cn(
												"z__view-selector-cell-action-button",
												openSaveAsNewViewPopup &&
													"z__view-selector-cell-action-button-selected"
											)}
											type="button"
											onClick={(e) => {
												e.stopPropagation();
												setOpenSaveAsNewViewPopup(true);
											}}
										>
											<Icon name="cog" />
										</button>
									</TextTooltip>
								</Popover>
								{!isStandardView && (
									<TextTooltip title="Delete View">
										<button
											className="z__view-selector-cell-action-button"
											type="button"
											onClick={(e) => {
												e.stopPropagation();
												setOpenDeleteConfirm(true);
											}}
										>
											<Icon name="delete" />
										</button>
									</TextTooltip>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>

			<div
				className="z__view-selector-popup"
				onClick={(e) => {
					e.stopPropagation();
				}}
			>
				<DeletePopup
					open={openDeleteConfirm}
					onCancel={() => setOpenDeleteConfirm(false)}
					viewId={viewId}
					onSave={() => {
						setOpenDeleteConfirm(false);
					}}
					viewName={title}
					refetch={refetch}
					isGlobalView={isGlobalView}
				/>
			</div>
		</>
	);
}
Cell.propTypes = {};
function DeletePopup({
	open,
	onCancel,
	onSave,
	viewId,
	refetch,
	isGlobalView,
	viewName,
}) {
	const [isDeleting, setIsDeleting] = useState(false);

	async function onDelete(viewId) {
		try {
			setIsDeleting(true);
			await deleteCustomView(viewId);
			await refetch();
			toast(`"${viewName}" deleted successfully`);
			onSave();
		} catch (error) {
			TriggerIssue("Error in deleting custom view", error);
		}
	}
	const ConfirmPopupHeader = (
		<div
			className="d-flex gap-8 align-items-center "
			style={{ padding: 10, background: "#F6F7FA", textAlign: "left" }}
		>
			<Icon name="exclamation" color="#FE6955" />
			<div className="flex-1">
				<Typography
					theme={theme}
					variant={SUB_HEADING_VARIANT.SUBHEADING_1_BOLD}
					color={GREY_VARIANT.SECONDARY_GREY_1}
					style={{ textAlign: "left" }}
				>
					Are you sure you want to delete the view?
				</Typography>
			</div>
			<div className="cursor-pointer" onClick={onCancel}>
				<Icon name="close" />
			</div>
		</div>
	);
	const ConfirmPopupText = (
		<div>
			<div
				className="d-flex flex-column justify-content-start "
				style={{ padding: 10 }}
			>
				<Typography
					variant={BODY_VARIANT.BODY_2_REGULAR}
					color={GREY_VARIANT.SECONDARY_GREY_2}
					theme={theme}
					style={{ textAlign: "left" }}
				>
					You are about to delete view{" "}
					{isGlobalView ? "that is shared with all users." : "."}
					This action can not be reverted
				</Typography>
			</div>
			<div className="flex  gap-10" style={{ padding: 10 }}>
				<Button
					variant={"primary-alternative"}
					theme={theme}
					onClick={() => {
						onCancel();
					}}
					className="flex-1"
				>
					Close
				</Button>
				<Button
					variant={"primary-danger"}
					theme={theme}
					className="flex-1"
					onClick={() => {
						onDelete(viewId);
					}}
					loading={isDeleting}
				>
					Delete View
				</Button>
			</div>
		</div>
	);

	return (
		<ConfirmPopup
			dialogClassName="z__view_delete-popup"
			headerClassName="confirm_popover_header"
			subHeaderClassName="confirm_popover_text"
			size="sm"
			showConfirmPopup={open}
			headerText={ConfirmPopupHeader}
			subHeaderText={ConfirmPopupText}
		/>
	);
}
DeletePopup.propTypes = {
	open: PropTypes.bool,
	onCancel: PropTypes.func,
	onSave: PropTypes.func,
	viewId: PropTypes.string,
	refetch: PropTypes.func,
	isGlobalView: PropTypes.bool,
	viewName: PropTypes.string,
};

function TextTooltip({ title, children }) {
	return (
		<Tooltip
			placement="top"
			delay={{ show: 250, hide: 400 }}
			content={(props) => (
				<Typography variant="body_2_regular" color="white">
					{title}
				</Typography>
			)}
			trigger={["hover"]}
		>
			{children}
		</Tooltip>
	);
}

TextTooltip.propTypes = {
	title: PropTypes.string,
	children: PropTypes.oneOfType([
		PropTypes.element,
		PropTypes.arrayOf(PropTypes.element),
	]).isRequired,
};
export default ViewSelector;
