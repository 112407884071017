import React from "react";
import { DotLottiePlayer } from "@dotlottie/react-player";
import lottieFile from "./loader.json";

export function LoaderPage() {
	return (
		<div
			className="d-flex justify-content-center align-items-center"
			style={{ height: "100vh" }}
		>
			<div style={{ width: "100px" }}>
				<DotLottiePlayer
					src={lottieFile}
					autoplay
					loop
				></DotLottiePlayer>
			</div>
		</div>
	);
}
