import {
	getAutomationsPlaybookIdFilterMetaData,
	getRulesFilterDatawithPlaybookId,
} from "modules/applications/components/automation/utils/automation.util";
import {
	editWorkflowDetails,
	getAllRulesV2,
} from "modules/workflow/service/api";
import React, { useEffect, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { useHistory } from "react-router";
import { TriggerIssue } from "utils/sentry";
import info from "assets/icons/info-orange.svg";
import { Loader } from "common/Loader/Loader";
import redalert from "assets/agents/inactive.svg";
import "./unpublishedplaybookmodal.css";
import { Link } from "react-router-dom";
import moment from "moment";
import { ApiResponseNotification } from "modules/shared/components/ApiResponseNotification/ApiResponseNotification";
import { apiResponseTypes } from "modules/shared/components/ApiResponseNotification/ApiResponseNotificationConstants";
import { getSegmentFromURL } from "@zluri/ui-components";

const UnpublishPlaybookModal = (props) => {
	const history = useHistory();
	const appId = getSegmentFromURL(2);
	const [isEditing, setIsEditing] = useState(false);
	const [isLoading, setisLoading] = useState(false);
	const [rules, setRules] = useState([]);
	function getRules() {
		const rulesMetadata = getRulesFilterDatawithPlaybookId(
			props?.data?.workflow_id || props?.data?._id || props?.data?.id
		);
		getAllRulesV2(0, 30, rulesMetadata, props?.data?.type)
			.then((res) => {
				setRules(res.data);
				setisLoading(false);
			})
			.catch((err) => {
				setisLoading(false);
				ApiResponseNotification({
					title: "Error in getting Rules data",
					responseType: apiResponseTypes.ERROR,
					errorObj: err,
				});
			});
	}
	useEffect(() => {
		setisLoading(true);
		getRules();
	}, [props?.data]);
	const editAndUnpublishPlaybook = () => {
		setIsEditing(true);
		editWorkflowDetails(
			props?.data?.workflow_id || props?.data?._id || props?.data?.id,
			{
				is_published: !props.data?.is_published,
			},
			true
		)
			.then((res) => {
				setIsEditing(false);
				if (res?.is_mini_playbook) {
					// props.entity !== "appPlaybooks"
					history.push(`/applications/${appId}/playbook/${res._id}`);
				} else {
					history.push(`/playbook/${res._id}`);
				}
				if (props.onClose) {
					props.onClose();
				}
			})
			.catch((err) => {
				setIsEditing(false);
				TriggerIssue("Error in unpublish playbook", err, "workflows");
			});
	};

	return (
		<>
			<Modal
				show={props.show}
				onHide={() => props.onHide}
				style={{ maxWidth: "50%!important" }}
				centered
				className="custom-map-modal"
			>
				{isLoading && (
					<div
						className=" d-flex justify-content-center align-items-center"
						style={{ height: "270px" }}
					>
						<Loader height={100} width={100} />
					</div>
				)}
				{!isLoading && (
					<>
						{Array.isArray(rules) && rules.length === 0 && (
							<>
								<Modal.Header className="pb-1">
									<Modal.Title className=" mt-4 text-center">
										<img
											height={40}
											width={40}
											alt=""
											src={info}
										/>
									</Modal.Title>
								</Modal.Header>
								<Modal.Body className="bg-white rounded-top">
									<div className="px-5">
										<div className="d-flex mt-4 flex-column align-items-center">
											<p className="font-18 bold-600 text-align-center">
												This playbook will be
												unpublished
											</p>
											<p
												style={{
													textAlign: "center",
													color: "#717171",
													fontFamily: "Sora",
												}}
											>
												To enable editing, this playbook
												will be unpublished. You can
												publish it once editing is
												complete.
											</p>
										</div>
									</div>
								</Modal.Body>
								<hr></hr>
							</>
						)}
						{Array.isArray(rules) && rules.length > 0 && (
							<>
								<Modal.Header className="pb-1">
									<Modal.Title className=" mt-4 text-center">
										<img
											height={40}
											width={40}
											src={redalert}
											alt=""
										/>
									</Modal.Title>
								</Modal.Header>
								<Modal.Body className="bg-white rounded-top">
									<div className="px-5">
										<div className="d-flex mt-4 flex-column align-items-center">
											<p className="font-18 bold-600 text-align-center">
												This playbook is already in use
												in {rules.length} active rules,
												editing the playbook might
												hamper the execution of rules
											</p>
											<p
												className="font-10 bold-500 text-align-center"
												style={{
													color: "#FE6955",
													background: "#FFE9E5",
													lineHeight: "16px",
													width: "80%",
												}}
											>
												Unpublised playbooks can not get
												triggered from a rule until
												published again
											</p>
											<div className="d-flex w-100 justify-content-evenly rule_details_table">
												<p
													style={{
														width: "50%",
													}}
												>
													Rule Name
												</p>
												<p
													style={{
														width: "20%",
													}}
												>
													Last Run
												</p>
												<p
													style={{
														width: "20%",
													}}
												>
													Total runs
												</p>
												<p
													style={{
														width: "10%",
													}}
												></p>
											</div>
											<hr
												style={{
													width: "100%",
													marginTop: "0px",
												}}
											></hr>
											<div
												style={{
													width: "100%",
													maxHeight: "160px",
													overflowY: "auto",
												}}
											>
												{rules.map((rule) => (
													<>
														<div
															className="d-flex w-100 justify-content-evenly rule_details_table"
															key={rule._id}
														>
															<p
																style={{
																	width: "50%",
																}}
															>
																<span className="green-dot"></span>
																{rule.name}
															</p>
															<p
																style={{
																	width: "20%",
																}}
															>
																{rule.last_run
																	? moment(
																			new Date(
																				rule.last_run
																			)
																		).format(
																			"DD MMM YYYY"
																		)
																	: "NA"}
															</p>
															<p
																style={{
																	width: "20%",
																}}
															>
																{rule.total_runs ||
																	0}
															</p>
															<Link
																to={`/workflow/rule/${rule._id}`}
																target="_blank"
																rel="noopener noreferrer"
															>
																View
															</Link>
														</div>
														<hr
															style={{
																width: "100%",
																marginTop:
																	"0px",
															}}
														></hr>
													</>
												))}
											</div>
										</div>
									</div>
								</Modal.Body>
							</>
						)}

						<Modal.Footer className="border-top">
							<Button variant="link" onClick={props.onHide}>
								Cancel
							</Button>
							<Button
								className="z-button-primary px-4"
								size="lg"
								onClick={() => {
									editAndUnpublishPlaybook();
								}}
								disabled={isEditing}
							>
								{isEditing && (
									<Spinner
										className="mr-2 mb-1 blue-spinner action-edit-spinner"
										animation="border"
									/>
								)}
								Unpublish and Edit
							</Button>
						</Modal.Footer>
					</>
				)}
			</Modal>
		</>
	);
};

export default UnpublishPlaybookModal;
