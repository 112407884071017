import React from "react";
import NoAuthorization from "../../assets/rbac/No_Authorization.svg";
import rbacContactSupportArrow from "../../assets/rbac/rbac_contact_support_arrow.svg";
import "./RbacError.css";
const RbacErrorComponent = ({
	size,
	backgroundColor,
	style,
	title,
	showIcon = true,
}) => {
	return (
		<div
			className="rbac__error-wrapper"
			style={{
				backgroundColor: !backgroundColor ? "white" : backgroundColor,
				...style,
				position: "relative",
			}}
		>
			{title && (
				<h6
					style={{
						position: "absolute",
						left: "10px",
						top: "10px",
						fontSize:
							size === "widget" || size === "small"
								? "10px"
								: size === "medium"
									? "13px"
									: "15px",
						color: "#717171",
					}}
				>
					{title.toUpperCase()}
				</h6>
			)}
			{showIcon ? (
				<img
					className={
						size ? `rbac__error-img-${size}` : "rbac__error-img"
					}
					src={NoAuthorization}
					alt="NoAuthorization"
				/>
			) : null}
			<h6
				className={
					size ? `rbac__error-heading-${size}` : "rbac__error-heading"
				}
			>
				You are not authorized to view this Information
			</h6>
			<p
				className={
					size ? `rbac__error-para-${size}` : "rbac__error-para"
				}
			>
				{size === "widget"
					? "You are not authorized to view this Information"
					: "Please reach out to your administrator to grant you additional permission"}
			</p>
			<button
				className={
					size
						? `rbac__error-contact-support-button-${size}`
						: "rbac__error-contact-support-button"
				}
			>
				<a
					style={{ textDecoration: "none" }}
					target="_blank"
					rel="noreferrer"
					href="https://support.zluri.com"
				>
					Contact Support
				</a>
				{!size ||
					(size !== "medium" && (
						<img width="8px" src={rbacContactSupportArrow} />
					))}
			</button>
		</div>
	);
};
export default RbacErrorComponent;
