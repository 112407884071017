import {
	addLicenseRequest,
	updateRequestLicense,
} from "services/api/employeeDashboard";

export const employeeConstants = {
	SET_THEME: "SET_THEME",
	SET_ICON: "SET_ICON",
	SET_ICON_LOADING: "SET_ICON_LOADING",
	SET_ICONS: "SET_ICONS",
	SET_REQUEST_LICENSE: "SET_REQUEST_LICENSE",
};

export const themeHeadings = {
	navigation_background: "Navigation Background",
	active_selection: "Active Selection",
	text: "Text",
	hover_item: "Hover Item",
	active_menu_text: "Active Menu Text",
};

export const updateKeys = {
	cost_per_item: "Cost",
	subscription_duration: "Subscription Details",
	license_name: "License Name",
	users: "Users",
	need_more_licenses: "Need More Licenses",
	quantity: "Licenses Count",
};

export const requestTypes = {
	ADD_REQUEST: "add",
	EDIT_REQUEST: "edit",
	ADD_CUSTOM_REQUEST: "custom",
};

export const requestLicenseHeaderAndApiConstants = {
	[requestTypes.ADD_REQUEST]: {
		headerTitle: "New Request",
		confirmString: "Confirm Request",
		requestApi: addLicenseRequest,
	},
	[requestTypes.EDIT_REQUEST]: {
		headerTitle: "Edit Request",
		confirmString: "Edit Request",
		requestApi: updateRequestLicense,
	},
	[requestTypes.ADD_CUSTOM_REQUEST]: {
		headerTitle: "New Application Request",
		confirmString: "Confirm Request",
		requestApi: addLicenseRequest,
	},
};

export const ON_APPROVAL = "on_approval";
export const ON_OFFBOARDING = "on_offboarding";

export const Actions = {
	PROVISION: "provision",
	DEPROVISION: "deprovision",
};
export const constants = {
	RUN_A_PLAYBOOK: "Run a Playbook",
	ASSIGN_TASK: "Assign a Task to Someone",
	PLAYBOOK: "playbook",
	TASK: "task",
};
