import React from "react";

const IconLabel = ({ iconLabel, style, className }) => {
	const { icon, label, bg, color, size, fontSize } = iconLabel;
	return (
		<div
			className={`d-flex align-items-center ${className}`}
			style={{
				width: "fit-content",
				background: bg,
				color: color,
				borderRadius: "50px",
				padding: "0px 2px",
				...style,
			}}
		>
			{icon && (
				<img src={icon} width={size || 16} height={size || 16} alt="" />
			)}
			{label && (
				<span className="ml-1 font-11 bold-500" style={{ fontSize }}>
					{label}
				</span>
			)}
		</div>
	);
};

export default IconLabel;
