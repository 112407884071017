import React, { useState } from "react";
import styled from "styled-components";
import {
	Typography,
	BLUE_VARIANT,
	Popover,
	Divider,
} from "@zluri/ui-components";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import OptionsIcon from "modules/transactionMapping/assets/rulesPage/OptionsIcon";
import { useSelector } from "react-redux";
import { changeRulePriority } from "modules/transactionMapping/services/transactionMapping.api";
import DeleteRuleModal from "modules/transactionMapping/components/Modal/DeleteRuleModal";
import { deleteRule } from "modules/transactionMapping/services/transactionMapping.api";

const EditWrapper = styled.div`
	border-radius: 8px;
	background: var(--Primary_Blue_Light-3, #e8f0fc);
	display: flex;
	height: 24px;
	padding: 4px 8px;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	&:hover {
		border: 1px solid #2266e2;
	}
	border: ${({ $active }) => ($active ? "1px solid #2266e2" : "")};
`;

const OptionsRendererWrapper = styled.div`
	display: flex;
	gap: 10px;
`;

const OptionWrapper = styled.div`
	padding: 8px;
	cursor: pointer;
	border-radius: 6px;
	&:hover {
		border: 1px solid var(--400, #b1c8f3);
		background: var(--100, #e8f0fc);
	}
`;

const OptionsRenderer = ({ data }) => {
	const history = useHistory();

	const [showDeleteModal, setShowDeleteModal] = useState(null);
	const [popoverOpen, setPopoverOpen] = useState(false);
	const [actionLoading, setActionLoading] = useState(false);
	const count = useSelector(
		(state) => state.v2Table["spends_rules"]["count"]
	);

	const changePriorityClicked = ({ currentOrder, newOrder }) => {
		if (!actionLoading) {
			setActionLoading(true);
			changeRulePriority({
				current_order: currentOrder,
				new_order: newOrder,
				ruleId: data._id,
				tag: "transaction",
			}).then(() => {
				setActionLoading(false);
				window.open(
					`${
						window.origin
					}/spends/automation?showToast=${JSON.stringify({
						message: `${data.name} is ${
							newOrder === 0 ? "moved to first" : "moved to last"
						}`,
					})}`,
					"_self"
				);
			});
		}
		setPopoverOpen(false);
	};

	return (
		<>
			<OptionsRendererWrapper>
				<EditWrapper
					onClick={() => {
						history.push(`/spends/automation/edit/${data._id}`);
					}}
				>
					<Typography
						variant="button_extrasmall_regular"
						color={BLUE_VARIANT.PRIMARY_BLUE_DARK_1}
					>
						Edit
					</Typography>
				</EditWrapper>
				<div style={{ cursor: "pointer" }}>
					<Popover
						trigger={["click"]}
						show={popoverOpen}
						onToggle={() => setPopoverOpen((state) => !state)}
						content={
							<>
								<OptionWrapper
									onClick={() => {
										if (!actionLoading) {
											if (data.status !== "draft") {
												setShowDeleteModal(true);
											} else {
												setActionLoading(true);
												setPopoverOpen(false);
												deleteRule({
													ruleId: data._id,
													tag: "transaction",
												}).then(() => {
													setActionLoading(false);
													window.open(
														`${
															window.origin
														}/spends/automation?showToast=${JSON.stringify(
															{
																message: `${data.name} is deleted successfully`,
															}
														)}`,
														"_self"
													);
												});
											}
										}
									}}
								>
									<Typography variant="button_extrasmall_medium">
										Delete Rule
									</Typography>
								</OptionWrapper>
								<Divider
									height={1}
									backgroundColor="#EBEBEB"
									style={{
										marginLeft: "4px",
										marginRight: "4px",
									}}
								/>
								{data.priority_order === 0 ? null : (
									<OptionWrapper
										onClick={() => {
											changePriorityClicked({
												newOrder: 0,
												currentOrder:
													data.priority_order,
											});
										}}
									>
										<Typography variant="button_extrasmall_medium">
											Move rule to first
										</Typography>
									</OptionWrapper>
								)}
								{data.priority_order >= count - 1 ? null : (
									<OptionWrapper
										onClick={() => {
											changePriorityClicked({
												newOrder: count - 1,
												currentOrder:
													data.priority_order,
											});
										}}
									>
										<Typography variant="button_extrasmall_medium">
											Move rule to last
										</Typography>
									</OptionWrapper>
								)}
							</>
						}
					>
						<EditWrapper
							style={{ padding: "4px 6px" }}
							$active={popoverOpen}
						>
							<OptionsIcon />
						</EditWrapper>
					</Popover>
				</div>
			</OptionsRendererWrapper>
			{showDeleteModal ? (
				<DeleteRuleModal
					closeHandler={() => setShowDeleteModal(null)}
					rule={data}
				/>
			) : null}
		</>
	);
};

export default OptionsRenderer;

OptionsRenderer.propTypes = {
	data: PropTypes.object,
};
