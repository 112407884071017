import React from "react";
import { toast } from "react-toastify";
import { TriggerIssue } from "utils/sentry";
import greenTick from "assets/green_tick.svg";
import inactive from "assets/agents/inactive.svg";
import { apiResponseTypes } from "./ApiResponseNotificationConstants";
import DefaultNotificationCard from "common/Notification/PusherNotificationCards/DefaultNotificationCard";
import ToastNotificaion from "UIComponents/Rbac/ToastNotification";
import { RBAC_ERROR, RBAC_ERROR_STATUS_CODES } from "constants/rbac";

export const ApiResponseNotification = ({
	responseType,
	errorObj,
	title,
	description,
	icon,
	retry,
	children,
	tag,
	triggerSentry = true,
}) => {
	const error = errorObj;
	const rbacError =
		error?.response?.data?.type === RBAC_ERROR &&
		RBAC_ERROR_STATUS_CODES.includes(error?.response?.status);
	if (!rbacError) {
		toast(
			<DefaultNotificationCard
				notification={{
					title: title || "Records Updated",
					description:
						typeof description === "string"
							? description
							: responseType === apiResponseTypes.ERROR
								? "Server Error! We couldn't complete your request."
								: "The changes might take some time to reflect.",
					icon:
						icon || responseType === apiResponseTypes.ERROR
							? inactive
							: greenTick,
					retry: retry,
				}}
			>
				{children}
			</DefaultNotificationCard>
		);
		if (triggerSentry && responseType === apiResponseTypes.ERROR) {
			TriggerIssue(title, errorObj, tag);
		}
	}
	return null;
};
