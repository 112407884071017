import React, { useState } from "react";

import PropTypes from "prop-types";
import { isNull } from "underscore";

import { Divider } from "antd";
import {
	Button,
	Field,
	Loader,
	PrimaryButtonVariant,
	Typography,
	theme,
} from "@zluri/ui-components";
import { HeaderTabWithPopover } from "./Header";

import {
	useDetectTextChange,
	useStringValidation,
} from "modules/applications/containers/OverviewV2/customHooks";
import {
	LINK_ACTION,
	STRNG_REGEX_MAP,
} from "modules/applications/constants/OverviewV2.constants";

const EditLink = ({ link, id, loading, onClose, handleAdd, handleEdit }) => {
	const [value, setValue] = useState(link);

	const { changed } = useDetectTextChange(link, value);
	const { isInvalid, message } = useStringValidation(
		value,
		true,
		"link",
		STRNG_REGEX_MAP.EMAIL
	);

	const handleSave = () => {
		if (link && !isNull(id)) {
			handleEdit && handleEdit(value, id);
			onClose && onClose();
		} else {
			handleAdd && handleAdd(value);
			onClose && onClose();
		}
	};

	return (
		<span className="d-flex flex-column align-items-start padding_4 gap-2">
			<Field
				value={value}
				handlers={{ onChange: (e) => setValue(e.target.value) }}
				placeholder="Enter Link"
				isInvalid={changed && isInvalid}
				isValidated={!isInvalid && changed}
				message={{
					error: message,
				}}
			/>
			<Divider style={{ background: "#ebebeb", margin: "0" }} />
			<span className="d-flex align-items-center gap-4">
				<Button
					disabled={loading}
					theme={theme}
					variant={PrimaryButtonVariant.primary_alternative}
					onClick={() => onClose && onClose()}
				>
					Cancel
				</Button>
				<Button
					disabled={!changed || loading || isInvalid}
					onClick={handleSave}
					theme={theme}
					variant={PrimaryButtonVariant.primary_default}
				>
					{loading ? (
						<Loader color="blue" height={18} />
					) : (
						"Save Link"
					)}
				</Button>
			</span>
		</span>
	);
};

const EditLinkPopover = ({ link, id, appId, onClose, ...props }) => {
	const [show, setShow] = useState(false);

	const handleClose = () => {
		setShow(false);
		onClose && onClose();
	};

	const handleOnClick = (tab) => {
		tab?.onClick?.({ link, id, ...props });
		tab?.closeOnDispatchOrClick && handleClose();
	};

	return (
		<div className="padding_2">
			{LINK_ACTION?.map((tab, idx) => (
				<HeaderTabWithPopover
					key={idx}
					hasPopover={tab?.content && true}
					popoverProps={{
						placement: "rightTop",
						content: (
							<>
								{tab?.content &&
									tab?.content({
										link,
										id,
										onClose: handleClose,
										...props,
									})}
							</>
						),
						className: tab?.className,
						show,
						onToggle: (open) => setShow(open),
					}}
					Component={
						<div
							className={`d-flex align-items-center gap-10 z_overview_header-action_option w-100 ${tab?.className}`}
							onClick={() => handleOnClick(tab)}
						>
							<img
								src={tab?.icon}
								width={16}
								height={16}
								alt=""
							/>
							<Typography>{tab?.text}</Typography>
						</div>
					}
				/>
			))}
		</div>
	);
};

export { EditLink, EditLinkPopover };

EditLink.propTypes = {
	link: PropTypes.string,
	id: PropTypes.string,
	onClose: PropTypes.func,
	handleAdd: PropTypes.func,
	handleEdit: PropTypes.func,
	loading: PropTypes.bool,
};

EditLinkPopover.propTypes = {
	link: PropTypes.string,
	id: PropTypes.string,
	onClose: PropTypes.func,
	appId: PropTypes.string,
};
