import React from "react";
import NumberPill from "UIComponents/NumberPill/NumberPill";
import { capitalizeFirstLetter } from "utils/common";
import tickMark from "assets/tick-mark.svg";
import { Dots } from "common/DottedProgress/DottedProgress";
import { Typography } from "@zluri/ui-components";
import { playbookOptimizationV3StatusOptions } from "modules/Optimization/constants/OptimizationConstants";

const statusColors = {
	active: {
		pillColor: "#009307",
		pillBackGround: "#E7F8E8",
	},
	inactive: {
		pillColor: "#BB2915",
		pillBackGround: "#FFE9E5",
	},
	suspended: {
		pillColor: "#222222",
		pillBackGround: "#ebebeb",
	},
	expired: {
		pillColor: "#BB2915",
		pillBackGround: "#FFE9E5",
	},
};

const StatusComponent = ({ value }) => (
	<div className="d-flex align-items-center">
		<NumberPill
			number
			pillBackGround={statusColors[value]?.pillColor}
			pillSize={8}
			style={{ marginRight: 4 }}
		/>
		<div>{capitalizeFirstLetter(value)}</div>
	</div>
);

export function AppStatus({ data, component, ...props }) {
	const value = data[component.valueKey];
	return (
		<div
			className="d-flex align-items-center"
			style={{
				height: 29,
				borderRadius: 8,
				background: statusColors[value]?.pillBackGround,
				width: "fit-content",
			}}
		>
			<div className="pl-2 py-1">
				<Dots color={statusColors[value]?.pillColor} size="7" />
			</div>
			<Typography
				variant="body_1_regular"
				style={{
					color: statusColors[value]?.pillColor,
				}}
				className="pl-1 pr-2"
			>
				{capitalizeFirstLetter(value)}
			</Typography>
		</div>
	);
}

export function PlaybookStatus({ data, component, ...props }) {
	const value = data[component.valueKey];
	return (
		<div
			className="d-flex align-items-center"
			style={{
				height: "29px",
				borderRadius: 8,
				background:
					playbookOptimizationV3StatusOptions[value]?.pillBackGround,
				width: "fit-content",
			}}
		>
			<Typography
				variant="body_1_regular"
				style={{
					color: playbookOptimizationV3StatusOptions[value]
						?.pillColor,
				}}
				className="pl-1 pr-2"
			>
				{capitalizeFirstLetter(value)}
			</Typography>
		</div>
	);
}

const options = [
	{
		label: "Active",
		id: "active",
	},
	{
		label: "Inactive",
		id: "inactive",
	},
];
export function AppStatusPopover({ data, component, ...props }) {
	const value = data[component.valueKey];
	const handleClick = (updatedValue) => {
		if (value === updatedValue) return;
		try {
			let patchObj = {
				patches: [
					{
						op: "replace",
						field: "status",
						value: updatedValue,
					},
				],
			};
			props.handleUpdate(data.app_id, patchObj, {
				app_status: updatedValue,
			});
		} catch (err) {
			console.error("Error updating application owner:", err);
		}
	};
	return (
		<div>
			<div
				className="flex static-select"
				style={{ flexDirection: "column" }}
			>
				{options.map((option, index) => (
					<div
						key={index}
						className="flex p-2 static-select-option cursor-pointer align-items-center justify-content-between"
						onClick={(e) => {
							handleClick(option.id);
						}}
					>
						<Typography
							variant="button_extrasmall_medium"
							className="mr-1"
						>
							{option.label}
						</Typography>
						{value === option.id.toLowerCase() && (
							<div>
								<img src={tickMark} alt="selected" />
							</div>
						)}
					</div>
				))}
			</div>
		</div>
	);
}
