import React from "react";
import PropTypes from "prop-types";
import { BUTTON_VARIANT, GREY_VARIANT, Typography } from "@zluri/ui-components";

import checkBlueIcon from "assets/check-filled.svg";

export function AppAboutSelectDropdown({
	options,
	checkOptionIsSelected,
	handleEdit,
	imageHeight,
	imageWidth,
}) {
	return (
		<div className="d-flex gap-2 app-about-popover-select-container padding_2">
			{Array.isArray(options) &&
				options?.map((option) => {
					return (
						<div
							key={option?.label}
							className={`d-flex app-about-select-container ${
								checkOptionIsSelected(option) && "blue_tint_bg"
							}`}
							onClick={() =>
								handleEdit && handleEdit(option?.value)
							}
						>
							<div className="d-flex app-about-select-container-left">
								{option?.icon && (
									<img
										src={option?.icon}
										alt={option?.label}
										height={imageHeight ?? 8}
										width={imageWidth ?? 8}
									/>
								)}
								<Typography
									color={GREY_VARIANT.SECONDARY_GREY_2}
									variant={
										BUTTON_VARIANT.BUTTON_EXTRA_SMALL_MEDIUM
									}
								>
									{option?.label}
								</Typography>
							</div>
							<div className="app-about-select-container-right">
								{checkOptionIsSelected(option) && (
									<img
										src={checkBlueIcon}
										alt="check"
										width={14}
										height={12}
									/>
								)}
							</div>
						</div>
					);
				})}
		</div>
	);
}

AppAboutSelectDropdown.propTypes = {
	options: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string.isRequired,
			icon: PropTypes.string,
			value: PropTypes.string.isRequired,
			scoreRange: PropTypes.arrayOf(PropTypes.string),
		})
	),
	checkOptionIsSelected: PropTypes.func,
	handleEdit: PropTypes.func,
	imageHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	imageWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
