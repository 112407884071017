import React, { useMemo } from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import { isEmpty, map } from "underscore";
import moment from "moment";

import { TOAST_COLOR, TabItem, toast } from "@zluri/ui-components";
import SourceTabList from "modules/applications/components/OverviewV2/SourceList/SourceTabList";
import TagList from "modules/applications/components/OverviewV2/SourceList/TagList";
import SourceTabHeader from "modules/applications/components/OverviewV2/SourceList/SourceTabHeader";
import { ChecklistCard } from "modules/applications/components/OverviewV2/CheckList/CheckListCard";
import CheckListHeader from "modules/applications/components/OverviewV2/CheckList/ChecklistHeader";
import HighChartsContainer from "modules/applications/components/OverviewV2/HighCharts/HighChartsContainer";

import { getAppNextEndingContract } from "modules/Application/utils/Application.utils";
import {
	fetchAppOverviewMetrics,
	setAppArchive,
} from "modules/applications/redux/Applications.actions";
import {
	archiveApplications,
	unArchiveApplications,
} from "services/api/applicationsv2";
import { getSourceSyncText } from "modules/applications/utils/Spends";
import {
	OVERVIEW_CHECKLIST_STATE,
	SOURCE_TABS,
} from "modules/applications/constants/OverviewV2.constants";
import {
	getCheckListCount,
	getOverviewActions,
	handleSearch,
	handleSort,
} from "modules/applications/utils/OverviewV2.utils";
import { fetchSingleApplicationBasicDetails } from "modules/Application/redux/Application.action";
import { TOAST_MESSAGE } from "modules/shared/constants/toast.constants";
import ErrorWrapper from "../../../../UIComponents/Rbac/ErrorWrapper";

const useOverviewMetric = (app, appId) => {
	const {
		app_active_users_via_direct_integration,
		active_app_user_count_via_all_sources,
		app_assigned_license_count,
		app_active_contracts,
		app_annualized_cost,
		app_cost,
		app_spend,
		active_app_user_count_diff,
		app_spend_diff,
		app_assigned_license_count_diff,
		optimizable_licenses_count,
		affected_license_count,
	} = app;
	const dispatch = useDispatch();

	const {
		data: { users, spends, licenses, contracts },
		loading,
		loaded,
		error,
	} = useSelector((state) => state.applications_v2.overview.metrics);

	const data = {
		users: {
			primary_active_users_count: app_active_users_via_direct_integration,
			active_users_count: active_app_user_count_via_all_sources,
			user_count_diff: active_app_user_count_diff || 0,
		},
		spends: {
			actual_spend: app_spend,
			annualized_spend: app_cost,
			actual_spend_diff: app_spend_diff || 0,
		},
		licenses: {
			assigned: app_assigned_license_count,
			optimizable: optimizable_licenses_count || 10,
			licenses_count_diff: app_assigned_license_count_diff || 0,
		},
		contracts: {
			upcoming_due_date:
				getAppNextEndingContract(app_active_contracts)?.end_date,
			affected_licenses: affected_license_count || 0,
			name:
				getAppNextEndingContract(app_active_contracts)?.name ||
				"No contract name available",
		},
	};
	useEffect(() => {
		if (app?.app_id) getOverviewMetrics();
	}, [app]);

	const getOverviewMetrics = () =>
		dispatch(fetchAppOverviewMetrics(appId, data));

	const refreshCallback = () =>
		dispatch(fetchSingleApplicationBasicDetails({ appId }));

	return {
		users,
		spends,
		licenses,
		contracts,
		loaded,
		loading,
		error,
		refreshCallback,
	};
};

const useAppArchiveAPI = (appId, setShow) => {
	const dispatch = useDispatch();

	const [updating, setUpdating] = useState(false);

	const { archived } = useSelector(
		(state) => state.applications_v2?.overview
	);

	const updateAppArchiveStatus = () => {
		setUpdating(true);
		!archived &&
			archiveApplications([appId]).then((res) => {
				setUpdating(false);
				dispatch(setAppArchive(true));
				setShow(false);
				if (res.error)
					toast(TOAST_MESSAGE.ARCHIVE_APPLICATION_ERROR, {
						indicatorColor: TOAST_COLOR.ERROR,
					});
				else
					toast(TOAST_MESSAGE.ARCHIVE_APPLICATION_SUCCESS, {
						indicatorColor: TOAST_COLOR.SUCCESS,
					});
			});
		archived &&
			unArchiveApplications([appId]).then((res) => {
				setUpdating(false);
				dispatch(setAppArchive(false));
				setShow(false);
				if (res.error)
					toast(TOAST_MESSAGE.UNARCHIVE_APPLICATION_ERROR, {
						indicatorColor: TOAST_COLOR.ERROR,
					});
				else
					toast(TOAST_MESSAGE.UNARCHIVE_APPLICATION_SUCCESS, {
						indicatorColor: TOAST_COLOR.SUCCESS,
					});
			});
	};
	return { updateAppArchiveStatus, updating, archived };
};

const useOverviewActionGenerator = (setShow, appId, app) => {
	const dispatch = useDispatch();
	const { updating, updateAppArchiveStatus, archived } = useAppArchiveAPI(
		appId,
		setShow
	);
	const { sourceSettings = {} } = useSelector(
		(state) => state.singleApplication?.[appId]?.sourceSettings || {}
	);
	const actions = map(
		getOverviewActions(
			app,
			archived,
			updateAppArchiveStatus,
			sourceSettings
		),
		(child) =>
			map(
				child,
				({
					action,
					subContent,
					dispatch: dispatchAction,
					...props
				}) => ({
					...props,
					onClick: () => {
						if (!action && !dispatchAction) return;
						dispatchAction ? dispatch(dispatchAction) : action();
						!subContent && setShow(false);
					},
					subContent: subContent
						? {
								...subContent,
								onCancel: () => setShow(false),
								loading: updating,
							}
						: null,
				})
			)
	);
	return { actions };
};

const useSourcesList = (sources) => {
	const tabs = map(sources, (source, idx) => ({
		key: idx,
		label: <SourceTabHeader {...source} />,
		children: (
			<TagList
				className="z_source_tab-tag_list"
				tags={source?.entities || []}
			/>
		),
		hasMetaData: source?.entities?.length,
		isConnected: getSourceSyncText(
			source?.updatedAt,
			source?.org_integration_status
		)?.isConnected,
	}));
	return { tabs };
};

const useSourcesTypes = (appId, searchQuery, selected, sort) => {
	const [sources, setSources] = useState({});
	const { loaded, loading, error, data } = useSelector(
		(state) => state.applications_v2.overview?.sources
	);

	useEffect(() => {
		if (data) setSources({ ...data });
	}, [data, error]);

	useEffect(() => {
		if (selected && searchQuery) {
			const searchList = handleSearch(
				"name",
				"keyword",
				sources[selected],
				data[selected],
				searchQuery
			);
			setSources({ ...sources, [selected]: [...searchList] });
		} else {
			setSources({ ...sources, ...data });
		}
	}, [searchQuery, selected]);

	useEffect(() => {
		if (sort && sources[selected]?.length) {
			const sorted_list = handleSort(
				"name",
				sources[selected],
				data[selected],
				sort
			);
			setSources({ ...sources, [selected]: [...sorted_list] });
		}
	}, [sort]);

	return {
		sources,
		loading,
		loaded,
		error,
		sortDisabled: sources?.[selected]?.length < 1,
	};
};

const useSourceTabList = (sources, connectIntegration) => {
	const tabList = map(SOURCE_TABS, ({ text, key }) => ({
		text,
		key,
		label: <TabItem text={text} count={sources[key]?.length || 0} />,
		children: (
			<SourceTabList
				sources={sources[key]}
				name={text}
				connectIntegration={connectIntegration}
			/>
		),
	}));
	return { tabs: tabList };
};

const useDetectTextChange = (text, changedValue) => {
	const [changed, setChanged] = useState(false);
	useEffect(() => {
		const val = text?.toString().trim() || "";
		const changedVal = changedValue?.toString().trim() || "";
		if (val !== changedVal) {
			setChanged(true);
		} else {
			setChanged(false);
		}
	}, [changedValue]);
	return { changed };
};

const useStringValidation = (string, required, name, regex) => {
	if (!string && required)
		return { isInvalid: true, message: `${name || "Field"} is required!` };
	if (!required) {
		return {
			isInvalid: false,
		};
	}
	if (regex) {
		const isValid = (string || "").match(regex);
		return {
			isInvalid: !isValid,
			message: !isValid && `${name || "Field value"} is invalid!`,
		};
	}
};

const useCheckListConfig = ({
	appId,
	config,
	apiConfig,
	setExpanded,
	expanded,
}) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const { basicDetails: app = {} } = useSelector(
		(state) => state.singleApplication?.[appId]?.basicDetails || {}
	);
	if (!isEmpty(apiConfig)) {
		return Object.keys(config)?.map((key) => {
			const val = config[key];
			const apiVal = apiConfig[key];
			const count = getCheckListCount(apiVal);
			const isExpanded = expanded?.includes(key);
			const children = val?.children?.map((child, idx) => {
				const apiV = apiVal[idx];
				const local_status = OVERVIEW_CHECKLIST_STATE[apiV?.status];
				const cta = child?.cta?.[local_status?.key];
				const icon =
					OVERVIEW_CHECKLIST_STATE[apiV?.status]?.icon || child?.icon;
				const isSkipped = apiV?.isSkipped;
				const isCompleted =
					local_status?.key ===
					OVERVIEW_CHECKLIST_STATE.COMPLETED.key;

				const action_click = () => {
					if (cta?.dispatch) dispatch(cta?.dispatch);
					if (cta?.redirect) cta?.redirect(appId);
					if (cta?.hash) history.push(cta?.hash);
					if (cta?.dispatchWithProps)
						dispatch(cta?.dispatchWithProps({ app, appId }));
				};

				return {
					...child,
					appId,
					...apiV,
					id: apiV?._id,
					cta: {
						...cta,
					},
					onClick: action_click,
					icon: icon,
					isSkipped,
					isCompleted,
					parentId: key,
				};
			});
			const cc = {
				...val,
				key: key,
				title: val?.text,
				pending: apiConfig?.[key]?.pending,
				errored_out: apiConfig?.[key]?.errored_out,
				children: [...children],
			};
			return {
				key: key,
				title: (
					<CheckListHeader
						isExpanded={isExpanded}
						expanded={expanded}
						headerText={cc?.title}
						id={cc?.key}
						pending={cc?.pending}
						erroredOut={cc?.errored_out}
						setExpanded={setExpanded}
						count={count}
					/>
				),
				children: children?.map((child, idx) => ({
					key: key + child?.title,
					title: <ChecklistCard appId={appId} key={idx} {...child} />,
				})),
			};
		});
	}
	return [];
};

const useNotesSort = () => {
	const { notes } = useSelector((state) => state.applications_v2?.overview);

	const [noteSorted, setNoteSorted] = useState(false);
	const [sortedNotes, setSortedNotes] = useState([]);

	useEffect(() => {
		if ((noteSorted && notes?.count > 0) || !sortedNotes.length) {
			const notesData = notes?.data ? [...notes?.data] : [];
			notesData?.sort((a, b) =>
				moment(b?.created_at ?? b?.createdAt).diff(
					moment(a?.created_at ?? a?.createdAt)
				)
			);
			setSortedNotes((prevNotes) => ({
				...prevNotes,
				data: notesData,
			}));
		}
	}, [notes, noteSorted]);

	const handleSortClick = () => {
		setNoteSorted((sort) => !sort);
	};

	return {
		notes: noteSorted ? sortedNotes : notes,
		sorted: noteSorted,
		handleSortClick,
	};
};

const useHighcartsTabConfig = ({
	tabConfig,
	loading,
	handleRefresh,
	splitBy,
}) => {
	const tabs = useMemo(
		() =>
			tabConfig?.map((c) => ({
				...c,
				label: c?.title,
				disabled: loading ? true : false,
				children: (
					<>
						{!isEmpty(c?.error) ? (
							<ErrorWrapper
								error={c?.error}
								refreshCall={handleRefresh && handleRefresh}
							/>
						) : (
							<HighChartsContainer
								loaded={c?.loaded}
								loading={c?.loading}
								error={c?.error}
								hasData={!isEmpty(c?.data)}
								chartOptions={c?.chartData}
								refreshCallBack={handleRefresh && handleRefresh}
								legends={c?.legends}
								splitBy={splitBy}
							/>
						)}
					</>
				),
			})),
		[tabConfig, loading, handleRefresh]
	);
	return { tabs };
};

export {
	useOverviewMetric,
	useAppArchiveAPI,
	useOverviewActionGenerator,
	useSourcesList,
	useSourcesTypes,
	useSourceTabList,
	useDetectTextChange,
	useCheckListConfig,
	useNotesSort,
	useStringValidation,
	useHighcartsTabConfig,
};
