import _ from "underscore";
import { offboardingDeclarationColumns } from "./offboarding";
import { OffboardingDashboardEmptyScreen } from "../components/OffboardingDashboardEmptyScreen/OffboardingDashboardEmptyScreen";
import NeedsReview from "../../../assets/workflow/needs-review.svg";
import Restricted from "../../../assets/workflow/restricted.svg";
import Unmanaged from "../../../assets/workflow/unmanaged.svg";
import schedule from "../../../assets/workflow/schedule.svg";
import scheduled from "../../../assets/workflow/scheduled.svg";
import approval from "../../../assets/workflow/approval.svg";
import approved from "../../../assets/workflow/approved.svg";
import immediately from "assets/workflow/immediately.svg";
import date from "assets/workflow/onboardingdate.svg";
import { INPUT_TYPE } from "constants/ui";
import mail from "../../../assets/workflow/mail.svg";
import jira from "../../../assets/workflow/jira.svg";
import slack from "assets/workflow/slack.svg";
import role from "assets/workflow/role.svg";
import license from "assets/workflow/license.svg";
import text from "assets/workflow/text.svg";
import variables from "assets/workflow/variables.svg";
import user from "assets/workflow/user_attribute.svg";
import application from "assets/workflow/app_attribute.svg";
import action from "assets/workflow/action_attribute.svg";
import plus from "assets/workflow/variable_plus.svg";

export const ICONS = {
	"needs review": { icon: NeedsReview, name: "Needs Review" },
	restricted: { icon: Restricted, name: "Restricted" },
	unmanaged: { icon: Unmanaged, name: "Unmanaged" },
};

export const EVENT_TYPE = {
	RUN_WORKFLOW: "run_workflow",
	WAIT: "wait",
};

export const TIME_UNIT = [
	{ unit: "minutes" },
	{ unit: "hours" },
	{ unit: "days" },
	{ unit: "weeks" },
];

export const STATUSES = {
	COMPLETED: "completed",
	PENDING: "pending",
	FAILED: "failed",
	COMPLETED_WITH_ERRORS: "completed_with_errors",
};

export const METHODS = [
	{ value: "GET" },
	{ value: "POST" },
	{ value: "PUT" },
	{ value: "DELETE" },
];

export const SIDEBAR_TABS = {
	RECOMMENDED: "recommended",
	OVERVIEW: "overview",
	VARIABLES: "variables",
	SETTINGS: "settings",
	RUNS: "runs",
	HELP: "help",
};

export const WORFKFLOW_TYPE = {
	ONBOARDING: "onboarding",
	OFFBOARDING: "offboarding",
	APPREQUISITION: "apprequisition",
	APPPLAYBOOK: "appPlaybook",
};

export const ACTION_TYPE = {
	MANUAL: "manual",
	INTEGRATION: "integration",
	INTERNAL: "internal",
	STATIC: "static",
	CUSTOM_MANUAL: "custom_manual",
	SSO_ACTION: "sso_action",
};

export const ACTION_SCOPE_VALIDATION_ERROR = {
	SCOPE_MISSING: "scope_missing",
	DISCONNECTED: "orgintegration_not_connected",
};

export const ACTION_EDIT_OPTIONS = [
	{
		name: "Edit action",
		type: "edit",
	},
	{
		name: "Delete Action",
		type: "delete",
	},
];

export const CAPTURE_CONSTRAINT = {
	SINGLE_ONLY: "single-only",
	MULTI_ONLY: "multi-only",
};

export const NO_ASSIGNEE_DATA = {
	app_owner: "No app owner for this app",
	current_user: "No current user for this app",
	dept_head: "No Department head for this user",
	workflow_users: "No workflow user for this app",
	reporting_manager: "No reporting manager for this user",
	it_admin: "No IT admin for this org",
	app_it_owner: "No app IT owner for this app",
	payment_owner: "No payment owner for this org",
	app_finance_owner: "No app finance owner for this app",
};

export const ASSIGNEE_SUGGESTION_TITLE = {
	app_owner: "Assign to App Owner",
	current_user: "Assign to Me",
	dept_head: "Assign to Department Head",
	workflow_users: "Assign to Users",
};

export const TAB_TYPES = {
	declaration: {
		type: "declaration",
		columns: offboardingDeclarationColumns,
		emptyScreen: OffboardingDashboardEmptyScreen,
	},
};

export function getSearchReqObj(searchQuery, fieldId, fieldName) {
	const searchObj = {
		field_values: [searchQuery],
		field_order: "contains",
		field_id: fieldId,
		filter_type: "search_in_string",
		field_name: fieldName,
		negative: false,
		is_custom: false,
	};
	return searchObj;
}

export const defaultReqBody = {
	filter_by: [],
	sort_by: [],
	columns: [],
};

export const defaultPublishedAndVariablesReqBody = {
	filter_by: [
		{
			field_values: [true],
			field_order: "contains",
			field_id: "is_published",
			filter_type: "boolean",
			field_name: "Published",
			negative: false,
			is_custom: false,
		},
		{
			field_values: false,
			field_id: "using_variables",
			filter_type: "boolean",
			field_name: "Variables",
			negative: false,
			is_custom: false,
		},
	],
	sort_by: [],
	columns: [],
};

export function filtersRequestBodyGenerator(query, reqBody = defaultReqBody) {
	let urlMetaData;
	let urlReqBody = reqBody;
	let newReqObj = {};
	if (!_.isEmpty(query.metaData)) {
		urlMetaData = JSON.parse(JSON.stringify(query));
		urlReqBody = JSON.parse(decodeURIComponent(urlMetaData.metaData));
	}
	newReqObj.sort_by = urlReqBody.sort_by || [];
	newReqObj.filter_by = urlReqBody.filter_by || [];
	newReqObj.columns = urlReqBody.columns || [];
	newReqObj.reset_filter = urlReqBody.reset_filter;

	return newReqObj;
}

export const SCHEDULE_TIME = {
	CURRENT_DATE: "immediate",
	ONBOARDING_DATE: "onboarding_date",
	OFFBOARDING_DATE: "offboarding_date",
};

export const generateEventOptions = (tag) => {
	const data = [
		{
			label: "immediately",
			description: `Runs as soon as an employee is marked for ${tag}`,
			logo: immediately,
			value: SCHEDULE_TIME.CURRENT_DATE,
		},
	];
	if (tag) {
		data.push({
			label: `On ${tag} Date and Time`,
			description: `Runs on ${tag} date of employee at a specified time`,
			logo: date,
			value:
				tag === WORFKFLOW_TYPE.ONBOARDING
					? SCHEDULE_TIME.ONBOARDING_DATE
					: SCHEDULE_TIME.OFFBOARDING_DATE,
		});
	}
	return data;
};

export const MANUAL_TASK_TIME_UNIT = [{ unit: "days" }, { unit: "weeks" }];

export const ACTION_OPTIONS = {
	SCHEDULE: "schedule",
	APPROVAL: "approval",
};

export const ACTION_RENDER_OPTIONS = [
	{
		label: "Schedule",
		value: "schedule",
		icon: schedule,
		selectedIcon: scheduled,
	},
	{
		label: "Approval",
		value: "approval",
		icon: approval,
		selectedIcon: approved,
	},
];

export const CUSTOM_FIELD_INPUT_TYPE = {
	SELECT: "select",
	BOOL: "bool",
	REFERENCE: "reference",
	TEXT: "text",
	DATE: "date",
};

export const userSourceResponseFormater = (list = [], key = "manual") => {
	const idx = list.findIndex((item, index) => item?.keyword === key);
	list[idx] = {
		...list[idx],
		source_name: "Manual",
		org_integration_id: "manual",
		logo: "https://ui-avatars.com/api/?name=Manual",
	};
	return [...list];
};

export const MANUAL_TASK_INPUT_FIELD = {
	STATIC_MANUAL_INPUT_FIELD: "static_manual_input_field",
	ASSIGNEE: "assignee",
	DUE_DATE: "due_date",
	DUE_IN: "due_in",
};

export const SUMMARISED_RUN_LOG_TABS = {
	log: "LOG",
	configured_parameters: "CONFIGURED PARAMETERS",
	app_response: "APP RESPONSE",
};

export const excludeActionFromManualTask = [
	"ZLURI.SEND_WORKFLOW_SUMMARY_MAIL",
	"ZLURI.SEND_CUSTOM_MAIL",
];

export const scheduleTimeUnit = [{ unit: "AM" }, { unit: "PM" }];

export const workflowTagClassifications = {
	DRAFT: "draft",
	PLAYBOOKS: "playbooks",
	COMPLETED: "completed",
	RUNS: "runs",
	RULES: "rules",
};

export const EXCLUDE_MULTIPLE_USER_INPUT_BUTTON = [
	INPUT_TYPE.RADIO,
	INPUT_TYPE.CHECKBOX,
	INPUT_TYPE.RICHTEXT,
	INPUT_TYPE.DOCUMENT,
];

export const ITSM_SERVICES = {
	EMAIL: "email",
	JIRA: "jira",
	SLACK: "slack",
};

export const ITSM_SERVICES_LIST = [
	{
		key: "email",
		icon: mail,
		title: "via Mail",
		value: ITSM_SERVICES.EMAIL,
		description: "Task will be assigned via email",
		isActive: true,
	},
	{
		key: "jira",
		icon: jira,
		title: "via Jira",
		value: ITSM_SERVICES.JIRA,
		description: "Task will be assigned via JIRA",
		isActive: true,
	},
	{
		key: "slack",
		icon: slack,
		title: "via Slack",
		value: ITSM_SERVICES.SLACK,
		description: "Task will be assigned via Slack",
		isActive: true,
	},
];

export const USER_RELATION_MAPPING = {
	Forwardee: "Forward",
};

export const APP_BASED_USAGE_OPTIONS = [
	{ label: "Active Users Only", value: false },
	{ label: "All Users (Force Run)", value: true },
];

export const WEBHOOK_EVENT_CLASS = [
	{ label: "User created", value: "onboarding" },
	{ label: "User removed", value: "offboarding" },
];

export const webhookEventClass = {
	key: "event_class",
	name: "Webhook Class",
	fact: "audit_log",
	path: "$.meta.webhook_meta.event_class",
	type: "select",
	operators: [
		{
			label: "equals",
			value: "equal",
		},
	],
	values: [
		{
			label: "User is Onboarded",
			value: "onboarding",
		},
		{
			label: "User is Offboarded",
			value: "offboarding",
		},
	],
	__v: 0,
	is_deleted: false,
	logo: "",
	operator: "equal",
	value: "",
	selectedValue: {
		label: "",
		value: "",
	},
};

export const webhookIntegrationSource = {
	key: "integration",
	name: "Webhook Source",
	fact: "audit_log",
	path: "$.meta.webhook_meta.org_integration_id",
	type: "entity",
	operators: [
		{
			label: "equals",
			value: "equal",
		},
	],
	values: [],
	__v: 0,
	is_deleted: false,
	logo: "",
	operator: "equal",
	value: "",
	global_integration_id: "",
	selectedValue: {
		label: "",
		value: "",
	},
};

export const ACTION_GROUP_MAPPER = {
	"Zluri ACTION": "Zluri Actions",
	ADD: "Add Actions",
	Others: "Other Actions",
	READ: "Read Actions",
	"SSO ACTION": "SSO Actions",
	UPDATE: "Update Actions",
	MANUAL: "Manual Actions",
	DELETE: "Delete Actions",
	Recommended: "Recommended Actions",
};

export const ONBOARDING_ACTION_GROUP_MAPPER = {
	Recommended: "Recommended Actions",
	ADD: "Add Actions",
	UPDATE: "Update Actions",
	READ: "Read Actions",
	"SSO ACTION": "SSO Actions",
	MANUAL: "Manual actions",
	"ZLURI ACTION": "Zluri actions",
	DELETE: "Delete Actions",
	Others: "Other Actions",
};

export const OFFBOARDING_ACTION_GROUP_MAPPER = {
	Recommended: "Recommended Actions",
	DELETE: "Delete Actions",
	UPDATE: "Update Actions",
	READ: "Read Actions",
	"SSO ACTION": "SSO Actions",
	MANUAL: "Manual actions",
	"ZLURI ACTION": "Zluri actions",
	ADD: "Add Actions",
	Others: "Other Actions",
};

export const VARIABLES_OBJECT_TYPE = [
	{
		label: "Access Request",
		value: "access_request",
		logo: text,
	},
	{
		label: "Text",
		value: "text",
		logo: text,
	},
];

export const VARIABLES_OPTIONS = [
	{
		label: "Application Attributes",
		value: "app",
		logo: application,
		description: "Select current application attributes",
	},
	{
		label: "User Attributes",
		value: "user",
		logo: user,
		description: "Select user attributes",
	},
	{
		label: "Current Action Attributes",
		value: "action",
		logo: action,
		description: "Select attributes of this action",
	},
	{
		label: "Variables",
		value: "variables",
		logo: variables,
		description: "Select a created variable",
	},
	{
		label: "New Variable",
		value: "new_variable",
		logo: plus,
		description: "Create a new variable",
	},
];

export const SYSTEM_ATTRIBUTES = {
	action: {
		name: 1,
	},
	app: {
		app_name: 1,
		app_status: 1,
		app_owner_name: 1,
		app_owner_email: 1,
		app_financial_owner_name: 1,
		app_financial_owner_email: 1,
		app_technical_owner_name: 1,
		app_technical_owner_email: 1,
	},
	user: {
		user_email: 1,
		user_name: 1,
		user_designation: 1,
		user_status: 1,
		user_role: 1,
		user_department_name: 1,
		user_reporting_manager_name: 1,
	},
};

export const DYNAMIC_VARIABLES_ATTRIBUTES = {
	access_request: {
		requested_app_name: 1,
		requester_name: 1,
		requester_email: 1,
		request_reason: 1,
		requested_user_email: 1,
		requested_user_name: 1,
		requested_license_name: 1,
		requested_role_name: 1,
		request_duration: 1,
		requested_quantity: 1,
	},
	text: {},
};
