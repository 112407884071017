import { costCenterReduxConstants } from "../constants/costCenterConstants";

const initialState = {
	costCenterInfo: {},
};

export default function CostCentersReducer(state = initialState, action) {
	switch (action.type) {
		case costCenterReduxConstants.COST_CENTER_INFO_REQUESTED:
		case costCenterReduxConstants.DELETE_COST_CENTERS_CACHE:
			var newState = { ...state };
			newState.costCenterInfo[action.payload?.id] = {
				loading: true,
				loaded: false,
				data: [],
			};
			return newState;
		case costCenterReduxConstants.COST_CENTER_INFO_FETCHED:
			var newState = { ...state };
			newState.costCenterInfo[action.payload?.id] = {
				loading: false,
				loaded: true,
				data: action.payload.data,
				err: action.payload.err,
			};
			return newState;

		default:
			return state;
	}
}
