import React from "react";
import increment from "assets/v2tables/Icon_Arrow_Up.svg";
import decrement from "assets/v2tables/Icon_Arrow_Down.svg";
import noChangeIcon from "assets/icons/v2Icons/Icon_Arrow_Down.svg";
import changeNotAvailableIcon from "assets/icons/v2Icons/changeNotAvailableIcon.svg";
import "./ChangeNumber.css";
import { getOrgCurrency } from "constants/currency";
import { Currency } from "@zluri/ui-components";

export default function ChangeNumber({ component, data, ...props }) {
	const changeStatusIcon = getChangeStatusIcon(data[component.valueKey]);
	const value = data[component.valueKey];
	return (
		<div
			style={{
				borderBottom:
					component?.hoverComponent && "1px dashed #000!important",
			}}
			className="v2table__change-number cursor-pointer"
		>
			{changeStatusIcon && (
				<img
					width={16}
					height={16}
					src={getChangeStatusIcon(data[component.valueKey])}
					className="mr-1"
				/>
			)}
			{!component.showOnlyIcon && (
				<>
					{
						<Currency
							color={
								data[component.valueKey] > 0
									? "accent_green_1"
									: data[component.valueKey] < 0
										? "accent_red_1"
										: "secondary_grey_4"
							}
							fontVariant="alerttag_regular_semibold"
							locale="en-US"
							useShortForm
							showDecimals={component.showDecimals}
							value={
								data[component.valueKey] ||
								data[component.valueKey] === 0
									? Math.abs(data[component.valueKey])
									: "N/A"
							}
						/>
						// <Typography
						// 	variant="alerttag_regular_semibold"
						// 	color={
						// 		data[component.valueKey] > 0
						// 			? "accent_green_1"
						// 			: "accent_red_1"
						// 	}
						// >
						// 	{Math.abs(data[component.valueKey] || 0)}
						// </Typography>
					}
				</>
			)}
		</div>
	);
}

export function getChangeStatusIcon(val) {
	if (val > 0) {
		return increment;
	} else if (val < 0) {
		return decrement;
	} else if (val === 0) {
		return noChangeIcon;
	} else {
		return changeNotAvailableIcon;
	}
}
const currencyFormatStandard = (orgCurrency) => ({
	style: "currency",
	currency: orgCurrency,
	notation: "compact",
	compactDisplay: "long",
});
export function ChangeSpend({ component, data, ...props }) {
	const changeStatusIcon = getChangeStatusIcon(data[component.valueKey]);
	let orgCurrency = getOrgCurrency();
	let amountFormatted = new Intl.NumberFormat(
		"en-US",
		currencyFormatStandard(orgCurrency)
	)
		.format(data[component.valueKey])
		.split("$");
	return (
		<div
			style={{
				borderBottom:
					component?.hoverComponent && "1px dashed #000!important",
			}}
			className="v2table__change-number cursor-pointer"
		>
			{changeStatusIcon && (
				<img
					width={component.withoutValue ? 24 : 16}
					height={component.withoutValue ? 24 : 16}
					src={getChangeStatusIcon(data[component.valueKey])}
				/>
			)}
			{component.withoutValue ? (
				<></>
			) : (
				<span
					style={{
						color:
							data[component.valueKey] > 0
								? "#5FCF64"
								: "#FE6955",
					}}
					className="font-600"
				>
					<div>
						{amountFormatted.map((i) => {
							i;
						})}
					</div>
				</span>
			)}
		</div>
	);
}
