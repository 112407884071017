import ProtectedRoute from "common/ProtectedRoute";
import UnauthorizedToView from "common/restrictions/UnauthorizedToView";
import React from "react";
import { useSelector } from "react-redux";
import { Route } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

export default function EmployeeView({ children }) {
	const { isAuthenticated } = useAuth0();
	const isEmployeeDashboardEnabled = useSelector(
		(state) => state?.userInfo?.employee_dashboard_enabled
	);

	return (
		<Route
			path="/user"
			render={() =>
				isEmployeeDashboardEnabled ? (
					children
				) : (
					<div className="m-auto">
						<ProtectedRoute>
							<UnauthorizedToView height="100vh" />
						</ProtectedRoute>
					</div>
				)
			}
		/>
	);
}
