export const singleUserReduxConstants = {
	USER_BASIC_DETAILS_REQUESTED: "USER_BASIC_DETAILS_REQUESTED",
	USER_BASIC_DETAILS_FETCHED: "USER_BASIC_DETAILS_FETCHED",
	USER_ACTION_HISTORY_REQUESTED: "USER_ACTION_HISTORY_REQUESTED",
	USER_ACTION_HISTORY_FETCHED: "USER_ACTION_HISTORY_FETCHED",
	USER_ASSOCIATIONS_REQUESTED: "USER_ASSOCIATIONS_REQUESTED",
	USER_ASSOCIATIONS_FETCHED: "USER_ASSOCIATIONS_FETCHED",
	USER_SPEND_DATA_REQUESTED: "USER_SPEND_DATA_REQUESTED",
	USER_SPEND_DATA_FETCHED: "USER_SPEND_DATA_FETCHED",
	UPDATE_USER_BASIC_DETAILS: "UPDATE_USER_BASIC_DETAILS",
	USER_UNARCHIVE_START: "USER_UNARCHIVE_START",
	USER_UNARCHIVE_COMPLETED: "USER_UNARCHIVE_COMPLETED",
	USER_OVERVIEW_METRICS_REQUESTED: "USER_OVERVIEW_METRICS_REQUESTED",
	USER_OVERVIEW_METRICS_FETCHED: "USER_OVERVIEW_METRICS_FETCHED",
	USER_ONBOARD_OFFBOARD_DETAILS_REQUESTED:
		"USER_ONBOARD_OFFBOARD_DETAILS_REQUESTED",
	USER_ONBOARD_OFFBOARD_DETAILS_FETCHED:
		"USER_ONBOARD_OFFBOARD_DETAILS_FETCHED",
	UPDATE_USER_CUSTOM_FIELD_DATA: "UPDATE_USER_CUSTOM_FIELD_DATA",
};

export const UserStatusValues = {
	ACTIVE: "active",
	INACTIVE: "inactive",
	SUSPENDED: "suspended",
};

export const UserTypeValues = {
	EMPLOYEE: "employee",
	SERVICE: "service",
	GROUP: "group",
};

export const UserAssociationIconModules = [
	{ applications_owner: "Applications" },
	{ contracts_owner: "Contracts" },
	{ departments_owner: "Departments" },
	{ reporting_manager: "Users" },
];
