import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "./BulkEdit.css";

import {
	BUTTON_VARIANT,
	Button,
	GREY_VARIANT,
	PrimaryButtonVariant,
	Popover,
	Typography,
	theme,
} from "@zluri/ui-components";

import {
	ApiMapper,
	INVALID_DATA,
	IconMapper,
	componentMapper,
	toastProps,
} from "./BulkEdit.constants";
import {
	getRefactoredData,
	updateSubMenuWithCountOptions,
	validateDataObject,
} from "./BulkEdit.utils";
import rightarrow from "assets/v2tables/pageHeader/rightarrow.svg";
import { ShowToast } from "./ShowToast";
import { TriggerIssue } from "utils/sentry";
import { customApi } from "./BulkEditCustomApiHelper";
import { useTableContext } from "containers/v2table/TableContext/context";
import PropTypes from "prop-types";
import { isObject } from "underscore";
import Icon from "components/Icon";

export const BulkEditV2 = ({ gridRef, entity, subEntityData, apiProps }) => {
	const {
		checkboxState,
		dataEntity,
		isAllDataSelected,
		enableBulkEdit,
		renderCustomBulkEditConfirmationPopup,
	} = useTableContext();
	const selectedRows = checkboxState.selected;
	const unselectedRows = checkboxState.unselected;

	const tableProperties = useSelector(
		(state) => state?.v2TableProperties[dataEntity]
	);
	const bulkEditConfig = tableProperties?.bulk_edit ?? [];
	const tableData = useSelector((state) => state?.v2Table[dataEntity]);

	const dispatch = useDispatch();

	const handleApiSuccess = (res) => {
		if (res?.status === "success" || res?.success || res) {
			toast(<ShowToast type={toastProps?.success} />);

			//TODO: possibly get it from useTable hook
			dispatch({
				type: "GET_TABLE_DATA",
				payload: {
					entity: dataEntity,
					subEntityData: subEntityData,
					shouldRefresh: true,
					needsReset: true, // TODO: needs fixes
					apiProps: apiProps,
				},
			});
		}
	};

	const handleApiCall = ({ data: additionalProps, selectedOption }) => {
		const submenuOption = selectedOption.submenu;
		const menu = selectedOption.menu;

		const apiData = {
			menu,
			selectedOption: submenuOption,
			selectedRows,
			unselectedRows,
			apiProps: {
				select_all: isAllDataSelected,
				filter_by: tableData.filter_by,
				...(additionalProps ?? {}),
			},
		};

		const isMenuDataValid = validateDataObject(menu) !== INVALID_DATA;
		const isSubmenuDataValid = menu.submenuType
			? validateDataObject(submenuOption) !== INVALID_DATA
			: true;

		// TODO: remove once the APIs are updated according to the standards
		const followsDeprecatedPayload = [
			"app_review_users",
			"app_users_emp_view",
			"pending",
		].includes(dataEntity);
		const api = ApiMapper?.[dataEntity]?.[menu?.patchApi];
		const isValid = isMenuDataValid && isSubmenuDataValid && api;

		//TODO:
		if (!isValid) return Promise.reject("Invalid Data type");

		if (followsDeprecatedPayload) {
			const refactoredData = {
				...menu,
				value: getRefactoredData(apiData),
			};

			return api(refactoredData)
				.then((res) => {
					// TODO: DEPRECATE
					// !! WARNING !! DO NOT USE THIS . IF NEEDED, CONNECT WITH ANY OF V2-POD MEMBERS
					// THIS IS FOR NOW USED ONLY ON ACCESS REVIEW TABLE
					// TO BE DEPRECATED
					if (menu?.customApi) {
						customApi[menu.customApi]({
							refactoredData,
							entity,
							apiProps,
							tableData,
							dispatch,
							res,
						});
					} else {
						handleApiSuccess(res);
					}
					return res;
				})
				.catch((err) => {
					toast(<ShowToast type={toastProps?.failure} />);
					TriggerIssue(
						`Bulk edit API issue for entity ${entity}`,
						err
					);
				})
				.finally(() => {
					gridRef?.current?.deselectAll();

					// TODO: should this be in .then instead??
					setModalData({ open: false, data: null });
				});
		}

		const patchKey = menu?.patchKey;
		const patchDataKey = menu?.patchDataKey ?? "value";

		const bulkEditPayload = {
			//TODO: handle this for cases where submenu isn't present
			...(patchKey && {
				[patchKey]: selectedOption?.submenu?.[patchDataKey],
			}),
			selected_ids: Object.keys(selectedRows),
			unselected_ids: Object.keys(unselectedRows),
			select_all: isAllDataSelected,
			filter_by: tableData.filter_by,
			...(additionalProps ?? {}),
		};

		return api(bulkEditPayload)
			.then((res) => {
				handleApiSuccess(res);
				return res;
			})
			.catch((err) => {
				toast(<ShowToast type={toastProps?.failure} />);
				TriggerIssue(`Bulk edit API issue for entity ${entity}`, err);
			})
			.finally(() => {
				gridRef?.current?.deselectAll();

				// TODO: should this be in .then instead??
				setModalData({ open: false, data: null });
			});
	};

	const [modalData, setModalData] = useState({ open: false, data: null });
	const onOptionClick = (menu, submenu) => {
		const selectedOption = { menu, submenu };
		if (!!renderCustomBulkEditConfirmationPopup) {
			setModalData({ open: true, data: selectedOption });
		} else {
			handleApiCall({ selectedOption });
		}
	};

	const onCancel = () => {
		setModalData({ open: false, data: null });
	};

	const [show, setShow] = useState(false);

	const showBulkEdit =
		enableBulkEdit &&
		(isAllDataSelected || Object.keys(selectedRows).length > 0);

	if (!showBulkEdit) return null;
	return (
		<>
			<Popover
				className="z-v2-filter__popover"
				show={show}
				onToggle={setShow}
				content={bulkEditConfig.map((menu) => (
					<MainMenu
						key={menu.menuName}
						menu={menu}
						onOptionClick={(...args) => {
							onOptionClick(...args);
							setShow(false);
						}}
					/>
				))}
				trigger={["click"]}
			>
				<Button
					className="z__bulk-edit-button"
					theme={theme}
					variant={PrimaryButtonVariant.primary_default}
				>
					<div className="w-100 d-flex justify-content-between align-items-center">
						Bulk Edit
						<Icon name="caret-down" />
					</div>
				</Button>
			</Popover>

			{!!modalData.open && (
				<ConfirmationModal
					selectedOption={modalData.data}
					handleApiCall={handleApiCall}
					onCancel={onCancel}
				/>
			)}
		</>
	);
};

const ConfirmationModal = React.memo(
	({ selectedOption, handleApiCall, onCancel }) => {
		const {
			checkboxState,
			isAllDataSelected,
			renderCustomBulkEditConfirmationPopup,
		} = useTableContext();
		return useMemo(
			() =>
				renderCustomBulkEditConfirmationPopup
					? renderCustomBulkEditConfirmationPopup({
							//TODO: see if this is good or not
							selectedOption,
							onConfirm: (data) =>
								handleApiCall({ selectedOption, data }),
							onCancel,
							checkboxState: {
								...checkboxState,
								isAllDataSelected,
							},
						})
					: null,
			[]
		);
	}
);
ConfirmationModal.displayName = "BulkEdit Confirmation";
const MainMenu = ({ menu, onOptionClick }) => {
	const [show, setShow] = useState(false);
	if (!menu?.submenu) {
		return (
			<MenuLabel
				iconName={menu?.menuIcon}
				menuName={menu?.menuName}
				onClick={() => onOptionClick(menu)}
			/>
		);
	}
	return (
		<div>
			<Popover
				placement="rightTop"
				show={show}
				onToggle={setShow}
				trigger={["click"]}
				content={
					<SubMenu
						menu={menu}
						onOptionClick={(...arg) => {
							setShow(false);
							onOptionClick(...arg);
						}}
					/>
				}
			>
				<div className="padding_2">
					<MenuLabel
						hasSubMenu
						iconName={menu?.menuIcon}
						menuName={menu?.menuName}
					/>
				</div>
			</Popover>
		</div>
	);
};

const SubMenuPropTypes = PropTypes.arrayOf(
	PropTypes.shape({
		label: PropTypes.string,
		icon: PropTypes.string,
		value: PropTypes.string,
	})
);
const MenuPropTypes = PropTypes.shape({
	menuIcon: PropTypes.string,
	menuName: PropTypes.string.isRequired,
	submenu: SubMenuPropTypes, // TODO:
	submenuType: PropTypes.oneOf(["image_and_text", "search"]),
	showCountinSubMenu: PropTypes.bool, // TODO: Deprecate, only used in Tasks module for now
	submenuClassname: PropTypes.string,
});

MainMenu.propTypes = {
	menu: MenuPropTypes,
	onOptionClick: PropTypes.func,
};
const MenuLabel = ({ iconName, menuName, hasSubMenu = false, onClick }) => {
	const menuIcon = IconMapper[iconName];
	return (
		<div
			className="gap-6 padding_6 bulk-edit-option justify-content-between cursor-pointer"
			onClick={onClick}
		>
			<div className="d-flex gap-6 align-items-center">
				{menuIcon && <img src={menuIcon} alt="" />}
				{menuName && (
					<div className="d-flex justify-content-between">
						<Typography
							variant={BUTTON_VARIANT.BUTTON_EXTRA_SMALL_MEDIUM}
							color={GREY_VARIANT.SECONDARY_GREY_2}
						>
							{menuName}
						</Typography>
					</div>
				)}
			</div>
			{hasSubMenu && <img src={rightarrow} alt="right arrow" />}
		</div>
	);
};
MenuLabel.propTypes = {
	iconName: PropTypes.string,
	menuName: PropTypes.string,
	hasSubMenu: PropTypes.string,
	onClick: PropTypes.func,
};

const SubMenu = ({ menu, onOptionClick }) => {
	const {
		checkboxState: { selected: selectedRows },
		dataEntity,
	} = useTableContext();
	const noOfRowsSelected = Object.keys(selectedRows || {})?.length;
	const SubMenuComponent = componentMapper[menu?.submenuType];

	//TODO: DEPRECATE
	//!!! WARNING !!! DO NOT USE THIS . IF NEEDED, CONNECT WITH ANY OF V2-POD MEMBERS
	// THIS IS FOR NOW USED ONLY ON TASKS TABLE
	// TO BE DEPRECATED
	if (!SubMenuComponent) return;
	if (menu && isObject(menu) && menu.showCountinSubMenu) {
		const updatedMenu = updateSubMenuWithCountOptions({
			option: { ...menu },
			noofRowsSelected: noOfRowsSelected,
			selectedRows: selectedRows,
		});
		return (
			<SubMenuComponent
				entity={dataEntity}
				option={updatedMenu}
				onOptionClick={onOptionClick}
			/>
		);
	}

	return (
		<SubMenuComponent
			entity={dataEntity}
			option={menu}
			onOptionClick={onOptionClick}
		/>
	);
};

SubMenu.propTypes = {
	menu: MenuPropTypes,
	onOptionClick: PropTypes.func,
};
