const initialState = {
	loading: {},
};
export default function v2TableReducer(state = initialState, action) {
	switch (action.type) {
		// There are two levels of table => module Level and entity level
		// module level table won't have entityId and can be identifed by entity only, entity level will be identified by "entityId" and "entity"
		// there was no concept of "entityId" as of now so couldn't change the existing structure of the redux state
		// that means for each entity level table all the data for each entity would be overriden by the latest data
		// So entityCache is introduced to store the the data entity wise, while the latest data will be available as it is.
		case "TABLE_DATA_FETCHED": {
			const {
				entity,
				data,
				columns,
				filter_by,
				sortBy,
				page,
				count,
				other_tabs_count,
				recordsPerPage,
				isInfinite,
				searchQuery,
				quick_filter,
				resetAndRepopulate,
				error,
				viewId,
				entityId,
			} = action.payload;

			const latestData = {
				columns,
				other_tabs_count,
				filter_by,
				sortBy,
				loading: false,
				isInfiniteLoading: false,
				count,
				loadedPages: 1,
				page,
				recordsPerPage,
				isInfinite: isInfinite === "true" || isInfinite, // isInfinite is sometimes boolean and sometimes 'true' | 'false' (string)
				searchQuery,
				quick_filter,
				error,
				viewId,
				entityId,
				//resetAndRepopulate flag is to make sure all previous data are removed
				// this flag is set whenever changes are made to either sort,filter or search
				data: resetAndRepopulate
					? { 0: data }
					: { ...state?.[entity]?.data, [page]: data },
			};
			const entityCache = entityId
				? {
						...(state[entity]?.entityCache ?? {}),
						[entityId]: {
							...(state[entity]?.entityCache?.[entityId] ?? {}),
							...latestData,
						},
					}
				: null;
			return {
				...state,
				[entity]: {
					...(state[entity] ?? {}),
					...latestData,
					entityCache,
				},
			};
		}
		case "RESTORE_LATEST_STATE": {
			const { entity, entityId } = action.payload;
			const latestData = entityId
				? state?.[entity]?.entityCache?.[entityId]
				: state[entity];
			const entityCache = entityId ? state?.[entity]?.entityCache : null;
			return {
				...state,
				[entity]: {
					...(latestData ?? {}),
					entityCache: entityCache,
				},
			};
		}
		case "TABLE_DATA_REQUESTED": {
			const { entity, isInfinite, enableInfiniteLoading } =
				action.payload;
			return {
				...state,
				[entity]: {
					...state[entity],
					loading: enableInfiniteLoading ? false : true,
					isInfiniteLoading: isInfinite && enableInfiniteLoading,
				},
			};
		}
		case "RECORDS_PER_PAGE_UPDATED": {
			const { entity, recordsPerPage } = action.payload;
			return { ...state, [entity]: { ...state[entity], recordsPerPage } };
		}
		case "PAGINATION_TYPE_UPDATED": {
			const { entity, isInfinite } = action.payload;
			return {
				...state,
				[entity]: {
					...state[entity],
					isInfinite,
					page: 0,
				},
			};
		}
		case "UPDATE_TABLE_META": {
			const { entity, metaData } = action.payload;
			return { ...state, [entity]: { ...state[entity], ...metaData } };
		}
		case "UPDATE_FILTER_META": {
			const { entity, filter_by } = action.payload;
			return { ...state, [entity]: { ...state[entity], filter_by } };
		}
		case "PAGE_FETCHED": {
			const { data, entity, page } = action.payload;
			const { loadedPages } = state[entity];

			return {
				...state,
				[entity]: {
					...state[entity],
					data: { ...state[entity].data, ...{ [page]: data } },
					page,
					loadedPages: loadedPages + 1,
					loading: false,
					error: false,
				},
			};
		}
		case "UPDATE_PAGE": {
			const { entity, page } = action.payload;
			const { loadedPages } = state[entity];

			return {
				...state,
				[entity]: {
					...state[entity],
					// data: {...state[entity].data, ...{[page+1]:data}},
					page,
					loadedPages: loadedPages + 1,
					loading: false,
					error: false,
				},
			};
		}

		case "UPDATE_RECORD": {
			const { rowIndex, columnIndex } = action.payload;

			const loadingRow = {
				...state.loading?.[rowIndex],
				...{ [columnIndex]: { loading: true, error: false } },
			};
			const loading = { ...state.loading, ...{ [rowIndex]: loadingRow } };
			return { ...state, loading };
		}

		case "RECORD_UPDATED": {
			const { entity, index, value, rowIndex, columnIndex, isInfinite } =
				action.payload;
			let rpp = state[entity].recordsPerPage;
			let page;
			if (isInfinite) {
				page = Math.floor(index / rpp);
			} else {
				page = state[entity].page;
			}
			const updatedData = { ...state[entity].data };
			const updatedPageData = [...state[entity].data[page]];
			updatedPageData[index % rpp] = {
				...state[entity].data[page][index % rpp],
				...value,
			};
			updatedData[page] = updatedPageData;
			// state[entity].data = updatedData;
			const loadingRow = {
				...state.loading?.[rowIndex],
				...{ [columnIndex]: { loading: false, error: false } },
			};
			const loading = { ...state.loading, ...{ [rowIndex]: loadingRow } };

			return {
				...state,
				[entity]: { ...state[entity], data: updatedData },
				loading,
			};
		}

		case "RECORD_UPDATE_FAILED": {
			const {
				entity,
				index,
				value,
				rowIndex,
				columnIndex,
				errorMessage,
			} = action.payload;

			const loadingRow = {
				...state.loading?.[rowIndex],
				...{
					[columnIndex]: {
						loading: false,
						error: true,
						errorMessage: errorMessage,
					},
				},
			};
			const loading = { ...state.loading, ...{ [rowIndex]: loadingRow } };

			return { ...state, loading };
		}
		case "REVERT_ERROR_AND_MESSAGE": {
			const { rowIndex, columnIndex } = action.payload;

			const loadingRow = {
				...state.loading?.[rowIndex],
				...{
					[columnIndex]: {
						loading: false,
						error: false,
					},
				},
			};
			const loading = { ...state.loading, ...{ [rowIndex]: loadingRow } };

			return { ...state, loading };
		}
		case "TAB_COUNT_UPDATED": {
			const { entity, tabCount } = action.payload;
			return {
				...state,
				[entity]: {
					...state[entity],
					tabCount: tabCount,
				},
			};
		}
		case "UPDATE_LATEST_TAB": {
			const { entity, latestTab } = action.payload;
			return {
				...state,
				[entity]: {
					...state[entity],
					latestTab,
				},
			};
		}
		case "CHANGE_LOADER_COMPLETED": {
			const { entity, loader } = action.payload;
			return {
				...state,
				[entity]: {
					...state[entity],
					loading: loader,
				},
			};
		}
		case "CLEAR_INTERNAL_DATA": {
			const { entity } = action.payload;
			let tempState = delete state[entity];
			return { ...tempState };
		}
		case "REORDER_DATA": {
			const { dragRowIndex, insertRowIndex, entity } = action.payload;
			const rows = [...state[entity]["data"][0]];
			const element = rows[dragRowIndex];
			rows.splice(dragRowIndex, 1);
			rows.splice(insertRowIndex, 0, element);
			return {
				...state,
				[entity]: {
					...state[entity],
					data: [rows],
				},
			};
		}

		default:
			return state;
	}
}
