import { updateAppSignOffFeasibleStatus } from "modules/AccessReview/redux/AccessReview.actions";

const certificationBulkReviewerTask = async ({
	refactoredData,
	apiProps,
	dispatch,
	tableData,
	entity,
	res,
}) => {
	let forceRemoveFilter;
	let filterBy = [...tableData.filter_by];

	if (Array.isArray(tableData.filter_by) && tableData.filter_by.length > 0) {
		let index = tableData.filter_by.findIndex(
			(row) => row.filter_type === "expr" && row.field_id === "user_id"
		);
		if (index > -1) {
			if (refactoredData?.value?.select_all) {
				forceRemoveFilter = true;
				filterBy = filterBy.filter(
					(row) =>
						row.filter_type !== "expr" && row.field_id !== "user_id"
				);
			}
		}
	}

	await dispatch(
		updateAppSignOffFeasibleStatus(apiProps?.appId, res?.signoff_feasible)
	);

	dispatch({
		type: "CLEAR_INTERNAL_DATA",
		payload: { entity: entity },
	});

	dispatch({
		type: "GET_TABLE_DATA",
		payload: {
			entity: entity,
			shouldRefresh: true,
			apiProps: {
				appId: apiProps?.appId,
				reviewId: apiProps?.reviewId,
			},
			...(forceRemoveFilter ? { filterBy: filterBy } : {}),
			cols: tableData?.columns || [],
		},
	});
};
export const customApi = {
	certificationBulkReviewerTask: certificationBulkReviewerTask,
};
