import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { Button } from "UIComponents/Button/Button";
import successInt from "../../../../assets/success-int.svg";

export function IntegrationComplete({ onHide, integration, requestSentData, inviteLinkDetails, clickedGenerateLink }) {
	const [copied, setCopied] = useState(false);
	const placeholderText = inviteLinkDetails.message
	return (
		<div className="d-flex flex-fill justify-content-center mt-4">
			<div
				align="center"
				className="z_i_connect_modal_req_coworker_container"
			>	
				{
					clickedGenerateLink? 
					(<Card>
						<Card.Body>
							<div className="px-5 py-4">
								<img src={successInt} />
								<div className="font-15 bold-600 mb-2">
									Link Generated
								</div>
									Share the following link and the access code with your co-worker
								</div>
							<textarea
							className="form-control mt-3"
							style={{minHeight: '100px' }}
							placeholder= {placeholderText}
							disabled
							/>
	
						</Card.Body>
	
						<div className="d-flex justify-content-center">
							<Button
								className="z-button-primary px-4 mb-4 mr-2"
								size="lg"
								style={{ width: "30%" }}
								onClick={() => {
									navigator.clipboard.writeText(inviteLinkDetails.message);
								setCopied(true)
								setTimeout(() => {
									setCopied(false);
								}, 3000);
								}}
							>
								{copied ? "Copied!" : "Copy"}
							</Button>
							<Button
								className="z-button-primary px-4 mb-4"
								size="lg"
								style={{ width: "30%" }}
								onClick={() => onHide(false)}
							>
								Done
							</Button>
						</div>
	
					</Card>): (
					<Card>
					<Card.Body>
						<div className="px-5 py-4">
							<img src={successInt} />
							<div className="font-15 bold-600 mb-2">
								Request sent
							</div>
							{`A mail has been sent to ${requestSentData?.to_email}  with the
							instructions complete this connection`}
						</div>
					</Card.Body>
					<Button
						className="z-button-primary px-4 mb-4"
						size="lg"
						style={{ width: "30%", margin: "auto" }}
						onClick={() => onHide(false)}
					>
						Close
					</Button>
					</Card>)
				}
			</div>
		</div>
	);
}
