import React from "react";
import axios from "axios";
import { getValueFromLocalStorage } from "./localStorage";
import { toast } from "react-toastify";
import { RBAC_ERROR, RBAC_ERROR_STATUS_CODES } from "constants/rbac";
import ToastNotificaion from "UIComponents/Rbac/ToastNotification";

const integration = axios.create({
	baseURL: process.env.REACT_APP_INTEGRATION_URL,
});

integration.interceptors.request.use((request) => {
	//Send orgId in the payload for supporting multi org user to connect integrations.
	const userDetails = getValueFromLocalStorage("userInfo");
	let orgId = userDetails?.org_id;
	if (request.data) {
		request.data.orgId = orgId;
	} else {
		request.data = {
			orgId: orgId,
		};
	}
	const token = getValueFromLocalStorage("token");
	request.headers.common["Authorization"] = `Bearer ${token}`;
	return request;
});

integration.interceptors.response.use(
	function (response) {
		return response;
	},
	function (error) {
		if (
			error.response.data.type === RBAC_ERROR &&
			RBAC_ERROR_STATUS_CODES.includes(error.response.status) &&
			error.response.data.operation === "mutation"
		) {
			toast(<ToastNotificaion />, {
				hideProgressBar: true,
			});
		}
		if (error && error.response && error.response.status === 401) {
			window.location.replace("/logout");
		}
		return Promise.reject(error);
	}
);

integration.CancelToken = axios.CancelToken;
integration.isCancel = axios.isCancel;

export { integration };
