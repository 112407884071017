import React from "react";
import close from "assets/close.svg";
import { Modal } from "react-bootstrap";
import warning from "assets/agents/inactive.svg";
import downloadDocument from "UIComponents/PreviewDocument/DownloadDocument.svg";
import ContractBulkUploadErrorCSVDownload from "./ContractBulkUploadErrorCSVDownload";
import LongTextTooltip from "modules/shared/components/LongTextTooltip/LongTextTooltip";
import { bulkContractUploadCSVHeaders } from "modules/licenses/constants/LicenseConstants";

export default function ContractBulkUploadErrorTable({
	data,
	isOpen,
	handleClose,
}) {
	return (
		<>
			<Modal centered show={isOpen} onHide={handleClose} size="lg">
				<Modal.Header closeButton={false}>
					<Modal.Title
						className="d-flex align-items-center"
						style={{ fontWeight: "600", gap: "8px" }}
					>
						<div className="font-18">
							Contract Bulk Upload Errors
						</div>
						<ContractBulkUploadErrorCSVDownload
							rows={data}
							top={25}
							right={-52}
						>
							<img
								src={downloadDocument}
								className="cursor-pointer mr-4"
								height={25}
								width={25}
							/>
						</ContractBulkUploadErrorCSVDownload>
					</Modal.Title>
					<img alt="Close" onClick={handleClose} src={close} />
				</Modal.Header>
				<Modal.Body>
					<div className="bulk_upload_contracts_error_table">
						<div className="bulk_upload_contracts_error_table_header_row">
							{bulkContractUploadCSVHeaders.map(
								(header, index) => (
									<React.Fragment key={index}>
										<div className="bulk_upload_contracts_error_table_header_cell">
											{header}
										</div>
									</React.Fragment>
								)
							)}
						</div>
						<div
							className="bulk_upload_contracts_error_table_body"
							style={{ height: "500px" }}
						>
							{data.map((row, rowIndex) => (
								<React.Fragment key={rowIndex}>
									<div
										className="bulk_upload_contracts_error_table_body_row"
										style={
											typeof row.Errors === "object" &&
											Object.keys(row.Errors).length > 0
												? {
														background: "#ff66661a",
												  }
												: {}
										}
									>
										{bulkContractUploadCSVHeaders.map(
											(header, cellIndex) => (
												<React.Fragment key={cellIndex}>
													<div className="bulk_upload_contracts_error_table_body_cell">
														<div className="bulk_upload_contracts_error_table_body_cell_value">
															{row[header] || "-"}
														</div>
														{row.Errors[header] && (
															<LongTextTooltip
																tooltipText={
																	row.Errors[
																		header
																	]
																}
																text={
																	<img
																		src={
																			warning
																		}
																		style={{
																			width: "12px",
																			height: "12px",
																		}}
																	/>
																}
															/>
														)}
													</div>
												</React.Fragment>
											)
										)}
									</div>
								</React.Fragment>
							))}
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
}
