import * as React from "react";
import { useCallback, useContext, useState, useMemo } from "react";
import { capitalizeAllFirstLettersOfUnderscoreString } from "utils/common";
import { useDispatch } from "react-redux";
import { openModal } from "reducers/modal.reducer";
import { ITableContextProviderData } from "../types";
export const DensityEnum = {
	COMPACT: "COMPACT",
	COMFORTABLE: "COMFORTABLE",
} as const;

type AnyObject = Record<string, any>;

type DensityEnumType = keyof typeof DensityEnum;
interface ICheckboxState {
	selected: AnyObject;
	unselected: AnyObject;
}
interface ITableContext extends ITableContextProviderData {
	density: DensityEnumType;
	isAllDataSelected: boolean;
	isBulkSelected: boolean;
	onAllDataSelectionChange: (bool: boolean) => void;
	resetCheckboxState: () => void;
	isAnyDataSelected: boolean;
	updateTableDensity: (density: DensityEnumType) => void;
	paginationText: string;
	appliedSearchLabel: string;
	openBulkUpdateSidesheet: () => void;
	checkboxState: ICheckboxState;
}
const TableContext = React.createContext<ITableContext | null>(null);

interface ITableContextProvider {
	children: React.ReactNode;
	data: ITableContextProviderData;
}
const TableContextProvider = ({ children, data }: ITableContextProvider) => {
	const onSelectionChangeFromProps = React.useRef(data.onSelectionChange);
	// This is if the all checkbox is selected from the header checkbox
	const [isBulkSelected, setIsBulkSelected] = useState(false);

	// This is when a user wants to select all the data (even remote data)
	const [isAllDataSelected, setIsAllDataSelected] = useState(false);

	const [density, setDensity] = useState(DensityEnum.COMPACT);

	// if isAllDataSelected ->checkboxState -> unselected
	// else -> checkboxState -> selected
	const [checkboxState, setCheckboxState] = useState({
		unselected: {},
		selected: {},
	});

	const resetCheckboxState = useCallback(() => {
		setIsBulkSelected(false);
		setIsAllDataSelected(false);
		setCheckboxState({ unselected: {}, selected: {} });
		data?.gridRef?.current?.deselectAll();
	}, [data?.gridRef]);

	const onSelectionChange = useCallback(
		({ selected, originalData }) => {
			let _isAllDataSelected = isAllDataSelected;
			const selectedLength = Object.keys(selected || {}).length;

			if (selectedLength) {
				if (selectedLength === originalData?.length) {
					setIsBulkSelected(true);

					_isAllDataSelected = selectedLength === data.totalDataCount;
				}
			} else {
				setIsBulkSelected(false);
				_isAllDataSelected = false;
			}

			const unselectedRows =
				_isAllDataSelected && originalData?.length
					? originalData
							.filter((row) => !selected[row["_id"]])
							.reduce((acc, cur) => {
								const key = cur["_id"];
								acc[key] = cur;
								return acc;
							}, {})
					: {};

			const selectionState = _isAllDataSelected
				? { unselected: unselectedRows, selected: selected }
				: { selected: selected, unselected: {} };
			setCheckboxState(selectionState);
			setIsAllDataSelected(_isAllDataSelected);
			onSelectionChangeFromProps.current?.({
				...selectionState,
				isAllDataSelected: _isAllDataSelected,
			});
		},
		[isAllDataSelected, data.totalDataCount]
	);

	const updateTableDensity = useCallback((_d) => {
		setDensity(_d);
	}, []);
	const isAnyDataSelected =
		isAllDataSelected ||
		isBulkSelected ||
		Object.keys(checkboxState.selected).length > 0;

	const dispatch = useDispatch();
	const onAllDataSelectionChange = useCallback(
		(bool) => setIsAllDataSelected(bool),
		[]
	);

	// TODO: This paginationEntity is intended to be removed in future
	// fallback to entity is also to be removed. (??)
	const {
		textMap,
		paginationEntity,
		filterBy,
		sortBy,
		filterProperties,
		hideExportCSVForExcel,
	} = data;
	const paginationText =
		textMap?.pagination ??
		textMap?.default ??
		paginationEntity ??
		data.entity;

	const appliedSearchLabel =
		textMap?.appliedSearchLabel ??
		textMap?.default ??
		capitalizeAllFirstLettersOfUnderscoreString(data.dataEntity);

	// !! this needs to be updated
	const openBulkUpdateSidesheet = useCallback(() => {
		return dispatch(
			openModal("openBulkUpdateSidesheet", {
				checked: Object.keys(checkboxState?.selected ?? {}) ?? [],
				filterBy,
				sortBy,
				hideExportCSVForExcel,
				filterProperties,
			}) as any
		);
	}, [
		checkboxState?.selected,
		dispatch,
		filterBy,
		filterProperties,
		sortBy,
		hideExportCSVForExcel,
	]);
	const contextValue = useMemo(
		() => ({
			...data,
			isAllDataSelected,
			isBulkSelected,
			onSelectionChange,
			onAllDataSelectionChange,
			resetCheckboxState,
			isAnyDataSelected,
			checkboxState,
			density,
			updateTableDensity,
			paginationText,
			appliedSearchLabel,
			openBulkUpdateSidesheet,
		}),
		[
			data,
			isAllDataSelected,
			isBulkSelected,
			onSelectionChange,
			onAllDataSelectionChange,
			resetCheckboxState,
			isAnyDataSelected,
			checkboxState,
			density,
			updateTableDensity,
			paginationText,
			appliedSearchLabel,
			openBulkUpdateSidesheet,
		]
	);

	return (
		<TableContext.Provider value={contextValue}>
			{children}
		</TableContext.Provider>
	);
};

//TODO:

const useTableContext = () => {
	const context = useContext(TableContext);

	if (!context) {
		throw new Error(
			"useTableContext can only be used inside V2Table components"
		);
	}

	return context;
};

export { TableContextProvider, useTableContext };
