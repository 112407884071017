import { filter, groupBy, keys } from "underscore";

import { searchAppSource, searchDepAppSource } from "services/api/search";
import {
	getAppUsersSourcesForAccessCert,
	getAppUsersSourcesForAccessCertEmpView,
} from "modules/AccessReview/service/AccessReview.service";
import { getSegmentFromURL } from "@zluri/ui-components";

// CONSTANTS
const SOURCE_API_MAPPER = {
	application_users: {
		api: ({ appId }) => searchDepAppSource(appId),
	},
	org_applications: {
		api: () => searchAppSource(),
	},
	access_certifications: {
		api: () => {
			let isEmployee = getSegmentFromURL(1) === "user";
			const certId = isEmployee
				? getSegmentFromURL(3)
				: getSegmentFromURL(2);

			const appId = isEmployee
				? getSegmentFromURL(5)
				: getSegmentFromURL(4);
			let api = isEmployee
				? getAppUsersSourcesForAccessCertEmpView
				: getAppUsersSourcesForAccessCert;

			return api(certId, appId);
		},
	},
};

const SOURCE_FILTER_GROUP = {
	agents: {
		name: "Agent",
		map_key: "global_agent_id",
		name_key: "source_name",
		key: "agent",
		filter: (source) => source.hasOwnProperty("global_agent_id"),
		options: [],
	},
	integrations: {
		name: "Direct Integration",
		map_key: "integration_id",
		name_key: "source_name",
		key: "integrations",
		id_key: "org_integration_id",
		filter: (source) =>
			source.hasOwnProperty("org_integration_id") &&
			source.hasOwnProperty("integration_id"),
		options: [],
	},
	sso: {
		name: "SSO",
		filter: () => {},
		options: [],
	},
};

// METHODS
const getNestedSourceOptions = (sources, config = {}) => {
	const { map_key = "", id_key = "", category } = config;
	const groupedSourcesByKey = groupBy(sources, (source) => source[map_key]);
	const generateApiData = (source) => ({
		apiData: {
			key: id_key || map_key,
			value: [source?.[id_key || map_key]],
			renderValue: [source?.source_name],
			value_type: "objectId",
		},
		...source,
	});
	return (
		keys(groupedSourcesByKey)?.map((key) => {
			const sources = groupedSourcesByKey[key];
			return {
				key:
					sources?.length === 1
						? sources[0]?.[id_key || map_key]
						: key,
				title:
					sources?.length > 1
						? sources[0]?.name
						: sources?.[0]?.source_name ||
							sources?.[0]?.source_name,
				category,
				data: generateApiData(sources[0]),
				children:
					sources?.length > 1
						? sources?.map((source) => ({
								key: source?.[id_key] || map_key,
								title: source?.source_name,
								category,
								data: generateApiData(source),
							}))
						: null,
			};
		}) || []
	);
};

const getGroupedSourcesOptions = (sources, config) => {
	return keys(config).map((key) => {
		const sourceConfig = config[key];
		const filteredSourcesByKey = sourceConfig?.filter
			? filter(sources, sourceConfig?.filter)
			: [];
		return {
			key: key,
			title: sourceConfig?.name,
			options: getNestedSourceOptions(filteredSourcesByKey, {
				...sourceConfig,
				category: key,
			}),
		};
	});
};

const getSourceDefaultValues = (apiValues) => {
	const obj = {
		integrations: { keys: [], nodes: [] },
		agents: { keys: [], nodes: [] },
		sso: { keys: [], nodes: [] },
	};
	if (!apiValues?.length) return obj;
	apiValues?.map((val) => {
		if (val?.key === "org_integration_id")
			obj.integrations = {
				keys: [...obj.integrations.keys, val?.value[0]],
				nodes: [
					...obj.integrations.nodes,
					{ data: { apiData: { ...val } } },
				],
			};
		if (val?.key === "global_agent_id")
			obj.agents = {
				keys: [...obj.agents.keys, val?.value[0]],
				nodes: [...obj.agents.nodes, { data: { apiData: { ...val } } }],
			};
	});
	return obj;
};

export {
	SOURCE_API_MAPPER,
	SOURCE_FILTER_GROUP,
	getNestedSourceOptions,
	getGroupedSourcesOptions,
	getSourceDefaultValues,
};
