import React from "react";
import { Loader } from "./Loader";
import { Typography } from "@zluri/ui-components";
import loadable from "@loadable/component";
import {
	getDurationWithMonthScale,
	getMomentLocaleConstant,
} from "utils/DateUtility";
import cn from "classnames";
import { getNested } from "utils/getNested";
const MomentTimezone = loadable.lib(() => import("moment-timezone"));

export function DateTime({ data, component }) {
	const valuefromComponent = component?.valueKey;
	const value = Array.isArray(valuefromComponent)
		? valuefromComponent?.reduce((_, x) => _?.[x], data)
		: getNested(data, valuefromComponent);

	return (
		<Typography
			variant="body_1_regular"
			color="secondary_grey_1"
			className={cn(
				"ellipsize-typography",
				component?.enableUnderline && "cell__dashed__underline",
				component?.hideColorOnHover && "cell__hide__color__on__hover"
			)}
			style={component?.style}
		>
			<MomentTimezone>
				{({ default: moment }) =>
					value ? (
						getDateTimeContent({ component, data, moment, value })
					) : component.loaderType ? (
						<Loader type={data[component.loaderType]} />
					) : (
						"-"
					)
				}
			</MomentTimezone>
		</Typography>
	);
}

const getDateTimeContent = ({ component, data, moment, value }) => {
	const format = component.format || "D MMM, YYYY";
	const timeZoneString = Intl.DateTimeFormat().resolvedOptions().timeZone;

	switch (true) {
		case component?.fromNow:
			moment.updateLocale("en", getMomentLocaleConstant());
			return moment(data[component?.valueKey]).fromNow(
				!!component?.hideAgo
			);
		case component?.fromNowInMonthScale:
			return getDurationWithMonthScale(data[component?.valueKey]);
		case component?.includeTimezone:
			return moment(data[component?.valueKey])
				.tz(timeZoneString)
				.format(format);
		default:
			return moment(value || data[component?.valueKey]).format(format);
	}
};
