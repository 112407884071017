import React, { useCallback, useEffect, useState } from "react";
import {
	CONFIGURE_ACTIONS,
	STATUS,
	ViewType,
	actionsData,
	configureReviewActions,
	defaultActions,
	defaultApproveAction,
	defaultReviewActions,
} from "modules/AccessReview/constants/constants";
import { useDispatch, useSelector } from "react-redux";
import totalUsers from "assets/icons/total-users.svg";
import learnMoreToolTip from "assets/icons/learn-more-tooltip.svg";
import { SelectV2 } from "../SelectV2/SelectV2";
import { debounce } from "underscore";
import { searchUsers } from "services/api/search";
import {
	updateProgressbar,
	updateReviewActions,
} from "modules/AccessReview/redux/AccessReview.actions";
import {
	checkIfAllFieldsFilledInObj,
	getFilterObj,
} from "modules/AccessReview/util/util";
// import ManualTaskAction from "../ManualTaskAction/ManualTaskAction";
import { getAllAppPlaybooksV2 } from "modules/applications/components/automation/service/automation-api";
import { ImageOrNameBadge, Field } from "@zluri/ui-components";
import ContentLoader from "react-content-loader";
import { TriggerIssue } from "utils/sentry";
import { AppAuthStatusIconAndTooltip } from "common/AppAuthStatus";
import "modules/AccessReview/components/SelectApplicationInModal/SelectApplicationInModal.css";
import "modules/AccessReview/components/SelectV2/SelectV2.css";
import "modules/AccessReview/components/ConfigureReviewActions/ConfigureReviewActions.css";

export default function ConfigureReviewActions(props) {
	const dispatch = useDispatch();
	const { applicationReview } = useSelector((state) => state.accessReview);
	const selectedApplication = applicationReview?.application;
	const [reviewActions, setReviewActions] = useState({
		...applicationReview?.review_actions,
		on_accept: {
			...defaultReviewActions,
			action: configureReviewActions.DO_NOTHING,
		},
	});
	const [queryForRejectAction, setQueryForRejectAction] = useState("");
	const [queryForModifyAction, setQueryForModifyAction] = useState("");
	const [playbooksForReject, setPlaybooksForReject] = useState([]);
	const [playbooksForModify, setPlaybooksForModify] = useState([]);
	const [defaultPlaybooks, setDefaultPlaybooks] = useState([]);
	const [optionsLoading, setOptionsLoading] = useState(false);
	// const defaultUsers = defaultUsersAndRoles?.defaultUsers;
	// const defaultRoles = defaultUsersAndRoles?.defaultRoles;
	// const [users, setUsers] = useState([]);
	const [isFetchingDefaultPlaybooks, setIsFetchingDefaultPlaybooks] =
		useState(true);

	// const debouncedChangeHandler = useCallback(
	// 	debounce((query, reqCancelToken) => {
	// 		if (query || query === "") {
	// 			searchUsers(query, reqCancelToken, true).then((res) => {
	// 				setUsers(res.results);
	// 				setOptionsLoading(false);
	// 			});
	// 		}
	// 	}, 500),
	// 	[]
	// );

	useEffect(() => {
		dispatch(updateReviewActions(reviewActions));
		const isValidated = checkIfAllFieldsFilledInObj(reviewActions);
		isValidated
			? props.setNextBtnDisabled(false)
			: props.setNextBtnDisabled(true);

		dispatch(
			updateProgressbar(
				3,
				isValidated ? STATUS.Completed : STATUS.InProgress,
				ViewType.ViewsInAddApplicationModal
			)
		);
	}, [reviewActions]);

	const debouncedChangeHandlerForPlaybooks = useCallback(
		debounce((query, dataPoint) => {
			getAllAppPlaybooksV2(
				"deprovision",
				0,
				10,
				getFilterObj(query),
				applicationReview?.appId
			)
				.then((res) => {
					dataPoint === "on_decline"
						? setPlaybooksForReject(res?.data)
						: setPlaybooksForModify(res?.data);
				})
				.catch((err) => {
					TriggerIssue(
						"Error while fetching app playbooks data",
						err
					);
				})
				.finally(() => {
					setOptionsLoading(false);
				});
		}, 500),
		[]
	);

	useEffect(() => {
		getAllAppPlaybooksV2(
			"deprovision",
			0,
			10,
			getFilterObj(""),
			applicationReview?.appId
		)
			.then((res) => {
				setDefaultPlaybooks(res?.data);
			})
			.catch((err) => {
				TriggerIssue("Error while fetching app playbooks data", err);
			})
			.finally(() => {
				setIsFetchingDefaultPlaybooks(false);
			});
	}, []);

	const isActionQueryNonEmpty = (action) => {
		switch (action) {
			case "on_decline":
				return queryForRejectAction && queryForRejectAction?.length > 0;
			case "on_modify":
				return queryForModifyAction && queryForModifyAction?.length > 0;
			default:
				return false;
		}
	};

	return (
		<div className="d-flex flex-column justify-content-center align-items-center select-application-card hide-scrollbar">
			<div
				style={{
					minWidth: "600px",
					minHeight: "52px",
					padding: "10px",
					borderRadius: "8px",
					backgroundColor: "white",
					margin: "10px 12px",
				}}
				className="d-flex justify-content-center align-items-center"
			>
				<div className="d-flex justify-content-between w-100">
					<div className="d-flex justify-content-start align-items-center">
						<ImageOrNameBadge
							url={selectedApplication?.app_logo}
							name={selectedApplication?.app_name}
							height={32}
							width={32}
							className="border-radius-4"
						/>
						<div className="bold-600 font-12 black-1 family-sora mx-2">
							{selectedApplication?.app_name}
						</div>
						<AppAuthStatusIconAndTooltip
							authStatus={selectedApplication?.app_state}
							tooltipClasses={"tooltip-black"}
						/>
					</div>
					<div className="d-flex flex-row align-items-center justify-content-start app__pill">
						<img src={totalUsers} alt="" className="pr-1" />
						<span className="family-sora bold-400 font-10 grey-1">{`${
							applicationReview?.selected_user_count || 0
						} Users selected to be reviewed`}</span>
					</div>
				</div>
			</div>
			<div
				style={{
					minWidth: "600px",
					padding: "10px",
					borderRadius: "8px",
					backgroundColor: "white",
				}}
			>
				<div>
					<div className="d-flex justify-content-start align-items-center mb-2">
						<div className="bold-600 font-12 black-1 family-sora">
							{CONFIGURE_ACTIONS}
						</div>
					</div>
					<div className="d-flex flex-column align-items-center justify-content-start pt-2">
						<div className="w-100 review__actions">
							{/* <SelectV2
								defaultClassNames={true}
								inputClassName={"select-reviewer-input-field"}
								search={true}
								value={[reviewActions?.on_accept]}
								selectorClassStyle={{ minWidth: "580px" }}
								options={defaultApproveAction}
								fieldNames={{
									label: "action",
								}}
								placeholder={"Approve Action *"}
								onChange={(obj) => {
									setReviewActions({
										...reviewActions,
										on_accept: {
											...reviewActions.on_accept,
											...obj,
										},
									});
								}}
								onSearch={(query) => {
									if (query?.length !== 0) {
										setReviewActions({
											...reviewActions,
										});
									} else {
										setReviewActions({
											...reviewActions,
											on_accept: defaultReviewActions,
										});
									}
								}}
								optionRender={(option, props) => {
									return (
										<div className="d-flex justify-content-between">
											<div className="d-flex align-items-center">
												<div className="d-flex flex-column family-sora bold-400">
													<div className="font-11 black-1">
														{option?.action}
													</div>
												</div>
											</div>
										</div>
									);
								}}
							/> */}
							<Field
								value="Do nothing when Approved"
								disabled={true}
								className="disabled__input__field w-100"
							/>
						</div>

						<div className="mt-2 w-100">
							{/* {actionsData?.manual_tasks?.map((task, i) => {
								return (
									<div
										key={task?.type + i}
										style={{ marginBottom: "8px" }}
									>
										<SelectV2
											defaultClassNames={true}
											inputStyle={{
												fontSize: "11px",
												fontWeight: "400",
												opacity: 1,
											}}
											style={{
												padding: "1px",
												height: "28px",
											}}
											search={true}
											value={[
												reviewActions?.[
													task?.dataPoint
												],
											]}
											isOptionsLoading={optionsLoading}
											optionsContainerClassName={
												"overflow-auto"
											}
											hideDropDown={false}
											options={defaultActions}
											fieldNames={{
												label: "action",
											}}
											optionStyles={{
												background: "white",
											}}
											placeholder={task?.placeHolder}
											onChange={(obj) => {
												setReviewActions({
													...reviewActions,
													[task?.dataPoint]: {
														...reviewActions?.[
															task?.dataPoint
														],
														...obj,
													},
												});
											}}
											onSearch={(query) => {
												if (query?.length !== 0) {
													setReviewActions({
														...reviewActions,
													});
												} else {
													setReviewActions({
														...reviewActions,
														[task?.dataPoint]:
															defaultReviewActions,
													});
												}
											}}
											optionRender={(option, props) => {
												const isPlaybookSelected =
													option?.action ===
													configureReviewActions.SELECT_PLAYBOOK;
												return (
													<div className="d-flex justify-content-between">
														<div className="d-flex align-items-center">
															<div className="d-flex flex-column">
																<div
																	className={`font-11 bold-500 ${
																		isPlaybookSelected
																			? "grey-4"
																			: "black-1"
																	}`}
																>
																	{
																		option?.action
																	}
																</div>
															</div>
														</div>
													</div>
												);
											}}
										/>
										{reviewActions?.[task?.dataPoint]
											?.action ===
											configureReviewActions.SETUP_MANUAL_TASK && (
											<ManualTaskAction
												reviewActions={reviewActions}
												setReviewActions={
													setReviewActions
												}
												setNextBtnDisabled={
													props.setNextBtnDisabled
												}
												query={
													task?.type === "Reject"
														? queryForRejectAction
														: queryForModifyAction
												}
												type={task?.type}
												defaultRoles={defaultRoles}
												defaultUsers={defaultUsers}
												optionsLoading={optionsLoading}
												setQuery={
													task?.type === "Reject"
														? setQueryForRejectAction
														: setQueryForModifyAction
												}
												setOptionsLoading={
													setOptionsLoading
												}
												debouncedChangeHandler={
													debouncedChangeHandler
												}
												users={users}
											/>
										)}
									</div>
								);
							})} */}
							{isFetchingDefaultPlaybooks ? (
								<div className="d-flex flex-column">
									<Loader
										height={36}
										background="white"
										speed={1}
										width={576}
										className="p-1"
									/>
									<Loader
										height={36}
										background="white"
										speed={1}
										width={576}
										className="p-1"
									/>
								</div>
							) : (
								actionsData?.select_playbook?.map((data, i) => {
									return (
										<div
											key={data?.type + i}
											style={{ marginBottom: "8px" }}
										>
											<SelectV2
												key={
													data?.type + data?.dataPoint
												}
												defaultClassNames={true}
												inputStyle={{
													fontSize: "11px",
													fontWeight: "400",
													opacity: 1,
												}}
												style={{
													padding: "1px",
													height: "28px",
												}}
												search={true}
												noDataComp={
													!defaultPlaybooks?.length >
													0
														? getNoDataComp(
																"No Playbooks have been setup for this application."
															)
														: null
												}
												value={[
													{
														...reviewActions?.[
															data?.dataPoint
														]?.workflow_template_id,
														profile_img:
															reviewActions?.[
																data?.dataPoint
															]
																?.workflow_template_id
																?.published_by_user_logo,
														user_name:
															reviewActions?.[
																data?.dataPoint
															]
																?.workflow_template_id
																?.published_by_user_name,
													},
												]}
												isOptionsLoading={
													optionsLoading
												}
												optionsContainerClassName={
													"overflow-auto"
												}
												hideDropDown={false}
												options={
													isActionQueryNonEmpty(
														data?.dataPoint
													)
														? data?.dataPoint ===
															"on_decline"
															? playbooksForReject
															: playbooksForModify
														: defaultPlaybooks
												}
												fieldNames={{
													label: "name",
													logo: "created_by_user_logo",
													description:
														"created_by_user_name",
												}}
												optionStyles={{
													background: "white",
												}}
												isNameBadge={true}
												imageOrNameBadgeImgClassName={
													"selected-option-img mr-1"
												}
												imageOrNameBadgeNameClassName={
													"search-name-options mr-1"
												}
												placeholder={data?.placeHolder}
												onChange={(obj) => {
													setReviewActions({
														...reviewActions,
														[data?.dataPoint]: {
															...reviewActions?.[
																data?.dataPoint
															],
															workflow_template_id:
																{
																	...obj,
																},
														},
													});
												}}
												onSearch={(query) => {
													if (query?.length !== 0) {
														setOptionsLoading(true);
														debouncedChangeHandlerForPlaybooks(
															query,
															data?.dataPoint
														);
													} else {
														setReviewActions({
															...reviewActions,
															[data?.dataPoint]:
																defaultReviewActions,
														});
													}
													data?.dataPoint ===
													"on_decline"
														? setQueryForRejectAction(
																query
															)
														: setQueryForModifyAction(
																query
															);
												}}
												onSearchClear={() =>
													setReviewActions({
														...reviewActions,
														[data?.dataPoint]:
															defaultReviewActions,
													})
												}
												optionRender={(
													option,
													props
												) => {
													return (
														<div className="d-flex justify-content-between">
															<div className="d-flex align-items-center">
																<ImageOrNameBadge
																	url={
																		option?.published_by_user_logo
																	}
																	name={
																		option?.published_by_user_name
																	}
																	nameBadgeClasses="search-name-options padding_10"
																	className="selected-option-img"
																/>
																<div className="d-flex flex-column family-sora bold-500 ml-2">
																	<div className="font-14 black-1">
																		{
																			option?.name
																		}
																	</div>
																	<div className="font-11 grey-1 pt-1">
																		{`Created by: ${option?.published_by_user_name}`}
																	</div>
																</div>
															</div>
															{/* <img
											className="justify-content-end"
											src={learnMoreToolTip}
											alt=""
										/> */}
														</div>
													);
												}}
											/>
										</div>
									);
								})
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

const getNoDataComp = (text) => {
	return (
		<div className="no__playbook__data__comp">
			<div className="font-11 bold-500 grey-4">{text}</div>
		</div>
	);
};

const Loader = (props) => {
	const { width, height, speed, background, className } = props;
	return (
		<ContentLoader
			className={`border-radius-8 ${className}`}
			height={height}
			backgroundColor={background}
			width={width}
			speed={speed || 1}
		>
			<rect width={width} height={height} fill="#ffffff" y="0" x="0" />
		</ContentLoader>
	);
};
