import React from "react";

import PropTypes from "prop-types";

import { BODY_VARIANT, Divider, Typography } from "@zluri/ui-components";

/* Being used in
   Application Users -> Instances Popover
 */
export function InstanceListPopover({ data, component }) {
	const { valueKey, ignoreFirstValue, style = {} } = component;

	const allInstances = data?.[valueKey];
	const instances = allInstances.slice(ignoreFirstValue ? 1 : 0);

	return (
		<div className="z_instance_container" style={style}>
			{instances?.map(({ id, instance_name, discovered_by }, idx) => (
				<>
					<InstanceDetail
						key={id}
						name={instance_name}
						src={discovered_by}
					/>
					{idx < instances?.length && (
						<Divider
							height="1px"
							backgroundColor="#EBEBEB"
							borderRadius="8px"
						/>
					)}
				</>
			))}
		</div>
	);
}
InstanceListPopover.propTypes = {
	data: PropTypes.object,
	component: PropTypes.shape({
		valueKey: PropTypes.string,
		ignoreFirstValue: PropTypes.bool,
		style: PropTypes.object,
	}),
};

function InstanceDetail({ name, src }) {
	return (
		<div className="z_instance_detail d-flex align-items-center justify-content-between gap-4 p-2">
			<Typography variant={BODY_VARIANT.BODY_2_BOLD} color="#484848">
				{name}
			</Typography>

			{src && (
				<div className="d-flex align-items-center gap-4">
					<Typography
						className="text-nowrap"
						variant={BODY_VARIANT.BODY_3_MEDIUM}
						color="#A8A8A8"
					>
						Source
					</Typography>
					<img src={src} alt={name} height="16px" width="16px" />
				</div>
			)}
		</div>
	);
}
InstanceDetail.propTypes = {
	name: PropTypes.string,
	src: PropTypes.string,
};
