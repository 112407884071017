import React from "react";
import { CustomCellComponent, getCellStyle } from "./componentFactory";

export default function GroupCellRenderer(props) {
	const { groupComponents } = props.component;
	return (
		<div
			style={{
				display: "flex",
				alignItems: "center",
				width: "100%",
				flexShrink: 0,
				gap: "8px",
				...props?.component?.style,
			}}
		>
			{groupComponents?.map((component, index) => (
				<div
					key={index}
					style={{
						...getCellStyle(component.type),
						...component.style,
						...(index === 0 && {
							flexShrink: 1,
							minWidth: 0,
						}),
					}}
				>
					<CustomCellComponent
						{...props}
						component={component}
						type={component.type}
					/>
				</div>
			))}
		</div>
	);
}
