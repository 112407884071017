import React from "react";
import squareGreen from "assets/v2tables/taskdashboard/square_green.svg";
import squareRed from "assets/v2tables/taskdashboard/square_red.svg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
	markActionasApproved,
	markActionasRejected,
	updateTaskActionStatus,
} from "modules/TaskManagement/service/TaskManagement.api";
import { ApiResponseNotification } from "modules/shared/components/ApiResponseNotification/ApiResponseNotification";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Typography } from "@zluri/ui-components";

const img_map = {
	approve: squareGreen,
	cancel: squareRed,
};
export default function TaskActions({ data, component, entity, parentEntity }) {
	const isCompleted =
		data?.status === "completed" || data?.status === "cancelled";
	const buttonToolTipText = !!data.is_approval_task
		? "Approve"
		: "Mark as Complete";
	return (
		<>
			{isCompleted && <CompletedTask status={data?.status} />}
			{!isCompleted && (
				<PendingTaskorApprovalTask
					buttonToolTipText={buttonToolTipText}
					data={data}
					entity={entity}
					parentEntity={parentEntity}
				/>
			)}{" "}
		</>
	);
}

function CompletedTask({ status }) {
	return (
		<>
			{status === "completed" && (
				<div
					style={{
						background: "#E7F8E8",
						padding: "4px 8px 4px 8px",
						alignItems: "center",
						justifyContent: "space-evenly",
						borderRadius: "8px",
						width: "fit-content",
						gap: "8px",
					}}
					className="d-flex"
				>
					<img src={img_map["approve"]} alt="" />
					<span>Marked as Completed</span>
				</div>
			)}
			{status === "cancelled" && (
				<div
					style={{
						background: "#FFE9E5",
						padding: "4px 8px 4px 8px",
						alignItems: "center",
						justifyContent: "space-evenly",
						borderRadius: "8px",
						width: "fit-content",
						gap: "8px",
					}}
					className="d-flex"
				>
					<img src={img_map["cancel"]} alt="" />
					<span>Cancelled</span>
				</div>
			)}
		</>
	);
}
function PendingTaskorApprovalTask({
	buttonToolTipText,
	data,
	entity,
	parentEntity,
}) {
	const dispatch = useDispatch();
	const tableData = useSelector((state) => state.v2Table[entity]);
	const updateTaskStatus = async (status) => {
		console.log("updateTaskStatus");
		try {
			dispatch({
				type: "CHANGE_LOADER_REQUESTED",
				payload: {
					entity: entity,
					loader: true,
				},
			});
			if (!data.is_approval_task) {
				const res = await updateTaskActionStatus({
					workflow_id: data.workflow_id,
					workflow_user_id: data.workflow_user._id,
					status: status,
					id_array: [{ workflow_action_id: data.action_id }],
					type: data.type,
				});
				if (res?.success === false) {
					throw new Error("error while updating task");
				}
			} else {
				let fn =
					status === "completed"
						? markActionasApproved
						: markActionasRejected;
				const res = await fn({
					workflow_id: data.workflow_id,
					workflow_user_id: data.workflow_user._id,
					workflow_action_id: data.action_id,
				});
				console.log(res.success);
				if (res?.success === false) {
					throw new Error("error while updating task");
				}
			}
			dispatch({
				type: "GET_TABLE_DATA",
				payload: {
					entity: entity,
					shouldRefresh: true,
					filterBy: tableData?.filter_by || [],
				},
			});
			dispatch({
				type: "UPDATE_TAB_COUNT",
				payload: {
					entity: parentEntity,
				},
			});
		} catch (err) {
			ApiResponseNotification({
				errorObj: err,
				description: "",
				responseType: "error",
				title: "Error while updating task",
			});
			dispatch({
				type: "CHANGE_LOADER_REQUESTED",
				payload: {
					entity: entity,
					loader: false,
				},
			});
		}
	};
	return (
		<div className="d-flex">
			<OverlayTrigger
				placement="top"
				delay={{ show: 250, hide: 400 }}
				overlay={(toolTipProps) => (
					<Tooltip className="black-tooltip" {...toolTipProps}>
						<div className="px-1">
							<Typography variant="body_2_regular" color="white">
								{buttonToolTipText}
							</Typography>
						</div>
					</Tooltip>
				)}
			>
				<button
					className="task_approve"
					onClick={() => updateTaskStatus("completed")}
				>
					<img src={img_map["approve"]} alt="" />
				</button>
			</OverlayTrigger>
			<OverlayTrigger
				placement="top"
				delay={{ show: 250, hide: 400 }}
				overlay={(toolTipProps) => (
					<Tooltip className="black-tooltip" {...toolTipProps}>
						<div className="px-1">
							<Typography variant="body_2_regular" color="white">
								{"Cancel"}
							</Typography>
						</div>
					</Tooltip>
				)}
			>
				<button
					className="task_cancel"
					onClick={() => updateTaskStatus("cancelled")}
				>
					<img src={img_map["cancel"]} alt="" />
				</button>
			</OverlayTrigger>
		</div>
	);
}
