import React from "react";

import PropTypes from "prop-types";
import {
	BODY_VARIANT,
	BUTTON_VARIANT,
	Button,
	DatePicker,
	GREY_VARIANT,
	Loader,
	PrimaryButtonVariant,
	TimeField,
	Tooltip,
	Typography,
	theme,
} from "@zluri/ui-components";

import reminderAlarmIcon from "assets/Icon_Reminder_Alarm.svg";
import deleteIcon from "assets/delete_filled.svg";
import iconBroken from "assets/icons/Icon_Unmap.svg";
import errorIcon from "assets/error_red_round.svg";

export const ReminderRemoveConfirmation = ({
	onCancel,
	onRemove,
	removingReminder,
}) => {
	return (
		<div className="d-flex flex-column gap-8 align-items-center padding_10">
			<div className="z_overview_note_remove_reminder_icon d-flex align-items-center p-2 img-circle">
				<img
					src={iconBroken}
					className="z_icon_broken_red"
					alt="remove"
					height="16px"
					width="16px"
				/>
			</div>
			<Typography
				color={GREY_VARIANT.SECONDARY_GREY_1}
				variant={BODY_VARIANT.BODY_2_MEDIUM}
			>
				Remove reminder for this note?
			</Typography>
			<div className="d-flex gap-8">
				<Button
					theme={theme}
					variant={PrimaryButtonVariant.primary_alternative}
					onClick={onCancel}
					disabled={removingReminder}
				>
					Cancel
				</Button>
				<Button
					theme={theme}
					variant={PrimaryButtonVariant.primary_danger}
					onClick={onRemove}
					disabled={removingReminder}
				>
					{removingReminder ? <Loader color="white" /> : "Remove"}
				</Button>
			</div>
		</div>
	);
};

export const ReminderUpdate = ({
	date,
	onDateChange,
	time,
	onTimeChange,
	reminderDateTime,
	onDelete,
	updatingReminder,
	onCancel,
	onSave,
}) => {
	return (
		<div className="p-2 d-flex flex-column gap-4">
			<DatePicker
				customFooterRenderer={() => null}
				onChange={onDateChange}
				minDate={new Date()}
				value={date}
			/>
			<TimeField onChange={onTimeChange} value={time} />
			<div className="d-flex justify-content-between align-items-center">
				{reminderDateTime ? (
					<div
						className="z_overview_note_reminder_delete d-flex align-items-center justify-content-center border-radius-8"
						onClick={onDelete}
						role="button"
					>
						<img
							className="cursor-pointer z_overview_delete--red"
							src={deleteIcon}
							alt="delete"
							height="12px"
							width="12px"
						/>
					</div>
				) : (
					<div />
				)}
				<div>
					<Button
						theme={theme}
						variant={PrimaryButtonVariant.primary_alternative}
						className="z_overview_footer_note_button mr-1"
						onClick={onCancel}
						disabled={updatingReminder}
					>
						Cancel
					</Button>
					<Button
						theme={theme}
						variant={PrimaryButtonVariant.primary_default}
						className="z_overview_footer_note_button"
						onClick={onSave}
						disabled={updatingReminder}
					>
						{updatingReminder ? <Loader color="white" /> : "Save"}
					</Button>
				</div>
			</div>
		</div>
	);
};

export const NoteReminder = ({
	onReminderClick,
	showReminderPopover,
	reminderDateTime,
	invalidDateTime,
	tooManyRequests,
}) => {
	return (
		<div className="d-flex align-items-center gap-8">
			<div
				className="z_overview_note_reminder cursor-pointer"
				onClick={onReminderClick}
			>
				<img
					className="z_overview_note_grey_reminder mr-1"
					src={reminderAlarmIcon}
					alt="reminder"
					height="12px"
					width="12px"
				/>

				<Typography
					className={`z_overview_note_reminder_text ${
						showReminderPopover &&
						"z_overview_note_reminder_text--active"
					}`}
					color={GREY_VARIANT.SECONDARY_GREY_3}
					variant={BUTTON_VARIANT.BUTTON_EXTRA_SMALL_MEDIUM}
				>
					{reminderDateTime
						? `Reminder at ${reminderDateTime}`
						: "Set Reminder"}
				</Typography>
			</div>
			{(invalidDateTime || tooManyRequests) && (
				<Tooltip
					content={
						<Typography
							color="white"
							variant="alerttag_small_medium"
						>
							{tooManyRequests
								? "Too many requests"
								: "Date must be greater than today"}
						</Typography>
					}
					placement="bottom"
				>
					<img src={errorIcon} alt="Error" />
				</Tooltip>
			)}
		</div>
	);
};

ReminderRemoveConfirmation.propTypes = {
	onCancel: PropTypes.func,
	onRemove: PropTypes.func,
	removingReminder: PropTypes.bool,
};
ReminderUpdate.propTypes = {
	date: PropTypes.object,
	onDateChange: PropTypes.func,
	time: PropTypes.object,
	onTimeChange: PropTypes.func,
	reminderDateTime: PropTypes.string,
	onDelete: PropTypes.func,
	updatingReminder: PropTypes.bool,
	onCancel: PropTypes.func,
	onSave: PropTypes.func,
};
NoteReminder.propTypes = {
	onReminderClick: PropTypes.func,
	showReminderPopover: PropTypes.bool,
	reminderDateTime: PropTypes.string,
	invalidDateTime: PropTypes.bool,
	tooManyRequests: PropTypes.bool,
};
