import React from "react";

import "./common.css";
import { RBAC_ERROR, RBAC_ERROR_STATUS_CODES } from "constants/rbac";
import ToastNotificaion from "UIComponents/Rbac/ToastNotification";
import { toast } from "react-toastify";

import {
	ALERT_TAG_VARIANT,
	BODY_VARIANT,
	GREY_VARIANT,
	StackedBar,
	Typography,
} from "@zluri/ui-components";
import {
	accessReviewUrls,
	certification_stage,
	certifications,
	csvExportJobName,
	defaultPropsForPopups,
	popupEntities,
} from "modules/AccessReview/constants/constants";
import {
	getDataForStackedBar,
	getNumber,
	getPercentageOfRecordsCompleted,
	getText,
} from "modules/AccessReview/util/util";
import ContentLoader from "react-content-loader";
import ConfirmPopup from "../ConfirmPopup/ConfirmPopup";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
	clearAllReviewDetails,
	clearModalData,
	removeCertData,
	toggleAddApplicationModal,
	updateSetReviewersToggle,
} from "modules/AccessReview/redux/AccessReview.actions";
import { generateExportReportCSV } from "common/Export/Export.service";
import {
	getReportUrl,
	updateCertStage,
} from "modules/AccessReview/service/AccessReview.service";
import { ApiResponseNotification } from "modules/shared/components/ApiResponseNotification/ApiResponseNotification";
import { apiResponseTypes } from "modules/shared/components/ApiResponseNotification/ApiResponseNotificationConstants";
import Divider from "containers/v2table/Divider/Divider";
import { trackActionSegment } from "modules/shared/utils/segment";
import { isEmpty } from "utils/common";

export const getTooltipContent = (
	content,
	tooltipVariant = ALERT_TAG_VARIANT.ALERT_TAG_SMALL_MEDIUM,
	tooltipColor = "white"
) => {
	return (
		<Typography variant={tooltipVariant} color={tooltipColor}>
			{content}
		</Typography>
	);
};

export const ReviewDetailsBar = ({
	reviewDetails,
	reviewText,
	hidePercent,
	stage = certification_stage.REVIEW,
	isCertOverviewScreen,
}) => {
	return (
		<div className="access__review__details__bar">
			<StackedBar
				width="100%"
				height={isCertOverviewScreen ? "16px" : "6px"}
				borderRadius="8px"
				barData={getDataForStackedBar(reviewDetails)}
			/>
			<Typography
				variant={ALERT_TAG_VARIANT.ALERT_TAG_EXTRA_SMALL_MEDIUM}
				color={GREY_VARIANT.SECONDARY_GREY_2}
				className="d-flex justify-content-between gap-4"
			>
				{`${getNumber(reviewDetails, stage)} ${
					reviewText || getText(stage)
				}`}
				{!hidePercent && (
					<span>{`${getPercentageOfRecordsCompleted(
						reviewDetails
					)}%`}</span>
				)}
			</Typography>
		</div>
	);
};

export const ActionsDetailsBar = ({
	reviewDetails,
	stage,
	reviewText,
	isCertOverviewScreen,
	hidePercent = false,
}) => {
	return (
		<div
			className="d-flex align-items-center justify-content-end"
			style={{ padding: "8px", gap: "5px" }}
		>
			<Typography
				variant={BODY_VARIANT.BODY_2_MEDIUM}
				color={GREY_VARIANT.SECONDARY_GREY_2}
				className="text-nowrap"
			>
				{getNumber(reviewDetails, stage)}
			</Typography>
			<Typography
				variant={BODY_VARIANT.BODY_3_REGULAR}
				color={GREY_VARIANT.SECONDARY_GREY_2}
				className="text-nowrap"
			>
				{reviewText || getText(stage)}
			</Typography>
			<div style={{ width: "138px" }}>
				<StackedBar
					width="138px"
					height={isCertOverviewScreen ? "16px" : "6px"}
					borderRadius="4px"
					barData={getDataForStackedBar(reviewDetails)}
				/>
			</div>
			{!hidePercent && (
				<>
					<Typography
						variant={BODY_VARIANT.BODY_2_MEDIUM}
						color={GREY_VARIANT.SECONDARY_GREY_2}
						className="text-nowrap"
					>
						{`${getPercentageOfRecordsCompleted(reviewDetails)}%`}
					</Typography>

					<Typography
						variant={BODY_VARIANT.BODY_3_REGULAR}
						color={GREY_VARIANT.SECONDARY_GREY_2}
						className="text-nowrap"
					>
						completed
					</Typography>
				</>
			)}
		</div>
	);
};

export const Loader = ({ style, width, height, rx, ry }) => {
	return (
		<ContentLoader style={{ width: width, height: height, ...style }}>
			<rect
				x="0"
				y="0"
				rx={rx | 10}
				ry={ry | 10}
				width="100%"
				height="100%"
			/>
		</ContentLoader>
	);
};

export const Popup = ({
	show,
	setShow,
	entity,
	handleAction,
	loading,
	dynamicBtnProps,
	dynamicRemainingProps,
}) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const popupEntity = defaultPropsForPopups?.[entity];

	if (!popupEntity) return;

	const performEntityAction = async (entity) => {
		switch (entity) {
			case popupEntities.cancelPopup:
				setShow(false);
				history.push(accessReviewUrls().LANDING_PAGE);
				dispatch(clearAllReviewDetails());
				break;

			case popupEntities.cancelPopupInAddAppModal:
				dispatch(toggleAddApplicationModal(false));
				dispatch(clearModalData());
				setShow(false);
				break;

			case popupEntities.defaultReviewers:
				dispatch(updateSetReviewersToggle(false));
				setShow(false);
				break;

			case popupEntities.sendReminder:
			case popupEntities.transitionStage:
			case popupEntities.signOffPopup:
			case popupEntities.deleteApp:
			case popupEntities.archiveCertification:
				handleAction && handleAction();
				break;

			default:
				break;
		}
	};

	const controlFlowBtnProps = {
		...popupEntity.btnGroupProps,
		...dynamicBtnProps,
	};

	const btnProps = {
		...controlFlowBtnProps,
		onActionClick: () => performEntityAction(entity),
		onCancelClick: () => setShow(false),
		actionBtnLoading: loading,
	};

	const restProps = { ...popupEntity.rest, ...dynamicRemainingProps };

	return (
		<ConfirmPopup
			show={show}
			setShow={setShow}
			btnProps={btnProps}
			{...restProps}
		/>
	);
};

export const PopupOption = ({
	option,
	forceShowDivider,
	setShowConfirmPopup,
}) => {
	const { onClick, imgUrl, text, showDivider } = option;
	return (
		<React.Fragment>
			{forceShowDivider && showDivider && (
				<Divider type="type1" className="my-1" />
			)}
			<div
				className="d-flex cell-header__menu-option align-items-center cursor-pointer z-option-menu__option"
				onClick={() => {
					if (option?.showConfirmationPopup) {
						setShowConfirmPopup(option?.popupComponentEntity);
					} else {
						onClick && onClick();
					}
				}}
			>
				<img
					src={imgUrl}
					alt=""
					style={{ marginRight: 6, height: 16, width: 16 }}
				/>
				<Typography variant="button_extrasmall_medium">
					{text}
				</Typography>
			</div>
		</React.Fragment>
	);
};

export const handleCSVExport = async (
	reviewId,
	applications,
	certificationType,
	certificationStage,
	file_format
) => {
	if (isEmpty(applications)) return;
	const set = new Set();
	for (let i = 0; i < applications?.length; i++) {
		const columnArr = applications[i]?.columns;
		columnArr?.forEach((column) => {
			if (!set.has(column)) set.add(column);
		});
	}

	const exportRequestObj = {
		filter_by: [],
		quick_filter: [],
		file_type: "csv",
		file_format: file_format,
		columns_required: Array.from(set) ?? [],
		is_sample: false,
	};

	const jobName =
		certificationType === certifications.ONGOING_CERTIFICATION
			? certificationStage === certification_stage.ACTIONS
				? csvExportJobName.COMPLETED_CERT_EXPORT
				: csvExportJobName.ONGOING_CERT_EXPORT
			: csvExportJobName.COMPLETED_CERT_EXPORT;

	try {
		await generateExportReportCSV(
			{
				...exportRequestObj,
			},
			jobName,
			reviewId
		);
		trackActionSegment(
			"CSV export generated from completed campaigns list",
			{
				currentCategory: "Access Reviews",
				currentPage: "List of Certifications",
			}
		);
		ApiResponseNotification({
			responseType: apiResponseTypes?.SUCCESS,
			title: "Export in progress",
			description: "We'll mail you as soon as the export is complete.",
		});
	} catch (error) {
		ApiResponseNotification({
			responseType: apiResponseTypes?.ERROR,
			title: "Export failed",
			description:
				"Please try again after some time or contact Zluri support.",
			errorObj: error,
		});
	}
};

export const handleReportDownload = async (reviewId, reviewName) => {
	try {
		const res = await getReportUrl(reviewId);
		if (res?.status === 200 || res?.data?.file) {
			handleReportDownloadFromURL(res?.data?.file, reviewName);
			trackActionSegment(
				"PDF report generated from completed campaigns list",
				{
					currentCategory: "Access Reviews",
					currentPage: "List of Certifications",
				}
			);
		} else if (
			res?.error?.response?.data?.type === RBAC_ERROR &&
			RBAC_ERROR_STATUS_CODES.includes(res?.error?.response?.status)
		) {
			toast(<ToastNotificaion />);
		} else {
			console.log(res);
			ApiResponseNotification({
				responseType: apiResponseTypes?.SUCCESS,
				title: "Report is being generated",
				description: "We’ll email you once the report is ready",
			});
		}
	} catch (error) {
		ApiResponseNotification({
			responseType: apiResponseTypes?.ERROR,
			title: "Report could not be generated",
			description:
				"Please try again after some time or contact Zluri support.",
			errorObj: error,
		});
	}
};

const handleReportDownloadFromURL = (url, reviewName) => {
	const oReq = new XMLHttpRequest();
	oReq.open("GET", url, true);
	oReq.responseType = "blob";
	oReq.onload = function () {
		const file = new Blob([oReq.response], { type: "application/pdf" });
		const fileURL = URL.createObjectURL(file);
		const link = document.createElement("a");
		link.href = fileURL;
		link.download = `${reviewName}.pdf`;
		link.click();
		// window.open(fileURL, "_blank");
		link.remove();
		URL.revokeObjectURL(fileURL);
	};
	oReq.send();
};

export const handleCloneCampaign = (
	reviewId,
	history,
	isCertOverviewScreen,
	hash
) => {
	if (!reviewId) return;
	trackActionSegment(
		`${
			isCertOverviewScreen
				? `Clone campaign button clicked from ${hash
						.replace("details", "")
						.slice(1)} campaign overview`
				: `Clone campaign button clicked from ${hash.slice(
						1
					)} campaigns list`
		}`
	);
	history.push({
		pathname: accessReviewUrls().CLONE_CERT_LOADER,
		state: { reviewId: reviewId },
	});
};

export const handleArchiveCampaign = async (
	reviewId,
	dispatch,
	certGroup,
	redirect
) => {
	if (!reviewId || !certGroup) return;
	try {
		await updateCertStage(reviewId, certification_stage.ARCHIVED);
		await dispatch(removeCertData(reviewId, certGroup));

		redirect && redirect();

		ApiResponseNotification({
			responseType: apiResponseTypes?.SUCCESS,
			title: "Certification archived",
			description:
				"The certification is archived and will no longer be visible on the platform.",
		});
	} catch (error) {
		ApiResponseNotification({
			responseType: apiResponseTypes?.ERROR,
			title: "Archive action failed",
			description:
				"Please try again after some time or contact Zluri support.",
			errorObj: error,
		});
	}
};
