import React from "react";
import { getCellRenderer } from "./componentFactory";
import { Tooltip, Typography, Popover } from "@zluri/ui-components";
import loadable from "@loadable/component";
import PropTypes from "prop-types";

const MomentTimezone = loadable.lib(() => import("moment-timezone"));

export default function withToolTip(MainComponent, props) {
	if (props.hoverComponent) {
		return (
			<Popover
				className={`${props.hoverComponent.className} v2-tooltip--updated`}
				trigger="hover"
				content={
					<div>
						{React.createElement(
							getCellRenderer(props.hoverComponent?.type),
							{
								component: props.hoverComponent,
								...props,
							}
						)}
					</div>
				}
				placement="bottomLeft"
			>
				<div>{MainComponent}</div>
			</Popover>
		);
	}

	if (props.toolTip) {
		const dataValueForTooltip = props.data?.[props.toolTip];

		if (props?.type === "date")
			return (
				<WithDateWidget
					includeTimeZone={props.includeTimezone}
					date={dataValueForTooltip}
					format={props.format}
				>
					{MainComponent}
				</WithDateWidget>
			);

		if (dataValueForTooltip)
			return (
				<ToolTipWrapper tooltipContent={dataValueForTooltip}>
					{MainComponent}
				</ToolTipWrapper>
			);

		return <div className="w-100">{MainComponent}</div>;
	}

	return MainComponent;
}
function ToolTipWrapper({ tooltipContent, children }) {
	return (
		<Tooltip
			className={"v2-table-cell"}
			placement={"bottom"}
			content={
				<Typography
					variant="body_2_regular"
					color="white"
					style={{ textTransform: "capitalize" }}
				>
					{tooltipContent}
				</Typography>
			}
		>
			<div className="w-100">{children}</div>
		</Tooltip>
	);
}
ToolTipWrapper.propTypes = {
	children: PropTypes.elementType,
	tooltipContent: PropTypes.string.isRequired,
};
function WithDateToolTip({
	date,
	format = "D MMM, YYYY",
	includeTimeZone = false,
	children,
}) {
	return (
		<MomentTimezone>
			{({ default: moment }) => {
				const timeZoneString =
					Intl.DateTimeFormat().resolvedOptions().timeZone;
				const value = includeTimeZone
					? moment(date)?.tz(timeZoneString).format(format)
					: moment(date)?.format(format);
				return (
					<Tooltip
						className={"v2-table-cell"}
						placement={"bottom"}
						content={
							<Typography
								variant="body_2_regular"
								color="white"
								style={{ textTransform: "capitalize" }}
							>
								{value}
							</Typography>
						}
					>
						<div className="w-100">{children}</div>
					</Tooltip>
				);
			}}
		</MomentTimezone>
	);
}

WithDateToolTip.propTypes = {
	date: PropTypes.string.isRequired,
	format: PropTypes.string,
	includeTimeZone: PropTypes.bool,
	children: PropTypes.elementType,
};

function WithDateWidget({
	date,
	format = "D MMM, YYYY",
	includeTimeZone = false,
	children,
}) {
	return (
		<MomentTimezone>
			{({ default: moment }) => {
				const timeZoneString =
					Intl.DateTimeFormat().resolvedOptions().timeZone;
				const value = includeTimeZone
					? moment(date)?.tz(timeZoneString).format(format)
					: moment(date)?.format(format);
				return (
					<Popover
						className={"z-v2-date-widget-popover"}
						placement={"bottom"}
						trigger={["hover"]}
						content={
							<div style={{ padding: 8 }}>
								<Typography
									variant="body_2_bold"
									color="secondary_grey_2"
								>
									{value}
								</Typography>
							</div>
						}
					>
						<div className="w-100">{children}</div>
					</Popover>
				);
			}}
		</MomentTimezone>
	);
}

WithDateWidget.propTypes = {
	date: PropTypes.string.isRequired,
	format: PropTypes.string,
	includeTimeZone: PropTypes.bool,
	children: PropTypes.elementType,
};
