import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { ButtonCard, Container, Popover } from "@zluri/ui-components";
import AppActions from "./AppActions";
import { NotesOverview } from "../NotesOverview/NotesOverview";

import {
	fetchAppOverviewNotes,
	setSelectedTab,
} from "modules/applications/redux/Applications.actions";
import { OVERVIEW_MODAL } from "modules/applications/constants/ApplicationConstants";
import { overviewHeaderTabs } from "modules/applications/utils/OverviewV2.utils";
import { NOTES_DRAWER_TYPE } from "modules/applications/constants/OverviewV2.constants";
import ErrorWrapper from "UIComponents/Rbac/ErrorWrapper";

const Header = ({
	showCheckList,
	app,
	loaded,
	appId,
	setShowCheckList,
	setNoteDrawerState,
}) => {
	const dispatch = useDispatch();

	const [showSub, setSubShow] = useState(false);
	const [showNotes, setShowNotes] = useState(false);

	const { notes, links, checklist, sources, error } = useSelector(
		(state) => state.applications_v2?.overview
	);

	useEffect(() => {
		if (appId) {
			dispatch(fetchAppOverviewNotes(appId));
		}
	}, [appId, dispatch]);

	const toggleShowNotes = () => {
		setShowNotes((prevShowNotes) => !prevShowNotes);
	};

	const clickListeners = {
		sources: () => dispatch(setSelectedTab(OVERVIEW_MODAL.APP_SOURCES)),
		checkList: () => setShowCheckList(true),
		notes: () => {
			if (notes?.count) toggleShowNotes();
			else {
				setNoteDrawerState({ type: NOTES_DRAWER_TYPE.ADDING_NOTE });
				dispatch(setSelectedTab(OVERVIEW_MODAL.NOTES));
			}
		},
	};

	const handleSubClose = (open) => setSubShow(open || false);

	if (error) {
		return (
			<Container
				style={{ overflow: "unset" }}
				className="h-auto d-flex flex-column gap-8"
			>
				<ErrorWrapper error={error} />
			</Container>
		);
	}

	return (
		<Container
			style={{ overflow: "unset" }}
			className="h-auto d-flex flex-column gap-8"
		>
			<div className="d-flex align-items-center gap-10">
				{overviewHeaderTabs(
					{ links, notes, sources, checklist },
					loaded,
					clickListeners,
					appId
				)?.map(
					(
						{
							popover,
							className,
							data,
							editable,
							prefix,
							title,
							activeBorderColor,
							loading,
							onClick,
							isCheckList,
						},
						index
					) => (
						<React.Fragment key={index}>
							{(!isCheckList || !showCheckList) && (
								<HeaderTabWithPopover
									Component={
										<ButtonCard
											className={`${className} z_overview_header-tab`}
											onClick={onClick}
											data={data}
											editable={editable}
											prefix={{ ...prefix }}
											title={title}
											borderColor="#82A7ED"
											activeBorderColor={
												activeBorderColor
											}
											loading={loading}
										/>
									}
									popoverProps={{
										...popover,
										show: showSub,
										key: index,
										content:
											popover?.content(handleSubClose),
										placement: popover?.placement,
										onToggle: handleSubClose,
									}}
									hasPopover={popover?.content}
								/>
							)}
						</React.Fragment>
					)
				)}
				<AppActions app={app} appId={appId} />
			</div>
			{showNotes && notes?.count > 0 && (
				<NotesOverview
					appId={appId}
					notes={notes}
					showCheckList={showCheckList}
					setNoteDrawerState={setNoteDrawerState}
				/>
			)}
		</Container>
	);
};

export default Header;

export const HeaderTabWithPopover = ({
	Component,
	popoverProps,
	hasPopover,
}) => {
	if (!hasPopover) return Component;
	return (
		<Popover destroyTooltipOnHide {...popoverProps}>
			{Component}
		</Popover>
	);
};

Header.propTypes = {
	showCheckList: PropTypes.bool,
	app: PropTypes.object,
	loaded: PropTypes.bool,
	appId: PropTypes.string,
	setShowCheckList: PropTypes.func,
	setNoteDrawerState: PropTypes.func,
};

HeaderTabWithPopover.propTypes = {
	Component: PropTypes.element,
	popoverProps: PropTypes.object,
	hasPopover: PropTypes.bool,
};
