import React, { useState } from "react";
import PropTypes from "prop-types";
import {
	ruleMappingStatesDropdown,
	rulesStatus,
} from "modules/transactionMapping/constants/ruleMapping";
import styled from "styled-components";
import {
	Typography,
	Popover,
	GREY_VARIANT,
	Divider,
	Button,
	theme,
} from "@zluri/ui-components";
import moment from "moment";
import ActivateDeactivateModal from "./ActivateDeactivateModal";
import { toast } from "@zluri/ui-components";
import { useDispatch } from "react-redux";
import { getValueFromLocalStorage } from "utils/localStorage";

const StatusWrapper = styled.div`
	display: flex;
	gap: 4px;
	padding: 4px 8px;
	align-items: center;
	border-radius: 8px;
	${({ $backgroundColor }) => `background: ${$backgroundColor}`};
`;

const HeaderWrapper = styled.div`
	border-radius: 8px 8px 0px 0px;
	background: var(--Secondary_Grey-7, #f6f7fa);
	display: flex;
	width: 220px;
	padding: 8px;
	justify-content: center;
	align-items: center;
	gap: 4px;
`;

const ActivatedByWrapper = styled.div`
	display: flex;
	padding: 8px;
	flex-direction: column;
	justify-content: center;
	gap: 4px;
`;

const FlexBox = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const ButtonWrapper = styled.div`
	padding: 8px;
	display: flex;
	justify-content: center;
`;

const PopoverWrapper = styled.div`
	height: 126px;
`;

const statusObject = ruleMappingStatesDropdown.reduce((acc, curr) => {
	return {
		...acc,
		[curr.value]: curr,
	};
}, {});

const StatusRenderer = ({ data }) => {
	const { status, status_modified_by, status_modified_at } = data;
	const currentStatus = statusObject[status];
	const [openTransactionModalType, setOpenTransactionModalType] =
		useState(null);
	const dispatch = useDispatch();
	const statusChangedDate = moment(status_modified_at).format("D MMM, YYYY");
	const orgId = getValueFromLocalStorage("userInfo")?.org_id;
	const getStatusWrapper = () => (
		<StatusWrapper $backgroundColor={currentStatus.backgroundColor}>
			<img
				width={16}
				height={16}
				alt={currentStatus.label}
				src={currentStatus.icon}
				style={{
					marginRight: "4px",
				}}
			/>
			<Typography
				variant="body_1_regular"
				color={currentStatus.textColor}
			>
				{currentStatus.label}
			</Typography>
		</StatusWrapper>
	);

	if (status === rulesStatus.DRAFT) {
		return getStatusWrapper();
	}

	return (
		<>
			<Popover
				trigger={["click"]}
				content={
					<PopoverWrapper>
						<HeaderWrapper>
							<Typography
								variant="body_2_regular"
								color={GREY_VARIANT.SECONDARY_GREY_2}
							>
								Rule Status
							</Typography>
							<StatusWrapper
								$backgroundColor={currentStatus.backgroundColor}
							>
								<img
									width={8}
									height={8}
									alt={currentStatus.label}
									src={currentStatus.icon}
									style={{
										marginRight: "4px",
									}}
								/>
								<Typography
									variant="body_3_medium"
									color={currentStatus.textColor}
								>
									{currentStatus.label}
								</Typography>
							</StatusWrapper>
						</HeaderWrapper>
						<ActivatedByWrapper>
							<FlexBox key={JSON.stringify(status_modified_by)}>
								<Typography
									color={GREY_VARIANT.SECONDARY_GREY_3}
									variant="body_3_regular"
								>
									{status === rulesStatus.ACTIVE
										? "Activated by"
										: status === rulesStatus.INACTIVE
										? "Deactivated by"
										: "-"}
								</Typography>
								{status_modified_by ? (
									<Typography
										variant="body_3_bold"
										key={status_modified_by.name}
									>
										{status_modified_by.name}
									</Typography>
								) : null}
							</FlexBox>
							<FlexBox>
								<Typography
									color={GREY_VARIANT.SECONDARY_GREY_3}
									variant="body_3_regular"
								>
									{status === rulesStatus.ACTIVE
										? "Activated on"
										: status === rulesStatus.INACTIVE
										? "Deactivated on"
										: "-"}
								</Typography>
								<Typography variant="body_3_bold">
									{statusChangedDate}
								</Typography>
							</FlexBox>
						</ActivatedByWrapper>
						<Divider />
						<ButtonWrapper>
							<Button
								variant="secondary-default"
								theme={theme}
								className="w-100"
								onClick={() => {
									setOpenTransactionModalType(
										status === rulesStatus.ACTIVE
											? rulesStatus.INACTIVE
											: rulesStatus.ACTIVE
									);
								}}
							>
								Make Rule{" "}
								{status === rulesStatus.ACTIVE
									? "Inactive"
									: "Active"}
							</Button>
						</ButtonWrapper>
					</PopoverWrapper>
				}
			>
				{getStatusWrapper()}
			</Popover>

			{!!openTransactionModalType ? (
				<ActivateDeactivateModal
					openTransactionModalType={openTransactionModalType}
					setOpenTransactionModalType={setOpenTransactionModalType}
					rowData={data}
					createRulePage={false}
					onSaveCallback={({ name, status }) => {
						toast(`${name} is saved as ${status} `, {
							autoClose: true,
						});
						setTimeout(() => {
							dispatch({
								type: "GET_TABLE_DATA",
								payload: {
									entity: "spends_rules",
									intID: orgId,
									shouldRefresh: true,
									cols: [],
								},
							});
						}, 2000);
					}}
				/>
			) : null}
		</>
	);
};

export default StatusRenderer;

StatusRenderer.propTypes = {
	data: PropTypes.object,
};
