import { Select } from "antd";
import React, { useState, useRef, useMemo } from "react";
import PropTypes from "prop-types";
import { Loader } from "common/Loader/Loader";
import debounce from "lodash.debounce";
import styled from "styled-components";
import { Field } from "@zluri/ui-components";
import arrow_down from "assets/arrow-down.svg";

const StyledField = styled(Field)`
	&.z_field_wrapper {
		height: 100%;
	}
	&.z_field_wrapper-filled {
		border: 1px solid #ddd;
	}
	background-color: white;
	width: ${({ $width }) => $width};
`;
const CustomSelectWrapper = styled.div`
	position: relative;
	width: 100%;
	.prefix-icon-wrapper {
		position: absolute;
		z-index: 1;
		width: 8px;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	${({ $hasPrefixIcon }) =>
		$hasPrefixIcon
			? "&& .ant-select .ant-select-selector {padding-left: 20px;} && .ant-select-selection-search-input {padding-left:12px !important;}"
			: ""}
`;

const RulesDebouncedSelect = ({
	fetchOptions,
	debounceTimeout = 800,
	placeholder,
	multiple,
	value,
	width,
	optionRender,
	onValueSelect,
	prefixIcon,
	disabled,
}) => {
	const [fetching, setFetching] = useState(false);
	const [options, setOptions] = useState([]);
	const fetchRef = useRef(0);

	const debounceFetcher = useMemo(() => {
		const loadOptions = (value) => {
			fetchRef.current += 1;
			const fetchId = fetchRef.current;
			setOptions([]);
			setFetching(true);

			fetchOptions(value).then((newOptions) => {
				if (fetchId !== fetchRef.current) {
					// for fetch callback order
					return;
				}

				setOptions(newOptions);
				setFetching(false);
			});
		};

		return debounce(loadOptions, debounceTimeout);
	}, [fetchOptions, debounceTimeout]);

	return (
		<StyledField
			fieldType="custom"
			placeholder={placeholder}
			$width={width}
			element={
				<CustomSelectWrapper $hasPrefixIcon={!!prefixIcon}>
					{prefixIcon ? (
						<div className="prefix-icon-wrapper">{prefixIcon}</div>
					) : null}
					<Select
						{...(multiple ? { mode: "multiple" } : {})}
						size="small"
						placeholder={placeholder}
						style={{ width: "100%" }}
						bordered={false}
						filterOption={false}
						onSearch={debounceFetcher}
						notFoundContent={
							fetching ? <Loader height={50} width={50} /> : null
						}
						options={options}
						suffixIcon={<img src={arrow_down} alt="arrow down" />}
						value={value}
						optionRender={optionRender}
						showSearch
						onChange={(e, option) => {
							onValueSelect(e, option);
						}}
						disabled={disabled}
					/>
				</CustomSelectWrapper>
			}
		/>
	);
};

export default RulesDebouncedSelect;

RulesDebouncedSelect.propTypes = {
	placeholder: PropTypes.string,
	options: PropTypes.array,
	value: PropTypes.array,
	multiple: PropTypes.bool,
	width: PropTypes.string,
	optionRender: PropTypes.func,
	fetchOptions: PropTypes.func,
	debounceTimeout: PropTypes.number,
	onValueSelect: PropTypes.func,
	prefixIcon: PropTypes.node,
	disabled: PropTypes.bool,
};
