import React, { StrictMode } from "react";
import "styles";
import "./theme.scss";
import "./index.css";
import { createRoot } from "react-dom/client";

import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router/immutable";

import * as serviceWorker from "./serviceWorker";
import { store } from "./utils/store";
import { InitializeSentry } from "./utils/sentry";
import { segmentAnalytics } from "./utils/analytics";
import Auth0ProviderWithHistory from "./services/auth/auth0provider";
import { PlatformProvider } from "modules/shared/utils/platform.hook";
import history from "utils/history";

import ViewSelector from "modules/shared/containers/ViewSelector/ViewSelector";

InitializeSentry();
segmentAnalytics();

export function Index() {
	return (
		<StrictMode>
			<Provider store={store}>
				<ConnectedRouter history={history}>
					<Router history={history}>
						<Auth0ProviderWithHistory>
							<PlatformProvider>
								<ViewSelector />
							</PlatformProvider>
						</Auth0ProviderWithHistory>
					</Router>
				</ConnectedRouter>
			</Provider>
		</StrictMode>
	);
}

const root = createRoot(document.getElementById("root"));
root.render(<Index />);

serviceWorker.unregister();
