import error_nocircle from "assets/error_nocircle.svg";
import deleteIcon from "assets/icons/delete.svg";
import emptyusers from "assets/workflow/emptyusers.svg";
import GetImageOrNameBadge from "common/GetImageOrNameBadge";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MONTH } from "utils/DateUtility";
import { Button, BUTTON_TYPE } from "../../../../UIComponents/Button/Button";
import { editWorkflowUsers } from "../../redux/workflow";
import "./compileworkflow.css";
const UsersSelector = ({
	setCurrentUser,
	currentUser,
	setCompiling,
	entity,
}) => {
	const users = useSelector(
		(state) => state[entity || "workflows"].workflow.users || []
	);
	const workflow_id = useSelector(
		(state) => state[entity || "workflows"].workflow.id
	);
	const workflow = useSelector(
		(state) => state[entity || "workflows"].workflow
	);

	const compiledExecDocs = useSelector(
		(state) => state[entity || "workflows"].compiledExecDocs
	);

	useEffect(() => {
		setCurrentUser(users[0]);
	}, [users]);

	const dispatch = useDispatch();

	const hasError = (compiled_doc) => {
		for (let node of compiled_doc.nodes) {
			if (!node.isSchemaValid) {
				return true;
			}
		}
		return false;
	};
	const errorsCount = () => {
		let count = 0;
		for (let compiled_doc of compiledExecDocs) {
			if (compiled_doc.has_action_error) {
				count++;
			}
		}
		return count;
	};

	const getName = (workflow, users) => {
		const workflow_type = workflow?.type || "";
		const userCount = users.length - 1;
		const date = new Date();
		const formattedDate = `${date.getDate()} ${MONTH[date.getMonth()]}`;
		const selectedUserName =
			users[0].user_name?.trim() || users[0].name?.trim();
		return `${workflow_type} ${selectedUserName}${
			userCount > 0 ? ` and ${userCount} others` : ""
		} on ${formattedDate}`;
	};

	const deleteSelectedUser = (user) => {
		setCompiling(true);
		const updatedUsers = users.filter(
			(current_user) =>
				current_user._id.toString() !== user.user_id.toString()
		);

		const updated_user_ids = updatedUsers.map((user) => user._id);
		const name = getName(workflow, updatedUsers);
		dispatch(
			editWorkflowUsers(
				workflow_id,
				{ users_ids: updated_user_ids, name: name },
				false,
				workflow.source
			)
		);
	};

	const changeUser = (data) => {
		setCurrentUser(data);
	};

	const usersCount = (
		<>
			{compiledExecDocs?.length && (
				<div
					className="d-flex flex-column"
					style={{ margin: "10px 0px 0px 10px" }}
				>
					<span style={{ fontSize: "10px", fontWeight: "700" }}>
						{compiledExecDocs?.length} USERS{" "}
					</span>
					{errorsCount() > 0 && (
						<small
							style={{ color: " #FE6955", fontSize: "8px" }}
						>{`${errorsCount()} of ${
							compiledExecDocs?.length
						} have setup errors`}</small>
					)}
				</div>
			)}
		</>
	);
	const selectedUsersUI = (
		<>
			<div
				style={{
					borderRadius: "8px",
					flexDirection: "column",
					backgroundColor: "#FFFFFF",
					minHeight: "20px",
					height: "85%",
				}}
				className="d-flex flex-1"
			>
				{usersCount}
				<div className="d-flex justify-content-center">
					<hr
						style={{
							color: "#EBEBEB",
							width: "100%",
							margin: "10px",
						}}
					/>
				</div>
				{compiledExecDocs && compiledExecDocs?.length === 0 && (
					<div
						style={{ flexDirection: "column" }}
						className="d-flex justify-content-center flex-1 align-items-center"
					>
						<img alt="" src={emptyusers} />
					</div>
				)}
				<div style={{ overflowY: "auto" }}>
					{compiledExecDocs &&
						compiledExecDocs?.length > 0 &&
						compiledExecDocs?.map((user, index) => (
							<div
								className={`d-flex p-2 ${
									currentUser.user_id === user.user_id
										? "selected_user_wrapper"
										: ""
								}`}
								style={{ margin: " 0px 10px 0px 10px" }}
								key={index + user?._id}
								onClick={() => changeUser(user)}
							>
								<div className="d-flex flex-row align-items-center flex-1">
									<GetImageOrNameBadge
										key={index}
										name={user.user_name}
										url={
											user.user_logo || user?.user_profile
										}
										width={25}
										height={25}
										imageClassName={
											index === 0
												? " mr-1 z-index-1  img-circle white-bg"
												: null +
												  "img-circle avatar  mr-1 z-index-1"
										}
										nameClassName={
											index === 0
												? " mr-1 z-index-1  img-circle white-bg"
												: null +
												  "img-circle avatar  mr-1 z-index-1"
										}
									/>
									<div className="mr-2">
										<span
											style={{
												display: "block",
												fontSize: "13px",
												fontWeight: "400",
											}}
											className="text-truncate-10vw"
										>
											{user?.user_name}
											{hasError(user) && (
												<img
													src={error_nocircle}
													width="20px"
													height="20px"
												/>
											)}
										</span>
										<span
											style={{
												display: "block",
												fontSize: "11px",
												fontWeight: "400",
											}}
											className="grey-1 text-truncate-10vw"
										>
											{user?.email || user?.user_email}
										</span>
									</div>
								</div>

								<Button
									style={{
										fontSize: "10px",
										fontWeight: "400",
										alignItems: "center",
									}}
									disabled={users.length === 1}
									className="p-0 d-flex title-text justify-content-end ml-2"
									type={BUTTON_TYPE.LINK}
									onClick={() => {
										deleteSelectedUser(user);
									}}
								>
									<img
										alt=""
										src={deleteIcon}
										height={15}
										width={15}
									/>
								</Button>
							</div>
						))}
				</div>
			</div>
		</>
	);

	return <>{selectedUsersUI}</>;
};
export default UsersSelector;
