import React from "react";
import { Tooltip, Typography } from "@zluri/ui-components";

type ITextTooltipPlacement = "bottom" | "top";
interface ITextTooltip {
	title: string;
	children: React.ReactNode;
	placement?: ITextTooltipPlacement;
}
function TextTooltip({ title, children, placement = "bottom" }: ITextTooltip) {
	return (
		<Tooltip
			placement={placement}
			delay={{ show: 250, hide: 400 }}
			content={() => (
				<Typography variant="body_2_regular" color="white">
					{title}
				</Typography>
			)}
			trigger={["hover"]}
		>
			{children}
		</Tooltip>
	);
}
export default TextTooltip;
