import { Typography, Tooltip } from "@zluri/ui-components";
import React from "react";
import TableHeaderOptionsMenu from "./cellRenderer/TableHeaderOptionsMenu";
import InfoIcon from "assets/v2tables/info-icon.svg";
import SortAscending from "assets/v2tables/sort-ascending.svg";
import SortDescending from "assets/v2tables/sort-descending.svg";

export const generateHeader = ({ column, onSort }) => {
	const {
		header,
		group_name: groupName,
		columnDescription: headerTooltipText,
		sortInfo,
		enabledFilters,
		sortKey,
	} = column;

	const isSortColumn = sortInfo?.find((sort) => sort.name === sortKey);

	const renderTooltip = (
		<div className="black-tooltip">
			<div
				className="d-flex flex-column align-items-start"
				style={{ maxWidth: 220, padding: 4 }}
			>
				<Typography
					variant="body_1_bold"
					color="white"
					className="text-transform-capitalize"
				>
					{header}
				</Typography>
				<Typography
					variant="body_2_regular"
					color="white"
					style={{ textAlign: "left" }}
				>
					{headerTooltipText}
				</Typography>
			</div>
		</div>
	);

	return (
		<div
			className="cell-header d-flex justify-content-between align-items-center"
			style={{ maxWidth: "inherit" }}
		>
			<div className="cell-header-title">
				<div className="d-flex">
					{groupName && (
						<Typography
							variant="alerttag_extrasmall_semibold"
							color="secondary_grey_2"
							className="table-header__width-ellipses column-header-name__ellipsis pl-1"
							style={{
								background: "#E8F0FC",
								borderRadius: 8,
								width: "fit-content",
								whiteSpace: "nowrap",
								padding: "2px 4px",
							}}
						>
							{groupName}
						</Typography>
					)}
				</div>
				<div style={{ maxWidth: "inherit" }}>
					<Typography
						variant="dataLabel_small_semibold"
						color="secondary_grey_2"
						className="table-header__width-ellipses column-header-name__ellipsis pl-1 text-transform-capitalize"
						style={{
							maxWidth: "inherit",
						}}
					>
						{header}
					</Typography>
				</div>
			</div>
			<div className="d-flex">
				<div className="header--sort_icon">
					{isSortColumn && (
						<>
							{isSortColumn?.dir === 1 ? (
								<img src={SortAscending} alt="" />
							) : (
								<img src={SortDescending} alt="" />
							)}
						</>
					)}
				</div>
				<div className="cell-header-info-bar">
					{headerTooltipText && (
						<Tooltip
							placement="top"
							delay={{ show: 250, hide: 400 }}
							content={renderTooltip}
							trigger={["hover", "focus"]}
						>
							<div className="column-header-info__icon">
								<img src={InfoIcon} alt="info" />
							</div>
						</Tooltip>
					)}
					<TableHeaderOptionsMenu
						isFilterable={column.isFilterable}
						isSortable={column.sortable}
						columnSortState={isSortColumn?.dir}
						enabledFilters={enabledFilters}
						onSort={onSort}
						sortKey={sortKey}
					/>
				</div>
			</div>
		</div>
	);
};
