import React, { useState } from "react";
import {
	BLUE_VARIANT,
	BODY_VARIANT,
	BUTTON_VARIANT,
	NoDataCard,
	SecondaryButtonVariant,
	Typography,
} from "@zluri/ui-components";
import { Button, theme } from "@zluri/ui-components";
import { bulkSendRemindersToReviewers } from "modules/AccessReview/service/AccessReview.service";
import { ProgressBarLoader } from "common/Loader/ProgressBarLoader";
import { ShowToast } from "containers/v2table/BulkEdit/ShowToast";
import { toast } from "react-toastify";
import { toastPropsForSingleReviewer } from "modules/AccessReview/constants/constants";
import { useDispatch, useSelector } from "react-redux";
import { IsCertOwner } from "modules/AccessReview/util/util";
import { TriggerIssue } from "utils/sentry";
import { trackActionSegment } from "modules/shared/utils/segment";
import { getSegmentFromURL } from "@zluri/ui-components";

export function ActionButtonRenderer({ component, data, ...props }) {
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const variantMapper = {
		"secondary-default": SecondaryButtonVariant.secondary_default,
	};
	// Can make a constant file for these

	const handleClick = () => {
		dispatch({
			type: "UPDATE_RECORD",
			payload: {
				rowIndex: props.rowIndex,
				columnIndex: props.columnIndex,
				skipApi: true,
			},
		});

		actionMapper[component.entity] &&
			actionMapper[component.entity]
				?.api()
				.then((res) => {
					actionMapper[component.entity]?.after_api(res);

					dispatch({
						type: "RECORD_UPDATED",
						payload: {
							entity: props.entity,
							rowIndex: props.rowIndex,
							columnIndex: props.columnIndex,
							index: props.rowIndex,
						},
					});
				})
				.catch((err) => {
					TriggerIssue(
						actionMapper[component.entity]?.errorMessage,
						response.error
					);
				});
	};

	const actionMapper = {
		reviewer_table: {
			api: () => {
				const certification_id = getSegmentFromURL(2);
				let reqObj = {
					reviewer_ids: [data._id],
				};
				trackActionSegment(
					"Send reminder button clicked for individual reviewer",
					{
						currentCategory: "Reviewers Progress Screen",
						currentPage: "Send Reminder Popup",
					}
				);
				return bulkSendRemindersToReviewers(certification_id, reqObj);
			},
			after_api: (res) => {
				toast(
					<ShowToast
						type={
							res?.status === "success"
								? toastPropsForSingleReviewer?.reminderSuccess
								: toastPropsForSingleReviewer?.reminderCoolOffPeriod
						}
					/>
				);
			},
			disabled_state: () => {
				const reviewData = useSelector(
					(state) => state.accessReview?.review.data
				);

				const isCertOwner = IsCertOwner(
					reviewData?.reviewOwner?.user_id
				);

				return data.pending_reviews === 0 || !isCertOwner;
			},
			errorMessage: "Error while sending reminder",
		},
	};
	return (
		<>
			<Button
				variant={variantMapper[component.buttonVariant]}
				theme={theme}
				onClick={() => {
					handleClick();
				}}
				className="ml-auto"
				disabled={
					actionMapper[component.entity]?.disabled_state() || loading
				}
			>
				<Typography
					variant={BUTTON_VARIANT.BUTTON_EXTRA_SMALL_MEDIUM}
					color={BLUE_VARIANT.PRIMARY_BLUE_DARK_1}
					className="d-flex align-items-center"
				>
					<div className="text-nowrap">
						{component.buttonText || `Show progress by reviewers`}
					</div>
				</Typography>
			</Button>
		</>
	);
}
