import React from "react";
import rbacNotificationSvg from "../../assets/rbac/rbac_notification.svg";
import "./ToastNotification.css";
const ToastNotificaion = () => {
	return (
		<div className="toast_notification-container">
			<div className="toast_notification-content">
				<img src={rbacNotificationSvg} alt="" />
				<div className="toast_notification-text">
					<h6>You are not authorized to take this action</h6>
					<span>
						Please reach out to your administrator to grant you
						additional permission
					</span>
				</div>
			</div>
		</div>
	);
};
export default ToastNotificaion;
