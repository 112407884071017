import { Typography } from "@zluri/ui-components";
import React, { useId } from "react";

export default function SingleSelectFilter({ onChange, value, filter }) {
	const id = useId();
	return (
		<div className="d-flex flex-column">
			{filter?.field_labels?.map((v, idx) => (
				<div className="d-flex align-items-center mb-3" key={v}>
					<input
						className="cursor-pointer"
						style={{ height: 14 }}
						type="radio"
						name={v}
						label={v}
						id={id}
						checked={filter?.field_values?.[idx] === value?.[0]}
						value={v}
						onClick={() => onChange([filter.field_values[idx]])}
					/>
					<label
						style={{
							fontFamily: "Sora",
							fontStyle: "normal",
							fontWeight: 600,
							fontSize: 10,
							lineHeight: "150%",
							marginBottom: 0,
							marginLeft: 9,
							textAlign: "center",
							color: "#484848",
						}}
						htmlFor={id}
					>
						<Typography
							variant={
								filter?.field_values?.[idx] === value?.[0]
									? "button_extrasmall_semibold"
									: "button_extrasmall_regular"
							}
							color="secondary_grey_2"
						>
							{v}
						</Typography>
					</label>
				</div>
			))}
		</div>
	);
}
