import { client } from "../../utils/client";
import { clientV2 } from "../../utils/client";

export async function fetchViewsList(screen_tag, appId) {
	let url = `views?screen_tag=${screen_tag}`;
	if (appId) {
		url = `views?screen_tag=${screen_tag}&appId=${appId}`;
	}
	const response = await client.get(url, {});
	return response.data;
}

export async function saveCustomViewService(reqBody) {
	const response = await clientV2.post("view", reqBody);
	return response.data;
}
export async function updateCustomViewService(viewId, reqBody) {
	const response = await clientV2.post(`update-view/${viewId}`, reqBody);
	return response.data;
}

export async function deleteCustomView(viewId) {
	const response = await clientV2.delete(`view/${viewId}`);
	return response.data;
}
export async function saveAsDefaultView(viewId, screenTag = "1", appId) {
	let reqBody = {
		screen_tag: screenTag,
		is_default: true,
	};
	if (appId) reqBody.appId = appId;
	const response = await clientV2.put(`view/${viewId}/default`, reqBody);
	return response.data;
}

export async function patchView(viewId, patchObj) {
	const response = await client.patch(`view/${viewId}`, patchObj);
	return response.data;
}

export async function getViewsv2(screen_tag, appId) {
	let url = `views?screen_tag=${screen_tag}`;
	if (appId) {
		url = `views?screen_tag=${screen_tag}&appId=${appId}`;
	}
	const response = await clientV2.get(url, {});
	return response.data;
}

export async function resetView(viewId) {
	const response = await clientV2.put(`view/${viewId}/reset`);
	return response.data;
}

export async function overrideView(screentag, reqBody) {
	const response = await clientV2.put(
		`view/${reqBody?.view_id}/override`,
		reqBody
	);
	return response.data;
}

export async function hideorUnhideView(screentag, reqBody, hideStatus) {
	let reqObj = {
		is_hidden: !reqBody.is_hidden,
	};
	const response = await clientV2.put(
		`view/${reqBody?.view_id}/hide-view`,
		reqObj
	);
	return response.data;
}
