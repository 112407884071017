import { Typography } from "@zluri/ui-components";
import React from "react";

export default function Link({ data, component }) {
	const valuefromComponent = component.valueKey;
	const link = Array.isArray(valuefromComponent)
		? valuefromComponent.reduce((_, x) => _?.[x], data)
		: data[valuefromComponent];
	if (!link) {
		return (
			<Typography color="secondary_grey_1" variant="body_1_regular">
				-
			</Typography>
		);
	}
	return (
		<span
			style={{
				borderBottom:
					component?.hoverComponent && "1px dashed #000!important",
				padding: component?.hoverComponent && "4px 5px",
				...component.style,
			}}
			className="v2table__cell-text v2table__cell-link"
		>
			{link ? (
				<Typography variant="body_1_medium" color="primary_blue_dark_1">
					{link}
				</Typography>
			) : (
				<div className="w-100 d-flex justify-content-center align-items-center">
					<span>-</span>
				</div>
			)}
		</span>
	);
}
