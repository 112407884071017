import React, { useEffect, useState } from "react";
import { getAllCustomField } from "modules/custom-fields/redux/custom-field";
import { useDispatch, useSelector } from "react-redux";
import { InlineEditCFValue } from "./EntityFieldRenderer";
import TextField from "./TextField";
import SelectRenderer from "./SelectRenderer";
import { patchApplication, patchVendors } from "services/api/applications";
import BooleanRenderer from "./BooleanRenderer";
import { patchUser } from "services/api/users";
import DateFieldRenderer from "./DateFieldRenderer";
import { patchDepartments } from "services/api/departments";
import { AddEditCustomFieldValue } from "modules/custom-fields/components/NewCustomFieldSectionInOverview";
import { patchSingleContract } from "services/api/licenses";
import { patchTransaction } from "services/api/transactions";

const fieldRendererMap = {
	reference: InlineEditCFValue,
	text: TextField,
	select: SelectRenderer,
	data: TextField,
	bool: BooleanRenderer,
	date: DateFieldRenderer,
};
const patchAPImap = {
	applications: patchApplication,
	users: patchUser,
	departments: patchDepartments,
	vendors: patchVendors,
	contracts: patchSingleContract,
	transactions: patchTransaction,
};

export default function CustomFieldRenderer({
	data,
	column,
	entity,
	handleRefresh,
	idx,
}) {
	const dispatch = useDispatch();
	const [field, setField] = useState();
	const { customFields } = useSelector((state) => state);

	useEffect(() => {
		if (Object.keys(customFields).length && entity) {
			const customField = customFields[entity]?.find(
				(field) => field.name === column.group_name
			);
			if (customField) {
				setField(customField);
			}
		}
	}, [customFields, idx]);

	useEffect(() => {
		if (!Object.keys(customFields)?.length) dispatch(getAllCustomField());
	}, []);

	const getCfValueData = (customField) => {
		const index = data?.custom_fields?.findIndex(
			(cfData) => cfData?.field_id === customField?.id
		);
		if (index > -1) {
			return data?.custom_fields?.[index];
		} else {
			return null;
		}
	};

	return (
		<>
			{field && fieldRendererMap[field.type] && (
				<>
					<AddEditCustomFieldValue
						customField={{ ...field }}
						cfValue={getCfValueData({ ...field })?.field_value}
						cfDisplayValue={
							getCfValueData({ ...field })?.custom_field_name
						}
						patcher={(patchObj) =>
							patchAPImap[entity](data?._id, patchObj)
						}
						onPatchSuccess={handleRefresh}
						isTableCell={true}
					/>
				</>
			)}
		</>
	);
}
