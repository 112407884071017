import styled from "styled-components";
import React from "react";
import mappingSuccessful from "../../assets/mapping-successful.svg";
import PropTypes from "prop-types";
import { Typography, GREY_VARIANT } from "@zluri/ui-components";

const SuccessBanner = styled.div`
	padding: 8px;
	border-radius: 8px;
	background: var(--accent-green-3, #e7f8e8);
	display: flex;
	align-items: center;
	gap: 10px;
`;

const ModalSuccessBanner = ({ textComponent }) => {
	return (
		<>
			<SuccessBanner>
				<img alt="Mapping successful" src={mappingSuccessful} />
				<Typography
					variant="body_2_regular"
					color={GREY_VARIANT.SECONDARY_GREY_3}
				>
					{textComponent}
				</Typography>
			</SuccessBanner>
		</>
	);
};

export default ModalSuccessBanner;

ModalSuccessBanner.propTypes = {
	textComponent: PropTypes.node,
};
