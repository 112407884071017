import React from "react";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import plus from "assets/icons/plus.svg";

import { Loader } from "common/Loader/Loader";
import { NameBadge } from "common/NameBadge";
import {
	checkSpecialCharacters,
	searchAllApps,
	searchAllUsersV2,
	searchAppCategories,
	searchUsers,
} from "services/api/search";
import { client } from "utils/client";
import { useDispatch } from "react-redux";
import { debounce, unescape } from "utils/common";
import close from "assets/v2tables/pageHeader/close.svg";
import completeiconimg from "components/Applications/Overview/completeicon.svg";
import search from "assets/v2tables/editEntity/search.svg";
import "components/Applications/Overview/Overview.css";
import "./select_entity.css";
import {
	Button,
	Typography,
	theme,
	Divider,
	Popover,
} from "@zluri/ui-components";
import { searchUserToMap } from "modules/Agents/constants/AgentConstants";
import { TriggerIssue } from "utils/sentry";
import { customApi } from "./selectEntityCustomApiHelper";
import { getAllVendorsV2 } from "services/api/applications";
import { getSearchReqObj } from "common/infiniteTableUtil";
import { Tooltip as UITooltip } from "@zluri/ui-components";
import redexclam from "assets/employee/redexclam.svg";
import { isEmpty } from "underscore";
import { searchUsersEmployeeDashboard } from "services/api/employeeDashboard";
import { getSegmentFromURL } from "@zluri/ui-components";
import { useTableContext } from "../TableContext/context";
import { searchAppPlaybooks } from "modules/Optimization/service/OptimizationV2.api";
import pencil from "assets/icons/edit-pen.svg";
import cn from "classnames";

const apiAndOtherInfo = {
	user: {
		searchAPI: searchUsers,
		placeholder: "Search Users",
		id_key: "user_id",
		name_key: "user_name",
		image_key: "profile_img",
		email_key: "user_email",
	},
	applications: {
		searchAPI: searchAllApps,
		placeholder: "Search Applications",
		id_key: "app_id",
		name_key: "app_name",
		image_key: "app_logo",
	},
	categories: {
		searchAPI: searchAppCategories,
		placeholder: "Search Categories",
		id_key: "sub_category_id",
		name_key: "name",
	},
	agentUser: {
		searchAPI: (query = "", cancelToken) => {
			searchUserToMap.filter_by[0].field_values = [`${query}`];
			return searchAllUsersV2(searchUserToMap, cancelToken);
		},
		placeholder: "Search Users",
		id_key: "user_id",
		name_key: "user_name",
		image_key: "profile_img",
		email_key: "user_email",
		uuid: "uuid",
	},
	vendor: {
		searchAPI: (query = "") => {
			const ReqBody = {
				columns: [],
				filter_by: [],
				sort_by: [],
			};
			const searchobj = getSearchReqObj(
				query,
				"vendor_name",
				"Vendor Name"
			);
			ReqBody.filter_by = [searchobj];
			return getAllVendorsV2(ReqBody, 0, 30);
		},
		placeholder: "Search Vendors",
		id_key: "vendor_id",
		name_key: "vendor_name",
	},
	certReviewer: {
		searchAPI: (query, cancelToken, compact, isEmployee) => {
			return isEmployee
				? searchUsersEmployeeDashboard(query, cancelToken, compact)
				: searchUsers(query, cancelToken, compact);
		},
		placeholder: "Search Users",
		id_key: "user_id",
		name_key: "user_name",
		image_key: "profile_img",
		email_key: "user_email",
		blockedOption: (searchUserId, referenceUserId) => {
			return searchUserId === referenceUserId;
		},
		blockedOptionText: "Users and their reviewers cannot be the same",
	},
	playbook: {
		searchAPI: (query, cancelToken) => {
			const appId = getSegmentFromURL(2);
			return searchAppPlaybooks(appId, query, cancelToken);
		},
		placeholder: "Search Playbook",
		id_key: "_id",
		name_key: "name",
	},
};

export default function SelectEntity(props) {
	const defaultState = {
		id: "",
		name: "",
		image: "",
	};

	let [value, setValue] = useState({ id: "", value: "", image: "" });
	const [editText, setEditText] = useState("");
	const [showSuggestions, setShowSuggestions] = useState(false);
	const [suggestions, setSuggestions] = useState([]);
	const [loading, setloading] = useState(true);
	const dispatch = useDispatch();
	const tableData = useSelector((state) => state.v2Table[props.entity]);
	const [submitting, setSubmitting] = useState(false);
	const [submitted, setSubmitted] = useState(false);
	const cancelToken = useRef();
	const editFieldRef = useRef();
	const [activeOption, setActiveOption] = useState(0);
	const target = useRef(null);
	const inputRef = useRef(null);
	let optionsRef = useRef(null);
	let isEmployee = getSegmentFromURL(1) === "user";

	window.optionsRef = optionsRef;

	const handleKeyDown = (e) => {
		switch (e.key) {
			case "ArrowUp":
				if (
					suggestions.length > 0 &&
					activeOption > 0 &&
					suggestions.length > 0
				) {
					setActiveOption((opt) => opt - 1);
					optionsRef.current.children[
						activeOption - 1
					].scrollIntoView({
						behavior: "smooth",
						block: "nearest",
						inline: "start",
					});
				}
				break;
			case "ArrowDown":
				if (
					suggestions.length > 0 &&
					activeOption < suggestions.length - 1
				) {
					setActiveOption((opt) => opt + 1);
					// optionsRef.current
					optionsRef.current.scrollBy({
						top: 20,
						behavior: "smooth",
					});
					optionsRef.current.children[
						activeOption + 1
					].scrollIntoView({
						behavior: "smooth",
						block: "nearest",
						inline: "start",
					});
				}
				break;
			case "Enter":
				if (
					!(
						apiAndOtherInfo[props.component?.entity]
							.blockedOption &&
						apiAndOtherInfo[props.component?.entity].blockedOption(
							suggestions[activeOption][
								apiAndOtherInfo[props.component?.entity].id_key
							],
							props.data.user_id
						)
					)
				) {
					handleSelect(suggestions[activeOption]);
				}

				break;
			default:
				break;
		}
	};

	const handleEdit = (text) => {
		setEditText(text);
		if (cancelToken.current) cancelToken.current.cancel();
		if (text.length === 0) {
			setShowSuggestions(false);
			setSuggestions([]);
		}

		if (text.length > 1) {
			if (checkSpecialCharacters(text, true)) {
				setSuggestions([]);
				setShowSuggestions(true);
				setloading(false);
			} else {
				setShowSuggestions(true);
				setloading(true);
				cancelToken.current = client.CancelToken.source();
				generateSuggestions(text, cancelToken.current);
			}
		}
	};

	const generateSuggestions = useCallback(
		debounce((query, cancelToken) => {
			apiAndOtherInfo[props.component?.entity]
				.searchAPI(query, cancelToken, true, isEmployee)
				.then((res) => {
					setSuggestions(res.results || res.data);
					setloading(false);
				})
				.catch((err) => {
					TriggerIssue(
						"There was an error while generating suggestions",
						err
					);
				});
		}, 200),
		[]
	);

	const handleSelect = (selectedValue) => {
		if (props?.localUpdate) {
			props?.onSelect?.(selectedValue);
			return;
		}
		setEditText(
			selectedValue[apiAndOtherInfo[props.component?.entity]?.name_key]
		);
		handleSave(selectedValue);
	};

	const handleSave = async (selectedValue) => {
		if (props.component?.customApi) {
			await customApi[props.component.customApi]({
				selectedValue,
				props,
				apiAndOtherInfo,
				dispatch,
				tableData,
			});
			return;
		}

		let patchObj = {};
		let id = props.data?._id;
		if (editText.length === 0) {
			setValue({ ...defaultState });
			patchObj = {
				patches: [
					{
						op: "delete",
						field: props.fieldName,
						value: null,
					},
				],
			};
		} else {
			setValue({
				id: selectedValue[
					apiAndOtherInfo[props.component?.entity]?.id_key
				],
				name: selectedValue[
					apiAndOtherInfo[props.component?.entity]?.name_key
				],
				image: selectedValue[
					apiAndOtherInfo[props.component?.entity]?.image_key
				],
			});
			patchObj = {
				patches: [
					{
						op: "replace",
						field: props.component?.patchKey,
						value: selectedValue[
							apiAndOtherInfo[props.component?.entity]?.id_key
						],
					},
				],
			};
		}

		// setSubmitting(true);

		try {
			if (id) {
				const { updateKeys } = props.component;
				if (
					updateKeys &&
					updateKeys?.id &&
					updateKeys?.name &&
					updateKeys?.image
				) {
					props.handleUpdate(id, patchObj, {
						[updateKeys.id]:
							selectedValue[
								apiAndOtherInfo[props.component?.entity]?.id_key
							],
						[updateKeys.name]:
							selectedValue[
								apiAndOtherInfo[props.component?.entity]
									?.name_key
							],
						[updateKeys.image]:
							selectedValue[
								apiAndOtherInfo[props.component?.entity]
									?.image_key
							],
					});
				}
			}
		} catch (err) {
			TriggerIssue("Error updating application owner:", err);
			setSubmitting(false);
			setSubmitted(true);
			setTimeout(() => {
				setSubmitted(false);
			}, 300);
			setValue({
				id: props.userId,
				name: props.userName,
				image: props.userImage,
			});
		}
	};

	const handleClearSelection = async (vendorId) => {
		await customApi?.removeVendorTask({
			vendorId,
			props,
			dispatch,
		});
	};

	useEffect(() => {
		inputRef.current.focus();
	}, []);

	const showClearAll = (() => {
		const showForCustomApi = {
			vendorTask: props.data?.vendor_name,
		};
		return (
			Object.keys(showForCustomApi).includes(
				props.component?.customApi
			) && !isEmpty(showForCustomApi[props.component?.customApi])
		);
	})();

	return (
		<>
			<div
				className="select__entity-list"
				style={{ padding: 5, ...props?.containerStyle }}
				ref={target}
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
				}}
			>
				<div
					ref={editFieldRef}
					className="d-flex align-items-center border-radius-6 select-entity-input-container"
				>
					<img
						src={search}
						className="ml-1"
						alt="search"
						height={12}
						width={12}
					/>
					<input
						type="text"
						ref={inputRef}
						className="border-0 select-entity-input-search"
						style={{
							flexGrow: 1,
							backgroundColor: "inherit",
							width: 140,
							height: 24,
						}}
						placeholder={`${
							apiAndOtherInfo[props.component?.entity].placeholder
						}`}
						value={editText}
						onKeyDown={handleKeyDown}
						onChange={(e) => {
							window.optionsRef = optionsRef;
							if (e.keyCode === 40) {
								optionsRef.current.focus();
							}
							handleEdit(e.target.value);
						}}
					/>

					{submitting && (
						<div className="d-flex align-items-center mr-2">
							<Spinner
								animation="border"
								variant="light"
								bsPrefix="my-custom-spinner"
								className="my-custom-spinner"
							/>
						</div>
					)}
					{submitted && (
						<div className="d-flex align-items-center mr-2">
							<img src={completeiconimg} />
						</div>
					)}
					{!submitting && !submitted && (
						<>
							<button
								onClick={() => {
									setShowSuggestions(false);
									setEditText("");
									props.setPreviousState &&
										props.setPreviousState();
								}}
								className="overview__middle__topconttext2__EditCategory__button1"
							>
								<img
									src={close}
									height={12}
									width={12}
									alt="close"
								/>
							</button>
						</>
					)}
				</div>

				{showSuggestions && (
					<div
						style={{
							background: "#FFFFFF",
							// border: "1px solid #2266E2",
						}}
						role="list"
						tabIndex="0"
					>
						{loading ? (
							<>
								<div className="quick-edit-menu-item">
									<Loader height={60} width={60}></Loader>
								</div>
							</>
						) : (
							<div
								ref={optionsRef}
								className={
									props?.hideScrollAndDivider &&
									"hide-scrollbar"
								}
								style={{
									overflow: "auto",
									maxHeight: props?.optionsMaxHeight ?? 250,
								}}
							>
								{suggestions?.length > 0 ? (
									suggestions.map((option, index) =>
										props?.optionsFormatter ? (
											props?.optionsFormatter(
												option,
												handleSelect
											)
										) : (
											<>
												<div
													role="listitem"
													tabIndex={index}
													className={`select__entity-suggestions__option my-1 ${
														index === activeOption
															? "select__entity-suggestions__option-active"
															: ""
													}`}
													onClick={() => {
														if (
															!(
																apiAndOtherInfo[
																	props
																		.component
																		?.entity
																]
																	.blockedOption &&
																apiAndOtherInfo[
																	props
																		.component
																		?.entity
																].blockedOption(
																	option[
																		apiAndOtherInfo[
																			props
																				.component
																				?.entity
																		].id_key
																	],
																	props.data
																		.user_id
																)
															)
														) {
															handleSelect(
																option
															);
															setShowSuggestions(
																false
															);
														}
													}}
													// ref={(input) => suggestionsRef(input)}
												>
													<div className="d-flex align-items-center w-100">
														{option[
															apiAndOtherInfo[
																props.component
																	?.entity
															].image_key
														] ? (
															<img
																src={unescape(
																	option[
																		apiAndOtherInfo[
																			props
																				.component
																				?.entity
																		]
																			.image_key
																	]
																)}
																width="16"
																className="mr-2"
																alt=""
															/>
														) : (
															<NameBadge
																name={
																	option[
																		apiAndOtherInfo[
																			props
																				.component
																				?.entity
																		]
																			?.name_key
																	]
																}
																width={16}
																className="mr-2"
															/>
														)}
														<div
															className="d-flex flex-column"
															style={{
																opacity:
																	apiAndOtherInfo[
																		props
																			.component
																			?.entity
																	]
																		.blockedOption &&
																	apiAndOtherInfo[
																		props
																			.component
																			?.entity
																	].blockedOption(
																		option[
																			apiAndOtherInfo[
																				props
																					.component
																					?.entity
																			]
																				.id_key
																		],
																		props
																			.data
																			.user_id
																	)
																		? 0.6
																		: 1,
															}}
														>
															<div
																className={
																	"truncate_10vw"
																}
															>
																<Typography
																	variant="button_extrasmall_medium"
																	color="secondary_grey_2"
																>
																	{
																		option[
																			apiAndOtherInfo[
																				props
																					.component
																					?.entity
																			]
																				.name_key
																		]
																	}
																</Typography>
															</div>
															{!props?.hideEmail && (
																<div
																	className={
																		"truncate_10vw "
																	}
																>
																	<Typography
																		variant="body_3_regular"
																		color="secondary_grey_3"
																	>
																		{
																			option[
																				apiAndOtherInfo[
																					props
																						.component
																						?.entity
																				]
																					.email_key
																			]
																		}
																	</Typography>
																</div>
															)}
														</div>
														{apiAndOtherInfo[
															props.component
																?.entity
														]?.blockedOption &&
															apiAndOtherInfo[
																props.component
																	?.entity
															].blockedOption(
																option[
																	apiAndOtherInfo[
																		props
																			.component
																			?.entity
																	].id_key
																],
																props.data
																	.user_id
															) && (
																<>
																	<UITooltip
																		placement="top"
																		className="custom_blocked_option_tooltip"
																		content={
																			<div
																				className="text_align_centre"
																				style={{
																					whiteSpace:
																						"nowrap",
																				}}
																			>
																				<Typography
																					variant="body_2_regular"
																					color="white"
																				>
																					{
																						apiAndOtherInfo[
																							props
																								.component
																								?.entity
																						]
																							.blockedOptionText
																					}
																				</Typography>
																			</div>
																		}
																	>
																		<div className="ml-auto">
																			<img
																				src={
																					redexclam
																				}
																			></img>
																		</div>
																	</UITooltip>
																</>
															)}
													</div>
													{!props?.hideScrollAndDivider &&
														index !==
															suggestions?.length -
																1 && (
															<Divider
																height={1}
															/>
														)}
												</div>
											</>
										)
									)
								) : (
									<div
										className="quick-edit-menu-item justify-content-center text-secondary"
										style={{ fontSize: 12 }}
									>
										<i>No results found</i>
									</div>
								)}
							</div>
						)}
					</div>
				)}
				{showClearAll && (
					<>
						<div className="quick-edit-menu-items-seperator mt-1" />
						<Button
							block
							variant="secondary-default"
							theme={theme}
							onClick={() => {
								handleClearSelection(props.data?.vendor_id);
							}}
						>
							Clear Selection
						</Button>
					</>
				)}
			</div>
		</>
	);
}

export function LocalEntitySelector({ data, component, ...props }) {
	const [open, setOpen] = useState(false);
	const { keys, placeholder = "Select" } = component;
	const { onCellUpdate } = useTableContext();

	const [selected, setSelected] = useState(null);
	return (
		<Popover
			show={open}
			onToggle={setOpen}
			content={
				<SelectEntity
					data={data}
					onSelect={(_selected) => {
						setSelected(_selected);
						const rowId = data?._id;
						onCellUpdate({ [rowId]: { [keys.id]: _selected } });
						setOpen(false);
					}}
					localUpdate
					component={component}
					{...props}
				/>
			}
			trigger="click"
		>
			<div className="z__local_entity_selector_container">
				{selected ? (
					<>
						<Typography
							variant={"body_1_regular"}
							color={
								selected
									? "secondary_grey_2"
									: "secondary_grey_3"
							}
							className={cn("ellipsize-typography")}
						>
							{selected?.[keys.nameKey]}
						</Typography>
						<div className="z__local_entity_selector_edit_icon-wrapper">
							<img src={pencil} alt="pencil icon" />
						</div>
					</>
				) : (
					<>
						<img
							className={`
							 "v2table__cell-empty-link-icon" : ""
						}`}
							src={plus}
							width={16}
							alt=""
						/>
						<Typography
							variant={"body_1_regular"}
							color={
								selected
									? "secondary_grey_2"
									: "secondary_grey_3"
							}
							className={cn("ellipsize-typography")}
						>
							{placeholder}
						</Typography>
					</>
				)}
			</div>
		</Popover>
	);
}
