import React from "react";
import { Loader } from "./Loader";
import thumbsup from "../../../assets/icons/green-thumbsup.svg";

const RESULT = {
	EXCELLENT: { result: "Excellent", from: 75, to: 100, bgColor: "#1491494d" },
	GOOD: { result: "Good", from: 50, to: 74, bgColor: "#ffa2174d" },
	MEDIOCRE: { result: "Mediocre", from: 25, to: 49, bgColor: "#6667724d" },
	POOR: { result: "Poor", from: 0, to: 24, bgColor: "#fc2e234d" },
};

function mapScoreToResult(score) {
	return RESULT[Object.keys(RESULT).find((k) => score >= RESULT[k].from)];
}

export function Score({ data, component, style }) {
	return (
		<span
			style={component.style}
			className="v2table__cell-score font-14 black-1"
		>
			{data[component.valueKey] ? (
				mapScoreToResult(data[component.valueKey])?.result
			) : (
				<Loader type={data[component.loaderType]} />
			)}
		</span>
	);
}

export function UsageBenchmark({ data, style, component }) {
	const temp = Object.keys(RESULT)
		.reverse()
		.map((k) => {
			let isBenchmark =
				RESULT[k].from <= data.app_usage_benchmark &&
				data.app_usage_benchmark <= RESULT[k].to;
			let isUsageValue =
				RESULT[k].from <= data.app_usage &&
				data.app_usage <= RESULT[k].to;
			return (
				<div className="mr-2">
					{isBenchmark || isUsageValue ? (
						<div
							className="border-radius-4 p-1 d-inline-block score-tooltip"
							style={{
								background: "#484848",
								position: "absolute",
								marginTop: "-16px",
							}}
						>
							<p className="m-0 bold-600 font-8 white mr-1 ml-1">
								{isBenchmark && (
									<>
										<span
											className="d-inline-block mr-1"
											style={{
												width: "6px",
												height: "6px",
												background: "#A0D8FF",
												borderRadius: "50%",
											}}
										></span>
										{data.app_usage_benchmark}
									</>
								)}

								{isUsageValue && (
									<>
										<span
											className="d-inline-block mr-1"
											style={{
												width: "6px",
												height: "6px",
												background: "#1176BE",
												borderRadius: "50%",
											}}
										></span>
										{data.app_usage}
									</>
								)}
							</p>
						</div>
					) : null}

					<span
						className="border-radius-4 d-inline-block"
						style={{
							width: "44px",
							height: "4px",
							background: RESULT[k].bgColor,
						}}
					></span>
					<p style={{ fontSize: "6px" }} className="bold-600 m-0">
						<span className="grey-1 mr-1">{RESULT[k].from}</span>
						<span style={{ color: "#A8A8A8" }}>
							{RESULT[k].result}
						</span>
					</p>
				</div>
			);
		});
	return (
		<div>
			{data.app_usage_benchmark && (
				<div className="d-flex justify-content-center mb-4">
					<img src={thumbsup} className="mr-1" alt="thumbs up" />
					<p className="bold-700 font-9 grey-1 m-0">
						{data.app_usage_benchmark}% better usage than benchmark.
					</p>
				</div>
			)}
			<div className="d-flex">{temp} </div>
			<ul className="list-style-none p-0 d-flex mt-2 mb-2">
				<li className="font-8 grey-1 bold-500 mr-2">
					<span
						className="d-inline-block mr-1"
						style={{
							width: "6px",
							height: "6px",
							background: "#A0D8FF",
							borderRadius: "50%",
						}}
					></span>{" "}
					Usage Value
				</li>
				<li className="font-8 grey-1 bold-500">
					<span
						className="d-inline-block mr-1"
						style={{
							width: "6px",
							height: "6px",
							background: "#1176BE",
							borderRadius: "50%",
						}}
					></span>
					Benchmark
				</li>
			</ul>
		</div>
	);
}
