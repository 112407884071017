import React, { useRef } from "react";

export default function ContractBulkUploadTemplate({}) {
	const hiddenAnchor = useRef();
	return (
		<>
			<a
				href={
					"https://zluri-assets-new.s3.us-west-1.amazonaws.com/Sample_contract_upload_format.csv"
				}
				hidden={true}
				download
				ref={hiddenAnchor}
			/>
			<div
				className="primary_bg_2 border-radius-8 padding_4 font-10 bold-500 font-white cursor-pointer"
				onClick={() => hiddenAnchor.current.click()}
			>
				Download Template
			</div>
		</>
	);
}
