import React, { useState } from "react";

import PropTypes from "prop-types";

import { Divider } from "antd";
import {
	Button,
	Loader,
	Popover,
	SecondaryButtonVariant,
	theme,
} from "@zluri/ui-components";
import { EditLink } from "./EditLink";
import { LinkWithCopyToClipboard } from "./LinkWithCopyToClipboard";

import add_blue from "assets/add-blue.svg";
import { useDispatch } from "react-redux";
import {
	addAppOverviewLink,
	deleteAppOverviewLink,
	updateAppOverviewLink,
} from "modules/applications/redux/Applications.actions";

const Links = ({ links, appId, loading, onClose }) => {
	const dispatch = useDispatch();

	const [show, setShow] = useState(false);

	const handleAddLink = (value) => {
		dispatch(addAppOverviewLink({ links, link: value, appId }));
	};

	const handleEditLink = (value, id) => {
		dispatch(updateAppOverviewLink({ links, link: value, id, appId }));
	};

	const handleDeleteLink = (value, id) => {
		dispatch(deleteAppOverviewLink({ links, link: value, id, appId }));
	};

	if (!links?.length) {
		return (
			<EditLink
				onClose={() => !loading && onClose()}
				loading={loading}
				handleAdd={handleAddLink}
				handleEdit={handleEditLink}
			/>
		);
	}

	return (
		<div className="d-flex flex-column gap-2 align-items-start padding_2 z_overview_header-action">
			<div className="d-flex flex-column gap-2 z_overview_header-links-list z_scrollbar-secondary w-100">
				{links?.map((link, idx) => (
					<>
						<LinkWithCopyToClipboard
							{...link}
							handleAdd={handleAddLink}
							handleEdit={handleEditLink}
							handleDelete={handleDeleteLink}
							appId={appId}
							key={idx}
						/>
						<Divider className="z_overview_header-action_option-divider" />
					</>
				))}
			</div>
			<Popover
				placement="rightTop"
				show={show}
				onToggle={(open) => setShow(open)}
				content={
					<EditLink
						loading={loading}
						onClose={() => !loading && setShow(false)}
						handleAdd={handleAddLink}
					/>
				}
			>
				<Button
					disabled={loading}
					className="w-100 z_overview_header-links-add_btn"
					startIcon={!loading && add_blue}
					variant={SecondaryButtonVariant.secondary_default}
					theme={theme}
				>
					{loading ? (
						<span className="d-flex align-items-center gap-10">
							<span>Adding Link</span>
							<Loader color="blue" height={20} width={20} />
						</span>
					) : (
						"Add Link"
					)}
				</Button>
			</Popover>
		</div>
	);
};

export default Links;

Links.propTypes = {
	links: PropTypes.array,
	appId: PropTypes.string,
	loading: PropTypes.bool,
	onClose: PropTypes.func,
};
