import PropTypes from "prop-types";

export const FieldTypePropTypes = PropTypes.oneOf(
	"text",
	"bool",
	"select",
	"date",
	"reference"
);
export const ReferencePropTypes = PropTypes.oneOf(
	"orgusers",
	"orgapplications"
);
