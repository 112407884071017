import React, { useState } from "react";
import { Field, Button, theme } from "@zluri/ui-components";
import PropTypes from "prop-types";

function CustomFieldTextEditPopover({ defaultValue, onClose, onSubmit }) {
	const [text, setText] = useState(defaultValue);

	return (
		<div>
			<div style={{ padding: 8 }} className="flex flex-column gap-4">
				<Field
					handlers={{
						onChange: (e) => {
							setText(e.target.value);
						},
					}}
					type="text"
					fieldType="input"
					value={text}
					placeholder="Enter value"
					className="font-11 bold-400 grey-1"
				/>
			</div>
			<div
				style={{ padding: "6px 8px 8px 8px" }}
				className="flex gap-2 justify-content-between"
			>
				<Button
					variant="primary-alternative"
					style={{ minWidth: 98 }}
					onClick={() => onClose()}
				>
					Cancel
				</Button>
				<Button
					theme={theme}
					variant="primary-default"
					style={{ minWidth: 98 }}
					onClick={() => {
						onSubmit(text);
						onClose();
					}}
				>
					Save
				</Button>
			</div>
		</div>
	);
}
CustomFieldTextEditPopover.propTypes = {
	defaultValue: PropTypes.string,
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default CustomFieldTextEditPopover;
