import React, { useState, useEffect, useMemo } from "react";
import playbookPlay from "assets/users/playbookPlay.svg";
import Dropdown from "UIComponents/CustomDropdown/Dropdown";
import { useSelector } from "react-redux";
import { WORFKFLOW_TYPE } from "modules/workflow/constants/constant";
import {
	bulkRunAPlaybookForUsers,
	createWorkflowFromTemplate,
	getAllPlaybookWorkflowsServiceV2,
} from "modules/workflow/service/api";
import {
	getAppPlaybooks,
	searchUserswithIds,
} from "../../../applications/components/automation/service/automation-api";
import ActionModal from "modules/workflow/components/ActionModal/ActionModal";
import { useHistory } from "react-router-dom";
import { TriggerIssue } from "utils/sentry";
import WorkflowsNameInTable from "modules/workflow/components/WorkflowName.js";
import { capitalizeFirstLetter } from "utils/common";
import arrowdropdown from "assets/arrowdropdown.svg";
import rightarrow from "assets/users/rightarrow.svg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { trackActionSegment } from "modules/shared/utils/segment";
import ViewPlaybookModal from "../ViewPlaybookModal/ViewPlaybookModal";
import { useDispatch } from "react-redux";
import {
	clearWorkflow,
	clearWorkflowLogs,
	updateSelectedUsers,
	updateWorkflow,
	clearCompileExecution,
} from "modules/workflow/redux/workflow";
import CompileWorkflow from "../CompileWorkflow/CompileWorkflow";
import { WorkflowModel } from "modules/workflow/model/model";
import UnpublishPlaybookModal from "../UnpublishedPlaybookModal/UnpublishedPlaybookModal";
import { searchAppPlaybooks } from "modules/Optimization/service/OptimizationV2.api";
import { useShouldRenderFeature } from "modules/shared/utils/shouldRenderFeature";
import { FEATURE_NAME } from "modules/shared/constants/plan.constant";

export default function BulkRunAPlaybook({
	userIds = [],
	className,
	style,
	checkAll,
	metaData,
	checkAllExceptionData,
	playbookOptionsMenuRight = -275,
	playbookTypeMenuRight,
	appIdforMiniPlaybook,
	pageLocation,
	toggler,
	menuOptionClass,
	dropdownWidth,
	menuStyleV2,
	isV2,
	inputClassName,
	rightarrowIcon,
}) {
	const { shouldRenderFeature } = useShouldRenderFeature();
	const history = useHistory();
	const [playbook, setPlaybook] = useState();
	const [loading, setLoading] = useState(false);
	const [users, setUsers] = useState([]);
	const dispatch = useDispatch();
	const { org_beta_features } = useSelector((state) => state.userInfo);
	let playbookOptions = [
		WORFKFLOW_TYPE.ONBOARDING,
		WORFKFLOW_TYPE.OFFBOARDING,
	];
	playbookOptions = useMemo(() => {
		return playbookOptions.filter((option) => {
			if (
				option === "onboarding" &&
				!shouldRenderFeature(FEATURE_NAME.WORKFLOWS_USER_ONBOARDING)
			) {
				return false;
			} else if (
				option === "offboarding" &&
				!shouldRenderFeature(FEATURE_NAME.WORKFLOWS_USER_OFFBOARDING)
			) {
				return false;
			} else {
				return true;
			}
		});
	}, []);

	const options = appIdforMiniPlaybook // && org_beta_features?.includes("automation")
		? [...playbookOptions, WORFKFLOW_TYPE.APPPLAYBOOK]
		: playbookOptions;
	const [showCompileWorkflowModal, setshowCompileWorkflowModal] =
		useState(false);
	const [showUnpublishPlaybookModal, setShowUnpublishPlaybookModal] =
		useState(false);
	const handleModel = {
		sayCloseToViewModal: (e, row) => {},

		sayOpenToEditPublishedPlaybook: () => {
			!showUnpublishPlaybookModal && setShowUnpublishPlaybookModal(true);
		},
		sayCloseToEditPublishedPlaybook: () => {
			showUnpublishPlaybookModal && setShowUnpublishPlaybookModal(false);
		},
	};
	useEffect(() => {
		async function fetchUsers() {
			const res = await searchUserswithIds(userIds, playbook?._id);
			setUsers(res?.data);
			const users_updated = res?.data.map((user) => ({
				user_id: user._id,
				name: user.name,
				email: user.email,
				existing_playbook_run_count_for_user:
					user.existing_playbook_run_count_for_user,
			}));
			dispatch(updateSelectedUsers(users_updated));
		}
		if (playbook?._id) fetchUsers();
	}, [userIds, playbook?._id]);

	return (
		<>
			<div
				className={className}
				style={style}
				key={JSON.stringify(playbook)}
			>
				<Dropdown
					isParentDropdown={true}
					dropdownWidth={dropdownWidth}
					toggler={
						toggler ? (
							toggler
						) : (
							<div
								className="d-flex align-items-center  border-1 border-radius-4"
								style={{
									height: "34px",
									padding: "0 8px",
									borderColor: "#dddddd !important",
								}}
							>
								<img src={playbookPlay} />
								<div className="font-13 ml-1">
									Run a playbook
								</div>
								<img
									src={arrowdropdown}
									style={{ marginLeft: "8px" }}
								/>
							</div>
						)
					}
					top={35}
					right={playbookTypeMenuRight}
					options={options}
					optionFormatter={(option) => (
						<Dropdown
							top={0}
							right={playbookOptionsMenuRight}
							dropdownWidth={dropdownWidth}
							toggler={
								<div
									className={`d-flex align-items-center `}
									style={{
										height: "30px",
										width: isV2 ? "160px" : "184px",
										justifyContent: isV2
											? "space-between"
											: "",
									}}
								>
									<div className="d-flex align-items-center font-12">
										{option === WORFKFLOW_TYPE.APPPLAYBOOK
											? capitalizeFirstLetter(option)
											: capitalizeFirstLetter(option) +
												" " +
												"playbook"}
									</div>
									<img
										src={isV2 ? rightarrowIcon : rightarrow}
										style={{
											marginLeft: isV2 ? "0" : "8px",
										}}
									/>
								</div>
							}
							options={[]}
							apiSearch={true}
							apiSearchCall={(query, cancelToken) =>
								option !== "appPlaybook"
									? getAllPlaybookWorkflowsServiceV2(
											option,
											0,
											query
										)
									: getAppPlaybooks({
											name: query,
											page: 0,
											row: 6,
											searchOnlyPublishedAndNoVariables: true,
										})
							}
							apiSearchDataKey="data"
							optionFormatter={(option) =>
								BulkRunAPlaybookOptionFormatter({
									playbook: option,
								})
							}
							onOptionSelect={(option) => {
								setPlaybook(option);
							}}
							optionStyle={{
								padding: "0px !important",
								flexDirection: "column",
								width: isV2 ? "auto" : "275px",
								minHeight: "60px",
								alignItems: "flex-start",
								paddingTop: "6px",
								paddingBottom: "6px",
							}}
							menuStyle={
								menuStyleV2
									? menuStyleV2
									: {
											width: "295px",
										}
							}
							searchBoxStyle={{ width: isV2 ? "100%" : "260px" }}
							menuHeaderText={`Select ${
								option === WORFKFLOW_TYPE.APPPLAYBOOK
									? capitalizeFirstLetter(option)
									: capitalizeFirstLetter(option) +
										" " +
										"playbook"
							} to run`}
							menuHeaderStyle={
								isV2 && { padding: "8px", fontSize: "12px" }
							}
							inputClassName={inputClassName}
						/>
					)}
					menuStyle={{
						overflow: "unset",
						width: "180px",
						padding: "2px",
						borderRadius: "8px",
					}}
					optionStyle={{ padding: "0px !important" }}
					optionClassName={menuOptionClass}
				/>
			</div>
			{!!playbook && (
				<ViewPlaybookModal
					modalClass="workflows-modal"
					onCloseModal={() => {
						setPlaybook();
						dispatch(clearWorkflow());
						dispatch(clearWorkflowLogs());
					}}
					openModal={!!playbook}
					workflowId={playbook?.workflow_id || playbook?._id}
					onUseTemplate={() => {
						handleModel.sayCloseToViewModal();
						handleModel.sayOpenToUseTemplateModal();
					}}
					buttonTitle="Use Playbook"
					title={playbook?.workflow_name || playbook?.name}
					data={playbook}
					onshowCompileScreen={() => {
						handleModel.sayCloseToViewModal();
						setshowCompileWorkflowModal(true);
					}}
					onEditPublishedPlaybook={(row) => {
						dispatch(clearWorkflow());
						dispatch(clearWorkflowLogs());
						handleModel.sayCloseToViewModal();
						handleModel.sayOpenToEditPublishedPlaybook();
					}}
				/>
			)}
			{showCompileWorkflowModal && (
				<CompileWorkflow
					openModal={showCompileWorkflowModal}
					modalClass="workflows-modal"
					isTemplate={true}
					onCloseModal={() => {
						setshowCompileWorkflowModal(false);
						dispatch(clearWorkflow());
						dispatch(clearWorkflowLogs());
						dispatch(clearCompileExecution());
					}}
				/>
			)}
			{showUnpublishPlaybookModal && (
				<UnpublishPlaybookModal
					show={showUnpublishPlaybookModal}
					onHide={() => {
						handleModel.sayCloseToEditPublishedPlaybook();
					}}
					data={playbook}
				/>
			)}
		</>
	);
}

export function BulkRunAPlaybookOptionFormatter({ playbook }) {
	return (
		<>
			<div className="d-flex justfiy-content-between">
				<WorkflowsNameInTable
					hideTitle={true}
					title={
						playbook?.workflow_name ||
						playbook?.workflowTemplateName ||
						playbook?.name
					}
					id={
						playbook?.workflow_id ||
						playbook?.workflowTemplateId ||
						playbook?._id
					}
					allApps={
						(playbook?.apps || playbook?.workflow_apps) &&
						(playbook?.apps?.length ||
							playbook?.workflow_apps?.length)
							? playbook?.apps || playbook?.workflow_apps
							: playbook?.mini_playbook_data
								? [playbook?.mini_playbook_data]
								: []
					}
					totalActionsCount={
						playbook?.action_count ||
						playbook?.workflow_action_count
							? playbook?.action_count ||
								playbook?.workflow_action_count
							: 0
					}
					total_apps={
						playbook?.app_count || playbook?.workflow_app_count || 0
					}
					total_actions={
						playbook?.action_count ||
						playbook?.workflow_action_count ||
						0
					}
					iconSize="13.5px"
				/>
				<div className="d-flex align-items-center font-8 grey-1">{`${capitalizeFirstLetter(
					playbook.type
				)} | ${
					playbook.action_count ||
					playbook?.workflow_action_count ||
					0
				} Actions`}</div>
			</div>
			<OverlayTrigger
				overlay={
					<Tooltip>
						{playbook.workflow_name ||
							playbook?.workflowTemplateName ||
							playbook?.name}
					</Tooltip>
				}
			>
				<div
					className="bold-500 mt-1 font-13 truncate_10vw"
					style={{ maxWidth: "265px" }}
				>
					{playbook.workflow_name ||
						playbook?.workflowTemplateName ||
						playbook?.name}
				</div>
			</OverlayTrigger>
		</>
	);
}
