import React from "react";
import styled from "styled-components";
import checked from "assets/verified-int.svg";
import unchecked from "assets/unchecked_circle.svg";
import PropTypes from "prop-types";
import { GREY_VARIANT, Typography } from "@zluri/ui-components";
import mandatoryIcon from "modules/transactionMapping/assets/transactionTable/mandatory-icon.svg";

const DualOptionSelectionContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: 8px;
	border-radius: 8px;
	background: var(--secondary-grey-7, #f6f7fa);
	width: 100%;
`;

const OptionsContainer = styled.div`
	display: flex;
`;

const OptionWrapper = styled.div`
	display: flex;
	height: 32px;
	padding: 8px;
	align-items: center;
	border-radius: 8px;
	width: 181px;
	background-color: ${({ $selected }) =>
		$selected ? "var(--brand-white, #FFF)" : "inherit"};
	cursor: pointer;
	&:hover {
		border: 1px solid var(--Secondary_Grey-4, #a8a8a8);
	}
`;

const ModalDualOptionSelection = ({
	title,
	options,
	onSelectHandler,
	selectedValue,
	text,
}) => {
	return (
		<>
			<Typography
				variant="body_2_regular"
				color={GREY_VARIANT.SECONDARY_GREY_2}
			>
				{text}
			</Typography>
			<DualOptionSelectionContainer>
				<div>
					<Typography
						variant="alerttag_regular_medium "
						style={{ marginBottom: "10px" }}
						color={GREY_VARIANT.SECONDARY_GREY_2}
					>
						{title}
					</Typography>
					<img
						src={mandatoryIcon}
						alt="mandatory"
						height={12}
						width={12}
					/>
				</div>
				<OptionsContainer>
					{options.map(({ label, value }) => (
						<OptionWrapper
							key={label}
							$selected={selectedValue === value}
							onClick={() => onSelectHandler(value)}
						>
							<img
								src={
									selectedValue === value
										? checked
										: unchecked
								}
								alt={
									selectedValue === value
										? "checked"
										: "unchecked"
								}
							/>
							<Typography
								variant="body_3_regular"
								style={{ marginLeft: "6px" }}
								color={GREY_VARIANT.SECONDARY_GREY_2}
							>
								{label}
							</Typography>
						</OptionWrapper>
					))}
				</OptionsContainer>
			</DualOptionSelectionContainer>
		</>
	);
};

export default ModalDualOptionSelection;

ModalDualOptionSelection.propTypes = {
	text: PropTypes.string,
	title: PropTypes.string,
	options: PropTypes.array,
	onSelectHandler: PropTypes.func,
	selectedValue: PropTypes.string,
};
