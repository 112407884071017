import { EntityCustomFieldData } from "modules/custom-fields/model/model";
import moment from "moment";
import { UTCDateFormatter } from "utils/DateUtility";

export class UserModel {
	constructor(user) {
		this.user_profile_img = user.user_profile_img || "";
		this.user_name = user.user_name || "";
		this.user_id = user.user_id || null;
		this.user_personal_email = user.user_personal_email;
		this.user_email = user.user_email || "";
		this.user_email_aliases_count = user.user_email_aliases_count || 0;
		this.user_account_type = user.user_account_type || "";
		this.user_archive = user?.user_archive || "";
		this.user_status = user.user_status || "";
		this.user_owner_id = user.user_owner?.user_owner_id || null;
		this.user_owner_name = user?.user_owner?.user_owner_name || "";
		this.user_owner_profile_img =
			user?.user_owner?.user_owner_profile_img || "";
		this.user_cost_center_id = user?.user_cost_center_id || null;
		this.user_cost_center_name = user?.user_cost_center_name || "";
		this.user_cost_center_code = user?.user_cost_center_code || "";
		this.user_business_unit_id = user?.user_business_unit_id || null;
		this.user_business_unit_name = user?.user_business_unit_name || "";
		this.user_business_unit_path_name =
			user?.user_business_unit_path_name || "";
		this.user_designation = user?.user_designation || "";
		this.user_department_name =
			user?.user_department?.department_name_path ||
			user?.user_department?.department_name ||
			"";
		this.user_department_id = user?.user_department?.department_id || null;
		this.user_reporting_manager_id =
			user?.user_reporting_manager?.user_reporting_manager_id || null;
		this.user_reporting_manager_name =
			user?.user_reporting_manager?.user_reporting_manager_name || "";
		this.user_reporting_manager_profile_img =
			user?.user_reporting_manager?.user_reporting_manager_profile_img ||
			"";
		this.user_added_on = user.user_added_on || "";
		this.user_onboarding_date =
			user?.user_onboard_offboard_details?.date || "";
		this.user_avg_usage_score = user?.user_average_usage?.avg_usage || 0;
		this.user_average_usage = user?.user_average_usage || {};
		this.user_source_array = user?.user_source_array || [];
		this.user_custom_fields = Array.isArray(user.user_custom_fields)
			? user.user_custom_fields
					.filter((cf) => cf.field_id)
					.map((cf) => new EntityCustomFieldData(cf))
			: [];
		this.is_user_integration_department =
			user.user_department?.is_integration_department || false;
		this.user_notes = user.user_notes || [];
	}
}

export class UserMetricsModel {
	constructor(user) {
		this.user_managed_apps_count_value =
			user?.user_managed_apps_count?.count || 0;
		this.user_managed_apps_count_change =
			user?.user_managed_apps_count?.change || 0;
		this.user_managed_apps_month_id =
			user?.user_managed_apps_count?.month_id || 1;
		this.user_restricted_apps_count_value =
			user?.user_restricted_apps_count?.count || 0;
		this.user_restricted_apps_count_change =
			user?.user_restricted_apps_count?.change || 0;
		this.user_restricted_apps_month_id =
			user?.user_restricted_apps_count?.month_id || 0;
		this.user_apps_used_count = user?.user_apps_used?.apps_count || 0;
		this.user_apps_used_count_change = user?.user_apps_used?.change || 0;
		this.user_apps_used_month_id = user?.user_apps_used?.month_id || 1;
		this.user_total_spend = user.user_total_spend?.total_spend || 0;
		this.user_avg_curr_month_spend =
			user?.user_total_spend?.avg_curr_month_spend || 0;
		this.user_total_spend_month_id = user?.user_total_spend?.month_id || 1;
		this.user_monthly_spend = user?.user_monthly_spend?.monthly_spend || 0;
		this.user_monthly_spend_change = user?.user_monthly_spend?.change || 0;
		this.user_monthly_spend_month_id =
			user?.user_monthly_spend?.month_id || 1;
	}
}

export class UserOnboardOffboardDetailsModel {
	constructor(user) {
		this.user_onboarding_date = user?.date
			? moment(user?.date).format("DD MMM YYYY")
			: "-";
		this.marked_user_id = user?.marked_by?._id || null;
		this.marked_user_name = user?.marked_by?.name || "";
		this.marked_user_profile_img = user?.marked_by?.profile_img || "";
		this.assignee_user_id = user?.assigned_to?._id || null;
		this.assignee_user_name = user?.assigned_to?.name || "";
		this.assignee_user_profile_img = user?.assigned_to?.profile_img || "";
		this.user_onboarding_type = user?.type || "OFFBOARDING";
	}
}
