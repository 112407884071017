import React from "react";

import PropTypes from "prop-types";

import {
	ALERT_TAG_VARIANT,
	BODY_VARIANT,
	Divider,
	ImageOrNameBadge,
	Tooltip,
	Typography,
} from "@zluri/ui-components";

/* Being used in
   Application Users -> Cost Centre Popover, Business Unit Popover
 */
export function CostCentrePopover({ data, component }) {
	const { valueKey, codeKey, ownerNameKey, ownerDesc, ownerProfileKey } =
		component;

	const {
		[valueKey]: title,
		[codeKey]: code = "",
		[ownerNameKey]: ownerName,
		[ownerProfileKey]: ownerProfile,
	} = data ?? {};

	return (
		<div className="padding_6">
			<div className="d-flex align-items-center justify-content-between gap-4">
				<Tooltip
					content={
						<Typography
							variant={
								ALERT_TAG_VARIANT.ALERT_TAG_EXTRA_SMALL_MEDIUM
							}
							color="white"
						>
							{title}
						</Typography>
					}
				>
					<Typography
						className="text-truncate-medium"
						variant={BODY_VARIANT.BODY_2_BOLD}
						color="#484848"
					>
						{title}
					</Typography>
				</Tooltip>
				<Typography
					variant={BODY_VARIANT.BODY_3_MEDIUM}
					color="#484848"
				>
					{code}
				</Typography>
			</div>
			{ownerName && (
				<>
					<Divider height="1px" borderRadius="8px" />
					<div className="d-flex align-items-center gap-6 py-1">
						<ImageOrNameBadge
							name={ownerName}
							url={ownerProfile}
							height="16px"
							width="16px"
						/>
						<div className="d-flex flex-column">
							<Typography
								variant={BODY_VARIANT.BODY_3_BOLD}
								color="#484848"
							>
								{ownerName}
							</Typography>
							<Typography
								variant={
									ALERT_TAG_VARIANT.ALERT_TAG_EXTRA_SMALL_MEDIUM
								}
								color="#717171"
							>
								{ownerDesc}
							</Typography>
						</div>
					</div>
				</>
			)}
		</div>
	);
}
CostCentrePopover.propTypes = {
	data: PropTypes.object,
	component: PropTypes.shape({
		valueKey: PropTypes.string,
		codeKey: PropTypes.string,
		ownerNameKey: PropTypes.string,
		ownerDesc: PropTypes.string,
		ownerProfileKey: PropTypes.string,
	}),
};
