import { TOAST_COLOR, toast } from "@zluri/ui-components";

import {
	removeTransactionVendor,
	updateVendorTask,
} from "modules/applications/service/Applications.api";
import { SingleApplicationBasicDetails } from "modules/Application/model/Application.model";
import {
	updateSingleApplicationBasicDetailKey,
	updateSingleApplicationCustomFieldData,
} from "modules/Application/redux/Application.action";
import { reassignManualTask } from "modules/workflow/service/api";
import { updateDesktopAgents } from "services/api/agents";
import { patchApplication, updateApplication } from "services/api/applications";
import { TriggerIssue } from "utils/sentry";
import {
	editAccessCertificationReviewer,
	editAccessCertificationReviewerEmpView,
} from "../../../modules/AccessReview/service/AccessReview.service";
import { patchCustomFieldForAnApplication } from "modules/Application/service/Application.api";
import { EntityCustomFieldData } from "modules/custom-fields/model/model";
import { updateAppSignOffFeasibleStatus } from "modules/AccessReview/redux/AccessReview.actions";
import { getCfValueData } from "modules/applications/utils/appAbout.utils";
import { TOAST_MESSAGE } from "modules/shared/constants/toast.constants";
import { OWNER_TOAST_CONTENT } from "modules/applications/constants/AppAbout.constants";
import { getSegmentFromURL } from "@zluri/ui-components";

const reassignTask = async ({
	selectedValue,
	props,
	apiAndOtherInfo,
	dispatch,
}) => {
	const assigned_to = {
		user_id: selectedValue[apiAndOtherInfo[props.component?.entity].id_key],
		user_name:
			selectedValue[apiAndOtherInfo[props.component?.entity].name_key],
		user_email:
			selectedValue[apiAndOtherInfo[props.component?.entity].email_key],
		user_logo:
			selectedValue[apiAndOtherInfo[props.component?.entity].image_key],
	};
	const reqData = {
		action_data: [
			{
				k:
					props.data?.workflow_user?._id ||
					props.data?.workflow_user_id,
				v: {
					assignee: [assigned_to],
				},
			},
		],
	};
	const { workflow_id, action_id, workflow_exec_id } = props.data;
	dispatch({
		type: "UPDATE_RECORD",
		payload: {
			rowIndex: props.rowIndex,
			columnIndex: props.columnIndex,
			skipApi: true,
		},
	});
	try {
		await reassignManualTask(
			workflow_id,
			action_id,
			reqData,
			{
				runId: workflow_exec_id,
			},
			true
		);
	} catch (err) {
		TriggerIssue("Error while reassigning the task", err);
	}
	dispatch({
		type: "RECORD_UPDATED",
		payload: {
			entity: props.entity,
			rowIndex: props.rowIndex,
			columnIndex: props.columnIndex,
			index: props.rowIndex,
			value: {
				assigned_to: {
					_id: assigned_to.user_id,
					name: assigned_to.user_name,
					email: assigned_to.user_email,
					profile_img: assigned_to.user_logo,
				},
			},
		},
	});
};

const vendorTask = ({ selectedValue, props, apiAndOtherInfo, dispatch }) => {
	dispatch({
		type: "UPDATE_RECORD",
		payload: {
			rowIndex: props.rowIndex,
			columnIndex: props.columnIndex,
			skipApi: true,
		},
	});
	updateVendorTask(
		props?.data[props?.component?.patchKey],
		selectedValue?.vendor_id
	)
		.then((vendorDataRespone) => {
			dispatch({
				type: "RECORD_UPDATED",
				payload: {
					entity: props.entity,
					rowIndex: props.rowIndex,
					columnIndex: props.columnIndex,
					index: props.rowIndex,
					value: {
						vendor_name: selectedValue?.vendor_name,
						vendor_id: selectedValue?.vendor_id,
						vendor_logo_url: selectedValue?.vendor_logo,
					},
				},
			});
		})
		.catch((error) =>
			TriggerIssue(error, "Vendor Transaction Updation Failed")
		);
};

const desktopAgentsTask = ({ props, selectedValue, dispatch }) => {
	const id = props?.data[props.component?.secondValueKey];
	const reqBody = {
		mapUsers: [
			{
				userId: selectedValue?.user_id,
				uuid: id,
			},
		],
	};

	dispatch({
		type: "UPDATE_RECORD",
		payload: {
			rowIndex: props.rowIndex,
			columnIndex: props.columnIndex,
			skipApi: true,
		},
	});
	dispatch({
		type: "UPDATE_RECORD",
		payload: {
			rowIndex: props.rowIndex,
			columnIndex: props.columnIndex + 1,
			skipApi: true,
		},
	});
	dispatch({
		type: "UPDATE_RECORD",
		payload: {
			rowIndex: props.rowIndex,
			columnIndex: props.columnIndex + 2,
			skipApi: true,
		},
	});
	updateDesktopAgents(reqBody)
		.then((data) => {
			dispatch({
				type: "RECORD_UPDATED",
				payload: {
					entity: props.entity,
					rowIndex: props.rowIndex,
					columnIndex: props.columnIndex,
					index: props.rowIndex,
					value: {
						user_name: selectedValue?.user_name,
						user_id: selectedValue?.user_id,
						user_image: selectedValue?.profile_img,
					},
				},
			});
			dispatch({
				type: "RECORD_UPDATED",
				payload: {
					entity: props.entity,
					rowIndex: props.rowIndex,
					columnIndex: props.columnIndex + 1,
					index: props.rowIndex,
					value: {
						user_email: selectedValue?.user_email,
						user_name: selectedValue?.user_name,
						user_id: selectedValue?.user_id,
						user_image: selectedValue?.profile_img,
					},
				},
			});
			dispatch({
				type: "RECORD_UPDATED",
				payload: {
					entity: props.entity,
					rowIndex: props.rowIndex,
					columnIndex: props.columnIndex + 2,
					index: props.rowIndex,
					value: {
						source_of_mapping: "manual",
					},
				},
			});
		})
		.catch((err) => {
			TriggerIssue(
				"There was an error while mapping the user to device",
				err
			);
		});
};

const certificationReviewerTask = ({
	selectedValue,
	props,
	apiAndOtherInfo,
	dispatch,
	tableData,
}) => {
	let reqBody = {
		reviewer_id: selectedValue?.user_id,
		access_certification_user_id: [props.data.access_certification_user_id],
		select_all: {
			enabled: false,
			filter_by: [],
			unselected_access_certification_user_id: [],
		},
	};

	let page = Math.floor(props.rowIndex / 50);
	let forceRemoveFilter;
	let filterBy = [...tableData.filter_by];
	const reviewId =
		getSegmentFromURL(2) === "accessreview"
			? getSegmentFromURL(3)
			: getSegmentFromURL(2);
	const isEmployee = getSegmentFromURL(1) === "user";

	if (Array.isArray(tableData.filter_by) && tableData.filter_by.length > 0) {
		let index = tableData.filter_by.findIndex(
			(row) => row.filter_type === "expr" && row.field_id === "user_id"
		);
		if (index > -1) {
			if (tableData.data[0].length === 1) {
				forceRemoveFilter = true;
				filterBy = filterBy.filter(
					(row) =>
						row.filter_type !== "expr" && row.field_id !== "user_id"
				);
			}
		}
	}

	const api = isEmployee
		? editAccessCertificationReviewerEmpView
		: editAccessCertificationReviewer;

	api &&
		api(reviewId, props.data.app_id, reqBody)
			.then(async (res) => {
				await dispatch(
					updateAppSignOffFeasibleStatus(
						props?.data?.app_id,
						res?.signoff_feasible
					)
				);

				dispatch({
					type: "V2TABLE/START_LOADING",
					payload: {
						entity: props.entity,
						subEntityData: props.subEntityData,
					},
				});

				dispatch({
					type: "GET_TABLE_DATA",
					payload: {
						entity: props?.subEntityData?.entity || props.entity,
						subEntityData: props.subEntityData,
						shouldRefresh: true,
						api: "api",
						apiProps: {
							appId: props.data.app_id,
							reviewId: reviewId,
						},
						recordsPerPage: 50,
						page: page,
						...(forceRemoveFilter ? { filterBy: filterBy } : {}),
					},
				});
			})
			.catch((err) => {
				TriggerIssue(
					"There was an error while updating the reviewer",
					err
				);
			});
};
const removeVendorTask = async ({ vendorId, props, dispatch }) => {
	try {
		dispatch({
			type: "UPDATE_RECORD",
			payload: {
				rowIndex: props.rowIndex,
				columnIndex: props.columnIndex,
				skipApi: true,
			},
		});
		await removeTransactionVendor(
			props?.data[props?.component?.patchKey],
			vendorId
		);
		dispatch({
			type: "RECORD_UPDATED",
			payload: {
				entity: props.entity,
				rowIndex: props.rowIndex,
				columnIndex: props.columnIndex,
				index: props.rowIndex,
				value: {
					vendor_name: null,
					vendor_id: null,
					vendor_logo_url: null,
				},
			},
		});
	} catch (error) {
		TriggerIssue(error, "Vendor Transaction Removal Failed");
	}
};
const ownerTask = async ({ props, selectedValue, dispatch }) => {
	const oldAppDetail = props?.data;
	const updatedValues = {
		[props.component.updateKeys.id]: selectedValue?.user_id,
		[props.component.updateKeys.name]: selectedValue?.user_name,
		[props.component.updateKeys.image]: selectedValue?.profile_img,
		[props.component.updateKeys.email]: selectedValue?.user_email,
		[props.component.updateKeys.type]: selectedValue?.user_account_type,
	};
	if (props?.appId) {
		try {
			dispatch(
				updateSingleApplicationBasicDetailKey({
					appId: props.appId,
					updateDetails: new SingleApplicationBasicDetails({
						...(props?.data ?? {}),
						...updatedValues,
					}),
				})
			);
			const res = await updateApplication(props.appId, updatedValues);
			if (res.error) {
				throw new Error(res.error);
			}
			toast(OWNER_TOAST_CONTENT[props.component.updateKeys.id].success, {
				indicatorColor: TOAST_COLOR.SUCCESS,
			});
		} catch (err) {
			TriggerIssue("Error updating application:", err);
			dispatch(
				updateSingleApplicationBasicDetailKey({
					appId: props.appId,
					updateDetails: new SingleApplicationBasicDetails(
						oldAppDetail
					),
				})
			);
			toast(OWNER_TOAST_CONTENT[props.component.updateKeys.id].error, {
				indicatorColor: TOAST_COLOR.ERROR,
			});
		}
	}
};

const customFieldTask = async ({ props, selectedValue, dispatch }) => {
	const { appId, patchType, fieldId, customFields } = props;

	try {
		dispatch(
			updateSingleApplicationCustomFieldData({
				appId,
				patchType,
				customFieldObj: new EntityCustomFieldData({
					field_id: fieldId,
					field_value:
						selectedValue?.user_id ?? selectedValue?.app_id,
					custom_field_name:
						selectedValue?.user_name ?? selectedValue?.app_name,
				}),
				customFieldData: customFields,
			})
		);

		const payload = {
			patches: [
				{
					op: patchType,
					field: "custom_fields",
					value: selectedValue?.user_id ?? selectedValue?.app_id,
					custom_field_id: fieldId,
				},
			],
		};

		await patchCustomFieldForAnApplication({
			appId,
			patchObj: payload,
		});
		toast(
			`${props?.customFieldName ?? "Custom field"} sucessfully updated`,
			{
				indicatorColor: TOAST_COLOR.SUCCESS,
			}
		);
	} catch (err) {
		TriggerIssue("Error patching custom fields: ", err);
		const cfData = getCfValueData(fieldId, customFields);
		dispatch(
			updateSingleApplicationCustomFieldData({
				appId,
				patchType: cfData ? patchType : "delete",
				customFieldObj: new EntityCustomFieldData({
					field_id: fieldId,
					field_value: cfData?.field_value,
					custom_field_name: cfData?.custom_field_name,
				}),
				customFieldData: customFields,
			})
		);
		toast(`Error updating ${props?.customFieldName ?? "custom field"}`, {
			indicatorColor: TOAST_COLOR.ERROR,
		});
	}
};

const subCategoryTask = async ({ props, selectedValue, dispatch }) => {
	const oldAppDetail = props?.data;
	const appId = props.appId;

	if (appId) {
		try {
			dispatch(
				updateSingleApplicationBasicDetailKey({
					appId: props.appId,
					updateDetails: new SingleApplicationBasicDetails({
						...props.data,
						app_sub_categories: [
							{
								sub_category_id: selectedValue?.sub_category_id,
								sub_category_name: selectedValue?.name,
								category_id: selectedValue?.category_id,
								category_name: selectedValue?.category_name,
							},
							...props.data?.app_sub_categories?.slice(1),
						],
					}),
				})
			);

			const subCategoryId =
				props?.data?.app_sub_categories?.[0]?.sub_category_id;
			let patchObj = {
				patches: [
					{
						op: subCategoryId ? "replace" : "add",
						field: "sub_category",
						value: selectedValue._id,
						old_value: subCategoryId,
					},
				],
			};

			const res = await patchApplication(appId, patchObj);
			if (res.errors) throw new Error(res.errors);

			toast(TOAST_MESSAGE.CATEGORY_UPDATE_SUCESS, {
				indicatorColor: TOAST_COLOR.SUCCESS,
			});
		} catch (err) {
			TriggerIssue("Error patching app category: ", err);
			dispatch(
				updateSingleApplicationBasicDetailKey({
					appId: props.appId,
					updateDetails: new SingleApplicationBasicDetails(
						oldAppDetail
					),
				})
			);
			toast(TOAST_MESSAGE.CATEGORY_UPDATE_ERROR, {
				indicatorColor: TOAST_COLOR.ERROR,
			});
		}
	}
};

export const customApi = {
	reassignTask: reassignTask,
	removeVendorTask,
	vendorTask: vendorTask,
	desktopAgentsTask: desktopAgentsTask,
	certificationReviewerTask: certificationReviewerTask,
	ownerTask: ownerTask,
	customFieldTask: customFieldTask,
	subCategoryTask: subCategoryTask,
};
