import { ErrorBoundary } from "@sentry/react";
import { ProgressBarLoader } from "common/Loader/ProgressBarLoader";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isUndefined, isNull } from "underscore";
import EmptyCell from "./EmptyCell";
import ViewCell from "./ViewCell";

export const componentState = {
	VIEW: "VIEW",
	EMPTY: "EMPTY",
	EDIT: "EDIT",
	LOADING: "LOADING",
};

const generateState = (value) => {
	if (Array.isArray(value)) {
		return value?.length ? componentState.VIEW : componentState.EMPTY;
	}
	// return value ? componentState.VIEW : componentState.EMPTY;
	return !(isUndefined(value) || isNull(value))
		? componentState.VIEW
		: componentState.EMPTY; // 0 is still a valid number so just checking for undefined or null value.
};
// ONLY USE THIS IN V2TABLE
export default function CellRenderer({ component, ...props }) {
	const value = props.data[component.valueKey];
	const [currentState, setCurrentState] = useState(() =>
		generateState(value)
	);
	const target = useRef(null);

	const dispatch = useDispatch();
	const resetState = () => setCurrentState(componentState.VIEW);
	const loading = useSelector((state) => state.v2Table.loading);
	const handleUpdate = (id, data, value) => {
		dispatch({
			type: "UPDATE_RECORD",
			payload: {
				id,
				entity: props.entity,
				tableEntity: props.tableEntity,
				data,
				value,
				index: props.rowIndex,
				rowIndex: props.rowIndex,
				columnIndex: props.columnIndex,
			},
		});
		resetState();
	};

	useEffect(() => {
		if (loading?.[props.rowIndex]?.[props.columnIndex]?.loading) {
			setCurrentState(componentState.LOADING);
			return;
		}
		if (component.alwaysRenderViewComponent) {
			setCurrentState(componentState.VIEW);
			return;
		}
		setCurrentState(generateState(value));
	}, [props.data, loading]);

	return (
		<ErrorBoundary fallback={<div>Error</div>}>
			<div ref={target}>
				{currentState === componentState.VIEW && (
					<ViewCell
						component={component}
						setCurrentState={setCurrentState}
						setEditState={() =>
							setCurrentState(componentState.VIEW)
						}
						handleUpdate={handleUpdate}
						resetState={resetState}
						loading={loading}
						{...props}
					/>
				)}
				{currentState === componentState.LOADING && (
					<ProgressBarLoader
						style={{ marginTop: "-2px" }}
						height={20}
						width={20}
					/>
				)}
				{currentState === componentState.EMPTY &&
					(props.emptyComponent ? (
						<EmptyCell
							component={props.emptyComponent}
							handleOnClick={() =>
								setCurrentState(componentState.EDIT)
							}
							setEditState={() =>
								setCurrentState(componentState.EDIT)
							}
							handleUpdate={handleUpdate}
							resetState={resetState}
							{...props}
						/>
					) : (
						<div
							className={`d-flex justify-content-${
								component?.dataType === "number"
									? "end"
									: "center"
							} align-items-center`}
						>
							{" "}
							-{" "}
						</div>
					))}
				{currentState === componentState.EDIT && (
					<ViewCell
						placement=""
						component={component}
						setCurrentState={setCurrentState}
						setEditState={() =>
							setCurrentState(componentState.EDIT)
						}
						handleUpdate={handleUpdate}
						resetState={resetState}
						loading={loading}
						{...props}
					/>
				)}
			</div>
		</ErrorBoundary>
	);
}
