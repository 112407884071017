import { authStatusObj } from "common/AppAuthStatus";
import { TOAST_COLOR, Typography, toast } from "@zluri/ui-components";
import React from "react";
import { useDispatch } from "react-redux";
import { setAppsBulkAuth } from "services/api/applications";
import { capitalizeFirstLetter } from "utils/common";
import tick from "assets/tick-mark.svg";
import { TriggerIssue } from "utils/sentry";
import { TOAST_MESSAGE } from "modules/shared/constants/toast.constants";

const applicationAuthStatus = [
	"centrally_managed",
	"team_managed",
	"individually_managed",
	"unmanaged",
	"restricted",
];
export function AppStatePopover({
	component,
	data,
	handleUpdate,
	selectedValueKey,
	...props
}) {
	const dispatch = useDispatch();
	return (
		<>
			{applicationAuthStatus.map((authStatus) => {
				const authName = authStatus?.replaceAll("_", " ");
				const isSelected =
					data[component.selectedValueKey] ===
					authStatus?.replaceAll("_", " ");
				return (
					<div
						key={authStatus}
						onClick={() => {
							if (isSelected) return;
							dispatch({
								type: "UPDATE_RECORD",
								payload: {
									rowIndex: props.rowIndex,
									columnIndex: props.columnIndex,
									skipApi: true,
								},
							});
							setAppsBulkAuth(authName, [data.app_id], [], false)
								.then((res) => {
									dispatch({
										type: "RECORD_UPDATED",
										payload: {
											index: props.rowIndex,
											value: {
												app_state:
													authStatus?.replaceAll(
														"_",
														" "
													),
											},
											entity: props.entity,
											rowIndex: props.rowIndex,
											columnIndex: props.columnIndex,
										},
									});
									toast(
										TOAST_MESSAGE.APPLICATION_AUTH_STATUS_UPDATE_SUCCESS,
										{ indicatorColor: TOAST_COLOR.SUCCESS }
									);
								})
								.catch((err) => {
									TriggerIssue(
										`Error while updating Authorization Status`,
										err
									);
									toast(
										TOAST_MESSAGE.APPLICATION_AUTH_STATUS_UPDATE_ERROR,
										{ indicatorColor: TOAST_COLOR.ERROR }
									);
								});
						}}
					>
						<div
							className={`d-flex flex-row align-items-center custom_risk_select_option  justify-content-between ${
								isSelected
									? "custom_risk_select_option_selected"
									: "cursor-pointer custom_risk_select_option_unselected"
							}`}
							style={{ padding: "6px" }}
						>
							<div className="d-flex">
								<img
									src={authStatusObj[authStatus].image}
									width={15}
									alt={authName}
								/>
								<div className="ml-1">
									<Typography
										variant="button_extrasmall_medium"
										color={
											isSelected
												? "secondary_grey_3"
												: "secondary_grey_1"
										}
									>
										{authStatus
											.split("_")
											.map((i) =>
												capitalizeFirstLetter(i)
											)
											.join(" ")}
									</Typography>
								</div>
							</div>
							<div>
								{isSelected && (
									<img src={tick} alt="Tick Icon" />
								)}
							</div>
						</div>
					</div>
				);
			})}
		</>
	);
}
