import { BODY_VARIANT, Tooltip, Typography } from "@zluri/ui-components";
import React from "react";
import cn from "classnames";
import icon_close from "assets/Icon_Close.svg";
import "./FilterPill.css";
import PropTypes from "prop-types";

export default function FilterPill({
	isActive = true,
	label,
	tooltip,
	variant = "small",
	onRemove,
	onClick,
	customLabel,
	isActionFilter = false,
}) {
	let content;
	if (customLabel) {
		content = customLabel;
	} else {
		content = (
			<Typography
				variant="button_extrasmall_regular"
				color={isActive ? "primary_blue_dark_2" : "secondary_grey_3"}
				className="text-nowrap"
				style={{ lineHeight: "100%" }}
			>
				{label}
			</Typography>
		);
	}

	return (
		<div
			onClick={!isActive ? onClick : null}
			className={cn(
				!isActionFilter ? "filter__pill" : "action__filter__pill",
				{ active: isActive },
				{ "cursor-pointer": !isActive },
				{ filter__pill__large: variant === "large" },
				{ filter__pill__small: variant === "small" }
			)}
			style={{ borderColor: "transparent" }}
		>
			{tooltip ? (
				<Tooltip
					content={
						<Typography
							variant={BODY_VARIANT.BODY_3_REGULAR}
							color="white"
						>
							{tooltip}
						</Typography>
					}
					placement="bottom"
				>
					{content}
				</Tooltip>
			) : (
				content
			)}
			{isActive && (
				<img
					className="cursor-pointer"
					src={icon_close}
					onClick={(e) => {
						onRemove();
						e.stopPropagation();
					}}
					width={9}
					alt=""
					style={{
						filter: isActionFilter
							? "invert(72%) sepia(54%) saturate(6098%) hue-rotate(326deg) brightness(102%) contrast(102%)"
							: "",
					}}
				/>
			)}
		</div>
	);
}

FilterPill.propTypes = {
	isActive: PropTypes.bool,
	label: PropTypes.string,
	tooltip: PropTypes.string,
	variant: PropTypes.string,
	onClick: PropTypes.func,
	onRemove: PropTypes.func,
	isActionFilter: PropTypes.bool,
	customLabel: PropTypes.string,
};
