import {
	SEARCH_FOR_APPLICATION,
	SELECT_APPLICATION,
	STATUS,
	ViewType,
} from "modules/AccessReview/constants/constants";
import {
	fetchDefaultUsersAndRoles,
	handleAddLevel,
	handleRemoveLevel,
	updateLevelData,
	updateProgressbar,
	updateSelectedApplicationDetails,
} from "modules/AccessReview/redux/AccessReview.actions";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAppSearchGlobal } from "services/api/search";
import { isEmpty, debounce } from "underscore";
import folder from "assets/icons/folder.svg";
import greenEllipse from "assets/icons/green-ellipse.svg";
import redCircle from "assets/icons/red-circle.svg";
import departments from "assets/icons/departments.svg";
import totalUsers from "assets/icons/total-users.svg";
import ContentLoader from "react-content-loader";
import learnMoreToolTip from "assets/icons/learn-more-tooltip.svg";
import { SelectV2 } from "../SelectV2/SelectV2";
import {
	checkIsReviewersDataFilled,
	refactorAppData,
} from "modules/AccessReview/util/util";
import { AppAuthStatusIconAndTooltip } from "common/AppAuthStatus";
import { ImageOrNameBadge } from "@zluri/ui-components";
import "modules/AccessReview/components/SelectV2/SelectV2.css";
import "modules/AccessReview/components/SelectApplicationInModal/SelectApplicationInModal.css";
import AddLevelsOfReviews from "../AddLevelsOfReviews/AddLevelsOfReviews";
import ErrorWrapper from "UIComponents/Rbac/ErrorWrapper";

export default function SelectApplicationInModal(props) {
	const dispatch = useDispatch();
	const { applicationReview, isLoading, defaultUsersAndRoles, applications } =
		useSelector((state) => state.accessReview);
	const selectedApplication = applicationReview?.application;
	const [appId, setAppId] = useState("");
	const [optionsLoading, setOptionsLoading] = useState(false);
	const [applicationsData, setApplicationsData] = useState([]);

	const defaultUsers = defaultUsersAndRoles?.defaultUsers;
	const defaultRoles = defaultUsersAndRoles?.defaultRoles;
	const isReviewerInfoFilled = checkIsReviewersDataFilled(
		applicationReview?.levels
	);
	const error = defaultUsersAndRoles?.error;

	const isAppActive = selectedApplication?.app_status === "active";

	useEffect(() => {
		if (appId || selectedApplication?.id) {
			dispatch(
				updateSelectedApplicationDetails(
					appId || selectedApplication?.id
				)
			);
		}
	}, [appId, selectedApplication?.id]);

	useEffect(() => {
		dispatch(
			updateProgressbar(
				0,
				isReviewerInfoFilled ? STATUS.Completed : STATUS.InProgress,
				ViewType.ViewsInAddApplicationModal
			)
		);
		if (isEmpty(defaultRoles) && isEmpty(defaultUsers)) {
			dispatch(fetchDefaultUsersAndRoles());
		}
		selectedApplication && isReviewerInfoFilled
			? props.setNextBtnDisabled(false)
			: props.setNextBtnDisabled(true);
	}, [applicationReview?.levels]);

	const debouncedChangeHandlerApp = useCallback(
		debounce((query, reqCancelToken) => {
			if (query || query === "") {
				getAppSearchGlobal(query, reqCancelToken, true).then((res) => {
					setApplicationsData(
						refactorAppData(res.results, applications)
					);
					setOptionsLoading(false);
				});
			}
		}, 500),
		[]
	);

	const updateLevelInfo = (obj, additionalInfo) => {
		dispatch(updateLevelData(obj, additionalInfo));
	};

	const handleAddLevelInfo = () => {
		dispatch(handleAddLevel());
	};

	const handleRemoveLevelInfo = (levelIndex) => {
		dispatch(handleRemoveLevel(levelIndex));
	};

	if (error) {
		return <ErrorWrapper error={error} />;
	}

	return (
		<div className="d-flex flex-1 justify-content-center align-items-center select-application-card hide-scrollbar overflow-auto">
			{isEmpty(selectedApplication) && !isLoading && (
				<div
					style={{
						minWidth: "600px",
						minHeight: "52px",
						padding: "10px",
						borderRadius: "8px",
						backgroundColor: "white",
					}}
				>
					<div className="bold-600 font-12 mb-2">
						{SELECT_APPLICATION}
					</div>
					<SelectV2
						defaultClassNames={true}
						isNameBadge={true}
						optionsContainerClassName={"dropdown_height"}
						search={true}
						hideDropDown={true}
						inputStyle={{
							fontSize: "11px",
							fontWeight: "400",
						}}
						style={{
							padding: "1px",
							height: "28px",
							marginTop: "9px",
						}}
						value={[selectedApplication]}
						isOptionsLoading={optionsLoading}
						imageOrNameBadgeImgClassName={"selected-option-img"}
						imageOrNameBadgeNameClassName={"search-name-options"}
						options={applicationsData}
						fieldNames={{
							label: "app_name",
							description: "app_type",
							logo: "app_image_url",
						}}
						placeholder={SEARCH_FOR_APPLICATION}
						onChange={(app) => {
							setAppId(app?.app_id);
						}}
						onSearch={(query) => {
							if (query?.length !== 0) {
								setOptionsLoading(true);
								debouncedChangeHandlerApp(query);
							}
						}}
						optionRender={(option, props) => {
							return (
								<div className="d-flex justify-content-between">
									<div className="d-flex align-items-center">
										<ImageOrNameBadge
											url={option?.app_image_url}
											name={option?.app_name}
											nameBadgeClasses="search-name-options"
											className="selected-option-img"
										/>
										<div className="d-flex flex-column family-sora bold-500 ml-2">
											<div className="font-14 black-1">
												{option?.app_name}
											</div>
											<div className="font-11 grey-1 pt-1">
												{option?.app_type}
											</div>
										</div>
									</div>
									{/* <img
										className="justify-content-end"
										src={learnMoreToolTip}
										alt=""
									/> */}
								</div>
							);
						}}
					/>
				</div>
			)}

			{isLoading && (
				<div className="d-flex flex-column align-items-center justify-content-center">
					<Loader
						width={900}
						height={65}
						background="white"
						speed={1}
						className="p-1"
					/>

					<Loader
						width={900}
						height={140}
						background="white"
						speed={1}
						className="p-1"
					/>
				</div>
			)}

			{!isEmpty(selectedApplication) && !isLoading && (
				<div className="d-flex flex-column gap-10 w-80">
					<div className="d-flex selected-application-card">
						<div className="d-flex justify-content-start align-items-center w-40">
							<ImageOrNameBadge
								url={selectedApplication?.app_logo}
								name={selectedApplication?.app_name}
								height={32}
								width={32}
								className="border-radius-4"
							/>
							<div className="bold-600 font-12 black-1 family-sora mx-2">
								{selectedApplication?.app_name}
							</div>
							<AppAuthStatusIconAndTooltip
								authStatus={selectedApplication?.app_state}
								tooltipClasses={"tooltip-black"}
							/>
						</div>

						<div
							className="d-flex bold-400 font-10 align-items-center justify-content-end w-60"
							style={{ gap: "10px" }}
						>
							<div className="d-flex align-items-center justify-content-center grey-1 app__pill">
								<img
									src={isAppActive ? greenEllipse : redCircle}
									alt=""
									className="pr-1"
								/>
								{isAppActive ? "Active" : "Inactive"}
							</div>
							<div className="d-flex align-items-center justify-content-center grey-1 app__pill">
								<img src={totalUsers} alt="" className="pr-1" />
								{`${
									selectedApplication?.active_app_user_count ||
									0
								} Users`}
							</div>
						</div>
					</div>
					<div
						className="review-details-form"
						style={{ margin: "0px", border: "1px solid #ebebeb" }}
					>
						<AddLevelsOfReviews
							header="Select Reviewers"
							hideDescription={true}
							levels={applicationReview?.levels}
							handleChange={updateLevelInfo}
							handleAddLevel={handleAddLevelInfo}
							handleRemoveLevel={handleRemoveLevelInfo}
							shouldRenderLevels={true}
						/>
					</div>
				</div>
			)}
		</div>
	);
}

const Loader = (props) => {
	const { width, height, speed, background, className } = props;
	return (
		<ContentLoader
			className={`border-radius-8 ${className}`}
			height={height}
			backgroundColor={background}
			width={width}
			speed={speed || 1}
		>
			<rect width={width} height={height} fill="#ffffff" y="0" x="0" />
		</ContentLoader>
	);
};
