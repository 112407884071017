import React from "react";
import { Loader } from "./Loader";

const QUALITY = {
	HIGH_RELIABLE: "High Reliable",
	UNRELIABLE: "Unreliable",
};

function getQualityTemplate(quality) {
	switch (quality) {
		case quality === QUALITY.HIGH_RELIABLE:
			return (
				<span className="q-green p-1 pl-2 pr-2">
					{QUALITY.HIGH_RELIABLE}
				</span>
			);
		case quality === QUALITY.UNRELIABLE:
		default:
			return (
				<span className="q-red p-1 pl-2 pr-2">
					{QUALITY.UNRELIABLE}
				</span>
			);
	}
}

export function UsageQuality({ data, component, style }) {
	return (
		<span style={style} className="v2table__cell-quality font-11 black-1">
			{data[component.valueKey] ? (
				getQualityTemplate(data[component.valueKey])
			) : (
				<Loader type={data[component.loaderType]} />
			)}
		</span>
	);
}
