import { client } from "utils/client";
import { DATA_FRESHNESS_CONSTANTS } from "../constants";
import { getSegmentFromURL } from "@zluri/ui-components";

export const getDataFreshness = async ({ page, app_id }) => {
	const applicationId = app_id ? app_id : getSegmentFromURL(2);
	if (
		page &&
		[
			DATA_FRESHNESS_CONSTANTS.TRANSACTION,
			DATA_FRESHNESS_CONSTANTS.USER,
			DATA_FRESHNESS_CONSTANTS.APPLICATION,
			DATA_FRESHNESS_CONSTANTS.CHARGEBACK,
		].some((i) => i === page)
	) {
		const response = await client.get(`data-freshness?page=${page}`);
		return response.data;
	}
	if (page && applicationId) {
		const response = await client.get(
			`data-freshness?page=${page}&appId=${applicationId}`
		);
		return response.data;
	}
	return {};
};

export const getDataCalculatedAt = async ({ page }) => {
	const response = await client.get(`data-calculated-at?page=${page}`);
	return response.data;
};
