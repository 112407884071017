import React from "react";

import PropTypes from "prop-types";

import {
	ALERT_TAG_VARIANT,
	GREY_VARIANT,
	Typography,
} from "@zluri/ui-components";

const TagList = ({ tags, className }) => {
	return (
		<div
			className={`d-flex align-items-center flex-wrap gap-8 ${className}`}
		>
			{tags?.map((tag, index) => (
				<Tag key={index} tag={tag} />
			))}
		</div>
	);
};

export const Tag = ({ tag }) => {
	return (
		<Typography
			variant={ALERT_TAG_VARIANT.ALERT_TAG_SMALL_MEDIUM}
			color={GREY_VARIANT.SECONDARY_GREY_2}
			className="padding_6 px-1 border-radius-4 blue_tint_bg"
			key={tag}
		>
			{tag}
		</Typography>
	);
};

export default TagList;

TagList.propTypes = {
	tags: PropTypes.array,
	className: PropTypes.string,
};

Tag.propTypes = {
	tag: PropTypes.string,
};
