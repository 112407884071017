import { getBulkLicenses } from "modules/applications/service/AppLicenses.api";
import { TOAST_COLOR, toast } from "@zluri/ui-components";
import { TOAST_MESSAGE } from "modules/shared/constants/toast.constants";
import { TriggerIssue } from "utils/sentry";

const updateLicense = ({
	props,
	dispatch,
	selectedValue,
	appId,
	bulkEditApiKey,
	isEditing,
}) => {
	const body = {
		selected_ids: [props?.data?.user_id],
		unselected_ids: [],
		filter_by: [],
		action: bulkEditApiKey ?? "assign",
		license_ids: selectedValue.map((lic) => lic?.license_id),
	};
	dispatch({
		type: "UPDATE_RECORD",
		payload: {
			rowIndex: props.rowIndex,
			columnIndex: props.columnIndex,
			skipApi: true,
		},
	});
	getBulkLicenses(appId, body)
		.then((data) => {
			if (data?.error) throw new Error(data?.message);
			toast(
				TOAST_MESSAGE[
					isEditing ? "LICENSE_UPDATE_SUCCESS" : "LICENSE_ADD_SUCCESS"
				],
				{
					indicatorColor: TOAST_COLOR.SUCCESS,
				}
			);
			dispatch({
				type: "RECORD_UPDATED",
				payload: {
					entity: props.entity,
					rowIndex: props.rowIndex,
					columnIndex: props.columnIndex,
					index: props.rowIndex,
					value: {
						licenses: selectedValue.map((lic) => {
							return {
								license_name: lic?.license_name,
								license_id: lic?.license_id,
							};
						}),
					},
				},
			});
		})
		.catch((error) => {
			dispatch({
				type: "RECORD_UPDATE_FAILED",
				payload: {
					entity: props.entity,
					rowIndex: props.rowIndex,
					columnIndex: props.columnIndex,
					errorMessage: "Error!",
				},
			});
			toast(
				TOAST_MESSAGE[
					isEditing ? "LICENSE_UPDATE_ERROR" : "LICENSE_ADD_ERROR"
				],
				{
					indicatorColor: TOAST_COLOR.ERROR,
				}
			);
			TriggerIssue(error, "Licenses Not Updated");
		});
};

export const customApi = {
	update_license: updateLicense,
};

export const apiKeyAndOtherData = {
	licenses: {
		license_name: "license_name",
		contract_name: "contract_name",
		compared_data: "license_id",
		rightHeadData: "cost_per_license.amount_org_currency_month",
	},
};
