import React, { useEffect, useState } from "react";
import "./index.css";

import classNames from "classnames";
import PropTypes from "prop-types";
import SearchBox from "components/SearchBox";

export default function SearchControl({
	handleSearch,
	searchPlaceholderText,
	isInSearchState,
	loading,
	defaultSearchQuery,
}) {
	const [tags, setTags] = useState(() =>
		Array.isArray(defaultSearchQuery)
			? defaultSearchQuery
			: (defaultSearchQuery?.split(",") ?? [])
	);

	useEffect(() => {
		setTags(
			Array.isArray(defaultSearchQuery)
				? defaultSearchQuery
				: (defaultSearchQuery?.split(",") ?? [])
		);
	}, [defaultSearchQuery]);

	const [text, setText] = useState("");

	return (
		<div
			className={classNames(`page_header_search_box`, {
				"page_header_search_box-disabled": loading,
			})}
		>
			<SearchBox
				tags={tags}
				onChangeTags={(tags) => {
					setTags(tags);
					setText("");
					handleSearch({ keywords: tags });
				}}
				value={text}
				tooltipText={searchPlaceholderText}
				onChangeText={(e) => setText(e.target.value)}
				onClear={() => {
					setText("");
					setTags([]);
					if (isInSearchState) handleSearch({ clearSearch: true });
				}}
				withTags
				showInfoTooltip
				placeholder={
					tags?.length > 0 ? "" : (searchPlaceholderText ?? "Search")
				}
				onEnter={() => {
					const _tags = [...(tags ?? [])];
					if (text.length > 0) {
						_tags.push(text);
						setTags(_tags);
						setText("");
					}

					if (isInSearchState) {
						handleSearch({ keywords: _tags });
						return;
					}
					if (text.length === 0 && tags?.length === 0) {
						return;
					}
					handleSearch({ keywords: _tags });
				}}
			/>
		</div>
	);
}
SearchControl.propTypes = {
	handleSearch: PropTypes.func,
	dataEntity: PropTypes.string,
	searchPlaceholderText: PropTypes.string,
	isInSearchState: PropTypes.bool,
	loading: PropTypes.bool,
	defaultSearchQuery: PropTypes.string,
};
