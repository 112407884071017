import { filterPropertiesHelper } from "modules/v2InfiniteTable/utils/v2infiniteTableUtil";
import { client, clientV2 } from "../../../utils/client";
import { TableRenewalApplication } from "../model/model";

export async function addRenewalForApplication(applicationID, data) {
	const response = await client.post(
		`applications/${applicationID}/renewal`,
		data
	);
	return response.data;
}

export async function editRenewalForApplication(data) {
	const response = await client.put(`renewals/${data.renewalID}`, data);
	return response.data;
}

export async function addSetReminderForApplication(
	applicationID,
	renewalID,
	data
) {
	const response = await client.post(`renewals/reminder/${renewalID}`, data);
	return response.data;
}

export async function editSetReminderForApplication(
	applicationID,
	renewalID,
	data
) {
	const response = await client.post(`renewals/reminder/${renewalID}`, data);
	return response.data;
}

export async function deleteRenewalForApplication(renewalID) {
	const response = await client.delete(`renewals/${renewalID}`);
	return response.data;
}

export async function getAllRenewals() {
	const response = await client.get(`renewals-calender`);
	return response.data;
}

export async function deleteReminderForRenewal(renewalID) {
	const response = await client.delete(`renewals/reminder/${renewalID}`);
	return response.data;
}

export async function getRenewalsV2(pageNo, row, reqBody) {
	const response = await clientV2.post(
		`renewals?page=${pageNo}&row=${row}`,
		reqBody
	);
	const new_data = response?.data?.data.map((val) => {
		return new TableRenewalApplication(val);
	});
	return { data: new_data, meta: response?.data?.meta };
}

export async function getRenewalsPropertiesList() {
	const response = await clientV2.get(`renewals/filters`);
	let newResponseData = filterPropertiesHelper(response.data);
	response.data = newResponseData;
	return response;
}
