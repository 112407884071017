import { Tooltip, Typography } from "@zluri/ui-components";
import React from "react";

interface ITextTooltip {
	title: string;
	children: React.ReactNode;
}
function TextTooltip({ title, children }: ITextTooltip) {
	return (
		<Tooltip
			placement="bottom"
			delay={{ show: 250, hide: 400 }}
			content={(props) => (
				<Typography variant="body_2_regular" color="white">
					{title}
				</Typography>
			)}
			trigger={["hover"]}
		>
			{children}
		</Tooltip>
	);
}
export default TextTooltip;
