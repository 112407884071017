import React from "react";
import plus from "assets/icons/plus.svg";
import license from "assets/v2tables/license-progress.svg";
import chargeback from "assets/v2tables/license-chargeback.svg";
import pending from "assets/v2tables/state-pending.svg";
import completed from "assets/v2tables/state-completed.svg";
import pencil from "assets/icons/edit-pen.svg";
import pencilGrey from "assets/v2tables/edit_pencil.svg";
import filter_icon from "assets/add_filter.svg";
import connected from "assets/v2tables/Icon_Sync.svg";
import disconnected from "assets/v2tables/Icon_Alert.svg";
import not_available from "assets/v2tables/Icon_Close.svg";
import redirectIcon from "assets/v2tables/arrow-int.svg";
import connectedIcon from "assets/v2tables/Type=Connected.svg";
import disconnectedIcon from "assets/v2tables/Type=Disconnected.svg";
import errorIcon from "assets/v2tables/Type=Error.svg";
import notavailableIcon from "assets/v2tables/Type=NotAvailable.svg";
import disconnectedInt from "assets/v2tables/disconnected_int.svg";
import unavailable from "assets/v2tables/security-unavailable.svg";
import available from "assets/available.svg";
import validated from "assets/v2tables/security-validated.svg";
import credit_card from "assets/v2tables/credit_card.svg";
import application from "assets/v2tables/application.svg";
import browser from "assets/v2tables/browser.svg";
import website from "assets/v2tables/website.svg";
import device from "assets/v2tables/device.svg";
import other from "assets/v2tables/other.svg";
import authorised from "assets/applications/authorised.svg";
import teammanaged from "assets/applications/teammanaged.svg";
import individuallymanaged from "assets/applications/individuallymanaged.svg";
import unmanaged from "assets/applications/unmanaged.svg";
import restricted from "assets/applications/restricted.svg";
import needsreview from "assets/applications/needsreview.svg";
import arrowConnect from "assets/v2tables/arrow-grey.svg";
import { getImageForPaymentMethodDropdownNew } from "components/Transactions/Recognised/Dropdown";
import externallogo from "assets/v2tables/externallogo.svg";
import employee from "assets/v2tables/employee.svg";
import service from "assets/v2tables/service.svg";
import group from "assets/v2tables/group.svg";
import { getImageByCardTypeV2 } from "modules/applications/utils/applicationutils";
import macos from "assets/agents/mac13.svg";
import linux from "assets/agents/linux.svg";
import windows from "assets/agents/win10.svg";
import optimizationType from "modules/Optimization/assets/optimizationType.svg";
import userType from "modules/Optimization/assets/user_type.svg";
import external from "assets/external.svg";
import edge from "assets/agents/edge.svg";
import okta from "assets/agents/okta.svg";
import csv from "assets/applications/Icon_CSVFile.svg";
import manual from "assets/applications/Icon_Manual.svg";
import integration from "assets/agents/integration.svg";
const LOGO_MAP = {
	plus,
	license,
	chargeback,
	pencil,
	pencilGrey,
	pending,
	completed,
	error: errorIcon,
	connected: connectedIcon,
	disconnected: disconnectedIcon,
	not_available: notavailableIcon,
	redirect: redirectIcon,
	error_source: disconnected,
	unavailable,
	available,
	validated,
	credit_card,
	filter_icon,
	application,
	browser,
	website,
	device,
	other,
	needs_review: needsreview,
	restricted: restricted,
	unmanaged: unmanaged,
	individually_managed: individuallymanaged,
	team_managed: teammanaged,
	centrally_managed: authorised,
	arrowConnect,
	external: externallogo,
	service,
	employee,
	group,
	macos,
	windows,
	linux,
	continuous: optimizationType,
	user_type: userType,
	employee: userType,
	external: external,
	edge,
	okta,
	csv,
	manual,
	integration,
};
export default function LogoCellRenderer({ data, component }) {
	const valuefromComponent = component?.valueKey;
	const logoValue = Array.isArray(valuefromComponent)
		? valuefromComponent.reduce((_, x) => _?.[x], data)
		: data?.[valuefromComponent];

	const logo = valuefromComponent
		? LOGO_MAP[logoValue?.toLowerCase().replaceAll(" ", "_")]
		: LOGO_MAP[component.value];

	const addItemIcons = ["plus"].includes(component.value); // todo: Icons can be updated based on icons added for link text like '+ Add Owner'
	return (
		<div style={{ ...(component.style ?? {}), display: "flex" }}>
			{logo &&
				(!!component?.hide
					? !(data[component?.hide?.key] === component?.hide?.value)
					: true) && (
					<img
						className={`${
							addItemIcons ? "v2table__cell-empty-link-icon" : ""
						} ${component.className || ""}`}
						src={logo}
						width={component.width || 16}
						alt={logoValue}
					/>
				)}
		</div>
	);
}
export function LogoArrayCellRenderer({ data, component }) {
	let array = data[component?.value];
	let paymentDetailType = data[component.valueKeyCategory];

	return (
		<div style={component.style}>
			{(array || paymentDetailType) && (
				<img
					className={component?.className || ""}
					src={
						(array &&
							getImageForPaymentMethodDropdownNew(array[0])) ||
						getImageByCardTypeV2({
							card_type: paymentDetailType,
						}) ||
						credit_card
					}
					width={component?.width || 16}
					alt={data?.[component?.valueKey] || ""}
				/>
			)}
		</div>
	);
}
