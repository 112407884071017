import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useTableContext } from "containers/v2table/TableContext/context";
import { trackActionSegment } from "modules/shared/utils/segment";
import { entityNameMapForQuickFilters } from "../constants";
import FilterPillButton from "../FilterPillButton";
import TextTooltip from "common/TextTooltip";
export default function QuickFilters({
	entity,
	appliedFilters,
	subEntityData,
	quickfiltersData,
	apiProps,
}) {
	const { isAnyDataSelected, enableBulkEdit } = useTableContext();
	const dispatch = useDispatch();
	const quickfiltersConfig = useSelector(
		(state) => state?.v2TableProperties[entity]?.quick_filters
	);
	const quick_filters = quickfiltersData || quickfiltersConfig || [];
	const isActiveFilter = (filter) => {
		const matchedFilter = appliedFilters.find(
			(f) => f.field_id === filter.field_id
		);
		if (
			matchedFilter &&
			matchedFilter?.field_values?.[0] === filter?.field_values?.[0]
		) {
			return true;
		}
		return false;
	};
	const handleClick = (filter) => {
		const updatedFilters = appliedFilters.filter(
			(appliedFilter) => appliedFilter.field_id !== filter.field_id
		);
		updatedFilters.push(filter);
		trackActionSegment(
			`${entityNameMapForQuickFilters[entity] || entity} - ${
				filter?.quick_filter_label || filter?.field_name
			} quick filter applied`
		);
		dispatch({
			type: "GET_TABLE_DATA",
			payload: {
				shouldRefresh: true,
				filterBy: updatedFilters,
				entity: subEntityData?.entity || entity,
				subEntityData,
				apiProps: apiProps,
			},
		});
	};
	const handleRemove = (filter) => {
		const updatedFilters = appliedFilters.filter(
			(appliedFilter) => appliedFilter.field_id !== filter.field_id
		);
		dispatch({
			type: "GET_TABLE_DATA",
			payload: {
				shouldRefresh: true,
				filterBy: updatedFilters,
				entity,
				subEntityData,
				apiProps: apiProps,
			},
		});
	};

	if (isAnyDataSelected && enableBulkEdit) return null;

	return (
		<div className="d-flex gap-8">
			{quick_filters?.map((filter, idx) => {
				const isThisFilterActive = isActiveFilter(filter);
				return (
					<TextTooltip
						key={filter?.field_id + idx}
						title="Apply Quick Filter"
					>
						<div>
							<FilterPillButton
								size="large"
								onRemove={handleRemove.bind(null, filter)}
								isActive={isThisFilterActive}
								onClick={() => {
									if (isThisFilterActive) {
										handleRemove(filter);
										return;
									}
									handleClick(filter);
								}}
								text={
									filter?.quick_filter_label ||
									filter?.field_name
								}
							/>
						</div>
					</TextTooltip>
				);
			})}
		</div>
	);
}

QuickFilters.propTypes = {
	entity: PropTypes.string,
	appliedFilters: PropTypes.array,
	subEntityData: PropTypes.object,
	quickfiltersData: PropTypes.array,
	apiProps: PropTypes.object,
};
