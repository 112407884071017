import React from "react";
import PropTypes from "prop-types";

const OptionsIcon = ({ color = "#2266E2" }) => {
	return (
		<svg
			width="12"
			height="12"
			viewBox="0 0 12 12"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M5.625 3.375C6.24632 3.375 6.75 2.87132 6.75 2.25C6.75 1.62868 6.24632 1.125 5.625 1.125C5.00368 1.125 4.5 1.62868 4.5 2.25C4.5 2.87132 5.00368 3.375 5.625 3.375Z"
				fill={color}
			/>
			<path
				d="M5.625 7.125C6.24632 7.125 6.75 6.62132 6.75 6C6.75 5.37868 6.24632 4.875 5.625 4.875C5.00368 4.875 4.5 5.37868 4.5 6C4.5 6.62132 5.00368 7.125 5.625 7.125Z"
				fill={color}
			/>
			<path
				d="M5.625 10.875C6.24632 10.875 6.75 10.3713 6.75 9.75C6.75 9.12868 6.24632 8.625 5.625 8.625C5.00368 8.625 4.5 9.12868 4.5 9.75C4.5 10.3713 5.00368 10.875 5.625 10.875Z"
				fill={color}
			/>
		</svg>
	);
};

export default OptionsIcon;
OptionsIcon.propTypes = {
	color: PropTypes.string,
};
