import {
	STATUS,
	certification_stage,
	progressBar,
	reviewDetailsForm,
	defaultReviewActions,
	configureReviewActions,
	entityTypes,
	defaultLevelState,
} from "../constants/constants";
import {
	checkEmptyValuesForReviewers,
	formatDate,
	getEntityStageText,
	getMatchingColumnObjects,
	getReviewersData,
	getRoleTypeString,
} from "../util/util";

export class ApplicationModel {
	constructor(obj) {
		this.reviewDetailsForm = obj.reviewDetailsForm;
		this.selectedApplication = obj.selectedApplication;
		this.reviewersInfo = obj.reviewersInfo;
		this.completeSetupObj = obj.completeSetupObj;
	}
}

class ReviewDetailsModel {
	constructor(obj) {
		this.totalRecords = obj?.totalRecords || obj?.total_records;
		this.pendingRecords = obj?.pendingRecords || obj?.pending;
		this.approvedRecords = obj?.approvedRecords || obj?.approved;
		this.declinedRecords = obj?.declinedRecords || obj?.declined;
		this.downgradedRecords = obj?.downgradedRecords || obj?.modified;
		this.completedRecords = obj?.completedRecords || obj?.completed;
		this.failedRecords = obj?.failedRecords || obj?.failed;
		this.notRequiredRecords = obj?.not_required;
	}
}

class UserModel {
	constructor(obj) {
		this.user_id = obj?.user_id || obj?._id;
		this.user_name = obj?.user_name || obj?.name;
		this.user_profile_img =
			obj?.profile_image ||
			obj?.profile_img ||
			obj?.user_profile_image ||
			obj?.logo;
		this.user_email = obj?.user_email;
	}
}

class ApplicationModelForReviewDetails {
	constructor(obj) {
		this.app_name = obj?.application_name;
		this.app_id = obj?.application_id;
		this.app_logo = obj?.application_image;
		this.columns = obj?.columns;
		this.latestUpdate = obj?.latestUpdate;
		this.notes = obj?.notes;
		this.reviewDetails = new ReviewDetailsModel(
			obj?.reviewDetails || obj?.review_details
		);
		this.actionDetails = new ReviewDetailsModel(
			obj?.actionDetails || obj?.task_details || obj?.actions_details
		);
		this.levels = obj?.reviewers?.map(
			(level) => new AccessReviewLevelData(level)
		);
		this.readyForRemediation = obj?.ready_for_remediation;
		this.currentReviewLevel = obj?.current_review_level;
		this.entityStageText = getEntityStageText(entityTypes.APPLICATION, obj);
		this.isSignOffFeasible = false;
	}
}

class AccessReviewLevelData {
	constructor(obj) {
		this.level = obj?.level;
		this.key = obj?.level;
		this.primary_reviewer = new AccessReviewReviewerModel(
			obj?.primary_reviewer
		);
		this.fallback_reviewer = new AccessReviewReviewerModel(
			obj?.fallback_reviewer
		);
	}
}

export class AccessReviewModel {
	constructor(obj) {
		this.reviewName = obj?.reviewName || obj?.name;
		this.reviewId = obj?.reviewId || obj?._id;
		this.startDate = obj?.startDate || obj?.start_date;
		this.endDate = obj?.endDate || obj?.end_date;
		this.review_concluded_on = obj?.review_concluded_on;
		this.certification_completed_on = obj?.certification_completed_on;
		this.remediationDate = obj?.remediation_end_date;
		this.reviewDetails = new ReviewDetailsModel(
			obj?.reviewDetails || obj?.review_details
		);
		this.actionDetails = new ReviewDetailsModel(
			obj?.actions_details || obj?.task_details
		);
		this.reviewOwner = new UserModel({
			user_name: obj?.owner_name,
			user_id: obj?.owner_id,
			user_profile_image: obj?.owner_profile_image,
		});
		this.applications = obj?.applications?.map(
			(application) => new ApplicationModelForReviewDetails(application)
		);
		this.notes = obj?.notes;
		this.org_id = obj?.org_id;
		this.certification_stage =
			obj?.concludeReviewJobStatus === "pending"
				? certification_stage.CONCLUDE_REVIEW_JOB_IN_PROGRESS
				: obj?.certification_stage ||
					certification_stage?.USER_CREATION_IN_PROGRESS;
		this.concludeReviewJobStatus =
			obj?.concludeReviewJobStatus || "not_started";
	}
}

export class AccessReviewOverviewUpdatesModel {
	constructor(obj) {
		this.updateType = obj?.updateType;
		this.updateMessage = obj?.updateMessage;
		this.reviewName = obj?.reviewName;
		this.reviewEndDate = obj?.reviewEndDate;
		this.reviewId = obj?.reviewId;
	}
}

export class AccessReviewCloneAndSanitizeCertDataModel {
	constructor(obj) {
		this.progressBar = JSON.parse(progressBar)?.map((step) => {
			if (step?.STEP === "Complete Setup") return { ...step };
			return { ...step, STATUS: STATUS.Completed };
		});
		this.reviewDetailsForm = {
			...reviewDetailsForm,
			name: `Copy of ${obj?.name}`,
			description: obj?.description,
			owner: {
				user_name: obj?.owner_name,
				user_email: obj?.owner_email,
				profile_img: obj?.owner_profile,
				_id: obj?.owner_id,
			},
		};
		this.initialLevels = obj?.reviewers_configuration?.map(
			(level) => new AccessReviewLevelData(level)
		) || [defaultLevelState];
		this.initialLevelsExist = checkEmptyValuesForReviewers(
			this.initialLevels[0]?.primary_reviewer,
			this.initialLevels[0]?.fallback_reviewer
		);
		this.applications = obj?.applications?.map(
			(app) =>
				new AccessReviewApplicationsModel({
					...app,
					columnsData: obj?.columnsData,
				})
		);
	}
}

export class AccessReviewApplicationsModel {
	constructor(obj) {
		this.appId = obj?.application_id;
		this.id = obj?.application_id;
		this.application = {
			id: obj?.application_id,
			app_name: obj?.application_name,
			app_logo: obj?.application_image,
			app_state: obj?.app_state,
		};
		this.levels = obj?.reviewers?.map(
			(level) => new AccessReviewLevelData(level)
		);
		this.review_actions = {
			on_accept: {
				...defaultReviewActions,
				action: configureReviewActions.DO_NOTHING,
			},
			on_decline: new AccessReviewWorkflowTemplateModel(
				obj?.review_actions?.on_decline
			),
			on_modify: new AccessReviewWorkflowTemplateModel(
				obj?.review_actions?.on_modify
			),
		};
		this.filter_by = obj?.filter_by;
		this.columns = getMatchingColumnObjects(obj?.columns, obj?.columnsData);
		this.dynamic_records = false;
	}
}

export class AccessReviewReviewerModel {
	constructor(obj) {
		this.role_type = obj?.role_type || obj?.value;
		this.user_email = obj?.user_email;
		this._id = obj?.user_id;
		this.user_name =
			obj?.user_name ||
			obj?.name ||
			getRoleTypeString(obj?.role_type || obj?.role);
		this.user_profile_img =
			obj?.user_profile_img ||
			obj?.profile_img ||
			obj?.user_profile ||
			obj?.profile_image;
		this.user_role = obj?.user_role;
		this.key = obj?.role_type || obj?.role;
	}
}

export class AccessReviewWorkflowTemplateModel {
	constructor(obj) {
		this.action = configureReviewActions.SELECT_PLAYBOOK;
		this.assignee = {};
		this.description = "";
		this.due_in_days = "";
		this.assignee_id = "";
		this.workflow_template_id = {
			_id: obj?.workflow_template_id,
			name: obj?.workflow_template_name,
			published_by_user_id: obj?.published_by_user_id,
			published_by_user_name: obj?.published_by_user_name,
			published_by_user_logo: obj?.published_by_user_logo,
		};
	}
}

export class AccessReviewCreateReviewRequestBody {
	constructor(obj) {
		this.certification_extension_enabled = false;
		this.review_setup_action = obj?.review_setup_action;
		this.start_date = formatDate(obj?.start_date, "YYYY-MM-DD", true);
		this.end_date = formatDate(obj?.end_date, "YYYY-MM-DD", true);
		this.remediation_end_date = formatDate(
			obj?.remediation_end_date,
			"YYYY-MM-DD",
			true
		);
		this.review_type = obj?.review_type;
		this.name = obj?.name;
		this.owner_id = obj?.owner?._id;
		this.description = obj?.description;
		this.applications = obj?.applications?.map(
			(app) => new AccessReviewCreateReviewApplicationsRequestBody(app)
		);
		this.reviewers_configuration = getReviewersData(
			obj?.reviewers_configuration
		);
	}
}

export class AccessReviewCreateReviewApplicationsRequestBody {
	constructor(app) {
		this.application_id =
			app?.application?.app_id ||
			app?.application?.id ||
			app?.application?.appId;
		this.columns = app?.columns
			?.map((col) => col.group_name)
			?.filter((id) => id);
		this.filter_by = app?.filter_by;
		this.users = [];
		this.review_actions = {
			...app?.review_actions,
			on_accept: {
				workflow_template_id: "",
			},
			on_modify: {
				workflow_template_id:
					app?.review_actions?.on_modify?.workflow_template_id?._id,
			},
			on_decline: {
				workflow_template_id:
					app?.review_actions?.on_decline?.workflow_template_id?._id,
			},
		};
		this.reviewers = getReviewersData(app?.levels);
		this.dynamic_records = false;
	}
}
