import React from "react";
import PropTypes from "prop-types";
import animationData from "../../assets/progress_bar.json";
// import loadable from "@loadable/component";
// const Lottie = loadable.lib(() => import("react-lottie"));
import Lottie from "react-lottie";

export function ProgressBarLoader(props) {
	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: animationData,
		rendererSettings: {
			preserveAspectRatio: "xMidYMid slice",
		},
	};
	return (
		<Lottie
			isClickToPauseDisabled
			options={defaultOptions}
			height={props.height || 600}
			width={props.width || 400}
			style={props?.style}
		/>
	);
}

ProgressBarLoader.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
	style: PropTypes.object,
};
