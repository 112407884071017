import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { Auth0Provider } from "@auth0/auth0-react";
import { getUrlVars } from "utils/common";
import {
	getValueFromSessionStorage,
	setValueToSessionStorage,
	getValueFromLocalStorage,
} from "utils/localStorage";

export const getCustomClaims = () => {
	const param = getUrlVars(window.location.search);
	const orgId =
		param.orgId || getValueFromSessionStorage("support_organization");
	const tenantId = param.tenantId || getValueFromSessionStorage("tenantId");
	const support = param.support || getValueFromSessionStorage("support");
	const orgSlug = getValueFromLocalStorage("orgSlug");

	if (orgId && support) {
		setValueToSessionStorage("support", support);
		setValueToSessionStorage("support_organization", orgId);
		return {
			isSupportUser: support,
			orgId,
		};
	}

	if (tenantId) {
		setValueToSessionStorage("tenantId", tenantId);
		return {
			orgId: tenantId,
		};
	}

	if (orgSlug && !tenantId) {
		return {
			orgSlug,
		};
	}
	return {};
};

const Auth0ProviderWithHistory = ({ children }) => {
	const history = useHistory();

	const onRedirectCallback = (appState) => {
		history.push(appState ? appState.returnTo : window.location.pathname);
	};

	let authProps = {
		domain: process.env.REACT_APP_AUTH0_DOMAIN,
		clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
		authorizationParams: Object.assign(
			{
				redirect_uri: window.location.origin,
				audience: process.env.REACT_APP_AUTH0_AUDIENCE,
				scopes: "openid profile email offline_access",
				responseType: "code",
			},
			getCustomClaims()
		),
		onRedirectCallback: onRedirectCallback,
		useRefreshTokens: true,
		//## Why am I getting a `missing_refresh_token` error after upgrading to v2?
		// v1 of the SDK used an iframe as a backup if no refresh token was available. You could control this behaviour with the `useRefreshTokensFallback` option, which was `true` by default. With v2, we have flipped the default for `useRefreshTokensFallback` to `false` (see [the migration guide](https://github.com/auth0/auth0-spa-js/blob/master/MIGRATION_GUIDE.md#no-more-iframe-fallback-by-default-when-using-refresh-tokens)). As a result, you may encounter `missing_refresh_token` errors.
		// To mitigate this, use one of the following solutions:
		// - Handle the error, and log the user in again using `loginWithRedirect` or `loginWithPopup`.
		// - Revert to the v1 behaviour by setting `useRefreshTokenFallback` to `true`.

		// In all cases, the fallback will not work in browsers where third-party cookies are blocked, unless you are using [custom domains](https://auth0.com/docs/customize/custom-domains).
		useRefreshTokensFallback: true,
		cacheLocation: "localstorage",
	};

	return <Auth0Provider {...authProps}>{children}</Auth0Provider>;
};

Auth0ProviderWithHistory.propTypes = {
	children: PropTypes.element,
};

export default Auth0ProviderWithHistory;
