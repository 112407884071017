import { getImageByCardTypeV2 } from "modules/applications/utils/applicationutils";
import React, { useEffect, useState } from "react";
import { getPaymentMethods, setPMTransBulk } from "services/api/transactions";
import { TriggerIssue } from "utils/sentry";

import selectImpact from "assets/applications/tick.svg";
import iconSearch from "assets/applications/Icon_Search.svg";
import {
	BODY_VARIANT,
	Field,
	GREY_VARIANT,
	Typography,
} from "@zluri/ui-components";
import { handleComparingData } from "modules/applications/utils/Spends";
import { useDispatch } from "react-redux";
import { Loader } from "common/Loader/Loader";

export default function SelectPaymentMethod(props) {
	const { data, component } = props;
	const [paymentsearchresult, setpaymentsearchresult] = useState([]);
	const [paymentMethods, setPaymentMethods] = useState([]);
	const [paymentLoading, setPaymentLoading] = useState(false);
	const [searchParams, setSearchParams] = useState();
	const dispatch = useDispatch();

	useEffect(() => {
		getPaymentMethods().then((res) => {
			setPaymentMethods(res.data);
			setPaymentLoading(true);
		});
	}, [data]);

	const handlePaymentData = (payment, totalPayment) => {
		Array.isArray(payment) &&
			payment?.length > 0 &&
			payment.map((el) => el && totalPayment.push(el));
	};
	const paymentDropdownOption = () => {
		const totalPayment = [];
		handlePaymentData(paymentMethods?.payment_ccs, totalPayment);
		handlePaymentData(paymentMethods?.payment_banks, totalPayment);
		handlePaymentData(paymentMethods?.payment_others, totalPayment);
		return searchParams && searchParams.length !== 0
			? paymentsearchresult
			: totalPayment;
	};

	const handleSearchPayment = (
		payment,
		cardName,
		matchingPaymentArray,
		search
	) => {
		Array.isArray(payment) &&
			payment?.length > 0 &&
			payment.map(
				(el) =>
					el?.[cardName]?.toLowerCase().includes(search) &&
					matchingPaymentArray.push(el)
			);
	};

	const handleChangePayment = (value, paymentLoading) => {
		const search = value?.toLowerCase();
		const matchingPaymentArray = [];
		handleSearchPayment(
			paymentMethods?.payment_ccs,
			"cc_card_name",
			matchingPaymentArray,
			search
		);
		handleSearchPayment(
			paymentMethods?.payment_banks,
			"bank_name",
			matchingPaymentArray,
			search
		);
		handleSearchPayment(
			paymentMethods?.payment_others,
			"payment_method_name",
			matchingPaymentArray,
			search
		);
		setpaymentsearchresult(matchingPaymentArray);
	};

	const handleSubmitPayment = (transaction_id, paymentData) => {
		const transcID = transaction_id.split();
		dispatch({
			type: "UPDATE_RECORD",
			payload: {
				rowIndex: props.rowIndex,
				columnIndex: props.columnIndex,
				skipApi: true,
			},
		});
		setPMTransBulk(transcID, paymentData?.payment_method_id)
			.then((res) => {
				dispatch({
					type: "RECORD_UPDATED",
					payload: {
						entity: props.entity,
						rowIndex: props.rowIndex,
						columnIndex: props.columnIndex,
						index: props.rowIndex,
						value: {
							payment_method_id: paymentData?.payment_method_id,
							payment_method_name:
								paymentData?.cc_card_name ||
								paymentData?.bank_name ||
								paymentData?.payment_method_name,
							payment_method_details_type:
								paymentData?.cc_card_type,
							payment_method_details_number:
								paymentData?.bank_masked_account_digits ||
								paymentData?.cc_masked_digits,
							payment_method_type:
								paymentData?.cc_card_type || null,
							payment_method_logo_url: getImageByCardTypeV2({
								card_type: paymentData?.cc_card_type,
							}),
						},
					},
				});
			})
			.catch((err) =>
				TriggerIssue("Error in Payment method Update", err)
			);
	};

	const comareTick = (option) =>
		option?.payment_method_id === data[component?.valueKey];

	return (
		<div className="p-1">
			<div className="mb-1 w-100 position-relative">
				<Field
					handlers={{
						onChange: (val) => {
							handleChangePayment(val.target?.value);
							setSearchParams(val.target?.value);
						},
					}}
					placeholder="Search Payment"
					className="w-100 border-radius-8 primary-color-border pl-4 font-11"
				/>
				<img
					src={iconSearch}
					alt=""
					className="position-absolute"
					style={{
						left: "8px",
						top: "7px",
						width: "12px",
						height: "12px",
						filter: "invert(32%) sepia(93%) saturate(1244%) hue-rotate(200deg) brightness(90%) contrast(97%)",
					}}
				/>
			</div>
			{paymentLoading ? (
				<div
					className="overflow-auto hide-scrollbar"
					style={{ maxHeight: "110px" }}
				>
					{searchParams !== undefined &&
					paymentsearchresult?.length === 0 ? (
						<div className="d-flex justify-content-center mt-3 mb-3 ml-auto mr-auto">
							<Typography
								variant={BODY_VARIANT.BODY_2_REGULAR}
								color={GREY_VARIANT.SECONDARY_GREY_3}
							>
								No payment methods
							</Typography>
						</div>
					) : (
						paymentDropdownOption().map((option) => {
							return (
								<div
									className={`d-flex justify-content-between align-items-center w-100 p-1 payment-card-dropdown ${handleComparingData(
										comareTick(option),
										"single-transc-dropdown-select",
										"single-transc-dropdown"
									)} `}
									key={option}
									onClick={() => {
										handleSubmitPayment(
											data[component?.updateKeys],
											option
										);
									}}
								>
									<div className="d-flex flex-row w-100">
										<div className="d-flex align-items-center">
											<img
												src={getImageByCardTypeV2({
													card_type:
														option?.cc_card_type,
												})}
												style={{
													marginRight: "4px",
													height: "14px",
													width: "22px",
												}}
												alt=""
											/>
										</div>
										<div>
											<div className="font-11 bold-700">
												{option.cc_card_name ||
													option.bank_name ||
													option.payment_method_name}
											</div>
											<div className="font-9 bold-400">
												{option.bank_name &&
													"Account Number"}
												{option.cc_card_name &&
													"Card Number"}
												{option.bank_masked_account_digits ||
												option.cc_masked_digits
													? " •••• " +
													  (option.bank_masked_account_digits ||
															option.cc_masked_digits)
													: null}
											</div>
										</div>
									</div>
									{handleComparingData(
										comareTick(option),
										<img src={selectImpact} alt="" />,
										<span className="single-transc-dropdown-hover primary-color">
											+
										</span>
									)}
								</div>
							);
						})
					)}
				</div>
			) : (
				<Loader height={60} width={60} />
			)}
		</div>
	);
}
