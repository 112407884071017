import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { GREY_VARIANT, Typography } from "@zluri/ui-components";


const StatsOuterWrapper = styled.div`
	display: flex;
	justify-content:space-between;
`;

const StatWrapper = styled.div`
	display: flex;
	gap: 8px;
	align-items: center;
`;

const TextWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

const ModalStatsBlock = ({ stats }) => {
	return (
		<>
			<StatsOuterWrapper>
				{stats.map(({ label, icon, value }) => (
					<StatWrapper key={label}>
						{icon}
						<TextWrapper>
							<Typography
								color={GREY_VARIANT.SECONDARY_GREY_2}
								variant="body_2_regular"
							>
								{label}
							</Typography>
							<Typography variant="body_2_bold">
								{value}
							</Typography>
						</TextWrapper>
					</StatWrapper>
				))}
			</StatsOuterWrapper>
		</>
	);
};

export default ModalStatsBlock;

ModalStatsBlock.propTypes = {
	stats: PropTypes.array,
};
