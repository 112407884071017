import React from "react";
import PropTypes from "prop-types";

import SelectEntity from "containers/v2table/cellRenderer/SelectEntity";
import { isNullOrUndefined } from "modules/applications/utils/appAbout.utils";
import { AppUserOption } from "../AppUserOption";

export function CustomReferenceEditPopover({
	field_reference,
	field_id,
	data,
	...rest
}) {
	const component = {
		entity: field_reference === "orgusers" ? "user" : "applications",
		updateKeys: {
			id: field_id,
		},
		customApi: "customFieldTask",
	};

	const optionsFormatter = (selected, handleSelect) => {
		const onSelect = () => handleSelect(selected);
		return (
			<AppUserOption
				id={selected?.user_id ?? selected?.app_id}
				name={selected?.user_name ?? selected?.app_name}
				imageSrc={selected?.profile_img ?? selected?.app_logo}
				selectedId={rest?.field_value}
				handleSelect={onSelect}
			/>
		);
	};

	return (
		<SelectEntity
			component={component}
			patchType={isNullOrUndefined(data?.[field_id]) ? "add" : "replace"}
			appId={data["app_id"]}
			fieldId={field_id}
			customFields={data?.custom_fields}
			hideScrollAndDivider
			hideEmail
			optionsMaxHeight={170}
			optionsFormatter={optionsFormatter}
			containerStyle={{
				padding: 2,
			}}
			customFieldName={rest?.field_sub_detail_text}
		/>
	);
}

CustomReferenceEditPopover.propTypes = {
	field_reference: PropTypes.string,
	field_id: PropTypes.string,
	data: PropTypes.shape({
		app_id: PropTypes.string,
		custom_fields: PropTypes.array,
	}),
};
