import React, { useRef, useState } from "react";
import "./NoteCard.css";

import PropTypes from "prop-types";
import moment from "moment";
import {
	Divider,
	ImageOrNameBadge,
	Typography,
	BODY_VARIANT,
	GREY_VARIANT,
	Tooltip,
	Popover,
	BLUE_VARIANT,
	ALERT_TAG_VARIANT,
} from "@zluri/ui-components";

import { NoteHeaderDropdownOptions } from "./NoteHeaderDropdownOptions";
import { NoteDragHandle } from "../NotesApplicationDrawer/NotesRearrangable";

import reminderAlarmIcon from "assets/Icon_Reminder_Alarm.svg";
import menuIcon from "assets/Icon_Menu_3.svg";

import { useOutsideClickListener } from "utils/clickListenerHook";

export function NoteHeader({
	ribbonColor,
	createdAt,
	reminderDateTime,
	createdBy,
	createdByProfile,
	onEdit,
	onDelete,
	isEditing,
	variant = "sm",
	showDragIcon,
}) {
	const noteCreatedDate = createdAt
		? moment(createdAt).format("D MMMM, YY")
		: null;

	const [showPopover, setShowPopover] = useState(false);
	const menuIconRef = useRef();
	useOutsideClickListener(
		menuIconRef,
		() => setShowPopover(false),
		[],
		["z_overview_note_header_options"]
	);

	return (
		<div
			className={`d-flex align-items-center justify-content-between w-100 ${
				variant !== "sm" && "mb-1"
			}`}
			style={{ height: "24px", overflow: "hidden" }}
		>
			<div className="d-flex align-items-center" style={{ gap: "4px" }}>
				<div
					className="z_overview_notes_ribbon"
					style={{
						backgroundColor: ribbonColor,
						borderColor: ribbonColor,
					}}
				/>
				{showDragIcon && <NoteDragHandle />}
				<div className="d-flex align-items-center gap-4">
					<ImageOrNameBadge
						height="12px"
						width="12px"
						name={createdBy}
						url={createdByProfile}
						fontSize="8px"
					/>
					<Typography
						color={GREY_VARIANT.SECONDARY_GREY_3}
						variant={
							variant === "sm"
								? BODY_VARIANT.BODY_3_REGULAR
								: BODY_VARIANT.BODY_2_REGULAR
						}
					>
						{createdBy}
					</Typography>
				</div>
				{noteCreatedDate && (
					<>
						<Divider
							width="1px"
							height="12px"
							backgroundColor="#DDD"
							borderRadius={4}
						/>
						<Typography
							color={GREY_VARIANT.SECONDARY_GREY_3}
							variant={
								variant === "sm"
									? BODY_VARIANT.BODY_3_REGULAR
									: BODY_VARIANT.BODY_2_REGULAR
							}
						>
							{noteCreatedDate}
						</Typography>
					</>
				)}
			</div>
			{!isEditing && (
				<div className="d-flex align-items-center gap-8 mr-2">
					{reminderDateTime &&
						(variant === "sm" ? (
							<Tooltip
								className="test"
								content={
									<Typography
										color="white"
										variant={
											ALERT_TAG_VARIANT.ALERT_TAG_SMALL_MEDIUM
										}
									>
										Reminder on {reminderDateTime}
									</Typography>
								}
								placement="bottom"
							>
								<img src={reminderAlarmIcon} alt="reminder" />
							</Tooltip>
						) : (
							<div className="d-flex align-items-center gap-4">
								<img src={reminderAlarmIcon} alt="reminder" />
								<Typography
									color={BLUE_VARIANT.PRIMARY_BLUE_DARK_2}
									variant={
										ALERT_TAG_VARIANT.ALERT_TAG_SMALL_MEDIUM
									}
								>
									Reminder on {reminderDateTime}
								</Typography>
							</div>
						))}
					<Popover
						show={showPopover}
						placement={variant === "sm" ? "bottom" : "bottomRight"}
						className="z_overview_notes_header_popover"
						content={
							<NoteHeaderDropdownOptions
								onEdit={onEdit}
								onDelete={onDelete}
								setShowPopover={setShowPopover}
							/>
						}
					>
						<img
							ref={menuIconRef}
							src={menuIcon}
							alt="menu"
							className="z_overview_notes_menu cursor-pointer"
							onClick={() => setShowPopover(true)}
						/>
					</Popover>
				</div>
			)}
		</div>
	);
}

NoteHeader.propTypes = {
	ribbonColor: PropTypes.string,
	createdAt: PropTypes.string,
	reminderDateTime: PropTypes.string,
	createdBy: PropTypes.string,
	createdByProfile: PropTypes.string,
	onEdit: PropTypes.func,
	onDelete: PropTypes.func,
	isEditing: PropTypes.bool,
	variant: PropTypes.string,
	showDragIcon: PropTypes.bool,
};
