import { getSegmentFromURL } from "@zluri/ui-components";
import { filterPropertiesHelper } from "modules/v2InfiniteTable/utils/v2infiniteTableUtil";
import { isNull } from "underscore";
import { client, clientV3 } from "utils/client";
import { clientV2 } from "utils/client";

export async function getSpendsOverviewDetails(appId) {
	const response = await clientV2.get(
		`applications/${appId}/spends-summary-details`
	);
	return response;
}
export async function getApplicationSpendsSetting(appId) {
	const response = await clientV2.get(
		`applications/${appId}/spends-settings`
	);
	return response;
}

export async function addApplicationSpendsSetting(setting, appId) {
	const response = await client.put(`applications/${appId}`, setting);
	return response.data;
}

export async function getApplicationSpendsSummary(appId) {
	// replacing with the prod endpoint
	const response = await client.get(`applications/${appId}/spend-summary`);
	return response;
}
export async function getApplicationSpendsPaymentMethod(appId) {
	// replacing with the prod endpoint
	const response = await client.get(`application/${appId}/payment-method`);
	return response;
}
export async function postApplicationSpendsPaymentDefault(
	defaultpayment,
	appId
) {
	// replacing with the prod endpoint
	const response = await client.put(
		`application/${appId}/default-payment-method`,
		defaultpayment
	);
	return response.data;
}
export async function getApplicationSpendsFinancial(appId) {
	// replacing with the prod endpoint
	const response = await clientV2.get(`applications/${appId}/app-sources`);
	return response;
}

export async function updateApplicationFinanceOwner(
	appId,
	app_financial_owner_id
) {
	// replacing with the prod endpoint
	const response = await client.put(
		`applications/${appId}/app-finance-owner`,
		{
			app_financial_owner_id,
		}
	);
	return response;
}

export async function getAccruedSpendsGraphData(
	appId,
	start_month,
	start_year,
	end_month,
	end_year
) {
	const response = await clientV2.get(
		`applications/${appId}/accrued-spend?start_month=${start_month}&start_year=${start_year}&end_month=${end_month}&end_year=${end_year}`
	);
	return response;
}

export async function getActualSpendsvsCostGraphData(
	appId,
	start_month,
	start_year,
	end_month,
	end_year,
	period = "month"
) {
	const response = await clientV2.get(
		`applications/${appId}/spend?period=${period}&start_month=${start_month}&start_year=${start_year}&end_month=${end_month}&end_year=${end_year}`
	);
	return response;
}

export async function getAmmortizedSpendsvsCostGraphData(
	appId,
	start_month,
	start_year,
	end_month,
	end_year,
	period = "month"
) {
	const response = await clientV2.get(
		`applications/${appId}/amortized-spend?period=${period}&start_month=${start_month}&start_year=${start_year}&end_month=${end_month}&end_year=${end_year}`
	);
	return response;
}

export async function getALLSpendsTransactionTable(
	reqObj,
	page,
	row,
	cancelTokenSource = null,
	searchReqParams,
	intId,
	apiProps
) {
	let options = {};
	let url = `applications/${intId}/transactions?page=${page}&row=${row}`;
	if (cancelTokenSource) {
		options = {
			cancelToken: cancelTokenSource.token,
		};
	}
	if (searchReqParams?.length > 0) {
		url = `applications/${intId}/transactions?is_search=${true}&search_query=${encodeURIComponent(
			searchReqParams
		)}`;
	}

	const response = await clientV3.post(url, reqObj, options);
	let newResponseData = filterPropertiesHelper(response.data);
	response.data = newResponseData;
	return response.data;
}

export async function getSpendsTransactionTable({ appId }) {
	const response = await clientV3.get(
		`applications/${appId}/transactions/filters`
	);
	let newResponseData = filterPropertiesHelper(response.data);
	response.data = newResponseData;
	return response.data;
}

export async function getSpendsTransactionTableSource(appId, transcId) {
	const response = await client.get(
		`applications/${appId}/transactions/${transcId}/transaction-source`
	);
	return response.data;
}

export async function updateVendorTask(transcId, vendorId) {
	const option = {
		vendorId: vendorId,
	};
	const response = await client.put(
		`transactions/${transcId}/update-vendor`,
		option
	);
	return response;
}

export async function removeTransactionVendor(transcId, vendorId) {
	const option = {
		vendorId,
	};
	const response = await client.put(
		`transactions/${transcId}/delete-vendor`,
		option
	);
	return response;
}

export async function fetchTransactionTableVendor(vendorId) {
	const response = await client.get(`vendors/${vendorId}`);
	return response.data;
}

export async function getApplicationUsers(
	reqObj,
	page,
	row,
	cancelTokenSource = null,
	searchReqParams,
	intId,
	apiProps
) {
	let options = {
		sort_by: [],
		filter_by: [],
		params: {
			page,
			row,
		},
	};
	let url = `applications/${intId}/users`;

	if (cancelTokenSource) {
		options.cancelToken = cancelTokenSource.token;
	}

	if (searchReqParams?.length > 0) {
		options = {
			...options,
			params: {
				...options.params,
				is_search: true,
				search_query: `${searchReqParams}`,
			},
		};
	}

	const body = {
		...reqObj,
		columns: reqObj?.columns
			?.map((col) => col.group_name)
			.filter((col) => col),
	};

	const response = await clientV3.post(
		url,
		body?.columns?.length === 0 ? reqObj : body,
		options
	);
	let newResponseData = filterPropertiesHelper(response.data);
	response.data = newResponseData;
	return response.data;
}

export async function getAppUserPropertiesList() {
	const response = await clientV3.get(`applications/users/filters`);
	let newResponseData = filterPropertiesHelper(response.data);
	response.data = newResponseData;
	return response.data;
}

// SECURITY & COMPLIANCE
export async function getAppCriticalUsersProperties(...props) {
	const appId = props[0]?.appId;
	const response = await clientV2.get(
		`applications/${appId}/critical-users/filters`
	);
	let newResponseData = filterPropertiesHelper(response.data);
	response.data = newResponseData;
	return response.data;
}

export async function getAppCriticalUsersList(
	reqObj,
	page,
	row,
	cancelTokenSource = null,
	searchReqParams,
	intId,
	apiProps
) {
	let options = {};
	let url = `applications/${intId}/critical-users?riskLevel=${apiProps?.riskLevel}&page=${page}&row=${row}`;
	if (cancelTokenSource) {
		options = {
			cancelToken: cancelTokenSource.token,
		};
	}
	if (searchReqParams?.length > 0) {
		url = `applications/${intId}/critical-users?riskLevel=${
			apiProps?.riskLevel
		}&is_search=${true}&search_query=${encodeURIComponent(
			searchReqParams
		)}`;
	}

	const response = await clientV2.post(
		url,
		{ ...reqObj, scope: [apiProps?.scope] },
		options
	);
	let newResponseData = filterPropertiesHelper(response.data);
	response.data = newResponseData;
	return response.data;
}
export async function getApplicationAlternateApps(appId) {
	const response = await client.get(`applications/${appId}/alternate`);
	return response;
}
export async function getAlternateAppFeatures(appIds) {
	const response = await client.post(`get-all-app-features`, { appIds });
	return response?.data;
}

// APP OVERVIEW
export async function getAppOverviewMetric(appId) {
	// const response = await clientV2.get(`applications/${appId}/app-metrics`);
	const response = {
		data: {},
	};
	return response;
}
export async function getAppOverviewNotes(appId) {
	const response = await client.post("application/hot/notes", {
		entity_id: appId,
		entity_type: "application",
	});
	return response;
}
export async function updateAppOverviewNotesOrder(appId, order) {
	const response = await client.post("application/hot/notes/arrange", {
		entity_id: appId,
		entity_type: "application",
		order,
	});
	return response;
}

export async function updateAppOverviewLinks({ appId, links }) {
	const data = {
		patches: [
			{
				op: "replace",
				field: "app_links",
				value: links,
			},
		],
	};
	const response = await clientV2.patch(`applications/${appId}`, data);
	return response;
}

export async function getAppOverviewChecklist(appId) {
	const response = await clientV2.get(`applications/${appId}/checklist`);
	return response;
}

export async function skipAppCheckListItem(
	appId,
	checklistId,
	parentId,
	isSkipped
) {
	const response = await clientV2.put(
		`applications/${appId}/checklist/${checklistId}/skip`,
		{
			isSkipped: isSkipped,
			block: parentId,
		}
	);
	return response;
}

export async function getAppSources(appId) {
	const response = await clientV2(`applications/${appId}/sources`);
	return response;
}

export async function getAppUserLicensesGraph({
	appId,
	split_by,
	user_type,
	integration_id,
	integration_name,
	orgId,
}) {
	const response = await clientV2.get(
		`v2/organization/${orgId}/applications/${appId}/overview/graphs/licenses?user_type=${user_type}&${
			isNull(integration_id)
				? `integration_name=${integration_name}`
				: `integration_id=${integration_id}`
		}&split_by=${split_by}`
	);
	return response;
}

export async function getAppOptimizationGraph({
	appId,
	split_by,
	user_type,
	integration_name,
	integration_id,
	orgId,
}) {
	const response = await clientV2.get(
		`applications/${appId}/overview/graphs/optimizations`,
		{
			params: {
				user_type,
				integration_name,
				split_by,
				integration_id,
			},
		}
	);
	return response;
}

export async function getAppUserStatusGraph({
	appId,
	split_by,
	user_type,
	integration_name,
	integration_id,
	orgId,
}) {
	const response = await clientV2.get(
		`v2/organization/${orgId}/applications/${appId}/overview/graphs/users`,
		{
			params: {
				user_type,
				integration_name,
				split_by,
				integration_id,
			},
		}
	);
	return response;
}
export async function getAppLicensesV2Properties({ appId }) {
	const response = await clientV3.get(`applications/licenses/filters`);
	let newResponseData = filterPropertiesHelper(response.data);
	response.data = newResponseData;
	return response.data;
}

export async function getAppContractsV2Properties() {
	const response = await clientV3.get(`applications/contracts/filters`);
	return response.data;
}

export async function getAppLicensesV2(
	reqObj,
	page,
	row,
	cancelTokenSource = null,
	searchReqParams,
	intId,
	apiProps
) {
	let options = {};
	let url = `applications/${intId}/licenses?page=${page}&row=${row}`;
	if (cancelTokenSource) {
		options = {
			cancelToken: cancelTokenSource.token,
		};
	}
	if (searchReqParams?.length > 0) {
		url += `&is_search=${true}&search_query=${encodeURIComponent(
			searchReqParams
		)}`;
	}

	const response = await clientV3.post(url, reqObj, options);
	let newResponseData = filterPropertiesHelper(response.data);
	response.data = newResponseData;
	return response.data;
}
export async function getAppContractsV2(
	reqObj,
	page,
	row,
	cancelTokenSource = null,
	searchReqParams,
	intId,
	apiProps
) {
	let options = {};
	let url = `applications/${apiProps.appId}/contracts?page=${page}&row=${row}`;
	if (cancelTokenSource) {
		options = {
			cancelToken: cancelTokenSource.token,
		};
	}
	if (searchReqParams?.length > 0) {
		url += `&is_search=${true}&search_query=${encodeURIComponent(
			searchReqParams
		)}`;
	}

	const response = await clientV3.post(url, reqObj, options);
	return response.data;
}
export async function getAppMapLicensesV2Properties({ appId }) {
	const response = await clientV3.get(
		`applications/licenses/map-licenses/filters`
	);
	return response.data;
}

export async function getAppMapLicensesV2(
	reqObj,
	page,
	row,
	cancelTokenSource = null,
	searchReqParams,
	intId,
	apiProps
) {
	let options = {};
	let url = `applications/${intId}/licenses/map-licenses?page=${page}&row=${row}`;
	if (cancelTokenSource) {
		options = {
			cancelToken: cancelTokenSource.token,
		};
	}
	if (searchReqParams?.length > 0) {
		url = `applications/${intId}/licenses/map-licenses?is_search=${true}&search_query=${encodeURIComponent(
			searchReqParams
		)}&page=${page}&row=${row}`;
	}

	const response = await clientV3.post(url, reqObj, options);
	let newResponseData = filterPropertiesHelper(response.data);
	response.data = newResponseData;
	return response.data;
}

export async function MappingLicenses() {}

export async function bulkEditAppUsers(data) {
	const appId = getSegmentFromURL(2);
	const response = await client.put(`applications/${appId}/batch`, data);
	return response?.data;
}

export async function fetchInitialRoles() {
	let response;

	try {
		const appId = getSegmentFromURL(2);
		response = await client.get(
			`applications/${appId}/initial-user-app-role`
		);
	} catch (error) {
		return { error: error };
	}
	return response.data;
}

export async function searchUserAppRoles(searchQuery, cancelTokenSource) {
	let response;
	let options = {};

	const appId = getSegmentFromURL(2);

	if (cancelTokenSource) {
		options = {
			cancelToken: cancelTokenSource.token,
		};
	}
	try {
		response = await client.get(
			`applications/${appId}/role/search?q=${searchQuery}`,
			options
		);
	} catch (error) {
		return { error: error };
	}
	return response.data;
}
