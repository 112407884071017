import { getSearchReqObj } from "common/infiniteTableUtil";
import { workflowTagClassifications } from "../constants/constant";
import replaceZluriWithPartnerName from "utils/replaceZluriFromResponse";
import { getValueFromLocalStorage } from "utils/localStorage";
import { PARTNER } from "modules/shared/constants/app.constants";

export const getHyperLinkMetaData = (
	workflowTag,
	workflowIds = null,
	entity
) => {
	const meta = {
		columns: [],
		filter_by: [],
		sort_by: [],
	};

	switch (workflowTag) {
		case workflowTagClassifications.RUNS:
			if (
				workflowIds &&
				Array.isArray(workflowIds) &&
				workflowIds?.length > 0
			) {
				if (entity === "playbook") {
					meta.filter_by.push({
						field_id: `workflow_template_id`,
						field_name: "Playbook Id",
						field_values: workflowIds,
						filter_type: "objectId",
						negative: false,
						is_custom: false,
					});
				} else if (entity === "draft") {
					meta.filter_by.push({
						field_id: `workflow_draft_id`,
						field_name: "Draft Id",
						field_values: workflowIds,
						filter_type: "objectId",
						negative: false,
						is_custom: false,
					});
				}
			}
			break;
		default:
			break;
	}

	return JSON.stringify(meta);
};

export const getFilterObj = (query) => {
	const isPublishedObj = {
		field_values: [true],
		field_order: "contains",
		field_id: "is_published",
		filter_type: "boolean",
		field_name: "Published",
		negative: false,
		is_custom: false,
	};
	const usingVariablesObj = {
		field_values: false,
		field_id: "using_variables",
		filter_type: "boolean",
		field_name: "Variables",
		negative: false,
		is_custom: false,
	};
	if (query && query.length > 0) {
		let filter_by = [getSearchReqObj(query, "name", "Workflow Name")];
		filter_by.push(isPublishedObj, usingVariablesObj);
		return { filter_by: filter_by, sort_by: [], columns: [] };
	}
	return {
		filter_by: [{ ...isPublishedObj }, { ...usingVariablesObj }],
		sort_by: [],
		columns: [],
	};
};

export function getNestedValue(obj, path) {
	// Split the path into an array of keys
	const keyArray = path?.split(".");

	let value = { ...obj };
	// Iterate over the keys in the path
	for (let key of keyArray) {
		value = value[key];
		if (!value) return value; //Undefined case
	}
	return value;
}

export const modifiedAppName = (appName, isStaticNode) =>
	isStaticNode ? replaceZluriWithPartnerName(appName) : appName;

export const modifiedAppLogo = (appLogo, isStaticNode) =>
	isStaticNode &&
	getValueFromLocalStorage("partner")?.name !== PARTNER.ZLURI.name
		? null
		: appLogo;
