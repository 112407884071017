import React, { useState } from "react";

import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import {
	BODY_VARIANT,
	BUTTON_VARIANT,
	Button,
	Collapsible,
	GREY_VARIANT,
	IconButton,
	Loader,
	Popover,
	SecondaryButtonVariant,
	Typography,
	theme,
} from "@zluri/ui-components";

import Icon_Help from "assets/icons/Icon_Help.svg";
import DotsImage from "assets/three-dots-black.svg";

import { skipCheckListItem } from "modules/applications/redux/Applications.actions";
import { handleArrowExpandIcon } from "modules/applications/utils/OverviewV2.utils";

const ChecklistCard = ({
	title,
	icon,
	info,
	onClick,
	cta,
	isCompleted,
	id,
	parentId,
	isSkipped,
	loading,
	error_message,
	appId,
}) => {
	const dispatch = useDispatch();

	const handleSkip = () =>
		dispatch(skipCheckListItem(appId, parentId, id, null));
	const handleUnskip = () =>
		dispatch(skipCheckListItem(appId, parentId, id, true));

	const items = [
		{
			label: (
				<CheckListCardHeader
					title={title}
					icon={icon}
					value={info}
					loading={loading}
				/>
			),
			children: (
				<CheckListCardContent
					isSkipped={isSkipped}
					description={info}
					error_message={error_message}
					actionText={cta?.text}
					isCompleted={isCompleted}
					onClick={onClick}
					handleSkip={handleSkip}
					handleUnskip={handleUnskip}
				/>
			),
		},
	];

	return (
		<Collapsible
			className={`z_overview_checklist_card ${
				isSkipped && "z_overview-checklist_card-skipped"
			} ${loading && "z_overview-checklist_card-loading"}`}
			expandIconPosition="end"
			bordered={false}
			expandIcon={handleArrowExpandIcon}
			items={items}
		/>
	);
};

const CheckListCardHeader = ({ title, icon, loading }) => {
	return (
		<span className="d-flex align-items-center gap-8">
			<img
				src={icon}
				alt=""
				width={16}
				height={16}
				className="z_overview-checklist_card-icon"
			/>
			<Typography
				variant={BUTTON_VARIANT.BUTTON_EXTRA_SMALL_SEMIBOLD}
				color={GREY_VARIANT.SECONDARY_GREY_1}
				className="z_overview-checklist_card-title mr-auto"
			>
				{title}
			</Typography>

			{loading && <Loader color="blue" />}
		</span>
	);
};

const CheckListCardContent = ({
	error_message,
	description,
	actionText,
	onClick,
	isCompleted,
	isSkipped,
	handleSkip,
	handleUnskip,
}) => {
	const [showSkip, setShowSkip] = useState(false);

	const handleSkipOption = () => {
		isSkipped ? handleUnskip && handleUnskip() : handleSkip && handleSkip();
		setShowSkip(false);
	};

	return (
		<span className={`d-flex flex-column gap-8 flex-1`}>
			<Typography
				variant={BODY_VARIANT.BODY_3_REGULAR}
				color={GREY_VARIANT.SECONDARY_GREY_2}
				className="mr-auto w-100"
			>
				{description}
			</Typography>
			{error_message && (
				<span
					className="d-flex align-items-center gap-10 border-radius-8 p-2"
					style={{ background: "#FFE9E5" }}
				>
					<img
						src={Icon_Help}
						alt=""
						width={16}
						height={16}
						className="z_overview-checklist_card-icon"
					/>
					<Typography
						variant={BODY_VARIANT.BODY_3_REGULAR}
						color={GREY_VARIANT.SECONDARY_GREY_2}
					>
						{error_message}
					</Typography>
				</span>
			)}
			<div className="d-flex align-items-center gap-8">
				{!isCompleted && (
					<Button
						className="z_overview-checklist_card-action_btn text-nowrap flex-1"
						theme={theme}
						onClick={onClick}
						variant={SecondaryButtonVariant.secondary_default}
					>
						{actionText}
					</Button>
				)}
				{!isCompleted && (
					<Popover
						show={showSkip}
						onToggle={(open) => setShowSkip(open)}
						content={
							<Typography
								onClick={handleSkipOption}
								variant={
									BUTTON_VARIANT.BUTTON_EXTRA_SMALL_MEDIUM
								}
								color={GREY_VARIANT.SECONDARY_GREY_2}
								className="cursor-pointer d-flex align-items-center flex-1 h-100 w-100"
							>
								{`${isSkipped ? "Unskip" : "Skip"}  Checklist`}
							</Typography>
						}
						placement="topRight"
						className="z_overview-checklist_card-skip_options"
					>
						<IconButton
							className="z_overview_header-actions"
							icon={DotsImage}
							theme={theme}
							variant="grey"
						/>
					</Popover>
				)}
			</div>
		</span>
	);
};

export { ChecklistCard, CheckListCardHeader, CheckListCardContent };

ChecklistCard.propTypes = {
	title: PropTypes.string,
	icon: PropTypes.string,
	info: PropTypes.string,
	onClick: PropTypes.func,
	cta: PropTypes.object,
	isCompleted: PropTypes.bool,
	id: PropTypes.string,
	parentId: PropTypes.string,
	isSkipped: PropTypes.bool,
	loading: PropTypes.bool,
	error_message: PropTypes.string,
};
CheckListCardHeader.propTypes = {
	title: PropTypes.string,
	icon: PropTypes.string,
	loading: PropTypes.bool,
};
CheckListCardContent.propTypes = {
	error_message: PropTypes.string,
	description: PropTypes.string,
	actionText: PropTypes.string,
	onClick: PropTypes.func,
	isCompleted: PropTypes.bool,
	isSkipped: PropTypes.bool,
	handleSkip: PropTypes.func,
	handleUnskip: PropTypes.func,
};
