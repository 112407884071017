import {
	getLicensesMappedStatus,
	getLicensesMappedUserStatus,
	getLicensesTypeSummary,
	getLicensesSummaryMetrics,
	getMapLicensesSummary,
	getLicensesAndContractsGraph,
	postLicensesByDepartment,
} from "modules/applications/service/AppLicenses.api";
import { AppLicensesConstants } from "./AppLicenses.constant";
import { TriggerIssue } from "utils/sentry";

export function setLicenseData(tab) {
	return async function (dispatch) {
		try {
			dispatch({
				type: AppLicensesConstants.SET_BULK_EDIT_LICENSE,
				payload: tab,
			});
		} catch (e) {}
	};
}

export function fetchMapLicensesSummary(appId) {
	return async function (disptach) {
		disptach({
			type: AppLicensesConstants.FETCH_MAP_LICENSES_SUMMARY,
			payload: {
				id: appId,
			},
		});
		try {
			const response = await getMapLicensesSummary(appId);
			if (response?.error) {
				disptach({
					type: AppLicensesConstants.FETCHED_MAP_LICENSES_SUMMARY,
					payload: {
						id: appId,
						error: response?.error,
					},
				});
				TriggerIssue(
					"Error occured while fetching licenses summary",
					response?.error
				);
			} else {
				disptach({
					type: AppLicensesConstants.FETCHED_MAP_LICENSES_SUMMARY,
					payload: {
						id: appId,
						data: response?.data,
						error: {},
					},
				});
			}
		} catch (error) {
			disptach({
				type: AppLicensesConstants.FETCHED_MAP_LICENSES_SUMMARY,
				payload: {
					id: appId,
					error: error,
				},
			});
			TriggerIssue(
				"Error occured while fetching licenses summary",
				error
			);
		}
	};
}
export function fetchLicensesMappingStatus(appId) {
	return async function (disptach) {
		disptach({
			type: AppLicensesConstants.FETCH_LICENSES_MAPPING_STATUS,
			payload: {
				id: appId,
			},
		});
		try {
			const response = await getLicensesMappedStatus(appId);
			if (response?.error) {
				disptach({
					type: AppLicensesConstants.FETCHED_LICENSES_MAPPING_STATUS,
					payload: {
						id: appId,
						error: response?.error,
					},
				});
				TriggerIssue(
					"Error occured while fetching licenses summary",
					response?.error
				);
			} else {
				disptach({
					type: AppLicensesConstants.FETCHED_LICENSES_MAPPING_STATUS,
					payload: {
						id: appId,
						data: response?.data,
						error: {},
					},
				});
			}
		} catch (error) {
			disptach({
				type: AppLicensesConstants.FETCHED_LICENSES_MAPPING_STATUS,
				payload: {
					id: appId,
					error: error,
				},
			});
			TriggerIssue(
				"Error occured while fetching licenses summary",
				error
			);
		}
	};
}
export function fetchLicensesMappingUserStatus(appId) {
	return async function (disptach) {
		disptach({
			type: AppLicensesConstants.FETCH_LICENSES_MAPPING_USER_STATUS,
			payload: {
				id: appId,
			},
		});
		try {
			const response = await getLicensesMappedUserStatus(appId);
			if (response?.error) {
				disptach({
					type: AppLicensesConstants.FETCHED_LICENSES_MAPPING_USER_STATUS,
					payload: {
						id: appId,
						error: response?.error,
					},
				});
				TriggerIssue(
					"Error occured while fetching licenses summary",
					response?.error
				);
			} else {
				disptach({
					type: AppLicensesConstants.FETCHED_LICENSES_MAPPING_USER_STATUS,
					payload: {
						id: appId,
						data: response?.data,
						error: {},
					},
				});
			}
		} catch (error) {
			disptach({
				type: AppLicensesConstants.FETCHED_LICENSES_MAPPING_USER_STATUS,
				payload: {
					id: appId,
					error: error,
				},
			});
			TriggerIssue(
				"Error occured while fetching licenses summary",
				error
			);
		}
	};
}

export function fetchLicensesAndContractsGraph(
	appId,
	start_month,
	start_year,
	end_month,
	end_year
) {
	return async function (dispatch) {
		dispatch({
			type: AppLicensesConstants.FETCH_LICENSES_AND_CONTRACTS_GRAPH,
			payload: {
				id: appId,
			},
		});
		try {
			const response = await getLicensesAndContractsGraph(
				appId,
				start_month,
				start_year,
				end_month,
				end_year
			);
			if (response?.error) {
				dispatch({
					type: AppLicensesConstants.FETCHED_LICENSES_AND_CONTRACTS_GRAPH,
					payload: {
						id: appId,
						error: response?.error,
					},
				});
				TriggerIssue(
					"Error occurred while fetching app spends accrued spend graph datapoints!",
					response.error
				);
			} else {
				dispatch({
					type: AppLicensesConstants.FETCHED_LICENSES_AND_CONTRACTS_GRAPH,
					payload: {
						id: appId,
						data: response?.data,

						error: {},
					},
				});
			}
		} catch (error) {
			dispatch({
				type: AppLicensesConstants.FETCHED_LICENSES_AND_CONTRACTS_GRAPH,
				payload: {
					id: appId,
					error: error,
				},
			});
			TriggerIssue(
				"Error occurred while fetching app spends accrued spend graph datapoints!",
				error
			);
		}
	};
}

export function fetchLicensesSummaryMetrics(appId) {
	return async function (disptach) {
		disptach({
			type: AppLicensesConstants.FETCH_LICENSES_MAPPING_USER_STATUS,
			payload: {
				id: appId,
			},
		});
		try {
			const response = await getLicensesSummaryMetrics(appId);
			if (response?.error) {
				disptach({
					type: AppLicensesConstants.FETCH_LICENSES_SUMMARY_METRICS,
					payload: {
						id: appId,
						error: response?.error,
					},
				});
				TriggerIssue(
					"Error occured while fetching licenses summary",
					response?.error
				);
			} else {
				disptach({
					type: AppLicensesConstants.FETCHED_LICENSES_SUMMARY_METRICS,
					payload: {
						id: appId,
						data: response?.data,
						error: {},
					},
				});
			}
		} catch (error) {
			disptach({
				type: AppLicensesConstants.FETCHED_LICENSES_SUMMARY_METRICS,
				payload: {
					id: appId,
					error: error,
				},
			});
			TriggerIssue(
				"Error occured while fetching licenses summary",
				error
			);
		}
	};
}

export function fetchLicensesTypeSummary(appId, licenseFilter) {
	return async function (disptach) {
		disptach({
			type: AppLicensesConstants.FETCH_LICENSES_TYPE_SUMMARY,
			payload: {
				id: appId,
			},
		});
		try {
			const isPaid = licenseFilter === "paid";
			const response = await getLicensesTypeSummary(appId, isPaid);
			if (response?.error) {
				disptach({
					type: AppLicensesConstants.FETCHED_LICENSES_TYPE_SUMMARY,
					payload: {
						id: appId,
						error: response?.error,
					},
				});
				TriggerIssue(
					"Error occured while fetching licenses summary",
					response?.error
				);
			} else {
				disptach({
					type: AppLicensesConstants.FETCHED_LICENSES_TYPE_SUMMARY,
					payload: {
						id: appId,
						data: response?.data,
						error: {},
					},
				});
			}
		} catch (error) {
			disptach({
				type: AppLicensesConstants.FETCHED_LICENSES_TYPE_SUMMARY,
				payload: {
					id: appId,
					error: error,
				},
			});
			TriggerIssue(
				"Error occured while fetching licenses summary",
				error
			);
		}
	};
}

export function fetchLicensesByDepartment(appId, filterObj) {
	const { licenseIds = [], showAll = true, filterTypeData } = filterObj;
	return async function (disptach) {
		disptach({
			type: AppLicensesConstants.FETCH_LICENSES_BY_DEPARTMENT,
			payload: {
				id: appId,
			},
		});
		try {
			const reqBody = {
				license_ids: licenseIds,
				show_all: showAll,
				department_ids: filterTypeData?.department || [],
			};
			const response = await postLicensesByDepartment(appId, reqBody);
			if (response?.error) {
				disptach({
					type: AppLicensesConstants.FETCHED_LICENSES_BY_DEPARTMENT,
					payload: {
						id: appId,
						error: response?.error,
					},
				});
				TriggerIssue(
					"Error occured while fetching licenses summary",
					response?.error
				);
			} else {
				disptach({
					type: AppLicensesConstants.FETCHED_LICENSES_BY_DEPARTMENT,
					payload: {
						id: appId,
						data: response?.data,
						error: {},
					},
				});
			}
		} catch (error) {
			disptach({
				type: AppLicensesConstants.FETCHED_LICENSES_BY_DEPARTMENT,
				payload: {
					id: appId,
					error: error,
				},
			});
			TriggerIssue(
				"Error occured while fetching licenses summary",
				error
			);
		}
	};
}
