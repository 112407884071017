import React from "react";
import colors from "colors.json";
import { kFormatter } from "constants/currency";
import { capitalizeFirstLetter } from "utils/common";
import { UTCDateFormatter } from "utils/DateUtility";
import { Dots, DottedProgress } from "common/DottedProgress/DottedProgress";
import { CategoryFormatter } from "modules/applications/components/table/AppCategoryFormatter";
import LongTextTooltip from "modules/shared/components/LongTextTooltip/LongTextTooltip";

export const getSingleAppOverviewLeftPaneTopFieldValues = (field, app) => {
	switch (field) {
		case "STATUS":
			return getAppOverviewLeftPaneStatusValue(app.app_status);
		case "CATEGORY":
			return <CategoryFormatter data={app} />;
		case "ACTIVE CONTRACTS":
			return app.app_active_contracts_count;
		case "TYPE":
			return capitalizeFirstLetter(app.app_type);
		case "APP BUDGET":
			return kFormatter(app.app_budget);
		case "USAGE":
			return <DottedProgress value={app.app_usage} />;
		case "APP LINK":
			return (
				app.app_link && (
					<LongTextTooltip
						text={app.app_link}
						maxWidth="150px"
						onClick={() => window.open(app.app_link)}
						style={{
							color: "#5ABAFF",
							fontSize: "14px",
							cursor: "pointer",
						}}
					/>
				)
			);
		default:
			return null;
	}
};

export const getAppOverviewLeftPaneStatusValue = (status) => {
	return (
		<div className="app_overview_left_pane_status_value">
			<Dots color={status === "active" ? "#40E395" : "#717171"} />
			<div className="app_overview_left_pane_status_value_text">
				{capitalizeFirstLetter(status)}
			</div>
		</div>
	);
};

export const getAppOverviewTopCardsValueFormatter = (field, value) => {
	switch (field) {
		case "app_active_users_via_direct_integration":
		case "active_app_user_count_via_all_sources":
		case "app_assigned_license_count":
			return value;
		case "app_active_contracts":
			return getAppNextEndingContract(value)
				? UTCDateFormatter(getAppNextEndingContract(value).end_date)
				: "-";
		case "app_spend":
		case "app_cost":
			return kFormatter(value);
		default:
			return "-";
	}
};

export const getAppNextEndingContract = (contracts) => {
	if (!Array.isArray(contracts)) {
		return null;
	}
	const _contracts = [];
	for (const contract of contracts) {
		if (contract.end_date) {
			_contracts.push(contract);
		}
	}
	if (_contracts.length > 0) {
		let nextEndingContract = _contracts[0];
		for (const _contract of _contracts) {
			if (
				new Date(_contract.end_date) <
				new Date(nextEndingContract.end_date)
			) {
				nextEndingContract = _contract;
			}
		}
		return nextEndingContract;
	} else {
		return null;
	}
};

export const getAppChargebackPieTotal = (depts) => {
	if (!Array.isArray(depts)) {
		return 0;
	}
	let total = 0;
	for (const dept of depts) {
		total += dept.spend;
	}
	return total;
};

export const getAppChargebackPieSeries = (depts) => {
	if (!Array.isArray(depts)) {
		return [];
	}
	const total = getAppChargebackPieTotal(depts);
	const pieSeries = [];
	let index = 0;
	for (const dept of depts) {
		pieSeries.push({
			name: dept.dept_name,
			y: isNaN((dept.spend / total) * 100)
				? 0
				: Number(((dept.spend / total) * 100).toFixed(2)),
			spend: dept.spend,
			spendText: kFormatter(dept.spend),
			deptId: dept.dept_id,
			hasChildren: dept.has_children,
			color: colors.Colors[index % colors.Colors.length],
		});
		index++;
	}
	return pieSeries;
};

export const getAppAssociationUserPillsData = (association) => {
	let userPillsData = [];
	for (const owners of Object.values(association)) {
		if (Array.isArray(owners)) {
			userPillsData = [...userPillsData, ...owners];
		}
	}
	return userPillsData.filter((owner, index, self) => {
		const foundIndex = self.findIndex((o) => o.owner_id === owner.owner_id);
		return foundIndex === index;
	});
};
