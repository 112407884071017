import React, { Fragment } from "react";
import copyIcon from "assets/icons/Icon_Copy.svg";
import {
	BODY_VARIANT,
	Divider,
	GREY_VARIANT,
	Typography,
} from "@zluri/ui-components";
import { isArray } from "underscore";

export function EmailPopover({ data, component, ...props }) {
	const valueList = isArray(data[component?.valueKey])
		? data[component?.valueKey]
		: [];

	return (
		<div
			className="value_list_popover d-flex flex-column padding_8"
			style={{ ...component?.style }}
		>
			{valueList.map((value, index) => (
				<>
					<EmailComponent
						key={index}
						containerClassName="value_list_popover_element w-100 padding_8 border-radius-4 d-flex justify-content-between gap-10"
						copyToClipBoard
						textVariant={BODY_VARIANT.BODY_2_REGULAR}
						textColor={GREY_VARIANT.SECONDARY_GREY_2}
						value={value}
					/>
					{index < valueList?.length - 1 && (
						<Divider height="1px" width="100%" />
					)}
				</>
			))}
		</div>
	);
}

export const EmailComponent = ({
	containerClassName = "",
	textVariant = BODY_VARIANT.BODY_2_REGULAR,
	textColor = GREY_VARIANT.SECONDARY_GREY_2,
	copyToClipBoard,
	value = "",
}) => {
	return (
		<div className={containerClassName}>
			<Typography
				variant={textVariant}
				color={textColor}
				className="text-truncate"
			>
				{value}
			</Typography>
			{copyToClipBoard && (
				<img
					className="value_list_popover_element_copy_button"
					alt=""
					src={copyIcon}
					width={12}
					height={12}
					onClick={() => {
						navigator.clipboard.writeText(value);
					}}
				/>
			)}
		</div>
	);
};
