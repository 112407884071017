export const userRoles = {
	ADMIN: "admin",
	OWNER: "owner",
	IT_ADMIN: "it admin",
	FINANCE_ADMIN: "finance admin",
	VIEWER: "viewer",
	IT_VIEWER: "it viewer",
	EMPLOYEE: "employee",
	PROCUREMENT_ADMIN: "procurement admin",
	INTEGRATION_ADMIN: "integration admin",
	SECURITY_ADMIN: "security admin",
	SUPER_USER: "super user",
	BUDGET_ADMIN: "budget admin",
};
