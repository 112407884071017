import React from "react";
import defaultNotification from "../../../assets/defaultNotification.svg";

import CurrencyFormatter from "../../CurrencyFormatter/CurrencyFormatter";
import { Button } from "UIComponents/Button/Button";
import notificationDownloadDoc from "assets/notifications/notificationDownloadDoc.svg";
import DownloadInSamePage from "modules/shared/components/DownloadInSamePage/DownloadInSamePage";
import { getS3LinkForDownload } from "services/api/notifications";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import arrowCornerRight from "assets/arrowCornerRight.svg";
import { unescape, urlifyImage } from "utils/common";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import loadable from "@loadable/component";
import ContractDraftEditButton from "modules/licenses/components/ContractUploads/ContractDraftEditButton";
import LicenseAssignmentNotification from "modules/licenses/components/LicenseMapper/LicenseAssignmentNotification";
const Jsx = loadable.lib(() =>
	import(/* webpackPrefetch: true */ "react-jsx-parser")
);

function DefaultNotificationCard({
	notification,
	jsxReqd,
	icon,
	children = null,
}) {
	const dispatch = useDispatch();
	const getCSVDownloadUrl = () => {
		return Array.isArray(notification?.data) &&
			notification?.data?.length > 0
			? notification?.data[0]?.entity_type === "reports"
				? notification?.data[0]?.url
				: null
			: null;
	};

	const getRedirectComponent = () => {
		return Array.isArray(notification?.data) &&
			notification?.data?.length > 0
			? notification?.data[0]?._id &&
					(notification?.data[0]?.entity_type ===
						"access_certification_ongoing" ||
						notification?.data[0]?.entity_type ===
							"access_certification_upcoming")
			: null;
	};

	const getContractPDFDraftRedirection = () => {
		return Array.isArray(notification?.data) &&
			notification?.data?.length > 0
			? notification?.data[0]?.entity_type === "contract_pdf_draft"
				? notification?.data[0]?.url
				: null
			: null;
	};

	const getBulkLicenseMappingRedirection = () => {
		return Array.isArray(notification?.data) &&
			notification?.data?.length > 0
			? notification?.data[0]?.entity_type === "bulk_license_mapping"
				? notification?.data[0]?.url
				: null
			: null;
	};

	function handleSingleItemClick(e, entityType, id) {
		e.stopPropagation();
		if (!entityType) {
			return;
		}

		const hashtagMap = {
			access_certification_ongoing: `/accessreview/${id}#ongoingdetails`,
			access_certification_upcoming: `/accessreview/${id}#upcomingdetails`,
		};

		dispatch(push(hashtagMap[entityType]));

		return;
	}
	return (
		<div className="ml-auto mr-auto row">
			<div className="col-md-2 p-0 mt-1" style={{ flexBasis: 0 }}>
				<img
					alt="notification-icon"
					className="notification_icon"
					src={notification?.icon || icon || defaultNotification}
				/>
			</div>
			<div className="col pr-0 pl-3">
				{notification?.title && (
					<div className="notification_title">
						{jsxReqd ? (
							<Jsx>
								{({ default: JsxParser }) => (
									<JsxParser
										components={{ CurrencyFormatter }}
										jsx={notification?.title}
									/>
								)}
							</Jsx>
						) : (
							<>{notification?.title}</>
						)}
					</div>
				)}
				{notification?.description && (
					<div className="notification_description">
						{jsxReqd ? (
							<Jsx>
								{({ default: JsxParser }) => (
									<JsxParser
										components={{ CurrencyFormatter }}
										jsx={notification?.description}
									/>
								)}
							</Jsx>
						) : (
							<>{notification?.description}</>
						)}
					</div>
				)}
				{children}
				{getCSVDownloadUrl() && (
					<DownloadInSamePage
						api={getS3LinkForDownload}
						tokenLink={getCSVDownloadUrl()}
					>
						<div className="d-flex align-items-center">
							<div className="font-13 bold-500 mr-1 primary-color">
								Download CSV
							</div>
							<img
								src={notificationDownloadDoc}
								width={14}
								height={14}
							/>
						</div>
					</DownloadInSamePage>
				)}
				{getRedirectComponent() && (
					<>
						<div className="d-flex flex-wrap">
							<div
								onClick={(e) =>
									handleSingleItemClick(
										e,
										notification?.data[0]?.entity_type,
										notification?.data[0]?._id
									)
								}
								className="notification_card_tag"
							>
								<div className="d-flex flex-row pt-1 pb-1">
									{notification?.data[0]?.image ? (
										<div
											className="rounded-circle bg_repeat_contain mt-auto mb-auto notification_card_tag_icon"
											style={{
												backgroundImage: `url(${urlifyImage(
													unescape(
														notification?.data[0]
															?.image
													)
												)})`,
											}}
										></div>
									) : (
										<img
											className="notification_card_tag_icon"
											src={defaultNotification}
											alt=""
										/>
									)}
									<OverlayTrigger
										placement="top"
										overlay={
											<Tooltip>
												{notification?.data[0]?.name}
											</Tooltip>
										}
									>
										<div className="mt-auto mb-auto ml-1 text-capitalize notification_card_tag_name">
											{notification?.data[0]?.name}
										</div>
									</OverlayTrigger>
									<img
										src={arrowCornerRight}
										alt="redirect"
										className="more_data mt-auto ml-1 mr-auto mb-auto"
									/>
								</div>
							</div>
						</div>
					</>
				)}
				{getContractPDFDraftRedirection() && (
					<>
						<ContractDraftEditButton
							bulkUploadId={getContractPDFDraftRedirection()}
							fromNotification
						/>
					</>
				)}
				{getBulkLicenseMappingRedirection() && (
					<>
						<LicenseAssignmentNotification
							contractId={getBulkLicenseMappingRedirection()}
						/>
					</>
				)}
				{notification?.retry && (
					<Button
						type="primary"
						className="d-flex align-items-center mt-1"
						style={{ height: "28px" }}
						onClick={() => notification.retry()}
					>
						Retry
					</Button>
				)}
			</div>
		</div>
	);
}

export default DefaultNotificationCard;
