// import { compose } from "redux";
import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery, axiosBaseQueryV2 } from "./client";

export const vendorsApi = createApi({
	reducerPath: "vendorsApi",
	baseQuery: axiosBaseQuery(),
	endpoints: (builder) => ({
		getVendorsList: builder.query({
			query: (bodyParams) => ({ url: "vendors", params: bodyParams }),
		}),
	}),
});

export const { useGetVendorsListQuery } = vendorsApi;

// TODO: This is just prototype for now
export const costCentersApi = createApi({
	reducerPath: "costCentersApi",
	baseQuery: axiosBaseQueryV2(),
	endpoints: (builder) => ({
		getCostCentersList: builder.query({
			query: (bodyParams = {}) => {
				const page = bodyParams.page ?? 0;
				const row = bodyParams.row ?? 50;

				const url = `cost-centers?page=${page}&row=${row}`;
				return {
					url,
					method: "POST",
					data: {
						columns: [],
						filter_by: [
							{
								field_name: "Cost Center Name",
								field_type: "string",
								field_values: [""],
								field_id: "name",
								filter_type: "search_in_string",
								negative: false,
								is_field_static: false,
								editable: false,
								draggable: false,
								validations: ["isAlpha"],
							},
						],
						quick_filter: [],
						sort_by: [],
					},
				};
			},
		}),
	}),
});

export const applicationsListApi = createApi({
	reducerPath: "applicationsListApi",
	baseQuery: axiosBaseQueryV2(),
	endpoints: (builder) => ({
		getApplicationsList: builder.query({
			query: (bodyParams = {}) => {
				const page = bodyParams.page ?? 0;
				const row = bodyParams.row ?? 50;

				const url = `applications?page=${page}&row=${row}`;
				return {
					url,
					method: "POST",
					data: {
						columns: [],
						filter_by: [
							{
								field_name: "Archive",
								field_type: "single_select",
								field_values: false,
								field_id: "app_archive",
								filter_type: "boolean",
								negative: false,
								is_field_static: true,
								editable: false,
								draggable: false,
								validations: ["isBoolean"],
								is_sortable: true,
								sort_default_value: -1,
								is_filterable: true,
								is_custom: false,
							},
						],
						quick_filter: [],
						sort_by: [],
					},
				};
			},
		}),
	}),
});

export const searchApplicationsListApi = createApi({
	reducerPath: "searchApplicationsListApi",
	baseQuery: axiosBaseQuery(),
	endpoints: (builder) => ({
		searchApplicationsList: builder.query({
			query: (queryParams) => {
				const searchQuery = queryParams.searchQuery ?? "";
				return {
					url: `search-apps?q=${searchQuery}&filter_org_apps=true`,
				};
			},
		}),
	}),
});

export const searchOwnersListApi = createApi({
	reducerPath: "searchOwnersListApi",
	baseQuery: axiosBaseQuery(),
	endpoints: (builder) => ({
		searchOwnersList: builder.query({
			query: (params) => {
				return {
					url: `users/search`,
					params,
				};
			},
		}),
	}),
});
export const searchCategoriesListApi = createApi({
	reducerPath: "searchCategoriesListApi",
	baseQuery: axiosBaseQuery(),
	endpoints: (builder) => ({
		searchCategoriesList: builder.query({
			query: (params) => {
				return {
					url: `search-app-categories`,
					params,
				};
			},
		}),
	}),
});

export const addApplicationsApi = createApi({
	reducerPath: "addApplicationsApi",
	baseQuery: axiosBaseQuery(),
	endpoints: (builder) => ({
		addApplications: builder.mutation({
			query: ({ data }) => {
				const url = `applications/custom`;
				return {
					url,
					method: "POST",
					data,
				};
			},
		}),
	}),
});

export const addExistingApplicationsApi = createApi({
	reducerPath: "addExistingApplicationsApi",
	baseQuery: axiosBaseQuery(),
	endpoints: (builder) => ({
		addExistingApplications: builder.mutation({
			query: ({ data }) => {
				const url = `applications`;
				return {
					url,
					method: "POST",
					data,
				};
			},
		}),
	}),
});

export const settingsFieldApi = createApi({
	reducerPath: "settingsFieldApi",
	baseQuery: axiosBaseQuery(),
	endpoints: (builder) => ({
		getSettingsFieldsApi: builder.query({
			query: (params) => {
				const url = `settings/fields`;
				return {
					url,
					params,
				};
			},
		}),
	}),
});

export const searchUsersListApi = createApi({
	reducerPath: "searchUsersListApi",
	baseQuery: axiosBaseQuery(),
	endpoints: (builder) => ({
		searchUsersList: builder.query({
			query: (queryParams) => {
				const searchQuery = queryParams.searchQuery ?? "";
				const compact = queryParams.compact ?? true;
				const userActive = queryParams.userActive ?? true;

				const url = `users/search?q=${searchQuery}&search_entity=users&compact=${
					compact
				}&user_active=${userActive}`;

				return {
					url,
				};
			},
		}),
	}),
});

export const appOverviewSummaryMetricsApi = createApi({
	reducerPath: "appOverviewSummaryMetricsApi",
	baseQuery: axiosBaseQueryV2(),
	endpoints: (builder) => ({
		appOverViewSummaryMetrics: builder.query({
			query: (queryParams) => {
				const appId = queryParams.appId;
				const url = `applications/${appId}/summary-metrics`;

				return {
					url,
				};
			},
		}),
	}),
});

export const { useAppOverViewSummaryMetricsQuery } =
	appOverviewSummaryMetricsApi;
export const { useGetApplicationsListQuery } = applicationsListApi;
export const {
	useSearchApplicationsListQuery,
	useLazySearchApplicationsListQuery,
} = searchApplicationsListApi;
export const { useSearchUsersListQuery, useLazySearchUsersListQuery } =
	searchUsersListApi;
export const { useGetCostCentersListQuery } = costCentersApi;
export const { useSearchOwnersListQuery } = searchOwnersListApi;
export const { useSearchCategoriesListQuery } = searchCategoriesListApi;
export const { useAddApplicationsMutation } = addApplicationsApi;
export const { useAddExistingApplicationsMutation } =
	addExistingApplicationsApi;
export const { useGetSettingsFieldsApiQuery } = settingsFieldApi;
