import { getOrgCurrencySymbol } from "constants/currency";
import React from "react";
// let count=0
const CurrencyGet=({currencyCode,classname}) => {
return (<div className={classname} style={{color:'green'}}>{getOrgCurrencySymbol(currencyCode)}
{/* {console.log("---Rerendering----",count++)} */}
</div>);
}

const CurrencySymbol = React.memo(CurrencyGet);
  
export default CurrencySymbol 

