import { getOrgCurrency } from "constants/currency";
import React from "react";
import classNames from "classnames";
import { Currency, CurrencySymbol } from "@zluri/ui-components";
import { getNested } from "utils/getNested";
export function Cost({
	data = { [component.valueKey]: 0 },
	component,
	classname = "v2table__cell-cost",
	stx = {},
	hasHover,
	hasClick,
}) {
	let orgCurrency = getOrgCurrency();
	const value =
		(Array.isArray(component.valueKey)
			? component.valueKey?.reduce((_, x) => _?.[x], data)
			: getNested(data, component.valueKey)) || 0;
	return (
		<div
			className={`${classname} d-flex align-items-center justify-content-between`}
			style={stx}
		>
			<CurrencySymbol
				color="secondary_grey_1"
				currencyCode={orgCurrency}
				fontVariant="body_1_regular"
				locale="en-US"
			/>
			<Currency
				color="secondary_grey_1"
				fontVariant="body_1_regular"
				locale="en-US"
				value={value}
				showDecimals={component.showDecimals}
				className={classNames(
					{ cell__dashed__underline: hasHover },
					{ cell__click__hover: hasClick }
				)}
			/>
		</div>
	);
}
