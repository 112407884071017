import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { updateBulkUploadDraft } from "modules/BulkUpload/service/BulkUpload.api";
import { ApiResponseNotification } from "modules/shared/components/ApiResponseNotification/ApiResponseNotification";
import { apiResponseTypes } from "modules/shared/components/ApiResponseNotification/ApiResponseNotificationConstants";

export default function ContractSaveAsDraftButton({
	draft,
	disabled,
	drafting,
	setDrafting,
	bulkUploadId,
	processBulkUploadId,
}) {
	const history = useHistory();

	const handleDrafting = () => {
		setDrafting(true);
		updateBulkUploadDraft({
			draft,
			bulk_upload_id: bulkUploadId,
			process_bulk_upload_id: processBulkUploadId,
		})
			.then((res) => {
				setDrafting(false);
				ApiResponseNotification({
					responseType: apiResponseTypes.SUCCESS,
					title: "Contract draft saved successfully!",
				});
				history.push("/licenses#uploads");
			})
			.catch((err) => {
				setDrafting(false);
				ApiResponseNotification({
					responseType: apiResponseTypes.ERROR,
					title: "Server Error! There was an error while saving the contract draft.",
					errorObj: err,
				});
			});
	};

	return (
		<>
			<div
				className="contract_save_as_draft_button"
				style={disabled ? { opacity: "0.6" } : {}}
				onClick={() => !disabled && handleDrafting()}
			>
				{drafting ? (
					<Spinner
						animation="border"
						role="status"
						variant="dark"
						style={{
							borderWidth: 2,
							borderColor: "#2262e2 transparent #2262e2 #2262e2",
							height: "16px",
							width: "16px",
						}}
					/>
				) : (
					"Save as Draft"
				)}
			</div>
		</>
	);
}
