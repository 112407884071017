import React from "react";
import {
	ContractIcons,
	appStatusOptions,
	dataComponent,
	editType,
	manualRiskLevelOptions,
	statusList,
	statusTypes,
} from "../constants/AppAbout.constants";
import { isUndefined, isNull } from "underscore";
import moment from "moment";

import { capitalizeFirstLetter, unescape } from "utils/common";
import { AppAboutText } from "../components/AppAbout/AppAboutText/AppAboutText";
import { AppAboutMultiText } from "../components/AppAbout/AppAboutMultiText/AppAboutMultiText";
import { AppAboutCategories } from "../components/AppAbout/AppAboutCategories/AppAboutCategories";
import { AppAboutTags } from "../components/AppAbout/AppAboutTags/AppAboutTags";
import { AppOwnerEditPopover } from "../components/AppAbout/AppAboutEditPopovers/AppOwnerEditPopover";
import { AppComplianceClickPopover } from "../components/AppAbout/AppAboutClickPopovers/AppComplianceClickPopover";
import {
	AppAboutContractsClickPopover,
	ContractOwnersPopover,
} from "../components/AppAbout/AppAboutClickPopovers/AppAboutContractsClickPopover";
import { AppAboutCategoriesClickPopover } from "../components/AppAbout/AppAboutClickPopovers/AppAboutCategoriesClickPopover";
import { CustomBoolEditPopover } from "../components/AppAbout/AppAboutEditPopovers/AppAboutCustomEditPopovers/CustomBoolEditPopover";
import { CustomSelectEditPopover } from "../components/AppAbout/AppAboutEditPopovers/AppAboutCustomEditPopovers/CustomSelectEditPopover";
import { CustomReferenceEditPopover } from "../components/AppAbout/AppAboutEditPopovers/AppAboutCustomEditPopovers/CustomReferenceEditPopover";
import { AppCategoryEditPopover } from "../components/AppAbout/AppAboutEditPopovers/AppCategoryEditPopover";
import { AppAboutSelectDropdown } from "../components/AppAbout/AppAboutEditPopovers/AppAboutSelectDropdown";
import { AppAboutSelectSearchDropdown } from "../components/AppAbout/AppAboutEditPopovers/AppAboutSelectSearchDropdown";
import { applicationState } from "components/Applications/EditApplicationType";
import { getThreatType } from "./applicationutils";
import { riskTypeChecker } from "../constants/ApplicationConstants";

import appStatusActiveIcon from "containers/v2table/BulkEdit/assets/Icon_Active.svg";
import appStatusInactiveIcon from "assets/applications/inactivecheck.svg";
import customIcon from "assets/applications/about/Icon_Custom.svg";
import { AppAboutTagsClickPopover } from "../components/AppAbout/AppAboutClickPopovers/AppAboutTagsClickPopover";

export const statusCheck = (statusType) => {
	switch (statusType) {
		case statusTypes.ACTIVE:
			return statusList.ACTIVE;
		case statusTypes.INACTIVE:
			return statusList.INACTIVE;
		default:
			break;
	}
};

export const componentState = {
	VIEW: "VIEW",
	EMPTY: "EMPTY",
	EDIT: "EDIT",
	CREATE: "CREATE",
	LOADING: "LOADING",
};

export const isNullOrUndefined = (value) => isNull(value) || isUndefined(value);

export const generateState = (value) => {
	if (Array.isArray(value)) {
		return value?.length === 0 ? componentState.EMPTY : componentState.VIEW;
	}

	if (isUndefined(value) || isNull(value)) return componentState.EMPTY;

	return componentState.VIEW;
};

const generateDataForComponent = (id, data) => {
	switch (id) {
		case "app_status": {
			const text = data[id];
			if (isUndefined(text) || isNull(text)) return null;

			const imageSrc =
				text === "active"
					? appStatusActiveIcon
					: text === "inactive"
						? appStatusInactiveIcon
						: null;
			const imageStyle = { height: 9, width: 9 };
			return { text: capitalizeFirstLetter(text), imageSrc, imageStyle };
		}
		case "app_type": {
			const text = data[id];
			if (isUndefined(text) || isNull(text)) return null;

			return { text: capitalizeFirstLetter(text) };
		}
		case "app_sub_categories": {
			const subCategories = data[id];
			if (
				(isUndefined(subCategories) || isNull(subCategories)) &&
				Array.isArray(subCategories) &&
				subCategories.length < 1
			)
				return null;

			return {
				subCategories,
				is_editable: data?.app_is_custom,
			};
		}
		case "app_tags": {
			const tags = data[id];
			if (
				(isUndefined(tags) || isNull(tags)) &&
				Array.isArray(tags) &&
				tags.length < -1
			)
				return null;

			return { tags };
		}
		case "app_link": {
			const text = data[id];
			if (isUndefined(text) || isNull(text)) return null;

			return { text, isLink: true };
		}
		case "app_owner_name": {
			const text = data[id];
			if (isUndefined(text) || isNull(text)) return null;

			const imageSrc = unescape(data["app_owner_profile"]);
			return { text, imageSrc, showDefaultAvatar: true };
		}
		case "app_technical_owner_name": {
			const text = data[id];
			if (isUndefined(text) || isNull(text)) return null;

			const imageSrc = unescape(data["app_technical_owner_profile"]);
			return { text, imageSrc, showDefaultAvatar: true };
		}
		case "app_financial_owner_name": {
			const text = data[id];
			if (isUndefined(text) || isNull(text)) return null;

			const imageSrc = unescape(data["app_financial_owner_profile"]);
			return { text, imageSrc, showDefaultAvatar: true };
		}
		case "app_alternate_owner_name": {
			const text = data[id];
			if (isUndefined(text) || isNull(text)) return null;

			const imageSrc = unescape(data["app_alternate_owner_profile"]);
			return { text, imageSrc, showDefaultAvatar: true };
		}
		case "app_active_contracts":
		case "contract_IT_owners":
		case "contract_financial_owners":
		case "contract_negotiation_owners":
		case "contract_owners":
			const texts = data[id];
			if (
				isUndefined(texts) ||
				isNull(texts) ||
				(Array.isArray(texts) && texts.length === 0)
			)
				return null;
			const formattedText = texts.map((contract) => contract.name);
			const prefixIcon =
				ContractIcons[texts[0]?.contract?.type ?? "contract"];

			return { texts: formattedText, prefixIcon };
		case "manual_risk_level": {
			const riskLevel = data[id];
			if (isUndefined(riskLevel) || isNull(riskLevel)) return null;

			const threatType = getThreatType(
				riskLevel,
				riskTypeChecker.APP_ABOUT
			);
			if (!threatType) return {};

			const text = threatType.title;
			const imageSrc = threatType.icon;
			return { text, imageSrc };
		}
		case "threat": {
			const threat = data[id];
			if (isUndefined(threat) || isNull(threat)) return null;

			const threatType = getThreatType(threat, riskTypeChecker.APP_ABOUT);
			if (!threatType) return {};

			const text = threatType.title;
			const imageSrc = threatType.icon;
			return { text, imageSrc };
		}
		case "compliance": {
			const certificates = data[id];
			if (
				(isUndefined(certificates) || isNull(certificates)) &&
				Array.isArray(certificates) &&
				certificates.length < -1
			)
				return null;

			return { tags: certificates };
		}
		default:
			return {};
	}
};

const generateDataComponent = (type) => {
	switch (type) {
		case dataComponent.TEXT:
			return AppAboutText;
		case dataComponent.MULTITEXT:
			return AppAboutMultiText;
		case dataComponent.CATEGORY:
			return AppAboutCategories;
		case dataComponent.TAG:
			return AppAboutTags;
		default:
			return null;
	}
};

export const getCfValueData = (fieldId, customFieldData) => {
	const index = customFieldData?.findIndex(
		(cfData) => cfData.field_id === fieldId
	);
	if (index > -1) {
		return customFieldData[index];
	} else {
		return null;
	}
};

export const getCfFieldData = (fieldId, appCustomFields) => {
	const index = appCustomFields?.findIndex((cfData) => cfData.id === fieldId);
	if (index > -1) {
		return appCustomFields[index];
	} else {
		return null;
	}
};

export const formatProperty = (objProp, data) => {
	let customFields = null;

	const customFieldsData = data["custom_fields"];
	const appCustomFields = data["appCustomFields"];

	if (Array.isArray(appCustomFields) && appCustomFields.length > 0) {
		customFields = {
			header_id: "custom_fields",
			header_text: "Custom Fields",
			sub_detail: appCustomFields.map((field) => {
				const fieldData = getCfValueData(field?.id, customFieldsData);
				const fieldValue = data?.[field?.id];
				return {
					field_id: field?.id,
					field_icon: customIcon,
					field_sub_detail_text: field.name,
					text:
						field.type === "reference"
							? fieldData?.custom_field_name
							: field.type === "date"
								? fieldValue
									? moment(fieldValue).format("DD MMM YYYY")
									: null
								: fieldValue,
					field_empty_text: `Add ${field.name}`,
					is_editable: true,
					edit_type:
						field.type === "text"
							? editType.INPUT
							: field.type === "date"
								? editType.CALENDAR
								: editType.POPOVER,
					field_reference: field.reference,
					hide_edit_popover: field.type === "text",
					Component: AppAboutText,
					is_custom_field: true,
					EditPopoverContent: generateEditPopoverForCustomFields({
						field_type: field.type,
					}),
					popover_props: {
						placement:
							field.type === "reference" ? "left" : "bottomRight",
					},
					...fieldData,
				};
			}),
		};
	}

	const formattedData = objProp.map((prop) => {
		let formattedSubDetail;

		if (prop.hasOwnProperty("sub_detail")) {
			formattedSubDetail = prop.sub_detail.map((det) => ({
				...det,
				...generateDataForComponent(det.field_id, data),
				Component: generateDataComponent(det.component_type),
			}));
		}

		return {
			...prop,
			sub_detail: formattedSubDetail,
		};
	});

	const indexOfContracts = formattedData.findIndex(
		(dt) => dt.header_id === "app_contracts"
	);

	if (customFields && customFields.sub_detail.length > 0) {
		formattedData.splice(indexOfContracts + 1, 0, customFields);
	}

	return formattedData;
};

export function renderEditPopover(props) {
	const { handleEdit } = props;
	const selectedValue = props?.data[props.field_id];

	const { field_id } = props;
	switch (field_id) {
		case "app_status": {
			return (
				<AppAboutSelectDropdown
					options={appStatusOptions}
					checkOptionIsSelected={(option) =>
						option.value === selectedValue
					}
					handleEdit={(value) => {
						if (props?.field_id && props?.data) {
							handleEdit({
								...props.data,
								[props.field_id]: value,
							});
						}
					}}
				/>
			);
		}
		case "app_type": {
			const initialOptions = Object.values(applicationState).map(
				(type) => ({
					label: capitalizeFirstLetter(type),
					value: type,
				})
			);
			return (
				<AppAboutSelectSearchDropdown
					initialOptions={initialOptions}
					checkOptionIsSelected={(option) =>
						option.value === selectedValue
					}
					handleEdit={(value) => {
						if (props?.field_id && props?.data) {
							handleEdit({
								...props.data,
								[props.field_id]: value,
							});
						}
					}}
					data={props?.data}
					field_id={props?.field_id}
				/>
			);
		}
		case "app_owner_name":
		case "app_technical_owner_name":
		case "app_financial_owner_name":
		case "app_alternate_owner_name":
			return <AppOwnerEditPopover {...props} />;
		case "manual_risk_level": {
			return (
				<AppAboutSelectDropdown
					options={manualRiskLevelOptions}
					checkOptionIsSelected={(option) =>
						option.scoreRange.includes(selectedValue)
					}
					handleEdit={handleEdit}
					imageHeight={16}
					imageWidth={16}
				/>
			);
		}
		case "app_sub_categories":
			return <AppCategoryEditPopover {...props} />;
		case "app_tags":
			return (
				<AppAboutTagsClickPopover
					handleEdit={(value) => {
						if (props?.field_id && props?.data) {
							handleEdit({
								...props.data,
								[props.field_id]: value,
							});
						}
					}}
					{...props}
				/>
			);
		default:
			return null;
	}
}

export function renderClickPopover(props) {
	const { field_id, handleEdit } = props;
	switch (field_id) {
		case "compliance":
			return <AppComplianceClickPopover {...props} />;
		case "app_active_contracts":
			return <AppAboutContractsClickPopover {...props} />;
		case "contract_IT_owners":
		case "contract_financial_owners":
		case "contract_negotiation_owners":
		case "contract_owners":
			return <ContractOwnersPopover {...props} />;
		case "app_sub_categories":
			return <AppAboutCategoriesClickPopover {...props} />;
		case "app_tags":
			return (
				<AppAboutTagsClickPopover
					handleEdit={(value) => {
						if (props?.field_id && props?.data) {
							handleEdit({
								...props.data,
								[props.field_id]: value,
							});
						}
					}}
					{...props}
				/>
			);
		default:
			return null;
	}
}

function generateEditPopoverForCustomFields(props) {
	const { field_type } = props;
	switch (field_type) {
		case "bool":
			return CustomBoolEditPopover;
		case "select":
			return CustomSelectEditPopover;
		case "reference":
			return CustomReferenceEditPopover;
		default:
			return null;
	}
}
