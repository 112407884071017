import React from "react";
import onboarding from "assets/v2tables/taskdashboard/onboarding.svg";
import offboarding from "assets/v2tables/taskdashboard/offboarding.svg";

const img_map = {
	onboarding: onboarding,
	offboarding: offboarding,
};
export default function TaskType({ data, component }) {
	return (
		<div
			style={{ textTransform: "capitalize", gap: "5px" }}
			className="d-flex"
		>
			<img src={img_map[data.type]} alt="" />
			{data.type + " " + "workflows"}{" "}
		</div>
	);
}
