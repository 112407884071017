import React, { useMemo } from "react";

import { CSS as cssDndKit } from "@dnd-kit/utilities";
import SortableItemContextWrapper from "./SortableItemContext";
import cn from "classnames";
import { useSortable } from "@dnd-kit/sortable";
import ListItem from "./ListItem";
interface ISortableItem<T> {
	header: string;
	name: string;
	columnDescription: string;
	onClick: () => void;
	id: string;
	checkedMap: Map<string, T>;
}
function SortableItem<T>({
	header,
	name,
	columnDescription,
	onClick,
	id,
	checkedMap,
}: ISortableItem<T>) {
	const {
		setNodeRef,
		transform,
		transition,
		listeners,
		attributes,
		isDragging,
		setActivatorNodeRef,
	} = useSortable({
		id,
	});

	const style = {
		opacity: isDragging ? 0.8 : undefined,
		transform: cssDndKit.Translate.toString(transform),
		transition,
		padding: 8,
	};

	const context = useMemo(
		() => ({
			attributes,
			listeners,
			ref: setActivatorNodeRef,
		}),
		[attributes, listeners, setActivatorNodeRef]
	);

	const isItemChecked = !!checkedMap.has(id);
	return (
		<SortableItemContextWrapper context={context}>
			<li
				ref={setNodeRef}
				style={style}
				className={cn(
					`flex align-items-center column-item justify-content-between`,
					isItemChecked && "column-item-checked"
				)}
				onClick={onClick}
			>
				<ListItem
					title={header}
					groupName={name}
					details={columnDescription}
					isItemChecked={isItemChecked}
				/>
			</li>
		</SortableItemContextWrapper>
	);
}
export default SortableItem;
