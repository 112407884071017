import React from "react";
import PropTypes from "prop-types";
import {
	ALERT_TAG_VARIANT,
	BLUE_VARIANT,
	BODY_VARIANT,
	GREY_VARIANT,
	Popover,
	Tooltip,
	Typography,
} from "@zluri/ui-components";
import { isNullOrUndefined } from "modules/applications/utils/appAbout.utils";

export function AppAboutMultiText({ texts, prefixIcon, ...rest }) {
	const firstText = texts?.[0];

	if (isNullOrUndefined(firstText)) return null;

	return (
		<div className="d-flex gap-4 align-items-center">
			<div className="d-flex align-items-center gap-4">
				{prefixIcon && (
					<img
						src={prefixIcon}
						width={12}
						height={12}
						alt={firstText}
						style={{ transform: "translateY(-1px)" }}
					/>
				)}
				{firstText && (
					<Tooltip
						content={
							<Typography
								color="white"
								variant={
									ALERT_TAG_VARIANT.ALERT_TAG_SMALL_MEDIUM
								}
							>
								{firstText}
							</Typography>
						}
						placement="bottom"
					>
						<Typography
							color={GREY_VARIANT.SECONDARY_GREY_1}
							variant={BODY_VARIANT.BODY_2_REGULAR}
							className="text-truncate-small"
							style={{
								maxWidth: "240px",
							}}
						>
							{firstText}
						</Typography>
					</Tooltip>
				)}
			</div>

			{texts?.length > 1 && (
				<Popover
					arrow
					content={rest?.clickPopoverContent}
					placement="bottomRight"
					trigger={["click"]}
				>
					<Typography
						color={BLUE_VARIANT.PRIMARY_BLUE_DARK_1}
						variant={BODY_VARIANT.BODY_2_MEDIUM}
						className="cursor-pointer px-1"
					>
						+{texts.length - 1}
					</Typography>
				</Popover>
			)}
		</div>
	);
}

AppAboutMultiText.propTypes = {
	texts: PropTypes.string,
	prefixIcon: PropTypes.string,
};
