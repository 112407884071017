import React from "react";
import { DragOverlay, defaultDropAnimationSideEffects } from "@dnd-kit/core";

function SortableOverlay({ children }: { children: React.ReactNode }) {
	const dropAnimationConfig = {
		sideEffects: defaultDropAnimationSideEffects({
			styles: {
				active: {
					opacity: "0.4",
				},
			},
		}),
	};

	return (
		<DragOverlay adjustScale={false} dropAnimation={dropAnimationConfig}>
			{children}
		</DragOverlay>
	);
}
export default SortableOverlay;
