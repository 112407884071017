import React, { useEffect, useState } from "react";
import close from "assets/close.svg";
import edit from "assets/icons/edit.svg";
import "../style/CustomFields.style.css";
import { Form, Spinner } from "react-bootstrap";
import { UTCDateFormatter } from "utils/DateUtility";
import { EntityCustomFieldData } from "../model/model";
import { useDispatch, useSelector } from "react-redux";
import blackTickCircle from "assets/blackTickCircle.svg";
import { getAllCustomField } from "../redux/custom-field";
import Dropdown from "UIComponents/CustomDropdown/Dropdown";
import { searchAllApps, searchUsers } from "services/api/search";
import NewDatePicker from "UIComponents/DatePicker/NewDatePicker";
import arrowdropdown from "components/Transactions/Unrecognised/arrowdropdown.svg";
import LongTextTooltip from "modules/shared/components/LongTextTooltip/LongTextTooltip";
import saveCustomField from "modules/TaskManagement/assets/taskActionMarkAsCompleted.svg";
import { ApiResponseNotification } from "modules/shared/components/ApiResponseNotification/ApiResponseNotification";
import { apiResponseTypes } from "modules/shared/components/ApiResponseNotification/ApiResponseNotificationConstants";
import moment from "moment";
import { TriggerIssue } from "utils/sentry";

export default function NewCustomFieldSectionInOverview({
	patcher,
	loading,
	cfEntity,
	cfEntityData = [],
	onPatchSuccess = () => {},
}) {
	const dispatch = useDispatch();
	const { customFields } = useSelector((state) => state);
	const entityCustomFields = customFields[cfEntity];

	useEffect(() => {
		if (!Object.keys(customFields).length) dispatch(getAllCustomField());
	}, [customFields]);

	const getCfValueData = (customField) => {
		const index = cfEntityData.findIndex(
			(cfData) => cfData.field_id === customField.id
		);
		if (index > -1) {
			return cfEntityData[index];
		} else {
			return null;
		}
	};

	return loading ? (
		<>
			<div className="custom_field_in_overview_container align-items-center justify-content-center">
				<Spinner
					animation="border"
					role="status"
					variant="dark"
					size="lg"
					style={{
						borderWidth: 2,
						borderColor: "#2262e2 transparent #2262e2 #2262e2",
					}}
				/>
			</div>
		</>
	) : (
		Array.isArray(entityCustomFields) && entityCustomFields.length > 0 && (
			<>
				<div className="custom_field_in_overview_container">
					{Array.isArray(entityCustomFields) &&
						entityCustomFields.map((customField, index) => (
							<React.Fragment key={index}>
								<div className="custom_field_in_overview_row">
									<div className="custom_field_in_overview_label">
										{customField.name}
									</div>
									<AddEditCustomFieldValue
										customField={customField}
										patcher={patcher}
										onPatchSuccess={onPatchSuccess}
										cfValue={
											getCfValueData(customField)
												?.field_value
										}
										cfDisplayValue={
											getCfValueData(customField)
												?.custom_field_name
										}
									/>
								</div>
							</React.Fragment>
						))}
				</div>
			</>
		)
	);
}

export function AddEditCustomFieldValue({
	customField,
	cfValue = null,
	cfDisplayValue = null,
	patcher,
	onPatchSuccess,
	isTableCell = false,
}) {
	const [value, setValue] = useState(cfValue);
	const [displayValue, setDisplayValue] = useState(cfDisplayValue);

	const [updating, setUpdating] = useState(false);
	const [clearing, setClearing] = useState(false);

	const [tableCellEditMode, setTableCellEditMode] = useState(false);
	const [error, setError] = useState(null);

	const getCustomFieldReactComponent = (customField) => {
		switch (customField.type) {
			case "text":
				return (
					<Form.Control
						value={value || ""}
						onChange={(e) => setValue(e.target.value?.trimStart())}
						placeholder={`Enter ${customField.name}`}
						bsPrefix="form-control custom_field_in_overview_text_input"
						style={{ border: isTableCell && "none" }}
					/>
				);
			case "select":
				return (
					<Dropdown
						toggler={
							<>
								<div
									className="custom_field_in_overview_dropdown_toggler"
									style={{ border: isTableCell && "none" }}
								>
									<LongTextTooltip
										text={
											value
												? value
												: `Select ${customField.name}`
										}
										placement={"top"}
										maxWidth="165px"
										style={{
											color: value
												? "#222222"
												: "#717171",
										}}
									/>
									<img
										src={arrowdropdown}
										height={10}
										width={7}
										alt=""
									/>
								</div>
							</>
						}
						options={
							Array.isArray(customField.options)
								? customField.options
								: []
						}
						optionFormatter={(option) => (
							<LongTextTooltip
								text={option}
								placement={"right"}
								maxWidth="180px"
							/>
						)}
						onOptionSelect={(option) => setValue(option)}
						menuClassName="custom_field_in_overview_dropdown_menu"
					/>
				);
			case "bool":
				return (
					<Dropdown
						toggler={
							<>
								<div
									className="custom_field_in_overview_dropdown_toggler"
									style={{ border: isTableCell && "none" }}
								>
									<LongTextTooltip
										text={
											value
												? value
												: `Select ${customField.name}`
										}
										placement={"top"}
										maxWidth="165px"
										style={{
											color: value
												? "#222222"
												: "#717171",
										}}
									/>
									<img
										src={arrowdropdown}
										height={10}
										width={7}
										alt=""
									/>
								</div>
							</>
						}
						options={
							Array.isArray(customField.options)
								? customField.options
								: []
						}
						optionFormatter={(option) => (
							<LongTextTooltip
								text={option}
								placement={"right"}
								maxWidth="180px"
							/>
						)}
						onOptionSelect={(option) => setValue(option)}
						menuClassName="custom_field_in_overview_dropdown_menu"
					/>
				);
			case "date":
				return (
					<NewDatePicker
						key={`${value}`}
						placeholder={
							<LongTextTooltip
								text={`Select ${customField.name}`}
								placement={"right"}
								maxWidth="150px"
								style={{ color: "#717171" }}
							/>
						}
						onChange={(date) => setValue(date)}
						calendarClassName="rangefilter-calendar"
						calendarContainerClassName="schedule-date-calendar"
						togglerClassName="custom_field_in_overview_date_picker_toggler"
						value={value}
						style={{ border: isTableCell && "none" }}
					/>
				);
			case "reference":
				return (
					<Dropdown
						toggler={
							<>
								<div
									className="custom_field_in_overview_dropdown_toggler"
									style={{ border: isTableCell && "none" }}
								>
									<LongTextTooltip
										text={
											displayValue
												? displayValue
												: `Select ${customField.name}`
										}
										placement={"top"}
										maxWidth="165px"
										style={{
											color: displayValue
												? "#222222"
												: "#717171",
										}}
									/>
									<img
										src={arrowdropdown}
										height={10}
										width={7}
										alt=""
									/>
								</div>
							</>
						}
						options={[]}
						apiSearch={true}
						apiSearchCall={(query, cancelToken) =>
							customField.reference === "orgapplications"
								? searchAllApps(query, cancelToken, true, false)
								: searchUsers(query, cancelToken, true, true)
						}
						apiSearchDataKey="results"
						optionFormatter={(option) => (
							<LongTextTooltip
								text={option.app_name || option.user_name}
								placement={"right"}
								maxWidth="180px"
							/>
						)}
						onOptionSelect={(option) => {
							if (customField.reference === "orgapplications") {
								setValue(option.app_id);
								setDisplayValue(option.app_name);
							} else {
								setValue(option.user_id);
								setDisplayValue(option.user_name);
							}
						}}
						menuClassName="custom_field_in_overview_dropdown_menu"
					/>
				);
			default:
				return <></>;
		}
	};

	const updateCustomFieldValue = (patchType) => {
		if (patchType === "delete") {
			setClearing(true);
		} else {
			setUpdating(true);
		}
		const payload = {
			patches: [
				patchType === "delete"
					? {
							op: patchType,
							field: "custom_fields",
							custom_field_id: customField.id,
						}
					: {
							op: patchType,
							field: "custom_fields",
							value: value,
							custom_field_id: customField.id,
						},
			],
		};
		patcher(payload)
			.then((res) => {
				ApiResponseNotification({
					title: "Custom field updated!",
					responseType: apiResponseTypes.SUCCESS,
					description:
						"The custom field value was successfully updated.",
				});
				if (onPatchSuccess) {
					onPatchSuccess(
						patchType,
						new EntityCustomFieldData({
							field_id: customField.id,
							field_value: value,
							custom_field_name: displayValue,
						})
					);
				}
				setClearing(false);
				setUpdating(false);
				setTableCellEditMode(false);
				if (patchType === "delete") {
					setValue(null);
					setDisplayValue(null);
				}
			})
			.catch((error) => {
				TriggerIssue("Error while updating the custom field", error);
				ApiResponseNotification({
					title: "Error while updating the custom field",
					responseType: apiResponseTypes.ERROR,
					errorObj: error,
					retry: () => updateCustomFieldValue(patchType),
				});
				setClearing(false);
				setUpdating(false);
				setError(error);
			});
	};

	return (
		<>
			{isTableCell ? (
				tableCellEditMode ? (
					<div className="custom_field_in_table_cell_add_edit_value_clear_save_button">
						<div className="custom_field_in_table_cell_add_edit_value_clear_button">
							{getCustomFieldReactComponent(customField)}
							{clearing ? (
								<div className="custom_field_in_table_cell_clear_button">
									<Spinner
										animation="border"
										role="status"
										variant="dark"
										size="sm"
										style={{
											borderWidth: "2px",
											width: "12px",
											minWidth: "12px",
											height: "12px",
											borderColor:
												"#2262e2 transparent #2262e2 #2262e2",
										}}
									/>
								</div>
							) : (
								<div className="custom_field_in_table_cell_clear_button">
									<LongTextTooltip
										text={
											<img
												src={close}
												className={`${
													clearing ||
													updating ||
													!cfValue
														? "o-6"
														: "cursor-pointer"
												}`}
												onClick={() =>
													!(
														clearing ||
														updating ||
														!cfValue
													) &&
													updateCustomFieldValue(
														"delete"
													)
												}
												height={8}
												width={8}
											/>
										}
										tooltipText={"Clear"}
										maxWidth="8px"
									/>
								</div>
							)}
						</div>
						{updating ? (
							<div className="custom_field_in_table_cell_submit_button">
								<Spinner
									animation="border"
									role="status"
									variant="dark"
									size="sm"
									style={{
										borderWidth: "2px",
										width: "16px",
										minWidth: "16px",
										height: "16px",
										borderColor:
											"#fff transparent #fff #fff",
									}}
								/>
							</div>
						) : (
							<div
								className={`custom_field_in_table_cell_submit_button ${
									updating || clearing || cfValue === value
										? "o-6"
										: "cursor-pointer"
								}`}
								onClick={() =>
									!(
										updating ||
										clearing ||
										cfValue === value
									) &&
									updateCustomFieldValue(
										cfValue ? "replace" : "add"
									)
								}
							>
								<LongTextTooltip
									text={
										<img
											src={saveCustomField}
											height={12}
											width={12}
											style={{
												filter: "invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%)",
											}}
										/>
									}
									tooltipText={"Save"}
									maxWidth="20px"
								/>
							</div>
						)}
					</div>
				) : (
					<>
						{value ? (
							<div className="custom_field_in_table_cell_value_display">
								<div className="custom_field_in_table_cell_value_display">
									{displayValue
										? displayValue
										: customField.type === "date"
											? moment(value).format(
													"DD MMM YYYY"
												)
											: value}
								</div>
								<img
									src={edit}
									width={16}
									height={16}
									alt=""
									onClick={() => setTableCellEditMode(true)}
								/>
							</div>
						) : (
							<div
								className="custom_field_in_table_cell_no_value_display"
								onClick={() => setTableCellEditMode(true)}
							>{`+ Add ${customField.name}`}</div>
						)}
					</>
				)
			) : (
				<div
					className="custom_field_in_overview_add_edit_value"
					key={clearing}
				>
					{getCustomFieldReactComponent(customField)}
					{clearing ? (
						<Spinner
							animation="border"
							role="status"
							variant="dark"
							size="sm"
							style={{
								borderWidth: "2px",
								width: "20px",
								minWidth: "20px",
								height: "20px",
								borderColor:
									"#2262e2 transparent #2262e2 #2262e2",
							}}
						/>
					) : (
						<div
							className="d-flex align-items-center justify-content-center"
							style={{ width: "20px", height: "20px" }}
						>
							<LongTextTooltip
								text={
									<img
										src={close}
										className={`${
											clearing || updating || !cfValue
												? "o-6"
												: "cursor-pointer"
										}`}
										onClick={() =>
											!(
												clearing ||
												updating ||
												!cfValue
											) &&
											updateCustomFieldValue("delete")
										}
										height={12}
										width={12}
										alt="delete"
									/>
								}
								tooltipText={"Clear"}
								maxWidth="12px"
							/>
						</div>
					)}
					{updating ? (
						<Spinner
							animation="border"
							role="status"
							variant="dark"
							size="sm"
							style={{
								borderWidth: "2px",
								width: "8px",
								minWidth: "8px",
								height: "8px",
								borderColor:
									"#2262e2 transparent #2262e2 #2262e2",
							}}
						/>
					) : (
						<div
							className="d-flex align-items-center justify-content-center"
							style={{ width: "20px", height: "20px" }}
						>
							<LongTextTooltip
								text={
									<img
										src={blackTickCircle}
										className={`${
											updating ||
											clearing ||
											cfValue === value
												? "o-6"
												: "cursor-pointer"
										}`}
										height={20}
										width={20}
										style={{
											filter: "invert(62%) sepia(98%) saturate(289%) hue-rotate(99deg) brightness(100%) contrast(93%)	",
										}}
										alt="save"
										onClick={() =>
											!(
												updating ||
												clearing ||
												cfValue === value
											) &&
											updateCustomFieldValue(
												cfValue ? "replace" : "add"
											)
										}
									/>
								}
								tooltipText={"Save"}
								maxWidth="20px"
							/>
						</div>
					)}
				</div>
			)}
		</>
	);
}
