import React from "react";
import { Dots } from "common/DottedProgress/DottedProgress";
import uploadpdfcontracticon from "../../licenses/assets/uploadpdfcontracticon.svg";

export const bulkUploadStatuses = {
	pending: {
		value: "pending",
		text: "Pending",
	},
	completed: {
		value: "completed",
		text: "Completed",
		csv: (
			<div className="contract_uploads_status_column_csv_completed">
				<Dots color="#40E395" />
				<div className="contract_uploads_status_column_csv_completed_text">
					Contract Added
				</div>
			</div>
		),
		pdf: (
			<div className="contract_uploads_status_column_pdf_completed">
				<img
					src={uploadpdfcontracticon}
					alt=""
					width={12}
					height={12}
				/>
				<div className="contract_uploads_status_column_pdf_completed_text">
					Review Draft
				</div>
			</div>
		),
	},
	validation_failed: {
		value: "validation_failed",
		text: "Validation Failed",
		style: { color: "red" },
	},
	script_failed: {
		value: "script_failed",
		text: "Processing Failed",
		style: { color: "red" },
	},
	data_added: {
		value: "data_added",
		text: "Validating",
	},
	validation_success: {
		value: "validation_success",
		text: "Processing",
		csv: (
			<div className="contract_uploads_status_column_csv_processing">
				<div className="contract_uploads_status_column_csv_processing_dot" />
				<div className="contract_uploads_status_column_csv_processing_text">
					Processing...
				</div>
			</div>
		),
		pdf: (
			<div className="contract_uploads_status_column_pdf_processing">
				<img
					src={uploadpdfcontracticon}
					alt=""
					width={12}
					height={12}
					className="contract_uploads_status_column_pdf_processing_icon"
				/>
				<div className="contract_uploads_status_column_pdf_processing_text">
					AI Processing...
				</div>
			</div>
		),
	},
	reviewed: {
		value: "reviewed",
		pdf: (
			<div className="contract_uploads_status_column_csv_completed">
				<Dots color="#40E395" />
				<div className="contract_uploads_status_column_csv_completed_text">
					Contract Added
				</div>
			</div>
		),
	},
	processing_failed: {
		value: "processing_failed",
		text: "Processing Failed",
		style: { color: "red" },
	},
	retry: {
		value: "retry",
		text: "Pending",
	},
	time_elapsed: {
		value: "time_elapsed",
		text: "Processing",
		csv: (
			<div className="contract_uploads_status_column_csv_processing">
				<div className="contract_uploads_status_column_csv_processing_dot" />
				<div className="contract_uploads_status_column_csv_processing_text">
					Processing...
				</div>
			</div>
		),
		pdf: (
			<div className="contract_uploads_status_column_pdf_processing">
				<img
					src={uploadpdfcontracticon}
					alt=""
					width={12}
					height={12}
					className="contract_uploads_status_column_pdf_processing_icon"
				/>
				<div className="contract_uploads_status_column_pdf_processing_text">
					AI Processing...
				</div>
			</div>
		),
	},
};
