import React, { useMemo, useState } from "react";
import moment from "moment";
import NewDatePicker from "UIComponents/DatePicker/NewDatePicker";
import calendar from "assets/v2tables/calendar.svg";
import NotificationIcon from "assets/v2tables/notification-blue-fill.svg";
import EnterpriseContract from "assets/v2tables/enterprise-contract.svg";
import SubscriptionContract from "assets/v2tables/subscription-contract.svg";
import PerpetualContract from "assets/v2tables/perpetual-contract.svg";
import { Typography, Tooltip } from "@zluri/ui-components";
import { Form } from "react-bootstrap";
import { capitalizeFirstLetter } from "utils/common";
import arrowConnect from "assets/v2tables/arrow-grey.svg";
import { useHistory } from "react-router-dom";
import { ProgressBarLoader } from "common/Loader/ProgressBarLoader";
import close from "assets/v2tables/close-blue.svg";
import {
	addCustomReminder,
	deleteCustomReminder,
	editCustomReminder,
	getContractOverviewDetails,
} from "services/api/licenses";
import {
	getNthDayBeforeDate,
	getNumberOfDaysBtwnTwoDates,
} from "utils/DateUtility";
import { useDispatch } from "react-redux";
import { addSetReminderForApplication } from "modules/renewals/service/api";

const subTextColor = "#A8A8A8";
const textColor = "#484848";

const ContractIcons = {
	contract: EnterpriseContract,
	subscription: SubscriptionContract,
	perpetual: PerpetualContract,
};

const ContractTypes = {
	contract: "contract",
	subscription: "subscription",
	perpetual: "perpetual",
};

// Commenting the ADD Reminder functionality (on UI only) because it was descoped for now
const ContractInfoCard = ({ contract, data, format, ...props }) => {
	const dispatch = useDispatch();
	const reminders = contract?.reminders || [];
	const customReminders = contract?.custom_reminders || [];
	const contractReminders = [...reminders, ...customReminders];
	const endDateReminders = [],
		renewalReminders = [];
	for (let i = 0; i < contractReminders.length; i++) {
		if (contractReminders[i].type === "end_date") {
			endDateReminders.push(contractReminders[i]);
		} else if (contractReminders[i]?.type === "upcoming_payment") {
			renewalReminders.push(contractReminders[i]);
		}
	}
	const remindersLength = useMemo(() => {
		if (
			[ContractTypes.contract, ContractTypes.perpetual].includes(
				contract.type
			)
		)
			return endDateReminders.length;
		return renewalReminders.length;
	}, [contract.type, endDateReminders.length, renewalReminders.length]);

	const [showReminderForm, setShowReminderForm] = useState(false);
	const [type, setType] = useState("input");
	const [contractData, setContractData] = useState({});
	const [showLoader, setShowLoader] = useState(false);

	const maxEndDateReminder = getNumberOfDaysBtwnTwoDates(
		new Date(),
		new Date(contract.end_date)
	);

	const [remindBefore, setRemindBefore] = useState(
		30 > maxEndDateReminder ? maxEndDateReminder : 30
	);

	const error = useMemo(() => {
		return (
			remindBefore &&
			(remindBefore < 1 || remindBefore > maxEndDateReminder)
		);
	}, [remindBefore, maxEndDateReminder]);

	const handleSubmit = () => {
		setShowLoader(true);
		if (
			[ContractTypes.contract, ContractTypes.perpetual].includes(
				contract.type
			)
		) {
			const remDate =
				type === "input"
					? getNthDayBeforeDate(
							remindBefore,
							new Date(contract.end_date)
						)
					: contractData.contract_date;
			addCustomReminder(contract._id, "end_date", remDate)
				.then(async (res) => {
					const contractOverviewDetails =
						await getContractOverviewDetails(contract._id);
					const activeContracts = (
						data.app_active_contracts || []
					)?.map((con) =>
						con._id === contract._id
							? {
									...con,
									custom_reminders:
										contractOverviewDetails.results
											?.custom_reminders || [],
								}
							: con
					);
					setShowLoader(false);
					setShowReminderForm(false);
					dispatch({
						type: "RECORD_UPDATED",
						payload: {
							index: props.rowIndex,
							value: {
								app_active_contracts: activeContracts,
							},
							entity: props.entity,
							rowIndex: props.rowIndex,
							columnIndex: props.columnIndex,
						},
					});

					setContractData({});
					setRemindBefore(2);
				})
				.catch((err) => {
					setShowLoader(false);
					setShowReminderForm(false);
					// todo: Need to update the error state
					console.log("error", err);
				});
		} else if (contract.type === ContractTypes.subscription) {
			// todo: Need to handle for subscription contract
			setShowLoader(false);
			const remDate =
				type === "input"
					? getNthDayBeforeDate(
							remindBefore,
							new Date(contract.renewal_date)
						)
					: contractData.contract_date;
			// addSetReminderForApplication(data.app_id, contract._id, remDate);
		}
	};

	const handleDelete = async (reminder_id) => {
		await deleteCustomReminder(contract._id, reminder_id);
		const contractOverviewDetails = await getContractOverviewDetails(
			contract._id
		);
		const activeContracts = (data.app_active_contracts || [])?.map((con) =>
			con._id === contract._id
				? {
						...con,
						custom_reminders:
							contractOverviewDetails.results?.custom_reminders ||
							[],
					}
				: con
		);
		dispatch({
			type: "RECORD_UPDATED",
			payload: {
				index: props.rowIndex,
				value: {
					app_active_contracts: activeContracts,
				},
				entity: props.entity,
				rowIndex: props.rowIndex,
				columnIndex: props.columnIndex,
			},
		});
	};

	return (
		<div key={contract._id} className="contracts-popover">
			<div
				style={{
					justifyContent: "space-between",
					backgroundColor: contract.is_error ? "#F6F7FA" : "none",
					alignItems: "center",
					filter: contract.is_error ? "grayscale(100%)" : "none",
				}}
				className="flex px-2 py-1"
			>
				<div
					style={{
						fontSize: "11px",
						fontWeight: "600",
						color: contract.is_error ? subTextColor : textColor,
						textAlign: "left",
					}}
					className="cursor-pointer"
				>
					<img
						src={ContractIcons[contract.type || "contract"]}
						className="mr-1"
						alt={contract.name}
					/>
					{contract.name || "-"}
				</div>
				<div
					style={{
						textAlign: "right",
						flexDirection: "column",
						width: "40%",
					}}
					className="flex"
				>
					<div style={{ color: subTextColor, fontSize: "8px" }}>
						{[
							ContractTypes.contract,
							ContractTypes.perpetual,
						].includes(contract.type)
							? "Ends on"
							: "Renews on"}
					</div>
					<Typography variant="body_3_bold">
						{[
							ContractTypes.contract,
							ContractTypes.perpetual,
						].includes(contract.type) && contract.end_date
							? moment(contract.end_date).format(format)
							: contract.next_renewal_date
								? moment(contract.next_renewal_date)
								: "-"}
					</Typography>
				</div>
			</div>
			<hr style={{ margin: "0 4px" }} />
			<div
				className="flex px-2 py-1"
				style={{ justifyContent: "space-between" }}
			>
				<div>
					<Typography variant="body_3_bold" color="secondary_grey_3">
						<img src={NotificationIcon} alt="notification bell" />{" "}
						{remindersLength === 0 ? "No" : remindersLength}{" "}
						Remainders Set{remindersLength > 0 && ":"}
					</Typography>
				</div>
				{/*{!showReminderForm && remindersLength === 0 && (*/}
				{/*	<button*/}
				{/*		className="contracts-add_remainder-submit__button"*/}
				{/*		onClick={() => setShowReminderForm(true)}*/}
				{/*	>*/}
				{/*		<Typography*/}
				{/*			variant="body_3_medium"*/}
				{/*			color="primary_blue_dark_1"*/}
				{/*		>*/}
				{/*			+ Add Reminder*/}
				{/*		</Typography>*/}
				{/*	</button>*/}
				{/*)}*/}
			</div>
			{remindersLength > 0 && (
				<div className="reminder--container px-2 py-1">
					<div className="reminder-date-row">
						{([
							ContractTypes.contract,
							ContractTypes.perpetual,
						].includes(contract.type)
							? endDateReminders
							: renewalReminders
						).map((rem) => (
							<div
								className="z_v2_reminder-pill common-tags__box  cursor-pointer contract-reminder-tag"
								key={rem._id}
								onClick={() => {
									handleDelete(rem._id);
								}}
							>
								<Typography
									variant="body_3_medium"
									className="z_v2_reminder-pill-text"
								>
									{moment(rem.date).format(format)}
								</Typography>
								<span className="contract-reminder-icon">
									<img src={close} alt="close" />
								</span>
							</div>
						))}
						{/*<button*/}
						{/*	className=" contracts-add_remainder-submit__button "*/}
						{/*	onClick={() => setShowReminderForm(true)}*/}
						{/*>*/}
						{/*	<Typography*/}
						{/*		variant="body_3_medium"*/}
						{/*		color="primary_blue_dark_1"*/}
						{/*	>*/}
						{/*		Add Reminder*/}
						{/*	</Typography>*/}
						{/*</button>*/}
					</div>
				</div>
			)}
			{showReminderForm && (
				<div className="my-1 px-2">
					<Form.Control
						as="select"
						value={type}
						onChange={(e) => {
							setType(e.target.value);
						}}
					>
						<option value="input">Days before end date</option>
						<option value="date">Specific Date</option>
					</Form.Control>
					{type === "input" ? (
						<>
							<div
								className="d-flex align-items-center py-2"
								style={{
									border: "1px solid #717171",
									padding: "6px 11px",
									borderRadius: "8px",
									margin: "8px 0px",
								}}
							>
								<Form.Control
									type="number"
									className="chip__input license_name_v2_input_box"
									placeholder={`Number of Days`}
									value={contractData.num_of_days}
									onChange={(e) => {
										setRemindBefore(e.target.value);
										setContractData({
											...contractData,
											num_of_days: e.target.value,
										});
									}}
								/>
							</div>
							{error && (
								<div hidden={!error}>
									<Typography
										variant="body_3_regular"
										color="accent_red_1"
									>
										Please enter a value less than{" "}
										{maxEndDateReminder}.
									</Typography>
								</div>
							)}
						</>
					) : (
						<div className="py-2">
							<NewDatePicker
								placeholder="Set Date"
								onChange={(date) =>
									setContractData({
										...contractData,
										contract_date: date,
									})
								}
								minDate={moment(new Date())}
								maxDate={moment(contract.end_date)}
								calendarClassName="rangefilter-calendar"
								calendarContainerClassName="schedule-date-calendar"
								style={{
									height: "36px ",
									border: "1px solid #717171 ",
									minWidth: "50px",
								}}
								value={contractData.contract_date}
								icon={calendar}
								iconWidth={15}
								iconHeight={15}
								calenderPosition="fixed"
							/>
						</div>
					)}
					{/*<button*/}
					{/*	className="contracts-add_remainder-submit__button cursor-pointer"*/}
					{/*	onClick={handleSubmit}*/}
					{/*	role="button"*/}
					{/*	disabled={error}*/}
					{/*>*/}
					{/*	{showLoader ? (*/}
					{/*		<ProgressBarLoader*/}
					{/*			style={{ marginTop: "-2px" }}*/}
					{/*			height={20}*/}
					{/*			width={20}*/}
					{/*		/>*/}
					{/*	) : (*/}
					{/*		<Typography*/}
					{/*			variant="button_extrasmall_medium"*/}
					{/*			color="primary_blue_dark_1"*/}
					{/*		>*/}
					{/*			Add Reminder*/}
					{/*		</Typography>*/}
					{/*	)}*/}
					{/*</button>*/}
				</div>
			)}
		</div>
	);
};

export function ContractsPopover({ component, data, ...props }) {
	const format = component.format || "D MMM 'YY";
	return data[component.valueKey]?.map((contract, index) => {
		return (
			<div key={contract._id}>
				<ContractInfoCard
					contract={contract}
					data={data}
					format={format}
					{...props}
				/>
				{index !== data[component.valueKey]?.length - 1 && (
					<hr style={{ margin: "0 4px" }} />
				)}
			</div>
		);
	});
}

export function ContractName({ data, component, ...props }) {
	return (
		<>
			{data[component.valueKey]?.slice(0, 1).map((obj) => (
				<div className="d-flex flex-column flex-1" key={obj._id}>
					<Tooltip
						content={
							<Typography variant="body_1_regular" color="white">
								{capitalizeFirstLetter(obj.name)}
							</Typography>
						}
					>
						<div
							className="subcategory__truncate"
							// subcategory__truncate hsa maxWidth:100px and it's used in other component as well, so overriding with style here
							style={{ maxWidth: "100%" }}
						>
							<img
								src={ContractIcons[obj.type || "contract"]}
								className="mr-1"
								alt={obj.name}
							/>
							{`${obj.name}`}
						</div>
					</Tooltip>
				</div>
			))}
		</>
	);
}

export function EmptyContactConnection({ component }) {
	const history = useHistory();

	// todo: This will be moved to cellRenderer as this may present for other components as well.
	const [isHovering, setIsHovering] = useState(false);

	return (
		<div
			onMouseOver={() => setIsHovering(true)}
			onMouseOut={() => setIsHovering(false)}
			className="d-flex align-items-center"
		>
			<Typography
				style={{
					color: isHovering && "#2266E2",
					borderBottom: isHovering && "1px solid #2266E2",
				}}
				// todo: onclick will be enabled once the add contract fields is confirmed.
				onClick={() => {
					history.push("/contract/new");
				}}
				className="v2table__cell-text cursor-pointer v2table__cell-empty-link"
				variant="body_1_regular"
				color="secondary_grey_4"
			>
				Add Contract
			</Typography>
			<img
				className="ml-1 v2table__cell-empty-link-icon"
				src={arrowConnect}
				alt="add contract"
			/>
		</div>
	);
}
