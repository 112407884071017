import React, { useCallback, useEffect, useState } from "react";
import {
	Actions,
	ViewsInCreateReviewPage,
	reviewDetailsFormStrings,
	ViewType,
	STATUS,
	CERTIFICATION_DETAILS,
	popupEntities,
} from "modules/AccessReview/constants/constants";
import { useDispatch, useSelector } from "react-redux";
import {
	updateReviewDetailsForm,
	changeView,
	makeActiveStep,
	updateProgressbar,
	updateInitialLevelData,
	handleAddInitialLevel,
	handleRemoveInitialLevel,
	updateSetReviewersToggle,
} from "modules/AccessReview/redux/AccessReview.actions";
import { Field, SUB_HEADING_VARIANT, Typography } from "@zluri/ui-components";
import { searchUsers } from "services/api/search";
import {
	checkIfAllFieldsFilled,
	checkIfIncompleteDataExists,
	checkIfWholeReviewersDataIsEmpty,
	checkIsReviewersDataFilled,
} from "modules/AccessReview/util/util";
import { SelectV2 } from "../SelectV2/SelectV2";
import { debounce } from "underscore";
import { ImageOrNameBadge } from "@zluri/ui-components";
import ControlFlowButtonGroup from "../ControlFlowButtonGroup/ControlFlowButtonGroup";
import "modules/AccessReview/components/ReviewDetailsForm/ReviewDetailsForm.css";
import "modules/AccessReview/components/SelectV2/SelectV2.css";
import AddLevelsOfReviews from "../AddLevelsOfReviews/AddLevelsOfReviews";
import { Popup } from "../common/common";

export default function ReviewDetailsForm() {
	const dispatch = useDispatch();

	const { defaultUsersAndRoles } = useSelector((state) => state.accessReview);
	const defaultUsers = defaultUsersAndRoles?.defaultUsers;
	const { reviewDetailsForm, initialLevels, initialLevelsExist } =
		useSelector((state) => state.accessReview);
	const [optionsLoading, setOptionsLoading] = useState(false);
	const [userData, setUserData] = useState(defaultUsers ?? []);
	const [showCancelPopup, setShowCancelPopup] = useState(false);
	const [showConfirmPopup, setShowConfirmPopup] = useState(false);
	const ifAllformFieldsFilled = checkIfAllFieldsFilled(reviewDetailsForm);
	const ifAllReviewersFilled = checkIsReviewersDataFilled(initialLevels);

	const isValidated = initialLevelsExist
		? ifAllReviewersFilled && ifAllformFieldsFilled
		: ifAllformFieldsFilled;

	const handleChange = (value, name) => {
		const reviewDetails = { ...reviewDetailsForm, [name]: value };
		dispatch(updateReviewDetailsForm(reviewDetails));
	};

	const handleNextClick = () => {
		dispatch(
			changeView(
				ViewsInCreateReviewPage.States.ReviewDetailsForm,
				Actions.NEXT,
				ViewType.ViewsInCreateReviewPage
			)
		);
		dispatch(
			updateProgressbar(
				0,
				STATUS.Completed,
				ViewType.ViewsInCreateReviewPage
			)
		);
	};

	const updateLevelInfo = (obj, additionalInfo) => {
		dispatch(updateInitialLevelData(obj, additionalInfo));
	};

	const handleAddLevel = () => {
		dispatch(handleAddInitialLevel());
	};

	const handleRemoveLevel = (key) => {
		dispatch(handleRemoveInitialLevel(key));
	};

	const handleSetupReviewersToggle = (value) => {
		if (!value) {
			const ifIncompleteDataExists =
				checkIfIncompleteDataExists(initialLevels);
			const ifAllFieldsAreEmpty =
				checkIfWholeReviewersDataIsEmpty(initialLevels);

			ifAllFieldsAreEmpty
				? dispatch(updateSetReviewersToggle(value))
				: ifIncompleteDataExists || ifAllReviewersFilled
				? setShowConfirmPopup(true)
				: dispatch(updateSetReviewersToggle(value));
		} else {
			dispatch(updateSetReviewersToggle(value));
		}
	};

	const debouncedChangeHandler = useCallback(
		debounce((query, reqCancelToken) => {
			if (query || query === "") {
				searchUsers(query, reqCancelToken, true).then((res) => {
					setUserData(
						res?.results?.filter(
							(user) => user?.user_status === "active"
						)
					);
					setOptionsLoading(false);
				});
			}
		}, 500),
		[]
	);
	useEffect(() => {
		if (defaultUsers) {
			setUserData(defaultUsers);
		} else {
			setOptionsLoading(true);
		}
	}, [defaultUsers]);

	useEffect(() => {
		dispatch(
			updateProgressbar(
				0,
				isValidated ? STATUS.Completed : STATUS.InProgress,
				ViewType.ViewsInCreateReviewPage
			)
		);

		dispatch(makeActiveStep(0));
	}, [reviewDetailsForm, initialLevels, dispatch, isValidated]);

	return (
		<React.Fragment>
			<div className="d-flex flex-column gap-10">
				<div className="review-details-form">
					<Typography variant={SUB_HEADING_VARIANT.SUBHEADING_2_BOLD}>
						{CERTIFICATION_DETAILS}
					</Typography>

					<div
						className="d-flex flex-row align-items-center mb-2"
						style={{ gap: "12px" }}
					>
						<div className="w-50 access-cert-input-field">
							<Field
								handlers={{
									onChange: (e) => {
										handleChange(
											e.target?.value,
											reviewDetailsFormStrings?.name
										);
									},
								}}
								value={reviewDetailsForm?.name}
								placeholder="Certification Name*"
								className="font-11 bold-400 w-100"
							/>
						</div>
						<div className="w-50">
							<SelectV2
								defaultClassNames={true}
								onSearchClear={() =>
									handleChange(
										{},
										reviewDetailsFormStrings.owner
									)
								}
								isNameBadge={true}
								inputStyle={{
									fontSize: "11px",
									fontWeight: "400",
									opacity: 1,
								}}
								style={{ padding: "1px" }}
								search={true}
								value={[reviewDetailsForm?.owner]}
								isOptionsLoading={optionsLoading}
								hideDropDown={false}
								imageOrNameBadgeImgClassName={
									"selected-option-img mr-1"
								}
								imageOrNameBadgeNameClassName={
									"search-name-options mr-1"
								}
								options={userData}
								fieldNames={{
									label: "user_name",
									description: "user_email",
									logo: "profile_img",
								}}
								placeholder={"Certification Owner*"}
								onChange={(obj) => {
									handleChange(
										obj,
										reviewDetailsFormStrings.owner
									);
								}}
								onSearch={(query) => {
									if(query?.length){
										setOptionsLoading(true);
										setUserData([]); 
										debouncedChangeHandler(query); 
									}
									else {
										setOptionsLoading(false); 
										setUserData(defaultUsers); 
										handleChange(
											{},
											reviewDetailsFormStrings.owner
										); 
									}
								}}
								optionRender={(option, props) => {
									return (
										<div className="d-flex justify-content-between">
											<div className="d-flex align-items-center">
												<ImageOrNameBadge
													url={option?.profile_img}
													name={option?.user_name}
													nameBadgeClasses="search-name-options"
													className="selected-option-img"
												/>
												<div className="d-flex flex-column family-sora bold-500 ml-2">
													<div className="font-14 black-1">
														{option.user_name}
													</div>
													<div className="font-11 grey-1 pt-1">
														{option.user_email}
													</div>
												</div>
											</div>
										</div>
									);
								}}
							/>
						</div>
					</div>
					<div className="w-100">
						<Field
							handlers={{
								onChange: (e) => {
									handleChange(
										e.target?.value,
										reviewDetailsFormStrings?.description
									);
								},
							}}
							fieldType="textarea"
							value={reviewDetailsForm?.description}
							placeholder="Certification Description"
							className="w-100 font-11 bold-400 access-cert-textarea font-family-sora"
						/>
					</div>
				</div>

				<div className="review-details-form">
					<AddLevelsOfReviews
						levels={initialLevels}
						handleChange={updateLevelInfo}
						handleAddLevel={handleAddLevel}
						handleRemoveLevel={handleRemoveLevel}
						initialLevelsExist={initialLevelsExist}
						handleSetupReviewersToggle={handleSetupReviewersToggle}
						isDefaultReviewersComp={true}
					/>
				</div>

				<div className="review-form-btn-container">
					<ControlFlowButtonGroup
						iconBtnDisabled
						onCancelClick={() => setShowCancelPopup(true)}
						onActionClick={handleNextClick}
						actionBtnDisabled={!isValidated}
						actionBtnTooltipText="You can proceed to the next step if default reviewers are set up or if you've disabled the option to set up default reviewers"
					/>
				</div>
			</div>
			<Popup
				show={showCancelPopup}
				setShow={setShowCancelPopup}
				entity={popupEntities.cancelPopup}
			/>
			<Popup
				show={showConfirmPopup}
				setShow={setShowConfirmPopup}
				entity={popupEntities.defaultReviewers}
			/>
		</React.Fragment>
	);
}
