import React, { useState } from "react";
import { Typography, Field } from "@zluri/ui-components";
import redAlert from "assets/v2tables/red-indicator.svg";
import MoneyIcon from "assets/Icon_Money.svg";
import PropTypes from "prop-types";

const SpendsBudgetPopOver = ({ data, component, ...props }) => {
	const value = data[component.valueKey];

	return (
		<div className="px-2 py-1 ">
			<div className="text-left">
				<Typography variant="subheading_4_bold">Budget</Typography>
			</div>
			<SpendsBudgetFields
				defaultValue={value}
				onCancel={() => props.hidePopup()}
				handleUpdate={props.handleUpdate}
				appId={data.app_id}
			/>
		</div>
	);
};

export function SpendsBudgetFields({
	defaultValue,
	onCancel,
	handleUpdate,
	appId,
}) {
	const [editedBudget, setEditedBudget] = useState(defaultValue);

	const handleClick = (updatedValue) => {
		if (defaultValue === updatedValue) return;
		try {
			const patchObj = {
				patches: [
					{
						op: "replace",
						field: "budget",
						value: updatedValue,
					},
				],
			};
			handleUpdate(appId, patchObj, {
				app_budget: updatedValue,
			});
		} catch (err) {
			console.error("Error updating application owner:", err);
		}
	};
	return (
		<>
			<Field
				handlers={{
					onChange: (e) => {
						setEditedBudget(e.target.value);
					},
				}}
				defaultValue={defaultValue}
				prefixIcon={MoneyIcon}
				type="number"
				fieldType="input"
				placeholder="Budget"
				className="font-11 bold-400 grey-1 z-spends-budget-popover"
			/>
			<div
				className="d-flex align-items-center"
				style={{ gap: "4px", marginTop: "7px" }}
			>
				<div
					className="d-flex align-items-center justify-content-center w-100 grey-1 font-10 cursor-pointer"
					onClick={() => {
						onCancel();
					}}
					style={{
						height: "32px",
						background: "#EBEBEB",
						borderRadius: "8px",
					}}
				>
					Cancel
				</div>
				<div
					className="d-flex align-items-center justify-content-center w-100  font-10 cursor-pointer"
					style={{
						height: "32px",
						background: "#5287E8",
						borderRadius: "8px",
						color: "white",
					}}
					onClick={() => {
						handleClick(editedBudget);
					}}
				>
					Confirm
				</div>
			</div>
		</>
	);
}

SpendsBudgetFields.propTypes = {
	defaultValue: PropTypes.number,
	onCancel: PropTypes.func,
	handleUpdate: PropTypes.func,
	appId: PropTypes.string,
};
export default SpendsBudgetPopOver;

export const SpendsLastDatePopover = () => {
	return (
		<div className="p-2 d-flex align-items-center">
			<img src={redAlert} width="16px" alt="" className="mr-1" />
			<Typography variant="body_2_regular" color="secondary_grey_2">
				No Transactions in last 30 days
			</Typography>
		</div>
	);
};
