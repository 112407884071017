import React from "react";
import { Link } from "react-router-dom";

import {
	Button,
	Container,
	ImageOrNameBadge,
	PrimaryButtonVariant,
	Typography,
	theme,
} from "@zluri/ui-components";

import unmap from "assets/icons/Icon_Unmap.svg";

interface IConnectIngegrationBanner {
	status:
		| "connect now"
		| "disconnected"
		| "unavailable"
		| "error"
		| "not-connected";
	integrationId: string;
}

const ConnectIntegrationBanner = ({
	status,
	integrationId,
}: IConnectIngegrationBanner) => {
	const isNotConnected = ["disconnected", "error", "not-connected"].includes(
		status
	);
	if (!isNotConnected) return null;
	return (
		<Container
			className="d-flex align-items-center gap-10 h-auto sticky-top"
			style={{ overflow: "unset", zIndex: 1 }}
			bgColor="#FFF6DC"
		>
			<span className="z_connect_integration-icon">
				<ImageOrNameBadge url={unmap} />
			</span>
			<span className="d-flex flex-column gap-2 mr-auto">
				<Typography>Direct Integration disconnected</Typography>
				<Typography>
					Connect now to increase user data quality.
				</Typography>
			</span>
			<Link to={`/integrations/catalog/detail/${integrationId}#overview`}>
				<Button
					theme={theme}
					variant={PrimaryButtonVariant.primary_default}
				>
					Connect Now
				</Button>
			</Link>
		</Container>
	);
};

export default ConnectIntegrationBanner;
