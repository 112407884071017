import React from "react";

import {
	BODY_VARIANT,
	GREY_VARIANT,
	ImageOrNameBadge,
	Popover,
	SUB_HEADING_VARIANT,
	Typography,
} from "@zluri/ui-components";

import blueTick from "assets/blueTick.svg";

import { reviewersPopoverType } from "modules/AccessReview/constants/constants";
import { RED_VARIANT } from "@zluri/ui-components";

export default function ReviewersDataAtLevels({
	levels,
	currentReviewLevel,
	popoverType = reviewersPopoverType.DEFAULT,
	placement = "bottom",
	children,
}) {
	const shouldRender = Array.isArray(levels) && levels?.length > 0;

	if (!shouldRender) return;

	const defaultPopoverContent = (
		<div className="reviewer_data_container_level">
			<div className="reviewer_data_header">
				<Typography
					variant={BODY_VARIANT.BODY_3_REGULAR}
					color={GREY_VARIANT.SECONDARY_GREY_3}
					style={{ marginLeft: "4px" }}
				>
					Level
				</Typography>
				<Typography
					variant={BODY_VARIANT.BODY_3_REGULAR}
					color={GREY_VARIANT.SECONDARY_GREY_3}
					style={{ marginLeft: "50px" }}
				>
					Primary Reviewer
				</Typography>
				<Typography
					variant={BODY_VARIANT.BODY_3_REGULAR}
					color={GREY_VARIANT.SECONDARY_GREY_3}
					style={{ marginLeft: "71px" }}
				>
					Fallback Reviewer
				</Typography>
			</div>
			<div className="reviewers_data_rows hide-scrollbar">
				{levels?.map((level, idx) => {
					return (
						<>
							<div key={level?.key} className="popover_level_row">
								<div className="d-flex w-100 gap-8 align-items-center">
									<Typography
										className="padding_10 level_text"
										variant={BODY_VARIANT.BODY_2_BOLD}
										color={GREY_VARIANT.SECONDARY_GREY_1}
									>
										{`Level - ${idx + 1} `}
									</Typography>
									<div className="primary_reviewer_data">
										<div className="d-flex gap-8 align-items-center">
											<ImageOrNameBadge
												url={
													level?.primary_reviewer
														?.user_profile ||
													level?.primary_reviewer
														?.profile_img ||
													level?.primary_reviewer
														?.user_profile_img
												}
												name={
													level?.primary_reviewer
														?.user_name
												}
												height={15}
												width={15}
												nameBadgeClasses="search-name-options"
												className="selected-option-img"
											/>
											<Typography
												className="text-break"
												variant={
													BODY_VARIANT.BODY_2_MEDIUM
												}
												color={
													GREY_VARIANT.SECONDARY_GREY_1
												}
											>
												{
													level?.primary_reviewer
														?.user_name
												}
											</Typography>
										</div>
									</div>
									<div className="fallback_reviewer_data">
										<div className="d-flex gap-8 align-items-center">
											<ImageOrNameBadge
												url={
													level?.fallback_reviewer
														?.user_profile ||
													level?.fallback_reviewer
														?.profile_img ||
													level?.fallback_reviewer
														?.user_profile_img
												}
												name={
													level?.fallback_reviewer
														?.user_name
												}
												height={15}
												width={15}
												nameBadgeClasses="search-name-options"
												className="selected-option-img"
											/>
											<Typography
												className="text-break"
												variant={
													BODY_VARIANT.BODY_2_MEDIUM
												}
												color={
													GREY_VARIANT.SECONDARY_GREY_1
												}
											>
												{
													level?.fallback_reviewer
														?.user_name
												}
											</Typography>
										</div>
									</div>
								</div>
							</div>
						</>
					);
				})}
			</div>
		</div>
	);

	const STEP_STATES = {
		default: (
			<div className="default_level_step--outer-circle">
				<div className="default_level_step--inner-circle"></div>
			</div>
		),
		active: (
			<div className="active_level_step--outer-circle">
				<div className="active_level_step--inner-circle"></div>
			</div>
		),
		completed: (
			<div className="completed_level_step--outer-circle">
				<div className="completed_level_step--inner-circle">
					<img src={blueTick} alt="" width={6} height={6} />
				</div>
			</div>
		),
	};

	const renderCurrentStep = (levelsLength, idx) => {
		const currIdx = idx + 1;
		const levelSignedOff = currIdx < currentReviewLevel;
		const activeLevel = currIdx === currentReviewLevel;
		const levelYetToBegin = currIdx > currentReviewLevel;
		return (
			<React.Fragment>
				{levelSignedOff
					? STEP_STATES.completed
					: activeLevel
					? STEP_STATES.active
					: levelYetToBegin
					? STEP_STATES.default
					: STEP_STATES.default}
				{levelsLength - 1 !== idx && (
					<div className="steppers_vertical_line"></div>
				)}
			</React.Fragment>
		);
	};

	const highlightedCurrentLevelContent = (
		<div className="current_reviewer_container_level">
			<div className="reviewer_data_header">
				<Typography
					variant={BODY_VARIANT.BODY_3_REGULAR}
					color={GREY_VARIANT.SECONDARY_GREY_3}
					style={{ marginLeft: "34px" }}
				>
					Level
				</Typography>
				<Typography
					variant={BODY_VARIANT.BODY_3_REGULAR}
					color={GREY_VARIANT.SECONDARY_GREY_3}
					style={{ marginLeft: "63px" }}
				>
					Primary Reviewer
				</Typography>
				<Typography
					variant={BODY_VARIANT.BODY_3_REGULAR}
					color={GREY_VARIANT.SECONDARY_GREY_3}
					style={{ marginLeft: "77px" }}
				>
					Fallback Reviewer
				</Typography>
			</div>
			<div className="reviewers_data_rows hide-scrollbar">
				{levels?.map((level, idx) => {
					const isActiveLevel = currentReviewLevel === idx + 1;
					return (
						<div
							key={level?.key}
							className={`d-flex align-items-center gap-10 padding_10 position-relative ${
								isActiveLevel ? "accent-blue-bg" : ""
							}`}
						>
							{renderCurrentStep(levels?.length, idx)}

							<div className="popover_level_row">
								<div className="d-flex w-100 gap-10 align-items-center">
									<Typography
										variant={BODY_VARIANT.BODY_2_BOLD}
										color={GREY_VARIANT.SECONDARY_GREY_1}
										className="level_text"
									>
										{`Level - ${idx + 1} `}
									</Typography>
									<div className="primary_reviewer_data">
										<div className="d-flex gap-8 align-items-center">
											<ImageOrNameBadge
												url={
													level?.primary_reviewer
														?.user_profile ||
													level?.primary_reviewer
														?.profile_img ||
													level?.primary_reviewer
														?.user_profile_img
												}
												name={
													level?.primary_reviewer
														?.user_name
												}
												height={15}
												width={15}
												nameBadgeClasses="search-name-options"
												className="selected-option-img"
											/>
											<Typography
												className="text-break"
												variant={
													BODY_VARIANT.BODY_2_MEDIUM
												}
												color={
													GREY_VARIANT.SECONDARY_GREY_1
												}
											>
												{
													level?.primary_reviewer
														?.user_name
												}
											</Typography>
										</div>
									</div>
									<div className="fallback_reviewer_data">
										<div className="d-flex gap-8 align-items-center">
											<ImageOrNameBadge
												url={
													level?.fallback_reviewer
														?.user_profile ||
													level?.fallback_reviewer
														?.profile_img ||
													level?.fallback_reviewer
														?.user_profile_img
												}
												name={
													level?.fallback_reviewer
														?.user_name
												}
												height={15}
												width={15}
												nameBadgeClasses="search-name-options"
												className="selected-option-img"
											/>
											<Typography
												className="text-break"
												variant={
													BODY_VARIANT.BODY_2_MEDIUM
												}
												color={
													GREY_VARIANT.SECONDARY_GREY_1
												}
											>
												{
													level?.fallback_reviewer
														?.user_name
												}
											</Typography>
										</div>
									</div>
								</div>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);

	function getPopoverContent(type) {
		switch (type) {
			case reviewersPopoverType.DEFAULT:
				return defaultPopoverContent;
			case reviewersPopoverType.HIGHLIGHTED_CURRENT_LEVEL:
				return highlightedCurrentLevelContent;
			default:
				return defaultPopoverContent;
		}
	}

	const popoverContent = getPopoverContent(popoverType);

	return (
		shouldRender && (
			<div className="d-flex flex-1 cursor-pointer">
				<Popover
					content={popoverContent}
					trigger="hover"
					placement={placement}
					backgroundColor="#f6f7fa"
				>
					{children ? (
						children
					) : (
						<div
							style={{
								borderBottom: "1px dotted #222",
							}}
						>
							<Typography
								variant={
									SUB_HEADING_VARIANT.SUBHEADING_2_REGULAR
								}
							>
								{`${levels?.length} ${
									levels?.length > 1 ? "Levels" : "Level"
								}`}
							</Typography>
						</div>
					)}
				</Popover>
			</div>
		)
	);
}
