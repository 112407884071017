import React from "react";

import PropTypes from "prop-types";

import {
	ALERT_TAG_VARIANT,
	BODY_VARIANT,
	Tooltip,
	Typography,
} from "@zluri/ui-components";

import caretRightIcon from "assets/icons/Icon_Arrow_Right_Gray.svg";

/* Being used in
   Application Users -> Department Path, Cost Centre Path, Business Unit Path
 */
export function Path({ data, component }) {
	const { valueKey, style = {} } = component;

	const value = data?.[valueKey];
	const paths = value?.split("/");
	const firstPath = paths?.at(0) ?? "";
	const lastPath = paths?.at(-1) ?? "";

	const ellipsisText = paths?.slice(1, -1)?.join(", ");

	const caretRight = (
		<img src={caretRightIcon} alt="caret right" height="8px" width="8px" />
	);

	const showLastPath = paths?.length > 1;
	const showFullPathWithEllipsis = paths?.length > 2;

	return (
		<Typography
			variant={BODY_VARIANT.BODY_1_REGULAR}
			className="w-100"
			color="#222222"
			style={style}
		>
			<div className="d-flex align-items-center gap-4">
				<Tooltip
					content={
						<Typography
							variant={ALERT_TAG_VARIANT.ALERT_TAG_SMALL_MEDIUM}
							color="white"
						>
							{firstPath}
						</Typography>
					}
				>
					<span className="v2table__cell-text">{firstPath}</span>
				</Tooltip>

				{showLastPath && (
					<>
						{caretRight}
						{showFullPathWithEllipsis && (
							<>
								<Tooltip
									content={
										<Typography
											variant={
												ALERT_TAG_VARIANT.ALERT_TAG_SMALL_MEDIUM
											}
											color="white"
										>
											{ellipsisText}
										</Typography>
									}
								>
									<span>...</span>
								</Tooltip>

								{caretRight}
							</>
						)}
						<Tooltip
							content={
								<Typography
									variant={
										ALERT_TAG_VARIANT.ALERT_TAG_SMALL_MEDIUM
									}
									color="white"
								>
									{lastPath}
								</Typography>
							}
						>
							<span className="v2table__cell-text">
								{lastPath}
							</span>
						</Tooltip>
					</>
				)}
			</div>
		</Typography>
	);
}
Path.propTypes = {
	data: PropTypes.object,
	component: PropTypes.shape({
		valueKey: PropTypes.string,
		style: PropTypes.object,
	}),
};
