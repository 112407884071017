import { BODY_VARIANT, GREY_VARIANT, Typography } from "@zluri/ui-components";
import { getStatusIcon } from "modules/applications/utils/SecurityCompliance";
import moment from "moment";
import React from "react";
import { capitalizeFirstLetter } from "utils/common";

export const ComplianceList = (props) => {
	const { complianceData } = props;

	return (
		<>
			{complianceData?.slice(0, 4)?.map((compliance, i) => (
				<div
					key={compliance?.compliance_id}
					className="d-flex align-items-center py-2 border-bottom"
				>
					<div className="mr-2">
						<img
							width={16}
							height={16}
							src={compliance?.compliance_image}
							alt=""
						/>
					</div>
					<div className="d-flex flex-column align-items-start flex-1">
						<Typography
							color={GREY_VARIANT.SECONDARY_GREY_1}
							variant={BODY_VARIANT.BODY_2_REGULAR}
						>
							{compliance?.compliance_name}
						</Typography>
						<div className="d-flex align-items-center">
							{compliance?.compliance_status && (
								<img
									src={
										getStatusIcon(
											compliance?.compliance_status,
										).icon
									}
									width={10}
									height={10}
									className="mr-1 border-none"
									alt=""
								/>
							)}
							<Typography
								color={
									getStatusIcon(compliance?.compliance_status)
										.color
								}
								variant={BODY_VARIANT.BODY_3_REGULAR}
							>
								{capitalizeFirstLetter(
									compliance?.compliance_status,
								)}
							</Typography>
						</div>
					</div>
					{compliance?.compliance_expires_on && (
						<div className="font-9 d-flex flex-column align-items-center justify-content-center">
							<Typography
								variant={BODY_VARIANT.BODY_3_REGULAR}
								color={GREY_VARIANT.SECONDARY_GREY_3}
							>
								Expires On
							</Typography>
							<Typography
								variant={BODY_VARIANT.BODY_3_BOLD}
								color={GREY_VARIANT.SECONDARY_GREY_3}
							>{`${moment(
								compliance.compliance_expires_on,
							).format("DD MMM YY")}`}</Typography>
						</div>
					)}
				</div>
			))}
		</>
	);
};
