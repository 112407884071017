export const ApplicationConstants = {
	ACTUAL_SPENDS_VS_EST_COST: "ACTUAL_SPENDS_VS_EST_COST",
	AMMORTIZED_SPENDS_VS_EST_COST: "AMMORTIZED_SPENDS_VS_EST_COST",
	ACCRUED_SPENDS: "ACCRUED_SPENDS",
	SET_SELECTED_TAB: "SET_SELECTED_TAB",
	SET_DEFAULT_PAYMENT_METHOD: "SET_DEFAULT_PAYMENT_METHOD",
	SET_APP_ARCHIVE: "SET_APP_ARCHIVE",

	FETCH_APPLICATION_SPENDS_OVERVIEW_DETAILS_REQUESTED:
		"FETCH_APPLICATION_SPENDS_OVERVIEW_DETAILS_REQUESTED",
	FETCHED_APPLICATION_SPENDS_OVERVIEW_DETAILS:
		"FETCHED_APPLICATION_SPENDS_OVERVIEW_DETAILS",

	FETCH_APPLICATION_SPENDS_SUMMARY_REQUESTED:
		"FETCH_APPLICATION_SPENDS_SUMMARY_REQUESTED",
	FETCHED_APPLICATION_SPENDS_SUMMARY: "FETCHED_APPLICATION_SPENDS_SUMMARY",

	FETCH_ACCRUED_SPENDS_GRAPH_REQUESTED:
		"FETCH_ACCRUED_SPENDS_GRAPH_REQUESTED",
	FETCHED_ACCRUED_SPENDS_GRAPH: "FETCHED_ACCRUED_SPENDS_GRAPH",

	FETCH_ACTUAL_SPENDS_VS_COST_GRAPH_REQUESTED:
		"FETCH_ACTUAL_SPENDS_VS_COST_GRAPH_REQUESTED",
	FETCHED_ACTUAL_SPENDS_VS_COST_GRAPH: "FETCHED_ACTUAL_SPENDS_VS_COST_GRAPH",

	FETCH_AMMORTIZED_SPENDS_VS_COST_GRAPH_REQUESTED:
		"FETCH_AMMORTIZED_SPENDS_VS_COST_GRAPH_REQUESTED",
	FETCHED_AMMORTIZED_SPENDS_VS_COST_GRAPH:
		"FETCHED_AMMORTIZED_SPENDS_VS_COST_GRAPH",

	FETCH_APPLICATION_SPENDS_FINANCIAL_REQUESTED:
		"FETCH_APPLICATION_SPENDS_FINANCIAL_REQUESTED",
	FETCHED_APPLICATION_SPENDS_FINANCIAL:
		"FETCHED_APPLICATION_SPENDS_FINANCIAL",

	FETCH_APPLICATION_SPENDS_SETTING_REQUESTED:
		"FETCH_APPLICATION_SPENDS_SETTING_REQUESTED",
	FETCHED_APPLICATION_SPENDS_SETTING: "FETCHED_APPLICATION_SPENDS_SETTING",

	FETCH_APPLICATION_SPENDS_PAYMENT_METHOD_REQUESTED:
		"FETCH_APPLICATION_SPENDS_PAYMENT_METHOD_REQUESTED",
	FETCHED_APPLICATION_SPENDS_PAYMENT_METHOD:
		"FETCHED_APPLICATION_SPENDS_PAYMENT_METHOD",

	FETCH_SPENDS_TRANSACTION_VENDOR_REQUESTED:
		"FETCH_SPENDS_TRANSACTION_VENDOR_REQUESTED",
	FETCHED_SPENDS_TRANSACTION_VENDOR: "FETCHED_SPENDS_TRANSACTION_VENDOR",

	UPDATE_SPENDS_CHART_BUDGET: "UPDATE_SPENDS_CHART_BUDGET",
	FETCH_APP_ABOUT_SIMILAR_APPS_REQUESTED:
		"FETCH_APP_ABOUT_SIMILAR_APPS_REQUESTED",
	FETCHED_APP_ABOUT_SIMILAR_APPS: "FETCHED_APP_ABOUT_SIMILAR_APPS",

	FETCH_ALTERNATE_APP_FEATURES_REQUESTED:
		"FETCH_ALTERNATE_APP_FEATURES_REQUESTED",
	FETCHED_ALTERNATE_APP_FEATURES: "FETCHED_ALTERNATE_APP_FEATURES",

	FETCH_ALTERNATE_APPS_REQUESTED: "FETCH_ALTERNATE_APPS_REQUESTED",
	FETCHED_ALTERNATE_APPS: "FETCHED_ALTERNATE_APPS",

	FETCH_APP_OVERVIEW_SOURCES_REQUESTED:
		"FETCH_APP_OVERVIEW_SOURCES_REQUESTED",
	FETCHED_APP_OVERVIEW_SOURCES: "FETCHED_APP_OVERVIEW_SOURCES",

	FETCH_APP_OVERVIEW_LINKS_REQUESTED: "FETCH_APP_OVERVIEW_LINKS_REQUESTED",
	FETCHED_APP_OVERVIEW_LINKS: "FETCHED_APP_OVERVIEW_LINKS",

	FETCH_APP_OVERVIEW_METRIC_REQUESTED: "FETCH_APP_OVERVIEW_METRIC_REQUESTED",
	FETCHED_APP_OVERVIEW_METRIC: "FETCHED_APP_OVERVIEW_METRIC",

	FETCH_APP_OVERVIEW_NOTES_REQUESTED: "FETCH_APP_OVERVIEW_NOTES_REQUESTED",
	FETCHED_APP_OVERVIEW_NOTES: "FETCHED_APP_OVERVIEW_NOTES",
	ADD_OVERVIEW_NOTE: "ADD_OVERVIEW_NOTE",
	UPDATE_OVERVIEW_NOTE: "UPDATE_OVERVIEW_NOTE",
	DELETE_OVERVIEW_NOTE: "DELETE_OVERVIEW_NOTE",
	UPDATE_NOTE_LIST: "UPDATE_NOTE_LIST",

	ADD_APP_OVERVIEW_LINK_REQUESTED: "ADD_APP_OVERVIEW_LINK_REQUESTED",
	ADDED_APP_OVERVIEW_LINK: "ADD_APP_OVERVIEW_LINK",

	UPDATE_APP_OVERVIEW_LINK_REQUESTED: "UPDATE_APP_OVERVIEW_LINK_REQUESTED",
	UPDATED_APP_OVERVIEW_LINK: "UPDATED_APP_OVERVIEW_LINK",

	DELETE_APP_OVERVIEW_LINK_REQUESTED: "DELETE_APP_OVERVIEW_LINK_REQUESTED",
	DELETED_APP_OVERVIEW_LINK: "DELETED_APP_OVERVIEW_LINK",

	FETCH_APP_OVERVIEW_CHECKLIST_REQUESTED:
		"FETCH_APP_OVERVIEW_CHECKLIST_REQUESTED",
	FETCHED_APP_OVERVIEW_CHECKLIST: "FETCHED_APP_OVERVIEW_CHECKLIST",

	SKIP_OVERVIEW_CHECKLIST_ITEM_REQUESTED:
		"SKIP_OVERVIEW_CHECKLIST_ITEM_REQUESTED",
	SKIPPED_OVERVIEW_CHECKLIST_ITEM: "SKIPPED_OVERVIEW_CHECKLIST_ITEM",

	FETCH_APP_USER_LICENSES_REQUESTED: "FETCH_APP_USER_LICENSES_REQUESTED",
	FETCHED_APP_USER_LICENSES: "FETCHED_APP_USER_LICENSES",

	FETCH_APP_USER_OPTIMIZATION_REQUESTED:
		"FETCH_APP_USER_OPTIMIZATION_REQUESTED",
	FETCHED_APP_USER_OPTIMIZATION: "FETCHED_APP_USER_OPTIMIZATION",

	FETCH_APP_USER_STATUS_REQUESTED: "FETCH_APP_USER_STATUS_REQUESTED",
	FETCHED_APP_USER_STATUS: "FETCHED_APP_USER_STATUS",
};
