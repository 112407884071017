import React from "react";

import PropTypes from "prop-types";

import {
	BADGE_VARIANTS,
	BODY_VARIANT,
	Button,
	GREY_VARIANT,
	ImageOrNameBadge,
	PrimaryButtonVariant,
	TagName,
	Tooltip,
	Typography,
} from "@zluri/ui-components";
import IconLabel from "../../SecurityCompliance/IconLabel/IconLabel";

import primary_check from "assets/Icon_Primary.svg";
import primary_disconnected_check from "assets/Icon_primary_disconnected.svg";

import { getSourceSyncText } from "modules/applications/utils/Spends";

const SourceTabHeader = ({
	logo,
	name,
	keyword,
	is_primary,
	updatedAt,
	org_integration_status,
	org_integration_name,
	tag,
	integration_id,
	entities,
}) => {
	const source_status = getSourceSyncText(updatedAt, org_integration_status);

	const handleResolve = () => {
		if (!integration_id) window.open(`/integrations`);
		else window.open(`/integrations/${integration_id}/instance#instances`);
	};

	const isDisconnected = !source_status?.isConnected;
	return (
		<span
			className={`d-flex align-items-center flex-1 gap-10 ${
				!source_status?.isConnected &&
				"z_source_tab_header-disconnected"
			}`}
		>
			<span
				className={`d-flex align-items-center justify-content-center img-circle z_source_tab-logo`}
			>
				<ImageOrNameBadge
					url={logo}
					name={name || keyword}
					width={20}
					height={20}
					variant={BADGE_VARIANTS.ROUND}
					fontColor="#2266e2"
					fontSize={14}
					nameBadgeClasses="z_source_tab-logo_name"
				/>
			</span>
			<span className="d-flex flex-column gap-2">
				<span className="d-flex align-items-center gap-4">
					<Tooltip content={name || "Manual"} placement="top">
						<Typography
							className=" z_source_tab_name"
							variant={BODY_VARIANT.BODY_1_MEDIUM}
							color={GREY_VARIANT.SECONDARY_GREY_2}
						>
							{name || "Manual"}
						</Typography>
					</Tooltip>
					<TagName
						className={`${source_status?.className} z_source_tab-status-pill`}
						text={source_status?.label}
						borderRadius="8px"
						fontSize={9}
						fontWeight={500}
						style={{ padding: "0px" }}
					/>

					{is_primary && (
						<IconLabel
							iconLabel={{
								icon: isDisconnected
									? primary_disconnected_check
									: primary_check,
								label: "Primary",
								bg: isDisconnected ? "#EBEBEB" : "#F0DCFF",
								color: isDisconnected ? "#717171" : "#9E17FF",
								size: 10,
								fontSize: "10px",
							}}
							style={{ padding: "0px 2px" }}
						/>
					)}
					{isDisconnected && (
						<IconLabel
							iconLabel={{
								label: "Disconnected",
								bg: "#FFE9E5",
								color: "#FE6955",
								size: 10,
								fontSize: "10px",
							}}
							style={{ padding: "0px 2px" }}
						/>
					)}
				</span>
				<span className="d-flex align-items-center gap-4 z_source_tab_type">
					<Typography
						variant={BODY_VARIANT.BODY_2_MEDIUM}
						color={GREY_VARIANT.SECONDARY_GREY_3}
					>
						{tag ?? "Manual"}
					</Typography>

					{org_integration_name && (
						<>
							<span className="z_break_point"></span>
							<Tooltip content={<>{org_integration_name}</>}>
								<Typography
									className="text-truncate-medium"
									variant={BODY_VARIANT.BODY_2_MEDIUM}
									color={GREY_VARIANT.SECONDARY_GREY_3}
									style={{ maxWidth: "200px" }}
								>
									{org_integration_name}
								</Typography>
							</Tooltip>
						</>
					)}
				</span>
			</span>
			<div className="flex-1" />
			{/* <span className="d-flex flex-column ml-auto align-items-end z_source_tab_header-meta_container">
				<Typography
					variant={BODY_VARIANT.BODY_3_REGULAR}
					color={GREY_VARIANT.SECONDARY_GREY_3}
				>
					Metadata Recorded
				</Typography>
				<Typography
					variant={BODY_VARIANT.BODY_1_BOLD}
					color={GREY_VARIANT.SECONDARY_GREY_2}
				>
					{entities?.length || 0} types
				</Typography>
			</span> */}
			{!source_status?.isConnected && (
				<Button
					variant={PrimaryButtonVariant.primary_alternative}
					onClick={handleResolve}
					color="secondary_grey_3"
				>
					Resolve
				</Button>
			)}
		</span>
	);
};

export default SourceTabHeader;

SourceTabHeader.propTypes = {
	logo: PropTypes.string,
	name: PropTypes.string,
	keyword: PropTypes.string,
	is_primary: PropTypes.bool,
	updatedAt: PropTypes.string,
	org_integration_status: PropTypes.string,
	integration_id: PropTypes.object,
	tag: PropTypes.string,
	entities: PropTypes.array,
	org_integration_name: PropTypes.string,
};
