import { client, clientV2 } from "../../utils/client";

export async function getTopRow(orgId) {
	const response = await client.get(
		`organization/${orgId}/overview/top-row2`
	);
	return response.data;
}

export async function getTopRowV2(
	orgId,
	start_month = 5,
	end_month = 4,
	start_year = 2022,
	end_year = 2023
) {
	const urlSuffix = `?start_month=${start_month}&end_month=${end_month}&start_year=${start_year}&end_year=${end_year}`;
	const response = await client.get(
		`organization/${orgId}/overview/top-row2` + urlSuffix
	);
	return response.data;
}

export async function getTopRowV2Total_Spends(
	orgId,
	start_month = 5,
	end_month = 4,
	start_year = 2022,
	end_year = 2023
) {
	const urlSuffix = `?start_month=${start_month}&end_month=${end_month}&start_year=${start_year}&end_year=${end_year}`;
	const response = await client.get(
		`v2/organization/${orgId}/overview/total-spends` + urlSuffix
	);
	return response.data;
}

export async function getMiniCharts(orgId) {
	const response = await client.get(
		`organization/${orgId}/overview/minicharts2`
	);
	return response.data;
}

export async function getMiniChartsV2(
	orgId,
	start_month,
	end_month,
	start_year,
	end_year
) {
	const urlSuffix = `?start_month=${start_month}&end_month=${end_month}&start_year=${start_year}&end_year=${end_year}`;
	const response = await client.get(
		`/v2/organization/${orgId}/overview/minicharts2-topappusage` + urlSuffix
	);
	return response.data;
}

export async function getMiniChartsV2SpendsMonth(
	orgId,
	start_month,
	end_month,
	start_year,
	end_year
) {
	const urlSuffix = `?start_month=${start_month}&end_month=${end_month}&start_year=${start_year}&end_year=${end_year}`;
	const response = await client.get(
		`/v2/organization/${orgId}/overview/minicharts2-spendpermonth` +
			urlSuffix
	);
	return response.data;
}

export async function getMiniChartsV2UserSpends(
	orgId,
	start_month,
	end_month,
	start_year,
	end_year
) {
	const urlSuffix = `?start_month=${start_month}&end_month=${end_month}&start_year=${start_year}&end_year=${end_year}`;
	const response = await client.get(
		`/v2/organization/${orgId}/overview/minicharts2-userspends` + urlSuffix
	);
	return response.data;
}

export async function getMiniChartsV2ContractCosts(
	orgId,
	start_month,
	end_month,
	start_year,
	end_year
) {
	const urlSuffix = `?start_month=${start_month}&end_month=${end_month}&start_year=${start_year}&end_year=${end_year}`;
	const response = await client.get(
		`/v2/organization/${orgId}/overview/minicharts2-contractcosts` +
			urlSuffix
	);
	return response.data;
}

export async function getBudget(orgId) {
	const response = await client.get(`organization/${orgId}/overview/budget`);
	return response.data;
}

export async function getApplications(orgId, sorted_via) {
	const response = await client.get(
		`organization/${orgId}/overview/applications2`,
		{
			params: {
				sorted_via,
			},
		}
	);
	return response.data;
}
export async function getSpendTrendOverview(
	orgId,
	start_month,
	end_month,
	start_year,
	end_year
) {
	const response = await client.get(
		`organization/${orgId}/overview/spendtrend/departments2`,
		{
			params: {
				start_month,
				end_month,
				start_year,
				end_year,
			},
		}
	);
	return response.data;
}

export async function getCategoryWiseTrendOverview(
	orgId,
	start_month,
	end_month,
	start_year,
	end_year
) {
	const response = await client.get(
		`organization/${orgId}/overview/spendtrend/app-category`,
		{
			params: {
				start_month,
				end_month,
				start_year,
				end_year,
			},
		}
	);
	return response.data;
}
