import React, { useCallback, useEffect, useState } from "react";

import "./AddLevelsOfReviews.css";

import cross from "assets/reports/cross.svg";

import { isEmpty, debounce } from "underscore";

import {
	BLUE_VARIANT,
	BODY_VARIANT,
	BUTTON_VARIANT,
	Button,
	IconButton,
	ImageOrNameBadge,
	RED_VARIANT,
	SecondaryButtonVariant,
	Tooltip,
	Typography,
	theme,
} from "@zluri/ui-components";
import { SelectV2 } from "../SelectV2/SelectV2";
import { searchUsers } from "services/api/search";
import { useDispatch, useSelector } from "react-redux";
import { fetchDefaultUsersAndRoles } from "modules/AccessReview/redux/AccessReview.actions";
import {
	FALLBACK_REVIEWER,
	MAX_NUMBER_OF_LEVELS,
	PRIMARY_REVIEWER,
	defaultPropsForSelectReviewer,
} from "modules/AccessReview/constants/constants";
import ErrorWrapper from "UIComponents/Rbac/ErrorWrapper";

export default function Levels({
	handleChange,
	handleAddLevel,
	handleRemoveLevel,
	levels,
}) {
	const dispatch = useDispatch();
	const { defaultUsersAndRoles } = useSelector((state) => state.accessReview);
	const defaultUsers = defaultUsersAndRoles?.defaultUsers;
	const defaultRoles = defaultUsersAndRoles?.defaultRoles;
	const error = defaultUsersAndRoles?.error;

	const addedPrimaryReviewersRoles = levels
		?.map((level) => level?.primary_reviewer?.key)
		.filter((obj) => obj);

	const addedPrimaryReviewerUsers = levels
		?.map((level) => level?.primary_reviewer?.user_id)
		.filter((obj) => obj);

	useEffect(() => {
		if (isEmpty(defaultRoles) && isEmpty(defaultUsers)) {
			dispatch(fetchDefaultUsersAndRoles());
		}
	}, []);

	const AddLevelButton = ({ handleAddLevel, disabled }) => {
		const BtnComp = (
			<Button
				block={true}
				theme={theme}
				variant={SecondaryButtonVariant.secondary_default}
				onClick={() => handleAddLevel && handleAddLevel()}
				disabled={disabled}
			>
				<Typography
					variant={BUTTON_VARIANT.BUTTON_EXTRA_SMALL_MEDIUM}
					color={BLUE_VARIANT.PRIMARY_BLUE_DARK_1}
				>
					+ Add Level
				</Typography>
			</Button>
		);
		return disabled ? (
			<Tooltip
				placement="bottom"
				content={
					<Typography
						variant={BODY_VARIANT.BODY_2_REGULAR}
						color="white"
						className="text-center"
					>
						{`You can add a maximum of ${MAX_NUMBER_OF_LEVELS} levels of reviewers`}
					</Typography>
				}
			>
				{BtnComp}
			</Tooltip>
		) : (
			BtnComp
		);
	};

	if (error) {
		<ErrorWrapper error={error} />;
	}

	return (
		<div className="add_levels_container">
			<div className="add_levels_container_level hide-scrollbar">
				<LevelRows
					levels={levels}
					handleChange={handleChange}
					handleRemoveLevel={handleRemoveLevel}
					defaultRoles={defaultRoles}
					defaultUsers={defaultUsers}
					addedPrimaryReviewerUsers={addedPrimaryReviewerUsers}
					addedPrimaryReviewersRoles={addedPrimaryReviewersRoles}
				/>
				<div className="add_level_btn">
					<AddLevelButton
						handleAddLevel={handleAddLevel}
						disabled={levels?.length === MAX_NUMBER_OF_LEVELS}
					/>
				</div>
			</div>
		</div>
	);
}

const RenderRoleAndUsers = ({
	isAlreadySelected,
	onOptionClick,
	option,
	reviewerType,
	isUserSelected,
	isRoleSelected,
}) => {
	return (
		<div
			onClick={(e) => {
				if (isAlreadySelected) {
					e.stopPropagation();
				} else {
					onOptionClick && onOptionClick(e, isAlreadySelected);
				}
			}}
			className={`z-selectv2-option
		${isAlreadySelected ? "z-selectv2-option-disabled" : ""}`}
		>
			<div className="d-flex justify-content-between align-items-center">
				<div className="d-flex align-items-center">
					<ImageOrNameBadge
						url={option?.profile_img}
						name={option?.user_name}
						nameBadgeClasses="search-name-options"
						className="selected-option-img"
					/>
					<div className="d-flex flex-column bold-500 ml-2">
						<div className="font-14 black-1">
							{option?.user_name}
						</div>
						<div className="font-11 grey-1 pt-1">
							{option?.user_email}
						</div>
					</div>
				</div>
				{reviewerType === PRIMARY_REVIEWER && (
					<>
						{isUserSelected && (
							<Typography
								variant={BODY_VARIANT.BODY_3_REGULAR}
								color="#FE6955"
							>
								User cannot be selected
							</Typography>
						)}

						{isRoleSelected && (
							<Typography
								variant={BODY_VARIANT.BODY_3_REGULAR}
								color="#FE6955"
							>
								Role cannot be selected
							</Typography>
						)}
					</>
				)}
			</div>
		</div>
	);
};

const SelectReviewer = ({
	value,
	defaultRoles,
	defaultUsers,
	level,
	reviewerType,
	handleChange,
	addedPrimaryReviewerUsers,
	addedPrimaryReviewersRoles,
}) => {
	const [optionsLoading, setOptionsLoading] = useState(false);
	const [options, setOptions] = useState([]);
	const [query, setQuery] = useState("");
	const isDefault = query === "";
	const debouncedChangeHandler = useCallback(
		debounce((query, reqCancelToken) => {
			if (query || query === "") {
				searchUsers(query, reqCancelToken, true).then((res) => {
					setOptions(res?.results ?? []);
					setOptionsLoading(false);
				});
			}
		}, 500),
		[]
	);

	const handleSearch = (query) => {
		setQuery(query);
		if (query?.length) {
			setOptionsLoading(true);
			setOptions([]);
			debouncedChangeHandler(query, reviewerType);
		}
		else {
			setOptionsLoading(true);
			setOptions(defaultUsers);
			handleChange({}, { reviewerType, key: level });
		}
	};

	const renderOptions = ({ option, props, onOptionClick }) => {
		const isRoleSelected = addedPrimaryReviewersRoles?.includes(
			option?.key
		);
		const isUserSelected = addedPrimaryReviewerUsers?.includes(
			option?.user_id
		);
		const isAlreadySelected =
			reviewerType === PRIMARY_REVIEWER &&
			(isUserSelected || isRoleSelected);
		return (
			<RenderRoleAndUsers
				isAlreadySelected={isAlreadySelected}
				isRoleSelected={isRoleSelected}
				isUserSelected={isUserSelected}
				onOptionClick={onOptionClick}
				option={option}
				reviewerType={reviewerType}
				key={(option?._id || option?.user_id) + reviewerType}
			/>
		);
	};

	const defaultProps = {
		...defaultPropsForSelectReviewer,
		value: value,
		isOptionsLoading: optionsLoading,
		placeholder:
			reviewerType === PRIMARY_REVIEWER
				? "Primary Reviewer*"
				: "Fallback Reviewer*",
		onSearch: (query) => handleSearch(query),
		onChange: (obj) => handleChange(obj, { reviewerType, key: level }),
		onSearchClear: () => handleChange({}, { reviewerType, key: level }),
		optionRender: (option, props, onOptionClick) =>
			renderOptions({ option, props, onOptionClick }),
	};

	return (
		<>
			{isDefault ? (
				<SelectV2
					{...defaultProps}
					groupBy={true}
					groupByOptions={(option, props) => {
						const primaryReviewer = {
							Role: defaultRoles,
							Users: defaultUsers,
						};
						const fallbackReviewer = {
							Users: defaultUsers,
						};
						return reviewerType === PRIMARY_REVIEWER
							? primaryReviewer
							: fallbackReviewer;
					}}
					options={[]}
				/>
			) : (
				<SelectV2 {...defaultProps} groupBy={false} options={options} />
			)}
		</>
	);
};

const LevelRows = ({
	levels,
	handleChange,
	handleRemoveLevel,
	defaultRoles,
	defaultUsers,
	addedPrimaryReviewersRoles,
	addedPrimaryReviewerUsers,
}) => {
	const defaultProps = {
		defaultRoles: defaultRoles,
		defaultUsers: defaultUsers,
		handleChange: (option, additionalInfo) => {
			handleChange(option, additionalInfo);
		},
		addedPrimaryReviewerUsers: addedPrimaryReviewerUsers,
		addedPrimaryReviewersRoles: addedPrimaryReviewersRoles,
	};
	return levels?.map((levelData, idx) => (
		<div className="level_row" key={`level_${levelData?.key}`}>
			<Typography
				variant={BODY_VARIANT.BODY_2_MEDIUM}
				className="level_card"
			>
				{`Level ${idx + 1}`}
				{idx === 0 && (
					<Typography
						variant={BODY_VARIANT.BODY_2_MEDIUM}
						color={RED_VARIANT.ACCENT_RED_1}
					>
						*
					</Typography>
				)}
			</Typography>
			<div className="d-flex w-100 gap-8">
				<SelectReviewer
					{...defaultProps}
					value={[levelData?.primary_reviewer]}
					level={levelData?.key}
					reviewerType={PRIMARY_REVIEWER}
				/>
				<SelectReviewer
					{...defaultProps}
					value={[levelData?.fallback_reviewer]}
					level={levelData?.key}
					reviewerType={FALLBACK_REVIEWER}
				/>
			</div>
			{idx !== 0 ? (
				<IconButton
					icon={cross}
					onClick={() => {
						handleRemoveLevel && handleRemoveLevel(levelData?.key);
					}}
				/>
			) : (
				<span style={{ width: "30px", height: "28px" }}></span>
			)}
		</div>
	));
};
