import React from "react";
import colors from "colors.json";
import NoDataIcon from "assets/applications/Icon_No_Summary.svg";
import {
	BODY_VARIANT,
	GREY_VARIANT,
	SUB_HEADING_VARIANT,
	Typography,
	NoDataCard,
	theme,
	Tooltip,
} from "@zluri/ui-components";
import { Skeleton } from "antd";
import _ from "underscore";
import { kFormatter } from "constants/currency";

export const refreshChartOrDownloadPdf = (id, chartRef, onRefresh) => {
	switch (id) {
		case "REFRESH_CHART":
			return onRefresh();
		case "DOWNLOAD_PDF":
			return chartRef?.current?.chart?.exportChart({
				type: "application/pdf",
			});
		default:
			return null;
	}
};

export const handleNoCardData = (title, className, btnText, btnClick) => {
	const actions = btnText &&
		btnClick && [
			{
				onClick: btnClick,
				theme: theme,
				variant: "secondary-default",
				text: btnText,
				className:
					"w-100 border-0 border-radius-8 blue_tint_bg p-2 bold-500 font-10 primary-color",
			},
		];
	return (
		<NoDataCard
			className={`summary-nodata-card ${className}`}
			actions={actions}
			icon={NoDataIcon}
			iconStyle={{
				height: "20px",
				width: "20px",
			}}
			iconContainerStyle={{
				background: "none",
				width: "20px",
				height: "20px",
				padding: "0px",
			}}
			label={
				<Typography
					style={{ marginTop: "2px" }}
					variant={BODY_VARIANT.BODY_2_REGULAR}
					color={GREY_VARIANT.SECONDARY_GREY_2}
				>
					No data available
				</Typography>
			}
			title={
				<Typography
					variant={SUB_HEADING_VARIANT.SUBHEADING_2_BOLD}
					color={GREY_VARIANT.SECONDARY_GREY_1}
					className="text-center"
				>
					{title}
				</Typography>
			}
		/>
	);
};

export const SummaryCardPill = (amt, style) => {
	return (
		(amt || amt === 0) && (
			<div
				className="blue_tint_bg border-radius-8 bold-700 font-21 primary-color tooltip_for_tag"
				style={{
					...style,
					padding: "3px 8px",
				}}
			>
				{amt}
			</div>
		)
	);
};

export const renderingSkeleton = (numberOfSkeleton, style, rows = 2) => {
	return numberOfSkeleton.map((i) => (
		<div
			key={i}
			className="d-flex flex-1 flex-row border-radius-8"
			style={{
				minWidth: "244px",
				height: "111px",
				padding: "10px",
				gap: "4px",
				background: "#f6f7fa",
				...style,
			}}
		>
			<Skeleton
				active
				paragraph={{
					rows,
					style: {
						marginBottom: "0px",
					},
				}}
			/>
		</div>
	));
};

export const handleDeparmentSummary = (deptSummary) => {
	const totalDeptCount =
		deptSummary?.departments_attributed?.total_department_count;
	const charegbackDeptCount =
		deptSummary?.departments_attributed?.chargeback_department_count;
	const totalChargebackUser =
		deptSummary?.user_attributed?.total_chargback_user;
	const highestLoadDepts =
		deptSummary?.highest_load_depts?.highest_load_department;
	const highestLoadDeptNames = deptSummary?.highest_load_depts?.dept_names;
	const highestLoadSpend = deptSummary?.highest_load_users?.spend;
	const highestLoadSpendUser = deptSummary?.highest_load_users?.user_names;

	return [
		{
			isUndefined: _.isUndefined(totalDeptCount && charegbackDeptCount),
			hasNoData: charegbackDeptCount,
			title: "Departments Attributed",
			color: "accent_green",
			iconName: "",
			description: `Out of ${totalDeptCount} total departments`,
			tooltipContent:
				"Number of departments to which the chargebacks are attributed to based on the chosen chargeback setting",
		},
		{
			isUndefined: _.isUndefined(totalChargebackUser),
			title: "Users with Spend for Current FY",
			color: "accent_purple",
			tagRender: SummaryCardPill(totalChargebackUser, {
				backgroundColor: "#FFF6DC",
				color: "#C97B07",
			}),
			description: "",
			tooltipContent: `Validate the count by applying on application users page by applying the following filters only:
			spend>0,
			User Archive: False and
			User Application Archive: False`,
		},
		{
			isUndefined: _.isUndefined(deptSummary?.highest_load_depts),
			title: "Highest Spend Department",
			accent: "accent_blue",
			tagRender: (
				<HandleToolTip
					tooltipData={SummaryCardPill(
						highestLoadDeptNames?.[0] || "No Department Found",
						{
							backgroundColor: "#F0DCFF",
							color: "#7807C9",
						}
					)}
					tooltipName={highestLoadDeptNames?.[0]}
					textLength={highestLoadDeptNames?.[0]?.length}
				/>
			),
			description: highestLoadDeptNames?.[0] ? (
				<HandleToolTip
					tooltipData={`${kFormatter(
						highestLoadDepts
					)} chargeback to ${highestLoadDeptNames?.[0]}`}
					textLength={highestLoadDeptNames?.[0]?.length}
					maxWidth="200px"
				/>
			) : (
				""
			),
			tooltipContent:
				"Departments with the highest spend based on the chosen chargeback setting",
		},
		{
			isUndefined: _.isUndefined(deptSummary?.highest_load_users),
			title: "Highest Spend User",
			color: "accent_red",
			tagRender: (
				<HandleToolTip
					tooltipData={SummaryCardPill(
						`${highestLoadSpendUser?.[0]}
				${
					highestLoadSpendUser?.length > 1
						? `+ ${highestLoadSpendUser?.length - 1}`
						: ""
				}` || "No User Found",
						{
							backgroundColor: "#E7F8E8",
							color: "#009307",
						}
					)}
					tooltipName={`${highestLoadSpendUser?.[0]}
			${
				highestLoadSpendUser?.length > 1
					? `+ ${highestLoadSpendUser?.length - 1}`
					: ""
			}
			`}
					textLength={highestLoadSpendUser?.[0]?.length}
				/>
			),
			description: (
				<HandleToolTip
					tooltipData={`${kFormatter(
						highestLoadSpend
					)} chargeback to ${highestLoadSpendUser?.[0]}`}
					maxWidth="200px"
					textLength={highestLoadSpendUser?.[0]?.length}
				/>
			),
			tooltipContent:
				"Users with the highest spends based on the chosen chargeback setting",
		},
	];
};

export const getChargebackPieTotal = (depts) => {
	let total = 0;
	for (const dept of depts) {
		total += dept.spend;
	}
	return total;
};

export const getChargebackPieSeries = (depts) => {
	const total = getChargebackPieTotal(depts);
	const pieSeries = [];
	let index = 0;
	for (const dept of depts) {
		pieSeries.push({
			name: dept.dept_name,
			y: isNaN((dept.spend / total) * 100)
				? 0
				: Number(((dept.spend / total) * 100).toFixed(2)),
			spend: parseFloat(dept.spend),
			spendText: kFormatter(dept.spend),
			deptId: dept.dept_id,
			hasChildren: dept.has_children,
			color: colors.Colors[index % colors.Colors.length],
		});
		index++;
	}
	return pieSeries;
};

export const getChargebackBarSeries = (deptSpends) => {
	return deptSpends?.map((actualSpend, index) => {
		return {
			name: actualSpend?.dept_name,
			data: actualSpend?.spend_trend?.map((spendVal) => {
				return {
					y: spendVal?.spend,
					year: spendVal?.year,
					hasChildren: actualSpend?.has_children,
					name: actualSpend?.dept_name,
					deptId: actualSpend?.dept_id,
					color: colors.Colors[index % colors.Colors.length],
				};
			}),
			pointPlacement: "between",
		};
	});
};
export const HandleToolTip = ({
	tooltipName,
	tooltipData,
	maxWidth,
	textLength,
}) => {
	const node = maxWidth ? (
		<div
			style={{
				maxWidth: maxWidth,
				width: "fit-content",
				whiteSpace: "nowrap",
				overflow: "hidden",
				textOverflow: "ellipsis",
			}}
		>
			{tooltipData}
		</div>
	) : (
		tooltipData
	);

	const tooltipContent =
		_.isUndefined(tooltipName) || _.isNull(tooltipName)
			? tooltipData
			: tooltipName;
	return (
		<Tooltip
			content={
				<Typography
					color="white"
					variant={SUB_HEADING_VARIANT.SUBHEADING_2_REGULAR}
				>
					{tooltipContent}
				</Typography>
			}
			className=""
			placement="bottom"
		>
			{node}
		</Tooltip>
	);
};

export const APP_CHARGEBACKS_CHART = {
	DEPARTMENT_SPEND: "DEPARTMENT_SPEND",
	ACTUAL_SPEND: "ACTUAL_SPEND",
};

function generateCSVdataForChargebacksChart(graph, type) {
	let csvData;
	if (type === APP_CHARGEBACKS_CHART.DEPARTMENT_SPEND) {
		csvData = graph?.series?.[0]?.data?.map((series) => {
			const csvRow = {};
			csvRow["Department"] = series?.name;
			csvRow["Spend"] = series?.spend;
			csvRow["Total Percentage"] = series?.y;

			return csvRow;
		});
	} else if (type === APP_CHARGEBACKS_CHART.ACTUAL_SPEND) {
		csvData = graph?.series?.map((department) => {
			const csvRow = {};
			csvRow["Department"] = department?.name;
			graph?.xAxis?.categories?.forEach((date, index) => {
				if (date) {
					csvRow[date] = department?.data?.[index]?.y ?? 0;
				}
			});
			return csvRow;
		});
	}

	const headers = Object?.keys(csvData?.[0]);

	const csv = [
		headers,
		...csvData?.map((obj) =>
			headers.map((header) => {
				const value = obj[header];
				return value;
			})
		),
	];
	return csv;
}

export function downloadCSV(data, fileName, type) {
	const csvData = generateCSVdataForChargebacksChart(data, type);
	try {
		let csvContent = "data:text/csv;charset=utf-8,";
		csvData.forEach((row) => {
			const csvRow = row?.join(",");
			csvContent += csvRow + "\r\n";
		});

		const encodedUri = encodeURI(csvContent);
		const link = document.createElement("a");
		link.setAttribute("href", encodedUri);
		link.setAttribute("download", `${fileName}.csv`);
		document.body.appendChild(link);

		link.click();
		document.body.removeChild(link);
	} catch (error) {
		console.error("Error occurred during CSV download:", error);
	}
}
