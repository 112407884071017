import IconAuthorizationStatus from "assets/common/icon_authorization_status.svg";
import IconArrowRight from "assets/common/icon_arrow_right.svg";
import IconCentrallyManaged from "assets/common/icon_centrally_manage.svg";
import IconUserWindows from "assets/common/Icon_User_Window.svg";
import IconUserFinance from "assets/common/Icon_User_Finance.svg";
import IconITOwner from "assets/common/Icon_User_IT.svg";
import IconStatus from "assets/common/Icon_Status.svg";
import IconTag from "assets/common/Icon_Tag.svg";
import IconApplicationOutline from "assets/common/Icon_Application_Outline.svg";
import IconArchive from "assets/common/Icon_Archive.svg";
import IconIndividuallyManaged from "assets/common/authorization-status/Icon_IndividuallyManaged.svg";
import IconRestricted from "assets/common/authorization-status/Icon_Restricted.svg";
import IconTeamManaged from "assets/common/authorization-status/team-managed.svg";
import IconUnManaged from "assets/common/authorization-status/unmanaged.svg";
import IconAppTypeApplication from "assets/common/Icon_AppType_Application.svg";
import IconAppTypeBrowser from "assets/common/application-type/Icon_AppType_Browser.svg";
import IconAppTypeWebsite from "assets/common/application-type/Icon_AppType_Website.svg";
import IconAppTypeOther from "assets/common/application-type/Icon_AppType_Other.svg";
import IconAppTypeDevice from "assets/common/application-type/Icon_AppType_Device.svg";
import IconSearch from "assets/icons/blue-search.svg";
import IconInactiveStatus from "assets/applications/inactivecheck.svg";
import IconActiveStatus from "containers/v2table/BulkEdit/assets/Icon_Active.svg";
import IconApprove from "assets/v2tables/square_green.svg";
import IconDowngrade from "assets/v2tables/square_amber.svg";
import IconRevoke from "assets/v2tables/square_red.svg";
import successSvg from "containers/v2table/BulkEdit/assets/greenCheckCircle.svg";
import failureSvg from "containers/v2table/BulkEdit/assets/unAuthorizedRed.svg";
import delegateReview from "containers/v2table/BulkEdit/assets/DelegateReview.svg";
import { applicationsBulkEdit } from "services/api/applications";
import { searchUsers } from "services/api/search";
import {
	bulkEditAccessRowReviewer,
	bulkEditAccessRowReviewerEmpView,
	bulkEditUserReviewStatus,
	bulkEditUserReviewStatusEmpView,
} from "modules/AccessReview/service/AccessReview.service";

import ImageAndText from "./components/ImageAndText";
import Search from "./components/Search";
import { bulkEditTasks } from "modules/TaskManagement/service/TaskManagement.api";
import { searchUsersEmployeeDashboard } from "services/api/employeeDashboard";
import { SearchSelectBulkEdit } from "modules/applications/components/AppLicenses/MapLicensesBulkEdit";
import { getBulkMapLicenses } from "modules/applications/service/AppLicenses.api";
import { bulkEditallApplications } from "services/api/applicationsv2";
import {
	bulkEditAppUsers,
	fetchInitialRoles,
	searchUserAppRoles,
} from "modules/applications/service/Applications.api";
import TagsBulkEdit from "./components/Tag";

export const componentMapper = {
	image_and_text: ImageAndText,
	search: Search,
	search_select: SearchSelectBulkEdit,
	tag: TagsBulkEdit,
};

export const IconMapper = {
	auth_status: IconAuthorizationStatus,
	arrow_right: IconArrowRight,
	centrally_managed: IconCentrallyManaged,
	user_windows: IconUserWindows,
	user_finance: IconUserFinance,
	it_owner: IconITOwner,
	status: IconStatus,
	tag: IconTag,
	app_outline: IconApplicationOutline,
	archive: IconArchive,
	individually_managed: IconIndividuallyManaged,
	restricted: IconRestricted,
	team_managed: IconTeamManaged,
	unmanaged: IconUnManaged,
	app_type_application: IconAppTypeApplication,
	app_type_browser: IconAppTypeBrowser,
	app_type_website: IconAppTypeWebsite,
	app_type_other: IconAppTypeOther,
	app_type_device: IconAppTypeDevice,
	search: IconSearch,
	inactive: IconInactiveStatus,
	active: IconActiveStatus,
	approve: IconApprove,
	downgrade: IconDowngrade,
	revoke: IconRevoke,
	delegate: delegateReview,
};

export const ApiMapper = {
	allApplications: {
		app_bulk_edit: bulkEditallApplications,
		search_users: searchUsers,
	},
	managed: {
		app_bulk_edit: bulkEditallApplications,
		search_users: searchUsers,
	},
	unmanaged: {
		app_bulk_edit: bulkEditallApplications,
		search_users: searchUsers,
	},
	restricted: {
		app_bulk_edit: bulkEditallApplications,
		search_users: searchUsers,
	},
	needsReview: {
		app_bulk_edit: bulkEditallApplications,
		search_users: searchUsers,
	},
	application: {
		app_bulk_edit: applicationsBulkEdit,
		search_users: searchUsers,
	},
	app_review_users: {
		review_actions_bulk_edit: bulkEditUserReviewStatus,
		search_users: searchUsers,
		change_reviewer_bulk_edit: bulkEditAccessRowReviewer,
	},
	app_users_emp_view: {
		review_actions_bulk_edit: bulkEditUserReviewStatusEmpView,
		search_users: searchUsersEmployeeDashboard,
		change_reviewer_bulk_edit: bulkEditAccessRowReviewerEmpView,
	},
	pending: {
		bulk_edit_tasks: bulkEditTasks,
	},
	app_map_licenses: {
		license_select: getBulkMapLicenses,
	},
	application_users: {
		bulk_edit_app_users: bulkEditAppUsers,
		search_role: searchUserAppRoles,
		search_role_initial: fetchInitialRoles,
	},
};

export const INVALID_DATA = "Invalid data";

export const toastProps = {
	success: {
		icon: successSvg,
		header: "Bulk Edit Successful",
		subheader:
			"All records have been updated successfully. The changes will reflect in some time.",
	},
	failure: {
		icon: failureSvg,
		header: "Bulk Edit Failed",
		subheader: "Please try again after some time or contact Zluri support.",
	},
};
