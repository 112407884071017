import { useEffect, useMemo, useState } from "react";
import { flatten, omit, reduce, values } from "underscore";

const useMultiLevelFilterOptions = (categorizedOptions, selectedCategory) => {
	const treeData = useMemo(() => {
		if (
			selectedCategory &&
			selectedCategory !== "all" &&
			categorizedOptions
		) {
			return (
				categorizedOptions?.find((op) => op?.key === selectedCategory)
					?.options || []
			);
		} else {
			const opt = [];
			categorizedOptions?.map((op) => opt.push(...(op?.options || [])));
			return opt?.filter((op) => !op?.length);
		}
	}, [selectedCategory, categorizedOptions]);
	return { options: treeData };
};

const useSelectedOptions = (category, defaultOptions) => {
	const [selectedOptions, setSelectedOptions] = useState({
		all: {},
	});

	useEffect(() => {
		setSelectedOptions({
			...defaultOptions,
			all: {
				keys: reduce(
					flatten(values(defaultOptions)),
					(acc, curr = {}) => [...acc, ...curr?.keys],
					[]
				),
			},
		});
	}, [defaultOptions]);

	const handleSelection = ({ keys, info, reset = false, onCheck }) => {
		const options = reset
			? {}
			: getUpdatedSelectedOptions(keys, info, selectedOptions, category);
		onCheck &&
			onCheck(
				flatten(options?.all?.nodes)?.map((node) => node?.data?.apiData)
			);
		setSelectedOptions(options);
	};
	return { selectedOptions, handleSelection };
};

const getUpdatedSelectedOptions = (keys, info, options, selectedCategory) => {
	const category =
		selectedCategory === "all" ? info?.node?.category : selectedCategory;

	const category_values = {
		keys: info?.checkedNodes
			?.filter((node) => node.category === category && !node?.children)
			?.map((node) => node?.key),
		count: info?.checkedNodes
			?.filter((node) => node.category === category && !node?.children)
			?.map((node) => node?.key)?.length,
		nodes: info?.checkedNodes?.filter(
			(node) => node.category === category && !node?.children
		),
	};
	const obj = {
		...options,
		[category]: { ...category_values },
	};

	const allOptions = reduce(
		Object.keys(omit(obj, "all")),
		(acc, key) => ({
			...acc,
			keys: [...acc.keys, ...(obj[key]?.keys || [])],
			nodes: [...acc.nodes, ...(obj[key]?.nodes || [])],
			count: acc.count + (obj[key]?.count || 0),
		}),
		{
			keys: [],
			nodes: [],
			count: 0,
		}
	);

	return {
		...options,
		...obj,
		all: allOptions,
	};
};

export { useMultiLevelFilterOptions, useSelectedOptions };
