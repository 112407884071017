import React from "react";
import PropTypes from "prop-types";
import {
	BLUE_VARIANT,
	BODY_VARIANT,
	Popover,
	TagName,
	Typography,
} from "@zluri/ui-components";

import { isNullOrUndefined } from "modules/applications/utils/appAbout.utils";
import { LongTextTooltip } from "../LongTextTooltip/LongTextTooltip";

export function AppAboutTags({ tags, showNumberOfTags = 2, ...rest }) {
	if (isNullOrUndefined(tags)) return null;

	const firstTwoTags = tags?.slice(0, showNumberOfTags);

	return (
		<div className="d-flex gap-4 align-items-center">
			<div className="d-flex gap-4 align-items-center">
				{firstTwoTags?.map((tag) => (
					<TagName
						key={tag}
						text={
							<div
								className="d-flex p-1 border-radius-8 light-blue-bg"
								style={{ border: "1px solid #B1C8F3" }}
							>
								<LongTextTooltip
									color="#5287E8"
									style={{ fontSize: "10px" }}
									maxWidth="100px"
									text={tag}
								/>
							</div>
						}
						borderRadius={8}
					/>
				))}
			</div>
			{tags?.length > showNumberOfTags && (
				<Popover
					arrow
					content={rest?.clickPopoverContent}
					placement="bottomRight"
					trigger={["click"]}
				>
					<Typography
						color={BLUE_VARIANT.PRIMARY_BLUE_DARK_1}
						variant={BODY_VARIANT.BODY_2_MEDIUM}
						className="cursor-pointer px-1"
					>
						+{tags.length - showNumberOfTags}
					</Typography>
				</Popover>
			)}
		</div>
	);
}

AppAboutTags.propTypes = {
	tags: PropTypes.array,
	showNumberOfTags: PropTypes.number,
};
