import React, { useEffect, useState } from "react";
import { getCellRenderer } from "../componentFactory";
import inactive from "assets/agents/inactive.svg";
import ToggleSwitch from "react-switch";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "./popovers.css";
import { Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import NewDatePicker from "UIComponents/DatePicker/NewDatePicker";
import calendar from "assets/v2tables/calendar.svg";
import name from "assets/v2tables/license-name.svg";
import cost from "assets/v2tables/license-cost.svg";
import range from "assets/v2tables/range.svg";
import { addLicensetoAnApplication } from "services/api/applications";
import { useDispatch } from "react-redux";
import { Typography } from "@zluri/ui-components";

function getProgressStyle(value) {
	if (value >= 75) {
		return {
			trailColor: "rgba(254, 105, 85, 0.2)",
			pathColor: "#FE6955",
		};
	} else if (value >= 25) {
		return {
			trailColor: "rgba(255, 162, 23, 0.2)",
			pathColor: "#FFA217",
		};
	} else {
		return {
			trailColor: "rgba(130, 167, 237, 0.2)",
			pathColor: "#82A7ED",
		};
	}
}
export function LicenseCustomViewRenderer({
	data,
	component,
	setShowPopover,
	...props
}) {
	let style = getProgressStyle(
		(data?.app_assigned_license_count / data?.app_total_license_count) * 100
	);

	return (
		<div
			className="w-100"
			onClick={() => {
				if (component?.openPopoverInViewComponent) setShowPopover(true);
			}}
		>
			<div className="d-flex align-items-center justify-content-between">
				<div style={{ height: "20px", width: "20px" }}>
					<CircularProgressbar
						value={data?.app_assigned_license_count}
						maxValue={data?.app_total_license_count}
						styles={buildStyles(style)}
						strokeWidth={20}
					/>
				</div>
				<div className="d-flex flex-column ml-1">
					<Typography
						variant="body_1_regular"
						color="secondary_grey_1"
						style={{ textAlign: "right" }}
					>
						{data?.app_total_license_count}
					</Typography>
					{data?.app_assigned_license_count ? (
						<div className="d-flex">
							<Typography
								variant="body_2_bold"
								color={
									data?.app_total_license_count -
										data?.app_assigned_license_count >
									0
										? "secondary_grey_3"
										: "accent_red_1"
								}
								className="mr-1"
							>
								{data?.app_total_license_count -
									data?.app_assigned_license_count >
								0
									? data?.app_assigned_license_count
									: data?.app_assigned_license_count -
									  data?.app_total_license_count}
							</Typography>
							<Typography
								variant="body_2_regular"
								color={
									data?.app_total_license_count -
										data?.app_assigned_license_count >
									0
										? "secondary_grey_3"
										: "accent_red_1"
								}
							>
								{data?.app_total_license_count -
									data?.app_assigned_license_count >
								0
									? "Assigned"
									: "Excess"}
							</Typography>
						</div>
					) : (
						<></>
					)}
				</div>
			</div>
		</div>
	);
}

export function LicenseCustomPopoverRenderer({
	data,
	component,

	setShowPopover,
	...props
}) {
	const history = useHistory();
	const [showAddFlow, setShowAddFlow] = useState(true);
	const [licenses, setLicenses] = useState([]);
	const [license, setLicense] = useState({});
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	return (
		<>
			{data?.app_unassigned_license_count < 0 ? (
				<div className="d-flex flex-column" style={{ width: "231px" }}>
					<div className="v2table__sources-header">
						<div
							className="d-flex align-items-center"
							style={{ gap: "4px" }}
						>
							<img src={inactive} width={10}></img>
							<div className="font-9 ">
								More licenses than available have been assigned
							</div>
						</div>
					</div>
					<div
						className="d-flex align-items-center"
						style={{ gap: "9px", padding: "8px" }}
					>
						<ToggleSwitch
							height={16}
							width={28}
							checked={true}
							onChange={() => {}}
							checkedIcon={false}
							uncheckedIcon={false}
							onColor={"#2266E2"}
						/>
						<div className="font-10">Auto-Increment</div>
					</div>
					<div style={{ padding: "0px 8px 9px" }}>
						<div
							className="d-flex align-items-center justify-content-center w-100  font-10 cursor-pointer primary-color"
							style={{
								height: "32px",
								background: "#E8F0FC",
								borderRadius: "8px",
							}}
							onClick={() => history.push("/contract/new")}
						>
							Add More Licenses
						</div>
					</div>
				</div>
			) : data?.app_total_license_count === 0 ? (
				<>
					<div
						className="d-flex flex-column"
						style={{ width: "230px", padding: "8px 16px" }}
					>
						<div className="grey bold-700 font-10">
							Add Licenses
						</div>
						{Array.isArray(licenses) && licenses.length > 0 && (
							<>
								<div className="d-flex flex-column">
									{licenses.map((el) => (
										<div
											className="d-flex align-items-center"
											style={{
												borderTop: "1px solid #F6F7FA",
												paddingTop: "9px",
											}}
										>
											<div className="d-flex flex-column">
												<div className="font-10 ">
													{el.license_name}
												</div>
												<div className="font-8 grey-1 mt-1">{`${el.license_quantity} licenses`}</div>
											</div>
											<div className="ml-auto grey-1 font-9">{`$${el.license_cost} /month`}</div>
										</div>
									))}
								</div>
							</>
						)}
						{showAddFlow && (
							<>
								<div
									className="d-flex align-items-center"
									style={{
										border: "1px solid #717171",
										padding: "6px 11px",
										borderRadius: "8px",
									}}
								>
									<img src={name}></img>
									<Form.Control
										type="text"
										className="chip__input license_name_v2_input_box"
										placeholder={`License Name`}
										value={license.license_name}
										onChange={(e) => {
											setLicense({
												...license,
												license_name: e.target.value,
											});
										}}
									/>
								</div>
								<div
									className="d-flex align-items-center"
									style={{
										border: "1px solid #717171",
										padding: "6px 11px",
										borderRadius: "8px",
										marginTop: "6px",
									}}
								>
									<img src={cost}></img>
									<Form.Control
										type="number"
										className="chip__input license_name_v2_input_box"
										placeholder={`Cost per month`}
										value={license.license_cost}
										onChange={(e) => {
											setLicense({
												...license,
												license_cost: e.target.value,
											});
										}}
									/>
								</div>

								<div
									className="d-flex align-items-center"
									style={{ gap: "4px" }}
								>
									<NewDatePicker
										// key={`${license.start_date}`}
										placeholder="From"
										onChange={(date) =>
											setLicense({
												...license,
												start_date: date,
											})
										}
										calendarClassName="rangefilter-calendar"
										calendarContainerClassName="schedule-date-calendar"
										style={{
											width: "96px",
											height: "28px ",
											border: "1px solid #717171 ",
											minWidth: "50px",
											marginTop: "6px",
										}}
										value={license.start_date}
										icon={calendar}
										iconWidth={15}
										iconHeight={15}
									/>
									<NewDatePicker
										// key={`${license.end_date}`}
										placeholder="To"
										onChange={(date) =>
											setLicense({
												...license,
												end_date: date,
											})
										}
										calendarClassName="rangefilter-calendar"
										calendarContainerClassName="schedule-date-calendar"
										style={{
											width: "96px",
											height: "28px ",
											border: "1px solid #717171 ",
											minWidth: "50px",
											marginTop: "6px",
										}}
										value={license.end_date}
										icon={calendar}
										iconWidth={15}
										iconHeight={15}
									/>
								</div>
								<div
									className="d-flex align-items-center"
									style={{
										border: "1px solid #717171",
										padding: "6px 11px",
										borderRadius: "8px",
										marginTop: "6px",
									}}
								>
									<img src={range}></img>
									<Form.Control
										type="number"
										className="chip__input license_name_v2_input_box"
										placeholder={`Quantity`}
										value={license.license_quantity}
										onChange={(e) => {
											setLicense({
												...license,
												license_quantity:
													e.target.value,
											});
										}}
									/>
								</div>

								<div
									className="d-flex align-items-center"
									style={{ gap: "4px", marginTop: "7px" }}
								>
									<div
										className="d-flex align-items-center justify-content-center w-100 grey-1 font-10 cursor-pointer"
										onClick={() => {
											setLicense({});
										}}
										style={{
											height: "32px",
											background: "#EBEBEB",
											borderRadius: "8px",
										}}
									>
										Cancel
									</div>
									<div
										className="d-flex align-items-center justify-content-center w-100  font-10 cursor-pointer"
										style={{
											height: "32px",
											background: "#5287E8",
											borderRadius: "8px",
											color: "white",
										}}
										onClick={() => {
											setLoading(true);
											let tempReqBody = {
												name: license.license_name,
												cost: license.license_cost,
												quantity:
													license.license_quantity,
												currency: "USD",
												start_date: license.start_date,
												end_date: license.end_date,
											};
											addLicensetoAnApplication(
												data?.app_id,
												tempReqBody
											)
												.then((res) => {
													if (res) {
														dispatch({
															type: "RECORD_UPDATED",
															payload: {
																index: props.rowIndex,
																value: {
																	app_total_license_count: 55,
																},
																entity: props.entity,
															},
														});
														setLoading(false);
														setShowAddFlow(false);
														setLicense({});
														setLicenses([
															...licenses,
															license,
														]);
													}
												})
												.catch((err) => {
													console.log(err);
													setLoading(false);
												});
										}}
									>
										Confirm
									</div>
								</div>
							</>
						)}
						{!showAddFlow && (
							<>
								<div
									className="d-flex align-items-center justify-content-center w-100  font-10 cursor-pointer primary-color mt-1"
									style={{
										height: "32px",
										background: "#E8F0FC",
										borderRadius: "8px",
									}}
									onClick={() => setShowAddFlow(true)}
								>
									Add More Licenses
								</div>
							</>
						)}
					</div>
				</>
			) : data.app_unassigned_license_count ===
			  data?.app_total_license_count ? (
				<>
					<div
						className="d-flex flex-column"
						style={{ width: "247px" }}
					>
						<div className="v2table__sources-header">
							<div className="font-10 bold-700">
								<span className="grey-1">Licenses are</span>
								<span className="red ml-1">Unassigned</span>
							</div>
						</div>
						<div style={{ padding: "0px 8px 9px" }}>
							<div
								className="d-flex align-items-center justify-content-center w-100 primary-color font-10 cursor-pointer"
								style={{
									height: "32px",
									background: "#E8F0FC",
									borderRadius: "8px",
								}}
								onClick={() =>
									history.push(
										`/applications/${data?._id}#users`
									)
								}
							>
								Map Licenses
							</div>
						</div>
					</div>
				</>
			) : (
				<>
					<div
						className="d-flex flex-column"
						style={{ width: "247px" }}
					>
						<div
							className="d-flex align-items-center "
							style={{ padding: "10px 16px" }}
						>
							<div className="font-10 ">Assigned</div>
							<div className="ml-auto font-9">
								{data?.app_assigned_license_count}
							</div>
						</div>
						<hr style={{ margin: "0px 4px" }}></hr>
						<div
							className="d-flex align-items-center"
							style={{ padding: "10px 16px" }}
						>
							<div className="font-10 ">Unmapped</div>
							<div className="ml-auto font-9">
								{data?.app_unassigned_license_count}
							</div>
						</div>
						<div
							className="d-flex align-items-center"
							style={{
								padding: "10px 16px",

								background: "#F6F7FA",
								borderRadius: "8px",
							}}
						>
							<div className="bold-700 font-10">Total</div>
							<div className="ml-auto bold-700 font-9">
								{data?.app_total_license_count}
							</div>
						</div>
					</div>
				</>
			)}
		</>
	);
}
