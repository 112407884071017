import { searchUsers } from "../../../services/api/search";
import { TriggerIssue } from "../../../utils/sentry";
import { ACTION_TYPE as ACTION_TYPE_CONSTANTS } from "../constants/constant";
import {
	WorkflowModel,
	Completed,
	CompletedV2,
	CompletedApps,
	WorkFlowApplicationModel,
	WorkFlowActionModel,
	AppDescriptionModel,
	AllManualTaskTemplatesModel,
	WorkFlowOffboardingDashboardModel,
	WorkFlowAutomationRuleResponseModel,
	WorkFlowAutomationRuleRequestModel,
	AppAutomationRuleResponseModel,
	AppAutomationRuleRequestModel,
} from "../model/model";
import { Integration } from "../../integrations/model/model";
import {
	editWorkflowDetails,
	editWorkflowNodesService,
	getWorkflow,
	getMostUsedTemplatesService,
	getAllCompletedWorkflowsServiceV2,
	getAllDraftsService,
	getAutomationRulesService,
	getAutomationRuleService,
	getTopFiveDraftsService,
	getUserslistServiceV2,
	getListOfRuns,
	getSummarisedRunLogData,
	fetchRecommendedApps,
	fetchRecommendedActions,
	getRawRunLogData,
	saveApplicationToWorkflow,
	saveActionToWorkflow,
	deleteApplicationFromWorkflow,
	deleteActionFromWorkflow,
	updateActionFromWorkflow,
	editApplicationInWorkflow,
	getAppDescriptionAPI,
	getAllManualTaskTemplatesAPI,
	getManualTaskTemplateByIdAPI,
	getManualTaskTemplateDataAPI,
	saveStaticApplicationToWorkflow,
	getIntegrationAccountsAPI,
	saveActionToWorkflowStaticNode,
	updateActionFromWorkflowStaticNode,
	getOffboardingTasksAPI,
	markOffboardingTaskAsCompleteAPI,
	signOffboardingDeclarationAPI,
	actionValidation,
	deleteAppFromMultiNodeService,
	seperateAppFromMultiNodeService,
	getPendingAppListAPI,
	addPendingAppListAPI,
	updateAutomationRulesService,
	updateAutomationRuleService,
	updateOrderOfRule,
	patchAutomationStatusService,
	getAllPlaybookWorkflowsServiceV2,
	getAllScheduledRunsServiceV2,
	compileWorkflowDraft,
	getIntegrationAccountsV2API,
	changeIntegrationAccountAPI,
	editWorkflowetailsviaTemplate,
	searchUserswithPlaybookId,
	getAllRulesV2WithoutTotalRuns,
	updateAppAutomationRuleService,
	getPlaybookVariablesAPI,
} from "../service/api";
import { getRulesFilterDatawithPlaybookId } from "modules/applications/components/automation/utils/automation.util";
import { getAppRules } from "modules/applications/components/automation/service/automation-api";

const ACTION_TYPE = {
	GET_WORKFLOW: "GET_WORKFLOW",
	CLEAR_WORKFLOW: "CLEAR_WORKFLOW",
	EDIT_WORKFLOW_DETAILS: "EDIT_WORKFLOW_DETAILS",
	SET_APP_ORG_ID: "SET_APP_ORG_ID",
	GET_RECOMMENDED_APPS: "GET_RECOMMENDED_APPS",
	GET_RECOMMENDED_ACTIONS: "GET_RECOMMENDED_ACTIONS",
	EDIT_WORKFLOW_NODE: "EDIT_WORKFLOW_NODE",
	ADD_APPLICATION_TO_WORKFLOW: "ADD_APPLICATION_TO_WORKFLOW",
	EDIT_APPLICATION_TO_WORKFLOW: "EDIT_APPLICATION_TO_WORKFLOW",
	ADD_ACTION_TO_WORKFLOW: "ADD_ACTION_TO_WORKFLOW",
	UPDATE_ACTION_TO_WORKFLOW: "UPDATE_ACTION_TO_WORKFLOW",
	DELETE_APPLICATION_TO_WORKFLOW: "DELETE_APPLICATION_TO_WORKFLOW",
	DELETE_ACTION_TO_WORKFLOW: "DELETE_ACTION_TO_WORKFLOW",
	DELETE_APP_FROM_MULTI_NODE: "DELETE_APP_FROM_MULTI_NODE",
	SEPARATE_APP_FROM_MULTI_NODE: "SEPARATE_APP_FROM_MULTI_NODE",
	WORKFLOWS_GET_ALL_TEMPLATES: "WORKFLOWS_GET_ALL_TEMPLATES",
	WORKFLOWS_GET_ALL_TEMPLATES_ERROR: "WORKFLOWS_GET_ALL_TEMPLATES_ERROR",
	WORKFLOWS_GET_MOST_USED_TEMPLATES: "WORKFLOWS_GET_MOST_USED_TEMPLATES",
	WORKFLOWS_GET_ALL_DRAFTS: "WORKFLOWS_GET_ALL_DRAFTS",
	WORKFLOWS_GET_AUTOMATION_RULES: "WORKFLOWS_GET_AUTOMATION_RULES",
	WORKFLOWS_UPDATE_AUTOMATION_RULES: "WORKFLOWS_UPDATE_AUTOMATION_RULES",
	WORKFLOWS_UPDATE_AUTOMATION_RULE: "WORKFLOWS_UPDATE_AUTOMATION_RULE",
	WORKFLOWS_GET_AUTOMATION_RULE: "WORKFLOWS_GET_AUTOMATION_RULE",
	UPDATE_APP_AUTOMATION_RULES: "UPDATE_APP_AUTOMATION_RULES",
	UPDATE_APP_AUTOMATION_RULE: "UPDATE_APP_AUTOMATION_RULE",
	CLEAR_APP_AUTOMATION_RULE_DATA: "CLEAR_APP_AUTOMATION_RULE_DATA",
	GET_APP_AUTOMATION_RULES: "GET_APP_AUTOMATION_RULES",
	SET_EDIT_AUTOMATION_RULE: "SET_EDIT_AUTOMATION_RULE",
	SET_EDIT_APP_AUTOMATION_RULE: "SET_EDIT_APP_AUTOMATION_RULE",
	GET_APP_AUTOMATION_RULE: "GET_APP_AUTOMATION_RULE",
	WORKFLOWS_GET_TOP_FIVE_DRAFTS: "WORKFLOWS_GET_TOP_FIVE_DRAFTS",
	WORKFLOWS_GET_ALL_COMPLETED_WORKFLOWS:
		"WORKFLOWS_GET_ALL_COMPLETED_WORKFLOWS",
	WORKFLOWS_GET_IN_PROGRESS_WORKFLOWS: "WORKFLOWS_GET_IN_PROGRESS_WORKFLOWS",
	WORKFLOWS_GET_ONBOARDING_USERS: "WORKFLOWS_GET_ONBOARDING_USERS",
	WORKFLOWS_GET_ACTIONS_BY_WORKFLOW_ID:
		"WORKFLOWS_GET_ACTIONS_BY_WORKFLOW_ID",
	WORKFLOWS_GET_SEARCHED_USERS: "WORKFLOWS_GET_SEARCHED_USERS",
	SET_EDIT_ACTION: "SET_EDIT_ACTION",
	SET_EDIT_APP_ID: "SET_EDIT_APP_ID",
	GET_LIST_OF_RUNS: "GET_LIST_OF_RUNS",
	GET_SUMMARISED_RUN_LOGS: "GET_SUMMARISED_RUN_LOGS",
	GET_RAW_RUN_LOGS: "GET_RAW_RUN_LOGS",
	UPDATE_WORKFLOW: "UPDATE_WORKFLOW",
	SET_WORKFLOW_EXECUTED: "SET_WORKFLOW_EXECUTED",
	CLEAR_WORKFLOW_LOGS: "CLEAR_WORKFLOW_LOGS",
	CLEAR_LIST_OF_WORKFLOWS: "CLEAR_LIST_OF_WORKFLOWS",
	GET_APP_DESCRIPTION: "GET_APP_DESCRIPTION",
	GET_ALL_MANUAL_TASK_TEMPLATES: "GET_ALL_MANUAL_TASK_TEMPLATES",
	GET_MANUAL_TASK_TEMPLATE_BY_ID: "GET_MANUAL_TASK_TEMPLATE_BY_ID",
	GET_MANUAL_TASK_TEMPLATE_DATA: "GET_MANUAL_TASK_TEMPLATE_DATA",
	CLEAR_MANUAL_TASK_TEMPLATE_DATA: "CLEAR_MANUAL_TASK_TEMPLATE_DATA",
	GET_INTEGRATION_ACCOUNTS: "GET_INTEGRATION_ACCOUNTS",
	REMOVE_INTEGRATION_ACCOUNT: "REMOVE_INTEGRATION_ACCOUNT",
	GET_OFFBOARDING_TASKS_LIST: "GET_OFFBOARDING_TASKS_LIST",
	MARK_OFFBOARDING_TASK_COMPLETE: "MARK_OFFBOARDING_TASK_COMPLETE",
	SIGN_OFFBOARDING_DECLARATION_TASK: "SIGN_OFFBOARDING_DECLARATION_TASK",
	CLEAR_OFFBOARDING_TASKS_LIST: "CLEAR_OFFBOARDING_TASKS_LIST",
	ACTION_SCOPE_VALIDATIONS: "ACTION_SCOPE_VALIDATIONS",
	GET_PENDING_APP_LIST: "GET_PENDING_APP_LIST",
	ADD_PENDING_APP_TO_WORKFLOW: "ADD_PENDING_APP_TO_WORKFLOW",
	REMOVE_TEMPLATE: "REMOVE_TEMPLATE",
	REMOVE_MOST_USED_TEMPLATE: "REMOVE_MOST_USED_TEMPLATE",
	EMPTY_TEMPLATES: "EMPTY_TEMPLATES",
	WORKFLOWS_UPDATE_AUTOMATION_RULES_ORDER:
		"WORKFLOWS_UPDATE_AUTOMATION_RULES_ORDER",
	WORKFLOWS_GET_SCHEDULED_RUNS: "WORKFLOWS_GET_SCHEDULED_RUNS",
	SELECTED_USERS: "SELECTED_USERS",
	WORKFLOWS_GET_SEARCHED_USERS_FOR_DRAFT:
		"WORKFLOWS_GET_SEARCHED_USERS_FOR_DRAFT",
	COMPILED_WORKFLOW_RESPONSE: "COMPILED_WORKFLOW_RESPONSE",
	COMPILED_WORKFLOW_ERROR_RESPONSE: "COMPILED_WORKFLOW_ERROR_RESPONSE",
	CLEAR_COMPILED_WORKFLOW_ERROR_RESPONSE:
		"CLEAR_COMPILED_WORKFLOW_ERROR_RESPONSE",
	UPDATE_DEPS_TRIGGER_RESPONSE: "UPDATE_DEPS_TRIGGER_RESPONSE",
	CLEAR_DEPS_TRIGGER_RESPONSE: "CLEAR_DEPS_TRIGGER_RESPONSE",
	UPDATE_TOGGLE_STATE_RUN_LOGS: "UPDATE_TOGGLE_STATE_RUN_LOGS",
	CLEAR_TOGGLE_STATE_RUN_LOGS: "CLEAR_TOGGLE_STATE_RUN_LOGS",
	GET_PLAYBOOK_VARIABLES: "GET_PLAYBOOK_VARIABLES",
	UPDATE_PLAYBOOK_VARIABLES: "UPDATE_PLAYBOOK_VARIABLES",
	CREATE_PLAYBOOK_VARIABLES: "CREATE_PLAYBOOK_VARIABLES",
	UPDATE_VARIABLES_IN_PLAYBOOK: "UPDATE_VARIABLES_IN_PLAYBOOK",
	UPDATE_SINGLE_VARIABLE_IN_PLAYBOOK: "UPDATE_SINGLE_VARIABLE_IN_PLAYBOOK",
};

export const getWorkFlow = (obj) => {
	return async function (dispatch) {
		try {
			const response = await getWorkflow(obj);
			if (response && !response.error)
				dispatch({
					type: ACTION_TYPE.GET_WORKFLOW,
					payload: { data: response },
				});
		} catch (err) {
			dispatch({
				type: ACTION_TYPE.GET_WORKFLOW,
				payload: { error: err },
			});
			TriggerIssue("Errors while getting workflow", err, "workflows");
		}
	};
};

export const getAppDescription = (obj) => {
	return async function (dispatch) {
		try {
			const response = await getAppDescriptionAPI(obj);
			if (response && !response.error)
				dispatch({
					type: ACTION_TYPE.GET_APP_DESCRIPTION,
					payload: { data: response.data },
				});
		} catch (err) {
			TriggerIssue(
				"Errors while getting App Description",
				err,
				"workflows"
			);
		}
	};
};

export const getRecommendedApps = (
	workflowId,
	entity,
	entity_id,
	isTemplate,
	onError
) => {
	return async function (dispatch) {
		try {
			const response = await fetchRecommendedApps(
				workflowId,
				entity,
				entity_id,
				isTemplate
			);
			if (!response.error) {
				dispatch({
					type: ACTION_TYPE.GET_RECOMMENDED_APPS,
					payload: { data: response },
				});
			} else {
				onError(response.error);
			}
		} catch (err) {
			onError(err);
			TriggerIssue(
				"Errors while getting Recommended Apps",
				err,
				"workflows"
			);
		}
	};
};

export const getRecommendedActions = (
	appID,
	parent_integration_id,
	type,
	onError
) => {
	return async function (dispatch) {
		try {
			const response = await fetchRecommendedActions(
				appID,
				parent_integration_id,
				type
			);
			if (!response.error) {
				dispatch({
					type: ACTION_TYPE.GET_RECOMMENDED_ACTIONS,
					payload: { data: response },
				});
			} else {
				onError(response.error);
			}
		} catch (err) {
			onError(err);
			TriggerIssue(
				"Errors while getting Recommended Actions",
				err,
				"workflows"
			);
		}
	};
};

export const editWorkflowUsers = (id, data, isTemplate, source) => {
	return async function (dispatch) {
		try {
			const response =
				source === "draft"
					? await editWorkflowDetails(id, data, isTemplate)
					: await editWorkflowetailsviaTemplate(id, data, isTemplate);
			if (!response.error)
				dispatch({
					type: ACTION_TYPE.EDIT_WORKFLOW_DETAILS,
					payload: { data: response },
				});
		} catch (err) {
			TriggerIssue(
				"Errors while editing workflow users",
				err,
				"workflows"
			);
		}
	};
};

export const editWorkFlowDetails = (
	id,
	data,
	isTemplate,
	entity_type,
	onError = (temp) => {}
) => {
	return async function (dispatch) {
		try {
			const response = await editWorkflowDetails(
				id,
				data,
				isTemplate,
				entity_type
			);
			if (!response.error)
				dispatch({
					type: ACTION_TYPE.EDIT_WORKFLOW_DETAILS,
					payload: { data: response },
				});
		} catch (err) {
			onError(false);
			TriggerIssue(
				"Errors while editing workflow details",
				err,
				"workflows"
			);
		}
	};
};

export const editWorkflowNodes = (id, nodes) => {
	return async function (dispatch) {
		try {
			const response = await editWorkflowNodesService(id, {
				nodes: nodes,
			});
			if (!response.error)
				dispatch({
					type: ACTION_TYPE.EDIT_WORKFLOW_NODE,
					payload: { data: response },
				});
		} catch (err) {
			TriggerIssue("Errors while edit workflow nodes", err, "workflows");
		}
	};
};

export const addAppToWorkflow = (
	workflowId,
	data,
	staticNode = false,
	isTemplate,
	onError
) => {
	return async function (dispatch) {
		try {
			let response;
			if (staticNode) {
				response = await saveStaticApplicationToWorkflow(
					workflowId,
					data,
					isTemplate
				);
			} else {
				response = await saveApplicationToWorkflow(
					workflowId,
					data,
					isTemplate
				);
			}

			if (!response.error) {
				dispatch({
					type: ACTION_TYPE.ADD_APPLICATION_TO_WORKFLOW,
					payload: {
						data: {
							...new WorkFlowApplicationModel(response),
							isAppAdded: data?.isAppAdded || false,
						},
						positionAt: data.positionAt,
					},
				});
			} else {
				onError(response.error);
			}
		} catch (err) {
			onError(err);
			TriggerIssue(
				"Errors while adding node to workflow",
				err,
				"workflows"
			);
		}
	};
};

export const addActionToWorkflow = (
	workflowId,
	workflowApplicationId,
	data,
	isStaticNode,
	isTemplate,
	onError
) => {
	return async function (dispatch) {
		let response;
		try {
			if (isStaticNode) {
				response = await saveActionToWorkflowStaticNode(
					workflowId,
					workflowApplicationId,
					data,
					isTemplate
				);
			} else {
				response = await saveActionToWorkflow(
					workflowId,
					workflowApplicationId,
					data,
					isTemplate
				);
			}

			if (!response.error) {
				const {
					action = {},
					system_attributes = [],
					variables = [],
				} = response;
				if (action && Object.keys(action).length) {
					dispatch({
						type: ACTION_TYPE.ADD_ACTION_TO_WORKFLOW,
						payload: {
							action: new WorkFlowActionModel(action),
							workflowApplicationId,
							system_attributes,
							variables,
						},
					});
				} else {
					dispatch({
						type: ACTION_TYPE.ADD_ACTION_TO_WORKFLOW,
						payload: {
							action: new WorkFlowActionModel(response),
							workflowApplicationId,
						},
					});
				}
			}
		} catch (err) {
			onError(err);
			TriggerIssue(
				"Errors while adding action to workflow",
				err,
				"workflows"
			);
		}
	};
};

export const updateActionToWorkflow = (
	workflowId,
	workflowApplicationId,
	workflowActionId,
	data,
	isStaticNode,
	isTemplate,
	onError
) => {
	return async function (dispatch) {
		let response;
		try {
			if (isStaticNode) {
				response = await updateActionFromWorkflowStaticNode(
					workflowId,
					workflowApplicationId,
					workflowActionId,
					data,
					isTemplate
				);
			} else {
				response = await updateActionFromWorkflow(
					workflowId,
					workflowApplicationId,
					workflowActionId,
					data,
					isTemplate
				);
			}
			if (!response.error) {
				const {
					action = {},
					system_attributes = [],
					variables = [],
				} = response;
				if (action && Object.keys(action).length) {
					dispatch({
						type: ACTION_TYPE.UPDATE_ACTION_TO_WORKFLOW,
						payload: {
							action: new WorkFlowActionModel(action),
							workflowApplicationId,
							system_attributes,
							variables,
						},
					});
				} else {
					dispatch({
						type: ACTION_TYPE.UPDATE_ACTION_TO_WORKFLOW,
						payload: {
							action: new WorkFlowActionModel(response),
							workflowApplicationId,
						},
					});
				}
			}
		} catch (err) {
			onError(err);
			TriggerIssue(
				"Errors while updating action to workflow",
				err,
				"workflows"
			);
		}
	};
};

export const editApplicationOfWorkflow = (
	workflowId,
	workflowApplicationId,
	data,
	isTemplate,
	onError
) => {
	return async function (dispatch) {
		try {
			const response = await editApplicationInWorkflow(
				workflowId,
				workflowApplicationId,
				data,
				isTemplate
			);
			if (!response.error) {
				dispatch({
					type: ACTION_TYPE.EDIT_APPLICATION_TO_WORKFLOW,
					payload: { data: new WorkFlowApplicationModel(response) },
				});
			} else {
				onError(response.error);
			}
		} catch (err) {
			onError(err);
			TriggerIssue(
				"Errors while editing Application of workflow",
				err,
				"workflows"
			);
		}
	};
};

export const deleteAppFromWorkflow = (
	workflowId,
	workflowApplicationId,
	isTemplate,
	onError
) => {
	return async function (dispatch) {
		try {
			const response = await deleteApplicationFromWorkflow(
				workflowId,
				workflowApplicationId,
				isTemplate
			);
			if (!response.error) {
				const { system_attributes = [], variables = [] } = response;
				dispatch({
					type: ACTION_TYPE.DELETE_APPLICATION_TO_WORKFLOW,
					payload: {
						system_attributes: system_attributes,
						variables: variables,
						workflowApplicationId,
					},
				});
			} else {
				onError(response.error);
			}
		} catch (err) {
			onError(err);
			TriggerIssue(
				"Errors while deleting App from workflow",
				err,
				"workflows"
			);
		}
	};
};

export const deleteAppFromMultiNode = (
	workflowId,
	workflowApplicationID,
	appId
) => {
	return async function (dispatch) {
		try {
			const response = await deleteAppFromMultiNodeService(
				workflowId,
				workflowApplicationID,
				appId
			);
			if (!response.error)
				dispatch({
					type: ACTION_TYPE.DELETE_APP_FROM_MULTI_NODE,
					payload: {
						data:
							response && Object.keys(response).length
								? new WorkFlowApplicationModel(response)
								: null,
						workflowApplicationID,
					},
				});
		} catch (err) {
			TriggerIssue(
				"Errors while delete App from MultiNode",
				err,
				"workflows"
			);
		}
	};
};

export const seperateAppFromMultiNode = (
	workflowId,
	workflowApplicationId,
	appIds
) => {
	return async function (dispatch) {
		try {
			const response = await seperateAppFromMultiNodeService(
				workflowId,
				workflowApplicationId,
				appIds
			);
			if (!response.error)
				dispatch({
					type: ACTION_TYPE.SEPARATE_APP_FROM_MULTI_NODE,
					payload: {
						data: response.map(
							(res) => new WorkFlowApplicationModel(res)
						),
						workflowApplicationId,
						appIds,
					},
				});
		} catch (err) {
			TriggerIssue(
				"Errors while seperate App from MultiNode",
				err,
				"workflows"
			);
		}
	};
};

export const deleteActFromWorkflow = (
	workflowId,
	workflowApplicationId,
	workflowActionId,
	isTemplate,
	onError
) => {
	return async function (dispatch) {
		try {
			const response = await deleteActionFromWorkflow(
				workflowId,
				workflowApplicationId,
				workflowActionId,
				isTemplate
			);
			if (!response.error) {
				const {
					node = {},
					system_attributes = [],
					variables = [],
				} = response;
				dispatch({
					type: ACTION_TYPE.DELETE_ACTION_TO_WORKFLOW,
					payload: {
						workflowApplicationId,
						workflowActionId,
						system_attributes,
						variables,
					},
				});
			} else {
				onError(response.error);
			}
		} catch (err) {
			onError(err);
			TriggerIssue(
				"Errors while delete action from workflow",
				err,
				"workflows"
			);
		}
	};
};

// export const getAllTemplates = (type, pageNo, onApiError) => {
// 	return async function (dispatch) {
// 		try {
// 			const response = await getAllTemplatesService(type, pageNo);
// 			if (!response.error)
// 				dispatch({
// 					type: ACTION_TYPE.WORKFLOWS_GET_ALL_TEMPLATES,
// 					payload: response,
// 				});
// 		} catch (reason) {
// 			onApiError();
// 			TriggerIssue("Errors while geting playbooks", reason, "workflows");
// 		}
// 	};
// };

export const getMostUsedTemplates = (type, pageNo, onApiError) => {
	return async function (dispatch) {
		try {
			const response = await getMostUsedTemplatesService(type, pageNo);
			if (!response.error)
				dispatch({
					type: ACTION_TYPE.WORKFLOWS_GET_MOST_USED_TEMPLATES,
					payload: response,
				});
		} catch (reason) {
			onApiError(reason);
			dispatch({
				type: ACTION_TYPE.WORKFLOWS_GET_MOST_USED_TEMPLATES,
				payload: { error: reason },
			});
			TriggerIssue(
				"Errors while getting most used playbooks",
				reason,
				"workflows"
			);
		}
	};
};

export const getAllDrafts = (type, pageNo, onApiError) => {
	return async function (dispatch) {
		try {
			const response = await getAllDraftsService(type, pageNo);
			if (!response.error)
				dispatch({
					type: ACTION_TYPE.WORKFLOWS_GET_ALL_DRAFTS,
					payload: response,
				});
		} catch (reason) {
			onApiError();
			dispatch({
				type: ACTION_TYPE.WORKFLOWS_GET_ALL_DRAFTS,
				payload: { error: reason },
			});
			TriggerIssue(
				"Errors while getting all drafts",
				reason,
				"workflows"
			);
		}
	};
};

export const getAutomationRules = (type, pageNo, onApiError) => {
	return async function (dispatch) {
		try {
			const rulesMetadata = getRulesFilterDatawithPlaybookId();
			const response = await getAllRulesV2WithoutTotalRuns(
				pageNo,
				30,
				rulesMetadata,
				type
			);
			if (!response.error)
				dispatch({
					type: ACTION_TYPE.WORKFLOWS_GET_AUTOMATION_RULES,
					payload: response,
				});
		} catch (reason) {
			onApiError();
			dispatch({
				type: ACTION_TYPE.WORKFLOWS_GET_AUTOMATION_RULES,
				payload: { error: reason },
			});
			TriggerIssue(
				"Errors while getting Automation rules",
				reason,
				"workflows"
			);
		}
	};
};

export const updateOrderOfAutomationRules = ({
	id,
	type,
	currentOrder,
	newOrder,
}) => {
	return async function (dispatch) {
		try {
			const response = await updateOrderOfRule({
				id,
				type,
				currentOrder,
				newOrder,
			});
			if (!response.error)
				dispatch({
					type: ACTION_TYPE.WORKFLOWS_UPDATE_AUTOMATION_RULES_ORDER,
					payload: response,
				});
		} catch (reason) {
			TriggerIssue(
				"Error in updateOrderOfAutomationRules",
				reason,
				"workflows"
			);
		}
	};
};

export const getAutomationRule = (ruleId) => {
	return async function (dispatch) {
		try {
			const response = await getAutomationRuleService(ruleId);
			if (!response.error)
				dispatch({
					type: ACTION_TYPE.WORKFLOWS_GET_AUTOMATION_RULE,
					payload: response,
				});
		} catch (reason) {
			dispatch({
				type: ACTION_TYPE.WORKFLOWS_GET_AUTOMATION_RULE,
				payload: { error: reason },
			});
			TriggerIssue(
				"Errors while getting Automation rule",
				reason,
				"workflows"
			);
		}
	};
};

export const getAppAutomationRule = (ruleId) => {
	return async function (dispatch) {
		try {
			const response = await getAutomationRuleService(ruleId);
			if (!response.error)
				dispatch({
					type: ACTION_TYPE.GET_APP_AUTOMATION_RULE,
					payload: response,
				});
		} catch (reason) {
			TriggerIssue(
				"Errors while getting Automation rule",
				reason,
				"workflows"
			);
		}
	};
};

export const setEditAutomationRule = (action) => {
	return async function (dispatch, getState) {
		const automationRule = getState()?.workflows?.automationRule;
		try {
			dispatch({
				type: ACTION_TYPE.SET_EDIT_AUTOMATION_RULE,
				payload: { action: { ...automationRule, ...action } },
			});
		} catch (reason) {
			TriggerIssue(
				"Errors while edit automation rule",
				reason,
				"workflows"
			);
		}
	};
};

export const setEditAppAutomationRule = (action) => {
	return async function (dispatch, getState) {
		const automationRule = getState()?.workflows?.appAutomationRule;
		try {
			dispatch({
				type: ACTION_TYPE.SET_EDIT_APP_AUTOMATION_RULE,
				payload: { action: { ...automationRule, ...action } },
			});
		} catch (reason) {
			TriggerIssue(
				"Errors while edit automation rule",
				reason,
				"workflows"
			);
		}
	};
};

export const updateAutomationRules = (data) => {
	return async function (dispatch) {
		try {
			const response = await updateAutomationRulesService(data);
			if (!response.error)
				dispatch({
					type: ACTION_TYPE.WORKFLOWS_UPDATE_AUTOMATION_RULES,
					payload: { data, response },
				});
		} catch (reason) {
			TriggerIssue(
				"Errors while update Automation rule",
				reason,
				"workflows"
			);
		}
	};
};

export const patchAutomationRuleStatus = (data, status) => {
	return async function (dispatch) {
		try {
			const response = await patchAutomationStatusService(data, status);
			if (!response.error)
				dispatch({
					type: ACTION_TYPE.WORKFLOWS_UPDATE_AUTOMATION_RULES,
					payload: { data, response },
				});
		} catch (reason) {
			TriggerIssue(
				"Errors while update automation rule",
				reason,
				"workflows"
			);
		}
	};
};

export const patchAppAutomationRuleStatus = (
	data,
	status,
	setShowStatusLoader
) => {
	return async function (dispatch) {
		try {
			const response = await patchAutomationStatusService(data, status);
			if (!response.error)
				dispatch({
					type: ACTION_TYPE.UPDATE_APP_AUTOMATION_RULES,
					payload: { data, response },
				});
			setShowStatusLoader && setShowStatusLoader(false);
		} catch (reason) {
			TriggerIssue(
				"Errors while update automation rule",
				reason,
				"workflows"
			);
		}
	};
};

export const getAppAutomationRules = (data) => {
	return async function (dispatch) {
		try {
			const rulesMetadata = getRulesFilterDatawithPlaybookId();
			const response = await getAppRules({
				...data,
				reqBody: rulesMetadata || data?.reqObj,
			});
			if (!response.error)
				dispatch({
					type: ACTION_TYPE.GET_APP_AUTOMATION_RULES,
					payload: response,
				});
		} catch (reason) {
			data?.onApiError && data?.onApiError(reason);
			TriggerIssue(
				"Errors while getting Automation rules",
				reason,
				"workflows"
			);
		}
	};
};

export const updateAutomationRule = (data, onResponse, onError) => {
	return async function (dispatch) {
		try {
			const response = await updateAutomationRuleService(
				new WorkFlowAutomationRuleRequestModel(data)
			);
			if (!response.error)
				dispatch({
					type: ACTION_TYPE.WORKFLOWS_UPDATE_AUTOMATION_RULE,
					payload: {
						data: new WorkFlowAutomationRuleResponseModel(response),
					},
				});
			onResponse && onResponse();
		} catch (reason) {
			TriggerIssue(
				"Errors while update automation rule",
				reason,
				"workflows"
			);
			onError && onError(reason);
		}
	};
};

export const updateAppAutomationRule = (data, onResponse, onError) => {
	return async function (dispatch) {
		try {
			const response = await updateAppAutomationRuleService(
				new AppAutomationRuleRequestModel(data)
			);
			if (!response.error)
				dispatch({
					type: ACTION_TYPE.UPDATE_APP_AUTOMATION_RULE,
					payload: {
						data: new AppAutomationRuleResponseModel(response),
					},
				});
			onResponse && onResponse();
		} catch (reason) {
			TriggerIssue(
				"Errors while update automation rule",
				reason,
				"workflows"
			);
			onError && onError(reason);
		}
	};
};

export const getTopFiveDrafts = (type, onApiError) => {
	return async function (dispatch) {
		try {
			const response = await getTopFiveDraftsService(type);
			if (!response.error)
				dispatch({
					type: ACTION_TYPE.WORKFLOWS_GET_TOP_FIVE_DRAFTS,
					payload: response,
				});
		} catch (reason) {
			onApiError();
			dispatch({
				type: ACTION_TYPE.WORKFLOWS_GET_TOP_FIVE_DRAFTS,
				payload: { error: reason },
			});
			TriggerIssue(
				"Errors while get Draft workflow",
				reason,
				"workflows"
			);
		}
	};
};

export function TransformCompleted(response) {
	let data = [];
	if (response && response.data && Array.isArray(response.data)) {
		response.data.forEach((element) => {
			let obj = new Completed(element);
			let apps = obj.workflow_apps.map((app) => {
				return new CompletedApps(app);
			});
			obj.workflow_apps = apps;
			data.push(obj);
		});
	}
	return data;
}

export const getAllCompletedWorkflows = (type, pageNo, reqBody, onApiError) => {
	return async function (dispatch) {
		try {
			const response = await getAllCompletedWorkflowsServiceV2(
				type,
				pageNo,
				reqBody
			);
			if (!response.error) {
				let data = TransformCompletedV2(response);
				// let data = response?.data;
				dispatch({
					type: ACTION_TYPE.WORKFLOWS_GET_ALL_COMPLETED_WORKFLOWS,
					payload: { meta: response?.meta, data },
				});
			}
		} catch (reason) {
			onApiError();
			dispatch({
				type: ACTION_TYPE.WORKFLOWS_GET_ALL_COMPLETED_WORKFLOWS,
				payload: { error: reason },
			});
			TriggerIssue("Errors while getting playbooks", reason, "workflows");
		}
	};
};

export const getAllScheduledRuns = (type, pageNo, reqBody, onApiError) => {
	return async function (dispatch) {
		try {
			const response = await getAllScheduledRunsServiceV2(
				type,
				pageNo,
				reqBody
			);
			if (!response.error) {
				dispatch({
					type: ACTION_TYPE.WORKFLOWS_GET_SCHEDULED_RUNS,
					payload: response,
				});
			}
		} catch (reason) {
			onApiError();
			dispatch({
				type: ACTION_TYPE.WORKFLOWS_GET_SCHEDULED_RUNS,
				payload: { error: reason },
			});
			TriggerIssue(
				"Errors while getting scheduled runs",
				reason,
				"workflows"
			);
		}
	};
};

export const getInProgressWorkflows = (type, pageNo, onApiError) => {
	return async function (dispatch) {
		try {
			let reqBody = {
				filter_by: [
					{
						field_values: ["pending"],
						field_order: "contains",
						field_id: "status",
						filter_type: "string",
						field_name: "Status",
						negative: false,
						is_custom: false,
					},
				],
				sort_by: [],
				columns: [],
			};

			const response = await getAllCompletedWorkflowsServiceV2(
				type,
				pageNo,
				reqBody
			);
			if (!response.error) {
				let data = TransformCompleted(response);
				dispatch({
					type: ACTION_TYPE.WORKFLOWS_GET_IN_PROGRESS_WORKFLOWS,
					payload: { meta: response?.meta, data },
				});
			}
		} catch (reason) {
			onApiError();
			dispatch({
				type: ACTION_TYPE.WORKFLOWS_GET_IN_PROGRESS_WORKFLOWS,
				payload: { error: reason },
			});
			TriggerIssue(
				"Errors while getting in-progress workflow",
				reason,
				"workflows"
			);
		}
	};
};

export const getAllUsers = (page, row, reqBody) => {
	return async function (dispatch) {
		try {
			const response = await getUserslistServiceV2(page, row, reqBody);
			if (!response.error) {
				dispatch({
					type: ACTION_TYPE.WORKFLOWS_GET_ONBOARDING_USERS,
					payload: response,
				});
			} else {
				dispatch({
					type: ACTION_TYPE.WORKFLOWS_GET_ONBOARDING_USERS,
					payload: { error: response.error },
				});
			}
		} catch (reason) {
			dispatch({
				type: ACTION_TYPE.WORKFLOWS_GET_ONBOARDING_USERS,
				payload: { error: reason },
			});
			TriggerIssue("Errors while getting all users", reason, "workflows");
		}
	};
};

export const compileWorkflow = (workflowId) => {
	return async function (dispatch) {
		try {
			const response = await compileWorkflowDraft(workflowId);
			if (!response.error && Array.isArray(response)) {
				dispatch({
					type: ACTION_TYPE.COMPILED_WORKFLOW_RESPONSE,
					payload: response,
				});
			} else {
				dispatch({
					type: ACTION_TYPE.COMPILED_WORKFLOW_ERROR_RESPONSE,
					payload: { error: response.error || response },
				});
			}
		} catch (error) {
			dispatch({
				type: ACTION_TYPE.COMPILED_WORKFLOW_ERROR_RESPONSE,
				payload: { error },
			});
			TriggerIssue("Errors while compiling workflow", error, "workflows");
		}
	};
};

export const clearCompileExecution = () => {
	return async function (dispatch) {
		dispatch({
			type: ACTION_TYPE.CLEAR_COMPILED_WORKFLOW_ERROR_RESPONSE,
		});
	};
};

export const searchDrafts = (payload) => {
	return async function (dispatch) {
		dispatch({
			type: ACTION_TYPE.WORKFLOWS_GET_ALL_DRAFTS,
			payload: payload,
		});
	};
};
export const searchTemplates = (payload) => {
	return async function (dispatch) {
		dispatch({
			type: ACTION_TYPE.WORKFLOWS_GET_ALL_TEMPLATES,
			payload: payload,
		});
	};
};

export const searchCompleted = (payload) => {
	return async function (dispatch) {
		dispatch({
			type: ACTION_TYPE.WORKFLOWS_GET_ALL_COMPLETED_WORKFLOWS,
			payload: payload,
		});
	};
};

export const searchAutomationRules = (payload) => {
	return async function (dispatch) {
		dispatch({
			type: ACTION_TYPE.WORKFLOWS_GET_AUTOMATION_RULES,
			payload: payload,
		});
	};
};

export const searchScheduleRuns = (payload) => {
	return async function (dispatch) {
		dispatch({
			type: ACTION_TYPE.WORKFLOWS_GET_SCHEDULED_RUNS,
			payload: payload,
		});
	};
};

export const getUsersBySearch = (
	searchTerm,
	cancelTokenSource,
	compact,
	playbookId
) => {
	return async function (dispatch) {
		try {
			searchUserswithPlaybookId({
				query: searchTerm,
				cancelTokenSource,
				compact,
				templateId: playbookId,
			})
				.then((response) => {
					if (response && !response.error) {
						let responseData = { data: response.results, meta: [] };
						dispatch({
							type: ACTION_TYPE.WORKFLOWS_GET_SEARCHED_USERS,
							payload: responseData,
						});
					} else if (response && response.error) {
						dispatch({
							type: ACTION_TYPE.WORKFLOWS_GET_SEARCHED_USERS,
							payload: { error: response.error },
						});
					}
				})
				.catch((error) => {
					dispatch({
						type: ACTION_TYPE.WORKFLOWS_GET_SEARCHED_USERS,
						payload: { error: error },
					});
					TriggerIssue(
						"Errors in search users while searching for onboarding/offboarding users",
						error,
						"workflows"
					);
				});
		} catch (reason) {
			TriggerIssue("Errors while searching users", reason, "workflows");
		}
	};
};

export const searchUsersForDraft = (searchTerm, cancelTokenSource, compact) => {
	return async function (dispatch) {
		try {
			searchUsers(searchTerm, cancelTokenSource, compact)
				.then((response) => {
					if (!response.error) {
						let responseData = { data: response.results, meta: [] };
						dispatch({
							type: ACTION_TYPE.WORKFLOWS_GET_SEARCHED_USERS_FOR_DRAFT,
							payload: responseData,
						});
					}
				})
				.catch((error) => {
					TriggerIssue(
						"Errors in search users while searching for onboarding/offboarding users",
						error,
						"workflows"
					);
				});
		} catch (reason) {
			TriggerIssue("Errors while searching users", reason, "workflows");
		}
	};
};

export const setEditActionWorkflow = (action) => {
	return async function (dispatch) {
		try {
			dispatch({
				type: ACTION_TYPE.SET_EDIT_ACTION,
				payload: { action },
			});
		} catch (reason) {
			TriggerIssue(
				"Errors while editing action workflow",
				reason,
				"workflows"
			);
		}
	};
};

export const setEditApplication = (app) => {
	return async function (dispatch) {
		try {
			dispatch({
				type: ACTION_TYPE.SET_EDIT_APP_ID,
				payload: app,
			});
		} catch (reason) {
			TriggerIssue("Errors while edit application", reason, "workflows");
		}
	};
};

export const setOrgIdApplication = (org_integration_id) => {
	return async function (dispatch) {
		try {
			dispatch({
				type: ACTION_TYPE.SET_APP_ORG_ID,
				payload: org_integration_id,
			});
		} catch (reason) {
			TriggerIssue(
				"Errors while update org integration id",
				reason,
				"workflows"
			);
		}
	};
};

export const fetchListOfRuns = (workflowId, type, errorFunction) => {
	return async function (dispatch) {
		try {
			const response = await getListOfRuns(workflowId, type);
			if (response && !response.error)
				dispatch({
					type: ACTION_TYPE.GET_LIST_OF_RUNS,
					payload: { data: response },
				});
		} catch (err) {
			errorFunction(true);
			TriggerIssue("Errors while getting list of runs", err, "workflows");
		}
	};
};
export const fetchSummarisedRunLogs = (
	workflowId,
	runId,
	isRefresh,
	type,
	apiError,
	isManualRefresh = false
) => {
	return async function (dispatch) {
		let response = await getSummarisedRunLogData(
			workflowId,
			runId,
			isRefresh,
			type
		);
		if (response && !response.error) {
			response?.run_log?.map((log) => {
				if (
					log?.action?.action_initiated &&
					(log?.action?.action_type === "manual" ||
						log?.action?.action_type === "custom_manual") &&
					log?.action?.action_status === "pending" &&
					!log.group_state
				) {
					let title = "",
						description = "";
					if (log?.action?.action_type === "manual") {
						title = `Awaiting ${
							log?.action?.action_data?.assignee?.[0]
								?.user_name ||
							log?.action?.action_data?.assignee?.[0]
								?.user_email ||
							""
						} to perform ${
							log?.action?.action_data?.title ||
							log?.action?.action_name ||
							""
						}`;
						description =
							"The user has not marked this task as completed yet.";
					} else {
						title = `Awaiting for a response from ${
							log?.action?.action_data?.assignee?.[0]
								?.user_name ||
							log?.action?.action_data?.assignee?.[0]
								?.user_email ||
							""
						}`;
					}
					const obj = {
						type: "pending",
						title: title,
						description: description,
						timestamp: null,
						group: "n8n",
					};
					log.action.action_log.push(obj);
				}
				if (log.group_state && log.apps.length) {
					return log.apps.map((app) => {
						if (
							app.app_action_status === "pending" &&
							log.action.action_status === "pending"
						) {
							const obj = {
								type: "pending",
								title: `Awaiting user to delete ${
									app.app_name || ""
								} account`,
								description:
									"The user has not marked this task as completed",
								timestamp: null,
								group: "n8n",
								appId: app.app_id,
							};
							log.action.action_log.push(obj);
						}
					});
				}
				if (
					log.action.action_type === "static" &&
					log.action.action_status === "pending" &&
					log.action.uniqId ===
						"ZLURI_STATIC.MAKE_HTTP_REQUEST_WITH_CALLBACK"
				) {
					const obj = {
						type: "pending",
						title: `Awaiting callback from the external service`,
						timestamp: null,
						group: "n8n",
					};
					log.action.action_log.push(obj);
				}
			});

			dispatch({
				type: ACTION_TYPE.GET_SUMMARISED_RUN_LOGS,
				payload: { data: response },
			});
		}
		if (response && response.error && isManualRefresh) {
			apiError(true);
		}
	};
};
export const fetchRawRunLogs = (
	workflowId,
	runId,
	isRefresh,
	type,
	apiError,
	isManualRefresh = false
) => {
	return async function (dispatch) {
		let response = await getRawRunLogData(
			workflowId,
			runId,
			isRefresh,
			type
		);
		if (response && !response.error) {
			dispatch({
				type: ACTION_TYPE.GET_RAW_RUN_LOGS,
				payload: { data: response },
			});
		}
		if (response && response.error && isManualRefresh) apiError(true);
	};
};

export const clearWorkflow = () => {
	return async function (dispatch) {
		try {
			dispatch({
				type: ACTION_TYPE.CLEAR_WORKFLOW,
			});
		} catch (reason) {
			TriggerIssue("Errors while clear workflow", reason, "workflows");
		}
	};
};

export const updateWorkflow = (workflow) => {
	return async function (dispatch) {
		try {
			dispatch({
				type: ACTION_TYPE.UPDATE_WORKFLOW,
				payload: workflow,
			});
		} catch (reason) {
			TriggerIssue("Errors while update workflow", reason, "workflows");
		}
	};
};

export const removeTemplateFromStore = (id) => {
	return async function (dispatch) {
		try {
			dispatch({
				type: ACTION_TYPE.REMOVE_TEMPLATE,
				payload: id,
			});
		} catch (reason) {
			TriggerIssue("Errors while delete playbook", reason, "workflows");
		}
	};
};

export const removeMostUsedTemplateFromStore = (id) => {
	return async function (dispatch) {
		try {
			dispatch({
				type: ACTION_TYPE.REMOVE_MOST_USED_TEMPLATE,
				payload: id,
			});
		} catch (reason) {
			TriggerIssue(
				"Errors while remove most used playbook",
				reason,
				"workflows"
			);
		}
	};
};
export const workflowExecuted = () => {
	return async function (dispatch) {
		try {
			dispatch({
				type: ACTION_TYPE.SET_WORKFLOW_EXECUTED,
			});
		} catch (reason) {
			TriggerIssue(
				"Errors while executing workflow",
				reason,
				"workflows"
			);
		}
	};
};

export const clearWorkflowLogs = () => {
	return async function (dispatch) {
		dispatch({
			type: ACTION_TYPE.CLEAR_WORKFLOW_LOGS,
		});
	};
};

export const clearListOfWorkflows = () => {
	return async function (dispatch) {
		dispatch({
			type: ACTION_TYPE.CLEAR_LIST_OF_WORKFLOWS,
		});
	};
};

export const clearAppAutomationRuleData = () => {
	return async function (dispatch) {
		dispatch({
			type: ACTION_TYPE.CLEAR_APP_AUTOMATION_RULE_DATA,
		});
	};
};

export const getAllManualTaskTemplates = (onlyCustomTemplate, onError) => {
	return async function (dispatch) {
		try {
			const response =
				await getAllManualTaskTemplatesAPI(onlyCustomTemplate);
			if (response && !response.error) {
				dispatch({
					type: ACTION_TYPE.GET_ALL_MANUAL_TASK_TEMPLATES,
					payload: { data: response.data },
				});
			} else {
				onError(response.error);
			}
		} catch (reason) {
			TriggerIssue(
				"Errors while update automation rule",
				reason,
				"workflows"
			);
			onError && onError(reason);
		}
	};
};

export const getManualTaskTemplateData = (
	workflow_users,
	appId,
	templateId,
	itsm = "email",
	workflowId,
	isTemplate
) => {
	return async function (dispatch) {
		const response = await getManualTaskTemplateDataAPI(
			workflow_users,
			appId,
			templateId,
			itsm,
			workflowId,
			isTemplate
		);
		if (response && !response.error) {
			dispatch({
				type: ACTION_TYPE.GET_MANUAL_TASK_TEMPLATE_DATA,
				payload: { data: response.data },
			});
		}
		if (response && response.error) {
			console.log(response.error);
		}
	};
};

export const getInegrationAccounts = (appId) => {
	return async function (dispatch) {
		const response = await getIntegrationAccountsAPI(appId);
		if (response && !response.error) {
			dispatch({
				type: ACTION_TYPE.GET_INTEGRATION_ACCOUNTS,
				payload: { data: response, appId },
			});
		}
		if (response && response.error) {
			console.log(response.error);
		}
	};
};

export const getIntegrationAccountsV2 = (appId, onError) => {
	return async function (dispatch) {
		const response = await getIntegrationAccountsV2API(appId);
		if (response && !response.error) {
			dispatch({
				type: ACTION_TYPE.GET_INTEGRATION_ACCOUNTS,
				payload: { data: response, appId },
			});
		} else {
			onError(response.error);
		}
		if (response && response.error) {
			onError(response.error);
			console.log(response.error);
		}
	};
};

export const addToScopeValidations = (orgIntegrationID) => {
	return async function (dispatch) {
		dispatch({
			type: ACTION_TYPE.ACTION_SCOPE_VALIDATIONS,
			payload: { orgIntegrationID, data: {} },
		});
	};
};

export const runScopeValidations = (workflowId, data, isTemplate) => {
	return async function (dispatch) {
		const response = await actionValidation(workflowId, data, isTemplate);
		if (response) {
			dispatch({
				type: ACTION_TYPE.ACTION_SCOPE_VALIDATIONS,
				payload: {
					orgIntegrationID: data.org_integration_id,
					data: response,
				},
			});
		}
		if (response && response.error) {
			console.log(response.error);
		}
	};
};

export const removeInegrationAccount = (appId) => {
	return async function (dispatch) {
		dispatch({
			type: ACTION_TYPE.REMOVE_INTEGRATION_ACCOUNT,
			payload: { appId },
		});
	};
};

export const clearManualTaskData = () => {
	return async function (dispatch) {
		try {
			dispatch({
				type: ACTION_TYPE.CLEAR_MANUAL_TASK_TEMPLATE_DATA,
			});
		} catch (reason) {
			TriggerIssue(
				"Errors while clear Manual task data",
				reason,
				"workflows"
			);
		}
	};
};

export const getOffboardingTasks = ({ workflowId, userId, token }) => {
	return async function (dispatch) {
		const response = await getOffboardingTasksAPI({
			workflowId,
			userId,
			token,
		});
		if (response && !response.error) {
			dispatch({
				type: ACTION_TYPE.GET_OFFBOARDING_TASKS_LIST,
				payload: { data: response },
			});
		}
		if (response && response.error) {
			console.log(response.error);
		}
	};
};

export const markOffboardingTaskAsComplete = ({
	workflowId,
	userId,
	selectedApp,
	token,
}) => {
	return async function (dispatch) {
		const response = await markOffboardingTaskAsCompleteAPI({
			workflowId,
			userId,
			selectedApp,
			token,
		});
		if (response && !response.error) {
			dispatch({
				type: ACTION_TYPE.MARK_OFFBOARDING_TASK_COMPLETE,
				payload: { response, req: selectedApp },
			});
		}
		if (response && response.error) {
			console.log(response.error);
		}
	};
};

export const signOffboardingDeclaration = ({ workflowId, userId, token }) => {
	return async function (dispatch) {
		const response = await signOffboardingDeclarationAPI({
			workflowId,
			userId,
			token,
		});
		if (response && !response.error) {
			dispatch({
				type: ACTION_TYPE.SIGN_OFFBOARDING_DECLARATION_TASK,
				payload: { response },
			});
		}
		if (response && response.error) {
			console.log(response.error);
		}
	};
};

export const getPendingAppList = ({ workflowId }) => {
	return async function (dispatch) {
		const response = await getPendingAppListAPI({
			workflowId,
		});
		if (response && !response.error) {
			dispatch({
				type: ACTION_TYPE.GET_PENDING_APP_LIST,
				payload: { response },
			});
		}
		if (response && response.error) {
			response.error;
		}
	};
};

export const addPendingAppList = ({ workflowId, apps }) => {
	return async function (dispatch) {
		const response = await addPendingAppListAPI({
			workflowId,
			apps,
		});
		if (response && !response.error) {
			dispatch({
				type: ACTION_TYPE.ADD_PENDING_APP_TO_WORKFLOW,
				payload: { response },
			});
		}
		if (response && response.error) {
			console.log(response.error);
		}
	};
};

export const emptyTemplates = () => {
	return async function (dispatch) {
		dispatch({
			type: ACTION_TYPE.EMPTY_TEMPLATES,
		});
	};
};

export const updateSelectedUsers = (selectedUsers) => {
	return async function (dispatch) {
		dispatch({
			type: ACTION_TYPE.SELECTED_USERS,
			payload: selectedUsers,
		});
	};
};

export const getAllTemplatesV2 = (type, pageNo, reqBody, onApiError) => {
	return async function (dispatch) {
		try {
			const response = await getAllPlaybookWorkflowsServiceV2(
				type,
				pageNo,
				reqBody
			);
			if (!response.error)
				dispatch({
					type: ACTION_TYPE.WORKFLOWS_GET_ALL_TEMPLATES,
					payload: response,
				});
		} catch (reason) {
			onApiError();
			dispatch({
				type: ACTION_TYPE.WORKFLOWS_GET_ALL_TEMPLATES,
				payload: { error: reason },
			});
			TriggerIssue(
				"Errors while getting v2 playbooks",
				reason,
				"workflows"
			);
		}
	};
};

export const changeIntegrationAccount = (
	workflowId,
	workflowApplicationId,
	reqObj,
	isTemplate,
	type
) => {
	return async function (dispatch) {
		try {
			const response = await changeIntegrationAccountAPI(
				workflowId,
				workflowApplicationId,
				reqObj,
				isTemplate,
				type
			);
			if (!response.error)
				dispatch({
					type: ACTION_TYPE.EDIT_APPLICATION_TO_WORKFLOW,
					payload: { data: new WorkFlowApplicationModel(response) },
				});
		} catch (reason) {
			TriggerIssue(
				"Error in changing Integration account",
				reason,
				"workflows"
			);
		}
	};
};

export function updateDepsTriggerResponse(response) {
	return async function (dispatch) {
		dispatch({
			type: ACTION_TYPE.UPDATE_DEPS_TRIGGER_RESPONSE,
			payload: {
				response,
			},
		});
	};
}

export function clearDepsTriggerResponse() {
	return async function (dispatch) {
		dispatch({
			type: ACTION_TYPE.CLEAR_DEPS_TRIGGER_RESPONSE,
		});
	};
}

export function updateToggleStateRunLogs(obj) {
	return async function (dispatch) {
		dispatch({
			type: ACTION_TYPE.UPDATE_TOGGLE_STATE_RUN_LOGS,
			payload: obj,
		});
	};
}

export function clearToggleStateRunLogs() {
	return async function (dispatch) {
		dispatch({
			type: ACTION_TYPE.CLEAR_TOGGLE_STATE_RUN_LOGS,
		});
	};
}

export function getPlaybookVariables(playbookId) {
	return async function (dispatch) {
		try {
			const response = await getPlaybookVariablesAPI(playbookId);
			if (!response.error)
				dispatch({
					type: ACTION_TYPE.GET_PLAYBOOK_VARIABLES,
					payload: response,
				});
		} catch (reason) {
			TriggerIssue(
				"Error in getting playbook variables",
				reason,
				"workflows"
			);
		}
	};
}

export function updateVariablesInPlaybook(variables) {
	return async function (dispatch) {
		dispatch({
			type: ACTION_TYPE.UPDATE_VARIABLES_IN_PLAYBOOK,
			payload: variables,
		});
	};
}

export function updateSingleVariableInPlaybook(variable) {
	return async function (dispatch) {
		dispatch({
			type: ACTION_TYPE.UPDATE_SINGLE_VARIABLE_IN_PLAYBOOK,
			payload: variable,
		});
	};
}

/**
 * STORE
 */
const workflowState = {
	workflow: {},
	editActionWorkflow: null,
	editApplication: null,
	recommendedApps: null,
	recommedendActions: null,
	overviewDrafts: null,
	templates: null,
	mostUsedTemplates: null,
	drafts: null,
	completed: null,
	automationRules: null,
	automationRule: null,
	appAutomationRule: null,
	scheduledRuns: null,
	initialAutomationRuleState: null,
	initialAppAutomationRule: null,
	inProgress: null,
	users: null,
	actions: null,
	selectedUsers: null,
	listOfRuns: {},
	summarisedRunLogs: {},
	rawRunLogs: {},
	appDescription: [],
	allManualTaskTemplates: [],
	manualTaskTemplateById: null,
	manualTaskTemplateData: null,
	integrationAccounts: {},
	offboardingTasks: {},
	scopeValidations: {},
	pendingAppList: [],
	depsTriggerResponseITSM: {},
	compileExecutionError: {},
	toggleStateRunLogs: {},
	appRules: null,
};

/**
 * Reducer
 * @param {*} state // state of the current reducer
 * @param {*} action // action dispatched {type:"",payload:{}}
 */
export function workflowsReducer(state = workflowState, action) {
	switch (action.type) {
		case ACTION_TYPE.SET_APP_ORG_ID:
			return Object.assign({}, state, {
				editApplication: Object.assign({}, state.editApplication, {
					orgIntegrationID: action.payload,
				}),
			});
		case ACTION_TYPE.GET_WORKFLOW:
		case ACTION_TYPE.EDIT_WORKFLOW_DETAILS:
		case ACTION_TYPE.EDIT_WORKFLOW_NODE:
			var workflow = action.payload.data
				? new WorkflowModel(action.payload.data)
				: null;
			return Object.assign({}, state, {
				workflow: action.payload.error
					? action.payload
					: refreshActionIndex(workflow),
				editActionWorkflow: null,
			});
		case ACTION_TYPE.GET_APP_DESCRIPTION:
			const data = action.payload.data[0];
			var appDescription = new AppDescriptionModel(data);
			const appDescriptionArray = [...state.appDescription];
			const index = appDescriptionArray.findIndex(
				(element) => element.id === appDescription.id
			);
			if (index === -1) {
				appDescriptionArray.push(appDescription);
			}
			return Object.assign({}, state, {
				appDescription: appDescriptionArray,
			});
		case ACTION_TYPE.GET_INTEGRATION_ACCOUNTS:
			let newData = action.payload.data;
			newData.appId = action.payload.appId;
			if (newData.integration) {
				newData.integration = new Integration(newData.integration);
			}
			const integrationAccounts = { ...state.integrationAccounts };
			integrationAccounts[action.payload.appId] = newData;
			return Object.assign({}, state, {
				integrationAccounts: integrationAccounts,
			});
		case ACTION_TYPE.REMOVE_INTEGRATION_ACCOUNT:
			let appId = action.payload.appId;
			const oldIntegrationAccountsObj = { ...state.integrationAccounts };
			Reflect.deleteProperty(oldIntegrationAccountsObj, appId);
			return Object.assign({}, state, {
				integrationAccounts: oldIntegrationAccountsObj,
			});
		case ACTION_TYPE.ADD_APPLICATION_TO_WORKFLOW:
			var nodes = state.workflow.nodes;
			if (action.payload.positionAt >= 0) {
				nodes.splice(action.payload.positionAt, 0, action.payload.data);
			} else {
				nodes.push(action.payload.data);
			}
			return Object.assign({}, state, {
				workflow: refreshActionIndex(
					Object.assign({}, state.workflow, {
						nodes,
					})
				),
				editActionWorkflow: null,
				editApplication: action.payload.data,
			});
		case ACTION_TYPE.SEPARATE_APP_FROM_MULTI_NODE:
			var nodes = state.workflow.nodes;
			var appIndex = nodes.findIndex(
				(node) =>
					node.workflowApplicationID ===
					action.payload.workflowApplicationId
			);
			var node = nodes[appIndex];
			if (action.payload.appIds.length > 1) {
				node.apps = [];
			} else {
				node.apps = node.apps.filter(
					(app) => app.id != action.payload.appIds[0]
				);
			}
			if (node.apps.length) {
				nodes.splice(appIndex, 1, node);
			} else {
				nodes.splice(appIndex, 1);
			}
			nodes = [...nodes, ...action.payload.data];
			return Object.assign({}, state, {
				workflow: refreshActionIndex(
					Object.assign({}, state.workflow, {
						nodes,
					})
				),
				editActionWorkflow: null,
				editApplication: action.payload.data[0],
			});
		case ACTION_TYPE.EDIT_APPLICATION_TO_WORKFLOW:
		case ACTION_TYPE.DELETE_APP_FROM_MULTI_NODE:
			var application = action.payload.data;
			var nodes = state.workflow.nodes;
			var appIndex = nodes.findIndex(
				(app) =>
					app.workflowApplicationID ===
					application.workflowApplicationID
			);
			if (application) {
				nodes.splice(appIndex, 1, application);
			} else {
				nodes.splice(appIndex, 1);
			}

			return Object.assign({}, state, {
				recommedendActions: null,
				integrationAccounts: {},
				workflow: refreshActionIndex(
					Object.assign({}, state.workflow, { nodes })
				),
			});
		case ACTION_TYPE.REMOVE_TEMPLATE:
			var templates = state.templates.data;
			var ind = templates.findIndex(
				(template) =>
					template.workflow_id || template._id === action.payload
			);
			templates.splice(ind, 1);
			return Object.assign({}, state, {
				templates: {
					data: templates,
					meta: state.templates.meta,
				},
			});
		case ACTION_TYPE.REMOVE_MOST_USED_TEMPLATE:
			var mostUsedTemplates = state.mostUsedTemplates.data;
			var inx = mostUsedTemplates.findIndex(
				(template) =>
					template.workflow_id || template._id === action.payload
			);
			mostUsedTemplates.splice(inx, 1);
			return Object.assign({}, state, {
				mostUsedTemplates: {
					data: mostUsedTemplates,
					meta: state.mostUsedTemplates.meta,
				},
			});
		case ACTION_TYPE.ADD_ACTION_TO_WORKFLOW:
		case ACTION_TYPE.UPDATE_ACTION_TO_WORKFLOW:
			var workflow = state.workflow;
			var nodes = workflow.nodes;

			var appIndex = nodes.findIndex(
				(app) =>
					app.workflowApplicationID ===
					action.payload.workflowApplicationId
			);
			var actionIndex = nodes[appIndex].actions.findIndex(
				(act) =>
					act.workflowActionID ===
					action.payload.action.workflowActionID
			);
			if (actionIndex > -1) {
				nodes[appIndex].actions.splice(
					actionIndex,
					1,
					action.payload.action
				);
			} else {
				nodes[appIndex].actions.push(action.payload.action);
			}
			nodes[appIndex].actions = nodes[appIndex].actions.filter(
				(act) => act.workflowActionID
			);
			var system_attributes =
				action.payload.system_attributes ||
				state.workflow.system_attributes;
			var variables =
				action.payload.variables || state.workflow.variables;
			return Object.assign({}, state, {
				workflow: refreshActionIndex(
					Object.assign({}, state.workflow, {
						nodes: workflow.nodes,
						system_attributes: system_attributes,
						variables: variables,
					})
				),
				editActionWorkflow:
					action.type === ACTION_TYPE.UPDATE_ACTION_TO_WORKFLOW
						? null
						: action.payload.action,
				editApplication: nodes[appIndex],
			});
		case ACTION_TYPE.DELETE_APPLICATION_TO_WORKFLOW:
			var nodes = state.workflow.nodes;
			var appIndex = nodes.findIndex(
				(app) =>
					app.workflowApplicationID ===
					action.payload.workflowApplicationId
			);
			nodes.splice(appIndex, 1);
			var system_attributes =
				action.payload.system_attributes ||
				state.workflow.system_attributes;
			var variables =
				action.payload.variables || state.workflow.variables;
			return Object.assign({}, state, {
				workflow: refreshActionIndex(
					Object.assign({}, state.workflow, {
						nodes: nodes,
						system_attributes: system_attributes,
						variables: variables,
					})
				),
				editApplication: null,
				editActionWorkflow: null,
				recommedendActions: null,
			});
		case ACTION_TYPE.DELETE_ACTION_TO_WORKFLOW:
			var nodes = state.workflow.nodes;
			var appIndex = nodes.findIndex(
				(app) =>
					app.workflowApplicationID ===
					action.payload.workflowApplicationId
			);
			var actionIndex = nodes[appIndex].actions.findIndex(
				(act) =>
					act.workflowActionID === action.payload.workflowActionId
			);
			nodes[appIndex].actions.splice(actionIndex, 1);
			var system_attributes =
				action.payload.system_attributes ||
				state.workflow.system_attributes;
			var variables =
				action.payload.variables || state.workflow.variables;
			return Object.assign({}, state, {
				workflow: refreshActionIndex(
					Object.assign({}, state.workflow, {
						nodes,
						system_attributes: system_attributes,
						variables: variables,
					})
				),
				editActionWorkflow: null,
			});
		case ACTION_TYPE.SET_WORKFLOW_EXECUTED:
			return Object.assign({}, state, {
				workflow: Object.assign({}, state.workflow, {
					isExecuted: true,
					editApplication: null,
					editActionWorkflow: null,
					recommedendActions: null,
				}),
			});
		case ACTION_TYPE.UPDATE_WORKFLOW:
			return Object.assign({}, state, {
				workflow: refreshActionIndex(action.payload),
				editActionWorkflow: null,
			});
		case ACTION_TYPE.WORKFLOWS_GET_ALL_TEMPLATES:
			return { ...state, templates: action.payload };
		case ACTION_TYPE.WORKFLOWS_GET_MOST_USED_TEMPLATES:
			return { ...state, mostUsedTemplates: action.payload };
		case ACTION_TYPE.WORKFLOWS_GET_TOP_FIVE_DRAFTS:
			return { ...state, overviewDrafts: action.payload };
		case ACTION_TYPE.WORKFLOWS_GET_ALL_DRAFTS:
			return { ...state, drafts: action.payload };
		case ACTION_TYPE.EMPTY_TEMPLATES:
			return { ...state, templates: null };
		case ACTION_TYPE.WORKFLOWS_GET_ALL_COMPLETED_WORKFLOWS:
			return { ...state, completed: action.payload };
		case ACTION_TYPE.WORKFLOWS_GET_AUTOMATION_RULES:
			return { ...state, automationRules: action.payload };
		case ACTION_TYPE.WORKFLOWS_GET_SCHEDULED_RUNS:
			return { ...state, scheduledRuns: action.payload };
		case ACTION_TYPE.WORKFLOWS_UPDATE_AUTOMATION_RULES_ORDER:
			return {
				...state,
				automationRules: Object.assign({}, state.automationRules, {
					data: action.payload,
				}),
			};
		case ACTION_TYPE.WORKFLOWS_UPDATE_AUTOMATION_RULES:
			const rules = { ...state.automationRules };
			const i = rules.data.findIndex(
				(rule) => rule._id === action.payload.data._id
			);
			if (i > -1) {
				rules.data[i] = action.payload.response;
			}
			return { ...state, automationRules: rules };

		case ACTION_TYPE.UPDATE_APP_AUTOMATION_RULES:
			const appRules = { ...state.appRules };
			const ruleIndex = appRules.data.findIndex(
				(rule) => rule._id === action.payload.data._id
			);
			if (ruleIndex > -1) {
				appRules.data[ruleIndex] = action.payload.response;
			}
			return { ...state, appRules: appRules };

		case ACTION_TYPE.WORKFLOWS_UPDATE_AUTOMATION_RULE:
			return {
				...state,
				automationRule: action.payload.data,
				initialAutomationRuleState: action.payload.data,
			};
		case ACTION_TYPE.UPDATE_APP_AUTOMATION_RULE:
			return {
				...state,
				appAutomationRule: action.payload.data,
				initialAppAutomationRule: action.payload.data,
			};
		case ACTION_TYPE.WORKFLOWS_GET_AUTOMATION_RULE:
			return {
				...state,
				automationRule: action.payload.error
					? action.payload
					: new WorkFlowAutomationRuleResponseModel(action.payload),
				initialAutomationRuleState: action.payload.error
					? action.payload
					: new WorkFlowAutomationRuleResponseModel(action.payload),
			};
		case ACTION_TYPE.GET_APP_AUTOMATION_RULE:
			return {
				...state,
				appAutomationRule: new AppAutomationRuleResponseModel(
					action.payload
				),
				initialAppAutomationRule: new AppAutomationRuleResponseModel(
					action.payload
				),
			};
		case ACTION_TYPE.CLEAR_APP_AUTOMATION_RULE_DATA:
			return {
				...state,
				appAutomationRule: null,
				initialAppAutomationRule: null,
			};
		case ACTION_TYPE.GET_APP_AUTOMATION_RULES:
			return {
				...state,
				appRules: action?.payload,
			};
		case ACTION_TYPE.SET_EDIT_AUTOMATION_RULE:
			return { ...state, automationRule: action.payload.action };
		case ACTION_TYPE.SET_EDIT_APP_AUTOMATION_RULE:
			return { ...state, appAutomationRule: action.payload.action };
		case ACTION_TYPE.WORKFLOWS_GET_IN_PROGRESS_WORKFLOWS:
			return { ...state, inProgress: action.payload };
		case ACTION_TYPE.WORKFLOWS_GET_ONBOARDING_USERS:
			return { ...state, users: action.payload };
		case ACTION_TYPE.WORKFLOWS_GET_ACTIONS_BY_WORKFLOW_ID:
			return { ...state, actions: action.payload };
		case ACTION_TYPE.WORKFLOWS_GET_SEARCHED_USERS:
			return { ...state, users: action.payload };
		case ACTION_TYPE.WORKFLOWS_GET_SEARCHED_USERS_FOR_DRAFT:
			return { ...state, usersForDraft: action.payload };
		case ACTION_TYPE.GET_RECOMMENDED_APPS:
			return Object.assign({}, state, {
				recommendedApps: action.payload.data
					.map((res) => {
						res.app_id = res._id;
						return res;
					})
					.map((res) => new WorkFlowApplicationModel(res)),
			});
		case ACTION_TYPE.GET_RECOMMENDED_ACTIONS:
			return Object.assign({}, state, {
				recommedendActions:
					action.payload.data.length === 0
						? []
						: action.payload.data.actions
								.map((res) => {
									res.action_id = res._id;
									res.group = "Recommended";
									if (res.inputFields?.length === 0) {
										res.data_reusable = true;
									}
									return res;
								})
								.map((res) => new WorkFlowActionModel(res)),
			});
		case ACTION_TYPE.SET_EDIT_ACTION:
			return Object.assign({}, state, {
				editActionWorkflow: action.payload.action,
			});
		case ACTION_TYPE.SET_EDIT_APP_ID:
			let recommedendAction = state.recommedendActions;
			if (!action.payload) {
				recommedendAction = null;
			}
			return Object.assign({}, state, {
				editApplication: action.payload,
				editActionWorkflow: null,
				recommedendActions: recommedendAction,
			});
		case ACTION_TYPE.GET_LIST_OF_RUNS:
			return { ...state, listOfRuns: action.payload.data };
		case ACTION_TYPE.GET_SUMMARISED_RUN_LOGS:
			let summarisedLogs = { ...action.payload.data };

			if (summarisedLogs?.executions) {
				let validRetryAttempt = getFailedRetriesForWorkflow(
					summarisedLogs?.executions
				);
				summarisedLogs.validRetryAttempt = validRetryAttempt;
				summarisedLogs?.run_log.map((runLog) => {
					if (
						runLog?.action?.action_status === "failed" ||
						runLog?.action?.action_status ===
							"completed_with_errors"
						// || runLog?.action?.action_status === "aborted"
					) {
						let actionRetryAttempt =
							getFailedRetriesForWorkflowActions(
								summarisedLogs?.executions,
								true,
								runLog?.action?.action_id
							);
						runLog.action.validRetryAttempt = actionRetryAttempt;
					}
				});
			}
			return { ...state, summarisedRunLogs: summarisedLogs };
		case ACTION_TYPE.GET_RAW_RUN_LOGS:
			let rawLogs = { ...action.payload.data };

			if (rawLogs?.executions) {
				let validRetryAttempt = getFailedRetriesForWorkflow(
					rawLogs?.executions
				);
				rawLogs.validRetryAttempt = validRetryAttempt;
				rawLogs?.run_log.map((rawLog) => {
					if (
						rawLog?.action_status === "failed" ||
						rawLog?.action_status === "aborted" ||
						rawLog?.action_status === "completed_with_errors"
					) {
						let actionRetryAttempt =
							getFailedRetriesForWorkflowActions(
								rawLogs?.executions,
								true,
								rawLog?.action_id
							);
						rawLog.validRetryAttempt = actionRetryAttempt;
					}
				});
			}
			return { ...state, rawRunLogs: rawLogs };
		case ACTION_TYPE.GET_ALL_MANUAL_TASK_TEMPLATES:
			let template = [];
			if (
				Array.isArray(action.payload.data) &&
				action.payload.data.length > 0
			) {
				template = action.payload.data.map((temp) => {
					return new AllManualTaskTemplatesModel(temp);
				});
			} else {
				template = [];
			}
			return Object.assign({}, state, {
				allManualTaskTemplates: template,
			});
		case ACTION_TYPE.GET_MANUAL_TASK_TEMPLATE_DATA:
			let resp = action.payload.data;
			return Object.assign({}, state, {
				manualTaskTemplateData: resp,
			});
		case ACTION_TYPE.CLEAR_MANUAL_TASK_TEMPLATE_DATA:
			return Object.assign({}, state, {
				manualTaskTemplateData: null,
				allManualTaskTemplates: [],
			});
		case ACTION_TYPE.CLEAR_WORKFLOW:
			return Object.assign({}, state, {
				workflow: {},
				editActionWorkflow: null,
				editApplication: null,
				recommendedApps: null,
				recommedendActions: null,
				listOfRuns: {},
				appDescription: [],
				allManualTaskTemplates: [],
				manualTaskTemplateById: null,
				manualTaskTemplateData: null,
				selected_users: [],
				integrationAccounts: {},
			});
		case ACTION_TYPE.CLEAR_WORKFLOW_LOGS:
			return Object.assign({}, state, {
				summarisedRunLogs: {},
				rawRunLogs: {},
			});
		case ACTION_TYPE.CLEAR_LIST_OF_WORKFLOWS:
			return {
				...state,
				drafts: null,
				overviewDrafts: null,
				templates: null,
				mostUsedTemplates: null,
				inProgress: null,
				completed: null,
				automationRules: null,
				automationRule: null,
				appAutomationRule: null,
			};
		case ACTION_TYPE.GET_OFFBOARDING_TASKS_LIST:
			return Object.assign({}, state, {
				offboardingTasks: new WorkFlowOffboardingDashboardModel(
					action.payload.data
				),
			});
		case ACTION_TYPE.MARK_OFFBOARDING_TASK_COMPLETE:
			const { response, req } = action.payload;
			const newOffboardingTask = { ...state.offboardingTasks };
			if (response) {
				newOffboardingTask.actions.length &&
					newOffboardingTask.actions.forEach((action) => {
						if (action && response.modified_action_ids.length) {
							response.modified_action_ids.forEach((id) => {
								if (
									action.workflowActionId ===
										id.workflow_action_id &&
									action.appId === id.app_id
								) {
									action.actionStatus = "completed";
									action.appActionStatus = "completed";
								}
							});
						}
					});
			}
			return Object.assign({}, state, {
				offboardingTasks: newOffboardingTask,
			});
		case ACTION_TYPE.SIGN_OFFBOARDING_DECLARATION_TASK:
			const rsp = action.payload.response;
			const newOffboardingTasks = { ...state.offboardingTasks };
			if (rsp.success) {
				newOffboardingTasks.declarationSigned = true;
			}
			return Object.assign({}, state, {
				offboardingTasks: newOffboardingTasks,
			});
		case ACTION_TYPE.ACTION_SCOPE_VALIDATIONS:
			const result = {};
			if (action.payload.data.actionMetadata) {
				action.payload.data.actionMetadata.forEach((actionObj) => {
					actionObj.currentScopes =
						action.payload.data.currentScopesObjects;
					result[actionObj.actionId] = actionObj;
				});
			} else {
				result.error = action.payload.data;
			}

			let scopeValidations =
				Object.assign(
					{},
					state.scopeValidations[action.payload.orgIntegrationID]
				) || {};
			return Object.assign({}, state, {
				scopeValidations: Object.assign({}, state.scopeValidations, {
					[action.payload.orgIntegrationID]: Object.assign(
						scopeValidations,
						result
					),
				}),
			});
		case ACTION_TYPE.GET_PENDING_APP_LIST:
			return Object.assign({}, state, {
				pendingAppList: action.payload.response,
			});
		case ACTION_TYPE.ADD_PENDING_APP_TO_WORKFLOW:
			var newWorkflow = new WorkflowModel(action.payload.response);
			return Object.assign({}, state, {
				workflow: refreshActionIndex(newWorkflow),
			});
		case ACTION_TYPE.SELECTED_USERS:
			return Object.assign({}, state, {
				selected_users: action.payload,
			});
		case ACTION_TYPE.COMPILED_WORKFLOW_RESPONSE:
			const exec_docs = action?.payload?.map((workflow) =>
				refreshActionIndex(workflow)
			);
			return Object.assign({}, state, {
				compiledExecDocs: exec_docs,
				compileExecutionError: {},
			});
		case ACTION_TYPE.COMPILED_WORKFLOW_ERROR_RESPONSE:
			return Object.assign({}, state, {
				compileExecutionError: action?.payload,
			});

		case ACTION_TYPE.CLEAR_COMPILED_WORKFLOW_ERROR_RESPONSE:
			return Object.assign({}, state, {
				compileExecutionError: {},
			});
		case ACTION_TYPE.UPDATE_DEPS_TRIGGER_RESPONSE:
			return {
				...state,
				depsTriggerResponseITSM: {
					...state.depsTriggerResponseITSM,
					...action.payload.response,
				},
			};
		case ACTION_TYPE.CLEAR_DEPS_TRIGGER_RESPONSE:
			return {
				...state,
				depsTriggerResponseITSM: {},
			};

		case ACTION_TYPE.UPDATE_TOGGLE_STATE_RUN_LOGS:
			const obj = action.payload;
			return {
				...state,
				toggleStateRunLogs: obj,
			};
		case ACTION_TYPE.CLEAR_TOGGLE_STATE_RUN_LOGS:
			return {
				...state,
				toggleStateRunLogs: {},
			};
		case ACTION_TYPE.GET_PLAYBOOK_VARIABLES:
		case ACTION_TYPE.UPDATE_VARIABLES_IN_PLAYBOOK:
			return {
				...state,
				workflow: {
					...state.workflow,
					variables: action.payload,
				},
			};
		case ACTION_TYPE.UPDATE_SINGLE_VARIABLE_IN_PLAYBOOK:
			const newVariables = [...state.workflow.variables];
			const varIndex = newVariables.findIndex(
				(variable) => variable.id === action.payload.id
			);
			if (varIndex < 0) return state;
			newVariables[varIndex] = {
				...newVariables[varIndex],
				...action.payload,
			};
			return {
				...state,
				workflow: {
					...state.workflow,
					variables: newVariables,
				},
			};
		default:
			return state;
	}
}

const refreshActionIndex = (workflow) => {
	let index = 1;
	workflow.nodes = workflow.nodes.map((app) => {
		app.actions = app.actions.map((action) => {
			action.index = index;
			index++;
			return action;
		});
		return app;
	});
	return workflow;
};

const getFailedRetriesForWorkflow = (executions) => {
	let validRetryAttempt = true;

	for (let i = 0; i < executions.length && validRetryAttempt; i++) {
		if (executions[i].type === "workflow" && !executions[i].log_refreshed) {
			if (
				(executions[i].n8n_workflow_status !== "failed" ||
					executions[i].n8n_workflow_status !== "aborted") &&
				!executions[i].log_refresh_error
			) {
				validRetryAttempt = false;
			}
		}
	}
	return validRetryAttempt;
};

const getFailedRetriesForWorkflowActions = (
	executions,
	validRetryAttempt,
	actionId
) => {
	for (let i = 0; i < executions.length && validRetryAttempt; i++) {
		if (executions[i].type === "workflow" && !executions[i].log_refreshed) {
			if (
				(executions[i].n8n_workflow_status !== "failed" ||
					executions[i].n8n_workflow_status !== "aborted") &&
				!executions[i].log_refresh_error
			) {
				validRetryAttempt = false;
			}
		} else if (
			executions[i].type === "action" &&
			executions[i].action_id.toString() === actionId.toString() &&
			!executions[i].log_refreshed
		) {
			if (
				(executions[i].n8n_workflow_status !== "failed" ||
					executions[i].n8n_workflow_status !== "aborted") &&
				!executions[i].log_refresh_error
			) {
				validRetryAttempt = false;
			}
		}
		return validRetryAttempt;
	}
	return validRetryAttempt;
};

export function TransformCompletedV2(response) {
	let data = [];
	if (response && response.data && Array.isArray(response.data)) {
		response.data.forEach((element) => {
			let obj = new CompletedV2(element);
			let apps = obj.workflow_apps.map((app) => {
				return new CompletedApps(app);
			});
			obj.workflow_apps = apps;
			data.push(obj);
		});
	}
	return data;
}
