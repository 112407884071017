import React from "react";
import arrowInt from "assets/v2tables/arrow-int.svg";

export function EmptyDirectIntegration({ component }) {
	return (
		<span
			style={{
				color: "#2266E2",
			}}
			className="v2table__cell-text cursor-pointer ml-2"
		>
			Connect Now
			<img
				className="ml-1"
				width={"15px"}
				src={arrowInt}
				alt="arrow Icon"
			/>
		</span>
	);
}
