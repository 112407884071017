import RulesDebouncedSelect from "modules/transactionMapping/components/Rules/RulesSetupBodyComponents/RulesDebouncedSelect";
import styled from "styled-components";
import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@zluri/ui-components";

const DropdownWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
`;

const DropdownContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px;
	border-radius: 8px;
	background: var(--secondary-grey-7, #f6f7fa);
	width: 100%;
`;

const DropdownTitle = styled.h2`
	font-size: 12px;
	font-style: normal;
	font-weight: 600;
	line-height: 120%;
	margin: 0px;
`;

const DropdownComponentWrapper = styled.div`
	width: ${({ $width }) => $width};
`;

const ModalDropdown = ({
	title,
	text,
	dropdownLabel,
	dropdownOptions,
	value,
	fetchOptionsHandler,
	optionRender,
	onValueSelect,
	width = "257px",
	prefixIcon,
}) => {
	return (
		<DropdownWrapper>
			{text ? (
				<Typography
					variant="body_2_regular"
					style={{ marginBottom: "10px" }}
				>
					{text}
				</Typography>
			) : null}
			<DropdownContainer>
				<DropdownTitle>{title}</DropdownTitle>
				<DropdownComponentWrapper $width={width}>
					<RulesDebouncedSelect
						multiple={false}
						placeholder={dropdownLabel}
						width={width}
						value={value}
						options={dropdownOptions}
						optionRender={optionRender}
						debounceTimeout={700}
						fetchOptions={fetchOptionsHandler}
						onValueSelect={onValueSelect}
						prefixIcon={prefixIcon}
					/>
				</DropdownComponentWrapper>
			</DropdownContainer>
		</DropdownWrapper>
	);
};

export default ModalDropdown;

ModalDropdown.propTypes = {
	text: PropTypes.string,
	title: PropTypes.string,
	dropdownLabel: PropTypes.string,
	dropdownOptions: PropTypes.array,
	value: PropTypes.array,
	fetchOptionsHandler: PropTypes.func,
	optionRender: PropTypes.node,
	onValueSelect: PropTypes.func,
	width: PropTypes.string,
	prefixIcon: PropTypes.node,
};
