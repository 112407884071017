import React, { createContext, useContext, useMemo, useState } from "react";

import PropTypes from "prop-types";
import {
	DndContext,
	DragOverlay,
	MouseSensor,
	TouchSensor,
	defaultDropAnimationSideEffects,
	useSensor,
	useSensors,
} from "@dnd-kit/core";
import {
	SortableContext,
	useSortable,
	verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS as cssDndKit } from "@dnd-kit/utilities";

import { SingleNote } from "../NoteCard/SingleNote";

import DragIcon from "assets/v2tables/draggable-icon.svg";

const SortableItemContext = createContext({
	attributes: {},
	listeners: undefined,
	ref() {},
});

export function NoteSortableItem({ id, children }) {
	const {
		setNodeRef,
		transform,
		transition,
		listeners,
		attributes,
		isDragging,
		setActivatorNodeRef,
	} = useSortable({
		id,
	});

	const style = {
		opacity: isDragging ? 0.8 : undefined,
		transform: cssDndKit.Translate.toString(transform),
		transition,
	};

	const context = useMemo(
		() => ({
			attributes,
			listeners,
			ref: setActivatorNodeRef,
		}),
		[attributes, listeners, setActivatorNodeRef]
	);

	return (
		<SortableItemContext.Provider value={context}>
			<div ref={setNodeRef} style={style}>
				{children}
			</div>
		</SortableItemContext.Provider>
	);
}

export function NoteDragHandle() {
	const { attributes, listeners, ref } = useContext(SortableItemContext);
	const [isGrabbed, setIsGrabbed] = useState(false);

	return (
		<div {...attributes} {...listeners} ref={ref}>
			<img
				src={DragIcon}
				height="12px"
				width="12px"
				alt="drag"
				onMouseDown={() => setIsGrabbed(true)}
				onMouseUp={() => setIsGrabbed(false)}
				style={{
					transform: "translateY(-1px)",
					cursor: isGrabbed ? "grabbing" : "grab",
				}}
			/>
		</div>
	);
}

function NoteSortableOverlay({ children }) {
	const dropAnimationConfig = {
		sideEffects: defaultDropAnimationSideEffects({
			styles: {
				active: {
					opacity: "0.4",
				},
			},
		}),
	};

	return (
		<DragOverlay dropAnimation={dropAnimationConfig}>
			<div style={{ cursor: "grabbing" }}>{children}</div>
		</DragOverlay>
	);
}

export function NotesRearrangable({ items, onSortEnd, children }) {
	const getIndex = (id) => {
		return items?.findIndex((item) => item?._id === id);
	};

	const sensors = useSensors(
		useSensor(MouseSensor, {
			activationConstraint: {
				distance: 10,
			},
		}),
		useSensor(TouchSensor, {
			activationConstraint: {
				delay: 250,
				tolerance: 5,
			},
		})
	);

	const [activeId, setActiveId] = useState();

	const activeItem = useMemo(
		() => items?.find((item) => item?._id === activeId),
		[activeId, items]
	);

	return (
		<DndContext
			sensors={sensors}
			onDragStart={({ active }) => {
				if (active) {
					setActiveId(active?.id);
				}
			}}
			onDragEnd={({ active, over }) => {
				if (over && active?.id !== over?.id) {
					onSortEnd({
						oldIndex: getIndex(active?.id),
						newIndex: getIndex(over?.id),
					});
				}
				setActiveId(null);
			}}
			onDragCancel={() => setActiveId(null)}
		>
			<SortableContext
				items={items}
				strategy={verticalListSortingStrategy}
			>
				{children}
			</SortableContext>
			<NoteSortableOverlay>
				{activeItem ? <SingleNote initialNote={activeItem} /> : null}
			</NoteSortableOverlay>
		</DndContext>
	);
}

NoteSortableItem.propTypes = {
	id: PropTypes.string,
	children: PropTypes.element,
};

NoteSortableOverlay.propTypes = {
	children: PropTypes.element,
};

NotesRearrangable.propTypes = {
	children: PropTypes.element,
	items: PropTypes.array,
	onSortEnd: PropTypes.func,
};
