import { Typography, DatePicker } from "@zluri/ui-components";
import React, { useEffect, useState } from "react";
import { capitalizeFirstLetter } from "utils/common";
import "./CondtionalSelect.css";
import { dateResetTimeZone } from "utils/DateUtility";
import downArrowToggle from "assets/dropdownarrow-black.svg";

const inputTypeComponent = {
	date: DatePicker,
};

export default function CondtionalSelect({
	onChange,
	text = "",
	selectedIndex,
	options,
	filterData,
	className,
}) {
	const [selected, setSelected] = useState(selectedIndex);
	const [searchValue, setSearchValue] = useState(text);
	const [rangeValue1, setRangeValue1] = useState(text);
	const [rangeValue2, setRangeValue2] = useState(text);
	useEffect(() => {
		if (text && Array.isArray(text)) {
			setRangeValue1(text?.[0]);
			setRangeValue2(text?.[1]);
			setSearchValue("");
		} else if (text) {
			setSearchValue(text);
			setRangeValue1("");
			setRangeValue2("text?.[1]");
		}
	}, [text]);
	useEffect(() => {
		if (selectedIndex > 0) {
			setSelected(selectedIndex);
		}
	}, [selectedIndex]);
	return (
		<div
			className={`d-flex flex-column ${className} px-2 z-conditional-select`}
		>
			{options.map((filter, idx) => (
				<div key={filter.label} className="d-flex flex-column">
					<div className="d-flex text-left align-items-center py-2 gap-8">
						<input
							className="cursor-pointer v2-text"
							style={{ height: 14 }}
							type="radio"
							name={filter?.label}
							id={filter?.label}
							label={filter?.label}
							checked={idx === selected}
							// value={"Contains"}
							onClick={() => {
								setSelected(idx);
								//onChange(searchValue, idx);
							}}
						/>
						<label
							style={{
								fontFamily: "Sora",
								fontStyle: "normal",
								fontWeight: 600,
								fontSize: 10,
								lineHeight: "150%",
								marginBottom: 0,
								/* identical to box height, or 15px */
								textAlign: "center",
								/* Secondary_Grey/2 */
								color: "#484848",
							}}
							htmlFor={filter?.label}
						>
							<>
								<img
									src={filter?.icon}
									width={16}
									className="mr-1"
									alt=""
								/>

								<Typography
									variant={"button_extrasmall_semibold"}
									color="secondary_grey_2"
								>
									{filter?.label}
								</Typography>
							</>
						</label>
					</div>
					{selected === idx &&
						(filter.inputType !== "range" ? (
							<div className="">
								{(inputTypeComponent[filter?.inputType] &&
									React.createElement(
										inputTypeComponent[filter?.inputType],
										{
											value: rangeValue1,
											calendarClassName: "fixed-calendar",
											setDisabledDate: () => false,
											suffixIcon: downArrowToggle,
											onChange: (val) => {
												setSearchValue(val);
												onChange(
													dateResetTimeZone(
														new Date(val)
													),
													idx
												);
											},
										}
									)) || (
									<input
										className="grey-1 px-2 py-3 range__filter-input border-radius-8"
										style={{
											border: "1px solid #DDDDDD",
											borderRadius: "8px !important",
										}}
										type={
											filter?.inputType
												? filter?.inputType
												: "text"
										}
										placeholder={
											filter?.inputType
												? capitalizeFirstLetter(
														filter?.inputType
													)
												: "Text"
										}
										value={
											filterData?.field_order?.toString() ===
											filter?.api_values?.field_order?.toString()
												? searchValue
												: ""
										}
										onChange={(e) => {
											setSearchValue(e.target.value);
											onChange(e.target.value, idx);
										}}
									/>
								)}
							</div>
						) : (
							<div className="d-flex align-items-center">
								{(inputTypeComponent[filter?.rangeType] &&
									React.createElement(
										inputTypeComponent[filter?.rangeType],
										{
											value: rangeValue1,
											calendarClassName: "fixed-calendar",
											suffixIcon: downArrowToggle,
											showFooter: true,
											setDisabledDate: () => false,
											onChange: (val) => {
												setRangeValue1(val);
												onChange(
													[val, rangeValue2],
													idx
												);
											},
										}
									)) || (
									<input
										className="grey-1 px-2 py-3 mr-1 range__filter-input border-radius-8 "
										style={{
											border: "1px solid #DDDDDD",
										}}
										type={filter?.rangeType || "number"}
										placeholder={
											filter?.inputType
												? capitalizeFirstLetter(
														filter?.inputType
													)
												: "Number"
										}
										value={rangeValue1}
										onChange={(e) => {
											setRangeValue1(e.target.value);
											onChange(
												[e.target.value, rangeValue2],
												idx
											);
										}}
									/>
								)}{" "}
								-
								{(inputTypeComponent[filter?.rangeType] &&
									React.createElement(
										inputTypeComponent[filter?.rangeType],
										{
											value: rangeValue2,
											calendarClassName: "fixed-calendar",
											showFooter: true,
											setDisabledDate: () => false,
											suffixIcon: downArrowToggle,
											onChange: (val) => {
												setRangeValue1(val);
												onChange(
													[
														rangeValue1,
														val
															? dateResetTimeZone(
																	new Date(
																		val
																	)
																)
															: null,
													],
													idx
												);
											},
										}
									)) || (
									<input
										className="grey-1 px-2 py-3 w-100 flex-1 ml-1 range__filter-input border-radius-8 "
										style={{
											border: "1px solid #DDDDDD",
											borderRadius: "8px !important",
										}}
										type={filter?.rangeType || "number"}
										placeholder={
											filter?.inputType
												? capitalizeFirstLetter(
														filter?.inputType
													)
												: "Number"
										}
										value={rangeValue2}
										onChange={(e) => {
											setRangeValue2(e.target.value);
											onChange(
												[rangeValue1, e.target.value],
												idx
											);
										}}
									/>
								)}
							</div>
						))}
				</div>
			))}
		</div>
	);
}
