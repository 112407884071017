/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState, useContext } from "react";
import "./Select.css";
import search from "../../assets/search.svg";
import arrowdropdown from "../../assets/arrowdropdown.svg";
import viewGridIcon from "../../assets/icons/grid.svg";
import refresh from "../../assets/new-refresh-icon.svg";
import defaultViewIcon from "../../assets/default_view_icon.svg";
import { useOutsideClickListener } from "../../utils/clickListenerHook";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { Button } from "../Button/Button";
import { viewIconsMap } from "../../common/tableView";
import SaveCustomView from "../../common/saveCustomView";
import { CUSTOM_VIEW_NAME, STANDARD_VIEW_NAME } from "../../constants/views";
import {
	BODY_VARIANT,
	GREY_VARIANT,
	Typography,
	Tooltip,
} from "@zluri/ui-components";
import "../../components/Applications/AllApps/NewDropdowns.css";
import RoleContext from "services/roleContext/roleContext";
import { openModal } from "reducers/modal.reducer";
import styled from "styled-components";
import edit from "../../assets/sharableViews/editSharableViews.svg";
import deleteIcon from "../../assets/sharableViews/deleteSharableViews.svg";

const DefaultTextWrapper = styled.span`
	margin-left: 2px;
	border-radius: 14px;
	background: var(--glow-light, rgba(90, 186, 255, 0.1));
	padding: 2px 4px;
	display: flex;
	align-items: center;
`;

const ViewTitleWrapper = styled.div`
	display: flex;
	height: 16px;
	align-items: center;
`;

const ViewTitle = styled.span`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 120px;
`;

const ViewContentWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 4px;
`;

const ViewOptionsWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 6px;
`;

// eslint-disable-next-line react/prop-types
const bulk_edit_menu = React.forwardRef(({ children, onClick }, ref) => (
	// eslint-disable-next-line jsx-a11y/anchor-is-valid
	<a
		className="cursor-pointer autho__dd__cont mt-auto mb-auto text-decoration-none"
		ref={ref}
		onClick={(e) => {
			e.preventDefault();
			e.stopPropagation();
			onClick(e);
		}}
		style={{ width: "100px", border: "none", height: "auto" }}
	>
		{children}
	</a>
));

bulk_edit_menu.displayName = "bulk_edit_menu";

export function SelectOld(props) {
	const {
		showIcons,
		isView,
		options: dropdownList,
		isPrimarySource,
		setOptionChanged,
		saving = false,
		setShowCustomViewModal,
		handleDefaultViewAction,
		handleDeleteView,
		isLoading,
		setCustomViewSaved,
		selectedItem,
		selectedItemName,
		viewOptions,
		handleSubmit,
		optionClassName,
		loadingMoreOptions,
		groupBy,
		value: propValue,
		loadingOptions: propLoadingOptions,
		label,
		onSelect,
		className,
		style,
		icon,
		isSearchable,
		inputTextStyle,
		optionListClass,
	} = props;
	const { isItViewer } = useContext(RoleContext);
	const [value, setValue] = useState(propValue ? propValue : null);
	const [query, setQuery] = useState("");
	const [activeDefaultActionPopup, setActiveDefaultActionPopup] = useState();
	const [loadingOptions, setLoadingOptions] = useState(
		propLoadingOptions || false
	);

	const [primarySourceIcon, setPrimarySourceIcon] = useState(
		Array.isArray(props?.value) && props?.value[0]?._id?.logo_url
	);
	const router = useSelector((state) => state.router);
	const { query: queryParam } = router.location;
	const [groupByOptions, setGroupByOptions] = useState({});
	const dispatch = useDispatch();
	useEffect(() => {
		setQuery(selectedItemName);
		setValue();
	}, [selectedItemName]);

	useEffect(() => {
		if (!queryParam.metaData) {
			setQuery(props?.defaultValue?.name);
			setValue();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [queryParam]);

	useEffect(() => {
		if (groupBy && dropdownList.length) {
			setGroupByOptions(groupOptions(dropdownList, groupByOptions));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dropdownList]);

	const groupOptions = (data) => {
		const obj = {};
		data.forEach((item) => {
			let category;
			if (isView) {
				category = item[groupBy]
					? CUSTOM_VIEW_NAME
					: STANDARD_VIEW_NAME;
			} else {
				category = item[groupBy] ? item[groupBy] : "Others";
			}
			if (obj.hasOwnProperty(category)) {
				obj[category].push(item);
			} else {
				obj[category] = [item];
			}
		});
		return obj;
	};

	useEffect(() => {
		setLoadingOptions(propLoadingOptions);
	}, [propLoadingOptions]);

	const [showOptions, setShowOptions] = useState(false);
	const ref = useRef(null);

	const onChange = (e) => {
		setQuery(e.target.value);
	};

	const logicToSetValue = (value) => {
		if (typeof value === "string") {
			return dropdownList.find((option) => option.value === value);
		}
		return value;
	};

	const defaultOptionContainer = (options) => {
		return (
			Array.isArray(options) &&
			options?.map((option, index) => {
				return (
					<div
						className={
							isView
								? `px-2 py-2 z__select--options__container--option`
								: optionClassName
								? `z__select--options__container--option ${optionClassName}`
								: "z__select--options__container--option"
						}
						key={index}
						onClick={() => !saving && onOptionSelect(index)}
					>
						{showIcons && (
							<span className="pr-2">
								<img src={option.logo} alt="logo" />
							</span>
						)}
						<span>{option[label]}</span>
						{option?.description && (
							<div
								style={{
									fontSize: "10px",
									color: "#717171",
									textTransform: "initial",
								}}
								className="mt-2"
							>
								{option?.description}
							</div>
						)}
					</div>
				);
			})
		);
	};

	const DefaultOptionGroupContainer = ({ groupOptions }) => {
		const standardViewSubmit = async (e, option, data) => {
			return handleDefaultViewAction(e, option, data);
		};
		return Object.keys(groupOptions).map((group, index) => {
			const options = groupByOptions[group].map((option, index) => {
				return (
					<div key={`${group}-${index}`}>
						<div
							className={
								isView
									? `px-3 py-2 z__select--options__container--option`
									: optionClassName
									? `z__select--options__container--option ${optionClassName}`
									: "z__select--options__container--option"
							}
							key={index}
							onClick={() =>
								!saving && onOptionSelect(option, true)
							}
						>
							<ViewContentWrapper>
								<div
									style={{
										maxWidth: "200px",
									}}
								>
									<ViewTitleWrapper>
										{showIcons && (
											<div className="pr-2">
												<img
													src={
														viewIconsMap[
															option?.name
														] || defaultViewIcon
													}
													alt={option?.name}
												/>
											</div>
										)}
										<Tooltip
											content={
												<Typography
													variant={
														BODY_VARIANT.BODY_2_REGULAR
													}
													color="white"
												>
													{option[label]}
												</Typography>
											}
											placement="bottom"
											className="border-radius-8"
										>
											<ViewTitle>
												{option[label]}
											</ViewTitle>
										</Tooltip>
										{option.is_default ? (
											<DefaultTextWrapper>
												<Typography
													color={
														GREY_VARIANT.SECONDARY_GREY_2
													}
													variant="alerttag_extrasmall_medium"
												>
													Default
												</Typography>
											</DefaultTextWrapper>
										) : null}
									</ViewTitleWrapper>
									{option?.description && (
										<Tooltip
											content={
												<Typography
													variant={
														BODY_VARIANT.BODY_2_REGULAR
													}
													color="white"
												>
													{option?.description}
												</Typography>
											}
											placement="bottom"
											className="border-radius-8"
										>
											<div
												style={{
													fontSize: "10px",
													color: "#717171",
													textTransform: "initial",
													maxHeight: "26px",
													whiteSpace: "nowrap",
													overflow: "hidden",
													textOverflow: "ellipsis",
												}}
												className="mt-2"
											>
												{option?.description}
											</div>
										</Tooltip>
									)}
								</div>
								{option._id === propValue?._id ||
								!option.is_custom ? (
									<ViewOptionsWrapper
										className={
											!option.is_custom
												? "show-on-hover-content"
												: ""
										}
									>
										<img
											src={edit}
											alt="edit"
											onClick={(e) => {
												e.stopPropagation();
												e.preventDefault();
												dispatch(
													openModal(
														"customViewModal",
														{
															viewData: option,
															show: true,
															handleSubmit:
																option.is_custom
																	? handleSubmit
																	: (
																			...options
																	  ) =>
																			standardViewSubmit(
																				e,
																				option,
																				options[2]
																			),
															viewOptions,
															isEdit: true,
															modalType: "edit",
														}
													)
												);
											}}
										/>
										{option?.is_custom && !isLoading ? (
											<img
												src={deleteIcon}
												alt="delete"
												onClick={(e) => {
													e.stopPropagation();
													e.preventDefault();
													dispatch(
														openModal(
															"customViewModal",
															{
																viewData:
																	option,
																show: true,
																handleSubmit,
																viewOptions,
																isEdit: true,
																modalType:
																	"delete",
																handleDelete:
																	handleDeleteView,
															}
														)
													);
												}}
											/>
										) : null}
									</ViewOptionsWrapper>
								) : null}
							</ViewContentWrapper>
						</div>
					</div>
				);
			});
			return (
				<div className="z-select-group-container" key={index}>
					<div className="z-select-group-info p-1 grey-1 pl-3">
						<h3 className="text-uppercase bold-600 font-10 m-0">
							{group}
						</h3>
					</div>
					{options}
					{isView && !isItViewer && (
						<SaveCustomView
							group={group}
							groupByOptions={groupByOptions}
							setShowCustomViewModal={setShowCustomViewModal}
							setCustomViewSaved={setCustomViewSaved}
							handleSubmit={handleSubmit}
							viewOptions={viewOptions}
						/>
					)}
				</div>
			);
		});
	};

	const primarySourcesOptions = (options) => {
		return options?.map((option, index) => {
			let primarySource = option.org_integration_id
				? {
						...option.org_integration_id,
						logo_url: option.integration_id.logo_url,
				  }
				: option.integration_id;
			if (!(primarySource && primarySource.name)) {
				return null;
			}
			return (
				<>
					{primarySource && (
						<div
							className={
								isView
									? `px-2 py-2 z__select--options__container--option`
									: optionClassName
									? `z__select--options__container--option ${optionClassName}`
									: "z__select--options__container--option"
							}
							key={index}
							onClick={() => onOptionSelect(index)}
						>
							{primarySource.logo_url && (
								<span className="pr-2">
									<img
										width="24px"
										src={primarySource.logo_url}
										alt="logo"
									/>
								</span>
							)}
							<span>{primarySource.name}</span>
						</div>
					)}
				</>
			);
		});
	};

	const onOptionSelect = (index, isObj = false) => {
		if (isObj) {
			setValue(index);
		} else {
			setValue(dropdownList[index]);
		}
		setOptionChanged && setOptionChanged(true);
	};

	const onValueChange = () => {
		if (isPrimarySource && value) {
			setQuery(value?.integration_id?.name);
			setPrimarySourceIcon(value?.integration_id?.logo_url);
		} else if (value) {
			setQuery(value[label]);
		}
		setValue(logicToSetValue(value));
		setActiveDefaultActionPopup(null);
		setShowOptions(false);
		onSelect(value);
	};

	const showOptionsConatiner = () => {
		setShowOptions(!showOptions);
	};

	useEffect(() => {
		if (value) {
			onValueChange();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value]);

	useOutsideClickListener(
		ref,
		() => {
			setShowOptions(false);
			setActiveDefaultActionPopup(null);
		},
		[],
		["default-ui__wrapper"]
	);

	return (
		<div
			className={className ? `z__select ${className}` : `z__select`}
			style={{
				...style,
				paddingLeft: icon && isSearchable ? "34px" : "",
			}}
			ref={ref}
		>
			{loadingOptions && (
				<Spinner className="select-spinner" animation="border" />
			)}

			{isSearchable && (
				<>
					{icon && (
						<img
							className="z__select--icon"
							src={search}
							aria-hidden="true"
							alt="search"
						/>
					)}
					<input
						type="text"
						name="z__select"
						value={query}
						onChange={(e) => {
							onChange(e);
						}}
						placeholder={props.placeholder}
						onClick={!saving && showOptionsConatiner}
					/>
				</>
			)}

			{!isSearchable && (
				<div
					className={
						props.dropdownOnClick
							? "z__select--input dropdown-click"
							: "z__select--input"
					}
					style={{ ...inputTextStyle }}
					onClick={() => {
						if (!saving && !loadingOptions) {
							showOptionsConatiner();
						}
					}}
				>
					{query ? (
						<span
							className={
								props.inputPlaceholderClassName
									? `${props.inputPlaceholderClassName}`
									: ""
							}
							style={{ ...props.inputTextSpanStyle }}
						>
							{showIcons && (
								<img
									style={{
										filter:
											!isPrimarySource &&
											"brightness(0.1)",
									}}
									className="pr-2"
									src={
										isPrimarySource
											? primarySourceIcon
											: viewGridIcon
									}
									width="24"
									alt="source"
								/>
							)}
							{query}
						</span>
					) : (
						<span
							className={
								props.inputPlaceholderClassName
									? `${props.inputPlaceholderClassName}`
									: ""
							}
						>
							{showIcons && (
								<img
									style={{
										filter:
											!isPrimarySource &&
											"brightness(0.1)",
									}}
									className="pr-2"
									src={
										props.placeholderImg
											? props.placeholderImg
											: isPrimarySource &&
											  Array.isArray(propValue)
											? props?.value[0]?.integration_id
													?.logo_url ||
											  primarySourceIcon
											: viewGridIcon
									}
									width="24"
									alt="source"
								/>
							)}
							{isPrimarySource && Array.isArray(propValue)
								? props?.value[0]?.integration_id?.name ||
								  props.placeholder
								: null}

							{propValue ? propValue[label] : props.placeholder}
						</span>
					)}
					<img
						className="z__select--input__icon bg-white"
						src={arrowdropdown}
						aria-hidden="true"
						alt="dropdown"
					/>
				</div>
			)}

			{showOptions && (
				<div
					className={
						optionListClass
							? `z__select--options__container ${optionListClass}`
							: "z__select--options__container"
					}
				>
					{props.actions && (
						<div className="z__select--options__container__actions">
							<div className="d-flex flex-row-reverse">
								<Button
									className="font-10"
									type="link"
									onClick={() => {
										props.refresh();
										setShowOptions(false);
										setActiveDefaultActionPopup(null);
									}}
								>
									<img
										src={refresh}
										width={9}
										className=""
										alt="refresh"
									/>{" "}
									Refresh
								</Button>
							</div>
						</div>
					)}

					{isPrimarySource ? (
						primarySourcesOptions(dropdownList)
					) : groupBy ? (
						<DefaultOptionGroupContainer
							groupOptions={groupByOptions}
							activeDefaultActionPopup={activeDefaultActionPopup}
							setActiveDefaultActionPopup={
								setActiveDefaultActionPopup
							}
							key="defaultoptions"
						/>
					) : (
						defaultOptionContainer(dropdownList)
					)}
					{loadingMoreOptions && (
						<div className="text-center mt-1 mb-1">
							<Spinner
								className="mr-2 blue-spinner action-edit-spinner"
								animation="border"
							/>
						</div>
					)}
				</div>
			)}
		</div>
	);
}

SelectOld.defaultProps = {
	placeholder: "Select",
	isSearchable: false,
	label: "label",
	style: {},
	dropdownOnClick: false,
	actions: false,
};
