import React from "react";
import { Typography, Loader } from "@zluri/ui-components";
function InfiniteScrollLoader() {
	return (
		<div className="z_v2_table_infinite-loader-container">
			<div className="z_v2_table_infinite-loader ">
				<Loader color="white" />
				<Typography variant="body_1_regular" color="white">
					loading more items...
				</Typography>
			</div>
		</div>
	);
}

export default InfiniteScrollLoader;
