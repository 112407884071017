import React, { useState } from "react";
import "./NoteCard.css";

import PropTypes from "prop-types";
import {
	Typography,
	GREY_VARIANT,
	BUTTON_VARIANT,
	RED_VARIANT,
	Loader,
} from "@zluri/ui-components";

import editPencilIcon from "modules/Optimization/assets/editPencil.svg";
import deleteIcon from "assets/delete_filled.svg";

export function NoteHeaderDropdownOptions({
	onEdit,
	onDelete,
	setShowPopover,
}) {
	const [confirmDelete, setConfirmDelete] = useState(false);
	const [removingNote, setRemovingNote] = useState(false);

	const handleEditClick = () => {
		onEdit && onEdit();
		setShowPopover(false);
	};

	const handleDeleteClick = async () => {
		if (confirmDelete) {
			setRemovingNote(true);
			onDelete && (await onDelete());
			setRemovingNote(false);
			setShowPopover(false);
		} else {
			setConfirmDelete(true);
		}
	};

	return (
		<div className="z_overview_note_header_options padding_2 d-flex flex-column gap-2">
			<div
				className="z_overview_note_option d-flex align-items-center w-100 gap-8 padding_6 cursor-pointer"
				onClick={handleEditClick}
			>
				<img
					src={editPencilIcon}
					alt="edit"
					height="14px"
					width="14px"
				/>
				<Typography
					color={GREY_VARIANT.SECONDARY_GREY_2}
					variant={BUTTON_VARIANT.BUTTON_EXTRA_SMALL_MEDIUM}
				>
					Edit
				</Typography>
			</div>
			<div
				className={`z_overview_note_option d-flex align-items-center w-100 gap-8 padding_6 cursor-pointer ${
					(confirmDelete || removingNote) &&
					"z_overview_note_option--confirm_delete"
				}`}
				onClick={handleDeleteClick}
			>
				{removingNote ? (
					<Loader color="red" className="mx-auto" />
				) : (
					<>
						<img
							src={deleteIcon}
							alt="delete"
							height="14px"
							width="14px"
							className={`${
								confirmDelete && "z_overview_delete--red"
							}`}
						/>
						<Typography
							color={
								confirmDelete
									? RED_VARIANT.ACCENT_RED_1
									: GREY_VARIANT.SECONDARY_GREY_2
							}
							variant={BUTTON_VARIANT.BUTTON_EXTRA_SMALL_MEDIUM}
						>
							{confirmDelete ? "Yes Delete" : "Delete"}
						</Typography>
					</>
				)}
			</div>
		</div>
	);
}

NoteHeaderDropdownOptions.propTypes = {
	onEdit: PropTypes.func,
	onDelete: PropTypes.func,
	setShowPopover: PropTypes.func,
};
