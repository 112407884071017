import { TriggerIssue } from "utils/sentry";
import {
	getApplicationSpendsSummary,
	getAccruedSpendsGraphData,
	getActualSpendsvsCostGraphData,
	getAmmortizedSpendsvsCostGraphData,
	getApplicationSpendsFinancial,
	getApplicationSpendsPaymentMethod,
	getApplicationSpendsSetting,
	getSpendsOverviewDetails,
	updateVendorTask,
	getAlternateAppFeatures,
	getApplicationAlternateApps,
	getAppOverviewMetric,
	getAppOverviewNotes,
	getAppOverviewChecklist,
	skipAppCheckListItem,
	getAppSources,
	getAppUserLicensesGraph,
	getAppOptimizationGraph,
	getAppUserStatusGraph,
	updateAppOverviewNotesOrder,
	updateAppOverviewLinks,
} from "../service/Applications.api";
import { ApplicationConstants } from "./Applications.constant";
import { TOAST_COLOR, toast } from "@zluri/ui-components";
import { TOAST_MESSAGE } from "modules/shared/constants/toast.constants";

// APP SPENDS
export function setSelectedTab(tab) {
	return async function (dispatch) {
		try {
			dispatch({
				type: ApplicationConstants.SET_SELECTED_TAB,
				payload: tab,
			});
		} catch (e) {}
	};
}
export function setSelectedModal(tab) {
	return async function (dispatch) {
		dispatch(setSelectedTab(tab));
	};
}
export function setDefaultPaymentMethod(paymentMethod) {
	return async function (dispatch) {
		dispatch({
			type: ApplicationConstants.SET_DEFAULT_PAYMENT_METHOD,
			payload: paymentMethod,
		});
	};
}
export function fetchSpendsOverviewDetails(appId) {
	return async function (dispatch) {
		dispatch({
			type: ApplicationConstants.FETCH_APPLICATION_SPENDS_OVERVIEW_DETAILS_REQUESTED,
		});
		try {
			const response = await getSpendsOverviewDetails(appId);
			if (response.error) {
				dispatch({
					type: ApplicationConstants.FETCHED_APPLICATION_SPENDS_OVERVIEW_DETAILS,
					payload: {
						id: appId,
						error: response.error,
					},
				});
				TriggerIssue(
					"Error occurred while fetching app spends overview details!",
					response.error
				);
			} else {
				dispatch({
					type: ApplicationConstants.FETCHED_APPLICATION_SPENDS_OVERVIEW_DETAILS,
					payload: {
						data: response.data,
						error: {},
						id: appId,
					},
				});
			}
		} catch (e) {
			TriggerIssue(
				"Error occurred while fetching app spends overview details!",
				e
			);
			dispatch({
				type: ApplicationConstants.FETCHED_APPLICATION_SPENDS_OVERVIEW_DETAILS,
				payload: {
					id: appId,
					error: e,
				},
			});
		}
	};
}
export function fetchSpendsSettings(appId) {
	return async function (dispatch) {
		dispatch({
			type: ApplicationConstants.FETCH_APPLICATION_SPENDS_SETTING_REQUESTED,
			payload: {
				id: appId,
			},
		});
		try {
			const response = await getApplicationSpendsSetting(appId);
			if (response?.error) {
				dispatch({
					type: ApplicationConstants.FETCHED_APPLICATION_SPENDS_SETTING,
					payload: {
						id: appId,
						error: response?.error,
					},
				});
				TriggerIssue(
					"Error occurred while fetching app spends settings details!",
					response.error
				);
			} else {
				dispatch({
					type: ApplicationConstants.FETCHED_APPLICATION_SPENDS_SETTING,
					payload: {
						id: appId,
						data: response?.data,
						error: {},
					},
				});
			}
		} catch (error) {
			dispatch({
				type: ApplicationConstants.FETCHED_APPLICATION_SPENDS_SETTING,
				payload: {
					id: appId,
					error: error,
				},
			});
			TriggerIssue(
				"Error occurred while fetching app spends settings details!",
				error
			);
		}
	};
}
export function fetchSpendsPaymentMethod(appId) {
	return async function (dispatch) {
		dispatch({
			type: ApplicationConstants.FETCH_APPLICATION_SPENDS_PAYMENT_METHOD_REQUESTED,
			payload: {
				id: appId,
			},
		});
		try {
			const response = await getApplicationSpendsPaymentMethod(appId);
			if (response?.error) {
				dispatch({
					type: ApplicationConstants.FETCHED_APPLICATION_SPENDS_PAYMENT_METHOD,
					payload: {
						id: appId,
						error: response?.error,
					},
				});
				TriggerIssue(
					"Error occurred while fetching app spends payment methods!",
					response.error
				);
			} else {
				dispatch({
					type: ApplicationConstants.FETCHED_APPLICATION_SPENDS_PAYMENT_METHOD,
					payload: {
						id: appId,
						data: response?.data,
					},
				});
			}
		} catch (error) {
			dispatch({
				type: ApplicationConstants.FETCHED_APPLICATION_SPENDS_PAYMENT_METHOD,
				payload: {
					id: appId,
					error: error,
				},
			});
			TriggerIssue(
				"Error occurred while fetching app spends payment methods!",
				error
			);
		}
	};
}
export function fetchSpendsFinancialSources(appId) {
	return async function (dispatch) {
		dispatch({
			type: ApplicationConstants.FETCH_APPLICATION_SPENDS_FINANCIAL_REQUESTED,
			payload: {
				id: appId,
			},
		});
		try {
			const response = await getApplicationSpendsFinancial(appId);
			if (response?.error) {
				dispatch({
					type: ApplicationConstants.FETCHED_APPLICATION_SPENDS_FINANCIAL,
					payload: {
						id: appId,
						error: response?.error,
					},
				});
				TriggerIssue(
					"Error occurred while fetching app spends financial sources list!",
					response.error
				);
			} else {
				dispatch({
					type: ApplicationConstants.FETCHED_APPLICATION_SPENDS_FINANCIAL,
					payload: {
						id: appId,
						data: response?.data,
						error: {},
					},
				});
			}
		} catch (error) {
			dispatch({
				type: ApplicationConstants.FETCHED_APPLICATION_SPENDS_FINANCIAL,
				payload: {
					id: appId,
					error: error,
				},
			});
			TriggerIssue(
				"Error occurred while fetching app spends financial sources list!",
				error
			);
		}
	};
}
export function fetchSpendsSummaryDetails(appId) {
	return async function (dispatch) {
		dispatch({
			type: ApplicationConstants.FETCH_APPLICATION_SPENDS_SUMMARY_REQUESTED,
			payload: {
				id: appId,
			},
		});
		try {
			const response = await getApplicationSpendsSummary(appId);
			if (response?.error) {
				dispatch({
					type: ApplicationConstants.FETCHED_APPLICATION_SPENDS_SUMMARY,
					payload: {
						id: appId,
						error: response.error,
					},
				});
				TriggerIssue(
					"Error occurred while fetching app spends summary metrics!",
					response.error
				);
			} else {
				dispatch({
					type: ApplicationConstants.FETCHED_APPLICATION_SPENDS_SUMMARY,
					payload: {
						id: appId,
						data: response?.data,
						error: {},
					},
				});
			}
		} catch (error) {
			dispatch({
				type: ApplicationConstants.FETCHED_APPLICATION_SPENDS_SUMMARY,
				payload: {
					id: appId,
					error: error,
				},
			});
			TriggerIssue(
				"Error occurred while fetching app spends summary metrics!",
				error
			);
		}
	};
}
export function fetchAccruedSpendsGraph(
	appId,
	{ start_month, end_month, start_year, end_year }
) {
	return async function (dispatch) {
		dispatch({
			type: ApplicationConstants.FETCH_ACCRUED_SPENDS_GRAPH_REQUESTED,
			payload: {
				id: appId,
			},
		});
		try {
			const response = await getAccruedSpendsGraphData(
				appId,
				start_month,
				start_year,
				end_month,
				end_year
			);
			if (response?.error) {
				dispatch({
					type: ApplicationConstants.FETCHED_ACCRUED_SPENDS_GRAPH,
					payload: {
						id: appId,
						error: response?.error,
					},
				});
				TriggerIssue(
					"Error occurred while fetching app spends accrued spend graph datapoints!",
					response.error
				);
			} else {
				dispatch({
					type: ApplicationConstants.FETCHED_ACCRUED_SPENDS_GRAPH,
					payload: {
						id: appId,
						data: {
							...response.data,
							spends_budget: response.data?.budget,
						},
						error: {},
					},
				});
			}
		} catch (error) {
			dispatch({
				type: ApplicationConstants.FETCHED_ACCRUED_SPENDS_GRAPH,
				payload: {
					id: appId,
					error: error,
				},
			});
			TriggerIssue(
				"Error occurred while fetching app spends accrued spend graph datapoints!",
				error
			);
		}
	};
}
export function fetchActualSpendsVsCostGraph(
	appId,
	{ start_month, end_month, start_year, end_year }
) {
	return async function (dispatch) {
		dispatch({
			type: ApplicationConstants.FETCH_ACTUAL_SPENDS_VS_COST_GRAPH_REQUESTED,
			payload: {
				id: appId,
			},
		});
		try {
			const response = await getActualSpendsvsCostGraphData(
				appId,
				start_month,
				start_year,
				end_month,
				end_year
			);
			if (response?.error) {
				dispatch({
					type: ApplicationConstants.FETCHED_ACTUAL_SPENDS_VS_COST_GRAPH,
					payload: {
						id: appId,
						error: response.error,
					},
				});
				TriggerIssue(
					"Error occurred while fetching app spends actual_spend vs est_cost graph datapoints!",
					response.error
				);
			} else {
				dispatch({
					type: ApplicationConstants.FETCHED_ACTUAL_SPENDS_VS_COST_GRAPH,
					payload: {
						id: appId,
						data: response?.data,
						error: {},
					},
				});
			}
		} catch (error) {
			dispatch({
				type: ApplicationConstants.FETCHED_ACTUAL_SPENDS_VS_COST_GRAPH,
				payload: {
					id: appId,
					error: error,
				},
			});
			TriggerIssue(
				"Error occurred while fetching app spends actual_spend vs est_cost graph datapoints!",
				error
			);
		}
	};
}
export function fetchAmmortizedSpendsVsCostGraph(
	appId,
	{ start_month, end_month, start_year, end_year }
) {
	return async function (dispatch) {
		dispatch({
			type: ApplicationConstants.FETCH_AMMORTIZED_SPENDS_VS_COST_GRAPH_REQUESTED,
			payload: {
				id: appId,
			},
		});
		try {
			const response = await getAmmortizedSpendsvsCostGraphData(
				appId,
				start_month,
				start_year,
				end_month,
				end_year
			);
			if (response?.error) {
				dispatch({
					type: ApplicationConstants.FETCHED_AMMORTIZED_SPENDS_VS_COST_GRAPH,
					payload: {
						id: appId,
						error: response?.error,
					},
				});
				TriggerIssue(
					"Error occurred while fetching app spends amortized spend graph datapoints!",
					response.error
				);
			} else {
				dispatch({
					type: ApplicationConstants.FETCHED_AMMORTIZED_SPENDS_VS_COST_GRAPH,
					payload: {
						id: appId,
						data: response?.data,
						error: {},
					},
				});
			}
		} catch (error) {
			dispatch({
				type: ApplicationConstants.FETCHED_AMMORTIZED_SPENDS_VS_COST_GRAPH,
				payload: {
					id: appId,
					error: error,
				},
			});
			TriggerIssue(
				"Error occurred while fetching app spends amortized spend graph datapoints!",
				error
			);
		}
	};
}
export function updateSpendsTransactionTableVendor(transId, vendorId) {
	return async function (dispatch) {
		dispatch({
			type: ApplicationConstants.FETCH_SPENDS_TRANSACTION_VENDOR_REQUESTED,
			payload: {
				id: transId,
			},
		});
		try {
			const response = await updateVendorTask(transId, vendorId);
			if (response?.error) {
				dispatch({
					type: ApplicationConstants.FETCHED_SPENDS_TRANSACTION_VENDOR,
					payload: {
						id: transId,
						error: response.error,
					},
				});
				TriggerIssue(
					"Error occurred while updating vendor for a transaction!",
					response.error
				);
			} else {
				dispatch({
					type: ApplicationConstants.FETCHED_SPENDS_TRANSACTION_VENDOR,
					payload: {
						id: transId,
						data: response,
						error: {},
					},
				});
			}
		} catch (error) {
			dispatch({
				type: ApplicationConstants.FETCHED_SPENDS_TRANSACTION_VENDOR,
				payload: {
					id: transId,
					error: error,
				},
			});
			TriggerIssue(
				"Error occurred while updating vendor for a transaction!",
				error
			);
		}
	};
}
export function updateChartBudget(spends_budget) {
	return async function (dispatch) {
		try {
			dispatch({
				type: ApplicationConstants.UPDATE_SPENDS_CHART_BUDGET,
				payload: { spends_budget },
			});
		} catch (e) {
			TriggerIssue("Error occurred while updating budget chart!", e);
		}
	};
}

// APP ABOUT
export function setAppArchive(value) {
	return async function (dispatch) {
		dispatch({
			type: ApplicationConstants.SET_APP_ARCHIVE,
			payload: value,
		});
	};
}
export function fetchAppAboutSimilarApp(appId, fetchAlternateApp = false) {
	return async function (dispatch) {
		if (!fetchAlternateApp)
			dispatch({
				type: ApplicationConstants.FETCH_APP_ABOUT_SIMILAR_APPS_REQUESTED,
				payload: {
					id: appId,
				},
			});
		dispatch({
			type: ApplicationConstants.FETCH_ALTERNATE_APPS_REQUESTED,
			payload: {
				id: appId,
			},
		});
		try {
			const response = await getApplicationAlternateApps(appId);
			if (response?.error) {
				TriggerIssue(
					"Error occurred while fetching similar app!",
					response?.error
				);
				if (!fetchAlternateApp)
					dispatch({
						type: ApplicationConstants.FETCHED_APP_ABOUT_SIMILAR_APPS,
						payload: {
							id: appId,
							error: response.error,
						},
					});
				dispatch({
					type: ApplicationConstants.FETCHED_ALTERNATE_APPS,
					payload: {
						id: appId,
						error: response.error,
					},
				});
			} else {
				if (!fetchAlternateApp)
					dispatch({
						type: ApplicationConstants.FETCHED_APP_ABOUT_SIMILAR_APPS,
						payload: {
							id: appId,
							data: response.data?.apps?.similar_apps,
							count: response.data?.apps?.similar_apps?.length,
							error: {},
						},
					});
				dispatch({
					type: ApplicationConstants.FETCHED_ALTERNATE_APPS,
					payload: {
						id: appId,
						count:
							response?.data.apps?.alternate_apps?.slice(0, 20)
								?.length || 0,
						alternate_apps:
							response?.data?.apps?.alternate_apps?.slice(
								0,
								20
							) || [],
					},
				});
			}
		} catch (error) {
			TriggerIssue("Error occurred while fetching similar app!", error);
			if (!fetchAlternateApp)
				dispatch({
					type: ApplicationConstants.FETCHED_APP_ABOUT_SIMILAR_APPS,
					payload: {
						id: appId,
						error: error,
					},
				});
			dispatch({
				type: ApplicationConstants.FETCHED_ALTERNATE_APPS,
				payload: {
					id: appId,
					error: error,
				},
			});
		}
	};
}
export function fetchAlternateAppFeatures(appIds) {
	return async function (dispatch) {
		try {
			dispatch({
				type: ApplicationConstants.FETCH_ALTERNATE_APP_FEATURES_REQUESTED,
				payload: {},
			});
			const response = await getAlternateAppFeatures(appIds);

			if (response?.error) {
				TriggerIssue(
					"Error occurred while fetching alternate app features!",
					response?.error
				);
				dispatch({
					type: ApplicationConstants.FETCH_ALTERNATE_APP_FEATURES_REQUESTED,
					payload: {
						error: response?.error,
					},
				});
			} else {
				dispatch({
					type: ApplicationConstants.FETCHED_ALTERNATE_APP_FEATURES,
					payload: {
						features: response?.data,
						count: response?.data?.length,
						error: {},
					},
				});
			}
		} catch (error) {
			TriggerIssue(
				"Error occurred while fetching alternate app features!",
				error
			);
			dispatch({
				type: ApplicationConstants.FETCH_ALTERNATE_APP_FEATURES_REQUESTED,
				payload: {
					error: error,
				},
			});
		}
	};
}

// APP OVERVIEW
export function fetchAppOverviewSources(appId) {
	return async function (dispatch) {
		dispatch({
			type: ApplicationConstants.FETCH_APP_OVERVIEW_SOURCES_REQUESTED,
		});
		try {
			const response = await getAppSources(appId);
			dispatch({
				type: ApplicationConstants.FETCHED_APP_OVERVIEW_SOURCES,
				payload: {
					data: response.data,
				},
			});
		} catch (e) {
			dispatch({
				type: ApplicationConstants.FETCHED_APP_OVERVIEW_SOURCES,
				payload: {
					error: e,
				},
			});
			TriggerIssue(
				"Error occurred while fetching application overview sources!",
				e
			);
		}
	};
}
export function fetchAppOverviewMetrics(appId, data) {
	return async function (dispatch) {
		dispatch({
			type: ApplicationConstants.FETCH_APP_OVERVIEW_METRIC_REQUESTED,
		});
		try {
			const response = await getAppOverviewMetric(appId);
			if (response.data) {
				dispatch({
					type: ApplicationConstants.FETCHED_APP_OVERVIEW_METRIC,
					payload: {
						data: data || response.data,
					},
				});
			}
		} catch (error) {
			dispatch({
				type: ApplicationConstants.FETCHED_APP_OVERVIEW_METRIC,
				payload: {
					error: error,
				},
			});
			TriggerIssue(
				"Error occurred while fetching app overview metrics details!",
				error
			);
		}
	};
}

export function fetchAppOverviewNotes(appId) {
	return async function (dispatch) {
		dispatch({
			type: ApplicationConstants.FETCH_APP_OVERVIEW_NOTES_REQUESTED,
		});
		try {
			const response = await getAppOverviewNotes(appId);
			if (response.data) {
				dispatch({
					type: ApplicationConstants.FETCHED_APP_OVERVIEW_NOTES,
					payload: {
						data: response.data,
					},
				});
			}
		} catch (error) {
			dispatch({
				type: ApplicationConstants.FETCHED_APP_OVERVIEW_NOTES,
				payload: {
					error,
				},
			});
			TriggerIssue(
				"Error occurred while fetching app overview notes!",
				error
			);
		}
	};
}
export function updateAppOverviewNoteOrder(appId, order) {
	return async function () {
		try {
			const response = await updateAppOverviewNotesOrder(appId, order);
			console.log("res: ", response);
		} catch (error) {
			TriggerIssue(
				"Error occurred while updating overview notes order!",
				error
			);
		}
	};
}

export function fetchAppOverviewLinks(links) {
	return async function (dispatch) {
		dispatch({
			type: ApplicationConstants.FETCH_APP_OVERVIEW_LINKS_REQUESTED,
		});
		try {
			dispatch({
				type: ApplicationConstants.FETCHED_APP_OVERVIEW_LINKS,
				payload: {
					data: links,
				},
			});
		} catch (e) {
			dispatch({
				type: ApplicationConstants.FETCHED_APP_OVERVIEW_LINKS,
				error: e,
			});
			TriggerIssue(
				"Error occurred while fetching application overview links!",
				e
			);
		}
	};
}
export function addAppOverviewLink({ links, link, appId }) {
	return async function (dispatch) {
		try {
			dispatch({
				type: ApplicationConstants.ADD_APP_OVERVIEW_LINK_REQUESTED,
			});
			const api_values = links?.map((link) => link?.link).concat(link);
			const response = await updateAppOverviewLinks({
				appId,
				links: api_values,
			});
			if (response?.data) {
				dispatch({
					type: ApplicationConstants.ADDED_APP_OVERVIEW_LINK,
					payload: {
						id: links?.length,
						link,
					},
				});
				toast(TOAST_MESSAGE.ADD_APPLICATION_LINK_SUCCESS, {
					indicatorColor: TOAST_COLOR.SUCCESS,
				});
			}
		} catch (error) {
			toast(TOAST_MESSAGE.ADD_APPLICATION_LINK_ERROR, {
				indicatorColor: TOAST_COLOR.ERROR,
			});
			TriggerIssue(
				"Error occurred while adding application links!",
				error
			);
			dispatch({
				type: ApplicationConstants.ADDED_APP_OVERVIEW_LINK,
				payload: {
					id: links?.length,
					link,
					error,
				},
			});
		}
	};
}
export function updateAppOverviewLink({ links, link, id, appId }) {
	return async function (dispatch) {
		try {
			dispatch({
				type: ApplicationConstants.UPDATE_APP_OVERVIEW_LINK_REQUESTED,
				payload: {
					link,
					id,
				},
			});
			const api_values = links?.map((l) => {
				if (l.id === id) return link;
				return l?.link;
			});
			const response = await updateAppOverviewLinks({
				appId,
				links: api_values,
			});
			if (response?.data) {
				dispatch({
					type: ApplicationConstants.UPDATED_APP_OVERVIEW_LINK,
					payload: {
						id,
						link,
					},
				});
				toast(TOAST_MESSAGE.EDIT_APPLICATION_LINK_SUCCESS, {
					indicatorColor: TOAST_COLOR.SUCCESS,
				});
			}
		} catch (error) {
			toast(TOAST_MESSAGE.EDIT_APPLICATION_LINK_ERROR, {
				indicatorColor: TOAST_COLOR.ERROR,
			});
			TriggerIssue(
				"Error occurred while updating application links!",
				error
			);
			dispatch({
				type: ApplicationConstants.UPDATED_APP_OVERVIEW_LINK,
				payload: {
					id,
					link,
					error,
				},
			});
		}
	};
}
export function deleteAppOverviewLink({ links, link, id, appId }) {
	return async function (dispatch) {
		try {
			dispatch({
				type: ApplicationConstants.DELETE_APP_OVERVIEW_LINK_REQUESTED,
				payload: {
					id,
					link,
				},
			});
			const api_values = links
				?.filter((l) => l?.id !== id)
				.map((l) => l?.link);
			const response = await updateAppOverviewLinks({
				appId,
				links: api_values,
			});
			if (response.data) {
				dispatch({
					type: ApplicationConstants.DELETED_APP_OVERVIEW_LINK,
					payload: {
						id,
						link,
					},
				});
				toast(TOAST_MESSAGE.DELETE_APPLICATION_LINK_SUCCESS, {
					indicatorColor: TOAST_COLOR.SUCCESS,
				});
			}
		} catch (error) {
			toast(TOAST_MESSAGE.DELETE_APPLICATION_LINK_ERROR, {
				indicatorColor: TOAST_COLOR.ERROR,
			});
			dispatch({
				type: ApplicationConstants.DELETED_APP_OVERVIEW_LINK,
				payload: {
					id,
					link,
					error,
				},
			});
			TriggerIssue(
				"Error occurred while updating adding application links!",
				error
			);
		}
	};
}

export function fetchAppOverviewChecklist(appId) {
	return async function (dispatch) {
		dispatch({
			type: ApplicationConstants.FETCH_APP_OVERVIEW_CHECKLIST_REQUESTED,
		});
		try {
			const response = await getAppOverviewChecklist(appId);
			if (response.data) {
				dispatch({
					type: ApplicationConstants.FETCHED_APP_OVERVIEW_CHECKLIST,
					payload: {
						data: response.data?.data,
					},
				});
			}
		} catch (error) {
			dispatch({
				type: ApplicationConstants.FETCHED_APP_OVERVIEW_CHECKLIST,
				payload: {
					error,
				},
			});
			TriggerIssue(
				"Error occurred while fetching application overview checklist!",
				error.message
			);
		}
	};
}
export function skipCheckListItem(appId, parent_id, item_id, unSkip = false) {
	return async function (dispatch) {
		dispatch({
			type: ApplicationConstants.SKIP_OVERVIEW_CHECKLIST_ITEM_REQUESTED,
			payload: {
				parent_id,
				item_id,
			},
		});
		try {
			const response = await skipAppCheckListItem(
				appId,
				item_id,
				parent_id,
				unSkip ? false : true
			);
			if (response?.data) {
				dispatch({
					type: ApplicationConstants.SKIPPED_OVERVIEW_CHECKLIST_ITEM,
					payload: {
						parent_id,
						item_id,
						unSkip,
					},
				});
				toast(
					unSkip
						? TOAST_MESSAGE.UNSKIP_CHECKLIST_SUCCESS
						: TOAST_MESSAGE.SKIP_CHECKLIST_SUCCESS,
					{
						indicatorColor: TOAST_COLOR.SUCCESS,
					}
				);
			}
		} catch (error) {
			toast(
				unSkip
					? TOAST_MESSAGE.UNSKIP_CHECKLIST_ERROR
					: TOAST_MESSAGE.SKIP_CHECKLIST_ERROR,
				{
					indicatorColor: TOAST_COLOR.ERROR,
				}
			);
			dispatch({
				type: ApplicationConstants.SKIPPED_OVERVIEW_CHECKLIST_ITEM,
				payload: {
					parent_id,
					item_id,
					unSkip,
					error,
				},
			});
			TriggerIssue(
				"Error occurred while skipping application overview checklist!",
				error
			);
		}
	};
}

export function fetchAppUserLicensesGraph({ appId, orgId, filterBy }) {
	return async function (dispatch) {
		dispatch({
			type: ApplicationConstants.FETCH_APP_USER_LICENSES_REQUESTED,
		});
		try {
			const response = await getAppUserLicensesGraph({
				appId,
				orgId,
				...filterBy,
			});
			dispatch({
				type: ApplicationConstants.FETCHED_APP_USER_LICENSES,
				payload: {
					data: response?.data,
				},
			});
		} catch (error) {
			dispatch({
				type: ApplicationConstants.FETCHED_APP_USER_LICENSES,
				payload: {
					error,
				},
			});
			TriggerIssue(
				"Error occurred while fetching application overview user licenses graph data!",
				error
			);
		}
	};
}
export function fetchAppUserOptimizationsGraph({ appId, orgId, filterBy }) {
	return async function (dispatch) {
		dispatch({
			type: ApplicationConstants.FETCH_APP_USER_OPTIMIZATION_REQUESTED,
		});
		try {
			const response = await getAppOptimizationGraph({
				appId,
				orgId,
				...filterBy,
			});
			dispatch({
				type: ApplicationConstants.FETCHED_APP_USER_OPTIMIZATION,
				payload: {
					data: response?.data,
				},
			});
		} catch (error) {
			dispatch({
				type: ApplicationConstants.FETCHED_APP_USER_OPTIMIZATION,
				payload: {
					error,
				},
			});
			TriggerIssue(
				"Error occurred while fetching application overview user optimizations graph data!",
				error
			);
		}
	};
}
export function fetchAppUserStatusGraph({ appId, orgId, filterBy }) {
	return async function (dispatch) {
		dispatch({
			type: ApplicationConstants.FETCH_APP_USER_STATUS_REQUESTED,
			payload: {},
		});
		try {
			const response = await getAppUserStatusGraph({
				appId,
				orgId,
				...filterBy,
			});
			dispatch({
				type: ApplicationConstants.FETCHED_APP_USER_STATUS,
				payload: {
					data: response?.data,
				},
			});
		} catch (error) {
			dispatch({
				type: ApplicationConstants.FETCHED_APP_USER_STATUS,
				payload: {
					error,
				},
			});
			TriggerIssue(
				"Error occurred while fetching application overview user status graph data!",
				error
			);
		}
	};
}
