import _ from "underscore";
import { requestTypes } from "modules/employee-dashboard/constants/employee";

export const getAddLicenseRequestPayload = (reqData) => {
	return {
		...(reqData.app_in_org
			? { org_app_id: reqData.app_id }
			: { app_parent_id: reqData.app_id }),

		...(reqData.license_id ? { license_id: reqData.license_id } : {}),
		...(reqData.approvers ? { approvers: reqData.approvers } : {}),
		...(reqData.on_approval_date
			? { on_approval_date: reqData.on_approval_date }
			: {}),
		...(reqData.on_approval_playbook
			? { on_approval_playbook: reqData.on_approval_playbook }
			: {}),
		...(reqData.on_approval_user
			? { on_approval_user: reqData.on_approval_user }
			: {}),
		...(reqData.has_onboarding_playbook
			? { has_onboarding_playbook: reqData.has_onboarding_playbook }
			: {}),
		...(reqData.has_offboarding_playbook
			? { has_offboarding_playbook: reqData.has_offboarding_playbook }
			: {}),
		license_name: reqData.license_name,
		request_role: reqData.request_role,
		quantity: reqData.licenses_required || 1,
		type: reqData?.app_in_org ? "license" : "application",
		// ...(reqData.license_name
		// 	? {
		// 			cost_per_item: {
		// 				currency: reqData.currency,
		// 				amount: reqData.license_cost,
		// 				complete_term: false,
		// 				frequency: 1,
		// 				period: reqData.per_license_term,
		// 			},
		// 	  }
		// 	: {}),
		// cost_per_item: {
		// 	currency: reqData.currency,
		// 	amount: reqData.license_cost,
		// 	complete_term: false,
		// 	frequency: 1,
		// 	period: reqData.per_license_term,
		// },
		documents: [...reqData.documents],
		requirement_description: reqData.requirement_text,
		need_more_licenses: reqData.need_more_licenses || false,
		other_subscription_users: reqData.users
			.filter((user) => user.user_id !== "me")
			.map((user) => user.user_id),
		...(reqData.subscription_duration_term &&
		reqData.subscription_duration_value
			? {
					subscription_duration: {
						period: reqData.subscription_duration_term,
						frequency: Number.parseInt(
							reqData.subscription_duration_value
						),
					},
			  }
			: {}),
	};
};

export const getUpdatedKeys = (newData, oldData) => {
	let tempArr = [];
	Object.keys(newData).forEach((el) => {
		if (el === "users") {
			let idArr1 = newData[el].map((el) => el.user_id);
			let idArr2 = oldData[el].map((el) => el.user_id);

			if (idArr1.length !== idArr2.length) {
				tempArr.push(el);
				return;
			} else {
				idArr2.forEach((row) => {
					if (!idArr1.includes(row)) {
						tempArr.push(el);
						return;
					}
				});
			}
			return;
		}
		if (newData[el] !== oldData[el]) {
			tempArr.push(el);
		}
	});

	let reqKeys = [
		"need_more_licenses",
		"requirement_text",
		"users",
		"documents",
	];

	let finalKeys = [];
	tempArr.forEach((el) => {
		if (
			el === "license_cost" ||
			el === "currency" ||
			el === "per_license_term"
		) {
			finalKeys.push("cost_per_item");
		} else if (
			el === "subscription_duration_value" ||
			el === "subscription_duration_term"
		) {
			finalKeys.push("subscription_duration");
		} else if (el === "licenses_required") {
			finalKeys.push("quantity");
		} else if (el === "license_name" || el === "license_id") {
			finalKeys.push("license_name");
		} else {
			finalKeys.push(el);
		}
	});

	finalKeys = _.uniq(finalKeys);

	return finalKeys;
};

export const getEditLicenseRequestPayload = (reqData, prevData = {}) => {
	let keys = getUpdatedKeys(reqData, prevData);

	return {
		...(reqData?.app_id
			? reqData.app_in_org
				? { org_app_id: reqData.app_id }
				: { app_parent_id: reqData.app_id }
			: {}),
		...(reqData.license_id ? { license_id: reqData.license_id } : {}),
		request_role: reqData.request_role?.trim(),
		license_name: reqData.license_name?.trim(),
		app_name: reqData?.app_name?.trim(),
		app_url: reqData?.app_url?.trim(),
		quantity: reqData.licenses_required || 1,
		type: reqData?.is_org_app ? "license" : "application",
		// cost_per_item: {
		// 	currency: reqData.currency,
		// 	amount: reqData.license_cost,
		// 	complete_term: false,
		// 	frequency: 1,
		// 	period: reqData.per_license_term,
		// },
		documents: [...reqData.documents],
		requirement_description: reqData.requirement_text,
		need_more_licenses: reqData.need_more_licenses || false,
		other_subscription_users: reqData.users
			.filter((user) => user.user_id !== "me")
			.map((user) => user.user_id),

		//...(reqData.subscription_duration_term &&
		// reqData.subscription_duration_value
		// 	? {
		// 			subscription_duration: {
		// 				period: reqData.subscription_duration_term,
		// 				frequency: Number.parseInt(
		// 					reqData.subscription_duration_value
		// 				),
		// 			},
		// 	  }
		// 	: {}),
		...(reqData.subscription_duration_term &&
		reqData.subscription_duration_value
			? {
					subscription_duration: {
						period: reqData.subscription_duration_term,
						frequency: Number.parseInt(
							reqData.subscription_duration_value
						),
					},
			  }
			: {}),
		...(keys ? { updated_keys: keys } : {}),
	};
};

export const getAddCustomAppRequestPayload = (reqData) => {
	return {
		...(reqData.app_in_org
			? { org_app_id: reqData.app_id }
			: { app_parent_id: reqData.app_id }),

		...(reqData.license_id ? { license_id: reqData.license_id } : {}),
		...(reqData.approvers ? { approvers: reqData.approvers } : {}),
		...(reqData.on_approval_date
			? { on_approval_date: reqData.on_approval_date }
			: {}),
		...(reqData.on_approval_playbook
			? { on_approval_playbook: reqData.on_approval_playbook }
			: {}),
		...(reqData.on_approval_user
			? { on_approval_user: reqData.on_approval_user }
			: {}),
		...(reqData.has_onboarding_playbook
			? { has_onboarding_playbook: reqData.has_onboarding_playbook }
			: {}),
		...(reqData.has_offboarding_playbook
			? { has_offboarding_playbook: reqData.has_offboarding_playbook }
			: {}),
		license_name: reqData.license_name?.trim(),
		request_role: reqData.request_role?.trim(),
		app_name: reqData?.app_name?.trim(),
		app_url: reqData?.app_url?.trim(),
		quantity: reqData.licenses_required || 1,
		type: reqData?.app_in_org ? "license" : "application",
		documents: [...reqData.documents],
		requirement_description: reqData.requirement_text?.trim(),
		need_more_licenses: reqData.need_more_licenses || false,
		other_subscription_users: Array.isArray(reqData.users)
			? reqData.users
					.filter((user) => user.user_id !== "me")
					.map((user) => user.user_id)
			: [],
		...(reqData.subscription_duration_term &&
		reqData.subscription_duration_value
			? {
					subscription_duration: {
						period: reqData.subscription_duration_term,
						frequency: _.isNaN(
							Number.parseInt(reqData.subscription_duration_value)
						)
							? 1
							: Number.parseInt(
									reqData.subscription_duration_value
							  ),
					},
			  }
			: {}),
	};
};

export const requestPayloadMapper =
	(requestType) => (reqData, request_license_info) => {
		switch (requestType) {
			case requestTypes.ADD_REQUEST:
				return getAddLicenseRequestPayload(reqData);
			case requestTypes.EDIT_REQUEST:
				return getEditLicenseRequestPayload(
					reqData,
					request_license_info
				);
			default:
				return getAddCustomAppRequestPayload(reqData);
		}
	};

export const getModifiedLicenseRequestOverview = (res) => {
	let tempUsers =
		Array.isArray(res.other_subscription_users) &&
		res.other_subscription_users.length > 0 &&
		res.other_subscription_users.map((el) => {
			return {
				user_image: el.profile_img,
				user_name: el.name,
				user_id: el._id,
				user_email: el.email,
			};
		});
	return {
		...res,
		license_cost: res.cost_per_item.amount,
		currency: res.cost_per_item.currency,
		per_license_term: res.cost_per_item.period,
		app_name: res.app_name,
		app_logo: res.app_image,
		app_id: res.app_id,
		license_name: res.license_name,
		license_id: res.licenseId,
		requested_on: res.createdAt,
		quantity: res.quantity,
		need_more_licenses: res.need_more_licenses || false,
		requirement_text: res.requirement_description,
		approvers: res.approvers,
		is_org_app: res.is_org_app,
		user_type: res.user_type,
		request_role: res.request_role,
		requestee_info: res.user_id,
		app_in_org: res.is_org_app,
		users: tempUsers || [],
		subscription_duration_term: res.subscription_duration?.period,
		subscription_duration_value: res.subscription_duration?.frequency,
	};
};

export const getModifiedLicenseCost = (res) => {
	return {
		...res,
		license_cost: res.request_lecense_details.cost_per_item.amount,
		currency: res.request_lecense_details.cost_per_item.currency,
		per_license_term: res.request_lecense_details.cost_per_item.period,
		period: res.request_lecense_details.cost_per_item.period,
	};
};

export const redirectToCustomAppRequest = (app_name, history) => {
	history.push({
		pathname: `/user/license/request/custom`,
		state: {
			data: {
				app_name: app_name,
			},
		},
	});
};

export const getModifiedApproversForRendering = (approvers) => {
	let newApprovers = [];
	let count = 0;

	for (let i = 0; i < approvers.length; i++) {
		if (
			approvers[i].action_type === "actiononbehalfof" ||
			approvers[i].action_type === "override"
		) {
			newApprovers[i - 1 - count].additionalInfo = approvers[i];
			count = count + 1;
		} else {
			newApprovers.push(approvers[i]);
		}
	}

	return [...newApprovers];
};

export const getReqBodyForAddingApprovers = (approvers) => {
	let newApprovers = [];
	for (let i = 0; i < approvers.length; i++) {
		if (approvers[i].approved_rejected_by_admin) {
			let tempObj = { ...approvers[i] };
			let nextObject = { ...approvers[i].additionalInfo };

			delete tempObj["additionalInfo"];
			newApprovers.push(tempObj);
			newApprovers.push(nextObject);
		} else {
			newApprovers.push(approvers[i]);
		}
	}
	return [...newApprovers];
};

export const getAddOrRearrangedMeta = (
	current_approvers,
	previous_approvers
) => {
	let current_approver_index;

	for (let i = 0; i < previous_approvers.length; i++) {
		if (
			previous_approvers[i].status !== "pending" &&
			!previous_approvers[i].approved_rejected_by_admin &&
			previous_approvers[i].user_id
		) {
			current_approver_index = i;
		}
	}

	let current_pending_approver_index;
	for (let i = 0; i < current_approvers.length; i++) {
		if (
			current_approvers[i].status === "pending" &&
			!current_approvers[i].approved_rejected_by_admin &&
			current_approvers[i].user_id
		) {
			current_pending_approver_index = i;
			break;
		}
	}

	const slice_approver_index = current_approver_index
		? current_approver_index + 1
		: 0;

	let remaining_previous_approvers =
		previous_approvers.slice(slice_approver_index);
	let remaining_new_approvers = current_approvers.slice(slice_approver_index);

	remaining_previous_approvers = remaining_previous_approvers.filter(
		(element) => element.user_id
	);

	remaining_new_approvers = remaining_new_approvers.filter(
		(element) => element.user_id
	);

	const action_type = detectChanges(
		remaining_new_approvers,
		remaining_previous_approvers
	);

	return action_type || "";
};

const detectChanges = (newArray, oldArray) => {
	if (oldArray.length !== newArray.length) {
		return "added";
	}

	const oldCopy = oldArray.slice();
	const newCopy = newArray.slice();

	oldCopy.sort((a, b) => (JSON.stringify(a) < JSON.stringify(b) ? -1 : 1));
	newCopy.sort((a, b) => (JSON.stringify(a) < JSON.stringify(b) ? -1 : 1));

	for (let i = 0; i < oldCopy.length; i++) {
		if (JSON.stringify(oldCopy[i]) !== JSON.stringify(newCopy[i])) {
			return "rearranged"; // Something was rearranged
		}
	}

	// If no differences found, nothing was added or rearranged
	return false;
};

export const getUniqueIdsOfApprovers = (approversArray) => {
	let ids = [...new Set(approversArray.map((x) => x.user_id))].filter(
		(x) => x
	);
	return ids;
};
