import React, { useState } from "react";
import inactive from "assets/agents/inactive.svg";
import ContractBulkUploadErrorTable from "./ContractBulkUploadErrorTable";
import ContractBulkUploadErrorCSVDownload from "./ContractBulkUploadErrorCSVDownload";

export default function ContractBulkUploadCSVErrorDisplay({
	rows,
	reUploadFile,
}) {
	const [showErrors, setShowErrors] = useState(false);
	return (
		<>
			<div
				className="d-flex flex-column align-items-center justify-content-center h-100"
				style={{ gap: "15px" }}
			>
				<img src={inactive} height="40px" width="40px" alt="success" />
				<div className="font-12">
					There were errors found in the CSV. Please fix them and
					re-upload it.
				</div>
				<div className="d-flex" style={{ gap: "8px" }}>
					<ContractBulkUploadErrorCSVDownload
						rows={rows}
						top={22}
						right={-3}
					>
						<div className="primary_bg_2 border-radius-8 padding_4 font-10 bold-500 font-white cursor-pointer">
							Download Errors
						</div>
					</ContractBulkUploadErrorCSVDownload>
					<div
						className="primary_bg_2 border-radius-8 padding_4 font-10 bold-500 font-white cursor-pointer"
						onClick={() => setShowErrors(true)}
					>
						View Errors
					</div>
					<div
						className="primary_bg_2 border-radius-8 padding_4 font-10 bold-500 font-white cursor-pointer"
						onClick={reUploadFile}
					>
						Re-upload File
					</div>
				</div>
			</div>
			{showErrors && (
				<ContractBulkUploadErrorTable
					data={rows}
					isOpen={showErrors}
					handleClose={() => setShowErrors(false)}
				/>
			)}
		</>
	);
}
