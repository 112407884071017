import { clientV3, client } from "utils/client";
import { TriggerIssue } from "utils/sentry";

export const changeRulePriority = async (payload) => {
	const { ruleId, new_order, current_order, tag = "transaction" } = payload;
	try {
		const res = await clientV3.put(`rules/${ruleId}/order?tag=${tag}`, {
			new_order,
			current_order,
		});
		return res;
	} catch (err) {
		TriggerIssue("Error while changing priority", err);
		throw err;
	}
};

export const createRule = async (payload) => {
	const {
		body,
		modify_existing_transactions = false,
		tag = "transaction",
	} = payload || {};

	try {
		const res = await clientV3.post(
			`rules/create?modify_existing_entities=${modify_existing_transactions}&tag=${tag}`,
			body
		);
		return res;
	} catch (err) {
		TriggerIssue("Error while creating rule", err);
		throw err;
	}
};

export const editRule = async (payload) => {
	const {
		body,
		ruleId,
		modify_existing_transactions = false,
		revert_rule_action = false,
		tag = "transaction",
	} = payload || {};
	try {
		const res = await clientV3.put(
			`rules/${ruleId}?tag=${tag}&modify_existing_entities=${modify_existing_transactions}&
			revert_rule_action=${revert_rule_action}`,
			body
		);
		return res;
	} catch (err) {
		TriggerIssue("Error while editing rule", err);
		throw err;
	}
};

export const getSingleRule = async (payload) => {
	const { ruleId, tag = "transaction" } = payload || {};
	try {
		const res = await clientV3.get(`rules/${ruleId}?tag=${tag}`);
		return res;
	} catch (err) {
		TriggerIssue("Error while fetching rule", err);
		throw err;
	}
};

export const executeAllRules = async (payload) => {
	const { tag, unrecongnized = false } = payload || {};
	try {
		const res = await clientV3.get(
			`execute-all-rules?tag=${tag}&unrecongnized=${unrecongnized}`
		);
		return res;
	} catch (err) {
		TriggerIssue("Error executing all rules", err);
		throw err;
	}
};

export const deleteRule = async (payload) => {
	const {
		ruleId,
		tag = "transaction",
		revert_rule_action = false,
	} = payload || {};
	try {
		const res = await clientV3.delete(
			`rules/${ruleId}?tag=${tag}&revert_rule_action=${revert_rule_action}`
		);
		return res;
	} catch (err) {
		TriggerIssue("Error while deleting rule", err);
		throw err;
	}
};

export const getTransactionTabCount = async (payload) => {
	const { tag = "transaction" } = payload || {};
	try {
		const res = await client.get(`transactions/tab-count?tag=${tag}`);
		return res;
	} catch (err) {
		TriggerIssue("Error while fetching transaction tab count", err);
		throw err;
	}
};

export const getExecuteAllStatus = async ({
	tag = "transaction",
	executionMode,
}) => {
	try {
		const res = await clientV3.get(
			`execute-all-rules/status?tag=${tag}&execution_mode=${executionMode}`
		);
		return res;
	} catch (err) {
		TriggerIssue("Error while fetching execute all rules status", err);
		throw err;
	}
};

export const getZluriAiRule = async ({ tag = "transaction" }) => {
	try {
		const res = await clientV3.get(`rules/zluri-ai?tag=${tag}`);
		return res;
	} catch (err) {
		TriggerIssue("Error while fetching zluri-ai", err);
		throw err;
	}
};

export const getRulesTabCount = async ({ tag = "transaction" }) => {
	try {
		const res = await clientV3.get(`rules/tab-count?tag=${tag}`);
		return res;
	} catch (err) {
		TriggerIssue("Error while fetching rules tabcount", err);
		throw err;
	}
};
