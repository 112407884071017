import React from "react";
import { openModal } from "reducers/modal.reducer";
import { getValueFromLocalStorage } from "utils/localStorage";
import mapLicenseStep1 from "assets/licenses/mapLicenseStep1.svg";
import mapLicenseStep2 from "assets/licenses/mapLicenseStep2.svg";
import mapLicenseStep3 from "assets/licenses/mapLicenseStep3.svg";
import uploadpdfcontracticon from "../assets/uploadpdfcontracticon.svg";
import { getHyperLinkMetaData } from "modules/Optimization/utils/OptimizationUtils";
import uploadpdfcontractfileuploadfailed from "../assets/uploadpdfcontractfileuploadfailed.svg";
import ContractBulkUploadTemplate from "../components/ContractUploads/ContractBulkUploadTemplate";
import ContractBulkUploadCSVImport from "../components/ContractUploads/ContractBulkUploadCSVImport";
import uploadpdfcontractfileuploadcompleted from "../assets/uploadpdfcontractfileuploadcompleted.svg";
import uploadpdfcontractfileuploadinprogress from "../assets/uploadpdfcontractfileuploadinprogress.svg";
import { optimizationLicenseClassifications } from "modules/Optimization/constants/OptimizationConstants";
import ContractBulkUploadCSVErrorDisplay from "../components/ContractUploads/ContractBulkUploadCSVErrorDisplay";

export const screenEntity = {
	SUBSCRIPTION: "subscription",
	CONTRACT: "contract",
	PERPETUAL: "perpetual",
};

export const returnURLsFromBC = {
	subscription: `/licenses#allSubscriptions`,
	contract: `/licenses#allContracts`,
	perpetual: `/licenses#allPerpetualContracts`,
};

export const documentTypes = [
	{
		label: "Contract",
		value: "contract",
	},
	{
		label: "Security",
		value: "security",
	},
	{
		label: "Compliance",
		value: "compliance",
	},
	{
		label: "Invoice",
		value: "invoice",
	},
	{
		label: "Order Form",
		value: "order form",
	},
	{
		label: "Quotation",
		value: "quotation",
	},
	{
		label: "Other",
		value: "other",
	},
];

export const booleanFieldArray = [
	{
		key: "Signed by both parties",
		value: false,
		is_custom: false,
	},
	{
		key: "Approved by Finance",
		value: false,
		is_custom: false,
	},
	{
		key: "Approved by IT",
		value: false,
		is_custom: false,
	},
	{
		key: "Compliance Verification",
		value: false,
		is_custom: false,
	},
	{
		key: "Security Verification",
		value: false,
		is_custom: false,
	},
];

export const defaultBooleanFieldArray = booleanFieldArray.filter(
	(field) => field.key !== "Security Verification"
);

export const defaultBooleanFieldKeysArray = booleanFieldArray.map((field) =>
	field?.key?.replaceAll("_", " ")?.toLowerCase()
);

export const contractReqBody = {
	entity: "contract",
	is_app: true,
	app_id: null,
	app_name: null,
	app_logo: null,
	renewing_contract: false,
	renewed_contract_id: null,
	renewed_contract_name: null,
	name: "Untitled",
	description: null,
	vendor_id: null,
	vendor_name: null,
	vendor_logo: null,
	primary_owner_id: null,
	primary_owner_name: null,
	primary_owner_profile: null,
	financial_owner_id: null,
	financial_owner_name: null,
	financial_owner_profile: null,
	negotiation_owner_id: null,
	negotiation_owner_name: null,
	negotiation_owner_profile: null,
	it_owner_id: null,
	it_owner_name: null,
	it_owner_profile: null,
	start_date: null,
	end_date: null,
	cancel_by: null,
	renew_by: null,
	contract_auto_renews: false,
	payment_term: "one_time",
	payment_date: null,
	payment_repeat_frequency: 1,
	payment_repeat_interval: "months",
	payment_repeat_on: null,
	documents: [],
	checklist: defaultBooleanFieldArray,
	licenses: [],
	has_base_price: false,
	base_price: null,
	base_period: "years",
	base_currency: getValueFromLocalStorage("userInfo")?.org_currency || "USD",
	complete_term: true,
	base_frequency: 1,
	custom_fields: [],
	payment_method_id: null,
	discount_type: "percentage",
	discount_value: null,
	one_time_fee: [],
	cost_amortization: "months",
	license_group_precedence: "start_date",
	agreement_type: null,
	true_ups_repeat_frequency: 1,
	true_ups_repeat_interval: "months",
	bulk_upload_id: null,
};

export const subReqBody = {
	entity: "subscription",
	is_app: true,
	app_id: null,
	app_name: null,
	app_logo: null,
	name: "Untitled",
	description: null,
	vendor_id: null,
	vendor_name: null,
	vendor_logo: null,
	primary_owner_id: null,
	primary_owner_name: null,
	primary_owner_profile: null,
	financial_owner_id: null,
	financial_owner_name: null,
	financial_owner_profile: null,
	negotiation_owner_id: null,
	negotiation_owner_name: null,
	negotiation_owner_profile: null,
	it_owner_id: null,
	it_owner_name: null,
	it_owner_profile: null,
	start_date: null,
	renewal_repeat_frequency: 1,
	renewal_repeat_interval: "months",
	next_renewal_date: null,
	auto_renews: true,
	licenses: [],
	has_base_price: false,
	base_price: null,
	base_period: "years",
	base_frequency: 1,
	base_currency: getValueFromLocalStorage("userInfo")?.org_currency || "USD",
	complete_term: true,
	custom_fields: [],
	payment_method_id: null,
	discount_type: "percentage",
	discount_value: null,
	one_time_fee: [],
	cost_amortization: "months",
	license_group_precedence: "start_date",
	documents: [],
	checklist: defaultBooleanFieldArray,
};

export const perpetualReqBody = {
	entity: "perpetual",
	is_perpetual: true,
	is_app: true,
	app_id: null,
	app_name: null,
	app_logo: null,
	renewing_contract: false,
	name: "Untitled",
	description: null,
	vendor_id: null,
	vendor_name: null,
	vendor_logo: null,
	primary_owner_id: null,
	primary_owner_name: null,
	primary_owner_profile: null,
	financial_owner_id: null,
	financial_owner_name: null,
	financial_owner_profile: null,
	negotiation_owner_id: null,
	negotiation_owner_name: null,
	negotiation_owner_profile: null,
	it_owner_id: null,
	it_owner_name: null,
	it_owner_profile: null,
	start_date: null,
	payment_term: "one_time",
	payment_date: null,
	documents: [],
	checklist: defaultBooleanFieldArray,
	has_base_price: false,
	base_price: null,
	base_period: "years",
	base_frequency: 1,
	base_currency: getValueFromLocalStorage("userInfo")?.org_currency || "USD",
	complete_term: true,
	licenses: [],
	custom_fields: [],
	payment_method_id: null,
	discount_type: "percentage",
	discount_value: null,
	one_time_fee: [],
	license_group_precedence: "start_date",
	cost_amortization: "start_date",
};

export const entityReqBodyObj = {
	subscription: subReqBody,
	contract: contractReqBody,
	perpetual: perpetualReqBody,
};

export const licenseTypes = [
	{
		label: "User Based",
		value: "user",
	},
	{
		label: "Qty Based",
		value: "quantity",
	},
];

export const LicenseDetailsHeaderCSS = {
	license_type: {
		width: 110,
		default: true,
	},
	license_name: {
		width: 140,
		default: true,
	},
	cost_per_license: {
		width: 145,
		default: true,
	},
	quantity: {
		width: 90,
		default: true,
	},
	in_use: {
		width: 90,
		default: true,
	},
	discount: {
		width: 90,
		default: false,
	},
	auto_increment: {
		width: 80,
		default: true,
	},
	license_included_in_base_price: {
		width: 80,
		default: true,
	},
	cost_per_term: {
		width: 80,
		default: true,
	},
	additional_col_dropdown: {
		width: 20,
		default: true,
	},
	license_term: {
		width: 250,
		default: false,
	},
};

export const amortizationOptions = [
	"months",
	"years",
	"financial_year",
	"quarter",
	"start_date",
];

export const assignSplitOptions = ["days", "months", "years"];

export const LicenseDetailsCSS = {
	Description: {
		width: 250,
		marginRight: "5px",
	},
	"License Type": {
		width: 110,
		marginRight: "5px",
	},
	"License Name": {
		width: 135,
		marginRight: "5px",
	},
	"License Term": {
		width: 215,
		marginRight: "5px",
	},
	"Start Date": {
		width: 105,
		marginRight: "5px",
	},
	"Cost/License": {
		width: 195,
		marginRight: "5px",
	},
	Discount: {
		width: 110,
		marginRight: "5px",
	},
	Quantity: {
		width: 120,
		marginRight: "5px",
	},
	"Auto Increment": {
		width: 100,
		marginRight: "5px",
	},
	"Auto Adjust": {
		width: 100,
		marginRight: "5px",
	},
	"Group Auto Increment": {
		width: 100,
		marginRight: "5px",
	},
	"Cost/Term": {
		width: 70,
		marginRight: "5px",
	},
	Edit: {
		width: 40,
		marginRight: "5px",
	},
	Remove: {
		width: 40,
		marginRight: "5px",
	},
	"Total Cost": {
		width: 70,
		marginRight: "5px",
	},
	Document: {
		width: 150,
		marginRight: "5px",
	},
};

export const LicenseGroupsHeaderCSS = {
	Description: {
		width: 160,
		marginRight: "5px",
	},
	"License Term": {
		width: 225,
		marginLeft: "5px",
		marginRight: "5px",
	},
	"Start Date": {
		width: 125,
		marginLeft: "5px",
		marginRight: "5px",
	},
	"Cost/License": {
		width: 195,
		marginLeft: "5px",
		marginRight: "5px",
	},
	Discount: {
		width: 110,
		marginLeft: "5px",
		marginRight: "5px",
	},
	Quantity: {
		width: 75,
		marginLeft: "5px",
		marginRight: "5px",
	},
	Document: {
		width: 150,
		marginLeft: "5px",
		marginRight: "5px",
	},
	Remove: {
		width: 40,
		marginLeft: "5px",
	},
};

export const licenseApplyCostOptions = [
	"pro-rata",
	"months",
	"quarter",
	"years",
];

export const defaultLicenseGroup = {
	description: "",
	start_date: null,
	end_date: null,
	amount: null,
	quantity: null,
	group_type: "true_up",
	discount_type: "percentage",
	discount: null,
	complete_term: false,
	frequency: 1,
	period: "term",
	document: null,
};

export const licenseDetailsErrorMsgs = {
	NEGATIVE_VALUE:
		"Negative quantity and negative cost/term are not allowed for a license. Please check and correct the license details and try again!",
	SAME_NAME:
		"Licenses with same name are not allowed. Please change the names and try again!",
	SAME_DESCRIPTION:
		"Groups of a single license cannot have the same description. Please make the description unique and try again!",
	NO_DESCRIPTION:
		"Description field of groups is mandatory. Please fill the description for all groups and try again!",
	NO_TRUE_UP:
		"Mandatory details are missing for licenses (license quantity, cost, period). Please fill those to proceed.",
};

export const licenseFormErrorMsgs = {
	EMPTY_FIELDS:
		"Name and description fields cannot be left empty. Please fill them out and try again!",
	GROUPS: {
		NOT_AN_ARRAY: "license.groups is not an array",
		EMPTY: "License does not have any groups",
		AUTO_INCREMENT_FOR_LICENSE_TERM:
			"Please select the cost / per license in month, years, quarter or terms only for auto incremented licenses.",
	},
	INCORRECT_TYPE:
		"Cannot change type from Seat Based to Quantity Based as license is assigned",
};

export const licenseGroupPrecedenceOptions = ["start_date", "amount"];

export const tooltipTexts = {
	COST_AMORTIZATION:
		"The contract/subscription cost will be distributed according to the option set here.",
	APPLY_COST:
		"The minimum duration for which cost will be applied for the license.",
	LICENSE_GROUP_PRECEDENCE:
		"Licenses will be assigned to users based on earliest start date/lowest amount based on this setting when multiple groups are active.",
	AUTO_INCREMENT:
		"True ups will be added automatically when more licenses are assigned to the users.",
	AUTO_ADJUST:
		"License quantity will be auto adjusted to match the assigned license quantity.",
	TOTAL_COST: "Cost of the license since start date till today",
	CONTRACT_DETAILS: "Cost of all licenses for their entire duration",
	SUBSCRIPTION_DETAILS:
		"Cost of all licenses since subscription start date till today",
	PERPETUAL_DETAILS: "Cost of all licenses",
};

export const contractPaymentDateSuggestions = [
	{
		display: "PIA",
		daysToBeAddedToStartDate: 0,
	},
	{
		display: "NET 7",
		daysToBeAddedToStartDate: 7,
	},
	{
		display: "NET 10",
		daysToBeAddedToStartDate: 10,
	},
	{
		display: "NET 15",
		daysToBeAddedToStartDate: 15,
	},
	{
		display: "NET 30",
		daysToBeAddedToStartDate: 30,
	},
	{
		display: "NET 45",
		daysToBeAddedToStartDate: 45,
	},
	{
		display: "NET 60",
		daysToBeAddedToStartDate: 60,
	},
	{
		display: "NET 90",
		daysToBeAddedToStartDate: 90,
	},
];

export const cancelByDateSuggestions = [
	{
		display: "30 Days",
		daysToBeAddedToStartDate: -30,
	},
	{
		display: "60 Days",
		daysToBeAddedToStartDate: -60,
	},
	{
		display: "90 Days",
		daysToBeAddedToStartDate: -90,
	},
];

export const licenseUtilizationTooltipArray = [
	{
		typeLabel: "Unassigned",
		numberKey: "unassigned_licenses",
		costKey: "unassigned_licenses_cost",
		costTypeKey: "unassigned_licenses_cost_type",
		monthlySavingsKey: "monthly_potential_savings_unassigned",
		yearlySavingsKey: "annual_potential_savings_unassigned",
		color: "#FFB169",
	},
	{
		typeLabel: "Undeprovisioned",
		numberKey: "left_organisation_licenses",
		costKey: "left_organisation_licenses_cost",
		costTypeKey: "left_organisation_licenses_cost_type",
		monthlySavingsKey: "monthly_potential_savings_left_org",
		yearlySavingsKey: "annual_potential_savings_left_org",
		color: "#B5D1FD",
		navigateTo: (history, app_id, selected_filter, license_id) =>
			history.push(
				`/applications/${app_id}?metaData=${getHyperLinkMetaData(
					optimizationLicenseClassifications.LEFT_ORG,
					selected_filter,
					license_id
				)}#users`
			),
	},
	{
		typeLabel: "Unused",
		numberKey: "unused_licenses",
		costKey: "unused_licenses_cost",
		costTypeKey: "unused_licenses_cost_type",
		monthlySavingsKey: "monthly_potential_savings_unused",
		yearlySavingsKey: "annual_potential_savings_unused",
		color: "#478CFA",
		navigateTo: (history, app_id, selected_filter, license_id) =>
			history.push(
				`/applications/${app_id}?metaData=${getHyperLinkMetaData(
					optimizationLicenseClassifications.UNUSED,
					selected_filter,
					license_id
				)}#users`
			),
	},
	{
		typeLabel: "Under Used",
		numberKey: "under_used_licenses",
		costKey: "under_used_licenses_cost",
		costTypeKey: "under_used_licenses_cost_type",
		monthlySavingsKey: "monthly_potential_savings_under_used",
		yearlySavingsKey: "annual_potential_savings_under_used",
		color: "#2A64F3",
		navigateTo: (history, app_id, selected_filter, license_id) =>
			history.push(
				`/applications/${app_id}?metaData=${getHyperLinkMetaData(
					optimizationLicenseClassifications.UNDER_USED,
					selected_filter,
					license_id
				)}#users`
			),
	},
	{
		typeLabel: "Actively Used",
		numberKey: "actively_used_licenses",
		color: "#6967E0",
		navigateTo: (history, app_id, selected_filter, license_id) =>
			history.push(
				`/applications/${app_id}?metaData=${getHyperLinkMetaData(
					optimizationLicenseClassifications.ACTIVELY_USED,
					selected_filter,
					license_id
				)}#users`
			),
	},
	// uncomment total row if required
	// {
	// 	typeLabel: "Total",
	// 	numberKey: "quantity",
	// 	monthlySavingsKey: "aggregated_monthly_potential_savings",
	// 	yearlySavingsKey: "aggregated_annual_potential_savings",
	// 	color: "#000000",
	// },
];

export const LicenseMapperReduxConstants = {
	REQUEST_ALL_LICENSE_MAPPER_USERS: "REQUEST_ALL_LICENSE_MAPPER_USERS",
	FETCH_ALL_LICENSE_MAPPER_USERS: "FETCH_ALL_LICENSE_MAPPER_USERS",
	CLEAR_ALL_LICENSE_MAPPER_USERS: "CLEAR_ALL_LICENSE_MAPPER_USERS",
	UPDATE_ALL_LICENSE_MAPPER_USERS: "UPDATE_ALL_LICENSE_MAPPER_USERS",
	UPDATE_FEW_LICENSE_MAPPER_USERS: "UPDATE_FEW_LICENSE_MAPPER_USERS",
	UPDATE_TABLE_SCROLL_POSITION: "UPDATE_TABLE_SCROLL_POSITION",
};

export const MapLicenseViaCSVSteps = [
	{
		title: "Download Template",
		description:
			"Download the data containing the list of all users and their currently assigned licenses & other details.",
		img: mapLicenseStep1,
	},
	{
		title: "Add Licenses",
		description:
			"Edit the the csv with the date you wish to update. Please follow the guide provided on the link below to understand in what format you should update the data in the csv.",
		img: mapLicenseStep2,
	},
	{
		title: "Upload CSV",
		description:
			"Upload the csv and verify the updated data in the platform. Then you can click ‘Save’ button on the right top corner to process the license updation.",
		img: mapLicenseStep3,
	},
];

export const LicenseMapperCSVHeaders = [
	"Email",
	"License",
	"Start Date",
	"Role",
	"End Date",
];

export const LicenseMapperCSVDateFormat = "YYYY-MM-DD";

export const BulkUploadAppUsersEmailHeader = "Email";
export const BulkUploadAppUsersRoleHeader = "Role";

export const AddAppUsersViaCSVSteps = [
	{
		title: "Download Template",
		description: "Download the template csv file for adding the emails.",
		img: mapLicenseStep1,
	},
	{
		title: "Add Emails",
		description:
			"Edit the the csv with the emails of users you want to add to the application.",
		img: mapLicenseStep2,
	},
	{
		title: "Upload CSV",
		description:
			"Upload the csv to add users associated with emails to the application.",
		img: mapLicenseStep3,
	},
];

export const contractAgreementTypes = {
	master: {
		label: "Master",
		value: "master",
	},
	sow: {
		label: "SOW",
		value: "sow",
	},
	service: {
		label: "Service",
		value: "service",
	},
	true_up: {
		label: "True Up",
		value: "true_up",
	},
	order: {
		label: "Order",
		value: "order",
	},
	others: {
		label: "Others",
		value: "others",
	},
};

export const ContractErrorMessages = {
	"license does not exist": "License does not exist.",
	"group end date is missing":
		"Please add a valid end date under License split quantity.",
	"missing contract details":
		"Please review the start date, base price amount and base price currency.",
	"license validation failed for license :":
		"Internal error: Please check the base currency.",
	"license validation failed for license_id :":
		"Internal error: Please check the base currency.",
	"amount is negative after discount":
		"The total cost is negative after discount. Please review the discount percentage/amount.",
	"contract payment fields not set":
		"Payment term not set. Please review the payment terms.",
	"cannot set renewal. payment frequency and period fields not set":
		"Payment term not set. Please review the payment terms.",
	"version error":
		"Concurrent requests to add/edit the contract. Please try again.",
	"cannot terminate contract":
		"Cannot terminate contract as there are auto-detected licenses",
	"cannot edit terminated contract": "The contract is terminated",
};

export const ContractLicenseTypeNames = {
	SEAT_BASED: "Seat based",
	QUANTITY_BASED: "Usage based",
};

export const EntityActionType = {
	CREATE: "create",
	EDIT: "edit",
	DRAFT: "draft",
};

export const ContractReduxConstants = {
	CONTRACT_OVERVIEW_DETAILS_REQUESTED: "CONTRACT_OVERVIEW_DETAILS_REQUESTED",
	CONTRACT_OVERVIEW_DETAILS_FETCHED: "CONTRACT_OVERVIEW_DETAILS_FETCHED",
	GET_UNMAPPED_LICENSES_DATA_REQUESTED:
		"GET_UNMAPPED_LICENSES_DATA_REQUESTED",
	GET_UNMAPPED_LICENSES_DATA_FETCHED: "GET_UNMAPPED_LICENSES_DATA_FETCHED",
};

export const BulkUploadContractsViaCSVSteps = [
	{
		title: "Download Template",
		description:
			"Click below to download the template containing field names & sample data.",
		img: mapLicenseStep1,
	},
	{
		title: "Edit CSV",
		description: "Update the csv with actual data in the given format.",
		img: mapLicenseStep2,
	},
	{
		title: "Upload CSV",
		description:
			"Click below to upload the formated csv with actual data. Please check back the uploads tab to know the status of the processing.",
		img: mapLicenseStep3,
	},
];

export const BulkContractUploadCSVTemplate = [
	{
		"Contract Name": "Google Workspace Contract",
		Application: "Google Workspace",
		Currency: "INR",
		"Base Price": "1000",
		Description: "Contract for GW",
		Vendor: "Google Workspace",
		"Primary Owner Email": "john.doe@zluri.com",
		"Finance Owner Email": "john.doe@zluri.com",
		"IT Owner Email": "john.doe@zluri.com",
		"Negotiation Owner Email": "john.doe@zluri.com",
		"Start Date": "2023-12-15",
		"End Date": "2024-12-14",
		"Cancel By Date": "2024-11-30",
		"Renew By Date": "2024-11-30",
		"Payment Term": "One Time",
		"Payment Term Period": "-",
		"Payment Term Frequency": "-",
		"Payment Date": "2023-12-15",
		"Agreement Type": "Order",
		"Auto Renews": "Yes",
		"Cost Amortization": "Months",
		"License Assignment Precedence": "Start Date",
		"Contract has True Ups?": "No",
		"True Up Period": "-",
		"True Up Frequency": "-",
		"First True Up Date": "-",
		"Discount Type": "Percentage",
		"Discount Value": "0",
		"License Name": "GW Basic Licenses",
		"License Type": "Seat",
		"Auto Increment": "Yes",
		"Apply Cost": "Pro-rata",
		"Group Description": "GW Basic Group",
		"Group Start Date": "2023-12-15",
		"Group End Date": "2024-12-14",
		"Group Cost Per License": "10",
		"Group Cost Period": "Months",
		"Group Quantity": "10",
		"Group Discount Type": "Percentage",
		"Group Discount Value": "0",
	},
];

export const BulkUploadMockCSVDataWithErrors = [
	{
		"Contract Name": "Google Workspace Contract",
		Application: "Google Book",
		Currency: "Kalashian Dollar",
		"Base Price": "Fifty",
		Description: "Contract for GW",
		Vendor: "Google Workspace",
		"Primary Owner Email": "",
		"Finance Owner Email": "bradley.cooper@zluri.com",
		"IT Owner Email": "john.doe@zluri.com",
		"Negotiation Owner Email": "john.doe@zluri.com",
		"Start Date": "2023-12-15",
		"End Date": "2022-12-14",
		"Cancel By Date": "",
		"Renew By Date": "",
		"Payment Term": "One Time",
		"Payment Term Period": "-",
		"Payment Term Frequency": "-",
		"Payment Date": "2023-12-15",
		"Agreement Type": "Order",
		"Cost Amortization": "",
		"License Assignment Precedence": "Start Date",
		"Contract has True Ups?": "No",
		"True Up Period": "-",
		"True Up Frequency": "-",
		"First True Up Date": "-",
		"Discount Type": "Percentage",
		"Discount Value": "0",
		"License Name": "GW Basic Licenses",
		"License Type": "Seat",
		"Auto Increment": "Yes",
		"Apply Cost": "Pro-rata",
		"Group Description": "GW Basic Group",
		"Group Start Date": "2023-12-15",
		"Group End Date": "2024-12-14",
		"Group Cost Per License": "10",
		"Group Cost Period": "Per Month",
		"Group Quantity": "10",
		"Group Discount Type": "Percentage",
		"Group Discount Value": "0",
	},
];

export const bulkContractUploadCSVHeaders = [
	"Contract Name",
	"Application",
	"Currency",
	"Base Price",
	"Description",
	"Vendor",
	"Primary Owner Email",
	"Finance Owner Email",
	"IT Owner Email",
	"Negotiation Owner Email",
	"Start Date",
	"End Date",
	"Cancel By Date",
	"Renew By Date",
	"Payment Term",
	"Payment Term Period",
	"Payment Term Frequency",
	"Payment Date",
	"Auto Renews",
	"Agreement Type",
	"Cost Amortization",
	// "License Assignment Precedence",
	"Contract has True Ups?",
	"True Up Period",
	"True Up Frequency",
	"First True Up Date",
	"Discount Type",
	"Discount Value",
	"License Name",
	"License Type",
	"Auto Increment",
	"Apply Cost",
	"Group Description",
	"Group Start Date",
	"Group End Date",
	"Group Cost Per License",
	"Group Cost Period",
	"Group Quantity",
	"Group Discount Type",
	"Group Discount Value",
];

export const ContractUploadsAddDropdownOptions = [
	{
		label: "Upload PDF",
		onClick: (handleRefresh, dispatch) => {
			dispatch(
				openModal("contractPDFUpload", {
					title: "Upload PDF Contract",
					handleRefresh,
				})
			);
		},
	},
	{
		label: "Upload CSV",
		onClick: (handleRefresh, dispatch) => {
			dispatch(
				openModal("bulkAddEditViaUploadCSV", {
					title: "Bulk Contract Upload",
					steps: BulkUploadContractsViaCSVSteps,
					getStepButtons: ({
						step,
						setUploading,
						setUploaded,
						setInvalidRows,
						setFile,
						handleClose,
					}) => {
						switch (step) {
							case 1:
								return (
									<>
										<ContractBulkUploadTemplate />
									</>
								);
							case 2:
								return <></>;
							case 3:
								return (
									<>
										<ContractBulkUploadCSVImport
											setInvalidRows={setInvalidRows}
											setUploading={setUploading}
											setUploaded={setUploaded}
											setFile={setFile}
											handleClose={() => {
												handleClose();
												handleRefresh();
											}}
										/>
									</>
								);
							default:
								return <></>;
						}
					},
					invalidRowsComponent: ({
						rows,
						reUploadFile,
						handleClose,
					}) => (
						<>
							<ContractBulkUploadCSVErrorDisplay
								rows={rows}
								reUploadFile={reUploadFile}
								handleClose={handleClose}
							/>
						</>
					),
					descriptionHeight: "70px",
				})
			);
		},
	},
];

export const uploadPDFContractFileProgressStatuses = {
	in_progress: {
		label: () => "Upload In Progress",
		icon: uploadpdfcontractfileuploadinprogress,
		barColor: "#5287e8",
	},
	completed: {
		label: () => "Upload Completed",
		icon: uploadpdfcontractfileuploadcompleted,
		barColor: "#009307",
	},
	failed: {
		label: (err) => `Upload Failed${err ? ` - ${err}` : ""}`,
		icon: uploadpdfcontractfileuploadfailed,
		barColor: "#FE6955",
	},
};

export const uploadPDFContractMaxSize = 5242880;

export const basicDetailsFormFields = [
	"app_id",
	"is_app",
	"description",
	"vendor_id",
	"primary_owner_id",
	"finance_owner_id",
	"it_owner_id",
	"negotiation_owner_id",
	"start_date",
	"end_date",
	"cancel_by",
	"renew_by",
	"payment_term",
	"payment_method_id",
	"agreement_type",
	"contract_auto_renews",
	"license_group_precedence",
	"cost_amortization",
];

export const filledByAITag = (
	<>
		<div className="filled_by_ai_tag">
			<img src={uploadpdfcontracticon} alt="" width={12} height={12} />
			<div className="filled_by_ai_tag_text">AI</div>
		</div>
	</>
);

export const ContractDetailsToolTip = {
	USAGE_BASED_LICENSE:
		"A usage based licensing is a type of license model where the vendor charges based on the usage of the application that the vendor sells.",
	SEAT_BASED_LICENSE:
		"A seat based license is a software license model based on the number of individual users who have access to a digital service or product",
	TOTAL_CONTRACT_VALUE: "Cost of all licenses for their enitre duration",
};
