import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function LicenseAssignmentNotification({ contractId }) {
	const history = useHistory();
	return (
		<>
			<div
				className="license_assignment_notification_redirection"
				onClick={() =>
					history.push(`/licenses/contracts/${contractId}#users`)
				}
			>
				View assigned licenses
			</div>
		</>
	);
}
