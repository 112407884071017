import V2Table from "containers/V2Table";
import {
	STATUS,
	V2_TABLE_ENTITIES,
	ViewType,
} from "modules/AccessReview/constants/constants";
import {
	updateFiltersBy,
	updateProgressbar,
} from "modules/AccessReview/redux/AccessReview.actions";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./SelectUsers.css";
import { getFilters, getReviewersObj } from "modules/AccessReview/util/util";
import HeaderContentForTable from "../HeaderContentForTable/HeaderContentForTable";
import "modules/AccessReview/components/SelectApplicationInModal/SelectApplicationInModal.css";
import "modules/AccessReview/components/SelectUsers/SelectUsers.css";

export default function SelectUsers(props) {
	const dispatch = useDispatch();
	const { applicationReview, isEdit } = useSelector(
		(state) => state.accessReview
	);
	const tableData = useSelector(
		(state) => state.v2Table?.[V2_TABLE_ENTITIES.SELECT_USERS]
	);
	const primaryReviewer = applicationReview?.levels[0]?.primary_reviewer;
	const fallbackReviewer = applicationReview?.levels[0]?.fallback_reviewer;
	const apiProps = {
		...getReviewersObj(primaryReviewer, fallbackReviewer),
		appId: applicationReview?.appId,
	};

	useEffect(() => {
		dispatch(
			updateFiltersBy(tableData?.filter_by || [], tableData?.count || 0)
		);
		const isValidated =
			tableData?.filter_by?.length > 0 &&
			!tableData?.loading &&
			tableData?.count > 0;
		dispatch(
			updateProgressbar(
				1,
				isValidated ? STATUS.Completed : STATUS.InProgress,
				ViewType.ViewsInAddApplicationModal
			)
		);
		isValidated
			? props.setNextBtnDisabled(false)
			: props.setNextBtnDisabled(true);
	}, [tableData?.filter_by, tableData?.count]);

	useEffect(() => {
		dispatch({
			type: "GET_TABLE_DATA",
			payload: {
				entity: V2_TABLE_ENTITIES.SELECT_USERS,
				filterBy: getFilters(isEdit, applicationReview),
				cols: [],
				shouldRefresh: true,
				apiProps: apiProps,
			},
		});
	}, []);

	return (
		<div className="select-application-card hide-scrollbar">
			<div className="users__table__container">
				<V2Table
					entity={V2_TABLE_ENTITIES.SELECT_USERS}
					apiProps={apiProps}
					intID={applicationReview?.appId}
					hideOptionsMenu={true}
					hideColumnModifier
					disableSort
					hideSearch
					headerContent={
						<HeaderContentForTable
							wrapperClasses="w-100"
							headerText="Add Filters to Select Users for Review"
							subHeaderText="Enable necessary filters to modify the list of users."
						/>
					}
					showCheckboxColumn={false}
					emptyMessage="No results found"
					paginationEntity="app users"
				></V2Table>
			</div>
		</div>
	);
}
