import React, { useState } from "react";
import "./styles.css";

import classNames from "classnames";
import { string, node, func, arrayOf, shape, bool, array } from "prop-types";
import { uniq } from "underscore";

import {
	BODY_VARIANT,
	CheckableTreeSelect,
	Divider,
	GREY_VARIANT,
	Typography,
} from "@zluri/ui-components";
import { CategoryOption, CategoryOptions } from "./CategoryOptions";

import { useMultiLevelFilterOptions, useSelectedOptions } from "./hooks";

const MultilevelFilter = ({
	subName,
	categorizedOptions,
	selectedKeys,
	onSearch,
	onCheck,
	loading,
}) => {
	const [selectedCategory, setSelectedCategory] = useState("all");
	const { options } = useMultiLevelFilterOptions(
		categorizedOptions,
		selectedCategory
	);

	const { selectedOptions, handleSelection } = useSelectedOptions(
		selectedCategory,
		selectedKeys
	);

	const handleOnSearchChange = (e) => {
		onSearch && onSearch(e);
	};

	const handleOnCheck = (keys, info) => {
		handleSelection({ keys, info, onCheck: onCheck });
	};

	return (
		<div className="d-flex flex-column flex-1 background-color-white border-radius-8 z_multilevel_filter">
			<section className="flex-1 d-flex gap-1 align-items-start z_multilevel_filter-content">
				<section
					className={classNames(`d-flex flex-column gap-1 flex-1`, {
						"z_loading-overlay": loading,
					})}
					style={{ width: "174px" }}
				>
					<Typography
						className="padding_8 z_multilevel_filter-sub_name"
						variant={BODY_VARIANT.BODY_3_BOLD}
						color={GREY_VARIANT.SECONDARY_GREY_2}
					>
						{subName}
					</Typography>
					<span className="d-flex flex-column gap-1 z_multilevel_filter-categories z_scrollbar-secondary">
						<CategoryOption
							title="All Types"
							count={selectedOptions["all"]?.keys?.length}
							onClick={() => setSelectedCategory("all")}
							isSelected={selectedCategory === "all"}
						/>
						<CategoryOptions
							options={
								categorizedOptions?.filter?.(
									(option) => option?.options?.length
								) || []
							}
							onClick={(key) => setSelectedCategory(key)}
							isSelected={(key) => key === selectedCategory}
							count={(key) => selectedOptions[key]?.keys?.length}
						/>
					</span>
				</section>
				<Divider
					borderRadius={10}
					height="inherit"
					width="1px"
					backgroundColor="#E1E8F8"
				/>
				<section className="d-flex flex-1" style={{ width: "203px" }}>
					<CheckableTreeSelect
						inputProps={{
							placeholder: "Start Typing...",
						}}
						height={165}
						containerClassname="flex-1"
						checkable
						onCheck={handleOnCheck}
						checkedKeys={uniq(
							selectedOptions[selectedCategory]?.keys
						)}
						treeData={options}
						onSearch={handleOnSearchChange}
						loading={loading}
					/>
				</section>
			</section>
		</div>
	);
};

export default MultilevelFilter;

MultilevelFilter.propTypes = {
	subName: node,
	categories: arrayOf(
		shape({
			value: string,
			title: node,
			disabled: bool,
		})
	),
	categorizedOptions: arrayOf(
		shape({
			value: string,
			title: node,
			children: arrayOf(
				shape({
					value: string,
					title: node,
					disabled: bool,
					children: array,
				})
			),
			disabled: bool,
		})
	),
	onSearch: func,
	onCheck: func,
	selectedKeys: arrayOf(shape({ [string]: arrayOf(string) })),
	loading: bool,
};
