import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { GREY_VARIANT, Typography } from "@zluri/ui-components";

const StatsBlockWrapper = styled.div`
	padding: 10px;
	display: flex;
	flex-direction: column;
	gap: 10px;
`;

const StatsOuterWrapper = styled.div`
	display: flex;
	justify-content: center;
	gap: 8px;
`;

const StatsItemWrapper = styled.div`
	border-radius: 8px;
	background: var(--Secondary_Grey-7, #f6f7fa);
	display: flex;
	padding: 8px;
	align-items: center;
	width: 100%;
	gap: 8px;
`;

const StatsBlock = ({ stats, heading }) => {
	return (
		<StatsBlockWrapper>
			<Typography
				variant="body_2_bold"
				color={GREY_VARIANT.SECONDARY_GREY_2}
			>
				{heading}
			</Typography>
			<StatsOuterWrapper>
				{stats.map(({ icon, name, value }) => (
					<StatsItemWrapper key={name}>
						<img src={icon} width={16} height={16} alt={name} />
						<Typography
							variant="body_2_regular"
							color={GREY_VARIANT.SECONDARY_GREY_2}
						>
							{name}
						</Typography>
						<Typography
							variant="body_2_bold"
							style={{ marginLeft: "auto" }}
							color={GREY_VARIANT.SECONDARY_GREY_2}
						>
							{value}
						</Typography>
					</StatsItemWrapper>
				))}
			</StatsOuterWrapper>
		</StatsBlockWrapper>
	);
};

export default StatsBlock;
StatsBlock.propTypes = {
	stats: PropTypes.array,
	heading: PropTypes.string,
};
