import appOverviewTopCardUsers from "../assets/appOverviewTopCardUsers.svg";
import appOverviewTopCardLicenses from "../assets/appOverviewTopCardLicenses.svg";
import appOverviewTopCardContract from "../assets/appOverviewTopCardContract.svg";
import appOverviewTopCardSpendCost from "../assets/appOverviewTopCardSpendCost.svg";
import { FEATURE_NAME } from "modules/shared/constants/plan.constant";
import { userRoles } from "constants/userRole";

export const singleApplicationReduxConstants = {
	APP_BASIC_DETAILS_REQUESTED: "APP_BASIC_DETAILS_REQUESTED",
	APP_BASIC_DETAILS_FETCHED: "APP_BASIC_DETAILS_FETCHED",
	UPDATE_APP_CUSTOM_FIELD_DATA: "UPDATE_APP_CUSTOM_FIELD_DATA",
	APP_CHARGEBACK_REQUESTED: "APP_CHARGEBACK_REQUESTED",
	APP_CHARGEBACK_FETCHED: "APP_CHARGEBACK_FETCHED",
	APP_ASSOCIATION_REQUESTED: "APP_ASSOCIATION_REQUESTED",
	APP_ASSOCIATION_FETCHED: "APP_ASSOCIATION_FETCHED",
	APP_SOURCE_SETTINGS_REQUESTED: "APP_SOURCE_SETTINGS_REQUESTED",
	APP_SOURCE_SETTINGS_FETCHED: "APP_SOURCE_SETTINGS_FETCHED",
	UPDATE_APP_BASIC_DETAILS_KEY: "UPDATE_APP_BASIC_DETAILS_KEY",
	APP_DEPARTMENT_SPLIT_REQUESTED: "APP_DEPARTMENT_SPLIT_REQUESTED",
	APP_DEPARTMENT_SPLIT_FETCHED: "APP_DEPARTMENT_SPLIT_FETCHED",
	APP_SPEND_TRIGGER_REQUESTED: "APP_SPEND_TRIGGER_REQUESTED",
	APP_SPEND_TRIGGER_FETCHED: "APP_SPEND_TRIGGER_FETCHED",
	UPDATE_APP_SPEND_TRIGGER_DETAILS: "UPDATE_APP_SPEND_TRIGGER_DETAILS",
	APP_DEPARTMENT_WISE_SPEND_REQUESTED: "APP_DEPARTMENT_WISE_SPEND_REQUESTED",
	APP_DEPARTMENT_WISE_SPEND_FETCHED: "APP_DEPARTMENT_WISE_SPEND_FETCHED",
};

export const singleAppOverviewLeftPaneTopFieldsBelowOwners = [
	"CATEGORY",
	"ACTIVE CONTRACTS",
	"TYPE",
	"APP BUDGET",
	"APP LINK",
	"USAGE",
];

export const ALL_APPS_TOOLTIP_TEXT = {
	active_user:
		"Number of users with 'User Application Status' as 'Active'  detected from all sources",
	actual_spends_in_current_fy:
		"Sum of transaction amounts from the beginning of financial year till today",
	annualised_cost: "Cost of licenses in the current month multiplied by 12",
	active_users_primary_source:
		"Number of active users detected from the direct integration",
	assigned_licenses: "Number of licenses which are assigned to users",
	active_users_from_direct_integration:
		"Number of active users detected from the direct integration",
	active_users_from_all_sources:
		"Number of users with 'User Application Status' as 'Active'  detected from all sources",
	managed_apps:
		"Number of centrally managed, team managed & individually managed applications with 'user application status' as active ",
	upcoming_contract_end_date:
		"The end date for the contract which is coming up for renewal next",
	restricted_apps:
		"The count of applications marked as 'Restricted' with  'User Application Status' as 'Active' ",
	total_apps:
		"The count of applications with 'User Application Status' as 'Active' ",
};

export const appOverviewTopRowCardDetails = {
	row1: [
		{
			title: "Active Users from Direct Integration",
			text: ALL_APPS_TOOLTIP_TEXT.active_users_from_direct_integration,
			img: appOverviewTopCardUsers,
			valueKey: "app_active_users_via_direct_integration",
		},
		{
			title: "Active Users from all sources",
			text: ALL_APPS_TOOLTIP_TEXT.active_users_from_all_sources,
			img: appOverviewTopCardUsers,
			valueKey: "active_app_user_count_via_all_sources",
		},
		{
			title: "Assigned Licenses",
			img: appOverviewTopCardLicenses,
			text: ALL_APPS_TOOLTIP_TEXT.assigned_licenses,
			valueKey: "app_assigned_license_count",
			shouldRender: FEATURE_NAME.LM_LICENSE,
			hideForRoles: [userRoles.IT_VIEWER],
		},
	],
	row2: [
		{
			title: "Upcoming Contract End Date",
			text: ALL_APPS_TOOLTIP_TEXT.upcoming_contract_end_date,
			img: appOverviewTopCardContract,
			valueKey: "app_active_contracts",
		},
		{
			title: "Actual Spend in Current FY",
			text: ALL_APPS_TOOLTIP_TEXT.actual_spends_in_current_fy,
			img: appOverviewTopCardSpendCost,
			valueKey: "app_spend",
			shouldRender: FEATURE_NAME.APP_SPENDS_BUDGET,
			hideForRoles: [userRoles.IT_VIEWER],
		},
		{
			title: "Annualized Cost",
			text: ALL_APPS_TOOLTIP_TEXT.annualised_cost,
			img: appOverviewTopCardSpendCost,
			valueKey: "app_cost",
			shouldRender: FEATURE_NAME.LM_COSTS,
		},
	],
};
