import React from "react";
import PropTypes from "prop-types";
import { BUTTON_VARIANT, GREY_VARIANT, Typography } from "@zluri/ui-components";

import { boolCustomFieldOptions } from "modules/applications/constants/AppAbout.constants";

import checkBlueIcon from "assets/check-filled.svg";

export function CustomBoolEditPopover({ data, field_id, ...rest }) {
	const handleEdit = (value) => {
		rest?.handleCustomFieldEdit(data?.[field_id] ? "replace" : "add", {
			id: field_id,
			value,
		});
	};

	return (
		<div className="d-flex gap-2 app-about-popover-select-container padding_2">
			{boolCustomFieldOptions.map((option) => {
				return (
					<div
						className="d-flex align-center justify-between app-about-select-container"
						style={{
							backgroundColor:
								option.value === data?.[field_id] && "#E8F0FC",
						}}
						key={option.value}
						onClick={() => handleEdit(option.value)}
					>
						<div className="d-flex align-center gap-4 app-about-select-container-left">
							<Typography
								color={GREY_VARIANT.SECONDARY_GREY_2}
								variant={
									BUTTON_VARIANT.BUTTON_EXTRA_SMALL_MEDIUM
								}
							>
								{option.label}
							</Typography>
						</div>
						<div className="app-about-select-container-right">
							{option.value === data[field_id] && (
								<img
									src={checkBlueIcon}
									alt="check"
									width={14}
									height={12}
								/>
							)}
						</div>
					</div>
				);
			})}
		</div>
	);
}

CustomBoolEditPopover.propTypes = {
	data: PropTypes.object,
	field_id: PropTypes.string,
};
