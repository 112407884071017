import React from "react";
import leftarrow from "assets/icons/Icon_Left_Chevron.svg";
import { BUTTONS } from "modules/AccessReview/constants/constants";
import {
	BODY_VARIANT,
	Button,
	IconButton,
	PrimaryButtonVariant,
	Tooltip,
	Typography,
	theme,
} from "@zluri/ui-components";
import "modules/AccessReview/components/OverviewDataVisualizationHeader/OverviewDataVisualizationHeader.css";

export default function ControlFlowButtonGroup({
	onBackClick,
	onCancelClick,
	onActionClick,
	actionBtnDisabled,
	actionBtnText,
	iconBtnDisabled,
	actionBtnLoading,
	actionBtnClasses,
	actionBtnVariant = PrimaryButtonVariant.primary_default,
	isCancelBtnBlock = false,
	isActionBtnBlock = false,
	style,
	cancelBtnText = BUTTONS.CANCEL,
	cancelBtnVariant = PrimaryButtonVariant.primary_alternative,
	actionBtnTooltipText,
}) {
	const ActionBtn = (
		<Button
			theme={theme}
			variant={actionBtnVariant}
			disabled={actionBtnDisabled}
			loading={actionBtnLoading}
			onClick={onActionClick}
			className={`ht-32px ${actionBtnClasses}`}
			block={isActionBtnBlock}
		>
			{actionBtnText || "Next"}
		</Button>
	);
	return (
		<div
			className="d-flex align-items-center justify-content-center"
			style={{ gap: "8px", ...style }}
		>
			{!iconBtnDisabled && onBackClick && (
				<IconButton
					onClick={onBackClick}
					icon={leftarrow}
					style={{ height: "33px" }}
				/>
			)}
			{onCancelClick && (
				<Button
					theme={theme}
					variant={cancelBtnVariant}
					onClick={onCancelClick}
					block={isCancelBtnBlock}
				>
					{cancelBtnText}
				</Button>
			)}
			{onActionClick &&
				(actionBtnTooltipText ? (
					<Tooltip
						content={
							<Typography
								variant={BODY_VARIANT.BODY_2_REGULAR}
								color="white"
								className="text-center"
							>
								{actionBtnTooltipText}
							</Typography>
						}
					>
						{ActionBtn}
					</Tooltip>
				) : (
					ActionBtn
				))}
		</div>
	);
}
