import React from "react";

import PropTypes from "prop-types";

import { BUTTON_VARIANT, Dropdown, Typography } from "@zluri/ui-components";
import { LegendDropdown } from "../../chargebacks/components/Legends/LegendInfo";

import dropdown_arrow_black from "assets/dropdownarrow-black.svg";

const Footer = ({ split_by, legends }) => {
	return (
		<div className="d-flex align-items-center gap-10">
			<Dropdown
				overlayClassName="z_graph_header-view"
				menu={{
					items: split_by?.map((s) => ({
						key: s?.value,
						label: s?.text,
					})),
				}}
				disabled={true}
			>
				<span
					className="d-flex align-items-center gap-8 p-2 blue_tint_bg border-radius-8 cursor-pointer z_graph_header-view_tab"
					style={{ width: "104px" }}
				>
					<Typography
						variant={BUTTON_VARIANT.BUTTON_EXTRA_SMALL_SEMIBOLD}
						color="#2266e2"
						className="text-nowrap"
					>
						{split_by?.[0]?.text}
					</Typography>
					<img
						src={dropdown_arrow_black}
						style={{
							filter: "invert(35%) sepia(94%) saturate(4254%) hue-rotate(213deg) brightness(94%) contrast(88%)",
						}}
						className="ml-auto"
						alt=""
					/>
				</span>
			</Dropdown>
			<LegendDropdown legends={legends} />
		</div>
	);
};

export default Footer;

Footer.propTypes = {
	split_by: PropTypes.arrayOf(
		PropTypes.shape({
			text: PropTypes.string,
			apiValue: PropTypes.string,
			apiKey: PropTypes.string,
		})
	),
	legends: PropTypes.array,
};
