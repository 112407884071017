import React, { useEffect, useRef, useState } from "react";

import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import moment from "moment";
import {
	Button,
	Loader,
	PrimaryButtonVariant,
	RichTextEditor,
	TOAST_COLOR,
	theme,
	toast,
} from "@zluri/ui-components";
import { NoteHeader } from "./NoteHeader";
import { NoteFooter } from "./NoteFooter";

import { getValueFromLocalStorage } from "utils/localStorage";
import { addNewNote, deleteNote, updateNote } from "services/api/notes";
import { TriggerIssue } from "utils/sentry";
import {
	add_edit_ReminderDispatcher,
	deleteReminderDispatcher,
} from "modules/notes/redux/reminder";
import { ApplicationConstants } from "modules/applications/redux/Applications.constant";
import { setSelectedTab } from "modules/applications/redux/Applications.actions";
import { OVERVIEW_MODAL } from "modules/applications/constants/ApplicationConstants";
import { NOTES_DRAWER_TYPE } from "modules/applications/constants/OverviewV2.constants";
import { plainTextToLexicalState } from "modules/applications/utils/OverviewV2.utils";
import { TOAST_MESSAGE } from "modules/shared/constants/toast.constants";

export function SingleNote({
	appId,
	initialNote,
	initialIsEditing = false,
	onClose,
	noteDrawerState,
	setNoteDrawerState,
	noteVariant = "md",
	contentEditableClassName,
	setDisableDrawerClose,
	showDragIcon,
}) {
	const addingNoteDrawer =
		noteDrawerState?.type === NOTES_DRAWER_TYPE.ADDING_NOTE;
	const updatingNoteDrawer =
		noteDrawerState?.type === NOTES_DRAWER_TYPE.UPDATING_NOTE;

	const [note, setNote] = useState({
		...initialNote,
		color: addingNoteDrawer ? "#FFA99D" : initialNote?.color,
	});
	const [isEditing, setIsEditing] = useState(initialIsEditing);
	const editorRef = useRef();
	const noteHistoryRef = useRef(initialNote);

	const [creatingNewNote, setCreatingNewNote] = useState(false);
	const [updatingNote, setUpdatingNote] = useState(false);

	const [updatingReminder, setUpdatingReminder] = useState(false);
	const [removingReminder, setRemovingReminder] = useState(false);

	const disableClose = creatingNewNote || updatingNote;
	useEffect(() => {
		setDisableDrawerClose && setDisableDrawerClose(disableClose);
	}, [disableClose, setDisableDrawerClose]);

	const [tooManyRequests, setTooManyRequests] = useState(false);

	const reminderAt = note?.reminder?.reminder_date;
	const [reminderDateTime, setReminderDateTime] = useState(null);
	useEffect(() => {
		setReminderDateTime(
			reminderAt ? moment(reminderAt).format("D MMM YY, HH:mm") : null
		);
	}, [reminderAt]);

	const dispatch = useDispatch();

	const noteId = note?._id;
	const userInfo = getValueFromLocalStorage("userInfo");

	useEffect(() => {
		if (editorRef.current?.setEditable) {
			editorRef.current.setEditable(isEditing);
		}
	}, [isEditing]);

	useEffect(() => {
		setNote({
			...initialNote,
			color: addingNoteDrawer ? "#FFA99D" : initialNote?.color,
		});
	}, [initialNote, addingNoteDrawer]);

	const setTextToInitialState = async () => {
		if (editorRef.current) {
			const editorState = await plainTextToLexicalState(
				note?.text,
				editorRef.current
			);
			const parsedEditorState =
				editorRef.current.parseEditorState(editorState);
			editorRef.current.setEditorState(parsedEditorState);
		}
	};

	const initialEditorState = note?.editor_text
		?.replaceAll("(", "{")
		?.replaceAll(")", "}");
	useEffect(() => {
		if (editorRef.current && !addingNoteDrawer) {
			if (initialEditorState) {
				const editorState =
					editorRef.current.parseEditorState(initialEditorState);
				editorRef.current.setEditorState(editorState);
			} else {
				setTextToInitialState();
			}
		}
	}, [initialEditorState, addingNoteDrawer]);

	const handleNoteAdd = async () => {
		let noteObj = {
			...note,
			editor_text: JSON.stringify(editorRef.current?.getEditorState()),
			text: editorRef.current?.getRootElement()?.innerText,
			entity_type: "application",
			note_belongs_to: appId,
			created_by: userInfo.user_id,
		};

		try {
			setCreatingNewNote(true);
			const res = await addNewNote(noteObj);
			if (res.error) throw new Error(res);

			if (res?.note) {
				dispatch({
					type: ApplicationConstants.ADD_OVERVIEW_NOTE,
					payload: res.note,
				});
				toast(TOAST_MESSAGE.ADD_APPLICATION_NOTES_SUCCESS, {
					indicatorColor: TOAST_COLOR.SUCCESS,
				});
			}

			setNoteDrawerState({ type: NOTES_DRAWER_TYPE.LISTING_NOTE });
		} catch (err) {
			toast(TOAST_MESSAGE.ADD_APPLICATION_NOTES_ERROR, {
				indicatorColor: TOAST_COLOR.ERROR,
			});
			TriggerIssue(`Error adding new note to application:`, err);
		} finally {
			setCreatingNewNote(false);
		}
	};

	const handleNoteDelete = async () => {
		let noteObj = {
			entity_type: "application",
			note_belongs_to: appId,
		};

		try {
			const res = await deleteNote(noteId, noteObj);
			if (res.error) throw new Error(res);

			dispatch({
				type: ApplicationConstants.DELETE_OVERVIEW_NOTE,
				payload: noteId,
			});
			toast(TOAST_MESSAGE.DELETE_APPLICATION_NOTES_SUCCESS, {
				indicatorColor: TOAST_COLOR.SUCCESS,
			});
		} catch (err) {
			toast(TOAST_MESSAGE.DELETE_APPLICATION_NOTES_ERROR, {
				indicatorColor: TOAST_COLOR.ERROR,
			});
			TriggerIssue(`Error updating application note:`, err);
		}
	};

	const handleNoteUpdate = async () => {
		let noteObj = {
			color: note?.color,
			editor_text: JSON.stringify(editorRef.current?.getEditorState()),
			text: editorRef.current?.getRootElement()?.innerText,
			entity_type: "application",
			note_belongs_to: appId,
			last_edited_by: userInfo.user_id,
		};

		try {
			setUpdatingNote(true);
			const res = await updateNote(noteId, noteObj);
			if (res.error) throw new Error(res);

			if (res?.note) {
				dispatch({
					type: ApplicationConstants.UPDATE_OVERVIEW_NOTE,
					payload: res.note,
				});
				toast(TOAST_MESSAGE.EDIT_APPLICATION_NOTES_SUCCESS, {
					indicatorColor: TOAST_COLOR.SUCCESS,
				});
			}

			noteHistoryRef.current = note;
		} catch (err) {
			toast(TOAST_MESSAGE.EDIT_APPLICATION_NOTES_ERROR, {
				indicatorColor: TOAST_COLOR.ERROR,
			});
			setNote(noteHistoryRef.current);
			TriggerIssue(`Error updating application note:`, err);
		} finally {
			if (updatingNoteDrawer)
				setNoteDrawerState({ type: NOTES_DRAWER_TYPE.LISTING_NOTE });
			setIsEditing(false);
			setUpdatingNote(false);
		}
	};

	const handleNoteColorChange = (color) => {
		setNote((prevNote) => ({ ...prevNote, color }));
	};

	const handleCancelClick = () => {
		if (addingNoteDrawer || updatingNoteDrawer) onClose();
		else {
			setNote(noteHistoryRef.current);
			setIsEditing(false);
		}
	};

	const handleSaveNote = async () => {
		addingNoteDrawer ? await handleNoteAdd() : await handleNoteUpdate();
	};

	const handleAddEditReminder = (date, { onSuccess }) => {
		const payloadObj = {
			entity_type: "application",
			note_belongs_to: appId,
			reminder_date: date,
			created_by: userInfo.user_id,
		};
		const entity = {
			id: appId,
			type: "application",
		};

		setUpdatingReminder(true);
		dispatch(
			add_edit_ReminderDispatcher(note, payloadObj, entity, (res) => {
				if (!res.error && res.note) {
					if (res?.note) {
						dispatch({
							type: ApplicationConstants.UPDATE_OVERVIEW_NOTE,
							payload: res.note,
						});
					}
					onSuccess && onSuccess();
				} else {
					if (
						res.error ===
						"Too many requests, please try again later."
					) {
						setTooManyRequests(true);
					}
				}
				setUpdatingReminder(false);
			})
		);
	};

	const handleDeleteReminder = async ({ onSuccess }) => {
		const payloadObj = {
			entity_type: "application",
			note_belongs_to: appId,
		};
		const entity = {
			id: appId,
			type: "application",
		};

		setRemovingReminder(true);
		dispatch(
			deleteReminderDispatcher(note, payloadObj, entity, (res) => {
				if (res?.note) {
					dispatch({
						type: ApplicationConstants.UPDATE_OVERVIEW_NOTE,
						payload: res.note,
					});
					onSuccess && onSuccess();
				}
				setRemovingReminder(false);
			})
		);
	};

	const handleEditDropdownClick = () => {
		if (noteVariant === "sm") {
			setNoteDrawerState({
				type: NOTES_DRAWER_TYPE.UPDATING_NOTE,
				payload: note,
			});
			dispatch(setSelectedTab(OVERVIEW_MODAL.NOTES));
		} else {
			setIsEditing(true);
		}
	};

	return (
		<RichTextEditor
			editorRef={editorRef}
			contentEditableClassName={`${
				noteVariant === "md" &&
				!isEditing &&
				"z_overview_content_editable--md"
			} ${contentEditableClassName}`}
			initialLexicalConfig={{
				editorState: initialEditorState,
				editable: isEditing,
			}}
			maxCharacterLength={300}
			headerNode={
				<NoteHeader
					ribbonColor={note?.color}
					createdAt={
						addingNoteDrawer
							? moment().toString()
							: note?.created_at ?? note?.createdAt
					}
					createdBy={
						addingNoteDrawer
							? userInfo.user_name
							: note?.created_by?.user_name ??
							  note?.created_by?.name
					}
					createdByProfile={
						addingNoteDrawer
							? userInfo.user_profile_img
							: note?.created_by?.user_profile ??
							  note?.created_by?.profile_img
					}
					reminderDateTime={reminderDateTime}
					onEdit={handleEditDropdownClick}
					onDelete={handleNoteDelete}
					isEditing={isEditing}
					variant={noteVariant}
					showDragIcon={showDragIcon}
				/>
			}
			isEditing={isEditing}
			footerLeftNode={
				<NoteFooter
					noteColor={note?.color}
					setNoteColor={handleNoteColorChange}
					reminderDateTime={reminderDateTime}
					setReminderDateTime={setReminderDateTime}
					onAddEditReminder={handleAddEditReminder}
					onDeleteReminder={handleDeleteReminder}
					hideReminder={addingNoteDrawer}
					updatingReminder={updatingReminder}
					removingReminder={removingReminder}
					tooManyRequests={tooManyRequests}
					setTooManyRequests={setTooManyRequests}
				/>
			}
			footerRightNode={
				<div>
					<Button
						theme={theme}
						variant={PrimaryButtonVariant.primary_alternative}
						className="z_overview_footer_note_button mr-1"
						onClick={handleCancelClick}
						disabled={disableClose}
					>
						Cancel
					</Button>
					<Button
						theme={theme}
						variant={PrimaryButtonVariant.primary_default}
						className="z_overview_footer_note_button"
						onClick={handleSaveNote}
						disabled={disableClose}
					>
						{disableClose ? <Loader color="white" /> : "Save"}
					</Button>
				</div>
			}
			placeholder={isEditing ? "Start typing your note..." : ""}
		/>
	);
}

SingleNote.propTypes = {
	appId: PropTypes.string,
	initialNote: PropTypes.shape({
		color: PropTypes.string,
		created_at: PropTypes.string,
		text: PropTypes.string,
		reminder: PropTypes.shape({
			reminder_date: PropTypes.string,
		}),
		created_by: {
			user_name: PropTypes.string,
			user_profile: PropTypes.string,
		},
	}),
	initialIsEditing: PropTypes.bool,
	footerLeftNode: PropTypes.element,
	footerRightNode: PropTypes.element,
	noteDrawerState: PropTypes.shape({
		type: PropTypes.string,
		payload: PropTypes.object,
	}),
	onClose: PropTypes.func,
	setNoteDrawerState: PropTypes.func,
	noteVariant: PropTypes.string,
	contentEditableClassName: PropTypes.string,
	setDisableDrawerClose: PropTypes.func,
	showDragIcon: PropTypes.bool,
};
