import { setValueToLocalStorage } from "utils/localStorage";

export const ignoreURL = [
	"/checkauth",
	"/login",
	"/logout",
	"/loginFailed",
	"/checkAuth",
	"/page-not-found",
	"/loginflow",
	"/v2/checkauth",
	"/v2/login",
	"/v2/logout",
	"/v2/loginFailed",
	"/v2/checkAuth",
	"/v2/page-not-found",
	"/v2/loginflow",
];

export function setRedirectURL() {
	if (
		!ignoreURL.includes(
			window.location.href.replace(window.location.origin, "")
		)
	) {
		setValueToLocalStorage(
			"redirectURL",
			window.location.href.replace(window.location.origin, "")
		);
	}
}
