import React from "react";
import PropTypes from "prop-types";

function RearrangeHover(props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			fill="none"
			viewBox="0 0 16 16"
			{...props}
		>
			<path
				fill="currentColor"
				d="M15 2H5a1 1 0 00-1 1v.667a1 1 0 001 1h10a1 1 0 001-1V3a1 1 0 00-1-1zM13 6.67H3a1 1 0 00-1 1v.667a1 1 0 001 1h10a1 1 0 001-1V7.67a1 1 0 00-1-1zM11 11.33H1a1 1 0 00-1 1v.667a1 1 0 001 1h10a1 1 0 001-1v-.667a1 1 0 00-1-1z"
			></path>
		</svg>
	);
}

function Rearrange(props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			fill="none"
			viewBox="0 0 16 16"
			{...props}
		>
			<circle
				cx="6.335"
				cy="3.335"
				r="1.335"
				fill="currentColor"
			></circle>
			<circle
				cx="10.335"
				cy="3.335"
				r="1.335"
				fill="currentColor"
			></circle>
			<circle
				cx="6.335"
				cy="8.005"
				r="1.335"
				fill="currentColor"
			></circle>
			<circle
				cx="10.335"
				cy="8.005"
				r="1.335"
				fill="currentColor"
			></circle>
			<circle
				cx="6.335"
				cy="12.665"
				r="1.335"
				fill="currentColor"
			></circle>
			<circle
				cx="10.335"
				cy="12.665"
				r="1.335"
				fill="currentColor"
			></circle>
		</svg>
	);
}
function Info(props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			fill="none"
			viewBox="0 0 16 16"
			{...props}
		>
			<path
				fill="#717171"
				d="M9.375 4.762a1 1 0 11-2 0 1 1 0 012 0zm-1.5 6.726V8.502a.25.25 0 00-.25-.25h-.25a.75.75 0 010-1.5H8.62a.75.75 0 01.75.75v3.986a.75.75 0 01-1.5 0h.005z"
			></path>
			<rect
				width="15"
				height="15"
				x="0.5"
				y="0.5"
				stroke="#DDD"
				rx="7.5"
			></rect>
		</svg>
	);
}
const iconDatabase = {
	rearrange: Rearrange,
	"rearrange-hover": RearrangeHover,
	info: Info,
};

const Icon = ({ name, ...otherProps }) => {
	const Component = iconDatabase[name];
	return <Component name={name} {...otherProps} />;
};
Icon.propTypes = {
	name: PropTypes.oneOf(Object.keys(iconDatabase)).isRequired,
};
Icon.DisplayName = "Column Modifier Icon";
export default Icon;
