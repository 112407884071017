import React from "react";
import { IconMapper } from "../BulkEdit.constants";

import { Typography, BUTTON_VARIANT, GREY_VARIANT } from "@zluri/ui-components";

export default function ImageAndText({ option, onOptionClick }) {
	return option?.submenu?.map((submenu_data) => (
		<ImageAndTextRenderer
			menu={option}
			key={submenu_data?.label}
			submenu_data={submenu_data}
			onOptionClick={onOptionClick}
		/>
	));
}

const ImageAndTextRenderer = ({ menu, submenu_data, onOptionClick }) => {
	const submenuIcon = IconMapper[submenu_data?.icon];
	const optionText = submenu_data?.label;
	return (
		<div className="padding_2 cursor-pointer">
			<div
				className="bulk-edit-option"
				onClick={() => {
					onOptionClick(menu, submenu_data);
				}}
			>
				<Typography
					variant={BUTTON_VARIANT.BUTTON_EXTRA_SMALL_MEDIUM}
					color={GREY_VARIANT.SECONDARY_GREY_2}
					className="d-flex gap-6 padding_6"
				>
					{submenuIcon && <img src={submenuIcon} alt="" />}
					{optionText && (
						<span>
							{optionText}{" "}
							{submenu_data.showCount && (
								<>
									({submenu_data.count} of{" "}
									{submenu_data.totalCount})
								</>
							)}
						</span>
					)}
				</Typography>
			</div>
		</div>
	);
};
