import {
	BODY_VARIANT,
	Button,
	GREY_VARIANT,
	Typography,
	theme,
} from "@zluri/ui-components";
import { handleEllipseText } from "modules/applications/constants/AppLicensesConstants";
import { spendsCurrencyFormat } from "modules/applications/utils/Spends";
import moment from "moment";
import React, { useState } from "react";
import SearchSelect from "./SearchSelect/SearchSelect";

export default function LicensesPopoverV2(props) {
	const { data, component } = props;
	const licenses = data[component?.valueKey];
	const [showAddLicense, setShowAddLicense] = useState(false);

	return !showAddLicense ? (
		<div className="w-100">
			<div
				className="overflow-auto"
				style={{ height: licenses?.length <= 3 ? "100%" : "20vh" }}
			>
				{licenses?.map((lic, idx) => {
					return (
						<div
							key={idx}
							className="d-flex justify-content-between align-items-center w-100 gap-2"
							style={{ padding: "6px" }}
						>
							<div className="d-flex flex-column gap-2">
								<Typography
									variant={BODY_VARIANT.BODY_2_BOLD}
									color={GREY_VARIANT.SECONDARY_GREY_2}
								>
									{handleEllipseText(
										lic?.license_name ?? "-",
										lic?.license_name,
										"100px"
									)}
								</Typography>
								<Typography
									variant={BODY_VARIANT.BODY_3_MEDIUM}
									color={GREY_VARIANT.SECONDARY_GREY_2}
								>
									{handleEllipseText(
										lic.contract_name ?? "-",
										lic.contract_name,
										"100px"
									)}
								</Typography>
							</div>
							<div className="d-flex flex-column gap-1">
								<div>
									<Typography
										variant={BODY_VARIANT.BODY_2_MEDIUM}
										color={GREY_VARIANT.SECONDARY_GREY_3}
									>
										{spendsCurrencyFormat(14 ?? 0)}
									</Typography>
									<Typography
										variant={BODY_VARIANT.BODY_2_REGULAR}
										color={GREY_VARIANT.SECONDARY_GREY_3}
									>
										/month
									</Typography>
								</div>
								{lic?.license_assigned_on ? (
									<Typography
										variant={BODY_VARIANT.BODY_3_MEDIUM}
										color={GREY_VARIANT.SECONDARY_GREY_2}
									>
										Since{" "}
										{moment(
											lic?.license_assigned_on
										).format("DD MMM 'YY") ?? "-"}
									</Typography>
								) : (
									"-"
								)}
							</div>
						</div>
					);
				})}
			</div>
			<div className="d-flex gap-4 w-100" style={{ padding: "5px" }}>
				<Button
					variant="secondary-default"
					theme={theme}
					className="w-100"
					onClick={() => setShowAddLicense(true)}
				>
					Configure Licenses
				</Button>
			</div>
		</div>
	) : (
		<SearchSelect
			{...props}
			selectedIds={licenses?.map((license) => license?.license_id)}
		/>
	);
}
