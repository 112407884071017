import React from "react";
import "./DropDownButtonTab.scss";
import classNames from "classnames";

interface Prop extends React.HTMLProps<HTMLDivElement> {}
export default function DropDownButtonTab({
	children,
	className,
	...rest
}: Prop) {
	return (
		<div
			className={classNames("dropdown__button__tab", className)}
			{...rest}
		>
			{children}
		</div>
	);
}
