export const v2PaginatedConstants = {
	PAGINATED_ALL_DATA_REQUESTED: "PAGINATED_ALL_DATA_REQUESTED",
	PAGINATED_ALL_DATA_FETCHED: "PAGINATED_ALL_DATA_FETCHED",
	PAGINATED_CLEAR_ALL_DATA_CACHE: "PAGINATED_CLEAR_ALL_DATA_CACHE",
	PAGINATED_SET_PAGE: "PAGINATED_SET_PAGE",
	PAGINATED_SET_ROW: "PAGINATED_SET_ROW",
	PAGINATED_SET_SCROLLTOP: "PAGINATED_SET_SCROLLTOP",
	PAGINATED_SEARCH_DATA_REQUESTED: "PAGINATED_SEARCH_DATA_REQUESTED",
	PAGINATED_SEARCH_DATA_FETCHED: "PAGINATED_SEARCH_DATA_FETCHED",
	PAGINATED_PROPERTY_FILE_REQUESTED: "PAGINATED_PROPERTY_FILE_REQUESTED",
	PAGINATED_PROPERTY_FILE_FETCHED: "PAGINATED_PROPERTY_FILE_FETCHED",
	PAGINATED_SOURCE_LIST_FOR_FILTER_REQUESTED:
		"PAGINATED_SOURCE_LIST_FOR_FILTER_REQUESTED",
	PAGINATED_SOURCE_LIST_FOR_FILTER_FETCHED:
		"PAGINATED_SOURCE_LIST_FOR_FILTER_FETCHED",
	PAGINATED_UPDATE_V2_DATA: "PAGINATED_UPDATE_V2_DATA",
	PAGINATED_CURRENT_DATA_KEY: "PAGINATED_CURRENT_DATA_KEY",
};

export const ARCHIVE_FIELD_ID = {
	APPLICATION: "app_archive",
	USER: "user_archive",
	DEPARTMENT: "dept_archive",
};
