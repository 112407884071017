import {
	getSingleApplicationChargeback,
	getSingleApplicationAssociation,
	getSingleApplicationSourceSettings,
	getSingleApplicationDepartmentSplits,
	getSingleApplicationDetailsFromHotDB,
	getSingleApplicationSpendTriggerDetails,
	getSingleApplicationDepartmentWiseSpend,
} from "../service/Application.api";
import { TriggerIssue } from "utils/sentry";
import { singleApplicationReduxConstants } from "../constants/Application.contants";
import { getAppChargebackPieSeries } from "../utils/Application.utils";

export function checkAndFetchSingleApplicationBasicDetails({ appId }) {
	return async function (dispatch, getState) {
		if (getState().singleApplication?.[appId]?.basicDetails?.loaded) {
			return;
		} else {
			dispatch(fetchSingleApplicationBasicDetails({ appId }));
		}
	};
}

export function fetchSingleApplicationBasicDetails({ appId }) {
	return async function (dispatch) {
		try {
			dispatch({
				type: singleApplicationReduxConstants.APP_BASIC_DETAILS_REQUESTED,
				payload: { appId },
			});
			const basicDetails = await getSingleApplicationDetailsFromHotDB({
				appId,
			});
			dispatch({
				type: singleApplicationReduxConstants.APP_BASIC_DETAILS_FETCHED,
				payload: { appId, basicDetails },
			});
		} catch (error) {
			console.log("error", error);
			dispatch({
				type: singleApplicationReduxConstants.APP_BASIC_DETAILS_FETCHED,
				payload: {
					appId,
					error: error,
					basicDetails: {},
				},
			});
			TriggerIssue(
				`Error while fetching single application basic details`,
				error
			);
		}
	};
}

export function updateSingleApplicationCustomFieldData({
	appId,
	patchType,
	customFieldObj,
	customFieldData,
}) {
	return async function (dispatch) {
		const cfDataArr = [...customFieldData];
		const index = cfDataArr.findIndex(
			(cf) => cf.field_id === customFieldObj.field_id
		);
		if (index > -1) {
			if (patchType === "delete") {
				cfDataArr.splice(index, 1);
			} else {
				cfDataArr[index] = customFieldObj;
			}
		} else {
			cfDataArr.push(customFieldObj);
		}
		dispatch({
			type: singleApplicationReduxConstants.UPDATE_APP_CUSTOM_FIELD_DATA,
			payload: { appId, custom_fields: cfDataArr },
		});
	};
}

export function checkAndFetchSingleApplicationChargeback({
	appId,
	key,
	deptId,
	startMonth,
	endMonth,
	startYear,
	endYear,
}) {
	return async function (dispatch, getState) {
		if (getState().singleApplication?.[appId]?.chargeback?.[key]?.loaded) {
			return;
		} else {
			dispatch(
				fetchSingleApplicationChargeback({
					appId,
					key,
					deptId,
					startMonth,
					endMonth,
					startYear,
					endYear,
				})
			);
		}
	};
}

export function fetchSingleApplicationChargeback({
	appId,
	key,
	deptId,
	startMonth,
	endMonth,
	startYear,
	endYear,
}) {
	return async function (dispatch) {
		try {
			dispatch({
				type: singleApplicationReduxConstants.APP_CHARGEBACK_REQUESTED,
				payload: { appId, key },
			});
			const chargeback = await getSingleApplicationChargeback({
				appId,
				deptId,
				startMonth,
				endMonth,
				startYear,
				endYear,
			});
			const chargebackDepts = getAppChargebackPieSeries(
				chargeback?.departments
			);
			dispatch({
				type: singleApplicationReduxConstants.APP_CHARGEBACK_FETCHED,
				payload: { appId, key, deptId, chargebackDepts, chargeback },
			});
		} catch (error) {
			dispatch({
				type: singleApplicationReduxConstants.APP_CHARGEBACK_FETCHED,
				payload: {
					appId,
					key,
					error: error,
					chargeback: [],
				},
			});
			TriggerIssue(
				`Error while fetching single application chargeback`,
				error
			);
		}
	};
}

export function checkAndFetchSingleApplicationAssociation({ appId }) {
	return async function (dispatch, getState) {
		if (getState().singleApplication?.[appId]?.association?.loaded) {
			return;
		} else {
			dispatch(
				fetchSingleApplicationAssociation({
					appId,
				})
			);
		}
	};
}

export function fetchSingleApplicationAssociation({ appId }) {
	return async function (dispatch) {
		try {
			dispatch({
				type: singleApplicationReduxConstants.APP_ASSOCIATION_REQUESTED,
				payload: { appId },
			});
			const association = await getSingleApplicationAssociation({
				appId,
			});
			dispatch({
				type: singleApplicationReduxConstants.APP_ASSOCIATION_FETCHED,
				payload: { appId, association },
			});
		} catch (error) {
			dispatch({
				type: singleApplicationReduxConstants.APP_ASSOCIATION_FETCHED,
				payload: {
					appId,
					error: error,
					association: {},
				},
			});
			TriggerIssue(
				`Error while fetching single application association`,
				error
			);
		}
	};
}

export function checkAndFetchSingleApplicationSourceSettings({ appId }) {
	return async function (dispatch, getState) {
		if (getState().singleApplication?.[appId]?.sourceSettings?.loaded) {
			return;
		} else {
			dispatch(
				fetchSingleApplicationSourceSettings({
					appId,
				})
			);
		}
	};
}

export function fetchSingleApplicationSourceSettings({ appId }) {
	return async function (dispatch) {
		try {
			dispatch({
				type: singleApplicationReduxConstants.APP_SOURCE_SETTINGS_REQUESTED,
				payload: { appId },
			});
			const sourceSettings = await getSingleApplicationSourceSettings({
				appId,
			});
			dispatch({
				type: singleApplicationReduxConstants.APP_SOURCE_SETTINGS_FETCHED,
				payload: { appId, sourceSettings },
			});
		} catch (error) {
			dispatch({
				type: singleApplicationReduxConstants.APP_SOURCE_SETTINGS_FETCHED,
				payload: {
					appId,
					error: error,
					sourceSettings: {},
				},
			});
			TriggerIssue(
				`Error while fetching single application source settings`,
				error
			);
		}
	};
}

export function updateSingleApplicationBasicDetailKey({
	appId,
	updateDetails,
}) {
	return async function (dispatch) {
		dispatch({
			type: singleApplicationReduxConstants.UPDATE_APP_BASIC_DETAILS_KEY,
			payload: { appId, updateDetails },
		});
	};
}

export function checkAndFetchSingleApplicationDepartmentSplits({ appId }) {
	return async function (dispatch, getState) {
		if (getState().singleApplication?.[appId]?.deptSplit?.loaded) {
			return;
		} else {
			dispatch(fetchSingleApplicationDepartmentSplits({ appId }));
		}
	};
}

export function fetchSingleApplicationDepartmentSplits({ appId }) {
	return async function (dispatch) {
		try {
			dispatch({
				type: singleApplicationReduxConstants.APP_DEPARTMENT_SPLIT_REQUESTED,
				payload: { appId },
			});
			const deptSplit = await getSingleApplicationDepartmentSplits({
				appId,
			});
			dispatch({
				type: singleApplicationReduxConstants.APP_DEPARTMENT_SPLIT_FETCHED,
				payload: { appId, deptSplit },
			});
		} catch (error) {
			dispatch({
				type: singleApplicationReduxConstants.APP_DEPARTMENT_SPLIT_FETCHED,
				payload: {
					appId,
					error: error,
					deptSplit: [],
				},
			});
			TriggerIssue(
				`Error while fetching single application department split`,
				error
			);
		}
	};
}

export function checkAndFetchSingleApplicationSpendTriggerDetails({ appId }) {
	return async function (dispatch, getState) {
		if (
			getState().singleApplication?.[appId]?.spendTriggerDetails?.loaded
		) {
			return;
		} else {
			dispatch(fetchSingleApplicationSpendTriggerDetails({ appId }));
		}
	};
}

export function fetchSingleApplicationSpendTriggerDetails({ appId }) {
	return async function (dispatch) {
		try {
			dispatch({
				type: singleApplicationReduxConstants.APP_SPEND_TRIGGER_REQUESTED,
				payload: { appId },
			});
			const spendTriggerDetails =
				await getSingleApplicationSpendTriggerDetails({
					appId,
				});
			dispatch({
				type: singleApplicationReduxConstants.APP_SPEND_TRIGGER_FETCHED,
				payload: { appId, spendTriggerDetails },
			});
		} catch (error) {
			dispatch({
				type: singleApplicationReduxConstants.APP_SPEND_TRIGGER_FETCHED,
				payload: {
					appId,
					error: error,
					spendTriggerDetails: {},
				},
			});
			TriggerIssue(
				`Error while fetching single application spend trigger details`,
				error
			);
		}
	};
}

export function updateSingleApplicationSpendTriggerDetails({
	appId,
	updateDetails,
}) {
	return async function (dispatch) {
		dispatch({
			type: singleApplicationReduxConstants.UPDATE_APP_SPEND_TRIGGER_DETAILS,
			payload: { appId, updateDetails },
		});
	};
}

export function checkAndFetchSingleApplicationDepartmentWiseSpend({
	appId,
	key,
	deptId,
	startMonth,
	endMonth,
	startYear,
	endYear,
}) {
	return async function (dispatch, getState) {
		if (
			getState().singleApplication?.[appId]?.deptWiseSpend?.[key]?.loaded
		) {
			return;
		} else {
			dispatch(
				fetchSingleApplicationDepartmentWiseSpend({
					appId,
					key,
					deptId,
					startMonth,
					endMonth,
					startYear,
					endYear,
				})
			);
		}
	};
}

export function fetchSingleApplicationDepartmentWiseSpend({
	appId,
	key,
	deptId,
	startMonth,
	endMonth,
	startYear,
	endYear,
}) {
	return async function (dispatch) {
		try {
			dispatch({
				type: singleApplicationReduxConstants.APP_DEPARTMENT_WISE_SPEND_REQUESTED,
				payload: { appId, key },
			});
			const deptWiseSpend = await getSingleApplicationDepartmentWiseSpend(
				{
					appId,
					deptId,
					startMonth,
					endMonth,
					startYear,
					endYear,
				}
			);
			dispatch({
				type: singleApplicationReduxConstants.APP_DEPARTMENT_WISE_SPEND_FETCHED,
				payload: { appId, key, deptId, deptWiseSpend },
			});
		} catch (error) {
			dispatch({
				type: singleApplicationReduxConstants.APP_DEPARTMENT_WISE_SPEND_FETCHED,
				payload: {
					appId,
					key,
					error: error,
					deptWiseSpend: [],
				},
			});
			TriggerIssue(
				`Error while fetching single application department wise spend`,
				error
			);
		}
	};
}
