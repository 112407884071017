import React from "react";
import PropTypes from "prop-types";

import {
	ALERT_TAG_VARIANT,
	BODY_VARIANT,
	GREY_VARIANT,
	Tooltip,
	Typography,
	BADGE_VARIANTS,
	ImageOrNameBadge,
} from "@zluri/ui-components";
import moment from "moment";
import {
	ContractIcons,
	ContractTypes,
} from "modules/applications/constants/AppAbout.constants";
import Divider from "containers/v2table/Divider/Divider";
import GetImageOrNameBadge from "common/GetImageOrNameBadge";
import { LinkOrDiv } from "containers/v2table/cellRenderer/image";
import { capitalizeFirstLetter } from "utils/common";

const ContractInfoCard = ({ contract, format }) => {
	const contractType = [
		ContractTypes.contract,
		ContractTypes.perpetual,
	].includes(contract?.type)
		? "Ends on"
		: "Renews on";

	const contractDate =
		[ContractTypes.contract, ContractTypes.perpetual].includes(
			contract?.type
		) && contract?.end_date
			? moment(contract.end_date).format(format)
			: contract?.next_renewal_date
				? moment(contract.next_renewal_date).format(format)
				: "-";

	return (
		<div key={contract?._id} className="contracts-popover">
			<div
				style={{
					justifyContent: "space-between",
					backgroundColor: contract?.is_error ? "#F6F7FA" : "none",
					alignItems: "center",
					filter: contract?.is_error ? "grayscale(100%)" : "none",
					gap: "12px",
					paddingInline: "10px",
				}}
				className="flex py-2"
			>
				<div className="d-flex align-items-center cursor-pointer">
					<img
						src={ContractIcons[contract?.type ?? "contract"]}
						className="mr-1"
						alt={contract?.name}
					/>
					<Tooltip
						content={
							contract?.name && (
								<Typography
									color="white"
									variant={
										ALERT_TAG_VARIANT.ALERT_TAG_SMALL_MEDIUM
									}
								>
									{contract.name}
								</Typography>
							)
						}
						placement="bottom"
					>
						<Typography
							variant={BODY_VARIANT.BODY_2_BOLD}
							color={
								contract?.is_error
									? GREY_VARIANT.SECONDARY_GREY_3
									: GREY_VARIANT.SECONDARY_GREY_2
							}
							className="text-truncate-small display-block"
							style={{
								maxWidth: "200px",
							}}
						>
							{contract?.name ?? "-"}
						</Typography>
					</Tooltip>
				</div>
				<div
					style={{
						textAlign: "right",
						flexDirection: "column",
					}}
					className="flex"
				>
					<Typography
						color={GREY_VARIANT.SECONDARY_GREY_3}
						variant={ALERT_TAG_VARIANT.ALERT_TAG_EXTRA_SMALL_MEDIUM}
					>
						{contractType}
					</Typography>
					<Typography
						color={GREY_VARIANT.SECONDARY_GREY_3}
						variant="body_3_bold"
					>
						{contractDate}
					</Typography>
				</div>
			</div>
		</div>
	);
};
ContractInfoCard.propTypes = {
	contract: PropTypes.shape({
		_id: PropTypes.string,
		is_error: PropTypes.bool,
		name: PropTypes.string,
		type: PropTypes.string,
		end_date: PropTypes.string,
		next_renewal_date: PropTypes.string,
	}),
	format: PropTypes.string,
};

export function AppAboutContractsClickPopover({ field_id, data }) {
	const format = "D MMM 'YY";

	const contractsData = data[field_id];

	return (
		<div
			style={{
				maxHeight: "280px",
				overflowY: "scroll",
			}}
		>
			{contractsData?.map((contract, index) => {
				return (
					<div key={contract._id}>
						<ContractInfoCard
							contract={contract}
							data={data}
							format={format}
						/>
						{index !== contractsData?.length - 1 && (
							<Divider
								backgroundColor="#EBEBEB"
								borderRadius={8}
								height={1}
								className="mx-1"
							/>
						)}
					</div>
				);
			})}
		</div>
	);
}

export function ContractOwnersPopover({ field_id, data }) {
	const contractOwnerData = data[field_id];
	return (
		<div
			style={{
				maxHeight: "280px",
				overflowY: "scroll",
			}}
		>
			{contractOwnerData?.map((owner, index) => {
				return (
					<div key={owner._id}>
						<div
							style={{
								backgroundColor: "none",
							}}
							className="flex px-2 py-2 z-v2_table_cell_popover__content align-items-center justify-content-between"
						>
							<div
								style={{
									gap: 8,
								}}
								className="flex align-items-center"
							>
								<ImageOrNameBadge
									url={owner.logo}
									name={owner.name}
									className="z-transaction-source_icon"
									width={20}
									height={18}
									variant={BADGE_VARIANTS.ROUND}
								/>
								<div
									as={"div"}
									className="flex flex-column align-items-start cursor-pointer"
								>
									{owner.contract_name && (
										<Typography
											variant="body_3_regular"
											color="secondary_grey_4"
											style={{ marginBottom: 2 }}
										>
											{capitalizeFirstLetter(
												owner.contract_name
											)}
										</Typography>
									)}
									<Typography
										variant="body_2_bold"
										color="secondary_grey_2"
									>
										{owner.name}
									</Typography>
									{owner.email && (
										<Typography
											variant="body_3_medium"
											color="secondary_grey_3"
										>
											{owner.email}
										</Typography>
									)}
								</div>
							</div>
						</div>
						{index !== contractOwnerData?.length - 1 && (
							<Divider
								backgroundColor="#EBEBEB"
								borderRadius={8}
								height={1}
								className="mx-1"
							/>
						)}
					</div>
				);
			})}
		</div>
	);
}
AppAboutContractsClickPopover.propTypes = {
	field_id: PropTypes.string,
	data: PropTypes.object,
};
