/* eslint-disable react/prop-types */
import React from "react";
import ModalDropdown from "../components/Modal/ModalDropdown";
import ModalSuccessBanner from "../components/Modal/ModalSuccessBanner";
import ModalConditionsPreview from "../components/Modal/ModalConditionsPreview";
import ModalDualOptionSelection from "../components/Modal/ModalDualOptionSelection";
import ModalInfoBanner from "../components/Modal/ModalInfoBanner";
import { Divider } from "antd";
import ModalStatsBlock from "../components/Modal/ModalStatsBlock";

export const modalMapping = {
	DROPDOWN_BLOCK: ({
		title,
		text,
		dropdownLabel,
		dropdownOptions,
		fetchOptionsHandler,
		optionRender,
		onValueSelect,
		value,
		prefixIcon,
	}) => (
		<ModalDropdown
			title={title}
			text={text}
			dropdownLabel={dropdownLabel}
			dropdownOptions={dropdownOptions}
			fetchOptionsHandler={fetchOptionsHandler}
			optionRender={optionRender}
			onValueSelect={onValueSelect}
			value={value}
			prefixIcon={prefixIcon}
		/>
	),
	SUCCESS_BANNER_BLOCK: ({ textComponent }) => (
		<ModalSuccessBanner textComponent={textComponent} />
	),
	PREVIEW_CONDITION_BLOCK: ({ text, title, conditions }) => (
		<ModalConditionsPreview
			text={text}
			title={title}
			conditions={conditions}
			groupAfter={2}
		/>
	),
	INFO_BANNER_BLOCK: ({ text, moveToBottom }) => (
		<ModalInfoBanner text={text} moveToBottom={moveToBottom} />
	),
	DUAL_OPTION_SELECTION_BLOCK: ({
		options,
		title,
		onSelectHandler,
		value,
		text,
	}) => (
		<ModalDualOptionSelection
			options={options}
			title={title}
			onSelectHandler={onSelectHandler}
			selectedValue={value}
			text={text}
		/>
	),
	CUSTOM_BLOCK: ({ customBlock }) => customBlock,
	DIVIDER_BLOCK: () => (
		<Divider style={{ margin: "0px", background: "#EBEBEB" }} />
	),
	STATS_BLOCK: ({ stats }) => <ModalStatsBlock stats={stats} />,
};
