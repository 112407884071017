import React from "react";
import { CustomCellComponent } from "./componentFactory";
export default function Filter({ component }) {
	return (
		<div className="">
			<div>{component.label || "Test Label"}</div>
			<div>
				<CustomCellComponent
					type={component.filterComponent.type}
					component={component.filterComponent}
				/>
			</div>
		</div>
	);
}
