import { getAppChargebackPieSeries } from "../utils/Application.utils";
import { SingleApplicationBasicDetails } from "../model/Application.model";
import { singleApplicationReduxConstants } from "../constants/Application.contants";

const initialState = {};

export function SingleApplicationReducer(state = initialState, action) {
	var tempState = { ...state };
	var appId = action?.payload?.appId;

	switch (action.type) {
		case singleApplicationReduxConstants.APP_BASIC_DETAILS_REQUESTED:
			if (!tempState[appId]) {
				tempState[appId] = {};
			}
			tempState[appId].basicDetails = { loaded: false };
			return tempState;

		case singleApplicationReduxConstants.APP_BASIC_DETAILS_FETCHED:
			if (!tempState[appId]) {
				tempState[appId] = {};
			}
			tempState[appId].basicDetails = {
				loaded: true,
				basicDetails: new SingleApplicationBasicDetails(
					action.payload.basicDetails
				),
				error: action.payload.error,
			};
			return tempState;

		case singleApplicationReduxConstants.UPDATE_APP_CUSTOM_FIELD_DATA:
			if (!tempState[appId]) {
				tempState[appId] = {};
			}
			tempState[appId].basicDetails = {
				loaded: true,
				basicDetails: {
					...tempState[appId].basicDetails.basicDetails,
					custom_fields: action.payload.custom_fields,
				},
			};
			return tempState;

		case singleApplicationReduxConstants.APP_CHARGEBACK_REQUESTED:
			if (!tempState[appId]) {
				tempState[appId] = {};
			}
			if (!tempState[appId].chargeback) {
				tempState[appId].chargeback = {};
			}
			tempState[appId].chargeback[action.payload.key] = {
				loaded: false,
			};
			return tempState;

		case singleApplicationReduxConstants.APP_CHARGEBACK_FETCHED:
			if (!tempState[appId]) {
				tempState[appId] = {};
			}
			if (!tempState[appId].chargeback) {
				tempState[appId].chargeback = {};
			}
			tempState[appId].chargeback[action.payload.key] = {
				loaded: true,
				chargebackDepts: action.payload.chargebackDepts,
				error: action.payload.error,
			};
			tempState[appId].chargeback[action.payload.key].error =
				action.payload.error;
			return tempState;

		case singleApplicationReduxConstants.APP_ASSOCIATION_REQUESTED:
			if (!tempState[appId]) {
				tempState[appId] = {};
			}
			if (!tempState[appId].association) {
				tempState[appId].association = {};
			}
			tempState[appId].association = {
				loaded: false,
			};
			return tempState;

		case singleApplicationReduxConstants.APP_ASSOCIATION_FETCHED:
			if (!tempState[appId]) {
				tempState[appId] = {};
			}
			if (!tempState[appId].association) {
				tempState[appId].association = {};
			}
			tempState[appId].association = {
				loaded: true,
				association: action.payload.association,
				error: action.payload.error,
			};
			return tempState;

		case singleApplicationReduxConstants.APP_SOURCE_SETTINGS_REQUESTED:
			if (!tempState[appId]) {
				tempState[appId] = {};
			}
			if (!tempState[appId].sourceSettings) {
				tempState[appId].sourceSettings = {};
			}
			tempState[appId].sourceSettings = {
				loaded: false,
			};
			return tempState;

		case singleApplicationReduxConstants.APP_SOURCE_SETTINGS_FETCHED:
			if (!tempState[appId]) {
				tempState[appId] = {};
			}
			if (!tempState[appId].sourceSettings) {
				tempState[appId].sourceSettings = {};
			}
			tempState[appId].sourceSettings = {
				loaded: true,
				sourceSettings: action.payload.sourceSettings,
			};
			return tempState;

		case singleApplicationReduxConstants.UPDATE_APP_BASIC_DETAILS_KEY:
			tempState[appId].basicDetails = {
				loaded: true,
				basicDetails: {
					...tempState[appId].basicDetails?.basicDetails,
					...action.payload.updateDetails,
				},
			};
			return tempState;

		case singleApplicationReduxConstants.APP_DEPARTMENT_SPLIT_REQUESTED:
			if (!tempState[appId]) {
				tempState[appId] = {};
			}
			tempState[appId].deptSplit = { loaded: false };
			return tempState;

		case singleApplicationReduxConstants.APP_DEPARTMENT_SPLIT_FETCHED:
			if (!tempState[appId]) {
				tempState[appId] = {};
			}
			tempState[appId].deptSplit = {
				loaded: true,
				deptSplit: action.payload.deptSplit,
				error: action.payload.error,
			};
			return tempState;

		case singleApplicationReduxConstants.APP_SPEND_TRIGGER_REQUESTED:
			if (!tempState[appId]) {
				tempState[appId] = {};
			}
			tempState[appId].spendTriggerDetails = { loaded: false };
			return tempState;

		case singleApplicationReduxConstants.APP_SPEND_TRIGGER_FETCHED:
			if (!tempState[appId]) {
				tempState[appId] = {};
			}
			tempState[appId].spendTriggerDetails = {
				loaded: true,
				spendTriggerDetails: action.payload.spendTriggerDetails,
				error: action.payload.error,
			};
			return tempState;

		case singleApplicationReduxConstants.UPDATE_APP_SPEND_TRIGGER_DETAILS:
			tempState[appId].spendTriggerDetails = {
				loaded: true,
				spendTriggerDetails: {
					...tempState[appId].spendTriggerDetails
						?.spendTriggerDetails,
					...action.payload.updateDetails,
				},
			};
			return tempState;

		case singleApplicationReduxConstants.APP_DEPARTMENT_WISE_SPEND_REQUESTED:
			if (!tempState[appId]) {
				tempState[appId] = {};
			}
			if (!tempState[appId].deptWiseSpend) {
				tempState[appId].deptWiseSpend = {};
			}
			tempState[appId].deptWiseSpend[action.payload.key] = {
				loaded: false,
			};
			return tempState;

		case singleApplicationReduxConstants.APP_DEPARTMENT_WISE_SPEND_FETCHED:
			if (!tempState[appId]) {
				tempState[appId] = {};
			}
			if (!tempState[appId].deptWiseSpend) {
				tempState[appId].deptWiseSpend = {};
			}
			tempState[appId].deptWiseSpend[action.payload.key] = {
				loaded: true,
				actual_dept_spend: Array.isArray(
					action.payload.deptWiseSpend?.actual_dept_spend
				)
					? action.payload.deptWiseSpend.actual_dept_spend
					: [],
			};
			tempState[appId].deptWiseSpend[action.payload.key].error =
				action.payload.error;
			return tempState;

		default:
			return state;
	}
}
