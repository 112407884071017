import { kFormatter } from "constants/currency";
import React from "react";
import { Cost } from "../cost";

export function CustomSpendForecastPopup({ data, component }) {
	return (
		<>
			<div
				className="d-flex flex-column"
				style={{
					width: "220px",
					backgroundColor: "#F6F7FA",
					borderRadius: "8px",
				}}
			>
				<div
					style={{ padding: "8px 14px" }}
					className="d-flex flex-column"
				>
					<div
						className="grey font-10 bold-700"
						style={{ textAlign: "left" }}
					>
						Forecasted Spend
					</div>
					<div
						style={{ marginTop: "2px", gap: "3px" }}
						className="font-9 bold-500 d-flex align-items-center"
					>
						<Cost data={data} component={component}></Cost> for FY
						2023-24
					</div>
				</div>
				<div
					style={{
						padding: "8px 16px",
						backgroundColor: "#F6F7FA",
						textAlign: "left",
					}}
				>
					{data?.app_forecast_vs_budget === "Over Budget" ? (
						<>
							<div className="font-9 bold-500">
								Forecasted Spend is{" "}
								<span className="unauthorized_red">{`Over Budget by ${kFormatter(
									data?.app_forecast_vs_budget_difference
								)}`}</span>
							</div>
						</>
					) : (
						<>
							<div className="font-9 bold-500">
								Forecasted Spend{" "}
								<span className="authorized_green">
									Under Budget
								</span>
							</div>
						</>
					)}
				</div>
			</div>
		</>
	);
}
