import { client } from "../../utils/client";

export async function addNewNote(noteObj) {
	const response = await client.post(`${noteObj.entity_type}/notes`, noteObj);
	return response.data;
}

export async function updateNote(id, noteObj) {
	const response = await client.put(
		`${noteObj.entity_type}/notes/${id}`,
		noteObj
	);
	return response.data;
}

export async function deleteNote(id, noteObj) {
	const response = await client.post(
		`${noteObj.entity_type}/notes/${id}`,
		noteObj
	);
	return response.data;
}

export async function add_edit_Reminder(id, noteObj) {
	const response = await client.put(
		`${noteObj.entity_type}/notes/${id}/reminder`,
		noteObj
	);
	return response.data;
}

export async function delete_Reminder(id, noteObj) {
	const response = await client.put(
		`${noteObj.entity_type}/notes/${id}/deletereminder`,
		noteObj
	);
	return response.data;
}

export async function fetchNotes({ entity_type, entity_id }) {
	const response = await client.post(`${entity_type}/hot/notes`, {
		entity_type,
		entity_id,
	});
	return response.data;
}
