import React from "react";
import { CSVLink } from "react-csv";
import summaryCSV from "assets/optimization/summaryCSV.svg";
import Dropdown from "UIComponents/CustomDropdown/Dropdown";
import { sanitizeCSVCell, sanitizeCSVCellForExcel } from "utils/common";

export default function ContractBulkUploadErrorCSVDownload({
	rows,
	top = 35,
	right = 0,
	children,
}) {
	const getErroredCSVData = (rows, forExcel) => {
		const erroredCSVData = [];
		for (const row of rows) {
			let err = [];
			for (const key in row.Errors) {
				err.push(`${key}: ${row.Errors[key]}`);
			}
			const _row = { ...row };
			delete _row.Errors;
			erroredCSVData.push({
				Errors: err.join(", "),
				..._row,
			});
		}
		if (forExcel) {
			for (const row of erroredCSVData) {
				for (const key in row) {
					row[key] = sanitizeCSVCellForExcel(row[key]);
				}
			}
		} else {
			for (const row of erroredCSVData) {
				for (const key in row) {
					row[key] = sanitizeCSVCell(row[key]);
				}
			}
		}
		return erroredCSVData;
	};

	return (
		<Dropdown
			className="d-flex align-items-center"
			toggler={children}
			top={top}
			right={right}
			options={[
				<CSVLink
					style={{
						border: "none",
						textDecoration: "none",
						color: "#000000",
						width: "100%",
					}}
					data={getErroredCSVData(rows)}
					filename={`Bulk Upload Contracts File with Errors.csv`}
				>
					<div
						className="contract_csv_upload_error_download_button"
						style={{ gap: "4px" }}
					>
						<img src={summaryCSV} width={20} height={20} alt="" />
						<div className="d-flex align-items-center">CSV</div>
					</div>
				</CSVLink>,
				<CSVLink
					style={{
						border: "none",
						textDecoration: "none",
						color: "#000000",
						width: "100%",
					}}
					data={getErroredCSVData(rows, true)}
					filename={`Bulk Upload Contracts File with Errors.csv`}
				>
					<div
						className="contract_csv_upload_error_download_button"
						style={{ gap: "4px" }}
					>
						<img src={summaryCSV} width={20} height={20} alt="" />
						<div className="d-flex align-items-center">
							CSV for Excel
						</div>
					</div>
				</CSVLink>,
			]}
			optionFormatter={(option) => option}
			optionClassName="contract_csv_upload_error_dropdown_option"
		/>
	);
}
