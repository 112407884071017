import { useDispatch, useSelector } from "react-redux";
import { chain, filter, omit } from "underscore";
import icon_database from "assets/Icon_Database.svg";
import icon_puzzle from "assets/Icon_Puzzle.svg";
import icon_usertick from "assets/Icon_UserTick.svg";
import icon_date from "assets/Icon_Date.svg";
import icon_document from "assets/Icon_Document.svg";
import icon_money from "assets/Icon_Money.svg";
import icon_checkbadge from "assets/Icon_CheckBadge.svg";
import icon_alertuser from "assets/Icon_Alert_User.svg";
import icon_chair from "assets/Icon_Chair.svg";
import icon_alert from "assets/Icon_Alert.svg";
import icon_tag from "assets/Icon_Tag.svg";
import icon_number_id from "assets/icons/icon_numberid.svg";
import icon_user_email from "assets/icons/user-email.svg";
import icon_agent_type from "assets/icons/agent-type.svg";
import icon_app_type_device from "assets/icons/icon_App_Type_Device.svg";
import icon_type_device from "assets/icons/icon_Type_Device.svg";
import StringFilter from "containers/v2table/cellRenderer/StringFilter";
import NumberRangeFilter from "containers/v2table/cellRenderer/NumberRangeFilter";
import DateRangeFilter from "containers/v2table/cellRenderer/DateRangeFIlter";
import SingleSelectFilter from "containers/v2table/cellRenderer/SingleSelectFilter";
import MultiSelectFilter from "containers/v2table/cellRenderer/MultiSelectFilter";
import icon_rulename_filter from "modules/transactionMapping/assets/rulesPage/rule-name-filter.svg";
import icon_action_type_filter from "modules/transactionMapping/assets/rulesPage/action-type-filter.svg";
import icon_status_filter from "modules/transactionMapping/assets/rulesPage/status-filter.svg";

import React, { useEffect, useMemo, useState } from "react";

import { useTableContext } from "containers/v2table/TableContext/context";
import { Typography } from "@zluri/ui-components";
import MultiLevelSource from "../SourceTypeFilter/SourceTypeFilter";
import DynamicSearchSelect from "../cellRenderer/DynamicFilterRenderer";
import { isEqual, isEmpty } from "underscore";

export const FILTER_ICON_MAP = {
	icon_database,
	icon_puzzle,
	icon_usertick,
	icon_date,
	icon_document,
	icon_money,
	icon_checkbadge,
	icon_alertuser,
	icon_chair,
	icon_alert,
	icon_tag,
	icon_number_id,
	icon_user_email,
	icon_agent_type,
	icon_app_type_device,
	icon_type_device,
	icon_rulename_filter,
	icon_action_type_filter,
	icon_status_filter,
};

export const FILTER_TYPE_RENDERER = {
	string: {
		renderer: StringFilter,
	},
	range: {
		renderer: NumberRangeFilter,
	},
	date_range: {
		renderer: DateRangeFilter,
	},
	single_select: {
		renderer: SingleSelectFilter,
	},
	multi_select: {
		renderer: MultiSelectFilter,
	},
	dynamic_filter: {
		renderer: DynamicSearchSelect,
	},
	multi_source: {
		renderer: MultiLevelSource,
	},
};

// all these props will be returned from context API once it is implemented
export const useFilterOptions = ({ enabledFilters }) => {
	// All the filters coming from the BE.
	const { filterPropertiesObj, filterProperties } = useTableContext();

	const options = useMemo(() => {
		return enabledFilters
			? enabledFilters
					?.map(
						(f) =>
							filterPropertiesObj?.[
								f.replace(/\s+/g, " ").trim()?.toLowerCase()
							]
					)
					?.filter((filter) => !!filter?.field_type)
			: filterProperties?.filter((filter) => !!filter?.field_type);
	}, [enabledFilters, filterProperties, filterPropertiesObj]);

	return { options };
};

export const FilterComponent = ({ filter, filterData, onRemove, ...props }) => {
	const { apiProps, intID } = useTableContext();
	const fieldValues = filterData?.field_values;

	const values =
		Array.isArray(fieldValues) || fieldValues === undefined
			? fieldValues
			: [fieldValues];

	const Component = FILTER_TYPE_RENDERER[filter.field_type]?.renderer;

	if (!Component) return null;

	return (
		<div style={{ minWidth: 200 }}>
			<div className="z__filter_menu-option-title-container">
				<Typography
					variant="subheading_4_bold"
					className="flex-1"
					color="secondary_grey_2"
					style={{ textOverflow: "ellipsis" }}
				>
					{filter.field_name}
				</Typography>
				<button
					className="z__filter_menu-option-remove-pill d-flex align-items-center justify-content-center"
					onClick={onRemove}
					disabled={isEmpty(filterData?.field_values)}
				>
					<Typography variant="button_extrasmall_regular">
						Clear
					</Typography>
				</button>
			</div>
			<div className="px-2 pb-2">
				<Component
					{...props}
					filterData={filterData}
					filter={filter}
					value={values}
					intID={intID}
					apiProps={apiProps}
					onRemove={onRemove}
					className="z-v2-filter__option-popup-filter"
				/>
			</div>
		</div>
	);
};

export const useSelectedOptions = () => {
	const { filterBy: appliedFilters } = useTableContext();

	const { selectedOptions, selectedOptionsObj } = appliedFilters
		?.filter((f) => f?.field_category !== "quick_filter")
		?.reduce(
			(obj, curr) => {
				return {
					selectedOptions: [
						...obj.selectedOptions,
						{ value: curr.field_name },
					],
					selectedOptionsObj: {
						...obj.selectedOptionsObj,
						[curr.field_name]: true,
					},
				};
			},
			{ selectedOptions: [], selectedOptionsObj: {} }
		) ?? { selectedOptions: [], selectedOptionsObj: {} };

	return { selectedOptions, selectedOptionsObj };
};

export function useFilterAPI() {
	const { entity, subEntityData, apiProps, intID } = useTableContext();
	const dataEntity = subEntityData?.entity || entity;
	//default applied filters for the table coming from the api response
	const appliedFilters = useSelector(
		(state) => state.v2Table[dataEntity]?.filter_by
	);
	const dispatch = useDispatch();
	const clearFilter = () => {
		dispatch({
			type: "GET_TABLE_DATA",
			payload: {
				shouldRefresh: true,
				filterBy: [],
				entity,
				subEntityData,
				apiProps,
				intID,
			},
		});
	};

	const resetFilter = () => {
		dispatch({
			type: "GET_TABLE_DATA",
			payload: {
				shouldRefresh: true,
				resetFilter: true,
				entity,
				subEntityData,
				apiProps,
				intID,
			},
		});
	};
	const applyFilter = (data) => {
		const appliedQuickFilters = filter(
			appliedFilters,
			(filter) => filter.filter_category === "quick_filter"
		);
		const apiValues = chain(data)
			.values(data)
			.union((filters) => [...filters], [...appliedQuickFilters])
			.filter((f) => f.state !== "REMOVED")
			.map((filter) =>
				omit(filter, ["label", "value", "state", "isApplied"])
			)
			.value();

		dispatch({
			type: "GET_TABLE_DATA",
			payload: {
				shouldRefresh: true,
				filterBy: apiValues,
				entity,
				subEntityData,
				apiProps,
				intID,
			},
		});
	};
	return { clearFilter, applyFilter, resetFilter };
}

// TODO: For now even for each columns all the filter state is passed
// need to scope the state based on column ids..
export const useFilterState = ({ enabledFilters, open }) => {
	// This state represents the current state of the filter
	// It will sync with the actual applied filters from the API response and will keep track of
	// whatever internal changes on Filter is being made on UI
	// once filter is applied, this is the actual state being sent to the API
	const [filterState, setFilterState] = useState({});
	const [filterChanged, setFilterChanged] = useState(false);

	const [defaultAppliedFiltersObj, setDefaultAppliedFiltersObj] = useState(
		{}
	);

	const { filterBy: appliedFilters } = useTableContext();

	// syncing the frontend filter state with the actual applied filter
	// open is added as a dependency to remove the filter state on filter popover open/close.
	useEffect(() => {
		if (appliedFilters?.length) {
			const filters = chain(appliedFilters)
				.filter((filter) => filter?.filter_category !== "quick_filter")
				.map((f) => ({ ...f, isApplied: true }))
				.indexBy("field_name")
				.value();
			setDefaultAppliedFiltersObj(filters);
			setFilterState(filters);
		} else {
			setFilterState([]);
			setDefaultAppliedFiltersObj({});
		}
	}, [appliedFilters, open]);
	const onRemoveFilterByName = (id) => {
		const defaultState = defaultAppliedFiltersObj?.[id];
		if (!defaultState) {
			setFilterState((v) => omit(v, id));
		} else {
			setFilterState((v) => ({
				...v,
				[id]: {
					...(defaultState ?? {}),
					field_values: [],
					isApplied: v?.[id].isApplied,
					state: "REMOVED",
				},
			}));
		}
		// setFilterState((v) => omit(v, name));
		setFilterChanged(true);
	};

	const onFilterUpdate = (filter, value, apiValues) => {
		const id = filter.field_name;
		setFilterChanged(true);

		const defaultState = defaultAppliedFiltersObj?.[id];
		if (
			value[0] === "" ||
			Number.isNaN(value[0]) ||
			value[0] === null ||
			value[0] === undefined
		) {
			if (!defaultState) {
				setFilterState((v) => omit(v, id));
			} else {
				setFilterState((v) => ({
					...v,
					[id]: {
						...filter,
						field_values: [],
						isApplied: v?.[id].isApplied,
						state: "REMOVED",
					},
				}));
			}
			return;
		}

		let actualValueToBeSent = value;

		// TODO: validte
		if (filter.field_type === "range") {
			if (apiValues.field_order?.length > 1) {
				if (Number.isNaN(+value[0]) || Number.isNaN(+value[1])) return;

				actualValueToBeSent = [+value[0], +value[1]];
			} else {
				if (Number.isNaN(+value[0])) return;

				actualValueToBeSent = [+value[0]];
			}
		}

		// This is needed to show what filters has been applied.. Check this implementation in AppliedFilter.js (Filter Pill tooltip)
		// TODO: any other approach??
		let dynamicFilterData = [];

		if (filter.field_type === "dynamic_filter") {
			actualValueToBeSent = value?.map((v) => v.value);
			dynamicFilterData = value;
		}
		const field_values =
			filter.filter_type === "boolean"
				? actualValueToBeSent[0]
				: actualValueToBeSent;

		const hasChanged =
			apiValues?.negative !== defaultState?.negative ||
			!isEqual(apiValues?.field_order, defaultState?.field_order) ||
			(Array.isArray(field_values)
				? !isEqual(field_values, defaultState?.field_values)
				: field_values !== defaultState?.field_values);

		setFilterState((v) => ({
			...v,
			[id]: {
				...filter,
				field_values,
				dynamicFilterData,
				...apiValues,
				isApplied: v?.[id]?.isApplied,
				state: hasChanged ? "UPDATED" : null,
			},
		}));
	};

	const { applyFilter, clearFilter, resetFilter } = useFilterAPI();
	const onApplyFilter = () => {
		applyFilter(filterState);
		setFilterChanged(false);
	};

	const onResetFilter = () => {
		resetFilter();
	};

	const onClearAllFilter = () => {
		if (enabledFilters) {
			const _updatedFilterState = omit(filterState, enabledFilters);

			// TODO: test to check if there has been any changes from the appliedFilters
			applyFilter(_updatedFilterState);

			setFilterState(_updatedFilterState);
			setFilterChanged(false);
		} else {
			clearFilter();
			setFilterState({});
		}
	};

	return {
		filterState,
		setFilterState,
		onRemoveFilterByName,
		onFilterUpdate,
		onApplyFilter,
		onClearAllFilter,
		hasFilterChanged: filterChanged,
		onResetFilter,
	};
};
