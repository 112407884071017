import React, { useState } from "react";
import plus from "assets/icons/plus.svg";
import { Typography, DatePicker, Popover } from "@zluri/ui-components";
import CustomFieldTextEditPopover from "./CustomFieldTextEditPopover";
import CustomFieldSelectEditPopover from "./CustomFieldSelectEditPopover";
import EntitySelector from "./EntitySelector";
import PropTypes from "prop-types";
import { FieldTypePropTypes, ReferencePropTypes } from "./const";

function EmptyCustomField({ type, reference, onSubmit, options }) {
	const label = getEmptyLabel(type, reference);

	const [openPopup, setOpenPoup] = useState(false);

	if (type === "date") {
		// Please don't blame me for this implementation. I found no other way with this  DatePicker implementation, sadly
		return (
			<DatePicker
				className="z__custom_field_date_picker"
				calendarView="date"
				cellRenderer={null}
				customToggler={() => (
					<div className="flex align-items-center gap-4">
						<img
							className={` v2table__cell-empty-link-icon `}
							src={plus}
							width={16}
							alt={"Add"}
						/>

						<Typography
							variant="body_1_regular"
							color="secondary_grey_4"
							className="v2table__cell-empty-link truncate"
						>
							{label}gggg
						</Typography>
					</div>
				)}
				customWeekNames={{
					lang: "en",
					weekNames: ["S", "M", "T", "W", "T", "F", "S"],
				}}
				format="DD/MM/YYYY"
				onChange={(date) => {
					onSubmit(date?.toISOString());
				}}
				showFooter={false}
				setDisabledDate={null}
				style={{ width: "75%" }}
			/>
		);
	}

	return (
		<Popover
			show={openPopup}
			onToggle={setOpenPoup}
			content={
				<EmptyPopoverTypeMapper
					type={type}
					onClose={() => setOpenPoup(false)}
					reference={reference}
					onSubmit={onSubmit}
					options={options}
				/>
			}
		>
			<div className="flex align-items-center gap-4">
				<img
					className={` v2table__cell-empty-link-icon `}
					src={plus}
					width={16}
					alt={"Add"}
				/>

				<Typography
					variant="body_1_regular"
					color="secondary_grey_4"
					className="v2table__cell-empty-link truncate"
				>
					{label}
				</Typography>
			</div>
		</Popover>
	);
}
EmptyCustomField.propTypes = {
	type: FieldTypePropTypes,
	reference: ReferencePropTypes,
	onSubmit: PropTypes.func,
	options: PropTypes.arrayOf(PropTypes.string),
};
function getEmptyLabel(type, reference) {
	if (type === "reference") {
		const referenceMap = {
			orgusers: "Add User",
			orgapplications: "Add Applications",
		};
		return referenceMap[reference] ?? "Add Entity";
	}
	const labelMap = {
		text: "Add Text",
		bool: "Choose Custom Field",
		select: "Choose Custom Field",
		date: "Select Custom Field",
	};

	return labelMap[type] ?? "Add Entity";
}

function EmptyPopoverTypeMapper({
	type,
	reference,
	onClose,
	onSubmit,
	options,
}) {
	if (type === "text") {
		return (
			<CustomFieldTextEditPopover onClose={onClose} onSubmit={onSubmit} />
		);
	}
	if (type === "select" || type === "bool") {
		return (
			<CustomFieldSelectEditPopover
				onClose={onClose}
				onSubmit={onSubmit}
				options={options}
			/>
		);
	}

	if (type === "reference") {
		return (
			<div style={{ padding: 2 }}>
				<EntitySelector onSelect={onSubmit} reference={reference} />
			</div>
		);
	}
}
EmptyPopoverTypeMapper.propTypes = {
	type: FieldTypePropTypes,
	reference: ReferencePropTypes,
	onClose: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
	options: PropTypes.arrayOf(PropTypes.string),
};

export default EmptyCustomField;
