import React from "react";

import { EditLink } from "../components/OverviewV2/Header/EditLink";
import ContentLoader from "react-content-loader";
import { BUTTON_VARIANT, GREY_VARIANT, Typography } from "@zluri/ui-components";

import Icon_Licenses from "assets/icons/Icon_Licenses.svg";
import refresh from "assets/icons/refresh.svg";
import upload from "assets/upload.svg";
import delete_icon from "assets/icons/delete.svg";
import editIcon from "assets/edit-icon.svg";
import warning from "components/Onboarding/warning.svg";
import completed_outlined from "assets/icons/Icon_Completed.svg";
import chargeback_primary from "assets/icons/Icon_chargbacks_primary.svg";
import icon_budget from "assets/icons/Icon_Budget_Primary.svg";
import icon_optimization from "assets/icons/Icon_Optimization_Primary.svg";
import integration_connected from "assets/icons/Icon_Integrations_Connected.svg";

import {
	fetchAppUserLicensesGraph,
	fetchAppUserOptimizationsGraph,
	fetchAppUserStatusGraph,
	setSelectedTab,
} from "../redux/Applications.actions";
import { SPENDS_MODAL } from "./ApplicationConstants";
import {
	licensesByDepartmentChartConfig,
	optimizable_colors,
	user_active_colors,
} from "../utils/GraphUtils";
import { openModal } from "reducers/modal.reducer";

const SOURCE_TABS = [
	{
		key: "all",
		text: "All",
	},
	{
		key: "integrations",
		text: "Integrations",
	},
	{
		key: "agent",
		text: "Agent",
	},
	{
		key: "sso",
		text: "SSO",
	},
];

const LINK_ACTION = [
	{
		icon: editIcon,
		text: "Edit",
		className: "z_overview_header-action_option-link",
		content: (props) => <EditLink {...props} />,
	},
	{
		icon: delete_icon,
		text: "Delete",
		onClick: ({ handleDelete, link, id }) => handleDelete(link, id),
		closeOnDispatchOrClick: true,
		className: "z_overview_header-action_option-delete_link",
	},
];

const OVERVIEW_CHECKLIST_STATE = {
	"NOT COMPLETED": {
		key: "not_completed",
	},
	COMPLETED: {
		key: "completed",
		icon: completed_outlined,
	},
	ERROR: {
		key: "error",
		icon: warning,
	},
};

const CHECKLIST_CONFIG = {
	integrations: {
		text: "Integrations",
		children: [
			{
				id: "integrations",
				icon: integration_connected,
				title: "Connect Direct Integration",
				cta: {
					error: {
						redirect: () => window.open("/integrations"),
						text: "Resolve Now",
					},
					not_completed: {
						redirect: () => window.open("/integrations"),
						text: "Connect Now",
					},
					completed: {
						text: "Done",
					},
				},
				info: "",
				error_message: "",
			},
		],
	},
	licenses: {
		text: "Licenses",
		children: [
			{
				id: "licenses",
				icon: chargeback_primary,
				title: "Add Contracts for licenses",
				cta: {
					error: {
						redirect: (appId) => window.open(`/contract/new`),
						text: "Add Contract",
					},
					not_completed: {
						redirect: (appId) => window.open(`/contract/new`),
						text: "Add Contract",
					},
					completed: {
						text: "Done",
					},
				},
				info: "",
				error_message: "",
				appId: "",
			},
		],
	},
	spends: {
		text: "Spends",
		children: [
			{
				id: "spend_transactions",
				icon: chargeback_primary,
				title: "Add/Check Spends",
				cta: {
					error: {
						hash: "#spends",
						text: "Review Now",
					},
					not_completed: {
						hash: "#spends",
						dispatch: setSelectedTab(
							SPENDS_MODAL.ADD_SINGLE_TRANSACTION
						),
						text: "Add Transactions",
					},
					completed: {
						text: "Done",
					},
				},
				info: "",
				error_message: "",
			},
			{
				id: "charegback_setting",
				icon: chargeback_primary,
				title: "Set App Chargeback setting",
				cta: {
					error: {
						hash: "#chargebacks",
						dispatch: openModal("chargebackSettingsV2"),
						text: "Review Now",
					},
					not_completed: {
						hash: "#chargebacks",
						dispatch: openModal("chargebackSettingsV2"),
						text: "Review Now",
					},
					completed: {
						text: "Done",
					},
				},
				info: "",
				error_message: "",
			},
			{
				id: "application_budget",
				icon: icon_budget,
				title: "Set Application Budget",
				cta: {
					error: {
						hash: "#spends",
						dispatch: setSelectedTab(SPENDS_MODAL.SPENDS_SETTING),
						text: "Add Now",
					},
					not_completed: {
						hash: "#spends",
						dispatch: setSelectedTab(SPENDS_MODAL.SPENDS_SETTING),
						text: "Add Now",
					},
					completed: {
						text: "Done",
					},
				},
				info: "",
				error_message: "",
			},
			{
				id: "continuous_optimization",
				icon: icon_optimization,
				title: "Set Continuous Optimization",
				cta: {
					error: {
						hash: "#optimization",
						dispatchWithProps: ({ app, appId }) =>
							openModal("optimizationSettings", { app, appId }),
						text: "Set Now",
					},
					not_completed: {
						hash: "#optimization",
						dispatchWithProps: ({ app, appId }) =>
							openModal("optimizationSettings", { app, appId }),
						text: "Set Now",
					},
					completed: {
						text: "Done",
					},
				},
				info: "",
				error_message: "",
			},
		],
	},
};

const SOURCE_CARD_LOADER = (
	<ContentLoader
		height="56px"
		width="202px"
		backgroundColor="#EBEBEB"
		foregroundColor="#f3f3f3"
	>
		<rect width="100%" height="100%" rx="8" />
	</ContentLoader>
);

const VIEW_BY = [
	{
		icon: Icon_Licenses,
		text: "Employees",
		apiValue: "employee",
		apiKey: "user_type",
		user_count_key: "total_employees",
	},
	{
		icon: Icon_Licenses,
		text: "Users",
		apiValue: "user",
		apiKey: "user_type",
		user_count_key: "total_users",
	},
	{
		icon: Icon_Licenses,
		text: "Service",
		apiValue: "service",
		apiKey: "user_type",
		user_count_key: "total_service_users",
	},
	{
		icon: Icon_Licenses,
		text: "Groups",
		apiValue: "group",
		apiKey: "user_type",
		user_count_key: "total_groups",
	},
	{
		icon: Icon_Licenses,
		text: "External Users",
		apiValue: "external",
		apiKey: "user_type",
		user_count_key: "total_external_users",
	},
];

const SPLIT_BY = [
	{
		text: "By Department",
		apiValue: "departments",
		apiKey: "split_by",
	},
	{
		text: "By Cost Centers",
		apiValue: "cost centers",
		apiKey: "split_by",
	},
	{
		text: "By Business Units",
		apiValue: "business units",
		apiKey: "split_by",
	},
	{
		text: "By Locations",
		apiValue: "locations",
		apiKey: "split_by",
	},
];

const TAB_CONFIG = [
	{
		title: "Active Users",
		key: "user_app_status",
		dispatcher: fetchAppUserStatusGraph,
		chartFormatter: licensesByDepartmentChartConfig,
		colors: user_active_colors,
	},
	{
		title: "Assigned Licenses",
		key: "user_app_license",
		dispatcher: fetchAppUserLicensesGraph,
		chartFormatter: licensesByDepartmentChartConfig,
	},
	{
		title: "Optimizable Users",
		key: "user_app_optimization",
		dispatcher: fetchAppUserOptimizationsGraph,
		chartFormatter: licensesByDepartmentChartConfig,
		colors: optimizable_colors,
	},
];

const SHOW_MORE_OPTIONS = [
	{
		id: "REFRESH_CHART",
		icon: <img src={refresh} width={16} height={16} alt="" />,
		label: (
			<div className="d-flex align-items-center gap-6">
				<Typography
					variant={BUTTON_VARIANT.BUTTON_EXTRA_SMALL_MEDIUM}
					color={GREY_VARIANT.SECONDARY_GREY_2}
				>
					Refresh
				</Typography>
			</div>
		),
		filter: "",
		text: "Refresh",
	},
	{
		id: "DOWNLOAD_AS_PDF",
		icon: <img src={upload} width={16} height={16} alt="" />,
		text: "Download As ",
		label: (
			<div className="d-flex align-items-center gap-6">
				<Typography
					variant={BUTTON_VARIANT.BUTTON_EXTRA_SMALL_MEDIUM}
					color={GREY_VARIANT.SECONDARY_GREY_2}
				>
					Download as PDF
				</Typography>
			</div>
		),
	},
	{
		id: "DOWNLOAD_AS_CSV",
		icon: <img src={upload} width={16} height={16} alt="" />,
		text: "Download CSV",
		label: (
			<div className="d-flex align-items-center gap-6">
				<Typography
					variant={BUTTON_VARIANT.BUTTON_EXTRA_SMALL_MEDIUM}
					color={GREY_VARIANT.SECONDARY_GREY_2}
				>
					Download as CSV
				</Typography>
			</div>
		),
	},
];

const NOTES_DRAWER_TYPE = {
	ADDING_NOTE: "ADDING_NOTE",
	LISTING_NOTE: "LISTING_NOTE",
	UPDATING_NOTE: "UPDATING_NOTE",
};

export const NOTE_COLORS = [
	{
		HEX: "#FFE9E5",
		HOVER_HEX: "#FFA99D",
	},
	{
		HEX: "#E7F8E8",
		HOVER_HEX: "#A3E4A6",
	},
	{
		HEX: "#E1E8F8",
		HOVER_HEX: "#B1C8F3",
	},
	{
		HEX: "#FFF6DC",
		HOVER_HEX: "#FFDC7A",
	},
	{
		HEX: "#F0DCFF",
		HOVER_HEX: "#C87AFF",
	},
	{
		HEX: "#EBEBEB",
		HOVER_HEX: "#DDDDDD",
	},
];

const STRNG_REGEX_MAP = {
	EMAIL: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
};

export {
	SOURCE_TABS,
	LINK_ACTION,
	OVERVIEW_CHECKLIST_STATE,
	CHECKLIST_CONFIG,
	VIEW_BY,
	SPLIT_BY,
	TAB_CONFIG,
	SHOW_MORE_OPTIONS,
	SOURCE_CARD_LOADER,
	NOTES_DRAWER_TYPE,
	STRNG_REGEX_MAP,
};
