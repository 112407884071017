import {
	SAVE_USER_INFO_OBJECT,
	UPDATE_USER_INFO_OBJECT,
	CLEAR_USER_INFO_OBJECT,
} from "../constants/user";

export function userInfoReducer(state = {}, action) {
	switch (action.type) {
		case CLEAR_USER_INFO_OBJECT:
			return {};
		case SAVE_USER_INFO_OBJECT:
			return { ...state, ...action.payload };
		case UPDATE_USER_INFO_OBJECT:
			return { ...state, ...action.payload };
		default:
			return state;
	}
}

export function employeeDashoboardFeatureSelector(state, feature) {
	return (
		Array.isArray(state?.userInfo?.org_beta_features) &&
		state?.userInfo?.org_beta_features.some((item) => item === feature)
	);
}
