import React, { useState } from "react";

import PropTypes from "prop-types";

import {
	BODY_VARIANT,
	GREY_VARIANT,
	Loader,
	Popover,
	Typography,
} from "@zluri/ui-components";
import CopyToClipboard from "react-copy-to-clipboard";
import { EditLinkPopover } from "./EditLink";

import DotsImage from "assets/three-dots-black.svg";
import copyIcon from "assets/icons/Icon_Copy_to_Clipboard.svg";

export const LinkWithCopyToClipboard = ({
	link,
	id,
	appId,
	linkActions = true,
	loading,
	handleEdit,
	handleAdd,
	handleDelete,
}) => {
	const [show, setShow] = useState(false);

	return (
		<div
			className={`d-flex align-items-center gap-10 z_overview_header-action_option w-100 ${
				loading && "opacity-50"
			}`}
		>
			<Typography
				variant={BODY_VARIANT.BODY_2_REGULAR}
				color={GREY_VARIANT.SECONDARY_GREY_2}
				className="text-truncate-medium flex-1 mr-auto"
			>
				{link}
			</Typography>

			{linkActions && !loading && (
				<>
					<CopyToClipboard options={{}} text={link}>
						<span className="d-flex align-items-center z_icon_hover">
							<img
								src={copyIcon}
								width={12}
								height={12}
								alt=""
								className="cursor-pointer"
							/>
						</span>
					</CopyToClipboard>
					<Popover
						show={show}
						onToggle={(open) => setShow(open)}
						content={
							<EditLinkPopover
								onClose={() => setShow(false)}
								link={link}
								id={id}
								appId={appId}
								handleAdd={handleAdd}
								handleEdit={handleEdit}
								handleDelete={handleDelete}
							/>
						}
						placement="right"
					>
						<span className="d-flex align-items-center z_icon_hover">
							<img
								src={DotsImage}
								width={12}
								height={12}
								alt=""
								className="cursor-pointer"
							/>
						</span>
					</Popover>
				</>
			)}
			{loading && <Loader color="blue" height={18} />}
		</div>
	);
};

LinkWithCopyToClipboard.propTypes = {
	link: PropTypes.string,
	linkActions: PropTypes.bool,
	id: PropTypes.string,
	appId: PropTypes.string,
	loading: PropTypes.bool,
	handleAdd: PropTypes.func,
	handleEdit: PropTypes.func,
	handleDelete: PropTypes.func,
};
