import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import "@inovua/reactdatagrid-enterprise/index.css";
import { Checkbox } from "@zluri/ui-components";
import "./Table.css";
import { getAppKey } from "../../utils/getAppKey";
import { DotLottiePlayer } from "@dotlottie/react-player";
import lottieFile from "./loadingInLoop.json";
import customLoadable from "utils/customLoadable";
import { RenderFirstLoad } from "./v2TableLoader";
import {
	DensityEnum,
	useTableContext,
} from "containers/v2table/TableContext/context";
import { IColumn } from "containers/v2table/types";
import { TypeDataGridProps } from "@inovua/reactdatagrid-enterprise/types";
const DataGrid = customLoadable(
	() =>
		import(/* webpackPrefetch: true */ "@inovua/reactdatagrid-enterprise"),
	<RenderFirstLoad />
);

const licenseKey = getAppKey("REACT_APP_DATAGRID_LICENSE");

interface ITable extends Partial<TypeDataGridProps> {
	dataSource: any[];
	handleOnScroll: () => void;
	columns: IColumn[];
	hideCheckBox: boolean;
	renderCustomCheckbox?: (arg: {
		handleChange: (arg: any) => void;
		checked: boolean;
		disabled: boolean;
		cellProps: any;
	}) => React.ReactNode;
	isCheckBoxDisabled: (rowIndex: number) => boolean;
	gridRef: any;
}
export function Table({
	dataSource,
	handleOnScroll,
	columns,
	sortInfo,
	loading: loadingFromProps,
	hideCheckBox = false,
	checkboxColumn,
	renderCustomCheckbox,
	style = { minHeight: 200 },
	scrollThreshold = 0.7,
	showEmptyRows = false,
	showColumnMenuTool = false,
	pagination = false,
	reorderColumns = false,
	livePagination = false,
	isCheckBoxDisabled,
	gridRef,
	onReady,
	rowHeight,
	idProperty,
	checkboxOnlyRowSelect = true,
	...rest
}: ITable) {
	const [loading, onLoadingChange] = useLoadingState(loadingFromProps);
	const { onSelectionChange, density } = useTableContext();

	const checkboxColumnRenderer = (dataSource) => {
		return {
			defaultLocked: true,
			renderCheckbox: (checkboxProps, cellProps) => {
				const { onChange, checked } = checkboxProps;

				const disabled = isCheckBoxDisabled(cellProps.rowIndex);

				const customCheckbox =
					renderCustomCheckbox &&
					renderCustomCheckbox({
						handleChange: (props) => onChange(props ?? !checked),
						checked,
						disabled,
						cellProps,
					});

				// if renderCustomCheckbox returns null or falsy value then return the default
				if (customCheckbox) return customCheckbox;
				return (
					<div>
						<Checkbox
							checked={checked}
							disabled={disabled}
							onChange={(e) => {
								e.stopPropagation();
								onChange(!checked);
							}}
							indeterminate={
								!(checked === true || checked === false)
							}
						/>
					</div>
				);
			},
		};
	};
	const positionRef = useRef(null);
	const checkhorizontalScroll = (e) => {
		const x = e?.current.scrollLeft;
		if (x !== positionRef.current) {
			positionRef.current = x;
			return true;
		}
	};

	function onScroll(a, b) {
		if (checkhorizontalScroll(gridRef)) {
			return;
		}
		handleOnScroll();
	}

	useLayoutEffect(() => {
		if (gridRef && gridRef.current && !loading) {
			const totalComputedWidth = gridRef.current?.totalComputedWidth;
			const availableWidth = gridRef.current?.availableWidth;
			if (
				gridRef.current.setColumnSizesToFit &&
				totalComputedWidth < availableWidth
			) {
				gridRef.current.setColumnSizesToFit();
			}
		}
	}, [gridRef, loading]);

	return (
		<DataGrid
			licenseKey={licenseKey}
			dataSource={dataSource}
			onSelectionChange={onSelectionChange}
			onLoadingChange={onLoadingChange}
			activeIndex={-1}
			reorderColumns={reorderColumns}
			key={density} // This is a hacky change for now, because as row changes DataGrid isn't being able to paint the change easily
			onScroll={onScroll}
			renderLoadMask={renderLoadMask}
			columns={columns}
			sortInfo={sortInfo}
			loading={loading}
			onReady={onReady}
			// This is to ensure that all the rows are selected if select all is clicked on infinite scroll
			checkboxColumn={
				!hideCheckBox && columns?.length > 0
					? checkboxColumnRenderer(dataSource)
					: false
			}
			enableColumnAutosize={true}
			style={style}
			rowClassName={"z-table "}
			scrollThreshold={scrollThreshold}
			theme="default-light"
			showEmptyRows={showEmptyRows}
			showColumnMenuTool={showColumnMenuTool}
			pagination={pagination}
			livePagination={livePagination}
			rowHeight={rowHeight ?? (density === DensityEnum.COMPACT ? 40 : 60)}
			idProperty={idProperty ?? "_id"}
			checkboxOnlyRowSelect={checkboxOnlyRowSelect}
			{...rest}
		/>
	);
}

// version at the time of writing: "@inovua/reactdatagrid-enterprise": "^5.10.2"
// When loading is being controlled from redux and dataSource provided to the table is not a promise but rather an array
// `Empty Text` will be rendered for a brief period of time even though the loading state and the dataSource are in sync
// So had to create an internal state which syncs up with the loading state from props (redux) whenever loading state from props is `true`
// however the power to change the loading state from `true` (set by the props) to `false` only lies to the `table` by using `onLoadingChangeFromTable` function
function useLoadingState(
	loadingFromProps?: boolean
): [boolean, (bool: boolean) => void] {
	const [loading, setLoading] = useState(loadingFromProps ?? false);
	useEffect(() => {
		if (loadingFromProps) setLoading(loadingFromProps);
	}, [loadingFromProps]);

	const onLoadingChangeFromTable = (_loading: boolean) => {
		if (loadingFromProps) {
			setLoading(true);
		} else {
			setLoading(_loading);
		}
	};

	return [loading, onLoadingChangeFromTable];
}

//TODO: create a separate component??
const renderLoadMask = ({ visible, livePagination, loadingText, zIndex }) => {
	if (visible)
		return (
			<div
				style={{
					top: 0,
					left: 0,
					right: 0,
					bottom: 0,
					zIndex,
					// background: "rgba(121, 134, 203, 0.25)",
					// color: "#ef9a9a",
					opacity: 0.6,
					display: "flex",
					position: "absolute",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<div style={{ width: "100px" }}>
					<DotLottiePlayer
						src={lottieFile}
						autoplay
						loop
					></DotLottiePlayer>
				</div>
			</div>
		);
	return null;
};
