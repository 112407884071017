import React from "react";
import { BUTTON_VARIANT, Typography } from "@zluri/ui-components";

import redexclam from "assets/employee/redexclam.svg";

export const accessReviewFormatter = (filter) => {
	return (
		<div className="d-flex align-items-center">
			<img src={redexclam} alt=""></img>
			<Typography
				className="ml-1"
				variant={BUTTON_VARIANT.BUTTON_EXTRA_SMALL_SEMIBOLD}
				color="#FE6955"
			>
				{`Update ${filter?.user_count} ${
					filter?.user_count > 1 ? "Reviewers" : "Reviewer"
				}`}
			</Typography>
		</div>
	);
};
