import React, { useEffect, useRef, useState } from "react";
import { Beta } from "modules/shared/components/BetaTagAndModal/Beta/beta";
import { Modal } from "react-bootstrap";
import close from "assets/close.svg";
import { Button } from "../../../../UIComponents/Button/Button";
import connectsvg from "assets/integrations-beta-connect.svg";

export default function IntegrationBetaPopup({
	setShowBetaPopup,
	integration,
	onOk,
	showBetaPopup,
}) {
	return (
		<>
			<Modal
				centered
				show={showBetaPopup}
				onHide={() => setShowBetaPopup(false)}
				dialogClassName="modal-561w-width"
			>
				<Modal.Body
					className="d-flex  flex-column w-100"
					style={{
						position: "relative",

						justifyContent: "center !important",
						alignItems: "center",
					}}
				>
					<img
						style={{
							position: "absolute",
							top: "-25px",
							right: "34px",
							cursor: "pointer",
						}}
						alt="Close"
						onClick={() => {
							setShowBetaPopup(false);
						}}
						src={close}
					/>
					<img src={connectsvg} height={57} width={57}></img>
					<div
						className=""
						style={{
							fontWeight: "600",
							fontSize: "22px",
							marginTop: "20px",
						}}
					>
						{integration.name}
					</div>
					<Beta></Beta>
					<div
						className="font-16"
						style={{
							margin: "22px 47px ",
							textAlign: "center",
						}}
					>
						This integration is in beta, which marks newer
						integrations that are tested from our side but aren't
						battle tested by many customers. Due to this, more edge
						cases can appear, so please let us know if you hit any!
						Once enough customers use the integration over time, the
						beta flag is removed.
					</div>
					<Button
						onClick={() => {
							setShowBetaPopup(false);
							onOk && onOk();
						}}
						style={{
							height: "48px",
							width: "227px",
						}}
					>
						Agree And Continue
					</Button>
				</Modal.Body>
			</Modal>
		</>
	);
}
