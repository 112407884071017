import React from "react";
import PropTypes from "prop-types";
import NoResultsFoundSVG from "../assets/v2tables/no-results.svg";
import workflowsImage from "assets/no_workflows_found.svg";
import licensesImage from "assets/no_licenses_found.svg";
import transactionsImage from "assets/no_transactions_found.svg";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { capitalizeFirstLetter } from "utils/common";
import { stateFilterMap } from "modules/v2InfiniteTable/InfiniteTableContainer";
import { getSearchReqObj } from "./infiniteTableUtil";
import qs from "query-string";

export const TabNameMap = {
	all: "Applications",
	needs_review: "Needs Review Apps",
	managed: "Managed Apps",
	unmanaged: "Unmanaged Apps",
	restricted: "Restricted Apps",
	employees: "Employees",
	group: "Groups",
	marked_for_onboarding: "Marked for Onboarding",
	marked_for_offboarding: "Marked for Offboarding",
	external: "External",
	service: "Service",
	allLicenses: "Licenses",
	allContracts: "Contracts",
	allSubscriptions: "Subscriptions",
	allPerpetualContracts: "Perpetuals",
	recognised: "Recognised Transactions",
	unrecognised: "Unrecognised Transactions",
	archived: "Archived Transactions",
	uploads: "Uploaded Transactions",
	drafts: "Drafts",
	playbooks: "Playbooks",
	completed: "Completed Tasks",
	alltasks: "Tasks",
	pending: "Pending Tasks",
	cancelled: "Cancelled Tasks",
	desktop_agents: "Desktop Agents",
	chargebacks: "Department",
	spend_overview: "Spend Overview",
	spends: "Application Spends",
	optimization: "Previous Optimization Runs",
	review_users: "Users",
	previous_optimization: "Previous Optimization Users",
	users: "Users",
};

const routeMap = {
	"needs review": "needs_review",
	managed: "managed",
	unmanaged: "unmanaged",
	restricted: "restricted",
	employee: "employees",
	group: "group",
	onboarding: "marked_for_onboarding",
	offboarding: "marked_for_offboarding",
	external: "external",
	service: "service",
	license: "allLicenses",
	contract: "allContracts",
	subscription: "allSubscriptions",
	perpetual: "allPerpetualContracts",
	recognised: "recognised",
	unrecognised: "unrecognised",
	archived: "archived",
	uploads: "uploads",
	drafts: "drafts",
	playbooks: "playbooks",
	"recent runs": "completed",
	spends: "spends",
};
export function EmptySearchV2({
	metaData,
	onReset,
	app_search = false,
	user_search = false,
	emptyMessage,
	...props
}) {
	const dispatch = useDispatch();
	const router = useSelector((state) => state.router);
	const { hash, pathname } = router.location;
	let tabName = hash.split("#");
	let [, route] = pathname.split("/");

	function getStateFilterObj(fieldId) {
		let obj = app_search
			? {
					field_id: "app_state",
					field_name: "Authorisation Status",
					field_values: stateFilterMap[routeMap[fieldId]],
					filter_type: "string",
					field_order: "contains",
					negative: false,
					is_custom: false,
				}
			: user_search
				? fieldId === "external"
					? {
							field_id: "user_is_external",
							field_name: "External",
							field_values: true,
							filter_type: "boolean",
							negative: false,
							is_custom: false,
						}
					: {
							field_id: "user_account_type",
							field_name: "User Account Type",
							field_values: stateFilterMap[routeMap[fieldId]],
							filter_type: "string",
							field_order: "contains",
							negative: false,
							is_custom: false,
						}
				: {};
		return [obj];
	}

	const totalCount = metaData?.other_tabs_count?.reduce(
		(sum, item) => sum + item.count,
		0
	);

	const NoResultsFoundSVGMap = {
		applications: NoResultsFoundSVG,
		users: NoResultsFoundSVG,
		licenses: licensesImage,
		transactions: transactionsImage,
		workflows: workflowsImage,
	};

	return (
		<div
			style={{
				height: "75%",
				margin: "auto",
			}}
			className="d-flex flex-column justify-content-center align-items-center"
		>
			<img
				className="pl-3 mb-2"
				src={NoResultsFoundSVGMap[route] || NoResultsFoundSVG}
				width={80}
				alt=""
				height={80}
			/>
			<div style={{ color: "black" }} className="empty-header">
				{emptyMessage
					? emptyMessage
					: `No results found ${
							tabName && tabName[1]
								? "in " + TabNameMap[tabName[1]]
								: ""
						}`}
			</div>
			{props.searchQuery && (
				<div style={{ color: "#484848" }} className="empty-lower">
					We couldn't find anything for '
					<span>{props.searchQuery}</span>'
				</div>
			)}
			{Array.isArray(metaData?.other_tabs_count) && totalCount > 0 && (
				<>
					<div className="my-3 align-items-center flex flex-column ">
						<div
							style={{
								flexWrap: "wrap",
								justifyContent: "center",
							}}
							className="flex mt-2"
						>
							{metaData?.other_tabs_count.map((item) => (
								<>
									{item?.count > 0 && (
										<div
											onClick={() => {
												let obj = {};
												obj.filter_by =
													app_search || user_search
														? getStateFilterObj(
																item?._id
															)
														: item?._id ===
															  "uploads"
															? [
																	getSearchReqObj(
																		props.searchQuery,
																		"name",
																		"Transaction name"
																	),
																]
															: item?._id ===
																  "archived"
																? [
																		getSearchReqObj(
																			props.searchQuery,
																			"transaction_description",
																			"Transaction Description"
																		),
																	]
																: metaData?.filter_by;

												const query = {
													[routeMap[item?._id]]:
														JSON.stringify({
															searchQuery:
																props.searchQuery,
														}),
												};
												const searchQuery =
													qs.stringify(query);

												dispatch(
													push({
														search: searchQuery,
														hash: routeMap[
															item?._id
														],
													})
												);
											}}
											style={{
												color: "#2266E2",
												background: "white",
												borderRadius: "8px",
											}}
											className="search-result-tab-info mx-4 py-1 p-2 px-3 my-2 cursor-pointer"
										>
											{item?.count || 0} Result found in{" "}
											{capitalizeFirstLetter(item?._id)}{" "}
										</div>
									)}
								</>
							))}
						</div>
					</div>
				</>
			)}
		</div>
	);
}

EmptySearchV2.propTypes = {
	searchQuery: PropTypes.string.isRequired,
};
