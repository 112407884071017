const initialState = {};
export default function v2ViewsReducer(state = initialState, action) {
	switch (action.type) {
		case "V2/VIEWS_FETCHED_NEW": {
			const { entity, data, error } = action.payload;

			return {
				...state,
				[entity]: { ...data, error },
			};
		}
		case "V2/UPDATED_VIEWS": {
			const { entity, data } = action.payload;
			return {
				...state,
				[entity]: { ...(state[entity] ?? {}), ...data },
			};
		}
		default:
			return state;
	}
}
