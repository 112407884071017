import React from "react";

const SearchIcon = ({ fillColor = "#717171", size = "14" }) => {
	return (
		<>
			<svg
				width={size}
				height={size}
				viewBox={`0 0 12 12`}
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M11.707 10.2955L9.38449 7.973C10.0917 6.91443 10.3798 5.63067 10.1928 4.37142C10.0059 3.11217 9.35725 1.96748 8.37306 1.16001C7.38887 0.352528 6.13952 -0.0599993 4.86801 0.00266578C3.59651 0.0653309 2.39377 0.598708 1.49375 1.49905C0.593731 2.39939 0.0607793 3.60231 -0.00143626 4.87384C-0.0636518 6.14536 0.349317 7.39457 1.15714 8.37848C1.96497 9.36238 3.10989 10.0106 4.3692 10.1971C5.62851 10.3836 6.91217 10.095 7.97049 9.3875L10.293 11.7095C10.4821 11.894 10.7358 11.9972 11 11.9972C11.2642 11.9972 11.5179 11.894 11.707 11.7095C11.8945 11.522 11.9998 11.2677 11.9998 11.0025C11.9998 10.7373 11.8945 10.483 11.707 10.2955ZM5.12499 1.5025C5.84195 1.5025 6.54281 1.7151 7.13893 2.11342C7.73506 2.51174 8.19969 3.07789 8.47405 3.74027C8.74842 4.40265 8.82021 5.13152 8.68034 5.8347C8.54047 6.53788 8.19522 7.18379 7.68825 7.69076C7.18129 8.19772 6.53537 8.54297 5.83219 8.68284C5.12901 8.82271 4.40015 8.75093 3.73776 8.47656C3.07538 8.20219 2.50923 7.73757 2.11091 7.14144C1.71259 6.54531 1.49999 5.84445 1.49999 5.1275C1.50105 4.16641 1.88331 3.24499 2.5629 2.5654C3.24249 1.88581 4.16391 1.50356 5.12499 1.5025Z"
					fill={fillColor}
				/>
			</svg>
		</>
	);
};

export default SearchIcon;
