import React from "react";
function VerticalDivider() {
	return (
		<div
			style={{
				height: 16,
				background: "#dddddd",
				width: 1,
				borderRadius: 8,
			}}
		/>
	);
}

export default VerticalDivider;
