import React, { useState } from "react";

import "./ReviewActionComment.css";

import { useDispatch, useSelector } from "react-redux";

import commentSvg from "assets/icons/Icon_Comment.svg";
import iconSignoffStatus from "assets/icons/Icon_SignoffStatus.svg";

import {
	BODY_VARIANT,
	Popover,
	PrimaryButtonVariant,
	Tooltip,
	Typography,
} from "@zluri/ui-components";
import {
	ACCESS_REVIEWS,
	APPROVED,
	DECLINED,
	MODIFIED,
	PENDING,
	TOOLTIP_SIGNOFF_CHECKMARK,
	V2_TABLE_ENTITIES,
	actionTextMapper,
	reviewActions,
	reviewActionsMapper,
} from "modules/AccessReview/constants/constants";
import {
	patchUserReviewStatus,
	patchUserReviewStatusEmpView,
} from "modules/AccessReview/service/AccessReview.service";
import EditReviewActionPopover, {
	CommentPopoverContent,
	EditActionPopoverContent,
	NotAReviewerPopup,
} from "../Popovers/Popovers";
import {
	IsCertOwner,
	formatDateToLocalTimeZone,
	getMetadataForSegmentAction,
	getStyle,
} from "modules/AccessReview/util/util";
import { TriggerIssue } from "utils/sentry";
import { useLocation } from "react-router-dom";
import { updateReviewData } from "modules/AccessReview/redux/AccessReview.actions";
import { trackActionSegment } from "modules/shared/utils/segment";
import { getTooltipContent } from "../common/common";
import { getSegmentFromURL } from "@zluri/ui-components";

export default function ReviewActionComment({
	component,
	data,
	handleUpdate,
	resetState,
	...props
}) {
	const location = useLocation();
	const dispatch = useDispatch();
	const isEmployee = getSegmentFromURL(1) === "user";
	const entity = isEmployee
		? V2_TABLE_ENTITIES.APP_OVERVIEW_USERS_EMP_VIEW
		: V2_TABLE_ENTITIES.APP_OVERVIEW_USERS;
	const entityData = useSelector((state) => state.v2Table?.[entity]);
	const [activeOption, setActiveOption] = useState();
	const [selectedOption, setSelectedOption] = useState({});
	const [hover, setHover] = useState(false);
	const [isPopoverOpen, setIsPopoverOpen] = useState(false);
	const [cellLoading, setCellLoading] = useState(false);
	const [commentText, setCommentText] = useState("");

	const getColor = reviewActions?.[data[component.valueKey]]?.bgColor;
	const isReviewer = IsCertOwner(data?.reviewer_id);
	const isLoggedInUserSelfReviewer = data?.reviewer_id === data?.user_id;
	const isReviewerAndActionsPending =
		data[component?.valueKey] === PENDING && isReviewer;

	const certification_id =
		getSegmentFromURL(2) === "accessreview"
			? getSegmentFromURL(3)
			: getSegmentFromURL(2);

	const selectedApp = useSelector((state) => state.accessReview?.selectedApp);
	const signOffJobNotPending = selectedApp?.signOffJobInProgress !== PENDING;
	const isNotSignedOff =
		data?.signoff_level < selectedApp?.currentReviewLevel;
	const shouldEdit =
		location.hash !== "#completeddetails" &&
		isReviewer &&
		signOffJobNotPending &&
		!data?.action_status &&
		!selectedApp?.readyForRemediation &&
		isNotSignedOff;

	const clearData = () => {
		setCellLoading(false);
		setIsPopoverOpen(false);
		setSelectedOption({});
		setCommentText("");
		setHover(false);
	};

	const handleReviewAction = ({ review_status, comment, postApiCleanUp }) => {
		const patchObj = {
			access_certification_user_id: [data.access_certification_user_id],
			review_status: review_status,
			comment: comment,
			select_all: {
				enabled: false,
				filter_by: [],
				unselected_access_certification_user_id: [],
			},
		};

		const api = isEmployee
			? patchUserReviewStatusEmpView
			: patchUserReviewStatus;

		api &&
			api(certification_id, data?.app_id, patchObj)
				.then((res) => {
					if (res || res?.status === "success") {
						dispatch({
							type: "RECORD_UPDATED",
							payload: {
								index: props?.rowIndex,
								value: {
									...data,
									review_status: review_status,
									review_comment: comment,
									...res?.last_reviewed_details,
								},
								entity: props?.entity,
								rowIndex: props?.rowIndex,
								columnIndex: props?.columnIndex,
								isInfinite: entityData?.isInfinite,
							},
						});
						dispatch(
							updateReviewData(
								certification_id,
								isEmployee,
								data?.app_id,
								res?.signoff_feasible
							)
						);
					}
				})
				.catch((err) => {
					TriggerIssue(
						`Error in updating review status for user ${data?.access_certification_user_id} and cert id ${certification_id}`,
						err,
						ACCESS_REVIEWS
					);
				})
				.finally(() => {
					postApiCleanUp && postApiCleanUp();
				});
	};

	return (
		<React.Fragment>
			{
				// if logged in user is the reviewer for himself
				isLoggedInUserSelfReviewer ? (
					<ActionAndCommentPill
						getColor={getColor}
						data={data}
						component={component}
					/>
				) : // is reviewer and actions are yet to be taken then show svgs for actions
				isReviewerAndActionsPending ? (
					<div className="d-flex align-items-center">
						{reviewActionsMapper?.map((el, index) => {
							const type = selectedOption?.type;
							const showCommentMandatoryText =
								type === MODIFIED || type === DECLINED;

							const isDisabled = () => {
								switch (type) {
									case APPROVED:
										return false;
									case MODIFIED:
									case DECLINED:
										return commentText === "";
									default:
										break;
								}
							};
							return (
								<Popover
									placement="bottomLeft"
									content={
										<EditActionPopoverContent
											commentText={commentText}
											actionBtnLoading={cellLoading}
											placeholder={`Start typing your comment here... ${
												type === APPROVED
													? "(Optional)"
													: ""
											}`}
											showCommentMandatoryText={
												showCommentMandatoryText
											}
											onCancelClick={() => clearData()}
											onActionClick={() => {
												trackActionSegment(
													"Review users table - review action taken",
													{
														currentCategory:
															"Reviewers Progress Screen",
														currentPage:
															"Send Reminder Popup",
														metaData:
															getMetadataForSegmentAction(
																data
															),
													}
												);
												setCellLoading(true);
												handleReviewAction({
													review_status: type,
													comment: commentText || "",
													postApiCleanUp: () =>
														clearData(),
												});
											}}
											actionBtnDisabled={isDisabled()}
											actionBtnVariant={
												showCommentMandatoryText
													? PrimaryButtonVariant.primary_danger
													: PrimaryButtonVariant.primary_default
											}
											actionBtnText={
												selectedOption?.label
											}
											handlers={{
												onChange: (e) =>
													setCommentText(
														e.target?.value
													),
											}}
										/>
									}
									show={
										isPopoverOpen &&
										selectedOption?.label === el?.label
									}
									onToggle={(val) => {
										setIsPopoverOpen(val);
										!val && clearData();
									}}
									key={el?.type}
								>
									<Tooltip
										placement="bottom"
										content={
											<Typography
												variant={
													BODY_VARIANT.BODY_2_REGULAR
												}
												color="white"
												className="text-center"
											>
												{el?.label}
											</Typography>
										}
									>
										<div
											className="d-flex align-items-center justify-content-center"
											style={getStyle(
												activeOption,
												selectedOption,
												index,
												el
											)}
											onMouseEnter={() => {
												setActiveOption(index);
											}}
											onMouseLeave={() => {
												setActiveOption(null);
											}}
											onClick={() => {
												setCommentText("");
												setIsPopoverOpen(true);
												setSelectedOption(el);
											}}
										>
											<img
												className="cursor-pointer"
												src={el?.img}
												alt=""
											/>
										</div>
									</Tooltip>
								</Popover>
							);
						})}
					</div>
				) : (
					<div className="review_action_comment_cell">
						{/* {action is already taken} */}
						<div
							className="d-flex w-100 justify-content-between align-items-center"
							onMouseEnter={() => setHover(true)}
							onMouseLeave={() => setHover(false)}
						>
							<ActionAndCommentPill
								getColor={getColor}
								data={data}
								component={component}
							/>

							<EditReviewActionPopover
								data={data}
								component={component}
								hover={hover}
								setHover={setHover}
								handleReviewAction={handleReviewAction}
								shouldEdit={shouldEdit}
							/>
						</div>
						{!isNotSignedOff && (
							<Tooltip
								placement="bottom"
								content={getTooltipContent(
									TOOLTIP_SIGNOFF_CHECKMARK
								)}
							>
								<img
									className="mr-2"
									src={iconSignoffStatus}
									alt=""
									height={16}
									width={16}
								/>
							</Tooltip>
						)}
					</div>
				)
			}
		</React.Fragment>
	);
}

const ActionAndCommentPill = ({ getColor, data, component }) => {
	return (
		<div
			className="review_action_text"
			style={{
				backgroundColor: getColor,
			}}
		>
			<div className="d-flex gap-8">
				{data[component?.valueKey] !== PENDING && (
					<img
						src={reviewActions?.[data[component?.valueKey]]?.img}
						alt=""
					/>
				)}
				{data[component?.valueKey] === PENDING ? (
					<NotAReviewerPopup />
				) : (
					actionTextMapper[data[component?.valueKey]]
				)}
			</div>

			{data?.review_comment && (
				<Popover
					placement="bottomRight"
					content={
						<CommentPopoverContent
							badgeName={data?.last_reviewed_by_name}
							badgeImg={data?.last_reviewed_by_profile}
							userName={data?.last_reviewed_by_name}
							userEmail={data?.last_reviewed_by_email}
							actionText={formatDateToLocalTimeZone(
								data?.date_of_review,
								"D MMM, YYYY"
							)}
							date={formatDateToLocalTimeZone(
								data?.date_of_review,
								"HH:mm:ss",
								true
							)}
							content={data?.review_comment}
						/>
					}
					trigger="hover"
				>
					<div className="action_comment">
						<img src={commentSvg} alt="" height={16} width={16} />
					</div>
				</Popover>
			)}
		</div>
	);
};
