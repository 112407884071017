import React, { useState } from "react";
import "./NoteCard.css";

import PropTypes from "prop-types";

export const NoteColor = ({ color, hoverColor, onClick, selectedColor }) => {
	const [hovering, setHovering] = useState(false);

	return (
		<div
			className="z_overview_note_color cursor-pointer"
			style={{
				backgroundColor: hovering
					? hoverColor
					: selectedColor === hoverColor
						? color
						: "white",
			}}
		>
			<div
				className="cursor-pointer z_overview_note_color_item"
				onMouseEnter={() => setHovering(true)}
				onMouseLeave={() => setHovering(false)}
				onClick={() => onClick(hoverColor)}
				style={{ backgroundColor: color }}
			/>
		</div>
	);
};

NoteColor.propTypes = {
	color: PropTypes.string,
	hoverColor: PropTypes.string,
	onClick: PropTypes.func,
	selectedColor: PropTypes.string,
};
