import React from "react";
import { useTableContext } from "containers/v2table/TableContext/context";
import { BLUE_VARIANT, BUTTON_VARIANT, Typography } from "@zluri/ui-components";

function NumberOfCheckedButton() {
	const { isBulkSelected, checkboxState } = useTableContext();
	const numberOfSelectedRows = Object.keys(
		checkboxState.selected ?? {}
	)?.length;

	const showNumberOfCheckedButton = !isBulkSelected && !!numberOfSelectedRows;

	if (!showNumberOfCheckedButton) return null;

	return (
		<>
			<div
				style={{
					width: "1px",
					height: "16px",
					borderRadius: "4px",
					background: "#ddd",
				}}
			></div>
			<div className="selected-rows-pill">
				<Typography
					variant={BUTTON_VARIANT.BUTTON_EXTRA_SMALL_REGULAR}
					color={BLUE_VARIANT.PRIMARY_BLUE_DARK_1}
					className="text-nowrap"
				>
					{`${numberOfSelectedRows} Selected`}
				</Typography>
			</div>
		</>
	);
}

export default NumberOfCheckedButton;
