import {
	call,
	put,
	select,
	takeEvery,
	takeLatest,
	throttle,
} from "redux-saga/effects";
import { replace } from "connected-react-router";
import qs from "query-string";
import {
	patchApplication,
	patchApplicationUser,
} from "services/api/applications";
import { API_TYPES, getEntityApi } from "containers/V2TableConstants";
import { getViewsv2 } from "services/api/viewsv2";

import { isEqual } from "underscore";
import { getSegmentFromURL } from "@zluri/ui-components";
import {
	convertCustomFieldFilterPayload,
	convertCustomFieldFilterResponse,
} from "./customFieldsFilterFormatter";
import { getValueFromLocalStorage } from "utils/localStorage";

const PATCH_API_MAP = {
	applications: patchApplication,
	managed: patchApplication,
	app_users: patchApplicationUser,
};

const ENTITY_SEARCH_FILTER_ID = {
	tasks: "action_name",
	allApplications: "app_name",
	managed: "app_name",
	unmanaged: "app_name",
	restricted: "app_name",
	needsReview: "app_name",
};

export function* watchFetchData() {
	yield takeEvery("V2TABLE/START_LOADING", startLoading);
	yield takeEvery("UPDATE_RECORD", updateField);
	yield takeLatest("GET_TABLE_DATA", getTableData);
	yield throttle(1000, "GET_NEXT_PAGE", getNextPage);
	yield takeLatest("GET_PREV_PAGE", getPrevPage);
	yield takeLatest("UPDATE_RECORDS_PER_PAGE", updateRecordsPerPage);
	yield takeLatest("UPDATE_PAGINATION_TYPE", updatePaginationType);
	yield takeLatest("UPDATE_TAB_COUNT", updateTabCount);
	yield takeLatest("CHANGE_LOADER_REQUESTED", showorHideLoader);
	yield takeLatest("V2/UPDATE_VIEWS", updateViews);
}

export function* startLoading({ payload: { entity, subEntityData } }) {
	const dataEntity = subEntityData?.entity || entity;
	yield put({
		type: "TABLE_DATA_REQUESTED",
		payload: { entity: dataEntity },
	});
}
export function* updateRecordsPerPage({
	payload: { entity, recordsPerPage, subEntityData, apiProps },
}) {
	const dataEntity = subEntityData?.entity || entity;
	let {
		sort_by: sortBy,
		searchQuery,
		filter_by: filterBy,
		entityId,
	} = yield select((state) => state.v2Table[dataEntity]);

	yield put({
		type: "TABLE_DATA_REQUESTED",
		payload: { entity: dataEntity },
	});
	yield fetchData({
		payload: {
			entity: dataEntity,
			recordsPerPage,
			page: 0,
			filterBy,
			sortBy,
			searchQuery,
			apiProps,
			persistView: true,
			entityId,
		},
	});
	yield put({
		type: "RECORDS_PER_PAGE_UPDATED",
		payload: { entity: dataEntity, recordsPerPage },
	});
}
export function* updateTabCount({ payload }) {
	yield put({
		type: "TAB_COUNT_REQUESTED",
		payload,
	});
	const data = yield call(getEntityApi(payload.entity, API_TYPES.TABCOUNT));
	yield put({
		type: "TAB_COUNT_UPDATED",
		payload: { entity: payload.entity, tabCount: data },
	});
}

export function* showorHideLoader({ payload }) {
	yield put({
		type: "CHANGE_LOADER_COMPLETED",
		payload,
	});
}
export function* updatePaginationType({ payload: { entity, isInfinite } }) {
	yield updateUrls(entity, isInfinite);
	yield put({
		type: "PAGINATION_TYPE_UPDATED",
		payload: { entity, isInfinite },
	});
}
export function* getNextPage({ payload }) {
	try {
		const { entity, subEntityData, isInfinite } = payload;
		const dataEntity = subEntityData?.entity || entity;
		let currentPage = yield select(
			(state) => state.v2Table[dataEntity]?.page
		);

		const nextPage = isNaN(parseInt(currentPage))
			? 0
			: parseInt(currentPage) + 1;

		const updatedPayload = {
			...payload,
			page: nextPage,
			persistView: true,
		};
		yield getTableData({ payload: updatedPayload });

		yield updateUrls(dataEntity, isInfinite);
	} catch (e) {
		console.log("ee", e);
	}
}

export function* getPrevPage({ payload }) {
	const { entity, subEntityData } = payload;
	const dataEntity = subEntityData?.entity || entity;

	let currentPage = yield select((state) => state.v2Table[dataEntity]?.page);

	const prevPage = currentPage - 1;
	if (prevPage < 0) return;
	const updatedPayload = { ...payload, page: prevPage, persistView: true };
	yield getTableData({ payload: updatedPayload });

	yield updateUrls(dataEntity);
}

export function* getTableData({ payload }) {
	const dataEntity = payload?.subEntityData?.entity || payload?.entity;
	const entityId = payload?.entityId;
	const existingData = yield select((state) =>
		entityId
			? state.v2Table[dataEntity]?.entityCache?.[entityId]
			: state.v2Table[dataEntity]
	);

	const page = !isNaN(parseInt(payload?.page))
		? payload?.page
		: !isNaN(parseInt(existingData?.page))
			? existingData?.page
			: 0;

	const disableUrlSync =
		payload?.disableUrlSync ?? existingData?.disableUrlSync;
	const isInfinite = payload?.isInfinite ?? existingData?.isInfinite;

	const shouldRestoreFromCache =
		existingData &&
		!existingData.loading &&
		existingData?.data?.[page] &&
		payload.isFirstLoad &&
		payload.enableCacheRestore;

	if (shouldRestoreFromCache) {
		yield put({
			type: "RESTORE_LATEST_STATE",
			payload: {
				entity: dataEntity,
				entityId: entityId,
			},
		});

		if (!disableUrlSync) {
			yield updateUrls(dataEntity, isInfinite);
		}
		return;
	}
	if (!payload.shouldRefresh) {
		if (
			existingData &&
			!existingData.loading &&
			existingData?.data?.[page]
		) {
			if (
				existingData?.data?.[page] &&
				page !== parseInt(existingData?.page)
			) {
				yield put({
					type: "UPDATE_PAGE",
					payload: {
						page: page,
						entity: dataEntity,
					},
				});
			}
			return;
		}
	}

	const isSortByChanged =
		payload?.sortBy && !isEqual(existingData?.sortBy, payload?.sortBy);

	const isSearchQueryChanged =
		payload?.searchQuery &&
		!isEqual(existingData?.searchQuery, payload?.searchQuery);

	const isFilterChanged =
		payload?.filterBy &&
		!isEqual(existingData?.filter_by, payload?.filterBy);

	const needsReset =
		isSortByChanged ||
		isSearchQueryChanged ||
		isFilterChanged ||
		payload?.needsReset; // TODO: prj This needs to be fixed

	const appliedViewId = payload?.persistView
		? existingData?.viewId
		: payload?.viewId;
	const updatedPayload = {
		...payload,
		screen_tag: payload?.screen_tag ?? existingData?.screen_tag,
		sortBy:
			payload?.sortBy ??
			existingData?.sortBy ??
			payload?.subEntityData?.sort_by ??
			[],
		cols: payload?.cols ?? existingData?.columns ?? [],
		recordsPerPage: payload?.recordsPerPage ?? existingData?.recordsPerPage,
		filterBy:
			payload?.filterBy ??
			existingData?.filter_by ??
			payload?.subEntityData?.filter_by ??
			[],
		searchQuery:
			payload?.searchQuery ??
			existingData?.searchQuery ??
			payload?.subEntityData?.search_query,
		page: needsReset ? 0 : page,
		resetAndRepopulate: needsReset,
		isInfinite: payload?.isInfinite ?? existingData?.isInfinite,
		quick_filter: payload?.quick_filter ?? existingData?.quick_filter ?? [],
		entityId: entityId ?? existingData?.entityId,

		// for now persistView is introduced only for search Query
		// Once we colocate all the query dispatch logic in a single place it should be easier to keep track of this
		// viewId is used to keep track of active view || if some actions happen (sort, filter, columns modifications) then views shouldn't be tracked
		viewId: appliedViewId,

		disableUrlSync,
	};

	yield getProperties({ payload });
	if (payload.isViewRequired) {
		// todo:fix - temp fix
		try {
			yield getViews({ payload });
			const views = yield select((state) => state.v2Views?.[dataEntity]);
			if (views?.data.length && !payload.filterBy && !payload.sortBy) {
				const {
					columns: defaultColumnsFromView,
					filterBy: defaultFilterByFromView,
					sortBy: defaultSortByFromView,
					viewId: defaultViewId,
				} = getDefaultStateFromView({ views, dataEntity });

				updatedPayload.cols = payload?.cols ?? defaultColumnsFromView;
				updatedPayload.filterBy =
					payload?.filterBy ?? defaultFilterByFromView;
				updatedPayload.sortBy =
					payload?.sortBy ?? defaultSortByFromView;
				updatedPayload.viewId = defaultViewId;
			}
		} catch (e) {
			console.error(e);
		}
	}

	if (payload.resetFilter) {
		try {
			const views = yield select((state) => state.v2Views?.[dataEntity]);
			if (views?.data.length) {
				const {
					filterBy: defaultFilterByFromView,
					columns: defaultColumnsFromView,
					sortBy: defaultSortByFromView,
					viewId: defaultViewId,
				} = getDefaultStateFromView({
					views,
					dataEntity,
					resetFilter: true,
				});

				updatedPayload.filterBy =
					payload?.filterBy ?? defaultFilterByFromView;

				const isColumnStateDifferentFromView = !isEqual(
					existingData?.columns?.map((c) => c.group_name),
					defaultColumnsFromView
				);

				const isSortStateDifferentFromView = !isEqual(
					existingData?.sortBy,
					defaultSortByFromView
				);

				if (
					!(
						isColumnStateDifferentFromView ||
						isSortStateDifferentFromView
					)
				) {
					updatedPayload.viewId = defaultViewId;
				}
			} else {
				updatedPayload.filterBy =
					payload?.subEntityData?.filter_by ?? [];
			}
		} catch (e) {
			console.error(e);
		}
	}
	// This flag makes sure the loading state is never set if its infinite pagination and data for new page is being loaded
	// Because everytime the data is being loaded, loading indicator is shown which didn't provide good UX
	// In future we may opt for skeleton loading for infinite pagination. Then we can rethink about this again.
	const isNexPageForInfiniteBeingLoaded = payload.isInfinite && page > 0;
	yield put({
		type: "TABLE_DATA_REQUESTED",
		payload: {
			entity: dataEntity,
			isInfinite: payload.isInfinite,
			enableInfiniteLoading: isNexPageForInfiniteBeingLoaded,
		},
	});
	yield fetchData({ payload: updatedPayload });
}

function getDefaultStateFromView({ views, dataEntity, resetFilter }) {
	const viewsFromLocalStorage = getValueFromLocalStorage("v2TableViews");
	const viewIdFromLocalStorage = viewsFromLocalStorage?.[dataEntity];

	const viewsData = views?.data ?? [];

	const _defaultView =
		viewsData.find((v) => v.is_default) ??
		viewsData.find((v) => v.is_system_default);

	const defaultView = viewIdFromLocalStorage
		? (viewsData.find(
				(v) => String(v._id) === String(viewIdFromLocalStorage)
			) ?? _defaultView)
		: _defaultView;

	return {
		columns: defaultView.v2_columns,
		filterBy: defaultView.filters,
		sortBy: defaultView.sort,
		viewId: defaultView?._id,
	};
}
export function* getProperties({ payload }) {
	const dataEntity = payload?.subEntityData?.entity || payload?.entity;

	// I am not sure why this is being called everytime new page data is being loaded
	// This flag makes sure the loading state is never set if its infinite pagination and data for new page is being loaded
	// Because everytime the data is being loaded, loading indicator is shown which didn't provide good UX
	// In future we may opt for skeleton loading for infinite pagination. Then we can rethink about this again.
	const isNexPageForInfiniteBeingLoaded =
		payload.isInfinite && payload.page > 0;
	if (!isNexPageForInfiniteBeingLoaded) {
		yield put({
			type: "TABLE_DATA_REQUESTED",
			payload: {
				entity: dataEntity,
			},
		});
	}

	const existingData = yield select(
		(state) => state.v2TableProperties[dataEntity]
	);

	if (existingData && !existingData.loading) return;

	try {
		const response = !payload?.apiProps
			? yield call(getEntityApi(payload?.entity, API_TYPES.FILTER))
			: yield call(
					getEntityApi(payload?.entity, API_TYPES.FILTER),
					payload?.apiProps
				);
		yield put({
			type: "V2/PROPERTIES_FETCHED",
			payload: { entity: dataEntity, data: response },
		});
	} catch (err) {
		yield put({
			type: "V2/PROPERTIES_FETCHED",
			payload: { entity: dataEntity, error: err },
		});
	}
}

export function* getViews({ payload }) {
	const dataEntity = payload?.subEntityData?.entity || payload?.entity;
	if (!payload.shouldRefresh) {
		let existingData = yield select(
			(state) => state.v2Views?.[payload.entity]
		);

		if (existingData && !existingData.loading) return;
	}

	try {
		const response = yield call(
			getViewsv2,
			payload.screen_tag,
			payload.appId
		);

		yield put({
			type: "V2/VIEWS_FETCHED_NEW",
			payload: { entity: dataEntity, data: response },
		});
	} catch (error) {
		yield put({
			type: "V2/VIEWS_FETCHED_NEW",
			payload: { entity: dataEntity, error },
		});
	}
}

function* fetchData({
	payload: {
		entity,
		subEntityData,
		sortBy,
		searchQuery,
		filterBy,
		skip = 0,
		api,
		resetAndRepopulate,
		screen_tag,
		cols = [],
		page,
		recordsPerPage,
		intID,
		isInfinite,
		apiProps,
		viewId,
		quick_filter,
		disableUrlSync,
		entityId,
	},
}) {
	const dataEntity = subEntityData?.entity || entity;
	let v2TableProperties = yield select(
		(state) => state.v2TableProperties[entity]
	);

	const columnsRequest = cols.map((c) => {
		if (typeof c === "string") {
			return c;
		} else return c?.group_name;
	});

	const entityWithDeprecatedPayloadFormatForCustomFields = [
		"app_users_emp_view",
		"app_review_users",
	];
	// This will be removed once app_review_users table follows this same pattern for custom fields filter
	const hasDeprecatedPayloadFormatForCustomFields =
		entityWithDeprecatedPayloadFormatForCustomFields.includes(entity);

	const formattedFilterByPayloadForCustomFields =
		hasDeprecatedPayloadFormatForCustomFields
			? filterBy
			: convertCustomFieldFilterPayload(filterBy);

	// We are fetching views first and chekcing the default views and applying the columns filter to the list API itself
	// So the screen_tag value and view_id value is irrelevant for now.
	try {
		const {
			data,
			meta: {
				columns,
				filter_by,
				sort_by,
				total,
				other_tabs_count,
				pagination,
				search_query,
			},
		} = yield call(
			getEntityApi(dataEntity, API_TYPES.LIST),
			{
				columns: columnsRequest || [],
				filter_by: formattedFilterByPayloadForCustomFields || [],
				sort_by: sortBy || [],
				screen_tag: undefined, // for now we aren't using views filter with screen_tag and view_id combo// FE is sending filter columns only.
				view_id: undefined,
				quick_filter: quick_filter,
			},
			isNaN(parseInt(page)) ? 0 : parseInt(page),
			recordsPerPage || 20,
			undefined,
			searchQuery,
			intID ? intID : getSegmentFromURL(2) || "",
			apiProps ? apiProps : {}
		);

		// weirdly sometimes backend sends the value of columns as null
		const updatedColumns = columns?.filter(Boolean)?.map((c) => {
			if (typeof c === "string") {
				const col = v2TableProperties?.columns?.find(
					(column) => column.group_name === c
				);
				return col;
			} else {
				const { ui, ...rest } = c;
				return { ...rest, ...ui };
			}
		});
		const updatedFilters = filter_by?.filter(
			(f) => f.field_id !== ENTITY_SEARCH_FILTER_ID[entity]
		);

		const formattedFilterByResponseForCustomFields =
			hasDeprecatedPayloadFormatForCustomFields
				? updatedFilters
				: convertCustomFieldFilterResponse(updatedFilters);

		yield put({
			type: "TABLE_DATA_FETCHED",
			payload: {
				data,
				count: total,
				page: pagination?.page,
				columns: updatedColumns,
				filter_by: formattedFilterByResponseForCustomFields,
				entity: dataEntity,
				sortBy: sort_by,
				searchQuery: search_query,
				other_tabs_count,
				recordsPerPage: pagination?.row,
				isInfinite,
				quick_filter: quick_filter,
				resetAndRepopulate,
				viewId,
				disableUrlSync,
				entityId,
			},
		});
		if (!disableUrlSync) {
			yield updateUrls(dataEntity, isInfinite);
		}
	} catch (err) {
		console.error(err);
		yield put({
			type: "TABLE_DATA_FETCHED",
			payload: {
				entity: dataEntity,
				entityId,
				error: err,
				disableUrlSync,
			},
		});
	}
}

export function* updateUrls(dataEntity, isInfinite) {
	const {
		filter_by,
		sortBy,
		page,
		recordsPerPage,
		columns,
		searchQuery,
		viewId,
		entityId,
	} = yield select((state) => state.v2Table[dataEntity]);
	const query = {
		[dataEntity]: JSON.stringify({
			filterBy: filter_by,
			sortBy,
			columns: columns.map((c) => c.group_name),
			...(!isInfinite
				? { page: page, recordsPerPage: recordsPerPage }
				: {}),
			isInfinite,
			searchQuery,
			viewId,
			entityId,
		}),
	};

	//API call to retrieve records
	//...
	// CHANGE HERE ENTITY ???????
	const searchString = qs.stringify(query);

	yield put(
		replace({
			search: searchString,
			hash: window.location.hash || "",
		})
	);
}
export function* updateField({
	payload: {
		tableEntity,
		entity,
		id,
		data,
		value,
		index,
		rowIndex,
		columnIndex,
		skipApi,
	},
}) {
	if (skipApi) return;
	try {
		let page = yield select((state) => state.v2Table[entity]?.page);
		let isInfinite = yield select(
			(state) => state.v2Table[entity]?.isInfinite
		);

		const response = yield call(PATCH_API_MAP[tableEntity], id, data);
		yield put({
			type: "RECORD_UPDATED",
			payload: {
				index,
				value: response?.data ?? value,
				entity,
				rowIndex,
				columnIndex,
				page,
				isInfinite,
			},
		});
	} catch (e) {
		yield put({
			type: "RECORD_UPDATE_FAILED",
			payload: { index, entity, rowIndex, columnIndex },
		});
		console.error(e);
	}
}

export function* updateViews({
	payload: { entity, subEntityData, screen_tag, appId },
}) {
	try {
		const dataEntity = subEntityData?.entity || entity;
		//refetching
		const response = yield call(getViewsv2, screen_tag, appId);
		yield put({
			type: "V2/UPDATED_VIEWS",
			payload: { entity: dataEntity, data: response },
		});
	} catch (e) {
		console.error(e);
	}
}
