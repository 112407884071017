import React from "react";
import pencil from "assets/pencil.svg";

export default function EditOnHover() {
	return (
		<div className="v2table__editpencil__wrapper">
			<img src={pencil} alt="pencil icon" />
		</div>
	);
}
