import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { Loader } from "./Loader";

function getProgressStyle(value) {
	if (value >= 75) {
		return {
			textSize: "40px",
			textColor: "#009307",
			backgroundColor: "#E7F8E8",
			trailColor: "transparent",
			pathColor: "#009307",
		};
	} else if (value >= 50) {
		return {
			textSize: "40px",
			textColor: "#FFA217",
			backgroundColor: "#FFF6DC",
			trailColor: "transparent",
			pathColor: "#FFA217",
		};
	} else if (value >= 25) {
		return {
			textSize: "40px",
			textColor: "#717171",
			backgroundColor: "#EBEBEB",
			trailColor: "transparent",
			pathColor: "#717171",
		};
	} else {
		return {
			textSize: "40px",
			textColor: "#FE6955",
			backgroundColor: "#FFF6DC",
			trailColor: "transparent",
			pathColor: "#FFA217",
		};
	}
}

export function Progress({ data, component }) {
	let style = getProgressStyle(data[component.valueKey]);
	style = component.hidePath
		? Object.assign(style, { pathColor: style.backgroundColor })
		: getProgressStyle(data[component.valueKey]);
	return (
		<span style={{}} className="v2table__cell-progress font-11 black-1">
			{data[component.valueKey] ? (
				<span>
					<CircularProgressbar
						background
						text={`${data[component.valueKey]}`}
						value={data[component.valueKey]}
						styles={buildStyles(style)}
					/>
				</span>
			) : (
				<Loader type={data[component.loaderType]} />
			)}
		</span>
	);
}
