import React from "react";

import PropTypes from "prop-types";

import { BODY_VARIANT, Typography } from "@zluri/ui-components";

import IconArchivedStatus from "assets/Icon_ArchiveStatus.svg";
import IconUnarchivedStatus from "assets/Icon_UnarchiveStatus.svg";

const ArchivedStatus = ({ data, component }) => {
	const isArchived = data[component?.valueKey];

	const ARCHIVED_TYPES = {
		ARCHIVE: { text: "Archived", icon: IconArchivedStatus },
		UNARCHIVE: { text: "Unarchived", icon: IconUnarchivedStatus },
	};

	return (
		<div className="d-flex align-items-center gap-4">
			<img
				src={
					isArchived
						? ARCHIVED_TYPES.ARCHIVE.icon
						: ARCHIVED_TYPES.UNARCHIVE.icon
				}
				alt={isArchived ? "Archived" : "Unarchived"}
				width={16}
				height={16}
			/>
			<Typography variant={BODY_VARIANT.BODY_1_REGULAR} color="#222222">
				{isArchived
					? ARCHIVED_TYPES.ARCHIVE.text
					: ARCHIVED_TYPES.UNARCHIVE.text}
			</Typography>
		</div>
	);
};
ArchivedStatus.propTypes = {
	data: PropTypes.object,
	component: PropTypes.shape({
		valueKey: PropTypes.string,
	}),
};

export default ArchivedStatus;
