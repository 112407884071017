import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import IconClose from "assets/Icon_Close.svg";
import {
	ALERT_TAG_VARIANT,
	BODY_VARIANT,
	GREY_VARIANT,
	Tooltip,
	Typography,
} from "@zluri/ui-components";

interface CommonSearchBoxProps {
	placeholder?: string;
	isCollapsible?: boolean;
	onClear?: () => void;
	defaultValue?: string;
	onChangeText?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onEnter?: () => void;
	onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void; // if both onEnter and onKeyDown props are present then onEnter will override onKeyDown.
	value?: string;
	tooltipText?: string; //this will be displayed on hover
}

interface TaggedSearchBoxProps {
	onChangeTags?: (tags: string[]) => void;
	defaultTags?: string[];
	tags?: string[];
	withTags: true;
	showInfoTooltip?: boolean;
	infoTooltipText?: string;
	showSearchIcon?: boolean;
}

interface TextSearchBoxProps {
	onChangeTags?: never;
	defaultTags?: never;
	tags?: never;
	withTags?: false | undefined;
	showInfoTooltip?: never;
	infoTooltipText?: never;
	showSearchIcon?: boolean;
	tooltipText?: string;
}

type SearchBoxProps = CommonSearchBoxProps &
	(TaggedSearchBoxProps | TextSearchBoxProps);

//TODO: see how to make use of combined ref from props and from within component
const SearchBox = React.forwardRef<HTMLInputElement, SearchBoxProps>(
	(props, externalInputRef) => {
		const {
			onChangeText,
			onChangeTags,
			placeholder,
			isCollapsible = true,
			onClear,
			defaultTags,
			defaultValue,
			value,
			tags: controlledTags,
			showInfoTooltip = false,
			withTags = false,
			tooltipText = "Search",
			infoTooltipText,
			onEnter,
			onKeyDown,
			showSearchIcon = true,
		} = props;

		const [text, setText] = useState(value ?? defaultValue ?? "");
		const [tags, setTags] = useState<string[]>(
			controlledTags ?? defaultTags ?? []
		);
		const internalInputRef = useRef<HTMLInputElement>(null);
		const [isSearchBoxFocused, setIsSearchBoxFocused] = useState(false);

		const textValue = value ?? text;
		const tagsList = controlledTags ?? tags;

		const tagsRef = useAutoScroller(tagsList);

		const onDelimiterDetected = (
			e: React.KeyboardEvent<HTMLInputElement>
		) => {
			const value = e.currentTarget.value;

			if (value === " " || value === ",") {
				return;
			}
			const _newTags = [...tagsList, value.slice(0, -1)];
			setTags(_newTags);
			setText("");
			if (onChangeTags) {
				onChangeTags(_newTags);
			}
		};

		const onBackSpaceClicked = () => {
			const _tags = tagsList.slice(0, -1);
			setTags(_tags);
			if (onChangeTags) onChangeTags(_tags);
		};

		useEffect(() => {
			if (isSearchBoxFocused) {
				internalInputRef.current?.focus();
			}
		}, [isSearchBoxFocused]);

		const onClearAll = () => {
			setTags([]);
			setText("");
			setIsSearchBoxFocused(false);
			if (onClear) {
				onClear();
			}
		};

		const onRemoveEachTags = (index: number) => {
			const _tags = [...tagsList];
			_tags.splice(index, 1);
			setTags(_tags);
			if (onChangeTags) {
				onChangeTags(_tags);
			}
		};

		const isExpanded = isCollapsible
			? isSearchBoxFocused || tagsList.length > 0 || textValue.length > 0
			: true;

		return (
			<search
				className="z__searchbox-container"
				style={{ width: isExpanded ? "100%" : 32 }}
			>
				<ConditionalTooltip title={tooltipText} show={!isExpanded}>
					<div
						className="z__searchbox"
						onClick={() => {
							setIsSearchBoxFocused(true);
						}}
						onBlur={(e) => setIsSearchBoxFocused(false)}
					>
						{showSearchIcon ? (
							<label
								htmlFor="z__searchbox-input"
								className="z__searchbox-input-label"
							>
								<SearchIcon />
							</label>
						) : null}
						{isExpanded && (
							<>
								{withTags && tagsList.length > 0 && (
									<div
										className="z__searchbox-search-tags-container z__searchbox-thin-scrollbar"
										ref={tagsRef}
									>
										{tagsList?.map((t, i) => (
											<SearchTags
												key={t + i}
												onRemove={() =>
													onRemoveEachTags(i)
												}
											>
												{t}
											</SearchTags>
										))}
									</div>
								)}
								<input
									ref={internalInputRef}
									id="z__searchbox-input"
									autoCapitalize="off"
									autoComplete="off"
									autoCorrect="off"
									spellCheck={false}
									type="search"
									className="typography--variant-body_2_regular"
									enterKeyHint="search"
									placeholder={placeholder ?? "search"}
									onKeyDown={(e) => {
										if (
											withTags &&
											e.key === "Backspace" &&
											textValue.length === 0
										) {
											onBackSpaceClicked();
										}

										if (onKeyDown) {
											onKeyDown(e);
										}
										if (e.key === "Enter") {
											if (onEnter) {
												internalInputRef.current?.blur();
												onEnter();
											}
										}
									}}
									onKeyUp={(e) => {
										if (withTags && e.key === ",") {
											onDelimiterDetected(e);
										}
									}}
									value={textValue}
									onChange={(e) => {
										setText(e.target.value);
										if (onChangeText) {
											onChangeText(e);
										}
									}}
								/>
								{onClear && text && (
									<button
										title="clear"
										type="reset"
										aria-label="clear"
										tabIndex={0}
										className="z__searchbox-clear"
										onClick={onClearAll}
									>
										<CloseIcon />
									</button>
								)}
							</>
						)}
					</div>
					{showInfoTooltip && isExpanded && isSearchBoxFocused && (
						<div
							className="z__searchbox-info-tooltip"
							role="tooltip"
							aria-hidden={isExpanded}
							aria-describedby="z__searchbox-info-tooltip-label"
						>
							<div className="z__searchbox-info-tooltip-content-container">
								<div
									className="z__searchbox-info-toolip-content"
									id="z__searchbox-info-tooltip-label"
								>
									<Typography
										variant={
											ALERT_TAG_VARIANT.ALERT_TAG_EXTRA_SMALL_MEDIUM
										}
										color={GREY_VARIANT.SECONDARY_GREY_3}
									>
										{infoTooltipText ??
											"Separate each keyword by COMMA"}
									</Typography>
								</div>
							</div>
						</div>
					)}
				</ConditionalTooltip>
			</search>
		);
	}
);
SearchBox.displayName = "SearchBox";

// auto scroll tags to the extreme right if new tag is added but don't scroll if removed
function useAutoScroller(tagsList: string[]) {
	// This ref is used to keep track of value of tagsList state before the new render.
	// Will be used to check if the changed tags list is due to new addition or due to removal
	const previousTagsList = useRef(tagsList);

	const tagsRef = useRef<HTMLDivElement | null>(null);

	// update the scroll position as tags is changed
	useEffect(() => {
		if (tagsRef.current) {
			// checking here if new tag is added or removed.
			if (previousTagsList.current.length < tagsList.length) {
				tagsRef.current?.scroll({
					left: tagsRef.current?.scrollWidth,
					behavior: "smooth",
				});
			}
			previousTagsList.current = tagsList;
		}
	}, [tagsList]);

	// Scroll to the extreme right possible on first render
	useEffect(() => {
		if (tagsRef.current) {
			// checking here if new tag is added or removed.
			tagsRef.current?.scroll({
				left: tagsRef.current?.scrollWidth,
				behavior: "smooth",
			});
		}
	}, []);

	return tagsRef;
}

//Maybe? Separate?
function SearchIcon() {
	return (
		<svg
			width="12"
			height="12"
			aria-label="search"
			viewBox={`0 0 12 12`}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className="z__searchbox-search-icon"
		>
			<path
				d="M11.707 10.2955L9.38449 7.973C10.0917 6.91443 10.3798 5.63067 10.1928 4.37142C10.0059 3.11217 9.35725 1.96748 8.37306 1.16001C7.38887 0.352528 6.13952 -0.0599993 4.86801 0.00266578C3.59651 0.0653309 2.39377 0.598708 1.49375 1.49905C0.593731 2.39939 0.0607793 3.60231 -0.00143626 4.87384C-0.0636518 6.14536 0.349317 7.39457 1.15714 8.37848C1.96497 9.36238 3.10989 10.0106 4.3692 10.1971C5.62851 10.3836 6.91217 10.095 7.97049 9.3875L10.293 11.7095C10.4821 11.894 10.7358 11.9972 11 11.9972C11.2642 11.9972 11.5179 11.894 11.707 11.7095C11.8945 11.522 11.9998 11.2677 11.9998 11.0025C11.9998 10.7373 11.8945 10.483 11.707 10.2955ZM5.12499 1.5025C5.84195 1.5025 6.54281 1.7151 7.13893 2.11342C7.73506 2.51174 8.19969 3.07789 8.47405 3.74027C8.74842 4.40265 8.82021 5.13152 8.68034 5.8347C8.54047 6.53788 8.19522 7.18379 7.68825 7.69076C7.18129 8.19772 6.53537 8.54297 5.83219 8.68284C5.12901 8.82271 4.40015 8.75093 3.73776 8.47656C3.07538 8.20219 2.50923 7.73757 2.11091 7.14144C1.71259 6.54531 1.49999 5.84445 1.49999 5.1275C1.50105 4.16641 1.88331 3.24499 2.5629 2.5654C3.24249 1.88581 4.16391 1.50356 5.12499 1.5025Z"
				fill="#484848"
			/>
		</svg>
	);
}

function CloseIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="12"
			height="12"
			fill="none"
			viewBox="0 0 12 12"
		>
			<path
				fill="#717171"
				d="M6.862 6.066a.094.094 0 010-.132l3.474-3.474a.563.563 0 10-.797-.795L6.066 5.137a.094.094 0 01-.132 0L2.46 1.665a.562.562 0 10-.795.795l3.472 3.474a.094.094 0 010 .132L1.665 9.54a.562.562 0 10.795.795l3.474-3.473a.094.094 0 01.132 0l3.473 3.473a.562.562 0 10.796-.795L6.862 6.066z"
			></path>
		</svg>
	);
}

interface SearchTagsProps {
	children: string;
	onRemove?: () => void;
}
function SearchTags(props: SearchTagsProps) {
	const { children, onRemove } = props;

	return (
		<span className="z__searchbox-search-tags">
			<Typography
				variant={BODY_VARIANT.BODY_2_REGULAR}
				color={GREY_VARIANT.SECONDARY_GREY_2}
			>
				{children}
			</Typography>
			<img
				className="z__searchbox-search-tags-cross-icon"
				role="button"
				alt="cross icon"
				aria-label="delete"
				onClick={onRemove}
				src={IconClose}
				height={10}
			/>
		</span>
	);
}
SearchTags.displayName = "SearchTags";
export default SearchBox;

function ConditionalTooltip({
	title,
	show,
	children,
}: {
	title: string;
	show?: boolean;
	children: React.ReactNode;
}) {
	if (!show) return children;
	return (
		<Tooltip
			content={(props) => (
				<Typography variant="body_2_regular" color="white">
					{title}
				</Typography>
			)}
		>
			{children}
		</Tooltip>
	);
}
