import React, { useState, useEffect } from "react";
import styled from "styled-components";
import InfoIcon from "assets/v2tables/info-icon.svg";
import PropTypes from "prop-types";
import DataFreshnessModalComponent from "./DataFreshnessModalComponent";
import {
	getDataFreshness,
	getDataCalculatedAt,
} from "../services/dataFreshness.api";
import { timeSince } from "utils/DateUtility";
import { Typography, GREY_VARIANT } from "@zluri/ui-components";

const DataFreshnessModalTextWrapper = styled.div`
	display: flex;
	align-items: center;
	cursor: ${({ $hasCursor }) => ($hasCursor ? "pointer" : "auto")};
`;

const IconWrapper = styled.div`
	margin-left: 4px;
	margin-right: 8px;
	cursor: ${({ $hasCursor }) => ($hasCursor ? "pointer" : "auto")};
`;

const ModalWrapper = styled.div`
	position: relative;
`;

const Backdrop = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(255, 255, 255, 0);
	z-index: 1000;
`;

const DataFreshnessModal = ({
	showText,
	page,
	alignRight,
	showDisclaimer,
	app_id,
	hasModal = true,
	showIcon = true,
	dataCalculatedAt = false,
}) => {
	const [dataFreshnessApiData, setDataFreshnessApiData] = useState(null);
	const [dataError, setDataError] = useState(null);

	const [showModal, setShowModal] = useState(false);

	const dataFreshnessTextClickedHandler = () => {
		setShowModal(true);
	};
	const backdropClickedHandler = () => {
		setShowModal(false);
	};
	const setFreshnessData = async () => {
		try {
			const result = await getDataFreshness({
				page,
				app_id: app_id,
			});
			setDataError(null);
			setDataFreshnessApiData(result);
		} catch (error) {
			setDataError(error);
		}
	};

	const setDataCalculatedAt = async () => {
		try {
			const result = await getDataCalculatedAt({
				page,
			});
			setDataFreshnessApiData(result);
			setDataError(null);
		} catch (error) {
			setDataError(error);
		}
	};

	useEffect(
		() => {
			if (dataCalculatedAt) {
				setDataCalculatedAt();
			} else {
				setFreshnessData();
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	if (!dataFreshnessApiData || !dataFreshnessApiData.view_refreshed_at)
		return null;

	return (
		<>
			{!dataError && (
				<DataFreshnessModalTextWrapper $hasCursor={hasModal}>
					{showText ? (
						<Typography
							color={GREY_VARIANT.SECONDARY_GREY_3}
							onClick={dataFreshnessTextClickedHandler}
							variant="subheading_2_regular"
							style={{ marginTop: "2px" }}
						>
							{dataFreshnessApiData?.view_refreshed_at
								? `Data updated
						${timeSince(new Date(dataFreshnessApiData?.view_refreshed_at), new Date())}
						ago`
								: "Updated at timestamp N/A"}
						</Typography>
					) : null}
					<ModalWrapper>
						<IconWrapper
							onClick={dataFreshnessTextClickedHandler}
							$hasCursor={hasModal}
						>
							{showIcon ? (
								<img src={InfoIcon} alt="info" />
							) : null}
						</IconWrapper>
						{hasModal && showModal ? (
							<>
								<DataFreshnessModalComponent
									alignRight={alignRight}
									sourceList={
										dataFreshnessApiData?.sources || []
									}
									showDisclaimer={showDisclaimer}
								/>
								<Backdrop onClick={backdropClickedHandler} />
							</>
						) : null}
					</ModalWrapper>
				</DataFreshnessModalTextWrapper>
			)}
		</>
	);
};

export default DataFreshnessModal;

DataFreshnessModal.propTypes = {
	showText: PropTypes.bool,
	alignRight: PropTypes.bool,
	page: PropTypes.string,
	showDisclaimer: PropTypes.bool,
	app_id: PropTypes.string,
	hasModal: PropTypes.bool,
	showIcon: PropTypes.bool,
	dataCalculatedAt: PropTypes.bool,
};
