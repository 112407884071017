import React, { useCallback, useEffect, useRef, useState } from "react";
import useTable from "./v2table/useTable.hook";
import { useDispatch } from "react-redux";
import { EmptySearchV2 } from "common/EmptySearchV2";
import { generateHeader } from "./v2table/GenerateHeader";
import Minus from "assets/minus-box-outline.svg";
import Plus from "modules/applications/components/chargebacks/assets/plus.svg";
import OptionsMenu from "./v2table/cellRenderer/OptionsMenu";
import AppliedFilters from "./v2table/AppliedFilters/AppliedFilters";
import { openModal } from "reducers/modal.reducer";
import col from "assets/icons/col.svg";
import QuickFilters from "./v2table/QuickFilters/QuickFilters";
import ViewsV2 from "./v2table/AppliedFilters/Views/ViewsV2";
import {
	Container,
	Button,
	IconButton,
	TOAST_COLOR,
	Tooltip,
	Typography,
	theme as V2_DEFAULT_THEME,
	toast,
} from "@zluri/ui-components";
import { Table } from "v2UIComponents/Table/Table";
import { isUndefined } from "underscore";
import { generateExportReportCSV } from "common/Export/Export.service";
import { TriggerIssue } from "utils/sentry";
import ActionFilters from "./v2table/ActionFilters/ActionFilters";
import cn from "classnames";
import {
	TableContextProvider,
	useTableContext,
} from "containers/v2table/TableContext/context";
import { BulkEditV2 } from "containers/v2table/BulkEdit/BulkEditV2";
import { TOAST_MESSAGE } from "modules/shared/constants/toast.constants";
import { noop } from "underscore";
import ErrorWrapper from "UIComponents/Rbac/ErrorWrapper";
import { csvFileFormat, TableEntityEnum } from "./V2TableConstants";
import SearchControl from "./v2table/SearchControl";
import TableFooter from "./v2table/TableFooter";
import InfiniteScrollLoader from "./v2table/InfiniteScrollLoader";
import PaginationControl from "./v2table/PaginationControl";
import AppliedeSearchRow from "./v2table/SearchControl/AppliedSearchRow";
import FilterControl from "./v2table/FilterControl";
import { RunPlaybookV2 } from "modules/applications/containers/Users/runPlaybookV2";
import { GridRefType, IV2Table } from "./v2table/types";
import SelectionInfoBanner from "./v2table/SelectionInfoBanner";
import NumberOfCheckedButton from "./v2table/NumberOfCheckedButton";
// TODO:
// - Remove intID
// - Merge displayable texts like => emptyMessage, selectAllText,searchPlaceholderText  (experiment going on..)
// - Remove headerContent -> needs a proper replacement
// - convert showCheckboColumn => hideCheckboxColumn or have showCheckboxColumn default as false
// - remove  headerStyles
// - fix saga and the loading state problem while rendering two table
// - make effective use of entityId, possibly should replace intID;
// - fix use cases of forceInfinite

export default function V2Table({
	entity,
	subEntityData,
	intID,
	hidePagination = false,
	hideSearch = false,
	hideColumnModifier,
	hideOptionsMenu,
	forceInfinite,
	headerContent,
	apiProps,
	hideFilters = false,
	showCheckboxColumn = true,
	quickfiltersmockdata,
	paginationEntity,
	emptyMessage,
	searchPlaceholderText,
	exportAllColumns = false,
	disabledUnselectedItems = [],
	exportEntity,
	mandatoryExportFieldId = "app_name",
	scheduleExportName = "Applications Export",
	hideExportAsCSV = false,
	onSelectionChange,
	hideExportCSVForExcel = false,
	hideQuickFilters,
	hideCustomExport = false,
	selectAllText,
	customModifyColumnHeader = null,
	columnMinWidth = 150,
	enablePlaybook = false,

	//@DEPRECATED DONOT USE THIS !!
	// ONLY USED IN APPLICATION ONGOING REVIEW SCREEN!
	hideActionFilters = true,
	virtualizeColumns = true,
	enableViews = false,
	enableRowReOrdering = false,
	renderRowReorderProxy = null,
	onRowReorder,
	enableColumnLevelFilter = false,
	enableBulkEdit = false,
	renderCustomBulkEditConfirmationPopup,
	disableScheduledExport = false,
	renderEmptyState,
	disableSort = false,
	renderAddComponent = noop,
	renderInformationBanner,
	disableUrlSync = false,
	renderDynamicController,
	textMap,
	onCellUpdate,
	enableBulkUpdate = false,
	enableCacheRestore = false,
	entityId,
	renderCustomCheckbox,
}: IV2Table) {
	const [showAppliedFilters, setShowAppliedFilters] = useState(false);

	const {
		columns,
		dataSource,
		count,
		handleOnSort,
		onSort,
		sortInfo,
		loading,
		loadMoreData,
		loadPrevData,
		filterBy: filter_by,
		other_tabs_count,
		page,
		recordsPerPage,
		propertiesColumns,
		isInfinite,
		tableMeta,
		loadNodeOnce,
		isLeafNode,
		isCheckBoxDisabled,
		quick_filter,
		tableData,
		isInSearchState,
		views,
		updateViews,
		isViewsEnabled,
		filterPropertiesObj,
		filterProperties,
		error,
		isInfiniteLoading,
		viewId,
		searchQuery,
	} = useTable({
		entity,
		subEntityData,
		intID,
		apiProps,
		forceInfinite,
		enableViews,
		disableUrlSync,
		enableCacheRestore,
		entityId,
	});
	const [gridRef, setGridRef] = useState<GridRefType>(null);

	// Reset selection of rows on sub entity change
	useEffect(() => {
		if (gridRef?.current) gridRef.current?.deselectAll();
	}, [subEntityData?.entity, gridRef?.current?.deselectAll, gridRef]);

	// Compare prev sort value based on data and unselect if different
	const prevSortInfoRef = useRef();
	useEffect(() => {
		if (!prevSortInfoRef.current) prevSortInfoRef.current = sortInfo;
		if (
			prevSortInfoRef.current &&
			JSON.stringify(prevSortInfoRef.current) !== JSON.stringify(sortInfo)
		) {
			if (gridRef?.current) gridRef.current?.deselectAll();
			prevSortInfoRef.current = sortInfo;
		}
	}, [sortInfo, gridRef]);

	// Compare prev filter value based on data and unselect if different
	const prevFilterInfoRef = useRef();
	useEffect(() => {
		if (!prevFilterInfoRef.current) prevFilterInfoRef.current = filter_by;
		if (
			prevFilterInfoRef.current &&
			JSON.stringify(prevFilterInfoRef.current) !==
				JSON.stringify(filter_by)
		) {
			if (gridRef?.current) gridRef.current?.deselectAll();
			prevFilterInfoRef.current = filter_by;
		}
	}, [filter_by, gridRef]);

	const dispatch = useDispatch();

	type HandleSearchType = {
		keywords?: string | string[];
		clearSearch?: boolean;
	};
	const handleSearch = useCallback(
		({ keywords, clearSearch = false }: HandleSearchType) => {
			if (clearSearch || !isUndefined(keywords)) {
				const searchQuery = clearSearch ? "" : keywords;
				dispatch({
					type: "GET_TABLE_DATA",
					payload: {
						entity,
						subEntityData,
						searchQuery,
						shouldRefresh: true,
						apiProps,
						persistView: true,
					},
				});
			}
		},
		[apiProps, dispatch, entity, subEntityData]
	);

	// TODO: prj: find a better way to handle selection state
	type IExportToCSVProp = {
		isCustom?: boolean;
		selectedRows?: string[];
		fileFormat: string;
	};
	function exportToCSV(prop: IExportToCSVProp) {
		const isCustom = prop?.isCustom ?? false;
		if (isCustom) {
			//TODO: prj: once the logic is verified then reorganize this
			dispatch(
				openModal("exportCSVModalV2Container", {
					selected: exportAllColumns ? propertiesColumns : [],
					disabledUnselectedItems: disabledUnselectedItems.map(
						(e: string) => e.toLowerCase()
					),
					allColumns: propertiesColumns,
					entity: entity,
					subEntity: subEntityData,
					stickyHeader: "application",
					filter_by: filter_by,
					mandatoryFieldId: mandatoryExportFieldId,
					selectedRowData: prop.selectedRows,
					selectedRowDataFieldId: "app_id", // not sure about this
					exportScheduleName: scheduleExportName,
					exportEntity: exportEntity,
					disableScheduledExport,
					hideExportCSVForExcel,
					intID,
				}) as any
			);
		} else {
			const exportRequestObj = {
				filter_by: filter_by ?? [],
				quick_filter: quick_filter ?? [],
				file_type: "csv",
				file_format: prop.fileFormat
					? prop.fileFormat
					: csvFileFormat.GENERAL,
				columns_required: mandatoryExportFieldId
					? [mandatoryExportFieldId]
					: [],
				is_sample: false,
			};

			const renderedColumns = columns
				.map((c) => c?.fieldIds)
				?.filter((a) => a)
				?.flat();
			generateExportReportCSV(
				{
					...exportRequestObj,
					columns_required:
						renderedColumns ?? exportRequestObj.columns_required,
				},
				exportEntity,
				intID ?? ""
			)
				.then((res) => {
					if (res.errors) {
						throw new Error(res.errors);
					} else {
						toast(TOAST_MESSAGE.EXPORT_CSV_SUCCESS, {
							indicatorColor: TOAST_COLOR.SUCCESS,
						});
					}
				})
				.catch((error) => {
					TriggerIssue("Error Exporting to CSV", error);
					toast(TOAST_MESSAGE.EXPORT_CSV_ERROR, {
						indicatorColor: TOAST_COLOR.ERROR,
					});
				});
		}
	}

	function handleOnScroll() {
		if (
			(isInfinite || forceInfinite) &&
			!loading &&
			!isInfiniteLoading &&
			gridRef?.current
		) {
			const scrollTop = gridRef.current?.getScrollTop();
			const gridRowHeight = gridRef.current?.rowHeight ?? 0;
			const rowHeight =
				typeof gridRowHeight === "number" ? gridRowHeight : 0;
			const clientHeight =
				gridRef.current?.getDOMNode()?.clientHeight ?? 0;
			const totalAvailableData = gridRef.current?.count;

			// since the table is virtualized we couldn't get actual scrollHeight from the DOM
			// so this is a tentative scrollheight computed based on total data and estimated row height
			const totalScrollHeight = totalAvailableData * rowHeight;

			const scrollBottom = totalScrollHeight - scrollTop - clientHeight;

			const totalPages = Math.ceil(count / recordsPerPage);
			if (page + 1 < totalPages && scrollBottom < 300) {
				loadMoreData();
			}
		}
	}

	const totalAppliedFilters = filter_by?.filter(
		(row) => !row.hideFilterOnAppliedFiltersSection
	)?.length;

	const showAllAppliedFiltersList =
		showAppliedFilters && !!totalAppliedFilters;

	const dataEntity = subEntityData?.entity ?? entity;

	const canRowReOrder =
		enableRowReOrdering && !isInSearchState && !filter_by?.length;

	const getEmptyState = () => {
		if (renderEmptyState) {
			return renderEmptyState({
				handleReset: () => {
					handleSearch({ clearSearch: true });
				},
			});
		}
		return (
			<EmptySearchV2
				searchQuery={searchQuery}
				metaData={{ other_tabs_count }}
				app_search={true}
				user_search={false}
				onReset={() => {
					handleSearch({ clearSearch: true });
				}}
				emptyMessage={textMap?.emptyMessage ?? emptyMessage}
			/>
		);
	};

	return (
		<TableContextProvider
			data={{
				entity,
				subEntityData,
				intID,
				apiProps,
				dataEntity,
				enableViews,
				enableColumnLevelFilter,
				filterPropertiesObj,
				filterProperties,
				gridRef,
				renderCustomBulkEditConfirmationPopup,
				enableBulkEdit,
				hideQuickFilters,
				totalDataCount: count,
				isInfinite,
				allAvailableColumns: propertiesColumns,
				onSelectionChange,
				disableSort,
				textMap,
				onCellUpdate,
				filterBy: filter_by,
				sortBy: sortInfo,
				columns: columns,
				enableBulkUpdate,
				hideExportCSVForExcel,
				entityId,

				// Intended to be removed
				paginationEntity,
			}}
		>
			<div className="d-flex gap-10 flex-column h-100">
				{error ? (
					<ErrorWrapper error={error} />
				) : (
					<Container className="flex-1 d-flex flex-column gap-10">
						<div className="d-flex gap-10 align-items-center justify-content-between">
							<div
								className={cn(
									"d-flex gap-8 align-items-center",
									headerContent && "flex-1"
								)}
							>
								{headerContent && headerContent}

								{isViewsEnabled && (
									<ViewsV2
										entity={entity}
										subEntityData={subEntityData}
										views={views}
										updateViews={updateViews}
										intID={intID}
										viewId={viewId}
										loading={loading}
										key={dataEntity}
									/>
								)}
								{!hideQuickFilters &&
									!!quickfiltersmockdata && (
										<QuickFilters
											entity={entity}
											appliedFilters={filter_by}
											subEntityData={subEntityData}
											quickfiltersData={
												quickfiltersmockdata
											}
											apiProps={apiProps}
										/>
									)}

								{enableBulkEdit && (
									<BulkEditV2
										gridRef={gridRef}
										entity={entity}
										subEntityData={subEntityData}
										apiProps={apiProps}
									/>
								)}
								{enablePlaybook && (
									<RunPlaybookV2 loading={loading} />
								)}
								{enableBulkUpdate && <BulkUpdateButton />}
								{!hideFilters && (
									<div className={` d-flex`}>
										<FilterControl
											appliedFiltersCount={
												totalAppliedFilters
											}
											showAllAppliedFiltersList={
												showAllAppliedFiltersList
											}
											onShowAppliedFilterClick={() => {
												setShowAppliedFilters((v) =>
													!!totalAppliedFilters
														? !v
														: false
												);
											}}
											loading={loading}
										/>
										{!hideActionFilters && (
											<div className="mr-auto ml-2">
												<ActionFilters
													entity={entity}
													appliedFilters={filter_by}
													subEntityData={
														subEntityData
													}
													apiProps={apiProps}
												/>
											</div>
										)}
									</div>
								)}
							</div>

							<div
								className="d-flex gap-10 align-items-center"
								key={dataEntity}
							>
								{!hideSearch && (
									<SearchControl
										loading={loading}
										searchPlaceholderText={
											textMap?.searchPlaceholder ??
											searchPlaceholderText ??
											`Search ${paginationEntity ?? ""}`
										}
										isInSearchState={isInSearchState}
										handleSearch={handleSearch}
										defaultSearchQuery={
											tableData?.searchQuery
										}
									/>
								)}
								<NumberOfCheckedButton />

								{renderDynamicController &&
									renderDynamicController()}

								{!hidePagination && (
									<PaginationControl
										key={subEntityData?.entity}
										loadMoreData={loadMoreData}
										loadPrevData={loadPrevData}
										page={page}
										recordsPerPage={recordsPerPage}
										loading={loading}
									/>
								)}
								{!hideColumnModifier && (
									<Tooltip
										content={
											<Typography
												variant="body_2_regular"
												color="white"
											>
												Modify Columns
											</Typography>
										}
									>
										<IconButton
											theme={V2_DEFAULT_THEME}
											disabled={loading}
											icon={col}
											onClick={() => {
												dispatch(
													openModal(
														"modifyColumnsModalV2",
														{
															selected:
																columns.map(
																	(c) =>
																		c.headerItem
																),
															allColumns:
																propertiesColumns,
															entity: entity,
															customModifyColumnHeader,
															subEntityData:
																subEntityData,
															stickyHeader:
																"application",
															intID,
															apiProps,
														}
													) as any
												);
											}}
											position="default"
											shape="square"
											variant="white"
										/>
									</Tooltip>
								)}
								{renderAddComponent && renderAddComponent()}
								{!hideOptionsMenu && (
									<OptionsMenu
										loading={loading}
										hideCustomExport={hideCustomExport}
										hideExportAsCSV={hideExportAsCSV}
										hideExportCSVForExcel={
											hideExportCSVForExcel
										}
										onExportClick={exportToCSV}
									/>
								)}
							</div>
						</div>
						{showAllAppliedFiltersList && (
							<AppliedFilters
								entity={entity}
								appliedFilters={filter_by}
								apiProps={apiProps}
								intID={intID}
								subEntityData={subEntityData}
							/>
						)}
						{isInSearchState && (
							<div className="applied__filters__row">
								<AppliedeSearchRow
									handleSearch={handleSearch}
									searchQuery={searchQuery}
									totalResult={count}
								/>
							</div>
						)}
						{renderInformationBanner
							? renderInformationBanner()
							: null}
						<SelectionInfoBanner
							count={count}
							selectAllText={selectAllText}
							gridRef={gridRef}
						/>
						<div
							className="flex-1 flex flex-column h-100 relative"
							style={{ position: "relative" }}
						>
							<div
								style={{
									position: "relative",
									flex: 1,
								}}
							>
								<Table
									gridRef={gridRef}
									onReady={setGridRef}
									columnMinWidth={columnMinWidth ?? 150}
									onSortInfoChange={handleOnSort}
									sortInfo={sortInfo}
									loading={loading}
									handleOnScroll={handleOnScroll}
									isCheckBoxDisabled={isCheckBoxDisabled}
									emptyText={
										dataSource?.length > 0
											? null
											: getEmptyState()
									}
									style={{
										height: "100%",
										overflow: "hidden",
									}}
									livePagination={false}
									columns={columns.map((c) => {
										if (typeof c.header === "string") {
											c.header = generateHeader({
												column: c,
												onSort,
											});
										}
										return c;
									})}
									dataSource={loading ? [] : dataSource}
									hideCheckBox={!showCheckboxColumn}
									virtualizeColumns={virtualizeColumns}
									reorderColumns={false}
									treeColumn={tableMeta?.treeColumn}
									loadNodeOnce={loadNodeOnce}
									isNodeLeaf={(nodeProps) =>
										isLeafNode(nodeProps.node)
									}
									renderCustomCheckbox={renderCustomCheckbox}
									isNodeAsync={() => true}
									renderTreeCollapseTool={({ domProps }) => {
										return (
											<div {...domProps} className="mr-1">
												<img
													src={Plus}
													alt="expand icon"
												/>
											</div>
										);
									}}
									renderTreeExpandTool={({ domProps }) => {
										return (
											<div className="mr-1" {...domProps}>
												<img
													src={Minus}
													alt="collapse icon"
												/>
											</div>
										);
									}}
									{...(canRowReOrder && {
										onRowReorder: (e) => {
											const {
												dragRowIndex,
												insertRowIndex,
											} = e;
											dispatch({
												type: "REORDER_DATA",
												payload: {
													dragRowIndex,
													insertRowIndex,
													entity:
														subEntityData?.entity ??
														entity,
												},
											});
											if (onRowReorder) {
												onRowReorder(e);
											}
										},
										renderRowReorderProxy,
									})}
								/>
							</div>
							<TableFooter
								page={page}
								recordsPerPage={recordsPerPage}
								isInfinite={isInfinite}
								total={count}
							/>
							{/* TODO: prj add animation */}
							{isInfiniteLoading && <InfiniteScrollLoader />}
						</div>
					</Container>
				)}
			</div>
		</TableContextProvider>
	);
}
export { TableEntityEnum };

//!! This is just a dummy implementation for applications main table only.
function BulkUpdateButton() {
	const { isAnyDataSelected, openBulkUpdateSidesheet } = useTableContext();

	if (!isAnyDataSelected) return null;
	return (
		<Button
			variant="primary-alternative"
			theme={V2_DEFAULT_THEME}
			onClick={() => {
				openBulkUpdateSidesheet();
			}}
		>
			Bulk Update
		</Button>
	);
}
