import {
	optimizationLicenseBreakdownBarCharts,
	pastOptimizationActions,
} from "../constants/OptimizationConstants";
import { capitalizeFirstLetter } from "utils/common";

export class OptimizationSummary {
	constructor(obj) {
		this.data = Array.isArray(obj?.data)
			? obj?.data?.map((row) => new OptimizationSummaryRow(row))
			: [];
		this.prev_month_estimated_wastage = obj?.app_estimated_wastage || 0;
		this.next_month_potential_savings = obj?.app_potential_savings || 0;
		this.next_month_realised_savings = obj?.app_realised_savings || 0;
		this.last_synced_at = obj.last_synced_at || null;
		this.app_settings_map = obj.app_settings_map || {};
	}
}

export class OptimizationSummaryRow {
	constructor(obj) {
		this.app_id = obj.app_id || null;
		this.app_name = obj.app_name || "";
		this.app_logo = obj.app_logo || null;
		this.app_state = obj.app_state || null;
		this.total_licenses = obj.app_total_seats_license_qty || 0;
		this.unassigned_licenses = obj.unassigned_licenses || 0;
		this.assigned_licenses = obj.assigned_licenses || 0;
		this.actively_used_licenses = obj.actively_used_licenses || 0;
		this.under_used_licenses = obj.under_used_licenses || 0;
		this.unused_licenses = obj.unused_licenses || 0;
		this.left_org_licenses = obj.left_organisation_licenses || 0;
		this.app_usage = obj.app_usage || 0;
		this.app_cost_per_license = obj.app_avg_cost_per_lic || 0;
		this.estimated_wastage = obj.past_month_wastage || 0;
		this.potential_savings =
			obj.aggregated_monthly_potential_savings ||
			obj.monthly_potential_savings ||
			0;
		this.optimized_licenses = obj.optimized_licenses_count || 0;
		this.realised_savings = obj.monthly_realised_savings || 0;
		this.license_types = obj.app_active_licenses_count || 1;
		this.optimizable_licenses = obj.optimizable_licenses_count || 0;
		this.left_organisation_licenses = obj.left_organisation_licenses || 0;
	}
}

export class OptimizationLicenseSummary {
	constructor(obj) {
		this.data = Array.isArray(obj?.data)
			? obj?.data?.map((row) => new OptimizationSummaryLicenseRow(row))
			: [];
	}
}

export class OptimizationSummaryLicenseRow {
	constructor(obj) {
		this.license_id = obj.license_id || null;
		this.license_name = obj.license_name || "";
		this.total_licenses = obj.app_total_seats_license_qty || 0;
		this.unassigned_licenses = obj.unassigned_licenses || 0;
		this.assigned_licenses = obj.assigned_licenses || 0;
		this.actively_used_licenses = obj.actively_used_licenses || 0;
		this.under_used_licenses = obj.under_used_licenses || 0;
		this.unused_licenses = obj.unused_licenses || 0;
		this.left_org_licenses = obj.left_organisation_licenses || 0;
		this.app_cost_per_license = obj.cost_per_license || 0;
		this.estimated_wastage = obj.past_month_wastage || 0;
		this.potential_savings =
			obj.aggregated_monthly_potential_savings ||
			obj.monthly_potential_savings ||
			0;
		this.optimized_licenses = obj.optimized_licenses_count || 0;
		this.realised_savings = obj.monthly_realised_savings || 0;
		this.optimizable_licenses = obj.optimizable_licenses_count || 0;
		this.left_organisation_licenses = obj.left_organisation_licenses || 0;
	}
}

export class OptimizationV2TopRow {
	constructor(obj) {
		this.optimizable_license_count = obj.optimizable_licenses_count || 0;
		this.optimizable_license_count_change =
			obj.optimizable_license_count_change || 0;
		this.wastage = obj.past_month_wastage || 0;
		this.wastage_change = obj.wastage_change || 0;
		this.potential_savings = obj.monthly_potential_savings || 0;
		this.potential_savings_change = obj.potential_savings_change || 0;
		this.realised_savings = obj.monthly_realised_savings || 0;
		this.realised_savings_change = obj.realised_savings_change || 0;
	}
}

export class OptimizationV2Settings {
	constructor(obj) {
		this.unused_licenses_settings = new OptimizationV2UnusedSettings(
			obj.unused_optimization_settings || {}
		);
		this.under_used_licenses_settings = new OptimizationV2UnderUsedSettings(
			obj.underused_optimization_settings || {}
		);
		this.left_org_licenses_settings = new OptimizationV2LeftOrgSettings(
			obj.left_org_optimization_settings || {}
		);
	}
}

export class OptimizationV2UnusedSettings {
	constructor(obj) {
		this.threshold_value = obj.threshold_value || 30;
		this.discovery_date_exclusion_days =
			obj.discovery_date_exclusion_days ?? 30;
		this.continuous_optimization_enabled = !!(
			obj.repeat_interval && obj.repeat_interval !== "never"
		);
		// this.optimization_action = obj.optimization_action || null;
		this.playbook = obj.playbook?._id
			? new OptimizationV2SettingsPlaybook(obj.playbook || {}) || null
			: null;
		this.repeat_interval = obj.repeat_interval || "never";
		this.repeat_week_day = obj.repeat_week_day || null;
		this.repeat_month = obj.repeat_month || null;
		this.repeat_date = obj.repeat_date || null;
		this.slack_notification = obj.slack_notification || false;
		this.email_notification = obj.email_notification || false;
		this.licenses_included = obj.licenses_included || [];
		this.account_types_included = obj.account_types_included || [];
		this.repeat_job_key = obj.repeat_job_key || null;
	}
}

export class OptimizationV2UnderUsedSettings {
	constructor(obj) {
		this.threshold_value = obj.threshold_value || 30;
		this.continuous_optimization_enabled = !!(
			obj.repeat_interval && obj.repeat_interval !== "never"
		);
		// this.optimization_action = obj.optimization_action || null;
		this.playbook = obj.playbook?._id
			? new OptimizationV2SettingsPlaybook(obj.playbook || {}) || null
			: null;
		this.repeat_interval = obj.repeat_interval || "never";
		this.repeat_week_day = obj.repeat_week_day || null;
		this.repeat_month = obj.repeat_month || null;
		this.repeat_date = obj.repeat_date || null;
		this.slack_notification = obj.slack_notification || false;
		this.email_notification = obj.email_notification || false;
		this.licenses_included = obj.licenses_included || [];
		this.account_types_included = obj.account_types_included || [];
		this.repeat_job_key = obj.repeat_job_key || null;
	}
}

export class OptimizationV2LeftOrgSettings {
	constructor(obj) {
		this.continuous_optimization_enabled = !!(
			obj.repeat_interval && obj.repeat_interval !== "never"
		);
		// this.optimization_action = obj.optimization_action || null;
		this.playbook = obj.playbook?._id
			? new OptimizationV2SettingsPlaybook(obj.playbook || {}) || null
			: null;
		this.repeat_interval = obj.repeat_interval || "never";
		this.repeat_week_day = obj.repeat_week_day || null;
		this.repeat_month = obj.repeat_month || null;
		this.repeat_date = obj.repeat_date || null;
		this.slack_notification = obj.slack_notification || false;
		this.email_notification = obj.email_notification || false;
		this.licenses_included = obj.licenses_included || [];
		this.account_types_included = obj.account_types_included || [];
		this.repeat_job_key = obj.repeat_job_key || null;
	}
}

export class OptimizationV2PostSettings {
	constructor(obj) {
		this.unused_optimization_settings =
			new OptimizationV2PostUnusedSettings(
				obj.unused_licenses_settings || {}
			);
		this.underused_optimization_settings =
			new OptimizationV2PostUnderUsedSettings(
				obj.under_used_licenses_settings || {}
			);
		this.left_org_optimization_settings =
			new OptimizationV2PostLeftOrgSettings(
				obj.left_org_licenses_settings || {}
			);
	}
}

export class OptimizationV2PostUnusedSettings {
	constructor(obj) {
		this.threshold_value = obj.threshold_value || 30;
		this.discovery_date_exclusion_days =
			obj.discovery_date_exclusion_days ?? 30;
		this.continuous_optimization_enabled = !!(
			obj.repeat_interval && obj.repeat_interval !== "never"
		);
		// this.optimization_action = obj.optimization_action || null;
		this.playbook = obj.playbook?._id || null;
		this.repeat_interval = obj.repeat_interval || "never";
		this.repeat_week_day = obj.repeat_week_day || null;
		this.repeat_month = obj.repeat_month || null;
		this.repeat_date = obj.repeat_date || null;
		this.slack_notification = obj.slack_notification || false;
		this.email_notification = obj.email_notification || false;
		this.licenses_included = obj.licenses_included || [];
		this.account_types_included = obj.account_types_included || [];
		this.repeat_job_key = obj.repeat_job_key || null;
	}
}

export class OptimizationV2PostUnderUsedSettings {
	constructor(obj) {
		this.threshold_value = obj.threshold_value || 30;
		this.continuous_optimization_enabled = !!(
			obj.repeat_interval && obj.repeat_interval !== "never"
		);
		// this.optimization_action = obj.optimization_action || null;
		this.playbook = obj.playbook?._id || null;
		this.repeat_interval = obj.repeat_interval || "never";
		this.repeat_week_day = obj.repeat_week_day || null;
		this.repeat_month = obj.repeat_month || null;
		this.repeat_date = obj.repeat_date || null;
		this.slack_notification = obj.slack_notification || false;
		this.email_notification = obj.email_notification || false;
		this.licenses_included = obj.licenses_included || [];
		this.account_types_included = obj.account_types_included || [];
		this.repeat_job_key = obj.repeat_job_key || null;
	}
}

export class OptimizationV2PostLeftOrgSettings {
	constructor(obj) {
		this.continuous_optimization_enabled = !!(
			obj.repeat_interval && obj.repeat_interval !== "never"
		);
		// this.optimization_action = obj.optimization_action || null;
		this.playbook = obj.playbook?._id || null;
		this.repeat_interval = obj.repeat_interval || "never";
		this.repeat_week_day = obj.repeat_week_day || null;
		this.repeat_month = obj.repeat_month || null;
		this.repeat_date = obj.repeat_date || null;
		this.slack_notification = obj.slack_notification || false;
		this.email_notification = obj.email_notification || false;
		this.licenses_included = obj.licenses_included || [];
		this.account_types_included = obj.account_types_included || [];
		this.repeat_job_key = obj.repeat_job_key || null;
	}
}

export class OptimizationV2SettingsPlaybook {
	constructor(obj) {
		this._id = obj.workflow_id || obj.template_id || obj._id || null;
		this.name = obj.workflow_name || obj.template_name || obj.name || null;
	}
}

export class OptimizationV2LicenseList {
	constructor(obj) {
		this.licenses = Array.isArray(obj.licenses)
			? obj.licenses.map((lic) => new OptimizationV2License(lic))
			: [];
	}
}

export class OptimizationV2License {
	constructor(obj) {
		this.license_id = obj._id || obj.license_id || null;
		this.license_name = obj.name || obj.license_name || "";
	}
}

export class OptimizationV2BarChartSection {
	constructor(obj) {
		for (const key of Object.keys(optimizationLicenseBreakdownBarCharts)) {
			this[key] = new OptimizationV2LicenseBreakdown({
				...optimizationLicenseBreakdownBarCharts[key],
				...(obj || {}),
			});
		}
	}
}

export class OptimizationV2LicenseBreakdown {
	constructor(obj) {
		this.bar_chart_data = Array.isArray(obj[obj.barChartKey])
			? obj[obj.barChartKey].map(
					(row) =>
						new OptimizationV2LicenseBreakdownBarChartRow(row || {})
				)
			: [];
		this.license_list_data = Array.isArray(obj[obj.licenseListKey])
			? obj[obj.licenseListKey].map(
					(row) =>
						new OptimizationV2LicenseBreakdownLicenseListRow({
							...(row || {}),
							countKey: obj.licenseListCountKey,
						})
				)
			: [];
		this.total_wastage = obj[obj.wastageKey] || 0;
		this.optimizable_licenses = obj[obj.optimizableLicenseCountKey] || 0;
		this.left_organisation_licenses =
			obj[obj.left_organisation_licenses] || 0;
	}
}

export class OptimizationV2LicenseBreakdownBarChartRow {
	constructor(obj) {
		this.label = obj.k || null;
		this.value = obj.v || 0;
	}
}

export class OptimizationV2LicenseBreakdownLicenseListRow {
	constructor(obj) {
		this.id = obj.license_id || "";
		this.name = obj.license_name || "";
		this.contractId = obj.contract_id || "";
		this.contractName = obj.contract_name || "";
		this.contractType = obj.contract_type || "";
		this.quantity = obj[obj.countKey] || 0;
		this.wastage = obj.wastage || 0;
	}
}

export class PastOptimization {
	constructor(obj) {
		this.total_realised_savings = obj.total_realised_savings;
		this.list = Array.isArray(obj.past_optimizations)
			? obj.past_optimizations.map(
					(optimization) => new PastOptimizationRow(optimization)
				)
			: [];
	}
}

export class PastOptimizationRow {
	constructor(obj) {
		this.run_on = obj.timestamp || null;
		this.type = obj.type === "manual" ? "one-time" : "continuous"; // [one-time, continuous]
		this.licenses_optimized = obj.optimized_licenses_count || 0;
		this.users_optimized = obj.optimized_users_count || 0;
		this.realised_savings = obj.realised_savings || 0;
		this.workflow_id = obj.workflow_id;
		// New fields for optimization logs
		this.licenses_run_on = obj.optimized_licenses_count || 0;
		this.users_run_on = obj.optimized_users_count || 0;
		this.pending = obj.pendingCount || 0;
		this.completed = obj.successCount || 0;
		this.error = obj.faliureCount || 0;
		this.log_id = obj.log_id || null;
		this.did_not_run = obj.did_not_run_count || 0;
		this.unique_user_count = obj.unique_user_count || 0;
		this.users_run_on_total_count = obj.users_run_on_total_count || 0;
	}
}

export class OptimizeNowUsersList {
	constructor(obj) {
		return Array.isArray(obj.users)
			? obj.users.map((user) => new OptimizeNowUser(user))
			: [];
	}
}

export class OptimizeNowUser {
	constructor(obj) {
		this._id = `${obj.user?._id || obj._id || obj.user_id || null}_${
			obj.license?._id || null
		}_${obj.workflow_details?.template_id || null}`;
		this.user_id = obj.user?._id || obj._id || obj.user_id || null;
		this.name = obj.user?.name || obj.name || obj.user_name || null;
		this.profile =
			obj.user?.profile_img ||
			obj.profile_img ||
			obj.user_profile ||
			null;
		this.license_id = obj.license?._id || null;
		this.license_name = obj.license?.license_name || null;
		this.cost_per_license = obj.license?.cost_per_license || {};
		this.role = obj.user?.role || obj.role || null;
		this.optimization_type = obj.license_utilization?.reason || "-";
		this.wastage = obj.license_utilization?.past_month_wastage || 0;
		this.action = obj.action || "remove_license";
		this.playbook =
			typeof obj.workflow_details === "object"
				? new OptimizationV2SettingsPlaybook(obj.workflow_details || {})
				: {};
		this.date_since_optimizable =
			obj.license_utilization?.date_since_optimisable || null;
		this.is_optimizable = obj.license_utilization?.is_optimisable || false;
		this.user_app_license_id = obj.user_app_license_id || null;
		this.playbook_id = obj.workflow_details?.template_id || null;
		this.user_status = obj.user?.user_status || null;
		this.user_app_last_used = obj.user?.user_app_last_used || null;
		this.user_account_type = obj.user?.user_account_type || "";
	}
}

export class OptimizeNowMeta {
	constructor(obj) {
		this.unused = {
			label: "Unused",
			license_count: obj.unused_optimizable_licenses_count || 0,
			potential_savings: obj.unused_potential_savings || 0,
		};
		this.under_used = {
			label: "Underused",
			license_count: obj.underused_optimizable_licenses_count || 0,
			potential_savings: obj.underused_potential_savings || 0,
		};
		this.left_org = {
			label: "Undeprovisioned",
			license_count: obj.leftorg_optimizable_licenses_count || 0,
			potential_savings: obj.leftorg_potential_savings || 0,
		};
		this.total_potential_savings = obj.total_potential_savings || 0;
		this.users_affected = obj.users_affected || 0;
		this.users_excluded = obj.users_excluded || 0;
		this.types_of_licenses = obj.types_of_licenses || 0;
	}
}

export class PastOptimizationUsersList {
	constructor(obj) {
		return Array.isArray(obj.users)
			? obj.users.map((user) => new PastOptimizationUser(user))
			: [];
	}
}

export class PastOptimizationUser {
	constructor(obj) {
		this._id = `${obj.user?._id || obj._id || obj.user_id || null}_${
			obj.license?._id || null
		}_${obj.workflow_details?.template_id || null}`;
		this.user_id =
			obj.user?._id ||
			obj._id ||
			obj.user_id ||
			obj.user?.user_id ||
			null;
		this.name = obj.user?.name || obj.name || obj.user_name || null;
		this.profile =
			obj.user?.profile_img ||
			obj.profile_img ||
			obj.user_profile ||
			null;
		this.license_id = obj.license?._id || null;
		this.license_name = obj.license?.license_name || null;
		this.cost_per_license = obj.license?.cost_per_license || {};
		this.role = obj.user?.role || obj.role || null;
		this.optimization_type = obj.license_utilization?.reason || "-";
		this.realised_savings =
			obj.license_utilization?.annualized_realized_savings || 0;
		this.action =
			pastOptimizationActions[obj.workflow_details.action_name] || "-";
		this.playbook =
			typeof obj.workflow_details === "object"
				? new OptimizationV2SettingsPlaybook(obj.workflow_details || {})
				: {};
		// New fields for optimization logs
		this.email = obj.user?.email || "-";
		this.account_type = obj.user?.account_type || "-";
		this.playbook_name = obj.workflow_details?.template_name || "-";
		this.playbook_status =
			capitalizeFirstLetter(obj.workflow_details?.status) || "-";
		this.user_archive = obj.user?.user_archive || false;
	}
}

export class PastOptimizationMeta {
	constructor(obj) {
		this.unused = {
			label: "Unused",
			license_count: obj.unused_licenses_count || 0,
			realised_savings: obj.unused_realised_saving || 0,
		};
		this.under_used = {
			label: "Underused",
			license_count: obj.underused_licenses_count || 0,
			realised_savings: obj.underused_realised_saving || 0,
		};
		this.left_org = {
			label: "Undeprovisioned",
			license_count: obj.undeprovisioned_licenses_count || 0,
			realised_savings: obj.undeprovisioned_realised_saving || 0,
		};
		this.total_realised_savings = obj.total_realized_savings || 0;
		this.status = obj.status || 0;
		this.users_affected = obj.users_affected || 0;
		this.types_of_licenses = obj.types_of_licenses || 0;
	}
}

export class RealizedSaving {
	constructor(obj) {
		this.userCount = obj.userCount;
		this.date = obj.date;
		this.total = obj.total;
		this.licenseCount = obj.licenseCount;
		this.userAppLicensesCount = obj.userAppLicensesCount;
	}
}
export class RealizedSavings {
	constructor(obj) {
		this.total_realised_savings = obj.total_realised_savings;
		this.list = Array.isArray(obj)
			? obj.map((val) => new RealizedSaving(val))
			: [];
	}
}

export class RealizedSavingUser {
	constructor(obj) {
		this.user_name = obj.user_name;
		this.license_id = obj.license_id;
		this.user_id = obj.user_id;
		this.realised_savings = obj.realised_savings;
		this.user_account_type = obj.user_account_type;
		this.user_email = obj.user_email;
		this.license_name = obj.license_name;
		this.date_since_optimizable = obj.date_since_optimisable || null;
		this.optimization_reason = obj.reason;
		this.user_archive = obj.user_archive || false;
		this.org_app_id = obj.org_app_id;
		this.end_date = obj.end_date;
	}
}
export class RealizedSavingUsersListModel {
	constructor(obj) {
		return Array.isArray(obj.users)
			? obj.users.map((user) => new RealizedSavingUser(user))
			: [];
	}
}
