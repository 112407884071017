import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import ReviewContractMiscDetails from "./ContractStepsComponents/ReviewContractMiscDetails";
import ReviewLicenseDetails from "./ContractStepsComponents/ReviewLicenseDetails";
import ReviewSetupDiscountTotal from "./ContractStepsComponents/ReviewSetupDiscountTotal";
import { Button } from "../../../../UIComponents/Button/Button";
import { Spinner } from "react-bootstrap";
import { screenEntity } from "modules/licenses/constants/LicenseConstants";
import { setInititalStepperState } from "common/Stepper/redux";
import { clearAllV2DataCache } from "modules/v2InfiniteTable/redux/v2infinite-action";
import { capitalizeFirstLetter } from "utils/common";
import { toast } from "react-toastify";
import DefaultNotificationCard from "common/Notification/PusherNotificationCards/DefaultNotificationCard";
import {
	getAddContractReqBody,
	getEditContractReqBody,
	getFormattedErrorMessage,
} from "modules/licenses/utils/LicensesUtils";
import { clearSpendCostGraphData } from "modules/spendvscost/redux/spendvscost_action";
import { clearAllV2DataCache as clearPaginatedTableData } from "modules/v2PaginatedTable/redux/v2paginated-action";
import { ApiResponseNotification } from "modules/shared/components/ApiResponseNotification/ApiResponseNotification";
import { apiResponseTypes } from "modules/shared/components/ApiResponseNotification/ApiResponseNotificationConstants";
import ContractSaveAsDraftButton from "./ContractStepsComponents/ContractSaveAsDraftButton";
import { useGetSegmentFromURL } from "@zluri/ui-components";

export default function Review({ entity, saveData, processBulkUploadId }) {
	let history = useHistory();
	const location = useLocation();
	const dispatch = useDispatch();
	const [drafting, setDrafting] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const [submitFailed, setSubmitFailed] = useState(false);
	const { data } = useSelector((state) => state.stepper);
	const contractId = useGetSegmentFromURL(3);

	const saveDataCall = () => {
		setSubmitting(true);
		const requestPayload = window.location.pathname.includes("edit")
			? getEditContractReqBody(data, entity)
			: getAddContractReqBody(data, entity);
		saveData &&
			saveData(requestPayload, contractId)
				.then((res) => {
					if (res.status === "success" && res.id) {
						dispatch(setInititalStepperState());
						dispatch(clearAllV2DataCache("licenses"));
						dispatch(clearAllV2DataCache(`${entity}s`));
						if (requestPayload.bulk_upload_id) {
							dispatch(
								clearPaginatedTableData("contract_uploads")
							);
						}
						dispatch(clearSpendCostGraphData());
						toast(
							<DefaultNotificationCard
								notification={{
									title: ` ${capitalizeFirstLetter(
										entity
									)} has been successfully ${
										window.location.pathname.includes(
											"edit"
										)
											? "edited"
											: "added"
									}`,
									description: `The renewals and costs might take some time to populate.`,
								}}
							/>
						);
						history.push(
							`/licenses/${
								entity !== screenEntity.PERPETUAL
									? entity
									: "perpetual"
							}s/${res.id}#overview`
						);
						setSubmitting(false);
					} else {
						ApiResponseNotification({
							responseType: apiResponseTypes.ERROR,
							errorObj: res,
							description: getFormattedErrorMessage(res),
							title: `Error while ${
								window.location.pathname.includes("edit")
									? "editing"
									: "adding"
							} ${entity}`,
							retry: saveDataCall,
						});
						setSubmitFailed(true);
						setSubmitting(false);
					}
				})
				.catch((error) => {
					ApiResponseNotification({
						responseType: apiResponseTypes.ERROR,
						description: getFormattedErrorMessage(error),
						errorObj: error,
						title: `Error while ${
							window.location.pathname.includes("edit")
								? "editing"
								: "adding"
						} ${entity}`,
						retry: saveDataCall,
					});
					setSubmitFailed(true);
					setSubmitting(false);
				});
	};

	return (
		<>
			<ReviewContractMiscDetails data={data} entity={entity} />
			<ReviewLicenseDetails data={data} entity={entity} />
			<ReviewSetupDiscountTotal data={data} entity={entity} />
			<hr style={{ margin: "17px 0px" }} />
			<div className="d-flex" style={{ gap: "12px" }}>
				{data.bulk_upload_id &&
					!window.location.pathname.includes("edit") && (
						<>
							<ContractSaveAsDraftButton
								draft={data}
								drafting={drafting}
								setDrafting={setDrafting}
								disabled={submitting || drafting}
								bulkUploadId={data.bulk_upload_id}
								processBulkUploadId={processBulkUploadId}
							/>
						</>
					)}
				<Button
					onClick={saveDataCall}
					style={{ width: "100%", height: "38px" }}
					className="text-capitalize d-flex align-items-center justify-content-center"
					disabled={submitting || drafting}
				>
					{submitting ? (
						<Spinner animation="border" />
					) : (
						<>
							{`${
								window.location.pathname.includes("edit")
									? "Save Changes"
									: `Add ${
											entity === screenEntity.PERPETUAL
												? "Perpetual Contract"
												: entity
									  }`
							}`}
						</>
					)}
				</Button>
			</div>
		</>
	);
}
