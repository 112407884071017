import {
	Actions,
	StatesForAddApplicationModal,
	StatesForCreateReviewPage,
	ViewType,
	ViewsInAddApplicationModal,
	ViewsInCreateReviewPage,
	createReviewActions,
	progressBar,
	progressBarInModal,
	defaultAppState,
	completeSetupObj,
	reviewDetailsForm,
	ACTION_TYPE,
	reviewStringMapper,
	defaultLevelState,
	MAX_NUMBER_OF_LEVELS,
	PRIMARY_REVIEWER,
	FALLBACK_REVIEWER,
} from "../constants/constants";

const initialState = {
	isLoading: false,
	showAddApplicationModal: false,
	isEdit: false,
	ViewsInCreateReviewPage: ViewsInCreateReviewPage.CurrentView,
	ViewsInAddApplicationModal: ViewsInAddApplicationModal.CurrentView,
	progressBar: JSON.parse(progressBar),
	progressBarInModal: JSON.parse(progressBarInModal),
	defaultUsersAndRoles: {},
	completeSetupObj: completeSetupObj,
	applicationReview: defaultAppState,
	reviewDetailsForm: reviewDetailsForm,
	applications: [],
	review: {},
	ongoingReviews: {},
	upcomingReviews: {},
	completedReviews: {},
	actionFilters: [],
	cloneCertification: {},
	selectedApp: {},
	initialLevels: [defaultLevelState],
	initialLevelsExist: false,
};

export function accessReviewReducer(state = initialState, action) {
	switch (action.type) {
		case ACTION_TYPE.GET_REVIEWS:
			const reviewType = reviewStringMapper[action.payload?.reviewType];
			if (!reviewType) return state;
			const reviewKey = state?.[reviewType];
			return {
				...state,
				[reviewType]: {
					...reviewKey,
					...action?.payload,
					count:
						action?.payload?.data?.length ||
						state?.[reviewType]?.data?.length,
				},
			};
		case createReviewActions.UPDATE_REVIEW_DETAILS_FORM:
			return {
				...state,

				reviewDetailsForm: {
					...action.payload.data,
				},
			};

		case createReviewActions.UPDATE_PROGRESS_BAR:
			const step = action.payload.step;
			const status = action.payload.status;
			const progressBarType =
				action.payload.viewType === ViewType.ViewsInCreateReviewPage
					? "progressBar"
					: "progressBarInModal";
			const viewType =
				action.payload.viewType === ViewType.ViewsInCreateReviewPage
					? state.progressBar
					: state.progressBarInModal;

			let newSidebar = JSON.parse(JSON.stringify(viewType));
			newSidebar[step].STATUS = status;

			return { ...state, [progressBarType]: newSidebar };

		case createReviewActions.MAKE_CURRENT_STEP_ACTIVE:
			const view = action.payload.currentView;

			const progressBarObj = state.progressBar.slice().map((step) => {
				return { ...step, ACTIVE: false };
			});
			progressBarObj[view].ACTIVE = true;

			state = { ...state, progressBar: progressBarObj };
			return state;

		case createReviewActions.CHANGE_VIEW:
			const currentView = action.payload.currentView;
			const actionType = action.payload.actionType;
			const viewName =
				action.payload.viewType === ViewType.ViewsInCreateReviewPage
					? ViewType.ViewsInCreateReviewPage
					: ViewType.ViewsInAddApplicationModal;

			const stateMachine =
				viewName === ViewType.ViewsInCreateReviewPage
					? StatesForCreateReviewPage
					: StatesForAddApplicationModal;

			if (actionType === Actions.NEXT) {
				const nextView = stateMachine[currentView.NEXT];
				if (nextView) {
					state = { ...state, [viewName]: nextView };
				}
			} else if (actionType === Actions.PREV) {
				const prevView = stateMachine[currentView.PREV];
				if (prevView) {
					state = { ...state, [viewName]: prevView };
				}
			}
			return state;
		case createReviewActions.UPDATE_SELECTED_APPLICATION:
			state = {
				...state,
				applicationReview: {
					...state.applicationReview,
					application: action.payload.data,
					appId: action.payload.appId,
					levels:
						state.applicationReview?.levels?.length > 0
							? state.applicationReview?.levels
							: state.initialLevels,
				},
			};
			return state;

		case createReviewActions.TOGGLE_LOADER:
			return { ...state, isLoading: action?.payload?.loading };

		case createReviewActions.TOGGLE_ADD_APPLICATION_MODAL:
			return {
				...state,
				showAddApplicationModal: action.payload.data,
			};
		case createReviewActions.FETCH_DEFAULT_USERS_AND_ROLES:
			state = {
				...state,
				defaultUsersAndRoles: {
					...state.defaultUsersAndRoles,
					...action?.payload,
				},
			};
			return state;
		case createReviewActions.UPDATE_COMPLETE_REVIEW_SETUP:
			state = {
				...state,
				completeSetupObj: action.payload.data,
			};
			return state;

		case createReviewActions.EDIT_APPLICATION:
			const appId = action.payload.appId;
			const applicationsCopy = state.applications.slice();
			const indexOfApp = applicationsCopy?.findIndex((app) => {
				return app?.appId === appId;
			});
			let app = {};
			if (indexOfApp > -1) {
				app = applicationsCopy[indexOfApp];
				state = {
					...state,
					applicationReview: {
						...app,
					},
				};
			}
			return state;
		case createReviewActions.ADD_APPLICATION:
			const isEditing = action.payload.isEditing;
			if (!isEditing) {
				const data = {
					...action.payload.data,
					dynamic_records: false,
				};
				const applicationsForReviewObj = state.applications.slice();
				applicationsForReviewObj.push(data);
				state = {
					...state,
					applications: applicationsForReviewObj,
					applicationReview: { ...defaultAppState },
					showAddApplicationModal: false,
					isEdit: false,
					ViewsInAddApplicationModal:
						ViewsInAddApplicationModal.CurrentView,
					progressBarInModal: JSON.parse(progressBarInModal),
				};
			} else {
				const appIdx = state.applications?.findIndex((app) => {
					return app?.appId === action?.payload?.data?.appId;
				});

				if (appIdx > -1) {
					const applicationsCp = state.applications.slice();
					applicationsCp[appIdx] = {
						...action.payload.data,
						dynamic_records: false,
					};
					state = {
						...state,
						applicationReview: {
							...defaultAppState,
						},
						ViewsInAddApplicationModal:
							ViewsInAddApplicationModal.CurrentView,
						applications: applicationsCp,
						isEdit: false,
						showAddApplicationModal: false,
						progressBarInModal: JSON.parse(progressBarInModal),
					};
				}
			}
			return state;

		case createReviewActions.UPDATE_ACTIONS_DATA:
			const applicationReviewObj = { ...state.applicationReview };
			applicationReviewObj["review_actions"] = { ...action.payload.data };
			state = {
				...state,
				applicationReview: applicationReviewObj,
			};
			return state;

		case createReviewActions.CLEAR_ALL_REVIEW_DETAILS:
			return {
				...initialState,
			};

		case createReviewActions.DELETE_APPLICATION:
			const id = action?.payload?.appId;
			const applicationsObj = state.applications.slice();
			const index = applicationsObj?.findIndex((app) => {
				return app?.appId === id;
			});
			if (index > -1) {
				applicationsObj.splice(index, 1);
			}
			state = { ...state, applications: applicationsObj };
			return state;

		case createReviewActions.IS_EDITING_APP:
			const val = action.payload.data;
			state = { ...state, isEdit: val };
			return state;

		case createReviewActions.CLEAR_MODAL_DATA:
			state = {
				...state,
				applicationReview: defaultAppState,
				ViewsInAddApplicationModal:
					ViewsInAddApplicationModal.CurrentView,
				progressBarInModal: JSON.parse(progressBarInModal),
			};
			return state;

		case createReviewActions.EMPTY_APPLICATIONS_ARRAY:
			state = {
				...state,
				applications: [],
			};
			return state;

		case createReviewActions.SET_REVIEW_DATA:
			return Object.assign({}, state, {
				review: { ...state.review, ...action.payload },
			});

		case createReviewActions.UPDATING_COLUMNS:
			if (state.applications?.length > 0) {
				if (state.applicationReview?.columns) {
					state = {
						...state,
						applicationReview: {
							...state.applicationReview,
							columns: action.payload.data,
						},
					};
				} else {
					state = {
						...state,
						applicationReview: {
							...state.applicationReview,
							columns: state.applications[0]?.columns,
						},
					};
				}
			} else {
				state = {
					...state,
					applicationReview: {
						...state.applicationReview,
						columns: action.payload.data,
					},
				};
			}
			return state;
		case createReviewActions.UPDATING_FILTER_BY:
			state = {
				...state,
				applicationReview: {
					...state.applicationReview,
					filter_by: action.payload.data,
					selected_user_count: action.payload.count,
				},
			};
			return state;
		case ACTION_TYPE.GET_ACTION_FILTERS:
			state = {
				...state,
				actionFilters: {
					...state.actionFilters,
					loading: true,
				},
			};
			return state;
		case ACTION_TYPE.FETCHED_ACTION_FILTERS:
			const data = action.payload.data;
			state = {
				...state,
				actionFilters: {
					...state.actionFilters,
					data: data,
					loading: false,
				},
			};
			return state;
		case ACTION_TYPE.CLEAR_ACTION_FILTERS:
			state = {
				...state,
				actionFilters: {},
			};
			return state;
		case ACTION_TYPE.CLONE_AND_EDIT_CERT_DATA:
			state = {
				...state,
				...action.payload?.data,
			};
			return state;
		case ACTION_TYPE.FETCHING_CERT_DATA:
			state = {
				...state,
				cloneCertification: {
					...state.cloneCertification,
					...action?.payload,
				},
			};
			return state;
		case createReviewActions.ADD_INITIAL_LEVEL:
			var initialLevelsCopy = state.initialLevels.slice();
			if (initialLevelsCopy?.length === MAX_NUMBER_OF_LEVELS)
				return state;

			initialLevelsCopy.push({ ...defaultLevelState, key: Date.now() });
			return {
				...state,
				initialLevels: initialLevelsCopy,
			};

		case createReviewActions.REMOVE_INITIAL_LEVEL:
			if (state?.initialLevels?.length === 1) return state;

			initialLevelsCopy = state.initialLevels.slice();

			const level_idx = initialLevelsCopy?.findIndex((level) => {
				return level?.key === action?.payload?.key;
			});

			if (level_idx > -1) {
				initialLevelsCopy.splice(level_idx, 1);
			}

			return {
				...state,
				initialLevels: initialLevelsCopy,
			};
		case createReviewActions.SET_INITIAL_LEVEL_DATA:
			var reviewerData = action?.payload?.reviewerData;
			var additionalInfo = action?.payload?.additionalInfo;
			var reviewerType = additionalInfo.reviewerType;
			initialLevelsCopy = state.initialLevels.slice();

			const indexOfLevel = initialLevelsCopy?.findIndex((level) => {
				return level?.key === additionalInfo?.key;
			});
			if (indexOfLevel > -1) {
				initialLevelsCopy[indexOfLevel] = {
					...initialLevelsCopy[indexOfLevel],
					[reviewerType === PRIMARY_REVIEWER
						? PRIMARY_REVIEWER
						: FALLBACK_REVIEWER]: reviewerData,
				};
			}

			return {
				...state,
				initialLevels: initialLevelsCopy,
			};

		case createReviewActions.ADD_LEVEL:
			initialLevelsCopy = state.applicationReview.levels.slice();
			if (initialLevelsCopy?.length === MAX_NUMBER_OF_LEVELS)
				return state;

			initialLevelsCopy.push({ ...defaultLevelState, key: Date.now() });
			return {
				...state,
				applicationReview: {
					...state.applicationReview,
					levels: initialLevelsCopy,
				},
			};
		case createReviewActions.REMOVE_LEVEL:
			if (state?.applicationReview?.levels?.length === 1) return state;

			initialLevelsCopy = state?.applicationReview?.levels.slice();

			const idx = initialLevelsCopy?.findIndex((level) => {
				return level?.key === action?.payload?.key;
			});

			if (idx > -1) {
				initialLevelsCopy.splice(idx, 1);
			}

			return {
				...state,
				applicationReview: {
					...state.applicationReview,
					levels: initialLevelsCopy,
				},
			};
		case createReviewActions.SET_LEVEL_DATA:
			reviewerData = action?.payload?.reviewerData;
			additionalInfo = action?.payload?.additionalInfo;
			reviewerType = additionalInfo?.reviewerType;
			initialLevelsCopy = state.applicationReview?.levels.slice();

			const indexOfLevelInApp = initialLevelsCopy?.findIndex((level) => {
				return level?.key === additionalInfo?.key;
			});
			if (indexOfLevelInApp > -1) {
				initialLevelsCopy[indexOfLevelInApp] = {
					...initialLevelsCopy[indexOfLevelInApp],
					[reviewerType === PRIMARY_REVIEWER
						? PRIMARY_REVIEWER
						: FALLBACK_REVIEWER]: reviewerData,
				};
			}

			return {
				...state,
				applicationReview: {
					...state.applicationReview,
					levels: initialLevelsCopy,
				},
			};

		case ACTION_TYPE.UPDATE_SIGN_OFF_JOB_STATUS:
			const appArrCopy = state.review?.data?.applications?.slice();
			const idxOfApp = appArrCopy?.findIndex((app) => {
				return app?.app_id === action?.payload?.appId;
			});

			if (idxOfApp > -1) {
				appArrCopy[idxOfApp] = {
					...appArrCopy[idxOfApp],
					signOffJobInProgress: action?.payload?.status,
				};
				return {
					...state,
					review: {
						...state.review,
						data: {
							...state?.review?.data,
							applications: appArrCopy,
						},
					},
					selectedApp: appArrCopy[idxOfApp],
				};
			}
			return state;

		case ACTION_TYPE.UPDATE_SELECTED_APP_INFO:
			return {
				...state,
				selectedApp: action?.payload?.selectedApp,
			};

		case ACTION_TYPE.UPDATE_SIGN_OFF_FEASIBLE_STATUS:
			const appArr = state.review?.data?.applications?.slice();
			const idxOfApplication = appArr?.findIndex((app) => {
				return app?.app_id === action?.payload?.appId;
			});

			if (idxOfApplication > -1) {
				appArr[idxOfApplication] = {
					...appArr[idxOfApplication],
					isSignOffFeasible: action?.payload?.isFeasible,
				};

				return {
					...state,
					review: {
						...state.review,
						data: {
							...state?.review?.data,
							applications: appArr,
						},
					},
					selectedApp: appArr[idxOfApplication],
				};
			}
			return state;

		case ACTION_TYPE.REMOVE_CERT_DATA:
			const { reviewId, certGroup } = action?.payload;
			const group = state?.[certGroup]?.data;
			if (!group) return state;

			const groupCopy = group.slice();

			const idxOfArchivedCert = groupCopy?.findIndex((cert) => {
				return cert?.reviewId === reviewId;
			});
			if (idxOfArchivedCert > -1) {
				groupCopy.splice(idxOfArchivedCert, 1);
			}

			return {
				...state,
				[certGroup]: { ...state?.[certGroup], data: groupCopy },
			};

		case ACTION_TYPE.UPDATE_SETUP_REVIEWERS_TOGGLE:
			const setupReviewersToggleValue = action?.payload?.value;
			return {
				...state,
				initialLevelsExist: setupReviewersToggleValue,
				initialLevels: [defaultLevelState],
			};

		default:
			return state;
	}
}
