export const modalCard = {
	DEFAULT: "Assigned Default",
	CSV: "uploads",
	INTEGRATION: "integrations",
	MANNUAL: "manually",
};

export const activityStatus = {
	CONNECTED: "connected",
	DISCONNECTED: "disconnected",
};
