import {
	ACCESS_REVIEWS,
	ACTION_TYPE,
	certification_stage,
	entities,
} from "modules/AccessReview/constants/constants";
import {
	fetchReviews,
	fetchReviewData,
	getActionFilterForReviewerTable,
	fetchReviewsEmpView,
	fetchReviewDataEmpView,
	fetchReviewDataForClone,
	getActionFilterForReviewerTableEmpView,
	getStatusForConcludeReviewJob,
} from "modules/AccessReview/service/AccessReview.service";
import {
	AccessReviewCloneAndSanitizeCertDataModel,
	AccessReviewModel,
} from "modules/AccessReview/model/AccessReview.model";
import { createReviewActions } from "modules/AccessReview/constants/constants";
import {
	getUserRolesSuggestion,
	getUserslistServiceV2,
} from "modules/workflow/service/api";
import {
	apiMapper,
	getSelectedAppFromReviewData,
	isNullOrUndefined,
	refactorRolesData,
	refactorUserData,
} from "../util/util";
import { getSingleApplicationDetailsFromHotDB } from "modules/Application/service/Application.api";
import { TriggerIssue } from "utils/sentry";
import { getAllCertificationAppUsersPropertiesListForCloning } from "services/api/certifications";

export const updateReviewDetailsForm = (reviewDetails) => {
	return async function (dispatch) {
		try {
			dispatch({
				type: createReviewActions.UPDATE_REVIEW_DETAILS_FORM,
				payload: { data: reviewDetails },
			});
		} catch (error) {
			console.log(error);
		}
	};
};

export const updateProgressbar = (step, status, viewType) => {
	return async function (dispatch) {
		try {
			dispatch({
				type: createReviewActions.UPDATE_PROGRESS_BAR,
				payload: { step: step, status: status, viewType: viewType },
			});
		} catch (error) {
			console.log(error);
		}
	};
};

export const changeView = (currentView, actionType, viewType) => {
	return async function (dispatch) {
		try {
			dispatch({
				type: createReviewActions.CHANGE_VIEW,
				payload: {
					currentView: currentView,
					actionType: actionType,
					viewType: viewType,
				},
			});
		} catch (error) {
			console.log(error);
		}
	};
};

export const toggleLoader = (val) => {
	return async function (dispatch) {
		dispatch({
			type: createReviewActions.TOGGLE_LOADER,
			payload: {
				loading: val,
			},
		});
	};
};

export const updateSelectedApplicationDetails = (appId) => {
	return async function (dispatch) {
		try {
			dispatch(toggleLoader(true));
			const data = await getSingleApplicationDetailsFromHotDB({ appId });
			dispatch({
				type: createReviewActions.UPDATE_SELECTED_APPLICATION,
				payload: { data: data, appId: appId },
			});
		} catch (error) {
			console.log(error);
		}
		dispatch(toggleLoader(false));
	};
};

export const toggleAddApplicationModal = (show) => {
	return async function (dispatch) {
		try {
			dispatch({
				type: createReviewActions.TOGGLE_ADD_APPLICATION_MODAL,
				payload: { data: show },
			});
		} catch (error) {
			console.log(error);
		}
	};
};

export const fetchDefaultUsersAndRoles = () => {
	return async function (dispatch) {
		try {
			const reqBody = {
				filter_by: [],
				sort_by: [],
				columns: [],
				screen_tag: 2,
			};

			const defaultUsers = await getUserslistServiceV2(0, 10, reqBody);
			const defaultRoles = await getUserRolesSuggestion({});
			dispatch({
				type: createReviewActions.FETCH_DEFAULT_USERS_AND_ROLES,
				payload: {
					defaultUsers: refactorUserData(defaultUsers?.data),
					metaData: defaultUsers,
					defaultRoles: refactorRolesData(defaultRoles),
				},
			});
		} catch (error) {
			dispatch({
				type: createReviewActions.FETCH_DEFAULT_USERS_AND_ROLES,
				payload: {
					error,
				},
			});
			console.log(error);
		}
	};
};

export const updateReviewSetupDetails = (reviewData) => {
	return async function (dispatch) {
		try {
			dispatch({
				type: createReviewActions.UPDATE_COMPLETE_REVIEW_SETUP,
				payload: { data: reviewData },
			});
		} catch (error) {
			console.log(error);
		}
	};
};

export const makeActiveStep = (currentView) => {
	return async function (dispatch) {
		try {
			dispatch({
				type: createReviewActions.MAKE_CURRENT_STEP_ACTIVE,
				payload: { currentView: currentView },
			});
		} catch (error) {
			console.log(error);
		}
	};
};

export const editApplicationInReview = (appId) => {
	return async function (dispatch) {
		try {
			dispatch({
				type: createReviewActions.EDIT_APPLICATION,
				payload: { appId: appId },
			});
		} catch (error) {
			console.log(error);
		}
	};
};

export const deleteApplicationInReview = (appId) => {
	return async function (dispatch) {
		try {
			dispatch({
				type: createReviewActions.DELETE_APPLICATION,
				payload: { appId: appId },
			});
		} catch (error) {
			console.log(error);
		}
	};
};

export const addApplicationToReview = (applicationReview, isEditing) => {
	return async function (dispatch) {
		try {
			dispatch({
				type: createReviewActions.ADD_APPLICATION,
				payload: {
					data: applicationReview,
					isEditing: isEditing,
				},
			});
		} catch (error) {
			console.log(error);
		}
	};
};

export const updateReviewActions = (reviewActions) => {
	return async function (dispatch) {
		try {
			dispatch({
				type: createReviewActions.UPDATE_ACTIONS_DATA,
				payload: { data: reviewActions },
			});
		} catch (error) {
			console.log(error);
		}
	};
};

export const setListReviewLoading = (reviewType, loading = false) => {
	return async function (dispatch) {
		try {
			dispatch({
				type: ACTION_TYPE.GET_REVIEWS,
				payload: {
					loading: loading,
					reviewType: reviewType,
				},
			});
		} catch (error) {
			console.log(error);
		}
	};
};

export const fetchReviewsV2 = (reviewType, page, row, isEmployee) => {
	return async function (dispatch) {
		dispatch(setListReviewLoading(reviewType, true));
		try {
			const response = isEmployee
				? await fetchReviewsEmpView(reviewType, page, row)
				: await fetchReviews(reviewType, page, row);
			if (response?.error)
				dispatch({
					type: ACTION_TYPE.GET_REVIEWS,
					payload: {
						loading: false,
						errors: response.error,
						reviewType: reviewType,
					},
				});
			else
				dispatch({
					type: ACTION_TYPE.GET_REVIEWS,
					payload: {
						data: response?.map(
							(data) => new AccessReviewModel(data)
						),
						loading: false,
						reviewType: reviewType,
					},
				});
		} catch (err) {
			dispatch({
				type: ACTION_TYPE.GET_REVIEWS,
				payload: {
					errors: err,
					reviewType: reviewType,
					loading: false,
				},
			});
		}
	};
};

export const isEditingApp = (val) => {
	return async function (dispatch) {
		try {
			dispatch({
				type: createReviewActions.IS_EDITING_APP,
				payload: { data: val },
			});
		} catch (error) {
			console.log(error);
		}
	};
};

export const clearModalData = () => {
	return async function (dispatch) {
		try {
			dispatch({
				type: createReviewActions.CLEAR_MODAL_DATA,
			});
		} catch (error) {
			console.log(error);
		}
	};
};

export const clearAllReviewDetails = () => {
	return async function (dispatch) {
		try {
			dispatch({
				type: createReviewActions.CLEAR_ALL_REVIEW_DETAILS,
			});
		} catch (error) {
			console.log(error);
		}
	};
};

export const emptyApplicationsArray = () => {
	return async function (dispatch) {
		try {
			dispatch({
				type: createReviewActions.EMPTY_APPLICATIONS_ARRAY,
			});
		} catch (error) {
			console.log(error);
		}
	};
};

export const getReviewData = (reviewId, isEmployee, appId, certStage) => {
	return async function (dispatch, getState) {
		dispatch({
			type: createReviewActions.SET_REVIEW_DATA,
			payload: {
				loading: true,
			},
		});
		try {
			const response = isEmployee
				? await fetchReviewDataEmpView(reviewId)
				: await fetchReviewData(reviewId);

			if (
				response?.certification_stage === certification_stage.ARCHIVED
			) {
				await dispatch({
					type: createReviewActions.SET_REVIEW_DATA,
					payload: {
						data: response,
						loading: false,
					},
				});
				return;
			}

			if (response?.error) {
				dispatch({
					type: createReviewActions.SET_REVIEW_DATA,
					payload: {
						loading: false,
						errors: response.error,
					},
				});
			} else {
				let data = new AccessReviewModel(response);
				if (!isEmployee) {
					const jobStatus = await getStatusForConcludeReviewJob({
						certId: reviewId,
					});
					data = new AccessReviewModel({
						...response,
						concludeReviewJobStatus: jobStatus?.status,
					});
				}

				await dispatch({
					type: createReviewActions.SET_REVIEW_DATA,
					payload: {
						data: data,
						loading: false,
					},
				});

				if (appId) {
					const selectedApp = getSelectedAppFromReviewData(
						data,
						appId
					);

					await dispatch(updateSelectedAppInfo(selectedApp));

					/* Don't call isSignOffFeasibleApi and checkJobStatus
					   APIs when just signed-off or if it is non-ongoing-review,
					   but do update the sign-off job status as pending when just signed-off */

					if (certStage === certification_stage.NON_ONG_REVIEW)
						return;

					if (certStage === certification_stage.REVIEW) {
						const reqBodyForSignOffJobStatus = {
							review_level:
								getState()?.accessReview?.selectedApp
									?.currentReviewLevel,
						};

						await dispatch(
							updateSignOffJobStatusForApp(
								reviewId,
								appId,
								isEmployee,
								reqBodyForSignOffJobStatus
							)
						);
						return;
					}

					const isSignOffFeasibleApi = apiMapper(
						isEmployee,
						entities?.checkIfSignOffFeasible
					);
					const res = await isSignOffFeasibleApi(reviewId, appId);
					await dispatch(
						updateAppSignOffFeasibleStatus(appId, res?.status)
					);

					// check job status and update accordingly
					const reqBodyForSignOffJobStatus = {
						review_level:
							getState()?.accessReview?.selectedApp
								?.currentReviewLevel,
					};

					await dispatch(
						updateSignOffJobStatusForApp(
							reviewId,
							appId,
							isEmployee,
							reqBodyForSignOffJobStatus
						)
					);
				}
			}
		} catch (err) {
			dispatch({
				type: createReviewActions.SET_REVIEW_DATA,
				payload: {
					loading: false,
					errors: err,
				},
			});
		}
	};
};

export const updateReviewData = (
	reviewId,
	isEmployee,
	appId,
	signoffFeasible
) => {
	return async function (dispatch) {
		try {
			const response = isEmployee
				? await fetchReviewDataEmpView(reviewId)
				: await fetchReviewData(reviewId);
			if (!response?.error) {
				dispatch({
					type: createReviewActions.SET_REVIEW_DATA,
					payload: {
						data: new AccessReviewModel(response),
					},
				});
				dispatch(
					updateAppSignOffFeasibleStatus(appId, signoffFeasible)
				);
			}
		} catch (err) {
			console.log(err);
		}
	};
};

export const updateColumns = (data) => {
	return async function (dispatch) {
		try {
			dispatch({
				type: createReviewActions.UPDATING_COLUMNS,
				payload: { data: data },
			});
		} catch (error) {
			console.log(error);
		}
	};
};

export const updateFiltersBy = (data, count) => {
	return async function (dispatch) {
		try {
			dispatch({
				type: createReviewActions.UPDATING_FILTER_BY,
				payload: { data: data, count: count },
			});
		} catch (error) {
			console.log(error);
		}
	};
};

export const getActionFiltersForReviewTable = (
	apiProps,
	filter_by,
	setFilterFetched,
	isEmployee
) => {
	return async function (dispatch) {
		try {
			dispatch({
				type: ACTION_TYPE.GET_ACTION_FILTERS,
				payload: {},
			});
			const res = isEmployee
				? await getActionFilterForReviewerTableEmpView(
						apiProps,
						filter_by
					)
				: await getActionFilterForReviewerTable(apiProps, filter_by);
			setFilterFetched(false);
			dispatch({
				type: ACTION_TYPE.FETCHED_ACTION_FILTERS,
				payload: {
					data: res,
					loading: false,
				},
			});
		} catch (error) {
			TriggerIssue("Error while fetching Action Filters", error);
		}
	};
};

export const cloneCertData = (reviewId) => {
	return async function (dispatch, getState) {
		try {
			dispatch({
				type: ACTION_TYPE.FETCHING_CERT_DATA,
				payload: {
					error: false,
					certDataLoaded: false,
				},
			});

			/* Fetch Roles and users data */

			// await dispatch(fetchDefaultUsersAndRoles());

			/* Fetch columns data from filters API call */

			const res =
				await getAllCertificationAppUsersPropertiesListForCloning({});
			const columnsData = res?.columns;

			const data = await fetchReviewDataForClone(reviewId);

			const reviewData = {
				...data,
				columnsData: columnsData,
			};

			/* Sanitize data through the Model */

			const sanitizedData = new AccessReviewCloneAndSanitizeCertDataModel(
				reviewData
			);
			dispatch({
				type: ACTION_TYPE.CLONE_AND_EDIT_CERT_DATA,
				payload: {
					data: sanitizedData,
				},
			});
			dispatch({
				type: ACTION_TYPE.FETCHING_CERT_DATA,
				payload: {
					certDataLoaded: true,
					error: false,
				},
			});
		} catch (err) {
			dispatch({
				type: ACTION_TYPE.FETCHING_CERT_DATA,
				payload: {
					certDataLoaded: false,
					error: true,
				},
			});
			TriggerIssue(`Cloning failed for cert ${reviewId}`, err);
		}
	};
};

export const handleAddInitialLevel = () => {
	return async function (dispatch) {
		dispatch({
			type: createReviewActions.ADD_INITIAL_LEVEL,
		});
	};
};

export const handleRemoveInitialLevel = (key) => {
	return async function (dispatch) {
		dispatch({
			type: createReviewActions.REMOVE_INITIAL_LEVEL,
			payload: { key: key },
		});
	};
};

export const updateInitialLevelData = (levelData, additionalInfo) => {
	return async function (dispatch) {
		dispatch({
			type: createReviewActions.SET_INITIAL_LEVEL_DATA,
			payload: { reviewerData: levelData, additionalInfo },
		});
	};
};

export const handleAddLevel = () => {
	return async function (dispatch) {
		dispatch({
			type: createReviewActions.ADD_LEVEL,
		});
	};
};

export const handleRemoveLevel = (key) => {
	return async function (dispatch) {
		dispatch({
			type: createReviewActions.REMOVE_LEVEL,
			payload: { key: key },
		});
	};
};

export const updateLevelData = (levelData, additionalInfo) => {
	return async function (dispatch) {
		dispatch({
			type: createReviewActions.SET_LEVEL_DATA,
			payload: { reviewerData: levelData, additionalInfo },
		});
	};
};

export const updateSignOffJobStatusForApp = (
	reviewId,
	appId,
	isEmployee,
	reqBody
) => {
	return async function (dispatch) {
		const signOffJobStatusApi = apiMapper(
			isEmployee,
			entities.signOffJobStatus
		);
		const res = await signOffJobStatusApi(reviewId, appId, reqBody);

		if (res?.error) {
			TriggerIssue(
				`Error in getting sign-off job status for review_id ${reviewId}, app_id ${appId}`,
				res?.error,
				ACCESS_REVIEWS
			);
		} else {
			dispatch({
				type: ACTION_TYPE.UPDATE_SIGN_OFF_JOB_STATUS,
				payload: { appId: appId, status: res?.status },
			});
		}
	};
};

export const updateSelectedAppInfo = (appInfo) => {
	return async function (dispatch) {
		dispatch({
			type: ACTION_TYPE.UPDATE_SELECTED_APP_INFO,
			payload: { selectedApp: appInfo },
		});
	};
};

export const updateAppSignOffFeasibleStatus = (appId, isFeasible) => {
	return async function (dispatch) {
		dispatch({
			type: ACTION_TYPE.UPDATE_SIGN_OFF_FEASIBLE_STATUS,
			payload: {
				appId: appId,
				isFeasible: isNullOrUndefined(isFeasible) ? false : isFeasible,
			},
		});
	};
};

export const removeCertData = (reviewId, certGroup) => {
	return async function (dispatch) {
		dispatch({
			type: ACTION_TYPE.REMOVE_CERT_DATA,
			payload: { reviewId: reviewId, certGroup: certGroup },
		});
	};
};

export const updateSetReviewersToggle = (value) => {
	return async function (dispatch) {
		dispatch({
			type: ACTION_TYPE.UPDATE_SETUP_REVIEWERS_TOGGLE,
			payload: { value: value },
		});
	};
};
