import React from "react";

import { times } from "underscore";

import { BUTTON_VARIANT, GREY_VARIANT, Typography } from "@zluri/ui-components";
import { Skeleton } from "antd";
import { CustomLottie } from "UIComponents/CustomLottie/CustomLottie";
import { Beta } from "modules/shared/components/BetaTagAndModal/Beta/beta";

import overview from "assets/applications/vendors/Icon_AppOverview.svg";
import info from "assets/applications/vendors/Icon_Info.svg";
import appInsight from "assets/applications/vendors/Icon_AppInsights.svg";
import userTick from "assets/applications/vendors/Icon_UserTick.svg";
import documentData from "assets/applications/vendors/Icon_Document.svg";
import optimisation from "assets/applications/vendors/Icon_Optimisation.svg";
import money from "assets/applications/vendors/Icon_Money.svg";
import budgeting from "assets/applications/vendors/Icon_Budgeting.svg";
import automation from "assets/applications/vendors/Icon_Automation.svg";
import events from "assets/applications/vendors/Icon_CheckBadge.svg";
import dataShared from "assets/applications/vendors/Icon_Database.svg";
import probes from "assets/applications/vendors/Icon_Application_Outline.svg";
import settings from "assets/applications/vendors/Icon_AppSettings.svg";
import lowRisk from "assets/low_risk.svg";
import mediumRisk from "assets/medium_risk.svg";
import highRisk from "assets/high_risk.svg";
import userName from "assets/applications/Icon_UserTick.svg";
import appStatus from "assets/applications/Icon_Status.svg";
import threatLevel from "assets/applications/Icon_Alert.svg";
import addFilter from "assets/applications/iconAddFilter.svg";
import ascending from "assets/applications/Icon_Ascending.svg";
import decending from "assets/applications/Icon_Decending.svg";
import rightArrow from "assets/auditlogs/rightarrow.svg";
import InformationCircle from "assets/information-circle.svg";
import check_filled from "assets/check-filled.svg";
import notification from "assets/applications/Icon_Notification.svg";
import email from "assets/applications/Icon_Email.svg";
import slack from "assets/applications/Icon_slack.svg";
import sassPlatformIcon from "assets/icons/v2Icons/Icon_Platforms.svg";
import igaPlatformIcon from "assets/icons/v2Icons/Icon_Platforms_Igp.svg";
import sassPlatformSolidIcon from "assets/Icon_SM_Solid.svg";
import igaPlatformSolidIcon from "assets/Icon_IGA_Solid.svg";
import select from "assets/applications/tick.svg";

import loading_config from "assets/lottieConfig/v2_bubble_loading.json";
import { PLATFORM_TYPE } from "modules/shared/utils/platform.hook";

export const authStatus = {
	AUTHORISED: "authorized",
	RESTRICTED: "restricted",
	NEEDSREVIEW: "needs review",
	CENTRALLY_MANAGED: "centrally managed",
	TEAM_MANAGED: "team managed",
	INDIVIDUALLY_MANAGED: "individually managed",
	UNMANAGED: "unmanaged",
};

export const INPUT_FIELD_STATES = {
	validated: {
		color: "",
		icon: check_filled,
		filter: "invert(29%) sepia(91%) saturate(1566%) hue-rotate(205deg) brightness(93%) contrast(95%)",
	},
	isInvalid: {
		color: "red",
		icon: InformationCircle,
		filter: "invert(53%) sepia(59%) saturate(3280%) hue-rotate(330deg) brightness(109%) contrast(99%)",
	},
	isValidating: {
		color: "",
		loader: (
			<CustomLottie
				animationData={loading_config}
				width={40}
				height={60}
			/>
		),
	},
};

export const HEADLINE_TAB = {
	OVERVIEW: {
		text: "Overview",
	},
	COMPLIANCES: {
		text: "Compliances",
	},
	APPLICATION_COMPLIANCE: {
		text: "Application Compliance",
	},
	SECURITY_PROBES: {
		text: "Security Probes",
	},
	DATA_SHARED: {
		text: "Data Shared",
	},
	CRITICAL_USERS: {
		text: "Critical Users",
	},
	SECURITY_EVENTS: {
		text: "Security Events",
	},
	APPLICATION_SPENDS: {
		text: "Spends",
	},
	APPLICATION_CHARGEBACKS: {
		text: "Chargebacks",
	},
	APPLICATION_APP_ABOUT: {
		text: "About",
	},
	APPLICATION_OPTIMIZATION: {
		text: "Optimization",
	},
	OPTIMIZATION_OVERVIEW: {
		text: "Optimization Overview",
	},
	PREVIOUS_OPTIMIZATION_RUNS: {
		text: "Previous Optimization Runs",
	},
	OPTIMIZATION_REVIEW_USERS: {
		text: "Review Users",
	},
	APP_USERS: {
		text: "Users",
	},
	Application_Licenses: {
		text: "Contracts & Licenses",
	},
	Application_Map_Licenses: {
		text: "Map Licenses",
	},
	APP_INSTANCES: {
		text: "Instances",
	},
	APP_INSIGHTS: {
		text: "App Insights",
	},
	AUTOMATION: {
		text: "Automation",
	},
};

export const amortizationSetting = [
	{
		field_id: "no_ammortization",
		field_name: "No Amortization",
	},
	// {
	// 	field_id: "pro_rata",
	// 	field_name: "Pro Rata",
	// },
];

export const alertRecipients = [
	{
		field_id: "admin_owner",
		field_name: "All Admins & Owners",
	},
	{
		field_id: "app_owner",
		field_name: "All Application Owners",
	},
	{
		field_id: "finance_owner",
		field_name: "Finance Owner only",
	},
];

export const handleDropddown = (setting, dropdown) => {
	return dropdown.filter((e) => e?.field_id?.includes(setting))[0]
		?.field_name;
};
export const transactions = [
	{
		field_id: "weekly",
		field_name: "Weekly",
	},
	{
		field_id: "monthly",
		field_name: "Monthly",
	},
	{
		field_id: "quarterly",
		field_name: "Quarterly",
	},
];
export const budgetPercentage = [
	{
		field_id: "25",
		field_name: "25 %",
	},
	{
		field_id: "50",
		field_name: "50 %",
	},
	{
		field_id: "75",
		field_name: "75 %",
	},
	{
		field_id: "90",
		field_name: "90 %",
	},

	{
		field_id: "100",
		field_name: "100 %",
	},
];
export const riskTypeChecker = {
	APP_ABOUT: "APP_ABOUT",
};
export const getDropDownOptions = ({ typeChecker }) => ({
	LOW_RISK: {
		title: typeChecker === riskTypeChecker.APP_ABOUT ? "Low" : "Low Risk",
		icon: lowRisk,
		scoreRange: [1, 2],
		background: "#40E3951A",
	},
	MEDIUM_RISK: {
		title:
			typeChecker === riskTypeChecker.APP_ABOUT
				? "Medium"
				: "Medium Risk",
		icon: mediumRisk,
		scoreRange: [3],
		background: "#FFC1171A",
	},
	HIGH_RISK: {
		title: typeChecker === riskTypeChecker.APP_ABOUT ? "High" : "High Risk",
		icon: highRisk,
		scoreRange: [4, 5],
		background: "#FF67671A",
	},
});

export const SECURITY_EVENTS_IMPACT_DATA = [
	{
		label: "All Events",
	},
	{
		label: "Very High Impact",
		value: 5,
	},
	{
		label: "High Impact",
		value: 4,
	},
	{
		label: "Medium Impact",
		value: 3,
	},
	{
		label: "Low Impact",
		value: 2,
	},
];

export const getSecurityEventImpacts = (selected) => {
	return SECURITY_EVENTS_IMPACT_DATA?.map(({ label, value }, idx) => ({
		key: idx,
		label: (
			<span className="d-flex flex-1 align-items-center">
				<Typography
					variant={BUTTON_VARIANT.BUTTON_EXTRA_SMALL_MEDIUM}
					color={GREY_VARIANT.SECONDARY_GREY_2}
				>
					{label}
				</Typography>
				{(selected?.key || 0) === idx && (
					<img
						src={select}
						alt=""
						className="ml-auto"
						style={{
							filter: "invert(33%) sepia(94%) saturate(1129%) hue-rotate(197deg) brightness(89%) contrast(99%)",
						}}
						width={10}
						height={8}
					/>
				)}
			</span>
		),
		text: label,
		impact: value,
	}));
};

export const dataFilter = [
	{
		field_id: "threat",
		field_svg: events,
		field_name: "Threat Level",
		field_values: ["Low Risk", "Medium Risk", "High Risk"],
		selectedValues: [],
	},
	{
		field_id: "scope",
		field_svg: automation,
		field_name: "Scope Access Level",
		field_values: ["Read", "Read & Write"],
		selectedValues: [],
	},
	{ button: "apply_and_clear_all_buttons" },
];

export const dataModalFilter = JSON.stringify([
	{
		field_id: "userName",
		field_name: "User Name",
		field_svg: userName,
		field_values: [
			"Contains",
			"Does Not Contain",
			"Starts with",
			"Ends with",
		],
		selectedValue: { filter: "Contains", value: undefined },
		field_type: "radio",
	},
	{
		field_id: "status",
		field_name: "App Status",
		field_svg: appStatus,
		field_values: ["Active", "Inactive"],
		selectedValues: [],
	},
	{
		field_id: "threat",
		field_name: "Threat Level",
		field_svg: threatLevel,
		field_values: ["Low Risk", "Medium Risk", "High Risk"],
		selectedValues: [],
	},
	{ field_name: "button" },
]);

export const tableFilterData = [
	{
		field_name: "Add filter",
		field_svg: addFilter,
		field_sub_svg: rightArrow,
	},
	{
		field_name: "Sort Ascending",
		field_svg: ascending,
		filter_key: "ascending",
	},
	{
		field_name: "Sort Descending",
		field_svg: decending,
		filter_key: "descending",
	},
];
export const TogglerData = (setting) => [
	{
		togglerHeader: "Allow Application Spend",
		toggler: true,
		dropData: {
			dropdown: true,
		},
		dropdownInitial: "Recipients",
		dropdownMenu: alertRecipients,
		spendAlertToggler: true,
		field_id: "toggle",
		unique_key: "spend_alert_settings",
		parent_key: "allow_application_spend_alerts",
		alert_key: "recipients",
		toggle_key: "is_enabled",
		spendsAlertData: [
			{
				alertHeading: "Notification",
				alertDesc: "Alerts will appear  as notifications by default.",
				alertSvg: notification,
				alertId: "in_app",
			},
			{
				alertHeading: "Email",
				alertDesc: "Alerts will be sent via emails to all recepeints. ",
				alertSvg: email,
				alertId: "email",
			},
			{
				alertHeading: "Slack",
				alertDesc: "A direct message will be sent for each alert.",
				alertSvg: slack,
				alertId: "slack",
			},
		],
		selectedValues: setting?.spend_alert_settings?.channel,
		toggleSwitch:
			setting?.spend_alert_settings?.allow_application_spend_alerts
				?.is_enabled,
		dropdownToggler:
			handleDropddown(
				setting?.spend_alert_settings?.allow_application_spend_alerts
					?.recipients,
				alertRecipients
			) || "",
	},
	{
		togglerHeader: "Transactions Missing",
		toggler: true,
		dropData: {
			dropdown: true,
		},
		dropdownInitial: "Frequency",
		dropdownMenu: transactions,
		toggleSwitch:
			setting?.spend_alert_settings?.transaction_missing?.is_enabled,
		dropdownToggler:
			handleDropddown(
				setting?.spend_alert_settings?.transaction_missing?.frequency,
				transactions
			) || "",
		alert_key: "frequency",
		parent_key: "transaction_missing",
		unique_key: "spend_alert_settings",
		toggle_key: "is_enabled",
	},
	{
		togglerHeader: "Application Budget reached",
		toggler: true,
		dropData: {
			dropdown: true,
		},
		dropdownInitial: "Budget Percentage",
		dropdownMenu: budgetPercentage,
		toggleSwitch:
			setting?.spend_alert_settings?.application_budget_reached
				?.is_enabled,
		dropdownToggler:
			handleDropddown(
				setting?.spend_alert_settings?.application_budget_reached
					?.budget_percentage,
				budgetPercentage
			) || "",
		alert_key: "budget_percentage",
		parent_key: "application_budget_reached",
		unique_key: "spend_alert_settings",
		toggle_key: "is_enabled",
	},
	{
		togglerHeader: "Unusually high transactions",
		toggler: true,
		dropData: {
			dropdown: true,
			input: true,
		},
		dropdownInitial: "Frequency",
		inputInitial: "Count",
		dropdownMenu: transactions,
		toggleSwitch:
			setting?.spend_alert_settings?.unusually_high_transactions
				?.is_enabled,
		settingInput:
			setting?.spend_alert_settings?.unusually_high_transactions
				?.frequency_count || "",
		dropdownToggler:
			handleDropddown(
				setting?.spend_alert_settings?.unusually_high_transactions
					?.frequency,
				transactions
			) || "",
		unique_key: "spend_alert_settings",
		parent_key: "unusual_high_transaction",
		alert_key_second: "frequency_count",
		alert_key: "frequency",
		toggle_key: "is_enabled",
	},
	{
		togglerHeader: "High-Value transactions",
		toggler: true,
		dropData: {
			input: true,
		},
		inputInitial: "Amount",
		toggleSwitch:
			setting?.spend_alert_settings?.high_value_transactions?.is_enabled,
		settingInput:
			setting?.spend_alert_settings?.high_value_transactions?.amount ||
			"",
		alert_key: "amount",
		parent_key: "high_value_transactions",
		unique_key: "spend_alert_settings",
		toggle_key: "is_enabled",
	},
	{
		togglerHeader: "Forecasted Spends exceeding Estimated Cost ",
		toggler: true,
		toggleSwitch:
			setting?.spend_alert_settings
				?.forecasted_spends_exceeding_estated_cost?.is_enabled,
		parent_key: "forecasted_spends_exceeding_estated_cost",
		unique_key: "spend_alert_settings",
		toggle_key: "is_enabled",
	},
];
export const SPENDS_MODAL = {
	SPENDS_SETTING: "SPENDS_SETTING",
	FINANCIAL_SOURCES: "FINANCIAL_SOURCES",
	PAYMENT_METHODS: "PAYMENT_METHODS",
	ADD_SINGLE_TRANSACTION: "ADD_SINGLE_TRANSACTION",
	BULK_UPLOAD_TRANSACTION: "BULK_UPLOAD_TRANSACTION",
	ADD_PAYMENT_METHOD: "ADD_PAYMENT_METHOD",
};

export const OVERVIEW_MODAL = {
	NOTES: "NOTES",
	APP_SOURCES: "APP_SOURCES",
	ADD_SINGLE_TRANSACTION: "ADD_SINGLE_TRANSACTION",
	MAP_APPLICATION: "MAP_APPLICATION",
	MERGE_APPLICATION: "MERGE_APPLICATION",
};

export const modalCard = {
	DEFAULT: "Assigned Default",
	CSV: "uploads",
	INTEGRATION: "integrations",
	MANNUAL: "manually",
};

export const activityStatus = {
	CONNECTED: "connected",
	DISCONNECTED: "disconnected",
};

export const AllAppsTab = [
	{
		tab: [
			{
				hash: "",
				text: "Overview",
				disable: true,
				tabLogo: overview,
			},
			{
				hash: "",
				disable: true,
				text: "About",
				tabLogo: info,
			},
			{
				hash: "",
				disable: true,
				text: "App Insights",
				tabLogo: appInsight,
			},
		],
	},
	{
		tab: [
			{
				hash: "#users",
				text: "Users",
				tabLogo: userTick,
			},
			{
				hash: "",
				disable: true,
				text: "Usage",
				tabLogo: userTick,
			},
			{
				hash: "",
				disable: true,
				text: "Contract & Licenses",
				tabLogo: documentData,
			},
			{
				hash: "",
				disable: true,
				text: "Optimization",
				tabLogo: optimisation,
				beta: "optimization",
			},
		],
	},

	{
		tab: [
			{
				hash: "#spends",
				text: "Spends",
				tabLogo: money,
			},
			{
				hash: "#chargebacks",
				text: <Beta text="Chargebacks" />,
				tabLogo: money,
			},
			{
				hash: "",
				disable: true,
				text: "Budgeting",
				tabLogo: budgeting,
			},
		],
	},
	{
		tab: [
			{
				hash: "",
				disable: true,
				text: <Beta text="Automation" />,
				tabLogo: automation,
				beta: "automation",
			},
		],
	},
	{
		tab: [
			{
				hash: "security_events",
				disable: true,
				text: "Security & Compliance",
				tabLogo: events,
			},
		],
	},
	{
		tab: [
			{
				hash: "#security_events",
				disable: true,
				text: "Security Events",
				tabLogo: events,
			},
			{
				hash: "#data_shared",
				disable: true,
				text: "Data Shared",
				tabLogo: dataShared,
			},
			{
				hash: "#security_compliance",
				disable: true,
				text: "Compliances",
				tabLogo: documentData,
			},
			{
				hash: "#security_probes",
				disable: true,

				text: "Security Probes",
				tabLogo: probes,
			},
		],
		subtab: {
			subtabHeader: "Security & Compliance",
			subTabLogo: events,
			hash: "#security_events",
		},
	},
	{
		tab: [
			{
				hash: "",
				disable: true,
				text: "Settings",
				tabLogo: settings,
			},
		],
	},
];

export const selectedAsString = (stringOption, stringValue) => {
	return (
		stringValue &&
		stringOption?.trim()?.toLowerCase() ===
			stringValue?.trim()?.toLowerCase()
	);
};

export const defaultFilters = (entity) => {
	return (
		{
			application_users: [
				{
					field_name: "User Archive",
					field_type: "single_select",
					field_values: false,
					field_labels: ["Yes", "No"],
					field_id: "user_archive",
					filter_type: "boolean",
					negative: false,
					is_field_static: false,
					editable: false,
					draggable: false,
					validations: ["isSpecial"],
					is_sortable: true,
					sort_default_value: -1,
					field_value_type: "boolean",
					is_filterable: true,
				},
				{
					field_name: "Application User Archive",
					field_type: "single_select",
					field_values: false,
					field_labels: ["Yes", "No"],
					field_id: "user_app_archive",
					filter_type: "boolean",
					negative: false,
					is_field_static: false,
					editable: false,
					draggable: false,
					validations: ["isSpecial"],
					is_sortable: true,
					sort_default_value: -1,
					is_filterable: true,
				},
			],
		}[entity] ?? []
	);
};
export const SummaryMetricLoader = ({ n = 4 }) =>
	times(n, (i) => (
		<div
			key={i}
			className="d-flex flex-1 flex-row border-radius-8"
			style={{
				minWidth: "244px",
				minHeight: "96px",
				height: "100%",
				padding: "10px",
				gap: "4px",
				background: "#f6f7fa",
			}}
		>
			<Skeleton
				active
				prefixCls="z_summary_loader"
				paragraph={{
					rows: 2,
					style: {
						marginBottom: "0px",
					},
				}}
			/>
		</div>
	));

export const platforms = [
	{
		id: "0",
		icon: sassPlatformIcon,
		iconSolid: sassPlatformIcon,
		title: "SaaS Management",
		description: "Manage Applications and Licenses",
		lastViewed: "2024-03-19T12:00:00",
		platform: PLATFORM_TYPE.SAAS,
		allowedRoutes: [
			"/overview",
			"/applications",
			"/users",
			"/groups",
			"/departments",
			"/transactions",
			"/licenses",
			"/optimization",
			"/security",
			"/reports",
			"/integrations",
			"/agents",
			"/workflows",
			"/auditlogs",
			"/settings",
			"/search",
		],
		excludedRoutes: ["/workflows/apprequisition"],
		defaultRoute: "/overview",
		active: true,
	},
	{
		id: "1",
		icon: igaPlatformIcon,
		iconSolid: igaPlatformIcon,
		title: "Identity Governance & Administration",
		description: "Manage and Review Access",
		lastViewed: "2024-03-19T12:00:00",
		platform: PLATFORM_TYPE.IGA,
		allowedRoutes: [
			"/applications",
			"/users",
			"/groups",
			"/departments",
			"/accessreview",
			"/workflows/apprequisition",
			"/settings",
			"/search",
		],
		defaultRoute: "/applications",
	},
];
