import React from "react";

const HeaderContentForTable = ({
	wrapperClasses,
	headerText,
	subHeaderText,
}) => {
	return (
		<div className={wrapperClasses}>
			<div className="bold-600 font-12 black-1">{headerText}</div>
			<div className="bold-400 font-11 grey-1">{subHeaderText}</div>
		</div>
	);
};

export default HeaderContentForTable;
