import React from "react";
import cn from "classnames";
import filterIcon from "assets/Icon_AddFilter.svg";
import { useTableContext } from "../TableContext/context";
import arrow_down from "assets/arrow-down.svg";
import { IconButton, theme } from "@zluri/ui-components";
import Filter from "../FilterList/Filter";
import TextTooltip from "../TextTooltip";
interface IFilterControl {
	showAllAppliedFiltersList: boolean;
	appliedFiltersCount: boolean;
	loading: boolean;
	onShowAppliedFilterClick: () => void;
}
function FilterControl({
	showAllAppliedFiltersList,
	appliedFiltersCount,
	loading,
	onShowAppliedFilterClick,
}: IFilterControl) {
	const { isAnyDataSelected, enableBulkEdit } = useTableContext();

	if (isAnyDataSelected && enableBulkEdit) return null;
	return (
		<div
			className={cn(
				"d-flex flex-row position-relative",
				!enableBulkEdit && "v2table-filters"
			)}
		>
			<div
				className={cn(
					"d-flex align-items-center gap-1",
					showAllAppliedFiltersList && "z-filter__button-active"
				)}
			>
				<Filter>
					<div>
						<TextTooltip title="Configure Filters">
							<IconButton
								disabled={loading}
								theme={theme}
								icon={filterIcon}
								position="left"
								shape="square"
								variant="grey"
								className="z-v2-table-filter_button-controller"
							/>
						</TextTooltip>
					</div>
				</Filter>

				<TextTooltip title="View Active Filters">
					<IconButton
						icon={arrow_down}
						theme={theme}
						onClick={onShowAppliedFilterClick}
						disabled={!appliedFiltersCount || loading}
						position="right"
						shape="square"
						variant="grey"
						className={`z-v2-table-filter_button-controller gap-4  ${
							showAllAppliedFiltersList
								? "z-v2-filter__applied-filters__button_show_filters"
								: ""
						}`}
						iconText={appliedFiltersCount || "0"}
					/>
				</TextTooltip>
			</div>
		</div>
	);
}
export default FilterControl;
