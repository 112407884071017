import React from "react";
import chargebackLicense from "assets/v2tables/license-chargeback.svg";
import chargebackManual from "assets/v2tables/chargeback-manual.svg";
import chargebackTotal from "assets/v2tables/chargeback-total.svg";
import chargebackUsers from "assets/v2tables/chargeback-users.svg";
import tickMark from "assets/tick-mark.svg";
import { useDispatch } from "react-redux";
import { setDepartmentSplitDefault } from "services/api/applications";
import { Typography } from "@zluri/ui-components";

export default function StaticSelect({ data, component, ...props}) {
	let selectedValue = data?.[component.selectedValueKey];
	const dispatch = useDispatch();
	let options = [
		{
			logo: chargebackUsers,
			name: "Based on Active Users",
			id: "active_users_last_30_days",
		},
		{
			logo: chargebackLicense,
			name: "Based on Licensed Users",
			id: "default",
		},
		{
			logo: chargebackTotal,
			name: "Based on Total Users",
			id: "active_users",
		},
		{ logo: chargebackManual, name: "Manual", id: "manual" },
	];

	const handleClick = (updatedValue) => {
		if(selectedValue === updatedValue) return;
		dispatch({
			type: "UPDATE_RECORD",
			payload: {
				rowIndex: props.rowIndex,
				columnIndex: props.columnIndex,
				skipApi: true,
			}
		})
		setDepartmentSplitDefault(data.app_id, {
			split_type: updatedValue,
		})
			.then(() => {
				dispatch({
					type: "RECORD_UPDATED",
					payload: {
						index: props.rowIndex,
						value: {
							app_chargeback: updatedValue,
						},
						entity: props.entity,
						rowIndex: props.rowIndex,
						columnIndex: props.columnIndex,
					},
				});
			})
			.catch((err) => {
				console.error('need to handle error case')
			});
	}

	return (
		<div className="flex static-select" style={{ flexDirection: "column" }}>
			{options.map((option, index) => (
				<div
					style={{ justifyContent: "space-between" }}
					key={index}
					className={`flex p-2 static-option cursor-pointer static-select-option align-items-center ${
						selectedValue === option.id
							? "static-select-option-selected"
							: ""
					}`}
					onClick={(e) => {
						handleClick(option.id);
					}}
				>
					<div className="flex align-items-center">
						<div className="mr-1">
							<img alt="" src={option.logo} />
						</div>
						<Typography
							variant="button_extrasmall_medium"
							color="grey_2"
						>
							{option.name}
						</Typography>
					</div>
					{selectedValue === option.id.toLowerCase() && (
						<div>
							<img src={tickMark} alt=""/>
						</div>
					)}
				</div>
			))}
		</div>
	);
}
