import React, { useState } from "react";
import NumberPill from "UIComponents/NumberPill/NumberPill";

import {
	capitalizeFirstLetter,
	capitalizeFirstLetterOfUnderscoreString,
} from "utils/common";
import dayjs from "dayjs";
import { Form } from "react-bootstrap";
import validated from "assets/v2tables/security-validated.svg";
import { getNumberOfDaysBtwnTwoDates } from "utils/DateUtility";
import yellowwarning from "assets/v2tables/yellow-warning.svg";
import greentick from "assets/v2tables/green-tick.svg";
import squaregreen from "assets/v2tables/square_green.svg";
import squarered from "assets/v2tables/square_red.svg";
import {
	markAsActive,
	markAsNotActive,
	patchTaskStatus,
} from "services/api/users";
import { ProgressBarLoader } from "common/Loader/ProgressBarLoader";
import { useDispatch, useSelector } from "react-redux";
import MediumRisk from "assets/medium_risk.svg";
import HighRisk from "assets/high_risk.svg";
import LowRisk from "assets/low_risk.svg";
import ThreatIcon from "assets/icons/threat-icon.svg";
import NoThreat from "assets/icons/green-ellipse.svg";
import moment from "moment";
import tick from "assets/tick-mark.svg";
import LeftIcon from "assets/v2tables/left-chevron.svg";
import arrowConnect from "assets/v2tables/arrow-grey.svg";
import pending from "assets/v2tables/pending.svg";
import yellowerror from "assets/v2tables/yellow-error.svg";
import { push } from "connected-react-router";
import cn from "classnames";
import "./tags.scss";
import rightarrow from "assets/Icon_Arrow_Right.svg";
import { openModal } from "reducers/modal.reducer";

import {
	BODY_VARIANT,
	Button,
	Loader,
	Field,
	TextPill,
	Typography,
	theme,
	Popover,
	toast,
	TOAST_COLOR,
} from "@zluri/ui-components";
import { actionTextMapper } from "modules/AccessReview/constants/constants";
import PropTypes from "prop-types";
import ReviewActionComment from "modules/AccessReview/components/ReviewActionComment/ReviewActionComment";
import "modules/employee-dashboard/components/overview.css";
import replaceZluriWithPartnerName from "utils/replaceZluriFromResponse";
import tagIcon from "assets/Icon_Tag.svg";
import closeIcon from "assets/Icon_Close.svg";
import { getSegmentFromURL } from "@zluri/ui-components";
import {
	fetchUserActionHistory,
	fetchUserApplicationsActionHistory,
} from "components/Users/redux";
import { TOAST_MESSAGE } from "modules/shared/constants/toast.constants";
import { TriggerIssue } from "utils/sentry";

export function TagsRenderer({ component, data }) {
	return (
		<>
			{data[component.valueKey]?.slice(0, 1).map((row, index) => (
				<>
					<NumberPill
						number={row}
						fontColor={"#484848"}
						fontSize="11px"
						fontWeight="400"
						pillBackGround={"#E1E8F8"}
						pillHeight="22px"
						borderRadius={99}
						style={{ padding: "8px" }}
					/>
				</>
			))}
		</>
	);
}

export function CustomThreat({ component, data }) {
	const value = data[component.valueKey];
	const threatLevel = [
		"unavailable",
		"low",
		"medium",
		"medium",
		"high",
		"high",
	];

	const threat = threatLevel[value ?? 0];

	const styles = {
		low: {
			color: "#009307",
			background: "#E7F8E8",
		},
		medium: {
			background: "#FFF6DC",
			color: "#C97B07",
		},
		high: {
			color: "#BB2915",
			background: "#FFE9E5",
		},
		unavailable: {
			color: "#717171",
			background: "#F6F7FA",
		},
	};

	return (
		<NumberPill
			number={capitalizeFirstLetter(threat)}
			style={{ padding: "8px", width: "fit-content" }}
			borderRadius="8px"
			fontSize="14px"
			fontWeight="500"
			fontColor={styles[threat]?.color}
			pillBackGround={styles[threat]?.background}
		/>
	);
}

export function CustomRisk({ component, data }) {
	const value = data[component.valueKey];
	const riskMapper = ["low", "low", "low", "medium", "high", "high"];

	if (value === undefined) {
		return "-";
	}
	const risk = value < 0 ? "low" : value > 5 ? "high" : riskMapper[value];
	const riskIcons = {
		low: LowRisk,
		medium: MediumRisk,
		high: HighRisk,
	};
	return (
		<>
			<div className="d-flex align-items-center">
				<img
					src={riskIcons[risk]}
					alt=""
					style={{ height: 16, width: 16 }}
				/>
				<div className="d-flex flex-column ml-2">
					<Typography
						variant="body_1_regular"
						color="secondary_grey_1"
						className="text-capitalize"
					>
						{risk}
					</Typography>
					{data?.app_risk_set_manually ? (
						<div
							className="grey-1 font-8 "
							style={{ marginTop: "3px" }}
						>
							Manual
						</div>
					) : (
						<></>
					)}
				</div>
			</div>
		</>
	);
}

export function agentTextRenderer({ component, data }) {
	return (
		<div className="d-flex align-items-center">
			<img
				src={
					data[component.valueKey]?.status === "active"
						? getNumberOfDaysBtwnTwoDates(
								new Date(
									data[component.valueKey]?.last_synced_at
								),
								new Date()
							) > 7
							? yellowwarning
							: greentick
						: null
				}
			></img>
			<div className="d-flex flex-column ml-2">
				<div className="font-12">
					{data[component.valueKey]?.status === "active"
						? getNumberOfDaysBtwnTwoDates(
								new Date(
									data[component.valueKey]?.last_synced_at
								),
								new Date()
							) > 7
							? "No Activity"
							: "Installed"
						: "Not Installed"}
				</div>
			</div>
		</div>
	);
}

export function CustomSecurityThreats({ component, data }) {
	const valueLength = data[component.valueKey]?.length;
	return (
		<>
			<div className="d-flex align-items-center">
				{/* <Dots
					color={
						data[component.valueKey] &&
						data[component.valueKey].length
							? "#FFA99D"
							: "#A3E4A6"
					}
				></Dots> */}
				<img src={valueLength > 0 ? ThreatIcon : NoThreat} alt="" />
				<Typography
					variant="body_1_regular"
					color="secondary_grey_1"
					className="ml-2"
				>{`${valueLength > 0 ? valueLength : "No"} Events`}</Typography>
			</div>
		</>
	);
}

export function ActionTag({ component, data }) {
	return (
		<div className="d-flex align-items-center">
			<TextPill
				background="#E8F0FC"
				borderRadius={8}
				color="#2266E2"
				fontVariant="button_extrasmall_regular"
				text={component.value}
			/>
		</div>
	);
}

export function AppUserActionsPopover({
	component,
	data,
	rowIndex,
	columnIndex,
	entity,
}) {
	const dispatch = useDispatch();
	const [open, setOpen] = useState(false);

	const UserApplicationStatusPopup = () => {
		const addOrRemoveRole = async (markActive) => {
			const successToastMessage = markActive
				? "User Employment Status is changed to Active Successfully"
				: "User Employment Status is changed to Inactive Successfully";
			const errorToastMessage = markActive
				? "User Employment Status isn't changed to Active"
				: "User Employment Status isn't changed to Inactive";

			dispatch({
				type: "UPDATE_RECORD",
				payload: {
					rowIndex: rowIndex,
					columnIndex: columnIndex,
					skipApi: true,
				},
			});
			try {
				markActive
					? await markAsActive(data?.user_id, data.user_app_id)
					: await markAsNotActive(data?.user_id, data.user_app_id);
				toast(successToastMessage, {
					indicatorColor: TOAST_COLOR.SUCCESS,
				});
				dispatch({
					type: "RECORD_UPDATED",
					payload: {
						entity: entity,
						rowIndex: rowIndex,
						columnIndex: columnIndex,
						index: rowIndex,
						value: {
							user_app_status: markActive ? "active" : "inactive",
						},
					},
				});
			} catch (error) {
				dispatch({
					type: "RECORD_UPDATE_FAILED",
					payload: {
						entity: entity,
						rowIndex: rowIndex,
						columnIndex: columnIndex,
						errorMessage: "Error!",
					},
				});
				toast(errorToastMessage, {
					indicatorColor: TOAST_COLOR.ERROR,
				});
				TriggerIssue(errorToastMessage, error);
			} finally {
				setOpen(false);
			}
		};
		return (
			<>
				{data?.user_app_status === "inactive" ? (
					<div
						className="d-flex cell-header__menu-option align-items-center cursor-pointer z-option-menu__option gap-6"
						onClick={() => {
							setOpen(false);
							addOrRemoveRole(true);
						}}
					>
						<Typography variant="button_extrasmall_medium">
							Mark as Active
						</Typography>
					</div>
				) : null}
				{data?.user_app_status === "active" ? (
					<div
						className="d-flex cell-header__menu-option align-items-center cursor-pointer z-option-menu__option gap-6"
						onClick={() => {
							addOrRemoveRole();
							setOpen(false);
						}}
					>
						<Typography variant="button_extrasmall_medium">
							Mark as Inactive
						</Typography>
					</div>
				) : null}

				{/* //Hidden for now
				 <div
					className="d-flex cell-header__menu-option align-items-center cursor-pointer z-option-menu__option gap-6"
					onClick={() => {
						setOpen(false);
					}}
				>
					<Typography variant="button_extrasmall_medium">
						Mark as Suspended
					</Typography>
				</div> */}
			</>
		);
	};
	const Popup = () => {
		return (
			<div>
				<Popover
					className="z-option-menu__popover_medium"
					content={<UserApplicationStatusPopup />}
					placement={"rightTop"}
					trigger={["hover"]}
				>
					<div
						className="d-flex cell-header__menu-option align-items-center justify-content-between cursor-pointer z-option-menu__option "
						onClick={() => {
							// dispatch(openModal("addUserModalV2"));
							setOpen(false);
						}}
					>
						<Typography variant="button_extrasmall_medium">
							User Application Status
						</Typography>
						<div className="ml-4">
							<img src={rightarrow} alt="right-arrow" />
						</div>
					</div>
				</Popover>

				<div
					className="d-flex cell-header__menu-option align-items-center cursor-pointer z-option-menu__option gap-6"
					onClick={() => {
						setOpen(false);
						dispatch(openModal("viewActionHistoryModalV2"));
						dispatch(
							fetchUserApplicationsActionHistory(
								data?.user_id,
								data.app_id
							)
						);
					}}
				>
					<Typography variant="button_extrasmall_medium">
						View Action history
					</Typography>
				</div>
			</div>
		);
	};
	return (
		<Popover
			className="z-option-menu__popover_medium"
			content={<Popup />}
			placement={"bottomRight"}
			show={open}
			onToggle={setOpen}
		>
			<div>
				<div className="d-flex align-items-center">
					<TextPill
						onClick={() => setOpen(true)}
						background="#E8F0FC"
						borderRadius={8}
						color="#2266E2"
						fontVariant="button_extrasmall_regular"
						text={component.value}
					/>
				</div>
			</div>
		</Popover>
	);
}
export function CustomSecurityThreatPopup({ component, data }) {
	const riskColors = {
		low: "#5FCF64",
		medium: "#FFA217",
		high: "#FE6955",
	};
	const getValue = (value) => {
		return value <= 1
			? "low"
			: value <= 3 && value >= 2
				? "medium"
				: "high";
	};

	return (
		<>
			{data[component.valueKey] &&
			Array.isArray(data[component.valueKey]) &&
			data[component.valueKey].length ? (
				<div
					className="d-flex flex-column"
					style={{
						padding: "8px 4px",
						width: "247px ",
						backgroundColor: "white",
						borderRadius: "8px",
					}}
				>
					{data[component.valueKey].map((el, index) => (
						<>
							<div className="d-flex flex-column px-1">
								<Typography
									variant="body_2_bold"
									color="secondary_grey_2"
									style={{ textAlign: "left" }}
								>
									{el.title}
								</Typography>
								<div
									className="d-flex align-items-center"
									style={{ marginTop: 2 }}
								>
									<Typography
										variant="body_3_regular"
										color="secondary_grey_3"
									>{`${dayjs(el.event_date).format(
										"DD MMM YYYY"
									)} |`}</Typography>
									<Typography
										variant="body_3_bold"
										style={{
											color: riskColors[
												getValue(el.impact)
											],
											marginLeft: 2,
										}}
									>
										{capitalizeFirstLetter(
											getValue(el.impact)
										)}{" "}
										Risk
									</Typography>
								</div>
							</div>
							{index < data[component.valueKey].length - 1 && (
								<div
									style={{
										margin: "8px 2px",
										height: 1,
										background: "#EBEBEB",
										borderRadius: 8,
									}}
								/>
							)}
						</>
					))}
				</div>
			) : (
				<></>
			)}
		</>
	);
}

export function CustomAppSecurityCompliancePopup({ component, data }) {
	const [proof, setProof] = useState();
	if (data[component.valueKey] === "available") {
		return (
			<div
				className="d-flex flex-column"
				style={{
					width: "220px ",
					backgroundColor: "white",
					borderRadius: "8px",
				}}
			>
				<div
					className="font-10 bold-700"
					style={{ padding: "8px 16px 6px", textAlign: "left" }}
				>
					Add Proof
				</div>
				<div className="" style={{ padding: "0px 12px 10px" }}>
					<Form.Control
						type="text"
						className="chip__input license_name_v2_input_box"
						placeholder={`Add URL`}
						value={proof}
						onChange={(e) => {
							setProof(e.target.value);
						}}
					/>
				</div>
				<div
					className="d-flex align-items-center"
					style={{ gap: "4px", padding: "0px 8px 8px" }}
				>
					<div
						className="d-flex align-items-center justify-content-center w-100 grey-1 font-10 cursor-pointer"
						onClick={() => {
							setProof("");
						}}
						style={{
							height: "32px",
							background: "#EBEBEB",
							borderRadius: "8px",
						}}
					>
						Cancel
					</div>
					<div
						className="d-flex align-items-center justify-content-center w-100  font-10 cursor-pointer"
						style={{
							height: "32px",
							background: "#5287E8",
							borderRadius: "8px",
							color: "white",
						}}
						onClick={() => {}}
					>
						Confirm
					</div>
				</div>
			</div>
		);
	}
	if (data[component.valueKey] === "validated") {
		return (
			<div
				className="d-flex align-items-center"
				style={{
					width: "220px",
					backgroundColor: "#F6F7FA",
					borderRadius: "8px",
					padding: "8px 14px",
				}}
			>
				<img src={validated}></img>
				<div className="ml-1 grey font-9" style={{ textAlign: "left" }}>
					Marked validated by John Doe on 12 Nov ‘22
				</div>
			</div>
		);
	}
	return <></>;
}

function DropdownIconTextCell({ isSelected = false, icon, text, onClick }) {
	return (
		<div
			className={`d-flex align-items-center cursor-pointer custom_risk_select_option ${
				isSelected
					? "custom_risk_select_option_selected"
					: "custom_risk_select_option_unselected"
			} justify-content-between`}
			onClick={onClick}
		>
			<div className="d-flex align-items-center">
				<img
					alt={text}
					src={icon}
					width={16}
					height={16}
					style={{ margin: "0px 6px" }}
				/>
				<Typography
					variant="button_extrasmall_medium"
					color={isSelected ? "secondary_grey_3" : "secondary_grey_`"}
				>
					{text}
				</Typography>
			</div>
			{isSelected && (
				<img alt="Tick Icon" src={tick} style={{ marginRight: 6 }} />
			)}
		</div>
	);
}
DropdownIconTextCell.propTypes = {
	isSelected: PropTypes.bool,
	icon: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	onClick: PropTypes.func,
};

export function CustomRiskSetupPopup({ component, data, ...props }) {
	const [riskSelectionActive, setRiskSelectionActive] = useState(false);
	const riskValue =
		data[component.valueKey] <= 1
			? "low"
			: data[component.valueKey] <= 3 && data[component.valueKey] >= 2
				? "medium"
				: "high";
	const riskColors = {
		low: "#5FCF64",
		medium: "#FFA217",
		high: "#FE6955",
	};

	const riskIcons = {
		low: LowRisk,
		medium: MediumRisk,
		high: HighRisk,
	};
	const options = [
		{
			label: "High Risk",
			value: "high",
			color: "invert(50%) sepia(64%) saturate(2311%) hue-rotate(329deg) brightness(109%) contrast(99%)",
		},
		{
			label: "Medium Risk",
			value: "medium",
			color: "invert(100%) sepia(61%) saturate(2383%) hue-rotate(310deg) brightness(108%) contrast(104%)",
		},
		{
			label: "Low Risk",
			value: "low",
			color: "invert(64%) sepia(94%) saturate(288%) hue-rotate(72deg) brightness(93%) contrast(87%)",
		},
	];

	const handleClick = (updatedValue) => {
		if (riskValue === updatedValue) return;

		const valueMapper = {
			low: 1,
			medium: 3,
			high: 5,
		};
		const actualValueToSend = valueMapper[updatedValue];

		try {
			const patchObj = {
				patches: [
					{
						op: "replace",
						field: component.patchKey,
						value: actualValueToSend,
					},
				],
			};
			props.handleUpdate(data.app_id, patchObj, {
				[component.valueKey]: actualValueToSend,
			});
		} catch (err) {
			console.error("Error updating application owner:", err);
		}
	};
	return (
		<div
			className="d-flex flex-column"
			style={{
				width: "220px ",
				backgroundColor: "white",
				borderRadius: "8px",
			}}
		>
			{riskSelectionActive ? (
				<>
					<div
						className="d-flex align-items-center p-2"
						style={{
							background: "#F6F7FA",
							borderRadius: "8px 8px 0px 0px",
						}}
						onClick={() => {
							setRiskSelectionActive(false);
						}}
					>
						<img
							src={LeftIcon}
							alt="back"
							style={{ marginRight: 6 }}
						/>
						<Typography
							variant="body_1_bold"
							color="secondary_grey_1"
						>
							Change Risk Level
						</Typography>
					</div>
					<div>
						{options.map((el) => (
							<DropdownIconTextCell
								key={el.value}
								isSelected={el.value === riskValue}
								icon={riskIcons[el.value]}
								text={el.label}
								onClick={() => handleClick(el.value)}
							/>
						))}
					</div>
				</>
			) : (
				<>
					<div
						className="d-flex align-items-center justify-content-between p-2"
						style={{
							background: "#F6F7FA",
							borderRadius: "8px 8px 0px 0px",
						}}
					>
						{data?.app_risk_set_manually ? (
							<>
								<div className="d-flex align-items-center">
									<img
										src={riskIcons[riskValue]}
										alt=""
										width="16px"
										height="16"
										className="mr-1"
									/>
									<div className="d-flex flex-column align-items-start">
										<Typography
											variant="body_2_bold"
											color="secondary_grey_2"
										>
											{capitalizeFirstLetter(riskValue)}{" "}
											Risk
										</Typography>
										<Typography
											variant="body_3_regular"
											color="secondary_grey_3"
										>
											Set by John Doe
										</Typography>
									</div>
								</div>
								<div className="d-flex flex-column align-items-end">
									<Typography
										variant="body_3_regular"
										color="secondary_grey_3"
									>
										Set on
									</Typography>
									<Typography
										variant="body_3_bold"
										color="secondary_grey_3"
									>
										{moment(new Date()).format(
											"DD MMM 'YY"
										)}
									</Typography>
								</div>
							</>
						) : (
							<>
								<div className="d-flex align-items-center">
									<Typography
										variant="body_2_regular"
										color="secondary_grey_2"
										className="mr-1"
									>
										App Identified as
									</Typography>
									<Typography
										variant="body_2_bold"
										style={{ color: riskColors[riskValue] }}
									>
										{capitalizeFirstLetter(riskValue)} Risk
									</Typography>
								</div>
								<div>
									<img
										src={riskIcons[riskValue]}
										alt=""
										width="16px"
										height="16"
									/>
								</div>
							</>
						)}
					</div>
					<div
						className="p-2 d-flex flex-column align-items-start"
						style={{ gap: "4px" }}
					>
						{data?.app_risk_set_manually ? (
							<>
								<div className="d-flex align-items-center">
									<Typography
										variant="body_3_medium"
										color="secondary_grey_2"
										style={{ marginRight: 2 }}
									>
										{replaceZluriWithPartnerName(
											"Zluri Identifies App as"
										)}
									</Typography>
									<Typography
										variant="body_3_bold"
										style={{ color: riskColors[riskValue] }}
									>
										{capitalizeFirstLetter(riskValue)} Risk
									</Typography>
								</div>
								<Typography
									variant="body_3_medium"
									color="secondary_grey_3"
									style={{ textAlign: "left" }}
								>
									Based on publicly available security and
									compliance data.
								</Typography>
							</>
						) : (
							<Typography
								variant="body_3_regular"
								color="secondary_grey_3"
								style={{ textAlign: "left" }}
							>
								{replaceZluriWithPartnerName(
									"Zluri identifies risk-level based on publicly available security and compliance data."
								)}
							</Typography>
						)}
					</div>
					<div
						className="common-tags__box common-tags__box-primary cursor-pointer d-flex align-items-center justify-content-center"
						onClick={() => {
							setRiskSelectionActive(true);
						}}
						style={{
							height: 32,
							borderRadius: 8,
							width: 204,
							margin: "0px 0px 8px 8px",
						}}
					>
						<Typography
							variant="body_3_regular"
							color="primary_blue_dark_1"
						>
							Change Risk-Level
						</Typography>
					</div>
				</>
			)}
		</div>
	);
}

export function CustomAppQuality({ component, data }) {
	const styles = {
		low: {
			color: "#BB2915",
			background: "#FFE9E5",
		},
		medium: {
			background: "#FFF6DC",
			color: "#C97B07",
		},
		high: {
			color: "#009307",
			background: "#E7F8E8",
		},
	};

	return (
		<>
			{data[component.valueKey] ? (
				<NumberPill
					number={capitalizeFirstLetter(data[component.valueKey])}
					style={{ padding: "8px", width: "fit-content" }}
					borderRadius="4px"
					fontSize="12px"
					fontWeight="500"
					fontColor={styles[data[component.valueKey]]?.color}
					pillBackGround={
						styles[data[component.valueKey]]?.background
					}
				/>
			) : (
				<>-</>
			)}
		</>
	);
}

export function CustomCertificationAction({
	component,
	data,
	handleUpdate,
	resetState,
	...props
}) {
	return (
		<ReviewActionComment
			component={component}
			data={data}
			handleUpdate={handleUpdate}
			resetState={resetState}
			{...props}
		/>
	);
}

export function MarkAsComplete({
	component,
	data,
	handleUpdate,
	resetState,
	...props
}) {
	const userInfo = useSelector((state) => state.userInfo);
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const updateFunc = (status) => {
		setLoading(true);
		let certification_id = getSegmentFromURL(3);
		let patchObj = {
			access_certification_user_id: data.access_certification_user_id,
			task_status: "complete",
		};

		patchTaskStatus(certification_id, data.app_id, patchObj)
			.then((res) => {
				dispatch({
					type: "RECORD_UPDATED",
					payload: {
						index: props.rowIndex,
						value: { ...data, task_status: status },
						entity: props.entity,
						rowIndex: props.rowIndex,
						columnIndex: props.columnIndex,
						isInfinite: true,
					},
				});
				setLoading(false);
			})
			.catch((err) => {
				console.log(err);
				setLoading(false);
			});
	};

	let mapper = [
		{
			type: "approved",
			style: {
				height: "fit-content",
				width: "fit-content",
				borderRadius: "8px ",
				padding: "3px",
			},
			hoverStyle: {
				background: "#E7F8E8",
				border: "1px solid #A3E4A6",
			},
			img: squaregreen,
			tooltip: "approve",
		},
	];
	const [activeOption, setActiveOption] = useState();
	const getStyle = (index) => {
		let tempObj = {};
		if (index === activeOption) {
			tempObj = { ...mapper[index].style, ...mapper[index].hoverStyle };
		} else {
			tempObj = {
				...mapper[index].style,
			};
		}
		return tempObj;
	};
	const getColor = () => {
		return data[component.valueKey] === "complete" ? "#E7F8E8" : "#EBEBEB";
	};

	return (
		<>
			{loading ? (
				<>
					<ProgressBarLoader
						style={{ marginTop: "-2px" }}
						height={20}
						width={20}
					/>
				</>
			) : data[component.valueKey] === "pending" &&
			  userInfo.user_id === data.task_assignee_id ? (
				<>
					<div className="d-flex align-items-center">
						{mapper.map((el, index) => (
							<>
								<div
									className={`d-flex align-items-center justify-content-center`}
									style={getStyle(index)}
									onMouseEnter={() => {
										setActiveOption(index);
									}}
									onMouseLeave={() => {
										setActiveOption(null);
									}}
									onClick={() => {
										updateFunc(el.type);
									}}
								>
									<img
										className="cursor-pointer mr-1"
										src={el.img}
									></img>
									Mark as Complete
								</div>
							</>
						))}
					</div>
				</>
			) : (
				<>
					<div
						className="d-flex align-items-center"
						style={{
							height: "32px",
							padding: "8px",
							borderRadius: "8px",
							backgroundColor: getColor(),
							color: "black",
						}}
					>
						{data[component.valueKey] !== "pending" && (
							<>
								<img src={squaregreen} className="mr-1"></img>
							</>
						)}
						{capitalizeFirstLetter(data[component.valueKey])}
					</div>
				</>
			)}
		</>
	);
}

export function AutomatedActionStatus({
	component,
	data,
	handleUpdate,
	resetState,
}) {
	const imgMapper = {
		completed: squaregreen,
		failed: squarered,
		pending: pending,
		completed_with_errors: yellowerror,
	};
	const dispatch = useDispatch();

	const { review } = useSelector((state) => state.accessReview);

	const concludeReviewJobStatus = review?.data?.concludeReviewJobStatus;

	const conculdeReviewJobPending = ["pending"].includes(
		concludeReviewJobStatus
	);

	const handleOnClick = () => {
		let metaData = {};
		metaData.filter_by = [
			{
				field_id: "run_timestamp",
				field_name: "Run On",
				field_values: [data?.run_timestamp],
				filter_type: "date_range",
				field_order: ["eq"],
				negative: false,
				is_custom: false,
				timestamp_type: true,
			},
			{
				field_id: "user_name",
				field_name: "User",
				field_values: [data?.user_name],
				filter_type: "search_in_string",
				field_order: "contains",
				negative: false,
				is_custom: false,
			},
			{
				field_id: "workflow_template_id",
				field_name: "Playbook Id",
				field_values: [data?.workflow_template_id],
				filter_type: "objectId",
				negative: false,
				is_custom: false,
			},
		];
		dispatch(
			push(
				`/applications/${data?.app_id}?metaData=${JSON.stringify(
					metaData
				)}&selectedTab=runs#automation`
			)
		);
	};
	const isEmployee = getSegmentFromURL(1) === "user";

	return conculdeReviewJobPending ? (
		<div className="d-flex gap-8 align-items-center">
			<Loader color="blue" />
			<Typography variant={BODY_VARIANT.BODY_2_MEDIUM}>
				Data Processing In Progress
			</Typography>
		</div>
	) : (
		<div className="w-100">
			<span
				onClick={() => {
					if (
						data[component?.valueKey] ===
							actionTextMapper?.not_required ||
						isEmployee
					)
						return;
					handleOnClick();
				}}
				className="v2table__cell-text cursor-pointer ml-2 d-flex "
			>
				<img
					src={imgMapper[data[component?.valueKey]]}
					className="mr-2"
					alt=""
				></img>
				{capitalizeFirstLetterOfUnderscoreString(
					data[component?.valueKey]
				)}
				{data[component.valueKey] !== actionTextMapper?.not_required &&
					!isEmployee && (
						<img
							className="ml-auto v2table__cell-empty-link-icon"
							width={"15px"}
							src={arrowConnect}
							alt=""
						/>
					)}
			</span>
		</div>
	);
}

export function CustomTagsRenderer({
	data,
	handleUpdate,
	component,
	...props
}) {
	const key = component?.valueKey;
	const value = data[key]?.[0];

	if (!value) return null;
	return (
		<>
			<button className={cn("z__tags_v2-pill")}>
				<Typography variant="button_extrasmall_regular" color="#5287E8">
					{value}
				</Typography>
			</button>
		</>
	);
}

export function CustomTagsPopover({ data, handleUpdate, component, ...props }) {
	const key = component?.valueKey;
	const dataValue = data[key];

	const [tags, setTags] = useState(dataValue);

	const [inputValue, setInputValue] = useState("");
	const handleSubmit = () => {
		const patchObj = {
			patches: [
				{
					op: "replace",
					field: "tags",
					value: tags,
				},
			],
		};

		handleUpdate(data._id, patchObj, { app_tags: tags });
	};

	return (
		<div>
			<div
				style={{
					height: 37,
					display: "flex",
					flexDirection: "column",
					background: "#F6F7FA",
					padding: 8,
				}}
			>
				<Typography variant="body_1_bold">Tags</Typography>
			</div>
			<div style={{ padding: 8 }}>
				<div className="flex gap-4 flex-wrap">
					{tags?.map((value, index) => {
						return (
							<button
								className={cn("z__tags_v2-pill")}
								key={value + index}
								onClick={() =>
									setTags((_t) => {
										return _t.filter((d) => d !== value);
									})
								}
							>
								<Typography
									variant="button_extrasmall_regular"
									color="#5287E8"
								>
									{value}
								</Typography>
								<img src={closeIcon} alt="close icon" />
							</button>
						);
					})}
				</div>
			</div>
			{tags.length > 0 && (
				<div
					style={{ paddingRight: 8, paddingLeft: 8, marginBottom: 8 }}
				>
					<div className="separator" />
				</div>
			)}
			<div
				style={{ padding: 8, paddingTop: 0 }}
				className="flex flex-column gap-4"
			>
				<Field
					handlers={{
						onChange: (e) => {
							setInputValue(e.target.value);
						},
						onKeyDown: (e) => {
							if (e.key === "Enter" && inputValue) {
								setTags((_t) => [..._t, inputValue]);
								setInputValue("");
							}
						},
					}}
					prefixIcon={tagIcon}
					type="text"
					fieldType="input"
					value={inputValue}
					placeholder="Add Tags"
					className="font-11 bold-400 grey-1"
				/>
				<div className="flex gap-1">
					<Typography
						variant="alerttag_extrasmall_medium"
						color="#717171"
					>
						Press ENTER after typing to add a tag.
					</Typography>
				</div>
			</div>
			<div
				style={{ padding: "6px 8px 8px 8px" }}
				className="flex gap-2 justify-content-between"
			>
				<Button
					variant="primary-alternative"
					onClick={() => props.hidePopup()}
					style={{ minWidth: 98 }}
				>
					Cancel
				</Button>
				<Button
					theme={theme}
					variant="primary-default"
					style={{ minWidth: 98 }}
					disabled={!!inputValue}
					onClick={handleSubmit}
				>
					Save
				</Button>
			</div>
		</div>
	);
}

function InfoIcon() {
	return (
		<div
			style={{
				height: "100%",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			}}
		>
			<svg
				width="4"
				height="10"
				viewBox="0 0 4 10"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M3.375 1.7625C3.375 1.96028 3.31635 2.15362 3.20647 2.31807C3.09659 2.48252 2.94041 2.61069 2.75768 2.68638C2.57496 2.76206 2.37389 2.78187 2.17991 2.74328C1.98593 2.7047 1.80775 2.60946 1.66789 2.4696C1.52804 2.32975 1.4328 2.15157 1.39421 1.95759C1.35563 1.76361 1.37543 1.56254 1.45112 1.37981C1.52681 1.19709 1.65498 1.04091 1.81943 0.931027C1.98388 0.821146 2.17722 0.762497 2.375 0.762497C2.64022 0.762497 2.89457 0.867854 3.08211 1.05539C3.26964 1.24293 3.375 1.49728 3.375 1.7625ZM1.875 8.4875V5.5025C1.875 5.43619 1.84866 5.3726 1.80178 5.32572C1.75489 5.27884 1.6913 5.2525 1.625 5.2525H1.375C1.17609 5.2525 0.985322 5.17348 0.844669 5.03283C0.704017 4.89217 0.625 4.70141 0.625 4.5025C0.625 4.30359 0.704017 4.11282 0.844669 3.97217C0.985322 3.83152 1.17609 3.7525 1.375 3.7525H2.62C2.81891 3.7525 3.00968 3.83152 3.15033 3.97217C3.29098 4.11282 3.37 4.30359 3.37 4.5025V8.4875C3.37 8.68641 3.29098 8.87717 3.15033 9.01783C3.00968 9.15848 2.81891 9.2375 2.62 9.2375C2.42109 9.2375 2.23032 9.15848 2.08967 9.01783C1.94902 8.87717 1.87 8.68641 1.87 8.4875H1.875Z"
					fill="#717171"
				/>
			</svg>
		</div>
	);
}

export function AppRealizedSavingsAction({
	component,
	data,
	rowIndex,
	columnIndex,
	entity,
}) {
	const dispatch = useDispatch();

	const openRealizedSavingUsersBreakdown = () => {
		dispatch(
			openModal("app_realized_savings_users_breakdown_v2", {
				date: data.date,
			})
		);
	};
	return (
		<div>
			<div className="d-flex align-items-center">
				<TextPill
					onClick={openRealizedSavingUsersBreakdown}
					background="#E8F0FC"
					borderRadius={8}
					color="#2266E2"
					fontVariant="button_extrasmall_regular"
					text="View Details"
				/>
			</div>
		</div>
	);
}
