import React from "react";
import PropTypes from "prop-types";
import {
	BUTTON_VARIANT,
	GREY_VARIANT,
	ImageOrNameBadge,
	Tooltip,
	Typography,
} from "@zluri/ui-components";

import iconTick from "assets/check-filled.svg";

export const AppUserOption = ({
	id,
	name,
	imageSrc,
	handleSelect,
	selectedId,
}) => {
	const optionIsSelected = selectedId === id;

	return (
		<div
			className={`d-flex app-about-select-container ${
				optionIsSelected && "blue_tint_bg"
			}`}
			onClick={() => handleSelect && handleSelect()}
			style={{ marginBlock: 2 }}
		>
			<div className="d-flex app-about-select-container-left">
				<ImageOrNameBadge
					name={name}
					url={imageSrc}
					height={16}
					width={16}
				/>
				<Tooltip
					placement="top"
					content={
						name && (
							<Typography
								variant="body_2_regular"
								color="white"
								className="px-1"
							>
								{name}
							</Typography>
						)
					}
				>
					<Typography
						color={GREY_VARIANT.SECONDARY_GREY_2}
						variant={BUTTON_VARIANT.BUTTON_EXTRA_SMALL_MEDIUM}
						className="text-truncate-small"
						style={{
							maxWidth: "180px",
						}}
					>
						{name ?? "-"}
					</Typography>
				</Tooltip>
			</div>
			<div className="app-about-select-container-right">
				{optionIsSelected && (
					<img src={iconTick} alt="check" width={14} height={12} />
				)}
			</div>
		</div>
	);
};

AppUserOption.propTypes = {
	id: PropTypes.string,
	name: PropTypes.string,
	imageSrc: PropTypes.string,
	selectedId: PropTypes.string,
	handleSelect: PropTypes.func,
};
