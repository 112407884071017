import React, { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { isUndefined } from "underscore";

export default function OverlayTooltip({
	isStickyTooltip,
	hide,
	alwaysShow,
	controlledShow,
	wrapperStyles,
	...props
}) {
	const [show, setShow] = useState(false);
	const handleMouseEnter = () => {
		isStickyTooltip && setShow(true);
		isStickyTooltip &&
			props.setTooltipArrowShow &&
			props.setTooltipArrowShow(true);
	};
	const handleMouseLeave = () => {
		isStickyTooltip && setShow(false);
		isStickyTooltip &&
			props.setTooltipArrowShow &&
			props.setTooltipArrowShow(false);
	};

	return (
		<OverlayTrigger
			{...props}
			overlay={
				props.isCustomTooltip ? (
					<Tooltip className={`${props.className || ""} v2-tooltip`}>
						<div
							onMouseEnter={handleMouseEnter}
							onMouseLeave={handleMouseLeave}
						>
							{props.overlay}
						</div>
					</Tooltip>
				) : (
					<div
						onMouseEnter={handleMouseEnter}
						onMouseLeave={handleMouseLeave}
					>
						{props.overlay}
					</div>
				)
			}
			delay={500}
			show={
				!isUndefined(alwaysShow)
					? alwaysShow
					: hide
					  ? false
					  : isStickyTooltip
					    ? show
					    : undefined
			}
		>
			<div
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				style={{ ...(wrapperStyles ?? {}) }}
			>
				{props.children}
			</div>
		</OverlayTrigger>
	);
}
