import { client, clientV2 } from "utils/client";

export async function getSingleApplicationDetailsFromHotDB({ appId }) {
	const response = await client.get(`applications/hot/${appId}`);
	return response.data;
}

export async function getApplicationActualSpendVsComputedCost(
	appId,
	period = "month",
	start_month,
	start_year,
	end_month,
	end_year
) {
	const response = await clientV2.get(
		`applications/${appId}/spend?period=${period}&start_month=${start_month}&start_year=${start_year}&end_month=${end_month}&end_year=${end_year}`
	);
	return response.data;
}

export async function patchCustomFieldForAnApplication({ appId, patchObj }) {
	const response = await client.patch(`applications/${appId}`, patchObj);
	return response.data;
}

export async function getSingleApplicationChargeback({
	appId,
	deptId,
	startMonth,
	startYear,
	endMonth,
	endYear,
}) {
	try {
		let url = `applications/${appId}/chargeback/dept-spend-chart`;
		let query;
		if (deptId === "parent") {
			query = `startMonth=${startMonth}&startYear=${startYear}&endMonth=${endMonth}&endYear=${endYear}`;
		} else {
			query = `startMonth=${startMonth}&startYear=${startYear}&endMonth=${endMonth}&endYear=${endYear}&parentId=${deptId}`;
		}
		const response = await clientV2.get(`${url}?${query}`);
		return response.data;
	} catch (error) {
		throw error;
	}
}

export async function getSingleApplicationAssociation({ appId }) {
	const response = await client.get(`applications/${appId}/app-associations`);
	return response.data;
}

export async function getSingleApplicationSourceSettings({ appId }) {
	const response = await client.get(`applications/${appId}/source/settings`);
	return response.data;
}

export async function getSingleApplicationDepartmentSplits({ appId }) {
	const response = await client.get(`applications/${appId}/department/split`);
	return response.data;
}

export async function getSingleApplicationSpendTriggerDetails({ appId }) {
	const response = await client.get(`applications/${appId}/spend/trigger`);
	return response.data;
}

export async function getSingleApplicationDepartmentWiseSpend({
	appId,
	deptId,
	startMonth,
	startYear,
	endMonth,
	endYear,
}) {
	let url = `applications/${appId}/chargeback/actual-spend-chart`;
	let query;
	if (deptId === "parent") {
		query = `startMonth=${startMonth}&startYear=${startYear}&endMonth=${endMonth}&endYear=${endYear}`;
	} else {
		query = `startMonth=${startMonth}&startYear=${startYear}&endMonth=${endMonth}&endYear=${endYear}&parentId=${deptId}`;
	}
	const response = await clientV2.get(`${url}?${query}`);
	return response.data;
}
