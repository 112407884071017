import React, { useState } from "react";
import edit from "assets/icons/edit.svg";
import { Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { contractReqBody } from "modules/licenses/constants/LicenseConstants";
import { getBulkUploadRecord } from "modules/BulkUpload/service/BulkUpload.api";
import { getFormDraftFromProcessData } from "modules/licenses/utils/LicensesUtils";
import { getAIFilledBasicDetailsFields } from "modules/licenses/utils/LicensesUtils";
import { bulkUploadStatuses } from "modules/BulkUpload/constants/BulkUpload.constants";
import { getBulkUploadProcessedRecords } from "modules/BulkUpload/service/BulkUpload.api";
import { ApiResponseNotification } from "modules/shared/components/ApiResponseNotification/ApiResponseNotification";
import { apiResponseTypes } from "modules/shared/components/ApiResponseNotification/ApiResponseNotificationConstants";

export default function ContractDraftEditButton({
	bulkUpload,
	bulkUploadId,
	fromNotification,
}) {
	const history = useHistory();
	const [fetchingDraft, setFetchingDraft] = useState(false);

	const fetchDraftAndRedirect = () => {
		setFetchingDraft(true);
		if (!bulkUpload) {
			getBulkUploadRecord({ bulk_upload_id: bulkUploadId }).then(
				(res) => {
					bulkUpload = res;
					if (res.status !== "completed") {
						ApiResponseNotification({
							title: "The contract for this draft has already been added!",
						});
						return;
					}
					if (res.retries > 3) {
						ApiResponseNotification({
							responseType: apiResponseTypes.ERROR,
							title: "Maximum retry limit has been reached!",
						});
						return;
					}
					fetchProcessBulkRecordAndRedirect();
				}
			);
		} else {
			fetchProcessBulkRecordAndRedirect();
		}
	};

	const fetchProcessBulkRecordAndRedirect = () => {
		getBulkUploadProcessedRecords({ bulk_upload_id: bulkUploadId })
			.then((res) => {
				if (bulkUpload.meta.enable_ai_processing) {
					const processBulkUploadId = res[0]._id;
					const data = res[0].data.processed_data;
					const draft =
						res[0].data.draft ??
						getFormDraftFromProcessData(data, bulkUploadId);
					if (!res[0].data.draft && Array.isArray(draft.documents)) {
						draft.documents.push({
							source_url: bulkUpload.file_source_url,
							name: bulkUpload.file_name,
							type: bulkUpload.file_type,
							doc_type: "contract",
						});
					}
					const processedBasicDetailsFields =
						getAIFilledBasicDetailsFields(data);
					history.push({
						pathname: `contract/pdf/draft/${bulkUploadId}`,
						state: {
							draft,
							processBulkUploadId,
							processedBasicDetailsFields,
							file_source_url: bulkUpload.file_source_url,
							ai_processed: bulkUpload.meta.enable_ai_processing,
						},
					});
				} else {
					const draft = {
						...contractReqBody,
						bulk_upload_id: bulkUploadId,
					};
					history.push({
						pathname: `contract/pdf/draft/${bulkUploadId}`,
						state: {
							draft,
							file_source_url: bulkUpload.file_source_url,
							ai_processed: bulkUpload.meta.enable_ai_processing,
						},
					});
				}
				setFetchingDraft(false);
			})
			.catch((err) => {
				ApiResponseNotification({
					responseType: apiResponseTypes.ERROR,
					title: "Server Error! There was an error while fetching contract draft.",
					errorObj: err,
				});
				setFetchingDraft(false);
			});
	};

	const buttonAndSpinnerComponent = (
		<>
			{fetchingDraft ? (
				<Spinner
					animation="border"
					role="status"
					variant="dark"
					style={{
						borderWidth: 2,
						borderColor: "#2262e2 transparent #2262e2 #2262e2",
						height: "16px",
						width: "16px",
					}}
				/>
			) : (
				<img
					src={edit}
					className="cursor-pointer"
					height={16}
					width={16}
					onClick={() =>
						history.push(`/contract/pdf/draft/${bulkUploadId}`)
					}
				/>
			)}
		</>
	);

	return (
		<>
			{fromNotification ? (
				<>
					<div className="contract_uploads_status_column">
						{
							bulkUploadStatuses[
								bulkUploadStatuses.completed.value
							].pdf
						}
						{buttonAndSpinnerComponent}
					</div>
				</>
			) : bulkUpload?.file_type === "pdf" &&
			  bulkUpload?.status === bulkUploadStatuses.completed.value ? (
				buttonAndSpinnerComponent
			) : null}
		</>
	);
}
