import { BUTTON_VARIANT, GREY_VARIANT, Typography } from "@zluri/ui-components";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { getCfFieldData } from "modules/applications/utils/appAbout.utils";

import closeIcon from "assets/v2tables/pageHeader/close.svg";
import searchIcon from "assets/icons/blue-search.svg";
import checkBlueIcon from "assets/check-filled.svg";

export function CustomSelectEditPopover({ data, field_id, ...rest }) {
	const initialOptions = getCfFieldData(
		field_id,
		data?.appCustomFields
	)?.options?.map((field) => ({ value: field, label: field }));
	const [options, setOptions] = useState(initialOptions);
	const [inputText, setInputText] = useState("");

	useEffect(() => {
		if (!inputText) setOptions(initialOptions);
		else
			setOptions(
				initialOptions.filter((option) =>
					option.value
						.toLowerCase()
						.includes(inputText.trim().toLowerCase())
				)
			);
	}, [inputText]);

	const handleCustomFieldEdit = (option) =>
		rest?.handleCustomFieldEdit(data?.[field_id] ? "replace" : "add", {
			id: field_id,
			value: option.value,
			name: getCfFieldData(field_id, data?.appCustomFields)?.name,
		});

	return (
		<div className="d-flex gap-2 app-about-popover-select-container padding_2">
			<div
				className="app-about-text-edit-container primary-color-border"
				style={{ marginBottom: "2px", height: 28 }}
			>
				<img src={searchIcon} alt="close" height={12} width={12} />
				<input
					type="text"
					value={inputText}
					onChange={(e) => {
						setInputText(e.target.value);
					}}
					placeholder="Search"
					className="app-about-text-search"
				/>
				<img
					onClick={() => {
						setInputText("");
					}}
					src={closeIcon}
					alt="close"
					className="cursor-pointer"
					height={12}
					width={12}
				/>
			</div>
			{options?.map((option, idx) => {
				return (
					<div
						className="d-flex align-center justify-between app-about-select-container"
						style={{
							backgroundColor:
								option?.value === data?.[field_id] && "#E8F0FC",
						}}
						key={option?.value ?? idx}
						onClick={() => handleCustomFieldEdit(option)}
					>
						<div className="d-flex align-center gap-4 app-about-select-container-left">
							<Typography
								color={GREY_VARIANT.SECONDARY_GREY_2}
								variant={
									BUTTON_VARIANT.BUTTON_EXTRA_SMALL_MEDIUM
								}
							>
								{option?.label ?? "-"}
							</Typography>
						</div>
						<div className="app-about-select-container-right">
							{option?.value === data?.[field_id] && (
								<img
									src={checkBlueIcon}
									alt="check"
									width={14}
									height={12}
								/>
							)}
						</div>
					</div>
				);
			})}
			{options?.length < 1 && (
				<div
					className="d-flex justify-content-center align-items-center"
					style={{ height: 30 }}
				>
					<Typography
						color={GREY_VARIANT.SECONDARY_GREY_4}
						variant={BUTTON_VARIANT.BUTTON_EXTRA_SMALL_MEDIUM}
						className="font-italic"
						style={{ fontSize: 12 }}
					>
						No Results Found!
					</Typography>
				</div>
			)}
		</div>
	);
}

CustomSelectEditPopover.propTypes = {
	data: PropTypes.shape({
		appCustomFields: PropTypes.array,
	}),
	field_id: PropTypes.string,
};
