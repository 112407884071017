import { BODY_VARIANT, GREY_VARIANT, Typography } from "@zluri/ui-components";
import moment from "moment/moment";
import React from "react";
import loadable from "@loadable/component";
const MomentTimezone = loadable.lib(() => import("moment-timezone"));

export default function CustomRenderingData(props) {
	const { data, component } = props;
	const timeZoneString = Intl.DateTimeFormat().resolvedOptions().timeZone;
	return (
		<div
			className="d-flex flex-column align-items-start border-radius-8 white_bg w-20 p-2"
			style={{ ...component?.style }}
		>
			<Typography
				variant={BODY_VARIANT.BODY_2_REGULAR}
				color={GREY_VARIANT.SECONDARY_GREY_1}
			>
				{data[component?.valueKey]}
			</Typography>
			<hr className="outer-border-thin mt-2 mb-2 ml-0 w-100" />
			<Typography
				variant={BODY_VARIANT.BODY_2_REGULAR}
				color={GREY_VARIANT.SECONDARY_GREY_1}
			>
				<MomentTimezone>
					{({ default: moment }) => {
						moment(data[component?.secondValueKey])
							.tz(timeZoneString)
							.format("DD MMM, YYYY, HH: mm z");
					}}
				</MomentTimezone>
			</Typography>
		</div>
	);
}
