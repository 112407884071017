export const overviewConstants = {
	TOP_ROW_REQUESTED: "TOP_ROW_REQUESTED",
	TOP_ROW_REQUESTED_TOTAL_SPENDS: "TOP_ROW_REQUESTED_TOTAL_SPENDS",
	TOP_ROW_FETCHED: "TOP_ROW_FETCHED",
	TROW_FETCHED_TOTAL_SPENDS: "FETCHED_TOTAL_SPENDS",
	BUDGET_REQUESTED: "BUDGET_REQUESTED",
	BUDGET_FETCHED: "BUDGET_FETCHED",
	MINIGRAPHS_REQUESTED: "MINIGRAPHS_REQUESTED",
	MINIGRAPHS_FETCHED: "MINIGRAPHS_FETCHED",
	MINIGRAPHS_REQUESTED_SPENDS_MONTH: "MINIGRAPHS_REQUESTED_SPENDS_MONTH",
	MINIGRAPHS_FETCHED_SPENDS_MONTH: "MINIGRAPHS_FETCHED_SPENDS_MONTH",
	MINIGRAPHS_REQUESTED_USER_SPENDS: "MINIGRAPHS_REQUESTED_USER_SPENDS",
	MINIGRAPHS_FETCHED_USER_SPENDS: "MINIGRAPHS_FETCHED_USER_SPENDS",
	MINIGRAPHS_REQUESTED_CONTRACT_COSTS: "MINIGRAPHS_REQUESTED_CONTRACT_COSTS",
	MINIGRAPHS_FETCHED_CONTRACT_COSTS: "MINIGRAPHS_FETCHED_CONTRACT_COSTS",
	APPLICATIONS_REQUESTED: "APPLICATIONS_REQUESTED",
	APPLICATIONS_FETCHED: "APPLICATIONS_FETCHED",
	SPENDTREND_REQUESTED: "SPENDTREND_REQUESTED",
	SPENDTREND_FETCHED: "SPENDTREND_FETCHED",
	ORG_ONBOARDING_STATUS_REQUESTED: "ORG_ONBOARDING_STATUS_REQUESTED",
	ORG_ONBOARDING_STATUS_FETCHED: "ORG_ONBOARDING_STATUS_FETCHED",
	REMOVE_ORG_ONBOARDING_STATUS: "REMOVE_ORG_ONBOARDING_STATUS",
	DEPARTMENT_SPEND_DATA_REQUESTED: "DEPARTMENT_SPEND_DATA_REQUESTED",
	DEPARTMENT_SPEND_DATA_FETCHED: "DEPARTMENT_SPEND_DATA_FETCHED",
	REMOVE_DEPARTMENT_SPEND_DATA: "REMOVE_DEPARTMENT_SPEND_DATA",
	CATEGORY_SPEND_DATA_REQUESTED: "CATEGORY_SPEND_DATA_REQUESTED",
	CATEGORY_SPEND_DATA_FETCHED: "CATEGORY_SPEND_DATA_FETCHED",
	REMOVE_CATEGORY_SPEND_DATA: "REMOVE_CATEGORY_SPEND_DATA",
};
