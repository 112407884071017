import { Link } from "react-router-dom";
import React from "react";
import PropTypes from "prop-types";

function LinkOrDiv({ to, style = {}, ...props }) {
	if (!to) return <div style={style} {...props} />;
	return (
		<Link to={to} style={{ textDecoration: "none", ...style }} {...props} />
	);
}

LinkOrDiv.propTypes = {
	to: PropTypes.string,
	style: PropTypes.object,
};
export default LinkOrDiv;
