import React from "react";
import { useSelector } from "react-redux";
import { FEATURE_NAME } from "../constants/plan.constant";
import { getValueFromLocalStorage } from "utils/localStorage";

export function useShouldRenderFeature() {
	const userInfo = useSelector((state) => state.userInfo);
	const shouldRenderFeature = (featureName) => {
		return shouldRenderLogic(featureName, userInfo);
	};
	return {
		shouldRenderFeature,
	};
}

function shouldRenderLogic(moduleName, userInfo) {
	let gate = [];
	if (userInfo.org_plan?.enable) {
		gate = [...gate, ...userInfo.org_plan.enable];
	}
	if (userInfo.org_plan?.add_on) {
		gate = [...gate, ...userInfo.org_plan.add_on];
	}

	if (!gate.length) {
		gate = Object.keys(FEATURE_NAME);
	}

	if (Array.isArray(moduleName)) {
		return moduleName.some((module) => gate.includes(module))
			? true
			: false;
	} else {
		return gate.includes(moduleName);
	}
}

export function filterTableColumns(columnsObj) {
	const columnsArr = Object.keys(columnsObj);
	const user_role = getValueFromLocalStorage("userInfo").user_role;
	columnsArr.forEach((columnKey) => {
		if (
			(columnsObj[columnKey].shouldRender &&
				columnsObj[columnKey].shouldRender() === false) ||
			(columnsObj[columnKey].betaKey &&
				!org_beta_features.includes(columnsObj[columnKey].betaKey)) ||
			(columnsObj[columnKey].hideForRoles &&
				columnsObj[columnKey].hideForRoles.includes(user_role))
		) {
			delete columnsObj[columnKey];
		}
	});
	return columnsObj;
}

export function filterByFeature(list, shouldRenderFeature) {
	return list.reduce((filteredList, item) => {
		if (!item.hasOwnProperty("featureName")) {
			filteredList.push(item);
		} else if (
			item.hasOwnProperty("featureName") &&
			shouldRenderFeature(item.featureName)
		) {
			filteredList.push(item);
		}

		if (
			(item.children && Array.isArray(item.children)) ||
			(item.items && Array.isArray(item.items))
		) {
			filteredList.push(
				...filterByFeature(item.children, shouldRenderFeature)
			);
		}

		return filteredList;
	}, []);
}
