import RulesPreview from "modules/transactionMapping/components/Rules/RulesPreview";
import React from "react";
import PropTypes from "prop-types";

const ConditionsRenderer = ({ data }) => {
	const { conditions, _id } = data;
	if (conditions?.any?.[0]["all"])
		return (
			<RulesPreview
				conditions={conditions["any"][0]["all"]}
				groupAfter={1}
				allConditions={conditions}
				redirectUrl={`/spends/automation/edit/${_id}`}
			/>
		);
	return "-";
};

export default ConditionsRenderer;

ConditionsRenderer.propTypes = {
	data: PropTypes.object,
};
