import React from "react";
import { DensityEnum, useTableContext } from "./TableContext/context";
import { invoke } from "./utils";
import { Tooltip, Typography } from "@zluri/ui-components";

interface ITableFooter {
	page: number;
	recordsPerPage: number;
	total: number;
	isInfinite: number;
}
function TableFooter({
	page = 0,
	recordsPerPage = 0,
	total = 0,
	isInfinite,
}: ITableFooter) {
	const { paginationText, density } = useTableContext();
	const displayInfo = invoke(() => {
		if (!total) return `0-0`;

		const start = page * recordsPerPage + 1;
		const end = (page + 1) * recordsPerPage;
		return `${start}-${end > total ? total : end}`;
	});
	return (
		<footer
			style={{
				background: "#F6F7FA",
				borderRadius: "0px 0px 8px 8px",
				display: "flex",
				alignItems: "center",
				border: "1px solid #e4e3e2",
				borderTop: "none",
				overflow: "hidden",
				padding: 0,
			}}
		>
			<div className="flex justify-content-between align-items-center flex-1">
				<div className="flex align-items-center flex-1 ">
					<div className="z_v2_table_footer_button">
						{isInfinite ? (
							<Typography variant="button_extrasmall_medium">
								Showing all {total?.toLocaleString()}{" "}
								<span className="text-capitalize">
									{paginationText}
								</span>
							</Typography>
						) : (
							<Typography variant="button_extrasmall_medium">
								Showing{" "}
								<Typography variant="button_extrasmall_medium">
									{displayInfo}{" "}
								</Typography>
								of {total?.toLocaleString()}{" "}
								<span className="text-capitalize">
									{paginationText}
								</span>
							</Typography>
						)}
					</div>

					{!isInfinite && (
						<div className="z_v2_table_footer_button">
							<Typography variant="button_extrasmall_medium">
								{recordsPerPage} Records Per Page
							</Typography>
						</div>
					)}

					<div className="z_v2_table_footer_button">
						<Typography variant="button_extrasmall_medium">
							{density === DensityEnum.COMPACT
								? "Compact"
								: "Comfortable"}
						</Typography>
					</div>
				</div>
				{/* <div className="z_v2_table_footer_button">
					<Icon name="recent" color="#717171" />
					<Typography
						variant="button_extrasmall_medium mr-1"
						color="#717171"
					>
						Data updated
					</Typography>

					<Typography
						variant="button_extrasmall_medium"
						color="#484848"
					>
						03 seconds ago
					</Typography>
				</div> */}
			</div>
		</footer>
	);
}
export default TableFooter;
