import React from "react";
import available_status from "assets/available_status.svg";
import unavailable from "assets/unavailable.svg";
import non_compliant from "assets/non_compliant.svg";
import validated from "assets/validated.svg";
import check_green_round from "assets/check_green_round.svg";
import error_red_round from "assets/error_red_round.svg";
import ContentLoader from "react-content-loader";
import { times } from "underscore";
import { BUTTON_VARIANT, GREY_VARIANT, Typography } from "@zluri/ui-components";

export const complianceDetailKeyMap = {
	status: "compliance_status",
	name: "compliance_name",
	link: "compliance_link",
	logo: "compliance_image",
	description: "compliance_description",
	effective_from: "compliance_start_date",
	expires_on: "compliance_end_date",
	proofs: "proofs",
	documents: "documents",
};

export const API_COMPLIANCE_STATUS = {
	NO_INFORMATION: "no information",
	AVAILABLE: "available",
	REJECTED: "rejected",
	APPROVED: "approved",
};

export const LOCAL_COMPLIANCE_STATUS = {
	VALIDATED: "Validated",
	AVAILABLE: "Available",
	NON_COMPLIANT: "Non Compliant",
	UNAVAILABLE: "No Information",
};

export const GRADE_COLORS = {
	GREEN_ACCENT: "#7FBB61",
	GREEN: "#5FCF64",
	GREYISH_YELLOW: "#9FA65E",
	LIGHT_BROWN: "#BE925B",
	RED: "#BB2915",
	DARK_BROWN: "#FE6955",
	PURPLE: "#DE7D58",
	GREY: "#717171",
	VIOLET: "#9E17FF",
};

export const compliant_status_options = [
	{
		key: API_COMPLIANCE_STATUS.AVAILABLE,
		label: (
			<Typography
				variant={BUTTON_VARIANT.BUTTON_EXTRA_SMALL_MEDIUM}
				color={GREY_VARIANT.SECONDARY_GREY_2}
			>
				{LOCAL_COMPLIANCE_STATUS.AVAILABLE}
			</Typography>
		),
		text: LOCAL_COMPLIANCE_STATUS.AVAILABLE,
		status_icon: available_status,
		filter: "invert(26%) sepia(71%) saturate(2309%) hue-rotate(187deg) brightness(100%) contrast(87%)",
		color: "#1176BE",
		bg: "#E6F5FF",
	},
	{
		key: API_COMPLIANCE_STATUS.NO_INFORMATION,
		label: (
			<Typography
				variant={BUTTON_VARIANT.BUTTON_EXTRA_SMALL_MEDIUM}
				color={GREY_VARIANT.SECONDARY_GREY_2}
			>
				{LOCAL_COMPLIANCE_STATUS.UNAVAILABLE}
			</Typography>
		),
		text: LOCAL_COMPLIANCE_STATUS.UNAVAILABLE,
		status_icon: unavailable,
		filter: "invert(8%) sepia(0%) saturate(866%) hue-rotate(271deg) brightness(105%) contrast(88%)",
		color: "#484848",
		bg: "#DDDDDD",
	},
	{
		key: API_COMPLIANCE_STATUS.APPROVED,
		label: (
			<Typography
				variant={BUTTON_VARIANT.BUTTON_EXTRA_SMALL_MEDIUM}
				color={GREY_VARIANT.SECONDARY_GREY_2}
			>
				{LOCAL_COMPLIANCE_STATUS.VALIDATED}
			</Typography>
		),
		text: LOCAL_COMPLIANCE_STATUS.VALIDATED,
		status_icon: validated,
		filter: "invert(32%) sepia(97%) saturate(931%) hue-rotate(90deg) brightness(100%) contrast(106%)",
		color: "#009307",
		bg: "#E7F8E8",
	},
	{
		key: API_COMPLIANCE_STATUS.REJECTED,
		label: (
			<Typography
				variant={BUTTON_VARIANT.BUTTON_EXTRA_SMALL_MEDIUM}
				color={GREY_VARIANT.SECONDARY_GREY_2}
			>
				{LOCAL_COMPLIANCE_STATUS.NON_COMPLIANT}
			</Typography>
		),
		text: LOCAL_COMPLIANCE_STATUS.NON_COMPLIANT,
		status_icon: non_compliant,
		filter: "invert(22%) sepia(69%) saturate(2948%) hue-rotate(352deg) brightness(83%) contrast(96%)",
		color: "#BB2915",
		bg: "#FFE9E5",
	},
];

export const ICON_LABEL = {
	true: {
		icon: check_green_round,
		label: "True",
		bg: "#E7F8E8",
		color: "#009307",
	},
	false: {
		icon: error_red_round,
		label: "False",
		bg: "#FFE9E5",
		color: "#BB2915",
	},
	SUPPORTED: {
		icon: check_green_round,
		label: "Supported",
		bg: "#E7F8E8",
		color: "#009307",
	},
	UNSUPPORTED: {
		icon: error_red_round,
		label: "Unsupported",
		bg: "#FFE9E5",
		color: "#BB2915",
	},
	Compliant: {
		icon: check_green_round,
		label: "Compliant",
		bg: "#E7F8E8",
		color: "#009307",
	},
	"Non-compliant": {
		icon: error_red_round,
		label: "Non-Compliant",
		bg: "#FFE9E5",
		color: "#BB2915",
	},
	vulnerable: {
		icon: error_red_round,
		label: "Vulnerable",
		bg: "#FFE9E5",
		color: "#BB2915",
	},
	"Not vulnerable": {
		icon: check_green_round,
		label: "Not Vulnerable",
		bg: "#E7F8E8",
		color: "#009307",
	},
};

export const SecurityProbeSummaryLoader = (
	<div className="d-flex align-items-center flex-1">
		<span className="d-flex gap-8 align-items-center">
			<ContentLoader
				height="64px"
				width="60px"
				backgroundColor="#EBEBEB"
				foregroundColor="#f3f3f3"
			>
				<rect width="100%" height="100%" rx="8" />
			</ContentLoader>
			<span className="d-flex flex-column gap-4">
				<ContentLoader
					height="30px"
					width="120px"
					backgroundColor="#EBEBEB"
					foregroundColor="#f3f3f3"
				>
					<rect width="100%" height="100%" rx="8" />
				</ContentLoader>
				<ContentLoader
					height="30px"
					width="120px"
					backgroundColor="#EBEBEB"
					foregroundColor="#f3f3f3"
				>
					<rect width="100%" height="100%" rx="8" />
				</ContentLoader>
			</span>
		</span>
		<span className="d-flex align-items-center gap-8 ml-auto">
			<span className="d-flex flex-column gap-4">
				<ContentLoader
					height="30px"
					width="300px"
					backgroundColor="#EBEBEB"
					foregroundColor="#f3f3f3"
				>
					<rect width="100%" height="100%" rx="8" />
				</ContentLoader>
				<ContentLoader
					height="30px"
					width="300px"
					backgroundColor="#EBEBEB"
					foregroundColor="#f3f3f3"
				>
					<rect width="100%" height="100%" rx="8" />
				</ContentLoader>
			</span>
			<ContentLoader
				height="64px"
				width="100"
				backgroundColor="#EBEBEB"
				foregroundColor="#f3f3f3"
			>
				<rect width="100%" height="100%" rx="8" />
			</ContentLoader>
			<ContentLoader
				height="64px"
				width="100"
				backgroundColor="#EBEBEB"
				foregroundColor="#f3f3f3"
			>
				<rect width="100%" height="100%" rx="8" />
			</ContentLoader>
		</span>
	</div>
);

export const ProbeListLoader = (
	<span className="d-flex flex-column gap-8 flex-1 padding_10">
		<span className="d-flex flex-1 align-items-center gap-8">
			{times(5, () => (
				<ContentLoader
					height="62px"
					width="220"
					backgroundColor="#EBEBEB"
					foregroundColor="#f3f3f3"
				>
					<rect width="100%" height="100%" rx="8" />
				</ContentLoader>
			))}
		</span>
		<span className="d-flex flex-column gap-8 flex-1">
			<ContentLoader
				height="200"
				width="100%"
				backgroundColor="#EBEBEB"
				foregroundColor="#f3f3f3"
			>
				<rect width="100%" height="100%" rx="8" />
			</ContentLoader>
			<span className="d-flex align-items-center gap-8">
				{times(2, () => (
					<ContentLoader
						height="200"
						width="50%"
						backgroundColor="#EBEBEB"
						foregroundColor="#f3f3f3"
					>
						<rect width="100%" height="100%" rx="8" />
					</ContentLoader>
				))}
			</span>
		</span>
	</span>
);
