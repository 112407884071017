import React, { useCallback, useEffect, useRef, useState } from "react";

import {
	Typography,
	GREY_VARIANT,
	BODY_VARIANT,
	Field,
	BUTTON_VARIANT,
	ImageOrNameBadge,
} from "@zluri/ui-components";

import { Loader } from "common/Loader/Loader";
import iconSearch from "assets/applications/Icon_Search.svg";
import { debounce } from "utils/common";
import { ApiMapper } from "../BulkEdit.constants";
import { client } from "utils/client";
import { TriggerIssue } from "utils/sentry";
import { isEmpty } from "underscore";

export default function Search({ option, entity, onOptionClick }) {
	const [suggestions, setSuggestions] = useState([]);
	const [initialSuggestions, setInitialSuggestions] = useState([]);
	const [loading, setLoading] = useState(false);
	const [loadingInitial, setLoadingInitial] = useState(false);
	const [query, setQuery] = useState("");
	const searchAPI = ApiMapper?.[entity]?.[option?.searchApi];
	const initialSearchAPI = ApiMapper?.[entity]?.[option?.initialSearchApi];
	const cancelToken = useRef();
	const { placeholder, fields, emptySuggestionsText, emptyQueryText } =
		option?.searchProps;

	const fetchInitialSuggestions = async () => {
		if (initialSuggestions.length || loadingInitial || !initialSearchAPI)
			return;

		try {
			setLoadingInitial(true);
			const res = await initialSearchAPI();
			if (res?.results) {
				setInitialSuggestions(res.results);
			}
			if (res?.data) {
				setInitialSuggestions(res.data);
			} else if (Array.isArray(res)) {
				setInitialSuggestions(res);
			}
		} catch (err) {
			TriggerIssue("Error while fetching records", err);
		} finally {
			setLoadingInitial(false);
		}
	};
	useEffect(() => {
		fetchInitialSuggestions();
	}, []);

	const handleInputChange = (val) => {
		setQuery(val?.trim());
		if (val?.length === 0) {
			setQuery("");
			setSuggestions([]);
		}
		if (cancelToken.current) {
			cancelToken.current.cancel(
				"Operation cancelled in favor of a new request"
			);
		}
		if (val?.length >= 1) {
			setLoading(true);
			cancelToken.current = client.CancelToken.source();
			generateSuggestions(val, cancelToken.current);
		}
	};
	const generateSuggestions = useCallback(
		debounce((query, reqCancelToken) => {
			searchAPI &&
				searchAPI(query, reqCancelToken, true)
					.then((res) => {
						if (res?.results) {
							setSuggestions(res.results);
						}
						if (res?.data) {
							setSuggestions(res.data);
						} else if (Array.isArray(res)) {
							setSuggestions(res);
						}
					})
					.catch((err) => {
						setSuggestions([]);
						TriggerIssue("Error while fetching org users", err);
					})
					.finally(() => setLoading(false));
		}, 300),
		[]
	);

	const handleClick = (data) => {
		setQuery("");
		setSuggestions([]);
		onOptionClick(option, data);
	};

	const showLoadingIndicator = loadingInitial || loading;
	const searchSuggestion = isEmpty(query) ? initialSuggestions : suggestions;

	return (
		<div className="padding_2">
			<div className="mb-1 w-100 position-relative">
				<Field
					handlers={{
						onChange: (e) => {
							handleInputChange(e?.target?.value);
						},
					}}
					value={query}
					placeholder={placeholder || "Search"}
					className="w-100 border-radius-8 primary-color-border pl-4 font-11"
				/>
				<img
					src={iconSearch}
					alt=""
					className="position-absolute"
					style={{
						left: "8px",
						top: "7px",
						width: "12px",
						height: "12px",
						filter: "invert(32%) sepia(93%) saturate(1244%) hue-rotate(200deg) brightness(90%) contrast(97%)",
					}}
				/>
			</div>
			{!showLoadingIndicator ? (
				<div
					className="overflow-auto hide-scrollbar"
					style={{ maxHeight: "110px" }}
				>
					{searchSuggestion?.length === 0 ? (
						<div className="d-flex justify-content-center mt-3 mb-3 ml-auto mr-auto">
							<Typography
								variant={BODY_VARIANT.BODY_2_REGULAR}
								color={GREY_VARIANT.SECONDARY_GREY_3}
							>
								{query?.length > 0
									? emptySuggestionsText || "No user found"
									: emptyQueryText || "Search for users"}
							</Typography>
						</div>
					) : (
						searchSuggestion?.map((data, idx) => {
							return (
								<div
									className="d-flex align-items-center w-100 bulk-edit-option"
									key={data?._id + idx}
									onClick={() => {
										handleClick(data);
									}}
								>
									<div className="d-flex flex-row w-100 align-items-center padding_6 gap-6 cursor-pointer">
										<ImageOrNameBadge
											url={data[fields?.logo]}
											name={data[fields?.name]}
											height={16}
											width={16}
											fontSize={8}
										/>

										<div className="d-flex flex-column">
											<Typography
												variant={
													BUTTON_VARIANT.BUTTON_EXTRA_SMALL_MEDIUM
												}
												color={
													GREY_VARIANT.SECONDARY_GREY_2
												}
												className="text-break"
											>
												{data[fields?.name]}
											</Typography>
											<Typography
												variant={
													BODY_VARIANT.BODY_3_REGULAR
												}
												color={
													GREY_VARIANT.SECONDARY_GREY_3
												}
												className="text-break"
											>
												{data[fields?.description]}
											</Typography>
										</div>
									</div>
								</div>
							);
						})
					)}
				</div>
			) : (
				<Loader height={60} width={60} />
			)}
		</div>
	);
}
