import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import FilterPill from "../FilterPill/FilterPill";
import { useEffect } from "react";
import { useState } from "react";
import { getActionFiltersForReviewTable } from "modules/AccessReview/redux/AccessReview.actions";
import { accessReviewFormatter } from "./ActionFiltersFormatters";
import { isEmpty } from "underscore";
import { useTableContext } from "containers/v2table/TableContext/context";
import { V2_TABLE_ENTITIES } from "modules/AccessReview/constants/constants";
import { getSegmentFromURL } from "@zluri/ui-components";

// DONOT USE THIS IN ANY OTHER PLACES WITHOUT CONTACTTING WITH DESIGNERS!!!
export default function ActionFilters({
	entity,
	appliedFilters,
	subEntityData,
	apiProps,
}) {
	let isEmployee = getSegmentFromURL(1) === "user";
	const apiAndOtherInfo = {
		[V2_TABLE_ENTITIES.APP_OVERVIEW_USERS]: {
			action: getActionFiltersForReviewTable,
			triggerMessage: "Error while Fetching Data",
			user_id: {
				formatter: accessReviewFormatter,
				tooltipContent:
					"Users and their reviewers cannot be the same, update the reviewers to a different user to proceed",
			},
			matchParam: "field_id",
			reduxStateName: "accessReview",
		},
		[V2_TABLE_ENTITIES.APP_OVERVIEW_USERS_EMP_VIEW]: {
			action: getActionFiltersForReviewTable,
			triggerMessage: "Error while Fetching Data",
			user_id: {
				formatter: accessReviewFormatter,
				tooltipContent:
					"Users and their reviewers cannot be the same, update the reviewers to a different user to proceed",
			},
			matchParam: "field_id",
			reduxStateName: "accessReview",
		},
	};
	const tableData = useSelector(
		(state) => state.v2Table[subEntityData?.entity || entity]
	);

	const [filterFetched, setFilterFetched] = useState(false);
	useEffect(() => {
		if (tableData && !filterFetched && tableData.filter_by) {
			setFilterFetched(true);
		}
	}, [tableData?.filter_by]);

	useEffect(() => {
		if (filterFetched) {
			dispatch(
				apiAndOtherInfo[entity]?.action(
					apiProps,
					tableData?.filter_by || [],
					setFilterFetched,
					isEmployee
				)
			);
		}
	}, [filterFetched]);

	const actionFilters = useSelector(
		(state) =>
			state?.[apiAndOtherInfo[entity]?.reduxStateName]?.actionFilters ||
			[]
	);
	const dispatch = useDispatch();

	const isActiveFilter = (filter) => {
		const matchedFilter = appliedFilters?.find(
			(f) => f.field_id === filter?.field_id
		);
		if (
			matchedFilter &&
			matchedFilter?.field_values?.[0] === filter?.field_values?.[0]
		) {
			return true;
		}
		return false;
	};
	const handleClick = useCallback((filter) => {
		const updatedFilters = appliedFilters.filter(
			(appliedFilter) => appliedFilter.field_id !== filter.field_id
		);
		updatedFilters.push(filter);
		dispatch({
			type: "GET_TABLE_DATA",
			payload: {
				shouldRefresh: true,
				filterBy: updatedFilters,
				entity: subEntityData?.entity || entity,
				subEntityData,
				apiProps: apiProps,
			},
		});
	});
	const handleRemove = useCallback((filter) => {
		const updatedFilters = appliedFilters.filter(
			(appliedFilter) => appliedFilter.field_id !== filter.field_id
		);
		dispatch({
			type: "GET_TABLE_DATA",
			payload: {
				shouldRefresh: true,
				filterBy: updatedFilters,
				entity,
				subEntityData,
				apiProps: apiProps,
			},
		});
	});

	const ActionFilter = ({ item }) => {
		return (
			!isEmpty(item) && (
				<FilterPill
					variant="large"
					onClick={handleClick.bind(null, item?.filter_by[0])}
					isActive={isActiveFilter(item?.filter_by[0])}
					onRemove={handleRemove.bind(null, item?.filter_by[0])}
					tooltip={
						apiAndOtherInfo[entity][
							item?.filter_by[0]?.[
								apiAndOtherInfo[entity].matchParam
							]
						]?.tooltipContent
					}
					customLabel={apiAndOtherInfo[entity][
						item?.filter_by[0]?.[apiAndOtherInfo[entity].matchParam]
					]?.formatter(item)}
					isActionFilter={true}
				/>
			)
		);
	};

	const { isAnyDataSelected, enableBulkEdit } = useTableContext();

	if (isAnyDataSelected && enableBulkEdit) return null;

	return (
		<div className="d-flex gap-10">
			{Array.isArray(actionFilters?.data) ? (
				actionFilters?.data?.map((item, idx) => (
					<div
						key={
							"quickFilter_" + item?.filter_by[0]?.field_id + idx
						}
					>
						<ActionFilter item={item} />
					</div>
				))
			) : (
				<ActionFilter item={actionFilters?.data} />
			)}
		</div>
	);
}

ActionFilters.propTypes = {
	entity: PropTypes.string,
	appliedFilters: PropTypes.array,
	subEntityData: PropTypes.object,
	apiProps: PropTypes.object,
};
